import {actionCreatorFactory} from 'typescript-fsa';
import { ImportHistory } from '../reducers/file_reducer';

const actionCreator = actionCreatorFactory('File');

// FETCH TEMPLATE FILE
export const getTemplateFile = actionCreator.async<string, undefined>(
	'GET_IMPORT_TEMPLATE'
);

// FETCH PROCESSED IMPORT FILE
export const getProcessedImportFile = actionCreator.async<string, undefined>(
	'GET_PROCESSED_IMPORT_FILE'
);

// FETCH IMPORT HISTORY DATA
export const getImportHistory = actionCreator.async<undefined, ImportHistory[]>(
	'GET_IMPORT_HISTORY_DATA'
);

// UPLOAD IMPORT TABLE
export type FileUpload = {
	file: number[];
	fileName: string;
};
export const uploadImportTable = actionCreator.async<FileUpload, ImportHistory>(
	'UPLOAD_IMPORT_TABLE'
);
