import { isIOS, isAndroid } from './is_mobile';

export const openMaps = (address: string | undefined) => {
    let uri = encodeURI(`https://www.google.com/maps?q=${address}`);
	if (isIOS) {
        uri = encodeURI(`maps://?q=${address}`);
	} else if (isAndroid) {
        uri = encodeURI(`geo://?q=${address}`);	
	}
    window.open(uri, '_self', 'location=yes');
};

export const makeOpenMaps = (address: string) => () => openMaps(address);
