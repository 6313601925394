import { isType } from 'typescript-fsa';
import { isEqual } from 'lodash';
import {
	FinanceParams,
	GetFinanceCash,
	GetFinanceInvestments,
	GetFinanceBalances,
	UpdateFinanceFilter,
	UpdateFinanceDates,
	SetFinanceDownlineAgent,
} from '../actions/finance_actions';
import { ClearCaches } from '../actions/authentication_actions';
import moment from 'moment';
import { DownlineAgent } from './product_reducer';
import { EMPTY_DOWNLINE_AGENT } from '../utilities/empty_entities';
import { Logout } from '../actions/authentication_actions';
import { GraphDataFilterValues, ChartGroup } from './performance_reducer';

export interface FinanceState {
	downlineAgent: DownlineAgent;
	filter: GraphDataFilterValues;
	cashCharts: ChartGroup;
	balanceCharts: ChartGroup;
	investmentCharts: ChartGroup;
}

export const getDefaultFinanceParams = (): FinanceParams => ({
	startDate: moment()
		.subtract(11, 'months')
		.startOf('month')
		.toDate().toDateString(),
	endDate: moment()
		.endOf('day')
		.toDate().toDateString(),
});

export const initialFinanceFilter: GraphDataFilterValues = (() => {
	const defaultParams = getDefaultFinanceParams();
	return {
		dateRange: 'Custom Date Range',
		startDate: moment(new Date(defaultParams.startDate)).toDate(), // using Date to fix moment deprecation
		endDate: moment(new Date(defaultParams.endDate)).toDate(), // using Date to fix moment deprecation
	};
})();

const initialState: FinanceState = {
	downlineAgent: EMPTY_DOWNLINE_AGENT,
	filter: initialFinanceFilter,
	cashCharts: { charts: [{title: '', summaryValue: '', data: [] }], summaryValue: '', detail: []},
	investmentCharts: {charts: [{ title: '', summaryValue: '', data: [] }], summaryValue: '', detail: []},
	balanceCharts: {charts: [{ title: '', summaryValue: '', data: [] }], summaryValue: '', detail: []},
};
export const financeReducer = (state = initialState, action: any) => {
	if (isType(action, GetFinanceCash.done)) {
		if (isEqual(state.cashCharts, action.payload.result)) {
			return state;
		}
		return {
			...state,
			cashCharts: action.payload.result,
		};
	}
	if (isType(action, GetFinanceBalances.done)) {
		if (isEqual(state.balanceCharts, action.payload.result)) {
			return state;
		}
		return {
			...state,
			balanceCharts: action.payload.result,
		};
	}
	if (isType(action, GetFinanceInvestments.done)) {
		if (isEqual(state.investmentCharts, action.payload.result)) {
			return state;
		}
		return {
			...state,
			investmentCharts: action.payload.result,
		};
	}
	if (isType(action, UpdateFinanceFilter)) {
		return {
			...state,
			filter: {
				...state.filter,
				dateRange: action.payload.filter,
			},
		};
	}
	if (isType(action, UpdateFinanceDates)) {
		return {
			...state,
			filter: {
				...state.filter,
				startDate: moment(action.payload.params.startDate).toDate(),
				endDate: moment(action.payload.params.endDate).toDate(),
			},
		};
	}
	if (isType(action, SetFinanceDownlineAgent)) {
		return {
			...state,
			downlineAgent: action.payload.agent || EMPTY_DOWNLINE_AGENT,
		};
	}
	if (isType(action, Logout.started)) {
		return {
			...initialState,
		};
	}
	if (isType(action, ClearCaches)) {
		return {
			...initialState,
		};
	}
	return state;
};
