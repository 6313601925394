import { StartCallSequence, StartCallSequenceAndLoadActivities, SelectActivitiesForCallSequence, SelectContactForCallSequence, CancelCallSequence } from './../../actions/sequence/call_sequence_actions';
import { Activity } from '../activity_reducer';
import { PhoneMetaData, composePhoneMetaData } from '../../utilities/phone_email_util';
import { isType } from 'typescript-fsa';
import { Logout } from '../../actions/authentication_actions';
import { ClearCaches } from '../../actions/authentication_actions';
import { Action } from 'redux';
import { StartClickToCallPhoneCall, TwilioOnDisconnect } from '../../actions/click_to_call_actions';

export enum CallSequenceStep {
	Inactive,
	LoadActivities,
	SelectActivities,
	SelectContact,
	DispositionActivity,
}

export interface CallSequenceState {
	step: CallSequenceStep;
	activitiesToDisposition: Activity[];
	phoneMetaData: PhoneMetaData;
	contactId?: string;
	contactPhoneNumber?: string;
}

const initialState: CallSequenceState = {
	step: CallSequenceStep.Inactive,
	activitiesToDisposition: [],
	phoneMetaData: {
		doNotContact: false,
		phones: [],
		preferred: '',
		doNotSms: false
	},
	contactId: undefined,
	contactPhoneNumber: undefined,
};


export function callSequenceReducer(
	state: CallSequenceState = initialState,
	action: Action
): CallSequenceState {
	if (isType(action, StartCallSequence)) {
		const phoneMetaData = composePhoneMetaData(action.payload.contacts);
		return {
			...state,
			phoneMetaData,
			step: action.payload.hasActivities
				? CallSequenceStep.SelectActivities
				: phoneMetaData.phones.length > 0
					? CallSequenceStep.SelectContact
					: CallSequenceStep.DispositionActivity,
		};
	} else if (isType(action, StartCallSequenceAndLoadActivities.started)) {
		return {
			...state,
			step: CallSequenceStep.LoadActivities,
		};
	} else if (isType(action, StartCallSequenceAndLoadActivities.done)) {
		const phoneMetaData = composePhoneMetaData(action.payload.result.contacts);
		let step: CallSequenceStep;
		if (state.step == CallSequenceStep.Inactive) {
			step = CallSequenceStep.Inactive;

		} else if (action.payload.result.hasActivities) {
			step = CallSequenceStep.SelectActivities;

		} else if (phoneMetaData.phones.length > 0) {
			step = CallSequenceStep.SelectContact;

		} else {
			step = CallSequenceStep.DispositionActivity;
		}
		return {
			...state,
			phoneMetaData,
			step,
		};
	} else if (isType(action, SelectActivitiesForCallSequence)) {
		return {
			...state,
			activitiesToDisposition: action.payload,
			step:
				state.phoneMetaData.phones.length > 0
					? CallSequenceStep.SelectContact
					: CallSequenceStep.DispositionActivity,
		};
	} else if (isType(action, SelectContactForCallSequence)) {
		return {
			...state,
			...action.payload,
			step: CallSequenceStep.DispositionActivity,
		};
	} else if (isType(action, StartClickToCallPhoneCall)) {
		return {
			...state,
			contactId: action.payload.contactId,
			contactPhoneNumber: action.payload.number,
			step: CallSequenceStep.Inactive,
		};
	} else if (isType(action, TwilioOnDisconnect)) {
		return {
			...state,
			step: CallSequenceStep.DispositionActivity,
		};
	} else if (isType(action, Logout.started) || isType(action, ClearCaches)) {
		return {
			...initialState,
		};
	} else if (isType(action, CancelCallSequence)) {
		return {
			...initialState,
			step: CallSequenceStep.Inactive,
		};
	} else {
		return state;
	}
}
