import { Button, Card, Grid } from '@material-ui/core';
import React from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import { navigateTo } from '../../actions/navigation_actions';
import { finishFirstTimeLogin } from '../../actions/authentication_actions';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { NavigationProps, navRoutes } from '../../components/nav/Routes';
import { themePalette } from '../../utilities/branding';
import { AppState } from '../../reducers/index';
import { DoneWatchingVideo } from '../../actions/user_actions';
import { Lookup, Lookups } from '../../utilities/lookup';
import { Dispatch } from 'redux';

interface StateProps {
	firstTimeLogin: boolean;
	introVideoUrl: string;
	isDesktop: boolean;
	videoFullScreen: boolean;
}

interface DispatchProps {
	finishFirstTimeLogin: () => void;
	doneWatchingVideo: () => void;
}

interface State {
	mode: string;
}

type Props = StateProps & DispatchProps & NavigationProps;

class IntroVideo extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			mode: window.innerHeight < 520 ? 'landscape' : 'portrait',
		};
	}

	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);
		this.updateDimensions();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions);
	}

	updateDimensions = () => {
		this.setState({
			mode: window.innerHeight < 520 ? 'landscape' : 'portrait',
		});
	};

	continueClick = () => {
		this.props.finishFirstTimeLogin();
		this.props.doneWatchingVideo();
		this.props.navigateTo( navRoutes.home.path);
	};

	render() {
		return (
			<BasePageContainer
				blockDialogs
				topComponent={
					<HeaderBarComponent
						title={<span style={{ marginLeft: 10 }}>Welcome Video</span>}
						hideSideMenuButton={
							this.props.firstTimeLogin || this.props.videoFullScreen
						}
						hideBackButton={
							this.props.firstTimeLogin || this.props.videoFullScreen
						}
					/>
				}
				bottomComponent={
					this.props.firstTimeLogin || this.props.videoFullScreen ? (
						<Button
							onClick={this.continueClick}
							style={{
								backgroundColor: themePalette.accept_button,
								color: themePalette.negative_text,
								float: 'right',
								margin: 10,
							}}
						>
							Continue
						</Button>
					) : (
						undefined
					)
				}
				middleComponent={
					<Grid container>
						<Grid item lg={3} />
						<Grid
							item
							xs={12}
							lg={6}
							style={{ textAlign: 'center', padding: 10 }}
						>
							<Card>
								<iframe
									id="video"
									src={this.props.introVideoUrl}
									frameBorder="0"
									height={this.state.mode === 'portrait' ? 300 : 200}
									allowFullScreen
									style={{ padding: '10px 0 20px 0' }}
								/>
							</Card>
						</Grid>
					</Grid>
				}
			/>
		);
	}
}

function mapStateToProps(state): StateProps {
	
	const introVideoUrl = state.lookup.getFirstChildOfType(Lookups.IntroVideoURL);
	return {
		firstTimeLogin: state.authentication.firstTimeLogin,
		introVideoUrl,
		isDesktop: state.layout.desktop,
		videoFullScreen: state.user.videoFullScreen,
	};
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps & Partial<NavigationProps>{
	return {
		navigateTo: (route: string) =>
			dispatch(navigateTo(route)),
		finishFirstTimeLogin: () => dispatch(finishFirstTimeLogin()),
		doneWatchingVideo: () => dispatch(DoneWatchingVideo()),
	};
}

export const IntroVideoContainer = connect(mapStateToProps, mapDispatchToProps, true)(
	IntroVideo
);
