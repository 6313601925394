import { Grid, Paper, Switch, Button, Icon, FormControlLabel } from '@material-ui/core';
import React from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { navRoutes } from '../../components/nav/Routes';
import {
	StoreLeadFilters,
	GetFilteredLeads,
	ToggleLeadFilter,
	ToggleLeadListMultiSelect,
	SelectLeadListItem,
} from '../../actions/lead_actions';
import { Lead, LeadListSelected } from '../../reducers/LeadReducer';
import { Loaded } from '../../utilities/utilities';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import { LeadsFilter } from '../../components/lead/lead_filter';
import { Contact } from '../../reducers/ContactReducer';
import { LeadFilters } from '../../reducers/LeadFilterReducer';
import { LeadListItem } from '../../components/lead/lead_list_item';
import { Lookups } from '../../utilities/lookup/lookup';
import { Activity } from '../../reducers/activity_reducer';
import { formatLeadFiltersPayload } from '../../utilities/lead_util';
import { GetHousehold, getHouseholdTags } from '../../actions/household_actions';
import { GetEmployer } from '../../actions/employer_actions';
import { EMPTY_CONTACT, EMPTY_LEAD } from '../../utilities/empty_entities';
import { InfiniteScroller } from '../../components/utility/infinite_scroller';
import { themePalette, themeLinks } from '../../utilities/branding';
import { createHelpAction, createMenuAction, MoreMenu } from '../../components/nav/more_menu';
import { GetAgentFromJwt } from '../../actions/agent_actions';
import { isMobileDevice } from '../../utilities/is_mobile';
import { StoreLeadRoutesIndex } from '../../actions/lead_routes_actions';
import { LeadRoutePages } from '../../reducers/lead_routes_reducer';
import { Strings } from '../../assets/common/strings';
import { LeadInfoDialog } from './lead_info_modal';
import { ToggleLeadInfoDialog } from '../../actions/dialogs_actions';
import { CallSequence } from '../dialogs/sequence/call_sequence';
import { SmsSequence } from '../dialogs/sequence/sms_sequence';
import { enforceStylesType } from '../../utilities/styles_util';
import { SendSmsToLeadListSelection, SmsLeadSelectionCleanup } from '../../actions/sequence/sms_sequence_actions';
import { LookupDictionary } from '../../utilities/lookup/lookup_dictionary';
import { nav } from '../..';
import { AppState } from '../../reducers';
import { DesktopPadding } from '../../components/Layout/desktop_padding';
import { EmptyMessage } from '../../components/utility/empty_message';

interface StateProps {
	contacts: Loaded<Contact>[];
	isLoading: boolean;
	hasError: boolean;
	lineOfBusinessList: string[];
	leadFilters: LeadFilters;
	vendorList: string[];
	inputSourceList: string[];
	campaignList: string[];
	dateTypeList: string[];
	sortList: string[];
	ratingList: string[];
	leads: Loaded<Lead>[];
	activities: Loaded<Activity>[];
	firstName: string;
	preferredName: string;
	lastName: string;
	pageNumber: number;
	pageSize: number;
	moreLeadsToLoad: boolean;
	locationHistory: string[];
	userId: string;
	lookups: LookupDictionary;
	desktopCallEnabled: boolean;
	desktopTextEnabled: boolean;
	callAttemptsList: string[];
	multiSelectEnabled: boolean;
	selectedLeads: LeadListSelected;
	atLeastOneValidSmsLead: boolean;
	leadTagFilterResult: string[];
}

interface DispatchProps {
	getFilteredLeads: (
		filters: LeadFilters,
		pageNum: number,
		pageSize: number
	) => any;
	storeLeadFilters: (filterValues: LeadFilters) => void;
	getAgentByAgentCode: () => void;
	getHousehold: (id: string) => void;
	getEmployer: (id: string) => void;
	storeLeadRoutesIndex: (idnex: number) => void;
	openLeadListDialog: () => void;
	toggleMultiSelect: (event: React.ChangeEvent<HTMLInputElement> | null, checked: boolean) => void;
	selectLead: (lead: Lead) => void;
	clearSelectedLeads: () => void;
	storeSelectedLeadsAndStartSmsSequence: () => void;
	getHouseholdTags: (search: string) => void;
}

interface LeadListPageState {
	lead: Lead;
	contact: Contact;
	dispositionDialogIsOpen: boolean;
	contactId: string;
	phoneCallDialogOpen: boolean;
}

type LeadListPageProps = StateProps &
	DispatchProps &
	LeadFilters;

class LeadListPage extends React.Component<
	LeadListPageProps,
	LeadListPageState
	> {
	networkCallLock = false;
	styles: { [key: string]: React.CSSProperties } = {
		contactHeader: {
			color: themePalette.primary_text,
			textAlign: 'center',
			paddingTop: '8px',
			fontWeight: 'bold',
		},
		buttonActions: {
			paddingBottom: '16px',
			margin: '0px 14px',
		},
		topPadding: {
			paddingTop: '8px',
		},
		dialogFont: {
			fontSize: '13px',
		},
		dialogField: {
			fontSize: '13px',
			paddingTop: '8px',
		},
		dialogSpanText: {
			color: themePalette.primary_text,
		},
		loader: {
			margin: '8px',
			textAlign: 'center',
			fontSize: '15px',
			clear: 'both',
		},
	};

	constructor(props: LeadListPageProps) {
		super(props);

		this.state = {
			lead: EMPTY_LEAD,
			contact: EMPTY_CONTACT,
			dispositionDialogIsOpen: false,
			phoneCallDialogOpen: false,
			contactId: '',
		};
	}

	componentWillMount() {
		if (!this.props.firstName) {
			this.props.getAgentByAgentCode();
		}
		this.saveFilters(this.props.leadFilters, true);
	}

	componentWillReceiveProps(nextProps: LeadListPageProps) {
		if (this.props.isLoading && nextProps.isLoading === false)
			this.networkCallLock = false;
	}

	componentWillUnmount() {
		this.props.clearSelectedLeads();
	}

	loadMoreLeads = () => {
		if (
			!this.props.hasError &&
			this.props.moreLeadsToLoad &&
			!this.networkCallLock
		) {
			this.networkCallLock = true;
			const leadFilters: LeadFilters = {
				statusFilter: this.props.statusFilter,
				statusGroupFilter: this.props.statusGroupFilter,
				sortBy: this.props.sortBy,
				ratingFilter: this.props.ratingFilter,
				lineOfBusinessFilter: this.props.lineOfBusinessFilter,
				vendorFilter: this.props.vendorFilter,
				inputSourceFilter: this.props.inputSourceFilter,
				campaignFilter: this.props.campaignFilter,
				dateTypeFilter: this.props.dateTypeFilter,
				filterBySearch: this.props.filterBySearch,
				fromDate: this.props.fromDate,
				toDate: this.props.toDate,
				sortCategory: this.props.sortCategory,
				sortDirection: this.props.sortDirection,
				filterIsOpen: false,
				leadTypeFilter: this.props.leadTypeFilter,
				callAttempts: this.props.callAttempts,
				leadTags: this.props.leadTags,
            };
			this.handleFilters(leadFilters);
		}
	};

	saveFilters = (leadFilters: LeadFilters, startOver: boolean = false) => {
		this.handleFilters(leadFilters, startOver);
	};

	handleFilters = (leadFilters: LeadFilters, startOver: boolean = false) => {
		this.networkCallLock = true;
		const pageNum = startOver ? 0 : this.props.pageNumber + 1;
		this.props.getFilteredLeads(leadFilters, pageNum, this.props.pageSize);
		this.props.storeLeadFilters(leadFilters);
	};

	routeToLead = (lead: Lead) => {
		const { multiSelectEnabled, selectLead } = this.props;
		if (multiSelectEnabled) {
			selectLead(lead);
		}
		else {
			this.props.storeLeadRoutesIndex(this.props.leads.findIndex(currentLead => currentLead.data.id == lead.id));

			if (lead.employer) {
				nav.push(navRoutes.employer.path.replace(Strings.Navigation.EmployerId, lead.employer.id));
			} else {
				nav.push(navRoutes.household.path.replace(Strings.Navigation.HouseholdId, lead.householdId));
			}
		}
	}

	onLeadInfoIconClick = (lead: Lead) => {
		const { multiSelectEnabled, selectLead } = this.props;
		if (multiSelectEnabled) {
			selectLead(lead);
		}
		else {
			this.setState({ lead }, this.props.openLeadListDialog);
		}
	}

	setLead = (lead: Lead) => this.setState({ lead });

	renderLeads = () => {
		const { leadFilters, multiSelectEnabled, selectedLeads } = this.props

		const leadsArray = this.props.leads.map(lead => {
			if ((leadFilters.campaignFilter !== '' || leadFilters.vendorFilter !== 'All') && lead.data.fund) {
				return;
			}

			if (leadFilters.statusFilter.label !== 'All' && 
				leadFilters.statusFilter.value !== lead.data.status &&
				leadFilters.statusFilter.value !== lead.data.statusCode
				) {
				return;
			}

			return (
				<LeadListItem
					key={'lead-list-item-' + lead.data.id}
					{...lead}
					householdId={lead.householdId || ''}
					employerId={lead.employerId}
					iconClick={this.onLeadInfoIconClick}
					setLead={this.setLead}
					onClick={this.routeToLead}
					multiSelect={multiSelectEnabled}
					isSelected={Boolean(selectedLeads[lead.data.id])}
				/>
			)
		});

		return leadsArray;
	};

	private renderCustomContent() {
		const { selectedLeads, atLeastOneValidSmsLead, getHouseholdTags } = this.props;
		const selectedLeadCount = Object.keys(selectedLeads).filter(key => Boolean(selectedLeads[key])).length;
		return (
			<>
				<LeadsFilter
					ratingFilter={this.props.ratingFilter}
					statusFilter={this.props.statusFilter}
					statusGroupFilter={this.props.statusGroupFilter}
					lineOfBusinessFilter={this.props.lineOfBusinessFilter}
					vendorFilter={this.props.vendorFilter}
					inputSourceFilter={this.props.inputSourceFilter}
					campaignFilter={this.props.campaignFilter}
					dateTypeFilter={this.props.dateTypeFilter}
					filterBySearch={this.props.filterBySearch}
					fromDate={this.props.fromDate}
					toDate={this.props.toDate}
					sortBy={this.props.sortBy}
					sortDirection={this.props.sortDirection}
					sortCategory={this.props.sortCategory}
					onSearch={this.saveFilters}
					ratingList={this.props.ratingList}
					sortList={this.props.sortList}
					lineOfBusinessList={this.props.lineOfBusinessList}
					vendorList={this.props.vendorList}
					inputSourceList={this.props.inputSourceList}
					campaignList={this.props.campaignList}
					dateTypeList={this.props.dateTypeList}
					pageSize={this.props.pageSize}
					lookups={this.props.lookups}
					leadTypeFilter={this.props.leadTypeFilter}
					callAttempts={this.props.callAttempts}
					callAttemptsList={this.props.callAttemptsList}
					leadTagFilterResult={this.props.leadTagFilterResult}
					leadTags={this.props.leadTags}
					leadTagSearchCallback={getHouseholdTags}
					filterIsOpen={this.props.filterIsOpen}
				/>
				<Paper elevation={0} square>
					<Grid container style={styles.card} alignItems='center' justify='space-between' direction='row'>
						<Grid item xs={6} sm={6}>
							<FormControlLabel
								control={
									<Switch
										checked={this.props.multiSelectEnabled}
										onChange={this.props.toggleMultiSelect}
									/>
								}
								label="Multi-Select"
								labelPlacement="start"
							/>
						</Grid>
						<Grid item xs={6} sm={6} style={{textAlign: 'right'}}>
							<Button
								disabled={!this.props.multiSelectEnabled || !selectedLeadCount || !atLeastOneValidSmsLead}
								onClick={this.props.storeSelectedLeadsAndStartSmsSequence}
								style={{ marginRight: 10 }}
							>
								{'SMS Template'}
								<Icon className={'icon-text-contact-button'} />
							</Button>
						</Grid>
					</Grid>
				</Paper>
				<PersistentNotifications />
			</>
		);
	}

	private renderMenuItems = () => {
		const helpAction = createHelpAction(themeLinks.helpLinkCRM);
		const printAction = createMenuAction('Print', () => {
			window.print();
		});
		return isMobileDevice
			? [<MoreMenu children={[helpAction]} />]
			: [<MoreMenu children={[printAction, helpAction]} />];
	};

	getPageDialogs = (leadContacts: Contact[]) => {
		const {householdId, employer} = this.state.lead;

		return [
			<LeadInfoDialog
				key={'lead-info-dialog'}
				householdId={householdId}
				storeLeadRoutesIndex={() => this.props.storeLeadRoutesIndex(this.props.leads.findIndex(currentLead => currentLead.data.id == this.state.lead.id))}
			/>,
			<CallSequence
				key={'call-sequence-dialog'}
				contacts={leadContacts}
				navigateTo={nav.push}
				householdId={householdId}
				employerId={employer && employer.id}
			/>,
			<SmsSequence
				key={'sms-sequence-dialog'}
				contacts={leadContacts}
            />
		];
	};

	public render() {
		const leadContacts: Contact[] = this.props.contacts
			.filter(contact => {
				if (this.state.lead.employer && this.state.lead.employer.id) {
					return contact.data.employerId == this.state.lead.employer.id;
				} else {
					return contact.data.householdId == this.state.lead.householdId;
				}
			})
			.map(loadedContact => loadedContact.data);

		const leadList = this.renderLeads();

		return (
			<BasePageContainer
				topComponent={
					<HeaderBarComponent
						title={'Leads'}
						customContent={this.renderCustomContent()}
						rightButtons={this.renderMenuItems()}
						isLoading={this.props.isLoading}
					/>
				}
				pageDialogs={this.getPageDialogs(leadContacts)}
			>
				<InfiniteScroller
					loadMore={this.loadMoreLeads}
					hasMore={this.props.moreLeadsToLoad}
					useWindow={false}
				>
					<DesktopPadding>
						{
							leadList.length === 0 && !this.props.isLoading
								? <EmptyMessage text="There are no matches for your search..." />
								: (
									<Paper elevation={0} square>
										{this.renderLeads()}
									</Paper>
								)
						}
					</DesktopPadding>
				</InfiniteScroller>
			</BasePageContainer>
		);
	}
}

const styles = enforceStylesType({
	card: {
		overflow: 'hidden'
	},
});

function mapStateToProps(state: AppState): StateProps & LeadFilters {
	const lineOfBusinessTypeList: string[] = state.lookup.getLabels(Lookups.LeadLineOfBusiness);
	const vendorList: string[] = state.lookup.getLabels(Lookups.Vendor);
	const inputSourceList: string[] = state.lookup.getLabels(Lookups.InputSource);
	const campaignList: string[] = [];
	const dateTypeList: string[] = state.lookup.getLabels(Lookups.DateType);
	const sortList: string[] = state.lookup.getLabels(Lookups.DateSort);
	const ratingList: string[] = state.lookup.getLabels(Lookups.LeadRating);
	const callAttemptsList: string[] = state.lookup.getLabels(Lookups.CallAttempts);
	return {
		isLoading: state.lead.isLoading,
		hasError: state.lead.error,
		contacts: state.contact.contacts,
		lineOfBusinessList: lineOfBusinessTypeList,
		vendorList: vendorList,
		inputSourceList: inputSourceList,
		campaignList: campaignList,
		dateTypeList: dateTypeList,
		sortList: sortList,
		ratingList: ratingList,
		callAttemptsList: callAttemptsList,

		leads: state.lead.leads,

		pageNumber: state.lead.leadListPageNumber,
		pageSize: state.lead.leadListPageSize,
		moreLeadsToLoad: state.lead.moreLeadsToLoad,
		leadFilters: state.leadFilters,
		ratingFilter: state.leadFilters.ratingFilter,
		statusFilter: state.leadFilters.statusFilter,
		statusGroupFilter: state.leadFilters.statusGroupFilter,
		lineOfBusinessFilter: state.leadFilters.lineOfBusinessFilter,
		leadTypeFilter: state.leadFilters.leadTypeFilter,
		vendorFilter: state.leadFilters.vendorFilter,
		inputSourceFilter: state.leadFilters.inputSourceFilter,
		campaignFilter: state.leadFilters.campaignFilter,
		dateTypeFilter: state.leadFilters.dateTypeFilter,
		sortBy: state.leadFilters.sortBy,
		fromDate: state.leadFilters.fromDate,
		toDate: state.leadFilters.toDate,
		filterBySearch: state.leadFilters.filterBySearch,
		sortCategory: state.leadFilters.sortCategory,
		sortDirection: state.leadFilters.sortDirection,
		leadTags: state.leadFilters.leadTags,
		filterIsOpen: state.leadFilters.filterIsOpen,

		leadTagFilterResult: state.advancedSearch.leadTagFilterResult,

		activities: state.activity.activities,
		firstName: state.agent.firstName,
		preferredName: state.agent.preferredName,
		lastName: state.agent.lastName,

		locationHistory: state.navigation.locationHistory,

		userId: state.user.id,
		lookups: state.lookup,
		desktopCallEnabled: state.user.enableDesktopCalls,
		desktopTextEnabled: state.user.enableDesktopTexts,

		callAttempts: state.leadFilters.callAttempts,
		multiSelectEnabled: state.lead.multiSelect,
		selectedLeads: state.lead.selectedLeads,
		atLeastOneValidSmsLead: state.lead.atLeastOneValidSmsLead,
	};
}

function mapDispatchToProps(dispatch: any): DispatchProps {
	return {
		getFilteredLeads: (
			leadFilters: LeadFilters,
			pageNum: number,
			pageSize: number
		) => {
			const payload = formatLeadFiltersPayload(leadFilters, pageNum, pageSize);
			return dispatch(GetFilteredLeads.started(payload));
		},
		storeLeadFilters: (filterValues: LeadFilters) =>
			dispatch(StoreLeadFilters(filterValues)),
		getAgentByAgentCode: () => dispatch(GetAgentFromJwt.started(undefined)),
		getHousehold: (id: string) => dispatch(GetHousehold.started(id)),
		getEmployer: (id: string) => dispatch(GetEmployer.started(id)),
		storeLeadRoutesIndex: (index: number) => dispatch(StoreLeadRoutesIndex({
			index,
			origin: LeadRoutePages.LeadList
		})),
		openLeadListDialog: () => dispatch(ToggleLeadInfoDialog(true)),
		toggleMultiSelect: (event: React.ChangeEvent<HTMLInputElement> | null, checked: boolean) => dispatch(ToggleLeadListMultiSelect(checked)),
		selectLead: (lead: Lead) => dispatch(SelectLeadListItem(lead)),
		clearSelectedLeads: () => dispatch(SmsLeadSelectionCleanup()),
		storeSelectedLeadsAndStartSmsSequence: () => dispatch(SendSmsToLeadListSelection()),
		getHouseholdTags: (search: string) => dispatch(getHouseholdTags.started(search)),
	};
}

export const LeadListPageContainer = connect(
	mapStateToProps,
	mapDispatchToProps, true
)(LeadListPage);
