import React from 'react';
import { Activity } from '../../reducers/activity_reducer';
import { ListSubheader } from '@material-ui/core';
import moment from 'moment';
import { Loaded } from '../../utilities/utilities';
import { OpenActivityItem } from '../../containers/activity/list/open_activity_item';
import { themePalette } from '../../utilities/branding';
import { wrapInCollapsibleCard } from '../higher_order_component/wrap_in_card';
import CustomList from '../utility/custom_list';
import { SimpleDialog } from '../dialogs/simple_dialog';

interface OpenActivityListProps {
	openActivities: Loaded<Activity>[];
	handleActivityDeleteClick: (activity: Activity) => any;
	handleNavigateToActivity: (activity: Activity) => any;
	handleActivityEditClick: (activity: Activity) => any;
}
interface OpenActivityListState {
	showDeleteConfirmation: boolean;
	focus?: Activity;
}
class OpenActivityList extends React.PureComponent<OpenActivityListProps, OpenActivityListState> {

	constructor(props: OpenActivityListProps) {
		super(props);
		this.state = {
			showDeleteConfirmation: false,
		};
	}

	handleDeleteClick = (activity: Activity) => {
		this.setState({
			showDeleteConfirmation: true,
			focus: activity,
		});
	};

	handleDismiss = () => {
		this.setState({ showDeleteConfirmation: false });
	};

	handleClose = () => {
		this.setState({ showDeleteConfirmation: false });
	};

	deleteAndCloseDialog = () => {
		this.setState({ showDeleteConfirmation: false });
		if (this.state.focus)
			this.props.handleActivityDeleteClick(this.state.focus);
	};

	getActivities(
		start?: moment.Moment,
		end?: moment.Moment
	): Loaded<Activity>[] {
		return this.props.openActivities
			.filter(a => {
				const afterStart = start
					? start <= moment.utc(a.data.time).local()
					: true;
				const beforeEnd = end ? moment.utc(a.data.time).local() <= end : true;
				return afterStart && beforeEnd;
			})
			.sort((a, b) => {
				return moment(a.data.time).valueOf() - moment(b.data.time).valueOf();
			});
	}

	renderDeleteConfirmationDialogue() {
		return (
			<SimpleDialog
				open={this.state.showDeleteConfirmation}
				onClose={this.handleDismiss}
				title={'Delete Activity'}
				description={'Are you sure you want to delete this activity?'}
				actions={[
					{ children: 'Cancel', onClick: this.handleDismiss },
					{ 
						children: 'Delete', 
						variant: 'contained', 
						disableElevation: true, 
						color: 'primary',
						style: { backgroundColor: themePalette.delete_remove_reject_button },
						onClick: this.deleteAndCloseDialog
					}
				]}
			/>
		);
	}
	render() {
		const pastDueActivities = this.getActivities(
			undefined,
			moment().startOf('day')
		);
		const todaysActivities = this.getActivities(
			moment().startOf('day'),
			moment().endOf('day')
		);
		const tomorrowsActivities = this.getActivities(
			moment().startOf('day').add(24, 'h'),
			moment().endOf('day').add(24, 'h')
		);
		const futureActivities = this.getActivities(
			moment().endOf('day').add(24, 'h'),
			undefined
		);
		
		return (
			<>
				<CustomList emptyMessage="You have no open activities">
					{!!pastDueActivities.length && <ListSubheader
						style={{ backgroundColor: themePalette.default_background }}
						key="header_old"
						disableSticky
					>
						Past Due
					</ListSubheader>}
					{pastDueActivities.map((activity, index) => {
						return (
							<OpenActivityItem
								key={`pastdue_${index}`}
								activity={activity.data}
								handleDeleteClick={this.handleDeleteClick}
								handleNavigateToActivity={this.props.handleNavigateToActivity}
								handleActivityEditClick={this.props.handleActivityEditClick}
							/>
						);
					})}
					{!!todaysActivities.length && <ListSubheader
						style={{ backgroundColor: themePalette.default_background }}
						key="header_today"
						disableSticky
					>
						Today
					</ListSubheader>}
					{todaysActivities.map((activity, index) => {
						return (
							<OpenActivityItem
								key={`today_${index}`}
								activity={activity.data}
								handleDeleteClick={this.handleDeleteClick}
								handleNavigateToActivity={this.props.handleNavigateToActivity}
								handleActivityEditClick={this.props.handleActivityEditClick}
							/>
						);
					})}
					{!!tomorrowsActivities.length && <ListSubheader
						style={{ backgroundColor: themePalette.default_background }}
						key="header_tomorrow"
						disableSticky
					>
						Tomorrow
					</ListSubheader>}
					{tomorrowsActivities.map((activity, index) => {
						return (
							<OpenActivityItem
								key={`tomorrow_${index}`}
								activity={activity.data}
								handleDeleteClick={this.handleDeleteClick}
								handleNavigateToActivity={this.props.handleNavigateToActivity}
								handleActivityEditClick={this.props.handleActivityEditClick}
							/>
						);
					})}
					{!!futureActivities.length && <ListSubheader
						style={{ backgroundColor: themePalette.default_background }}
						key="header_future"
						disableSticky
					>
						Future
					</ListSubheader>}
					{futureActivities.map((activity, index) => {
						return (
							<OpenActivityItem
								key={`future_${index}`}
								activity={activity.data}
								handleDeleteClick={this.handleDeleteClick}
								handleNavigateToActivity={this.props.handleNavigateToActivity}
								handleActivityEditClick={this.props.handleActivityEditClick}
							/>
						);
					})}
				</CustomList>
				{this.renderDeleteConfirmationDialogue()}
			</>
		);
	}
}

export const OpenActivityListCard = wrapInCollapsibleCard(OpenActivityList);