import React from 'react';
import uuid from 'uuid';
import {
	Card,
	CardContent,
	CardActions,
	Grid,
	List,
	Paper,
	Button,
	LinearProgress,
} from '@material-ui/core';
import ExpandableTableRow from '../utility/expandable_table_row';
import { themePalette } from '../../utilities/branding';
import { TeamViewRow } from '../../reducers/teamview_reducer';
import { Strings } from '../../assets/common/strings';
import { enforceStylesType } from '../../utilities/styles_util';

export interface TableCardProps {
	color: 'primary' | 'secondary' | 'tertiary';
	loading?: boolean;
	titleLeft: string;
	titleRight?: string;
	rows: TeamViewRow[];
	clickDisabled?: boolean;
	onClickCell?: (id: string) => void;
	onClickNavigate?: () => void;
	onClickRow?: (id: string, title: string) => void,
	showChildren?: (id: string, card: string) => void;
	flat?: boolean;
	style?: React.CSSProperties;
	cardStyle?: React.CSSProperties;
	compressed?: boolean;
	maxRows?: number;
	bottomText?: string;
	rowsToHighlight?: number[];
}

type Props = TableCardProps;

type State = {
	showAllEntries: boolean,
}

class TableCard extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			showAllEntries: false,
		}
	}

	componentWillUnmount() {
		this.setState({
			showAllEntries: false,
		});
	}

	toggleShowAllEntries = () => {
		const { showAllEntries } = this.state;

		this.setState({
			showAllEntries: !showAllEntries,
		});
	}

	render() {

		const styles = getStyles();

		const { style = {}, maxRows = 9999, rows } = this.props;
		const columnNames = this.getColumnNames();
		var rowsShown = 0;
		let cardContentStyle: React.CSSProperties = {};

		const tableMessage = this.props.loading
			? <React.Fragment />
			: <div>{Strings.TeamView.NO_DATA_FOUND}</div>

		if (this.props.compressed) {
			cardContentStyle = styles.compressedCard;
		}

		return (
			<Card style={{ ...style, ...styles.cardStyle }} elevation={this.props.flat ? 0 : undefined}>
				<Grid
					container
					onClick={!this.props.clickDisabled && this.props.onClickNavigate
						? this.props.onClickNavigate : undefined}
					style={{ backgroundColor: this.getActiveColor() }}
				>
					<Grid item xs style={getTitleStyles(this.props)}>
						{this.props.titleLeft}
					</Grid>
					{this.props.titleRight && <Grid item xs style={{ ...getTitleStyles(this.props), ...styles.titleRightStyle }}>
						{this.props.titleRight}
					</Grid>}
				</Grid>

				{this.props.loading && this.props.rows.length === 0 && (
					<div style={styles.loading}>
						<LinearProgress />
					</div>
				)}

				<CardContent
					style={{
						...styles.contentStyle,
						...cardContentStyle,
					}}
				>
					{this.props.rows.length > 0
						? <Paper style={styles.paperStyle}>
							<List style={{ ...styles.cardListStyle }} component="nav">
								{this.renderExpandableRow(columnNames, 0, true)}
								<div style={{ ...getCardDataStyles(this.props, this.state) }}>
									{rows.map((currentRow) => {
										rowsShown += 1;
										return this.renderExpandableRow(currentRow, 0, false, rowsShown);
									})}
								</div>
							</List>
						</Paper>
						: <div>{tableMessage}</div>
					}
				</CardContent>

				<CardActions>
					{this.props.rows.length > maxRows &&
						<Button
							onClick={this.toggleShowAllEntries}
						>
							{this.state.showAllEntries
								? Strings.TeamView.SHOW_LESS
								: Strings.TeamView.SHOW_MORE
							}
						</Button>}
				</CardActions>
			</Card>
		);
	}

	getActiveColor = () => {
		let activeColor = themePalette.bubbles_primary;
		if (this.props.color === 'secondary') {
			activeColor = themePalette.bubbles_secondary;
		} else if (this.props.color === 'tertiary') {
			activeColor = themePalette.bubbles_tertiary;
		}
		return activeColor;
	}

	getColumnNames = () => {
		const { titleLeft, rows } = this.props;
		var columnNames: TeamViewRow = { agentcode: '', results: {}, reports: 0, children: [], terminated: false };

		if (rows.length > 0 && rows[0].results) {
			Object.keys(rows[0].results).forEach((key) => {
				columnNames.results[key] = { value: key, delta: 0 };
				columnNames.agentcode = titleLeft + '-header';
			});
		}

		return columnNames;
	}

	requestChildData = (id: string) => {
		const { onClickRow, titleLeft } = this.props;

		if (onClickRow) {
			onClickRow(id, titleLeft);
		}
	}

	renderExpandableRow = (
		row: TeamViewRow,
		depth: number = 0,
		header: boolean = false,
		rowsShown: number = 0,
	): JSX.Element => {
		const { titleLeft, maxRows = 9999, showChildren } = this.props;
		const { showAllEntries } = this.state;
		let onClickRow = () => this.requestChildData(row.agentcode);
		let incomingNestedRow = false;

		if (row.agentcode === '') {
			row.agentcode = uuid.v4();
		}

		if (row.terminated && row.agentcode.includes('Terminated')) {
			incomingNestedRow = true;
			if (!/\d/.test(row.agentcode))
				row.agentcode = row.agentcode + ' ' + (depth + 1);
			if (showChildren)
				onClickRow = () => showChildren(row.agentcode, titleLeft);
		}

		if (row.reports === 0) {
			onClickRow = () => { };
		}

		if (depth === 0 && !showAllEntries && rowsShown > maxRows) {
			return <div key={titleLeft + row.agentcode} />;
		}

		return (
			<ExpandableTableRow
				key={titleLeft + row.agentcode}
				id={row.agentcode}
				isHidden={false}
				columns={row.results}
				numChildren={row.reports}
				incomingNestedRow={incomingNestedRow}
				isItalicText={row.terminated}
				onClickRow={onClickRow}
				onClickIcon={this.props.onClickCell}
				level={depth}
				isHeader={header}
				headerBottomBorderColor={this.getActiveColor()}
			>
				{!row.hideChildren
					? row.children.map((child) => (
						this.renderExpandableRow(child, depth + 1)
					))
					: []
				}
			</ExpandableTableRow>
		);
	}
}

export default TableCard;

const getTitleStyles = (props: Props) => {
	const css: React.CSSProperties = {
		textOverflow: 'ellipsis',
		overflow: 'hidden' as 'hidden',
		WebkitTextSizeAdjust: '100%',
		fontFamily: 'Roboto, sans-serif',
		fontSize: '14px',
		fontWeight: 'bold',
		textAlign: 'left',
		color: 'white',
		marginTop: 7,
		marginBottom: 7,
		padding: 10,
		cursor: props.clickDisabled ? undefined : 'pointer',
	}
	return css;
};
const getCardDataStyles = (props: Props, state: State) => {
	const css: React.CSSProperties = {
		height: state.showAllEntries
			? props.rows.length > 0
				? '400px'
				: 'auto'
			: '200px',
		overflow: 'auto' as 'auto',
	}
	return css;
};
const getStyles = () => enforceStylesType({
	cardStyle: {
		textAlign: 'center',
	},
	loading: {
		width: '100%',
		backgroundColor: '#fff',
	},
	titleRightStyle: {
		textAlign: 'right',
	},
	cardListStyle: {
		backgroundColor: 'white',
		padding: 0,
		borderBottomColor: themePalette.divider,
		borderBottomWidth: 1,
		borderBottomStyle: 'solid',
		color: themePalette.sub_text,
		width: '100%',
		minWidth: '800px',
	},

	contentStyle: {
		color: 'grey',
		fontFamily: 'Roboto, sans-serif',
		fontSize: 14,
		textAlign: 'center',
		maxHeight: '100%',
		position: 'relative' as 'relative',
	},
	compressedCard: {
		paddingTop: 0,
	},
	paperStyle: {
		overflow: 'auto' as 'auto',
	},
});