import { Action, isType } from 'typescript-fsa';
import { UpdateSelectedOptions } from '../actions/data_table_actions';

export interface SelectedDataTableOptions {
    sortKey: string;
    sortDescending: boolean;
    page: number;
    rowsPerPage: number;
}
export const defaultOptions: SelectedDataTableOptions = {
    sortKey: '',
    sortDescending: true,
    page: 0,
    rowsPerPage: 20,
};
export interface DataTableState {
    [dataTableName: string]: SelectedDataTableOptions
}
const initialState: DataTableState = {};

export const dataTableReducer = (state=initialState, action: Action<any>) => {
    if (isType(action, UpdateSelectedOptions)) {
        const { dataTableName, options } = action.payload;
        const oldOptions = state[dataTableName] || defaultOptions;
        return {
            ...state,
            [dataTableName]: {
                ...oldOptions,
                ...options
            }
        }
    }
    return state;
}