import { StateProps } from '../components/Layout/table/filter_table';
import { Action as ReduxAction } from 'redux';
import { UpdateTable } from '../actions/table_actions';
import { isType } from 'typescript-fsa';

export type FilterTableState = {
	[tableId: string]: StateProps;
};

export function tableReducer(
	state: FilterTableState = {},
	action: ReduxAction
): FilterTableState {
	if (isType(action, UpdateTable)) {
		const { tableId, tableState } = action.payload;
		const oldstate = state[tableId] || {};
		return {
			...state,
			[action.payload.tableId]: {
				...oldstate,
				...tableState,
			},
		};
	} else {
		return state;
	}
}