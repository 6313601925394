import { Action as ReduxAction } from 'redux';
import { isType } from 'typescript-fsa';
import { Contact, HouseholdRole } from './ContactReducer';
import { OwnershipHistory } from './ownership_history_reducer';
import {
	CreateLead,
	DeleteLead,
	GetLead,
	GetNotContactedLeadCount,
	GetStatusCounts,
	GetHomeStatusCounts,
	UpdateLead,
	GetFilteredLeads,
	StoreLeadConnectStatus,
	StoreInactiveLeadId,
	BulkReassign,
	StoreLiveTransferStatus,
	ToggleLeadListMultiSelect,
	SelectLeadListItem,
	ClearSelectedLeads,
	StoreSelectedLeadContacts,
	IncrementSelectedLeadIndex,
	ResetLeadListIndex,
	GetLeadConnectStatus,
	GetDashboardCounts,
	GetAccountSummary,
} from '../actions/lead_actions';
import { getImportHistory } from '../actions/file_actions';
import { DeleteContact } from '../actions/contact_actions';
import _ from 'lodash';
import { isNullOrUndefined } from 'util';
import { Loaded } from '../utilities/utilities';
import BaseDTO from '../utilities/base_dto';
import { Employer } from './employer_reducer';
import { GetHousehold, ReassignHousehold } from '../actions/household_actions';
import {
	GetEmployer,
	UpdateEmployer,
	DeleteEmployer,
	SearchEmployers,
	ReassignEmployer,
} from '../actions/employer_actions';
import { ClearCaches } from '../actions/authentication_actions';
import { DefaultDictionary } from '../utilities/lookup';
import { Activity } from './activity_reducer';
import { Logout } from '../actions/authentication_actions';
import { Strings } from '../assets/common/strings';

export enum TransferStatus {
	Unknown = -1,
	Paused = 0,
	Active = 1,
}

export interface LeadIntakeStatus {
	Global: TransferStatus,
	LiveTransfer: TransferStatus
}

export interface AccountSummary {
	TotalActiveOrder: number, 
	TotalInactiveOrders: number,
	TotalLeadsByHour: number,
	TotalLeadsByDay: number,
	TotalLeadsByWeek: number,
	TotalLeadsByMonth: number,
	AgentBalance: number,
	TotalPendingDisputes: number,
	TotalLeadsCapacityByHour: number,
	TotalLeadsCapacityByDay: number,
	TotalLeadsCapacityByWeek: number,
	TotalLeadsCapacityByMonth: number,
};

const initialSummary: AccountSummary = {
	TotalActiveOrder: 0,
	TotalInactiveOrders: 0,
	TotalLeadsByHour: 0,
	TotalLeadsByDay: 0,
	TotalLeadsByWeek: 0,
	TotalLeadsByMonth: 0,
	AgentBalance: 0,
	TotalPendingDisputes: 0,
	TotalLeadsCapacityByHour: 0,
	TotalLeadsCapacityByDay: 0,
	TotalLeadsCapacityByWeek: 0,
	TotalLeadsCapacityByMonth: 0
};

export type HomeLeadStatuses =
	| 'New Leads'
	| 'Attempted'
	| 'Contacted'
	| 'Unmatched Policies';

export interface Lead extends BaseDTO {
	contacts: Contact[];
	linesOfBusiness: string[];
	employer: Employer;
	assetOwnerId: string;
	opportunityRatingCode: string;
	actualCloseDate?: string;
	sourceOther: string;
	status: string;
	statusCode: string;
	statusDate?: string;
	inputSource: string;
	leadCost?: number;
	leadTypeOther: string;
	vendorOther: string;
	leadType: string;
	vendor: string;
	campaign: string;
	fund?: string;
	lmsId: string;
	assignedDate?: string;
	isCompanyLead: boolean; //DOES NOT MEAN IT'S AN EMPLOYER LEAD
	isLeadCostRefunded: boolean;
	marketingRefCode: string;
	priorPremium?: number;
	marketoOppId: string;
	quoteId: string;
	priorCarrierName: string;
	isPriorCoverage: boolean;
	priorCoverageType?: number;
	reasonforChange: string;
	dialerCallId: string;
	dialerCallType: string;
	dialerDisposition: string;
	callDuration?: number;
	callAttempts?: number;
	telephonicSoaCompleted: boolean;
	ownershipHistory?: OwnershipHistory[];
	householdId: string;
	createdByName: string;
	nextActivity?: Activity;
	history?: LeadHistory[];
	callCounter?: number;
}

export interface LeadHistory {
	updatedOn: string;
	statusBeforeUpdate: string;
	statusCodeBeforeUpdate: string;
}

export type LeadListSelected = { [id: string]: Lead };
export interface Leads {
	leads: Loaded<Lead>[];
	statusCounts: { [key: string]: number };
	homeStatusCounts: { [key: string]: number };

	isLoading: boolean;
	isReceivingLiveTransferLeads: TransferStatus;
	isReceivingLeadConnectLeads: TransferStatus;
	leadListPageNumber: number;
	leadListPageSize: number;
	moreLeadsToLoad: boolean;
	inactiveLeadIdForActivity: string;
	error: any;

	multiSelect: boolean;
	selectedLeads: LeadListSelected,
	selectedLeadContacts: Contact[][],
	selectedLeadIndex: number,
	atLeastOneValidSmsLead: boolean;
	accountSummary: AccountSummary;
}

export const initialState: Leads = {
	leads: [],
	statusCounts: {},
	homeStatusCounts: {},
	isLoading: false,
	isReceivingLiveTransferLeads: TransferStatus.Paused,
	isReceivingLeadConnectLeads: TransferStatus.Paused,
	leadListPageNumber: 0,
	leadListPageSize: 20,
	moreLeadsToLoad: true,
	error: undefined,
	inactiveLeadIdForActivity: '',
	multiSelect: false,
	selectedLeads: {},
	selectedLeadContacts: [],
	selectedLeadIndex: 0,
	atLeastOneValidSmsLead: false,
	accountSummary: initialSummary,
};

export type LeadUpdate = { 
	status: string; reason: string; dirty: boolean 
};


export function leadReducer(
	state: Leads = initialState,
	action: ReduxAction
): Leads {
	if (isType(action, GetLead.started) || isType(action, DeleteLead.started)) {
		const leadId: string = action.payload;
		const leadsInState = state.leads.slice();
		upsertLeadStart(leadsInState, { id: leadId } as Lead);
		return {
			...state,
			leads: leadsInState,
			isLoading: true,
		};
	} else if (isType(action, CreateLead.started)) {
		const leadsInState = state.leads.slice();
		upsertLeadStart(leadsInState, action.payload.lead);
		return {
			...state,
			leads: leadsInState,
			isLoading: true,
		};
	} else if (isType(action, CreateLead.done)) {
		const sentLead = action.payload.params.lead;
		const receivedLead = action.payload.result;
		const leadsInState = state.leads.slice();

		if (sentLead.householdId != receivedLead.householdId) {
			sentLead.isDeleted = true;
			upsertLeadDone(leadsInState, sentLead);
		}
		upsertLeadDone(leadsInState, receivedLead);
		return {
			...state,
			leads: leadsInState,
			isLoading: false,
		};
	} else if (isType(action, UpdateLead.started)) {
		const leadsInState = state.leads.slice();
		upsertLeadStart(leadsInState, action.payload);
		return {
			...state,
			leads: leadsInState,
		};
	} else if (isType(action, GetLead.done) || isType(action, UpdateLead.done)) {
		const leadToUpsert = action.payload.result;
		const leadsInState = state.leads.slice();
		upsertLeadDone(leadsInState, leadToUpsert);
		updateCallCounters(leadsInState, leadToUpsert.householdId, leadToUpsert.callCounter || 0);
		return {
			...state,
			leads: leadsInState,
			isLoading: false,
		};
	} else if (isType(action, DeleteLead.done)) {
		const leadsInState = state.leads.filter(
			lead => lead.data.id !== action.payload.params
		);
		return {
			...state,
			leads: leadsInState,
		};
	} else if (
		isType(action, GetLead.failed) ||
		isType(action, DeleteLead.failed)
	) {
		const leadId = action.payload.params;
		const leadsInState = state.leads.slice();
		upsertLeadDone(leadsInState, { id: leadId } as Lead, action.payload.error);
		return {
			...state,
			leads: leadsInState,
		};
	} else if (
		isType(action, UpdateLead.failed) ||
		isType(action, CreateLead.failed)
	) {
		const leadToUpsert = isType(action, CreateLead.failed)
			? action.payload.params.lead
			: action.payload.params;
		const leadsInState = state.leads.slice();
		upsertLeadDone(leadsInState, leadToUpsert, action.payload.error);
		return {
			...state,
			leads: leadsInState,
			isLoading: false,
		};
	} else if (
		isType(action, GetEmployer.done) ||
		isType(action, UpdateEmployer.done) ||
		isType(action, GetHousehold.done)
	) {
		const leadsInState = state.leads.slice();
		const newLeads = action.payload.result.leads;
		updateStateWithInboundLeads(leadsInState, newLeads);
		return {
			...state,
			leads: leadsInState,
			isLoading: false,
		};
	} else if (isType(action, GetFilteredLeads.started)) {
		return {
			...state,
			isLoading: true,
			moreLeadsToLoad: true,
			leadListPageNumber: action.payload.pageNum,
			leadListPageSize:
				action.payload.pageSize || initialState.leadListPageSize,
			error: undefined,
		};
	} else if (
		isType(action, GetHomeStatusCounts.started) ||
		isType(action, GetHousehold.started) ||
		isType(action, GetEmployer.started) ||
		isType(action, GetDashboardCounts.started)
	) {
		return {
			...state,
			isLoading: true,
			error: undefined,
		};
	} else if (isType(action, GetStatusCounts.done)) {
		return {
			...state,
			statusCounts: action.payload.result,
		};
	} else if (isType(action, GetHomeStatusCounts.done)) {
		return {
			...state,
			homeStatusCounts: action.payload.result,
			isLoading: false,
		};
	} else if (isType(action, GetHomeStatusCounts.failed)) {
		return {
			...state,
			isLoading: false,
		};
	} else if (isType(action, GetNotContactedLeadCount.done)) {
		return {
			...state,
			statusCounts: {
				...state.statusCounts,
				'Not Contacted': action.payload.result,
			},
			isLoading: false,
		};
	} else if (isType(action, ReassignHousehold.done)) {
		const oldLeads = state.leads.slice();
		const newLeads = oldLeads.filter(lead => {
			return lead.householdId != action.payload.params.householdId;
		});
		return {
			...state,
			leads: newLeads,
			isLoading: false,
		};
	} else if (isType(action, ReassignEmployer.done)) {
		const oldLeads = state.leads.slice();
		const newLeads = oldLeads.filter(lead => {
			return lead.employerId != action.payload.params.employerId;
		});
		return {
			...state,
			leads: newLeads,
			isLoading: false,
		};
	} else if (isType(action, DeleteEmployer.done)) {
		const oldLeads = state.leads.slice();
		const newLeads = oldLeads.filter(lead => {
			lead.employerId != action.payload.params;
		});
		return {
			...state,
			leads: newLeads,
		};
	} else if (isType(action, GetFilteredLeads.done)) {
		const leadsInState =
			action.payload.params.pageNum === 0 ? [] : state.leads.slice();
		const newLeads = action.payload.result;
		const moreLeads = newLeads.length == state.leadListPageSize;
		updateStateWithInboundLeads(leadsInState, newLeads);	
		return {
			...state,
			leads: leadsInState,
			moreLeadsToLoad: moreLeads,
			isLoading: false,
			error: undefined,
		};
	} else if (isType(action, GetFilteredLeads.failed)) {
		return {
			...state,
			isLoading: false,
			error: action.payload.error,
			moreLeadsToLoad: false,
		};
	} else if (isType(action, GetDashboardCounts.done) || isType(action, GetDashboardCounts.failed)) {
		return {
			...state,
			isLoading: false,
		};
	} else if (isType(action, StoreLeadConnectStatus)) {
		return {
			...state,
			isReceivingLeadConnectLeads: action.payload,
			isLoading: false,
		};
	} else if (isType(action, StoreLiveTransferStatus)) {
		return {
			...state,
			isReceivingLiveTransferLeads: action.payload,
			isLoading: false,
		};
	} else if (isType(action, DeleteContact.done)) {
		let leadsInState = state.leads.slice();
		const contactDeleted = action.payload.params;
		if (
			contactDeleted.employerPrimaryContact ||
			contactDeleted.householdRole === HouseholdRole.Primary
		) {
			leadsInState = removeLeadsForContact(leadsInState, contactDeleted);
		}
		return {
			...state,
			leads: leadsInState,
		};
	} else if (
		isType(action, getImportHistory.started) &&
		state.leads.length !== 0
	) {
		return {
			...state,
			leads: [],
		};
	} else if (
		isType(action, getImportHistory.done) &&
		state.leads.length !== 0
	) {
		return {
			...state,
			leads: [],
		};
	} else if (isType(action, SearchEmployers.done)) {
		const leadsInState = state.leads.slice();
		let newLeads: Lead[] = [];
		action.payload.result.forEach(fullEmployer => {
			newLeads = newLeads.concat(fullEmployer.leads);
		});
		updateStateWithInboundLeads(leadsInState, newLeads);
		return {
			...state,
			leads: leadsInState,
		};
	} else if (isType(action, StoreInactiveLeadId)) {
		const leadId = action.payload;
		return {
			...state,
			inactiveLeadIdForActivity: leadId,
		};
	} else if (isType(action, Logout.started)) {
		return {
			...initialState,
		};
	} else if (isType(action, ClearCaches)) {
		return {
			...initialState,
		};
	} else if (isType(action, BulkReassign.done) && state.leads.length > 0) {
		const leadsInState = state.leads.slice();
		const filteredLeads = leadsInState.filter(lead =>
			action.payload.params.entities.some(
				entity =>
					entity.id != lead.householdId &&
					entity.id != lead.employerId &&
					entity.id != lead.data.householdId &&
					((lead.data.employer && entity.id != lead.data.employer.id) ||
						!lead.data.employer)
			)
		);
		return {
			...state,
			leads: filteredLeads,
		};
	} else if (isType(action, StoreSelectedLeadContacts)) {
		return {
			...state,
			selectedLeadContacts: action.payload,
			selectedLeadIndex: 0,
		};
	} else if (isType(action, IncrementSelectedLeadIndex)) {
		return {
			...state,
			selectedLeadIndex: state.selectedLeadIndex + 1,
		};
	} else if (isType(action, ToggleLeadListMultiSelect)) {
		return {
			...state,
			multiSelect: action.payload,
			selectedLeadIndex: 0,
		};
	} else if (isType(action, GetLeadConnectStatus)) {
		return {
			...state,
			isLoading: true,
		};
	} else if (isType(action, SelectLeadListItem)) {
		const leadId = action.payload.id;
		const selectedLeads = {
			...state.selectedLeads,
		};
		if (Boolean(state.selectedLeads[leadId])) {
			delete selectedLeads[leadId];
		} else {
			selectedLeads[leadId] = action.payload;
		}
		const atLeastOneValidSmsLead = Object.keys(selectedLeads).some(key => (
			selectedLeads[key].contacts.some(
				contact => 
					contact.status != Strings.Status.Inactive && 
					contact.phones.some(phone => !phone.isSmsDnc)
			)
		));
		return {
			...state,
			selectedLeads,
			atLeastOneValidSmsLead,
		};
	} else if (isType(action, ClearSelectedLeads)) {
		return {
			...state,
			selectedLeads: {},
			selectedLeadIndex: 0,
			selectedLeadContacts: []
		};
	} else if (isType(action, ResetLeadListIndex)) {
		return {
			...state,
			selectedLeadIndex: 0,
		};
	} else if (isType(action, GetAccountSummary.done)) {
		return {
			...state,
			accountSummary: action.payload.result,
		}
	} else {
		return state;
	}
}

export function getMatchingLeadIndex(
	leadsToLookThrough: Loaded<Lead>[],
	leadId: string
) {
	return _.findIndex(leadsToLookThrough, (lead: Loaded<Lead>) => {
		return !isNullOrUndefined(lead.data) && lead.data.id === leadId;
	});
}

function removeLeadsForContact(
	leadsToLookThrough: Loaded<Lead>[],
	contact: Contact
) {
	return leadsToLookThrough.filter(
		lead =>
			!(
				lead.householdId === contact.householdId ||
				lead.employerId === contact.employerId
			)
	);
}

function upsertLeadStart(leadsInState: Loaded<Lead>[], lead: Lead) {
	const matchedLeadIndex = _.findIndex(
		leadsInState,
		cachedLead => cachedLead.data.id === lead.id
	);
	if (matchedLeadIndex !== -1) {
		leadsInState[matchedLeadIndex] = {
			...leadsInState[matchedLeadIndex],
			loading: true,
			employerId: lead.employer ? lead.employer.id : undefined,
			householdId: lead.householdId,
		};
	} else {
		const newLead: Loaded<Lead> = {
			data: lead,
			loading: true,
			employerId: lead.employer ? lead.employer.id : undefined,
			householdId: lead.householdId,
			errors: [],
		};
		leadsInState.push(newLead);
	}
}

function upsertLeadDone(leadsInState: Loaded<Lead>[], lead: Lead, error?: any) {
	const matchedLeadIndex = _.findIndex(
		leadsInState,
		cachedLead => cachedLead.data.id === lead.id
	);
	if (matchedLeadIndex !== -1) {
		if (lead.isDeleted) leadsInState.splice(matchedLeadIndex, 1);
		else if (error)
			leadsInState[matchedLeadIndex] = {
				...leadsInState[matchedLeadIndex],
				loading: false,
				errors: [...leadsInState[matchedLeadIndex].errors, error],
			};
		else
			leadsInState[matchedLeadIndex] = {
				...leadsInState[matchedLeadIndex],
				loading: false,
				employerId: lead.employer ? lead.employer.id : undefined,
				householdId: lead.householdId,
				data: lead,
				errors: [],
			};
	} else {
		const newLead: Loaded<Lead> = {
			data: lead,
			loading: false,
			employerId: lead.employer ? lead.employer.id : undefined,
			householdId: lead.householdId,
			errors: error ? [error] : [],
		};
		leadsInState.push(newLead);
	}
}

function updateStateWithInboundLeads(
	leadsInState: Loaded<Lead>[],
	inboundLeads: Lead[],
	employerId?: string
) {
	inboundLeads.forEach(inboundLead => {
		const leadId = inboundLead.id;
		let wasALeadUpdated = false;
		const statusCode = DefaultDictionary.getLabel(inboundLead.statusCode);
		leadsInState.forEach((currentLead, index) => {
			if (currentLead.data.id === leadId) {
				wasALeadUpdated = true;
				if (currentLead.data.isDeleted) {
					leadsInState.splice(index, 1);
				} else {
					leadsInState[index] = {
						...leadsInState[index],
						loading: false,
						data: {
							...inboundLead,
							statusCode,
							callAttempts: leadsInState[index].data.callAttempts,
						},
						householdId: inboundLead.householdId,
						employerId: inboundLead.employer
							? inboundLead.employer.id || employerId
							: undefined,
					};
				}
			}
		});
		if (!wasALeadUpdated) {
			const newLead: Loaded<Lead> = {
				loading: false,
				data: inboundLead,
				errors: [],
				householdId: inboundLead.householdId,
				employerId: inboundLead.employer
					? inboundLead.employer.id || employerId
					: '',
			};
			leadsInState.push(newLead);
		}
	});
}

function updateCallCounters(
	leadsInState: Loaded<Lead>[],
	householdId: string,
	callCounter: number
) {
	// const leads = leadsInState.filter(lead => lead.householdId == householdId) || [];

	for(var i = 0; i < leadsInState.length; i++) {
		if(leadsInState[i].householdId == householdId) {
			leadsInState[i] = {
				...leadsInState[i],
				data: {
					...leadsInState[i].data,
					callCounter: callCounter
				}
			}
		}
	}
}
