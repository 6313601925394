import React from 'react';
import { Card, Grid, CardContent, Typography } from '@material-ui/core';
import { Field } from 'redux-form';
import { formatCurrency } from '../../utilities/formatting/data_normalizations';
import { validateCurrency } from '../../utilities/form_validation';
import { Strings as S } from '../../assets/common/strings';
import { FormTextField } from '../../utilities/forms';

interface PolicyAmountsCardProps { }

interface PolicyAmountsCardState { }

export class PolicyAmountsCard extends React.Component<
	PolicyAmountsCardProps,
	PolicyAmountsCardState
	> {
	render() {
		return (
			<div>
				<Typography variant="h5" style={{ margin: '20px 0 5px 20px' }}>
					{S.ProductPolicyAmountsEditCard.PolicyAmounts}
				</Typography>
				<Card style={styles.cardStyle}>
					<CardContent>
						<Grid container>
							<Grid item xs={12} sm={6}>
								<Field
									name="application.individualDeductible"
									label={S.ProductPolicyAmountsEditCard.IndividualDeductible}
									component={FormTextField}
									validate={validateCurrency}
									normalize={formatCurrency}
									style={styles.editListItem}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Field
									name="application.individualMaxOutOfPocket"
									label={S.ProductPolicyAmountsEditCard.IndividualDeductible}
									component={FormTextField}
									validate={validateCurrency}
									normalize={formatCurrency}
									style={styles.editListItem}
								/>
							</Grid>
						</Grid>
						<Grid container>
							<Grid item xs={12} sm={6}>
								<Field
									name="application.perPersonDeductible"
									label={S.ProductPolicyAmountsEditCard.PerPersonDeductible}
									component={FormTextField}
									validate={validateCurrency}
									normalize={formatCurrency}
									style={styles.editListItem}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Field
									name="application.ppMaxOutOfPocket"
									label={S.ProductPolicyAmountsEditCard.PerPersonMaxOutOfPocket}
									component={FormTextField}
									validate={validateCurrency}
									normalize={formatCurrency}
									style={styles.editListItem}
								/>
							</Grid>
						</Grid>
						<Grid container>
							<Grid item xs={12} sm={6}>
								<Field
									name="application.familyDeductible"
									label={S.ProductPolicyAmountsEditCard.FamilyDeductible}
									component={FormTextField}
									validate={validateCurrency}
									normalize={formatCurrency}
									style={styles.editListItem}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Field
									name="application.familyMaxOutOfPocket"
									label={S.ProductPolicyAmountsEditCard.FamilyMaxOutOfPocket}
									component={FormTextField}
									validate={validateCurrency}
									normalize={formatCurrency}
									style={styles.editListItem}
								/>
							</Grid>
						</Grid>
						<Grid container>
							<Grid item xs={12} sm={6}>
								<Field
									name="application.faceAmount"
									label={S.ProductPolicyAmountsEditCard.FaceAmount}
									component={FormTextField}
									validate={validateCurrency}
									normalize={formatCurrency}
									style={styles.editListItem}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Field
									name="application.benefitAmount"
									label={S.ProductPolicyAmountsEditCard.BenefitAmount}
									component={FormTextField}
									validate={validateCurrency}
									normalize={formatCurrency}
									style={styles.editListItem}
								/>
							</Grid>
						</Grid>
						<Grid container>
							<Grid item xs={12} sm={6}>
								<Field
									name="application.drugDeductible"
									label={S.ProductPolicyAmountsEditCard.DrugDeductible}
									component={FormTextField}
									validate={validateCurrency}
									normalize={formatCurrency}
									style={styles.editListItem}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Field
									name="application.annualDrugCost"
									label={S.ProductPolicyAmountsEditCard.AnnualDrugCost}
									component={FormTextField}
									validate={validateCurrency}
									normalize={formatCurrency}
									style={styles.editListItem}
								/>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</div>
		);
	}
}

const styles: { [key: string]: React.CSSProperties } = {
	cardStyle: {
		margin: 10,
		marginTop: 0,
	},
	editListItem: {
		marginLeft: 10,
		width: '90%',
	},
};
