import React from 'react';
import { Grid } from '@material-ui/core';
import { ReadOnlyTextField } from '../utility/read_only_text_field';
import { wrapInCollapsibleCard } from '../higher_order_component/wrap_in_card';

interface Props {
	firstName: string;
	lastName: string;
	line1: string;
	line2: string;
	city: string;
	state: string;
	zipCode: string;
	preferredPhone: string;
	preferredEmail: string;
	nameFromCarrier?: string;
}
const PolicyInsuredPeople: React.FC<Props> = (props) => {
	return (
		<Grid container>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField label="First Name" value={props.firstName} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField label="Last Name" value={props.lastName} />
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12}>
					<ReadOnlyTextField
						label="Street Address"
						value={props.line1 + ' ' + props.line2}
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField label="City" value={props.city} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField label="State" value={props.state} />
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12}>
					<ReadOnlyTextField label="Zip Code" value={props.zipCode} />
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Home Phone"
						value={props.preferredPhone}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Business Email"
						value={props.preferredEmail}
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Name from Carrier"
						value={props.nameFromCarrier}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export const PolicyInsuredPeopleCard = wrapInCollapsibleCard(PolicyInsuredPeople);
