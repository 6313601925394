import React from 'react';
import { defaultSvgProps, SvgProps } from '..';

const DocumentsSvg: React.FC<SvgProps> = (props) => (
    <svg viewBox="0 0 35 35" {...props}>
        <path
            d="M19.69 29.22a.5.5 0 0 1-.5-.5v-6a.5.5 0 0 1 .5-.5h6a.51.51 0 0 1 .5.5.5.5 0 0 1-.5.5h-5.5v5.5a.5.5 0 0 1-.5.5z"
        />
        <path
            d="M19.69 29.22H9.35a.5.5 0 0 1-.5-.5V8.2a.5.5 0 0 1 .5-.5h16.34a.5.5 0 0 1 .5.5v14.52a.53.53 0 0 1-.15.36l-6 6a.5.5 0 0 1-.35.14zm-9.84-1h9.63l5.71-5.7V8.7H9.85z"
        />
        <path
            d="M18.6 12.49h-5.87a.5.5 0 0 1 0-1h5.87a.5.5 0 0 1 0 1zM22.48 14.91h-9.75a.5.5 0 0 1-.5-.5.5.5 0 0 1 .5-.5h9.75a.5.5 0 0 1 .5.5.51.51 0 0 1-.5.5zM22.48 17.32h-9.75a.5.5 0 0 1-.5-.5.5.5 0 0 1 .5-.5h9.75a.51.51 0 0 1 .5.5.5.5 0 0 1-.5.5zM16.81 19.74h-4.08a.5.5 0 0 1 0-1h4.08a.5.5 0 0 1 0 1z"
        />
    </svg>
);
DocumentsSvg.defaultProps = defaultSvgProps;

export default DocumentsSvg;
