import { createSelector } from "reselect";
import { AppState } from "../reducers";
import { Podcast } from "../reducers/podcast_reducer";


const getPodcasts = (state: AppState) => (state.podcast.podcasts|| []);
export const getWidgetPodcasts = createSelector(
	[getPodcasts],
	(podcasts: Podcast[]) =>
        podcasts.slice(0, 3)
);