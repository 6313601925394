import React from 'react';
import { InjectedProps as DialogInjectedProps, connectDialog } from '.';
import { AgentAppointmentWebsite, UpsertAppointmentWebsite } from '../../actions/agent_actions';
import { connect, Dispatch } from '@optum-uhone-hmkts/rise';
import { AppState } from '../../reducers';
import { DialogForm } from './dialog_form';
import { validateURL } from '../../utilities/form_validation';
import { TextField } from '@material-ui/core';


interface StateProps {
    userId: string;
	appointmentWebsite: string;
}
interface DispatchProps {
    upsertAppointmentWebsite: (payload: AgentAppointmentWebsite) => void;
}
type Props = StateProps & DispatchProps & DialogInjectedProps;

interface State {
    enteredAppointmentWebsite: string;
}

class AppointmentWebsiteDialogComponent extends React.PureComponent<Props, State> {
    constructor(props: Props) {
		super(props);

		this.state = {
			enteredAppointmentWebsite: this.props.appointmentWebsite,
		};
    }
    
	saveAppointmentWebsite = () => {
		const payload: AgentAppointmentWebsite = {
			userId: this.props.userId,
			appointmentWebsite: this.state.enteredAppointmentWebsite,
		};
		this.props.upsertAppointmentWebsite(payload);
	};

	setAppointmentWebsite = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			enteredAppointmentWebsite: event.target.value,
		});
	};
    
    validate = () => {
        return validateURL(this.state.enteredAppointmentWebsite);
    }

    render() {
        const { dismissSelf, appointmentWebsite } = this.props;
        
        return (
            <DialogForm
                onSubmit={this.saveAppointmentWebsite}
                dismissSelf={dismissSelf}
                validate={this.validate}
            >
                <TextField
                    label={'Website'}
                    onKeyDown={event =>
                        event.key == 'Enter'
                            ? this.saveAppointmentWebsite
                            : undefined
                    }
                    onChange={this.setAppointmentWebsite}
                    helperText={'Please enter a valid URL'}
                    error={!validateURL(this.state.enteredAppointmentWebsite)}
                    fullWidth
                    defaultValue={appointmentWebsite.toString()}
                />
            </DialogForm>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps  => ({
    userId: state.user.id,
    appointmentWebsite: state.agent.appointmentWebsite || '',
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    upsertAppointmentWebsite: (payload: AgentAppointmentWebsite) =>
        dispatch(UpsertAppointmentWebsite.started(payload)),    
});

const AppointmentWebsiteDialogContainer = connect(mapStateToProps, mapDispatchToProps, true)(AppointmentWebsiteDialogComponent);
export const AppointmentWebsiteDialog = connectDialog(AppointmentWebsiteDialogContainer);