export interface ThemeMiscellaneous {
	tagsThreshold: number;
	loginSupportEmail: string;
	loginSupportPhone: string;
	helpAndSupportEmail: string;
	helpAndSupportPhone: string;
	clientConnectAppointments: boolean;
	isReshopLive: boolean;
	connectureAllowedOffices: string;
	connectureAllowedStates: string;
	connectureAllowedAgents: string;
	connectureNextYearStart: string;
}

export const themeMisc: ThemeMiscellaneous = {
	tagsThreshold: 5,
	loginSupportEmail: 'hmfieldsupport@hmkts.com',
	loginSupportPhone: '18887314447',
	helpAndSupportEmail: 'hmfieldsupport@hmkts.com',
	helpAndSupportPhone: '18887314447',
	clientConnectAppointments: false,
	isReshopLive: false,
	connectureAllowedOffices: "*",// not case sensitive
	connectureAllowedStates: "*",// not case sensitive
	connectureAllowedAgents: "Insite.agent", // not case sensitive
	connectureNextYearStart: "1001" // date format mmdd
};
