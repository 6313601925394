import React from 'react';
import { Avatar } from '@material-ui/core';
import { themePalette } from '../../utilities/branding';
import { Strings } from '../../assets/common/strings';
import { SimpleListItem } from '../utility/simple_list_item';
import { useLinkToExternal, useNavigateTo } from '../../utilities/hooks';

interface Props {
	id: string;
	name: string;
	description: string | null;
	learnMore: string | null;
	url: string;
	isSSO: boolean;
	hideDescription?: boolean;
}

export const ToolItem: React.FC<Props> = (props: Props) => {
	
	const { name, url, hideDescription, description, id } = props;

	const navToBattles = useNavigateTo(url);
	const navToLink = useLinkToExternal(url);
	const openLink = id === Strings.SideBarMenu.Battles
		? navToBattles
		: navToLink;

	return (
		<SimpleListItem
			key={id}
			title={name}
			subtitle={hideDescription ? null : description}
			onClick={openLink}
			hideArrow
			icon={
				<Avatar
					color={themePalette.negative_text}
					style={{ backgroundColor: themePalette.tools_avatar }}
				>
					{name.charAt(0)}
				</Avatar>
			}
		/>
	);
};
