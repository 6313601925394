import { SessionSummary } from "../../reducers/click_to_call_reducer";
import { InjectedProps as DialogInjectedProps, connectDialog } from '../../containers/dialogs';
import { NavigationProps } from '../nav/Routes';
import React from 'react';
import { connect, Dispatch } from '@optum-uhone-hmkts/rise';
import { Dialog, DialogContent, Typography, Grid, Button, CircularProgress } from '@material-ui/core';
import { toCurrencyString } from '../../assets/common/string_builders';
import { Strings } from '../../assets/common/strings';
import moment from 'moment';

interface StateProps {
    sessionSummary?: SessionSummary,
    summaryLoading: boolean
}

interface DispatchProps {

}

type Props = DispatchProps & DialogInjectedProps & StateProps & NavigationProps;

interface State {
    open: boolean
}

class ClickToCallSummaryComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            open: true
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.props.summaryLoading && !nextProps.summaryLoading && !nextProps.sessionSummary) {
            this.setState({
                open: false
            }, this.closeDialog);
        }
    }

    closeDialog = () => {
        this.props.dismissSelf();
    }

    render() {
        return (
            <Dialog open={this.state.open} fullWidth={true}>
                {this.props.summaryLoading || this.props.sessionSummary == undefined ?
                    <DialogContent>
                        <Typography align='center' variant='h5'>
                            Ending Click To Call Session
                            <br /><br />
                            <CircularProgress variant='indeterminate' />
                        </Typography>
                    </DialogContent> :
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography style={{ margin: 'auto' }} align='center' variant='h5'>Click To Call Session Summary</Typography>
                            </Grid>
                            <br /><br />
                            <Grid item container xs={12}>
                                <Grid item xs={10}>
                                    <span>Total Calls Made: </span>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography align='right'>{this.props.sessionSummary!.totalCalls}</Typography>
                                </Grid>
                            </Grid>
                            {this.props.sessionSummary!.dispositions && <Grid item container xs={12}>
                                <Grid item xs={10}>
                                    <span>Total Dispositions Summary: </span>
                                </Grid>
                                <Grid item xs={2}>
                                </Grid>
                            </Grid>}
                            {this.props.sessionSummary!.dispositions && this.props.sessionSummary!.dispositions.map((disposition) => {
                                return (
                                    <Grid item container xs={12}>
                                        <Grid item xs={1} />
                                        <Grid item xs={9}>
                                            <li>{disposition.disposition || "No Disposition"}</li>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography align='right'>{disposition.count}</Typography>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                            <br /><br />
                            <Grid item container xs={12}>
                                <Grid item xs={8}>
                                    <span>Total Call Duration: </span>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography align='right'>{moment.utc(moment.duration({minutes: this.props.sessionSummary!.totalCallDuration}, "minutes").asMilliseconds()).format("HH:mm:ss")}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12}>
                                <Grid item xs={10}>
                                    <span>Total Session Cost: </span>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography align='right'>{toCurrencyString(this.props.sessionSummary!.sessionCost)}*</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography align='right' variant='caption'>*Partial minutes included.</Typography>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <Button
                                    variant="contained"
                                    color={Strings.Theming.Primary}
                                    onClick={this.closeDialog}
                                >
                                    Close
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                }
            </Dialog>
        )
    }
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return {

    }
}

function mapStateToProps(state): StateProps {
    
    return {
        sessionSummary: state.clickToCall.sessionSummary,
        summaryLoading: state.clickToCall.summaryLoading
    }
}

const ClickToCallSummaryContainer = connect(mapStateToProps, mapDispatchToProps, true)(ClickToCallSummaryComponent);
export const StartClickToCallSummaryDialog = connectDialog(ClickToCallSummaryContainer);