import { Action as ReduxAction } from 'redux';
import { isType } from 'typescript-fsa';
import { SearchLeads, SaveLeadStatus } from '../actions/lead_search_actions';
import { ClearCaches } from '../actions/authentication_actions';
import { Logout } from '../actions/authentication_actions';
import { Lookup, Lookups } from '../utilities/lookup';

export type LeadSearchResult = {
	name: string;
	createdOn: Date;
	address?: string;
	phone?: string;
	email?: string;
	leadId: string;
	leadStatus: string;
	leadStatusReason: string;
	linesOfBusiness: string[];
	employerId?: string;
	householdId?: string;
};

export type LeadSearchState = {
	results: LeadSearchResult[];
	totalResults: number;
	loading: boolean;
	currentPage: number;
	initialized: boolean;
};

const initialState: LeadSearchState = {
	results: [],
	totalResults: 0,
	loading: false,
	currentPage: 0,
	initialized: false,
};


export function getDisplayStatusFromUiState(status: string, reason: string) {
	const {
		derivedStatus,
	} = getUiStateFromReduxState(status, reason);

	return derivedStatus;
}

export function getUiStateFromReduxState(status: string, reason: string) {
	let derivedStatus = '';
	let derivedStatusReason = '';

	if (status === Lookups.OpenStatusCode.value)
		derivedStatus = reason;
	else {
		derivedStatus = status;
		derivedStatusReason = reason;
	}

	return {
		derivedStatus,
		derivedStatusReason
	};
}

export function getReduxStateFromUiState(status: string, reason: string) {
	let derivedStatus = '';
	let derivedReason = '';
	
	if (reason === '') {
		derivedStatus = Lookups.OpenStatusCode.value;
		derivedReason = status;
	} else {
		derivedStatus = status;
		derivedReason = reason;
	}

	return {
		derivedStatus,
		derivedReason
	};
}

export function getUiStateFromDisplayStatus(status: string) {
	let trueStatus = '';
	let trueReason = '';

	if (status === Lookups.NoSale.value) {
		trueStatus = status;
		trueReason = '';
	} else if (status === Lookups.Sale.value) {
		trueStatus = status;
		trueReason = Lookups.Sale.label;
	} else {
		trueStatus = Lookups.OpenStatusCode.value;
		trueReason = status;
	}

	return {
		trueStatus,
		trueReason
	};
}

export function leadSearchReducer(
	state: LeadSearchState = initialState,
	action: ReduxAction
): LeadSearchState {
	if (isType(action, SearchLeads.started)) {
		return {
			...state,
			loading: true,
		};
	} else if (isType(action, SearchLeads.done)) {
		const results =
			action.payload.params.pageIndex === 0
				? action.payload.result.results
				: state.results.concat(action.payload.result.results);
		return {
			results,
			totalResults: action.payload.result.totalResults,
			loading: false,
			currentPage: action.payload.params.pageIndex,
			initialized: true,
		};
	} else if (isType(action, SearchLeads.failed)) {
		return {
			...state,
			loading: false,
		};
	} else if (isType(action, SaveLeadStatus.done)) {
		const {
			id,
			statusCode,
			statusReason
		} = action.payload.params;
		const updatedLeadIndex = state.results.findIndex(result => result.leadId === id);

		if (updatedLeadIndex >= 0) {
			const oldLead = state.results[updatedLeadIndex];
			const {
				derivedStatus
			} = getReduxStateFromUiState(statusCode, statusReason);

			let newResults = state.results.slice();
			newResults[updatedLeadIndex] = {
				...oldLead,
				leadStatus: derivedStatus,
				leadStatusReason: statusReason,
			};
	
			return {
				...state,
				results: newResults
			};
		} else {
			return state;
		}
	} else if (isType(action, Logout.started) || isType(action, ClearCaches)) {
		return initialState;
	} else {
		return state;
	}
}
