import React from 'react';
import { Icon } from '@material-ui/core';

interface Props {
	size: number;
	activeIndex: number;
	activeColor?: string;
	handleChangeIndex?(index: number): void;
}

export const SliderViewDots = ({
	size,
	activeIndex,
	activeColor,
	handleChangeIndex,
}: Props) => {
	const DotList: any = [];

	for (let i = 0; i < size; i++) {
		DotList.push(
			<Icon
				key={i}
				onClick={handleChangeIndex ? () => handleChangeIndex(i) : undefined}
				style={{
					fontSize: 12,
					padding: '0 2px',
					color:
						i === activeIndex ? activeColor || 'black' : 'rgb(241, 241, 241)',
				}}
			>
				fiber_manual_record
			</Icon>
		);
	}
	return (
		<div
			style={{
				textAlign: 'center',
				backgroundColor: 'white',
			}}
		>
			{DotList}
		</div>
	);
};
