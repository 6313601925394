import React from 'react';
import { Loaded } from '../../utilities/utilities';
import { SuggestedValues } from './suggested_values';
import { Employer } from '../../reducers/employer_reducer';
import { EmployerSearchResult } from './employer_search_result';

interface Props {
    employers: Loaded<Employer>[],
    selectedEmployerId: string,
    onSelectEmployer: (employer: Employer) => void,
}
export const EmployerSuggestions: React.FC<Props> = (props) => {

    const { employers, selectedEmployerId, onSelectEmployer } = props;

    return (
        <SuggestedValues>
            {employers.map((employer) => (
                <EmployerSearchResult
                    selected={employer.data.id == selectedEmployerId}
                    employer={employer.data}
                    key={"employer-" + employer.data.id}
                    onSelectEmployer={onSelectEmployer}
                />
            ))}
        </SuggestedValues>
    );

};