import React from 'react';
import { TextField, Grid, MenuItem, Chip } from '@material-ui/core';
import { Strings } from '../../../assets/common/strings';
const FT = Strings.Filter;

interface MultiSelectProps {
    selected: string[];
    label: string;
    options: string[];
    setValues: (values: string[]) => void;
    disabled?: boolean;
}

interface State {
    chips: string[];
    options: string[];
}

class MultiselectDropdownWithChips extends React.Component<MultiSelectProps, State>{

    constructor(props: MultiSelectProps) {
        super(props);

        const options = this.setOptions(props.selected);

        this.state = {
            chips: props.selected,
            options: [],
        };
    }

    componentDidMount() {
        this.setChips(this.props.selected);
    }

    componentWillReceiveProps(nextProps: MultiSelectProps) {
        if (this.props.selected != nextProps.selected) {
            this.setChips(nextProps.selected);
        }
    }

    setOptions = (selected: string[]) => {
        const filteredOptions = this.props.options.filter(option => selected.every(chip => chip != option));
        const newOptions = ['All'].concat(filteredOptions);
        this.setState({ options: newOptions });
    };

    setChips = (chips: string[]) => {
        this.setState({ chips });
        this.props.setValues(chips);
        this.setOptions(chips)
    };

    addChip = (event: any) => {
        if (this.props.disabled) return;

        const { chips, options } = this.state;

        const newChips = event.target.value == FT.All
            ? []
            : chips.concat(event.target.value).filter(chip => chip != FT.All);

        this.setChips(newChips)

    };

    removeChip = (value: string) => () => {
        if (this.props.disabled) return;

        const { chips } = this.state;

        const newChips = chips
            .slice()
            .filter(chip => chip != value);

        this.setChips(newChips);
    };

    render() {
        const { label, disabled } = this.props;
        const { chips, options } = this.state;

        const value = chips.length > 0
            ? chips[chips.length - 1]
            : 'All';

        return (
            <Grid item xs={12}>
                <TextField
                    key={FT.MultiSelect.concat(this.props.label)}
                    select
                    onChange={this.addChip}
                    fullWidth
                    value={value}
                    label={label}
                    disabled={disabled}
                >
                    {options.map((item: string, index: number) =>
                        <MenuItem key={index} value={item}>
                            {item}
                        </MenuItem>)
                    }
                </TextField>
                <div style={styles.chipWrapper}>
                    {chips.map(chip => (
                        <Chip
                            style={styles.chip}
                            key={chip}
                            label={chip}
                            onDelete={this.removeChip(chip)}
                        />))}
                </div>
            </Grid>
        );
    }
}

const styles: { [key: string]: React.CSSProperties } = {
    chip: {
        marginTop: '2%',
        marginRight: '.5%'
    },
    chipWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '10'
    }

};

export default MultiselectDropdownWithChips;