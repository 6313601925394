import moment from 'moment';
import { normalizeNumeric } from './formatting/string_normalization';
import { Strings } from '../assets/common/strings';

export const maxLength = (max: number) => (value: any) =>
	value && value.length > max ? `Must be ${max} characters or less` : undefined;

export function validateCurrency(value: string) {
	if (!value) {
		return undefined;
	} else {
		let figures = value;
		if (typeof value === 'string') {
			figures = value.replace(/[^\d\.]/g, '').split('.')[0];
		}
		return figures.length > 6 ? 'Must be under six figures' : undefined;
	}
}

export const validateRequired = (value: any) => value ? undefined : Strings.Form.Required;
export const validateNotDefault = (defaultValue: any) => (value: any) => value === defaultValue ? Strings.Form.Required : undefined;
export const validateRequiredNoSpaces = (value: string) => value && value.trim() != '' ? undefined : Strings.Form.Required;

export const validateCarrier = (value: string, allValues: any, props: any) => {
	let carrierIndex = props.carriers.indexOf(value);
	return carrierIndex > -1 ? undefined : 'Invalid Carrier';
};

export const validateProductType = (value: string, allValues: any, props: any) => {
	let productTypeIndex = props.productTypes.indexOf(value);
	return productTypeIndex > -1 ? undefined : 'Invalid Product Type';
};

export const validateAlpha = (value: string) =>
	value && value.match(/^[A-Za-z]+$/)
		? undefined
		: 'Only Alpha characters allowed';

export const validateAddressLine = (value: string) => {
	return value.match(/^[\w-.#/ ]{0,100}$/i)
		? undefined
		: 'Limited to 100 characters, alphanumeric, periods, slashes and dashes'
}

export const validateAddressCity = (value: string) => {
	return value.match(/^[A-Za-z- ]{0,100}$/i)
		? undefined
		: 'Limited to 100 characters, alpha characters, and dashes'
}

export const validateAddressZip = (value: string) => {
	const matched = value.match(/^[0-9-]{5,10}$/i)
			? value.match(/^[^-]+-{0,1}[^-]+$/)
			: false;

	return matched
		? undefined
		: '5-10 characters, only numerals and a dash'
}

export const validateName = (value: string) => {
	//Allow names with alphanumeric, spaces, period, comma, hyphens and apostrophes
	if (value && value.match(/^[A-Za-z0-9]+[A-Za-z0-9 .,'’‘-]*$/)) {
		return undefined;
	} else if (!value) {
		return 'Required';
	} else {
		return "Only alphanumeric, space and [.,'’‘-] characters allowed (except at the beginning)";
	}
};

export const validateNameIfExists = (value: string) => {
	//Allow names with alphanumeric, spaces, period, comma, hyphens and apostrophes
	if (!value) {
		return undefined;
	}
	if (value && value.match(/^[A-Za-z0-9]+[A-Za-z0-9 .,'’‘-]*$/)) {
		return undefined;
	} else {
		return "Only alphanumeric, space and [.,'’‘-] characters allowed (except at the beginning)";
	}
};

export const validateExtension = (value: string) =>
	value.length <= 10 ? undefined : 'Limit to 10 characters';

export const addressLineValidation = (value: string) =>
	value && (value.length > 100 || !value.match(/^[a-zA-Z0-9-\. ]+$/))
		? `Limit to 100 characters, alphanumeric, periods, and dashes`
		: undefined;

export const zipValidation = (value: string) =>
	value && (value.length > 10 || !value.match(/^[0-9-]+$/))
		? 'Limit to 10 characters, only numerals and dashes'
		: undefined;

export const validateMiddleInitial = (value: any) => {
	if (value) {
		const val = value.replace(/[^A-Za-z]/g, '');
		return val.length != 1
			? 'Middle Initial must be a single letter'
			: undefined;
	}
};

export const validateSSN = (value: string) =>
	value && (value.length != 4 || !value.match(/^[0-9]+$/))
		? 'Requires 4 characters, only numerals'
		: undefined;

export const validateEmail = (value: string) => {
	if (value) {
		const testEmail = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/.test(
			value
		);
		return testEmail ? undefined : 'Invalid Email';
	} else return undefined;
};

/**
* Validates date format/today/past/length/year.
* @param item - string for message display ie. Cannot create a -item- in the past.
* @param allowToday - Allows today as a valid date time.
* @param allowPast - Allows Past dates to be valid
* @returns returns undefined if dates are valid else error message.
*/
export const validateDateString = (item: string, allowToday: boolean, allowPast: boolean ) => (value: string) => {
	if (!value) {
		return undefined;
	} else {
		const date = moment(value, 'MM/DD/YYYY', true);
		const isValidDate = date.isValid();
		const isPast = date.isBefore(moment().startOf('day'));
		const isInvalidLength = value.length < 8
		const isInvalidYear = date.get('year').toString().length > 4;
		const isToday = date.isSame(moment(), 'day');
		if (!isValidDate) {
			return 'Not a valid date (MM/DD/YYYY)'
		} else if (isInvalidLength){
			return 'Not a valid date (MM/DD/YYYY)*'
		} else if (isPast && !allowPast) {	
			return 'Cannot create a ' + item + ' in the past'
		} else if (!allowToday && isToday) {
			return 'Cannot create a ' + item + ' in current day'
		} else if (isInvalidYear) {
			return 'Year cannot be greater 4 digits';
		} else {
			return undefined
		}
	}
};

export const validateYear = (year: string) => {
	const validLength = validateSSN(year);
	if (validLength) {
		return validLength;
	}
	return validateYearLength(year);
};
export const validateYearLength = (year: string) => {
	if (year && year != '') {
		if (year.length < 4 || year.length > 4) {
			return 'Invalid Year';
		}
		if ('1950' > year || year > moment().year().toString()) {
			return 'Invalid Year';
		}
	}
	return undefined;
};

export const validatePhone = (value: string) =>
	!value || normalizeNumeric(value).length === 10
		? undefined
		: 'Invalid Phone Number';

export const validateWeight = (value: number) =>
	!value || value < 1000 ? undefined : 'Value must be within three digits';

export const validateURL = (value: string) => {
    try {
        const url = new URL(value);
        return url.protocol === 'http:' || url.protocol === 'https:';
    }
    catch (err) {
        return false;
    }
}


export const validateMaxInteger = (max?: number) => (value?: number) =>
	value !== undefined && max !== undefined && value >= max
		? 'Value cannot be greater than max'
		: undefined;

export const validateMinInteger = (min?: number) => (value?: number) =>
	value !== undefined && min !== undefined && value <= min
		? 'Value cannot be less than min'
		: undefined;