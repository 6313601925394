import {
	Button,
	Dialog,
	DialogContent,
	DialogActions,
	DialogContentText,
} from '@material-ui/core';
import React from 'react';
import { Contact, HouseholdRole } from '../../reducers/ContactReducer';
import { Loaded } from '../../utilities/utilities';
import { Policy } from '../../reducers/policy_reducer';
import { DeletePersonModal } from '../contact/delete_person';
import { MoreMenu } from '../nav/more_menu';
import { SubtitleProps, CustomListItem } from '../utility/custom_list_item';
import {
	composeNameGenderDobAge,
	composeAddressFromContact,
	composePhoneNumberFromContact,
} from '../../utilities/contact_util';
import { EMPTY_CONTACT } from '../../utilities/empty_entities';
import { themePalette } from '../../utilities/branding';
import { Strings as S } from '../../assets/common/strings'
import { wrapInCollapsibleCard } from '../higher_order_component/wrap_in_card';
import CustomList from '../utility/custom_list';
import { copyToClipboard } from '../../utilities/is_mobile';

interface Props {
	contacts: Loaded<Contact>[];
	parentId: string;
	policies: Loaded<Policy>[];
	enableMenu?: boolean;
	customSpans?: {
		setSpanText: (contact: Contact) => string;
		newCss: React.CSSProperties;
	}[];
	deleteContactDisabled?: boolean;
	deleteFromHousehold: boolean;
	showJobTitles?: boolean;
	onContactClick: (contact: Contact) => void;
	removeContact: (contactId: string) => void;
	setNewPrimaryContact: (newPrimaryId: string) => void;
	deletePerson: (contact: Contact) => void;
	returnToDetailPage: () => void;
	goToLeadsDashboard: () => void;
	updateHouseholdRole?: (contact: Contact) => void;
	editPerson: (contact: Contact) => void;
}

interface State {
	selectedContact: Contact;
	deleteModalVisible: boolean;
	removeModalVisible: boolean;
}

class ContactList extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			deleteModalVisible: false,
			removeModalVisible: false,
			selectedContact: EMPTY_CONTACT,
		};
	}

	orderMembers = () => {
		return this.props.contacts.sort(
			(memberA: Loaded<Contact>, memberB: Loaded<Contact>) => {
				if (memberA.data.householdRole < memberB.data.householdRole) return -1;
				if (memberA.data.householdRole > memberB.data.householdRole) return 1;
				else return 0;
			}
		);
	};
	householdMenu = (contact: Contact, phoneNumber: string) => {
		const markPrimaryAction = [
			{
				children: S.MoreMenu.EditPerson,
				disabled: false,
				onClick: () => this.props.editPerson(this.state.selectedContact)
			},
			{
				children: S.MoreMenu.MarkAsPrimary,
				disabled: contact.householdRole === HouseholdRole.Primary,
				onClick: () => {
					if (this.state.selectedContact)
						this.props.setNewPrimaryContact(this.state.selectedContact.id);
				},
			},
		];
		const menuActions = this.props.updateHouseholdRole
			? markPrimaryAction.concat([
				{
					children: S.MoreMenu.MarkAsDependent,
					onClick: () => {
						if (
							this.state.selectedContact &&
							this.props.updateHouseholdRole
						) {
							const newContact = {
								...this.state.selectedContact,
								householdRole: HouseholdRole.Dependent,
							};
							this.props.updateHouseholdRole(newContact);
						}
					},
					disabled: this.props.contacts.length == 1 ||
						contact.householdRole == HouseholdRole.Dependent ||
						this.state.selectedContact.householdRole == HouseholdRole.Primary
						&& this.props.contacts.filter((contact) => contact.data.householdRole == HouseholdRole.Primary).length == 1,

				},
				{
					children: S.MoreMenu.MarkAsSpouse,
					onClick: () => {
						if (
							this.state.selectedContact &&
							this.props.updateHouseholdRole
						) {
							const newContact = {
								...this.state.selectedContact,
								householdRole: HouseholdRole.Secondary,
							};
							this.props.updateHouseholdRole(newContact);
						}
					},
					disabled:
						this.props.contacts.length == 1 ||
						contact.householdRole == HouseholdRole.Secondary ||
						contact.householdRole === HouseholdRole.Primary,
				},
			])
			: markPrimaryAction;
		const actions = menuActions.concat([
			{
				children: S.MoreMenu.MoveToNewHousehold,
				onClick: () => this.setState({ removeModalVisible: true }),
				disabled: this.props.contacts.length == 1,
			},
			{
				children: S.MoreMenu.CopyPhoneNumber,
				onClick: () => copyToClipboard(phoneNumber),
				disabled: !phoneNumber,
			},
			{
				children: S.MoreMenu.Delete,
				onClick: () => this.setState({ deleteModalVisible: true }),
				disabled: this.props.deleteContactDisabled || false,
			},
		]);
		return (
			<MoreMenu
				children={actions}
				onMenuClick={() => this.setState({ selectedContact: contact })}
				color={themePalette.default_avatar}
			/>
		)
	};

	employerMenu = (contact: Contact, phoneNumber: string) => {
		const isMarkPrimaryDisabled =
			this.state.selectedContact &&
			this.state.selectedContact &&
			(this.state.selectedContact.employerPrimaryContact ||
				(this.state.selectedContact.householdRole === HouseholdRole.Primary &&
					!this.state.selectedContact.employerId));
		const actions = [
			{
				children: S.MoreMenu.EditPerson,
				disabled: false,
				onClick: () => this.props.editPerson(this.state.selectedContact)
			},
			{
				children: S.MoreMenu.MarkAsPrimary,
				onClick: () => {
					if (this.state.selectedContact) {
						this.props.setNewPrimaryContact(this.state.selectedContact.id);
					}
				},
				disabled: isMarkPrimaryDisabled,
			},
			{
				children: S.MoreMenu.CopyPhoneNumber,
				onClick: () => copyToClipboard(phoneNumber),
				disabled: !phoneNumber,
			},
			{
				children: S.MoreMenu.RemoveMember,
				onClick: () => {
					if (this.state.selectedContact) {
						this.setState({ removeModalVisible: true });
					}
				},
				disabled: this.props.contacts.length == 1,
			},
		];
		return (
			<MoreMenu
				children={actions}
				onMenuClick={() => this.setState({ selectedContact: contact })}
				color={themePalette.tertiary_text}
			/>);
	};

	renderContactItem = (contact: Contact) => {
		const subtitles: SubtitleProps[] = [];

		const phoneNumber = composePhoneNumberFromContact(contact);
		const address = composeAddressFromContact(contact);
		if (phoneNumber) subtitles.push({ value: phoneNumber });
		if (address) subtitles.push({ value: address });

		const rightActionIcon = this.props.enableMenu
			? this.props.updateHouseholdRole
				? this.householdMenu(contact, phoneNumber)
				: this.employerMenu(contact, phoneNumber)
			: undefined;
		const key = 'contact-' + contact.id;
		return (
			<CustomListItem
				id={key}
				key={key}
				onClick={() => this.props.onContactClick(contact)}
				header={composeNameGenderDobAge(contact)}
				subtitles={subtitles}
				rightActionElement={rightActionIcon}
				leftColumnSize={0}
				primaryColumnSize={7}
				rightSubtitles={
					this.props.customSpans
						? this.props.customSpans.map(spanBuilder => ({
							value: spanBuilder.setSpanText(contact),
						}))
						: undefined
				}
			/>
		);
	};

	render() {
		const orderedMembers: Loaded<Contact>[] = this.orderMembers();

		return (
			<>
				<CustomList emptyMessage="You have no contacts">
					{orderedMembers.map((contact: Loaded<Contact>, index) =>
						this.renderContactItem(contact.data)
					)}
				</CustomList>
				<Dialog open={this.state.removeModalVisible}>
					<DialogContent>
						<DialogContentText>
							{'Are you sure you want to remove this person?'}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						{[
							<Button
								key={'cancelButton'}
								color="secondary"
								onClick={() => this.setState({ removeModalVisible: false })}
							>
								Cancel
							</Button>,
							<Button
								key={'removeButton'}
								variant="contained"
								color="primary"
								style={{
									backgroundColor: themePalette.delete_remove_reject_button
								}}
								onClick={() => {
									this.props.removeContact(this.state.selectedContact.id);
									this.setState({ removeModalVisible: false });
								}}
							>
								Remove
							</Button>
						]}
					</DialogActions>
				</Dialog>
				<DeletePersonModal
					contact={this.state.selectedContact}
					deleteContact={() => {
						this.setState({
							deleteModalVisible: false,
						});
						this.props.deletePerson(this.state.selectedContact);
					}}
					goToLeadsDashboard={this.props.goToLeadsDashboard}
					returnToDetailPage={this.props.returnToDetailPage}
					visible={this.state.deleteModalVisible}
					onClose={() => {
						this.setState({
							deleteModalVisible: false,
						});
					}}
					deleteFromHousehold={this.props.deleteFromHousehold}
				/>
			</>
		);
	}
}

export const ContactListCard = wrapInCollapsibleCard(ContactList);
