import {
	CreateNotification,
	GetPersistentNotification,
	GetNewNotification,
	GetNotification,
	GetNotificationList,
	UpdateCreateFormTags,
	UpdateEditFormTags,
	GetNotificationTargetCount,
	EditNotification,
	GetLoginNotification,
} from '../actions/notification_actions';
import { isType } from 'typescript-fsa';
import { ClearCaches } from '../actions/authentication_actions';
import { Logout, SuccessfulLogin } from '../actions/authentication_actions';
import { Notification } from '../containers/notifications_page/notifications_page';
import { Strings as S } from '../assets/common/strings'



export interface NotificationState {
	editTags: string[];
	createTags: string[];
	persistentNotifications: Notification[];
	newNotifications: Notification[];
	createNotificationStatus: S.NotificationStatus;
	selectedNotification?: Notification;
	notificationTargetCount: number;
	loading: boolean;
	persistentLoading: boolean;
	newLoading: boolean;
	pagedNotifications: {
		pageNum: number,
		pageSize: number,
		hasMore: boolean
	}
}
export interface NotificationInput {
	groupId: string;
	title: string;
	category: string;
	content: ContentInput;
	isUrgent: boolean;
	isPersistent: boolean;
	isPush: boolean;
	tags: Array<string | null>;
	startDate: string;
	endDate: string;
	senderAgentId: string;
	targetedAgents?: string[];
	url?: string;
	referrerUrl?: string;
};
interface ContentInput {
	base: string;
	extended?: string | null;
};

const initialState: NotificationState = {
	editTags: [],
	createTags: [],
	persistentNotifications: [],
	newNotifications: [],
	createNotificationStatus: S.NotificationStatus.Unset,
	loading: false,
	persistentLoading: false,
	newLoading: false,
	notificationTargetCount: 0,
	pagedNotifications: {
		pageNum: 0,
		pageSize: 20,
		hasMore: true
	}
};

export function notificationReducer(
	state: NotificationState = initialState,
	action: any
): NotificationState {
	if (isType(action, UpdateCreateFormTags)) {
		return {
			...state,
			createTags: action.payload.tags,
		};
	} else if (isType(action, UpdateEditFormTags)) {
		return {
			...state,
			editTags: action.payload.tags,
		};
	} else if (isType(action, Logout.started) || isType(action, ClearCaches)) {
		return {
			...initialState,
		};
	} else if (isType(action, CreateNotification.failed) || isType(action, EditNotification.failed)) {
		return {
			...state,
			createNotificationStatus: S.NotificationStatus.Failed
		};
	} else if (isType(action, CreateNotification.done) || isType(action, EditNotification.done)) {
		return {
			...state,
			createNotificationStatus: S.NotificationStatus.Succeeded
		};
	} else if (isType(action, GetPersistentNotification.failed) || isType(action, GetLoginNotification.failed)) {
		return {
			...state,
			persistentNotifications: [],
			persistentLoading: false
		};
	} else if (isType(action, GetPersistentNotification.done) || isType(action, GetLoginNotification.done)) {
		return {
			...state,
			persistentNotifications: action.payload.result,
			persistentLoading: false
		};
	} else if (isType(action, GetPersistentNotification.started) || isType(action, GetLoginNotification.started)) {
		return {
			...state,
			persistentLoading: true
		};
	} else if (isType(action, GetNewNotification.failed)) {
		return {
			...state,
			newNotifications: [],
			newLoading: false
		};
	} else if (isType(action, GetNewNotification.done)) {
		return {
			...state,
			newNotifications: action.payload.result,
			newLoading: false
		};
	} else if (isType(action, GetNewNotification.started)) {
		return {
			...state,
			newLoading: true
		};
	} else if (isType(action, GetNotification.failed)) {
		return {
			...state,
			selectedNotification: undefined,
			loading: false
		};
	} else if (isType(action, GetNotification.done)) {
		return {
			...state,
			selectedNotification: action.payload.result,
			loading: false
		};
	} else if (isType(action, GetNotification.started)) {
		return {
			...state,
			selectedNotification: undefined,
			loading: true
		};
	} else if (isType(action, GetNotificationList.failed)) {
		return {
			...state,
			loading: false
		};
	} else if (isType(action, GetNotificationList.done)) {

		const persistentNotifs: Notification[] = action.payload.result.filter(notif => notif.isPersistent);
		const newNotifs: Notification[] = action.payload.result.filter(notif => !notif.isPersistent);
		
		const currentNewNotifications : Notification[] = state.newNotifications.slice();
		const currentPersistentNotifications : Notification[] = state.persistentNotifications.slice();
		
		const hasMore = action.payload.result.length === 0 ? false : true;
		const pageNum =
			hasMore &&
				action.payload.params.pageNum >= 0
				? action.payload.params.pageNum + 1
				: state.pagedNotifications.pageNum;

		const pagedNotifications = {
			pageSize: state.pagedNotifications.pageSize,
			pageNum,
			hasMore
		}
		
		updateStateWithInboundNotifications(currentNewNotifications, newNotifs);
		updateStateWithInboundNotifications(currentPersistentNotifications, persistentNotifs);
		return {
			...state,
			createNotificationStatus: S.NotificationStatus.Unset,
			persistentNotifications: currentPersistentNotifications,
			newNotifications: currentNewNotifications,
			loading: false,
			pagedNotifications,
		};
	} else if (isType(action, GetNotificationList.started)) {
		return {
			...state,
			loading: true
		};
	} else if (isType(action, GetNotificationTargetCount.failed)) {
		return {
			...state,
			notificationTargetCount: 0,
		};
	} else if (isType(action, GetNotificationTargetCount.done)) {
		return {
			...state,
			notificationTargetCount: action.payload.result,
		};
	} else if (isType(action, SuccessfulLogin)) {
		return {
			...state,
			persistentNotifications: []
		};
	} else {
		return state;
	}
}

function updateStateWithInboundNotifications(
	notificationsInState: Notification[],
	inboundNotifications: Notification[]
) {
	inboundNotifications.forEach(inboundNotification => {
		let notificationUpdated: boolean = false;

		notificationsInState.forEach((currentNotificationInState, index) => {
			if (currentNotificationInState.id == inboundNotification.id) {
				notificationUpdated = true
				if (currentNotificationInState.isDeleted) {
					notificationsInState.splice(index, 1);
				} else {
					notificationsInState[index] = inboundNotification
				}
			}
		});

		if(!notificationUpdated){
			notificationsInState.push(inboundNotification);
		}

	});

}