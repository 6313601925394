export interface ThemeImageCollection {
    imageCollection: string;
}
export interface ThemeImages {
	logo: string;
	loginLogo: string;
	loginBackground: string;
	favicon: string;
	bubblesPrimary: string;
	bubblesSecondary: string;
	bubblesTertiary: string;
}

export const themeImages: ThemeImages = {
	logo: require('../../assets/hmkts_logo.png'),
	loginLogo: require('../../assets/LoginLogo.png'),
	loginBackground: require('../../assets/LoginBackground.jpg'),
	favicon: require('../../assets/favicon.png'),
	bubblesPrimary: require('../../assets/bubbles_green.png'),
	bubblesSecondary: require('../../assets/bubbles_blue.png'),
	bubblesTertiary: require('../../assets/bubbles_orange.png'),
};
