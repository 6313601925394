import { Application } from '../reducers/application_reducer';
import { Policy, ProductStatus } from '../reducers/policy_reducer';

export function getProductUserId(app?: Application, policy?: Policy) {
	if (app && app.userId) {
		return app.userId;
	} else if (policy && policy.userId) {
		return policy.userId;
	} else {
		return '';
	}
}

export function getProductStatus(app?: Application, policy?: Policy) {
	if (app && app.id && policy && policy.id) {
		let policyStatus = policy.policyStatus;
		if (
			leftStatusIsFartherAlong(policy.manualPolicyStatus, policy.policyStatus)
		) {
			policyStatus = policy.manualPolicyStatus;
		}
		return leftStatusIsFartherAlong(app.applicationStatus, policyStatus)
			? app.applicationStatus
			: policyStatus;
	} else if (app && app.id && (!policy || !policy.id)) {
		return app.applicationStatus;
	} else if ((!app || !app.id) && policy && policy.id) {
		if (
			leftStatusIsFartherAlong(policy.manualPolicyStatus, policy.policyStatus)
		) {
			return policy.manualPolicyStatus;
		} else {
			return policy.policyStatus;
		}
	} else {
		return 0;
	}
}

export function getProductStatusReason(app?: Application, policy?: Policy) {
	if (app && app.id && policy && policy.id) {
		let policyStatusReason = policy.statusReason;
		let policyStatus = policy.policyStatus;
		if (
			leftStatusIsFartherAlong(policy.manualPolicyStatus, policy.policyStatus)
		) {
			policyStatusReason = policy.manualStatusReason;
			policyStatus = policy.manualPolicyStatus;
		}
		if (leftStatusIsFartherAlong(app.applicationStatus, policyStatus)) {
			return app.statusReason;
		} else {
			return policyStatusReason;
		}
	} else if (app && app.id && (!policy || !policy.id)) {
		return app.statusReason;
	} else if ((!app || !app.id) && policy && policy.id) {
		if (
			leftStatusIsFartherAlong(policy.manualPolicyStatus, policy.policyStatus)
		) {
			return policy.manualStatusReason;
		} else {
			return policy.statusReason;
		}
	} else {
		return '';
	}
}

export function leftStatusIsFartherAlong(
	leftStatus?: ProductStatus,
	rightStatus?: ProductStatus
) {
	if (leftStatus === ProductStatus.Pending) {
		return false;
	} else if (leftStatus === ProductStatus.Active) {
		if (rightStatus === ProductStatus.Pending) {
			return true;
		} else if (
			rightStatus === ProductStatus.Active ||
			rightStatus === ProductStatus.Terminated
		) {
			return false;
		}
	} else if (leftStatus === ProductStatus.Terminated) {
		if (
			rightStatus === ProductStatus.Pending ||
			rightStatus === ProductStatus.Active
		) {
			return true;
		} else if (rightStatus === ProductStatus.Terminated) {
			return false;
		}
	}
	return false;
}
