import { Action } from 'typescript-fsa';
import {
	Template,
	BulkEmail,
	TemplatePreview,
} from '../reducers/template_reducer';
import { QueueSnackbar } from '../actions/snackbar_actions';
import {
	DeleteTemplate,
	GetTemplateById,
	PostTemplate,
	GetTemplateSubstitutions,
	SendBulkEmail,
	GetAgentTemplates,
	GetCorporateTemplates,
	GetTemplateWithSubstitutionsById
} from '../actions/template_actions';
import { http } from '../utilities';
import { call, put, all, race, take } from 'redux-saga/effects';
import { HttpOptions } from '../utilities/http';
import { Strings } from '../assets/common/strings';
import { takeLatestForActionType } from '../utilities/saga_util';
import { getSnackbarInfoProps, getSnackbarSuccessProps, getSnackbarErrorProps } from '../utilities/snackbar_util';
import { getReferrerUrl } from '../utilities/brokerage_utils';

const STS = Strings.TemplateSaga;
const STM = Strings.TemplateManagement;

// Get Template
function* getTemplateByIdSaga(action: Action<string>) {
	try {
		const response = yield call(getTemplateByIdClient, action.payload);
		if (response.ok) {
			const data = yield response.json();
			yield put(
				GetTemplateById.done({
					params: action.payload, result: data
				}));
		} else {
			yield put(
				GetTemplateById.failed({
					params: action.payload,
					error: {
						errorCode: response.status
					}
				})
			);
			yield put(QueueSnackbar(retrieveErrorSnackbar));
		}
	} catch (error) {
		yield put(
			GetTemplateById.failed({
				params: action.payload, error: error
			}));
		yield put(QueueSnackbar(retrieveErrorSnackbar));
	}
}

function* getTemplateWithSubstitutionsByIdSaga(action: Action<string>) {
	let error = '';

	yield put(GetTemplateById.started(action.payload));
	const { done: templateDone, failed: templateFailed } = yield race({
		done: take(GetTemplateById.done.type),
		failed: take(GetTemplateById.failed.type),
	});

	if (templateDone) {
		const template = templateDone.payload.result as Template;

		yield put(GetTemplateSubstitutions.started({
			htmlContent: template.htmlContent,
			emailType: template.emailType || STM.Agent
		}));
		const { done: subDone, failed: subFailed } = yield race({
			done: take(GetTemplateSubstitutions.done.type),
			failed: take(GetTemplateSubstitutions.failed.type),
		});

		if (subFailed) {
			error = subFailed.payload.error;
		}
	} else if (templateFailed) {
		error = templateFailed.payload.error;
	}

	if (error) {
		yield put(GetTemplateWithSubstitutionsById.failed({
			params: action.payload,
			error
		}));
	} else {
		yield put(GetTemplateWithSubstitutionsById.done({
			params: action.payload,
			result: null
		}));
	}
}

function getTemplateByIdClient(templateId: string): Promise<any> {
	return http(STS.ApiBase + templateId);
}

// Agent Templates
function* getAgentTemplatesSaga(action: Action<undefined>) {
	try {
		const response = yield call(getAgentTemplatesClient);

		if (response.ok) {
			const data = yield response.json();
			yield put(
				GetAgentTemplates.done({
					params: undefined, result: data
				}));
		} else {
			yield put(
				GetAgentTemplates.failed({
					params: undefined,
					error: {
						errorCode: response.status
					}
				})
			);
			yield put(QueueSnackbar(retrieveErrorSnackbar));
		}
	} catch (error) {
		yield put(
			GetCorporateTemplates.failed({
				params: undefined,
				error: error
			})
		);
		yield put(QueueSnackbar(retrieveErrorSnackbar));
	}
}

function getAgentTemplatesClient(): Promise<any> {
	return http(STS.ApiBase + STS.GetAgentTemplates);
}

// Corporate Templates
function* getCorporateTemplatesSaga(action: Action<undefined>) {
	try {
		const response = yield call(getCorporateTemplatesClient);

		if (response.ok) {
			const data = yield response.json();
			yield put(
				GetCorporateTemplates.done({
					params: undefined,
					result: data
				})
			);
		} else {
			yield put(
				GetCorporateTemplates.failed({
					params: undefined,
					error: {
						errorCode: response.status
					}
				})
			);
			yield put(QueueSnackbar(retrieveErrorSnackbar));
		}
	} catch (error) {
		yield put(
			GetCorporateTemplates.failed({
				params: undefined,
				error: error
			})
		);
		yield put(QueueSnackbar(retrieveErrorSnackbar));
	}
}

function getCorporateTemplatesClient(): Promise<any> {
	return http(`${STS.ApiBase}${STS.GetCorporateTemplates}?referrerUrl=${getReferrerUrl()}`);
}

// Delete Template
function* deleteTemplateSaga(action: Action<string>) {
	try {
		const response = yield call(deleteTemplateClient, action.payload);

		if (response.ok) {
			const data = yield response.json();
			yield all([
				put(
					DeleteTemplate.done({
						params: action.payload,
						result: data
					})
					),
				put(QueueSnackbar(getSnackbarSuccessProps(STS.DeleteSuccess)))
			]);
		} else {
			yield all([
				put(
					DeleteTemplate.failed({
						params: action.payload,
						error: {
							errorCode: response.status
						}
					})
				),
				put(QueueSnackbar(getSnackbarErrorProps(STS.DeleteFailed)))
			]);
		}
	} catch (error) {
		yield all([
			put(
				DeleteTemplate.failed({
					params: action.payload,
					error: error
				})
			),
			put(QueueSnackbar(getSnackbarErrorProps(STS.DeleteFailed)))
		]);
	}
}

function deleteTemplateClient(templateId: string): Promise<any> {
	const options: HttpOptions = { method: Strings.Http.Delete };

	return http(STS.ApiBase + templateId, options);
}

//Send Bulk Email
function* sendBulkEmailSaga(action: Action<BulkEmail>) {
	try {
		const response = yield call(sendBulkEmailClient, action.payload);

		if (response.ok) {
			yield all([
				put(SendBulkEmail.done({ params: action.payload, result: STS.Success })),
				put(QueueSnackbar(getSnackbarSuccessProps('Successfully queued bulk emails')))
			]);
		} else {
			yield all([
				put(
					SendBulkEmail.failed({
						params: action.payload,
						error: response.status,
					})
				),
				put(QueueSnackbar(getSnackbarErrorProps('Error queueing bulk emails')))
			]);
		}
	} catch (error) {
		yield all([
			put(SendBulkEmail.failed({ params: action.payload, error: error })),
			put(QueueSnackbar(getSnackbarErrorProps('Error queueing bulk emails')))
		]);
	}
}

function sendBulkEmailClient(bulkEmail: BulkEmail): Promise<any> {
	const options: HttpOptions = {
		method: Strings.Http.Post,
		body: JSON.stringify(bulkEmail),
	};
	return http(STS.EnqueueApi, options);
}

// POST Template
function* postTemplateSaga(action: Action<Template>) {
	try {
		const response = yield call(postTemplateClient, action.payload);

		if (response.ok) {
			const data = yield response.json();
			yield all([
				put(
					PostTemplate.done({
						params: action.payload,
						result: data
					})
				),
				put(QueueSnackbar(getSnackbarSuccessProps(STS.SaveSuccess)))
			]);
		} else {
			yield all([
				put(
					PostTemplate.failed({
						params: action.payload,
						error: {
							errorCode: response.status
						}
					})
				),
				put(QueueSnackbar(getSnackbarErrorProps(STS.SaveFailed)))
			]);
		}
	} catch (error) {
		yield all([
			put(
				PostTemplate.failed({
					params: action.payload,
					error: error
				})
			),
			put(QueueSnackbar(getSnackbarErrorProps(STS.SaveError)))
		]);
	}
}

function postTemplateClient(template: Template): Promise<any> {
	template.domain = getReferrerUrl();
	const options: HttpOptions = {
		method: Strings.Http.Post,
		body: JSON.stringify(template),
	};

	return http(STS.LowerCaseApi, options);
}

// GET Template Subs
function* getTemplateSubstitutionsSaga(action: Action<TemplatePreview>) {
	try {
		const response = yield call(
			getTemplateSubstitutionsClient,
			action.payload
		);

		if (response.ok) {
			const data = yield response.json();
			yield put(
				GetTemplateSubstitutions.done({
					params: action.payload,
					result: data
				}));
		} else {
			yield put(
				GetTemplateSubstitutions.failed({
					params: action.payload,
					error: {
						errorCode: response.started,
					}
				})
			);
		}
	} catch (error) {
		yield put(
			GetTemplateSubstitutions.failed({
				params: action.payload,
				error: error
			}));
	}
}

function getTemplateSubstitutionsClient(input: TemplatePreview): Promise<any> {
	const options: HttpOptions = {
		method: Strings.Http.Post,
		body: JSON.stringify(input),
	};

	return http(STS.SubsApi, options);
}

const retrieveErrorSnackbar = getSnackbarInfoProps(STS.RetrieveFail, 6000);

export function* templateSagas() {
	yield all([
		takeLatestForActionType(
			GetTemplateById.started,
			getTemplateByIdSaga
		),
		takeLatestForActionType(
			GetTemplateWithSubstitutionsById.started,
			getTemplateWithSubstitutionsByIdSaga
		),
		takeLatestForActionType(
			GetAgentTemplates.started,
			getAgentTemplatesSaga
		),
		takeLatestForActionType(
			GetCorporateTemplates.started,
			getCorporateTemplatesSaga
		),
		takeLatestForActionType(
			DeleteTemplate.started,
			deleteTemplateSaga
		),
		takeLatestForActionType(
			SendBulkEmail.started,
			sendBulkEmailSaga
		),
		takeLatestForActionType(
			PostTemplate.started,
			postTemplateSaga
		),
		takeLatestForActionType(
			GetTemplateSubstitutions.started,
			getTemplateSubstitutionsSaga
		)
	]);
}