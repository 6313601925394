import React from 'react';
import { Grid } from '@material-ui/core';
import { ReadOnlyTextField } from '../utility/read_only_text_field';
import { isDefaultDate } from '../../utilities/date_util';
import { wrapInCollapsibleCard } from '../higher_order_component/wrap_in_card';

interface Props {
	policyStatus: string;
	statusReason: string;
	carrierStatus: string;
	policyStatusDate: string;
	statusReasonDate: string;
	cancellationReason: string;
}
const PolicyStatusInformation: React.FC<Props> = (props) => {
	const policyStatusDate = isDefaultDate(props.policyStatusDate) ? "None" : props.policyStatusDate;
	const statusReasonDate = isDefaultDate(props.statusReasonDate) ? "None" : props.statusReasonDate;

	return (
		<Grid container>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Policy Status"
						value={props.policyStatus}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Policy Status Date"
						value={policyStatusDate}
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Status Reason"
						value={props.statusReason}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Status Reason Date"
						value={statusReasonDate}
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Carrier Status"
						value={props.carrierStatus}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Cancellation Reason"
						value={props.cancellationReason}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export const PolicyStatusInformationCard = wrapInCollapsibleCard(PolicyStatusInformation);
