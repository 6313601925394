import { Strings } from '../assets/common/strings';
import { EmailAutomation, EmailAutomationEvent, EmailAutomationGroup } from '../reducers/email_automation_reducer';
import {actionCreatorFactory} from 'typescript-fsa';
import { Action as ReduxAction } from 'redux';
import { EmailAutomationResponse } from '../sagas/email_automation_sagas';

const emailAutomationActionCreator = actionCreatorFactory(
	'EmailAutomationActionCreator'
);

// GET
export const GetEmailAutomationsByAgentId = emailAutomationActionCreator.async<
	undefined,
	EmailAutomation
>('GetEmailAutomation');

export function getEmailAutomationsByAgentId(): ReduxAction {
	return GetEmailAutomationsByAgentId.started(undefined);
}

export function getEmailAutomationsByAgentIdSuccess(
	emailAutomations: EmailAutomation
): ReduxAction {
	return GetEmailAutomationsByAgentId.done({
		params: undefined,
		result: emailAutomations,
	});
}

export function getEmailAutomationsByAgentIdFailure(error: any): ReduxAction {
	return GetEmailAutomationsByAgentId.failed({
		params: undefined,
		error: error,
	});
}

// POST
export const PostEmailAutomation = emailAutomationActionCreator.async<
	EmailAutomation,
	undefined
>('PostEmailAutomation');

export function postEmailAutomation(
	emailAutomations: EmailAutomation
): ReduxAction {
	return PostEmailAutomation.started(emailAutomations);
}

export function postEmailAutomationSuccess(
	emailAutomations: EmailAutomation
): ReduxAction {
	return PostEmailAutomation.done({
		params: emailAutomations,
		result: undefined,
	});
}

export function postEmailAutomationFailure(
	emailAutomations: EmailAutomation,
	error: any
): ReduxAction {
	return PostEmailAutomation.failed({ params: emailAutomations, error: error });
}
export const StoreEmailEvents = emailAutomationActionCreator<EmailAutomationEvent[]>('STORE_EMAIL_EVENTS')

export const mapEmailAutomationToEvents = (emailAutomation: EmailAutomationResponse): EmailAutomationEvent[] => {
	const events: EmailAutomationEvent[] = [];
	Object.keys(emailAutomation).forEach(scope => {
		Object.keys(emailAutomation[scope]).forEach(group => {
			emailAutomation[scope][group].forEach(evt => events.push({
				...evt,
				eventGroup: group as Strings.EmailGroup,
				scope: scope as Strings.TemplateType,
			}))
		})
	});
	return events;
};

export const mapEventsToEmailAutomationGroup = (events: EmailAutomationEvent[], scope: Strings.TemplateType): EmailAutomationGroup => ({
	Active: events.filter(e => e.eventGroup == Strings.EmailGroup.active && e.scope == scope),
	Lost: events.filter(e => e.eventGroup == Strings.EmailGroup.lost && e.scope == scope),
	Terminated: events.filter(e => e.eventGroup == Strings.EmailGroup.terminated && e.scope == scope),
	Working: events.filter(e => e.eventGroup == Strings.EmailGroup.working && e.scope == scope),
});

export const deconstructAndStoreEmailEvents = (emailAutomationResponse: EmailAutomationResponse) => {
	const events = mapEmailAutomationToEvents(emailAutomationResponse);
	return StoreEmailEvents(events);
}