import React from 'react';
import { Field } from 'redux-form';
import { makeRestrictRange } from '../../utilities/formatting/data_normalizations';
import { enforceStylesType } from '../../utilities/styles_util';
import { FormTextField } from '../../utilities/forms';
const restrictAgeYear = makeRestrictRange({
    min: 0,
    max: 125,
    onlyIntegerValues: true
});

const restrictAgeMonth = makeRestrictRange({
    min: 0,
    max: 11,
    onlyIntegerValues: true
});

interface Props {
    name: string;
    label: string;
}

export const AgeInput: React.SFC<Props> = (props: Props) => {
    const { name, label } = props;
    const styles = getStyles();
    return (
        <React.Fragment>
            <Field
                name={name + 'Year'}
                component={FormTextField}
                type="number"
                normalize={restrictAgeYear}
                style={styles.YearStyle}
                label={label}
                props={{ helperText: "Year" }}
            />
            <Field
                name={name + 'Month'}
                component={FormTextField}
                type="number"
                normalize={restrictAgeMonth}
                style={styles.MonthStyle}
                label={label}
                props={{ helperText: "Month" }}
            />
        </React.Fragment>
    )
}

const getStyles = () => enforceStylesType({
    YearStyle: {
        width: 90,
        paddingRight: 5
    },
    MonthStyle: {
        width: 90
    },
});

