import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { FormTextField,} from '../../utilities/forms';
import { getAppState } from '../..';

interface SettingsConfirmPassword { }

class SettingsPasswordConfirmation extends React.Component<
	SettingsConfirmPassword,
	any
	> {
	render() {
		return (
			<Field name="password" type="password" component={FormTextField} fullWidth />
		);
	}
}

export const SettingsPasswordConfirmationComponent = reduxForm({
	form: 'SettingsPasswordConfirmationForm',
	getFormState: (state) => getAppState().form,
})(SettingsPasswordConfirmation);
