import React from 'react';
import { Field } from 'redux-form';
import {
	Typography,
	Icon,
	Collapse,
	CardActions,
	IconButton,
	FormControl,
	InputLabel,
	TextField,
	FormHelperText,
	Grid,
	FormControlLabel,
	Radio,
	MenuItem,
} from '@material-ui/core';
import { FormSelect, FormRadioGroup} from '../../../utilities/forms';
import { connect, RiseTextField } from '@optum-uhone-hmkts/rise';
import { getCurrentAgentCode } from '../../../selectors/agent_selectors';
import { change, getFormValues } from 'redux-form';
import {
	SearchAgent,
	SearchFilterFormValues,
} from '../../../reducers/advanced_search_reducer';
import jwt_auth from '../../../utilities/auth/jwt_auth';
import { ExpandFilterFields } from '../../../actions/advanced_search_actions';
import { AgentSelectorComponent } from '../../../components/agent/agent_selector';
import { DownlineAgent } from '../../../reducers/product_reducer';
import { themePalette } from '../../../utilities/branding';
import { isAgentRole } from '../../../utilities/agent_util';
import { P } from '../../../utilities/auth/permissions';
import { Strings } from '../../../assets/common/strings';
import { fullName } from '../../../assets/common/string_builders';
import { AppState } from '../../../reducers';

interface Props {
	formName: string;
	consumerType: string;
}

interface StateProps {
	isAdmin: boolean;
	formValues: SearchFilterFormValues;
	currentAgentCode: string;
	agencies: { [key: string]: string[] };
	agentCommonExpanded: boolean;
	agentAdvancedExpanded: boolean;
}

interface DispatchProps {
	changeFieldValue: (field: string, value: any) => void;
	expandFilterFields: (filterFieldGroup: string) => void;
}

interface State {
	agentDialogVisible: boolean;
}

class _AgentFields extends React.PureComponent<
	Props & StateProps & DispatchProps,
	State
	> {
	constructor(props: any) {
		super(props);
		this.state = {
			agentDialogVisible: false,
		};
	}

	handleExpand = (section: string) => () => {
		this.props.expandFilterFields(section);
	};

	handleSaveAgentSearch = (agent: DownlineAgent) => {
		if (agent) {
			const agentName = fullName(agent);
			const searchAgent: SearchAgent = {
				id:agent.agentCode,
				name: agentName,
			};
			this.setState({ agentDialogVisible: false });
			this.props.changeFieldValue('agent', searchAgent);
		}
	};

	handleCancelAgentSearch = () => {
		this.setState({ agentDialogVisible: false });
	};

	componentWillReceiveProps(nextProps: Props & StateProps & DispatchProps) {
		if (
			this.props.consumerType === Strings.ASearch.BLeads &&
			nextProps.consumerType !== Strings.ASearch.BLeads
		) {
			this.props.changeFieldValue('agent', null);
			this.props.changeFieldValue('agentStatus', null);
		}
	}

	resetAgentAdvanced = () => {
		this.props.changeFieldValue('agency', null);
		this.props.changeFieldValue('channel', null);
	};

	updateChannel = (agency: string, channel: string) => () => {
		this.props.changeFieldValue('agency', agency);
		this.props.changeFieldValue('channel', channel);
	};

	render() {
		const { agencies, formValues } = this.props;

		let agentName: string = '';
		if (
			formValues &&
			formValues.agent &&
			formValues.agent.name
		) {
			agentName = formValues.agent.name;
		}

		const areAgentFieldsEditable: boolean =
			jwt_auth.hasPermission(P.AdvancedSearchFilterEdit)
			|| this.props.consumerType === Strings.ASearch.BLeads;

		return (
			<div>
				<Typography
					variant="h5"
					style={{ paddingLeft: 16, paddingTop: 16 }}
				>
					{Strings.AboutMeLabels.Agent}
				</Typography>
				<div onClick={this.handleExpand('agentCommonExpanded')} style={styles.expandable}>
					<CardActions style={{ marginBottom: '0px' }}>
						<span
							style={{
								fontSize: '20px',
								color: themePalette.sub_text,
								paddingLeft: 10,
							}}
						>
							Common
						</span>
						<IconButton
							style={
								this.props.agentCommonExpanded
									? styles.expandOpen
									: styles.expand
							}
						>
							<Icon>keyboard_arrow_down</Icon>
						</IconButton>
					</CardActions>
				</div>
				<Collapse
					in={this.props.agentCommonExpanded}
					style={styles.collapsable}
				>
					<div style={styles.addBottomMargin}>
						<FormControl fullWidth>
							<Field
								name="agentStatus"
								component={FormSelect}
								children={['', Strings.AgentChannelStatus.Active, Strings.AgentChannelStatus.Terminated].map(option => (
									<MenuItem value={option}>{option}</MenuItem>
								))}
								fullWidth
								disabled={!areAgentFieldsEditable}
								label={'Agent Status'}
							/>
							{!areAgentFieldsEditable ? (
								<FormHelperText>
									Cannot search by Agent Status until B-Lead Consumer Type is
									selected
								</FormHelperText>
							) : (
									undefined
								)}
						</FormControl>
					</div>
					<Grid container>
						<Grid item xs={11}>
							<div
								style={{
									...styles.addBottomMargin,
									// pointerEvents: !areAgentFieldsEditable ? 'none' : 'auto',
								}}
							>
								<TextField
									id="Agent Name"
									value={agentName}
									label="Agent Name"
									fullWidth
									onClick={() => {
										if (areAgentFieldsEditable) {
											this.setState({ agentDialogVisible: true });
										}
									}}
									disabled={!areAgentFieldsEditable}
									helperText={
										!areAgentFieldsEditable
											? 'Cannot search by Agent Name until B-Lead Consumer Type is selected'
											: undefined
									}
								/>
							</div>
						</Grid>
						<Grid item xs={1} style={{ marginBottom: -5, marginTop: 5 }}>
							<IconButton
								key="remove-agent-icon-button"
								onClick={() => this.props.changeFieldValue('agent', null)}
								disabled={!areAgentFieldsEditable}
							>
								<Icon>cancel</Icon>
							</IconButton>
						</Grid>
					</Grid>
				</Collapse>

				{this.props.isAdmin && <div>
					<div onClick={this.handleExpand('agentAdvancedExpanded')} style={styles.expandable}>
						<CardActions style={{ marginBottom: '0px' }}>
							<span
								style={{
									fontSize: '20px',
									color: themePalette.sub_text,
									paddingLeft: 10,
								}}
							>
								Advanced
							</span>
							<IconButton
								style={
									this.props.agentAdvancedExpanded
										? styles.expandOpen
										: styles.expand
								}
							>
								<Icon>keyboard_arrow_down</Icon>
							</IconButton>
						</CardActions>
					</div>
					<Collapse
						in={this.props.agentAdvancedExpanded}
						style={styles.collapsable}
					>
						<FormControl component="fieldset" fullWidth>
							<span
								style={{
									fontSize: '16px',
									color: themePalette.sub_text,
									paddingTop: 20,
								}}
							>
								Search By
							</span>
							<Field name="searchBy" component={FormRadioGroup} onChange={this.resetAgentAdvanced}>
								<FormControlLabel
									value={'all'}
									control={<Radio />}
									label={'All'}
								/>
								<FormControlLabel
									value={'me'}
									control={<Radio />}
									label={'Me'}
								/>
								<FormControlLabel
									value={'agency'}
									control={<Radio />}
									label={'Agency/Channel'}
								/>
							</Field>
							{
								this.props.formValues?.searchBy === 'agency' && <div
									style={{
										border: `2px solid ${themePalette.sub_text}`,
										fontSize: '14px',
										color: themePalette.sub_text,
										paddingLeft: 20,
									}}
								>
									{Object.keys(agencies).filter(agency => agency.length > 0).map(agency => (
										<span key={agency}>
											<Field name="agency" component={FormRadioGroup}>
												<FormControlLabel
													value={agency}
													control={<Radio />}
													label={agency}
													onClick={this.updateChannel(agency, '')}
												/>
											</Field>
											<Field name="channel" component={FormRadioGroup}>
												{agencies[agency].map(channel => (
													<FormControlLabel
														key={`${agency}_${channel}`}
														value={channel}
														control={<Radio />}
														label={channel}
														style={{ paddingLeft: 20 }}
														onClick={this.updateChannel(agency, channel)}
													/>
												))}
											</Field>
										</span>
									))}
								</div>
							}
						</FormControl>
					</Collapse>
				</div>}

				<AgentSelectorComponent
					visible={this.state.agentDialogVisible}
					currentAgentCode={this.props.currentAgentCode}
					onSave={this.handleSaveAgentSearch}
					onCancel={this.handleCancelAgentSearch}
					headerText="Agent Search"
					saveText="Select"
					downlineOnly={!jwt_auth.hasPermission(P.AdvancedSearchFilterEdit)}
					searchTerminated={this.props.formValues?.agentStatus == Strings.AgentChannelStatus.Terminated}
				/>
			</div>
		);
	}
}

const styles: { [key: string]: React.CSSProperties } = {
	expandable: {
		cursor: 'pointer',
	},
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
	},
	expandOpen: {
		transform: 'rotate(180deg)',
		marginLeft: 'auto',
	},
	collapsable: {
		padding: '0 20px',
	},
	listStyle: {
		listStyleType: 'disc',
		margin: 0,
	},
};

const mapStateToProps = (state: AppState, ownProps: Props): StateProps => {
	
	const expandedStates = state.advancedSearch.expandedStates;

	const { agencyChannels } = state.agent;
	const agencies: { [key: string]: string[] } = {};
	agencyChannels.forEach(({ agency, activeChannel }) => {
		const channels = agencies[agency] || [];
		channels.push(activeChannel);
		agencies[agency] = channels;
	});

	if (!Object.keys(agencies).length) {
		if (state.agent.agency) {
			agencies[state.agent.agency] = state.agent.activeChannel
				? [state.agent.activeChannel]
				: [];
		} else {
			agencies['No associated agencies'] = [];
		}
	}

	const isAdmin = isAgentRole([Strings.ADRoles.Operations, Strings.ADRoles.Admin]);

	return {
		isAdmin,
		formValues: getFormValues(ownProps.formName)(state),
		currentAgentCode: getCurrentAgentCode(state),
		agencies,
		agentCommonExpanded: expandedStates.agentCommonExpanded,
		agentAdvancedExpanded: expandedStates.agentAdvancedExpanded,
	};
};

const mapDispatchToProps = (dispatch: any, ownProps: Props): DispatchProps => ({
	changeFieldValue: (field: string, value: any) => {
		dispatch(change(ownProps.formName, field, value));
	},
	expandFilterFields: (filterFieldGroup: string) =>
		dispatch(ExpandFilterFields(filterFieldGroup)),
});

export const AgentFields = connect(mapStateToProps, mapDispatchToProps, true)(
	_AgentFields
);