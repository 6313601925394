import { Strings } from '../assets/common/strings';
import {actionCreatorFactory} from 'typescript-fsa';
import { Template, BulkEmail, TemplatePreview, EmailTemplateFilter, TemplateTypeOptions } from '../reducers/template_reducer';

const STA = Strings.TemplateActions;
const TemplateActionFactory = actionCreatorFactory(STA.FactoryName);

export const GetTemplateById = TemplateActionFactory.async<string, Template>(
	STA.GetTemplateById
);
export const GetTemplateWithSubstitutionsById = TemplateActionFactory.async<string, null>(
	STA.GetTemplateWithSubstitutionsById
);
export const GetAgentTemplates = TemplateActionFactory.async<undefined, Template[]>(
	STA.GetAgentTemplates
);
export const GetCorporateTemplates = TemplateActionFactory.async<undefined, Template[]>(
	STA.GetCorporateTemplates
);
export const DeleteTemplate = TemplateActionFactory.async<string, string>(
	STA.DeleteTemplate
);
export const SendBulkEmail = TemplateActionFactory.async<BulkEmail, string>(
	STA.SendBulk
);
export const PostTemplate = TemplateActionFactory.async<Template, Template>(
	STA.PostTemplate
);
export const GetTemplateSubstitutions = TemplateActionFactory.async<TemplatePreview, string>(
	STA.GetSubs
);
export const StoreTemplateFilter = TemplateActionFactory<EmailTemplateFilter>(STA.StoreTemplateFilters);

export const clearTemplateFilter = () => StoreTemplateFilter({
	linesOfBusiness: [],
	emailEventTypeCategory: [],
	emailType: TemplateTypeOptions.all,
});

export const StoreEditedTemplate = TemplateActionFactory<Template>(STA.StoreEdit);
export const ClearEditedTemplate = TemplateActionFactory(STA.ClearEdit);
export const ClearTemplateStores = TemplateActionFactory(STA.ClearStore);
export const SetTablePage = TemplateActionFactory<number>(STA.SetTablePage);
export const SetRowsPerPage = TemplateActionFactory<number>(STA.SetRowsPerPage);
