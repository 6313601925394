import React from 'react';
import { Divider, Grid } from '@material-ui/core';
import { ReadOnlyTextField } from '../utility/read_only_text_field';
import {
	Address,
	Contact,
	mapEnumToHouseholdRole,
} from '../../reducers/ContactReducer';
import { Strings } from '../../assets/common/strings';
import { findWithFallbacks, isFirst } from '../../utilities/array_util';
import { normalizePhone } from '../../utilities/formatting/data_normalizations';
import { wrapInCollapsibleCard } from '../higher_order_component/wrap_in_card';


const renderContactList = (contacts: Contact[]) => {
	let contactPhone: string;
	let phoneLabel: string;
	let address: Address;
	let email: string;

	return contacts.map((contact: Contact, index: number) => {
		const bestContactAddress = contact.addresses.find(address => address.isPreferred);
		if (bestContactAddress) {
			address = bestContactAddress;
		} else {
			address = {} as Address;
		}

		const bestContactPhone = findWithFallbacks(contact.phones,
			(phone) => phone.isPreferred,
			(phone) => phone.type === Strings.PhoneTypes.Home,
			(phone) => phone.type === Strings.PhoneTypes.Mobile,
			(phone) => phone.type === Strings.PhoneTypes.Business,
			(phone) => phone.type === Strings.PhoneTypes.Alternate
		);
		if (bestContactPhone) {
			contactPhone = normalizePhone(bestContactPhone.number);
			phoneLabel = bestContactPhone.type + ' Phone';
		} else {
			contactPhone = '';
			phoneLabel = 'Phone';
		}

		const bestContactEmail = findWithFallbacks(contact.emails,
			(email) => email.isPreferred,
			isFirst
		);
		if (bestContactEmail) {
			email = bestContactEmail.emailAddress;
		} else {
			email = '';
		}

		return (
			<React.Fragment key={contact.id}>
				<Grid container>
					<Grid item xs={12} sm={6}>
						<ReadOnlyTextField
							label={'Name'}
							value={contact.firstName + ' ' + contact.lastName}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<ReadOnlyTextField
							label={'Type'}
							value={mapEnumToHouseholdRole(contact.householdRole)}
						/>
					</Grid>
					<Grid item xs={12}>
						<ReadOnlyTextField
							label="Street Address"
							value={(address.line1 || '') + ' ' + (address.line2 || '')}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<ReadOnlyTextField label="City" value={address.city || ''} />
					</Grid>
					<Grid item xs={12} sm={6}>
						<ReadOnlyTextField label="State" value={address.state || ''} />
					</Grid>
					<Grid item xs={12}>
						<ReadOnlyTextField label="Zip Code" value={address.zipCode || ''} />
					</Grid>
					<Grid item xs={12} sm={6}>
						<ReadOnlyTextField label={phoneLabel} value={contactPhone} />
					</Grid>
					<Grid item xs={12} sm={6}>
						<ReadOnlyTextField label={'Email'} value={email} />
					</Grid>
				</Grid>
				{index + 1 !== contacts.length && (
					<Grid container>
						<Grid item xs={12}>
							<br />
							<Divider />
							<br />
						</Grid>
					</Grid>
				)}
			</React.Fragment>
		);
	});
}


interface Props {
	contacts: Contact[];
}
const PrimaryInsuredInfo: React.FC<Props> = (props) => {
	return (
		<Grid container>
			{renderContactList(props.contacts)}
		</Grid>
	);
};

export const PrimaryInsuredInfoCard = wrapInCollapsibleCard(PrimaryInsuredInfo);
