import { Action } from 'typescript-fsa';
import { call, put, all } from 'redux-saga/effects';
import { takeEveryForActionType } from '../utilities/saga_util';
import http, { HttpOptions } from '../utilities/http';
import { ReshopResultsRequest, ReshopResultsResponse, ReshopTableData } from '../reducers/reshop_reducer';
import { getSnackbarErrorProps, getSnackbarInfoProps, getSnackbarSuccessProps } from '../utilities/snackbar_util';
import { AgentEnrollmentSettings, RecommendationStatusRequest } from '../reducers/reshop_reducer';
import {
	getReshopResultsByAgentId,
	getReshopSettings,
	updateReshopSettings,
	updateReshopRecommendationStatus,
} from '../actions/reshop_actions';
import { QueueSnackbar } from '../actions/snackbar_actions';

function* mockApiCallHandler(action: Action<ReshopResultsRequest>) {
	const successSnackbarProps = getSnackbarInfoProps('Using agent id: ' + action.payload);
	yield new Promise((resolve) => setTimeout(resolve, 1000));
	yield put(
		getReshopResultsByAgentId.done({
			params: action.payload,
			result: {
				currentPage: 1,
				pageSize: 20,
				results: [],
				totalCount: 0,
			},
		})
	);
	yield put(QueueSnackbar(successSnackbarProps));
}

function* getReshopResultsByAgentCodeHandler(action: Action<ReshopResultsRequest>) {
	const errorSnackbarProps = getSnackbarErrorProps('Failed to load reshop results');
	try {
		const response = yield call(getReshopResultsByAgentCodeRequest, action.payload);
		if (response.ok) {
			const summaries: ReshopResultsResponse = yield response.json();
			yield put(getReshopResultsByAgentId.done({ params: action.payload, result: summaries }));
		} else {
			yield put(QueueSnackbar(errorSnackbarProps));
			yield put(
				getReshopResultsByAgentId.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
		}
	} catch (error) {
		yield put(QueueSnackbar(errorSnackbarProps));
		yield put(getReshopResultsByAgentId.failed({ params: action.payload, error }));
	}
}

function getReshopResultsByAgentCodeRequest(requestOptions: ReshopResultsRequest) {
	const reshopResultsUrl =
		'reshop/reshopresults?agentId=' + requestOptions.agentId + '&statusType=' + requestOptions.statusType;
	const paginationData = {
		index: requestOptions.page,
		size: requestOptions.pageSize,
	};
	return http(reshopResultsUrl, undefined, paginationData);
}

function* putReshopSettingsHandler(action: Action<AgentEnrollmentSettings>) {
	const successSnackbarProps = getSnackbarSuccessProps('ReShop settings successfully updated');
	const errorSnackbarProps = getSnackbarErrorProps('Failed to update ReShop settings');
	try {
		const response = yield call(putReshopDashboardSettings, action.payload);
		if (response.ok) {
			const settings: AgentEnrollmentSettings = yield response.json();
			yield put(updateReshopSettings.done({ params: action.payload, result: settings }));
			yield put(QueueSnackbar(successSnackbarProps));
		} else {
			yield put(QueueSnackbar(errorSnackbarProps));
			yield put(
				updateReshopSettings.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
		}
	} catch (error) {
		yield put(QueueSnackbar(errorSnackbarProps));
		yield put(updateReshopSettings.failed({ params: action.payload, error }));
	}
}

function putReshopDashboardSettings(dashboardSettings: AgentEnrollmentSettings) {
	const options: HttpOptions = {
		method: 'PUT',
		body: JSON.stringify(dashboardSettings),
	};

	return http('reshop/agentdashboardsettings', options);
}
function* putRecommendationStatusHandler(action: Action<RecommendationStatusRequest>) {
	const successSnackbarProps = getSnackbarSuccessProps('Recommendation successfully updated');
	const errorSnackbarProps = getSnackbarErrorProps(
		`Failed to ${action.payload.isAccepted ? 'accept' : 'reject'} recommendation`
	);
	try {
		const response = yield call(putRecommendationStatus, action.payload.reshopId, action.payload.isAccepted);
		if (response.ok) {
			const settings: ReshopTableData = yield response.json();
			yield put(updateReshopRecommendationStatus.done({ params: action.payload, result: settings }));
			yield put(QueueSnackbar(successSnackbarProps));
		} else {
			yield put(QueueSnackbar(errorSnackbarProps));
			yield put(
				updateReshopRecommendationStatus.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
		}
	} catch (error) {
		yield put(QueueSnackbar(errorSnackbarProps));
		yield put(updateReshopRecommendationStatus.failed({ params: action.payload, error }));
	}
}

function putRecommendationStatus(reshopId: string, accepted: boolean) {
	const options: HttpOptions = {
		method: 'PUT',
	};

	return http(`reshop/saveagentreshopresult/${reshopId}/${accepted}`, options);
}

function* getReshopSettingsHandler(action: Action<string>) {
	const errorSnackbarProps = getSnackbarErrorProps('Failed to retrieve reshop settings');
	try {
		const response = yield call(getReshopDashboardSettings, action.payload);
		if (response.ok) {
			const statusResponse: AgentEnrollmentSettings = yield response.json();
			yield put(getReshopSettings.done({ params: action.payload, result: statusResponse }));
		} else {
			yield put(QueueSnackbar(errorSnackbarProps));
			yield put(
				getReshopSettings.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
		}
	} catch (error) {
		yield put(QueueSnackbar(errorSnackbarProps));
		yield put(getReshopSettings.failed({ params: action.payload, error }));
	}
}

function getReshopDashboardSettings(agentId: string) {
	return http('reshop/agentdashboardsettings?agentId=' + agentId);
}

export function* reshopSagas() {
	yield all([
		takeEveryForActionType(getReshopResultsByAgentId.started, getReshopResultsByAgentCodeHandler),
		takeEveryForActionType(updateReshopSettings.started, putReshopSettingsHandler),
		takeEveryForActionType(getReshopSettings.started, getReshopSettingsHandler),
		takeEveryForActionType(updateReshopRecommendationStatus.started, putRecommendationStatusHandler),
	]);
}
