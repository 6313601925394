import actionCreatorFactory, {
	AsyncActionCreators,
} from 'typescript-fsa';
import { DownlineAgent } from '../reducers/product_reducer';
import { ChartGroup } from '../reducers/performance_reducer';

const actionCreator = actionCreatorFactory('Finance');

export interface FinanceParams {
	startDate: string;
	endDate: string;
	agentCode?: string;
	dateRangeOverride?: number;
}

export type FinanceActionCreator = AsyncActionCreators<
	FinanceParams,
	ChartGroup,
	any
>;

export const UpdateFinanceFilter = actionCreator<{ filter: string }>(
	'UPDATE_FINANCE_FILTER'
);

export const SetFinanceDownlineAgent = actionCreator<{
	agent: DownlineAgent | undefined;
}>('SET_FINANCE_DOWNLINE_AGENT');

export const UpdateFinanceDates = actionCreator<{
	params: FinanceParams;
}>('UPDATE_FINANCE_DATES');

export const GetAllFinanceData = actionCreator.async<
	FinanceParams,
	void
>('GET_ALL_FINANCE_DATA');

export const GetFinanceCash = actionCreator.async<
	FinanceParams,
	ChartGroup
>('GET_FINANCE_CASH');

export const GetFinanceInvestments = actionCreator.async<
	FinanceParams,
	ChartGroup
>('GET_FINANCE_INVESTMENTS');

export const GetFinanceBalances = actionCreator.async<
	FinanceParams,
	ChartGroup
>('GET_FINANCE_BALANCES');

