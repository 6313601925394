import React from 'react';
import { reduxForm, DecoratedFormProps } from 'redux-form';
import { Address, Contact, Phone } from '../../reducers/ContactReducer';
import {
	BasicInfoFields,
	BasicInfoFieldsData,
} from '../lead_upsert/basic_info_fields';
import {
	ContactFields,
	ContactFieldsData,
} from '../lead_upsert/contact_fields';
import {
	HouseholdRoleField,
	HouseholdRoleFieldData,
} from '../../components/contact/household_role_fields';
import { getAppState } from '../..';
export const FORM_NAME = 'UpsertContactForm';

export type ContactData = BasicInfoFieldsData &
	ContactFieldsData &
	HouseholdRoleFieldData;

interface OwnProps {
	isEmployee?: boolean;
	primaryAddress?: Address;
	contact?: Contact;
	contacts?: Contact[];
	isNewContact?: boolean;
}

export type Props = OwnProps & DecoratedFormProps<ContactData, any, any>;

const _UpsertContactForm: React.FC<Props> = (
	props: Props
) => {
	return (
		<form>
			<HouseholdRoleField
				hidden={props.isEmployee}
				contact={props.contact}
				contacts={props.contacts}
			/>
			<BasicInfoFields formName={FORM_NAME} employee={props.isEmployee} />
			<ContactFields
				formName={FORM_NAME}
				primaryAddress={props.primaryAddress}
				isNewContact={props.isNewContact}
				isEmployee={props.isEmployee}
			/>
		</form>
	);
};

export const UpsertContactForm = reduxForm<ContactData, OwnProps>({
	form: FORM_NAME,
	enableReinitialize: true,
	getFormState: (state) => getAppState(state).form,
})(_UpsertContactForm);
