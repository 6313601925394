import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import React, { useEffect, useState } from 'react';
import { MultiText } from '../redux_form_material/multi_text';
import { WideButton } from '../utility/wide_button';

interface Props {
    tags?: string[];
    saveTags?: (tags: string[]) => void;
}

export const TagsList: React.FC<Props> = (props) => {

    const { tags=[], saveTags } = props;

    const [chips, setChips] = useState(tags);
    const [edit, setEdit] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);
    useEffect(() => setChips(tags), [props.tags]);
    const handleAdd = (tag: string) => {
        setChips(chips.filter(chip => chip !== tag).concat(tag));
        setHasChanged(true);
    };
    const handleRemove = (tag: string) => {
        setChips(chips.filter(chip => chip !== tag));
        setHasChanged(true);
    };

    const onSave = () => {
        if (saveTags) {
            saveTags(chips);
        }
        onClose();
        setHasChanged(false);
    };

    const onClose = () => setEdit(false);

    return (
        <>
            <WideButton
                key="tags-button"
                primary={'Tags'}
                onClick={() => setEdit(true)}
                icon={<Icon>create</Icon>}
            >
                <Grid container direction="row" style={{ paddingLeft: 14, paddingRight: 14, paddingBottom: tags?.length ? 8 : 0 }}>
                    {tags.map((tag, index) => (
                        <Grid key={index} item style={{ paddingTop: 4, paddingLeft: 2, paddingRight: 2 }}>
                            <Chip
                                label={tag}
                                size="small"
                            />
                        </Grid>
                    ))}
                </Grid>
            </WideButton>
            <Dialog
                open={edit}
                onClose={onClose}
            >
                <DialogTitle>Tags</DialogTitle>
                <DialogContent>
                    Add Tags to the household/employer to quickly find them later.

                    <MultiText
                        addValue={handleAdd}
                        removeValue={handleRemove}
                        values={chips}
                        allowEnterKey
                    />

                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onSave}
                        disabled={!hasChanged}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );

};
