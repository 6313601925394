import React, { ChangeEvent } from 'react';
import { Field } from 'redux-form';
import { FormSelect, FormTextField } from '../../../utilities/forms';
import {
	Typography,
	Icon,
	Collapse,
	CardActions,
	IconButton,
	FormControl,
	InputLabel,
	MenuItem,
} from '@material-ui/core';
import { connect } from '@optum-uhone-hmkts/rise';
import { MinMax, MultiSelect } from '../../../components/redux_form_material';
import ToFromPickers from '../../../components/redux_form_material/to_from_pickers';
import { SearchFilterFormValues } from '../../../reducers/advanced_search_reducer';
import { change, getFormValues } from 'redux-form';
import moment from 'moment';
import _ from 'lodash';
import { ExpandFilterFields } from '../../../actions/advanced_search_actions';
import { themePalette } from '../../../utilities/branding';
import { Lookups } from '../../../utilities/lookup';
import { Strings } from '../../../assets/common/strings';
import { getHouseholdTags } from '../../../actions/household_actions';
import { AppState } from '../../../reducers';


interface IProps {
	formName: string;
	onlyCommon?: boolean;
	onlyConsumerSearch?: boolean;
	tagSearch?: boolean;
}
interface StateProps {
	formValues: SearchFilterFormValues;
	lineOfBusinessList: string[];
	leadStatusCodes: string[];
	leadStatuses: string[];
	leadStatusReasons: string[];
	leadTypeList: string[];
	vendorList: string[];
	callAttemptList: string[];
	leadsCommonExpanded: boolean;
	leadsAdvancedExpanded: boolean;
	leadTagFilterResult: string[];
}

interface DispatchProps {
	changeFieldValue: (form: string, field: string, value: any) => void;
	expandFilterFields: (filterFieldGroup: string) => void;
	getHouseholdTags: (search: string) => void;
}

type Props = IProps & StateProps & DispatchProps;

class _LeadFields extends React.Component<Props, {}> {
	constructor(props: Props) {
		super(props);
	}

	handleExpandCommonClick = () => {
		this.props.expandFilterFields('leadsCommonExpanded');
	};

	handleExpandAdvancedClick = () => {
		this.props.expandFilterFields('leadsAdvancedExpanded');
	};

	handleClickLeadCreatedSelect = (_event: any, value: string) => {
		const fromDate = moment().startOf('day');
		const toDate = moment().endOf('day');
		if (value === 'Created Anytime') {
			this.props.changeFieldValue(
				this.props.formName,
				'leadCreatedDateFrom',
				null
			);
			this.props.changeFieldValue(
				this.props.formName,
				'leadCreatedDateTo',
				null
			);
		} else if (value === 'Created Today') {
			this.props.changeFieldValue(
				this.props.formName,
				'leadCreatedDateFrom',
				fromDate
			);
			this.props.changeFieldValue(
				this.props.formName,
				'leadCreatedDateTo',
				toDate
			);
		} else if (value === 'Created in last 7 days') {
			this.props.changeFieldValue(
				this.props.formName,
				'leadCreatedDateFrom',
				fromDate.subtract(7, 'days')
			);
			this.props.changeFieldValue(
				this.props.formName,
				'leadCreatedDateTo',
				toDate
			);
		} else if (value === 'Created in last 30 Days') {
			this.props.changeFieldValue(
				this.props.formName,
				'leadCreatedDateFrom',
				fromDate.subtract(30, 'days')
			);
			this.props.changeFieldValue(
				this.props.formName,
				'leadCreatedDateTo',
				toDate
			);
		}
	};

	handleClickDatePickerLeadCreated = () => {
		this.props.changeFieldValue(
			this.props.formName,
			'leadCreatedDateSearch',
			'Custom'
		);
	};

	handleClickLeadContactSelect = (_event: any, value: string) => {
		const fromDate = moment().startOf('day');
		const toDate = moment().endOf('day');
		if (value === 'Contacted Anytime') {
			this.props.changeFieldValue(
				this.props.formName,
				'leadContactedDateFrom',
				null
			);
			this.props.changeFieldValue(
				this.props.formName,
				'leadContactedDateTo',
				null
			);
		} else if (value === 'Contacted Today') {
			this.props.changeFieldValue(
				this.props.formName,
				'leadContactedDateFrom',
				fromDate
			);
			this.props.changeFieldValue(
				this.props.formName,
				'leadContactedDateTo',
				toDate
			);
		} else if (value === 'Contacted in last 7 Days') {
			this.props.changeFieldValue(
				this.props.formName,
				'leadContactedDateFrom',
				fromDate.subtract(7, 'days')
			);
			this.props.changeFieldValue(
				this.props.formName,
				'leadContactedDateTo',
				toDate
			);
		} else if (value === 'Contacted in last 30 Days') {
			this.props.changeFieldValue(
				this.props.formName,
				'leadContactedDateFrom',
				fromDate.subtract(30, 'days')
			);
			this.props.changeFieldValue(
				this.props.formName,
				'leadContactedDateTo',
				toDate
			);
		}
	};

	handleClickDatePickerLeadContact = () => {
		this.props.changeFieldValue(
			this.props.formName,
			'leadContactedDateSearch',
			'Custom'
		);
	};

	handleCallAttemptsSelect = (_event: any, value: string) => {
		this.props.changeFieldValue(
			this.props.formName,
			'callAttempts',
			value
		);
	};

	handleClickOpenToDoDateSelect = (_event: any, value: string) => {
		const fromDate = moment().startOf('day');
		const toDate = moment().endOf('day');
		if (value === 'Due Anytime') {
			this.props.changeFieldValue(
				this.props.formName,
				'openToDoInNextDaysFrom',
				null
			);
			this.props.changeFieldValue(
				this.props.formName,
				'openToDoInNextDaysTo',
				null
			);
		} else if (value === 'Past Due') {
			this.props.changeFieldValue(
				this.props.formName,
				'openToDoInNextDaysFrom',
				null
			);
			this.props.changeFieldValue(
				this.props.formName,
				'openToDoInNextDaysTo',
				moment()
			);
		} else if (value === 'Due Today') {
			this.props.changeFieldValue(
				this.props.formName,
				'openToDoInNextDaysFrom',
				fromDate
			);
			this.props.changeFieldValue(
				this.props.formName,
				'openToDoInNextDaysTo',
				toDate
			);
		} else if (value === 'Due Tomorrow') {
			this.props.changeFieldValue(
				this.props.formName,
				'openToDoInNextDaysFrom',
				fromDate.add(1, 'days')
			);
			this.props.changeFieldValue(
				this.props.formName,
				'openToDoInNextDaysTo',
				toDate.add(1, 'days')
			);
		} else if (value === 'Due in Next 7 Days') {
			this.props.changeFieldValue(
				this.props.formName,
				'openToDoInNextDaysFrom',
				fromDate
			);
			this.props.changeFieldValue(
				this.props.formName,
				'openToDoInNextDaysTo',
				toDate.add(7, 'days')
			);
		} else if (value === 'Due in Next 30 Days') {
			this.props.changeFieldValue(
				this.props.formName,
				'openToDoInNextDaysFrom',
				fromDate
			);
			this.props.changeFieldValue(
				this.props.formName,
				'openToDoInNextDaysTo',
				toDate.add(30, 'days')
			);
		}
	};

	handleClickDatePickerOpenToDoDate = () => {
		this.props.changeFieldValue(
			this.props.formName,
			'openToDoInNextDaysSearch',
			'Custom'
		);
	};

	leadCreatedDropDownOptions = () => {
		let options: string[] = [
			'Created Anytime',
			'Created Today',
			'Created in last 7 days',
			'Created in last 30 Days',
			'Custom',
		];

		// Add back-end generated options on Saved Searches thru formValues
		if (
			this.props.formValues &&
			this.props.formValues.leadCreatedDateSearch &&
			!_.includes(options, this.props.formValues.leadCreatedDateSearch)
		) {
			options = options.concat(this.props.formValues.leadCreatedDateSearch);
		}

		return options;
	};

	leadContactedDropDownOptions = () => {
		let options: string[] = [
			'Contacted Anytime',
			'Contacted Today',
			'Contacted in last 7 Days',
			'Contacted in last 30 Days',
			'Custom',
		];

		// Add back-end generated options on Saved Searches thru formValues
		if (
			this.props.formValues &&
			this.props.formValues.leadContactedDateSearch &&
			!_.includes(options, this.props.formValues.leadContactedDateSearch)
		) {
			options = options.concat(this.props.formValues.leadContactedDateSearch);
		}

		return options;
	};

	openToDoDropDownOptions = () => {
		let options: string[] = [
			'Due Anytime',
			'Past Due',
			'Due Today',
			'Due Tomorrow',
			'Due in Next 7 Days',
			'Due in Next 30 Days',
			'Custom',
		];

		// Add back-end generated options on Saved Searches thru formValues
		if (
			this.props.formValues &&
			this.props.formValues.openToDoInNextDaysSearch &&
			!_.includes(options, this.props.formValues.openToDoInNextDaysSearch)
		) {
			options = options.concat(this.props.formValues.openToDoInNextDaysSearch);
		}

		return options;
	};

	render() {
		const { leadTagFilterResult } = this.props;

		return (
			<div>
				<Typography
					variant="h5"
					style={{ paddingLeft: 16, paddingTop: 16 }}
				>
					{'Leads'}
				</Typography>
				<div onClick={this.handleExpandCommonClick} style={styles.pointer}>
					<CardActions style={{ marginBottom: '0px' }}>
						<span
							style={{
								fontSize: '20px',
								color: themePalette.sub_text,
								paddingLeft: 10,
							}}
						>
							Common
						</span>
						<IconButton
							style={
								this.props.leadsCommonExpanded
									? styles.expandOpen
									: styles.expand
							}
						>
							<Icon>keyboard_arrow_down</Icon>
						</IconButton>
					</CardActions>
				</div>
				<Collapse
					in={this.props.leadsCommonExpanded}
					style={{ padding: '0 20px' }}
				>
					{this.props.tagSearch && <Field
						name={Strings.LeadFieldNames.LeadTags}
						label="Lead Tags"
						component={MultiSelect}
						resultSet={leadTagFilterResult}
						fullWidth
						forceAutoComplete
						onChangeCallback={(value: string) => this.props.getHouseholdTags(value)}
					/>}
					<Field
						name={Strings.LeadFieldNames.LineOfBusiness}
						label="Line of Business"
						component={MultiSelect}
						resultSet={this.props.lineOfBusinessList}
						fullWidth
					/>
					<Field
						name={Strings.LeadFieldNames.CreatedDateSearch}
						label="Lead Created Date"
						component={FormSelect}
						children={this.leadCreatedDropDownOptions().map(
							(option: string) => (
								<MenuItem value={option}>{option}</MenuItem>
							)
						)}
						onChange={this.handleClickLeadCreatedSelect}
					/>
					<ToFromPickers
						name={Strings.LeadFieldNames.CreatedDate}
						onChange={this.handleClickDatePickerLeadCreated}
					/>
					<Field
						label="Last Contacted Date"
						name={Strings.LeadFieldNames.ContactedDateSearch}
						component={FormSelect}
						children={this.leadContactedDropDownOptions().map(
							(option: string) => (
								<MenuItem value={option}>{option}</MenuItem>
							)
						)}
						onChange={this.handleClickLeadContactSelect}
					/>
					<ToFromPickers
						name={Strings.LeadFieldNames.ContactedDate}
						onChange={this.handleClickDatePickerLeadContact}
					/>
					<div style={styles.addBottomMargin}>
						<Field
							name={Strings.LeadFieldNames.StatusCode}
							label="Sale Status"
							component={MultiSelect}
							resultSet={this.props.leadStatusCodes}
							fullWidth
						/>
					</div>
					<div style={styles.addBottomMargin}>
						<Field
							name={Strings.LeadFieldNames.StatusReason}
							label="Status Reason"
							component={MultiSelect}
							resultSet={this.props.leadStatusReasons.sort()}
							fullWidth
						/>
					</div>
					<Field
						label="Callable"
						name={Strings.LeadFieldNames.Callable}
						component={FormSelect}
						children={[
							<MenuItem value="" />,
							<MenuItem value="true">{Strings.YesNoOptionDisplay.Yes}</MenuItem>,
							<MenuItem value="false">{Strings.YesNoOptionDisplay.No}</MenuItem>,
						]}
						fullWidth
					/>
					{this.props.onlyConsumerSearch &&
						<div style={styles.addBottomMargin}>
							<FormControl fullWidth>
								<Field
									name={Strings.LeadFieldNames.CallAttempts}
									component={FormSelect}
									children={['All'].concat(this.props.callAttemptList).map(
										(option: string) => (
											<MenuItem value={option}>{option}</MenuItem>
										)
									)}
									onChange={this.handleCallAttemptsSelect}
									fullWidth
									label={'Call Attempts'}
								/>
							</FormControl>
						</div>}
				</Collapse>
				{!this.props.onlyCommon &&
					<>
						<div onClick={this.handleExpandAdvancedClick} style={styles.pointer}>
							<CardActions style={{ marginBottom: '0px' }}>
								<span
									style={{
										fontSize: '20px',
										color: themePalette.sub_text,
										paddingLeft: 10,
									}}
								>
									Advanced
								</span>
								<IconButton
									style={
										this.props.leadsAdvancedExpanded
											? styles.expandOpen
											: styles.expand
									}
								>
									<Icon>keyboard_arrow_down</Icon>
								</IconButton>
							</CardActions>
						</div>
						<Collapse
							in={this.props.leadsAdvancedExpanded}
							style={{ padding: '0 20px' }}
						>
							<Field
								name={Strings.LeadFieldNames.Type}
								label="Lead Type"
								component={MultiSelect}
								resultSet={this.props.leadTypeList}
								fullWidth
							/>
							<Field
								name={Strings.LeadFieldNames.Vendor}
								label="Lead Vendor"
								component={MultiSelect}
								resultSet={this.props.vendorList.sort()}
								fullWidth
							/>
							<Field
								name={Strings.LeadFieldNames.Campaign}
								component={FormTextField}
								label="Enter Campaign"
								fullWidth
							/>

							<Field
								name={Strings.LeadFieldNames.Fund}
								component={FormTextField}
								label="Enter Fund"
								fullWidth
							/>

							<Field
								name={Strings.LeadFieldNames.LmsId}
								component={FormTextField}
								label="Enter LMS ID"
								fullWidth
							/>

							<Field
								name={Strings.LeadFieldNames.QuoteId}
								component={FormTextField}
								label="Enter Quote ID"
								fullWidth
							/>

							<MinMax name="leadCost" title="Lead Cost" min={0} />
							<Field
								name={Strings.LeadFieldNames.OpenToDoInNextDaysSearch}
								component={FormSelect}
								children={this.openToDoDropDownOptions().map(
									(option: string) => (
										<MenuItem value={option}>{option}</MenuItem>
									)
								)}
								onChange={this.handleClickOpenToDoDateSelect}
								label={'Open To-Do'}
							/>
							<ToFromPickers
								name={Strings.LeadFieldNames.OpenToDoInNextDays}
								onChange={this.handleClickDatePickerOpenToDoDate}
							/>
							<Field
								name={Strings.LeadFieldNames.ManuallyEntered}
								label="Lead Manually Entered"
								component={FormSelect}
								children={['', Strings.YesNoOptionDisplay.Yes, Strings.YesNoOptionDisplay.No].map(option => (
									<MenuItem value={option}>{option}</MenuItem>
								))}
								fullWidth
							/>
						</Collapse>
					</>}
			</div>
		);
	}
}

function mapStateToProps(state: AppState, ownProps: Props): StateProps {
	const { expandedStates, leadTagFilterResult } = state.advancedSearch;

	return {
		formValues: getFormValues(ownProps.formName)(state),
		lineOfBusinessList: state.lookup.getLabels(Lookups.LeadLineOfBusiness),
		leadStatusCodes: state.lookup.getLabels(Lookups.LeadStatusCode),
		leadStatuses: state.lookup.getLabels(Lookups.LeadStatus),
		leadStatusReasons: state.lookup.getLabels(Lookups.LeadStatusReason),
		leadTypeList: state.lookup.getLabels(Lookups.LeadType).sort(),
		vendorList: state.lookup.getLabels(Lookups.Vendor),
		leadsCommonExpanded: expandedStates.leadsCommonExpanded,
		leadsAdvancedExpanded: expandedStates.leadsAdvancedExpanded,
		callAttemptList: state.lookup.getLabels(Lookups.CallAttempts),
		leadTagFilterResult,
	};
}

function mapDispatchToProps(dispatch: any): DispatchProps {
	return {
		changeFieldValue: (form: string, field: string, value: any) =>
			dispatch(change(form, field, value)),
		expandFilterFields: (filterFieldGroup: string) =>
			dispatch(ExpandFilterFields(filterFieldGroup)),
		getHouseholdTags: (search: string) =>
			dispatch(getHouseholdTags.started(search)),
	};
}

export const LeadFields = connect(mapStateToProps, mapDispatchToProps, true)(
	_LeadFields
) as React.ComponentClass<IProps>;

const styles: { [key: string]: React.CSSProperties } = {
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
	},
	expandOpen: {
		transform: 'rotate(180deg)',
		marginLeft: 'auto',
	},
	addBottomMargin: {
		marginBottom: 15,
	},
	pointer: {
		cursor: 'pointer',
	},
};