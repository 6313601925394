import { call, put, all } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import {
	GetAgentFromJwt,
	GetAgentWithAgentId,
	UpdateIconImages,
	UploadAgentIcon,
	UpsertAppointmentWebsite,
	GetAgentHierarchy,
	GetAgencyChannels,
	UpsertTimeZone,
	UploadIconPayload,
	AgentAppointmentWebsite,
	AgentTimeZone,
	UpsertStorefrontAddress,
	UpdateAgentEmailTitle,
	AgentEmailTitlePayload,
	GetSubagentsByAgentCodeNotCached,
	UpdateAgentNeedsProposalApproval,
	AgentNeedsProposalApprovalPayload,
	ConnectureSAMLResponseParams,
	ConnectureSSOredirect,
} from '../actions/agent_actions';
import http, { HttpOptions } from '../utilities/http';
import { submitLog } from '../utilities/logging_util';
import { Agent, AgentHierarchyDto } from '../reducers/agent_reducer';
import { Strings } from '../assets/common/strings';
import { takeLatestForActionType, selectFromImmutable, takeEveryForActionType } from '../utilities/saga_util';
import { getAgentHierarchyLoader } from '../selectors/agent_selectors';
import { getFromCache } from '../utilities/loader_util';
import { Address } from '../reducers/ContactReducer';
import { QueueSnackbar } from '../actions/snackbar_actions';
import { getSnackbarErrorProps, getSnackbarSuccessProps } from '../utilities/snackbar_util';
import { AuthenticationState } from '../reducers/authentication_reducer';
import { getAuthenticationState } from '../selectors/authentication_selectors';


//////////  Get Agent  //////////
function* handleGetAgentFromJwt() {
	const authState: AuthenticationState = yield selectFromImmutable<AuthenticationState>(getAuthenticationState);
	const jwt: string = authState ? authState.jwt || "No jwt present" : "Invalid login state";
	try {
		const response = yield call(http, 'agent/');

		if (response.ok) {
			const data = yield response.json();
			yield put(GetAgentFromJwt.done({ params: undefined, result: data }));
		} else {
			yield put(GetAgentFromJwt.failed({ params: undefined, error: response.status }))
		}
	} catch (error) {
		submitLog(Strings.Log.Error, `Failed to retrieve agent data from web api`, {
			error,
			payload: jwt,
		});
		yield put(GetAgentFromJwt.failed({ params: undefined, error }));
	}
}

function* handleGetAgencyChannels() {
	try {
		const response = yield call(http, Strings.ApiUrls.AgencyChannels);
		if (response.ok) {
			const result = yield response.json();
			yield put(GetAgencyChannels.done({ params: undefined, result }));
		} else {
			submitLog(Strings.Log.Error, 'Failed to retrieve agency channels.', { error: response });
		}
	} catch (error) {
		submitLog(Strings.Log.Error, 'Failed to retrieve agency channels.', { error });
	}
}


//////////  Get Agent With Id  //////////
function* getAgentWithAgentIdSaga(action: Action<string>) {
	const params = action.payload;
	try {
		const response = yield call(http, `agent/GetById/${params}`);

		if (response.ok) {
			const data = yield response.json();
			yield put(GetAgentWithAgentId.done({ params, result: data }));
		} else {
			yield put(GetAgentWithAgentId.failed({ params, error: response.error }));
		}
	} catch (error) {
		submitLog(Strings.Log.Error, `Failed to get agent from web api`, {
			error,
			payload: params,
		});
		yield put(GetAgentWithAgentId.failed({ params, error }));
	}
}

// async functions
function postAgentIcon(id: any, data: any, jwt: any): Promise<any> {
	const options: HttpOptions = {
		method: 'POST',
		body: JSON.stringify({
			imgData: data
		})
	};

	return http(`image`, options);
}

function* handleUploadAgentIcon(action: Action<UploadIconPayload>) {
	try {
		const id = action.payload.agentId;
		const iconImage = action.payload.iconImage;
		const jwt = action.payload.auth;
		const response = yield call(postAgentIcon, id, iconImage, jwt);
		const iconImages = yield call([response, response.json]);
		if (iconImages && iconImages.length) {
			yield put(UpdateIconImages({ iconImages }));
		}
	} catch (e) {
		submitLog(Strings.Log.Error, `Error uploading agent icon`, { error: e });
	}
}

function* upsertAppointmentWebsiteSaga(action: Action<AgentAppointmentWebsite>) {
	try {
		const id = action.payload.userId;
		const website = action.payload.appointmentWebsite;
		const response = yield call(upsertAppointmentWebsiteCall, id, website);

		if (response.ok) {
			const data = String(yield response.json());
			yield put(
				UpsertAppointmentWebsite.done({
					params: action.payload,
					result: data,
				})
			);
		}
	} catch (error) {
		submitLog(Strings.Log.Error, `Error upserting appointment website`, { error });
		yield put(
			UpsertAppointmentWebsite.failed({ params: action.payload, error })
		);
	}
}

function upsertAppointmentWebsiteCall(id: any, data: any): Promise<any> {
	const options: HttpOptions = {
		method: 'POST',
		body: JSON.stringify(data),
	};

	return http(`agent/${id}/appointmentwebsite`, options);
}

/// Upsert timezone
function* upsertTimeZoneSaga(action: Action<AgentTimeZone>) {
	try {
		const { userId, timeZone } = action.payload;
		const response = yield call(upsertTimeZoneCall, userId, timeZone);

		if (response.ok) {
			const data = String(yield response.json());
			yield put(
				UpsertTimeZone.done({
					params: action.payload,
					result: data,
				})
			);
		}
	} catch (error) {
		submitLog(Strings.Log.Error, `Error upserting timezone`, { error });
		yield put(
			UpsertTimeZone.failed({ params: action.payload, error })
		);
	}
}

function upsertTimeZoneCall(id: any, data: any): Promise<any> {
	const options: HttpOptions = {
		method: 'POST',
		body: JSON.stringify(data),
	};

	return http(`agent/${id}/timezone`, options);
}

function* upsertStorefrontAddressSaga(action: Action<Address[]>) {
	try {
		const addresses = action.payload;
		const response = yield call(upsertStorefrontAddressCall, addresses);
		if (response.ok) {
			const data = yield response.json();
			yield put(UpsertStorefrontAddress.done({
				params: action.payload,
				result: data
			}));
		}
	} catch (error) {
		submitLog(Strings.Log.Error, 'Failed to upsert storefront addresses.', { error });
	}
}

function upsertStorefrontAddressCall(addresses: Address[]): Promise<any> {
	const options: HttpOptions = {
		method: 'POST',
		body: JSON.stringify(addresses),
	};

	return http('agent/storefront', options);
}

///Agent hierarchy
function fetchAgentHierarchy(agentCode: string): Promise<any> {
	const options: HttpOptions = {
		method: 'GET',
	};

	return http(`agent/hierarchy/${agentCode}`, options);
}

const HIERARCHY_TTL_S = 3600;
function* getAgentHierarchySaga(action: Action<string>) {
	if (action.payload.length > 0) {
		yield* getFromCache(getAgentHierarchyLoader, GetAgentHierarchy, action, HIERARCHY_TTL_S, function* () {
			try {
				const term = action.payload;

				const response = yield call(fetchAgentHierarchy, term);
				if (response.ok) {
					let hierarchy: AgentHierarchyDto = yield response.json();
					hierarchy.agentHierarchyDirectReports = hierarchy.agentHierarchyDownline.filter(
						agent => agent.reportsTo && agent.reportsTo.toLowerCase() == action.payload.toLowerCase()
					);
					yield put(
						GetAgentHierarchy.done({
							params: action.payload,
							result: hierarchy,
						})
					);
				} else {
					yield put(GetAgentHierarchy.failed({
						params: action.payload,
						error: response.statusText
					}))
				}
			} catch (error) {
				submitLog(Strings.Log.Error, `Error getting agent hierarchy`, { error });
				yield put(
					GetAgentHierarchy.failed({ params: action.payload, error })
				);
			}
		});
	}
}

function* getSubagentsByAgentCodeNotCached(action: Action<string>) {
	const agentCode = action.payload;
	if (agentCode) {
		const response = yield call(http, `agent/subagents/${agentCode}`);
		if (response.ok) {
			const result: Agent[] = yield response.json();
			yield put(GetSubagentsByAgentCodeNotCached.done({ params: action.payload, result }));
		}
		else {
			yield all([
				put(GetSubagentsByAgentCodeNotCached.failed({ params: action.payload, error: response.statusText })),
				put(QueueSnackbar(getSnackbarErrorProps('Unable to retrieve subagents')))
			]);
		}
	}
	yield GetSubagentsByAgentCodeNotCached.done({ params: action.payload, result: [] });
}

function* updateAgentEmailTitle(action: Action<AgentEmailTitlePayload>) {
	const { agentId, title, scope } = action.payload;
	if (agentId && title) {
		const response = yield call(http, `agent/${agentId}/emailtitle`, {
			method: 'POST',
			body: JSON.stringify(title),
		});
		if (response.ok) {
			const result: boolean = yield response.json();
			if (result) {
				yield all([
					put(UpdateAgentEmailTitle.done({ params: action.payload, result: { result, scope } })),
					put(QueueSnackbar(getSnackbarSuccessProps('Email title updated')))
				]);
			}
		}
		else {
			yield all([
				put(UpdateAgentEmailTitle.failed({ params: action.payload, error: response.statusText })),
				put(QueueSnackbar(getSnackbarErrorProps('Not able to update email title')))
			]);
		}
		return;
	}
	yield UpdateAgentEmailTitle.done({ params: action.payload, result: { result: false, scope } });
}

function* updateAgentNeedsProposalApproval(action: Action<AgentNeedsProposalApprovalPayload>) {
	const { agentId, value, scope } = action.payload;
	if (agentId) {
		const response = yield call(http, `agent/${agentId}/needsproposalapproval`, {
			method: 'POST',
			body: JSON.stringify(value),
		});
		if (response.ok) {
			const result: boolean = yield response.json();
			if (result) {
				yield all([
					put(UpdateAgentNeedsProposalApproval.done({ params: action.payload, result: { result, scope } })),
					put(QueueSnackbar(getSnackbarSuccessProps('Approval updated')))
				]);
			}
		}
		else {
			yield all([
				put(UpdateAgentNeedsProposalApproval.failed({ params: action.payload, error: response.statusText })),
				put(QueueSnackbar(getSnackbarErrorProps('Not able to update approval setting')))
			]);
		}
		return;
	}
	yield UpdateAgentNeedsProposalApproval.done({ params: action.payload, result: { result: false, scope } });
}

function* connectureSSOredirect(action: Action<ConnectureSAMLResponseParams>) {
	const { userId } = action.payload;
	if (userId != "") {
		const response = yield call(http, `Connecture/ConnectureSSOredirect`, {
			method: 'POST',
			body: JSON.stringify(action.payload)
		});
		if (response.ok) {
			const data: string = yield response.json();
			if (data) {
				yield all([
					put(ConnectureSSOredirect.done({ params: action.payload, result: data }))
				]);
			}
		}
		else {
			yield all([
				put(ConnectureSSOredirect.failed({ params: action.payload, error: response.statusText })),
				put(QueueSnackbar(getSnackbarErrorProps('Server Error')))
			]);
		}
		return;
	}
	yield put(ConnectureSSOredirect.done({ params: action.payload, result: "" }));
}

export function* agentSagas() {
	yield all([
		takeLatestForActionType(
			GetAgentWithAgentId.started,
			getAgentWithAgentIdSaga
		),
		takeLatestForActionType(
			UploadAgentIcon,
			handleUploadAgentIcon
		),
		takeLatestForActionType(
			UpsertAppointmentWebsite.started,
			upsertAppointmentWebsiteSaga
		),
		takeLatestForActionType(
			UpsertTimeZone.started,
			upsertTimeZoneSaga
		),
		takeLatestForActionType(
			GetAgentHierarchy.started,
			getAgentHierarchySaga
		),
		takeLatestForActionType(
			GetAgentFromJwt.started,
			handleGetAgentFromJwt
		),
		takeLatestForActionType(
			GetAgencyChannels.started,
			handleGetAgencyChannels
		),
		takeLatestForActionType(
			UpsertStorefrontAddress.started,
			upsertStorefrontAddressSaga
		),
		takeLatestForActionType(
			GetSubagentsByAgentCodeNotCached.started,
			getSubagentsByAgentCodeNotCached
		),
		takeEveryForActionType(
			UpdateAgentEmailTitle.started,
			updateAgentEmailTitle
		),
		takeEveryForActionType(
			UpdateAgentNeedsProposalApproval.started,
			updateAgentNeedsProposalApproval
		),
		takeEveryForActionType(
			ConnectureSSOredirect.started,
			connectureSSOredirect
		)
	]);
}