import {actionCreatorFactory} from 'typescript-fsa';
import { Action as ReduxAction } from 'redux';

const actionCreator = actionCreatorFactory('Layout');

export const WindowResized = actionCreator<{
	width: number;
	height: number;
}>('Window_Resized');
export function windowResized(width: number, height: number): ReduxAction {
	return WindowResized({ width, height });
}

export const ToggleDesktopView = actionCreator<{
	desktop: boolean;
}>('Toggle_Desktop_View');
export function toggleDesktopView(desktop: boolean): ReduxAction {
	return ToggleDesktopView({ desktop });
}
export const SetSideMenuBarState = actionCreator<{isVisible: boolean;}>('SET_SIDE_MENUBAR_STATE');
export function setSideMenuBarState(isVisible: boolean): ReduxAction {
	return SetSideMenuBarState({ isVisible });
}
