import { Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import { AppState } from '../../../reducers';
import { Dispatch } from '@reduxjs/toolkit';
import { GetAgentAppointments } from '../../../actions/license_appointment_actions';
import { AgentAppointment, AppointmentForm } from '../../../reducers/license_appointment_reducer';
import { getCurrentAgentCode } from '../../../selectors/agent_selectors';
import { getAgentAppointments } from '../../../selectors/license_appointment_selectors';
import AppointmentTable from '../../../components/agentLicenseAppointment/appointment/appointment_table';
import _ from 'lodash';

interface StateProps {
    loading: boolean;
    currentAgentCode: string;
    agentAppointments: AgentAppointment[];
    filterAppointmentForm: AppointmentForm,

}

interface DispatchProps {
    getAgentAppointments: (filterForm: AppointmentForm) => void
}

type Props = StateProps & DispatchProps;

const AppointmentListComponent: React.FC<Props> = (props) => {

    const columns = [
        { key: 'CarrierName', name: 'Carrier' },
        { key: 'State', name: 'State' },
        { key: 'StatusText', name: 'Status' }
        // Add more columns as needed
    ];

    const { loading, currentAgentCode, agentAppointments, getAgentAppointments, filterAppointmentForm } = props;

    useEffect(() => {
        if (currentAgentCode != 'unknown') {
            getAgentAppointments(filterAppointmentForm);
        }
    }, [currentAgentCode]);

    const renderAppointmentData = () => {
        if (loading) {
            return (
                <Typography align='center'>Loading...</Typography>
            );
        }
        else if (agentAppointments && agentAppointments.length > 0) {
            return (
                <AppointmentTable columns={columns} rows={agentAppointments} />
            );
        } else {
            return null;
        }
    }

    return (
        renderAppointmentData()
    );
}

const mapStateToProps = (state: AppState): StateProps => ({
    loading: state.licenseAppointment.loading,
    currentAgentCode: getCurrentAgentCode(state),
    agentAppointments: getAgentAppointments(state),
    filterAppointmentForm: state.licenseAppointment.filterAppointmentForm

});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    getAgentAppointments: (filterForm: AppointmentForm) => dispatch(GetAgentAppointments.started(filterForm)),
});

export const AppointmentListContainer = connect(mapStateToProps, mapDispatchToProps, true)(AppointmentListComponent);
