import { createSelector } from "reselect";
import { AppState } from "../reducers";
import { makeSubstateAccessorCreator } from "../utilities/selectors_util";
import { AgentState } from "../reducers/license_appointment_reducer";


const getLicenseAppointmentState = (state: AppState) => state.licenseAppointment;
const createAgentLicensesAccessor = makeSubstateAccessorCreator(getLicenseAppointmentState);

export const getAgentStates = (state: AppState) => state.licenseAppointment.agentStates;
export const getAgentLicenses = (state: AppState) => state.licenseAppointment.agentLicenses;
export const getAgentAppointmentCountByState = (state: AppState) => state.licenseAppointment.agentAppointmentCount;
export const getAgentLicenseLineOfAuthorities = (state: AppState) => state.licenseAppointment.agentLicenseLineOfAuthorities;
export const getAgentAppointments = (state: AppState) => state.licenseAppointment.agentAppointments;


export const getResidentState = createSelector(
	[getAgentStates],
	(agentStates: AgentState[]) => {
		const resident = agentStates.find((agentState: AgentState) => agentState.IsResident);
		return resident?.State || "";
	}
);