import { Action as ReduxAction } from 'redux';
import { isType } from 'typescript-fsa';
import {
	GetAgentFromJwt,
	GetAgentWithAgentId,
	UpdateIconImages,
	UpdateAgentState,
	UpsertAppointmentWebsite,
	GetAgentHierarchy,
	GetAgencyChannels,
	UpsertTimeZone,
	UpsertStorefrontAddress,
	UpdateAgentEmailTitle,
	AgentScope,
	GetSubagentsByAgentCodeNotCached,
	UpdateAgentNeedsProposalApproval,
	ConnectureSSOredirect,
} from '../actions/agent_actions';
import { ClearCaches } from '../actions/authentication_actions';
import { Logout, FailedLogin } from '../actions/authentication_actions';
import { Loading, initialLoading, LoaderState, setFetched, setRetrievedFromCache, setFailed } from '../utilities/loader_util';
import { Address } from './ContactReducer';

export interface AgentChannel {
	channelStatus: string;
	channelName: string;
	agency: string;
	channelType: string;
}
export interface AgencyChannel {
	agency: string;
	activeChannel: string;
}
export interface AgentContactInfo {
	contactInfo: string;
	contactMode: string;
	contactType: string;
	isPrimary: string;
}

export interface AgentAddress {
	addressType: string;
	address1: string;
	address2: string;
	address3: string;
	city: string;
	state: string;
	zipCode: string;
	isPrimary: string;
}

export interface AgentLicense {
	LicenseState: string,
	LicenseNumber: string
}

export interface Agent {
	firstName: string;
	preferredName: string;
	lastName: string;
	middleInitial: string;
	suffix: string;
	id: string;
	agentCode: string;
	agentRoles: string[];
	activeChannel: string;
	agency: string;
	channels: AgentChannel[];
	title: string;
	agentTitle?: string;
	leadAgent: object;
	contactInfo: AgentContactInfo[];
	addresses: AgentAddress[];
	storefrontAddresses: Address[];
	emailAddress: string;
	appointmentWebsite: string;
	isLoading: boolean;
	fieldOfficeInfo: {
		FieldOfficeId: string,
		FieldOfficeName: string,
		FieldOfficeAddress1: string,
		FieldOfficeAddress2: string,
		FieldOfficeCity: string,
		FieldOfficeState: string,
		FieldOfficeZip: string,
		FieldOfficePhoneNumber: string,
		FieldOfficeFaxNumber: string,
		FieldOfficeType: string
	},
	bio: string;
	businessPhoneLiveTransfer: string;
	personalPhoneLiveTransfer: string;
	agentLicenses: AgentLicense[];
	timeZone: string;
	reportsTo: string;
	agentImage: string;
}

export const initialFieldOfficeInfo = {
	FieldOfficeId: '',
	FieldOfficeName: '',
	FieldOfficeAddress1: '',
	FieldOfficeAddress2: '',
	FieldOfficeCity: '',
	FieldOfficeState: '',
	FieldOfficeZip: '',
	FieldOfficePhoneNumber: '',
	FieldOfficeFaxNumber: '',
	FieldOfficeType: ''
}

export const initialAgentAddress: AgentAddress = {
	addressType: '',
	address1: '',
	address2: '',
	address3: '',
	city: '',
	state: '',
	zipCode: '',
	isPrimary: '',
};

export const initialAgent: Agent = {
	firstName: '',
	preferredName: '',
	lastName: '',
	middleInitial: '',
	suffix: '',
	id: '',
	agentCode: '',
	agentRoles: [],
	activeChannel: '',
	agency: '',
	channels: [],
	title: '',
	leadAgent: {},
	contactInfo: [
		{
			contactInfo: '',
			contactMode: '',
			contactType: '',
			isPrimary: '',
		},
	],
	addresses: [
		initialAgentAddress,
	],
	storefrontAddresses: [],
	emailAddress: '',
	appointmentWebsite: '',
	isLoading: true,
	fieldOfficeInfo: initialFieldOfficeInfo,
	bio: '',
	businessPhoneLiveTransfer: '',
	personalPhoneLiveTransfer: '',
	agentLicenses: [],
	timeZone: '',
	reportsTo: '',
	agentImage: '',
};

export interface AgentState extends Agent {
	profileImage?: string;
	iconImages?: string[];
	hierarchy: Loading<AgentHierarchyDto, string>;
	subagents: Agent[];
	agencyChannels: AgencyChannel[];
	connectureSamlResponse : string;
	connectureSamlSSOFailed?:any;
	
	
	
}

export interface AgentHierarchyDto {
	agentCode: string;
	agentHierarchyUpline: Agent[];
	agentHierarchyDirectReports: Agent[];
	agentHierarchyDownline: Agent[];
}

const initialState: AgentState = {
	...initialAgent,
	leadAgent: { firstName: '', lastName: '' },
	addresses: [],
	storefrontAddresses: [],
	contactInfo: [],
	isLoading: false,
	agencyChannels: [],
	agentImage: '',
	profileImage: undefined,
	iconImages: undefined,
	hierarchy: initialLoading(),
	subagents: [],
	connectureSamlResponse:'',
	connectureSamlSSOFailed:{}	
};

function getProfileIcon(iconImages: string[]) {
	if (!iconImages) {
		return undefined;
	} else {
		const index = iconImages.findIndex((value, index, obj) => {
			return value.search('-profile') != -1;
		});
		return iconImages[index];
	}
}

export function agentReducer(
	state: AgentState = initialState,
	action: ReduxAction
): AgentState {
	if (isType(action, UpdateAgentState)) {
		if (
			action.payload.agentImage &&
			!action.payload.agentImage.includes('https')
		) {
			action.payload.agentImage = action.payload.agentImage.replace(
				'http',
				'https'
			);
		}

		if (action.payload.iconImages) {
			action.payload.iconImages = action.payload.iconImages.map(value => {
				return value + '?ver=' + new Date().getTime();
			});
			const profileIcon = getProfileIcon(action.payload.iconImages);
			return {
				...state,
				...action.payload,
				profileImage: profileIcon,
			};
		} else {
			return {
				...state,
				...action.payload,
			};
		}
	} else if (isType(action, GetAgentFromJwt.done)) {
		return {
			...state,
			...action.payload.result,
		};
	} else if (isType(action, GetAgentWithAgentId.done)) {
		let result = action.payload.result;
		let preferredOrFirstName = result.preferredName || result.firstName;
		return {
			...state,
			leadAgent: {
				preferredOrFirstName,
				lastName: result.lastName,
			},
		};
	} else if (isType(action, UpdateIconImages)) {
		let profileIcon: string | undefined = undefined;
		if (action.payload.iconImages) {
			action.payload.iconImages = action.payload.iconImages.map(value => {
				return value + '?ver=' + new Date().getTime();
			});
			profileIcon = getProfileIcon(action.payload.iconImages);
		}

		return {
			...state,
			iconImages: action.payload.iconImages,
			profileImage: profileIcon,
		};
	} else if (isType(action, Logout.started) || isType(action, FailedLogin)) {
		return {
			...initialState,
		};
	} else if (isType(action, ClearCaches)) {
		return {
			...initialState,
		};
	} else if (isType(action, UpsertAppointmentWebsite.done)) {
		return {
			...state,
			appointmentWebsite: action.payload.result,
		};
	} else if (isType(action, UpsertTimeZone.done)) {
		return {
			...state,
			timeZone: action.payload.result,
		};
	} else if (isType(action, UpsertStorefrontAddress.done)) {
		return {
			...state,
			storefrontAddresses: action.payload.result
		}
	} else if (isType(action, GetAgentHierarchy.started)) {
		return {
			...state,
			isLoading: true,
			hierarchy: {
				...state.hierarchy,
				state: LoaderState.Loading,
			}
		}
	} else if (isType(action, GetAgentHierarchy.cacheDone)) {
		return {
			...state,
			isLoading: false,
			hierarchy: setRetrievedFromCache(state.hierarchy, action.payload),
		}
	} else if (isType(action, GetAgentHierarchy.done)) {
		return {
			...state,
			isLoading: false,
			hierarchy: setFetched(state.hierarchy, action.payload),
		}
	} else if (isType(action, GetAgentHierarchy.failed)) {
		return {
			...state,
			isLoading: false,
			hierarchy: setFailed(state.hierarchy, action.payload)
		}
	} else if (isType(action, GetAgencyChannels.done)) {
		return {
			...state,
			agencyChannels: action.payload.result,
		};
	} else if (isType(action, GetSubagentsByAgentCodeNotCached.started) || isType(action, UpdateAgentEmailTitle.started) || isType(action, UpdateAgentNeedsProposalApproval.started)) {
		return {
			...state,
			isLoading: true,
		};
	} else if (isType(action, GetSubagentsByAgentCodeNotCached.failed) || isType(action, UpdateAgentEmailTitle.failed) || isType(action, UpdateAgentNeedsProposalApproval.failed)) {
		return {
			...state,
			isLoading: false,
		};
	} else if (isType(action, GetSubagentsByAgentCodeNotCached.done)) {
		return {
			...state,
			isLoading: false,
			subagents: action.payload.result,
		};
	} else if (isType(action, UpdateAgentEmailTitle.done)) {
		const { result, scope } = action.payload.result;
		if (result) {
			const { agentId, title } = action.payload.params;

			if (scope === AgentScope.Subagents) {
				const updatedAgents = state.subagents.map(agent => {
					if (agent.id === agentId) {
						agent.agentTitle = title;
					}
					return agent;
				});
				return {
					...state,
					isLoading: false,
					subagents: updatedAgents,
				};
			}

			// ***** WAS USING HIERARCHY BEFORE, BUT FOR NOW ONLY UPDATING SUBAGENTS, USING
			// ******* NEW ENDPOINT TO GET SUBAGENTS

			// let updatedAgents: Agent[] = [];
			// if (scope === AgentScope.Downline) {
			// 	updatedAgents = state.hierarchy.data?.agentHierarchyDownline || [];
			// 	const index = updatedAgents.findIndex(agent => agent.id === agentId);
			// 	if (index > -1) {
			// 		updatedAgents[index].agentTitle = title;
			// 	}
			// 	return {
			// 		...state,
			// 		hierarchy: {
			// 			...state.hierarchy,
			// 			data: {
			// 				agentCode: state.hierarchy.data?.agentCode || '',
			// 				agentHierarchyDirectReports: state.hierarchy.data?.agentHierarchyDirectReports || [],
			// 				agentHierarchyUpline: state.hierarchy.data?.agentHierarchyUpline || [],
			// 				agentHierarchyDownline: updatedAgents,
			// 			}
			// 		}
			// 	};
			// }
			// else if (scope === AgentScope.Upline) {
			// 	updatedAgents = state.hierarchy.data?.agentHierarchyUpline || [];
			// 	const index = updatedAgents.findIndex(agent => agent.id === agentId);
			// 	if (index > -1) {
			// 		updatedAgents[index].agentTitle = title;
			// 	}
			// 	return {
			// 		...state,
			// 		hierarchy: {
			// 			...state.hierarchy,
			// 			data: {
			// 				agentCode: state.hierarchy.data?.agentCode || '',
			// 				agentHierarchyDirectReports: state.hierarchy.data?.agentHierarchyDirectReports || [],
			// 				agentHierarchyDownline: state.hierarchy.data?.agentHierarchyDownline || [],
			// 				agentHierarchyUpline: updatedAgents,
			// 			}
			// 		}
			// 	};
			// }
			// else if (scope === AgentScope.Me) {
			// 	return {
			// 		...state,
			// 		agentTitle: action.payload.params.title,
			// 	};
			// }
		}
		return state;
	} else if (isType(action, UpdateAgentNeedsProposalApproval.done)) {
		const { result, scope } = action.payload.result;
		if (result) {
			const { agentId, value } = action.payload.params;

			if (scope === AgentScope.Subagents) {
				const updatedAgents = state.subagents.map(agent => {
					if (agent.id === agentId) {
						agent.needsProposalApproval = value;
					}
					return agent;
				});
				return {
					...state,
					isLoading: false,
					subagents: updatedAgents,
				};
			}
		}
		return state;
	}else if (isType(action, ConnectureSSOredirect.started)) {
		return {
			...state,
			isLoading: true,
			connectureSamlResponse:""
		}
	}
	else if (isType(action, ConnectureSSOredirect.done)) {
		return {
			...state,
			isLoading: false,
			connectureSamlResponse: action.payload.result,
		}
	}
	else if (isType(action, ConnectureSSOredirect.failed)) {
		return {
			...state,
			isLoading: false,
			connectureSamlSSOFailed: action.payload.error,
		}
	}	
	 else {
		return state;
	}
}
