import actionCreatorFactory, {
	Action as ReduxAction,
} from 'typescript-fsa';

export interface InfiniteScrollMeta {
	query: any;
	pageRequested: number;
	pageSize: number;
	resultSize: number;
}

const actionCreator = actionCreatorFactory('InfiniteScroll');

export const StoreInfiniteScrollMeta = actionCreator<InfiniteScrollMeta>(
	'STORE_INFINITE_SCROLL_META'
);

export const ResetInfiniteScroll = actionCreator('RESET_INFINITE_SCROLL');
