import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { Strings } from '../../assets/common/strings';
import { ChartGauge, GaugeChartSettings } from '../utility/chart_gauge';
import { LeadChartStats } from '../teamview/leads_chart_stats';
import { ProductivityParams, GetNewLeadsReport } from '../../actions/productivity_actions';
import { ReportCard } from '../utility/report_card';

export const NewLeadsReports: React.FC<{ params: ProductivityParams; hidden?: boolean; }> = props => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const reportData = useSelector((state: any) => state.App.productivity.newLeadReport, shallowEqual);

    useEffect(() => {
        dispatch(GetNewLeadsReport.started(props.params))
    }, [props.params]);

    const date = moment().format('M.D.YYYY');

    const renderGraphs = () => (
        <>
            <Grid container>
                <Grid item xs className={classes.secondaryTitle}>
                    <Typography variant="subtitle1">Disposition Rates</Typography>
                </Grid>
            </Grid>
            <Grid container>
                {reportData.graphs.filter(g => g.type === 'Disposition Rates').map(g => (
                    <Grid item xs={12} sm={6} key={g.title} className={classes.stats}>
                        <Typography variant="subtitle2">{g.title}</Typography>
                        <ChartGauge
                            data={[g.dataPoint]}
                            chartSettings={leadsChartsProps.dispostion}
                        />
                        <LeadChartStats result={g.result} slAvg={g.slAvg} taken={g.total}/>
                    </Grid>))}
            </Grid>
            <Grid container>
                <Grid item xs className={classes.secondaryTitle}>
                    <Typography variant="subtitle1">Conversion Rates (not normalized for lead type)</Typography>
                </Grid>
            </Grid>
            <Grid container>
                {reportData.graphs.filter(g => g.type === 'Conversion Rates').map(g => (
                    <Grid item xs={12} sm={6} key={g.title} className={classes.stats}>
                        <Typography variant="subtitle2">{g.title}</Typography>
                        <ChartGauge
                            data={[g.dataPoint]}
                            chartSettings={leadsChartsProps.conversionRates}
                        />
                        <LeadChartStats result={g.result} slAvg={g.slAvg} taken={g.total} />
                    </Grid>))}
            </Grid>
        </>
    );

    return (
        <div hidden={props.hidden}>
            {reportData?.isLoading
            ? (
                <ReportCard
                    color="secondary"
                    titleLeft={`${Strings.TeamView.NEW_LEAD_CARD} ${date}`}
                    isLoading
                />)
            : (
                <ReportCard
                    color="secondary"
                    titleLeft={`${Strings.TeamView.NEW_LEAD_CARD} ${date}`}
                >
                    {renderGraphs()}
                </ReportCard>)}
        </div>
    );
};

const useStyles = makeStyles({
    title: {
		overflow: 'hidden',
		WebkitTextSizeAdjust: '100%',
		fontFamily: 'Roboto, sans-serif',
		fontSize: '14px',
		fontWeight: 'bold',
		textAlign: 'left',
		padding: 20,
        color: 'white',
    },
    secondaryTitle: {
        textAlign: 'center',
        padding: 10,
        backgroundColor: '#edf1f7',
        borderLeft: '1px solid black',
        borderRight: '1px solid black',
    },
    stats: {
        overflow: 'hidden',
        WebkitTextSizeAdjust: '100%',
        fontFamily: 'Roboto, sans-serif',
        fontSize: '14px',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: 10,
        border: '1px solid black',
        boxSizing: 'border-box',
    },
    loading: {
        height: 330,
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        border: '1px solid black',
        boxSizing: 'border-box',
    },
});

const leadsChartsProps: {dispostion: GaugeChartSettings, conversionRates: GaugeChartSettings} = {
    dispostion: {
        yAxisMin: 0,
        yAxisMax: .75,
        plotBands: [
            {to: 0, from: .25, color: '#d62413'},
            {to: .25, from: .50, color: '#e8d717'},
            {to: .50, from: .75, color: '#5da859'},
        ],

    },
    conversionRates: {
        yAxisMin: 0,
        yAxisMax: .15,
        plotBands: [
            {to: 0, from: .05, color: '#d62413'},
            {to: .05, from: .10, color: '#e8d717'},
            {to: .10, from: .15, color: '#5da859'},
        ],
    },
}
