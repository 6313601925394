import {actionCreatorFactory} from 'typescript-fsa';
import { Activity } from '../../reducers/activity_reducer';
import { Contact } from '../../reducers/ContactReducer';

const actionCreator = actionCreatorFactory('CALL_SEQUENCE');

export type StartCallParams = {
	householdId: string;
	employerId?: string;
}

export const StartCallSequence = actionCreator<{ hasActivities: boolean; contacts: Contact[]; }>('START');
export const StartCallSequenceAndLoadActivities = actionCreator.async<StartCallParams,{ hasActivities: boolean; contacts: Contact[] }>('START_AND_LOAD_ACTIVITIES');
export const SelectActivitiesForCallSequence = actionCreator<Activity[]>('SELECT_ACTIVITIES');
export const SelectContactForCallSequence = actionCreator<{ contactId: string; contactPhoneNumber: string }>('SELECT_CONTACT');
export const CancelCallSequence = actionCreator('CANCEL');
