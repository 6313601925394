import React from 'react';
import { defaultSvgProps, SvgProps } from '..';

const LeadsSvg: React.FC<SvgProps> = (props) => (
    <svg viewBox="0 0 35 35" {...props}>
        <path
            d="M13.73 17a4.13 4.13 0 0 1-3.85-4.35 4.13 4.13 0 0 1 3.85-4.35 4.12 4.12 0 0 1 3.84 4.35A4.12 4.12 0 0 1 13.73 17zm0-7.7a3.13 3.13 0 0 0-2.85 3.35A3.13 3.13 0 0 0 13.73 16a3.13 3.13 0 0 0 2.84-3.35 3.13 3.13 0 0 0-2.84-3.34zM20.08 28H7.21a.49.49 0 0 1-.5-.46l-.29-4.16a3.78 3.78 0 0 1 2.35-3.47 14.57 14.57 0 0 1 9.75 0 3.75 3.75 0 0 1 2.34 3.47l-.28 4.16a.5.5 0 0 1-.5.46zM7.67 27h11.94l.26-3.69a2.76 2.76 0 0 0-1.7-2.46 13.54 13.54 0 0 0-9.06 0 2.8 2.8 0 0 0-1.69 2.46z"
        />
        <path
            d="M20.08 28H7.21a.49.49 0 0 1-.5-.46l-.29-4.16a3.78 3.78 0 0 1 2.35-3.47 14.57 14.57 0 0 1 9.75 0 3.75 3.75 0 0 1 2.34 3.47l-.28 4.16a.5.5 0 0 1-.5.46zM7.67 27h11.94l.26-3.69a2.76 2.76 0 0 0-1.7-2.46 13.54 13.54 0 0 0-9.06 0 2.8 2.8 0 0 0-1.69 2.46zM22.5 18.38a3.57 3.57 0 0 1-3.33-3.76 3.56 3.56 0 0 1 3.33-3.76 3.57 3.57 0 0 1 3.33 3.76 3.57 3.57 0 0 1-3.33 3.76zm0-6.52a2.57 2.57 0 0 0-2.33 2.76 2.57 2.57 0 0 0 2.33 2.76 2.58 2.58 0 0 0 2.33-2.76 2.58 2.58 0 0 0-2.33-2.76z"
        />
        <path
            d="M27.28 22a2.19 2.19 0 0 0-1.05-.9A11.41 11.41 0 0 0 19 21a.5.5 0 0 1-.63-.32.49.49 0 0 1 .32-.63 12.4 12.4 0 0 1 7.94.12 3.25 3.25 0 0 1 2 3l-.24 3.51a.49.49 0 0 1-.49.47h-7.61a.5.5 0 0 1-.5-.5.5.5 0 0 1 .5-.5h7.12l.21-3.05a2 2 0 0 0-.34-1.1"
        />
    </svg>
);
LeadsSvg.defaultProps = defaultSvgProps;

export default LeadsSvg;
