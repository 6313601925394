import { themePalette } from "../../utilities/branding";
import React from 'react';
import { wrapInCollapsibleCard } from '../higher_order_component/wrap_in_card';
import { Grid } from '@material-ui/core';

interface Props {
    hasIcons: boolean;
    inEdit: boolean;
    isActivated: boolean;
    style?: React.CSSProperties;
}

const CCCardContent: React.FC<Props> = (props) => {
    const { hasIcons, inEdit, isActivated, style=defaultStyle } = props;
    return (
        <div style={style}>
            {!hasIcons ? (
                <Grid container justifyContent="center" style={{ color: themePalette.error, padding: 20 }}>
                    Approve your icon with Icon Management before activating ClientConnect
                </Grid>
            ) : (
                inEdit || isActivated ? (
                    <Grid container justifyContent="flex-start">
                        {props.children}
                    </Grid>
                ) : null
            )}
        </div>
    );
};

const defaultStyle: React.CSSProperties = {
    flex: 'display',
    position: 'relative',
};

export const CCCard = wrapInCollapsibleCard(CCCardContent);