import React from 'react';
import { defaultSvgProps, SvgProps } from '..';


const AnnouncementsSvg: React.FC<SvgProps> = (props) => (
    <svg viewBox="0 0 35 35" {...props}>
        <path
            d="M27.36 27a.54.54 0 0 1-.36-.14.51.51 0 0 1 0-.71 10.4 10.4 0 0 0 0-14.69.5.5 0 1 1 .71-.71 11.41 11.41 0 0 1 0 16.11.54.54 0 0 1-.35.14z"
        />
        <path
            d="M25.09 24.71a.5.5 0 0 1-.35-.85 7.18 7.18 0 0 0 0-10.17.5.5 0 0 1 0-.71.51.51 0 0 1 .71 0 8.18 8.18 0 0 1 0 11.58.51.51 0 0 1-.36.15zM11.74 23H8.37a4.37 4.37 0 0 1 0-8.73h3.38a.51.51 0 0 1 .5.5.5.5 0 0 1-.5.5H8.36A3.37 3.37 0 0 0 6 21a3.35 3.35 0 0 0 2.38 1h3.37a.51.51 0 0 1 .5.5.5.5 0 0 1-.51.5z"
        />
        <path
            d="M21.51 27.11h-.16l-9.53-3.35a.49.49 0 0 1-.32-.57 22.64 22.64 0 0 0 0-9.13.48.48 0 0 1 .32-.57l9.53-3.34a.49.49 0 0 1 .41 0 .52.52 0 0 1 .24.34 39.57 39.57 0 0 1 0 16.22.48.48 0 0 1-.24.33.54.54 0 0 1-.25.07zm-9-4.17l8.58 3a39 39 0 0 0 0-14.69l-8.58 3a23.4 23.4 0 0 1 .05 8.69zM10.42 26.72a2.48 2.48 0 0 1-2-1.11 4.25 4.25 0 0 1-.75-2.47.5.5 0 0 1 .5-.5.5.5 0 0 1 .5.5A3.24 3.24 0 0 0 9.24 25a1.51 1.51 0 0 0 1.18.7.49.49 0 0 1 .5.49.5.5 0 0 1-.49.51z"
        />
    </svg>
);
AnnouncementsSvg.defaultProps = defaultSvgProps;

export default AnnouncementsSvg;
