import React from 'react';
import {
    Button, Card, Grid, Typography, makeStyles
} from '@material-ui/core';
import { Drug } from './index';

interface Props {
    drug: Drug;
    deleteDrug: (drug: Drug) => void;
    editDrug: (drug: Drug) => void;
    readOnly?: boolean;
}

export const PrescriptionSelectListItem: React.FC<Props> = ({ drug, deleteDrug, editDrug, readOnly }) => {
    const classes = useStyles();

    const drugOwnerDisplay = () => {
        const ownerList = drug.owner;
        let ownerText = 'For: ';
        Object.keys(ownerList).filter(key => ownerList[key] === true).forEach(owner => {
            if (ownerText === 'For: ') {
                ownerText += owner;
            } else {
                ownerText += `, ${owner}`;
            }
        });
        return ownerText;
    }

    const handelEdit = () => {
        editDrug(drug)
    }

    const handleDelete = () => {
        deleteDrug(drug);
    }

    return (
        <Card className={classes.root}>
            <div className={classes.content}>
                <Grid
                    container
                    alignItems="center"
                >
                    <Grid item xs={12} sm={8}>
                        <Typography variant="button" display="block" className={classes.drugName}>
                            {`${drug.name} ${drug.rxterms_dose_form} ${drug.strength}`}
                        </Typography>
                        <Typography variant="caption" color="textSecondary" display="block">
                            {drugOwnerDisplay()}
                        </Typography>
                    </Grid>
                    <div/>
                    <Grid item xs={12} sm={4} container justify="flex-end" >
                        <Button
                            size="small"
                            color="secondary"
                            onClick={handelEdit}
                            disabled={readOnly}
                        >
                            Edit
                        </Button>
                        <Button
                            size="small"
                            color="primary"
                            onClick={handleDelete}
                            className={classes.button}
                            disabled={readOnly}
                        >
                            Remove
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Card>
    );
}

const useStyles = makeStyles({
    root: {
        marginBottom: 10,
    },
    content: {
        padding: 16,
    },
    drugName: {
        fontWeight: 600,
    },
    button: {
        marginLeft: 10,
    },
  });
