import { AppState } from '../reducers/index';
import { Loaded } from '../utilities/utilities';
import { createSelector } from 'reselect';
import { Activity } from '../reducers/activity_reducer';
import { getPage, getId, PageType } from './base_selectors';
import moment from 'moment';
import { Contact } from '../reducers/ContactReducer';
import { Strings } from '../assets/common/strings';
import { QuoteActivity } from '../reducers/quote_activity_reducer';
import { getUserId } from './user_selectors';

/**
 * Activity selectors
 */
const getActivities = (state: AppState) => (state.activity.activities || []);

export const getPageActivities = createSelector(
	[getPage, getId, getActivities],
	(page: PageType, id: string, activities: Loaded<Activity>[]) => {
		switch (page) {
			case 'HOUSEHOLD_PAGE': {
				return activities.filter(activity => activity.householdId == id);
			}
			case 'EMPLOYER_PAGE': {
				return activities.filter(activity => activity.employerId == id);
			}
			default:
				return activities;
		}
	}
);

const mostRecentSort = (a: Loaded<Activity>, b: Loaded<Activity>) => {
	const aTime = moment(a.data.time);
	const bTime = moment(b.data.time);
	if (aTime.isBefore(bTime)) {
		return -1;
	} else if (aTime.isAfter(bTime)) {
		return 1;
	}
	return 0;
};
const todayFilter = (a: Loaded<Activity>) =>
	!a.loading &&
	a.data?.status === 'open' &&
	moment().date() === moment.utc(a.data?.time).local().date();
export const getTodaysActivities = createSelector(
	[getActivities],
	(activities: Loaded<Activity>[]) => activities.filter(todayFilter)
);

export const getTodaysLatestActivities = createSelector(
	[getTodaysActivities],
	(activities: Loaded<Activity>[]) =>	
		activities.sort(mostRecentSort).slice(0, 3)
);

const getQuoteActivities = (state: AppState) => (state.quoteActivity.quoteActivities || []);

export const getQuoteActivitiesAsActivities = createSelector(
	[getId, getQuoteActivities],
	(id: string, activities: Loaded<QuoteActivity>[]) : Loaded<Activity>[] => {
		return activities.filter(quote => quote.data.householdId == id).map(quote => {
			return {
				employerId: quote.employerId,
				errors: quote.errors,
				householdId: quote.householdId,
				loading: quote.loading,
				data: {
					id: quote.data.id,
					title: 'Quoted',
					description: quote.data.type,
					location: quote.data.url,
					type: Strings.Activity.Quoted,
					sender: quote.data.source,
					userId: quote.data.userId,
					status: Strings.ActivityStatus.Completed,
					lead: quote.data.leadId,
					time: quote.data.createdOn,
					isHighPriority: false,
					createdByName: '',
					modifiedBy: quote.data.updatedBy,
					modifiedOn: quote.data.updatedOn,
					alternatePhoneNumber: '',
					isDeleted: false,
					googleEventId: '',
					toRecipients: '',
					appointmentType: '',
					createdBy: quote.data.createdBy,
					createdOn: quote.data.createdOn,
					updatedBy: quote.data.updatedBy,
					updatedOn: quote.data.updatedOn,
					contact: {} as Contact,
					completedOn: quote.data.createdOn,
					twilioSid: '',
					clickToCallDisposition: '',
					clickToCallSessionId: undefined
				}
			}
		})
	}
);

export const getNextActivity = createSelector(
	[getPageActivities, getUserId],
	(activities: Loaded<Activity>[], userId: string) => {
		const futureActivities = activities
			.filter(activity => activity.data.status == 'open' && activity.data.userId === userId)
			.sort((a, b) => {
				return (
					moment.utc(a.data.time).valueOf() - moment.utc(b.data.time).valueOf()
				);
			});

		if (futureActivities.length) {
			let activity = futureActivities[0];
			for (let i = 0; i < futureActivities.length; i++) {
				activity = futureActivities[i];
				const start = moment.utc(activity.data.time).local();
				if (start.isBefore() && futureActivities[i + 1]) {
					continue;
				} else {
					break;
				}
			}
			return activity.data;
		} else {
			return undefined;
		}
	}
);
