import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogContent,
    Button,
    DialogTitle,
    DialogContentText,
    DialogActions,
} from "@material-ui/core";
import { isIOS, isMobileDevice } from "../../utilities/is_mobile";

import safariIcon from '../../assets/icon-installsafari.svg';



interface StateProps { }

interface DispatchProps { }

type Props = StateProps & DispatchProps;

const PwaInstallDialog: React.FC<Props> = () => {
    const [pwaInstallDialogOpen, setPwaInstallDialogOpen] = useState(isIOS);
    const [isPwaInstalled, setIsPwaInstalled] = useState(false);
    const [deferredPrompt, setDeferredPrompt] = useState<Event | null>(
        new Event("beforeinstallprompt")
    );

    useEffect(() => {
        if (
            window.matchMedia("(display-mode: standalone)").matches ||
            (navigator as any).standalone ||
            !isMobileDevice
        ) {
            setIsPwaInstalled(true);
        } else {
            window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
            window.addEventListener("appinstalled", handleAfterInstall);
        }

        return () => {
            window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
            window.removeEventListener("appinstalled", handleAfterInstall);
        };
    }, []);

    const handleBeforeInstallPrompt = (event: Event) => {
        event.preventDefault(); // prevents default browser install prompt
        setDeferredPrompt(event); // saving the event for later trigger
        setPwaInstallDialogOpen(true);
    };
    const handleAfterInstall = (event: Event) => {
        handleCloseDialog();
        setDeferredPrompt(null);
    };
    const handleCloseDialog = () => {
        setPwaInstallDialogOpen(false);
    };

    const handleInstall = async (deferredPrompt) => {
        deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        const { outcome } = await deferredPrompt.userChoice;

        setDeferredPrompt(null);
    };

    const installMessage = isIOS
        ? <p>Tap &nbsp;<img src={safariIcon} />&nbsp; on the bottom of the screen
        and then select "Add to Home Screen".</p>
        : 'Do you want to install this application to your home screen?';

    return (
        <Dialog
            open={!isPwaInstalled && pwaInstallDialogOpen}
            onClose={handleCloseDialog}
            disableBackdropClick
        >
            <DialogTitle>{`Install ${document.title}?`}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {installMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {!isIOS && <Button
                    onClick={() => {
                        handleInstall(deferredPrompt);
                    }}
                    color="primary"
                >
                    Install
                </Button>}
                <Button onClick={handleCloseDialog} color="primary" autoFocus>
                    {isIOS ? 'Ok' : 'Cancel'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PwaInstallDialog;
