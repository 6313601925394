import React, { useState } from 'react';
import {
	Toolbar,
	Button,
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Grow,
	Icon,
	IconButton,
} from '@material-ui/core';
import { connect, goBack } from '@optum-uhone-hmkts/rise';
import { NavigationProps } from '../nav/Routes';
import { themePalette } from '../../utilities/branding';
import { HeaderBarComponent, iconButtonStyles } from './HeaderBar';
import { useNavigationProps } from '../../utilities/hooks';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

interface Props {
	title: string;
	subtitle?: string;
	isSaveDisabled?: boolean;
	hideSave?: boolean;
	isSecondaryButtonDisabled?: boolean;
	saveText?: string;
	secondaryButtonText?: string;
	onSave: () => void;
	onSecondaryButtonClick?: () => void;
	cancelText?: string;
	onCancel?: () => void;
	useBackArrow?: boolean;
	noSave?: boolean;
	overrideCancelDialog?: boolean;
	bypassCancelConfirmation?: boolean;
	trueCancelAndClose?: boolean;
	isLoading?: boolean;
}
export const SaveCancelHeaderBarComponent: React.FC<Props> = (props) => {

	const { 
		title, subtitle, isSaveDisabled, hideSave, isSecondaryButtonDisabled, saveText,
		secondaryButtonText, onSave, onSecondaryButtonClick, cancelText, onCancel,
		useBackArrow, noSave, overrideCancelDialog, bypassCancelConfirmation, trueCancelAndClose, isLoading,
	} = props;

	const { navigateBack } = useNavigationProps();
	const [isCancelDialogOpen, setCancelDialogOpen] = useState(false);
	const handleCancelDialogOpen = () => {
		if (bypassCancelConfirmation) {
			handleTrueCancel();
		} else {
			setCancelDialogOpen(true);
		}
	};

	const handleCancelDialogClose = () => {
		setCancelDialogOpen(false);
	};

	const handleTrueCancel = () => {
		if (typeof onCancel === 'function') {
			if (trueCancelAndClose) {
				handleCancelDialogClose();
			}
			onCancel();
		} else {
			navigateBack();
		}
	};

	const composeLeftIcon = (): JSX.Element => {
		return (
			useBackArrow
				? <IconButton
					color="inherit"
					onClick={
						overrideCancelDialog && onCancel
							? handleTrueCancel
							: handleCancelDialogOpen
					}
				>
					<ChevronLeftIcon />
				</IconButton>
				: <Button
					style={{ color: themePalette.default_background, paddingTop: 7 }}
					onClick={
						overrideCancelDialog && onCancel
							? handleTrueCancel
							: handleCancelDialogOpen
					}
				>
					{cancelText || 'CANCEL'}
			</Button>
		);
	};

	const composeSecondaryIcon = (): JSX.Element => {
		if (noSave) {
			return <span />;
		}
		return (
			<Button
				style={{
					color: themePalette.negative_text,
					opacity: isSecondaryButtonDisabled ? 0.4 : 1,
				}}
				disabled={isSecondaryButtonDisabled}
				onClick={onSecondaryButtonClick}
			>
				{secondaryButtonText || 'Follow-Up'}
			</Button>
		);
	};

	const composeRightIcon = () => {
		if (noSave) {
			return <span />;
		}
		return hideSave
			? null
			: (
				<Button
					style={{
						color: themePalette.default_background,
						opacity: isSaveDisabled ? 0.4 : 1,
					}}
					disabled={isSaveDisabled}
					onClick={onSave}
				>
					{saveText || 'SAVE'}
				</Button>
			);
	};

	return (
		<HeaderBarComponent
			hideTopRow
			isLoading={isLoading}
			customContent={
				<Toolbar>
					{composeLeftIcon()}
					<Typography
						variant="h6"
						style={{ flex: 1, textAlign: 'center' }}
					>
						{subtitle 
							? (
								<div>
									<span
										style={{
											color: themePalette.negative_text,
											position: 'relative',
											top: 8,
										}}
									>
										{title}
									</span>
									<span style={{ color: themePalette.negative_text, fontSize: 14 }}>
										<br />
										{subtitle}
									</span>
								</div>
							)
							: (
								<span style={{ color: themePalette.negative_text }}>
									{title}
								</span>
							)
						}
					</Typography>
					{onSecondaryButtonClick ? composeSecondaryIcon() : undefined}
					{composeRightIcon()}
					<Dialog
						disableBackdropClick
						disableEscapeKeyDown
						onClose={handleCancelDialogClose}
						open={isCancelDialogOpen}
						TransitionComponent={Grow}
					>
						<DialogTitle>Confirmation</DialogTitle>
						<DialogContent>
							Are you sure you want to cancel without saving?
						</DialogContent>
						<DialogActions>
							<Button
								onClick={handleCancelDialogClose}
							>
								Stay
							</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={handleTrueCancel}
							>
								Leave
							</Button>
						</DialogActions>
					</Dialog>
				</Toolbar>
			}
		/>
	);
};
