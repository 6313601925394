import {
	Avatar,
	Button,
	Card,
	CardContent,
	CardHeader,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Icon,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemIcon,
	ListItemText,
} from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import { isNullOrUndefined } from 'util';
import { DeleteActivity, EditActivity, GetActivity } from '../../actions/activity_actions';
import { GetEmployer } from '../../actions/employer_actions';
import { GetHousehold } from '../../actions/household_actions';
import { navigateBack, navigateTo } from '../../actions/navigation_actions';
import { createNote, CreateNoteParams, deleteNote, UpdateNote } from '../../actions/note_actions';
import { PhoneIcon } from '../../components/Layout/contact_header_bar/phone_icon';
import { ActivityDispositionDialog } from '../../components/activity/disposition_dialog';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { NavigationProps, navRoutes } from '../../components/nav/Routes';
import { NoteList } from '../../components/notes/note_list';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import { themePalette, themeLinks } from '../../utilities/branding';
import { AppState } from '../../reducers';
import { Activity } from '../../reducers/activity_reducer';
import { Employer } from '../../reducers/employer_reducer';
import { Lead } from '../../reducers/LeadReducer';
import { Note } from '../../reducers/note_reducer';
import { getPageLeads } from '../../selectors/lead_selectors';
import { getPageNotes } from '../../selectors/note_selectors';
import { openMaps } from '../../utilities/maps';
import { makeCall } from '../../utilities/phone_email_util';
import { Loaded } from '../../utilities/utilities';
import { MoreMenu, createMenuAction, createHelpAction } from '../../components/nav/more_menu';
import { fullName, formatPhone } from '../../assets/common/string_builders';
import { Strings } from '../../assets/common/strings';
import { Phone } from '../../reducers/ContactReducer';
import { displayDate, DateTimeFormat } from '../../utilities/date_util';
import { LeadInfoDialog } from '../lead/lead_info_modal';
import { ToggleLeadInfoDialog } from '../../actions/dialogs_actions';
import CheckIcon from '@material-ui/icons/Check';
import { openExternalLink } from '../../utilities';
import { AppConfig } from '../../types/config';

interface StateProps {
	activity: Loaded<Activity>;
	userId: string;
	firstName: string;
	preferredName: string;
	lastName: string;
	notes: Loaded<Note>[];
	employers: Loaded<Employer>[];
	householdId?: string;
	employerId?: string;
	leads: Loaded<Lead>[];
	desktopCallEnabled: boolean;
	impersonatingId?: string;
	activities: Loaded<Activity>[];
}

interface DispatchProps {
	editActivity: (activityToEdit: Activity) => void;
	deleteActivity: (activityToDelete: Activity) => void;
	getActivity: (activityId: string) => void;
	deleteNote: (noteToDelete: Note) => void;
	createNote: (newNoteParams: CreateNoteParams) => void;
	updateNote: (noteToUpdate: Note) => void;
	getHousehold: (id: string) => void;
	getEmployer: (id: string) => void;
	openLeadListDialog: () => void;
}

interface Props extends StateProps, DispatchProps, NavigationProps {

}

interface State {
	confirmDelete: boolean;
	employerName: string;
	dispositionDialogIsOpen: boolean;
	passContact: boolean;
	createNoteDialogOpen: boolean;
	isLeadInfoModalOpen?: boolean;
}

export const determineActivityStatus = (activity: Activity) => {
	switch (activity?.type) {
		case Strings.Activity.LeadStatusChange:
		case Strings.Activity.Quoted:
		case Strings.Activity.Sms:
			return activity?.description;
		case Strings.Activity.Email:
			return activity?.subject || 'Subject no longer available.';
		default:
			return activity?.disposition || 'No Status Set';
	}
};

class ActivityDetail extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			confirmDelete: false,
			employerName: '',
			dispositionDialogIsOpen: false,
			passContact: false,
			createNoteDialogOpen: false,
		};

		this.handleCheckClick = this.handleCheckClick.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleDeleteClick = this.handleDeleteClick.bind(this);
		this.handleCancelDelete = this.handleCancelDelete.bind(this);
	}

	componentWillMount() {
		if (this.props.match.params.activity_id)
			this.props.getActivity(this.props.match.params.activity_id);

		if (this.props.activity && this.props.activity.employerId)
			this.props.getEmployer(this.props.activity.employerId);


	}


	componentWillReceiveProps(nextProps: Props) {
		// Load leads into Redux to try and find an open lead to update

		if (
			this.props.activity &&
			this.props.activity.data.id !== nextProps.activity.data.id
		) {
			if (
				nextProps.activity &&
				nextProps.activity.employerId &&
				this.props.activity.employerId != nextProps.activity.employerId
			)
				this.props.getEmployer(nextProps.activity.employerId);
			else if (
				nextProps.activity &&
				nextProps.activity.data.householdId &&
				Boolean(nextProps.activity.data.householdId) &&
				this.props.activity.householdId != nextProps.activity.householdId
			)
				this.props.getHousehold(nextProps.activity.data.householdId);
		}

		if (this.props.employerId) {
			const emps = nextProps.employers.filter(
				e => e.data.id == this.props.employerId && e.data.companyName
			);
			if (emps.length > 0) {
				this.setState({ employerName: emps[0].data.companyName });
			}
		}
	}

	navigateToRoute = (route: string) =>
		this.props.navigateTo( route);

	public handleCheckClick(activity: Activity) {
		let activityToUpdate = Object.assign({}, activity) as Activity;
		const currentTime = moment.utc().toDate().toDateString();
		const name = fullName(this.props);

		if (activityToUpdate.status == Strings.ActivityStatus.Completed) {
			activityToUpdate.completedBy = undefined;
			activityToUpdate.completedByName = undefined;
			activityToUpdate.completedOn = undefined;
			activityToUpdate.status = Strings.ActivityStatus.Open;
			activityToUpdate.updatedBy = this.props.userId;
			activityToUpdate.modifiedByName = name;
			activityToUpdate.updatedOn = new Date(currentTime);
			this.props.editActivity(activityToUpdate);
		} else {
			if (
				activityToUpdate.type == Strings.Activity.PhoneCall ||
				activityToUpdate.type == Strings.Activity.Appointment
			) {
				this.openDispositionDialog();
			} else {
				activityToUpdate.completedBy = this.props.userId;
				activityToUpdate.completedByName = name;
				activityToUpdate.completedOn = new Date(currentTime);
				activityToUpdate.status = Strings.ActivityStatus.Completed;
				activityToUpdate.updatedBy = this.props.userId;
				activityToUpdate.modifiedByName = name;
				activityToUpdate.updatedOn = new Date(currentTime);
				this.props.editActivity(activityToUpdate);
			}
		}
	}

	public handleDeleteClick() {
		this.setState({
			confirmDelete: true,
		});
	}

	public handleCancelDelete() {
		this.setState({ confirmDelete: false });
	}

	public handleDelete(activity: Activity) {
		let activityToDelete = Object.assign({}, activity);
		const currentTime = moment.utc().toDate().toDateString();
		const name = fullName(this.props);
		activityToDelete.updatedBy = this.props.userId;
		activityToDelete.modifiedByName = name;
		activityToDelete.updatedOn = new Date(currentTime);

		this.setState({ confirmDelete: false });
		this.props.navigateBack();
		this.props.deleteActivity(activityToDelete);
	}

	handleClick = (householdId: string, contactId: string) => {
		this.props.navigateTo(
			navRoutes.contactDetail.path
				.replace(Strings.Navigation.HouseholdId, householdId)
				.replace(Strings.Navigation.ContactId, contactId)
		);
	};

	generatePhoneList = (phones: Phone[]): JSX.Element[] => {
		let phoneNumbers: JSX.Element[] = [];
		let numberListItem: JSX.Element;
		let isPreferred: string = '';

		phones.filter(phone => phone.isPreferred).map((phone, index) => {
			const isDNC: boolean = phone.isCompanyDnc;
			numberListItem = (
				<ListItem
					key={phone.number + '_' + index}
					button
					onClick={() => {
						if (this.props.activity.data.contact) {
							this.openDispositionDialog();
						}
						makeCall(phone.number, this.props.desktopCallEnabled);
					}}
					disabled={isDNC}
				>
					<ListItemAvatar>
						<PhoneIcon phone={phone} avatar />
					</ListItemAvatar>
					<ListItemText
						primary={formatPhone(phone.number)}
						secondary={
							phone.type != null
								? isPreferred ? isPreferred + ', ' + phone.type : phone.type
								: phone.isPreferred ? 'Preferred' : isPreferred
						}
					/>
				</ListItem>
			);
			phoneNumbers.push(numberListItem);
		});
		return phoneNumbers;
	};

	generateAltPhoneListItem = (number: string): JSX.Element => {
		return (
			<ListItem
				key={number}
				button
				onClick={() => {
					if (this.props.activity.data.contact) {
						this.openDispositionDialog();
					}
					makeCall(number, this.props.desktopCallEnabled);
				}}
			>
				<ListItemAvatar>
					<PhoneIcon phone={number} avatar />
				</ListItemAvatar>
				<ListItemText
					primary={number ? number : ''}
					secondary={'Alternate Phone Number'}
				/>
			</ListItem>
		);
	};

	openInsite = () => {
		if (this.props.activity.employerId) {
			const url = AppConfig.benefitter_link;
			openExternalLink(url);
		} else if (this.props.activity.householdId) {
			const url = `
				${themeLinks.quoteConnect}
				Proposal/CreateFromOpportunity/
				${this.props.leads[0].data.id}
			`;
			openExternalLink(url);
		}
	};

	locationGenerator = (location: string): string[] => {
		const commaArray = location.split(',')
		const finalArray: string[] = [];
		const stateZip = commaArray[commaArray.length - 1].trim();
		const stateZipArray = stateZip.split(' ');

		if (stateZipArray[0].length == 2) {
			commaArray[commaArray.length - 1] = (' ' + stateZipArray[0]);
			commaArray.push(' ' + stateZipArray[1]);
		};

		const cityIndex = commaArray.length - 3;
		const topSplit = commaArray.slice(0, cityIndex).join(',');
		const bottomSplit = commaArray.slice(cityIndex).join(',');
		finalArray.push(topSplit);
		finalArray.push(bottomSplit);
		return finalArray;
	};

	openDispositionDialog = () =>
		this.setState({ dispositionDialogIsOpen: true });

	closeDispositionDialog = () =>
		this.setState({ dispositionDialogIsOpen: false });

	toggleDispositionDialog = (toggle: boolean, passContact?: boolean) => {
		if (passContact) {
			this.setState({ passContact: passContact });
		}

		this.setState({ dispositionDialogIsOpen: toggle });
	};

	handleSaveNote = (noteContent: string) => {
		const newNoteParams = {
			householdId: this.props.match.params.householdID,
			content: noteContent,
			userId:
				this.props.userId != this.props.impersonatingId &&
					this.props.impersonatingId
					? this.props.impersonatingId
					: this.props.userId,
		};
		this.props.createNote(newNoteParams);
	}

	editActivity = () => {
		const { type } = this.props.activity.data;
		if (type == Strings.Activity.Appointment) {
			this.props.navigateTo(
				navRoutes.appointmentEdit.path
					.replace(Strings.Navigation.ActivityId, this.props.match.params.activity_id)
					.replace(Strings.Navigation.Reschedule, '0')
			);
		} else if (type == Strings.Activity.PhoneCall) {
			this.props.navigateTo(
				navRoutes.phoneEdit.path
					.replace(Strings.Navigation.ActivityId, this.props.match.params.activity_id)
					.replace(Strings.Navigation.Reschedule, '0')
			);
		} else if (type == Strings.Activity.Task) {
			this.props.navigateTo(
				navRoutes.activityEdit.path.replace(
					Strings.Navigation.ActivityId,
					this.props.match.params.activity_id
				)
			);
		}
	};

	public render() {
		if (!this.props.activity) return <span />;

		const activity = this.props.activity.data;
		const type = activity.type;

		let avatarColor: string = 'transparent';
		if (activity.status == 'completed') {
			avatarColor = themePalette.selected_item;
		}

		const menuItems = [
			<IconButton
				key="done"
				onClick={() => this.handleCheckClick(activity)}
				style={{ width: 48, height: 48 }}
			>
				<Avatar
					style={{
						backgroundColor: avatarColor,
						height: 30,
						width: 30,
					}}
				>
					<CheckIcon style={{ color: themePalette.negative_icon }} />
				</Avatar>
			</IconButton>,
			<MoreMenu
				children={[
					createMenuAction(Strings.MoreMenu.Edit, this.editActivity),
					createMenuAction(Strings.MoreMenu.Delete, this.handleDeleteClick),
					createHelpAction(themeLinks.helpLinkCRM),
				]}
			/>,
		];

		const important = activity.isHighPriority ? (
			<span
				style={{
					color: themePalette.required_text,
					fontWeight: 700,
					fontSize: 18,
					paddingRight: 3,
				}}
			>
				!
			</span>
		) : null;

		const contact = activity.contact;

		let location = '';
		let locationBottom = '';
		if (activity.location) {
			let locationArray = this.locationGenerator(activity.location);
			location = locationArray[0];
			locationBottom = locationArray[1];
		}

		const dueTime = displayDate(
			moment.utc(activity.time).local(),
			DateTimeFormat.FullDateTime
		);

		return (
			<BasePageContainer
				topComponent={
					<HeaderBarComponent
						title={type}
						forceOverflow
						rightButtons={menuItems}
						isLoading={this.props.activity.loading}
					/>
				}
			>
				<PersistentNotifications />
				<ActivityDispositionDialog
					open={this.state.dispositionDialogIsOpen}
					isAppointment={type == Strings.Activity.Appointment}
					activities={[this.props.activity.data]}
					closeDispositionDialog={this.closeDispositionDialog}
					navigateTo={this.navigateToRoute}
				/>
				<Dialog
					disableBackdropClick
					disableEscapeKeyDown
					open={this.state.confirmDelete}
				>
					<DialogTitle>Confirmation</DialogTitle>
					<DialogContent>
						{'Are you sure you want to delete this ' + type + '?'}
					</DialogContent>
					<DialogActions>
						<Button color="secondary" onClick={this.handleCancelDelete}>
							Cancel
						</Button>
						<Button
							color="primary"
							style={{ backgroundColor: themePalette.delete_remove_reject_button }}
							onClick={() => this.handleDelete(activity)}
							variant="contained"
						>
							Delete
						</Button>
					</DialogActions>
				</Dialog>
				<Grid container>
					<Grid item lg={3} />
					<Grid item xs={12} lg={6}>
						<Card style={styles.paperCardStyle}>
							<CardHeader
								avatar={
									activity.type != Strings.Activity.Task ?
										<IconButton onClick={this.props.openLeadListDialog}>
											<Icon style={styles.infoIcon}>info_outline</Icon>
										</IconButton> : ''
								}
								style={styles.wordWrap}
								title={
									<span style={styles.title}>
										{important}
										{activity.title}
									</span>
								}
							/>
							<CardContent
								style={{
									...styles.wordWrap,
									marginTop: -15,
									whiteSpace: 'pre-wrap'
								}}
							>
								{activity.description}
								<div
									style={{
										color: themePalette.sub_text,
										paddingTop: '10px',
									}}
								>
									{type == Strings.Activity.Task ? (
										<span>Due </span>
									) : (
											<span />
										)}
									{dueTime}
									<br />
									{activity.duration != 'none' ? (
										<span>Duration: {activity.duration}</span>
									) : (
											<span />
										)}
									<br />
									{type != Strings.Activity.Task &&
										activity.status == Strings.ActivityStatus.Completed ? (
											<span>
												Activity Status: {determineActivityStatus(activity)}
											</span>
										) : (
											<span />
										)}
								</div>
								{type == Strings.Activity.PhoneCall ||
									type == Strings.Activity.Appointment ? (
										<div style={styles.paddingStyle}>
											<Button
												onClick={this.openInsite}
											>
												Quote <Icon>launch</Icon>
											</Button>
										</div>
									) : null}
								{(type == Strings.Activity.PhoneCall ||
									type == Strings.Activity.Appointment) &&
									activity.contact &&
									activity.contact.id != null ? (
										<div>
											<div style={styles.paddingStyle}>
												<Chip
													onClick={() => {
														this.handleClick(contact.householdId, contact.id);
													}}
													label={
														activity.contact.employerId
															? `${
															this.state.employerName
															} - ${fullName(contact)}`
															: fullName(contact)
													}
												/>

												{activity.location ? (
													<div>
														<div style={styles.paddingStyle}>Location</div>
														<List>
															<ListItem
																button
																onClick={() => openMaps(activity.location)}
															>
																<ListItemIcon>
																	<Icon
																		style={{
																			color: themePalette.sub_text,
																			fontSize: '40px',
																			paddingBottom: '15px',
																			paddingRight: '15px',
																		}}
																		className="icon-location"
																	/>
																</ListItemIcon>
																<ListItemText
																	style={styles.wordWrap}
																	primary={location}
																	secondary={locationBottom}
																/>
															</ListItem>
														</List>
													</div>
												) : null}
											</div>

											<div style={styles.paddingStyle}>
												<span>
													{contact.phones.length > 0 ||
														activity.alternatePhoneNumber
														? 'Phone Numbers'
														: ''}
												</span>
												<List>
													{activity.alternatePhoneNumber
														? this.generateAltPhoneListItem(
															activity.alternatePhoneNumber
														)
														: null}
													{this.generatePhoneList(contact.phones)}
												</List>
											</div>
										</div>
									) : null}
								{type == Strings.Activity.PhoneCall ||
									type == Strings.Activity.Appointment ? (
										<div style={styles.paddingStyle}>
											Notes:
											<div style={styles.paddingStyle}>
												<NoteList
													notes={this.props.notes}
													handleDeleteNote={this.props.deleteNote}
													canDelete={false}
													updateNote={this.props.updateNote}
													handleSaveNote={(noteContent: string) =>
														this.handleSaveNote(noteContent)
													}
													createNoteDialogIsOpen={this.state.createNoteDialogOpen}
													updateCreateNoteDialogState={(isOpen: boolean) =>
														this.setState({ createNoteDialogOpen: isOpen })
													}
												/>
											</div>

										</div>
									) : null}
								<div
									style={{
										wordWrap: 'break-word',
										fontSize: '12px',
										marginTop: 20,
									}}
								>
									<div
										onClick={() =>
											this.props.navigateTo(navRoutes.profile.path)
										}
									>
										Created on{' '}
										{moment(activity.createdOn).format('MM-DD-YYYY @ hh:mm A')}{' '}
										by {activity.createdByName}
										<br />
										{activity.modifiedBy ? (
											<div>
												Modified on{' '}
												{moment(activity.modifiedOn).format(
													'MM-DD-YYYY @ hh:mm A'
												)}{' '}
												by {activity.modifiedByName}
											</div>
										) : null}
										<br />
										{activity.completedBy ? (
											<div
												style={{
													position: 'relative',
													top: '-18px',
												}}
											>
												Completed on{' '}
												{moment(activity.completedOn).format(
													'MM-DD-YYYY @ hh:mm A'
												)}{' '}
												by {activity.completedByName}
											</div>
										) : null}
									</div>
								</div>
							</CardContent>
						</Card>
					</Grid>
				</Grid>

				<LeadInfoDialog
					householdId={this.props.householdId || ""}
				/>
			</BasePageContainer>
		);
	}
}

function mapStateToProps(
	state: AppState,
	props: Props
): StateProps {

	const activity = state.activity.activities.find(
		activity => activity.data.id == props.match.params.activity_id
	);
	let notes = getPageNotes(state, props);
	const leads = getPageLeads(state, props);
	let filteredLeads: Loaded<Lead>[] = [];

	if (activity && activity.employerId) {
		notes = notes.filter(
			note =>
				activity &&
				activity.employerId &&
				note.employerId &&
				note.employerId == activity.employerId
		);
		filteredLeads = leads.filter(
			lead =>
				!isNullOrUndefined(lead.householdId) &&
				lead.employerId &&
				activity.employerId &&
				lead.employerId == activity.employerId
		);
	} else if (activity && activity.householdId) {
		notes = notes.filter(
			note => activity && note.householdId == activity.householdId
		);
		filteredLeads = leads.filter(
			lead =>
				!isNullOrUndefined(lead.householdId) &&
				lead.householdId == activity.householdId
		);
	} else {
		notes = [];
	}
	return {
		activity: activity!,
		userId: state.user.id,
		firstName: state.agent.firstName,
		preferredName: state.agent.preferredName,
		lastName: state.agent.lastName,
		employers: state.employer.employers, notes,
		householdId: activity && activity.householdId ? activity.householdId : undefined,
		employerId: activity && activity.employerId ? activity.employerId : undefined,
		leads: filteredLeads,
		desktopCallEnabled: state.user.enableDesktopCalls,
		impersonatingId: state.user.impersonatingId,
		activities: state.activity.activities,
	};
}

function mapDispatchToProps(dispatch: any): DispatchProps & Partial<NavigationProps> {
	return {
		navigateTo: (route: string) =>
			dispatch(navigateTo(route)),
		navigateBack: () => dispatch(navigateBack()),
		getActivity: (activityId: string) =>
			dispatch(GetActivity.started(activityId)),
		editActivity: (activityToEdit: Activity) =>
			dispatch(EditActivity.started(activityToEdit)),
		deleteActivity: (activityToDelete: Activity) =>
			dispatch(DeleteActivity.started(activityToDelete)),
		deleteNote: (noteToDelete: Note) => dispatch(deleteNote(noteToDelete)),
		getHousehold: (id: string) => dispatch(GetHousehold.started(id)),
		getEmployer: (id: string) => dispatch(GetEmployer.started(id)),
		createNote: (newNoteParams: CreateNoteParams) => {
			dispatch(createNote(newNoteParams));
		},
		updateNote: (updatedNote: Note) =>
			dispatch(UpdateNote.started(updatedNote)),
		openLeadListDialog: () => dispatch(ToggleLeadInfoDialog(true)),

	};
}

export const ActivityDetailContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
	true
)(ActivityDetail);

const styles: { [key: string]: React.CSSProperties } = {
	wordWrap: {
		wordBreak: 'break-word',
	},
	paperCardStyle: {
		margin: '0 10px 10px',
	},
	paddingStyle: {
		paddingTop: '10px',
	},
	infoIcon: {
		height: '28px',
		width: '28px',
		paddingTop: '4px',
		margin: 'auto',
		display: 'block',
		color: themePalette.tertiary_text,
	},
	title: {
		fontSize: '1.25rem',
	},
};
