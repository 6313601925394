import React from 'react';
import { defaultSvgProps, SvgProps } from '..';

const LeadConfigSvg: React.FC<SvgProps> = (props) => (
    <svg viewBox="0 0 35 35" {...props}>
        <path
            d="M12.57 6a4 4 0 0 0-3.75 4.24 4 4 0 0 0 3.75 4.24 4 4 0 0 0 3.74-4.24A4 4 0 0 0 12.57 6zm0 7.51a3.06 3.06 0 0 1-2.78-3.27A3.06 3.06 0 0 1 12.57 7a3.05 3.05 0 0 1 2.77 3.27 3.05 3.05 0 0 1-2.77 3.27zM18.88 23.43zm.09 0l.37.08v-.08zm-.48-5.27a3.52 3.52 0 0 0-1-.73c-.09 0-.17-.09-.26-.12a14.24 14.24 0 0 0-9.49 0 3.67 3.67 0 0 0-2.29 3.39l.27 4a.5.5 0 0 0 .49.46h11.14a1.34 1.34 0 0 1-.12-1H6.67l-.25-3.6a2.72 2.72 0 0 1 1.65-2.4 13.27 13.27 0 0 1 8.84 0 2.69 2.69 0 0 1 1.64 2.4L18.46 22a1.48 1.48 0 0 1 .42-.07 1.61 1.61 0 0 1-.07-.85 1.47 1.47 0 0 1 .64-.89 3.68 3.68 0 0 0-.96-2.02z"
        />
        <path
            d="M18.88 23.43zm.09 0l.37.08v-.08zm-.48-5.27a3.52 3.52 0 0 0-1-.73c-.09 0-.17-.09-.26-.12a14.24 14.24 0 0 0-9.49 0 3.67 3.67 0 0 0-2.29 3.39l.27 4a.5.5 0 0 0 .49.46h11.14a1.34 1.34 0 0 1-.12-1H6.67l-.25-3.6a2.72 2.72 0 0 1 1.65-2.4 13.27 13.27 0 0 1 8.84 0 2.69 2.69 0 0 1 1.64 2.4L18.46 22a1.48 1.48 0 0 1 .42-.07 1.61 1.61 0 0 1-.07-.85 1.47 1.47 0 0 1 .64-.89 3.68 3.68 0 0 0-.96-2.02zM21.12 8.51a3.49 3.49 0 0 0-3.25 3.68 3.48 3.48 0 0 0 3.25 3.66 3.48 3.48 0 0 0 3.24-3.66 3.48 3.48 0 0 0-3.24-3.68zm0 6.37a2.51 2.51 0 0 1-2.27-2.69 2.52 2.52 0 0 1 2.27-2.7 2.52 2.52 0 0 1 2.27 2.7 2.51 2.51 0 0 1-2.27 2.69z"
        />
        <path
            d="M19.34 23.44v.08l-.37-.08h.37zM27.08 20.54v.08a1.49 1.49 0 0 0-.36-.05 1.46 1.46 0 0 0-.52.1 1.47 1.47 0 0 0-.1-.54 2.29 2.29 0 0 0-1.33-1.62 11.16 11.16 0 0 0-6.27-.34c-.28.07-.56.14-.83.23a.48.48 0 1 1-.3-.92h.13a12 12 0 0 1 7.61.16 3.16 3.16 0 0 1 1.97 2.9zM18.93 23.44zM18.89 23.45zM19 23.44zM18.89 23.45zM19 23.44zM18.93 23.44zm0 0zm0 0z"
        />
        <path
            d="M28.37 25a.67.67 0 0 0-.45-.32l-.73-.15v-.07l-.1-.46.63-.4a.72.72 0 0 0 .22-1l-.62-1a.65.65 0 0 0-.27-.25.7.7 0 0 0-.35-.09.66.66 0 0 0-.38.11l-.63.4h-.07l-.32-.22a.11.11 0 0 1 0-.05l.16-.73a.73.73 0 0 0-.1-.55.69.69 0 0 0-.45-.31l-1.15-.25h-.16a.71.71 0 0 0-.7.57l-.15.72a.12.12 0 0 1-.08 0l-.37.07-.42-.63a.71.71 0 0 0-.45-.32h-.15a.69.69 0 0 0-.39.11l-1 .63a.71.71 0 0 0-.22 1l.4.62a.06.06 0 0 1 0 .07l-.21.33-.86-.12a.79.79 0 0 0-.54.09.76.76 0 0 0-.17.15.59.59 0 0 0-.15.32l-.25 1.13a.69.69 0 0 0 .1.54.7.7 0 0 0 .45.32l.73.16v.06l.06.39-.64.4a.79.79 0 0 0-.32.45.71.71 0 0 0 .11.55l.62 1a.77.77 0 0 0 .45.31.44.44 0 0 0 .16 0 .63.63 0 0 0 .38-.11l.65-.4h.06l.31.21a.14.14 0 0 1 0 .06l-.16.73a.74.74 0 0 0 .56.86l1.12.24a.52.52 0 0 0 .17 0 .74.74 0 0 0 .71-.56l.15-.73a.12.12 0 0 1 .07 0l.38-.07h.05l.39.61a.72.72 0 0 0 .62.33.66.66 0 0 0 .38-.11l1-.63a.77.77 0 0 0 .32-.45.74.74 0 0 0-.11-.54l-.4-.62a.1.1 0 0 1 0-.07l.21-.32h.05l.73.16a.47.47 0 0 0 .16 0 .72.72 0 0 0 .71-.57l.24-1.14a.69.69 0 0 0 .01-.46zm-9.45-1.53zm-.05 0zm8.59 2.94l-.67-.15h-.16a.88.88 0 0 0-.72.37l-.2.29a.89.89 0 0 0 0 1l.36.55-.84.53-.36-.56a1 1 0 0 0-.75-.38H24l-.34.07a.89.89 0 0 0-.74.66l-.13.65-1-.21.14-.66a.83.83 0 0 0-.36-.88l-.28-.19a.9.9 0 0 0-.34-.17.89.89 0 0 0-.63.11l-.56.35-.58-.79.57-.36a.88.88 0 0 0 .36-.89l-.06-.34a.88.88 0 0 0-.66-.73l-.65-.14.18-.88v-.09l.3.07h.16l.17.05h.18a.82.82 0 0 0 .5-.17.49.49 0 0 0 .22-.21l.2-.29a.91.91 0 0 0 0-1l-.36-.54.83-.54.35.55a.93.93 0 0 0 .76.39h.15l.32-.07a.86.86 0 0 0 .74-.66l.14-.65 1 .21-.14.65a.86.86 0 0 0 .37.89l.28.2a.83.83 0 0 0 .53.18.76.76 0 0 0 .32-.06.61.61 0 0 0 .14-.07l.56-.35.54.81-.57.37a.88.88 0 0 0-.37.9l.06.33a.86.86 0 0 0 .67.73l.65.14z"
        />
        <path d="M18.93 23.44zm0 0zm0 0zm0 0z" fill="#fff" />
        <path d="M18.89 23.45zM19 23.44z" />
        <path
            d="M25 24.42a1.86 1.86 0 0 0-2.78-1l-.07.05a1.81 1.81 0 0 0-.7 1 .33.33 0 0 0 0 .13 1.39 1.39 0 0 0 0 .4 1.85 1.85 0 0 0 1.45 1.82 1.76 1.76 0 0 0 1.4-.25 1.84 1.84 0 0 0 .78-2.1zm-.6.79a1.22 1.22 0 0 1-.53.76 1.16 1.16 0 0 1-.64.19 1.09 1.09 0 0 1-.26 0A1.19 1.19 0 0 1 22 24.7a.94.94 0 0 1 .1-.28 1.2 1.2 0 0 1 1.07-.67.77.77 0 0 1 .26 0 1.18 1.18 0 0 1 .76.53.39.39 0 0 1 .06.11 1.14 1.14 0 0 1 .13.82z"
            strokeWidth={0.2}
        />
    </svg>
);
LeadConfigSvg.defaultProps = defaultSvgProps;

export default LeadConfigSvg;
