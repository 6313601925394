import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { themePalette } from '../branding';
import { isIOS, isIPhoneX } from '../is_mobile';

export const useListItemStyles = makeStyles((theme: Theme) =>
	createStyles({
		small: {
			width: theme.spacing(4),
			height: theme.spacing(4),
		},
		rightText: {
			position: 'absolute',
			top: 10,
			right: 20,
			color: themePalette.tertiary_text,
		},
		padded: {
			paddingTop: 10,
			paddingBottom: 10,
		},
		textPadding: {
			paddingRight: '4em',
		},
		leftIcon: {
			minWidth: 50,
		},
		arrowIcon: {
			minWidth: 40,
		},
		fontBold: {
			fontWeight: 'bold',
		},
		fontNormal: {
			fontWeight: 'normal',
		},
		disabled: {
			opacity: 0.4,
		},
		margin: {
			margin: 10,
		},
	})
);

export const useBubbleStyles = makeStyles((theme: Theme) =>
	createStyles({
		bubble: {
			background: 'transparent',
			border: `2px solid ${themePalette.default_background}`,
			borderRadius: '50%',
			cursor: 'pointer',
			height: 90,
			width: 90,
			textAlign: 'center',
			display: 'inline-block',
			justifyContent: 'center',
		},
		content: {
			display: 'flex',
			fontFamily: 'Roboto, sans-serif, thin',
			placeContent: 'center',
			alignItems: 'center',
			fontSize: '22px',
			color: themePalette.negative_text,
			marginTop: 'calc(50% - 12px)',
			justifyContent: 'center',
		},
		caption: {
			color: themePalette.negative_text,
			fontWeight: 700,
			fontSize: '9.5pt',
			textAlign: 'center',
			marginTop: 10,
			fontFamily: 'Roboto, sans-serif, thin',
			marginLeft: 0,
			textDecoration: 'none',
		},
		noPadding: {
			padding: 0,
		},
	})
);

export const useCardStyles = makeStyles((theme: Theme) =>
	createStyles({
		expand: {
			transform: 'rotate(0deg)',
			transition: theme.transitions.create('transform', {
				duration: theme.transitions.duration.shortest,
			}),
		},
		expandOpen: {
			transform: 'rotate(180deg)',
		},
		padded: {
			padding: 16,
		},
		noPadding: {
			padding: 0,
		},
		header: {
			paddingTop: 2,
			paddingBottom: 2,
		},
		centered: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
		},
	})
);

export const useHeaderBarStyles = makeStyles((theme: Theme) =>
	createStyles({
		depth: {
			zIndex: 3,
		},
		top: {
			// paddingTop: (isIOS ? 20 : 0) + (isIPhoneX() ? 10 : 0),
		},
	})
);

export const useReshopCardStyles = makeStyles((theme: Theme) =>
	createStyles({
		currentPlanText: {
			color: themePalette.primary,
			margin: '10px 0px',
		},
		recommendedPlanText: {
			color: themePalette.favorite,
			margin: '10px 0px',
		},
		currentPlanCard: {
			borderColor: themePalette.primary,
			borderWidth: 2,
			borderRadius: 5,
			width: '100%',
		},
		recommededPlanCard: {
			borderColor: themePalette.favorite,
			borderWidth: 2,
			borderRadius: 5,
			width: '100%',
		},
		currentPlanExpansionText: {
			color: themePalette.primary,
		},
		recommendedPlanExpansionText: {
			color: themePalette.favorite,
		},
	})
);

export const useButtonCollapseStyles = makeStyles((theme: Theme) =>
	createStyles({
		primaryColor: {
			color: themePalette.primary,
		},
		recommendedColor: {
			color: themePalette.favorite,
		},
		primaryCollapse: {
			borderStyle: 'solid none',
			borderColor: themePalette.primary,
			borderWidth: 1,
			width: '100%',
			backgroundColor: themePalette.secondary_background,
		},
		recommendedCollapse: {
			borderStyle: 'solid none',
			borderColor: themePalette.favorite,
			borderWidth: 1,
			width: '100%',
			backgroundColor: themePalette.secondary_background,
		},
	})
);

export const useFitScoreRibbonStyles = makeStyles((theme: Theme) =>
	createStyles({
		ribbon: {
			position: 'absolute',
			maxWidth: 80,
			zIndex: 2,
			marginTop: -10,
			right: 10,
		},
		ribbonText: {
			marginTop: -60,
			color: themePalette.negative_text,
			textAlign: 'center',
			zIndex: 3,
		},
	})
);
