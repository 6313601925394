import { Lookup, Lookups } from './lookup';

let stages = [
	{ title: 'New', statuses: ['Not Contacted'] },
	{
		title: 'Attempted',
		statuses: [
			'Emailed - Awaiting Response',
			'Texted - Awaiting Response',
			'Called - No Answer',
			'Called - Left Message',
		],
	},
	{ title: 'Contacted', statuses: ['Callback Requested', 'Appointment Set'] },
	{
		title: 'Deals in Progress',
		statuses: ['Quoted', 'Application in Progress'],
	},
	{ title: 'Complete', statuses: ['No Sale', 'Sale'] },
];

export const isLeadStatusUpdateValid = (
	currentStatus: string,
	newStatus: string
) => {
	let newStage: number = -1;
	let currentStage: number = -1;

	for (var i in stages) {
		if (stages[i].statuses.indexOf(newStatus) > -1) {
			newStage = Number(i);
		}
		if (stages[i].statuses.indexOf(currentStatus) > -1) {
			currentStage = Number(i);
		}
	}

	if (newStage === -1 || currentStage === -1) {
		return false;
	}
	return newStage >= currentStage;
};

export const isLookupSequenceValid = (
	typeToCompare: Lookup,
	currentLookup: Lookup,
	newLookup: Lookup
) => {
	const currentStage = currentLookup.getFirstParentOrSelfOfType(typeToCompare)
		.sequence;
	const newStage = newLookup.getFirstParentOrSelfOfType(typeToCompare).sequence;
	return currentStage == -1 || newStage == -1
		? false
		: currentStage <= newStage;
};
