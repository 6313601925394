import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import { ReadOnlyTextField } from '../utility/read_only_text_field';
import { Lisence } from './license';
import { Agent, AgentAddress } from '../../reducers/agent_reducer';
import { wrapInCollapsibleCard } from '../higher_order_component/wrap_in_card';
import { Strings } from '../../assets/common/strings';

interface Props {
    agent: Agent;
    businessPhone?: string;
    personalPhone?: string;
    businessEmail?: string;
    personalEmail?: string;
    businessAddress?: AgentAddress;
    appointmentWebsite?: string;
    readOnly?: boolean;
    handleClickEditAppointmentWebsite?: () => void;
}

const ContactInformation: React.FC<Props> = (props) => {
    const { agent, businessPhone, personalPhone, businessEmail, personalEmail, businessAddress, appointmentWebsite, readOnly=false, handleClickEditAppointmentWebsite } = props;

    return (
        <Grid container>
            <Grid item xs={6}>
                <ReadOnlyTextField
                    label={Strings.AboutMeLabels.BusinessPhone}
                    value={businessPhone}
                />
            </Grid>
            <Grid item xs={12}>
                <ReadOnlyTextField
                    label={Strings.AboutMeLabels.BusinessEmailAddress}
                    value={businessEmail}
                />
            </Grid>
            {readOnly &&
                <>
                    <Grid item xs={6}>
                        <ReadOnlyTextField
                            label={Strings.AboutMeLabels.Address}
                            value={`${businessAddress?.address1 || ''} ${businessAddress?.address2 || ''}`}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ReadOnlyTextField 
                            label={Strings.AboutMeLabels.Location} 
                            value={`${businessAddress?.city ? `${businessAddress.city}, ` : ''}${businessAddress?.state}`} 
                        />
                    </Grid>
                </>
            }
            {!readOnly && 
                <>
                    <Grid item xs={6}>
                        <ReadOnlyTextField
                            label={Strings.AboutMeLabels.LiveTransfer}
                            value={agent?.businessPhoneLiveTransfer || ''}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ReadOnlyTextField
                            label={Strings.AboutMeLabels.LiveTransfer}
                            value={agent?.personalPhoneLiveTransfer || ''}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ReadOnlyTextField
                            label={Strings.AboutMeLabels.PersonalPhone}
                            value={personalPhone}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ReadOnlyTextField
                            label={Strings.AboutMeLabels.PersonalEmailAddress}
                            value={personalEmail}
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <ReadOnlyTextField
                            label={Strings.AboutMeLabels.AppointmentWebsite}
                            value={appointmentWebsite}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton onClick={handleClickEditAppointmentWebsite}>
                            <CreateIcon/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        {agent?.agentLicenses?.map(license =>
                            <Lisence key={license.LicenseNumber} license={license} />
                        )}
                    </Grid>
                </>
            }
        </Grid>
    );
}

export const ContactInformationCard = wrapInCollapsibleCard(ContactInformation);