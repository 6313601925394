import { isType, Action } from 'typescript-fsa';
import { GetAllDocuments, FilterDocuments, UpdateFilteredDocuments } from '../actions/document_actions';
import { Strings } from '../assets/common/strings';

export interface DocumentDto {
  id: string;
  title: string;
  description: string;
  fileExtension: string;
  urlPath: string;
  modifiedDate: string;
  createdDate: string;
  category: string;
  lineOfBusiness: string;
  favorite: boolean;
  viewsLifeTime: string;
  viewsRecent: string;
  fileType: string;
}

export interface DocumentForm {
  searchText: string;
  category: string;
  lineOfBusiness: string;
  onlyFavorites: boolean;
}

export interface DocumentState {
  loading: boolean;
  documents: DocumentDto[];
  filteredDocuments: DocumentDto[];
  filterForm: DocumentForm;
}

export const initialForm: DocumentForm = {
  searchText: '',
  category: Strings.DocumentFilterLabels.AllCategories,
  lineOfBusiness: Strings.DocumentFilterLabels.AllLinesOfBusiness,
  onlyFavorites: false,
};

const initialState: DocumentState = {
  loading: false,
  documents: [],
  filteredDocuments: [],
  filterForm: initialForm,
};

export const documentsReducer = (
  state: DocumentState = initialState,
  action: Action<any>
): DocumentState => {
  if (isType(action, GetAllDocuments.started)) {
    return {
      ...state,
      loading: action.payload.loading,
    };
  } else if (isType(action, GetAllDocuments.done)) {
    return {
      ...state,
      documents: action.payload.result,
      loading: false,
    };
  } else if (isType(action, GetAllDocuments.failed)) {
    return {
      ...state,
      loading: false,
    };
  } else if (isType(action, FilterDocuments)) {
    return {
      ...state,
      filterForm: action.payload,
    };
  } else if (isType(action, UpdateFilteredDocuments)) {
    return {
      ...state,
      filteredDocuments: action.payload,
    };
  } else {
    return state;
  }
};