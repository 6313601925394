import React from 'react';
import { defaultSvgProps, SvgProps } from '..';


const BattlesSvg: React.FC<SvgProps> = (props) => (
    <svg viewBox="0 0 35 35" {...props}>
        <path
            d="M8.43 30a.5.5 0 0 1-.5-.5V7.79a.5.5 0 1 1 1 0v21.67a.5.5 0 0 1-.5.54z"
        />
        <path
            d="M24.2 26.83a14.6 14.6 0 0 1-6-1.86 13.76 13.76 0 0 0-5.56-1.76 5.51 5.51 0 0 0-3.89 1.67.49.49 0 0 1-.54.1.51.51 0 0 1-.3-.46V9.57a.52.52 0 0 1 .16-.37 6.46 6.46 0 0 1 4.57-1.95c2 0 4 .95 6 1.86s3.75 1.77 5.56 1.77a5.52 5.52 0 0 0 3.89-1.68.5.5 0 0 1 .6-.06.51.51 0 0 1 .22.57c-.93 3.2-1.89 6.14-2.94 9a41.72 41.72 0 0 0 2.88 5.54.49.49 0 0 1-.08.63 6.42 6.42 0 0 1-4.57 1.95zm-11.54-4.62a14.6 14.6 0 0 1 6 1.86c1.93.9 3.75 1.76 5.56 1.76a5.39 5.39 0 0 0 3.58-1.4 45.86 45.86 0 0 1-2.8-5.5.55.55 0 0 1 0-.38c.9-2.44 1.74-5 2.55-7.63a6.13 6.13 0 0 1-3.31 1c-2 0-4-1-6-1.86s-3.75-1.77-5.56-1.77a5.4 5.4 0 0 0-3.75 1.5v13.66a6.21 6.21 0 0 1 3.73-1.24z"
        />
    </svg>
);
BattlesSvg.defaultProps = defaultSvgProps;

export default BattlesSvg;
