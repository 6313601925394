import { SQLCipher, getAppState } from '../../';
import { store } from '../../';
import { AppState } from '../../reducers/index';
import { pinEnabled, ShowPin } from '../../actions/authentication_actions';
import fingerprint_auth from './fingerprint_auth';
import { submitLog } from '../logging_util';
import { Strings } from '../../assets/common/strings';

interface SavedUserData {
	userID: string;
}

export default class pin_auth {
	// Setup Functions
	static initializePin() {
		return new Promise((resolve, reject) => {
			this._loadPinSettings()
				.then(resolve)
				.catch((error: Error) => {
					submitLog(Strings.Log.Warn, Strings.PinAuth.LoadError, { error });
					resolve();
				});
		});
	}

	// Handle Pin Display
	static showPin() {
		window.screen?.orientation?.lock('portrait');
		store.dispatch(ShowPin(true));
	}

	static dismissPin() {
		window.screen?.orientation?.unlock();
		store.dispatch(ShowPin(false));
	}

	// Handle Pin Lifecycle
	static setting_pin = false;
	static reset_pin = false;
	static from_fingerprint = false;
	private static _has_pin = false;
	private static _loaded_has_pin = false;

	static setPin(newPin: string) {
		// TODO: Validate new pin and handle flow
		this.setting_pin = false;
		this.reset_pin = false;
		if (SQLCipher) {
			const state = getAppState();
			const userID: string = state.user.agentID;
			SQLCipher.sqlBatch(
				[
					'DELETE FROM pin_code WHERE userID="' + userID + '"',
					['INSERT INTO pin_code VALUES (?, ?)', [newPin, userID]],
				],
				() => {
					submitLog(Strings.Log.Info, Strings.PinAuth.StorePin);
				},
				(error: Error) => {
					submitLog(Strings.Log.Error, Strings.PinAuth.StorePinError, {
						error,
						userId: userID,
					});
				}
			);
		}
		this._has_pin = true;
		this._loaded_has_pin = true;
		this.setPinEnabled(true);
		if (this.from_fingerprint) {
			this.from_fingerprint = false;
			fingerprint_auth.setFingerprintEnabled(true);
		}
		this.dismissPin();
	}

	static clearPinFromDevice() {
		if (SQLCipher) {
			this.getLastLoggedInUserID()
				.then((response) => {
					const userID: string = response.userID.toUpperCase();
					SQLCipher.sqlBatch(
						['DELETE FROM pin_code WHERE userID="' + userID + '"'],
						() => submitLog(Strings.Log.Info, Strings.PinAuth.Cleared),
						(error: Error) => submitLog(Strings.Log.Error, Strings.PinAuth.ClearedError, { error })
					);
					this._has_pin = false;
					this._loaded_has_pin = true;
					this.setPinEnabled(false, userID);
					fingerprint_auth.setFingerprintEnabled(false, userID);
				})
				.catch(e => {
					submitLog(Strings.Log.Error, `Error clearing pin`, { error: e });
				});
		}
	}

	static hasPin(): Promise<boolean> {
		return new Promise((resolve, reject) => {
			if (this._loaded_has_pin) {
				resolve(this._has_pin);
			} else {
				this._getPin().then((pin: string) => {
					resolve(pin.length == 4);
				});
			}
		});
	}

	private static _getPin(): Promise<string> {
		return new Promise((resolve, reject) => {
			if (SQLCipher) {
				this.getLastLoggedInUserID()
					.then((response) => {
						const userID = response.userID.toUpperCase();
						SQLCipher.executeSql(
							'SELECT * FROM pin_code',
							[],
							(rs: any) => {
								const { rows } = rs;
								let i = 0,
									userRow;
								for (i; i < rows.length; i++) {
									const row = rows.item(i);
									const user = row
										? row.userID.toUpperCase()
										: '';
									if (user === userID) {
										userRow = row;
										break;
									}
								}
								const storedPin = userRow
									? userRow.value
									: '';
								this._has_pin = storedPin.length == 4;
								this._loaded_has_pin = true;
								resolve(storedPin);
							},
							(error: Error) => {
								if (error) {
									submitLog(Strings.Log.Error, Strings.PinAuth.GetPinError,
										{ error, userId: userID }
									);
								}
							}
						);
					})
					.catch(resolve);
			} else {
				this._has_pin = false;
				this._loaded_has_pin = true;
				resolve('');
			}
		});
	}

	// Authentication Management
	private static challenge_promise?: (
		value?: boolean | null | PromiseLike<boolean | null>
	) => void = undefined;
	static incorrect_attempts = 0;
	static allowed_attempts = 3;

	static challengePin(): Promise<boolean | null> {
		if (this.challenge_promise) {
			this.challenge_promise(false);
			this.challenge_promise = undefined;
		}
		this.setting_pin = false;

		return new Promise((resolve, reject) => {
			if (this.pin_loaded) {
				this.hasPin().then((hasPin: boolean) => {
					if (this.pin_enabled && hasPin) {
						this.challenge_promise = resolve;
						this.showPin();
					} else {
						resolve(false);
					}
				});
			} else {
				this._loadPinSettings().then(() => {
					this.hasPin().then((hasPin: boolean) => {
						if (this.pin_enabled && hasPin) {
							this.challenge_promise = resolve;
							this.showPin();
						} else {
							resolve(false);
						}
					});
				});
			}
		});
	}

	private static _resolveChallenge(correct: boolean) {
		if (this.incorrect_attempts >= this.allowed_attempts) {
			this.incorrect_attempts = 0;
			this.dismissPin();
			if (this.challenge_promise) {
				this.challenge_promise(false);
			}
		} else if (correct) {
			if (this.challenge_promise) {
				this.incorrect_attempts = 0;
				const prom = this.challenge_promise;
				this.challenge_promise = undefined;
				prom(true);
				this.dismissPin();
			}
		}
	}

	static verifyPin(enteredPin: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			this._getPin().then((storedPin: string) => {
				const correct = storedPin.length > 0 && storedPin == enteredPin;
				if (!correct) {
					this.incorrect_attempts += 1;
				}
				this._resolveChallenge(correct);
				resolve(correct);
			});
		});
	}

	static cancelVerification() {
		if (this.challenge_promise) {
			this.challenge_promise(null);
		}
		this.dismissPin();
	}

	// Settings Management
	private static pin_loaded = false;
	static pin_enabled = false;

	static setPinEnabled(enabled: boolean, userID?: string) {
		this.pin_loaded = true;
		this.pin_enabled = enabled;
		store.dispatch(pinEnabled(enabled));
		this._savePinSettings(userID);
	}

	private static _savePinSettings(userId?: string) {
		if (SQLCipher) {
			const state = getAppState();
			const userID: string = (userId || state.user.agentID || '').toUpperCase();
			SQLCipher.sqlBatch(
				[
					`DELETE FROM pin_settings WHERE userID="${userID}"`,
					['INSERT INTO pin_settings VALUES (?,?)', [this.pin_enabled, userID]],
				],
				() => submitLog(Strings.Log.Info, Strings.PinAuth.Settings),
				(error: Error) =>
					submitLog(Strings.Log.Error, Strings.PinAuth.SettingsError, {
						error,
						userId: userID,
					})
			);
		}
	}

	static getLastLoggedInUserID(): Promise<SavedUserData> {
		return new Promise((resolve, reject) => {
			if (SQLCipher) {
				SQLCipher.transaction((tx: any) => {
					tx.executeSql(
						'SELECT userID FROM ac_userid',
						[],
						(tx: any, rs: any) => {
							const user = rs.rows.item(0);
							const userID = user ? user.userID : '';

							resolve({ userID });
						},
						(tx: any, error: Error) => {
							resolve({ userID: '' });
						}
					);
				});
			} else {
				resolve({ userID: '' });
			}
		});
	}

	private static _loadPinSettings(): Promise<any> {
		return new Promise((resolve, reject) => {
			if (SQLCipher) {
				this.getLastLoggedInUserID()
					.then((response: any) => {
						const userID: string = response.userID.toUpperCase();
						SQLCipher.executeSql(
							'SELECT * FROM pin_settings',
							[],
							(rs: any) => {
								const { rows } = rs;
								let i = 0,
									userRow;
								for (i; i < rows.length; i++) {
									const row = rows.item(i);
									const user = row
										? row.userID.toUpperCase()
										: '';
									if (user === userID) {
										userRow = row;
										break;
									}
								}
								this.pin_enabled = userRow
									? userRow.value == 'true'
									: false;
								store.dispatch(pinEnabled(this.pin_enabled));
								resolve();
							}
						);
					})
					.catch((error: Error) => {
						submitLog(Strings.Log.Warn, Strings.PinAuth.LoadError, { error });
						resolve();
					});
			} else {
				resolve();
			}
		});
	}
}
