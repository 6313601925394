import React from 'react';
import { TextField, Grid, MenuItem } from '@material-ui/core';

interface SingleSelectProps {
    options: string[];
    label: string;
    changeFunction: (newValue: any) => void;
    value?: string;
    defaultAll?: boolean;
    disabled?: boolean
}

const SingleSelectDropdown: React.SFC<SingleSelectProps> = (props: SingleSelectProps) => {
    const { options, value, label, changeFunction, defaultAll } = props;

    const currentValue = (!value && defaultAll)
        ? 'All'
        : (value || '')

    const currentOptions = defaultAll ?
        ['All'].concat(options)
        : options;

    return (
        <Grid item xs={12}>
            <div>
                <TextField
                    select
                    onChange={event => changeFunction(event.target.value)}
                    fullWidth
                    value={currentValue}
                    label={label}
                    disabled={props.disabled}
                >
                    {currentOptions.map((item: string, index: number) =>
                        <MenuItem
                            key={item + index}
                            value={item}
                        >
                            {item}
                        </MenuItem>
                    )}
                </TextField>
            </div>
        </Grid>
    );
}

export default SingleSelectDropdown;