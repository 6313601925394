import actionCreatorFactory from "typescript-fsa";
import { ContactVerificationResult, ContactVerificationRequest, SessionHousehold, ClickToCallSession, SessionSummary } from '../reducers/click_to_call_reducer';

const actionCreator = actionCreatorFactory('ClickToCall');

export interface C2CSelectedContact {
    name: string;
    number: string;
    contactId: string;
    phoneId: string;
};

export const ClearClickToCallData = actionCreator('CLEAR_C2C_DATA');
export const SetupTwilioClient = actionCreator.async<undefined, string>('SETUP_TWILIO_CLIENT');
export const CheckLeadConnectBalance = actionCreator.async<undefined, boolean>('CHECK_LEAD_CONNECT_BALANCE');
export const CheckCallerId = actionCreator.async<undefined, boolean>('CHECK_CALLER_ID');
export const VerifyCallerId = actionCreator.async<string, string>('VERIFY_CALLER_ID');
export const VerifyClickToCallContacts = actionCreator.async<ContactVerificationRequest, ContactVerificationResult>('VERIFY_C2C_CONTACTS');
export const StartClickToCallSession = actionCreator.async<SessionHousehold, ClickToCallSession>('START_C2C_SESSION');

export const StartClickToCallPhoneCall = actionCreator<C2CSelectedContact>('START_C2C_PHONE_CALL');
export const EndClickToCallPhoneCall = actionCreator<void>('END_C2C_PHONE_CALL');
export const ClickToCallPhoneCallAccepted = actionCreator('C2C_CALL_ACCEPTED');

export const IncrementClickToCallCounter = actionCreator('INCREMENT_C2C_CALLS');
export const IncrementHouseholdIndex = actionCreator('INCREMENT_C2C_HOUSEHOLDS');

export const StartCallTimer = actionCreator('START_CALL_TIMER');
export const IncrementCallTimer = actionCreator('INCREMENT_CALL_TIMER');
export const EndCallTimer = actionCreator('END_CALL_TIMER');
export const EndClickToCallSession = actionCreator.async<undefined, SessionSummary>('END_C2C_SESSION');
export const GetClickToCallSession = actionCreator.async<undefined, ClickToCallSession>('GET_C2C_SESSION');
export const GetClickToCallSessionHousehold = actionCreator.async<string, SessionHousehold>('GET_C2C_SESSION_HOUSEHOLD');
export const StoreCallSid = actionCreator<string>('STORE_CALL_SID');

// Twilio actions - take Connection, Device, and Error Twilio objects
export const TwilioOnAccept = actionCreator<any>('TWILIO_ON_ACCEPT');
export const TwilioOnConnect = actionCreator<any>('TWILIO_ON_CONNECT');
export const TwilioOnDisconnect = actionCreator<any>('TWILIO_ON_DISCONNECT');
export const TwilioOnCancel = actionCreator<any>('TWILIO_ON_CANCEL');
export const TwilioOnError = actionCreator<any>('TWILIO_ON_ERROR');
export const TwilioOnReady = actionCreator<any>('TWILIO_ON_READY');
export const TwilioOnIncoming = actionCreator<any>('TWILIO_ON_INCOMING');