import React from 'react';
import { Loaded, displayFullAddress, agentAddressToAddress } from '../../utilities/utilities';
import { SuggestedValues } from './suggested_values';
import { Contact, Address } from '../../reducers/ContactReducer';
import { Grid } from '@material-ui/core';
import { LocationSuggestion } from './location_suggestion';
import { AgentAddress } from '../../reducers/agent_reducer';
import { fullName } from '../../assets/common/string_builders';

interface Props {
    contacts: Loaded<Contact>[],
    selectedEmployerContact?: Contact,
    selectedContact: Contact;
    selectedAddress: string,
    onSelectLocation: (address: Address) => void,
    selectedEmployerCompanyName: string,
    businessAddresses?: AgentAddress[],
    storefrontAddresses?: Address[],
    agentDisplayName: string;
}
export const LocationSuggestions: React.FC<Props> = (props) => {    
    
    const {
        contacts,
        selectedAddress,
        onSelectLocation,
        selectedEmployerContact,
        selectedEmployerCompanyName,
        selectedContact,
        businessAddresses,
        storefrontAddresses,
        agentDisplayName
    } = props;

    return (
        <SuggestedValues>
            {contacts.map(({ data }, contactIndex) => {
                return data.addresses.map((address, addressIndex) => (
                    <Grid item xs={12} key={`contact${contactIndex}-address${addressIndex}`}>
                        <LocationSuggestion
                            selected={displayFullAddress(address) == selectedAddress}
                            address={address}
                            name={fullName(data)}
                            onClick={onSelectLocation}
                        />
                    </Grid>
                ));
            })}
            {selectedEmployerContact && selectedEmployerContact.addresses.map((address, index) => (
                <Grid item xs={12} key={index}>
                    <LocationSuggestion
                        selected={displayFullAddress(address) == selectedAddress}
                        address={address}
                        name={selectedEmployerCompanyName}
                        onClick={onSelectLocation}
                    />
                </Grid>
            ))}
            {contacts.findIndex(contact => contact.data.id === selectedContact.id) === -1
                && selectedContact.addresses
                && selectedContact.addresses.map((address, index) => (
                        <Grid item xs={12} key={index}>
                            <LocationSuggestion
                                selected={displayFullAddress(address) == selectedAddress}
                                address={address}
                                name={fullName(selectedContact)}
                                onClick={onSelectLocation}
                            />
                        </Grid>
                    )
                )
            }
            {businessAddresses && businessAddresses.map(
                (address, index) => (
                    <Grid item xs={12} key={index}>
                        <LocationSuggestion
                            selected={displayFullAddress(agentAddressToAddress(address)) == selectedAddress}
                            address={agentAddressToAddress(address)}
                            name={agentDisplayName}
                            onClick={onSelectLocation}
                        />
                    </Grid>
                )
            )}
            {storefrontAddresses && storefrontAddresses.map(
                (address, index) => (
                    <Grid item xs={12} key={index}>
                        <LocationSuggestion
                            selected={(displayFullAddress(address) == selectedAddress)}
                            address={address}
                            name={agentDisplayName}
                            onClick={onSelectLocation}
                        />
                    </Grid>
                )
            )}
        </SuggestedValues>
    );

};