import { Button } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import { navRoutes } from '../nav/Routes';
import { Widget } from './base_widget';
import ToolsSvg from '../svgs/icons/tools';
import { themePalette, themeLinks } from '../../utilities/branding';
import { GetTools } from '../../actions/tools_actions';
import { Tool } from '../../reducers/tools_reducer';
import { nav } from '../..';
import { AppState } from '../../reducers';
import { Dispatch } from '@reduxjs/toolkit';
import LoadingList from '../utility/loading_list';
import { Strings } from '../../assets/common/strings';
import { getWidgetTools } from '../../selectors/tools_selectors';
import { ToolItem } from '../Tools/ToolItem';
import { isConnectureDRXAllowed } from '../../utilities/agent_util';
import { Agent } from '../../reducers/agent_reducer';
import { ConnectureToolItemComponent } from '../Tools/ConnectureToolItem';



interface StateProps {
	agent: Agent;
	tools: Tool[];
	loading: boolean;
}
interface DispatchProps {
	getTools: () => any;
}
type Props = StateProps & DispatchProps;

const ToolsWidget: React.FC<Props> = (props) => {

	const { agent, tools, loading, getTools } = props;
	const isConnectureAllowed = isConnectureDRXAllowed(agent?.fieldOfficeInfo?.FieldOfficeName, '', agent.agentCode);


	const navToTools = () => nav.push(navRoutes.tools.path);
	useEffect(() => {
		getTools();
	}, []);

	const connectureDRXCard = isConnectureAllowed ? (
		<ConnectureToolItemComponent />
	) : null;


	return (
		<Widget
			title={Strings.WidgetTitles.Tools}
			avatar={<ToolsSvg width={30} height={30} fill={themePalette.menuSvgTools} />}
			actionButtons={[
				<Button onClick={navToTools}>
					View All
				</Button>,
			]}
			onClick={navToTools}
		>
			{connectureDRXCard}
			{
				loading && tools.length === 0
					? <LoadingList size={2} />
					: (
						tools.map((tool, index) => (<ToolItem key={`tool_${index}`} {...tool} hideDescription />))
					)
			}
		</Widget>
	);
}

const mapStateToProps = (state: AppState): StateProps => ({
	agent: state.agent,
	tools: getWidgetTools(state),
	loading: state.tools.loading,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	getTools: () => dispatch(GetTools.started()),
});

export const ToolsWidgetContainer = connect(mapStateToProps, mapDispatchToProps, true)(ToolsWidget);
