import { Contact } from './ContactReducer';
import { Action as ReduxAction } from 'redux';
import { isType } from 'typescript-fsa';
import { Loaded } from '../utilities/utilities';
import {
	GetEmployer,
	UpdateEmployer,
	DeleteEmployer,
	SearchEmployers,
	ReassignEmployer,
	saveEmployerTags,
} from '../actions/employer_actions';
import _ from 'lodash';
import BaseDTO from '../utilities/base_dto';
import { ClearCaches } from '../actions/authentication_actions';
import { Logout } from '../actions/authentication_actions';

export interface Employer extends BaseDTO {
	id: string;
	owningUser: string;
	accountNumber: string;
	isAncillariesOffered: boolean;
	clientType: string;
	groupCoverageAdvantage: string;
	currentCarrier: string;
	employerContributionAmount?: number;
	employerContributionBaseAmount?: number;
	isEmployerContributesToGroupCoverage: boolean;
	isFamilyCoverageOffered: boolean;
	isGroupPlanOffered: boolean;
	marketoCompanyId: string;
	marketoLastUpdated?: Date;
	numberOfGroupEligibles?: number;
	numberOfGroupParticipants?: number;
	numberOfPartTime?: number;
	isAge65OrAboveAllowed: boolean;
	purchased: string;
	renewalDate?: Date;
	state: string;
	isSyncedToMarketo: boolean;
	leadSource: string;
	vueInsuredId: string;
	description: string;
	industry: string;
	companyName: string;
	numberOfEmployees?: number;
	annualRevenue?: number;
	sicCode: string;
	website: string;
	contacts: Contact[];
	updatedByName: string;
	createdByName: string;
	tags?: string[];
}

export interface EmployerState {
	employers: Loaded<Employer>[];
	currentEmployerId?: string;
	searchLoading: boolean;
}

const initialState: EmployerState = {
	employers: [],
	searchLoading: false,
};

export function employerReducer(
	state: EmployerState = initialState,
	action: ReduxAction
): EmployerState {
	if (
		isType(action, GetEmployer.started) ||
		isType(action, UpdateEmployer.started) ||
		isType(action, DeleteEmployer.started)
	) {
		const employerId = isType(action, GetEmployer.started)
			? action.payload
			: action.payload.id;
		const employersInState = state.employers.slice();
		upsertEmployerStart(employersInState, { id: employerId } as Employer);
		return {
			...state,
			employers: employersInState,
		};
	} else if (isType(action, saveEmployerTags.started)) {
		const employersInState = state.employers.slice();
		upsertEmployerStart(employersInState, { id: action.payload.employerId } as Employer);
		return {
			...state,
			employers: employersInState,
		};
	} else if (
		isType(action, ReassignEmployer.done) ||
		isType(action, DeleteEmployer.done)
	) {
		const idToRemove = isType(action, ReassignEmployer.done)
			? action.payload.params.employerId
			: action.payload.params;
		const oldEmployers = state.employers.slice();
		const newEmployers = oldEmployers.filter(employer => {
			return employer.employerId != idToRemove;
		});
		return {
			...state,
			employers: newEmployers,
		};
	} else if (
		isType(action, GetEmployer.done) ||
		isType(action, UpdateEmployer.done)
	) {
		const employersInState = state.employers.slice();
		const {
			leads,
			notes,
			activities,
			attachments,
			policies,
			applications,
			...employer
		} = action.payload.result;
		upsertEmployerDone(employersInState, employer);
		return {
			...state,
			employers: employersInState,
		};
	} else if (
		isType(action, GetEmployer.failed) ||
		isType(action, UpdateEmployer.failed) ||
		isType(action, DeleteEmployer.failed)
	) {
		const employerId = isType(action, UpdateEmployer.failed)
			? action.payload.params.id
			: action.payload.params;
		const employersInState = state.employers.slice();
		upsertEmployerDone(
			employersInState,
			{ id: employerId } as Employer,
			action.payload.error
		);
		return {
			...state,
			employers: employersInState,
		};
	} else if (isType(action, SearchEmployers.started)) {
		return {
			...state,
			searchLoading: true,
		};
	} else if (isType(action, SearchEmployers.failed)) {
		return {
			...state,
			searchLoading: false,
		};
	} else if (isType(action, SearchEmployers.done)) {
		const employersInState = state.employers.slice();
		const newEmployers: Employer[] = [];
		action.payload.result.forEach(fullEmployer => {
			const {
				leads,
				notes,
				activities,
				attachments,
				policies,
				applications,
				...employer
			} = fullEmployer;
			newEmployers.push(employer);
		});
		updateStateWithInboundEmployers(employersInState, newEmployers);
		return {
			...state,
			employers: employersInState,
			searchLoading: false,
		};
	} else if (isType(action, Logout.started)) {
		return {
			...initialState,
		};
	} else if (isType(action, ClearCaches)) {
		return {
			...initialState,
		};
	} else {
		return state;
	}
}

function upsertEmployerStart(
	cachedEmployers: Loaded<Employer>[],
	employer: Employer
) {
	const matchedEmployerIndex = _.findIndex(
		cachedEmployers,
		cachedEmployer => cachedEmployer.data.id === employer.id
	);
	if (matchedEmployerIndex !== -1) {
		cachedEmployers[matchedEmployerIndex] = {
			...cachedEmployers[matchedEmployerIndex],
			loading: true,
			employerId: employer.id,
		};
	} else {
		const newEmployer: Loaded<Employer> = {
			data: employer,
			loading: true,
			employerId: employer.id,
			errors: [],
		};
		cachedEmployers.push(newEmployer);
	}
}

function upsertEmployerDone(
	cachedEmployers: Loaded<Employer>[],
	updatedEmployer: Employer,
	error?: any
) {
	const index = _.findIndex(
		cachedEmployers,
		cachedEmployer => cachedEmployer.data.id === updatedEmployer.id
	);
	if (index > -1) {
		if (error)
			cachedEmployers[index] = {
				...cachedEmployers[index],
				loading: false,
				errors: [...cachedEmployers[index].errors, error],
			};
		else
			cachedEmployers[index] = {
				...cachedEmployers[index],
				data: updatedEmployer,
				employerId: updatedEmployer.id,
				loading: false,
				errors: [],
			};
	} else {
		const newEmployer: Loaded<Employer> = {
			data: updatedEmployer,
			loading: false,
			employerId: updatedEmployer.id,
			errors: error ? [error] : [],
		};
		cachedEmployers.push(newEmployer);
	}
}

function updateStateWithInboundEmployers(
	employersInState: Loaded<Employer>[],
	inboundEmployers: Employer[]
) {
	inboundEmployers.forEach(inboundEmployer => {
		const employerId = inboundEmployer.id;
		let wasAEmployerUpdated = false;

		employersInState.forEach((currentEmployer, index) => {
			const currentEmployerId = currentEmployer.data.id;
			if (currentEmployerId === employerId) {
				wasAEmployerUpdated = true;
				employersInState[index] = {
					...employersInState[index],
					loading: false,
					data: inboundEmployer,
					employerId: inboundEmployer.id,
					errors: [],
				};
			}
		});
		if (!wasAEmployerUpdated) {
			const newEmployer: Loaded<Employer> = {
				loading: false,
				data: inboundEmployer,
				errors: [],
				employerId: inboundEmployer.id,
			};
			employersInState.push(newEmployer);
		}
	});
}
