import React from 'react';
import { wrapInCollapsibleCard } from '../higher_order_component/wrap_in_card';

interface Props {
    largestIcon?: string;
}

const ImageCardContent: React.SFC<Props> = (props) => (
    <div
        style={styles.cardContent}
    >
        {props.largestIcon ? (
            <img
                src={props.largestIcon.replace('http://', 'https://')}
                style={{ width: '120px', height: '120px' }}
            />
        ) : (
            <p>
                You currently do not have a custom icon. <br />
                Select Edit to customize your icon.
            </p>
        )}
    </div>
);

const styles: {[key: string]: React.CSSProperties} = {
    cardContent: {
        width: '100%',
        textAlign: 'center',
        alignContent: 'center',
    }
};

export const ImageCard = wrapInCollapsibleCard(ImageCardContent);
