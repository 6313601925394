import React, { Dispatch } from 'react';
import {
	Icon,
	IconButton,
	Checkbox,
	TextField,
	Button,
	Grid,
	Dialog,
	DialogActions,
	DialogTitle,
	Typography,
	FormControlLabel,
	FormHelperText,
	Select,
	Input,
	Chip,
	MenuItem,
	InputLabel,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { ReadOnlyTextField } from '../utility/read_only_text_field';
import { AppState } from '../../reducers/index';
import { connect } from '@optum-uhone-hmkts/rise';
import { normalizePhone } from '../../utilities/formatting/data_normalizations';
import { validateEmail } from '../../utilities/form_validation';
import {
	FetchClientConnectSettings,
	PostClientConnectSettings,
} from '../../actions/client_connect_actions';
import { ClientConnectAppointmentTypes, ClientConnectSettings } from '../../sagas/clientConnect_sagas';
import { navRoutes } from '../nav/Routes';
import { navigateTo } from '../../actions/navigation_actions';
import { CCCard } from './client_connect_contacts_card';
import { Contact } from '../../reducers/ClientConnectReducer';
import { Strings } from '../../assets/common/strings';
import { GAService } from '../../utilities/services/google_analytics';
import { SimpleListItem } from '../utility/simple_list_item';
import { createMenuAction, MoreMenu } from '../nav/more_menu';
import { themeMisc, themePalette } from '../../utilities/branding';
import { ActivityDurations, DaysOfTheWeek, orderActivityDurations, orderDaysOfWeek } from '../../utilities/date_util';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface ComponentProps {
	personalPhone?: string;
	businessPhone?: string;
	businessEmail: string;
}
interface StateProps {
	jwt: string | undefined;
	agentCode: string;
	settings: ClientConnectSettings;
	hasIcons: boolean;
}
interface DispatchProps {
	navigateTo(route: string): void;
	fetchClientConnectSettings: () => void;
	postClientConnectSettings: (settings: any) => void;
}
type Props = ComponentProps & StateProps & DispatchProps; 
interface State {activatedDialog: boolean;
	isActivated: boolean;
	shouldDisplayErrorText: boolean;
	contactList: Array<Contact>;
	contactsLoaded: boolean;
	newContactType: string;
	fieldError: string;
	inEdit: boolean;
	filteredOptions: Array<string>;
	addMenuOpen: boolean;
	appointmentTypes: ClientConnectAppointmentTypes[];
	appointmentDays: DaysOfTheWeek[];
	appointmentTime: [Date?, Date?];
	appointmentDurations: ActivityDurations[];
}

class CCSettings extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			activatedDialog: false,
			isActivated: false,
			shouldDisplayErrorText: false,
			contactList: [],
			contactsLoaded: false,
			newContactType: '',
			fieldError: '',
			inEdit: false,
			filteredOptions: Contact.CONTACT_TYPES.slice(0),
			addMenuOpen: false,
			appointmentTypes: props.settings.appointmentSettings.types,
			appointmentDays: props.settings.appointmentSettings.days,
			appointmentTime: props.settings.appointmentSettings.time,
			appointmentDurations: props.settings.appointmentSettings.durations,
		};
	}

	localContacts: Array<Contact>;

	componentWillMount() {
		this.props.fetchClientConnectSettings();
	}

	componentWillReceiveProps(nextProps: Props) {
		if (!this.state.contactsLoaded && !this.state.inEdit) {
			let contacts = nextProps.settings.contacts;

			if (!contacts) {
				// Setup defaults if contacts have not be set up yet
				contacts = [];

				if (this.props.personalPhone) {
					let mobileContact = new Contact(Strings.ClientConnectContactInfoType.Mobile);
					mobileContact.data.number = this.props.personalPhone;
					mobileContact.data.canCall = true;
					mobileContact.data.canText = false;
					mobileContact.errorMessages = [];
					contacts.push(mobileContact);
				}

				if (this.props.businessPhone) {
					let officeContact = new Contact(Strings.ClientConnectContactInfoType.Office);
					officeContact.data.number = this.props.businessPhone;
					officeContact.errorMessages = [];
					contacts.push(officeContact);
				}

				if (this.props.businessEmail) {
					let emailContact = new Contact(Strings.ClientConnectContactInfoType.Email);
					emailContact.data.email = this.props.businessEmail;
					emailContact.errorMessages = [];
					contacts.push(emailContact);
				}
			}

			contacts.forEach((contact: Contact) => {
				contact.errorMessages = contact.errorMessages || [];
			});
			this.setState(
				{
					isActivated: nextProps.settings.isActivated,
					contactList: contacts,
					contactsLoaded: !!nextProps.settings.contacts,
				},
				this.filterContactOptions
			);
		}

		if (!this.localContacts || this.localContacts.length === 0) {
			this.localContacts = this.state.contactList.slice(0);

			this.localContacts.forEach((contact: Contact) => {
				contact.errorMessages = [];
			});
		}
	}

	filterContactOptions() {
		const newFilteredOptions = Contact.CONTACT_TYPES.filter(contactOptionType => {
			if (contactOptionType === Strings.ClientConnectContactInfoType.Assistant) {
				return true;
			} else {
				const stateHasContactOfType = this.state.contactList.some(
					contact => contact.type === contactOptionType
				);
				return !stateHasContactOfType;
			}
		});

		this.setState({
			filteredOptions: newFilteredOptions,
		});
	}

	updateCheck(_: any, checked: boolean) {
		this.setState({
			isActivated: checked,
		});
	}

	createNewContact = (value: any) => {
		let newContactList = this.state.contactList.concat(new Contact(value));
		this.setState(
			{
				contactList: newContactList,
			},
			this.filterContactOptions
		);
	};

	getDeleteButton(contact: Contact) {
		return (
			<Grid item xs={1}>
				<IconButton
					onClick={(event: object) => {
						let idx = this.state.contactList.indexOf(contact);
						if (idx !== -1) {
							this.state.contactList.splice(idx, 1);
						}
						this.filterContactOptions();
					}}
				>
					<Icon>delete</Icon>
				</IconButton>
			</Grid>
		);
	}

	areContactsValid() {
		const hasError = this.state.contactList.some(contact => (contact.errorMessages || []).some(Boolean));
		return !hasError;
	}

	renderMobileContact = (contact: Contact) => {
		const { inEdit } = this.state;
		return (
			<Grid container alignItems="flex-start" justify="space-between" spacing={1}>
				{
					inEdit
						? (
							<>
								<Grid item xs={6}>
									<TextField
										fullWidth
										label="Mobile Number"
										value={contact.data.number}
										helperText={
											contact.errorMessages
												? contact.errorMessages[0] || contact.errorMessages[1]
												: ''
										}
										error={this.state.shouldDisplayErrorText}
										onChange={(event: any) => {
											const newValue = event.target.value;
											contact.data.number = normalizePhone(newValue);
											if (!contact.errorMessages) {
												contact.errorMessages = [];
											}

											if (contact.data.number.length !== 14) {
												contact.errorMessages[0] =
													'Invalid phone number: must be 10 digits.';
											} else {
												contact.errorMessages[0] = '';
											}
											this.forceUpdate();
										}}
									/>
								</Grid>
								<Grid item xs={2}>
									<FormControlLabel
										control={
											<Checkbox
												checked={contact.data.canCall}
												onChange={(event: object, isInputChecked: boolean) => {
													if (!contact.errorMessages) {
														contact.errorMessages = [];
													}

													contact.data.canCall = isInputChecked;
													if (!(contact.data.canCall || contact.data.canText)) {
														contact.errorMessages[1] =
															'At least one contact option (call or text) must be selected.';
													} else {
														contact.errorMessages[1] = '';
													}
													this.forceUpdate();
												}}
											/>
										}
										label={<Typography variant="caption">Call?</Typography>}
										labelPlacement="top"
									/>
								</Grid>
								<Grid item xs={2}>
									<FormControlLabel
										control={
											<Checkbox
												checked={contact.data.canText}
												onChange={(event: object, isInputChecked: boolean) => {
													if (!contact.errorMessages) {
														contact.errorMessages = [];
													}

													contact.data.canText = isInputChecked;
													if (!(contact.data.canCall || contact.data.canText)) {
														contact.errorMessages[1] =
															'At least one contact option (call or text) must be selected.';
													} else {
														contact.errorMessages[1] = '';
													}
													this.forceUpdate();
												}}
											/>
										}
										label={<Typography variant="caption">Text?</Typography>}
										labelPlacement="top"
									/>
								</Grid>
								{this.getDeleteButton(contact)}
							</>
						)
						: (
							<>
								<Grid item xs={6}>
									<ReadOnlyTextField
										label="Mobile Number"
										value={contact.data.number}
									/>
								</Grid>
								<Grid item xs={2}>
									<FormControlLabel
										control={
											<Checkbox
												checked={contact.data.canCall}
												disabled
											/>
										}
										label={<Typography variant="caption">Call?</Typography>}
										labelPlacement="top"
									/>
								</Grid>
								<Grid item xs={2}>
									<FormControlLabel
										control={
											<Checkbox
												checked={contact.data.canText}
												disabled
											/>
										}
										label={<Typography variant="caption">Text?</Typography>}
										labelPlacement="top"
									/>
								</Grid>
								<Grid item xs={1} />
							</>
						)
				}
			</Grid>
		);
	}

	renderOfficeContact = (contact: Contact) => {
		const { inEdit } = this.state;
		return (
			<Grid container alignItems="flex-start" justify="space-between" spacing={1}>
				{
					inEdit
						? (
							<>
								<Grid item xs={11}>
									<TextField
										fullWidth
										label="Office Number"
										value={contact.data.number}
										helperText={contact.errorMessages ? contact.errorMessages[0] : ''}
										error={this.state.shouldDisplayErrorText}
										onChange={(event: any) => {
											const newValue = event.target.value;
											contact.data.number = normalizePhone(newValue);
											if (!contact.errorMessages) {
												contact.errorMessages = [];
											}
											if (contact.data.number.length !== 14) {
												contact.errorMessages[0] =
													'Invalid phone number: must be 10 digits.';
											} else {
												contact.errorMessages[0] = '';
											}
											this.forceUpdate();
										}}
									/>
								</Grid>
								{this.getDeleteButton(contact)}
							</>
						)
						: (
							<>
								<Grid item xs={11}>
									<ReadOnlyTextField
										label={'Office Number'}
										value={contact.data.number}
									/>
								</Grid>
								<Grid item xs={1} />
							</>
						)
				}
			</Grid>
		);
	}

	renderEmailContact = (contact: Contact) => {
		const { inEdit } = this.state;
		return (
			<Grid container alignItems="flex-start" justify="space-between" spacing={1}>
				{
					inEdit
						? (
							<>
								<Grid item xs={11}>
									<TextField
										fullWidth
										label="Email Address"
										value={contact.data.email}
										helperText={contact.errorMessages ? contact.errorMessages[0] : ''}
										error={this.state.shouldDisplayErrorText}
										onChange={(event: any) => {
											contact.data.email = event.target.value;
											if (!contact.errorMessages) {
												contact.errorMessages = [];
											}

											if (
												!contact.data.email ||
												validateEmail(contact.data.email) === 'Invalid Email'
											) {
												contact.errorMessages[0] = 'Invalid email.';
											} else {
												contact.errorMessages[0] = '';
											}
											this.forceUpdate();
										}}
									/>
								</Grid>
								{this.getDeleteButton(contact)}
							</>
						)
						: (
							<>
								<Grid item xs={11}>
									<ReadOnlyTextField label={'Email'} value={contact.data.email} />
								</Grid>
								<Grid item xs={1} />
							</>
						)
				}
				
			</Grid>
		);
	}

	renderAssistantContact = (contact: Contact) => {
		const { inEdit } = this.state;
		return (
			<Grid container alignItems="flex-start" justify="space-between" spacing={1}>
				{
					inEdit
						? (
							<>
								<Grid item xs={6}>
									<TextField
										fullWidth
										label={'Assistant Name'}
										value={contact.data.asstName}
										error={this.state.shouldDisplayErrorText}
										helperText={contact.errorMessages ? contact.errorMessages[0] : ''}
										onChange={(event: any) => {
											if (!contact.errorMessages) {
												contact.errorMessages = [];
											}

											contact.data.asstName = event.target.value as string;
											if (contact.data.asstName.length === 0) {
												contact.errorMessages[0] = 'Invalid name.';
											} else {
												contact.errorMessages[0] = '';
											}
											this.forceUpdate();
										}}
									/>
								</Grid>
								<Grid item xs={5}>
									<TextField
										fullWidth
										label={'Assistant Number'}
										value={contact.data.asstNumber}
										error={this.state.shouldDisplayErrorText}
										helperText={contact.errorMessages ? contact.errorMessages[1] : ''}
										onChange={(event: any) => {
											if (!contact.errorMessages) {
												contact.errorMessages = [];
											}

											contact.data.asstNumber = normalizePhone(event.target.value);
											if (contact.data.asstNumber.length !== 14) {
												contact.errorMessages[1] =
													'Invalid phone number: must be 10 digits.';
											} else {
												contact.errorMessages[1] = '';
											}
											this.forceUpdate();
										}}
									/>
								</Grid>
								{this.getDeleteButton(contact)}
							</>
						)
						: (
							<>
								<Grid item xs={6}>
									<ReadOnlyTextField
										label={'Assistant Name'}
										value={contact.data.asstName}
									/>
								</Grid>
								<Grid item xs={5}>
									<ReadOnlyTextField
										label={'Assistant Number'}
										value={contact.data.asstNumber}
									/>
								</Grid>
								<Grid item xs={1} />
							</>
						)
				}
				
			</Grid>
		);
	}

	getRenderTypeFunction(contactType: string) {
		switch (contactType) {
			case Strings.ClientConnectContactInfoType.Mobile:
				return this.renderMobileContact;
			case Strings.ClientConnectContactInfoType.Office:
				return this.renderOfficeContact;
			case Strings.ClientConnectContactInfoType.Email:
				return this.renderEmailContact;
			case Strings.ClientConnectContactInfoType.Assistant:
				return this.renderAssistantContact;
		}
	}

	renderSaveButton() {
		const { appointmentTypes } = this.state;
		const canSave = 
			themeMisc.clientConnectAppointments
				? (
					appointmentTypes.includes(ClientConnectAppointmentTypes.Phone) ||
					appointmentTypes.includes(ClientConnectAppointmentTypes.InPerson)
				)
				: true;
		return [
			<Button
				key='save'
				onClick={() => {
					const contactsAreValid = this.areContactsValid();
					this.setState({
						shouldDisplayErrorText: !contactsAreValid,
					});
					if (!contactsAreValid) {
						return;
					}
					const newSettings: ClientConnectSettings = {
						isActivated: true,
						contacts: this.state.contactList,
						email: this.props.businessEmail,
						appointmentSettings: {
							types: this.state.appointmentTypes,
							days: this.state.appointmentDays,
							time: this.state.appointmentTime,
							durations: this.state.appointmentDurations,
						}
					};
					this.props.postClientConnectSettings(newSettings);

					GAService.sendEvent('ClientConnect Settings', 'EDIT', 'save');
					this.setState({
						inEdit: false,
						contactsLoaded: false,
						activatedDialog:
							!this.props.settings.isActivated && !this.state.isActivated,
					});

					this.localContacts = this.state.contactList.slice(0);
				}}
				disabled={!canSave}
			>
				SAVE
			</Button>,
			<Button
				key='cancel'
				onClick={() => {
					this.setState({
						shouldDisplayErrorText: false,
						inEdit: false,
						contactsLoaded: false,
						contactList: this.localContacts.slice(0),
					});
				}}
			>
				CANCEL
			</Button>,
		];
	}

	renderEditButton = () => {
		return [
			<Button
				key='edit'
				onClick={() => {
					GAService.sendEvent('ClientConnect Settings', 'EDIT', 'edit');
					this.setState({
						inEdit: true,
					});
				}}
			>
				EDIT
			</Button>
		];
	};

	renderAppointmentDates = () => {
		const { inEdit } = this.state;
		const appointmentDays = inEdit
			? this.state.appointmentDays
			: this.props.settings.appointmentSettings.days;
		const appointmentTime = inEdit
			? this.state.appointmentTime
			: this.props.settings.appointmentSettings.time;
		return (
			<Grid container>
				<InputLabel shrink>Available Days</InputLabel>
				<Grid container alignItems="center" justify="center">
				{
					Object.keys(DaysOfTheWeek).map((day: keyof typeof DaysOfTheWeek) => (
						<Grid item xs={1}>
							<FormControlLabel
								control={
									<Checkbox checked={appointmentDays?.includes(DaysOfTheWeek[day])}
										onChange={this.changeAppointmentDays}
										name={DaysOfTheWeek[day]}
									/>
								}
								style={{ margin: 0 }}
								label={<Typography variant="body2">{day.charAt(0).toUpperCase()}</Typography>}
								labelPlacement="bottom"
								disabled={!inEdit}
							/>
						</Grid>
					))
				}
				</Grid>
				<Grid container spacing={1} style={{ paddingTop: 20 }}>
					<Grid item xs={12}>
						<InputLabel shrink>Available Business Hours</InputLabel>
					</Grid>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<Grid item xs={6}>
							<TimePicker
								label={'From'}
								value={appointmentTime[0]}
								defaultValue={'8:00 AM'}
								onChange={this.changeAppointmentFromTime}
								disabled={!inEdit}
								views={['hours']}
							/>
						</Grid>
						<Grid item xs={6}>
							<TimePicker
								label={'To'}
								value={appointmentTime[1]}
								defaultValue={'5:00 PM'}
								onChange={this.changeAppointmentToTime}
								disabled={!inEdit}
								views={['hours']}
							/>
						</Grid>
					</MuiPickersUtilsProvider>
				</Grid>
			</Grid>
		);
	};

	changeAppointmentFromTime = (date: MaterialUiPickersDate) => {
		this.setState({ appointmentTime: [date?.toDate(), this.state.appointmentTime[1]] });
	};
	changeAppointmentToTime = (date: MaterialUiPickersDate) => {
		this.setState({ appointmentTime: [this.state.appointmentTime[0], date?.toDate()] });
	};

	changeAppointmentDays = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, checked } = event.target;
		const appointmentDays = checked
			? this.state.appointmentDays.concat(name as DaysOfTheWeek)
			: this.state.appointmentDays.filter(type => type !== name);
		this.setState({ appointmentDays: orderDaysOfWeek(appointmentDays) });
	};

	renderAppointmentDurations = () => {
		const { inEdit } = this.state;
		const appointmentDurations = inEdit
			? this.state.appointmentDurations
			: this.props.settings.appointmentSettings.durations || [];
		return (
			<Grid container style={{ paddingTop: 20 }}>
        		<InputLabel shrink>Available Durations</InputLabel>
				<Select
					fullWidth
					multiple
					value={appointmentDurations}
					onChange={this.changeAppointmentDuration}
					input={<Input id="select-multiple-chip" />}
					renderValue={(selected: string[]) => (
						<div style={{
							display: 'flex',
							flexWrap: 'wrap',
						}}>
							{selected.map((value) => (
								<Chip key={value} label={value} style={{ margin: 2 }} />
							))}
						</div>
					)}
					disabled={!inEdit}
				>
					{Object.keys(ActivityDurations).map((key) => (
						<MenuItem key={key} value={ActivityDurations[key]}>{ActivityDurations[key]}</MenuItem>
					))}
				</Select>
			</Grid>
		);
	};

	changeAppointmentDuration = (event: React.ChangeEvent<{ value: string[] }>) => {
		const { value } = event.target;
		this.setState({ appointmentDurations: orderActivityDurations(value as ActivityDurations[]) });
	};

	renderAppointmentTypes = () => {
		const { inEdit } = this.state;
		const appointmentTypes = inEdit
			? this.state.appointmentTypes
			: this.props.settings.appointmentSettings.types || [];
		return (
			<Grid container style={{ paddingTop: 20 }}>
				<Grid item xs={12}>
					<InputLabel shrink>Available Appointment Types</InputLabel>
				</Grid>
				<Grid item xs={4}>
					<FormControlLabel
						control={
							<Checkbox 
								checked={appointmentTypes.includes(ClientConnectAppointmentTypes.Phone)} 
								onChange={this.changeAppointmentType} 
								name={ClientConnectAppointmentTypes.Phone}
							/>
						}
						label={<Typography variant="body2">Phone</Typography>}
						disabled={!inEdit}
					/>
				</Grid>
				<Grid item xs={8}>
					<FormControlLabel
						control={
							<Checkbox 
								checked={appointmentTypes.includes(ClientConnectAppointmentTypes.InPerson)} 
								onChange={this.changeAppointmentType} 
								name={ClientConnectAppointmentTypes.InPerson} 
							/>
						}
						label={<Typography variant="body2">In-Person</Typography>}
						disabled={!inEdit}
					/>
				</Grid>
				{inEdit && <FormHelperText>{'The types of appointments that a customer can schedule with you.  At least one option must be selected.  For In-Person appointments, customers can choose from your Storefront Addresses.'}</FormHelperText>}
			</Grid>
		);
	}

	changeAppointmentType = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, checked } = event.target;
		const appointmentTypes = checked
			? this.state.appointmentTypes.concat(name as ClientConnectAppointmentTypes)
			: this.state.appointmentTypes.filter(type => type !== name);
		this.setState({ appointmentTypes });
	};

	render() {
		const { hasIcons } = this.props;
		const { inEdit, filteredOptions, contactList=[] } = this.state;

		const actions = this.state.inEdit
			? this.renderSaveButton()
			: this.renderEditButton();

		return (
			<>
				<CCCard
					title={Strings.AboutMeLabels.ClientConnectSettings}
					hasIcons={hasIcons}
					isActivated={this.state.isActivated}
					inEdit={this.state.inEdit}
					actions={actions}
					noPadding
				>
					<Grid container>
						<Grid container>
							<SimpleListItem
								subtitle={'Contacts'}
								secondaryAction={
									inEdit && (
										<MoreMenu
											icon={'add_circle_outline'}
											color={themePalette.tertiary_text}
											children={filteredOptions.map(option => (
												createMenuAction(option, this.createNewContact.bind(this, option))
											))}
										/>
									)
								}
							/>
							<Grid container style={{ padding: 20 }}>
								{contactList.map((contact: Contact) => {
									const renderFunction = this.getRenderTypeFunction(contact.type);
									if (renderFunction) {
										return (
											<Grid item xs={12}>
												{renderFunction(contact)}
											</Grid>
										);
									}
								})}
							</Grid>
						</Grid>
						{themeMisc.clientConnectAppointments && <Grid container>
							<SimpleListItem
								subtitle={'Appointments'}
							/>
							<Grid container style={{ padding: 20 }}>
								{this.renderAppointmentDates()}
								{this.renderAppointmentDurations()}
								{this.renderAppointmentTypes()}
							</Grid>
						</Grid>}
					</Grid>
				</CCCard>
				<Dialog open={this.state.activatedDialog}>
					<DialogTitle>ClientConnect is now activated</DialogTitle>
					<DialogActions>
						{[
							<Button
								key={'stay'}
								onClick={() => {
									this.setState({ activatedDialog: false });
								}}
							>
								Stay here
							</Button>,
							<Button
								key={'go'}
								color="primary"
								onClick={() => {
									this.props.navigateTo(navRoutes.clientConnect.path);
								}}
							>
								Go to ClientConnect
							</Button>,
						]}
					</DialogActions>

				</Dialog>
			</>
		);
	}
}

const mapStateToProps = (state: AppState): StateProps => ({
	jwt: state.authentication.jwt,
	agentCode: state.user.agentID,
	settings: { ...state.clientConnect.settings },
	hasIcons: !!state.agent.iconImages && !!state.agent.iconImages.length,
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	navigateTo: (route: string) => dispatch(navigateTo(route)),
	fetchClientConnectSettings: () => dispatch(FetchClientConnectSettings()),
	postClientConnectSettings: (settings: any) =>
		dispatch(PostClientConnectSettings(settings)),
});
export const ClientConnectSettingsCard = connect(mapStateToProps, mapDispatchToProps, true)(CCSettings);