import { navigateToWithoutAddingToHistory } from '../actions/navigation_actions';
import { Application } from '../reducers/application_reducer';
import { Policy } from '../reducers/policy_reducer';
import { Activity } from '../reducers/activity_reducer';
import { call, put, all, debounce, takeEvery, takeLatest } from 'redux-saga/effects';
import http, { HttpOptions } from '../utilities/http';
import { Lead } from '../reducers/LeadReducer';
import {
	GetHousehold,
	GetHouseholdContacts,
	RemoveContactFromHousehold,
	SetNewPrimaryContactOnHousehold,
	ReassignHousehold,
	MergeHouseholds,
	MergeHouseholdRequest,
	ContactOnHouseholdParams,
	ReassignHouseholdParams,
	saveHouseholdTags,
	HouseholdTagPayload,
	getHouseholdTags
} from '../actions/household_actions';
import { Contact, HouseholdRole } from '../reducers/ContactReducer';
import { QueueSnackbar, SnackbarProps } from '../actions/snackbar_actions';
import { Note } from '../reducers/note_reducer';
import { Attachment } from '../reducers/attachment_reducer';
import { OwnershipHistory } from '../reducers/ownership_history_reducer';
import { navRoutes } from '../components/nav/Routes';
import { GetEmailHistorySummarySetByHousehold } from '../actions/email_summary_actions';
import { Strings as S } from '../assets/common/strings';
import { submitLog } from '../utilities/logging_util';
import { getAppState } from '..';
import { ExecuteAdvancedSearch } from '../actions/advanced_search_actions';
import { getSnackbarErrorProps, getSnackbarSuccessProps } from '../utilities/snackbar_util';
import { QuoteActivity } from '../reducers/quote_activity_reducer';
import { Action } from 'typescript-fsa';
import { TagDto } from '../reducers/shared/tags';


export interface FullHousehold {
	householdId: string;
	contacts: Contact[];
	leads: Lead[];
	activities: Activity[];
	quoteActivities: QuoteActivity[];
	policies: Policy[];
	applications: Application[];
	notes: Note[];
	attachments: Attachment[];
	ownershipHistory: OwnershipHistory[];
	createdOn: Date;
	createdBy: string;
	updatedOn: Date;
	updatedBy: string;
	updatedByName: string;
	createdByName: string;
	tags?: string[];
}

// GET HOUSEHOLD
function* getHouseholdSaga(action: Action<string>) {
	if (!action.payload || action.payload === 'new'){
		return;
	}
	const errorSnackbarProps = getSnackbarErrorProps('Error getting household data');
	try {
		const response = yield call(getHouseholdClient, action.payload);

		if (response.ok) {
			const fullHousehold: FullHousehold = yield response.json();
			if (!fullHousehold) {
				return;
			}

			hydrateHouseholdLeadContacts(fullHousehold);
			yield put(
				GetHousehold.done({ params: action.payload, result: fullHousehold })
			);
			yield put(GetEmailHistorySummarySetByHousehold.started(action.payload));
		} else {
			yield put(
				GetHousehold.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
			yield put(QueueSnackbar(errorSnackbarProps));
		}
	} catch (error) {
		yield put(GetHousehold.failed({ params: action.payload, error }));
		yield put(QueueSnackbar(errorSnackbarProps));
	}
}
export function getHouseholdClient(id: string) {
	return http('household/' + id);
}

// GET HOUSEHOLD CONTACTS
function* getHouseholdContactsSaga(action: Action<string>) {
	const errorSnackbarProps = getSnackbarErrorProps('Error getting household contacts');

	try {
		const response = yield call(getHouseholdContactsClient, action.payload);
		if (response.ok) {
			const householdContacts: Contact[] = yield response.json();
			yield put(
				GetHouseholdContacts.done({
					params: action.payload,
					result: householdContacts,
				})
			);
		} else {
			yield put(
				GetHouseholdContacts.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
			yield put(QueueSnackbar(errorSnackbarProps));
		}
	} catch (error) {
		yield put(GetHouseholdContacts.failed({ params: action.payload, error }));
		yield put(QueueSnackbar(errorSnackbarProps));
	}
}
function getHouseholdContactsClient(householdId: string): Promise<any> {
	return http('household/contacts/' + householdId);
}

// SET NEW PRIMARY CONTACT
function* setNewPrimaryContactSaga(action: Action<ContactOnHouseholdParams>) {
	const snackBarProps: SnackbarProps = getSnackbarErrorProps('Unable to set contact as primary');

	try {
		const response = yield call(
			setNewPrimaryContactClient,
			action.payload.householdId,
			action.payload.contactId
		);
		if (response.ok) {
			const data: FullHousehold = yield response.json();
			hydrateHouseholdLeadContacts(data);
			yield put(
				SetNewPrimaryContactOnHousehold.done({
					params: action.payload,
					result: data,
				})
			);
		} else {
			yield put(
				SetNewPrimaryContactOnHousehold.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
			yield put(QueueSnackbar(snackBarProps));
		}
	} catch (error) {
		yield put(
			SetNewPrimaryContactOnHousehold.failed({
				params: action.payload,
				error,
			})
		);
		yield put(QueueSnackbar(snackBarProps));
	}
}
export function setNewPrimaryContactClient(householdId: string, contactId: string) {
	const options: HttpOptions = { method: S.Http.Post };
	return http(
		'household/SetNewPrimary/' + householdId + '/' + contactId,
		options
	);
}

// REMOVE CONTACT FROM HOUSEHOLD
function* removeContactFromHouseholdSaga(action: Action<ContactOnHouseholdParams>) {
	const snackBarProps: SnackbarProps = getSnackbarErrorProps('Unable to remove member from household');

	try {
		const response = yield call(
			removeContactFromHouseholdClient,
			action.payload.householdId,
			action.payload.contactId
		);
		if (response.ok) {
			const data: FullHousehold = yield response.json();
			hydrateHouseholdLeadContacts(data);
			yield put(
				RemoveContactFromHousehold.done({
					params: action.payload,
					result: data,
				})
			);
		} else {
			yield put(
				RemoveContactFromHousehold.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
			yield put(QueueSnackbar(snackBarProps));
		}
	} catch (error) {
		yield put(
			RemoveContactFromHousehold.failed({ params: action.payload, error })
		);
		yield put(QueueSnackbar(snackBarProps));
	}
}
function removeContactFromHouseholdClient(householdId: string, contactId: string) {
	const options: HttpOptions = { method: S.Http.Post };
	return http(
		'household/RemoveContactFromHousehold/' + householdId + '/' + contactId,
		options
	);
}

function* mergeHouseholdSaga(action: Action<MergeHouseholdRequest>) {
	try {
		const response = yield call(mergeHouseholdClient, action.payload)
		if (response.ok) {

			const state = getAppState();
			yield put(
				MergeHouseholds.done({ params: action.payload, result: {} })
			);

			yield put(ExecuteAdvancedSearch.started({
				searchFilterObject: state.advancedSearch.appliedSearchFilters,
				pageIndex: 0,
				pageSize: state.infiniteScroll.pageSize
			}));

			successfulSnackBar(S.Household.SuccessMergeHouseholds);
		}
		else {
			yield mergeHouseholdError(response.status);
		}
	}
	catch (error) {
		yield mergeHouseholdError(error);
	}
}


function* mergeHouseholdError(error: any) {
	let errorText: string = S.Household.FailedMergeHouseholds;
	yield put(MergeHouseholds.failed);

	createErrorMessages(errorText, error);
}

function mergeHouseholdClient(mergeHouseholdRequest: MergeHouseholdRequest) {
	const options: HttpOptions = {
		method: S.Http.Post

	};
	return http(
		'household/MergeHouseholds/?primaryhouseholdId=' + mergeHouseholdRequest.primaryHouseholdId
		+ '&secondaryHouseholdId=' + mergeHouseholdRequest.secondaryHouseholdId,
		options
	);
}


// REASSIGN HOUSEHOLD
function* startReassignHouseholdSaga(action: Action<ReassignHouseholdParams>) {
	try {
		const response = yield call(
			reassignHouseholdClient,
			action.payload.householdId,
			action.payload.agentCode
		);

		if (response.ok) {
			const data: boolean = yield response.json();

			yield put(
				ReassignHousehold.done({ params: action.payload, result: data })
			);

			yield put(
				QueueSnackbar(getSnackbarSuccessProps(`This lead has been reassigned to ${action.payload.agentName}`))
			);

			yield put(
				navigateToWithoutAddingToHistory(navRoutes.leadDashboard.path)
			);

		} else {
			yield put(
				ReassignHousehold.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
			yield put(
				QueueSnackbar(getSnackbarErrorProps(`Unable to reassign household`))
			);
		}
	} catch (error) {
		yield put(ReassignHousehold.failed({ params: action.payload, error }));
		yield put(
			QueueSnackbar(getSnackbarErrorProps(`Unable to reassign household`))
		);
	}
}

function reassignHouseholdClient(householdId: string, newAgentCode: string) {
	const options: HttpOptions = { method: S.Http.Post };
	let agentURL = 'household/reassign/' + householdId + '?newAgentCode=' + newAgentCode;

	return http(agentURL, options);
}

// HELPER FUNCTIONS
export function hydrateHouseholdLeadContacts(fullHousehold: FullHousehold) {
	if (!fullHousehold) return;
	const numberOfContacts = fullHousehold.contacts.length;

	fullHousehold.contacts.forEach(contact => {
		contact.numberOfHouseholdMembers = numberOfContacts - 1;
	});

	fullHousehold.leads.forEach(lead => {
		lead.contacts.forEach((contact, index) => {

			const contactIndex = fullHousehold.contacts.findIndex(_contact => {
				return _contact.id === contact.id;
			});

			if (contactIndex > -1) {
				lead.contacts[index] = fullHousehold.contacts[contactIndex];
			}
		});
	});

	const primaryContact = fullHousehold.contacts.find(
		contact => contact.householdRole === HouseholdRole.Primary
	);

	fullHousehold.activities.forEach(activity => {
		if (!activity.contact && primaryContact) {
			activity.contact = { ...primaryContact };
		} else {
			const contact = fullHousehold.contacts.find(
				contact => contact.id == activity.contact.id
			);

			activity.contact = contact
				? contact
				: activity.contact;
		}
	});
}

function* saveHouseholdTagsSaga(action: Action<HouseholdTagPayload>) {
	try {
		const body = JSON.stringify({ tags: action.payload.tags });
		const response = yield call(http, `household/${action.payload.householdId}/tags`, { method: S.Http.Post, body });
		if (response.ok) {
			const fullHousehold: FullHousehold = yield response.json();
			yield put(GetHousehold.done({ params: action.payload.householdId, result: fullHousehold }));
		} else {
			yield put(GetHousehold.failed({
				params: action.payload.householdId,
				error: { errorCode: response.status },
			}));
			yield createErrorMessages('Unable to save tags to household', response);
		}
	} catch (err) {
		yield put(GetHousehold.failed({
			params: action.payload.householdId,
			error: err,
		}));
		yield createErrorMessages('Unable to save tags to household', err);
	}
}

function* getHouseholdTagsSaga(action: Action<string>) {
	try {
		if (action.payload) {
			const response = yield call(http, `household/tags?search=${action.payload}`);
			if (response.ok) {
				const tags: TagDto[] = yield response.json();
				yield put(getHouseholdTags.done({ params: action.payload, result: tags?.map(t => t.tag) }));
			} else {
				yield put(getHouseholdTags.failed({
					params: action.payload,
					error: { errorCode: response.status },
				}));
				yield createErrorMessages('Unable to get household tags', response);
			}
		}
	} catch (err) {
		yield put(getHouseholdTags.failed({
			params: action.payload,
			error: err,
		}));
		yield createErrorMessages('Unable to get household tags', err);
	}
}

function* createErrorMessages(errorText: string, error: any) {
	yield submitLog(error, errorText, { error });
	yield put(QueueSnackbar(getSnackbarErrorProps(errorText)));
}

function* successfulSnackBar(successText: string) {
	yield put(QueueSnackbar(getSnackbarSuccessProps(successText)));
};

export function* householdSagas() {
	yield all([
		takeLatest(
			GetHousehold.started,
			getHouseholdSaga
		),
		takeLatest(
			GetHouseholdContacts.started,
			getHouseholdContactsSaga
		),
		takeLatest(
			SetNewPrimaryContactOnHousehold.started,
			setNewPrimaryContactSaga
		),
		takeLatest(
			RemoveContactFromHousehold.started,
			removeContactFromHouseholdSaga
		),
		takeLatest(
			MergeHouseholds.started,
			mergeHouseholdSaga
		),
		takeEvery(
			ReassignHousehold.started,
			startReassignHouseholdSaga
		),
		takeLatest(
			saveHouseholdTags.started,
			saveHouseholdTagsSaga
		),
		debounce(
			600,
			getHouseholdTags.started,
			getHouseholdTagsSaga
		),
	]);
}
