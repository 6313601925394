import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Grid, LinearProgress, Typography } from '@material-ui/core';
import { themePalette } from '../../utilities/branding';

interface Props {
    titleLeft: string;
    color: string;
    isLoading?: boolean;
    noData?: boolean;
    titleRight?: string;
}

export const ReportCard: React.FC<Props> = props => {
    const classes = useStyles();
    const { titleLeft, color, isLoading, noData, titleRight, children } = props;

    return (
        <Card style={{marginBottom: 20}}>
            <Grid
                container
                style={{backgroundColor: getActiveColor(color)}}
            >
                <Grid item xs className={classes.title} style={{textAlign: 'left'}}>
                    {titleLeft}
                </Grid>
                {titleRight && (
                    <Grid item xs className={classes.title} style={{textAlign: 'right'}}>
                        {titleRight}
                    </Grid>
                )}
                {isLoading && (
                    <div className={classes.loading}>
                        <LinearProgress />
                    </div>
                )}
            </Grid>
            {noData
            ? (
                <Typography variant="h6" align="center" className={classes.noData}>
                    No Data Available
                </Typography>)
            : (
                children)}
        </Card>
    )
};

const getActiveColor = (color: string) => {
    let activeColor = themePalette.bubbles_secondary;
    if (color === 'primary') {
        activeColor = themePalette.bubbles_primary;
    } else if (color === 'tertiary') {
        activeColor = themePalette.bubbles_tertiary;
    }
    return activeColor;
}

const useStyles = makeStyles({
    title: {
		overflow: 'hidden',
		WebkitTextSizeAdjust: '100%',
		fontFamily: 'Roboto, sans-serif',
		fontSize: '14px',
		fontWeight: 'bold',
		marginTop: 7,
		marginBottom: 7,
		padding: 10,
		color: 'white',
    },
    loading: {
        width: '100%',
        paddingBottom: 20,
        backgroundColor: '#fff',
    },
    noData: {
        width: '100%',
        padding: 30,
    }
});
