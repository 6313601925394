import React from 'react';
import { List, Typography } from '@material-ui/core';
import { PrescriptionSelectListItem, Drug } from './index';
import { Strings } from '../../../../../assets/common/strings';

interface Props {
    drugs: Drug[];
    deleteDrug: (drug: Drug) => void;
    editDrug: (drug: Drug) => void;
    readOnly?: boolean;
}

export const PrescriptionSelectedList: React.FC<Props> = ({ drugs, deleteDrug, editDrug, readOnly }) => {

    return (
        <>
          <Typography variant="subtitle2" >
            {Strings.FactFinderPrescriptionLookup.selectedDrugs}
          </Typography>
          <div>
            <List>
              {drugs.map(item => (
                <PrescriptionSelectListItem
                    key={item.rxcui}
                    drug={item}
                    deleteDrug={deleteDrug}
                    editDrug={editDrug}
                    readOnly={readOnly}
                />
              ))}
            </List>
          </div>
        </>
    )
};

