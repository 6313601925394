import { Action as ReduxAction } from 'redux';
import { isType } from 'typescript-fsa';
import {
	getEmailHistorySummarySetByEmployer,
	GetEmailHistorySummarySetByHousehold,
} from '../actions/email_summary_actions';
import { Activity, cleanupActivity } from './activity_reducer';
import { Loaded } from '../utilities/utilities';
import { ClearCaches } from '../actions/authentication_actions';
import { Logout } from '../actions/authentication_actions';

export interface EmailHistorySummaryState {
	emailHistorySummarySet: Loaded<Activity>[];
	isSetLoading: boolean;
}

const initialState: EmailHistorySummaryState = {
	emailHistorySummarySet: [],
	isSetLoading: false,
};

export function emailHistorySummaryReducer(
	state: EmailHistorySummaryState = initialState,
	action: ReduxAction
): EmailHistorySummaryState {
	if (
		isType(action, getEmailHistorySummarySetByEmployer.started) ||
		isType(action, GetEmailHistorySummarySetByHousehold.started)
	) {
		return {
			...state,
			isSetLoading: true,
		};
	} else if (
		isType(action, getEmailHistorySummarySetByEmployer.done) ||
		isType(action, GetEmailHistorySummarySetByHousehold.done)
	) {
		const activitiesInState = state.emailHistorySummarySet.slice();
		updateStateWithInboundActivities(activitiesInState, action.payload.result);

		return {
			...state,
			emailHistorySummarySet: activitiesInState,
			isSetLoading: false,
		};
	} else if (
		isType(action, getEmailHistorySummarySetByEmployer.failed) ||
		isType(action, GetEmailHistorySummarySetByHousehold.failed)
	) {
		return { ...state, isSetLoading: false };
	} else if (isType(action, Logout.started)) {
		return {
			...initialState,
		};
	} else if (isType(action, ClearCaches)) {
		return {
			...initialState,
		};
	} else {
		return state;
	}
}

function updateStateWithInboundActivities(
	activitiesInState: Loaded<Activity>[],
	inboundActivities: Activity[]
) {
	inboundActivities.forEach(inboundActivity => {
		const activityId = inboundActivity.id;
		let wasAActivityUpdated = false;
		const householdId =
			inboundActivity.contact && inboundActivity.contact.householdId;
		const employerId =
			inboundActivity.contact && inboundActivity.contact.employerId;

		cleanupActivity(inboundActivity);

		activitiesInState.forEach((currentActivity, index) => {
			const currentActivityId = currentActivity.data.id;
			if (currentActivityId === activityId) {
				wasAActivityUpdated = true;
				activitiesInState[index] = {
					...activitiesInState[index],
					loading: false,
					data: inboundActivity,
					employerId: employerId,
					householdId: householdId,
					errors: [],
				};
			}
		});
		if (!wasAActivityUpdated) {
			const newActivity: Loaded<Activity> = {
				loading: false,
				data: inboundActivity,
				errors: [],
				employerId: employerId,
				householdId: householdId,
			};
			activitiesInState.push(newActivity);
		}
	});
}
