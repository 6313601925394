import { Action as ReduxAction } from 'redux';
import { isType } from 'typescript-fsa';
import { GetAgentCommissionStatementSummary, GetAgentCommissionStatementDates } from '../actions/commission_statement_actions';

export interface AgentCommissionStatementSummary {
    StatementDate: Date;
    AgentCode: string;
    UnearnedBalanceOpening: number;
    UnearnedBalanceClosing: number;
    BalanceBeginning: number;
    BalanceEnding: number;
    PersonalEarnedComm: number;
    PersonalFirstYear: number;
    PersonalRenewal: number;
    UnearnedBalanceLapsePolicy: number;
    OverridesEarnedComm: number;
    OverridesFirstYear: number;
    OverridesRenewal: number;
    Adjustments: number;
}

export interface AgentCommissionStatementState {
    agentCommissionStatementDates: Date[];
    agentCommissionStatementSummary: AgentCommissionStatementSummary;
    loading: boolean;
}

const initialSummary: AgentCommissionStatementSummary = {
    StatementDate: new Date(),
    AgentCode: "",
    UnearnedBalanceOpening: 0,
    UnearnedBalanceClosing: 0,
    BalanceBeginning: 0,
    BalanceEnding: 0,
    PersonalEarnedComm: 0,
    PersonalFirstYear: 0,
    PersonalRenewal: 0,
    UnearnedBalanceLapsePolicy: 0,
    OverridesEarnedComm: 0,
    OverridesFirstYear: 0,
    OverridesRenewal: 0,
    Adjustments: 0
}

const initialState: AgentCommissionStatementState = {
    agentCommissionStatementDates: [],
    agentCommissionStatementSummary: initialSummary,
    loading: false
};

export function commissionStatementReducer(
    state: AgentCommissionStatementState = initialState,
    action: ReduxAction
): AgentCommissionStatementState {

    if (isType(action, GetAgentCommissionStatementDates.started)) {
        return {
            ...state,
            loading: true
        }
    } else if (isType(action, GetAgentCommissionStatementDates.done)) {
        return {
            ...state,
            agentCommissionStatementDates: action.payload.result,
            loading: false
        }
    } else if (isType(action, GetAgentCommissionStatementSummary.done)) {
        return {
            ...state,
            agentCommissionStatementSummary: action.payload.result,
            loading: false
        }
    }
    else {
        return state;
    }
}