import React from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import { jwt_auth } from '../../utilities/auth';
import { Strings as S, Strings } from '../../assets/common/strings';
import { P } from '../../utilities/auth/permissions';
import moment from 'moment';
import { SmsTemplate } from '../../reducers/sms_template_reducer';
import { GetAllSmsTemplates, CreateSmsTemplate, UpdateSmsTemplate, DeleteSmsTemplate } from '../../actions/sms_template_actions';
import { TemplateTypeOptions } from '../../reducers/template_reducer';
import { Dictionary, makeStringDictionary } from '../../utilities/object_util';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { NavigationProps, navRoutes } from '../../components/nav/Routes';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import Fab from '../../components/nav/fab';
import { navigateBack, navigateTo } from '../../actions/navigation_actions';
import { User } from '../../reducers/user_reducer';
import { themePalette } from '../../utilities/branding';
import { Lookups } from '../../utilities/lookup';
import { renderCell, ColumnOption, FilterTableList } from '../../components/Layout/table/filter_table';
import { SmsTemplatePreviewState } from './sms_template_page';
import { FilterComponents, FilterFieldProps } from '../../components/Layout/filter';
import { AppState } from '../../reducers';

interface StateProps {
    smsTemplates: SmsTemplate[];
    isLoading: boolean;
    user: User;
    substitutions: string[];
}

interface DispatchProps {
    getSmsTemplates: () => void;
    createSmsTemplate: (template: SmsTemplate) => void;
    updateSmsTemplate: (template: SmsTemplate) => void;
    deleteSmsTemplate: (template: SmsTemplate) => void;
}

type Props = StateProps & DispatchProps & NavigationProps;

type State = {
    selectedSmsTemplate?: SmsTemplate;
    confirmDelete: boolean;
}

export const templateTypeOptions = makeStringDictionary([
    TemplateTypeOptions.agent,
    TemplateTypeOptions.corporate
]);

export const languageOptions = makeStringDictionary([
    "English",
    "Spanish",
]);
class TemplateTableWithFilterComponent extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            confirmDelete: false,
        }
    };

    componentDidMount() {
        this.props.getSmsTemplates();
    }

    filters: FilterFieldProps[] = jwt_auth.hasPermission(P.CorporateTemplatesRead)
        ? [
            {
                label: "Template Type",
                options: templateTypeOptions,
                compareFn: (t: SmsTemplate, selection: Dictionary<string>) => !!selection["All"] || !!selection[t.templateType],
                includeAll: true,
                component: FilterComponents.Dropdown,
                defaultSelection: { All: "All" }
            }, {
                label: "Language",
                options: languageOptions,
                compareFn: (t: SmsTemplate, selection: Dictionary<string>) => !!selection["All"] || !t.language || !!selection[t.language],
                includeAll: true,
                component: FilterComponents.Dropdown,
                defaultSelection: { All: "All" }
            }
        ]
        : [{
            label: "Language",
            options: languageOptions,
            compareFn: (t: SmsTemplate, selection: Dictionary<string>) => !!selection["All"] || !t.language || !!selection[t.language],
            includeAll: true,
            component: FilterComponents.Dropdown,
            defaultSelection: { All: "All" }
        }
        ];

    columns: ColumnOption[] = [
        {
            label: "Title",
            field: "subject",
            render: (t: SmsTemplate) => renderCell(t.subject),
        }, {
            label: "Language",
            field: "language",
            render: (t: SmsTemplate) => renderCell(t.language),
        }, {
            label: "Updated On",
            field: "updatedOn",
            render: (t: SmsTemplate) => renderCell(moment(t.updatedOn).format(S.DateFormat.MDYYYYHMMA)), //TODO back to moment
        }, {
            label: "Template Type",
            field: "templateType",
            render: (t: SmsTemplate) => renderCell(t.templateType),
        }
    ];

    navigate = (newState: SmsTemplatePreviewState) => (template: SmsTemplate) => this.props.navigateTo(
        navRoutes.smsTemplatePreview.path
            .replace(Strings.Navigation.TemplateId, template.id)
            .replace(Strings.Navigation.TemplatePageState, newState)
    );

    onPreview = this.navigate("Preview");
    onEdit = this.navigate("Edit");

    onCreate = () => this.props.navigateTo(
        navRoutes.smsTemplatePreview.path.replace(Strings.Navigation.TemplatePageState, "Create")
    );

    onDelete = (smsTemplate: SmsTemplate) => this.setState({ selectedSmsTemplate: smsTemplate, confirmDelete: true });

    onCloseDelete = () => this.setState({ selectedSmsTemplate: undefined, confirmDelete: false });
    onConfirmDelete = () => {
        this.props.deleteSmsTemplate(this.state.selectedSmsTemplate!)
        this.onCloseDelete();
    }

    renderHeader() {
        return (
            <HeaderBarComponent
                title={'Manage SMS Template'}
                isLoading={this.props.isLoading}
            />
        );
    }

    renderTable() {
        const { smsTemplates } = this.props;
        const hasCorporateUpdate = jwt_auth.hasPermission(P.CorporateSmsUpdate);
        const hasCorporateDelete = jwt_auth.hasPermission(P.CorporateSmsDelete);
        return <FilterTableList
            filterProps={this.filters}
            columns={this.columns}
            rows={smsTemplates}
            onClick={this.onPreview}
            rowActions={{
                Preview: { action: this.onPreview, isDisabled: (row: SmsTemplate) => false },
                Edit: { action: this.onEdit, isDisabled: (row: SmsTemplate) => row.templateType == S.TemplateType.Corporate && !hasCorporateUpdate },
                Delete: { action: this.onDelete, isDisabled: (row: SmsTemplate) => row.templateType == S.TemplateType.Corporate && !hasCorporateDelete },
            }}
        />

    }

    renderDeleteDialog = () => <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={this.state.confirmDelete}
    >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
            {'Are you sure you want to delete this template?'}
        </DialogContent>
        <DialogActions>
            <Button color="secondary" onClick={this.onCloseDelete}>
                Cancel
        </Button>
            <Button
                color="primary"
                style={{ backgroundColor: themePalette.delete_remove_reject_button }}
                onClick={this.onConfirmDelete}
                variant="contained"
            >
                Delete
            </Button>
        </DialogActions>
    </Dialog>

    render() {
        return (
            <BasePageContainer
                topComponent={this.renderHeader()}
                middleComponent={this.renderTable()}
                bottomComponent={
                    <Fab
                        icon="add"
                        onClick={this.onCreate}
                    />
                }
                pageDialogs={[this.renderDeleteDialog()]}
            />
        );
    }
}

function mapStateToProps(state: AppState): StateProps {
    const dictionary = state.smsTemplate.smsTemplates;

    const smsTemplates = Object.keys(dictionary).map(templateId => dictionary[templateId]);

    return {
        smsTemplates,
        isLoading: state.smsTemplate.isLoading,
        user: state.user,
        substitutions: state.lookup.getLabels(Lookups.EmailTemplateSubstitution),
    };
}

function mapDispatchToProps(dispatch: any): DispatchProps & Partial<NavigationProps> {
    return {
        getSmsTemplates: () => dispatch(GetAllSmsTemplates.started(undefined)),
        createSmsTemplate: (template: SmsTemplate) => dispatch(CreateSmsTemplate.started(template)),
        updateSmsTemplate: (template: SmsTemplate) => dispatch(UpdateSmsTemplate.started(template)),
        deleteSmsTemplate: (template: SmsTemplate) => dispatch(DeleteSmsTemplate.started(template)),
        navigateBack: () => dispatch(navigateBack()),
        navigateTo: (route: string) =>
            dispatch(navigateTo(route)),
    };
}

export const SmsTemplateManagementPage = connect(
    mapStateToProps,
    mapDispatchToProps,
    true
)(TemplateTableWithFilterComponent);
