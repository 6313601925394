import React from 'react';
import {
	ListItem,
	ListItemText,
	ListItemAvatar,
	Avatar,
	Icon,
	CircularProgress,
} from '@material-ui/core';

import { themePalette } from '../../utilities/branding';
import { Contact, HouseholdRole, Address } from '../../reducers/ContactReducer';
import { initials, fullName } from '../../assets/common/string_builders';
import { getOldestItem, getAgeInYears } from '../../utilities/date_util';
import { Strings } from '../../assets/common/strings';

export interface SearchResultProps {
	avatar?: any;
	onClick?: (resultId?: string) => void;
	primaryText?: string;
	secondaryText?: string;
	isSelected?: boolean;
	isLoading?: boolean;
	resultId?: string;
	spans?: JSX.Element[];
	hidden?: boolean;
}

export class SearchResult extends React.PureComponent<SearchResultProps> {
	renderAvatar = () => {
		const {
			isLoading,
			avatar,
			isSelected
		} = this.props;

		if (isLoading) {
			return <CircularProgress size={40} />;
		} else if (isSelected) {
			return (
				<Avatar
					style={{
						color: themePalette.negative_text,
						backgroundColor: themePalette.selected_avatar,
						alignSelf: 'center',
					}}
				>
					<Icon>check</Icon>
				</Avatar>
			);
		} else if (avatar) {
			return avatar;
		} else {
			return (
				<Avatar
					style={{
						color: themePalette.negative_text,
						backgroundColor: themePalette.default_avatar,
						alignSelf: 'center',
					}}
				>
					<Icon>check</Icon>
				</Avatar>
			);
		}
	};

	renderPrimaryText = () => {
		const {
			primaryText, spans
		} = this.props;

		if (primaryText) {
			const primarySpan = (
				<span key={'primary_span_text'}>{primaryText}</span>
			);
			if (spans) return [primarySpan].concat(spans);
			else return primarySpan;
		} else {
			return spans;
		}
	};

	onClick = () => this.props.onClick && this.props.onClick(this.props.resultId);

	render() {
		const {
			onClick,
			hidden,
			secondaryText
		} = this.props;

		const hasOnClickAction = Boolean(onClick);
		return (
			<ListItem
				divider
				button={!hasOnClickAction ? false : undefined}
				onClick={this.onClick}
				hidden={hidden}
			>
				<ListItemAvatar>
					{this.renderAvatar()}
				</ListItemAvatar>
				<ListItemText
					primary={this.renderPrimaryText()}
					secondary={secondaryText}
				/>
			</ListItem>
		);
	}
}

export interface MapOptions {
	includeAgeInSecondaryText: boolean;
	showPrimaryText: boolean;
	useDefaultAddressIfNone: boolean;
}
const defaultOptions: MapOptions = {
	includeAgeInSecondaryText: false,
	showPrimaryText: false,
	useDefaultAddressIfNone: false,
};
export const mapContactsToSearchResults = (contacts: Contact[], options: MapOptions = defaultOptions): SearchResultProps[] => {
	return contacts.map(contact => {
		const agentInitials = initials(contact);

		const agentAvatar = (
			<Avatar
				style={{
					alignSelf: 'center',
					backgroundColor: themePalette.default_avatar,
				}}
			>
				{agentInitials}
			</Avatar>
		);
		const primaryAddressIndex = contact.addresses
			? contact.addresses.findIndex(address => address.isPreferred)
			: -1;
		let addressString = '';
		if (contact.addresses && contact.addresses.length > 0) {

			let address = contact.addresses[0];
			if (primaryAddressIndex > -1) {
				address = contact.addresses[primaryAddressIndex];

			} else if (options.useDefaultAddressIfNone) {

				const homeAddresses = contact.addresses
					.filter(address => address.type === Strings.ContactInfoTypes.Home);
				const workAddresses = contact.addresses
					.filter(address => address.type === Strings.ContactInfoTypes.Business);
				const mailingAddresses = contact.addresses
					.filter(address => address.type === Strings.ContactInfoTypes.Mailing);

				const getAddressDateCreated = (address: Address) => address.dateCreated;
				if (homeAddresses.length > 0) {
					address = getOldestItem(homeAddresses, getAddressDateCreated)!;
				} else if (workAddresses.length > 0) {
					address = getOldestItem(workAddresses, getAddressDateCreated)!;
				} else if (mailingAddresses.length > 0) {
					address = getOldestItem(mailingAddresses, getAddressDateCreated)!;
				}
			}
			addressString =
				address.city && address.state
					? address.city + ', ' + address.state
					: address.city ? address.city : address.state || '';
			if (address.zipCode) {
				addressString = addressString
					? addressString.concat(', ', address.zipCode)
					: address.zipCode;
			}
		}

		const ageString = contact.birthDate
			? ` Age: ${getAgeInYears(contact.birthDate)}`
			: '';

		const role = HouseholdRole[contact.householdRole];
		const otherMembers = contact.numberOfHouseholdMembers && contact.numberOfHouseholdMembers > 1
			? contact.numberOfHouseholdMembers - 1
			: 0;
		const spanString = `Household, ${role}, +${otherMembers} others`;
		const container = document.getElementById('addContactDialog');
		const containerWidth = container ? container.clientWidth : 0;
		const detailsSpan =
			container && container.clientWidth > 700 ? (
				<span
					style={{ position: 'absolute', right: '10%', textAlign: 'right' }}
					id={contact.id}
					key={contact.id}
				>
					{spanString}
				</span>
			) : (
					<span key={'blank' + Math.random()} />
				);
		const primaryText = fullName(contact);
		const detailSpan = document.getElementById(contact.id);
		const primaryTextWidth = () =>
			detailSpan ? 800 - detailSpan.clientWidth : containerWidth;
		const primarySpan = (
			<span
				style={{
					width: primaryTextWidth(),
					overflowX: 'hidden',
					textOverflow: 'ellipsis',
				}}
				key={primaryText}
			>
				{primaryText}
			</span>
		);
		const spans = [detailsSpan].concat(primarySpan);

		return {
			avatar: agentAvatar,
			resultId: contact.id,
			primaryText: options.showPrimaryText ? primaryText : undefined,
			secondaryText: options.includeAgeInSecondaryText ? addressString.concat(ageString) : addressString,
			spans,
		};
	});
};