
type GetUserMediaFunc = (constraints: MediaStreamConstraints) => Promise<MediaStream>;
interface MediaDevices {
    getUserMedia: GetUserMediaFunc;
}

interface Navigator {
    mediaDevices: MediaDevices;
    webkitGetUserMedia?: GetUserMediaFunc;
    mozGetUserMedia?: GetUserMediaFunc;
}

const n = <Navigator>navigator;
const fallback = (constraints: MediaStreamConstraints) => {

    // First get ahold of the legacy getUserMedia, if present
    var getUserMedia = n.webkitGetUserMedia || n.mozGetUserMedia;

    // Some browsers just don't implement it - return a rejected promise with an error
    // to keep a consistent interface
    if (!getUserMedia) {
        return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
    }

    // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
    return new Promise<MediaStream>((resolve, reject) => navigator.getUserMedia.call(navigator, constraints, resolve, reject));
};

// Older browsers might not implement mediaDevices at all, so we set an empty object first
if (n.mediaDevices === undefined) {
    n.mediaDevices = { getUserMedia: fallback };
}

// Some browsers partially implement mediaDevices. We can't just assign an object
// with getUserMedia as it would overwrite existing properties.
// Here, we will just add the getUserMedia property if it's missing.
if (n.mediaDevices.getUserMedia === undefined) {
    n.mediaDevices.getUserMedia = fallback;
}

export const getUserMedia = (constraints: MediaStreamConstraints): Promise<MediaStream> => {
    return (<Promise<MediaStream>>n.mediaDevices.getUserMedia(constraints));
};