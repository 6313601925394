import { isType } from 'typescript-fsa';
import { ChangeBattlesList, GetBattle, GetBattleList, CreateBattle, EditBattle } from '../actions/battles_actions';
import { ClearCaches } from '../actions/authentication_actions';
import { Logout } from '../actions/authentication_actions';
import { SearchAgent } from './agent_search_reducer';
import moment, { Moment } from 'moment';


export type BattleStatus = keyof typeof BattleStatuses;

export type BattleItemStatus = keyof typeof BattleItemStatuses;

export enum BattleListTypes {
	active = 'active',
	sent = 'sent',
	received = 'received',
	completed = 'completed',
	leaderboard = 'leaderboard',
};

export type BattleListType = keyof typeof BattleListTypes;
export enum BattleStatuses {
	//new
	requested = 'requested',
	expired = 'expired',

	//accepted
	upcoming = 'upcoming',
	ongoing = 'ongoing',

	//rejected
	rejected = 'rejected',

	//completed
	won = 'won',
	lost = 'lost'
};

export enum BattleItemStatuses {
	new = 'new',
	completed = 'completed',
	accepted = 'accepted',
	rejected = 'rejected'
};

export interface BattlesState {
	listView: BattleListType;
	battles: Battle[];
	battleSaveStatus?: BattleSaveStatus;
	loading: boolean;
}
export enum BattleSaveStatus {
	CompletedEdit,
	CompletedCreate,
	Pending,
	Failed
}

export interface BattleMessage {
	id: string;
	sendingUser: string;
	message: string;
	battleId: string;
	sent: string;
}

export interface Battle {
	id: string;
	title: string;
	sendingUser: SearchAgent
	receivingUser: SearchAgent;
	status: BattleItemStatus;
	battleStatus?: BattleStatus;
	sendingUserScore?: number;
	receivingUserScore?: number;
	resultDisplay?: any;
	startTime: string;
	endTime: string;
	metric: string;
	listType?: BattleListType;
	lineOfBusiness: string;
	lineOfBusinessId?: string;
	messages: BattleMessage[];
	createdOn?: Date;
	updatedOn?: Date;
	createdBy?: string;
	updatedBy?: string;
	winningUser?: string;
	isWinning?: boolean;

}

export interface BattleView {
	id: string;
	title: string;
	startTime: Moment;
	endTime: Moment;
	listType: BattleListType;
	winning: boolean;
	score: any;
	status: BattleStatus;
	opponent: {
		parsedName: string;
		avatar: JSX.Element;
	}
}

export function mapStatusToBattleStatus(battle: Battle, userId: string) {
	const isInFuture: boolean = moment(battle.startTime) > moment();

	switch (battle.status) {
		case BattleItemStatuses.new:
			if (isInFuture) {
				battle.battleStatus = BattleStatuses.requested;
			} else if (!isInFuture) {
				battle.battleStatus = BattleStatuses.expired;
			}
			break;
		case BattleItemStatuses.accepted:
			battle.battleStatus = isInFuture ?
				BattleStatuses.upcoming :
				BattleStatuses.ongoing;
			break;
		case BattleItemStatuses.completed:
			if (battle.winningUser == userId) {
				battle.battleStatus = BattleStatuses.won;
			}
			else {
				battle.battleStatus = BattleStatuses.lost;
			}
			break;
		case BattleItemStatuses.rejected:
			battle.battleStatus = BattleStatuses.rejected
	}
}

const initialState = {
	listView: BattleListTypes.active as BattleListType,
	battles: [],
	loading: false
};

export function battlesReducer(
	state: BattlesState = initialState,
	action: any
): BattlesState {
	if (isType(action, ChangeBattlesList)) {
		return {
			...state,
			battleSaveStatus: undefined,
			listView: action.payload.battlesList,
		};
	} else if (isType(action, Logout.started)) {
		return {
			...initialState,
		};
	} else if (isType(action, ClearCaches)) {
		return {
			...initialState,
		};
	} else if (isType(action, GetBattle.started)) {
		return {
			...state,
			loading: true,
			battles: [],
		};
	} else if (isType(action, GetBattle.failed)) {
		return {
			...state,
			loading: false,
			battles: [],
		};
	} else if (isType(action, GetBattle.done)) {
		return {
			...state,
			loading: false,
			battles: [action.payload.result],
		};
	} else if (isType(action, GetBattleList.started)) {
		return {
			...state,
			loading: true,
			battleSaveStatus: undefined,
			battles: [],
		};
	} else if (isType(action, GetBattleList.failed)) {
		return {
			...state,
			loading: false,
			battles: [],
		};
	} else if (isType(action, GetBattleList.done)) {
		return {
			...state,
			loading: false,
			battles: action.payload.result,
		};
	} else if (isType(action, CreateBattle.started) ||
		isType(action, EditBattle.started)) {
		return {
			...state,
			battleSaveStatus: BattleSaveStatus.Pending
		};
	} else if (isType(action, CreateBattle.failed) ||
		isType(action, EditBattle.started)) {
		return {
			...state,
			battleSaveStatus: BattleSaveStatus.Failed
		};
	} else if (isType(action, CreateBattle.done)) {
		return {
			...state,
			battleSaveStatus: BattleSaveStatus.CompletedCreate
		};
	} else if (isType(action, EditBattle.done)) {
		return {
			...state,
			battleSaveStatus: BattleSaveStatus.CompletedEdit
		};
	} else {
		return state;
	}
}
