import { ThemeImages } from '../utilities/branding';

export const BrandingAssets: { [key: string]: ThemeImages } = {
	"HealthMarkets": {
		"logo": require('./hmkts_logo.png'),
		"loginLogo": require('./LoginLogo.png'),
		"loginBackground": require('./LoginBackground.jpg'),
		"favicon": require('./favicon.png'),
		"bubblesPrimary": require('./bubbles_green.png'),
		"bubblesSecondary": require('./bubbles_blue.png'),
		"bubblesTertiary": require('./bubbles_orange.png'),
	},
	"Excelsior": {
		"logo": require('./excelsior_logo.png'),
		"loginLogo": require('./LoginLogo_Excelsior.png'),
		"loginBackground": '',
		"favicon": require('./favicon_Excelsior.png'),
		"bubblesPrimary": '',
		"bubblesSecondary": '',
		"bubblesTertiary": '',
	}
}