import React from 'react';
import { List, Paper } from '@material-ui/core';
import LoadingList from './loading_list';
import { EmptyMessage } from './empty_message';

interface Props {
    loading?: boolean;
    children?: React.ReactNode;
    emptyMessage?: string;
    style?: React.CSSProperties;
}

const CustomList: React.FC<Props> = (props: Props) => {
    return (
        <List style={props.style}>
            {
                props.loading
                    ? <LoadingList />
                    : props.children && React.Children.toArray(props.children).length > 0
                        ? (
                            <Paper elevation={0} square>
                                {props.children}
                            </Paper>
                        )
                        : <EmptyMessage text={props.emptyMessage} />
            }
        </List>
    );
};
CustomList.defaultProps = {
    loading: false,
    children: [],
    emptyMessage: 'There are no items to display.',
    style: { padding: 0, margin: 0 },
};

export default CustomList;
