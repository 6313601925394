import React from 'react';
import { useTheme, Button } from '@material-ui/core';
import {
    RisePage, RiseAppBar, Grid, RiseFormField, RiseCard,
    riseStrings, useNavigation
} from "@optum-uhone-hmkts/rise";
import { useAboutMeState } from '../../hooks';

export const AboutMePage: React.FC<{}> = () => {
    const theme = useTheme();
    const aboutMe = useAboutMeState();

    const nav = useNavigation({
        path: riseStrings._Route_AboutMe,
        onLoad:
            () => {
                aboutMe.getAboutMe()
            }
    });

    const handleSave = () => {
        aboutMe.save();
        nav.dispatchBack();
    }

    return (
        <RisePage
            path={riseStrings._Route_AboutMe}
        >

            <RiseAppBar
                position="static"
                loadingBar
                backAction
                confirmBack={aboutMe.isDirty ? {
                    titleText: "This form has unsaved data",
                    subtitleText: "Are you sure you want to go back without saving?"
                } :  undefined}
                forwardAction={
                    <Button
                        style={{ color: aboutMe.isDirty ? theme.palette.common.white : theme.palette.text.disabled }}
                        disabled={!aboutMe.isDirty}
                        onClick={handleSave}>{riseStrings.Save}
                    </Button>
                }
            />
            <RiseCard
                container
                gridSize='12|12'
                alignContent="center"
                titleText={riseStrings.EditAboutMeSlide}
            >

                <RiseFormField
                    label="Years of Insurance Experience"
                    gridSize="12|12"
                    onChange={aboutMe.createChangeHandler("aboutMeYears")}
                    value={aboutMe.currentForm.aboutMeYears}
                />

                <RiseFormField
                    label="Health:"
                    gridSize="12|12"
                    onChange={aboutMe.createChangeHandler("appointedHealth")}
                    value={aboutMe.currentForm.appointedHealth}
                />

                <RiseFormField
                    label="Life:"
                    gridSize="12|12"
                    onChange={aboutMe.createChangeHandler("appointedLife")}
                    value={aboutMe.currentForm.appointedLife}
                />

                <RiseFormField
                    label="Medicare:"
                    gridSize="12|12"
                    onChange={aboutMe.createChangeHandler("appointedMedicare")}
                    value={aboutMe.currentForm.appointedMedicare}
                />

                <RiseFormField
                    label="Others:"
                    gridSize="12|12"
                    onChange={aboutMe.createChangeHandler("appointedOthers")}
                    value={aboutMe.currentForm.appointedOthers}
                />
                <RiseFormField
                    label="Active in the following local organizations:"
                    gridSize="12|12"
                    onChange={aboutMe.createChangeHandler("aboutMeOrgs")}
                    value={aboutMe.currentForm.aboutMeOrgs}
                />
            </RiseCard >

        </RisePage >

    );
}
