import { Action as ReduxAction } from 'typescript-fsa';
import {
	InfiniteScrollMeta,
	StoreInfiniteScrollMeta,
	ResetInfiniteScroll,
} from '../actions/infinite_scroll_actions';
import { ClearCaches } from '../actions/authentication_actions';
import { Logout } from '../actions/authentication_actions';

export interface InfiniteScrollState {
	query: any;
	pageSize: number;
	pagesReceived: number;
	resultsReceived: number;
	loadMore: boolean;
}

const initialState: InfiniteScrollState = {
	query: {},
	pageSize: 100,
	pagesReceived: 0,
	resultsReceived: 0,
	loadMore: false,
};

export function infiniteScrollReducer(
	state: InfiniteScrollState = initialState,
	action: ReduxAction<InfiniteScrollMeta>
): InfiniteScrollState {
	switch (action.type) {
		case StoreInfiniteScrollMeta.type: {
			const resultsReceived = state.resultsReceived + action.payload.resultSize;

			const receivedFullPage =
				action.payload.resultSize == action.payload.pageSize;

			const lastPage = action.payload.resultSize < state.pageSize;

			const pagesReceived =
				action.payload.resultSize != 0
					? action.payload.pageRequested + 1
					: action.payload.pageRequested;
			return {
				...state,
				resultsReceived,
				loadMore: !lastPage,
				pagesReceived,
				query: action.payload.query,
			};
		}
		case ResetInfiniteScroll.type: {
			return {
				...state,
				query: {},
				pagesReceived: 0,
				resultsReceived: 0,
				loadMore: false,
			};
		}
		case Logout.type: {
			return {
				...initialState,
			};
		}
		case ClearCaches.type: {
			return {
				...initialState,
			};
		}
		default: {
			return state;
		}
	}
}
