
import { delay, createApiClient, normalize, schema, createSliceSelector, r_snackbar, riseStrings, r_device, ConfigSlice } from "@optum-uhone-hmkts/rise";
import { createAsyncThunk, nanoid } from "@reduxjs/toolkit";
import { FactFinderSlice } from "./fact_finder_slice";
import FileSaver from 'file-saver';

export const selectTemplate = (sliceState: FactFinderSlice.State, templateId: string) => {
    const stateTemplates = sliceState[riseStrings._TemplatePPT];
    const selectedTemplate = stateTemplates[templateId];

    if (!selectedTemplate) {
        return;

    } else {
        const { ...formValues } = selectedTemplate;
        return {
            ...formValues,
            slides: []
        } as FactFinderSlice.PowerPointTemplate;
    }
}

export type NewTemplateUpsertPayload = { templateId: string, baseTemplateId: string };

export const createPowerPointActions = (servicePath: string) => {
    const selectAuthToken = createSliceSelector(ConfigSlice, "session", "authToken");
    const selectConnectionStrings = createSliceSelector<ConfigSlice.ConnectionStrings>(ConfigSlice, "connectionStrings");

    // called agentId, but is actually user ID
    const getAgentId = (appState) => ConfigSlice.selectState(appState).session.userId;
    const getServiceEndpoint = (appState, route: string) => selectConnectionStrings(appState).servicesUrl + servicePath + route;
    const getApiEndpoint = (appState, route: string) => selectConnectionStrings(appState).servicesUrl + "api/" + route;

    const client = createApiClient();

    return {
        getTemplatesByAgentId: createAsyncThunk(
            "FactFinder/GetPowerPointTemplates",
            async (__, thunkApi) => {
                const appState = thunkApi.getState();
                let jwt = selectAuthToken(appState);
                let agentId = getAgentId(appState);
                let maxLoopCounter = 4;
                while ( (!agentId || !jwt) && maxLoopCounter > 0 ) {
                    await delay(1500);
                    const appState = thunkApi.getState();
                    jwt = selectAuthToken(appState);
                    agentId = getAgentId(appState);
                    maxLoopCounter--;
                }

                const response = await client.getRequest(
                    getServiceEndpoint(appState, "GetTemplatesByAgentId"),
                    {
                        authToken: jwt,
                        params: [
                            [riseStrings._AgentId, agentId],
                        ]
                    });

                if (response.ok) {
                    return await response.json();
                }
            }),

        createTemplate: createAsyncThunk(
            "FactFinder/NewPowerPointTemplate",
            async (masterTemplateId: string, thunkApi) => {
                const appState = thunkApi.getState();
                const configState = ConfigSlice.selectState(appState as any);

                let response = await client.getRequest<FactFinderSlice.PowerPointTemplate>(
                    getServiceEndpoint(appState, "GetBaseTemplateByEnterprise"),
                    {
                        authToken: selectAuthToken(appState),
                        params: [
                            [riseStrings._Enterprise, configState.enterpriseName.toLowerCase()],
                            ["baseType", masterTemplateId],
                        ]
                    });
                if (response.ok) {
                    const template: FactFinderSlice.PowerPointTemplate = await response.json();
                    template.agentid = getAgentId(appState);
                    template.id = nanoid();
                    template.masterTemplateId = masterTemplateId;
                    return template;
                }
            }),

        downloadPresentation: createAsyncThunk(
            "FactFinder/DownloadFactFinderPowerPoint",
            async (payload: { templateId: string, fileName: string }, thunkApi) => {
                const appState = thunkApi.getState();
                thunkApi.dispatch(r_snackbar.new(thunkApi.dispatch, "Started download", 2000))
                const response = await client.downloadPptRequest(
                    getServiceEndpoint(appState, "DownloadPresentation"),
                    {
                        authToken: selectAuthToken(appState),
                        params: [
                            ["presentationId", payload.templateId],
                            [riseStrings._AgentId, getAgentId(appState)]
                        ]
                    },

                );
                if (response.ok) {
                    const templateDl = await response.blob();
                    return FileSaver.saveAs(templateDl, payload.fileName);
                }
            }),

        deleteTemplate: createAsyncThunk(
            "FactFinder/DeletePowerPointTemplate",
            async (payload: FactFinderSlice.PowerPointTemplate, thunkApi) => {
                const appState = thunkApi.getState();

                const response = await client.postRequest(
                    getServiceEndpoint(appState, "UpsertTemplate"),
                    payload,
                    selectAuthToken(appState)
                )
                if (response.ok) {
                    const templateDeleted = await response.json() as FactFinderSlice.PowerPointTemplate;
                    thunkApi.dispatch(r_snackbar.new(thunkApi.dispatch, "Template Deleted", 2000));
                    return normalizeTemplate(templateDeleted);
                };
            }),

        saveTemplate: createAsyncThunk(
            "FactFinder/SavePowerPointTemplate",
            async (payload: FactFinderSlice.PowerPointTemplate, thunkApi) => {
                const appState = thunkApi.getState()
                const response = await client.postRequest(
                    getServiceEndpoint(appState, "UpsertTemplate"),
                    payload,
                    selectAuthToken(appState)).then(res => {
                        thunkApi.dispatch(r_snackbar.new(thunkApi.dispatch, "Template Saved"))
                        return res;
                    });
                if (response.ok) {
                    thunkApi.dispatch(r_snackbar.new(thunkApi.dispatch, "Template Saved", 2000));
                    return await response.json() as FactFinderSlice.PowerPointTemplate;
                }
            },
        ),

        getAboutMe: createAsyncThunk(
            "FactFinder/GetProfile",
            async (__, thunkApi) => {
                const appState = thunkApi.getState();
                const response = await client.getRequest(
                    getApiEndpoint(appState, "profile/" + getAgentId(appState)),
                    {
                        authToken: selectAuthToken(appState),
                    }
                );
                if (response.ok) {
                    thunkApi.dispatch(r_snackbar.new(thunkApi.dispatch, "Template Saved", 2000));
                    return await response.json() as FactFinderSlice.AboutMeSlide;
                }
            },
        ),

        saveAboutMe: createAsyncThunk(
            "FactFinder/SaveProfile",
            async (payload: FactFinderSlice.AboutMeSlide, thunkApi) => {
                const appState = thunkApi.getState();
                const requestPayload = { ...payload, username: getAgentId(appState) }
                const response = await client.postRequest<FactFinderSlice.AboutMeSlide>(
                    getApiEndpoint(appState, "profile"),
                    requestPayload,
                    selectAuthToken(appState));

                if (response.ok) {
                    const profile = await response.json() as FactFinderSlice.AboutMeSlide;
                    thunkApi.dispatch(r_snackbar.new(thunkApi.dispatch, "About Me Saved"))
                    return profile;
                }
            },
        ),
    }
}
const slides = new schema.Entity(riseStrings._SlidePPT);
const template = new schema.Entity(riseStrings._TemplatePPT, {
    [riseStrings._SlidePPT]: [slides],
})
const templates = new schema.Array(template);
template.define({ templates })

const entities = new schema.Entity(riseStrings._TemplatePPT, { templates });

export const normalizeTemplate = (response) => normalize(response, entities)
