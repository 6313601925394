import {actionCreatorFactory} from 'typescript-fsa';
import { SearchResult } from '../reducers/advanced_search_reducer';
import { LeadRoutePages } from '../reducers/lead_routes_reducer';

const actionCreator = actionCreatorFactory('LeadRoutes');

// STORE LEAD ROUTES INDEX
interface StoreLeadRoutesIndexPayload {
    index: number;
    origin?: LeadRoutePages;
};
export const StoreLeadRoutesIndex = actionCreator<StoreLeadRoutesIndexPayload>('STORE_LEAD_ROUTES_INDEX');

export const UpdateLeadRoutesFromAdvancedSearch = actionCreator.async<{},SearchResult[]>('UPDATE_LEAD_ROUTES_FROM_ADVANCED_SEARCH');