import { LookupDictionary, DefaultDictionary } from '../utilities/lookup';
import { Action as ReduxAction } from 'redux';
import { isType } from 'typescript-fsa';
import { StoreLookupData } from '../actions/lookup_actions';
import { Logout } from '../actions/authentication_actions';

export type LookupTypes = LookupDictionary;

const initialState: LookupTypes = DefaultDictionary;

export function lookupReducer(
	state: LookupTypes = initialState,
	action: ReduxAction
): LookupTypes {
	if (isType(action, StoreLookupData)) {
		return state.updateWithInboundLookups(action.payload);
	} else if (isType(action, Logout.started)) {
		return initialState;
	} else {
		return state;
	}
}
