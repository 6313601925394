export const makeTrimToMaxLength = (maxLength: number) => (value: string) =>
    value.substr(0, maxLength);
    
export const stringToNDigitIntegerString = (value: string | undefined, numberOfDigits: number, maxValue: number) => {
    let nDigitString = '';

    if (value) {
        let numberValue = Number(value);
        if (Number.isInteger(numberValue)) {
            numberValue = numberValue <= maxValue ? numberValue : maxValue;	
            nDigitString = numberValue.toString();
        }
    } 

    while (nDigitString.length < numberOfDigits) {
        nDigitString = '0' + nDigitString;
    }

    return nDigitString;
};

export const stringToNumber = (value: string) => {
	const numericValue = normalizeMoneyNumeric(value);
	return Number(numericValue);
};

const makeRemoveCharactersMatchingRegex = (reg: RegExp) => (value?: string) => {
	if (!value) {
        return '';
    }
    const stringWithoutCharactersMatchingRegex = value.replace(reg, '');
    return stringWithoutCharactersMatchingRegex;
};

export const normalizeNumeric = makeRemoveCharactersMatchingRegex(/[^0-9]/g);
export const normalizeMoneyNumeric = makeRemoveCharactersMatchingRegex(/[^0-9.]/g);

// This is a very broad regex. Could be refined once we have a better idea what carrier statuses look like
export const normalizeCarrierStatus = makeRemoveCharactersMatchingRegex(/[^0-9:;<=>?@A-Za-z \-\[\\\]^`_]/g);
export const normalizeNames = makeRemoveCharactersMatchingRegex(/[^a-zA-Z'\- ]/g);