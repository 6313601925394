import { AppState } from '../reducers/index';
import { createSelector } from 'reselect';
import { RouteComponentProps } from 'react-router-dom';
import { EMPTY_HOUSEHOLD_META_DATA, loaded } from '../utilities/empty_entities';

/**
 * Household selectors
 */
const getHouseholds = (state: AppState, props: any) =>
	state.householdMetaData.loadedHouseholdMetaData || [];

const getHouseholdId = <Props extends RouteComponentProps<any>>(
	state: any,
	props: Props
): string => props.match.params.householdID;

export const getPageHouseholdMetaData = createSelector(
	[getHouseholds, getHouseholdId],
	(households, householdId) => {
		const householdIndex = households.findIndex(
			loadedHousehold =>
				loadedHousehold.data.id == householdId ||
				loadedHousehold.householdId == householdId
		);
		return householdIndex > -1
			? { ...households[householdIndex] }
			: loaded(EMPTY_HOUSEHOLD_META_DATA, { householdId });
	}
);
