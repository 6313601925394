import React from 'react';
import { FormControl, Grid } from '@material-ui/core';
import { themePalette } from '../../utilities/branding';
import { AgeInput } from './age_input';
import { enforceStylesType } from '../../utilities/styles_util';
import { Strings } from '../../assets/common/strings';

interface Props {
	name: string;
}

export const AgeRangePicker: React.SFC<Props> = (props: Props) => {
	const { name } = props;
	const styles = getStyles();

	return (
		<FormControl fullWidth style={styles.FormControl}>

			<span style={styles.title}>{Strings.ConsumerSearchFilters.Age}</span>

			<Grid container spacing={2}>

				<Grid item xs={5} style={styles.ageGrid}>
					<AgeInput name={`${name}Min`} label={Strings.ConsumerSearchFilters.Min} />
				</Grid>

				<Grid item xs={2} style={styles.toGrid}>
					<span style={styles.subTextColor}>{Strings.ConsumerSearchFilters.To}</span>
				</Grid>

				<Grid item xs={5} style={styles.ageGrid}>
					<AgeInput name={`${name}Max`} label={Strings.ConsumerSearchFilters.Max} />
				</Grid>

			</Grid>

		</FormControl>
	);
};

const getStyles = () => enforceStylesType({
	FormControl: {
		paddingBottom: 0, paddingTop: 2
	},
	ageGrid: {
		display: 'inline-flex'
	},
	subTextColor: {
		color: themePalette.sub_text
	},
	toGrid: {
		marginTop: 'auto', textAlign: 'center'
	},
	title: {
		fontSize: '16px',
		color: themePalette.sub_text,
	},
});