import React from 'react';
import { Switch, FormControlLabel, FormControl, FormHelperText } from '@material-ui/core';
import { Grid } from '@optum-uhone-hmkts/rise';
import { ReduxFormsFieldProps, useReduxFormFieldProps } from './use_redux_form_field_props';


export const FormSwitch: React.FC<ReduxFormsFieldProps> = (props) => {


    const { gridSize, options = {}, onFocus, onBlur, label, value, helperText, error, input } = useReduxFormFieldProps(props);


    return (
        <Grid
            gridSize={gridSize}
        >
            <FormControl
                component="fieldset"
                error={error}
            >
                <FormControlLabel
                    label={label}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    control={
                        <Switch
                            checked={value}
                            onChange={input.onChange}

                        />}
                />
                <FormHelperText >{helperText}</FormHelperText>
            </FormControl>
        </Grid>

    )
}
