import { Action as ReduxAction } from 'redux';
import { isType } from 'typescript-fsa';
import _ from 'lodash';
import { isNullOrUndefined } from 'util';
import {
	getTemplateFile,
	getImportHistory,
	uploadImportTable,
	getProcessedImportFile,
} from '../actions/file_actions';
import { ClearCaches } from '../actions/authentication_actions';
import { Logout } from '../actions/authentication_actions';

export interface ImportHistory {
	userId: string;
	fileName: string;
	createdOn: Date;
	numberOfRows: number;
	numberOfErrors: number;
	resultsRead: number;
	status: string;
	type: string;
}

export interface FileState {
	imports: ImportHistory[];
	isImportHistoryLoading: boolean;
	isAnImportTemplateDownloading: boolean;
	isAnImportFileUploading: boolean;
}

export const initialState: FileState = {
	imports: [],
	isImportHistoryLoading: false,
	isAnImportTemplateDownloading: false,
	isAnImportFileUploading: false,
};

export function fileReducer(
	state: FileState = initialState,
	action: ReduxAction
): FileState {
	if (
		isType(action, getTemplateFile.started) ||
		isType(action, getProcessedImportFile.started)
	) {
		return {
			...state,
			isAnImportTemplateDownloading: true,
		};
	} else if (
		isType(action, getTemplateFile.done) ||
		isType(action, getTemplateFile.failed) ||
		isType(action, getProcessedImportFile.done) ||
		isType(action, getProcessedImportFile.failed)
	) {
		return {
			...state,
			isAnImportTemplateDownloading: false,
		};
	} else if (isType(action, getImportHistory.started)) {
		return {
			...state,
			isImportHistoryLoading: true,
		};
	} else if (isType(action, getImportHistory.done)) {
		return {
			...state,
			imports: action.payload.result,
			isImportHistoryLoading: false,
		};
	} else if (isType(action, getImportHistory.failed)) {
		return {
			...state,
			isImportHistoryLoading: false,
		};
	} else if (isType(action, uploadImportTable.started)) {
		return {
			...state,
			isAnImportFileUploading: true,
		};
	} else if (isType(action, uploadImportTable.done)) {
		let imports: ImportHistory[] = state.imports.slice();
		let matchedImportIndex: number = getMatchingImportHistoryIndex(
			imports,
			action.payload.result.fileName
		);

		if (matchedImportIndex === -1) {
			imports.push(action.payload.result);
		} else {
			imports[matchedImportIndex] = action.payload.result;
		}

		return {
			...state,
			imports: imports,
			isAnImportFileUploading: false,
		};
	} else if (isType(action, uploadImportTable.failed)) {
		return {
			...state,
			isAnImportFileUploading: false,
		};
	} else if (isType(action, Logout.started)) {
		return {
			...initialState,
		};
	} else if (isType(action, ClearCaches)) {
		return {
			...initialState,
		};
	} else {
		return state;
	}
}

export function getMatchingImportHistoryIndex(
	importHistoryToLookThrough: ImportHistory[],
	fileName: string
) {
	return _.findIndex(
		importHistoryToLookThrough,
		(importHistory: ImportHistory) => {
			return (
				!isNullOrUndefined(importHistory.fileName) &&
				importHistory.fileName === fileName
			);
		}
	);
}
