import React from 'react';
import { Grid } from '@material-ui/core';
import { ReadOnlyTextField } from '../utility/read_only_text_field';
import { isDefaultDate } from '../../utilities/date_util';
import { wrapInCollapsibleCard } from '../higher_order_component/wrap_in_card';

interface Props {
	policyNumber: string;
	issuedDate: string;
	effectiveDate: string;
	faceAmount: string;
	paymentMode: string;
	insuredType: string;
	annualPremium: string;
	paidThroughDate: string;
	benefitAmount: string;
}
const PolicyDetailInformation: React.FC<Props> = (props) => {
	const effectiveDate = isDefaultDate(props.effectiveDate) ? "None" : props.effectiveDate;
	const issuedDate = isDefaultDate(props.issuedDate) ? "None" : props.issuedDate;

	return (
		<Grid container>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Policy Number"
						value={props.policyNumber}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField label="Issued Date" value={issuedDate} />
				</Grid>
			</Grid>
			<Grid container>
			<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Effective Date"
						value={effectiveDate}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Paid Through Date"
						value={props.paidThroughDate}
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Payment Mode"
						value={props.paymentMode}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField label="Face Amount" value={props.faceAmount} />
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Annual Premium"
						value={props.annualPremium}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Insured Type"
						value={props.insuredType}
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Benefit Amount"
						value={props.benefitAmount}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export const PolicyDetailInformationCard = wrapInCollapsibleCard(PolicyDetailInformation);
