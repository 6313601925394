import React from 'react';
import { defaultSvgProps, SvgProps } from '..';

const ImportantSvg: React.FC<SvgProps> = (props) => (
    <svg viewBox="0 0 35 35" {...props}>
        <path
            d="M31.19 29.53H4.7a.82.82 0 0 1-.7-.4.83.83 0 0 1 0-.81L17.25 5.37a.84.84 0 0 1 1.39 0l13.25 23a.79.79 0 0 1 0 .81.82.82 0 0 1-.7.35zm-25.1-1.61H29.8L18 7.39z"
        />
        <path
            d="M18 22.28a.8.8 0 0 1-.81-.8v-8.06a.81.81 0 0 1 1.61 0v8.06a.8.8 0 0 1-.8.8z"
        />
        <circle cx={17.95} cy={24.7} r={0.81} />
    </svg>
);
ImportantSvg.defaultProps = defaultSvgProps;

export default ImportantSvg;
