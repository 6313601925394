import React from 'react';
import { WideButton } from '../utility/wide_button';
import { Activity } from '../../reducers/activity_reducer';
import moment from 'moment';
import { Strings } from '../../assets/common/strings';

interface Props {
	onClick: () => void;
	nextActivity?: Activity;
}
export const NextActivityCard: React.FC<Props> = (props: Props) => {
	const text = getNextActivityText(props.nextActivity);

	return (
		<WideButton
			primary={'Next Activity'}
			secondary={text}
			onClick={props.onClick}
			disabled={!props.nextActivity}
		/>
	);
};

const getNextActivityText = (nextActivity?: Activity) => {
	const activityScheduled = nextActivity &&
		nextActivity.type != Strings.Activity.Task &&
		nextActivity.contact;
	const text = activityScheduled
		? getNextActivityTextForDefinedActivity(nextActivity!)
		: 'None scheduled';

	return text;
}

const getNextActivityTextForDefinedActivity = (nextActivity: Activity) => {
	let text: string;
	switch (nextActivity.type) {
		case Strings.Activity.PhoneCall:
			text = `Call ${nextActivity.contact.firstName} `;
			break;
		case Strings.Activity.Appointment:
			text = 'Appointment ';
			break;
		default:
			text = '';
			break;
	}

	text += moment(nextActivity.time).calendar();
	return text;
}