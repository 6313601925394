import { Action as ReduxAction } from 'redux';
import {
	StoreProductFilters,
	SetHasMore,
	SearchProducts,	
	ToggleFilterDrawer,
} from '../actions/product_actions';
import { isType } from 'typescript-fsa';
import moment from 'moment';
import { ClearCaches } from '../actions/authentication_actions';
import { Logout } from '../actions/authentication_actions';

export interface ProductFilters {
	downlineAgentCode: string;
	contactFilter: string;
	productCategoryFilter: string;
	productNumberFilter: string;
	carrierFilter: string;
	lineOfBusinessFilter: string;
	productDateFilter: string;
	productStatusFilter: string;
	productTypeFilter: string;
	productMatchFilter: string;
	productFilter: string;
	fromDate: Date;
	toDate: Date;
	sortBy: string;
	sortCategory: string;
	sortDirection: string;
	pageNumber?: number;
	pageSize?: number;
	hasMore?: boolean;
	isFilterOpen?: boolean;
	toggleFilterDrawer? : (isOpen: boolean) => void;
	isLoading?: boolean;
}

export const initialState: ProductFilters = {
	downlineAgentCode: '',
	contactFilter: '',
	productCategoryFilter: 'all',
	productNumberFilter: '',
	carrierFilter: 'All',
	lineOfBusinessFilter: 'All',
	productDateFilter: 'All',
	productStatusFilter: 'All',
	productTypeFilter: 'All',
	productMatchFilter: 'All',
	productFilter: '',
	fromDate: moment()
		.startOf('day')
		.toDate(),
	toDate: moment()
		.endOf('day')
		.toDate(),
	sortBy: 'Oldest to Newest by Modified Date',
	sortCategory: '',
	sortDirection: '',
	pageNumber: 0,
	pageSize: 20,
	hasMore: false,
	isLoading: true,
	isFilterOpen: false
};

export function productFilterReducer(
	state: ProductFilters = initialState,
	action: ReduxAction
): ProductFilters {
	if (isType(action, StoreProductFilters)) {
		return {
			...state,
			...action.payload,
		};
	} else if (isType(action, SearchProducts.started)) {
		return {
			...state,
			isLoading: true,
		};
	} else if (isType(action, SearchProducts.done)) {
		return {
			...state,
			isLoading: false,
		};
	} else if (isType(action, SearchProducts.failed)) {
		return {
			...state,
			isLoading: false,
		};
	} else if (isType(action, SetHasMore)) {
		return {
			...state,
			hasMore: action.payload,
		};
	} else if (isType(action, Logout.started)) {
		return {
			...initialState,
		};
	} else if (isType(action, ClearCaches)) {
		return {
			...initialState,
		};
	} else if (isType(action, ToggleFilterDrawer)){
		return {
			...state,
			isFilterOpen: action.payload,
		};
	} else {
		return state;
	}
}
