import { StartCallSequenceAndLoadActivities, StartCallParams } from './../../actions/sequence/call_sequence_actions';
import { Action } from 'typescript-fsa';
import { getEmployerClient, FullEmployer, hydrateEmployerLeadContacts } from '../employer_sagas';
import { getHouseholdClient, FullHousehold, hydrateHouseholdLeadContacts } from '../household_sagas';
import { Activity } from '../../reducers/activity_reducer';
import { put, all, call } from 'redux-saga/effects';
import { GetEmployer } from '../../actions/employer_actions';
import { GetHousehold } from '../../actions/household_actions';
import { Strings } from '../../assets/common/strings';
import { takeLatestForActionType } from '../../utilities/saga_util';
import { Contact } from '../../reducers/ContactReducer';

function* getPhoneCallActivityData(action: Action<StartCallParams>) {
	try {
		const response = action.payload.employerId
			? yield call(getEmployerClient, action.payload.employerId)
			: yield call(getHouseholdClient, action.payload.householdId);
		let activities: Activity[] = [];
		let contacts: Contact[] = [];
		if (response.ok) {
			if (action.payload.employerId) {
				const fullEmployer: FullEmployer = yield response.json();
				hydrateEmployerLeadContacts(fullEmployer);
				activities = fullEmployer.activities;
				contacts = fullEmployer.contacts;
				yield put(
					GetEmployer.done({
						params: action.payload.employerId,
						result: fullEmployer,
					})
				);
			} else {
				const fullHousehold: FullHousehold = yield response.json();
				hydrateHouseholdLeadContacts(fullHousehold);
				activities = fullHousehold.activities;
				contacts = fullHousehold.contacts;
				yield put(
					GetHousehold.done({
						params: action.payload.householdId,
						result: fullHousehold,
					})
				);
			}

			const hasActivities = activities.some(
				activity =>
					activity.type == Strings.Activity.PhoneCall &&
					activity.status == Strings.ActivityStatus.Open
			);
			yield put(
				StartCallSequenceAndLoadActivities.done({
					params: action.payload,
					result: { contacts, hasActivities },
				})
			);
		} else {
			yield put(
				StartCallSequenceAndLoadActivities.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
		}
	} catch (error) {
		yield put(
			StartCallSequenceAndLoadActivities.failed({ params: action.payload, error })
		);
	}
}

export function* callSequenceSagas() {
	yield all([
		takeLatestForActionType(
			StartCallSequenceAndLoadActivities.started,
			getPhoneCallActivityData
		),
	])
}