import React from 'react';
import { defaultSvgProps, SvgProps } from '..';

const LicenseAppointmentSvg: React.FC<SvgProps> = (props) => (
    <svg viewBox="0 -960 960 960" {...props}>
        <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h168q13-36 43.5-58t68.5-22q38 0 68.5 22t43.5 58h168q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm80-80h280v-80H280v80Zm0-160h400v-80H280v80Zm0-160h400v-80H280v80Zm200-190q13 0 21.5-8.5T510-820q0-13-8.5-21.5T480-850q-13 0-21.5 8.5T450-820q0 13 8.5 21.5T480-790ZM200-200v-560 560Z"
        />
    </svg>
);
LicenseAppointmentSvg.defaultProps = defaultSvgProps;

export default LicenseAppointmentSvg;
