// ************************************************** //
// ************** CLIENTCONNECT ********************* //
// ************************************************** //

import ContactSvg from '../../assets/home/ClientConnectUsage_contact.svg';
import RegistrationSvg from '../../assets/home/ClientConnectUsage_registration.svg';
import AppointmentSvg from '../../assets/home/ClientConnectUsage_appointment.svg';
import ReferralSvg from '../../assets/home/ClientConnectUsage_referral.svg';

export interface ClientConnectActivity {
    ActivityType: ClientConnectActivityType;
    Description: string;
    Name: string;
    Phone: string;
    Time: Date;
}
export enum ClientConnectActivityType {
	ContactAttempted = 'Contact Attempted',
	Referral = 'Referral',
	Registration = 'Registration',
	AppointmentRequested = 'Appointment / Quote Requested', // Values of "Appointment" and "Quote" combined
	// QuoteRequested = 'Quote Requested', // We don't need 'QuoteRequested' because the activity object will always get saved with ActivityType.AppointmentRequested on CC api server
};
export const ClientConnectActivityTypesList = ['All Activities']
    .concat(
        Object.keys(ClientConnectActivityType).map(key => ClientConnectActivityType[key])
    );
export const getClientConnectItemAvatar = (activityType: ClientConnectActivityType) => ({
    [ClientConnectActivityType.ContactAttempted]: ContactSvg,
    [ClientConnectActivityType.Registration]: RegistrationSvg,
    [ClientConnectActivityType.AppointmentRequested]: AppointmentSvg,
    // [ClientConnectActivityType.QuoteRequested]: require('../../assets/home/ClientConnectUsage_quote.svg'),
    [ClientConnectActivityType.Referral]: ReferralSvg,
}[activityType]);