import React from 'react';
import { defaultSvgProps, SvgProps } from '..';

const CommissionStatementSvg: React.FC<SvgProps> = (props) => (
    <svg viewBox="0 0 66 82.5" {...props}>
    <g>
        <path
            d="M62.34,47.899c-3.894-0.987-10.152,1.61-12.852,2.873c-0.437-2.357-4.634-3.696-6.916-4.151   c-4.254-0.739-11.871-5.082-11.881-5.082c-3.763-2.788-9.106-1.014-12.468,0.625l-0.146-0.859   c-0.069-0.408-0.452-0.682-0.865-0.614L2.225,43.235c-0.41,0.069-0.683,0.458-0.614,0.865l3.215,18.94   c0.061,0.36,0.374,0.625,0.739,0.625c0.042,0,0.084-0.004,0.125-0.011L20.68,61.11c0.41-0.069,0.684-0.459,0.614-0.865   l-0.281-1.658c6.489,1.005,15.274,4.005,15.347,4.03c5.026,1.886,10.453,1.082,15.165-1.339c3.972-2.059,9.632-4.133,12.373-6.937   C65.912,52.283,65.031,48.572,62.34,47.899z M6.18,62.05L3.216,44.588l13.51-2.293l2.964,17.462L6.18,62.05z M62.825,53.293   c-2.506,2.563-8.197,4.688-11.988,6.652c-2.731,1.404-8.174,3.436-13.969,1.26c-0.393-0.135-9.459-3.23-16.119-4.172l-2.262-13.326   c2.59-1.309,7.948-3.458,11.386-0.915c0.322,0.184,7.927,4.522,12.425,5.303c1.943,0.391,6.386,1.801,5.608,3.547   c-2.089,2.09-5.307,2.799-8.726,0.817c-2.614-1.516-5.773-1.96-5.906-1.979c-0.415-0.06-0.788,0.231-0.844,0.641   c-0.056,0.41,0.23,0.789,0.641,0.845c0.03,0.004,3.005,0.426,5.358,1.79c4.06,2.354,8.023,1.469,10.549-1.065   c0.846-0.432,8.797-4.402,12.998-3.338C63.514,49.739,64.05,52.041,62.825,53.293z" />
        <path
            d="M12.503,44.126c-1.062,0-1.926,0.864-1.926,1.926c0,1.062,0.864,1.926,1.926,1.926s1.926-0.864,1.926-1.926   C14.429,44.99,13.565,44.126,12.503,44.126z M12.503,46.478c-0.235,0-0.426-0.191-0.426-0.426c0-0.235,0.191-0.426,0.426-0.426   c0.235,0,0.426,0.191,0.426,0.426C12.929,46.287,12.738,46.478,12.503,46.478z" />
        <path
            d="M7.542,45.038c-1.062,0-1.926,0.864-1.926,1.926c0,1.062,0.864,1.926,1.926,1.926c1.062,0,1.926-0.864,1.926-1.926   C9.468,45.902,8.604,45.038,7.542,45.038z M7.542,47.39c-0.235,0-0.426-0.192-0.426-0.426c0-0.235,0.191-0.426,0.426-0.426   c0.235,0,0.426,0.191,0.426,0.426C7.968,47.199,7.777,47.39,7.542,47.39z" />
        <path
            d="M11.449,24.488c1.464,0.005,2.66,1.201,2.665,2.665c0.002,0.413,0.337,0.747,0.75,0.747h41.276   c0.413,0,0.749-0.334,0.75-0.747c0.005-1.456,1.181-2.644,2.63-2.667c0.437,0.032,0.804-0.316,0.805-0.748V9.961   c0-0.431-0.359-0.776-0.799-0.748c-1.444-0.02-2.616-1.189-2.638-2.632c0.002-0.019,0.002-0.037,0.002-0.055   c0-0.414-0.336-0.75-0.75-0.75H14.865c-0.414,0-0.75,0.336-0.75,0.75c0,0.016,0,0.032,0.002,0.049   c-0.02,1.452-1.208,2.631-2.667,2.637c-0.413,0.002-0.747,0.337-0.747,0.75v13.777C10.702,24.151,11.036,24.487,11.449,24.488z    M12.202,10.642c1.7-0.315,3.043-1.665,3.349-3.366h39.903c0.307,1.712,1.66,3.065,3.372,3.372v12.404   c-1.701,0.306-3.05,1.649-3.365,3.349H15.544c-0.314-1.688-1.653-3.027-3.342-3.342V10.642z" />
        <path
            d="M18.32,21.014c2.309,0,4.188-1.878,4.188-4.187c0-2.309-1.878-4.188-4.188-4.188s-4.188,1.878-4.188,4.188   C14.133,19.136,16.011,21.014,18.32,21.014z M18.32,14.14c1.482,0,2.688,1.205,2.688,2.688c0,1.481-1.206,2.687-2.688,2.687   c-1.482,0-2.688-1.206-2.688-2.687C15.633,15.345,16.838,14.14,18.32,14.14z" />
        <path
            d="M35.48,24.453c4.224,0,7.661-3.421,7.661-7.626c0-4.224-3.437-7.661-7.661-7.661c-4.224,0-7.661,3.436-7.661,7.661   C27.819,21.032,31.256,24.453,35.48,24.453z M35.48,10.666c3.397,0,6.161,2.764,6.161,6.161c0,3.378-2.764,6.126-6.161,6.126   c-3.397,0-6.161-2.748-6.161-6.126C29.319,13.43,32.083,10.666,35.48,10.666z" />
        <path
            d="M52.694,21.014c2.309,0,4.188-1.878,4.188-4.187c0-2.309-1.878-4.188-4.188-4.188c-2.309,0-4.188,1.878-4.188,4.188   C48.507,19.136,50.385,21.014,52.694,21.014z M52.694,14.14c1.482,0,2.688,1.205,2.688,2.688c0,1.481-1.206,2.687-2.688,2.687   c-1.482,0-2.688-1.206-2.688-2.687C50.007,15.345,51.212,14.14,52.694,14.14z" />
        <path
            d="M33.058,18.644L32.172,19.7c0.882,0.769,1.877,1.21,2.985,1.323v0.886h0.777v-0.874c0.825-0.041,1.488-0.283,1.99-0.728   c0.501-0.445,0.752-1.021,0.752-1.729c0-0.708-0.218-1.248-0.655-1.62c-0.437-0.372-1.121-0.667-2.051-0.886h-0.036V13.79   c0.623,0.089,1.201,0.324,1.735,0.704l0.789-1.117c-0.793-0.542-1.635-0.845-2.524-0.91v-0.619h-0.777v0.606   c-0.785,0.041-1.422,0.284-1.912,0.729c-0.489,0.445-0.734,1.018-0.734,1.717c0,0.7,0.213,1.227,0.637,1.583   c0.425,0.356,1.094,0.644,2.009,0.862V19.7C34.437,19.587,33.738,19.235,33.058,18.644z M35.935,17.552   c0.485,0.146,0.817,0.301,0.995,0.467c0.178,0.166,0.267,0.391,0.267,0.674s-0.114,0.518-0.34,0.704   c-0.227,0.186-0.534,0.299-0.922,0.339V17.552z M34.235,15.398c-0.162-0.158-0.243-0.372-0.243-0.644   c0-0.271,0.103-0.498,0.31-0.679c0.206-0.182,0.491-0.29,0.855-0.322v2.1C34.705,15.707,34.397,15.556,34.235,15.398z" />
        <path
            d="M55.301,43.145c0.349,0,0.651-0.242,0.73-0.579l2.624-11.228h4.358c0.414,0,0.75-0.336,0.75-0.75V3.089   c0-0.414-0.336-0.75-0.75-0.75H8.03c-0.007,0-0.013-0.004-0.019-0.004c-0.357,0-0.642,0.255-0.717,0.589   C7.29,2.934,7.283,2.943,7.28,2.954l-6.26,26.74c-0.094,0.404,0.156,0.807,0.559,0.901l53.55,12.53   C55.186,43.139,55.243,43.145,55.301,43.145z M62.263,29.838c-48.681,0,5.574,0-42.317,0c-0.009,0-0.017-0.003-0.026-0.003H8.765   c0-8.4,0.006-10.349,0.006-25.996h53.493V29.838z M53.584,31.338L52.938,34.1c-1.744-0.093-3.354,0.929-4.039,2.5l-22.472-5.262   H53.584z M7.261,30.384l-4.608-1.079L7.261,9.621c0,10.208,0.304,7.21-1.331,14.192c-0.094,0.403,0.156,0.806,0.559,0.901   c0.269,0.063,0.527,0.169,0.772,0.315V30.384z M11.338,31.338h8.509l29.373,6.877c0.403,0.092,0.805-0.154,0.9-0.556   c0.332-1.39,1.726-2.294,3.126-2.013c0.422,0.16,0.891-0.09,0.995-0.531l0.884-3.778h1.99l-2.374,10.156L11.338,31.338z" />
    </g>
</svg>
);
CommissionStatementSvg.defaultProps = defaultSvgProps;

export default CommissionStatementSvg;
