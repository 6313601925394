import { Action } from 'typescript-fsa';
import { call, put, all, takeEvery } from 'redux-saga/effects';
import {
	ClearNewClientConnectActivity,
	FetchNewClientConnectActivity,
	CacheNewClientConnectActivity,
	ReturnClientConnectSettings,
	FetchClientConnectSettings,
	PostClientConnectSettings,
	FetchClientConnectURL,
	CacheClientConnectURL,
	getClientConnectInfo,
	openClientConnectConfigureDialog,
	getClientConnectUsageActivities,
} from '../actions/client_connect_actions';
import { submitLog } from '../utilities/logging_util';
import { Strings } from '../assets/common/strings';
import { AppState } from '../reducers';
import { getAppState } from '..';
import { Contact } from '../reducers/ClientConnectReducer';
import { takeLatestForActionType } from '../utilities/saga_util';
import { jwt_auth } from '../utilities/auth';
import { P } from '../utilities/auth/permissions';
import { navigateTo } from '../actions/navigation_actions';
import { navRoutes } from '../components/nav/Routes';
import { ClientConnectActivity } from '../utilities/shared';
import { ActivityDurations, DaysOfTheWeek } from '../utilities/date_util';
import { themeMisc } from '../utilities/branding';
import { local_storage } from '../utilities/storage';
import { AppConfig } from '../types/config';


export enum ClientConnectAppointmentTypes {
	Phone = 'phone',
	InPerson = 'inPerson'
};
export interface ClientConnectSettingsDto {
	IsActivated: boolean;
	Contacts?: Array<Contact>;
	AppointmentSettings?: ClientConnectAppointmentSettings;
}
export interface ClientConnectSettings {
	isActivated: boolean;
	contacts?: Array<Contact>;
	email?: string;
	appointmentSettings: ClientConnectAppointmentSettings;
}
export interface ClientConnectAppointmentSettings {
	types: ClientConnectAppointmentTypes[];
	days: DaysOfTheWeek[];
	time: [Date?, Date?];
	durations: ActivityDurations[]
}

const morning = new Date();
morning.setHours(8, 0, 0, 0);
const evening = new Date();
evening.setHours(17, 0, 0, 0);
export const InitialCCAppointmentSettings: ClientConnectAppointmentSettings = {
	types: [],
	days: [
		DaysOfTheWeek.Monday,
		DaysOfTheWeek.Tuesday,
		DaysOfTheWeek.Wednesday,
		DaysOfTheWeek.Thursday,
		DaysOfTheWeek.Friday,
	],
	time: [morning, evening],
	durations: [ActivityDurations.ThirtyMinutes],
};


// TODO: update these sagas, this is all whack.  And put specific api calls into service worker cache
function fetchWithJwtHeader(input: RequestInfo, init?: RequestInit) {
	const jwt = local_storage.getJwt();

	return fetch(input, {
		...init,
		headers: {
			...(init ? init.headers : {}),
			authorization: jwt || '',
		},
	})
}

function clearClientConnectActivity(): Promise<any> {
	return fetchWithJwtHeader(AppConfig.client_connect_link + '/api/activity/new', {
		method: 'DELETE',
	});
}

function fetchNewClientConnectActivityCount(): Promise<any> {
	return fetchWithJwtHeader(AppConfig.client_connect_link + '/api/activity/new/count', {
		method: 'GET',
	});
}
function fetchClientConnectSettings(): Promise<any> {
	return fetchWithJwtHeader(AppConfig.client_connect_link + '/api/settings', {
		method: 'GET',
	});
}

function postClientConnectSettings(settingsDto: ClientConnectSettingsDto): void {
	if (settingsDto.Contacts === undefined || !jwt_auth.hasPermission(P.ClientConnect)) {
		return;
	}

	settingsDto.Contacts.forEach((contact: Contact) => {
		delete contact.errorMessages;
	});
	fetchWithJwtHeader(AppConfig.client_connect_link + '/api/settings', {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
		},
		body: JSON.stringify({
			isActivated: settingsDto.IsActivated,
			contacts: settingsDto.Contacts,
			appointmentSettings: themeMisc.clientConnectAppointments
				? settingsDto.AppointmentSettings
				: undefined,
		}),
	}).catch(error => {
		submitLog(Strings.Log.Error, `Error updating client connect settings`, { error });
	});
}

function register(businessEmail?: string) {
	if (businessEmail === undefined) return;
	if (!jwt_auth.hasPermission(P.ClientConnect)) {
		return;
	}

	const emailLocalPart = businessEmail.substring(0, businessEmail.indexOf('@'));
	if (!emailLocalPart) return;

	fetchWithJwtHeader(AppConfig.client_connect_link + '/api/register', {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
		},
		body: JSON.stringify({
			URL: emailLocalPart,
		}),
	}).catch(error => {
		submitLog(Strings.Log.Error, `Error registering for client connect`, { error });
	});
}

function fetchClientConnectURL(): Promise<any> {
	return fetchWithJwtHeader(AppConfig.client_connect_link + '/api/register', {
		method: 'GET',
	});
}

function* handleClearClientConnectActivity(action: Action<undefined>) {
	try {
		yield call(clearClientConnectActivity);
		yield put(CacheNewClientConnectActivity(0));
	} catch (e) {
		submitLog(Strings.Log.Error, `Error clearing client connect activity`, {
			error: e,
		});
	}
}

function* handleFetchClientConnectActivity() {
	try {
		if (!jwt_auth.hasPermission(P.ClientConnect)) {
			return;
		}
		const resp = yield call(fetchNewClientConnectActivityCount);
		const count = yield resp.text();
		yield put(CacheNewClientConnectActivity(+count));
	} catch (e) {
		submitLog(Strings.Log.Error, `Error fetching client connect activity`, {
			error: e,
		});
	}
}

function* handleFetchClientConnectSettings() {
	try {
		if (!jwt_auth.hasPermission(P.ClientConnect)) {
			return;
		}
		const resp = yield call(fetchClientConnectSettings);
		const settings: ClientConnectSettingsDto = yield resp.json();
		if (settings) {
			yield put(
				ReturnClientConnectSettings({
					isActivated: settings.IsActivated,
					contacts: settings.Contacts,
					appointmentSettings: settings.AppointmentSettings || InitialCCAppointmentSettings,
				})
			);
		} else {
			yield put(
				ReturnClientConnectSettings({
					isActivated: false,
					contacts: undefined,
					appointmentSettings: InitialCCAppointmentSettings,
				})
			);
		}
	} catch (e) {
		submitLog(Strings.Log.Error, `Error fetching client connect settings`, {
			error: e,
		});
	}
}

function* handlePostClientConnectSettings(
	action: Action<ClientConnectSettings>
) {
	try {
		if (action.payload.isActivated) {
			register(action.payload.email);
		}
		let settingsDto: ClientConnectSettingsDto = {
			IsActivated: action.payload.isActivated,
			Contacts: action.payload.contacts,
			AppointmentSettings: action.payload.appointmentSettings,
		};
		postClientConnectSettings(settingsDto);
		yield put(ReturnClientConnectSettings(action.payload));
	} catch (e) {
		submitLog(Strings.Log.Error, `Error updating client connect settings`, {
			error: e,
		});
	}
}

function* handleFetchClientConnectURL(action: Action<undefined>) {
	try {
		if (!jwt_auth.hasPermission(P.ClientConnect)) {
			return;
		}
		const request = yield call(fetchClientConnectURL);
		const response = yield request.json();
		const url = response['Url'];
		yield put(CacheClientConnectURL(url || ''));
	} catch (e) {
		submitLog(Strings.Log.Error, `Error fetching client connect url`, { error: e });
	}
}

function* handleGetClientConnectInfo() {
	try {
		if (!jwt_auth.hasPermission(P.ClientConnect)) {
			return;
		}
		const resp = yield call(fetchClientConnectSettings);
		const settings: ClientConnectSettingsDto = yield resp.json();
		if (settings) {
			yield put(
				ReturnClientConnectSettings({
					isActivated: settings.IsActivated,
					contacts: settings.Contacts,
					appointmentSettings: settings.AppointmentSettings || InitialCCAppointmentSettings,
				})
			);
			if (settings.IsActivated) {
				const resp = yield call(fetchNewClientConnectActivityCount);
				const count = yield resp.text();
				yield put(CacheNewClientConnectActivity(+count));
			}
		} else {
			yield put(
				ReturnClientConnectSettings({
					isActivated: false,
					contacts: undefined,
					appointmentSettings: InitialCCAppointmentSettings
				})
			);
		}

	} catch (error) {
		submitLog(Strings.Log.Error, 'Error getting ClientConnect info', { error });
	}
}

function* handleOpenClientConnectConfigureDialog() {
	const state: AppState = getAppState();
	if (!jwt_auth.hasPermission(P.ClientConnect)) {
		return;
	}

	if (!state.clientConnect.settings.isActivated) {
		yield put(openClientConnectConfigureDialog.done({ result: true }));
		return;
	}
	
	yield put(navigateTo(navRoutes.clientConnect.path));
}

function* handleGetClientConnectUsage() {
	try {
		if (!jwt_auth.hasPermission(P.ClientConnect)) {
			return;
		}
		const response = yield call(fetchWithJwtHeader, AppConfig.client_connect_link + '/api/activity', { method: 'GET' });
		const result: ClientConnectActivity[] = yield response.json();
		yield put(getClientConnectUsageActivities.done({ result }));
	}
	catch (error) {
		yield put(getClientConnectUsageActivities.failed({ error }))
		submitLog(Strings.Log.Error, 'Error getting ClientConnect Usage Activities', { error });
	}
}


export function* clientConnectSagas() {
	yield all([
		takeEvery(
			ClearNewClientConnectActivity,
			handleClearClientConnectActivity
		),
		takeEvery(
			FetchClientConnectURL.type,
			handleFetchClientConnectURL
		),
		takeEvery(
			PostClientConnectSettings,
			handlePostClientConnectSettings
		),
		takeEvery(
			FetchClientConnectSettings,
			handleFetchClientConnectSettings
		),
		takeEvery(
			FetchNewClientConnectActivity,
			handleFetchClientConnectActivity
		),
		takeLatestForActionType(
			getClientConnectInfo.started,
			handleGetClientConnectInfo
		),
		takeLatestForActionType(
			openClientConnectConfigureDialog.started,
			handleOpenClientConnectConfigureDialog
		),
		takeLatestForActionType(
			getClientConnectUsageActivities.started,
			handleGetClientConnectUsage
		)
	]);
}