import {actionCreatorFactory} from 'typescript-fsa';
import { QuoteActivity } from '../reducers/quote_activity_reducer';

const actionCreator = actionCreatorFactory('QuoteActivity');

export const GetQuoteActivity = actionCreator.async<string, QuoteActivity>(
	'GET_QUOTE_ACTIVITY'
);

export const DeleteQuoteActivity = actionCreator.async<string, boolean>(
	'DELETE_QUOTE_ACTIVITY'
);
