import React from 'react';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { themeLinks } from '../../utilities/branding';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { MoreMenu, createHelpAction } from '../../components/nav/more_menu';
import { isBrokerage } from '../../utilities/brokerage_utils';
import { NotificationSummary, notificationCategories } from '../../components/notifications/notificationSummary';
import { isDesktop } from '../../utilities/is_mobile';
import { DesktopPadding } from '../../components/Layout/desktop_padding';
import { WideButton } from '../../components/utility/wide_button';
import { useLinkToExternal } from '../../utilities/hooks';
import { AppConfig } from '../../types/config';

interface Props {}
export const ContractCompensationContainer: React.FC<Props> = (props) => {

	const openLeadContribution = useLinkToExternal(AppConfig.lead_contribution_link);
	const openBankingInformation = useLinkToExternal(AppConfig.banking_info_link);

	return (
		<BasePageContainer
			topComponent={
				<HeaderBarComponent
					title={'Contract & Compensation'}
					titleStyle={!isDesktop() ? { fontSize: '0.8rem' } : {}}
					rightButtons={
						<MoreMenu
							children={[createHelpAction(themeLinks.helpLinkProfile)]}
						/>
					}
				/>
			}
		>
			<PersistentNotifications />
			<NotificationSummary categorySelector={notificationCategories.profile}/>
			<DesktopPadding>
				{!isBrokerage &&
					<WideButton
						primary={'View Lead Contribution'}
						secondary={'View/manage your lead contributions'}
						onClick={openLeadContribution}
					/>
				}
				<WideButton
					primary={'View Banking Information'}
					secondary={'View/manage your banking information'}
					onClick={openBankingInformation}
				/>
			</DesktopPadding>
		</BasePageContainer>
	);
}
