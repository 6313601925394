import {actionCreatorFactory} from 'typescript-fsa';

export interface LookupData {
	type: string;
	name: string;
	sequence: number;
	visible: boolean;
}

export const createStaticLookup = (data: LookupData) => {
	
}

export const actionCreator = actionCreatorFactory('Lookup');

export const removeHiddenValuesThenStoreLookups = (lookups: LookupData[]) => {
	const visibleLookups = lookups.filter(lookup => lookup.visible);
	return StoreLookupData(visibleLookups);
}

export const StoreLookupData = actionCreator<LookupData[]>('STORE_LOOKUP_DATA');
export const GetLookupData = actionCreator('GET_LOOKUP_DATA');
