import { QueueSnackbar } from '../actions/snackbar_actions';
import {
	DownloadAttachment,
	addAttachmentFailure,
	addAttachmentSuccess,
	AddAttachment,
	DeleteAttachment,
	deleteAttachmentSuccess,
	deleteAttachmentFailure,
} from '../actions/attachment_actions';
import http, { HttpOptions } from '../utilities/http';
import { call, put, all } from 'redux-saga/effects';
import local_storage from '../utilities/storage/local_storage';
import _ from 'lodash';
import { Attachment } from '../reducers/attachment_reducer';
import { submitLog } from '../utilities/logging_util';
import { Strings } from '../assets/common/strings';
import { takeLatestForActionType } from '../utilities/saga_util';
import { Action } from 'typescript-fsa';

//////////  Add Attachment //////////
function* addAttachmentSaga(action: Action<Attachment>) {
	try {
		const options: HttpOptions = {
			method: 'POST',
			body: JSON.stringify(action.payload),
		};

		const response = yield call(http, 'attachment/SaveAttachment', options);

		if (response.ok) {
			const data: Attachment = yield response.json();
			yield put(addAttachmentSuccess(action.payload, data));
		} else {
			yield put(
				addAttachmentFailure(action.payload, { errorCode: response.status })
			);
		}
	} catch (error) {
		yield put(addAttachmentFailure(action.payload, error));
	}
}

function* deleteAttachmentSaga(action: Action<Attachment>) {
	try {
		const response = yield call(deleteAttachmentCall, action.payload);
		if (response.ok) {
			yield put(deleteAttachmentSuccess(action.payload, action.payload.id));
		} else {
			yield put(
				deleteAttachmentFailure(action.payload, { errorCode: response.status })
			);
		}
	} catch (error) {
		submitLog(Strings.Log.Error, `Failed to delete attachment`, {
			error,
			payload: action.payload,
		});
		yield put(deleteAttachmentFailure(action.payload, error));
	}
}

function deleteAttachmentCall(attachmentToDelete: Attachment): Promise<any> {
	return http('attachment/DeleteAttachment/', {
		method: 'POST',
		body: JSON.stringify(attachmentToDelete),
	});
}

//////////  Download Attachment from Contact  //////////
function* downloadAttachmentSaga(action: Action<Attachment>) {
	try {
		yield put(QueueSnackbar({ text: 'Starting download...' }));

		const options: HttpOptions = {
			method: 'POST',
			body: JSON.stringify(action.payload),
		};

		const response = yield call(http, 'attachment/GetAttachment', options);

		if (response.ok) {
			const data = yield response.blob();
            local_storage.downloadFileFromBrowser(data, action.payload.fileName);
		} else {
			yield put(QueueSnackbar({ text: 'We could not get your file from the Server, please try again later.' }));
		}
	} catch (error) {
		yield put(QueueSnackbar({ text: 'We could not initiate download.' }));
	}
}

export function* attachmentSagas() {
	yield all([
		takeLatestForActionType(
			AddAttachment.started,
			addAttachmentSaga
		),
		takeLatestForActionType(
			DeleteAttachment.started,
			deleteAttachmentSaga
		),
		takeLatestForActionType(
			DownloadAttachment,
			downloadAttachmentSaga
		),
	]);
}