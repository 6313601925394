import React from 'react';
import { ListItem, ListItemText, Paper, ListItemIcon } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useListItemStyles } from '../../utilities/hooks/styles';

interface Props {
	onClick?: () => void;
	primary: React.ReactNode;
	secondary?: React.ReactNode;
	icon?: JSX.Element;
	leftIcon?: JSX.Element;
	disabled?: boolean;
}

export const WideButton: React.FC<Props> = (props) => {
	const { primary, secondary, icon, leftIcon, disabled, onClick, children } = props;
	const styles = useListItemStyles();
	return (
		<Paper className={styles.margin}>
			<ListItem
				button={!Boolean(disabled)}
				onClick={disabled ? undefined : onClick}
				className={styles.padded}
			>
				{leftIcon && (
					<ListItemIcon className={styles.leftIcon}>{leftIcon}</ListItemIcon>
				)}
				<ListItemText
					primary={primary}
					secondary={secondary}
				/>
				{!disabled && (
					<ListItemIcon className={styles.arrowIcon}>
						{icon || <ChevronRightIcon/>}
					</ListItemIcon>
				)}
			</ListItem>
			{children}
		</Paper>
	);
};
