import React from 'react';
import _ from 'lodash';
import { Loaded } from '../../utilities/utilities';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@material-ui/core';
import { Contact } from '../../reducers/ContactReducer';
import { Policy } from '../../reducers/policy_reducer';
import { Employer } from '../../reducers/employer_reducer';
import { themePalette } from '../../utilities/branding';
import { connect } from '@optum-uhone-hmkts/rise';
import { AppState } from '../../reducers/index';
import { GetEmployer, DeleteEmployer } from '../../actions/employer_actions';
import { GetHouseholdContacts } from '../../actions/household_actions';
import { fullName } from '../../assets/common/string_builders';

interface StateProps {
	contacts: Loaded<Contact>[];
	employers: Loaded<Employer>[];
	policies: Loaded<Policy>[];
}

interface DeletePersonProps {
	contact: Contact;
	visible: boolean;
	deleteContact: () => void;
	returnToDetailPage: () => void;
	goToLeadsDashboard: () => void;
	onClose: () => void;
	deleteFromHousehold?: boolean;
	deleteFromContact?: boolean;
}

interface DispatchProps {
	getEmployer: (employerId: string) => void;
	deleteEmployer: (employerId: string) => void;
	getHouseholdContacts: (householdId: string) => void;
}

type Props = StateProps & DeletePersonProps & DispatchProps;

class DeletePerson extends React.Component<Props> {
	constructor(props: Props) {
		super(props);
	}

	componentDidMount() {
		if (this.props.contact) {
			if (
				this.props.contact.employerId &&
				_.findIndex(
					this.props.employers,
					employer => employer.data.id === this.props.contact.employerId
				) === -1
			) {
				this.props.getEmployer(this.props.contact.employerId);
			}
		}
	}

	doesPolicyExist(): boolean {
		let policies = this.props.policies.slice();
		policies = policies.filter(policy => {
			if (policy.data.primaryInsured) {
				return policy.data.primaryInsured.id === this.props.contact.id;
			} else {
				return false;
			}
		});

		return policies.length > 0;
	}

	isPrimaryPerson(): boolean {
		return this.props.contact.householdRole === 0;
	}

	isPrimaryEmployee(): boolean {
		return this.props.contact.employerPrimaryContact;
	}

	isSolePerson(): boolean {
		const people = this.props.contacts.filter(person => {
			return person.data.householdId === this.props.contact.householdId;
		});

		return people.length === 1;
	}

	isSoleEmployee(): boolean {
		const people = this.props.contacts.filter(person => {
			return (
				this.props.contact.employerId &&
				person.data.employerId === this.props.contact.employerId
			);
		});

		return people.length === 1;
	}

	render() {
		let dialogActions: JSX.Element = {} as JSX.Element;
		let dialogTitle: string = '';
		let dialogContent: string = '';

		if (this.props.contact) {
			const primaryPerson = this.isPrimaryPerson();
			const primaryEmployee = this.isPrimaryEmployee();
			const onlyPerson = this.isSolePerson();
			const onlyEmployee = this.isSoleEmployee();
			const policyExists = this.doesPolicyExist();

			dialogTitle = 'Confirmation';
			this.props.deleteFromHousehold
				? (dialogContent = householdDialogs.DeleteText)
				: (dialogContent = employerDialogs.DeleteText);
			dialogActions = (
				<div>
					<Button color="secondary" onClick={this.props.onClose}>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							this.props.deleteContact();
							this.props.returnToDetailPage();
							this.props.onClose();
						}}
						style={{ backgroundColor: themePalette.delete_remove_reject_button }}
					>
						Delete
					</Button>
				</div>
			);

			if (policyExists && (!this.isPrimaryPerson && !this.isSolePerson && !this.isSoleEmployee && !this.isPrimaryEmployee)) {
				dialogTitle = 'Warning';

				this.props.deleteFromHousehold
					? (dialogContent = householdDialogs.PersonWithPolicyText)
					: (dialogContent = employerDialogs.PersonWithPolicyText);

				dialogActions = (
					<Button variant="contained" color="primary" onClick={this.props.onClose}>
						OK
					</Button>
				);
			} else if (primaryPerson || primaryEmployee) {
				if (
					(primaryPerson && onlyPerson) ||
					(primaryEmployee && onlyEmployee)
				) {
					dialogTitle = 'Confirmation';
					primaryEmployee
						? (dialogContent = employerDialogs.WholeEntityText)
						: (dialogContent = householdDialogs.WholeEntityText);
					dialogActions = (
						<div>
							<Button color="secondary" onClick={this.props.onClose}>
								Cancel
							</Button>
							<Button
								variant="contained"
								color="primary"
								style={{ backgroundColor: themePalette.delete_remove_reject_button }}
								onClick={() => {
									if (onlyEmployee)
										this.props.deleteEmployer(this.props.contact.employerId);
									this.props.deleteContact();

									if (this.props.deleteFromContact) {
										this.props.goToLeadsDashboard();
									} else if (
										(!primaryPerson || this.props.deleteFromHousehold) &&
										(!primaryEmployee || !this.props.deleteFromHousehold)
									) {
										this.props.goToLeadsDashboard();
									} else {
										this.props.returnToDetailPage();
									}
									this.props.onClose();
								}}
							>
								Delete
							</Button>
						</div>
					);
				}

				if (primaryEmployee && !onlyEmployee) {
					dialogTitle = 'Warning';
					dialogContent = `${fullName(this.props.contact)} ${
						employerDialogs.PrimaryContactText
						}`;
					dialogActions = (
						<div>
							<Button
								variant="contained"
								color="primary"
								onClick={() => {
									if (this.props.returnToDetailPage) {
										this.props.returnToDetailPage();
									} else {
										this.props.goToLeadsDashboard();
									}
									this.props.onClose();
								}}
							>
								OK
							</Button>
						</div>
					);
				}

				if (primaryPerson && !onlyPerson) {
					dialogTitle = 'Warning';
					dialogContent = `${fullName(this.props.contact)} ${
						householdDialogs.PrimaryContactText
						}`;
					dialogActions = (
						<div>
							<Button
								variant="contained"
								color="primary"
								onClick={() => {
									if (this.props.returnToDetailPage) {
										this.props.returnToDetailPage();
									} else {
										this.props.goToLeadsDashboard();
									}
									this.props.onClose();
								}}
							>
								OK
							</Button>
						</div>
					);
				}
			}
		}
		return (
			<Dialog open={this.props.visible}>
				<DialogTitle>{dialogTitle}</DialogTitle>
				<DialogContent>{dialogContent}</DialogContent>
				<DialogActions>{dialogActions}</DialogActions>
			</Dialog>
		);
	}
}

function mapStateToProps(state: any): StateProps {
	
	return {
		contacts: state.contact.contacts,
		employers: state.employer.employers,
		policies: state.policy.policies,
	};
}

function mapDispatchToProps(dispatch: any): DispatchProps {
	return {
		getEmployer: (employerId: string) =>
			dispatch(GetEmployer.started(employerId)),
		deleteEmployer: (employerId: string) =>
			dispatch(DeleteEmployer.started(employerId)),
		getHouseholdContacts: (householdId: string) =>
			dispatch(GetHouseholdContacts.started(householdId)),
	};
}

export const DeletePersonModal = connect(mapStateToProps, mapDispatchToProps, true)(
	DeletePerson
) as React.ComponentClass<DeletePersonProps>;

const householdDialogs = {
	WholeEntityText:
		'If you delete this person, you will delete the household with all associated leads and activities. Are you sure you wish to delete this person?',
	PersonWithPolicyText:
		'There are one or more policies tied to the household of this person. You cannot delete this person.',
	PrimaryContactText:
		'is the primary contact for the household. You must choose a new primary contact.',
	DeleteText: 'Are you sure you wish to delete this person?',
};

const employerDialogs = {
	WholeEntityText:
		'If you delete this employee, you will delete the employer with all associated leads and activities. Are you sure you wish to delete this employee?',
	PersonWithPolicyText:
		'There are one or more policies tied to the employer of this employee . You cannot delete this employee.',
	PrimaryContactText:
		'is the primary contact for the employer. You must choose a new primary contact.',
	DeleteText: 'Are you sure you wish to delete this employee?',
};
