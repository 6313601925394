import { Action } from 'typescript-fsa';
import { call, put, all, race, take } from 'redux-saga/effects';
import {
	CreateLead,
	DeleteLead,
	GetLead,
	GetNotContactedLeadCount,
	GetStatusCounts,
	GetHomeStatusCounts,
	UpdateLead,
	GetFilteredLeads,
	ToggleLeadConnectLeads,
	GetLeadConnectStatus,
	StoreLeadConnectStatus,
	ReassignBLead,
	BulkReassign,
	StoreLiveTransferStatus,
	LeadCreatePayload,
	GetFilteredLeadsPayload,
	GetLeadConnectStatusParams,
	ToggleLeadConnectLeadsParams,
	ReassignBLeadParams,
	GetDashboardCounts,
	GetAccountSummary,
} from '../actions/lead_actions';
import http, { HttpOptions } from '../utilities/http';
import { Lead, TransferStatus, LeadIntakeStatus, AccountSummary } from '../reducers/LeadReducer';
import moment from 'moment';
import { GetHousehold } from '../actions/household_actions';
import { GetEmployer } from '../actions/employer_actions';
import { GetProductCounts, UpdatePolicy } from '../actions/policy_actions';
import { LeadFilters } from '../reducers/LeadFilterReducer';
import { QueueSnackbar } from '../actions/snackbar_actions';
import { BulkReassignRequestDto } from '../reducers/advanced_search_reducer';
import { navigateToWithoutAddingToHistory } from '../actions/navigation_actions';
import { navRoutes } from '../components/nav/Routes';
import { submitLog } from '../utilities/logging_util';
import { hydrateLeads } from '../utilities/lead_util';
import { themePalette } from '../utilities/branding';
import { Strings } from '../assets/common/strings';
import { takeLatestForActionType, takeEveryForActionType } from '../utilities/saga_util';
import { getSortByProperties } from '../utilities/sort_util';
import { getSnackbarErrorProps, getSnackbarSuccessProps } from '../utilities/snackbar_util';

// GET LEAD
function* getLeadSaga(action: Action<string>) {
	try {
		const response = yield call(getLeadClient, action.payload);
		if (response.ok) {
			const data: Lead = yield response.json();
			yield put(GetLead.done({ params: action.payload, result: data }));
		} else {
			yield put(
				GetLead.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
		}
	} catch (error) {
		submitLog(Strings.Log.Error, `Error getting lead`, {
			error,
			payload: action.payload,
		});
		yield put(GetLead.failed({ params: action.payload, error }));
	}
}
function getLeadClient(id: string) {
	return http('lead/' + id);
}

// UPDATE LEAD
function* updateLeadSaga(action: Action<Lead>) {
	try {
		const response = yield call(updateLeadClient, action.payload);
		if (response.ok) {
			const data: Lead = yield response.json();
			yield put(UpdateLead.done({ params: action.payload, result: data }));
		} else {
			yield put(
				UpdateLead.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
		}
	} catch (error) {
		yield put(UpdateLead.failed({ params: action.payload, error }));
	}
}
function updateLeadClient(lead: Lead) {
	const options: HttpOptions = {
		method: 'PUT',
		body: JSON.stringify(lead),
	};

	return http('lead', options);
}

// CREATE LEAD
function* createLeadSaga(action: Action<LeadCreatePayload>) {
	try {
		const response = yield call(createLeadClient, action.payload.lead, action.payload.dupeCheck);
		if (response.ok) {
			const updatedLead: Lead = yield response.json();
			yield put(
				CreateLead.done({ params: action.payload, result: updatedLead })
			);

			if (action.payload.policy && action.payload.history) {
				const navigateRoute = 
					navRoutes.productDetail.path.replace(
						Strings.Navigation.ProductId,
						'|' + action.payload.policy.id
					);
				yield put(
					navigateToWithoutAddingToHistory(navigateRoute)
				)
			} else if (action.payload.history) {
				const navigateRoute =
					updatedLead.employer && updatedLead.employer.id
						? navRoutes.employer.path.replace(
							Strings.Navigation.EmployerId,
							updatedLead.employer.id
						)
						: navRoutes.household.path.replace(
							Strings.Navigation.HouseholdId,
							updatedLead.householdId
						);
				yield put(
					navigateToWithoutAddingToHistory(navigateRoute)
				);
			}

			if (action.payload.lead.employer && action.payload.lead.employer.id) {
				yield put(GetEmployer.started(action.payload.lead.employer.id));
			} else if (updatedLead.householdId) {
				yield put(GetHousehold.started(updatedLead.householdId));
			}

			if (action.payload.policy) {
				yield put(UpdatePolicy.started(action.payload.policy));
			}
		} else {
			yield put(
				CreateLead.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
			yield put(
				QueueSnackbar(getSnackbarErrorProps('Unable to create lead', 10000))
			);
		}
	} catch (error) {
		yield put(CreateLead.failed({ params: action.payload, error }));
		yield put(
			QueueSnackbar(getSnackbarErrorProps('Unable to create lead', 10000))
		);
	}
}
function createLeadClient(lead: Lead, dupeCheck: boolean) {
	const options: HttpOptions = {
		method: 'POST',
		body: JSON.stringify(lead),
	};
	return http('lead?dupeCheck=' + dupeCheck.toString(), options);
}

// DELETE LEAD
function* deleteLeadSaga(action: Action<string>) {
	try {
		const response = yield call(deleteLeadClient, action.payload);
		if (response.ok) {
			const data = yield response.json();
			yield put(DeleteLead.done({ params: action.payload, result: data }));
		} else {
			yield put(
				DeleteLead.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
		}
	} catch (error) {
		yield put(DeleteLead.failed({ params: action.payload, error }));
	}
}
function deleteLeadClient(leadId: string) {
	const options: HttpOptions = {
		method: 'DELETE',
	};
	return http('lead/' + leadId, options);
}

// GET LEAD STATUS COUNT
function* handleGetStatusCountsSaga(action: Action<undefined>) {
	try {
		const endpoint = 'lead/StatusCounts?scope=Me';
		const response = yield call(http, endpoint);
		if (response.ok) {
			const data = yield response.json();
			yield put(
				GetStatusCounts.done({ params: action.payload, result: data })
			);
		} else {
			yield put(
				GetStatusCounts.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
		}
	} catch (error) {
		yield put(GetStatusCounts.failed({ params: action.payload, error }));
	}
}

// GET HOME LEAD STATUS COUNT
function* getHomeStatusCountsSaga(action: Action<undefined>) {
	try {
		const response = yield call(getHomeStatusCountsClient);
		if (response.ok) {
			const data = yield response.json();
			yield put(
				GetHomeStatusCounts.done({ params: action.payload, result: data })
			);
		} else {
			yield put(
				GetHomeStatusCounts.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
		}
	} catch (error) {
		yield put(GetHomeStatusCounts.failed({ params: action.payload, error }));
	}
}
function getHomeStatusCountsClient() {
	return http('lead/HomePageStatusCounts?scope=Me');
}

// GET NOT CONTACTED LEAD COUNT
function* getNotContactedLeadsSaga(action: Action<undefined>) {
	try {
		const response = yield call(getNotContactedLeadsCount);
		if (response.ok) {
			const data = yield response.json();
			yield put(
				GetNotContactedLeadCount.done({
					params: action.payload,
					result: data,
				})
			);
		} else {
			yield put(
				GetNotContactedLeadCount.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
		}
	} catch (error) {
		yield put(
			GetNotContactedLeadCount.failed({ params: action.payload, error })
		);
	}
}
function getNotContactedLeadsCount() {
	return http('lead/NotContactedCount?scope=Me');
}

//////////  Get Filtered Leads  //////////
function* getFilteredLeadsSaga(action: Action<GetFilteredLeadsPayload>) {
	try {
		const sortingProperties: {
			category: string;
			direction: string;
		} = getSortByProperties(action.payload.sortBy);
		const {
			pageNum,
			pageSize,
			statusFilterLabels,
			...payload
		} = action.payload;
		const filterPayload: any = {
			...payload,
			statusFilter: statusFilterLabels,
			sortCategory: sortingProperties.category,
			sortDirection: sortingProperties.direction,
			toDate: moment.utc(action.payload.toDate).toDate(),
			fromDate: moment.utc(action.payload.fromDate).toDate(),
		};
		const response = yield call(getFilteredLeadsClient, {
			filters: filterPayload,
			pageNum: pageNum,
			pageSize: pageSize,
		});

		if (response.ok) {
			const leads: Lead[] = yield response.json();
			hydrateLeads(leads);
			yield put(
				GetFilteredLeads.done({ params: action.payload, result: leads })
			);
		} else {
			yield put(
				GetFilteredLeads.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
		}
	} catch (error) {
		yield put(GetFilteredLeads.failed({ params: action.payload, error }));
	}
}

function getFilteredLeadsClient(params: {
	filters: LeadFilters;
	pageNum: number;
	pageSize: number;
}) {
	return http(
		'lead/GetFilteredLeads?scope=Me',
		{
			method: 'POST',
			body: JSON.stringify(params.filters),
		},
		{ index: params.pageNum, size: params.pageSize }
	);
}

// GET LEAD STATUS FROM LEADCONNECT
function* getLeadConnectStatusSaga(action: Action<GetLeadConnectStatusParams>) {
	try {
		const response = yield call(getLeadStatusClient, action.payload.agentId);
		if (response.ok) {
			const result = yield response.json();
			const status: LeadIntakeStatus = JSON.parse(result);
			yield put(StoreLeadConnectStatus(status.Global));
			yield put(StoreLiveTransferStatus(status.LiveTransfer));
		} else {
			yield put(StoreLeadConnectStatus(TransferStatus.Unknown));
			yield put(StoreLiveTransferStatus(TransferStatus.Unknown));
		}
	} catch (error) {
		yield put(StoreLeadConnectStatus(TransferStatus.Unknown));
		yield put(StoreLiveTransferStatus(TransferStatus.Unknown));
	}
}
function getLeadStatusClient(agentId: string) {
	return http(`LeadConnect/agent/${agentId}/OrderStatus`);
}

// TOGGLE LEADCONNECT STATUS
function* toggleLeadConnectStatusSaga(action: Action<ToggleLeadConnectLeadsParams>) {
	const errorSnackbarProps = getSnackbarErrorProps('Cannot update LeadConnect; Call Field Support');
	try {
		const response = yield call(
			postLeadStatusClient,
			action.payload.agentId,
			action.payload.type,
			action.payload.status == 1
		);

		if (response.ok) {
			const successful: boolean = yield response.json();
			if (successful) {
				let leadMessage = '';
				if (action.payload.type.toLowerCase() === 'livetransfer') {
					yield put(StoreLiveTransferStatus(action.payload.status));
					leadMessage = 'Live Transfer';
				} else {
					yield put(StoreLeadConnectStatus(action.payload.status));
					leadMessage = 'All'
				}

				yield put(
					QueueSnackbar(getSnackbarSuccessProps(`${leadMessage} leads set to: ${TransferStatus[action.payload.status]}`))
				);
			} else {
				if (action.payload.type.toLowerCase() === 'livetransfer') {
					yield put(StoreLiveTransferStatus(TransferStatus.Unknown));
				} else {
					yield put(StoreLeadConnectStatus(TransferStatus.Unknown));
				}
				yield put(QueueSnackbar(errorSnackbarProps));
			}
		} else {
			yield put(QueueSnackbar(errorSnackbarProps));
		}
	} catch (error) {
		yield put(QueueSnackbar(errorSnackbarProps));
	}
}
function postLeadStatusClient(agentId: string, type: string, newStatus: boolean) {
	const options = {
		method: 'PUT',
		body: JSON.stringify({ newStatus: newStatus }),
	};
	return http(`LeadConnect/agent/${agentId}/OrderStatus/${type}/${newStatus}`, options as HttpOptions);
}

// REASSIGN B-LEAD
function* startReassignBLeadSaga(action: Action<ReassignBLeadParams>) {
	try {
		const response = yield call(
			reassignBLeadClient,
			action.payload.householdId,
			action.payload.agentCode
		);
		if (response.ok) {
			const data = yield response.json();
			yield put(ReassignBLead.done({
				params: action.payload,
				result: data
			}));
			yield put(QueueSnackbar(getSnackbarSuccessProps(`This B-Lead has been reassigned to ${action.payload.agentName}`)));
		} else {
			yield put(
				ReassignBLead.failed({
					params: action.payload,
					error: response.status
				})
			);
			yield put(QueueSnackbar(getSnackbarErrorProps('Unable to reassign B-Lead')));
		}
	} catch (error) {
		yield put(
			ReassignBLead.failed({
				params: action.payload,
				error
			})
		);
		yield put(QueueSnackbar(getSnackbarErrorProps('Unable to reassign B-Lead')));
	}
}
function reassignBLeadClient(householdId: string, newAgentCode: string) {
	const options: HttpOptions = {
		method: 'POST',
	};
	return http(
		'lead/assignBLead?householdId=' +
		householdId +
		'&newAgentCode=' +
		newAgentCode,
		options
	);
}

// LEAD BULK REASSIGN
function* startBulkReassignSaga(action: Action<BulkReassignRequestDto>) {
	try {
		const response = yield call(bulkReassignClient, action.payload);
		if (response.ok) {
			const data = yield response.json();
			yield put(BulkReassign.done({ params: action.payload, result: data }));
			yield put(QueueSnackbar(getSnackbarSuccessProps('Re-assignment started')));
		}
	} catch (error) {
		yield put(BulkReassign.failed({ params: action.payload, error }));
		yield put(QueueSnackbar(getSnackbarErrorProps('Unable to start re-assignment')));
	}
}
function bulkReassignClient(
	bulkReassignRequestDto: BulkReassignRequestDto
): Promise<any> {
	const options: HttpOptions = {
		method: 'POST',
		body: JSON.stringify(bulkReassignRequestDto),
	};
	return http('lead/BulkReassign', options);
}

// Lead Dashboard status counts
function* handleGetDashboardCountsSaga() {
	try {
		yield all([
			put(GetStatusCounts.started()),
			put(GetProductCounts.started()),
		]);

		// Wait for responses
		yield all([
			race([take(GetStatusCounts.done.type), take(GetStatusCounts.failed.type)]),
			race([take(GetProductCounts.done.type), take(GetProductCounts.failed.type)]),
		]);

		yield put(GetDashboardCounts.done({ result: undefined }));
	}
	catch (error) {
		yield all([
			put(GetDashboardCounts.failed({ params: undefined, error })),
			put(QueueSnackbar(getSnackbarErrorProps('Unable to get dashboard counts')))
		]);
	}
}

// GET ACCOUNT SUMMARY FROM LEADCONNECT
function* getLeadAccountSummarySaga() {
	try {
		const response = yield call(getLeadAccountSummary);
		if (response.ok) {
			const data: AccountSummary = yield response.json();
			yield put(GetAccountSummary.done({ result: data }));
		} else {
			yield put(
				GetAccountSummary.failed({
					error: { errorCode: response.status },
				})
			);
		}
	} catch (error) {
		submitLog(Strings.Log.Error, `Error getting lead account summary`, {
			error,
		});
		yield put(GetAccountSummary.failed({ error }));
	}
}
function getLeadAccountSummary() {
	return http(`LeadConnect/AccountSummary`);
}

export function* leadSagas() {
	yield all([
		takeLatestForActionType(
			GetLead.started,
			getLeadSaga
		),
		takeLatestForActionType(
			UpdateLead.started,
			updateLeadSaga
		),
		takeLatestForActionType(
			CreateLead.started,
			createLeadSaga
		),
		takeLatestForActionType(
			DeleteLead.started,
			deleteLeadSaga
		),
		takeLatestForActionType(
			GetHomeStatusCounts.started,
			getHomeStatusCountsSaga
		),
		takeLatestForActionType(
			GetNotContactedLeadCount.started,
			getNotContactedLeadsSaga
		),
		// Note this should be takeEvery so all instances of call go through
		takeEveryForActionType(
			GetFilteredLeads.started,
			getFilteredLeadsSaga
		),
		takeLatestForActionType(
			GetLeadConnectStatus,
			getLeadConnectStatusSaga
		),
		takeLatestForActionType(
			ToggleLeadConnectLeads,
			toggleLeadConnectStatusSaga
		),
		takeEveryForActionType(
			ReassignBLead.started,
			startReassignBLeadSaga
		),
		takeEveryForActionType(
			BulkReassign.started,
			startBulkReassignSaga
		),
		takeLatestForActionType(
			GetStatusCounts.started,
			handleGetStatusCountsSaga
		),
		takeLatestForActionType(
			GetDashboardCounts.started,
			handleGetDashboardCountsSaga
		),
		takeLatestForActionType(
			GetAccountSummary.started,
			getLeadAccountSummarySaga
		),		
	])
}