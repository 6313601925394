import React from 'react';
import { connect, Dispatch } from '@optum-uhone-hmkts/rise';
import { Route, Switch, } from 'react-router-dom';

import { NavigationProps, navRoutes } from '../../components/nav/Routes';
import { AgentHierarchyContainer } from './agent_hierarchy';
import { AppState } from '../../reducers';
import { GetAgentProfile } from '../../actions/agent_search_actions';
import { Agent } from '../../reducers/agent_reducer';
import { AgentProfile } from './agent_profile';

interface StateProps {
	agent: Agent;
	// viewingAgentCode: string;
}
interface DispatchProps {
	getAgentProfile: (agentCode: string) => void;
}
export type LandingProps = StateProps & DispatchProps & NavigationProps;

const AgentProfileLanding: React.FC<LandingProps> = (props) => {

	const agentCode: string = props.match.params.agentcode;

	return (
		<Switch>
			<Route
				exact
				path={navRoutes.agentProfile.path}
				render={() => <AgentProfile {...props} />}
			/>
			<Route
				exact
				path={navRoutes.agentHierarchy.path}
				render={() => <AgentHierarchyContainer agentCode={agentCode} />}
			/>
		</Switch>
	);
}

const mapStateToProps = (state: AppState): StateProps => ({
	agent: state.agentSearch.selectedAgentProfile!,
	// viewingAgentCode: state.agent.agentCode,
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	getAgentProfile: (agentCode: string) => dispatch(GetAgentProfile.started(agentCode))
});

export const AgentProfileLandingContainer = connect(
	mapStateToProps, mapDispatchToProps, true
)(AgentProfileLanding);
