import { LogToSplunk } from '../actions/logging_actions';
import { store, getAppState } from '../';
import * as uuid from 'uuid';
import { Strings } from '../assets/common/strings';
import { isMobileDevice, isIOS, isAndroid } from './is_mobile';
import { AppConfig } from '../types/config';

let deviceData = {
	platform: navigator.userAgent,
	manufacturer: navigator.vendor,
	model: navigator.appName,
	version: navigator.appVersion,
	uuid: 'N/A',
	isMobileDevice,
	isIOS,
	isAndroid,
};

export const sessionToken = uuid.v4();

export const getSession = () => ({
	...deviceData,
	sessionToken,
	appVersion: VERSION,
});

const verbosityLevels: { [lowestLevel: string]: Strings.Log[] } = {
	emerg: [Strings.Log.Emerg],
	crit: [Strings.Log.Emerg, Strings.Log.Crit],
	alert: [Strings.Log.Emerg, Strings.Log.Crit, Strings.Log.Alert],
	error: [Strings.Log.Emerg, Strings.Log.Crit, Strings.Log.Alert, Strings.Log.Error],
	warn: [Strings.Log.Emerg, Strings.Log.Crit, Strings.Log.Alert, Strings.Log.Error, Strings.Log.Warn],
	notice: [Strings.Log.Emerg, Strings.Log.Crit, Strings.Log.Alert, Strings.Log.Error, Strings.Log.Warn, Strings.Log.Notice],
	info: [Strings.Log.Emerg, Strings.Log.Crit, Strings.Log.Alert, Strings.Log.Error, Strings.Log.Warn, Strings.Log.Notice, Strings.Log.Info],
	log: [Strings.Log.Emerg, Strings.Log.Crit, Strings.Log.Alert, Strings.Log.Error, Strings.Log.Warn, Strings.Log.Notice, Strings.Log.Info, Strings.Log.Log],
};
const verbosity: Strings.Log[] =
	verbosityLevels[AppConfig.verbosity_level || Strings.Log.Log];

export const submitLog = (
	level: Strings.Log,
	message: string,
	metadata?: any
) => {
	const state = getAppState();
	metadata = {
		userId: state.user?.id,
		impersonatingId: state.user?.impersonatingId,
		agentCode: state.user?.agentID,
		clientStack: level === Strings.Log.Error ? Error().stack : undefined,
		...metadata,
	};

	const sessionData = getSession();

	switch (level) {
		case Strings.Log.Error:
			console.error(message, metadata, sessionData);
			break;
		case Strings.Log.Warn:
			console.warn(message, metadata, sessionData);
			break;
		case Strings.Log.Info:
			console.info(message, metadata, sessionData);
			break;
		default:
			console.log(message, metadata, sessionData);
	}

	if (verbosity.indexOf(level) > -1) {
		store.dispatch(LogToSplunk.started({ level, message, metadata }));
	}
};

export const setupGlobalExceptionHandler = () => {
	const oldWindowOnError = window.onerror;
	window.onerror = (msg, url, line, col, error) => {
		oldWindowOnError && oldWindowOnError(msg, url, line, col, error);

		// Note that col & error are new to the HTML 5 spec and may not be
		// supported in every browser.  It worked for me in Chrome.
		let extra = !col ? '' : '\ncolumn: ' + col;
		extra += !error ? '' : '\nerror: ' + error;

		const message = "Error: " + msg + "\nurl: " + url + "\nline: " + line + extra;
		submitLog(Strings.Log.Error, message, { error });
	};
};
