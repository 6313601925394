import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Icon,
} from '@material-ui/core';
import React from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import { navigateBack, navigateTo } from '../../actions/navigation_actions';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { NavigationProps, navRoutes } from '../../components/nav/Routes';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import { themePalette } from '../../utilities/branding';
import { AppState } from '../../reducers';
import { Loaded } from '../../utilities/utilities';
import { MoreMenu, createMenuAction } from '../../components/nav/more_menu';
import { Strings } from '../../assets/common/strings';
import { QuoteActivity } from '../../reducers/quote_activity_reducer';
import { DeleteQuoteActivity, GetQuoteActivity } from '../../actions/quote_activity_actions';
import { DateTimeFormat, displayDate } from '../../utilities/date_util';
import { makeOpenExternalLink } from '../../utilities';

interface StateProps {
	quoteActivity: Loaded<QuoteActivity>;
}

interface DispatchProps {
	getQuoteActivity: (id: string) => void,
    deleteQuoteActivity: (id: string) => void
}

interface Props extends StateProps, DispatchProps, NavigationProps {
}

interface State {
	confirmDelete: boolean;
}

class QuoteActivityDetail extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			confirmDelete: false
		};
	}

	componentDidMount() {
        if (this.props.match.params.quoteactivity_id && 
            (!this.props.quoteActivity || !this.props.quoteActivity.data.createdByName)) {
            this.props.getQuoteActivity(this.props.match.params.quoteactivity_id);
        }
	}

	navigateToRoute = (route: string) =>
		this.props.navigateTo( route);

	public handleCancelDelete = () => this.setState({ confirmDelete: false });
    
    public handleDeleteClick = () => this.setState({ confirmDelete: true });

	public handleDelete(quoteActivity: QuoteActivity) {
		this.setState({ confirmDelete: false });
		this.props.navigateBack();
		this.props.deleteQuoteActivity(quoteActivity.id);
	}

	openUrl = makeOpenExternalLink(this.props.quoteActivity.data.url);

	public render() {
		if (!this.props.quoteActivity) return <span />;

		const quoteActivity = this.props.quoteActivity.data;
		const type = quoteActivity.type;

		let checkColor = themePalette.negative_text;
		let avatarColor = themePalette.header_bar;

		const menuItems = [
			<MoreMenu
				children={[
					createMenuAction(Strings.MoreMenu.Delete, this.handleDeleteClick),
				]}
			/>,
		];

		return (
			<BasePageContainer
				topComponent={
					<HeaderBarComponent
						title={'Quoted'}
						forceOverflow
						rightButtons={menuItems}
						isLoading={this.props.quoteActivity.loading}
					/>
				}
			>
				<PersistentNotifications />
				<Dialog
					disableBackdropClick
					disableEscapeKeyDown
					open={this.state.confirmDelete}
				>
					<DialogTitle>Confirmation</DialogTitle>
					<DialogContent>
						{'Are you sure you want to delete this quote activity?'}
					</DialogContent>
					<DialogActions>
						<Button color="secondary" onClick={this.handleCancelDelete}>
							Cancel
						</Button>
						<Button
							color="primary"
							style={{ backgroundColor: themePalette.delete_remove_reject_button }}
							onClick={() => this.handleDelete(quoteActivity)}
							variant="contained"
						>
							Delete
						</Button>
					</DialogActions>
				</Dialog>
				<Grid container>
					<Grid item lg={3} />
					<Grid item xs={12} lg={6}>
						<Card style={styles.paperCardStyle}>
							<CardHeader
								style={styles.wordWrap}
								title={
									<span>
										{'Quoted'}
									</span>
								}
							/>
							<CardContent
								style={{
                                    ...styles.wordWrap,
                                    marginTop: -15,
									whiteSpace: 'pre-wrap',
								}}
							>
                                <div style={{marginLeft: 15, lineHeight: '2em'}}>
                                    <span> Created Date: {displayDate(quoteActivity.createdOn, DateTimeFormat.FullDateTime)} </span><br />
                                    <span> Quote ID: {quoteActivity.quoteId} </span><br />
                                    <span> Quote Source: {quoteActivity.source} </span><br />
                                    <span> {quoteActivity.type} </span><br />
                                </div>
                                <br />
                                <Button
                                    onClick={this.openUrl}
                                >
                                    View Proposal <Icon>launch</Icon>
                                </Button>

                                <div
									style={{
										wordWrap: 'break-word',
										fontSize: '12px',
										marginTop: 20,
									}}
								>
                                <div
                                    onClick={() =>
                                        this.props.navigateTo(
                                            this.props.history,
                                            navRoutes.profile.path
                                        )
                                    }
                                >
                                    Created on{' '}
                                    {displayDate(quoteActivity.createdOn, DateTimeFormat.FullDateTime)}{' '}
                                    by {quoteActivity.createdByName}
                                    <br />
                                    {quoteActivity.updatedOn ? (
                                        <div>
                                            Modified on{' '}
                                            {displayDate(quoteActivity.updatedOn, DateTimeFormat.FullDateTime)}{' '}
                                            by {quoteActivity.updatedByName}
                                        </div>
                                    ) : null}
                                    <br />
                                </div>
                                </div>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</BasePageContainer>
		);
	}
}

function mapStateToProps(
	state,
	props: Props
): StateProps {
	
	const activity = state.quoteActivity.quoteActivities.find(
		activity => activity.data.id == props.match.params.quoteactivity_id
	);
	
	return {
		quoteActivity: activity!,
	};
}

function mapDispatchToProps(dispatch: any): DispatchProps & Partial<NavigationProps> {
	return {
		navigateTo: (route: string) =>
			dispatch(navigateTo(route)),
		navigateBack: (history: any) => dispatch(navigateBack()),
		getQuoteActivity: (id: string) =>
			dispatch(GetQuoteActivity.started(id)),
		deleteQuoteActivity: (id: string) =>
			dispatch(DeleteQuoteActivity.started(id)),
	};
}

export const QuoteActivityDetailContainer = connect(
	mapStateToProps,
	mapDispatchToProps, true
)(QuoteActivityDetail);

const styles: { [key: string]: React.CSSProperties } = {
	wordWrap: {
		wordBreak: 'break-word',
	},
	paperCardStyle: {
		margin: '0 10px 10px',
	}
};
