import React, { useEffect } from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import {
	Button,
} from '@material-ui/core';
import { Widget } from './base_widget';
import ChartCard from '../utility/chart_card';
import { AppState } from '../../reducers';
import {
	PerformanceParams,
	GetPerformanceProduction,
} from '../../actions/performance_actions';
import { navRoutes, } from '../nav/Routes';
import { Dispatch } from 'redux';
import {
	ChartGroup,
	getDefaultPerformanceParams,
} from '../../reducers/performance_reducer';
import PerformanceSvg from '../svgs/icons/performance';
import { themePalette } from '../../utilities/branding';
import { nav } from '../..';
import { Strings } from '../../assets/common/strings';


interface StateProps {
	productionCharts: ChartGroup;
}
interface DispatchProps {
	getProductionCharts: (dates: PerformanceParams) => any;
}
type Props = StateProps & DispatchProps;

const _PerformanceWidget: React.FC<Props> = (props) => {

	const { productionCharts, getProductionCharts } = props;
	useEffect(() => {
		const dates: PerformanceParams = getDefaultPerformanceParams();
		getProductionCharts(dates);
	}, []);

	const viewAll = () => nav.push(navRoutes.performance.path);
	const viewDetails = () => nav.push(navRoutes.performanceProduction.path);
	return (
		<Widget
			title={Strings.WidgetTitles.Performance}
			avatar={<PerformanceSvg width={30} height={30} fill={themePalette.menuSvgPerformance} />}
			onClick={viewAll}
			actionButtons={[
				<Button onClick={viewAll}>View All</Button>,
				<Button onClick={viewDetails}>View Details</Button>,
			]}
		>
			<ChartCard
				color="tertiary"
				titleLeft="Production"
				charts={productionCharts.charts || []}
				chartStyle={{ height: 200 }}
				compressed
				detailData={productionCharts.detail || []}
			/>
		</Widget>
	);
}

const mapStateToProps = (state: AppState): StateProps => ({
	productionCharts: state.performance.productionCharts,
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	getProductionCharts: (dates: PerformanceParams) =>
		dispatch(GetPerformanceProduction.started(dates)),
});

export const PerformanceWidget = connect(mapStateToProps, mapDispatchToProps, true)(_PerformanceWidget)  as React.ComponentClass<ComponentProps>;

