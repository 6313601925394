import React from 'react';
import { Grid } from '@material-ui/core';
import { ReadOnlyTextField } from '../utility/read_only_text_field';
import { toCurrencyString } from '../../assets/common/string_builders';
import { wrapInCollapsibleCard } from '../higher_order_component/wrap_in_card';

interface Props {
	individualDeductible?: number;
	perPersonDeductible?: number;
	familyDeductible?: number;
	individualMaxOutOfPocket?: number;
	ppMaxOutOfPocket?: number;
	familyMaxOutOfPocket?: number;
	faceAmount?: number;
	benefitAmount?: number;
	annualDrugCost?: number;
	drugDeductible?: number;
}
const PolicyAmountsInfo: React.FC<Props> = (props) => {
	const individualDeductibleCurrency = toCurrencyString(props.individualDeductible);
	const perPersonDeductibleCurrency = toCurrencyString(props.perPersonDeductible);
	const familyDeductibleCurrency = toCurrencyString(props.familyDeductible);
	const individualMaxOutOfPocketCurrency = toCurrencyString(props.individualMaxOutOfPocket);
	const ppMaxOutOfPocketCurrency = toCurrencyString(props.ppMaxOutOfPocket);
	const familyMaxOutOfPocketCurrency = toCurrencyString(props.familyMaxOutOfPocket);
	const faceAmountCurrency = toCurrencyString(props.faceAmount);
	const benefitAmountCurrency = toCurrencyString(props.benefitAmount);
	const annualDrugCost = toCurrencyString(props.annualDrugCost);
	const drugDeductible = toCurrencyString(props.drugDeductible);

	return (
		<Grid container>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Individual Deductible"
						value={individualDeductibleCurrency}
						multiline
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Per Person Deductible"
						value={perPersonDeductibleCurrency}
						multiline
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Family Deductible"
						value={familyDeductibleCurrency}
						multiline
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Ind. Max Out of Pocket"
						value={individualMaxOutOfPocketCurrency}
						multiline
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Per Person Max Out of Pocket"
						value={ppMaxOutOfPocketCurrency}
						multiline
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Family Max Out of Pocket"
						value={familyMaxOutOfPocketCurrency}
						multiline
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Face Amount"
						value={faceAmountCurrency}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Benefit Amount"
						value={benefitAmountCurrency}
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Drug Deductible"
						value={drugDeductible}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Annual Drug Cost"
						value={annualDrugCost}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export const PolicyAmountsInfoCard = wrapInCollapsibleCard(PolicyAmountsInfo);
