import React from 'react';
import { Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';
import { AgentSelectorComponent } from '../agent/agent_selector';
import { themePalette } from '../../utilities/branding';
import { fullName } from '../../assets/common/string_builders';
import { isAgentRole } from '../../utilities/agent_util';
import { Strings } from '../../assets/common/strings';

interface ComponentProps {
	visible: boolean;
	currentAgentCode: string;
	reassignToAgent: (agentCode: string, agentName: string) => void;
	closeReassignDialog: () => void;
	fullScreen?: boolean;
}

interface State {
	displayConfirmationDialog: boolean;
	selectedAgent: any;
}

type Props = ComponentProps;

export class ReassignDialog extends React.Component<Props, State> {

	static defaultProps = {
		fullScreen: true,
	};

	constructor(props: Props) {
		super(props);
		this.state = {
			displayConfirmationDialog: false,
			selectedAgent: {},
		};
	}

	handleCancel = () => {
		this.props.closeReassignDialog();
	};

	handleDisplayConfirmationDialog = (selectedAgent: any) => {
		this.setState({
			displayConfirmationDialog: true,
			selectedAgent: selectedAgent,
		});
	};

	handleDismissConfirmationDialog = () => {
		this.setState({
			selectedAgent: {},
			displayConfirmationDialog: false,
		});
	};

	handleReassignConfirmation = () => {
		this.props.reassignToAgent(
			this.state.selectedAgent.agentCode,
			fullName(this.state.selectedAgent)
		);
		this.setState(
			{
				displayConfirmationDialog: false,
			},
			() => {
				setTimeout(() => {
					this.setState({ selectedAgent: {} });
				}, 1000);
			}
		);
	};

	renderReassignConfirmationDialog = () => {
		const selectedAgent = this.state.selectedAgent;
		if (selectedAgent) {
			const selectedAgentName = fullName(selectedAgent);

			return (
				<Dialog
					open={this.state.displayConfirmationDialog}
					onClose={this.handleDismissConfirmationDialog}
				>
					<DialogContent>
						Are you sure you want to reassign this lead to {selectedAgentName}?
					</DialogContent>
					<DialogActions>
						<Button
							color="secondary"
							onClick={this.handleDismissConfirmationDialog}
						>
							Cancel
						</Button>
						<Button
							color="primary"
							variant="contained"
							onClick={this.handleReassignConfirmation}
							style={{ backgroundColor: themePalette.accept_button }}
						>
							Reassign
						</Button>
					</DialogActions>
				</Dialog>
			);
		}
	};

	render() {
		return (
			<div>
				{this.renderReassignConfirmationDialog()}
				<AgentSelectorComponent
					onSave={this.handleDisplayConfirmationDialog}
					onCancel={this.handleCancel}
					saveText="Reassign"
					headerText="Reassign Lead"
					visible={this.props.visible}
					currentAgentCode={this.props.currentAgentCode}
					fullScreen={this.props.fullScreen}
					uplineOnly={isAgentRole([Strings.ADRoles.SubAgent])}
				/>
			</div>
		);
	}
}