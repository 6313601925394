import _ from 'lodash';
import moment from 'moment';
import { SearchAgent } from '../../reducers/advanced_search_reducer';
import { hasValueAnd } from '../object_util';
import { normalizeNumeric, stringToNumber } from './string_normalization';
import { Strings } from '../../assets/common/strings';
import { MatchPolicy } from '../../actions/policy_actions';
import { Phone } from '../../reducers/ContactReducer';

export type NumberRangeRestriction = {
	min?: number;
	max?: number;
	onlyIntegerValues?: boolean;
}

export const makeRestrictRange = (rangeRestriction: NumberRangeRestriction) => (value: number) => {
	const { min, max, onlyIntegerValues } = rangeRestriction;
	if (hasValueAnd(min, _min => value < _min)) {
		return min;
	} else if (hasValueAnd(max, _max => value > _max)) {
		return max;
	}
	return onlyIntegerValues ? Math.trunc(value) : value;
};

const normalizeDollar = (value: string): string => {
	return value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatCurrency(value: string, previousValue: string) {
	if (!value) {
		return value;
	}

	const numberValues = value.replace(/[^\d\.]/g, '');
	const values = numberValues.split('.');
	if (values.length > 1) {
		return "$" + normalizeDollar(values[0]) + '.' + values[1].substr(0, 2);
	}
	return "$" + normalizeDollar(values[0]);
}

export function requireNumber(value: number, previousValue: string) {
	const parsedNumber = Number(value);
	if (parsedNumber !== 0 && !parsedNumber) return previousValue;
	else return value;
}

export function normalizeInteger(value: number) {
	return Math.trunc(value);
}

export function normalizePhone(value: string): string {
	//null values
	if (!value || value === '0') {
		return '';
	}

	if (value.length > 0 && value[0] == '(') {
		return value;
	}

	let onlyNums = value.replace(/[^\d]/g, '');
	if (onlyNums[0] == '1') {
		// Remove first digit(s) if it is a 1
		onlyNums = onlyNums.replace(/^1+/, '');
	}

	const cleaned = ('' + onlyNums).replace(/\D/g, '')
	const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
	if (match) {
		return '(' + match[1] + ') ' + match[2] + '-' + match[3]
	}
	return cleaned;
}

export function normalizePhoneWithExtension(phone: Phone) {
	const extension =
		phone.extension && phone.extension.length > 0
			? ` Ext: ${phone.extension}`
			: ``;

	return normalizePhone(phone.number || '').concat(extension);
}

export const normalizeZipCode = (value: string) => {
	let zip = '';
	if (value) {
		zip = normalizeNumeric(value);
		if (value.length > 5) {
			zip = value.substr(0, 5);
		}
	}
	return zip;
};

export const normalizeSSN = (value: string) => {
	if (!value || !value.length || value.length < 1) {
		return '';
	}
	const noSpecialChars = value.replace(/[^0-9]/g, '').slice(0, 4);
	return noSpecialChars;
};

export const normalizeYear = (year: string) => {
	// same requirements for year and ssn last 4 (4 digits)
	return normalizeSSN(year);
}

export const normalizeCamelToRegular = (value: string) => {
	if (!value) {
		return '';
	}
	const convertedString = value
		.replace(/([A-Z])/g, ' $1')
		.replace(/^./, function (str) {
			return str.toUpperCase();
		});
	return convertedString;
};

export const normalizeInches = (value: string | number) => {
	const stringValue = value.toString();
	const newValue = stringToNumber(stringValue);
	if (newValue > 11) {
		return 11;
	} else if (newValue < 0) {
		return 0;
	} else {
		return newValue;
	}
};

export const normalizeWeight = (value: string | number) => {
	const stringValue = value.toString();
	const newValue = normalizeNumeric(stringValue);
	if (newValue.length > 3) {
		return newValue?.substring(0, 3);
	}
	return newValue;
};

export const formatFeet = (value: string) => {
	const stringValue = value.toString();
	const newValue = normalizeNumeric(stringValue);
	return newValue + ' ft.';
};

export const formatInches = (value: string) => {
	if (!value) return '';
	const newValue = normalizeNumeric(value);
	return newValue + ' in.';
};

export const setCaretAtTheEnd = (event: any, value: string) => {
	const el = event.target;
	if (el.setSelectionRange) {
		setTimeout(() => {
			el.setSelectionRange(value.length + 10, value.length + 10);
		});
		setTimeout(() => {
			el.setSelectionRange(value.length + 10, value.length + 10);
		}, 100);
		setTimeout(() => {
			el.setSelectionRange(value.length + 10, value.length + 10);
		}, 300);
		setTimeout(() => {
			el.setSelectionRange(value.length + 10, value.length + 10);
		}, 500);
	}
};

type booleanObj = { [key: string]: boolean };
export function objToArray(obj: booleanObj): string[] {
	const array: string[] = [];

	for (const key in obj) {
		if (obj[key]) array.push(key);
	}

	return array;
}

export function arrayToObj(array: string[]): booleanObj {
	const obj: booleanObj = {};

	for (const key of array) {
		obj[key] = true;
	}

	return obj;
}

export const numberWithCommas = (x?: string | number) => {
	if (x == undefined) {
		return x;
	}
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const numberRoundedWithCommas = (x?: string | number) => {
	if (x == undefined) {
		return x;
	}
	return Number(x)
		.toFixed(0)
		.toString()
		.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const normalizeHeight = (heightFeet: string, heightInches: string) => {
	let height = '';
	if (
		(heightFeet === '' && heightInches === '') ||
		(heightFeet === '0' && (heightInches === '0' || heightInches === '00'))
	) {
		return height;
	} else {
		height = heightFeet + "' " + heightInches + '"';
		return height;
	}
};

export type AppliedFilters = { [key: string]: string }[];
export const normalizeFilterStringsArray = (appliedFiltersList: AppliedFilters) => {
	return appliedFiltersList.map((filter, index) => {
		const key = Object.keys(filter)[0];
		return `${normalizeCamelToRegular(key)} - ${normalizeFilterValue(
			key,
			appliedFiltersList[index][key]
		)}`;
	});
}

export const normalizeFilterValue = (key: string, value?: string | string[] | {} | boolean | number) => {
	if (!value || Array.isArray(value)) {
		return value
	}

	key = key.toLowerCase();
	let formattedValue = value;

	if ((typeof (value) === 'object' || typeof (value) === 'string') && key !== 'agent' && filterValueIsDate(key, value)) {
		formattedValue = moment(value).format('MMM Do YY');

	} else if (typeof (value) === 'object') {
		if (key === 'agent') {
			const searchAgent: SearchAgent = value
			return searchAgent.name
		} else {
			formattedValue = ''
		}

	} else if (typeof (value) !== 'string') {
		formattedValue = value

	} else if (value.toLowerCase().indexOf('descending') !== -1) {
		formattedValue = _.startCase(
			value.toLowerCase().replace(/(descending)/g, '▼')
		);

	} else if (value.toLowerCase().indexOf('ascending') !== -1) {
		formattedValue = _.startCase(
			value.toLowerCase().replace(/(ascending)/g, '▲')
		);
	}

	return formattedValue;
};

const filterValueIsDate = (key: string, value: string | {}) => {
	return moment(value).isValid() && key.indexOf('date') !== -1;
};

export const getYesNoFromBool = (bool?: boolean) => {
	return Boolean(bool) ? Strings.YesNoOptionDisplay.Yes : Strings.YesNoOptionDisplay.No;
}

export const getBoolFromYesNo = (string: string) => {
	return string === Strings.YesNoOptionDisplay.Yes ? true : false;
}

export const stringBoolToBool = (stringBool: string) => {
	return stringBool.toLowerCase() === 'true';
}