import { actionCreatorFactory } from 'typescript-fsa';
import { DialogFrame } from '../reducers/dialogs_reducer';

const actionCreator = actionCreatorFactory('Dialogs');

export const QueueDialog = actionCreator<DialogFrame>(
	'QUEUE_DIALOG'
);

export const DismissDialog = actionCreator<string>(
	'DISMISS_DIALOG'
);

export const ConfirmDialog = actionCreator<{}>(
	'CONFIRM_DIALOG'
);

export const ToggleLeadInfoDialog = actionCreator<boolean>(
	'TOGGLE_LEAD_INFO_DIALOG'
);

export const ToggleFactFinderContactLinkDialog = actionCreator<boolean>(
	'TOGGLE_LEAD_SEARCH_DIALOG'
);

export const TogglePrescriptionSearchDialog = actionCreator<boolean>(
	'TOGGLE_PRESCRIPTION_SEARCH_DIALOG'
);

export const ToggleProviderSearchDialog = actionCreator<boolean>(
	'TOGGLE_PROVIDER_SEARCH_DIALOG'
);


export const OpenRiseFormDialog = actionCreator<"providers"|"prescriptions">(
	'OPEN_RISE_FORM_DIALOG'
)