import { ExecuteAdvancedSearch } from '../actions/advanced_search_actions';
import { UpdateLeadRoutesFromAdvancedSearch } from '../actions/lead_routes_actions';
import { put, all } from 'redux-saga/effects';
import { getAdvancedSearchResults } from '../selectors/advanced_search_selectors';
import { selectFromImmutable, takeLatestForActionType } from '../utilities/saga_util';
import { SearchResult } from '../reducers/advanced_search_reducer';
import { Action } from 'typescript-fsa';

function* startUpdateLeadRoutes() {
	yield put(UpdateLeadRoutesFromAdvancedSearch.started({}));
}

function* updateLeadRoutes(action: Action<{}>) {
    const advancedSearchResults: SearchResult[] = yield selectFromImmutable<SearchResult[]>(getAdvancedSearchResults);
    yield put(UpdateLeadRoutesFromAdvancedSearch.done({result: advancedSearchResults, params: {}}));
}

export function* leadRouteSagas() {
	yield all([
		takeLatestForActionType(
			UpdateLeadRoutesFromAdvancedSearch.started,
			updateLeadRoutes
		),
		takeLatestForActionType(
			ExecuteAdvancedSearch.done,
			startUpdateLeadRoutes
		)
	])
}