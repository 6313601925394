import {
	Switch,
} from '@material-ui/core';
import React from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import { themePalette, themeLinks } from '../../utilities/branding';
import { AppState } from '../../reducers';
import { User } from '../../reducers/user_reducer';
import { Strings as S } from '../../assets/common/strings';
import { MoreMenu, createHelpAction } from '../../components/nav/more_menu';
import { UpdateDesktopCallSettings } from '../../actions/user_actions';
import { Dispatch } from '@reduxjs/toolkit';
import { DesktopPadding } from '../../components/Layout/desktop_padding';
import { ToggleCard } from '../../components/higher_order_component/wrap_in_card';
import { SimpleListItem } from '../../components/utility/simple_list_item';


interface StateProps {
	user: User;
	loading: boolean;
}
interface DispatchProps {
	updateUserState: (userState: User) => void;
}
type Props = StateProps & DispatchProps;
const SettingsDesktopCallPage: React.FC<Props> = (props) => {

	const { user, updateUserState, loading } = props;
	const callEnabled = !!user.enableDesktopCalls;
	const textEnabled = !!user.enableDesktopTexts;

	const toggleCall = (enableDesktopCalls: boolean) => {
		updateUserState({ ...user, enableDesktopCalls });
	};

	const toggleText = (enableDesktopTexts: boolean) => {
		updateUserState({ ...user, enableDesktopTexts });
	};

	return (
		<BasePageContainer
			topComponent={
				<HeaderBarComponent
					title="Settings"
					isLoading={loading}
					rightButtons={
						<MoreMenu children={[createHelpAction(themeLinks.helpLinkSettings)]} />
					}
				/>
			}
		>
			<PersistentNotifications />
			<DesktopPadding>
				<ToggleCard title={S.Settings.WebCallSettings} noPadding>
					<SimpleListItem
						title={S.Settings.Calling}
						subtitle={S.Settings.CallingInfo}
						secondaryAction={
							<Switch
								color="primary"
								onChange={(_event, checked) => toggleCall(checked)}
								checked={callEnabled}
							/>
						}
					/>
					<SimpleListItem
						title={S.Settings.Texting}
						subtitle={S.Settings.TextingInfo}
						secondaryAction={
							<Switch
								color="primary"
								onChange={(_event, checked) => toggleText(checked)}
								checked={textEnabled}
							/>
						}
					/>
				</ToggleCard>
			</DesktopPadding>
		</BasePageContainer>
	);
}

const mapStateToProps = (state: AppState): StateProps => ({
	user: state.user,
	loading: state.user.loading
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	updateUserState: (user: User) => dispatch((UpdateDesktopCallSettings.started(user))),
});

export const SettingsDesktopCallContainer = connect(mapStateToProps, mapDispatchToProps, true)
	(SettingsDesktopCallPage);