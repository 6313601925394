import { isType } from 'typescript-fsa';
import { ClearCaches } from '../actions/authentication_actions';
import { UpdateAgentSearchFilter, UpdateAgentSearchAgents, SearchAgentsByTerm, SearchAgentsByTag, GetTags, GetAgentProfile  } from '../actions/agent_search_actions';
import { Agent, initialAgent } from './agent_reducer';
import { Logout } from '../actions/authentication_actions';
import { DownlineAgent } from './product_reducer';
import { AutocompleteValue } from '../components/redux_form_material/autocomplete';

export type SearchAgent = DownlineAgent;

export interface AgentSearchState {
    filter: string;
	agents: SearchAgent[];
	isLoading: boolean;
	tagsLoading: boolean;
	availableTags: AutocompleteValue[];
	selectedAgentProfile: Agent;
}
const initialState: AgentSearchState = {
	filter: '',
	agents: [],
	availableTags: [],
	isLoading: false,
	tagsLoading: false,
	selectedAgentProfile: initialAgent,
};

export const agentSearchReducer = (state = initialState, action: any): AgentSearchState => {
	if (isType(action, UpdateAgentSearchFilter)) {
		return {
			...state,
			filter: action.payload.filter,
		};
    }
    if (isType(action, UpdateAgentSearchAgents)) {
        return {
            ...state,
            agents: action.payload.agents,
        };
    }
	if (isType(action, Logout.started)) {
		return {
			...initialState,
		};
	}
	if (isType(action, ClearCaches)) {
		return {
			...initialState,
		};
	}
	if (isType(action, SearchAgentsByTerm.started)) {
		return {
			...state,
			agents: [],
			isLoading: true
		}
	}
	if (isType(action, SearchAgentsByTerm.done)) {
		return {
			...state,
			agents: action.payload.result,
			isLoading: false
		}
	}
	if (isType(action, SearchAgentsByTerm.failed)) {
		return {
			...state,
			agents: [],
			isLoading: false
		}
	}
	if (isType(action, SearchAgentsByTag.started)) {
		return {
			...state,
			agents: [],
			isLoading: true
		}
	}
	if (isType(action, SearchAgentsByTag.done)) {
		return {
			...state,
			agents: action.payload.result,
			isLoading: false
		}
	}
	if (isType(action, SearchAgentsByTag.failed)) {
		return {
			...state,
			agents: [],
			isLoading: false
		}
	}
	if (isType(action, GetTags.started)) {
		return {
			...state,
			availableTags: [],
			tagsLoading: true
		}
	}
	if (isType(action, GetTags.done)) {
		const tags = action.payload.result;
		const autocompleteOptions: AutocompleteValue[] =  [];
		tags.forEach((tag: string) => {
			const tagSplit = tag.split(':');
			if (tagSplit[1]) {
				autocompleteOptions.push({
					value: tag,
					text: `${tagSplit[1]} - ${tagSplit[0]}`,
				});
			}
		});
		return {
			...state,
			availableTags: autocompleteOptions,
			tagsLoading: false
		}
	}
	if (isType(action, GetTags.failed)) {
		return {
			...state,
			availableTags: [],
			tagsLoading: false
		}
	} else if (isType(action, GetAgentProfile.started)) {
		if(state.selectedAgentProfile != undefined) {
			return {
				...state,
				selectedAgentProfile: initialAgent
			}
		} else {
			return {
				...state
			}
		}
	} else if (isType(action, GetAgentProfile.done)){
		action.payload.result.isLoading = false;
		return {
			...state,
			selectedAgentProfile: action.payload.result
		}
	} else if (isType(action, GetAgentProfile.failed)) {
		return {
			...state,
			selectedAgentProfile: {
				...initialAgent,
				isLoading: false
			}
		}
	} else {
		return state;
	}
};
