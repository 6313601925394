import React from 'react';
import { ListItem, ListItemText, Paper } from '@material-ui/core';
import { useListItemStyles } from '../../utilities/hooks/styles';

interface Props {
	primary: React.ReactNode;
	secondary?: React.ReactNode;
}

export const WidgetDetail: React.FC<Props> = (props) => {
	const { primary, secondary } = props;
	const styles = useListItemStyles();
	return (
		<Paper className={styles.margin}>
			<ListItem
				className={styles.padded}
			>
				<ListItemText
					primary={primary}
					secondary={secondary}
				/>
			</ListItem>
		</Paper>
	);
};
