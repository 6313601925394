import _ from 'lodash';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Divider,
	FormControlLabel,
	Grid,
	Icon,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
	Switch as StandardSwitch,
	TextField as StandardTextField,
	Typography
} from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import { Field, getFormValues, change, touch } from 'redux-form';
import { SaveCancelHeaderBarComponent } from '../../components/Layout/SaveCancelHeaderBar';
import { ValueHolder, SingleSelect } from '../../components/redux_form_material';
import { createRequiredLabel } from '../../components/utility/required_label';
import { themePalette } from '../../utilities/branding';
import { Address, HouseholdAddress, HouseholdRole } from '../../reducers/ContactReducer';
import { US_STATES, stringJoin } from '../../utilities/utilities';
import { Strings as S } from '../../assets/common/strings';
import { enforceStylesType } from '../../utilities/styles_util';
import { AppState } from '../../reducers';
import { SetApplyAddressToHousehold } from '../../actions/contact_actions';
import uuid from 'uuid';
import { SimpleListItem } from '../../components/utility/simple_list_item';
import { createMenuAction, MoreMenu } from '../../components/nav/more_menu';
import { validateAddressCity, validateAddressLine, validateAddressZip } from '../../utilities/form_validation';

export type AddressFieldsData = {
	addresses: Address[];
	address?: string;
	householdRole: number;
};

interface ComponentProps {
	formName: string;
	primaryAddress?: Address;
	addressType?: string;
	upsertAddress?: (addresses: Address[]) => void;
	maxAddressCount?: number;
	isNewContact?: boolean;
	isEmployee?: boolean;
}

interface StateProps {
	formValues: AddressFieldsData;
	householdAddresses: HouseholdAddress;
}
interface DispatchProps {
	changeFieldValue(field: string, value: any): void;
	touchField(field: string): void;
	applyAddressToHousehold: (type: string, address: Address | undefined) => void;
}

type Props = ComponentProps & StateProps & DispatchProps;
interface State {
	showInput: boolean;
	showEditDialog: boolean;
	showApplyToHouseholdDialog: boolean;
	itemToEdit: number;
	editAddressLine1: string;
	editAddressLine2: string;
	editAddressCity: string;
	editAddressState: string;
	editAddressZip: string;
	editAddressType: string;
	editAddressIsPreferred: boolean;
	editAddressRPM_FieldsChanged: boolean;
	editAddressCounty: string;
	dialogErrors: any;
	addressIndex?: number;
	addingAddress: boolean;
	applyToHousehold: boolean;
	formHasChanged: boolean;
}

export class _AddressFields extends React.Component<Props, State> {
	defaultAddress: any = {};
	constructor(props: Props & StateProps) {
		super(props);

		if (props.primaryAddress) {
			this.defaultAddress = {
				editAddressLine1: props.primaryAddress.line1,
				editAddressLine2: props.primaryAddress.line2,
				editAddressCity: props.primaryAddress.city,
				editAddressState: props.primaryAddress.state,
				editAddressZip: props.primaryAddress.zipCode,
				editAddressType: props.primaryAddress.type,
			};
			props.changeFieldValue(S.ContactInfoTypes.Address, this.defaultAddress.editAddressLine1);
		}

		this.state = {
			showInput: true,
			showEditDialog: false,
			showApplyToHouseholdDialog: false,
			dialogErrors: {},
			itemToEdit: 0,
			editAddressLine1: '',
			editAddressLine2: '',
			editAddressCity: '',
			editAddressState: '',
			editAddressZip: '',
			editAddressType: '',
			editAddressCounty: '',
			editAddressIsPreferred: false,
			editAddressRPM_FieldsChanged: false,
			addingAddress: false,
			applyToHousehold: false,
			formHasChanged: false,
			...this.defaultAddress,
		};
	}

	onClickAdd = () => {
		const addresses = (this.props.formValues.addresses || []).slice();
		const type = this.props.addressType
			? this.props.addressType
			: S.ContactInfoTypes.Home
		this.setState(prevState => {
			return {
				...this.defaultAddress,
				editAddressLine1: '',
				editAddressLine2: '',
				editAddressIsPreferred: false,
				editAddressType: type,
				editAddressCity: '',
				editAddressState: '',
				editAddressZip: '',
				editAddressCounty: '',
				showEditDialog: true,
				itemToEdit: addresses.length,
				showInput: false,
				dialogErrors: {
					...prevState.dialogErrors,
				},
				addingAddress: true,
			};
		});
		this.setState({ addressIndex: undefined });
		this.props.touchField(S.ContactInfoTypes.Phones);
	};

	clearDialogForm = () => {
		this.setState({
			editAddressCity: '',
			editAddressIsPreferred: false,
			editAddressLine1: '',
			editAddressLine2: '',
			editAddressState: '',
			editAddressType: S.ContactInfoTypes.Home,
			editAddressZip: '',
			editAddressCounty: '',
			addingAddress: false,
			applyToHousehold: false,
			...this.defaultAddress,
		});
	};

	onClickEdit = () => {
		if (this.state.addressIndex !== undefined) {
			const index = this.state.addressIndex;
			const addressToEdit = this.props.formValues.addresses[index];
			this.setState(prevState => {
				return {
					showEditDialog: true,
					itemToEdit: index,
					editAddressLine1: addressToEdit.line1,
					editAddressLine2: addressToEdit.line2,
					editAddressCity: addressToEdit.city,
					editAddressState: addressToEdit.state,
					editAddressZip: addressToEdit.zipCode,
					editAddressType: addressToEdit.type,
					editAddressIsPreferred: addressToEdit.isPreferred,
					editAddressCounty: addressToEdit.county,
					editAddressRPM_FieldsChanged:
						addressToEdit.wasRpmAddressChanged || false,
					addingAddress: false,
					dialogErrors: {
						...prevState.dialogErrors,
						line1: addressToEdit.line1 ? undefined : S.ContactInfoTypes.Required,
						city: addressToEdit.city ? undefined : S.ContactInfoTypes.Required,
						state: addressToEdit.state ? undefined : S.ContactInfoTypes.Required,
						zip: addressToEdit.zipCode ? undefined : S.ContactInfoTypes.Required,
					},
					addressIndex: undefined,
				};
			});
		}
	};

	onClickDelete = () => {
		if (this.state.addressIndex !== undefined) {
			const index = this.state.addressIndex;
			const addresses = this.props.formValues.addresses.slice();
			addresses[index].isDeleted = true;
			if (addresses.length > 1 && !addresses.some((address, indexNum) => address.isPreferred && indexNum !== index)) {
				if (index === 0) addresses[1].isPreferred = true;
				else addresses[0].isPreferred = true;
			}
			this.props.upsertAddress && this.props.upsertAddress(addresses.slice());
			addresses.splice(index, 1);
			this.props.changeFieldValue(S.ContactInfoTypes.Addresses, addresses);
			this.props.touchField(S.ContactInfoTypes.Addresses);
			this.setState({ addressIndex: undefined });
		}
	};

	onClickSetPreferred = () => {
		if (this.state.addressIndex !== undefined) {
			const index = this.state.addressIndex;
			const currentPreferredIndex = this.props.formValues.addresses.findIndex(
				address => address.isPreferred
			);
			const addresses = this.props.formValues.addresses.slice();
			if (currentPreferredIndex > -1)
				addresses[currentPreferredIndex].isPreferred = false;
			addresses[index].isPreferred = true;
			this.props.upsertAddress && this.props.upsertAddress(addresses);
			this.props.changeFieldValue(S.ContactInfoTypes.Addresses, addresses);
			this.props.touchField(S.ContactInfoTypes.Addresses);
			this.setState({ addressIndex: undefined });
		}
	};

	onEditAddress = () => {
		const { formValues, isNewContact } = this.props;
		const addresses = (formValues.addresses || []).slice();
		const updatedAddress = {
			...addresses[this.state.itemToEdit],
			type: this.state.editAddressType,
			line1: this.state.editAddressLine1,
			line2: this.state.editAddressLine2,
			city: this.state.editAddressCity,
			state: this.state.editAddressState,
			zipCode: this.state.editAddressZip,
			county: this.state.editAddressCounty,
			country: '',
			isPreferred: this.state.editAddressIsPreferred,
			wasRpmAddressChanged: this.state.editAddressRPM_FieldsChanged,
			dateCreated: moment().toDate(),
		};

		if (isNewContact && formValues.householdRole == HouseholdRole.Secondary || formValues.householdRole == HouseholdRole.Dependent) {
			updatedAddress.id = '';
		}
		if (updatedAddress.isPreferred) {
			addresses.forEach(addr => (addr.isPreferred = false));
		}
		addresses[this.state.itemToEdit] = updatedAddress;
		if (!addresses.some(addr => addr.isPreferred)) {
			addresses[0].isPreferred = true;
		}

		if (this.state.applyToHousehold) {
			if (this.state.addingAddress) {
				updatedAddress.id = uuid.v4();
			}
			this.props.applyAddressToHousehold(this.state.editAddressType, updatedAddress);
		}

		this.props.changeFieldValue(S.ContactInfoTypes.Addresses, addresses);
		this.props.touchField(S.ContactInfoTypes.Addresses);
		this.props.upsertAddress && this.props.upsertAddress(addresses);
		this.clearDialogForm();
		this.props.changeFieldValue(S.ContactInfoTypes.Address, '');
	};

	onUpdateAddressLine1 = (event: any) => {
		if (event.target.value === '') {
			this.setState({
				editAddressLine1: event.target.value,
				dialogErrors: {
					...this.state.dialogErrors,
					line1: S.ContactInfoTypes.Required,
				},
				formHasChanged: true,
			});
		} else {
			this.setState({
				editAddressLine1: event.target.value,
				dialogErrors: {
					...this.state.dialogErrors,
					line1: validateAddressLine(event.target.value),
				},
				editAddressRPM_FieldsChanged: true,
				formHasChanged: true,
			});
		}
	};

	onUpdateAddressLine2 = (event: any) => {
		this.setState({
			editAddressLine2: event.target.value,
			dialogErrors: {
				...this.state.dialogErrors,
				line2: validateAddressLine(event.target.value),
			},
			editAddressRPM_FieldsChanged: true,
			formHasChanged: true,
		});
	};

	onUpdateAddressType = (event: any) => {
		this.setState({
			editAddressType: event.target.value,
			formHasChanged: true,
		});
	};

	onUpdateAddressCity = (event: any) => {
		if (event.target.value === '') {
			this.setState({
				editAddressCity: event.target.value,
				dialogErrors: {
					...this.state.dialogErrors,
					city: S.ContactInfoTypes.Required,
				},
				editAddressRPM_FieldsChanged: true,
				formHasChanged: true,
			});
		} else {
			this.setState({
				editAddressCity: event.target.value,
				dialogErrors: {
					...this.state.dialogErrors,
					city: validateAddressCity(event.target.value),
				},
				editAddressRPM_FieldsChanged: true,
				formHasChanged: true,
			});
		}
	};

	onUpdateAddressState = (value: any) => {
		const matched = value.match(/^[A-Z]{2}$/i);
		if (matched) {
			this.setState({
				editAddressState: value,
				dialogErrors: {
					...this.state.dialogErrors,
					state: value ? undefined : S.ContactInfoTypes.Required,
				},
				editAddressRPM_FieldsChanged: true,
				formHasChanged: true,
			});
		}
		else {
			this.setState({ editAddressState: '', formHasChanged: true })
		};
	};

	onUpdateAddressZip = (event: any) => {
		if (event.target.value === '') {
			this.setState({
				editAddressZip: event.target.value,
				dialogErrors: {
					...this.state.dialogErrors,
					zip: S.ContactInfoTypes.Required,
				},
				formHasChanged: true,
			});
		} else {
			this.setState({
				editAddressZip: event.target.value,
				dialogErrors: {
					...this.state.dialogErrors,
					zip: validateAddressZip(event.target.value),
				},
				editAddressRPM_FieldsChanged: true,
				formHasChanged: true,
			});
		}
	};

	onUpdateAddressPreferred = (event: any) => {
		this.setState({
			editAddressIsPreferred: !this.state.editAddressIsPreferred,
			formHasChanged: true,
		});
	};

	onUpdateAddressCounty = (event: any) => {
		const countyVal = event.target.value;
		
		this.setState({
			editAddressCounty: countyVal,
			dialogErrors: {
				...this.state.dialogErrors,
				county: validateAddressCity(countyVal),
			},
			editAddressRPM_FieldsChanged: true,
			formHasChanged: true,
		});
	};

	showApplyToHousehold = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		if (!checked) {
			// if the user turns it off, we need to remove the address from the object in redux
			this.props.applyAddressToHousehold(this.state.editAddressType, undefined);
		}
		this.setState({
			applyToHousehold: checked,
			showApplyToHouseholdDialog: checked
		});
	};

	validateDialogForm = () => {
		if (
			this.state.editAddressLine1 == '' ||
			this.state.editAddressCity == '' ||
			this.state.editAddressState == '' ||
			this.state.editAddressZip == '' ||
			!this.state.formHasChanged
		) {
			return true;
		}
		return _.some(this.state.dialogErrors, value => value);
	};

	addressHeader = () => {
		const { addressType = '', maxAddressCount = 99, formValues } = this.props;
		const addressCount = formValues && formValues.addresses ? formValues.addresses.length : 0;
		return (
			<Grid container>
				<Grid item xs={9} sm={10}>
					<Typography variant="subtitle1" style={{ padding: 16 }}>
						{addressType ? addressType + ' Address Info:' : 'Address Info:'}
					</Typography>
				</Grid>
				<Grid item xs={3} sm={2} style={{ textAlign: S.CSS.Center }}>
					{addressCount < maxAddressCount && <div>
						<IconButton onClick={this.onClickAdd}>
							<Icon>add_circle_outline</Icon>
						</IconButton>
					</div>}
				</Grid>
			</Grid>
		);
	};

	setIndex = (addressIndex: number, callbackFn: () => void) => () => {
		this.setState({ addressIndex }, callbackFn);
	};

	listAddresses = () => {
		const { addresses=[] } = this.props.formValues;
		return addresses.map((address, index) => {
			let secondaryText = '';
			const iconStyle: React.CSSProperties = {};
			if (address.isPreferred) {
				iconStyle.color = themePalette.address_fields_text;
				secondaryText = `${S.ContactInfoTypes.Preferred}, ${address.type}`;
			}
			const addressStr = stringJoin([
				address.line1,
				address.line2,
				address.city,
				address.state
			]);
			const countyStr = address.county ? '(' + address.county + ')' : '';

			return (
				<SimpleListItem
					key={address.line1}
					icon={
						<ListItemIcon style={iconStyle}>
							<Icon>home</Icon>
						</ListItemIcon>
					}
					title={`${addressStr} ${address.zipCode} ${countyStr}`}
					subtitle={secondaryText}
					secondaryAction={
						<MoreMenu color={themePalette.tertiary_text} children={[
							createMenuAction('Mark as Preferred', this.setIndex(index, this.onClickSetPreferred)),
							createMenuAction('Edit', this.setIndex(index, this.onClickEdit)),
							createMenuAction('Delete', this.setIndex(index, this.onClickDelete))
						]}/>
					}
				/>
			);
		});
	};

	editDialog = () => {
		const { formValues, addressType, householdAddresses } = this.props;
		const addressTitle = addressType ? addressType + ' ' + S.Address.Address : S.Address.Address;
		const addresses = _.get(this.props, 'formValues.addresses', []);
		const { householdRole } = formValues;
		if (addresses != []) {
			return (
				<>
					<Dialog fullScreen open={this.state.showEditDialog}>
						<SaveCancelHeaderBarComponent
							title={this.state.addingAddress
								? S.Address.Add + ' ' + addressTitle
								: S.Address.Edit + ' ' + addressTitle}
							onSave={() => {
								this.onEditAddress();
								this.setState({ showEditDialog: false });
							}}
							onCancel={() => {
								this.setState({ showEditDialog: false });
								this.clearDialogForm();
							}}
							saveText="Save"
							isSaveDisabled={this.validateDialogForm()}
						/>
						<Grid container spacing={2} style={{ padding: 16 }}>
							<Grid item xs={12}>
								<StandardTextField
									label={createRequiredLabel(S.Address.Line1)}
									value={this.state.editAddressLine1}
									onChange={this.onUpdateAddressLine1}
									InputLabelProps={{ disableAnimation: true, shrink: true }}
									error={!!this.state.dialogErrors.line1}
									helperText={this.state.dialogErrors.line1}
									fullWidth
									autoComplete='address-line1'
								/>
							</Grid>
							<Grid item xs={12}>
								<StandardTextField
									label={S.Address.Line2}
									value={this.state.editAddressLine2}
									onChange={this.onUpdateAddressLine2}
									InputLabelProps={{ disableAnimation: true, shrink: true }}
									error={!!this.state.dialogErrors.line2}
									helperText={this.state.dialogErrors.line2}
									fullWidth
									autoComplete='address-line2'
								/>
							</Grid>
							<Grid item xs={12}>
								<StandardTextField
									label={createRequiredLabel(S.Address.City)}
									value={this.state.editAddressCity}
									onChange={this.onUpdateAddressCity}
									InputLabelProps={{ disableAnimation: true, shrink: true }}
									error={!!this.state.dialogErrors.city}
									helperText={this.state.dialogErrors.city}
									fullWidth
								/>
							</Grid>
							<Grid item xs={12}>
								<StandardTextField
									label={S.Address.County}
									value={this.state.editAddressCounty}
									onChange={this.onUpdateAddressCounty}
									InputLabelProps={{ disableAnimation: true, shrink: true }}
									error={!!this.state.dialogErrors.county}
									helperText={this.state.dialogErrors.county}
									fullWidth
								/>
							</Grid>
							<Grid item xs={12}>
								<SingleSelect
									inputLabelProps={{ shrink: true }}
									label={createRequiredLabel(S.Address.State)}
									resultSet={US_STATES.map((state) => state.abbreviation)}
									input={{
										value: this.state.editAddressState || '',
										onChange: this.onUpdateAddressState
									}}
									initialValue={this.state.editAddressState || ''}
									fullWidth
									//https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill-detail-tokens
									autoComplete='not-a-real-auto-complete-keyword'
								/>
							</Grid>
							<Grid item xs={12}>
								<StandardTextField
									label={createRequiredLabel(S.Address.ZipCode)}
									value={this.state.editAddressZip}
									onChange={this.onUpdateAddressZip}
									InputLabelProps={{ disableAnimation: true, shrink: true }}
									error={!!this.state.dialogErrors.zip}
									helperText={this.state.dialogErrors.zip}
									fullWidth
									autoComplete='postal-code'
								/>
							</Grid>
							<Grid item xs={6}>
								<FormControlLabel
									control={
										<StandardSwitch
											checked={this.state.editAddressIsPreferred}
											onChange={this.onUpdateAddressPreferred}
											color={S.Theming.Primary}
										/>
									}
									style={{ marginLeft: 5, marginTop: 7 }}
									label={S.ContactInfoTypes.Preferred}
								/>
							</Grid>
							<Grid item xs={6} style={{ paddingRight: '20px' }}>
								<StandardTextField
									fullWidth
									select
									label="Type"
									value={this.props.addressType
										? this.props.addressType
										: (this.state.editAddressType || S.ContactInfoTypes.Home)}
									onChange={this.onUpdateAddressType}
									disabled={Boolean(this.props.addressType)}
								>
									<MenuItem value={S.ContactInfoTypes.Home}>Home</MenuItem>
									<MenuItem value={S.ContactInfoTypes.Business}>Work</MenuItem>
									<MenuItem value={S.ContactInfoTypes.Mailing}>Mailing</MenuItem>
									{this.props.addressType &&
										<MenuItem value={this.props.addressType}>{this.props.addressType}</MenuItem>}
								</StandardTextField>
							</Grid>
							{householdRole === HouseholdRole.Primary && !Boolean(this.props.isEmployee) && <Grid item xs={6}>
								<FormControlLabel
									control={
										<StandardSwitch
											checked={
												this.state.applyToHousehold || (
													Boolean(householdAddresses[this.state.editAddressType]?.id) &&
													householdAddresses[this.state.editAddressType]?.id  ===
														addresses[(this.state.itemToEdit)]?.id
												)
											}
											onChange={this.showApplyToHousehold}
											color={S.Theming.Primary}
										/>
									}
									style={{ marginLeft: 5, marginTop: 7 }}
									label={S.ContactInfoTypes.ApplyToHousehold}
								/>
							</Grid>}
						</Grid>
					</Dialog>
					<Dialog open={this.state.showApplyToHouseholdDialog}>
						<DialogContent>
							<DialogContentText>
								{`This ${this.state.editAddressType} address will apply to all household members' addresses.`}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								color="secondary"
								onClick={() => this.setState({
									applyToHousehold: false,
									showApplyToHouseholdDialog: false
								})}
							>
								Cancel
							</Button>
							<Button
								style={{ backgroundColor: themePalette.delete_remove_reject_button, color: themePalette.negative_text }}
								onClick={() => this.setState({ showApplyToHouseholdDialog: false, formHasChanged: true })}
							>
								Apply
							</Button>
						</DialogActions>
					</Dialog>
				</>
			);
		}
	};

	render() {
		return (
			<div>
				{this.addressHeader()}
				{this.listAddresses()}
				<Field name={S.ContactInfoTypes.Addresses} component={ValueHolder} />
				{this.editDialog()}
			</div>
		);
	}
}

function mapStateToProps(state: AppState, ownProps: Props): StateProps {
	return {
		formValues: getFormValues(ownProps.formName)(state),
		householdAddresses: state.contact.householdAddresses,
	};
}

function mapDispatchToProps(
	dispatch: any,
	ownProps: Props
): DispatchProps {
	return {
		changeFieldValue: (field: string, value: any) => {
			dispatch(change(ownProps.formName, field, value));
		},
		touchField: (field: string) => {
			dispatch(touch(ownProps.formName, field));
		},
		applyAddressToHousehold: (type: string, address: Address | undefined) =>
			dispatch(SetApplyAddressToHousehold({ [type]: address }))
	};
}

export const AddressFields = connect(mapStateToProps, mapDispatchToProps, true)(
	_AddressFields
) as React.ComponentClass<ComponentProps>;
