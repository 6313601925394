import {actionCreatorFactory} from 'typescript-fsa';
import { Contact, ContactDto, HouseholdAddress } from '../reducers/ContactReducer';

const actionCreator = actionCreatorFactory('Contact');

// FETCH CONTACT
export const GetContact = actionCreator.async<string, ContactDto>('GET_CONTACT');

// UPDATE CONTACT
export const UpdateContact = actionCreator.async<Contact, Contact>(
	'UPDATE_CONTACT'
);

// CREATE CONTACT
export const CreateContact = actionCreator.async<Contact, Contact>(
	'CREATE_CONTACT'
);

// DELETE CONTACT
export const DeleteContact = actionCreator.async<Contact, string>(
	'DELETE_CONTACT'
);

// SEARCH CONTACTS
export interface ContactLiveSearchParams {
	searchText: string;
	includeEmployees: boolean;
	pageRequested: number;
	pageSize: number;
}
export const ContactLiveSearch = actionCreator.async<
	ContactLiveSearchParams,
	Contact[]
>('CONTACT_LIVE_SEARCH');

// ADD CONTACT TO EMPLOYER
export const AddContactToEmployer = actionCreator.async<Contact, Contact>(
	'ADD_CONTACT_TO_EMPLOYER'
);

export const SetApplyAddressToHousehold = actionCreator<HouseholdAddress>('APPLY_ADDRESS_TO_HOUSEHOLD');
export const ResetApplyAddressToHousehold = actionCreator('RESET_APPLY_ADDRESS_TO_HOUSEHOLD');
