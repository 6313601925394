import { Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, Grow } from '@material-ui/core';
import React from 'react';

interface Props extends DialogProps {
    description?: React.ReactNode;
    actions?: ButtonProps[];
}
export const SimpleDialog: React.FC<Props> = (props) => {
    const { open, title, description, actions, children, ...rest } = props;
    return (
        <Dialog open={open} TransitionComponent={Grow} {...rest}>
            {title && <DialogTitle children={title} />}
            <DialogContent>
                <DialogContentText>
                    {description}
                    {children}
                </DialogContentText>
            </DialogContent>
            {actions && (
                <DialogActions children={actions.map(buttonProps => (
                    <Button {...buttonProps}/>
                ))} 
                />
            )}
        </Dialog>
    );
};
