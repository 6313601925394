import React from 'react';
import { shallowEqual } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    Collapse, Icon, LinearProgress, List, ListItem, ListItemText
} from '@material-ui/core';
import { FormSlice, useSelector } from '@optum-uhone-hmkts/rise';
import { dedupeList } from './';
import { Strings } from '../../../../../assets/common/strings';
import { cleanStringForRegex } from '../../../../../utilities/utilities';

interface Props {
    hasSearched: boolean;
    isLoading: boolean;
    drugName: string;
    handleSelect: (drug: FormSlice.PrescriptionLookupResponse) => void;
}

export const PrescriptionDropdown: React.FC<Props> = props => {
    const { hasSearched, isLoading, drugName, handleSelect } = props;
    const classes = useStyles();

    const searchResults = useSelector((state: any) => state?.FormBuilder?.activeForm?.prescriptionInfo?.searchResults || [], shallowEqual);

    const dedupedSearchResults = dedupeList(searchResults, 'name');
    const matchingResults = dedupedSearchResults.filter(result => {
        const regex = RegExp(`^${cleanStringForRegex(drugName)}`);
        const resultName = result.name.toLowerCase();
        const match = regex.test(resultName);
        return match;
    });

    const titleText = isLoading
        ? Strings.FactFinderPrescriptionLookup.searchingForDrugs
        : `${matchingResults.length} results found`;

    return (
        <Collapse in={hasSearched && drugName.length > 1}>
            <LinearProgress hidden={!isLoading} />
            <List className={classes.list} style={{paddingTop: 0, paddingBottom: 0}}>
                <ListItem
                    key={'list-title'}
                    className={classes.title}
                >
                    <ListItemText secondary={titleText} />
                </ListItem>
                {matchingResults.map(drug => (
                        <ListItem
                            key={drug.rxcui}
                            button={true}
                            onClick={() => handleSelect(drug)}
                            className={classes.item}
                        >
                            <ListItemText primary={drug.name} />
                            <Icon>navigate_next</Icon>
                        </ListItem>
                    ))}
            </List>
        </Collapse>)
};

const useStyles = makeStyles({
    list: {
        maxHeight: 400 ,
        overflow: 'auto' ,
        borderWidth: '1px 1px 0px 1px' ,
        borderStyle: 'solid' ,
        borderColor: '#dcdedf' ,
    },
    title: {
        paddingTop: 0,
        paddingBottom: 0,
        fontStyle: 'italic' ,
        borderBottom: '1px solid #dcdedf',
    },
    item: {
        borderBottom: '1px solid #dcdedf',
        wordBreak: 'break-all',
    },
});
