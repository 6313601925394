import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    List, ListItem, ListItemText
} from '@material-ui/core';
import { Provider, ProviderSelectListItem } from './';
import { Strings } from '../../../../../assets/common/strings';

interface Props {
    addedProviders: Provider[];
    deleteProvider: (provider: Provider) => void;
    togglePCP: (provider: Provider) => void;
    readOnly?: boolean;
}

export const ProviderSelectedList: React.FC<Props> = ({ addedProviders, deleteProvider, togglePCP, readOnly }) => {
    const classes = useStyles();

    return (
        <List className={classes.list} style={{paddingTop: 0, paddingBottom: 0}}>
            <ListItem
                key={'list-title'}
                className={classes.title}
            >
                <ListItemText secondary={Strings.FactFinderProviderLookup.selectedDoctors} />
            </ListItem>
            {addedProviders.map(result => (
                <ProviderSelectListItem
                    key={result.provider.npi}
                    provider={result}
                    handleDeleteProvider={deleteProvider}
                    handleTogglePCP={togglePCP}
                    readOnly={readOnly}
                />))}
        </List>)
};

const useStyles = makeStyles({
    list: {
        maxHeight: 400 ,
        overflow: 'auto' ,
        borderWidth: '1px 1px 0px 1px' ,
        borderStyle: 'solid' ,
        borderColor: '#dcdedf' ,
    },
    title: {
        paddingTop: 0,
        paddingBottom: 0,
        fontStyle: 'italic' ,
        borderBottom: '1px solid #dcdedf',
    },
});
