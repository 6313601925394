import React from 'react';
import { Grid, GridProps } from '@material-ui/core';

interface GridWrapperProps extends GridProps {
    fullWidth?: boolean;
    hide?: boolean
    centerContent?: boolean;
}

export const GridItem: React.FC<GridWrapperProps> = (props) =>
    props.hide
        ? null
        : <Grid
            md={props.md || (props.fullWidth && 12) || 6}
            xs={props.sm || 12}
            zeroMinWidth
            item
            {...props}
        >
            {props.children}
        </Grid>



export const GridContainer: React.FC<GridWrapperProps> = (props) =>
    props.hide
        ? null
        : <Grid
            alignContent={props.centerContent ? "center" : undefined}
            spacing={props.spacing || 2}
            zeroMinWidth
            container
            wrap="wrap"
            {...props}
        >
            {props.children}
        </Grid>
