import { ResetInfiniteScroll } from './../actions/infinite_scroll_actions';
import { isType } from 'typescript-fsa';
import {
	SelectAdvancedSearchResult,
	SelectAllAdvancedSearchResults,
	StoreAdvancedSearchFilters,
	StoreSelectedContactIds,
	ExecuteAdvancedSearch,
	getSavedCorporateAdvSearchFilters,
	getSavedUserAdvSearchFilters,
	upsertAdvancedSearchFilters,
	deleteSavedAdvancedSearchFilters,
	selectSavedSearchName,
	setIsBLeadSearch,
	SetAdvSearchSelectAllFlag,
	SaveAdvSearchFormFilters,
	ExpandFilterFields,
	exportAdvancedSearchResults,
} from '../actions/advanced_search_actions';
import { Action as ReduxAction } from 'redux';
import BaseDTO from '../utilities/base_dto';
import { ClearCaches } from '../actions/authentication_actions';
import { BulkReassign } from '../actions/lead_actions';
import moment, { Moment } from 'moment';
import { Logout } from '../actions/authentication_actions';
import { getHouseholdTags, MergeHouseholds } from '../actions/household_actions';
import { getEmployerTags } from '../actions/employer_actions';

export interface SearchResult {
	ContactId: string;
	HouseholdId: string | null;
	EmployerId: string | null;
	FirstName: string;
	MiddleName: string;
	LastName: string;
	City: string;
	State: string;
	ZipCode: string;
	HouseholdRole: string;
	ContactedStatus: string;
	Products: string[];
	ProductLinesOfBusiness: string[];
	OwningAgent: string;
	IsSelected: boolean;
	UserId: string;
}

export interface SearchResultObject {
	CurrentPage: number;
	Items: SearchResult[];
	PageSize: number;
	TotalCount: number;
	ProcessId: string;
}

export type SearchFilterFormValues = SearchConfigurationFilters &
	ProductSearchFilters &
	PersonDemographicFilters &
	LeadSearchFilters &
	EmployerFilters &
	SubagentFilters &
	AgentFilters;

export interface SearchConfigurationFilters {
	openSearch?: string;
	sortBy?: string;

	//////// Pre-Defined Searches
	consumerType?: string;
	searchAcrossPrimaryOnly?: string;
	showPrimaryResultsOnly?: string;
}

export interface ProductSearchFilters {
	// Product Filters
	//////// Product Type
	consumerHas?: string;
	productTypesConsumerHas?: string;
	productTypesConsumerDoesNotHave?: string;
	//////// Product Info
	appStatus?: string;
	LOB?: string[];
	//////// Benefit Amounts
	benefitAmountMin?: number;
	benefitAmountMax?: number;
	faceAmountMin?: number;
	faceAmountMax?: number;
	familyDeductibleMin?: number;
	familyDeductibleMax?: number;
	familyMaxOutOfPocketMin?: number;
	familyMaxOutOfPocketMax?: number;
	individualDeductibleMin?: number;
	individualDeductibleMax?: number;
	individualMaxOutOfPocketMin?: number;
	individualMaxOutOfPocketMax?: number;
	perPersonDeductibleMin?: number;
	perPersonDeductibleMax?: number;
	perPersonMaxOutOfPocketMin?: number;
	perPersonMaxOutOfPocketMax?: number;
	//////// Advanced
	product?: string;
	metalLevel?: string;
	applicationDateFrom?: Date;
	applicationDateTo?: Date;
	issueDateFrom?: Date;
	issueDateTo?: Date;
	effectiveDateFrom?: Date;
	effectiveDateTo?: Date;
	carrierStatus?: string;
	statusReason?: string;
	monthlyPremiumMin?: number;
	monthlyPremiumMax?: number;
	monthlySubsidyMin?: number;
	monthlySubsidyMax?: number;
	monthlyNetCostMin?: number;
	monthlyNetCostMax?: number;
	numberOfApplicantsMin?: number;
	numberOfApplicantsMax?: number;
	policyEndDateFrom?: Date;
	policyEndDateTo?: Date;
	policyNumber?: number;
	ffmAppId?: string;
	appQuoteId?: string;
}

export interface PersonDemographicFilters {
	//////// Common
	ageMin?: number;
	ageMax?: number;
	familyStatus?: string;
	selfEmployed?: string;
	numFamilyMembersMin?: number;
	numFamilyMembersMax?: number;
	householdIncomeMin?: number;
	householdIncomeMax?: number;
	//////// Advanced
	phone?: string;
	email?: string;
	fullName?: string;
	monthMin?: number;
	monthMax?: number;
	dayMin?: number;
	dayMax?: number;
	tobaccoUser?: string;
	gender?: string;
	clientType?: string;
	lastUnemploymentYearMin?: string;
	lastUnemploymentYearMax?: string;
	//////// Geographics
	geoStates?: string[];
	geoCities?: string[];
	geoCounties?: string[];
	geoZipsWithRadius?: string[];

	geoZipCode?: string; // Front-end only value
	geoRadius?: string; // Front-end only value
}

export interface LeadSearchFilters {
	//////// Common
	leadLOB?: string;
	leadCreatedDateSearch?: string;
	leadCreatedDateFrom?: Moment;
	leadCreatedDateTo?: Moment;
	leadContactedDateSearch?: string;
	leadContactedDateFrom?: Moment;
	leadContactedDateTo?: Moment;
	leadStatusCode?: string[];
	leadStatusReason?: string[];
	callable?: string;
	callAttempts?: string;

	//////// Advanced
	leadType?: string;
	leadVendor?: string;
	leadCampaign?: string;
	leadFund?: string;
	leadLMSID?: string;
	leadQuoteID?: string;
	leadCostMin?: number;
	leadCostMax?: number;
	openToDoInNextDaysSearch?: string;
	openTodoInNextDaysFrom?: Moment;
	openTodoInNextDaysTo?: Moment;
	leadManuallyEntered?: string;
	leadInputSource?: string[];

	leadTags?: string[];
}

export interface EmployerFilters {
	//////// Common
	companyName?: string;
	numEmployeesMin?: number;
	numEmployeesMax?: number;
	employerIndustry?: string;
	//////// Advanced
	noOfGroupEligibleMin?: number;
	noOfGroupEligibleMax?: number;
	annualRevenueMin?: number;
	annualRevenueMax?: number;
	renewalDateFrom?: Date;
	renewalDateTo?: Date;
	ancillariesOffered?: string;
	employerContributionsToGroupCoverage?: string;
	employerContributionAmountMin?: number;
	employerContributionAmountMax?: number;

	employerTags?: string[];
}

export interface SubagentFilters {
	subagent?: SearchAgent;
}

export interface AgentFilters {
	//////// Common
	agent?: SearchAgent;
	agentStatus?: string;
	searchBy?: string;
	agency?: string;
	channel?: string;
}

export interface SearchAgent {
	id?: string;
	name?: string;
}

export interface AdvSearchFilterPresetDto extends BaseDTO {
	name: string;
	filters: SearchFilterFormValues;
}

export interface AdvancedSearchState {
	results: SearchResult[];
	currentFormFilters: SearchFilterFormValues;
	appliedSearchFilters: SearchFilterFormValues;
	selectedSavedSearchName: string;
	corporateFilters: AdvSearchFilterPresetDto[];
	userSavedFilters: AdvSearchFilterPresetDto[];
	bulkEmailContacts: string[];
	resultsLoading: boolean;
	exportRequestLoading: boolean;
	numSelected: number;
	pageIndex: number;
	pageSize: number;
	hasMoreResults: boolean;
	totalResultCount: number;
	isSelectAll: boolean;
	isBLeadSearch: boolean;
	expandedStates: any;
	error: any;
	processId?: string;
	leadTagFilterResult: string[];
	employerTagFilterResult: string[];
}

export interface BulkReassignRequestEntity {
	id: string;
	type: string;
}

export interface BulkReassignRequestDto {
	criteria?: SearchFilterFormValues;
	entities: BulkReassignRequestEntity[];
	newAgentCode: string;
}

export const BULK_REASSIGN_BLEAD_TYPE: string = 'BLead';
export const BULK_REASSIGN_EMPLOYER_TYPE: string = 'Employer';
export const BULK_REASSIGN_HOUSEHOLD_TYPE: string = 'Household';

export const DEFAULT_UNSELECTED: string = 'Unselected';

const initialState: AdvancedSearchState = {
	results: [],
	currentFormFilters: {},
	appliedSearchFilters: {},
	selectedSavedSearchName: DEFAULT_UNSELECTED,
	corporateFilters: [],
	userSavedFilters: [],
	bulkEmailContacts: [],
	resultsLoading: false,
	exportRequestLoading: false,
	numSelected: 0,
	pageIndex: 0,
	pageSize: 20,
	hasMoreResults: false,
	totalResultCount: 0,
	isSelectAll: false,
	isBLeadSearch: false,
	expandedStates: {
		savedSearchesExpanded: false,
		predefinedSearchesExpanded: false,
		productTypeExpanded: false,
		productInfoExpanded: false,
		benefitAmountsExpanded: false,
		productsAdvancedExpanded: false,
		personDemographicsCommonExpanded: false,
		personDemographicsAdvancedExpanded: false,
		personDemographicsGeographicExpanded: false,
		leadsCommonExpanded: false,
		leadsAdvancedExpanded: false,
		employerCommonExpanded: false,
		employerAdvancedExpanded: false,
		agentCommonExpanded: false,
		subagentExpanded: false,
	},
	error: undefined,
	leadTagFilterResult: [],
	employerTagFilterResult: [],
};

export function advancedSearchReducer(
	state: AdvancedSearchState = initialState,
	action: ReduxAction
): AdvancedSearchState {
	if (isType(action, SelectAdvancedSearchResult)) {
		const newResults = state.results.slice();
		newResults[action.payload.index].IsSelected = action.payload.selected
		let newNumSelected = 0;
		newResults.forEach(result => {
			if (result.IsSelected) {
				newNumSelected++;
			}
		});
		return {
			...state,
			results: newResults,
			numSelected: newNumSelected,
		};
	} else if (isType(action, SelectAllAdvancedSearchResults)) {
		const newResults = state.results.slice();
		for (let i = 0; i < newResults.length; i++) {
			newResults[i].IsSelected = action.payload;
		}
		let newNumSelected = 0;
		if (action.payload) {
			newNumSelected = newResults.length;
		}
		return {
			...state,
			results: newResults,
			numSelected: newNumSelected,
		};
	} else if (isType(action, SetAdvSearchSelectAllFlag)) {
		return {
			...state,
			isSelectAll: action.payload,
		};
	} else if (isType(action, ExpandFilterFields)) {
		const newState = { ...state };
		newState.expandedStates[action.payload] = !newState.expandedStates[
			action.payload
		];
		return newState;
	} else if (isType(action, StoreSelectedContactIds)) {
		let newBulkEmailContacts: string[] = [];
		state.results.forEach(result => {
			if (result.IsSelected) {
				newBulkEmailContacts.push(result.ContactId);
			}
		});
		return {
			...state,
			bulkEmailContacts: newBulkEmailContacts,
		};
	} else if (isType(action, SaveAdvSearchFormFilters)) {
		const newFilters: SearchFilterFormValues = action.payload.filters;
		return {
			...state,
			currentFormFilters: newFilters,
		};
	} else if (isType(action, StoreAdvancedSearchFilters)) {
		const newFilters: SearchFilterFormValues = action.payload.filters;
		return {
			...state,
			appliedSearchFilters: newFilters,
		};
	} else if (isType(action, MergeHouseholds.started)) {
		return {
			...state,
			numSelected: initialState.numSelected,
			results: initialState.results,
		};
	} else if (isType(action, ExecuteAdvancedSearch.started)) {
		return {
			...state,
			resultsLoading: true,
		};
	} else if (isType(action, ExecuteAdvancedSearch.done)) {
		const updatedResultObject: SearchResultObject = action.payload.result;
		const cachedResults = state.results.slice();
		const inboundResults: SearchResult[] = updatedResultObject.Items;
		const newResults =
			action.payload.params.pageIndex >= 1
				? cachedResults.concat(inboundResults)
				: inboundResults;
		if (state.isSelectAll === true) {
			newResults.forEach((result: SearchResult) => {
				result.IsSelected = true;
			});
		}
		const newResultCount = action.payload.result.TotalCount;
		return {
			...state,
			resultsLoading: false,
			error: undefined,
			results: newResults,
			totalResultCount: newResultCount,
			processId: action.payload.result.ProcessId
		};
	} else if (isType(action, ResetInfiniteScroll)) {
		return {
			...state,
			resultsLoading: false,
			error: undefined,
			results: [],
			numSelected: 0,
		};
	} else if (isType(action, ExecuteAdvancedSearch.failed)) {
		const error = action.payload.error;
		return {
			...state,
			error: error,
			resultsLoading: false,
		};
	} else if (isType(action, selectSavedSearchName)) {
		return {
			...state,
			selectedSavedSearchName: action.payload,
		};
	} else if (isType(action, setIsBLeadSearch)) {
		return {
			...state,
			isBLeadSearch: action.payload,
		};
	} else if (isType(action, getSavedCorporateAdvSearchFilters.done)) {
		const corporateFilters: AdvSearchFilterPresetDto[] = action.payload.result;
		corporateFilters.forEach((corpFilter: AdvSearchFilterPresetDto) => {
			if (corpFilter.filters.leadCreatedDateSearch) {
				const updatedLeadCreatedDates: CalculatedFromToDates = calculateSearchDates(
					corpFilter.filters.leadCreatedDateSearch
				);
				if (updatedLeadCreatedDates.from) {
					corpFilter.filters.leadCreatedDateFrom = updatedLeadCreatedDates.from;
				}
				if (updatedLeadCreatedDates.to) {
					corpFilter.filters.leadCreatedDateTo = updatedLeadCreatedDates.to;
				}
			}
			if (corpFilter.filters.leadContactedDateSearch) {
				const updatedLeadContactDates: CalculatedFromToDates = calculateSearchDates(
					corpFilter.filters.leadContactedDateSearch
				);
				if (updatedLeadContactDates.from) {
					corpFilter.filters.leadContactedDateFrom =
						updatedLeadContactDates.from;
				}
				if (updatedLeadContactDates.to) {
					corpFilter.filters.leadContactedDateTo = updatedLeadContactDates.to;
				}
			}
			if (corpFilter.filters.openToDoInNextDaysSearch) {
				const updatedOpenToDoDates: CalculatedFromToDates = calculateSearchDates(
					corpFilter.filters.openToDoInNextDaysSearch
				);
				if (updatedOpenToDoDates.from) {
					corpFilter.filters.openTodoInNextDaysFrom = updatedOpenToDoDates.from;
				}
				if (updatedOpenToDoDates.to) {
					corpFilter.filters.openTodoInNextDaysTo = updatedOpenToDoDates.to;
				}
			}
		});

		return {
			...state,
			corporateFilters: corporateFilters,
		};
	} else if (isType(action, getSavedUserAdvSearchFilters.done)) {
		const userFilters: AdvSearchFilterPresetDto[] = action.payload.result;
		userFilters.forEach((userFilter: AdvSearchFilterPresetDto) => {
			if (userFilter.filters.leadCreatedDateSearch) {
				const updatedLeadCreatedDates: CalculatedFromToDates = calculateSearchDates(
					userFilter.filters.leadCreatedDateSearch
				);
				if (updatedLeadCreatedDates.from) {
					userFilter.filters.leadCreatedDateFrom = updatedLeadCreatedDates.from;
				}
				if (updatedLeadCreatedDates.to) {
					userFilter.filters.leadCreatedDateTo = updatedLeadCreatedDates.to;
				}
			}
			if (userFilter.filters.leadContactedDateSearch) {
				const updatedLeadContactDates: CalculatedFromToDates = calculateSearchDates(
					userFilter.filters.leadContactedDateSearch
				);
				if (updatedLeadContactDates.from) {
					userFilter.filters.leadContactedDateFrom =
						updatedLeadContactDates.from;
				}
				if (updatedLeadContactDates.to) {
					userFilter.filters.leadContactedDateTo = updatedLeadContactDates.to;
				}
			}
			if (userFilter.filters.openToDoInNextDaysSearch) {
				const updatedOpenToDoDates: CalculatedFromToDates = calculateSearchDates(
					userFilter.filters.openToDoInNextDaysSearch
				);
				if (updatedOpenToDoDates.from) {
					userFilter.filters.openTodoInNextDaysFrom = updatedOpenToDoDates.from;
				}
				if (updatedOpenToDoDates.to) {
					userFilter.filters.openTodoInNextDaysTo = updatedOpenToDoDates.to;
				}
			}
		});

		return {
			...state,
			userSavedFilters: userFilters,
		};
	} else if (isType(action, upsertAdvancedSearchFilters.done)) {
		let currentSavedFilters: AdvSearchFilterPresetDto[] = state.userSavedFilters.slice();
		const resultingSavedFilters: AdvSearchFilterPresetDto =
			action.payload.result;
		const matchingIndex: number = currentSavedFilters.findIndex(
			(filterInState: AdvSearchFilterPresetDto) => {
				return filterInState.id === resultingSavedFilters.id;
			}
		);
		if (matchingIndex !== -1) {
			currentSavedFilters.splice(matchingIndex, 1, resultingSavedFilters);
		} else {
			currentSavedFilters.push(resultingSavedFilters);
		}

		return {
			...state,
			userSavedFilters: currentSavedFilters,
		};
	} else if (isType(action, deleteSavedAdvancedSearchFilters.done)) {
		let currentSavedFilters: AdvSearchFilterPresetDto[] = state.userSavedFilters.slice();
		const deletedUserSavedFilterId: string = action.payload.result;
		const matchingIndex: number = currentSavedFilters.findIndex(
			(filterInState: AdvSearchFilterPresetDto) => {
				return filterInState.id === deletedUserSavedFilterId;
			}
		);
		if (matchingIndex !== -1) {
			currentSavedFilters.splice(matchingIndex, 1);
		}

		return {
			...state,
			userSavedFilters: currentSavedFilters,
		};
	} else if (isType(action, BulkReassign.done)) {
		const newResults: SearchResult[] = [];
		state.results.forEach(result => {
			if (!result.IsSelected) {
				newResults.push(result);
			}
		});
		return {
			...state,
			results: newResults,
			totalResultCount: newResults.length,
			numSelected: 0,
			isSelectAll: false,
		};
	} else if (isType(action, exportAdvancedSearchResults.started)) {
		return {
			...state,
			exportRequestLoading: true
		};
	} else if (isType(action, exportAdvancedSearchResults.failed)) {
		return {
			...state,
			exportRequestLoading: false
		};
	} else if (isType(action, exportAdvancedSearchResults.done)) {
		return {
			...state,
			exportRequestLoading: false
		};
	} else if (isType(action, getHouseholdTags.done)) {
		return {
			...state,
			leadTagFilterResult: action.payload.result || []
		};
	} else if (isType(action, getEmployerTags.done)) {
		return {
			...state,
			employerTagFilterResult: action.payload.result || []
		};
	} else if (isType(action, Logout.started)) {
		return {
			...initialState,
		};
	} else if (isType(action, ClearCaches)) {
		return {
			...initialState,
		};
	} else {
		return state;
	}
}

interface CalculatedFromToDates {
	from?: Moment;
	to?: Moment;
}

function calculateSearchDates(searchValue: string) {
	searchValue = searchValue.toLowerCase();
	const resultDates: CalculatedFromToDates = {};
	const fromDate = moment().startOf('day');
	const toDate = moment()
		.endOf('day')
		.subtract(1, 'minute');

	if (searchValue.includes('anytime')) {
		resultDates.from = undefined;
		resultDates.to = undefined;
	} else if (searchValue.includes('today')) {
		resultDates.from = fromDate;
		resultDates.to = toDate;
	} else if (searchValue.includes('tomorrow')) {
		resultDates.from = fromDate.add(1, 'days');
		resultDates.to = toDate.add(1, 'days');
	} else if (searchValue.includes('yesterday')) {
		resultDates.from = fromDate.subtract(1, 'days');
		resultDates.to = toDate.subtract(1, 'days');
	} else if (searchValue.includes('past due')) {
		resultDates.from = undefined;
		resultDates.to = moment();
	} else if (searchValue.includes('last') && searchValue.includes('days')) {
		const onlyNums: string = searchValue.replace(/[^\d]/g, '');

		if (onlyNums.length > 0) {
			const days: number = parseInt(onlyNums);
			resultDates.from = fromDate.subtract(days, 'days');
			resultDates.to = toDate;
		}
	} else if (searchValue.includes('next') && searchValue.includes('days')) {
		const onlyNums: string = searchValue.replace(/[^\d]/g, '');

		if (onlyNums.length > 0) {
			const days: number = parseInt(onlyNums);
			resultDates.from = fromDate;
			resultDates.to = toDate.add(days, 'days');
		}
	} else if (searchValue.includes('last') && searchValue.includes('week')) {
		resultDates.from = getLastSundayDate().subtract(7, 'days');
		resultDates.to = getLastSundayDate()
			.subtract(1, 'days')
			.endOf('d');
	} else if (searchValue.includes('this') && searchValue.includes('week')) {
		resultDates.from = getLastSundayDate();
		resultDates.to = toDate;
	}

	return resultDates;
}

function getLastSundayDate() {
	const myIsoWeekDay = 7; // Sunday
	const today = moment().startOf('day');

	const daysToSubtract =
		today.isoWeekday() > myIsoWeekDay
			? today.isoWeekday() - myIsoWeekDay
			: 7 + today.isoWeekday() - myIsoWeekDay;

	return today.subtract(daysToSubtract, 'd');
}
