import { Button } from '@material-ui/core';
import React from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import { navRoutes } from '../nav/Routes';
import { Widget } from './base_widget';
import { isBrokerage } from '../../utilities/brokerage_utils';
import { normalizePhone } from '../../utilities/formatting/data_normalizations';
import { AppState } from '../../reducers';
import { Dispatch } from '@reduxjs/toolkit';
import { Strings } from '../../assets/common/strings';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';
import { useLinkToExternal, useMakeCall, useNavigateTo, useOpenEmail } from '../../utilities/hooks';
import { themeMisc } from '../../utilities/branding/misc';
import { SimpleListItem } from '../utility/simple_list_item';
import { AppConfig } from '../../types/config';


interface StateProps {}
interface DispatchProps { }
type Props = StateProps & DispatchProps;

const HelpSupportWidget: React.FC<Props> = (props) => {

	const navToHelp = useNavigateTo(navRoutes.helpSupport.path);
	const navToRC = useLinkToExternal(AppConfig.help_support_link);
	const callSupport = useMakeCall(themeMisc.helpAndSupportPhone);
	const emailSupport = useOpenEmail(themeMisc.helpAndSupportEmail);

	const actions = [<Button onClick={navToHelp}>View All</Button>];
	if (!isBrokerage) {
		actions.push(<Button onClick={navToRC}>Help Documents</Button>);
	}
	return (
		<Widget
			title={Strings.WidgetTitles.Help}
			avatar={<HelpOutlineIcon/>}
			onClick={navToHelp}
			actionButtons={actions}
		>
			<SimpleListItem
				title={normalizePhone(themeMisc.helpAndSupportPhone)}
				icon={<CallIcon/>}
				onClick={callSupport}
				divider={false}
				hideArrow
			/>
			<SimpleListItem
				title={themeMisc.helpAndSupportEmail}
				icon={<EmailIcon/>}
				onClick={emailSupport}
				divider={false}
				hideArrow
			/>
		</Widget>
	);
}

const mapStateToProps = (state: AppState): StateProps => ({});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({});

export const HelpSupportWidgetContainer = connect(mapStateToProps, mapDispatchToProps, true)(HelpSupportWidget);
