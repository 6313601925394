import React from 'react';
import {
	Table,
	Paper,
	TableRow,
	TableCell,
	TableBody,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TeamViewRow } from '../../reducers/teamview_reducer';

export const ProductivityTableExtraRow: React.FC<{rows: TeamViewRow[]}> = props => {
    const classes = useStyles();
    const { rows } = props;

    return (
        <Paper style={{ overflowX: 'auto' }}>
            <Table>
                <TableBody>
                    {rows.map((row, index1) => {
                        const columns = row.results;
                        return (
                            <TableRow key={index1}>
                                {/* <TableCell /> */}
                                {Object.keys(columns).map((key, index2) => (
                                    <TableCell
                                        key={index2}
                                        align="center"
                                        colSpan={3}
                                        className={classes.row}
                                    >
                                        {`${key} : ${columns[key].value}`}
                                    </TableCell>
                                ))}
                            </TableRow>)})}
                </TableBody>
            </Table>
        </Paper>
    );
};

const useStyles = makeStyles({
    row: {
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
    }
});
