import React from 'react';
import { themePalette } from '../../utilities/branding';
import {
	Typography,
	Card,
	CardContent,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableSortLabel,
	ButtonBase,
	Tooltip,
} from '@material-ui/core';
import { ImportHistory } from '../../reducers/file_reducer';
import moment from 'moment';
import _ from 'lodash';

interface ImportHistoryTableProps {
	leadImports: ImportHistory[];
	getProcessedLeadImportFile: (fileName: string) => void;
	order: 'asc' | 'desc' | undefined;
	orderBy: string;
	sortData: (order: 'asc' | 'desc', orderBy: string) => void;
}

export const ImportHistoryTable = (props: ImportHistoryTableProps) => {
	const columnHeaders: any = [
		{ id: 'fileName', label: 'File Name' },
		{ id: 'createdOn', label: 'Create Date' },
		{ id: 'numberOfRows', label: 'Number of Rows' },
		{ id: 'numberOfErrors', label: 'Number of Rows with Errors' },
		{ id: 'resultsRead', label: 'Results (Number of Rows Processed)' },
		{ id: 'status', label: 'Status' },
		{ id: 'type', label: 'Lead or Application' },
	];

	return (
		<Card style={styles.cardStyle}>
			<CardContent>
				<Table>
					<TableHead>
						<TableRow>
							{columnHeaders.map((columnHeader: any) => {
								return (
									<TableCell
										key={columnHeader.id}
										sortDirection={
											props.orderBy === columnHeader.id ? props.order : false
										}
										style={styles.cellPadding}
									>
										<Tooltip
											title="Sort"
											placement="bottom-start"
											enterDelay={300}
										>
											<TableSortLabel
												active={props.orderBy === columnHeader.id}
												direction={props.order}
												onClick={() =>
													props.sortData(
														props.order === 'asc' ? 'desc' : 'asc',
														columnHeader.id
													)
												}
											>
												{columnHeader.label}
											</TableSortLabel>
										</Tooltip>
									</TableCell>
								);
							})}
						</TableRow>
					</TableHead>
					<TableBody>
						{props.leadImports.map((leadImport: ImportHistory) => {
							const createdOnString = _.get(leadImport, 'createdOn', '');
							const createdOn = createdOnString
								? moment
										.utc(createdOnString)
										.local()
										.format('MM/DD/YY')
								: '';

							return (
								<TableRow key={leadImport.fileName}>
									<TableCell style={styles.cellPadding}>
										{leadImport.status === 'Submitted' ||
										leadImport.status === 'Completed' ||
										leadImport.status === 'Completed with Errors' ? (
											<ButtonBase
												style={{
													textDecoration: 'underline',
													color: themePalette.import_button,
												}}
												onClick={() =>
													props.getProcessedLeadImportFile(
														leadImport.fileName
													)
												}
											>
												{leadImport.fileName}
											</ButtonBase>
										) : (
											leadImport.fileName
										)}
									</TableCell>
									<TableCell style={styles.cellPadding}>
										{createdOn}
									</TableCell>
									<TableCell style={styles.cellPadding}>
										{leadImport.numberOfRows}
									</TableCell>
									<TableCell style={styles.cellPadding}>
										{leadImport.numberOfErrors}
									</TableCell>
									<TableCell style={styles.cellPadding}>
										{leadImport.resultsRead}
									</TableCell>
									<TableCell style={styles.cellPadding}>
										{leadImport.status == 'Submitted'
											? 'Completed'
											: leadImport.status}
									</TableCell>
									<TableCell style={styles.cellPadding}>
										{leadImport.type}
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
};

const styles: { [key: string]: React.CSSProperties } = {
	cardStyle: {
		margin: 10,
		overflowX: 'auto',
	},
	cellPadding: {
		paddingRight: 0,
	},
};
