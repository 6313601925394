import { Card, CardHeader, Avatar, IconButton, Drawer, Grid, Button, createStyles, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import { Strings } from '../../assets/common/strings';
import { SaveCancelHeaderBarComponent } from '../Layout/SaveCancelHeaderBar';
import { BaseFilterForm } from '../search/base_filter_form';
import TuneIcon from '@material-ui/icons/Tune';
import { useToggle } from '../../utilities/hooks';
import { themePalette } from '../../utilities/branding';

const filterIcon = require('../../assets/filter_icon.png');

interface Props {
    cardAvatar?: JSX.Element;
    cardTitle?: string;
    cardSubtitle?: string;
    drawerTitle?: string
    drawerSubtitle?: string;
    searchButtonText?: string;
    isSearchDisabled?: boolean;
    customContent?: React.ReactNode;
    startOpen?: boolean;

    onSearch: () => void;
    onReset?: () => void;
    onCancel?: () => void;
}

export const ListFilter: React.FC<Props> = (props) => {

    const {
        cardAvatar = <Avatar src={filterIcon} />,
        cardTitle = Strings.ButtonText.Search,
        cardSubtitle = '',
        drawerTitle = Strings.ButtonText.Search,
        drawerSubtitle = '',
        searchButtonText = Strings.ButtonText.Search,
        isSearchDisabled = false,
        customContent,
        startOpen = false,

        onSearch,
        onReset,
        onCancel,

        children
    } = props;

    const [drawerOpen, toggleDrawerOpen] = useToggle(startOpen);
    const [currentFilter, setCurrentFilter] = useState(cardSubtitle);
    const filterStyles = useFilterStyles();

    const onBeginSearch = () => {
        setCurrentFilter(cardSubtitle);
        toggleDrawerOpen();
        onSearch();
    };
    const onCancelSearch = () => {
        toggleDrawerOpen();
        onCancel?.();
    };

    return (
        <Card raised={false} elevation={0} square>
            <CardHeader
                className={filterStyles.card}
                avatar={cardAvatar}
                title={cardTitle}
                subheader={currentFilter}
                onClick={toggleDrawerOpen}
                action={<IconButton><TuneIcon /></IconButton>}
                style={{ padding: 8 }}
            />
            {customContent}
            <Drawer
				anchor="right"
				open={drawerOpen}
				onClose={toggleDrawerOpen}
				PaperProps={{  className: filterStyles.width }}
                keepMounted
			>
				<SaveCancelHeaderBarComponent
					title={drawerTitle}
					onSave={onBeginSearch}
					saveText={searchButtonText}
					onCancel={onCancelSearch}
					bypassCancelConfirmation
					isSaveDisabled={isSearchDisabled}
				/>
				<Grid container style={{ padding: 10, overflowY: 'scroll' }}>
					<Grid item xs={12}>
                        <Card raised={false} elevation={0} square>
							<CardHeader
								avatar={cardAvatar}
								title={cardTitle}
								subheader={drawerSubtitle}
                                style={{ padding: 8 }}
                                action={onReset &&
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={onReset}
                                        size='small'
                                        disableElevation
                                    >
                                        <div style={{ color: themePalette.negative_text }}>Reset</div>
                                    </Button>
                                }
							/>
						</Card>
					</Grid>
                    <Grid item xs={12} style={{ padding: 10 }}>
                        <BaseFilterForm submit={onBeginSearch} hideOverflow>
                            {children}
                        </BaseFilterForm>
                    </Grid>
				</Grid>
			</Drawer>
        </Card>
    );

};

const useFilterStyles = makeStyles((theme: Theme) => ({
    width: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            width: 500,
        },
    },
    card: {
        cursor: 'pointer',
    },
}));