import { createSelector } from "reselect";
import { AppState } from "../reducers";
import { Tool } from "../reducers/tools_reducer";

const toolsWidgetFilter = (tool: Tool) => tool.isWidgetTool;

const getTools = (state: AppState) => (state.tools.tools || []);
export const getWidgetTools = createSelector(
	[getTools],
	(tools: Tool[]) =>
        tools.filter(toolsWidgetFilter)
            .slice(0, 4)
);