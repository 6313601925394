import {
    useState,
    useLogging,
    useEffect,
} from '@optum-uhone-hmkts/rise';
import { WrappedFieldProps } from 'redux-form';


export type ReduxFormsFieldProps = WrappedFieldProps & {
    label: string;
    onBlur?: (event) => void;
    onFocus?: (eventOrValue) => void;
    onChange?: (event) => void;
    type?: string;
    validate: (value) => string;
    children;
    gridSize?: string;
    r_pipe?: (value: string) => string;
    riseValidate?: (value: string) => string | undefined;
    [key: string]: any;
}

export const useReduxFormFieldProps = (props: ReduxFormsFieldProps) => {
    const { log } = useLogging({ caller: useReduxFormFieldProps.name });

    const {
        name,
        label,
        onBlur,
        onFocus,
        onChange,
        meta: { error, touched },
        input,
        type,
        validate,
        children,
        gridSize,
        r_pipe,
        riseValidate,
        ...componentProps
    } = props

    const [currentValue, setCurrentValue] = useState(props.input.value || "");
    // const [prevValue, setPrevValue] = useState(currentValue);
    const [errorMessage, setErrorMessage] = useState(error);

    const handleChange = (value: string) => {
        const newError = validate && validate(value);
        setErrorMessage(newError);
        // setPrevValue(currentValue);
        setCurrentValue(value);
    }

    const onFieldChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, input?) => {
        const newValue = event?.target?.value || event?.currentTarget?.value;
        // const normalizedValue = props.r_pipe ? props.r_pipe(newValue) : newValue;

        // const caretStart = event?.target?.selectionStart;
        // const caretEnd = event?.target?.selectionEnd;

        handleChange(newValue);
        props.input?.onChange && props.input?.onChange(newValue);

        // if (caretStart !== undefined && caretEnd !== undefined) {
        //     event?.target?.setSelectionRange(caretStart, caretEnd);
        // }
    }

    useEffect(() => {
        if (props.input.value != currentValue)
            handleChange(props.input.value);
    }, [props.input]);

    const handleFocus = (event) => {
        input.onFocus(event);
        props.onFocus && props.onFocus(event);
    }

    const handleBlur = (event) => {
        input.onBlur(event);
        props.onBlur && props.onBlur(event);
    }

    return {
        name,
        gridSize: props.gridSize || "12|12",
        label: props.label,
        error: touched && Boolean(error),
        helperText: touched && errorMessage,
        value: currentValue,
        inputvalue: props.input.value,
        options: props?.options,
        onChange: onFieldChange,
        onFocus: handleFocus,
        onBlur: handleBlur,
        input,
        children: props.children || [],
        componentProps,
    };

}
