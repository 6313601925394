import { Grid } from '@material-ui/core';
import React from 'react';
import {
	findAgentRole,
	getAgentFullName,
	getAgentStatus,
} from '../../utilities/agent_util';
import { Agent } from '../../reducers/agent_reducer';
import { AgentAvatar } from './agent_avatar';
import { Strings } from '../../assets/common/strings';
import { themePalette } from '../../utilities/branding';

interface Props {
	agent: Agent;
	isLoading?: boolean;
}
export const AgentHeader: React.FC<Props> = (props: Props) => {
	
	const { agent, isLoading } = props;

	const agentRole = findAgentRole(agent);
	const agentStatus = getAgentStatus(agent);

	return (
		<Grid container justify="center" alignItems="center" direction="column" spacing={2}>
			<Grid item>
				<AgentAvatar
					avatarSize={100}
					agent={agent}
					disabled={agentStatus === Strings.AgentChannelStatus.Suspended}
					style={{ backgroundColor: themePalette.default_avatar }}
				/>
			</Grid>
			<Grid item container justify="center" direction="column" alignItems="center" style={{ marginBottom: 10 }}>
				<Grid item><strong>{getAgentFullName(agent)}</strong></Grid>
				<Grid item>{`${agentRole} ${agentRole && agent.agentCode ? '-' : ''} ${agent.agentCode}`}</Grid>
				{!isLoading && <Grid item>Status: {agentStatus}</Grid>}
			</Grid>
		</Grid>
	);
};


