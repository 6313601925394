import { FcmRegistration, ReceivedFcmNotification } from './../actions/fcm_actions';
import { put, all } from 'redux-saga/effects';
import { FcmError } from '../actions/fcm_actions';
import { Action as TypescriptAction } from 'typescript-fsa';
import { DeviceSettings, initialDeviceSettings } from '../reducers/user_reducer';
import { store } from '..';
import { AppState } from '../reducers';
import { UpdateDeviceSettings } from '../actions/user_actions';
import { registerFireBase, FcmNotification, initializeOnMessageListener } from '../utilities/event_listeners/fcm_listeners';
import { GetAllNotifications } from '../actions/notification_actions';
import jwt_auth from '../utilities/auth/jwt_auth';
import { getBulkReassignFilter, formatLeadFiltersPayload } from '../utilities/lead_util';
import { StoreLeadFilters, GetFilteredLeads, GetNotContactedLeadCount } from '../actions/lead_actions';
import { navigateTo } from '../actions/navigation_actions';
import { QueueSnackbar, NextSnackbar } from '../actions/snackbar_actions';
import { submitLog } from '../utilities/logging_util';
import { Strings } from '../assets/common/strings';
import { P } from '../utilities/auth/permissions';
import { selectFromImmutable, takeEveryForActionType, takeLatestForActionType } from '../utilities/saga_util';
import { Questionable } from '../utilities/object_util';
import { getCurrentDevice, getUserId } from '../selectors/user_selectors';
import { getSnackbarSuccessProps } from '../utilities/snackbar_util';
import { GAService } from '../utilities/services/google_analytics';
import { getDeviceId } from '../utilities/device_util';
import { MessagePayload } from 'firebase/messaging';

// -- SAGAS --
function* fcmRegistrationSaga(action: TypescriptAction<undefined>) {
    try {
        const currentToken: string = yield registerFireBase();
        const currentDeviceSettings: Questionable<DeviceSettings> = yield selectFromImmutable<Questionable<DeviceSettings>>(getCurrentDevice);
        const currentFcmKeys = currentDeviceSettings?.fcmKey || [];
        if (!currentFcmKeys.includes(currentToken)) {
            const deviceToUpdate: DeviceSettings = {
                ...initialDeviceSettings,
                ...currentDeviceSettings,
                deviceID: getDeviceId(),
                fcmKey: currentFcmKeys.concat(currentToken),
            };
            yield put(UpdateDeviceSettings.started(deviceToUpdate));
            const userId: string = yield selectFromImmutable<string>(getUserId);
            GAService.sendDeviceEvent(userId, deviceToUpdate.deviceID);
        }
        yield initializeOnMessageListener();
        yield put(FcmRegistration.done({ params: action.payload, result: true }));

    } catch (error) {
        const errorPayload: FcmError = { ...error, message: "Failed in fcmRegistrationSaga" };
        yield all([
            put(FcmError(errorPayload)),
            put(FcmRegistration.failed({ params: action.payload, error }))
        ]);
    }
}

function* fcmPushNotificationSaga(action: TypescriptAction<MessagePayload>) {
    const { payload } = action;

    const state: AppState = yield selectFromImmutable<AppState>();

    if (state.user.id && jwt_auth.hasPermission(P.Notification)) {
        yield put(GetAllNotifications.started(state.user.id))
    }

    if (payload.data && payload.data.type && payload.data.type.includes('LeadAdded')) {
        yield put(GetNotContactedLeadCount.started(undefined));
    }

    if (state.authentication.isAuthenticated) {
        if (payload.notification && payload.data && payload.data.url) {
            const snackbarProps = getSnackbarSuccessProps(payload.notification.title, 10000, () => {
                store.dispatch(NextSnackbar());
                store.dispatch(navigateTo(payload.data!.url));
            });

            if (payload.data.url.includes('/lead/list')) {
                const filter = getBulkReassignFilter(payload.data.url);
                yield put(StoreLeadFilters(filter));

                const actionPayload = formatLeadFiltersPayload(filter, 0, state.lead.leadListPageSize);
                yield put(GetFilteredLeads.started(actionPayload));

                payload.data.url = payload.data.url.split('?')[0];
            }

            yield put(QueueSnackbar(snackbarProps));
        }

        if (payload.notification && payload.notification.title) {
            yield put(QueueSnackbar(getSnackbarSuccessProps(payload.notification.title)));
        }
    }
}

export function* fcmSagas() {
    yield all([
        takeEveryForActionType(
            FcmRegistration.started,
            fcmRegistrationSaga
        ),
        takeLatestForActionType(
            ReceivedFcmNotification,
            fcmPushNotificationSaga
        ),
        takeLatestForActionType(
            FcmError, () => submitLog(Strings.Log.Error,
                `Error registering FCM key`)
        ),
    ]);
}