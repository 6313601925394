import React from 'react';
import { StrictFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';
import {
	EmployerFields,
	EmployerFieldsData,
} from '../lead_upsert_employer/employer_fields';
import { getAppState } from '../..';

export const FORM_NAME = 'UpsertNewEmployerLeadForm';

export type FormData = EmployerFieldsData;

interface Props { }

const _UpsertEmployerForm = ({
	initialValues,
}: Props & StrictFormProps<FormData, any, any>) => {
	return (
		<form>
			<EmployerFields />
		</form>
	);
};

export const UpsertEmployerForm = reduxForm({
	form: FORM_NAME,
	getFormState: (state) => getAppState().form,

})(_UpsertEmployerForm);
