import {
	Avatar,
	Icon,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Grow,
	TextField,
	DialogContentText,
} from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { Note } from '../../reducers/note_reducer';
import ClampLines from 'react-clamp-lines';
import { CreateNoteDialog } from './create_note_dialog';
import { Loaded } from '../../utilities/utilities';
import { displayDate } from '../../utilities/date_util';
import { themePalette } from '../../utilities/branding';
import { wrapInCollapsibleCard } from '../higher_order_component/wrap_in_card';
import { MoreMenu, createMenuAction } from '../nav/more_menu';
import { SimpleListItem } from '../utility/simple_list_item';
import CustomList from '../utility/custom_list';

interface NoteListProps {
	notes: Loaded<Note>[];
	canDelete: boolean;
	createNoteDialogIsOpen?: boolean;
	createNoteFullScreen?: boolean;
	updateCreateNoteDialogState?: (isOpen: boolean) => void;
	handleDeleteNote?: (note: Note) => any;
	handleSaveNote?: (content: string) => void;
	updateNote?: (note: Note) => void;
}

interface NoteListState {
	selectedNote?: Note;
	dialogIsOpen: boolean;
	displayDeleteConfirmation: boolean;
	createNoteDialogIsOpen: boolean;
}

export class NoteList extends React.Component<NoteListProps, NoteListState> {
	constructor(props: NoteListProps) {
		super(props);

		this.state = {
			dialogIsOpen: false,
			displayDeleteConfirmation: false,
			createNoteDialogIsOpen: !!props.createNoteDialogIsOpen,
		};
	}

	componentDidUpdate(prevProps: NoteListProps) {
		if (prevProps.createNoteDialogIsOpen != this.props.createNoteDialogIsOpen)
			this.setState({
				createNoteDialogIsOpen: this.props.createNoteDialogIsOpen ? true : false,
			});
	}

	handleNoteClick = (note?: Note) => {
		this.setState({ dialogIsOpen: true, selectedNote: note });
	};

	handleClose = () => {
		this.setState({
			dialogIsOpen: false,
			selectedNote: undefined,
			createNoteDialogIsOpen: false,
		});
		this.props.updateCreateNoteDialogState && this.props.updateCreateNoteDialogState(false);
	};

	onSave = (content: string) => {
		if (this.state.selectedNote && this.props.updateNote) {
			this.props.updateNote({ ...this.state.selectedNote, content });
		} else if (this.props.handleSaveNote) {
			this.props.handleSaveNote(content);
		}
		this.setState({ selectedNote: undefined, createNoteDialogIsOpen: false });
		this.props.updateCreateNoteDialogState && this.props.updateCreateNoteDialogState(false);
	};

	handleConfirmDelete = () => {
		this.setState({
			displayDeleteConfirmation: false,
			dialogIsOpen: false,
			selectedNote: undefined,
		});
		this.props.handleDeleteNote && this.state.selectedNote
			? this.props.handleDeleteNote(this.state.selectedNote)
			: {};
	};

	dismissDeleteDialog = () => {
		this.setState({
			displayDeleteConfirmation: false,
			selectedNote: undefined
		});
	};

	renderEditNoteDialog = () => {
		const editNoteContent = this.state.selectedNote ? this.state.selectedNote.content : '';

		return <CreateNoteDialog
			onSave={this.onSave}
			closeDialog={this.handleClose}
			initialContent={editNoteContent}
			isOpen={this.state.createNoteDialogIsOpen}
			createNoteFullScreen={this.props.createNoteFullScreen}
		/>
	}

	renderDeleteNoteDialogue = () => {
		return (
			<Dialog
				open={this.state.displayDeleteConfirmation}
				onClose={this.handleClose}
			>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to delete this note?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button color="secondary" onClick={this.dismissDeleteDialog}>
						Cancel
					</Button>
					<Button
						color="primary"
						variant="contained"
						onClick={this.handleConfirmDelete}
						style={{ backgroundColor: themePalette.delete_remove_reject_button }}
					>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	renderNoteDialogue() {
		const targetNote = this.state.selectedNote ? this.state.selectedNote : ({} as Note);
		return (
			<Dialog
				open={this.state.dialogIsOpen}
				onClose={this.handleClose}
				fullWidth
				TransitionComponent={Grow}
			>
				<DialogTitle>
					{
						targetNote.dateCreated 
							? moment
								.utc(targetNote.dateCreated)
								.local()
								.format('MMMM Do, h:mmA')
							: ''
					}
				</DialogTitle>
				<DialogContent>
					<TextField
						multiline
						value={targetNote.content}
						fullWidth
						InputProps={{
							disableUnderline: true,
							inputProps: {
								readOnly: true
							}
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Button color="secondary" onClick={this.handleClose}>
						Close
					</Button>
					<Button
						variant="contained"
						color="primary"
						style={{ backgroundColor: themePalette.delete_remove_reject_button }}
						onClick={() => this.setState({ displayDeleteConfirmation: true })}
					>
						Delete Note
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	editNote = (note: Note) => () => {
		this.setState({ 
			createNoteDialogIsOpen: true,
			selectedNote: note,
		});
	};

	deleteNote = (note: Note) => () => {
		this.setState({
			displayDeleteConfirmation: true,
			selectedNote: note,
		});
	}

	render() {
		const sortedNotes = [...this.props.notes].sort(
			(a: Loaded<Note>, b: Loaded<Note>) => {
				return (
					moment
						.utc(b.data.dateCreated)
						.local()
						.valueOf() -
					moment
						.utc(a.data.dateCreated)
						.local()
						.valueOf()
				);
			}
		).map(note => note.data);

		return (
			<>
				<CustomList emptyMessage="You have no notes">
					{sortedNotes.map((note, index: number) => (
						<SimpleListItem
							key={note.id}
							onClick={() => this.handleNoteClick(note)}
							title={displayDate(moment.utc(note.dateCreated).local())}
							subtitle={
								<ClampLines
									key={note?.id || `${index}`}
									text={note?.content || ''}
									lines={2}
									buttons={false}
								/>
							}
							icon={
								<Avatar>
									<Icon>event_note</Icon>
								</Avatar>
							}
							secondaryAction={
								<MoreMenu
									color={themePalette.tertiary_text}
									children={[
										createMenuAction('Delete', this.deleteNote(note)),
										createMenuAction('Edit', this.editNote(note)),
									]}
								/>
							}
						/>
					))}
				</CustomList>
				{this.renderNoteDialogue()}
				{this.renderDeleteNoteDialogue()}
				{this.renderEditNoteDialog()}
			</>
		);
	}
}

export const NoteListCard = wrapInCollapsibleCard(NoteList);