import {actionCreatorFactory} from 'typescript-fsa';
import { Employer } from '../reducers/employer_reducer';
import { FullEmployer } from '../sagas/employer_sagas';

const actionCreator = actionCreatorFactory('Employer');

// GET EMPLOYER BY ID
export const GetEmployer = actionCreator.async<string, FullEmployer>(
	'GET_EMPLOYER'
);

// UPDATE EMPLOYER
export const UpdateEmployer = actionCreator.async<Employer, FullEmployer>(
	'UPDATE_EMPLOYER'
);

// SET PRIMARY EMPLOYEE
export interface SetPrimaryEmployerParams {
	employerId: string;
	contactId: string;
}
export const SetPrimaryEmployee = actionCreator.async<
	SetPrimaryEmployerParams,
	string
	>('SET_PRIMARY_EMPLOYEE');

// DELETE EMPLOYER
export const DeleteEmployer = actionCreator.async<string, string>(
	'DELETE_EMPLOYER'
);

// REASSIGN EMPLOYER
export interface ReassignEmployerParams {
	employerId: string;
	agentCode: string;
	agentName: string;
}
export const ReassignEmployer = actionCreator.async<
	ReassignEmployerParams,
	boolean
	>('REASSIGN_EMPLOYER');

// SEARCH EMPLOYERS
export const SearchEmployers = actionCreator.async<string, FullEmployer[]>(
	'SEARCH_EMPLOYERS'
);

export interface EmployerTagPayload {
	employerId: string;
	tags: string[];
}
export const saveEmployerTags = actionCreator.async<EmployerTagPayload, void>('SAVE_EMPLOYER_TAGS');
export const getEmployerTags = actionCreator.async<string, string[]>('GET_EMPLOYER_TAGS');
