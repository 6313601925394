import { all, call, put } from 'redux-saga/effects';
import moment from 'moment';
import {
	TeamViewActionCreator,
	TeamViewNewActionCreator,
	TeamViewValueActionCreator,
	TeamViewParams,
	GetAllTeamViewData,
	GetTeamViewActivity,
	GetTeamViewLeads,
	GetTeamViewProduction,
	GetTeamViewQuality,
	GetTeamView206020,
	GetTeamViewNewAgentConversion,
	GetWritingAgentStats,
	GetTeamViewSummaryReport,
	GetActiveSalesLeaderCount,
	GetProfessionalAgents,
	NavigatePayload,
	NavigateToAgentPerformance,
} from '../actions/teamview_actions';
import { SetPerformanceDownlineAgent } from '../actions/performance_actions';
import { Action } from 'typescript-fsa';
import { http } from '../utilities';
import { TeamViewRow, WritingAgentReport } from '../reducers/teamview_reducer';
import { takeLatestForActionType, makeHandleError } from '../utilities/saga_util';
import { Strings } from '../assets/common/strings';
import { getAgentProfileCall } from './agent_search_sagas';
import { navigateTo } from '../actions/navigation_actions';

function* handleGetAllTeamViewData(action: Action<TeamViewParams>) {
	yield all([
		put(GetTeamViewActivity.started(action.payload)),
		put(GetTeamViewLeads.started(action.payload)),
		put(GetTeamViewProduction.started(action.payload)),
		put(GetTeamViewQuality.started(action.payload)),
	]);
}

const getTeamViewSaga = (
	actionCreator: TeamViewActionCreator,
	api: string,
) =>
	function*(action: Action<TeamViewParams>) {
		try {
			const response = yield call(getTeamViewData(api), action.payload);
			if (response.ok) {
				const teamViewTable: TeamViewRow[] = yield response.json();
				if (!teamViewTable) {
					return;
				}

				teamViewTable.forEach(row => {
					if (row.terminated && row.agentcode.includes('Terminated')) {
						row.hideChildren = true;
					}
				});

				yield put(
					actionCreator.done({
						params: action.payload,
						result: teamViewTable,
					}),
				);
			} else {
				yield handleError(actionCreator, action, {
					errorCode: response.status,
				});
				yield put(
					actionCreator.done({
						params: action.payload,
						result: [] as TeamViewRow[],
					}),
				);
			}
		} catch (error) {
			yield handleError(actionCreator, action, error);
			yield put(
				actionCreator.done({
					params: action.payload,
					result: [] as TeamViewRow[],
				}),
			);
		}
	}

const GetWritingAgentReport = (
	actionCreator: TeamViewNewActionCreator,
) =>
	function*(action: Action<TeamViewParams>) {
		try {
			const response = yield call(getTeamViewData(Strings.TeamViewAPI.WRITING_AGENTS), action.payload);
			if (response.ok) {
				const writingAgentGraph: WritingAgentReport = yield response.json();
				if (!writingAgentGraph) {
					return;
				}
				yield put(
					GetWritingAgentStats.done({
						params: action.payload,
						result: writingAgentGraph,
					}),
				);
			} else {
				yield handleError(GetWritingAgentStats, action, {
					errorCode: response.status,
				});
				yield put(
					GetWritingAgentStats.done({
						params: action.payload,
						result: { table: null , graph: null },
					}),
				);
			}
		} catch (error) {
			yield handleError(GetWritingAgentStats, action, error);
			yield put(
				GetWritingAgentStats.done({
					params: action.payload,
					result: { table: null , graph: null },
				}),
			);
		}
	}

	const getActiveSLCountSaga = (
		actionCreator: TeamViewValueActionCreator,
	) =>
		function*(action: Action<TeamViewParams>) {
			try {
				const response = yield call(getTeamViewData(Strings.TeamViewAPI.ACTIVE_SL_ROUTE), action.payload);
				if (response.ok) {
					const count: number = yield response.json();
					if (!count) {
						return;
					}
					yield put(
						GetActiveSalesLeaderCount.done({
							params: action.payload,
							result: count,
						}),
					);
				} else {
					yield handleError(GetActiveSalesLeaderCount, action, {
						errorCode: response.status,
					});
					yield put(
						GetActiveSalesLeaderCount.done({
							params: action.payload,
							result: null,
						}),
					);
				}
			} catch (error) {
				yield handleError(GetActiveSalesLeaderCount, action, error);
				yield put(
					GetActiveSalesLeaderCount.done({
						params: action.payload,
						result: null,
					}),
				);
			}
		}

function* navigateToAgentPerformancePage(action: Action<NavigatePayload>) {
	try {
		const response = yield call(getAgentProfileCall, action.payload.agentCode);
		if (response.ok) {
			const data = yield response.json();
			yield put(SetPerformanceDownlineAgent({
				agent: data,
			}));
			yield put(navigateTo(action.payload.route));
		}
	} catch (error) {
		console.error('Unable to navigate to performance page');
	}
}

const getTeamViewData = (api: string) => (
	params: TeamViewParams,
): Promise<any> => {
	// Convert to UTC here
	const startDate = moment.utc([params.startDate.getFullYear(), params.startDate.getMonth(), params.startDate.getDate()])
		.startOf('day')
		.subtract(params.utcOffset, 'minutes');
	const endDate = moment.utc([params.endDate.getFullYear(), params.endDate.getMonth(), params.endDate.getDate()])
		.endOf('day')
		.subtract(params.utcOffset, 'minutes');
	const urlParams = `
		?startDate=${startDate.format()}
		&endDate=${endDate.format()}
		&LOB=${params.lineOfBusiness}
		&comparePrevious=${params.comparePrevious}
		&utcOffset=${params.utcOffset}
		${params.agentCode ? `&agentCode=${params.agentCode}` : ''}
		${
		params.dateRangeOverride
			? `&dateRangeOverride=${params.dateRangeOverride}`
			: ''
		}
	`;
	return http(`${api}${params.agentCode ? Strings.TeamViewAPI.DOWNLINE_API : ''}${urlParams}`);
}

// UTILITY
const handleError = makeHandleError('Error getting team view data');

export function* teamviewSagas() {
	yield all([
		takeLatestForActionType(
			GetAllTeamViewData,
			handleGetAllTeamViewData,
		),
		takeLatestForActionType(
			GetTeamViewLeads.started,
			getTeamViewSaga(GetTeamViewLeads, Strings.TeamViewAPI.LEADS),
		),
		takeLatestForActionType(
			GetTeamViewProduction.started,
			getTeamViewSaga(GetTeamViewProduction, Strings.TeamViewAPI.PRODUCTION),
		),
		takeLatestForActionType(
			GetTeamViewQuality.started,
			getTeamViewSaga(GetTeamViewQuality, Strings.TeamViewAPI.QUALITY),
		),
		takeLatestForActionType(
			GetTeamViewActivity.started,
			getTeamViewSaga(GetTeamViewActivity, Strings.TeamViewAPI.ACTIVITY),
		),
		takeLatestForActionType(
			GetTeamView206020.started,
			getTeamViewSaga(GetTeamView206020, Strings.TeamViewAPI.TWENTY_SIXTY_TWENTY),
		),
		takeLatestForActionType(
			GetTeamViewNewAgentConversion.started,
			getTeamViewSaga(GetTeamViewNewAgentConversion, Strings.TeamViewAPI.NEW_AGENT_CONVERSION),
		),
		takeLatestForActionType(
			GetWritingAgentStats.started,
			GetWritingAgentReport(GetWritingAgentStats),
		),
		takeLatestForActionType(
			GetTeamViewSummaryReport.started,
			getTeamViewSaga(GetTeamViewSummaryReport, Strings.TeamViewAPI.SUMMARY),
		),
		takeLatestForActionType(
			GetActiveSalesLeaderCount.started,
			getActiveSLCountSaga(GetActiveSalesLeaderCount),
		),
		takeLatestForActionType(
			GetProfessionalAgents.started,
			getTeamViewSaga(GetProfessionalAgents, Strings.TeamViewAPI.PROFESSIONAL_AGENTS),
		),
		takeLatestForActionType(
			NavigateToAgentPerformance,
			navigateToAgentPerformancePage,
		),
	]);
}