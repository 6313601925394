import { minWidthMatch } from '@optum-uhone-hmkts/rise';
import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { makeOpenExternalLink } from '..';
import { navigateBack, navigateTo, navigateToWithoutAddingToHistory, updateDispositionReturnPath } from '../../actions/navigation_actions';
import { makeCall, sendEmail } from '../phone_email_util';



// GENERIC
export const useToggle = (initialState: boolean, forceInitial?: boolean): [boolean, () => void] => {
    const [isToggled, setIsToggled] = useState(initialState);

    const memoizedSetIsToggled = useCallback(
        () => setIsToggled(toggled => forceInitial ? initialState : !toggled),
        [setIsToggled]
    );

    return [isToggled, memoizedSetIsToggled];
};




// BREAKPOINTS
export const useMediaMatches = (px: number) => {
    const memoizedMatches = useCallback(
        minWidthMatch,
        [px]
    );
    return memoizedMatches(px);
};




// LINKS
export const useLinkToExternal = (link: string): (() => void) => {
    return useCallback(
        makeOpenExternalLink(link),
        [link]
    );
};

export const useOpenEmail = (emailAddress: string): (() => void) => {
    return useCallback(
        () => sendEmail(emailAddress),
        [emailAddress]
    );
};

export const useMakeCall = (phone: string): (() => void) => {
    return useCallback(
        () => makeCall(phone, true),
        [phone]
    );
};




// NAVIGATION
interface NavigationStack {
    navigateBack: () => void;
    navigateTo: (route: string) => void;
    navigateToWithoutAddingToHistory: (route: string) => void;
    updateDispositionReturnPath: (route: string) => void;
}
export const useNavigationProps = (): NavigationStack => {
    const dispatch = useDispatch();
    return {
        navigateBack: () => dispatch(navigateBack()),
        navigateTo: (route: string) => dispatch(navigateTo(route)),
        navigateToWithoutAddingToHistory: (route: string) => dispatch(navigateToWithoutAddingToHistory(route)),
        updateDispositionReturnPath: (route: string) => dispatch(updateDispositionReturnPath(route)),
    };
};

export const useNavigateTo = (route: string): (() => void) => {
    const dispatch = useDispatch();
    return useCallback(
        () => dispatch(navigateTo(route)),
        [route]
    );
};