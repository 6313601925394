import moment from 'moment';

export enum DaysOfTheWeek {
    Sunday = 'Sunday',
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday'
};
export const OrderedDaysOfWeek = [
    DaysOfTheWeek.Sunday,
    DaysOfTheWeek.Monday,
    DaysOfTheWeek.Tuesday,
    DaysOfTheWeek.Wednesday,
    DaysOfTheWeek.Thursday,
    DaysOfTheWeek.Friday,
    DaysOfTheWeek.Saturday
];

export enum ActivityDurations {
    FifteenMinutes = '15 minutes',
    ThirtyMinutes = '30 minutes',
    FourtyFiveMinutes = '45 minutes',
    OneHour = '1 hour',
    HourAndAHalf = '1 hour and 30mins',
    TwoHours = '2 hours'
};
export const OrderedActivityDurations = [
    ActivityDurations.FifteenMinutes,
    ActivityDurations.ThirtyMinutes,
    ActivityDurations.FourtyFiveMinutes,
    ActivityDurations.OneHour,
    ActivityDurations.HourAndAHalf,
    ActivityDurations.TwoHours,
];

export const orderDaysOfWeek = (days: DaysOfTheWeek[]) =>
    days.sort((a, b) => OrderedDaysOfWeek.indexOf(a) - OrderedDaysOfWeek.indexOf(b));

export const orderActivityDurations = (durations: ActivityDurations[]) =>
    durations.sort((a, b) => OrderedActivityDurations.indexOf(a) - OrderedActivityDurations.indexOf(b));

export class DateUtility {
    static DateOnlyToUtc = (date: Date): Date | undefined => {
        if (date) {
            return new Date(date.getFullYear() + '-' +
                ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
                ('0' + date.getDate()).slice(-2));
        }
        return undefined;
    }

    static DateTimeToUtc = (date: Date): Date | undefined => {
        if (date) {
            return new Date(date.getFullYear() + '-' +
                ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
                ('0' + date.getDate()).slice(-2) + 'T' +
                ('0' + date.getHours()).slice(-2) + ':' +
                ('0' + date.getMinutes()).slice(-2) + ':' +
                ('0' + date.getSeconds()).slice(-2) + 'Z');
        }
        return undefined;
    }

    static MomentToUtc = (moment: moment.Moment): moment.Moment | undefined => {
        if (moment) {
            const date: Date = moment.toDate();
            return moment.year(date.getFullYear())
                .month(date.getMonth())
                .date(date.getDate())
                .hour(date.getHours())
                .minute(date.getMinutes())
                .seconds(date.getSeconds());
        }
        return undefined;
    }
}

export enum DateTimeFormat {
    FullDateTime,
    Date,
    DateTime,
    Time,
    DayMonthTime,
    MonthDateTime,
}

const EPOCH_END_DATE = '9999-12-31T23:59:59Z';
const EPOCH_NULL_DATE = '9999-12-31T00:00:00Z';
export const getFormattedDate = (date?: Date | string | null, format = 'MM/DD/YY', localTimezone = true) => {
    if (!date) { return '' }
    const formattedDate = moment.utc(date);
    if (
        formattedDate.valueOf() === moment.utc(EPOCH_NULL_DATE).valueOf() ||
        formattedDate.valueOf() === moment.utc(EPOCH_END_DATE).valueOf()
    ) {
        return '';
    }
    return localTimezone ? formattedDate.local().format(format) : formattedDate.format(format)
}

export const isDefaultDate = (date?: Date | string | null): boolean =>
    !Boolean(getFormattedDate(date));

export function displayDate(
    localDate: Date | moment.Moment | string | undefined,
    dateFormat?: DateTimeFormat,
    leftLabel?: string
): string {
    if (localDate == undefined) {
        return 'Invalid Date';
    }

    const date = moment(localDate);
    const label = leftLabel ? leftLabel.concat(' ') : '';

    switch (dateFormat) {
        case DateTimeFormat.DateTime: {
            return label.concat(
                date.calendar(undefined, {
                    sameDay: '[Today] h:mmA',
                    nextDay: '[Tomorrow] h:mmA',
                    lastDay: '[Yesterday] h:mmA',
                    lastWeek: yearCheck(date),
                    nextWeek: yearCheck(date),
                    sameElse: yearCheck(date),
                })
            );
        }
        case DateTimeFormat.Date: {
            return label.concat(date.format(`MM‑DD‑YYYY`));
        }
        case DateTimeFormat.Time: {
            return label.concat(date.format(`h:mmA`));
        }
        case DateTimeFormat.MonthDateTime: {
            return label.concat(date.format(`MMM Do h:mmA`));
        }
        case DateTimeFormat.DayMonthTime: {
            return label.concat(date.format(`ddd, MMM D h:mmA`))
        }
        case DateTimeFormat.FullDateTime:
            return label.concat(date.format(`MMMM Do YYYY [at] h:mmA`));
        default: {
            return label.concat(
                date.calendar(undefined, {
                    sameDay: '[Today] h:mmA',
                    nextDay: '[Tomorrow] h:mmA',
                    lastDay: '[Yesterday] h:mmA',
                    lastWeek: yearCheck(date),
                    nextWeek: yearCheck(date),
                    sameElse: yearCheck(date),
                })
            );
        }
    }
}

const yearCheck = (m: any) => {
    return moment(m).year() == moment().year() ? `MMM D h:mmA` : `MM-DD-YYYY`;
};

const getItemWithExtremeDate = <T>(max: boolean, list: Array<T>, getDate: (item: T) => Date): T | undefined => {
    let extremeItem: T | undefined = undefined;
    let extremeDate: Date | undefined = undefined;

    const isExtreme = (d: Date) => {
        if (extremeDate) {
            return max ? d > extremeDate : d < extremeDate;
        } else {
            return true;
        }
    };

    list.forEach((item) => {
        const itemDate = getDate(item);
        if (isExtreme(itemDate)) {
            extremeDate = itemDate;
            extremeItem = item;
        }
    });

    return extremeItem;
}

export const getNewestItem = <T>(list: Array<T>, getDate: (item: T) => Date) =>
    getItemWithExtremeDate(true, list, getDate);
export const getOldestItem = <T>(list: Array<T>, getDate: (item: T) => Date) =>
    getItemWithExtremeDate(false, list, getDate);

export const getAgeInYears = (birthDate: Date): number => getAge(birthDate, 'years');
export const getAgeInSeconds = (date: Date): number => getAge(date, 'seconds');

export const getAge = (date: Date, unit: moment.unitOfTime.Diff): number => {
    const timeSinceNowInYears = moment.utc(date).diff(moment.utc(), unit);
    return Math.abs(timeSinceNowInYears);
}

export const getUTCStartOfDay = (date: Date) => {
    return moment.utc([
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
    ]);
};

export const getUTCEndOfDay = (date: Date) => {
    return moment.utc([
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        23,
        59
    ]);
};