import React from 'react';
import { Grid } from '@material-ui/core';
import { ReadOnlyTextField } from '../utility/read_only_text_field';
import { Strings as S } from '../../assets/common/strings'


const generateItem = (title: string, value: string) =>
	<ReadOnlyTextField label={title} value={value} />;
interface BasicInfoProps {
	firstName: string;
	middleName: string;
	lastName: string;
	dateOfBirth: string;
	jobTitle: string;
	status: string;
}
export const BasicInfo = (props: BasicInfoProps) => {
	return (
		<Grid container>
			<Grid container>
				<Grid item xs={12} sm={6} lg={4}>
					{generateItem(S.PersonInfo.FirstName, props.firstName)}
				</Grid>
				<Grid item xs={12} sm={6} lg={4}>
					{generateItem(S.PersonInfo.MiddleName, props.middleName)}
				</Grid>					
				<Grid item xs={12} sm={6} lg={4}>
					{generateItem(S.PersonInfo.LastName, props.lastName)}
				</Grid>
				<Grid item xs={12} sm={6} lg={4}>
					{generateItem(S.PersonInfo.DOB, props.dateOfBirth)}
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12} sm={6} lg={4}>
					{generateItem(S.PersonInfo.Title, props.jobTitle)}
				</Grid>
				<Grid item xs={4}>
				{generateItem(S.PersonInfo.Status, props.status)}
			</Grid>					
			</Grid>
		</Grid>
	);
};
