import { Lookup, DefaultDictionary } from './lookup';
import { LookupData } from '../../actions/lookup_actions';

/**
 * Data structure for storing and manipulating lookups in the front end.
 */
export class LookupDictionary {
	private lookups: Lookup[];

	constructor(newLookups: Lookup[]) {
		this.lookups = newLookups;
	}

	contains(target: Lookup): boolean {
		return this.lookups.some(lookup => {
			if (lookup == target) return true;
			else if (lookup.getChildren().length == 0) return false;
			else return lookup.containsLookup(target);
		});
	}

	/**
	 * Returns the index whose lookup matches the key; otherwise returns -1
	 * @param key id, value, or label to match the lookup
	 * @param type optional parameter to filter on type
	 */
	findIndex(key: string, type?: string): number {
		return this.lookups.findIndex(
			lookup => lookup && lookup.matches(key, type)
		);
	}

	/**
	 * Returns the lookup that matches key; otherwise returns empty (falsey) lookup
	 * @param key id, value, or label to match the lookup
	 */
	findOrCreate(key: string): Lookup {
		const index = this.findIndex(key);
		const lookup = this.lookups[index];
		if (lookup) {
			return lookup;
		} else {
			const newParent = new Lookup(key);
			this.lookups = this.lookups.concat(newParent);
			return newParent;
		}
	}

	/**
	 * Iterates over each lookup and executes a callback
	 * @param callback: the function to be executed on each index
	 */
	forEach = (callback: (currentValue: Lookup, index: number) => void) =>
		this.lookups.forEach(callback);

	/**
	 * Iterates over each lookup and maps them to a new array of objects
	 * @param callback: the function to be executed on each index
	 */
	map = (callback: (currentValue: Lookup, index: number) => any) =>
		this.lookups.map(callback);

	/**
	 * Returns the lookup at a given index
	 * @param index: the index in the lookups array to return
	 */
	get = (index: number): Lookup | undefined => {
		return 0 < index && index < this.lookups.length
			? this.lookups[index]
			: undefined;
	};

	/**
	 * Returns a lookup label that matches the key
	 * @param key: id, value, or label to match the lookup
	 */
	getLabel = (key: string): string => {
		const index = this.findIndex(key);
		return index > -1 ? this.lookups[index].label : DefaultDictionary.findOrCreate(key).label || 'undefined';
	};

	/**
	 * Returns a lookup value that matches the key
	 * @param key: id, value, or label to match the lookup
	 */
	getValue = (key: string): string => {
		const index = this.findIndex(key);
		return index > -1 ? this.lookups[index].value : 'undefined value';
	};

	/**
	 * Returns the children target(s), excluding the target Lookups.
	 * @param targets The parent Lookup(s)
	 */
	getChildren(
		targets: (Lookup | string) | (Lookup | string)[],
		avoidParent?: Lookup
	): Lookup[] {
		const children = Array.isArray(targets)
			? this.lookups.filter(lookup =>
				targets.every(parent => lookup.isChildOf(parent))
			)
			: this.lookups.filter(lookup => lookup.isChildOf(targets));

		return avoidParent
			? children.filter(lookup => lookup.isNotChildOf(avoidParent))
			: children;
	}

	/**
	 * Returns the first child's value.
	 * @param targets The parent Lookup(s)
	 * @param avoidParent Do not return children of this parent
	 */
	getFirstChildOfType(
		targets: (Lookup | string) | (Lookup | string)[],
		avoidParent?: Lookup
	) {
		const children = this.getChildren(targets, avoidParent);
		return children.length > 0 ? children[0].value : '';
	}

	/**
	 * Returns the labels of the target(s) children, exlucind the targets.
	 * @param targets the parent Lookup(s)
	 */
	getLabels = (
		targets: (Lookup | string) | (Lookup | string)[],
		avoidParent?: Lookup
	) => {
		const current = this.getChildren(targets, avoidParent);

		const children = current.length > 0
			? current
			: DefaultDictionary.getChildren(targets, avoidParent);

		return children
			.sort((a, b) =>
				a.sequence != b.sequence
					? a.sequence - b.sequence
					: a.label.localeCompare(b.label)
			).map(lookup => lookup.label);
	}

	/**
	 * Returns true if a lookup is of a give type; otherwise returns
	 * @param type the type being searched for
	 */
	hasType(type: string) {
		return this.lookups.some(lookup => lookup.isChildOf(type));
	}

	/**
	 * Update current lookups with incoming values
	 * @param rawLookups inbound json objects to update cached values
	 */
	updateWithInboundLookups(rawLookups: LookupData[]) {
		rawLookups.forEach(lookup => {
			const key = lookup.type;
			const keyLookup = this.findOrCreate(key);
			const value = lookup.name;
			const lookupIndex = this.findIndex(value, keyLookup.value);
			if (~lookupIndex) {
				const cachedLookup = this.get(lookupIndex);
				if (cachedLookup) {
					cachedLookup.label = value;
				}
			} else {
				this.lookups = this.lookups.concat(
					new Lookup({
						id: '',
						label: value,
						value: value,
						sequence: lookup.sequence,
						parents: [keyLookup],
					})
				);
			}
		});
		const cachedLookups = this.lookups.slice();
		return new LookupDictionary(cachedLookups);
	}
	
	/**
	 * Returns the lookup that matches key; otherwise returns empty (falsey) lookup
	 * @param key id, value, or label to match the lookup
	 */
	find(key: string): Lookup {
		const index = this.findIndex(key);
		const lookup = this.lookups[index];
		if (lookup) {
			return lookup;
		} else {
			const newParent = new Lookup(key);
			this.lookups = this.lookups.concat(newParent);
			return newParent;
		}
	}
}

