import {actionCreatorFactory} from 'typescript-fsa';
import { ContactDedupeDto, ContactDedupeResult } from '../reducers/dedupe_reducer';

const actionCreator = actionCreatorFactory('Dedupe');

export const ContactDedupeCheck = actionCreator.async<
    ContactDedupeDto, 
    ContactDedupeResult[]
>('CONTACT_DEDUPE_CHECK');

export const CloseDedupeDialog = actionCreator('CONTACT_DEDUPE_CHECK');

