import _ from 'lodash';
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Icon,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListSubheader,
    Grow,
} from '@material-ui/core';
import React from 'react';
import { themePalette } from '../../../utilities/branding';
import { selectNext } from '../../../utilities/object_util';
import { enforceStylesType } from '../../../utilities/styles_util';


export type ListSelectionAction = {
    disabled?: boolean;
    style?: React.CSSProperties;
    onClick: (selection: { [key: string]: { id: string, value: string } }) => void;
    enforceSelection?: boolean;
    label: string;
    iconClass?: string;
}

interface SelectDialogProps {
    open: boolean;
    title: string;
    listItems: SelectableItemProps[];
    listTitle: string;
    handleClose: () => void;
    handleSelect: (selection: { id: string, value: string }[]) => void;
    selectActionIconClass: string;
    selectActionLabel: string;
    selectableCount?: number;
    secondaryListTitle?: string;
    secondaryListItems?: SelectableItemProps[];
    secondaryActions?: ListSelectionAction[];
    alternateTitle?: string;
    autoselect?: boolean;
}

interface State {
    selection: Selection;
}

type SelectableItemProps = {
    primary: string;
    secondary: string;
    isDisabled?: boolean;
    value: string;
}
type Selection = { [key: string]: { id: string, value: string } };
export class SelectDialog extends React.Component<SelectDialogProps, State> {

    state = {
        selection: {} as Selection,
    }

    componentDidMount() {
        const { autoselect, listItems, secondaryListItems, selectableCount } = this.props;
        if (autoselect) {
            const items = [...listItems, ...(secondaryListItems ? secondaryListItems : [])];
            const item: any = items[0];
            if (item) {
                const selection: Selection = selectNext(this.state.selection, item.value, selectableCount || Boolean(selectableCount), item);
                this.props.handleSelect(Object.keys(selection).map(key => (selection[key])));
            }
        }
        else {
            this.setState({ selection: {} });
        }
    }

    hasSelectedItem = (): boolean => Boolean(Object.keys(this.state.selection).length);

    onSelection = () => {
        const { selection } = this.state;
        this.props.handleSelect(Object.keys(selection).map(key => (selection[key])));
    }

    handleSelect = (obj: any) => () => {
        const newSelection = selectNext(this.state.selection, obj.value, this.props.selectableCount || Boolean(this.props.selectableCount), obj);
        this.setState({ selection: newSelection });
    }

    renderAction = (
        onClick: (selection?: Selection) => void,
        label: string,
        iconClass?: string,
        disabled?: boolean,
        style?: React.CSSProperties,
    ) => {
        const handleOnClick = () => onClick(this.state.selection);

        return label
            ? (
                <Button
                    key={label}
                    disabled={disabled}
                    style={disabled ? styles.disabled : style}
                    onClick={handleOnClick}
                >
                    {label}
                    {iconClass && <Icon className={iconClass} />}
                </Button>
            )
            : undefined;
    };


    renderDialogActions(): (JSX.Element | undefined)[] {
        const { handleClose, selectActionIconClass, selectActionLabel, } = this.props;

        const actions = [
            (
                <Button 
                    key={'cancel'}
                    color="secondary"
                    onClick={handleClose}>
                    Cancel
                </Button>
            ),
            this.renderAction(
                this.onSelection,
                selectActionLabel,
                selectActionIconClass,
                !this.hasSelectedItem(),
                {
                    backgroundColor: themePalette.accept_button,
                    color: themePalette.negative_text,
                }
            )
        ];

        const secondaryActions: ListSelectionAction[] = this.props.secondaryActions || [];

        secondaryActions.forEach(action => {
            const actionButton = this.renderAction(
                action.onClick,
                action.label,
                action.iconClass,
                action.disabled || (!!action.enforceSelection && !this.hasSelectedItem()),
                action.style,
            );
            actions.push(actionButton);
        });

        return actions;
    }

    renderList = (input?: SelectableItemProps[], title?: string) => {
        const listItems = input || [];

        if (listItems.length) {
            return (
                <List
                    subheader={<ListSubheader
                    key="select-dialog-header"
                        style={{ backgroundColor: themePalette.default_background }}>
                        {title || ''}
                    </ListSubheader>
                    }
                >
                    {listItems.map((item, index) =>
                        <ListItem
                            button
                            key={`${item.primary}${item.secondary}${index}`}
                            onClick={this.handleSelect(item)}
                            disabled={item.isDisabled}
                        >
                            <ListItemAvatar>
                                <Avatar
                                    style={{
                                        backgroundColor: this.state.selection[item.value] ? themePalette.selected_item : themePalette.default_avatar,
                                        alignSelf: 'center',
                                    }}
                                >
                                    <Icon>check</Icon>
                                </Avatar></ListItemAvatar>
                            <ListItemText
                                primary={item.primary}
                                secondary={item.secondary}
                            />
                        </ListItem>
                    )}
                </List>
            );
        }
    }


    render() {
        const {
            open,
            listItems,
            secondaryListItems,
            title,
            listTitle,
            secondaryListTitle,
            alternateTitle,
            autoselect,
        } = this.props;

        return (
            <Dialog
                open={autoselect ? false : open}
                fullWidth
                style={{ margin: '5px' }}
                disableBackdropClick
                TransitionComponent={Grow}
            >
                {alternateTitle && <DialogTitle>
                    <div style={styles.alternateTitle}>{alternateTitle}</div>
                </DialogTitle>}
                <DialogTitle>
                    <div style={{ textAlign: 'left' }}>{title}</div>
                </DialogTitle>
                <DialogContent style={{ padding: 0 }}>
                    {this.renderList(listItems, listTitle)}
                    {this.renderList(secondaryListItems, secondaryListTitle)}
                </DialogContent>
                <DialogActions>
                    {this.renderDialogActions()}
                </DialogActions>
            </Dialog>
        );
    }
}

const styles = enforceStylesType({
    disabled: {
        backgroundColor: themePalette.disabled_button,
        color: themePalette.negative_text
    },
    alternateTitle: {
        textAlign: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }
});