import { TextField } from '@material-ui/core';
import moment from 'moment';
import React from 'react';

import { isDesktop } from '../../utilities/is_mobile';
import { normalizeTimeIE } from '../../utilities/formatting/date_time_formatting';

const bowser = require('bowser');

interface Props {
	label: string;
	fullWidth?: boolean;
	disabled?: boolean;
	minDate?: Date;
	maxDate?: Date;
	meta: {
		dirty: boolean;
		error: string | undefined;
		invalid: boolean;
		pristine: boolean;
		touched: boolean;
		valid: boolean;
		visited: boolean;
		warning: string | undefined;
	};
	input: {
		value: Date;
		onChange: (value: Date) => void;
	};
	handleChange?: (value: Date) => void | Date;
}

interface State {
	internalValue: string;
}

const noHTML5 =
	isDesktop() &&
	(bowser.name === 'Internet Explorer' || bowser.name === 'Safari');
const timeFormat = 'HH:mm';
class TimePicker extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		const momentValue = moment(props.input.value);
		this.state = {
			internalValue: momentValue.isValid()
				? momentValue.format(timeFormat)
				: '',
		};
	}

	onChange = (event: any) => {
		if(noHTML5) {
			const value = normalizeTimeIE(event.target.value);
			this.setState({
				internalValue: value,
			});
		}
		const val = this.props.input.value
			? moment(this.props.input.value)
			: moment();
		const [hour, minute] = event.target.value.split(':');
		val.set({
			hour: Number(hour),
			minute: Number(minute),
		});
		if (val.isValid()) {
			let dateVal = val.toDate();
			if (this.props.handleChange) {
				const changedVal = this.props.handleChange(dateVal);
				if (changedVal && noHTML5) {
					dateVal = changedVal;
				}
			}
			this.props.input.onChange(dateVal);
		}
	};

	render() {
		const {
			meta,
			input,
			minDate,
			maxDate,
			handleChange,
			...config
		} = this.props;
		const { error, warning } = meta;
		const errorText = error || warning;
		const errorCheck = !!errorText;
		const inputProps: any = {};
		if (minDate) inputProps.min = moment(minDate).format(timeFormat);
		if (maxDate) inputProps.max = moment(maxDate).format(timeFormat);
		const trueValue = noHTML5
			? this.state.internalValue
			: moment(input.value).format(timeFormat);

		return (
			<TextField
				{...config}
				error={errorCheck}
				helperText={errorText}
				value={trueValue}
				onChange={this.onChange}
				inputProps={inputProps}
				type={noHTML5 ? 'text' : 'time'}
			/>
		);
	}
}

export default TimePicker;
