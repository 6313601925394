import React from 'react';
import { AdvancedSearchFilterForm, ADVANCED_SEARCH_FORM_NAME } from './advanced_search_filter_form';
import { SaveCancelHeaderBarComponent } from '../../components/Layout/SaveCancelHeaderBar';
import { AppState } from '../../reducers';
import { getFormValues } from 'redux-form';
import moment from 'moment';
import { connect } from '@optum-uhone-hmkts/rise';
import {
	SaveAdvSearchFormFilters,
	selectSavedSearchName,
	SetAdvSearchSelectAllFlag,
	StoreAdvancedSearchFilters,
} from '../../actions/advanced_search_actions';
import {
	AdvSearchFilterPresetDto,
	DEFAULT_UNSELECTED,
	SearchFilterFormValues,
} from '../../reducers/advanced_search_reducer';
import _ from 'lodash';
import { LookupDictionary } from '../../utilities/lookup';
import { ExpandFilterFields } from '../../actions/advanced_search_actions';
import { BaseFilterForm } from '../../components/search/base_filter_form';
import { Strings } from '../../assets/common/strings';

interface ParentProps {
	handleCancel: () => void;
	executeAdvancedSearch: (
		searchFilterObject: SearchFilterFormValues,
		isBLeadSearch: boolean,
		processId?: string
	) => void;
	isDrawerOpen: boolean;
	currentFormFilters: SearchFilterFormValues;
}

interface DispatchProps {
	saveAdvSearchFormFilters: (filters: SearchFilterFormValues) => void;
	storeAdvancedSearchFilters: (filters: SearchFilterFormValues) => void;
	selectSavedSearchName: (savedSearchName: string) => void;
	setAdvSearchSelectAllFlag: (value: boolean) => void;
	expandFilterFields: (filterFieldGroup: string) => void;
}

interface StateProps {
	processId?: string;
	formValues: SearchFilterFormValues;
	selectedSavedSearchName: string;
	corporateFilters: AdvSearchFilterPresetDto[];
	userSavedFilters: AdvSearchFilterPresetDto[];
	lookups: LookupDictionary;
	syncErrors: any;
	expandedStates: any;
}

type AdvancedSearchFilterProps = ParentProps & DispatchProps & StateProps;

interface AdvancedSearchFilterState {
	initialFilterValues: SearchFilterFormValues;
}

class AdvancedSearchFilterComponentForm extends React.Component<
	AdvancedSearchFilterProps,
	AdvancedSearchFilterState
	> {
	initialFilters: SearchFilterFormValues = {
		sortBy: 'Modified Date Descending',
		consumerType: '',
		leadCreatedDateSearch: '',
		leadContactedDateSearch: '',
		openToDoInNextDaysSearch: '',
		searchAcrossPrimaryOnly: 'Primary Only',
		showPrimaryResultsOnly: 'Each Person', // This field is defaulted to each person so that we can control
		// both fields using searchAcross until we enhance the backend
		geoRadius: '',
		searchBy: '',
		agency: '',
		channel: '',
	};

	constructor(props: any) {
		super(props);
		if (Object.keys(props?.currentFormFilters || []).length > 0) {
			this.state = {
				initialFilterValues: props.currentFormFilters,
			};
		} else {
			this.state = {
				initialFilterValues: this.initialFilters,
			};
		}
	}

	applySearchFilters = () => {
		let sentFilter = _.cloneDeep(this.props.formValues) as any || {};
		if (sentFilter.openToDoInNextDaysFrom) {
			sentFilter.openToDoInNextDaysFrom = moment.utc(
				sentFilter.openToDoInNextDaysFrom
			);
		}
		if (sentFilter.openToDoInNextDaysTo) {
			sentFilter.openToDoInNextDaysTo = moment.utc(
				sentFilter.openToDoInNextDaysTo
			);
		}
		if (sentFilter.leadContactedDateFrom) {
			sentFilter.leadContactedDateFrom = moment.utc(
				sentFilter.leadContactedDateFrom
			);
		}
		if (sentFilter.leadContactedDateTo) {
			sentFilter.leadContactedDateTo = moment.utc(
				sentFilter.leadContactedDateTo
			);
		}
		if (sentFilter.leadCreatedDateFrom) {
			sentFilter.leadCreatedDateFrom = moment.utc(
				sentFilter.leadCreatedDateFrom
			).subtract(moment().utcOffset(), 'minutes');
		}
		if (sentFilter.leadCreatedDateTo) {
			sentFilter.leadCreatedDateTo = moment.utc(
				sentFilter.leadCreatedDateTo
			).subtract(moment().utcOffset(), 'minutes');
		}

		if (sentFilter.leadStatusCode) {
			let mappedStatuses: string[] = [];
			sentFilter.leadStatusCode.forEach((statusCode: string) => {
				if (statusCode == 'No Sale') {
					mappedStatuses.push('Lost');
				} else if (statusCode == 'Sale') {
					mappedStatuses.push('Won');
				} else {
					mappedStatuses.push(statusCode);
				}
			});
			sentFilter.leadStatusCode = mappedStatuses;
		}
		this.props.saveAdvSearchFormFilters(this.props.formValues);
		this.props.storeAdvancedSearchFilters(this.props.formValues);
		let isBLeadSearch: boolean = false;
		if (
			this.props.formValues &&
			this.props.formValues.consumerType &&
			this.props.formValues.consumerType === Strings.ASearch.BLeads
		) {
			isBLeadSearch = true;
		}
		this.props.setAdvSearchSelectAllFlag(false);
		this.props.executeAdvancedSearch(sentFilter, isBLeadSearch, this.props.processId);
		this.props.handleCancel();
	};

	collapseExpandables = () => {
		for (var fieldGroup in this.props.expandedStates) {
			if (this.props.expandedStates.hasOwnProperty(fieldGroup)) {
				if (this.props.expandedStates[fieldGroup]) {
					// if expanded collapse
					this.props.expandFilterFields(fieldGroup);
				}
			}
		}
	};

	componentWillReceiveProps(nextProps: AdvancedSearchFilterProps) {
		if (
			nextProps.selectedSavedSearchName !== this.props.selectedSavedSearchName
		) {
			let allFilterPresets: AdvSearchFilterPresetDto[] = this.props.corporateFilters.concat(
				this.props.userSavedFilters
			);
			let matchingIndex: number = allFilterPresets.findIndex(
				(filterPreset: AdvSearchFilterPresetDto) => {
					return filterPreset.name === nextProps.selectedSavedSearchName;
				}
			);
			if (matchingIndex !== -1) {
				this.setState({
					initialFilterValues: allFilterPresets[matchingIndex].filters,
				});
			} else {
				this.setState({
					initialFilterValues: this.initialFilters,
				});
			}
		}
	}

	resetForm = () => {
		this.props.selectSavedSearchName(DEFAULT_UNSELECTED);
		this.setState({ initialFilterValues: this.initialFilters });
		this.collapseExpandables();
	};

	render() {
		return (
			<>
				<SaveCancelHeaderBarComponent
					title={'Filter'}
					onSave={this.applySearchFilters}
					isSaveDisabled={this.props.syncErrors}
					saveText={'Apply'}
					onCancel={() => {
						this.props.saveAdvSearchFormFilters(this.props.formValues);
						this.props.handleCancel();
					}}
					overrideCancelDialog={true}
				/>
				<BaseFilterForm
					submit={this.applySearchFilters}>
					<AdvancedSearchFilterForm
						initialValues={this.state.initialFilterValues}
						formValues={this.props.formValues}
						resetForm={this.resetForm}
					/>
				</BaseFilterForm>
			</>
		);
	}
}

function mapDispatchToProps(dispatch: any): DispatchProps {
	return {
		saveAdvSearchFormFilters: (filters: SearchFilterFormValues) =>
			dispatch(SaveAdvSearchFormFilters({ filters })),
		storeAdvancedSearchFilters: (filters: SearchFilterFormValues) =>
			dispatch(StoreAdvancedSearchFilters({ filters })),
		selectSavedSearchName: (savedSearchName: string) =>
			dispatch(selectSavedSearchName(savedSearchName)),
		setAdvSearchSelectAllFlag: (value: boolean) =>
			dispatch(SetAdvSearchSelectAllFlag(value)),
		expandFilterFields: (filterFieldGroup: string) =>
			dispatch(ExpandFilterFields(filterFieldGroup)),
	};
}

function mapStateToProps(state): StateProps {
	

	let expandedStates = state.advancedSearch.expandedStates;
	return {
		processId: state.advancedSearch.processId,
		lookups: state.lookup,
		formValues: getFormValues(ADVANCED_SEARCH_FORM_NAME)(state),
		selectedSavedSearchName: state.advancedSearch.selectedSavedSearchName,
		corporateFilters: state.advancedSearch.corporateFilters,
		userSavedFilters: state.advancedSearch.userSavedFilters,
		syncErrors: _.get(state, `form.${ADVANCED_SEARCH_FORM_NAME}.syncErrors`),
		expandedStates: expandedStates,
	};
}

export const AdvancedSearchFilterComponent = connect(
	mapStateToProps,
	mapDispatchToProps, true
)(AdvancedSearchFilterComponentForm) as React.ComponentClass<
	Partial<AdvancedSearchFilterProps>
>;
