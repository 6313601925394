import { List, Icon, Typography, ListItem, ListItemText } from '@material-ui/core';
import React from 'react';
import { Contact } from '../../reducers/ContactReducer';
import { ContactInfoList } from './contact_info_list';
import { navRoutes } from '../nav/Routes';
import { getAgeFromDate } from '../../utilities/utilities';
import { SubtitleProps, CustomListItem } from '../utility/custom_list_item';
import { composePhoneNumberFromContact, composeAddressFromContact, composeNameGenderDobAge } from '../../utilities/contact_util';
import { themePalette } from '../../utilities/branding';
import { Strings } from '../../assets/common/strings';
import { fullName } from '../../assets/common/string_builders';
import { wrapInCollapsibleCard } from '../higher_order_component/wrap_in_card';
import { SimpleListItem } from '../utility/simple_list_item';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

interface Props {
	contact: Contact;
	navigateTo: (route: string) => void;
	desktopCallEnabled: boolean;
	history: any;
	phoneClick: () => void;
}

const PrimaryContact = (props: Props) => {
	if (!props.contact)
		return (
			<span>
				<Typography variant="subtitle1" style={{ paddingLeft: 16 }}>
					Contact Info
				</Typography>
			</span>
		);

	const nameToDisplay = fullName(props.contact, 'Primary Contact');

	const subtitles: SubtitleProps[] = [];
	
	const header = composeNameGenderDobAge(props.contact);
	const phoneNumber = composePhoneNumberFromContact(props.contact);
	const address = composeAddressFromContact(props.contact);

	if (phoneNumber) subtitles.push({ value: phoneNumber });
	if (address) subtitles.push({ value: address });
	const key = 'primary-contact' + props.contact.id;
	return (
		<>
			<List style={{ padding: 0 }}>
				{props.contact && props.contact.employerPrimaryContact ? 
					<CustomListItem
						key={key}
						id={key}
						header={header}
						subtitles={subtitles}
						leftColumnSize={2}
						primaryColumnSize={10}
						leftActionElement={<AccountBoxIcon style={{ color: themePalette.default_avatar }}/>}
						onClick={() =>
							props.navigateTo(
								navRoutes.contactDetail.path
									.replace(Strings.Navigation.HouseholdId, props.contact.householdId)
									.replace(Strings.Navigation.ContactId, props.contact.id)
							)
						}
					/>
					: <SimpleListItem title="No primary contact set"/>
				}
			</List>
			{props.contact &&
				props.contact.employerPrimaryContact &&
				((props.contact.addresses && props.contact.addresses.length > 0) ||
					(props.contact.phones && props.contact.phones.length > 0) ||
					(props.contact.emails && props.contact.emails.length > 0)) ? (
					[
						<ContactInfoList
							phoneClick={props.phoneClick}
							key="primary-contact-info-list"
							contact={props.contact}
						/>,
					]
				) : (
					props.contact.employerPrimaryContact && 
					<Typography variant="subtitle1" style={{ paddingLeft: 16 }}>
						No contact information for {nameToDisplay}
					</Typography>
				)}
		</>
	);
};


export const PrimaryContactCard = wrapInCollapsibleCard(PrimaryContact);