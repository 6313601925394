import { isType } from 'typescript-fsa';
import {
	QueueSnackbar,
	DequeueSnackbar,
	ClearSnackbar,
	SnackbarProps,
	SetDeviceConnectionStatus,
	SetShowedOfflineSnackBar,
} from '../actions/snackbar_actions';

export interface SnackbarState {
	queue: SnackbarProps[];
	open: boolean;
	deviceIsOnline: boolean;
	showedOfflineSnackBar: boolean;
	current?: SnackbarProps;
}

const initialState = {
	queue: [],
	open: false,
	current: undefined,
	deviceIsOnline: true,
	showedOfflineSnackBar: false,
};

export function snackbarReducer(
	state: SnackbarState = initialState,
	action: any
): SnackbarState {
	if (isType(action, QueueSnackbar)) {
		const newQueue = state.queue.concat(action.payload);
		return {
			...state,
			queue: newQueue,
		};
	} else if (isType(action, DequeueSnackbar)) {
		const nextSnackbar = state.queue.shift();
		const open = !!nextSnackbar
		return {
			...state,
			current: nextSnackbar || {},
			queue: state.queue,
			open,
		};
	} else if (isType(action, ClearSnackbar)) {
		return {
			...state,
			open: false,
		};
	} else if (isType(action, SetDeviceConnectionStatus)) {
		return {
			...state,
			deviceIsOnline: action.payload.connectionStatus,
		};
	} else if (isType(action, SetShowedOfflineSnackBar)) {
		return {
			...state,
			showedOfflineSnackBar: action.payload.showedOfflineSnackBar,
		};
	} else {
		return state;
	}
}
