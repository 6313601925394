import React from 'react';
import {
	Avatar,
	Icon,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
} from '@material-ui/core';
import { themePalette } from '../../../utilities/branding';

interface ComponentProps {
	listName: string;
	sectionColumnNames: string[];
	selectedColumns: string[];
	isSectionSelected: boolean;
	selectColumn: (columnName: string) => void;
	selectOrDeselectSection: () => void;
}

type Props = ComponentProps; 

export class ExportResultsColumnSection extends React.Component<Props> {
	getColumnAvatar = (columnName: string, isSectionSelected?: boolean) => {
		let backgroundPaletteColor = themePalette.unselected_item;
		if (this.props.selectedColumns.indexOf(columnName) !== -1 || isSectionSelected) {
			backgroundPaletteColor = themePalette.selected_item;
		}
		return (
			<Avatar
				style={{
					width: '20px',
					height: '20px',
					color: themePalette.default_avatar,
					backgroundColor: backgroundPaletteColor,
					alignSelf: 'center',
				}}
			>
				<Icon>check</Icon>
			</Avatar>
		);
	};

	render(){
		return (
			<List
				key={this.props.listName}
				style={{ marginBottom: '16px', marginLeft: '16px' }}
			>
				<ListItem
					style={{ padding: '2px 16px 2px 16px' }}
					button={true}
					onClick={() => this.props.selectOrDeselectSection()}
				>
					<ListItemAvatar>
						{this.getColumnAvatar('', this.props.isSectionSelected)}
					</ListItemAvatar>
					<ListItemText
						primary={this.props.isSectionSelected ? 'Deselect All' : 'Select All'}
					/>
				</ListItem>
				{this.props.sectionColumnNames.map((colName, index) => {
					return (
						<ListItem
							style={{ padding: '2px 16px 2px 16px' }}
							button={true}
							onClick={() => this.props.selectColumn(colName)}
							key={this.props.listName + '-' + index}
						>
							<ListItemAvatar>{this.getColumnAvatar(colName)}</ListItemAvatar>
							<ListItemText primary={colName} />
						</ListItem>
					);
				})}
			</List>
		);
	}
};