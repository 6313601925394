import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import { themeMisc } from '../../utilities/branding/misc';
import { BaseFilterForm } from '../search/base_filter_form';

interface Props {
    values?: string[];
    addValue: (value: string) => void;
    removeValue: (value: string) => void;
    label?: string;
    maxLength?: number;
    maxValues?: number;
    allowEnterKey?: boolean;
};
export const MultiText: React.FC<Props> = (props) => {

    const { values = [], addValue, removeValue, label = '', maxLength = 30, maxValues = themeMisc.tagsThreshold, allowEnterKey = false } = props;

    const [textValue, setTextValue] = useState('');
    const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => setTextValue(event.target.value);

    const add = () => {
        if (textValue && addValue && values.length < maxValues) {
            addValue(textValue);
            setTextValue('');
        }
    };
    const remove = (tag: string) => (e: React.ChangeEvent<{}>) => {
        e.stopPropagation();
        removeValue && removeValue(tag);
    };

    const field = (
        <TextField
            onChange={handleChangeValue}
            value={textValue}
            fullWidth
            inputProps={{ maxLength }}
            label={label}
        />
    );

    return (
        <>
            <Grid container direction="row" alignItems="center">
                <Box width="85%">
                    {allowEnterKey
                        ? (
                            <BaseFilterForm
                                submit={add}
                                hideOverflow
                            >
                                {field}
                            </BaseFilterForm>
                        )
                        : field
                    }
                </Box>
                <Box width="15%">
                    <Button onClick={add} disabled={values.length >= maxValues}><Icon>add</Icon></Button>
                </Box>
            </Grid>

            <Grid container direction="row">
                {values.map(tag => {
                    return (
                        <Grid key={tag} item style={{ paddingTop: 4, paddingLeft: 2, paddingRight: 2 }}>
                            <Chip
                                label={tag}
                                onDelete={remove(tag)}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );

};