import React from 'react';
import { TextField, Menu, MenuItem, Paper } from '@material-ui/core';
import { isIOS } from '../../utilities/is_mobile';
const bowser = require('bowser');

type Props = {
	label: JSX.Element;
	fullWidth?: boolean;
	resultSet: string[];
	styleObject?: object;
	input: {
		value: any;
		onChange: (value: string) => void;
	};
	meta: {error?: string};
	inputLabelProps?: any;
	initialValue?: string;
	autoComplete?: string;
};

type State = {
	currentResultSet: string[];
	searchText: string;
	selected: boolean;
	invalidItem: boolean;
	currentItem: string;
	autocompleteMenuOpen: boolean;
	anchorEl?: HTMLElement;
};

class SingleSelect extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			currentResultSet: props.resultSet.slice(),
			invalidItem: false,
			selected: false,
			searchText: props.initialValue || '',
			currentItem: props.initialValue || '',
			autocompleteMenuOpen: false,
		};
	}

	componentDidUpdate() {
		if (
			this.props.input.value.length == 0 &&
			this.props.resultSet.length > this.state.currentResultSet.length
		) {
			this.resetResultSet();
		}
	}

	componentWillReceiveProps(nextProps: any) {
		if (nextProps.initialValue !== this.state.searchText) {
			this.setState({
				searchText: nextProps.initialValue,
				currentItem: nextProps.initialValue,
			});
		}
	}

	resetResultSet = () => {
		this.setState({
			currentResultSet: this.props.resultSet,
		});
	};

	autocompleteOnResultItemSelected = (item: string) => {
		let updatedCurrentResultSet: string[] = this.props.resultSet.slice();

		this.setState({
			autocompleteMenuOpen: false,
			searchText: item,
			currentItem: item,
			selected: true,
			currentResultSet: updatedCurrentResultSet,
		}, () => {
			this.props.input.onChange(item);
		});
		const inputValue: string[] = this.props.input.value;
		const updatedSelectedValues = Array.isArray(inputValue)
			? inputValue.slice()
			: [];
		this.validItem(item, this.props.resultSet);
		updatedSelectedValues.push(item);
	};

	renderAutocompleteResults = () => {
		const resultItems = this.state.currentResultSet
			.filter(
				item =>
					this.state.searchText && 
					item && 
					item.toLowerCase().indexOf(this.state.searchText.toLowerCase()) != -1
			)
			.map(item => {
				return (
					<MenuItem
						key={item}
						value={item}
						onClick={() => this.autocompleteOnResultItemSelected(item)}
						autoFocus={false}
					>
						{item}
					</MenuItem>
				);
			});
		return isIOS || bowser.name === 'Internet Explorer' ? (
			this.state.autocompleteMenuOpen &&
				resultItems.length && (
					<Paper style={{ marginTop: 10, maxHeight: 300, overflow: 'scroll' }}>
						{resultItems}
					</Paper>
				)
		) : (
			<Menu
				anchorEl={this.state.anchorEl}
				open={this.state.autocompleteMenuOpen && resultItems.length > 0}
				onClose={() => {
					if (!this.state.selected) {
						this.setState({ autocompleteMenuOpen: false, searchText: '' });
					} else {
						this.setState({ autocompleteMenuOpen: false });
					}
				}}
				style={{ marginTop: 50 }}
				MenuListProps={{ style: { maxHeight: 300 } }}
				onEnter={() => this.state.anchorEl!.focus()}
				autoFocus={false}
				disableAutoFocus
				disableEnforceFocus
				disableAutoFocusItem
			>
				{resultItems}
			</Menu>
		);
	};

	validItem = (value: string, results: any) => {
		let index = results.indexOf(value);
		let invalid = index === -1 ? true : false;
		this.setState({ invalidItem: invalid });
	};

	handleChange = (event: object) => {
		let value = (event as any).target.value;
		if (this.state.selected && value.trim() != '') {
			return this.setState({
				searchText: value[value.length - 1],
				autocompleteMenuOpen: true,
				selected: false,
			});
		}
		this.props.input.onChange(value);
		this.validItem(value, this.props.resultSet);
		this.setState({
			searchText: value,
			selected: false,
			autocompleteMenuOpen: true,
		});
	};

	render() {
		return (
			<div>
				<TextField
					InputProps={{
						onFocus: event => this.setState({ anchorEl: event.currentTarget }),
					}}
					label={this.props.label}
					InputLabelProps={this.props.inputLabelProps}
					onKeyDown={key => {
						if (this.state.selected && key.keyCode === 8) {
							this.handleChange({target: {value: ''}})
						}
					}}
					onClick={(event: any) => (event.target as HTMLInputElement).focus()}
					onChange={this.handleChange}
					fullWidth={this.props.fullWidth}
					value={this.state.searchText}
					helperText={this.state.invalidItem ? 'Not Found' : this.props.meta?.error}
					error={!!this.props?.meta?.error}
					style={this.props.styleObject}
					autoComplete={this.props.autoComplete}
				/>
				{this.renderAutocompleteResults()}
			</div>
		);
	}
}

export default SingleSelect;
