import React from 'react';
import { Avatar, Icon } from '@material-ui/core';
import { themePalette } from '../../../utilities/branding';
import { Phone } from '../../../reducers/ContactReducer';

type Props = {
	phone: Phone | string;
	avatar?: boolean;
	onClick?: () => void;
	style?: React.CSSProperties;
};

export const PhoneIcon = (props: Props) => {
	let color = themePalette.sub_text;
	let icon = 'icon-call-check-bttn';
	let fontSize = 35;

	if (isPhone(props.phone)) {
		const variant = getPhoneVariant(props.phone);

		switch (variant) {
			case PhoneVariant.Preferred: {
				color = themePalette.accept_button;
				break;
			}
			case PhoneVariant.NationalDnc: {
				color = themePalette.national_dnc;
				icon = 'icon-dnc-ntl';
				fontSize = 20;
				break;
			}
			case PhoneVariant.CompanyDnc: {
				color = themePalette.company_dnc;
				icon = 'icon-dnc-hm';
				fontSize = 20;
				break;
			}
		}
	}

	if (props.avatar) {
		return (
			<Avatar
				style={{
					color: themePalette.negative_avatar,
					backgroundColor: color,
					...props.style,
				}}
				onClick={props.onClick}
			>
				<Icon className={icon} style={{ fontSize: fontSize }} />
			</Avatar>
		);
	} else {
		return (
			<Icon
				onClick={props.onClick}
				className={icon}
				style={{
					color: color,
					...props.style,
				}}
			/>
		);
	}

};


const isPhone = (input: any): input is Phone => {
	const phone = input as any as Phone;

	if (phone && phone.type && phone.number)
		return true;
	return false;
}

export enum PhoneVariant {
	NationalDnc = 'nationalDNC',
	CompanyDnc = 'companyDNC',
	Preferred = 'preferred',
	Other = '',
}


export const getPhoneVariant = (phone: Phone) =>
	phone.isCompanyDnc
		? PhoneVariant.CompanyDnc
		: phone.isNationalDnc
			? PhoneVariant.NationalDnc
			: phone.isPreferred ? PhoneVariant.Preferred : PhoneVariant.Other;
