import React from 'react';
import _ from 'lodash';
import { connect } from '@optum-uhone-hmkts/rise';
import H from 'history';
import { NavigationProps, navRoutes } from '../nav/Routes';
import { SaveCancelHeaderBarComponent } from '../../components/Layout/SaveCancelHeaderBar';
import { Employer } from '../../reducers/employer_reducer';
import { SearchEmployers } from '../../actions/employer_actions';
import { UpdateContact } from '../../actions/contact_actions';
import { Loaded } from '../../utilities/utilities';
import { LiveSearch } from '../../components/search/live_search';
import { Contact, Address } from '../../reducers/ContactReducer';
import {
	Dialog,
	DialogContent,
	DialogActions,
	Button,
	Avatar,
	Icon,
	CircularProgress,
} from '@material-ui/core';
import { themePalette } from '../../utilities/branding';
import { QueueSnackbar, SnackbarProps } from '../../actions/snackbar_actions';
import {
	navigateToWithoutAddingToHistory,
	navigateBack,
	navigateTo,
} from '../../actions/navigation_actions';
import { FabMenu } from '../../components/nav/fab_menu';
import { Strings } from '../../assets/common/strings';
import { fullName } from '../../assets/common/string_builders';
import { getSnackbarSuccessProps } from '../../utilities/snackbar_util';
import { DesktopPadding } from '../Layout/desktop_padding';

interface AddEmployerProps {
	isOpen: boolean;
	onClose: () => void;
	currentContact: Contact;
	history: H.History;
}

interface StateProps {
	employers: Loaded<Employer>[];
	contacts: Loaded<Contact>[];
	searching: boolean;
}

interface DispatchProps {
	searchEmployers: (searchText: string) => void;
	updateContact: (contact: Contact) => void;
	queueSnackbar: (props: SnackbarProps) => void;
	navigateToWithoutAddingToHistory: (route: string) => void;
	navigateBack: () => void;
	navigateTo: (route: string) => void;
}

interface State {
	employerSearchString: string;
	selectedEmployerId: string;
	isSaveDialogOpen: boolean;
	isSaveDisabled: boolean;
	isDialogOpen: boolean;
}

type Props = AddEmployerProps & StateProps & DispatchProps & NavigationProps;

class AddEmployer extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			employerSearchString: '',
			selectedEmployerId: '',
			isSaveDialogOpen: false,
			isSaveDisabled: true,
			isDialogOpen: this.props.isOpen,
		};
		this.showDialog = this.showDialog.bind(this);
	}

	componentWillReceiveProps(nextProps: Props) {
		this.setState({
			isDialogOpen: nextProps.isOpen,
		});
	}

	handleSearch = (searchString: string) => {
		if (searchString.length >= 2) {
			this.props.searchEmployers(searchString);
		}
		this.setState({
			employerSearchString: searchString,
		});
	};

	mapEmployersToSearchResults = () => {
		const searchTokens = this.state.employerSearchString.split(' ');

		let primaryContact: Contact | undefined;
		const filteredEmployers = this.props.employers.filter(loadedEmployer => {
			const employer = loadedEmployer.data;

			const primaryContactIndex = _.findIndex(employer.contacts, contact => {
				return contact.employerPrimaryContact;
			});

			if (primaryContactIndex > -1) {
				primaryContact = employer.contacts[primaryContactIndex];
			} else if (employer.contacts && employer.contacts.length > 0) {
				primaryContact = employer.contacts[0];
			}

			let emails = [] as string[];
			let addresses = [] as string[];
			let phones = [] as string[];

			if (primaryContact) {
				emails = primaryContact.emails.map(email => email.emailAddress);
				addresses = primaryContact.addresses.map(
					address => address.city + ' ' + address.state + ' ' + address.zipCode
				);
				phones = primaryContact.phones.map(phone => phone.number);
			}

			const searchArray = [employer.companyName].concat(
				emails,
				addresses,
				phones
			);

			let searchString = searchArray.join().toLowerCase();

			searchString = searchString.replace(/\s/g, '');

			const matchSearch = searchTokens.every(
				token => searchString.indexOf(token.toLowerCase()) > -1
			);
			return matchSearch;
		});

		const employers = filteredEmployers.map(employer => {
			const avatar = (
				<Avatar
					style={{
						alignSelf: 'center',
						backgroundColor: themePalette.default_avatar,
					}}
				>
					<Icon>check</Icon>
				</Avatar>
			);

			const contactIndex = _.findIndex(employer.data.contacts, contact => {
				return contact.employerPrimaryContact;
			});

			if (contactIndex > -1) {
				primaryContact = employer.data.contacts[contactIndex];
			}

			let address: Address | undefined;
			if (primaryContact) {
				const addressIndex = _.findIndex(primaryContact.addresses, address => {
					return address.isPreferred;
				});

				if (addressIndex > -1) {
					address = primaryContact.addresses[addressIndex];
				} else if (primaryContact.addresses.length > 0) {
					address = primaryContact.addresses[0];
				}
			}

			let numEmployees: number = 0;
			if (employer.data.numberOfEmployees) {
				numEmployees = employer.data.numberOfEmployees;
			}

			let industry: string = 'no specified industry';
			if (employer.data.industry) {
				industry = employer.data.industry;
			}

			let city: string = '';
			let state: string = '';
			let zipCode: string = '';
			if (address) {
				if (address.city) {
					city = address.city;
				}
				if (address.state) {
					state = address.state;
				}
				if (address.zipCode) {
					zipCode = address.zipCode;
				}
			} else {
				city = 'No address found';
			}
			const secondString = `${city} ${state} ${zipCode}, \n ${
				numEmployees
				} employee(s),
            ${industry}`;

			return {
				avatar: avatar,
				resultId: employer.data.id,
				primaryText: employer.data.companyName,
				secondaryText: secondString,
			};
		});
		employers.sort((a, b) => {
			if (a.primaryText < b.primaryText) return -1;
			if (a.primaryText > b.primaryText) return 1;
			return 0;
		});
		return employers;
	};

	selectEmployer = (employerId: string) => {
		this.setState({
			selectedEmployerId: employerId,
		});

		if (employerId) {
			this.setState({
				isSaveDisabled: false,
			});
		} else {
			this.setState({
				isSaveDisabled: true,
			});
		}
	};

	addEmployer = () => {
		if (this.state.selectedEmployerId) {
			const snackBarProps: SnackbarProps = getSnackbarSuccessProps('Employer added');
			const contactToUpdate = _.cloneDeep(this.props.currentContact);
			contactToUpdate.employerId = this.state.selectedEmployerId;
			this.props.updateContact(contactToUpdate);
			this.props.queueSnackbar(snackBarProps);
		}
		this.setState({
			isSaveDialogOpen: false,
		});
		this.props.onClose();
	};

	showDialog() {
		this.setState({
			isSaveDialogOpen: true,
		});
	}

	closeDialog = () => {
		this.setState({
			isSaveDialogOpen: false,
		});
	};

	navigateToAddEmployerLead = () => {
		this.props.navigateTo(
			navRoutes.employerLeadUpsert.path
				.replace(Strings.Navigation.LeadId, '0')
				.replace(Strings.Navigation.EmployerId, '0')
		);
	};

	renderDialog() {
		let companyName = '';

		const contact = this.props.currentContact;
		const personName = fullName(contact);

		const employerIndex = _.findIndex(this.props.employers, employer => {
			return employer.data.id === this.state.selectedEmployerId;
		});

		if (employerIndex > -1) {
			companyName = this.props.employers[employerIndex].data.companyName;
		}

		return (
			<Dialog open={this.state.isSaveDialogOpen} onClose={this.closeDialog}>
				<DialogContent>
					Are you sure you want to add {personName} to {companyName}?
				</DialogContent>
				<DialogActions>
					<Button
						color="secondary"
						onClick={() => {
							this.closeDialog();
						}}
					>
						Cancel
					</Button>
					<Button
						color="primary"
						variant="contained"
						onClick={this.addEmployer}
						style={{ backgroundColor: themePalette.accept_button }}
					>
						Link
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	render() {
		const narrowScreen = window.innerWidth < 420;
		const searchResults = this.mapEmployersToSearchResults();
		const label = (
			<span style={{ fontSize: narrowScreen ? 12 : 16 }}>
				Enter company name, phone, email, city, state, zip
			</span>
		);

		return (
			<Dialog 
				open={this.props.isOpen} 
				fullScreen
				PaperProps={{ style: { backgroundColor: themePalette.secondary_background } }}
			>
				<SaveCancelHeaderBarComponent
					title={'Find Employer'}
					onSave={this.showDialog}
					isSaveDisabled={this.state.isSaveDisabled}
					onCancel={() => this.props.onClose()}
					saveText={'Link'}
					bypassCancelConfirmation
					isLoading={this.props.searching}
				/>
				<DesktopPadding style={{ overflowY: 'scroll' }}>
					<LiveSearch
						results={
							this.state.employerSearchString.length >= 2 ? searchResults : []
						}
						handleSearch={this.handleSearch}
						resetSearch={() => {
							this.handleSearch('');
							this.setState({ isSaveDisabled: true });
						}}
						label={label}
						onSelect={this.selectEmployer}
						loading={this.props.searching}
					/>
					
				</DesktopPadding>
				{this.renderDialog()}
				<FabMenu inDialog onClick={this.navigateToAddEmployerLead} items={[]} placeholderPropsIds={["FactFinder-FactFinderFabItem"]} />
			</Dialog>
		);
	}
}

function mapStateToProps(state: any): StateProps {
	

	return {
		employers: state.employer.employers,
		contacts: state.contact.contacts,
		searching: state.employer.searchLoading,
	};
}

function mapDispatchToProps(dispatch: any): DispatchProps {
	return {
		searchEmployers: (searchText: string) =>
			dispatch(SearchEmployers.started(searchText)),
		updateContact: (contact: Contact) =>
			dispatch(UpdateContact.started(contact)),
		queueSnackbar: (props: SnackbarProps) => dispatch(QueueSnackbar(props)),
		navigateToWithoutAddingToHistory: (route: string) =>
			dispatch(navigateToWithoutAddingToHistory(route)),
		navigateBack: () => dispatch(navigateBack()),
		navigateTo: (route: string) =>
			dispatch(navigateTo(route)),
	};
}

export const AddEmployerDialog = connect(mapStateToProps, mapDispatchToProps, true)(
	AddEmployer
) as React.ComponentClass<AddEmployerProps>;
