import React from 'react';
import { Card, CardContent, Grid, ListItem, Typography } from '@material-ui/core';
import { findAgentRole, getAgentFullName, getIsTerminated } from '../../utilities/agent_util';
import { themePalette } from '../../utilities/branding';
import { AgentAvatar } from '../agent/agent_avatar';
import { Agent, AgentHierarchyDto } from '../../reducers/agent_reducer';
import { useNavigationProps } from '../../utilities/hooks';
import { navRoutes } from '../nav/Routes';
import { Strings } from '../../assets/common/strings';

interface Props {
	agentCode: string;
	hierarchy: AgentHierarchyDto;
}

export const Hierarchy: React.FC<Props> = (props) => {

	const { agentCode, hierarchy } = props;
	const { agentHierarchyUpline: upline=[], agentHierarchyDirectReports: reports=[] } = hierarchy;
	const { navigateTo } = useNavigationProps();

	const createAvatar = (agent: any) => {
		const selectedAgent = agentCode.toUpperCase() === agent.agentCode.toUpperCase();
		const halo: React.CSSProperties = {};
		if (selectedAgent) {
			if (agent.agentImage) {
				halo.border = `3px solid ${themePalette.agent_avatar}`;
			} else {
				halo.backgroundColor = themePalette.agent_avatar;
			}
		}
		return (
			<AgentAvatar
				avatarSize={50}
				agent={agent}
				style={halo}
			/>
		);
	};

	const renderAgent = (agent: Agent, last: boolean, upline: boolean) => (
		<ListItem
			key={agent.id}
			button
			divider={false}
			onClick={() => navigateTo(navRoutes.agentProfile.path.replace(Strings.Navigation.AgentCode, agent.agentCode))}
			style={{ padding: 0, height: 75 }}
		>
			<Grid container>
				<Grid item sm={3} />
				<Grid item sm={9}>
					<Grid container alignItems="center">
						{!upline && <Grid item>
							{last
								? <LConnector/>
								: <TConnector/>
							}
						</Grid>}
						<Grid item>
							{createAvatar(agent)}
						</Grid>
						<Grid item style={{ paddingLeft: 10 }}>
							<Typography variant="body1">{getAgentFullName(agent)}</Typography>
							<Typography variant="body2" color="textSecondary">{findAgentRole(agent)}</Typography>
						</Grid>
						{upline && last && <Grid container>
							<Grid item sm={4}>
								<IConnector/>
							</Grid>
							<Grid item sm={8} />
						</Grid>}
					</Grid>
				</Grid>
			</Grid>
		</ListItem>
	);

	const nonTerminatedReportingAgents = reports.filter(reportingAgent => !getIsTerminated(reportingAgent));
	const numberOfUplineAgents: number = upline.length;
	const numberOfDirectReports: number = nonTerminatedReportingAgents.length;
	return (
		<Card style={{ margin: 10 }}>
			<CardContent>
				{upline.map((agent, index) => renderAgent(agent, index !== numberOfUplineAgents-1, true))}
				{nonTerminatedReportingAgents.map((agent, index) => renderAgent(agent, index === numberOfDirectReports-1, false))}
			</CardContent>
		</Card>
	);
	
};

const IConnector: React.FC = () => (
	<svg height="15" width="54">
		<line x1="27" y1="0" x2="27" y2="15" style={{ stroke: themePalette.divider, strokeWidth: 3 }} />
	</svg>
);
const LConnector: React.FC = () => (
    <svg height="75" width="62">
        <line x1="32" y1="0" x2="32" y2="36" style={{ stroke: themePalette.divider, strokeWidth: 3 }} />
        <line x1="31" y1="35" x2="50" y2="35" style={{ stroke: themePalette.divider, strokeWidth: 3 }} />
    </svg>
);
const TConnector: React.FC = () => (
    <svg height="75" width="62">
        <line x1="32" y1="0" x2="32" y2="75" style={{ stroke: themePalette.divider, strokeWidth: 3 }} />
        <line x1="32" y1="35" x2="50" y2="35" style={{ stroke: themePalette.divider, strokeWidth: 3 }} />
    </svg>
);