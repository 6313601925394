import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { GetAgentsAdmin, UpsertAgentAdmin, UpdateUpsertForm } from '../actions/admin_actions';
import { TableData } from '../components/Layout/table/data_table';


export interface AccountUpsertForm {
    agentCode: string;
    firstName: string;
    lastName: string;
    preferredName: string;
    activeChannel: string;
    agency: string;
    timezone: string;
}
export interface ManagedAgent extends TableData {
    agentCode: string;
    firstName: string;
    lastName: string;
    preferredName: string;
    activeChannel: string;
    agency: string;
    timezone: string;
    createdOn: string;
    updatedOn: string;
}
interface ManageUsersState {
    loading: boolean;
    agents: ManagedAgent[];
    total: number;
    upsertForm: AccountUpsertForm;
}
export interface AdminState {
    manageUsers: ManageUsersState;
}

export const initialUpsertForm: AccountUpsertForm = {
    agentCode: '',
    firstName: '',
    lastName: '',
    preferredName: '',
    activeChannel: '',
    agency: '',
    timezone: '',
}
const initialManageState: ManageUsersState = {
    loading: false,
    agents: [],
    total: 0,
    upsertForm: initialUpsertForm,
};
const initialState: AdminState = {
    manageUsers: initialManageState,
};

export const adminReducer = (state=initialState, action: Action) => {
    if (isType(action, GetAgentsAdmin.started) || isType(action, UpsertAgentAdmin.started)) {
        return {
            ...state,
            manageUsers: {
                ...state.manageUsers,
                loading: true,
            },
        }
    } else if (isType(action, GetAgentsAdmin.done)) {
        const agents =
            action.payload.params.pageNum === 0
                    ? action.payload.result.results
                    : [...state.manageUsers.agents, ...action.payload.result.results];
        return {
            ...state,
            manageUsers: {
                ...state.manageUsers,
                loading: false,
                agents,
                total: action.payload.result.totalResults,
            }
        };
    } else if (isType(action, UpsertAgentAdmin.done)) {
        if (action.payload.result.results.length > 0) {
            const upsertedAgent = action.payload.result.results[0];
            const existingAgent = state.manageUsers.agents.find(ma => ma.id == upsertedAgent.id);
            let agentsCopy;

            if (existingAgent) {
                agentsCopy = state.manageUsers.agents.map((item, index) => {
                    if (item.id == existingAgent.id) {
                        return upsertedAgent;
                    }
                    return item;
                });
            }
            else {
                agentsCopy = state.manageUsers.agents;
                agentsCopy.push(upsertedAgent);
            }
            return {
                ...state,
                manageUsers: {
                    ...state.manageUsers,
                    loading: false,
                    agents: agentsCopy,
                    total: action.payload.result.totalResults,
                }
            };
        }
        else{
            return {
                ...state
            };
        }

    } else if (isType(action, UpdateUpsertForm)) {
        return {
            ...state,
            manageUsers: {
                ...state.manageUsers,
                upsertForm: action.payload,
            }
        };
    } else {
        return state;
    }
};