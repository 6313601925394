import { connect } from '@optum-uhone-hmkts/rise';
import { Paper } from '@material-ui/core';
import { Dispatch } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { AgentEmailTitlePayload, AgentNeedsProposalApprovalPayload, AgentScope, GetSubagentsByAgentCodeNotCached, UpdateAgentEmailTitle, UpdateAgentNeedsProposalApproval } from '../../actions/agent_actions';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { DataTable, TableHeader } from '../../components/Layout/table/data_table';
import { EmptyMessage } from '../../components/utility/empty_message';
import { AppState } from '../../reducers';
import { Agent } from '../../reducers/agent_reducer';

interface Subagent extends Agent {};

interface StateProps {
    agentCode: string;
    subagents?: Subagent[];
    isLoading: boolean;
}
interface DispatchProps {
    getSubagents: (agentCode: string) => void;
    updateAgentEmailTitle: (payload: AgentEmailTitlePayload) => void;
    updateAgentNeedsProposalApproval: (payload: AgentNeedsProposalApprovalPayload) => void;
}
type Props = StateProps & DispatchProps;

const ManageSubagents: React.FC<Props> = (props) => {
    const { agentCode, subagents=[], isLoading, getSubagents, updateAgentEmailTitle, updateAgentNeedsProposalApproval } = props;

    useEffect(() => {
        getSubagents(agentCode);
    }, [agentCode]);

    const onEmailTitleChange = (id: string, value: string) => {
        updateAgentEmailTitle({ agentId: id, title: value, scope: AgentScope.Subagents });
    };
    const onApprovalChange = (subagent: Subagent, value: boolean) => {
        updateAgentNeedsProposalApproval({ agentId: subagent.id, value, scope: AgentScope.Subagents });
    };

    const headers: TableHeader<Subagent>[] = [
        { label: 'First Name', key: 'firstName', fieldType: 'readonly' },
        { label: 'Last Name', key: 'lastName', fieldType: 'readonly' },
        { label: 'Email Title', key: 'agentTitle', fieldType: 'dropdown', options: ['Licensed Insurance Agent', 'Customer Service Representative'], onOptionChange: onEmailTitleChange },
        { label: 'Needs Proposal Approval', key: 'needsProposalApproval', fieldType: 'checkbox', onCheck: onApprovalChange }
    ];

    return (
        <BasePageContainer
            topComponent={
                <HeaderBarComponent
                    title='Manage SubAgents'
                    isLoading={isLoading}
                />
            }
        >
            {
                !isLoading && subagents.length === 0
                    ? <EmptyMessage text="No subagents to manage" />
                    : (
                        <Paper elevation={0}>
                            <DataTable
                                headers={headers}
                                data={subagents}
                                total={subagents.length}
                                name={'MANAGE_SUBAGENTS'}
                                disablePaging
                            />
                        </Paper>
                    )
            }
        </BasePageContainer>
    );
};

const mapStateToProps = (state: AppState): StateProps => ({
    agentCode: state.agent.agentCode,
    subagents: state.agent.subagents,
    isLoading: state.agent.isLoading,
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    getSubagents: (agentCode: string) => dispatch(GetSubagentsByAgentCodeNotCached.started(agentCode)),
    updateAgentEmailTitle: (payload: AgentEmailTitlePayload) => dispatch(UpdateAgentEmailTitle.started(payload)),
    updateAgentNeedsProposalApproval: (payload: AgentNeedsProposalApprovalPayload) => dispatch(UpdateAgentNeedsProposalApproval.started(payload)),
});
export const ManageSubagentsContainer = connect(mapStateToProps, mapDispatchToProps, true)(ManageSubagents);