import { ThemeOptions, createTheme } from '@material-ui/core';
import { darken, lighten } from '@material-ui/core/styles';
import { themePalette } from './branding';
import { isMobileDevice } from './is_mobile';

const getSnackbarTheme = (backgroundColor: string) => ({
	backgroundColor,
	'& .MuiAlert-icon': {
		color: themePalette.negative_text,
	},
});
const getThemeOptions: () => ThemeOptions = () => ({
	typography: {
		fontFamily: '"Open Sans", sans-serif',
		caption: {
			fontFamily: '"Roboto", "Helvetica", "Arial", "Open Sans", sans-serif',
		}
	},
	palette: {
		primary: {
			main: themePalette.primary,
		},
		secondary: {
			main: themePalette.secondary,
		},
		error: {
			main: themePalette.error,
		},
	},
	overrides: {
		MuiTableRow: {
			root: {
				'&$selected': {
					backgroundColor: themePalette.selected_overlay,
				},
			},
		},
		MuiAlert: {
			standardSuccess: getSnackbarTheme(themePalette.success_snackbar),
			standardError: getSnackbarTheme(themePalette.failure_snackbar),
			standardInfo: getSnackbarTheme(themePalette.info_snackbar),
			standardWarning: getSnackbarTheme(themePalette.failure_snackbar),
			icon: {
				color: themePalette.negative_text,
			},
			message: {
				color: themePalette.negative_text,
			},
			action: {
				color: themePalette.negative_text,
			},
		},
		// TODO: Use overrides for appbar/toolbar - but fix TablePagination component 
		// MuiToolbar: {
		// 	root: {
		// 		backgroundColor: themePalette.header_bar,
		// 	},
		// },
		MuiLinearProgress: {
			colorPrimary: {
				backgroundColor: lighten(themePalette.progress_bar, 0.6),
			},
			bar1Indeterminate: {
				backgroundColor: themePalette.progress_bar,
			},
			bar2Indeterminate: {
				backgroundColor: themePalette.progress_bar,
			},
		},
		MuiCircularProgress: {
			colorPrimary: {
				color: themePalette.progress_bar,
			},
			colorSecondary: {
				color: themePalette.login_loading,
			},
		},
		MuiFab: {
			root: {
				backgroundColor: themePalette.fab_menu_button,
				color: themePalette.negative_text,
				'&:hover': {
					backgroundColor: darken(themePalette.fab_menu_button, 0.1),
				}
			},
		},
		MuiCardHeader: {
			action: {
				marginTop: 0,
				marginRight: 0,
			},
		},
	},
	props: {
		MuiButtonBase: {
			disableRipple: !isMobileDevice,
		},
		MuiSwitchBase: {
			disableRipple: !isMobileDevice,
		},
	},
});

export const getTheme = () => createTheme(getThemeOptions());
