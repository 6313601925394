import { NavigationProps, navRoutes } from '../../components/nav/Routes';
import { Loaded, PageSize } from '../../utilities/utilities';
import {
	AppOrPolicy,
	Policy,
	ProductStatus,
} from '../../reducers/policy_reducer';
import { Application } from '../../reducers/application_reducer';
import { AppState } from '../../reducers/index';
import { navigateTo } from '../../actions/navigation_actions';
import { GetAgentFromJwt } from '../../actions/agent_actions';
import React from 'react';
import {
	HeaderBarComponent,
} from '../../components/Layout/HeaderBar';
import { themePalette, themeLinks } from '../../utilities/branding';
import {
	Paper,
	Button,
	Chip,
} from '@material-ui/core';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { compose } from 'redux';
import { connect } from '@optum-uhone-hmkts/rise';
import { ProductListItem } from '../../components/product/product_list_item';
import { ProductFilter } from '../../components/product/product_filter';
import moment from 'moment';
import { ProductFilters } from '../../reducers/product_filter_reducer';
import {
	SearchProducts,
	ProductFilterPayload,
	StoreProductDownlineAgent,
	StoreProductFilters,
} from '../../actions/product_actions';
import { Contact, Address, Email, Phone } from '../../reducers/ContactReducer';
import {
	getProductStatus,
	getProductStatusReason,
} from '../../utilities/product_util';
import { SetProductTab } from '../../actions/policy_actions';
import { findAgentRoleFromArray, isAgentRole } from '../../utilities/agent_util';
import { styles } from '../FinancePage/FinancePage';
import { AgentSelectorComponent } from '../../components/agent/agent_selector';
import {
	DownlineAgent,
	initialDownlineAgent,
} from '../../reducers/product_reducer';
import { MoreMenu, createHelpAction } from '../../components/nav/more_menu';
import { Lookups } from '../../utilities/lookup';
import { fullName } from '../../assets/common/string_builders';
import { get } from '../../utilities/object_util';
import { findAndGet, findWithFallbacks, isFirst } from '../../utilities/array_util';
import { Strings } from '../../assets/common/strings';
import { DesktopPadding } from '../../components/Layout/desktop_padding';
import { InfiniteScroller } from '../../components/utility/infinite_scroller';
import { EmptyMessage } from '../../components/utility/empty_message';
import { AgentAvatar } from '../../components/agent/agent_avatar';


const productMatch = [
	Strings.ProductMatch.Unmatched,
	Strings.ProductMatch.Matched,
];
const productDate = [
	Strings.ProductDateOptions.ApplicationDate,
	Strings.ProductDateOptions.EffectiveDate,
	Strings.ProductDateOptions.TerminatedDate,
	Strings.ProductDateOptions.IssuedDate,
	Strings.ProductDateOptions.OutstandingDocumentDueDate
];

export interface Product {
	data: Application | Policy;
	date?: Date;
	isLinked: boolean;
	isMatched?: boolean;
	policyOrApplication: Strings.PolicyOrApplicationValue
	status: ProductStatus;
	carrier: string;
	productType: string;
	id: string;
	lineOfBusiness: string;
	contact?: Contact;
	productNumber: string;
	cityState: string;
	productId: string;
	dateLabel: string;
	statusReason: string;
	employerId?: string;
	fullName?: string;
	linkedApplication?: Application;
}

interface StateProps extends ProductFilters {
	agentCode: string;
	applications: Loaded<Application>[];
	carrier: string[];
	downlineAgent: DownlineAgent;
	isApplicationLoading: boolean;
	isPolicyLoading: boolean;
	lineOfBusinessTypeList: string[];
	policies: Loaded<Policy>[];
	product: string[];
	productType: string[];
	sortList: string[];
	agentRoles: string[];
}

interface DispatchProps {
	searchProducts: (filterValues: ProductFilterPayload) => void;
	storeProductFilters: (filterValues: ProductFilterPayload) => void;
	setProductTab: (appOrPolicy: AppOrPolicy) => void;
	storeDownlineAgent: (downlineAgent: DownlineAgent) => void;
	getAgentFromJWT: () => void;	
}

type Props = StateProps & DispatchProps & NavigationProps;

type State = {
	downlineAgentCode: string;
	downlineAgentName: string;
	downlineAgentAvatar: JSX.Element;
	showReassignDialog: boolean;
};

export const mapApplicationsToProduct = (
	applications: Loaded<Application>[]
): Loaded<Product>[] => {
	return applications
		.filter(application => !application.data.isLinked)
		.map(application => {
			let date: any;
			let dateLabel: string = '';
			const status = getProductStatus(application.data, undefined);
			const statusReason = getProductStatusReason(application.data, undefined);

			if (status == ProductStatus.Pending) {
				date = application.data.appDate;
				dateLabel = 'Application Date:';
			} else if (status == ProductStatus.Active) {
				date = application.data.effectiveDate;
				dateLabel = 'Effective Date:';
			} else if (status == ProductStatus.Terminated) {
				date = application.data.updatedOn || application.data.createdOn;
				dateLabel = 'Terminated Date:';
			} else {
				date = '';
				dateLabel = '';
			}

			const applicationId = application.data.id;

			const productId = `${applicationId}|`;
			let primaryAddressIndex =
				application.data.primaryInsured &&
					application.data.primaryInsured.addresses
					? application.data.primaryInsured.addresses.findIndex(
						address => address.isPreferred
					)
					: -1;

			if (
				primaryAddressIndex === -1 &&
				application.data.primaryInsured &&
				application.data.primaryInsured.addresses.length === 1
			) {
				primaryAddressIndex = 0;
			}
			const address =
				primaryAddressIndex > -1
					? application.data.primaryInsured.addresses[primaryAddressIndex]
					: { city: '', state: '' };
			const cityAddress =
				primaryAddressIndex > -1 && address.city && address.state
					? address.city + ', ' + address.state
					: address.city ? address.city : ', ' + address.state || '';

			const product: Product = {
				carrier: application.data.carrier,
				data: application.data,
				date: date,
				productType: application.data.productType,
				isLinked: application.data.isLinked,
				isMatched: !!application.data.primaryInsured,
				policyOrApplication: Strings.PolicyOrApplicationValue.Application,
				status: status,
				id: application.data.id,
				lineOfBusiness: application.data.lineOfBusiness,
				productNumber: application.data.applicationNumber,
				contact: application.data.primaryInsured,
				cityState: cityAddress,
				productId: productId,
				dateLabel: dateLabel,
				statusReason: statusReason,
			};

			return {
				loading: false,
				errors: [],
				data: product,
				householdId: application.householdId,
				employerId: application.employerId,
			};
		});
};

export const mapPoliciesToProduct = (
	policies: Loaded<Policy>[],
	applications: Loaded<Application>[]
): Loaded<Product>[] => {
	return policies.map(policy => {
		const appId = policy.data.applicationId;
		const isLinked = !!appId;

		const linkedApplication = findAndGet(applications,
			app => app.data.id === appId,
			app => app.data
		);

		const applicationId = policy.data.applicationId || '';
		const productId = `${applicationId}|${policy.data.id}`;

		const addresses = get(() => policy.data.primaryInsured!.addresses);
		const primaryAddress = addresses && findWithFallbacks(addresses,
			address => address.isPreferred,
			isFirst
		);
		const address = primaryAddress || { city: undefined, state: undefined };
		const cityAddress =
			address.city && address.state
				? address.city + ', ' + address.state
				: address.city ? address.city : address.state || '';

		const employerId = policy.data.employerId || undefined;

		let date: any;
		let dateLabel: string = '';
		const status = getProductStatus(linkedApplication, policy.data);
		const statusReason = getProductStatusReason(linkedApplication, policy.data);
		if (status == ProductStatus.Active) {
			date = policy.data.effectiveDate;
			dateLabel = 'Effective Date:';
		} else if (status == ProductStatus.Pending) {
			date = policy.data.applicationDate;
			dateLabel = 'Application Date:';
		} else if (status == ProductStatus.Terminated) {
			date =
				policy.data.policyStatusDate ||
				policy.data.updatedOn ||
				policy.data.createdOn;
			dateLabel = 'Terminated Date:';
		} else {
			date = '';
			dateLabel = '';
		}

		const product: Product = {
			data: policy.data,
			carrier: policy.data.carrier,
			productType: policy.data.productType,
			date: date,
			isLinked: isLinked,
			isMatched: !!policy.data.primaryInsured || !!policy.data.employerId,
			policyOrApplication: linkedApplication ? Strings.PolicyOrApplicationValue.Both : Strings.PolicyOrApplicationValue.Policy,
			status: status,
			id: policy.data.id,
			lineOfBusiness: policy.data.lineOfBusiness,
			productNumber: policy.data.policyNumber,
			contact: policy.data.primaryInsured,
			fullName: policy.data.primaryInsuredName,
			cityState: cityAddress,
			productId: productId,
			dateLabel: dateLabel,
			statusReason: statusReason,
			employerId: employerId,
			linkedApplication: linkedApplication,
		};

		return {
			loading: false,
			errors: [],
			data: product,
			householdId: policy.householdId,
			employerId: policy.employerId,
		};
	});
};

class ProductListPage extends React.Component<Props, State> {
	networkLock = false;
	toDetailPage = false;

	constructor(props: Props) {
		super(props);
		let downlineExists = false;
		if (props.downlineAgent.agentCode) {
			downlineExists = true;
		}

		this.state = {
			downlineAgentCode: downlineExists ? props.downlineAgent.agentCode : '',
			downlineAgentName: downlineExists ? fullName(props.downlineAgent) : '',
			downlineAgentAvatar: downlineExists ? (
				<AgentAvatar agent={props.downlineAgent} />
			) : (
					<span />
				),
			showReassignDialog: false,		
		};
		this.networkLock = props.isLoading || false;
	}

	componentWillReceiveProps(nextProps: Props) {
		if (this.props.isLoading && nextProps.isLoading === false)
			this.networkLock = false;
	}

	componentDidMount() {
		if (!this.props.agentRoles) {
			this.props.getAgentFromJWT();
		}
	}

	handleFilters = (productFilters: ProductFilters) => {
		for (let product in productFilters) {
			if ((productFilters as any)[product] === 'any') {
				(productFilters as any)[product] = '';
			}
		}
		productFilters.pageNumber = 0;
		productFilters.pageSize = PageSize();
		this.props.searchProducts(productFilters as ProductFilterPayload);
	};

	loadMore = () => {
		if (this.props.hasMore && !this.props.isLoading && !this.networkLock) {
			this.networkLock = true;
			const updatedFilters: ProductFilterPayload = this.getCurrentFilters();
			updatedFilters.pageNumber = (this.props.pageNumber || 0) + 1;
			this.props.searchProducts(updatedFilters);
		}
	};

	routeToProductDetail = (
		productId: string,
		appOrPolicy: 'application' | 'policy'
	) => {
		this.toDetailPage = true;
		if (appOrPolicy === 'application') {
			this.props.setProductTab(AppOrPolicy.Application);
		} else {
			this.props.setProductTab(AppOrPolicy.Policy);
		}
		this.props.navigateTo(
			navRoutes.productDetail.path.replace(Strings.Navigation.ProductId, productId)
		);
	};

	routeToHouseholdOrEmployer = (
		id: string,
		type?: 'household' | 'employer'
	) => {
		if (type) {
			if (type === 'employer')
				this.props.navigateTo(
					navRoutes.employer.path.replace(Strings.Navigation.EmployerId, id)
				);
			else
				this.props.navigateTo(
					navRoutes.household.path.replace(Strings.Navigation.HouseholdId, id)
				);
		}
	};

	searchContact(product: Product, contact?: Contact) {
		const contactFilter = this.props.contactFilter;

		if (!contactFilter) return true;
		
		if (!contact && !product.fullName) return false;

		const names: string[] = [];
		const addresses: Address[] = [];
		const emails: Email[] = [];
		const phones: Phone[] = []

		if (product.data.employerId && product.data.employerName) {
			names.push(product.data.employerName);
		}

		if (contact) {
			names.push(contact.firstName);
			names.push(contact.lastName);
			names.push(contact.preferredName);
			addresses.concat(contact.addresses);
			emails.concat(contact.emails);
			phones.concat(contact.phones);
		}

		if (product.fullName) names.push(product.fullName);

		let cities: string[] = [];
		let states: string[] = [];
		let emailAddresses: string[] = [];
		let phoneNumbers: string[] = [];

		addresses.forEach(address => {
			cities.push(address.city);
			states.push(address.state);
		});

		emails.forEach(email => {
			emailAddresses.push(email.emailAddress);
		});

		phones.forEach(phone => {
			phoneNumbers.push(phone.number);
		});

		let filters = contactFilter.split(' ');
		let returnValue = false;
		let missedMatch = false;
		filters.forEach(contactFilter => {
			if (
				names.some(
					name =>
						!!name &&
						name.toLowerCase().indexOf(contactFilter.toLowerCase()) > -1
				)
			)
				returnValue = true;
			else if (
				cities.some(
					city =>
						!!city &&
						city.toLowerCase().indexOf(contactFilter.toLowerCase()) > -1
				)
			)
				returnValue = true;
			else if (
				states.some(
					state =>
						!!state &&
						state.toLowerCase().indexOf(contactFilter.toLowerCase()) > -1
				)
			)
				returnValue = true;
			else if (
				emailAddresses.some(
					emailAddress =>
						!!emailAddress &&
						emailAddress.toLowerCase().indexOf(contactFilter.toLowerCase()) > -1
				)
			)
				returnValue = true;
			else if (
				phoneNumbers.some(
					phoneNumber =>
						!!phoneNumber &&
						phoneNumber.toLowerCase().indexOf(contactFilter.toLowerCase()) > -1
				)
			)
				returnValue = true;
			else missedMatch = true;
		});
		return returnValue && !missedMatch;
	}

	searchDate = (product: Product): boolean => {
		let date: any;

		if (!this.props.productDateFilter) {
			return true;
		} else if (
			this.props.productDateFilter == Strings.ProductDateOptions.ApplicationDate &&
			'appDate' in product.data
		) {
			date = product.data.appDate;
		} else if (
			this.props.productDateFilter == Strings.ProductDateOptions.ApplicationDate &&
			'applicationDate' in product.data
		) {
			date = product.data.applicationDate;
		} else if (this.props.productDateFilter == Strings.ProductDateOptions.EffectiveDate) {
			date = product.data.effectiveDate;
		} else if (
			this.props.productDateFilter == Strings.ProductDateOptions.TerminatedDate &&
			'policyStatusDate' in product.data
		) {
			if (product.status !== ProductStatus.Terminated) {
				return false;
			}
			date = product.data.policyStatusDate;
		} else if (
			this.props.productDateFilter == Strings.ProductDateOptions.IssuedDate &&
			'issuedDate' in product.data
		) {
			date = product.data.issuedDate;
		} else if (
			this.props.productDateFilter == Strings.ProductDateOptions.OutstandingDocumentDueDate &&
			'outstandingDocumentsDueDate' in product.data
		) {
			date = product.data.outstandingDocumentsDueDate;
		} else if (
			this.props.productDateFilter == Strings.ProductDateOptions.OutstandingDocumentDueDate &&
			product.linkedApplication &&
			'outstandingDocumentsDueDate' in product.linkedApplication
		) {
			date = product.linkedApplication.outstandingDocumentsDueDate;
		} else {
			date = '';
		}

		return moment
			.utc(date)
			.isBetween(
				moment(this.props.fromDate).startOf('day'),
				moment(this.props.toDate).endOf('day')
			);
	};

	searchString(searchString: string, filter: string): boolean {
		if (!filter) return true;
		else if (!searchString) return false;
		else if (filter.toLowerCase() === searchString.toLowerCase()) return true;
		else return false;
	}

	searchMatch(product: Product, matchFilter: string): boolean {
		if (!matchFilter) {
			return true;
		} else if (
			matchFilter === 'Matched' &&
			product.isMatched &&
			(product.policyOrApplication.toLowerCase() === Strings.PolicyOrApplicationValue.Policy ||
			product.policyOrApplication.toLowerCase() == Strings.PolicyOrApplicationValue.Both)
		) {
			return true;
		} else if (
			matchFilter === 'Unmatched' &&
			!product.isMatched &&
			(product.policyOrApplication.toLowerCase() === Strings.PolicyOrApplicationValue.Policy ||
			product.policyOrApplication.toLowerCase() == Strings.PolicyOrApplicationValue.Both)
		) {
			return true;
		} else {
			return false;
		}
	}

	getCurrentFilters = () => {
		const filters: ProductFilterPayload = {
			downlineAgentCode:
				this.state.downlineAgentCode == this.props.agentCode
					? ''
					: this.state.downlineAgentCode,
			contactFilter: this.props.contactFilter,
			productCategoryFilter: this.props.productCategoryFilter,
			productNumberFilter: this.props.productNumberFilter,
			productStatusFilter: this.props.productStatusFilter,
			carrierFilter: this.props.carrierFilter,
			productTypeFilter: this.props.productTypeFilter,
			productMatchFilter: this.props.productMatchFilter,
			productDateFilter: this.props.productDateFilter,
			lineOfBusinessFilter: this.props.lineOfBusinessFilter,
			productFilter: this.props.productFilter,
			sortBy: this.props.sortBy,
			sortCategory: this.props.sortCategory,
			sortDirection: this.props.sortDirection,
			fromDate: this.props.fromDate,
			toDate: this.props.toDate,
			pageNumber: 0,
			pageSize: PageSize(),
		};

		return filters;
	};

	selectNewAgent = (agent: DownlineAgent) => {
		this.setState(
			{
				downlineAgentCode: agent.agentCode,
				downlineAgentName: fullName(agent),
				downlineAgentAvatar: <AgentAvatar agent={agent} />,
				showReassignDialog: false,
			},
			() => {
				this.props.storeDownlineAgent(agent);
				this.props.searchProducts(this.getCurrentFilters());
			}
		);
	};

	onChipDelete = () => {
		this.setState(
			{
				downlineAgentCode: this.props.agentCode,
				downlineAgentName: '',
				downlineAgentAvatar: <span />,
			},
			() => {
				this.props.storeDownlineAgent(initialDownlineAgent);
				this.props.searchProducts(this.getCurrentFilters());
			}
		);
	};

	componentWillUnmount() {
		// TODO: Keep track of this 
		//  - 8321b0406d5253b6b8e19a5957b63e51fc4e41ad
		//  - cca1379e96024a6b7a28cb7c5cfb46dc76513cef
		if (!this.toDetailPage) {
			this.props.storeDownlineAgent(initialDownlineAgent);
			this.props.storeProductFilters(this.getCurrentFilters());
		}
	}

	lookUpAgentClick = () => {
		this.setState({ showReassignDialog: true });
	};

	closeReassignDialog = () => {
		this.setState({ showReassignDialog: false });
	};

	renderCustomContent = () => {
		const { agentRoles=[] } = this.props;
		const topAgentRole = findAgentRoleFromArray(agentRoles);
		const viewDownline =
			topAgentRole === Strings.AgentRoles.SVPAbbrev ||
			topAgentRole === Strings.AgentRoles.TVPAbbrev ||
			topAgentRole === Strings.AgentRoles.SalesLead ||
			isAgentRole([Strings.AgentRoles.SponsorAgent, Strings.ADRoles.Assistant]);

		return (
			<>
				<ProductFilter
					downlineAgentCode={this.state.downlineAgentCode}
					carrier={this.props.carrier}
					lineOfBusinessTypeList={this.props.lineOfBusinessTypeList}
					product={this.props.product}
					productDate={productDate}
					productStatus={['Pending', 'Active', 'Terminated']}
					productMatch={productMatch}
					productType={this.props.productType}
					contactFilter={this.props.contactFilter}
					productCategoryFilter={this.props.productCategoryFilter}
					productNumberFilter={this.props.productNumberFilter}
					carrierFilter={this.props.carrierFilter}
					lineOfBusinessFilter={this.props.lineOfBusinessFilter}
					productDateFilter={this.props.productDateFilter}
					productStatusFilter={this.props.productStatusFilter}
					productTypeFilter={this.props.productTypeFilter}
					productMatchFilter={this.props.productMatchFilter}
					productFilter={this.props.productFilter}
					fromDate={this.props.fromDate}
					toDate={this.props.toDate}
					sortBy={this.props.sortBy}
					sortCategory={this.props.sortCategory}
					sortDirection={this.props.sortDirection}
					sortList={this.props.sortList}
					onSearch={this.handleFilters}
					urlStatus={this.props.match.params.status}
					urlMatch={this.props.match.url}
					isFilterOpen={Boolean(this.props.isFilterOpen)}			
				/>
				{viewDownline ? (
					<div style={{ ...styles.lookUpAgentButton, backgroundColor: themePalette.default_background }}>
						{this.state.downlineAgentCode &&
							this.state.downlineAgentCode != this.props.agentCode ? (
								<Chip
									label={this.state.downlineAgentName}
									avatar={this.state.downlineAgentAvatar}
									onDelete={this.onChipDelete}
									style={styles.chipStyle}
								/>
							) : (
								<Button onClick={this.lookUpAgentClick}>Look Up Agent</Button>
							)}
					</div>
				) : null}
			</>
		);
	};

	render() {
		const productList = this.renderProducts();
		return (
			<BasePageContainer
				topComponent={
					<HeaderBarComponent
						title="Products"
						customContent={this.renderCustomContent()}
						isLoading={
							this.props.isPolicyLoading ||
							this.props.isApplicationLoading ||
							this.props.isLoading
						}
						rightButtons={
							<MoreMenu
								children={[createHelpAction(themeLinks.helpLinkCRM)]}
							/>
						}
					/>
				}
				middleComponent={
					<div
						style={{ height: '100%', overflowY: 'auto', overflowX: 'hidden' }}
					>
						<InfiniteScroller
							loadMore={this.loadMore}
							hasMore={this.props.hasMore}
							useWindow={false}
						>
							<DesktopPadding>
								{
									productList.length === 0 && !this.props.isLoading
										? <EmptyMessage text="There are no matches for your search..." />
										: (
											<Paper elevation={0} square>
												{this.renderProducts()}
											</Paper>
										)
								}
							</DesktopPadding>
						</InfiniteScroller>
						<AgentSelectorComponent
							visible={this.state.showReassignDialog}
							currentAgentCode={this.props.agentCode}
							onSave={this.selectNewAgent}
							onCancel={this.closeReassignDialog}
							headerText="Find Agent"
							saveText="View"
							downlineOnly
						/>
					</div>
				}
			/>
		);
	}

	renderProducts(): JSX.Element[] {
		const products = mapApplicationsToProduct(this.props.applications)
			.concat(mapPoliciesToProduct(this.props.policies, this.props.applications));

		const productArray = products
			.filter(product => {
				if (
					this.searchContact(product.data, product.data.contact) &&
					this.searchString(product.data.carrier, this.props.carrierFilter) &&
					this.searchString(
						product.data.productNumber,
						this.props.productNumberFilter
					) &&
					this.searchString(
						product.data.lineOfBusiness,
						this.props.lineOfBusinessFilter
					) &&
					this.searchString(
						ProductStatus[product.data.status],
						this.props.productStatusFilter
					) &&
					this.searchString(
						product.data.productType,
						this.props.productTypeFilter
					) &&
					this.searchDate(product.data) &&
					this.searchMatch(product.data, this.props.productMatchFilter)
				) {
					return true;
				} else return false;
			})
			.map(product => {
				return (
					<ProductListItem
						key={product.data.id}
						{...product}
						onClick={this.routeToProductDetail}
						iconClick={this.routeToHouseholdOrEmployer}
					/>
				);
			});

		return productArray;
	}
}

function mapStateToProps(state: AppState): StateProps {
	return {
		applications: state.application.applications,
		agentCode: state.user.agentID || '',
		carrier: state.lookup.getLabels(Lookups.Carrier),
		downlineAgent: state.product.downlineAgent,
		isPolicyLoading: state.policy.isLoading,
		isApplicationLoading: state.application.isLoading,
		lineOfBusinessTypeList: state.lookup.getLabels(Lookups.ProductLineOfBusiness),
		policies: state.policy.policies,
		product: [],
		productType: state.lookup.getLabels(Lookups.ProductType),
		sortList: state.lookup.getLabels(Lookups.DateSort),
		agentRoles: state.agent.agentRoles,
		...state.productFilters,
	};
}

function mapDispatchToProps(dispatch: any): DispatchProps & Partial<NavigationProps> {
	return {
		navigateTo: (route: string) =>
			dispatch(navigateTo(route)),
		searchProducts: (filterValues: ProductFilterPayload) =>
			dispatch(SearchProducts.started(filterValues)),
		storeProductFilters: (filterValues: ProductFilterPayload) =>
			dispatch(StoreProductFilters(filterValues)),		
		setProductTab: (appOrPolicy: AppOrPolicy) =>
			dispatch(SetProductTab({ appOrPolicy })),
		storeDownlineAgent: (downlineAgent: DownlineAgent) =>
			dispatch(StoreProductDownlineAgent(downlineAgent)),
		getAgentFromJWT: () =>
			dispatch(GetAgentFromJwt.started(undefined)),
	};
}

export const ProductListPageContainer = compose(
	connect(mapStateToProps, mapDispatchToProps, true)
)(ProductListPage);
