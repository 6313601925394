import React from 'react';
import { reduxForm } from 'redux-form';
import { ApplicationGeneralCard } from './GeneralCard';
import { PrimaryInsuredCard } from './PrimaryInsuredCard';
import { PremiumInfoCard } from './PremiumInfoCard';
import { PolicyAmountsCard } from './PolicyAmountsCard';
import { AdminCard } from './AdminCard';
import LeadSelectorCard from './lead_selector_card';
import { Application } from '../../reducers/application_reducer';
import { Contact } from '../../reducers/ContactReducer';
import { Lead } from '../../reducers/LeadReducer';
import { Loaded } from '../../utilities/utilities';
import { Strings } from '../../assets/common/strings';
import { getAppState } from '../..';

export interface ApplicationUpsertFormValues {
	application: Application;
	insureds: { [index: string]: boolean };
}

interface ApplicationUpsertFormProps {
	formValues: ApplicationUpsertFormValues;
	linesOfBusiness: string[];
	metalLevel: string[];
	carriers: string[];
	productTypes: string[];
	contacts: Loaded<Contact>[];
	leads: Loaded<Lead>[];
	changeFieldValue: (formName: string, formPath: string, value: any) => void;
	onUpdate: (pristine: boolean, invalid: boolean, submitting: boolean) => void;
	householdOrEmployer: 'Household' | 'Employer';
}

interface ApplicationUpsertFormState { }

class ApplicationUpsertForm extends React.Component<
	ApplicationUpsertFormProps,
	ApplicationUpsertFormState
	> {
	componentWillReceiveProps(nextProps: any) {
		nextProps.onUpdate(
			nextProps.pristine,
			nextProps.invalid,
			nextProps.submitting
		);
	}

	render() {
		return (
			<form>
				<ApplicationGeneralCard
					formValues={this.props.formValues}
					linesOfBusiness={this.props.linesOfBusiness}
					metalLevels={this.props.metalLevel}
					carriers={this.props.carriers}
					productTypes={this.props.productTypes}
					formName="ApplicationUpsertForm"
					changeFieldValue={this.props.changeFieldValue}
				/>
				<PrimaryInsuredCard
					formName="ApplicationUpsertForm"
					contacts={this.props.contacts}
					changeFieldValue={this.props.changeFieldValue}
					householdOrEmployer={this.props.householdOrEmployer}
				/>
				<PremiumInfoCard />
				<PolicyAmountsCard />
				<AdminCard />
				<LeadSelectorCard leads={this.props.leads.map(lead => lead.data).filter(lead => lead.status != Strings.Status.Merged)} />

				<div style={{ marginBottom: '15%' }} />
			</form>
		);
	}
}

export const ApplicationUpsertFormComponent = reduxForm<ApplicationUpsertFormValues, ApplicationUpsertFormProps>({
	form: 'ApplicationUpsertForm',
	enableReinitialize: true,
	getFormState: (state) => getAppState().form,
	destroyOnUnmount: false,
})(ApplicationUpsertForm);
