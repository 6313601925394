import { call, put, all } from 'redux-saga/effects';
import {
	GetActivity,
	GetPagedActivities,
	CreateActivity,
	EditActivity,
	GetGoogleSignInCode,
	DeleteActivity,
	DisableGoogleCalendarSync,
	CreateEmployerActivity,
	GetActivitiesByContactId,
	CloseOldActivities,
	GetActivitiesParams,
} from '../actions/activity_actions';
import http from '../utilities/http';
import { Activity, GoogleSignInRequest } from '../reducers/activity_reducer';
import { QueueSnackbar } from '../actions/snackbar_actions';
import _ from 'lodash';
import { hydrateActivityContact } from './email_summary_sagas';
import { selectFromImmutable } from '../utilities/saga_util';
import { getSnackbarErrorProps, getSnackbarSuccessProps } from '../utilities/snackbar_util';
import { getContacts } from '../selectors/contact_selectors';
import { Loaded } from '../utilities/utilities';
import { Contact } from '../reducers/ContactReducer';
import { VoidAction, takeLatest, Action } from '@optum-uhone-hmkts/rise';
import { AppConfig } from '../types/config';

function* getActivitySaga(action: Action<string>) {
	try {
		const response = yield call(getActivityCall, action.payload);
		if (response.ok) {
			const data: Activity = yield response.json();
			yield put(GetActivity.done({ params: action.payload, result: data }));
		} else {
			yield put(
				GetActivity.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
		}
	} catch (error) {
		yield put(GetActivity.failed({ params: action.payload, error }));
	}
}
function getActivityCall(id: string) {
	return http('activity/' + id);
}

function* getPagedActivitiesSaga(action: Action<GetActivitiesParams>) {
	try {
		const response = yield call(getPagedActivitiesCall, action.payload);

		if (response.ok) {
			const data: Activity[] = yield response.json();
			yield put(
				GetPagedActivities.done({ params: action.payload, result: data })
			);
		} else {
			yield put(
				GetPagedActivities.failed({
					params: action.payload,
					error: {
						errorCode: response.status,
					},
				})
			);
		}
	} catch (error) {
		yield put(GetPagedActivities.failed({ params: action.payload, error }));
	}
}
function getPagedActivitiesCall(options: {
	filters: any;
	pageNum: number;
	pageSize: number;
}): Promise<any> {
	return http(
		'activity/filter?scope=Me',
		{
			method: 'POST',
			body: JSON.stringify(options.filters),
		},
		{ index: options.pageNum, size: options.pageSize }
	);
}

function* createActivitySaga(action: Action<Activity>) {
	const errorSnackbarProps = getSnackbarErrorProps('Could not create To Do')
	const successSnackbarProps = getSnackbarSuccessProps(`Your ${_.startCase(action.payload.type)} has been saved`);
	try {
		const response = yield call(createActivityCall, action.payload);
		if (response.ok) {
			const data: Activity = yield response.json();
			yield put(
				QueueSnackbar(successSnackbarProps)
			);
			yield put(
				CreateActivity.done({ params: action.payload, result: data })
			);
		} else {
			yield put(QueueSnackbar(errorSnackbarProps));
			yield put(
				CreateActivity.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
		}
	} catch (error) {
		yield put(QueueSnackbar(errorSnackbarProps));
		yield put(CreateActivity.failed({ params: action.payload, error }));
	}
}
function createActivityCall(activityToCreate: Activity) {
	return http('activity/', {
		method: 'POST',
		body: JSON.stringify(activityToCreate),
	});
}

function* createEmployerActivitySaga(action: Action<Activity>) {
	const errorSnackbarProps = getSnackbarErrorProps('Could not create To Do');
	const successSnackbarProps = getSnackbarSuccessProps(`Your ${_.startCase(action.payload.type)} has been saved`);
	try {
		const response = yield call(createEmployerActivityCall, action.payload);
		if (response.ok) {
			const data: Activity = yield response.json();
			yield put(
				QueueSnackbar(successSnackbarProps)
			);
			yield put(
				CreateEmployerActivity.done({ params: action.payload, result: data })
			);
		} else {
			yield put(QueueSnackbar(errorSnackbarProps));
			yield put(
				CreateEmployerActivity.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
		}
	} catch (error) {
		yield put(QueueSnackbar(errorSnackbarProps));
		yield put(
			CreateEmployerActivity.failed({ params: action.payload, error: error })
		);
	}
}
function createEmployerActivityCall(activityToCreate: Activity): Promise<any> {
	return http('activity/employer', {
		method: 'POST',
		body: JSON.stringify(activityToCreate),
	});
}

function* editActivitySaga(action: Action<Activity>) {
	try {
		const response = yield call(editActivityCall, action.payload);
		if (response.ok) {
			const data: Activity = yield response.json();
			yield put(EditActivity.done({ params: action.payload, result: data }));
		} else {
			yield put(
				EditActivity.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
		}
	} catch (error) {
		yield put(EditActivity.failed({ params: action.payload, error }));
	}
}
function editActivityCall(activityToEdit: Activity): Promise<any> {
	return http('activity/', {
		method: 'PUT',
		body: JSON.stringify(activityToEdit),
	});
}

function* getGoogleSignInCodeSaga(action: Action<GoogleSignInRequest>) {
	const errorSnackbarProps = getSnackbarErrorProps('Failed to retrieve your Google Sign In code');
	try {
		const response = yield call(getGoogleSignInCodeCall);
		if (response.ok) {
			const data = yield response.json();
			yield put(
				GetGoogleSignInCode.done({ params: action.payload, result: data })
			);
		} else {
			yield put(
				GetGoogleSignInCode.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
			yield put(QueueSnackbar(errorSnackbarProps));
		}
	} catch (error) {
		yield put(GetGoogleSignInCode.failed({ params: action.payload, error }));
		yield put(QueueSnackbar(errorSnackbarProps));
	}
}
function getGoogleSignInCodeCall() {
	return fetch(
		AppConfig.google_calendar_code_link +
		'?client_id=' +
		AppConfig.google_client_id +
		'&scope=' +
		AppConfig.google_calendar_code_scope,
		{
			method: 'POST',
			mode: 'cors',
		}
	);
}

function* deleteActivitySaga(action: Action<Activity>) {
	const errorSnackbarProps = getSnackbarErrorProps('Failed to delete ' + _.startCase(action.payload.type));
	const successSnackbarProps = getSnackbarSuccessProps(_.startCase(action.payload.type) + ' deleted');
	try {
		const response = yield call(deleteActivityCall, action.payload);
		if (response.ok) {
			yield put(
				DeleteActivity.done({ params: action.payload, result: undefined })
			);
			yield put(
				QueueSnackbar(successSnackbarProps)
			);
		} else {
			yield put(
				DeleteActivity.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
			yield put(QueueSnackbar(errorSnackbarProps));
		}
	} catch (error) {
		yield put(DeleteActivity.failed({ params: action.payload, error }));
		yield put(QueueSnackbar(errorSnackbarProps));
	}
}
function deleteActivityCall(activityToDelete: Activity): Promise<any> {
	return http('activity/', {
		method: 'DELETE',
		body: JSON.stringify(activityToDelete),
	});
}

function* disableGoogleCalendarSyncSaga(action: Action<void>) {
	const successSnackbarProps = getSnackbarSuccessProps('Disable Sync was successful');
	const errorSnackbarProps = getSnackbarErrorProps('Failed to disable Google Sync');
	try {
		const response = yield call(disableGoogleCalendarSyncCall);
		if (response.ok || response.status == 204) {
			yield put(
				DisableGoogleCalendarSync.done({
					params: action.payload,
					result: undefined,
				})
			);
			yield put(
				QueueSnackbar(successSnackbarProps)
			);
		} else {
			yield put(
				DisableGoogleCalendarSync.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
			yield put(
				QueueSnackbar(errorSnackbarProps)
			);
		}
	} catch (error) {
		yield put(
			DisableGoogleCalendarSync.failed({ params: action.payload, error })
		);
		yield put(
			QueueSnackbar(errorSnackbarProps)
		);
	}
}
function disableGoogleCalendarSyncCall(): Promise<any> {
	return http('activity/disableGoogleCalendarSync', {
		method: 'POST',
	});
}

function* getActivitiesByContactIdSaga(action: Action<string>) {
	try {
		const response = yield call(getActivitiesByContactId, action.payload);
		if (response.ok) {
			const contacts: Loaded<Contact>[] = yield selectFromImmutable<Loaded<Contact>[]>(getContacts);
			const activities: Activity[] = yield response.json();
			hydrateActivityContact(activities, contacts, action.payload);

			yield put(
				GetActivitiesByContactId.done({
					params: action.payload,
					result: activities,
				})
			);
		} else {
			yield put(
				GetActivitiesByContactId.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
		}
	} catch (error) {
		yield put(
			GetActivitiesByContactId.failed({ params: action.payload, error: error })
		);
	}
}
function getActivitiesByContactId(contactId: string): Promise<any> {
	return http('activity/contact/' + contactId, {
		method: 'GET',
	});
}

function* closeOldActivitiesSaga(action: VoidAction) {
	const errorSnackbarProps = getSnackbarErrorProps('Failed to close old To Dos');
	const successSnackbarProps = getSnackbarSuccessProps('Old To Dos closed successfully');
	try {
		const response = yield call(closeOldActivitiesCall);
		if (response.ok) {
			yield put(
				QueueSnackbar(successSnackbarProps)
			);
		} else {
			yield put(QueueSnackbar(errorSnackbarProps));
		}
	} catch (error) {
		yield put(QueueSnackbar(errorSnackbarProps));
	}
}
function closeOldActivitiesCall(): Promise<any> {
	return http('activity/closeOld', {
		method: 'POST',
	});
}

export function* activitySagas() {
	yield all([
		takeLatest(
			GetActivity.started,
			getActivitySaga
		),
		takeLatest(
			GetPagedActivities.started,
			getPagedActivitiesSaga
		),
		takeLatest(
			CreateActivity.started,
			createActivitySaga
		),
		takeLatest(
			EditActivity.started,
			editActivitySaga
		),
		takeLatest(
			CreateEmployerActivity.started,
			createEmployerActivitySaga
		),
		takeLatest(
			DeleteActivity.started,
			deleteActivitySaga
		),
		takeLatest(
			DisableGoogleCalendarSync.started,
			disableGoogleCalendarSyncSaga
		),
		takeLatest(
			CloseOldActivities,
			closeOldActivitiesSaga
		),
		takeLatest(
			GetActivitiesByContactId.started,
			getActivitiesByContactIdSaga
		),
		takeLatest(
			GetGoogleSignInCode.started,
			getGoogleSignInCodeSaga
		),
	]);
}