import { createSelector } from 'reselect';
import { AppState } from '../reducers/index';
import { AgentState } from '../reducers/agent_reducer';
import { fullName } from '../assets/common/string_builders';
import { LoaderState } from '../utilities/loader_util';
import { Strings } from '../assets/common/strings';

/**
 * Agent selectors
 */
export const getAgentState = (state: AppState) => state.agent || {};
export const getAgentCode = (state: AppState) => state.agent.agentCode;

export const getCurrentAgentDisplayName = createSelector(
	[getAgentState],
	(agent: AgentState) => {
		return fullName(agent);
	}
);

export const getCurrentAgentCode = createSelector([getAgentState], agent => {
	return agent.agentCode || 'unknown';
});

export const getAgentHierarchyLoader = createSelector(
	[getAgentState],
	(agent: AgentState) => agent.hierarchy
);

export const getAgentHierarchy = createSelector(
	[getAgentHierarchyLoader],
	(agentHierarchyLoader) => agentHierarchyLoader.state === LoaderState.Done ? agentHierarchyLoader.data : undefined
);

const isSubagentRole = (role: string) => role === Strings.AgentRoles.SubAgent;
export const getSubagents = createSelector(
	[getAgentHierarchy, getAgentCode],
	(hierarchy, agentCode) =>
		hierarchy?.agentHierarchyDownline.filter(
			downline => downline.reportsTo === agentCode && downline.agentRoles.some(isSubagentRole)
		)
)