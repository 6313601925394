import React, { HTMLProps } from 'react';
import { Grid, Paper } from '@material-ui/core';

interface Props extends HTMLProps<HTMLDivElement> {
    showBackground?: boolean;
}
export const DesktopPadding: React.FC<Props> = (props) => {

    const { showBackground=false, key, style, children } = props;

    return (
        <Grid key={key} container style={style}>
            <Grid item lg={3} />
            <Grid item xs={12} lg={6}>

                {showBackground
                    ? (
                        <Paper>
                            {children}
                        </Paper>
                    )
                    : children
                }

            </Grid>
        </Grid>
    )
};