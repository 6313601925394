import React from 'react';
import { Divider, Grid, GridSize, Paper } from '@material-ui/core';
import { AgentAddress } from '../../reducers/agent_reducer';
import { ReadOnlyTextField } from '../utility/read_only_text_field';
import { wrapInCollapsibleCard } from '../higher_order_component/wrap_in_card';
import { Strings } from '../../assets/common/strings';
import { UpsertStorefrontAddressContainer } from '../../containers/MyProfilePage/upsert_storefront_address';
import { SimpleListItem } from '../utility/simple_list_item';


interface Props {
    businessAddress?: AgentAddress;
    homeAddress?: AgentAddress;
}
const Address: React.FC<Props> = (props) => {
    const { businessAddress, homeAddress } = props;
    return (
        <Grid container>
            <SimpleListItem subtitle="Business Address" />
            <Grid container style={{ padding: 16 }}>
                {generateItem(Strings.AboutMeLabels.AddressLine1, businessAddress?.address1)}
                {generateItem(Strings.AboutMeLabels.AddressLine2, businessAddress?.address2)}
                {generateItem(Strings.Address.City, businessAddress?.city, 7)}
                {generateItem(Strings.Address.State, businessAddress?.state, 2)}
                {generateItem(Strings.Address.ZipCode, businessAddress?.zipCode, 3)}
            </Grid>

            <SimpleListItem subtitle="Personal Address" />
            <Grid container style={{ padding: 16 }}>
                {generateItem(Strings.AboutMeLabels.AddressLine1, homeAddress?.address1)}
                {generateItem(Strings.AboutMeLabels.AddressLine2, homeAddress?.address2)}
                {generateItem(Strings.Address.City, homeAddress?.city, 7)}
                {generateItem(Strings.Address.State, homeAddress?.state, 2)}
                {generateItem(Strings.Address.ZipCode, homeAddress?.zipCode, 3)}
            </Grid>

            <Paper style={{ margin: 10, width: '100%' }}>
                <UpsertStorefrontAddressContainer />
            </Paper>
        </Grid>
    );
}

const generateItem = (label: string, value: string='', grid: GridSize=12) => (
    <Grid item xs={grid}>
        <ReadOnlyTextField
            label={label}
            value={value}
        />
    </Grid>
);

export const AddressCard = wrapInCollapsibleCard(Address);