import React from 'react';
import { Dialog, DialogContent, DialogActions, Button, DialogTitle } from '@material-ui/core';
import { themePalette } from '../../utilities/branding';
import { InjectedProps as DialogInjectedProps } from '.';
import { Strings } from '../../assets/common/strings';

interface Props extends DialogInjectedProps {
    onSubmit: () => void;
    dismissSelf: () => void;
    validate?: () => boolean;
    preventCancel?: boolean;
    title?: string | JSX.Element;
    submitText?: string;
}

export class DialogForm extends React.PureComponent<Props> {
    render() {
        const { dismissSelf, children, onSubmit, validate, preventCancel, title, submitText } = this.props;

        return (
            <Dialog open fullWidth>
                {Boolean(title)
                    && <DialogTitle>
                        {title}
                    </DialogTitle>
                }
                <DialogContent>
                    {children}
                </DialogContent>
                <DialogActions>
                    {!Boolean(preventCancel)
                        && <Button
                            key={'cancelButton'}
                            color="secondary"
                            onClick={dismissSelf}
                        >
                            {Strings.ButtonText.Cancel}
                        </Button>
                    }
                    <Button
                        key={'saveButton'}
                        variant="contained"
                        color="primary"
                        style={{ backgroundColor: themePalette.accept_button }}
                        onClick={() => {
                            if (!Boolean(validate) || validate!()) {
                                onSubmit();
                                dismissSelf();
                            }
                        }}
                    >
                        {Boolean(submitText) ? submitText : Strings.ButtonText.Save}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}