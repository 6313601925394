import React from 'react';
import { change, Field, getFormValues } from 'redux-form';
import { FormSelect, FormTextField } from '../../../utilities/forms';
import {
	Typography,
	Icon,
	Collapse,
	CardActions,
	IconButton,
	FormControl,
} from '@material-ui/core';
import { connect } from '@optum-uhone-hmkts/rise';
import {
	MinMax,
	ToFromPickers,
	MultiSelect,
} from '../../../components/redux_form_material';
import { ExpandFilterFields } from '../../../actions/advanced_search_actions';
import { themePalette } from '../../../utilities/branding';
import { Lookups } from '../../../utilities/lookup';
import { BlankYesNoMenuOptionsArray } from '../../../components/utility/form';
import { AppState } from '../../../reducers';
import { EmployerFilters } from '../../../reducers/advanced_search_reducer';
import { getEmployerTags } from '../../../actions/employer_actions';

interface OwnProps {
	formName: string;
}

interface StateProps {
	formValues: EmployerFilters;
	industryList: string[];
	employerCommonExpanded: boolean;
	employerAdvancedExpanded: boolean;
	employerTagFilterResult: string[];
}

interface DispatchProps {
	expandFilterFields: (filterFieldGroup: string) => void;
	changeFieldValue: (form: string, field: string, value: any) => void;
	getEmployerTags: (search: string) => void;
}

type Props = OwnProps & StateProps & DispatchProps;
class _EmployerFields extends React.Component<Props> {
	constructor(props: any) {
		super(props);
	}

	handleExpandCommonClick = () => {
		this.props.expandFilterFields('employerCommonExpanded');
	};

	handleExpandAdvancedClick = () => {
		this.props.expandFilterFields('employerAdvancedExpanded');
	};

	render() {
		const { employerTagFilterResult } = this.props;
		return (
			<div>
				<Typography
					variant="h5"
					style={{ paddingLeft: 16, paddingTop: 16 }}
				>
					{'Employer'}
				</Typography>
				<div onClick={this.handleExpandCommonClick} style={styles.pointer}>
					<CardActions style={{ marginBottom: '0px' }}>
						<span
							style={{
								fontSize: '20px',
								color: themePalette.sub_text,
								paddingLeft: 10,
							}}
						>
							Common
						</span>
						<IconButton
							style={
								this.props.employerCommonExpanded
									? styles.expandOpen
									: styles.expand
							}
						>
							<Icon>keyboard_arrow_down</Icon>
						</IconButton>
					</CardActions>
				</div>
				<Collapse
					in={this.props.employerCommonExpanded}
					style={{ padding: '0 20px' }}
				>
					<Field
						name="employerTags"
						label="Employer Tags"
						component={MultiSelect}
						resultSet={employerTagFilterResult}
						fullWidth
						forceAutoComplete
						onChangeCallback={(value: string) => this.props.getEmployerTags(value)}
					/>
					<div style={styles.addBottomMargin}>
						<Field
							name="companyName"
							component={FormTextField}
							label="Enter Company Name"
							fullWidth
						/>
					</div>
					<MinMax name="numEmployees" title="Number of Employees" min={0} />
					<div style={styles.addBottomMargin}>
						<Field
							name="employerIndustry"
							label="Industry"
							component={MultiSelect}
							resultSet={this.props.industryList}
							fullWidth
						/>
					</div>
				</Collapse>
				<div onClick={this.handleExpandAdvancedClick} style={styles.pointer}>
					<CardActions style={{ marginBottom: '0px' }}>
						<span
							style={{
								fontSize: '20px',
								color: themePalette.sub_text,
								paddingLeft: 10,
							}}
						>
							Advanced
						</span>
						<IconButton
							style={
								this.props.employerAdvancedExpanded
									? styles.expandOpen
									: styles.expand
							}
						>
							<Icon>keyboard_arrow_down</Icon>
						</IconButton>
					</CardActions>
				</div>
				<Collapse
					in={this.props.employerAdvancedExpanded}
					style={{ padding: '0 20px' }}
				>
					<div style={styles.addBottomMargin}>
						<MinMax
							name="noOfGroupEligible"
							title="No of Group Eligible"
							min={0}
						/>
					</div>
					<div style={styles.addBottomMargin}>
						<MinMax name="annualRevenue" title="Annual Revenue" min={0} />
					</div>
					<ToFromPickers name="renewalDate" title="Renewal Date" />
					<div style={styles.addBottomMargin}>
						<FormControl fullWidth>
							<Field
								name="ancillariesOffered"
								component={FormSelect}
								children={BlankYesNoMenuOptionsArray}
								fullWidth
								label={'Ancillaries Offered'}
							/>
						</FormControl>
					</div>
					<div style={styles.addBottomMargin}>
						<FormControl fullWidth>
							<Field
								name="employerContributionsToGroupCoverage"
								component={FormSelect}
								children={BlankYesNoMenuOptionsArray}
								fullWidth
								label={'Employer Contributions to Group Coverage'}
							/>
						</FormControl>
					</div>
					<div style={styles.addBottomMargin}>
						<MinMax
							name="employerContributionAmount"
							title="Employer Contribution Amount"
							min={0}
						/>
					</div>
				</Collapse>
			</div>
		);
	}
}

function mapStateToProps(state: AppState, ownProps: Props): StateProps {
	const { expandedStates, employerTagFilterResult } = state.advancedSearch;

	return {
		formValues: getFormValues(ownProps.formName)(state),
		industryList: state.lookup.getLabels(Lookups.EmployerIndustry),
		employerCommonExpanded: expandedStates.employerCommonExpanded,
		employerAdvancedExpanded: expandedStates.employerAdvancedExpanded,
		employerTagFilterResult,
	};
}

function mapDispatchToProps(dispatch: any): DispatchProps {
	return {
		expandFilterFields: (filterFieldGroup: string) =>
			dispatch(ExpandFilterFields(filterFieldGroup)),
		changeFieldValue: (form: string, field: string, value: any) =>
			dispatch(change(form, field, value)),
		getEmployerTags: (search: string) =>
			dispatch(getEmployerTags.started(search)),
	};
}

export const EmployerFields = connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps, true)(
	_EmployerFields
);

const styles: { [key: string]: React.CSSProperties } = {
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
	},
	expandOpen: {
		transform: 'rotate(180deg)',
		marginLeft: 'auto',
	},
	addBottomMargin: {
		marginBottom: 15,
	},
	pointer: {
		cursor: 'pointer',
	},
};
