import React from 'react';
import { RadioGroup, } from '@material-ui/core';
import { Grid } from '@optum-uhone-hmkts/rise';

export const FormRadioGroup = ({ label, input, fullWidth, gridSize, children }) => (
    <Grid
        gridSize={fullWidth ? "12|12" : gridSize}
    >
        <RadioGroup
            {...input}
            valueSelected={input.value}
            onChange={(event, value) => input.onChange(value)}
        >
            {children}
        </RadioGroup>
    </Grid>
)