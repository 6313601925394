import { Avatar } from '@material-ui/core';
import React from 'react';
import { Agent, AgentState } from '../../reducers/agent_reducer';
import { initials } from '../../assets/common/string_builders';
import { DownlineAgent } from '../../reducers/product_reducer';

interface Props {
	avatarSize?: number;
	agent?: Agent | AgentState | DownlineAgent;
	style?: React.CSSProperties;
	showInitials?: boolean;
	disabled?: boolean;
}
export const AgentAvatar: React.FC<Props> = (props: Props) => {

	const { agent, avatarSize=40, style, showInitials=false, disabled=false } =  props;
	if (!agent) {
		return null;
	}
	const avatarStyle: React.CSSProperties = {
		width: avatarSize,
		height: avatarSize,
		opacity: disabled ? 0.3 : 1.0,
		fontSize: avatarSize / 2,
		...style,
	};

	const imageUrl = (agent as AgentState)?.profileImage || (agent as Agent)?.agentImage || (agent as DownlineAgent)?.image || '';
	return (
		<Avatar
			style={avatarStyle}
			src={!showInitials ? imageUrl?.replace('http://', 'https://') : undefined}
			sizes={avatarSize.toString()}
		>
			{initials(agent)}
		</Avatar>
	);
};
