import React from 'react';
import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core';

const useTextStyle = makeStyles(
	createStyles({
		text: {
            height: '100%', 
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 10,
            paddingRight: 10,
            textAlign: 'center',
        },
	}),
);

interface Props {
    text?: string;
}
export const EmptyMessage: React.FC<Props> = ({ text='No content to display' }) => {
    const styles = useTextStyle();
    return (
        <Grid container justify="center" alignItems="center" className={styles.text}>
            <Typography variant="body2">{text}</Typography>
        </Grid>
    );
};