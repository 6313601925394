import { isDesktop, isMobileDevice } from './is_mobile';
import { store, getAppState } from '..';
import { toggleDesktopView, windowResized } from '../actions/layout_actions';
import { debounce } from 'lodash';


//TODO: get rid of all this, we shouldn't be manually calculating a lot of this

export function getScreenHeight(): number {
	return window.innerHeight;
}

export function getScreenWidth(): number {
	return window.innerWidth;
}

export const doOnResizeWindow = () => {
	const state = getAppState().layout;
	const height = getScreenHeight();
	const width = getScreenWidth();
	if (state.width !== width || state.height !== height) {
		if (isDesktop() && !state.desktop) {
			store.dispatch(toggleDesktopView(true));
		}

		if (!isDesktop() && state.desktop) {
			store.dispatch(toggleDesktopView(false));
		}
		store.dispatch(windowResized(width, height));
		return true;
	}
	return false;
}

let interval: NodeJS.Timer | undefined;
let timeout: NodeJS.Timer | undefined;
const orientationInterval = () => {
	if (interval) {
		clearInterval(interval);
	}
	if (timeout) {
		clearTimeout(timeout);
	}
	interval = setInterval(() => {
		if (doOnResizeWindow()) {
			if (interval) {
				clearInterval(interval);
			}
			if (timeout) {
				clearTimeout(timeout);
			}
		}
	}, 50);
	timeout = setTimeout(() => {
		if (interval) {
			clearInterval(interval);
		}
	}, 500);
}

export const setupResponsiveBehavior = () => {
	window.addEventListener('orientationchange', orientationInterval);
    if (!isMobileDevice) {
        window.addEventListener('resize', debounce(doOnResizeWindow, 300));
    }
}
