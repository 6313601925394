import React from 'react';
import {
	Typography,
	Grid,
	Button,
	TextField,
	MenuItem,
	Collapse,
	CardHeader,
	IconButton,
	Icon,
	CardContent,
	Switch,
} from '@material-ui/core';
import { themePalette } from '../../utilities/branding';
import Moment from 'moment';
import { Template, TemplateTypeOptions } from '../../reducers/template_reducer';
import {
	EmailAutomationEvent,
	EmailAutomationTemplateInfo,
} from '../../reducers/email_automation_reducer';
import { SnackbarProps } from '../../actions/snackbar_actions';
import { MoreMenu } from '../nav/more_menu';
import ClampLines from 'react-clamp-lines';
import { tabInfo } from '../../containers/emails/email_automation_page';
import { getMonth } from '../utility/months';
import { composeSearchString } from '../../utilities/search_util';
import { getSnackbarInfoProps } from '../../utilities/snackbar_util';

interface EventDetailCardProps {
	agentTemplates: Template[];
	corporateTemplates: Template[];
	event: EmailAutomationEvent;
	tab: string;
	onAdd: (
		templateInfo: EmailAutomationTemplateInfo,
		event: EmailAutomationEvent
	) => void;
	onDelete: (
		oldTemplateInfo: EmailAutomationTemplateInfo,
		newTemplateInfo: EmailAutomationTemplateInfo,
		event: EmailAutomationEvent
	) => void;
	triggerEdit: (
		templateInfo: EmailAutomationTemplateInfo,
		event: EmailAutomationEvent
	) => void;
	resetEdits: (event: EmailAutomationEvent) => void;
	editRowAdd: (
		oldTemplateInfo: EmailAutomationTemplateInfo,
		newTemplateInfo: EmailAutomationTemplateInfo,
		event: EmailAutomationEvent
	) => void;
	queueSnackBar: (snackBarProps: SnackbarProps) => void;
	triggerSave: () => void;
}

interface EventDetailCardState {
	selectedTemplate?: Template;
	selectedEditTemplate?: Template;
	timeValue: number;
	editTimeValue: number;
	expanded: boolean;
}

const happyBirthday = 'Happy Birthday';

export class EventDetailCard extends React.Component<
	EventDetailCardProps,
	EventDetailCardState
	> {
	constructor(props: EventDetailCardProps) {
		super(props);
		this.state = {
			selectedTemplate: undefined,
			selectedEditTemplate: undefined,
			timeValue: 0,
			editTimeValue: 0,
			expanded: false,
		};
	}

	filterAndRenderTemplateOptions = (templates: Template[]) => templates
		.slice()
		.filter(template => {
			const isValid = !template.isDeleted && !template.isDraft;
			const allEventTypes = template.emailAutomationEventTypeCategory.length == 0;
			const allLinesOfBusiness = template.linesOfBusiness.length == 0;

			const matchesLinesOfBusiness = template.linesOfBusiness.some(templateLob => templateLob == this.props.event.lob)
			const matchesTemplateType = template.emailAutomationEventTypeCategory.some(
				templateEventType => templateEventType == this.props.event.emailAutomationEventTypeCategory
			)

			return isValid &&
				(
					(matchesTemplateType || allEventTypes)
						&& (matchesLinesOfBusiness || allLinesOfBusiness)
				)
		}).map((template, index) => (
			<MenuItem
				value={template.id}
				key={'EA_CT_' + template.title + '_' + index}
			>
				{template.title + ' - ' + composeSearchString(template.linesOfBusiness, true)}
			</MenuItem>
		));

	composeOptions = (): JSX.Element[] => ([
		...this.filterAndRenderTemplateOptions(this.props.corporateTemplates),
		<hr key='hr' />,
		...this.filterAndRenderTemplateOptions(this.props.agentTemplates),
	]);

	getTemplates = (): Template[] => {
		return this.props.corporateTemplates.concat(this.props.agentTemplates);
	};

	handleAddRowClick = (isMonth: boolean) => {
		if (this.state.selectedTemplate && this.state.timeValue >= 0) {
			const templateInfo: EmailAutomationTemplateInfo = {
				id: '',
				userId: '',
				updatedOn: Moment().toDate(),
				updatedBy: '',
				createdOn: Moment().toDate(),
				createdBy: '',
				isDeleted: false,
				EmailTemplateId: this.state.selectedTemplate.id,
				Value: this.state.timeValue == 0 && isMonth ? 1 : this.state.timeValue,
				isEditting: false,
				isUpdated: true,
			};

			if (
				this.validateTimeValue(templateInfo) &&
				this.validateTemplateLimit()
			) {
				this.props.onAdd(templateInfo, this.props.event);
			}
		} else {
			this.props.queueSnackBar(getSnackbarInfoProps('Template or Time Value fields are invalid', 6000));
		}
	};

	handleDays = (event: any) => {
		let value = event.target.value;

		if (!value || value < 0) value = 0;
		else if (value > 99) value = 99;

		this.setState({ timeValue: value });
	};

	handleEditDays = (event: any) => {
		let value = event.target.value;

		if (!value || value < 0) value = 0;
		else if (value > 99) value = 99;

		this.setState({ editTimeValue: value });
	};

	handleEditMonthChange = (event: any) => {
		let timeValue = event.target.value;

		if (!timeValue || timeValue < 1) timeValue = 1;
		else if (timeValue > 12) timeValue = 12;

		this.setState({ editTimeValue: timeValue });
	};

	handleEditLostChange = (event: any) => {
		let timeValue = event.target.value;

		if (!timeValue || timeValue < 0) timeValue = 0;
		else if (timeValue > 5) timeValue = 5;

		this.setState({ editTimeValue: timeValue });
	};

	handleRowRemove = (oldTemplateInfo: EmailAutomationTemplateInfo) => {
		const newTemplateInfo: EmailAutomationTemplateInfo = {
			id: oldTemplateInfo.id,
			userId: oldTemplateInfo.userId,
			updatedBy: '',
			updatedOn: Moment().toDate(),
			createdBy: oldTemplateInfo.createdBy,
			createdOn: oldTemplateInfo.createdOn,
			isDeleted: true,
			EmailTemplateId: oldTemplateInfo.EmailTemplateId,
			Value: oldTemplateInfo.Value,
			isEditting: true,
			isUpdated: true,
		};

		this.props.onDelete(oldTemplateInfo, newTemplateInfo, this.props.event);
	};

	handleEditRowAdd = (oldTemplateInfo: EmailAutomationTemplateInfo) => {
		if (this.state.selectedEditTemplate && this.state.editTimeValue >= 0) {
			const newTemplateInfo: EmailAutomationTemplateInfo = {
				id: oldTemplateInfo.id,
				userId: oldTemplateInfo.userId,
				updatedBy: '',
				updatedOn: Moment().toDate(),
				createdBy: oldTemplateInfo.createdBy,
				createdOn: oldTemplateInfo.createdOn,
				isDeleted: false,
				EmailTemplateId: this.state.selectedEditTemplate.id,
				Value: this.state.editTimeValue,
				isEditting: true,
				isUpdated: true,
			};

			if (this.validateEditTimeValue(oldTemplateInfo, newTemplateInfo)) {
				this.props.editRowAdd(
					oldTemplateInfo,
					newTemplateInfo,
					this.props.event
				);
			}
		} else {
			this.props.queueSnackBar(getSnackbarInfoProps('Template or Time Value fields are invalid', 6000));
		}
	};

	handleEditTemplateDropDownChange = (event: any) => {
		const id = event.target.value;
		const templates = this.getTemplates();

		let selectedTemplate = templates.find(template => {
			return template.id === id;
		});

		this.setState({ selectedEditTemplate: selectedTemplate });
	};

	handleExpand = () => {
		this.setState({ expanded: !this.state.expanded });
	};

	handleMonthChange = (event: any) => {
		let timeValue = event.target.value;

		if (timeValue < 1) timeValue = 1;
		if (timeValue > 12) timeValue = 12;

		this.setState({ timeValue: timeValue });
	};

	handleLostChange = (event: any) => {
		let timeValue = event.target.value;

		if (timeValue < 0) timeValue = 0;
		if (timeValue > 5) timeValue = 5;

		this.setState({ timeValue: timeValue });
	};

	handleTemplateDropDownChange = (event: any) => {
		const id = event.target.value;
		const templates = this.getTemplates();

		let selectedTemplate = templates.find(template => {
			return template.id === id;
		});

		this.setState({ selectedTemplate: selectedTemplate });
	};

	toggleEditTime = () => {
		this.props.triggerSave();
		this.setState({ editTimeValue: this.state.editTimeValue == 1 ? 0 : 1 });
	};

	toggleTime = () => {
		this.props.triggerSave();
		this.setState({ timeValue: this.state.timeValue == 1 ? 0 : 1 });
	};

	triggerPreview = (templateInfo: EmailAutomationTemplateInfo) => {
		window.open(`/#/template/preview/${templateInfo.EmailTemplateId}/Management`, '_blank');
	};

	triggerEdit = (templateInfo: EmailAutomationTemplateInfo, event: EmailAutomationEvent) => {
		const templates = this.getTemplates();
		let selectedTemplate = templates.find(template => {
			return template.id === templateInfo.EmailTemplateId;
		});

		templateInfo.isEditting = true;

		this.setState({
			selectedEditTemplate: selectedTemplate,
			editTimeValue: templateInfo.Value,
		}, () => this.props.triggerEdit(templateInfo, event));
	};

	validateTimeValue = (templateInfo: EmailAutomationTemplateInfo): boolean => {
		let isValid = true;

		if (
			this.props.event.eventName == 'Follow-up' ||
			this.props.event.eventName == 'Trying to Reach You'
		) {
			this.props.event.templateInfo.forEach(eventTemplateInfo => {
				if (
					eventTemplateInfo.Value == templateInfo.Value &&
					!eventTemplateInfo.isDeleted
				) {
					const templateArray = this.props.agentTemplates.concat(
						this.props.corporateTemplates
					);
					let templateName = 'This Template';

					templateArray.forEach(template => {
						if (templateInfo.EmailTemplateId == template.id) {
							templateName = template.title;
						}
					});

					this.props.queueSnackBar(
						getSnackbarInfoProps(`${templateName} cannot be sent on the same day as previous follow-up emails.`, 6000)
					);
					isValid = false;
				}
			});
		}
		if (this.props.event.eventName == 'No Sale') {
			this.props.event.templateInfo.forEach(eventTemplateInfo => {
				if (
					eventTemplateInfo.Value == templateInfo.Value &&
					!eventTemplateInfo.isDeleted
				) {
					const templateArray = this.props.agentTemplates.concat(
						this.props.corporateTemplates
					);
					let templateName = 'This Template';

					templateArray.forEach(template => {
						if (templateInfo.EmailTemplateId == template.id) {
							templateName = template.title;
						}
					});

					this.props.queueSnackBar(
						getSnackbarInfoProps(`${templateName} cannot be sent for the same reason as another No Sale email.`, 6000)
					);
					isValid = false;
				}
			});
		}
		return isValid;
	};

	validateEditTimeValue = (
		oldTemplateInfo: EmailAutomationTemplateInfo,
		newTemplateInfo: EmailAutomationTemplateInfo
	): boolean => {
		let isValid = true;

		if (
			this.props.event.eventName == 'Follow-up' ||
			this.props.event.eventName == 'Trying to Reach You'
		) {
			this.props.event.templateInfo.forEach(eventTemplateInfo => {
				if (
					eventTemplateInfo.Value == newTemplateInfo.Value &&
					newTemplateInfo.Value != oldTemplateInfo.Value
				) {
					const templateArray = this.props.agentTemplates.concat(
						this.props.corporateTemplates
					);
					let templateName = 'This Template';

					templateArray.forEach(template => {
						if (newTemplateInfo.EmailTemplateId == template.id) {
							templateName = template.title;
						}
					});

					this.props.queueSnackBar(
						getSnackbarInfoProps(`${templateName} cannot be sent on the same day as previous follow-up emails.`, 6000)
					);

					isValid = false;
				}
			});
		}
		if (this.props.event.eventName == 'No Sale') {
			this.props.event.templateInfo.forEach(eventTemplateInfo => {
				if (
					eventTemplateInfo.Value == newTemplateInfo.Value &&
					newTemplateInfo.Value != oldTemplateInfo.Value
				) {
					const templateArray = this.props.agentTemplates.concat(
						this.props.corporateTemplates
					);
					let templateName = 'This Template';

					templateArray.forEach(template => {
						if (newTemplateInfo.EmailTemplateId == template.id) {
							templateName = template.title;
						}
					});

					this.props.queueSnackBar(
						getSnackbarInfoProps(`${templateName} cannot be sent for the same reason as another No Sale email.`, 6000)
					);
					isValid = false;
				}
			});
		}

		return isValid;
	};

	validateTemplateLimit = (): boolean => {
		let count = 0;
		this.props.event.templateInfo.forEach(template => {
			if (!template.isDeleted) {
				count++;
			}
		});
		if (this.props.event.templateLimit < count) {
			this.props.queueSnackBar(getSnackbarInfoProps('Template limit reached for this event...', 6000));
			return false;
		}

		return true;
	};

	renderPrependedTimeValueDescription = () => {
		let valueDescription = '';
		switch (this.props.tab) {
			case tabInfo.working.tabName:
				if (this.props.event.eventName == 'No Sale') {
					valueDescription = 'Reason:  ';
				} else {
					valueDescription = 'Send Email';
				}
				break;
			case tabInfo.lost.tabName:
			case tabInfo.active.tabName:
			case tabInfo.terminated.tabName:
			default:
				valueDescription = '';
				break;
		}
		return valueDescription;
	};

	renderPostpendedTimeValueDescription = () => {
		let valueDescription = '';
		switch (this.props.tab) {
			case tabInfo.working.tabName:
				if (this.props.event.eventName == 'No Sale') {
					valueDescription = '';
				} else {
					valueDescription = 'days after lead created.';
				}
				break;
			case tabInfo.active.tabName:
				if (this.props.event.eventName == happyBirthday) {
					valueDescription = '';
				} else {
					valueDescription = 'months before renewal.';
				}
				break;
			case tabInfo.lost.tabName:
			case tabInfo.terminated.tabName:
			default:
				valueDescription = '';
				break;
		}
		return valueDescription;
	};

	renderAddRowDays = () => {
		const { selectedTemplate } = this.state;
		let isBirthdayEvent = this.props.event.eventName == happyBirthday;
		return this.countTemplates() < this.props.event.templateLimit ? (
			<Grid container alignItems="center">
				<Grid item xs={12} sm={10}>
					{
						this.props.agentTemplates &&
							this.props.corporateTemplates ? (
								<TextField
									select
									onChange={this.handleTemplateDropDownChange}
									value={(selectedTemplate && selectedTemplate.id) || ''}
									style={styles.select}
									InputProps={{ style: styles.bodyFont }}
								>
									{this.composeOptions()}
								</TextField>
							) : (
								<TextField select value="hr">
									<MenuItem>
										<hr />
									</MenuItem>
								</TextField>
							)}
					{isBirthdayEvent ? (
						<Switch
							checked={this.state.timeValue == 1}
							onChange={this.toggleTime}
						/>
					) : (
							<Typography style={{ display: 'inline-block' }} component="div">
								{this.renderPrependedTimeValueDescription()}

								<TextField
									type="number"
									disabled={this.props.event.isUneditable}
									value={this.props.event.isUneditable ? 0 : this.state.timeValue}
									onChange={this.handleDays}
									InputProps={{ style: styles.bodyFont, disableUnderline: true }}
									inputProps={{ style: { textAlign: 'center' } }}
									style={{ width: '3em' }}
								/>
								{this.renderPostpendedTimeValueDescription()}
							</Typography>
						)}
					{isBirthdayEvent ? (
						<Typography style={{ display: 'inline-block' }}>
							BCC on Birthday Email
						</Typography>
					) : null}
				</Grid>
				<Grid item xs={12} sm={2} style={{ textAlign: 'right' }}>
					<Button
						variant="contained"
						onClick={() => this.handleAddRowClick(false)}
						disabled={!selectedTemplate}
					>
						Add
					</Button>
				</Grid>
			</Grid>
		) : null;
	};

	renderAddRowMonths = () => {
		return this.countTemplates() < this.props.event.templateLimit ? (
			<Grid container alignItems="center">
				<Grid item xs={12} sm={10}>
					{this.props.agentTemplates &&
						this.props.corporateTemplates &&
						this.state.selectedTemplate ? (
							<TextField
								select
								onChange={this.handleTemplateDropDownChange}
								value={this.state.selectedTemplate.id}
								style={styles.select}
								InputProps={{ style: styles.bodyFont }}
							>
								{this.composeOptions()}
							</TextField>
						) : (
							<TextField select value="hr">
								<MenuItem>
									<hr />
								</MenuItem>
							</TextField>
						)}
					<TextField
						select
						value={this.state.timeValue > 0 ? this.state.timeValue : 1}
						onChange={this.handleMonthChange}
						disabled={this.props.event.isUneditable}
						InputProps={{ style: styles.bodyFont }}
					>
						{monthMenuItems}
					</TextField>
				</Grid>
				<Grid item xs={12} sm={2} style={{ textAlign: 'right' }}>
					<Button variant="contained" onClick={() => this.handleAddRowClick(true)}>
						Add
					</Button>
				</Grid>
			</Grid>
		) : null;
	};

	renderAddRowLost = () => {
		const { selectedTemplate } = this.state;
		return this.countTemplates() < this.props.event.templateLimit ? (
			<Grid container alignItems="center">
				<Grid item xs={12} sm={10}>
					{this.props.agentTemplates &&
						this.props.corporateTemplates ? (
							<TextField
								select
								onChange={this.handleTemplateDropDownChange}
								value={(selectedTemplate && selectedTemplate.id) || ''}
								style={styles.select}
								InputProps={{ style: styles.bodyFont }}
							>
								{this.composeOptions()}
							</TextField>
						) : (
							<TextField select value="hr">
								<MenuItem>
									<hr />
								</MenuItem>
							</TextField>
						)}
					<Typography style={{ display: 'inline-block' }} component="div">
						{this.renderPrependedTimeValueDescription()}
						<TextField
							select
							value={this.state.timeValue > 0 ? this.state.timeValue : 1}
							onChange={this.handleLostChange}
							disabled={this.props.event.isUneditable}
							InputProps={{ style: styles.bodyFont }}
							inputProps={{ style: { textAlign: 'center' } }}
						>
							{lostMenuItems}
						</TextField>
					</Typography>
				</Grid>
				<Grid item xs={12} sm={2} style={{ textAlign: 'right' }}>
					<Button
						variant="contained"
						onClick={() => this.handleAddRowClick(false)}
						disabled={!selectedTemplate}
					>
						Add
					</Button>
				</Grid>
			</Grid>
		) : null;
	};

	renderClamp = (description: string): JSX.Element => {
		return (
			<Typography
				component="div"
				style={{
					color: themePalette.tertiary_text,
				}}
			>
				<ClampLines
					text={description || ''}
					lines={2}
					buttons={false}
				/>
			</Typography>
		);
	};

	renderEditRowDays = (
		templateInfo: EmailAutomationTemplateInfo,
		index: number
	): JSX.Element => {
		const isBirthdayEvent = this.props.event.eventName == happyBirthday;
		return (
			<div
				key={templateInfo.EmailTemplateId + '_' + index}
				style={styles.templateBorder}
			>
				<Grid container alignItems="center">
					<Grid item xs={12} sm={8} lg={10}>
						{this.props.agentTemplates &&
							this.props.corporateTemplates &&
							this.state.selectedEditTemplate ? (
								<TextField
									select
									onChange={this.handleEditTemplateDropDownChange}
									value={this.state.selectedEditTemplate.id}
									style={styles.select}
									InputProps={{ style: styles.bodyFont }}
								>
									{this.composeOptions()}
								</TextField>
							) : (
								<TextField select value="hr">
									<MenuItem>
										<hr />
									</MenuItem>
								</TextField>
							)}
						<Typography style={{ display: 'inline-block' }} component="div">
							{this.renderPrependedTimeValueDescription()}
							{isBirthdayEvent ? (
								<Grid container style={{ marginTop: '-15px' }}>
									<Grid item>
										<Switch
											checked={this.state.editTimeValue == 1}
											onChange={this.toggleEditTime}
										/>
									</Grid>
									<Grid item>
										<Typography style={{ marginTop: '15px' }}>
											BCC on Birthday Email
										</Typography>
									</Grid>
								</Grid>
							) : (
									<TextField
										type="number"
										disabled={this.props.event.isUneditable}
										fullWidth
										value={this.state.editTimeValue}
										onChange={this.handleEditDays}
										InputProps={{
											style: styles.bodyFont,
											disableUnderline: true,
										}}
										inputProps={{ style: { textAlign: 'center' } }}
										style={{ width: '3em' }}
									/>
								)}
							{this.renderPostpendedTimeValueDescription()}
						</Typography>
					</Grid>
					<Grid item xs={10} sm={2} lg={1} style={{ textAlign: 'right' }}>
						<Button
							onClick={() => this.props.resetEdits(this.props.event)}
						>
							Cancel
						</Button>
					</Grid>
					<Grid item xs={2} sm={2} lg={1} style={{ textAlign: 'right' }}>
						<Button
							variant="contained"
							onClick={() => this.handleEditRowAdd(templateInfo)}
						>
							Save
						</Button>
					</Grid>
				</Grid>
			</div>
		);
	};

	renderEditRowMonths = (
		templateInfo: EmailAutomationTemplateInfo,
		index: number
	): JSX.Element => {
		return (
			<div
				key={templateInfo.EmailTemplateId + '_' + index}
				style={styles.templateBorder}
			>
				<Grid container alignItems="center">
					<Grid item xs={12} sm={8} lg={10}>
						{this.props.agentTemplates &&
							this.props.corporateTemplates &&
							this.state.selectedEditTemplate ? (
								<TextField
									select
									onChange={this.handleEditTemplateDropDownChange}
									value={this.state.selectedEditTemplate.id}
									style={styles.select}
									InputProps={{ style: styles.bodyFont }}
								>
									{this.composeOptions()}
								</TextField>
							) : (
								<TextField select value="hr">
									<MenuItem>
										<hr />
									</MenuItem>
								</TextField>
							)}
						<TextField
							select
							value={
								this.state.editTimeValue > 0 ? this.state.editTimeValue : 1
							}
							onChange={this.handleEditMonthChange}
							disabled={this.props.event.isUneditable}
							InputProps={{ style: styles.bodyFont }}
						>
							{monthMenuItems}
						</TextField>
					</Grid>
					<Grid item xs={10} sm={2} lg={1} style={{ textAlign: 'right' }}>
						<Button
							onClick={() => this.props.resetEdits(this.props.event)}
						>
							Cancel
						</Button>
					</Grid>

					<Grid item xs={2} sm={2} lg={1} style={{ textAlign: 'right' }}>
						<Button
							variant="contained"
							onClick={() => this.handleEditRowAdd(templateInfo)}
						>
							Save
						</Button>
					</Grid>
				</Grid>
			</div>
		);
	};

	renderEditRowLost = (
		templateInfo: EmailAutomationTemplateInfo,
		index: number
	): JSX.Element => {
		return (
			<div
				key={templateInfo.EmailTemplateId + '_' + index}
				style={styles.templateBorder}
			>
				<Grid container alignItems="center">
					<Grid item xs={12} sm={8} lg={10}> {
						this.props.agentTemplates
							&& this.props.corporateTemplates
							&& this.state.selectedEditTemplate
							? (
								<TextField
									select
									onChange={this.handleEditTemplateDropDownChange}
									value={this.state.selectedEditTemplate.id}
									style={styles.select}
									InputProps={{ style: styles.bodyFont }}
								>
									{this.composeOptions()}
								</TextField>
							) : (
								<TextField select value="hr">
									<MenuItem>
										<hr />
									</MenuItem>
								</TextField>
							)}
						<Typography style={{ display: 'inline-block' }} component="div">
							{this.renderPrependedTimeValueDescription()}
							<TextField
								select
								value={this.state.editTimeValue > 0 ? this.state.editTimeValue : 1}
								onChange={this.handleEditLostChange}
								disabled={this.props.event.isUneditable}
								InputProps={{ style: styles.bodyFont }}
								inputProps={{ style: { textAlign: 'center' } }}
							>
								{lostMenuItems}
							</TextField>
						</Typography>
					</Grid>
					<Grid item xs={10} sm={2} lg={1} style={{ textAlign: 'right' }}>
						<Button
							onClick={() => this.props.resetEdits(this.props.event)}
						>
							Cancel
						</Button>
					</Grid>

					<Grid item xs={2} sm={2} lg={1} style={{ textAlign: 'right' }}>
						<Button
							variant="contained"
							onClick={() => this.handleEditRowAdd(templateInfo)}
						>
							Save
						</Button>
					</Grid>
				</Grid>
			</div>
		);
	};

	renderRow = (
		templateInfo: EmailAutomationTemplateInfo,
		index: number
	): JSX.Element => {
		const templateArray = this.getTemplates();
		let foundIndex = templateArray.findIndex(
			template => template.id == templateInfo.EmailTemplateId
		);

		const isBirthdayEvent = this.props.event.eventName == happyBirthday;

		return foundIndex > -1 ? (
			<div
				key={templateInfo.EmailTemplateId + '_' + index}
				style={{
					...styles.templateBorder,
					paddingRight: 0,
				}}
			>
				<Grid container>
					<Grid item xs={isBirthdayEvent ? 4 : 9} sm={isBirthdayEvent ? 4 : 9}>
						<Typography style={{ fontWeight: 'bold' }}>
							{templateArray[foundIndex].title +
								' ' +
								this.renderTimeValue(templateInfo.Value)}
						</Typography>
						{this.renderClamp(templateArray[foundIndex].description)}
					</Grid>
					{isBirthdayEvent ? (
						<Grid item xs={5} sm={5}>
							<Grid container>
								<Grid item>
									<Switch checked={templateInfo.Value == 1} disabled={true} />
								</Grid>
								<Grid item>
									<Typography style={{ marginTop: '15px' }}>
										BCC on Birthday Email
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					) : null}
					<Grid item xs={2} sm={2} style={{ textAlign: 'right' }}>
						<Typography>
							Last Modified
							<br />
							{Moment.utc(templateInfo.updatedOn).format('M-D-YYYY')}
						</Typography>
					</Grid>
					<Grid item xs={1} sm={1} style={{ textAlign: 'center' }}>
						<MoreMenu
							children={[
								{
									children: 'Preview Template',
									onClick: () =>
										this.triggerPreview(templateInfo)
								},
								{
									children: 'Edit',
									onClick: () =>
										this.triggerEdit(templateInfo, this.props.event),
								},
								{
									children: 'Remove',
									onClick: () => this.handleRowRemove(templateInfo),
								},
							]}
							key={'EmailAutomationPageMoreMenu_' + index}
							onMenuClick={() => this.props.resetEdits(this.props.event)}
							color={themePalette.default_avatar}
						/>
					</Grid>
				</Grid>
			</div>
		) : (
				<hr key={'NT_' + index} />
			);
	};

	renderTimeValue = (timeValue: number): string => {
		if (this.props.event.isUneditable) {
			return '';
		}
		let valueDescription = '';
		switch (this.props.tab) {
			case tabInfo.working.tabName:
				if (this.props.event.eventName != 'No Sale') {
					if (timeValue == 1) {
						valueDescription = '(1 Day)';
					} else {
						valueDescription = '(' + timeValue + ' Days)';
					}
				} else {
					valueDescription = '(Reason: ' + this.getLostReason(timeValue) + ')';
				}
				break;
			case tabInfo.active.tabName:
				if (timeValue == 1) {
					valueDescription = '(1 month until renewal)';
				} else {
					valueDescription = '(' + timeValue + ' months until renewal)';
				}
				break;
			case tabInfo.terminated.tabName:
				valueDescription = '(Send in ' + getMonth(timeValue) + ')';
				break;
			case tabInfo.lost.tabName:
				valueDescription = '(' + getMonth(timeValue) + ')';
				break;
			default:
				valueDescription = '';
				break;
		}
		return valueDescription;
	};

	render() {
		return this.props.corporateTemplates && this.props.agentTemplates ? (
			<div>
				<CardHeader
					style={{
						backgroundColor: themePalette.secondary_background,
						border: `1px solid ${themePalette.divider}`,
						cursor: 'pointer',
						padding: 2,
					}}
					title={this.props.event.eventName}
					subheader={
						this.countTemplates() + ' / ' + this.props.event.templateLimit
					}
					onClick={this.handleExpand}
					action={
						<IconButton
							onClick={this.handleExpand}
							aria-expanded={this.state.expanded}
							aria-label="Show more"
						>
							{this.state.expanded ? (
								<Icon>expand_less</Icon>
							) : (
									<Icon>expand_more</Icon>
								)}
						</IconButton>
					}
				/>
				<Collapse in={this.state.expanded} timeout="auto">
					<CardContent>
						<Typography style={{ marginBottom: '15px' }}>
							{this.props.event.eventDescription}
						</Typography>
						{this.props.event.templateInfo.map(
							(templateInfo: EmailAutomationTemplateInfo, index: number) => {
								return this.renderRows(templateInfo, index);
							}
						)}
						{this.renderAddRows()}
					</CardContent>
				</Collapse>
			</div>
		) : (
				<div />
			);
	}

	countTemplates() {
		let count = 0;
		this.props.event.templateInfo.forEach(template => {
			if (!template.isDeleted) {
				count++;
			}
		});
		return count;
	}

	renderAddRows() {
		if (this.props.tab == tabInfo.working.tabName) {
			if (this.props.event.eventName != 'No Sale') {
				return this.renderAddRowDays();
			} else {
				return this.renderAddRowLost();
			}
		} else if (this.props.tab == tabInfo.active.tabName) {
			return this.renderAddRowDays();
		} else {
			return this.renderAddRowMonths();
		}
	}

	renderRows(templateInfo: EmailAutomationTemplateInfo, index: number) {
		if (templateInfo.isDeleted) {
			return null;
		}

		if (templateInfo.isEditting) {
			if (this.props.tab == tabInfo.working.tabName) {
				if (this.props.event.eventName != 'No Sale') {
					return this.renderEditRowDays(templateInfo, index);
				} else {
					return this.renderEditRowLost(templateInfo, index);
				}
			} else if (this.props.tab == tabInfo.active.tabName) {
				return this.renderEditRowDays(templateInfo, index);
			} else {
				return this.renderEditRowMonths(templateInfo, index);
			}
		} else {
			return this.renderRow(templateInfo, index);
		}
	}

	getLostReason(value: number) {
		let reason = '';
		switch (value) {
			case 1:
				reason = 'Cost';
				break;
			case 2:
				reason = 'Covered by Competitor';
				break;
			case 3:
				reason = 'Covered by Spouse/Group';
				break;
			case 4:
				reason = 'Disconnected/Wrong Number';
				break;
			case 5:
				reason = 'Other Reasons';
				break;
		}
		return reason;
	}
}

const styles: { [selector: string]: React.CSSProperties } = {
	templateBorder: {
		border: '1px solid lightgrey',
		marginBottom: '10px',
		paddingLeft: '25px',
		paddingRight: '25px',
		paddingTop: '10px',
		paddingBottom: '10px',
	},
	select: {
		marginRight: '25px',
	},
	bodyFont: {
		fontSize: '0.875rem',
	},
};

const monthMenuItems: JSX.Element[] = [
	<MenuItem value={1} key={'Jan_1'}>
		January
	</MenuItem>,
	<MenuItem value={2} key={'Feb_2'}>
		February
	</MenuItem>,
	<MenuItem value={3} key={'Mar_3'}>
		March
	</MenuItem>,
	<MenuItem value={4} key={'Apr_4'}>
		April
	</MenuItem>,
	<MenuItem value={5} key={'May_5'}>
		May
	</MenuItem>,
	<MenuItem value={6} key={'Jun_6'}>
		June
	</MenuItem>,
	<MenuItem value={7} key={'Jul_7'}>
		July
	</MenuItem>,
	<MenuItem value={8} key={'Aug_8'}>
		August
	</MenuItem>,
	<MenuItem value={9} key={'Sep_9'}>
		September
	</MenuItem>,
	<MenuItem value={10} key={'Oct_10'}>
		October
	</MenuItem>,
	<MenuItem value={11} key={'Nov_11'}>
		November
	</MenuItem>,
	<MenuItem value={12} key={'Dec_12'}>
		December
	</MenuItem>,
];

const lostMenuItems: JSX.Element[] = [
	<MenuItem value={1} key={'Cost_0'}>
		Cost
	</MenuItem>,
	<MenuItem value={2} key={'Comp_1'}>
		Covered by Competitor
	</MenuItem>,
	<MenuItem value={3} key={'Spouse_2'}>
		Covered by Spouse/Group
	</MenuItem>,
	<MenuItem value={4} key={'Discon_3'}>
		Disconnected/Wrong Number
	</MenuItem>,
	<MenuItem value={5} key={'Other_4'}>
		Other Reasons
	</MenuItem>,
];
