import React from 'react';
import { MenuItem } from '@material-ui/core';

interface Props {
	options: SelectOption[];
}

export interface SelectOption {
	option: string;
	value?: string;
}

export const SelectListOptions = (props: Props): JSX.Element[] => {
	const menuList: JSX.Element[] = [];
	props.options.forEach((selectListOption, index) => {
		menuList.push(
			<MenuItem
				value={
					selectListOption.value
						? selectListOption.value
						: selectListOption.option
				}
				key={index}
			>
				{selectListOption.option}
			</MenuItem>
		);
	});

	return menuList;
};
