import React from 'react';
import moment from 'moment';
import {
	Avatar,
	Dialog,
	DialogContent,
	DialogActions,
	Button,
	Grow,
	DialogContentText,
} from '@material-ui/core';
import { Attachment } from '../../reducers/attachment_reducer';
import { displayDate } from '../../utilities/date_util';
import { themePalette } from '../../utilities/branding';
import { getIconSource } from '../../utilities/image_util';
import { wrapInCollapsibleCard } from '../higher_order_component/wrap_in_card';
import CustomList from '../utility/custom_list';
import { SimpleListItem } from '../utility/simple_list_item';
import { MoreMenu, createMenuAction } from '../nav/more_menu';

interface Props {
	attachments: Attachment[];
	onDelete: (attachment: Attachment) => void;
	onDownload: (attachment: Attachment) => void;
}

interface State {
	deleteDialogIsOpen: boolean;
	anchorEl?: HTMLElement;
	attachmentToDelete?: Attachment;
}

class AttachmentList extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			deleteDialogIsOpen: false,
		};
	}

	download = (attachment: Attachment) => {
		this.props.onDownload(attachment);
		this.setState({ anchorEl: undefined });
	};

	getAttachmentIcon = (attachment: Attachment) => {
		const splitFilename = attachment.fileName.split('.');
		if (splitFilename.length > 0) {
			const fileExtension = splitFilename[splitFilename.length - 1];
			return getIconSource(fileExtension);
		} else return '';
	};

	deleteAttachment = () => {
		if (this.state.attachmentToDelete) {
			this.props.onDelete(this.state.attachmentToDelete);
		}
		this.setState({ deleteDialogIsOpen: false });
	};

	renderCancelDialog() {
		return (
			<Dialog
				open={this.state.deleteDialogIsOpen}
				onClose={() => this.setState({ deleteDialogIsOpen: false })}
				TransitionComponent={Grow}
			>
				<DialogContent>
					<DialogContentText>Are you sure you would like to delete this attachment?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						color="secondary"
						onClick={() => this.setState({ deleteDialogIsOpen: false })}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="primary"
						style={{ backgroundColor: themePalette.delete_remove_reject_button }}
						onClick={this.deleteAttachment}
					>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	render() {
		return (
			<>
				<CustomList emptyMessage="You have no attachments">
					{this.props.attachments.map((attachment, index) => (
						<SimpleListItem
							key={`attachment-${index}`}
							icon={<Avatar src={this.getAttachmentIcon(attachment)} />}
							title={displayDate(moment.utc(attachment.dateCreated).local())}
							subtitle={attachment.fileName}
							onClick={() => this.download(attachment)}
							secondaryAction={
								<MoreMenu
									color={themePalette.tertiary_text}
									children={[createMenuAction('Delete', () => this.setState({ deleteDialogIsOpen: true, attachmentToDelete: attachment }))]}
								/>
							}
						/>
					))}
				</CustomList>
				{this.renderCancelDialog()}
			</>
		);
	}
}

export const AttachmentListCard = wrapInCollapsibleCard(AttachmentList);
