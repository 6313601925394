import React from 'react';
import { defaultSvgProps, SvgProps } from '..';

const PodcastSvg: React.FC<SvgProps> = (props) => (
    <svg viewBox="0 0 63.66 86.69" {...props}>
        <path 
            d="M57.66,38a1,1,0,0,1-1-1v-.5a1,1,0,0,1,1-1h2a10.59,10.59,0,0,0-10.51-9.33h0a10.59,10.59,0,0,0-10.51,9.33h2a1,1,0,0,1,1,1V37a1,1,0,0,1-1,1H38.6v2.56h2.12a1,1,0,0,1,1,1v.49a1,1,0,0,1-1,1H38.6v2.56h2.12a1,1,0,0,1,1,1v.49a1,1,0,0,1-1,1H38.6v2.56h2.12a1,1,0,0,1,1,1v.5a1,1,0,0,1-1,1H38.6V66A10.59,10.59,0,0,0,49.19,76.6h0A10.59,10.59,0,0,0,59.78,66V53.11H57.66a1,1,0,0,1-1-1v-.5a1,1,0,0,1,1-1h2.12V48.08H57.66a1,1,0,0,1-1-1V46.6a1,1,0,0,1,1-1h2.12V43.05H57.66a1,1,0,0,1-1-1v-.49a1,1,0,0,1,1-1h2.12V38Z" transform="translate(-17.36 -5.2)"
        />
        <path 
            d="M49.19,80.92A15,15,0,0,1,34.24,66V61.26a1.35,1.35,0,1,1,2.69,0V66a12.26,12.26,0,0,0,24.52,0V61.26a1.35,1.35,0,1,1,2.69,0V66a15,15,0,0,1-15,15Z" transform="translate(-17.36 -5.2)"
        />
        <path 
            d="M52.42,80.85v.51a1,1,0,0,1-1,1H47a1,1,0,0,1-1-1v-.51a1,1,0,0,1,1-1H51.4a1,1,0,0,1,1,1Z" transform="translate(-17.36 -5.2)"
        />
        <path 
            d="M61.32,61.61h.25a.51.51,0,0,1,.51.51v2.21a.51.51,0,0,1-.51.51h-.25a.51.51,0,0,1-.51-.51V62.12a.51.51,0,0,1,.51-.51Z" transform="translate(-17.36 -5.2)"
        />
        <path 
            d="M36.81,61.61h.25a.51.51,0,0,1,.51.51v2.21a.51.51,0,0,1-.51.51h-.25a.51.51,0,0,1-.51-.51V62.12a.51.51,0,0,1,.51-.51Z" transform="translate(-17.36 -5.2)"
        />
        <path 
            d="M49.19,91.63a2.28,2.28,0,0,1-2.29-2.29V80.6a2.29,2.29,0,0,1,4.58,0v8.74a2.29,2.29,0,0,1-2.29,2.29Z" transform="translate(-17.36 -5.2)"
        />
        <path 
            d="M56.9,91.89H41.48A5.85,5.85,0,0,1,47.33,86h3.72a5.85,5.85,0,0,1,5.85,5.85Z" transform="translate(-17.36 -5.2)"
        />
        <path 
            d="M81,37A31.83,31.83,0,1,0,30.6,62.86a1.22,1.22,0,0,0,1.93-1h0a1.2,1.2,0,0,0-.51-1,29.41,29.41,0,1,1,34.34,0,1.19,1.19,0,0,0-.51,1h0a1.21,1.21,0,0,0,1.91,1A31.82,31.82,0,0,0,81,37Z" transform="translate(-17.36 -5.2)"
        />
        <path 
            d="M75.1,37A25.91,25.91,0,1,0,30.44,54.9a1.21,1.21,0,0,0,2.09-.83h0a1.2,1.2,0,0,0-.34-.84,23.49,23.49,0,1,1,34,0,1.2,1.2,0,0,0-.34.84h0a1.21,1.21,0,0,0,2.09.83A25.82,25.82,0,0,0,75.1,37Z" transform="translate(-17.36 -5.2)"
        />
        <path 
            d="M48.68,17.05A20,20,0,0,0,30.17,43.19a1.21,1.21,0,0,0,2.36-.39h0a1.19,1.19,0,0,0-.06-.37,17.57,17.57,0,1,1,33.44,0,1.19,1.19,0,0,0-.06.37h0a1.22,1.22,0,0,0,2.37.38A20,20,0,0,0,48.68,17.05Z" transform="translate(-17.36 -5.2)"
        />
    </svg>
);
PodcastSvg.defaultProps = defaultSvgProps;

export default PodcastSvg;
