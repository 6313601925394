import {
	Avatar,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	IconButton,
	Icon,
} from '@material-ui/core';
import React from 'react';
import { themePalette } from '../../utilities/branding';
import { Strings as S } from '../../assets/common/strings';

interface Props {
	input: any;
	changeFieldValue: any;
	formName: string;
	lineOfBusinessType: string;
	lineOfBusinessDescription: string;
}

export const LineOfBusinessField = (props: Props) => {
	function toggleLineOfBusiness(
		formName: string,
		lineOfBusinessType: string,
		lineOfBusinessSelected: boolean
	) {
		props.changeFieldValue(
			formName,
			`linesOfBusiness.${lineOfBusinessType}`,
			!lineOfBusinessSelected
		);
	}

	const {
		lineOfBusinessType,
		lineOfBusinessDescription,
		input,
		formName,
	} = props;

	const lineOfBusinessSelected = input.value;
	const backgroundColor: string = lineOfBusinessSelected
		? themePalette.selected_avatar
		: themePalette.lob_avatar;
	let lineOfBusinessShortString: string = lineOfBusinessType.slice(0, 1);
	switch (lineOfBusinessType) {
		case S.LineOfBusiness.LongTermCare:
			lineOfBusinessShortString = S.LineOfBusinessAbbreviations.LongTermCare
			break;
		case S.LineOfBusiness.Medicaid:
			lineOfBusinessShortString = S.LineOfBusinessAbbreviations.Medicaid
			break;
		case S.LineOfBusiness.MedicareSupp:
			lineOfBusinessShortString = S.LineOfBusinessAbbreviations.MedicareSupp
			break;
		case S.LineOfBusiness.Association:
			lineOfBusinessShortString = S.LineOfBusinessAbbreviations.Association
			break;
	}
	const leftAvatar = lineOfBusinessSelected ? (
		<Avatar style={{ ...styles.avatarStyle, backgroundColor }}>
			<Icon style={{ color: themePalette.negative_text }}>check</Icon>
		</Avatar>
	) : (
		<Avatar style={{ ...styles.avatarStyle, backgroundColor }}>
			<div style={{ color: themePalette.negative_text }}>{lineOfBusinessShortString}</div>
		</Avatar>
	);

	return (
		<List style={{ backgroundColor: themePalette.default_background }}>
			<ListItem
				button
				{...input}
				onClick={() => {
					toggleLineOfBusiness(
						formName,
						lineOfBusinessType,
						lineOfBusinessSelected
					);
				}}
			>
				<ListItemAvatar>{leftAvatar}</ListItemAvatar>
				<ListItemText
					primary={lineOfBusinessType}
					secondary={lineOfBusinessDescription || ''}
				/>
			</ListItem>
		</List>
	);
};

const styles: { [key: string]: React.CSSProperties } = {
	avatarStyle: {
		alignSelf: 'center',
	},
};
