import {actionCreatorFactory} from 'typescript-fsa';
import {
	CitySearchResult,
	CountySearchResult,
	ZipCodeSearchResult,
} from '../reducers/geographic_search_reducer';

const actionCreator = actionCreatorFactory('GeographicSearch');

//////// Get Geographic City AutoComplete Data
export const getCityAutoCompleteData = actionCreator.async<
	string,
	CitySearchResult[]
>('GET_CITY_AUTOCOMPLETE');

//////// Get Geographic County AutoComplete Data
export const getCountyAutoCompleteData = actionCreator.async<
	string,
	CountySearchResult[]
>('GET_COUNTY_AUTOCOMPLETE');

//////// Get Geographic Zip Code AutoComplete Data
export const getZipCodeAutoCompleteData = actionCreator.async<
	string,
	ZipCodeSearchResult[]
>('GET_ZIP_CODE_AUTOCOMPLETE');
