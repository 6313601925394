import React, { useState } from "react";
import {
    Dialog, DialogContent, DialogTitle,
    Button, DialogActions, Icon
} from '@material-ui/core';
import { WideButton } from '../utility/wide_button';
import { Contact } from '../../reducers/ContactReducer';
import { SubtitleProps, CustomListItem } from '../utility/custom_list_item';
import { composePhoneNumberFromContact, orderLoadedContacts } from '../../utilities/contact_util';
import { composeAddressFromContact } from '../../utilities/contact_util';
import { composeNameGenderDobAge } from '../../utilities/contact_util';
import { Loaded } from '../../utilities/utilities';
import CustomList from '../utility/custom_list';
import { connect } from '@optum-uhone-hmkts/rise';
import { AppState } from '../../reducers';
import { SnackbarProps, QueueSnackbar } from '../../actions/snackbar_actions';
import { getSnackbarInfoProps } from '../../utilities/snackbar_util';
import { navRoutes } from '../nav/Routes';
import { Strings } from '../../assets/common/strings';
import { openExternalLink } from '../../utilities';
import { getRealmUrl } from "../../utilities/brokerage_utils";

interface ComponentProps {
    contacts: Loaded<Contact>[];
}

interface DispatchProps {
    getSnackbar: (props: SnackbarProps) => void;
}

interface StateProps {
}

type Props = ComponentProps & DispatchProps & StateProps

export const ConnectureSSOCard: React.FC<Props> = (props) => {
    const { contacts, getSnackbar } = props;
    const [connectureDialogState, setConnectureDialogState] = useState(false);

    const redirectConnectureSSO = (currentContactId: string) => {
        const endpoint = navRoutes.openConnectureByContactId.path.replace(Strings.Navigation.ContactId, currentContactId);
        const route = `${getRealmUrl()}/#${endpoint}`;
        openExternalLink(route);
        getSnackbar(getSnackbarInfoProps("Redirecting...", 5000));
        setConnectureDialogState(false);
    };

    const renderContactItem = (contact: Contact) => {
        const subtitles: SubtitleProps[] = [];
        const phoneNumber = (contact) ? composePhoneNumberFromContact(contact) : "";
        const address = (contact) ? composeAddressFromContact(contact) : "";
        if (phoneNumber) subtitles.push({ value: phoneNumber });
        if (address) subtitles.push({ value: address });
        const preferedName = (contact?.preferredName || contact?.firstName) ? composeNameGenderDobAge(contact) : "You Have no contact";

        const currentContactId = contact?.id ?? ""

        const key = 'contact-' + contact?.id;
        return (
            <CustomListItem
                id={key}
                key={key}
                header={preferedName}
                subtitles={subtitles}
                leftColumnSize={0}
                primaryColumnSize={7}
                style={{ width: '100%' }}
                onClick={() => redirectConnectureSSO(currentContactId)}
                rightActionElement={<Icon style={{ marginRight: 0 }}>open_in_new</Icon>}
            />
        );
    }

    const renderDialog = () => {
        const orderedMembers: Contact[] = orderLoadedContacts(contacts ?? []);
        return (
            <Dialog
                open={connectureDialogState}
                disableBackdropClick
                fullWidth
            >
                <DialogTitle>
                    Quote Over 65
                </DialogTitle>
                <DialogContent>
                    <CustomList emptyMessage="You have no contacts">
                        {orderedMembers?.map(renderContactItem)}
                    </CustomList>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        onClick={() => setConnectureDialogState(false)}
                    >
                        Cancel
					</Button>
                </DialogActions>
            </Dialog>
        )
    };

    return (
        <>
            <WideButton
                primary={'Quote Over 65'}
                secondary={'Connecture DRX'}
                onClick={() => setConnectureDialogState(true)}
            />
            {renderDialog()}
        </>
    );
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps): StateProps => ({});

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
    getSnackbar: (props: SnackbarProps) =>
        dispatch(QueueSnackbar(props))
});

export const ConnectureSSOCardComponent = connect(
    mapStateToProps,
    mapDispatchToProps, true
)(ConnectureSSOCard);

