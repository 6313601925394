import React from 'react';
import { Box, Grid, List, Typography } from '@material-ui/core';

interface Props {}
export const SuggestedValues: React.FC<Props> = (props) => (
    <Grid container>
        <Grid item xs={12}>
            <Typography variant="caption">
                <Box fontStyle="italic">
                    Suggested
                </Box>
            </Typography>
            <List>
                {props.children}
            </List>
        </Grid>
    </Grid>
);
