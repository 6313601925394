import React from 'react';
import { Paper, Grid, Icon, Avatar, IconButton } from '@material-ui/core';
import { SearchFilterFormValues, SearchResult } from '../../../reducers/advanced_search_reducer';
import { themePalette } from '../../../utilities/branding';
import { EMPTY_GUID } from '../../../utilities/empty_entities';
import {Strings as S } from '../../../assets/common/strings';
import { orDefault } from '../../../assets/common/string_builders';
import { CustomListItem } from '../../../components/utility/custom_list_item';
import { displayLineOfBusinessAvatars } from '../../../components/utility/advanced_search_lob';

interface ComponentProps {
	appliedFilters: SearchFilterFormValues;
	searchResult: SearchResult;
	onClickResult: (id: string, type: string, index: number) => void;
	onSelectResult: (event: React.MouseEvent<HTMLElement>) => void;
	isSelectAllSet: boolean;
	index: number;
	highlight?: boolean;
	agentName: string;
}

type Props = ComponentProps;

export const AdvancedSearchResultListItem: React.FC<Props> = (props) => {

	const { appliedFilters, searchResult, onClickResult, onSelectResult, isSelectAllSet, index, highlight, agentName } = props;

	const onClickSearchResult = () => {
		if ((appliedFilters &&
				appliedFilters?.consumerType &&
				appliedFilters?.consumerType !== S.ASearch.BLeads) ||
			!appliedFilters?.consumerType
		) {
			onClickResult(
				searchResult.EmployerId != EMPTY_GUID
					? (searchResult.EmployerId || '')
					: (searchResult.HouseholdId || ''),
				searchResult.EmployerId != EMPTY_GUID
					? S.ASearchMode.Employer
					: S.ASearchMode.Household,
				index
			);
		}
	};

	const handleClickAvatar = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		if (!isSelectAllSet) {
			onSelectResult(event);
		}
	};

	const name = `${searchResult.FirstName} ${orDefault(searchResult.MiddleName).charAt(0)} ${searchResult.LastName}`;
	const location = searchResult.City
		? `${searchResult.City}, ${searchResult.State} ${searchResult.ZipCode}`
		: '';
	const backgroundColor: string = searchResult.IsSelected
		? themePalette.selected_item
		: themePalette.unselected_item;

	return (
		<Paper
			elevation={0}
			square
			style={{
				backgroundColor: highlight
					? themePalette.secondary_background
					: undefined,
			}}
		>
			<CustomListItem
				id={searchResult.ContactId}
				header={name}
				subtitles={[
					{ value: location },
					{ value: searchResult.HouseholdRole },
				]}
				rightSubtitles={[
					{ value: searchResult.ContactedStatus },
					{ value: searchResult.OwningAgent == agentName ? '' : searchResult.OwningAgent },
					{ value: (
						<Grid container justify={S.CSS.FlexEnd}>
							{displayLineOfBusinessAvatars(
								searchResult.Products,
								searchResult.ProductLinesOfBusiness
							)}
						</Grid>
					) }
				]}
				leftColumnSize={2}
				rightColumnSize={0}
				leftActionElement={
					<Avatar style={{ backgroundColor }}>
						<IconButton
							onClick={(event) => handleClickAvatar(event)}
							disabled={isSelectAllSet}
						>
							<Icon style={{ color: themePalette.negative_avatar }}>check</Icon>
						</IconButton>
					</Avatar>
				}
				onClick={onClickSearchResult}
				style={{ minHeight: 84 }}
			/>
		</Paper>
	);
};
