import {
	Switch,
} from '@material-ui/core';
import React from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import { GetUserById, UpdateDeviceSettings } from '../../actions/user_actions';
import {
	HeaderBarComponent,
} from '../../components/Layout/HeaderBar';
import { themePalette, themeLinks } from '../../utilities/branding';
import { AppState } from '../../reducers/index';
import { User, DeviceSettings } from '../../reducers/user_reducer';
import { jwt_auth } from '../../utilities/auth';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { SvgIcon } from '../../components/svgs/svg_icon';
import { MoreMenu, createHelpAction } from '../../components/nav/more_menu';
import { isBrokerage } from '../../utilities/brokerage_utils';
import { DesktopPadding } from '../../components/Layout/desktop_padding';
import { ToggleCard } from '../../components/higher_order_component/wrap_in_card';
import { SimpleListItem } from '../../components/utility/simple_list_item';

export type NotificationSetting =
	| 'general'
	| 'announcements'
	| 'battles'
	| 'clientConnect'
	| 'crm'
	| 'documents'
	| 'finances'
	| 'leads'
	| 'performance'
	| 'profile'
	| 'settings'
	| 'todoList'
	| 'tools'
	| 'training'
	| 'notificationsEnabled';


interface StateProps {
	user: User;
	device: DeviceSettings;
}
interface DispatchProps {
	updateDeviceSettings: (device: DeviceSettings) => void;
	getUser: (id: string) => void;
}

type Props = StateProps & DispatchProps;
class SettingsNotificationPage extends React.Component<Props> {

	componentDidMount() {
		const userId = this.props.user.impersonatingId
			|| this.props.user.id;

		this.props.getUser(userId);
	}

	getNotificationCategoryDisplay = (setting: string) => {
		if (setting == 'crm') {
			return setting.toUpperCase();
		} else if (setting == 'todoList') {
			return 'To-Do List';
		} else {
			const firstChar = setting.substr(0, 1);
			const rest = setting.substr(1);
			return `${firstChar.toUpperCase()}${rest}`;
		}
	};

	toggleNotification = (key: NotificationSetting, value: boolean) => {
		const { device, updateDeviceSettings } = this.props;
		if (device) {
			const updatedDevice: DeviceSettings = {
				...device,
			};
			if (key == 'notificationsEnabled') {
				Object.keys(updatedDevice).forEach(k => {
					if (k != 'deviceID' && k != 'fcmKey')
						updatedDevice[k] = value;
				});
			} else {
				updatedDevice[key] = value;
			}
			updateDeviceSettings(updatedDevice);
		}
	};

	render() {
		const { device } = this.props;
		return (
			<BasePageContainer
				topComponent={
					<HeaderBarComponent
						title="Settings"
						rightButtons={
							<MoreMenu children={[createHelpAction(themeLinks.helpLinkSettings)]} />
						}
					/>
				}
			>
				<PersistentNotifications />
				<DesktopPadding>
					<ToggleCard
						title={'Push Notification Settings'}
						noPadding
					>
						<SimpleListItem
							title={'Push Notifications'}
							subtitle={'Enable/Disable push notifications'}
							secondaryAction={
								<Switch
									color="primary"
									onChange={() =>
										this.toggleNotification('notificationsEnabled', !device.notificationsEnabled)
									}
									checked={device.notificationsEnabled}
								/>
							}
						/>
						<SimpleListItem
							subtitle={'Sections'}
						/>
						{
							device && Object.keys(device).map((key: string) => {
								const value: string | boolean | string[] = device[key];
								const disableLeadNotification = (key === 'leads' && isBrokerage)

								return (
									typeof value === 'boolean' && 
									key !== 'notificationsEnabled' && 
									!disableLeadNotification && 
									jwt_auth.findPermission(key) && (
										<SimpleListItem
											key={key}
											title={this.getNotificationCategoryDisplay(key)}
											icon={<SvgIcon type={key} size={40} />}
											secondaryAction={
												<Switch
													color="primary"
													checked={value}
													onChange={(_event) => {
														this.toggleNotification(key as NotificationSetting, !value);
													}}
												/>
											}
										/>
									)
								);
							})
						}
					</ToggleCard>
				</DesktopPadding>
			</BasePageContainer>
		);
	}
}

const mapStateToProps = (state: AppState): StateProps => ({
	user: state.user,
	device: state.user.currentDevice,
});
const mapDispatchToProps = (dispatch: any): Partial<Props> => ({
	updateDeviceSettings: (device: DeviceSettings) => dispatch(UpdateDeviceSettings.started(device)),
	getUser: (id: string) => dispatch(GetUserById.started({ id })),
});

export const SettingsNotificationContainer = connect(mapStateToProps, mapDispatchToProps, true)(SettingsNotificationPage);
