import _ from 'lodash';
import {
	Avatar,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
	Grid,
	Icon,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
} from '@material-ui/core';
import React from 'react';
import { MatchSelectionDialog } from '../../components/Policy/policy_match_selection_dialog';
import { themePalette } from '../../utilities/branding';
import { Address, Contact } from '../../reducers/ContactReducer';
import { Employer } from '../../reducers/employer_reducer';
import { Policy } from '../../reducers/policy_reducer';
import { Loaded } from '../../utilities/utilities';
import { MoreMenu } from '../nav/more_menu';
import { SearchResultProps, mapContactsToSearchResults } from '../utility/search_result';
import { wrapInCollapsibleCard } from '../higher_order_component/wrap_in_card';

interface Props {
	policy: Policy;
	loadedContacts: Loaded<Contact>[];
	loadedEmployers: Loaded<Employer>[];
	handleContactSearch: (searchString: string) => void;
	hasMoreContacts: boolean;
	resetLiveSearch: () => void;
	navigateToWithoutAddingToHistory: (route: string) => void;
	getHouseholdContacts: (householdId: string) => void;
	matchPolicy: (policy: Policy) => void;
	unmatchPolicy: (policyId: string) => void;
	handleEmployerSearch: (searchText: string) => void;
	getEmployer: (id: string) => void;
	isLoading: boolean;
}
interface State {
	isMatched: boolean;
	isEmployerPolicy: boolean;
	matchContactDialogIsOpen: boolean;
	matchEmployerDialogIsOpen: boolean;
	unlinkEmployerDialogOpen: boolean;
	searchString: string;
}
class PolicyMatch extends React.PureComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			isMatched: false,
			matchContactDialogIsOpen: false,
			matchEmployerDialogIsOpen: false,
			searchString: '',
			isEmployerPolicy: false,
			unlinkEmployerDialogOpen: false,
		};
	}

	componentWillMount() {
		this.updateStateOnPolicyChange(this.props.policy);
	}

	updateStateOnPolicyChange = (policy: Policy) => {
		if (policy) {
			//TODO: Before dropping check w/data team to see if this
			//is still the correct way to get Employer LOB Policies
			if (
				!(
					policy.productType &&
					policy.productType.toLowerCase().includes('small')
				) &&
				!(
					policy.insuredType &&
					policy.insuredType.toLowerCase().includes('small')
				)
			) {
				if (policy.primaryInsured !== null) {
					this.setState({
						isMatched: true,
						matchContactDialogIsOpen: false,
						isEmployerPolicy: false,
					});
				} else {
					this.setState({
						isMatched: false,
						matchContactDialogIsOpen: false,
						isEmployerPolicy: false,
					});
				}
			} else {
				if (policy.employerId) {
					this.setState({
						isMatched: true,
						isEmployerPolicy: true,
						matchEmployerDialogIsOpen: false,
						matchContactDialogIsOpen: false,
						unlinkEmployerDialogOpen: false,
					});
					this.props.getEmployer(policy.employerId);
				} else {
					this.setState({
						isMatched: false,
						isEmployerPolicy: true,
						matchEmployerDialogIsOpen: false,
						matchContactDialogIsOpen: false,
						unlinkEmployerDialogOpen: false,
					});
				}
			}
		}
	};

	getMatchedContactsForPolicy = () => {
		let matchedContacts: Contact[] = [];

		if (this.props.policy.primaryInsured) {
			matchedContacts.push(this.props.policy.primaryInsured);

			if (this.props.policy.secondaryInsured) {
				matchedContacts.push(this.props.policy.secondaryInsured);
			}

			if (
				this.props.policy.dependents &&
				this.props.policy.dependents.length > 0
			) {
				this.props.policy.dependents.forEach(dependent => {
					matchedContacts.push(dependent);
				});
			}
		}
		return matchedContacts;
	};

	getMatchedEmployersForPolicy = () => {
		let matchedEmployers: Employer[] = this.props.loadedEmployers
			.filter(loadedEmployer => {
				if (loadedEmployer.data.id === this.props.policy.employerId) {
					return true;
				}
			})
			.map(loadedEmployer => loadedEmployer.data);

		return matchedEmployers;
	};

	matchContacts = (contactsToMatch: Contact[]) => {
		if (contactsToMatch?.length > 0) {
			let updatedPolicy = this.props.policy;
			updatedPolicy.primaryInsured = contactsToMatch[0];
			updatedPolicy.dependents = [];
			if (contactsToMatch.length > 1) {
				for (let i = 1; i < contactsToMatch.length; i++) {
					updatedPolicy.dependents.push(contactsToMatch[i]);
				}
			}
			this.props.matchPolicy(updatedPolicy);
			this.updateStateOnPolicyChange(updatedPolicy);
		} else {
			this.props.unmatchPolicy(this.props.policy.id);
			this.setState({
				isMatched: false,
				matchContactDialogIsOpen: false,
				isEmployerPolicy: false,
			});
		}
	};

	matchEmployer = (employerToMatch?: Employer) => {
		if (employerToMatch && employerToMatch.id) {
			let updatedPolicy = this.props.policy;
			updatedPolicy.employerId = employerToMatch.id;
			this.props.matchPolicy(updatedPolicy);
			this.updateStateOnPolicyChange(updatedPolicy);
		} else {
			this.props.unmatchPolicy(this.props.policy.id);
			this.setState({
				isMatched: false,
				matchContactDialogIsOpen: false,
				unlinkEmployerDialogOpen: false,
			});
		}
	};

	mapContactsToSearchResults = (contacts: Contact[]) => mapContactsToSearchResults(contacts, {
		includeAgeInSecondaryText: false,
		showPrimaryText: !this.state.matchContactDialogIsOpen,
		useDefaultAddressIfNone: true,
	});

	renderMatchSelectionDialog() {
		const matchedContacts = this.getMatchedContactsForPolicy();
		const searchTokens = this.state.searchString.split(' ');

		const filteredContacts = this.props.loadedContacts
			.filter(loadedContact => {
				const contact = loadedContact.data;
				const emails = contact.emails.map(email => email.emailAddress);
				const phones = contact.phones.map(phone => phone.number);
				const searchArray = [
					contact.firstName,
					contact.preferredName,
					contact.lastName,
				].concat(emails, phones);

				const searchString = searchArray.join().toLowerCase();
				searchString.replace(/\s/g, '');
				const matchSearch = searchTokens.every(
					token => searchString.indexOf(token.toLowerCase()) > -1
				);
				return matchSearch;
			})
			.map(contact => contact.data)
			.sort((a, b) => {
				if (a.lastName < b.lastName) return -1;
				else if (a.lastName > b.lastName) return 1;
				else if (a.firstName < b.firstName) return -1;
				else if (a.firstName > b.firstName) return 1;
				else return 0;
			});

		let primaryContact: Contact | undefined;
		const filteredEmployers = this.props.loadedEmployers
			.filter(loadedEmployer => {
				const employer = loadedEmployer.data;

				const primaryContactIndex = _.findIndex(employer.contacts, contact => {
					return contact.employerPrimaryContact;
				});

				if (primaryContactIndex > -1) {
					primaryContact = employer.contacts[primaryContactIndex];
				} else {
					primaryContact =
						employer.contacts?.length > 0 ? employer.contacts[0] : undefined;
				}

				let emails = [] as string[];
				let addresses = [] as string[];
				let phones = [] as string[];

				if (primaryContact) {
					emails = primaryContact.emails.map(email => email.emailAddress);
					addresses = primaryContact.addresses.map(
						address =>
							address.city + ' ' + address.state + ' ' + address.zipCode
					);
					phones = primaryContact.phones.map(phone => phone.number);
				}

				const searchArray = [employer.companyName].concat(
					emails,
					addresses,
					phones
				);
				let searchString = searchArray.join().toLowerCase();
				searchString = searchString.replace(/\s/g, '');
				const matchSearch = searchTokens.every(
					token => searchString.indexOf(token.toLowerCase()) > -1
				);
				return matchSearch;
			})
			.map(employer => employer.data)
			.sort((a, b) => {
				if (a.companyName < b.companyName) return -1;
				if (a.companyName > b.companyName) return 1;
				return 0;
			});

		return (
			<MatchSelectionDialog
				filteredContacts={
					this.state.searchString?.length > 0 ? filteredContacts : []
				}
				loadedContacts={this.props.loadedContacts}
				filteredEmployers={
					this.state.searchString?.length > 0 ? filteredEmployers : []
				}
				visible={
					this.state.matchContactDialogIsOpen ||
					this.state.matchEmployerDialogIsOpen
				}
				loadMore={this.props.hasMoreContacts}
				isEmployerPolicy={this.state.isEmployerPolicy}
				closeDialog={() => {
					this.updateStateOnPolicyChange(this.props.policy);
				}}
				handleSearch={(searchString: string) => {
					if (this.state.isEmployerPolicy) {
						this.props.handleEmployerSearch(searchString);
					} else {
						this.props.handleContactSearch(searchString);
					}
					this.setState({
						searchString: searchString.toLowerCase(),
					});
				}}
				resetSearch={() => {
					this.props.resetLiveSearch();
					this.setState({
						searchString: '',
					});
				}}
				getHouseholdContacts={this.props.getHouseholdContacts}
				matchContacts={this.matchContacts}
				matchEmployer={this.matchEmployer}
				mapContactsToSearchResults={this.mapContactsToSearchResults}
				mapEmployersToSearchResults={this.mapEmployersToSearchResults}
				policyIsMatched={this.state.isMatched}
				matchedContacts={matchedContacts}
				navigateToWithoutAddingToHistory={this.props.navigateToWithoutAddingToHistory}
				policy={this.props.policy}
				isLoading={this.props.isLoading}
			/>
		);
	}

	mapEmployersToSearchResults = (
		filteredEmployers: Employer[]
	): SearchResultProps[] => {
		let primaryContact: Contact | undefined;
		return filteredEmployers.map(employer => {
			const avatar = (
				<Avatar
					style={{
						alignSelf: 'center',
						backgroundColor: themePalette.default_avatar,
					}}
				>
					<Icon>check</Icon>
				</Avatar>
			);

			const contactIndex = _.findIndex(employer.contacts, contact => {
				return contact.employerPrimaryContact;
			});

			if (contactIndex > -1) {
				primaryContact = employer.contacts[contactIndex];
			}

			let address: Address | undefined;
			if (primaryContact) {
				const addressIndex = _.findIndex(primaryContact.addresses, address => {
					return address.isPreferred;
				});

				if (addressIndex > -1) {
					address = primaryContact.addresses[addressIndex];
				} else if (primaryContact.addresses.length > 0) {
					address = primaryContact.addresses[0];
				}
			}

			let numEmployees: number = 0;
			if (employer.numberOfEmployees) {
				numEmployees = employer.numberOfEmployees;
			}

			let industry: string = 'no specified industry';
			if (employer.industry) {
				industry = employer.industry;
			}

			let city: string = '';
			let state: string = '';
			let zipCode: string = '';
			if (address) {
				if (address.city) {
					city = address.city;
				}
				if (address.state) {
					state = address.state;
				}
				if (address.zipCode) {
					zipCode = address.zipCode;
				}
			} else {
				city = 'No address found';
			}
			const secondString = `${city} ${state} ${zipCode}, \n ${
				numEmployees
			} employee(s),
            ${industry}`;

			return {
				avatar: avatar,
				resultId: employer.id,
				primaryText: employer.companyName,
				secondaryText: secondString,
			};
		});
	};

	renderUnlinkEmployerDialog = () => {
		return (
			<Dialog
				key={'unlink_employer_confirmation_dialog'}
				open={this.state.unlinkEmployerDialogOpen}
				onClose={() => this.setState({ unlinkEmployerDialogOpen: false })}
			>
				<DialogContent>
					{'Are you sure you want to unlink this policy?'}
				</DialogContent>
				<DialogActions>
					<Button
						color="secondary"
						onClick={() => this.setState({ unlinkEmployerDialogOpen: false })}
					>
						Cancel
					</Button>
					<Button
						color="primary"
						variant="contained"
						onClick={() => this.matchEmployer()}
						style={{ backgroundColor: themePalette.accept_button }}
					>
						Unlink
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	render() {
		const actions = [
			{
				onClick: () => this.setState({ unlinkEmployerDialogOpen: true }),
				disabled: false,
				children: 'Unlink',
			},
		];
		return (
			<Grid container>
				{!this.state.isMatched ? (
					<Grid container item direction="column" xs={12}>
						{this.renderMatchSelectionDialog()}
						<div
							key={'unmatched-policy'}
							style={{
								fontSize: '18px',
								textAlign: 'center',
								paddingBottom: 5,
							}}
						>
							Unmatched Policy
						</div>
						<Divider />
						<div
							key={'no-linked'}
							style={{
								fontSize: '12px',
								textAlign: 'center',
								paddingTop: 5,
								paddingBottom: 10,
							}}
						>
							{this.state.isEmployerPolicy
								? 'Currently, no Employer is linked to this policy.'
								: 'Currently, no person is linked to this policy'}
						</div>
						<Button
							variant="contained"
							color="primary"
							fullWidth={true}
							onClick={() => {
								if (this.state.isEmployerPolicy) {
									this.setState({
										matchEmployerDialogIsOpen: true,
									});
								} else {
									this.setState({
										matchContactDialogIsOpen: true,
									});
								}
							}}
						>
							Link
						</Button>
					</Grid>
				) : this.state.isEmployerPolicy ? (
					<Grid container item xs={12}>
						{this.renderUnlinkEmployerDialog()}
						<List key={'matched-employer'} style={{ marginBottom: '16px', width: '100%' }}>
							{this.mapEmployersToSearchResults(
								this.getMatchedEmployersForPolicy()
							).map((employerResult, index) => {
								return (
									<ListItem divider key={`matched-employer-${index}`}>
										<ListItemText
											primary={employerResult.primaryText}
											secondary={employerResult.secondaryText}
										/>
										<MoreMenu 
											children={actions} 
											color={themePalette.default_avatar}
										/>
									</ListItem>
								);
							})}
						</List>
					</Grid>
				) : (
					<Grid container item xs={12}>
						{this.renderMatchSelectionDialog()}
						<List key={'matched-contacts'} style={{ marginBottom: '16px', width: '100%' }}>
							{this.mapContactsToSearchResults(
								this.getMatchedContactsForPolicy()
							).map((contactResult, index) => {
								return (
									<ListItem divider key={`matched-contact-${index}`}>
										<ListItemAvatar>{contactResult.avatar}</ListItemAvatar>
										<ListItemText
											primary={contactResult.primaryText}
											secondary={contactResult.secondaryText}
										/>
									</ListItem>
								);
							})}
						</List>
						<Button
							variant="contained"
							color="primary"
							fullWidth={true}
							onClick={() => {
								this.setState({
									matchContactDialogIsOpen: true,
								});
							}}
						>
							Manage
						</Button>
					</Grid>
				)}
			</Grid>
		);
	}
}

export const PolicyMatchCard = wrapInCollapsibleCard(PolicyMatch);
