import React from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import { AppState } from '../../reducers';
import { getLeadAccountSummary } from '../../selectors/lead_selectors';
import { AccountSummary } from '../../reducers/LeadReducer';
import { Box, InputLabel, Typography } from '@material-ui/core';
import { useStylesLeads } from '../../utilities/lead_util';
import { Strings } from '../../assets/common/strings';
import classNames from 'classnames';

interface StateProps {
	accountSummary: AccountSummary;
}

interface ComponentProps {
    style?: React.CSSProperties;
}

type Props = StateProps & ComponentProps;

const _LeadAccountSummary: React.FC<Props> = (props) => {

	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		currencySign: 'accounting'
	});

	const { accountSummary, style } = props;
	const classes = useStylesLeads();
	const typographyStyle = classNames(classes.leadAccountSummaryFieldsValueColor, classes.flexRowCenter);
	const inputLabelStyle = classNames(classes.leadAccountSummaryLabel);

	return (
			<Box className={classes.leadAccountSummaryBox} style={style}>

				<div className={classes.flexRowCenter}>
					<InputLabel className={inputLabelStyle} children={Strings.LeadAccountSummary.CurrentBalance + ': '} />
					<Typography className={typographyStyle}>
						{formatter.format(accountSummary?.AgentBalance)}
					</Typography>
				</div>

				<div className={classes.flexRowCenter}>
					<InputLabel
						className={inputLabelStyle}
						children={Strings.LeadAccountSummary.ActiveOrders + ': '}
					/>
					<Typography className={typographyStyle}>{accountSummary?.TotalActiveOrder}</Typography>

				</div>

				<div className={classes.flexRowCenter}>
					<InputLabel className={inputLabelStyle} children={Strings.LeadAccountSummary.InactiveOrders + ': '} />
					<Typography className={typographyStyle}>
						{accountSummary?.TotalInactiveOrders}
					</Typography>
				</div>

				<div className={classes.flexRowCenter}>
					<InputLabel className={inputLabelStyle} children={Strings.LeadAccountSummary.DisputesPending + ': '} />
					<Typography className={typographyStyle}>
						{accountSummary?.TotalPendingDisputes}
					</Typography>
				</div>

			</Box>
	);
};

const mapStateToProps = (state: AppState): StateProps => ({
	accountSummary: getLeadAccountSummary(state),
});

export const LeadAccountSummary = connect(mapStateToProps, {}, true)(_LeadAccountSummary) as React.ComponentClass<ComponentProps>;
