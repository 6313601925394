import { AppState } from '../reducers/index';
import { createSelector } from 'reselect';
import { Loaded } from '../utilities/utilities';
import { Contact, HouseholdRole } from '../reducers/ContactReducer';
import { getPage, getId, PageType } from './base_selectors';
import { EMPTY_CONTACT, loaded } from '../utilities/empty_entities';
import { NavigationProps } from '../components/nav/Routes';
import { OwnProps } from '../components/activity/disposition_dialog';
import { get } from '../utilities/object_util';

/**
 * Contact selectors
 */
export const getContacts = (state: AppState) => state.contact?.contacts || [];
export const getLoadedContactsSelector = createSelector(
	[getContacts],
	contacts => contacts.map(contact => contact.data)
);

const getContactId = (state: AppState, props: NavigationProps) => props.match.params.contactID;
export const makeGetMatchedContact = () => createSelector(
	[getContacts, getContactId],
	(contacts, contactId) => {
		const matchedContact = contacts.find(contact => contact.data.id.toLowerCase() === contactId.toLowerCase());
		return matchedContact || loaded(EMPTY_CONTACT);
	}
);

const getContactIdFromFirstActivity = (state: AppState, props: OwnProps) => props.contactId || get(() => props.activities[0].contact.id);
export const makeGetContactFromFirstActivity = () => createSelector(
	[getContacts, getContactIdFromFirstActivity],
	(contacts, contactId) => (
		contactId && contacts.find(contact => contact.data.id === contactId)
	)
)

export const getPageContacts = createSelector(
	[getPage, getId, getContacts],
	(page: PageType, id: string, contacts: Loaded<Contact>[]) => {
		switch (page) {
			case 'HOUSEHOLD_PAGE': {
				return contacts.filter(contact => contact.householdId == id);
			}
			case 'EMPLOYER_PAGE': {
				return contacts.filter(contact => contact.employerId == id);
			}
			default:
				return contacts;
		}
	}
);

export const getPrimaryContact = createSelector(
	[getPage, getPageContacts],
	(page: PageType, contacts: Loaded<Contact>[]) => {
		switch (page) {
			case 'HOUSEHOLD_PAGE': {
				const contact = contacts.find((contact: Loaded<Contact>) => {
					return contact.data.householdRole == HouseholdRole.Primary;
				});
				return contact ? contact.data : { ...EMPTY_CONTACT };
			}
			case 'EMPLOYER_PAGE': {
				if(contacts.length == 0) {
					return { ...EMPTY_CONTACT };
				}

				const contact = contacts.find((contact: Loaded<Contact>) => {
					return contact.data.employerPrimaryContact;
				});
				return contact ? contact.data : contacts[0].data;
			}
			default:
				return { ...EMPTY_CONTACT };
		}
	}
);
