import React from 'react';
import { Switch } from '@material-ui/core';
import { TransferStatus } from '../../reducers/LeadReducer';
import { ToggleCard } from '../higher_order_component/wrap_in_card';
import { SimpleListItem } from '../utility/simple_list_item';
import { Strings } from '../../assets/common/strings';

interface Props {
	isReceivingLiveTransferLeads: TransferStatus;
	isReceivingLeadConnectLeads: TransferStatus;
	toggleLiveTransferLeads: (status: TransferStatus) => void;
	toggleLeadConnectLeads: (status: TransferStatus) => void;
	canUpdate: boolean;
}
export const LeadTransferStatus: React.FC<Props> = (props) => {

	const { canUpdate, isReceivingLeadConnectLeads, isReceivingLiveTransferLeads, toggleLeadConnectLeads, toggleLiveTransferLeads } = props;

	return (
		<ToggleCard
			title={'Incoming Leads'}
			noPadding
		>
			<SimpleListItem
				title={Strings.LeadTransferStatus.AllLeads}
				subtitle={
					isReceivingLeadConnectLeads == TransferStatus.Unknown
						? Strings.LeadTransferStatus.UnableToReceive
						: TransferStatus[isReceivingLeadConnectLeads]
				}
				secondaryAction={
					<Switch
						color="primary"
						onChange={() =>
							toggleLeadConnectLeads(
								isReceivingLeadConnectLeads == TransferStatus.Active
									? TransferStatus.Paused
									: TransferStatus.Active
							)
						}
						checked={isReceivingLeadConnectLeads == TransferStatus.Active}
						disabled={!canUpdate || isReceivingLeadConnectLeads == TransferStatus.Unknown}
					/>
				}
			/>
			<SimpleListItem
				title={Strings.LeadTransferStatus.LiveTransfer}
				subtitle={TransferStatus[isReceivingLiveTransferLeads]}
				secondaryAction={
					<Switch
						color="primary"
						onChange={event =>
							toggleLiveTransferLeads(
								event.target.checked
									? TransferStatus.Active
									: TransferStatus.Paused
							)
						}
						checked={isReceivingLiveTransferLeads == TransferStatus.Active}
						disabled={!canUpdate || isReceivingLeadConnectLeads != TransferStatus.Active}
					/>
				}
			/>
		</ToggleCard>
	);
};
