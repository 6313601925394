import React from 'react';
import _ from 'lodash';
import { Card, Typography, CardContent, List, Divider } from '@material-ui/core';
import { Contact } from '../../reducers/ContactReducer';
import { Loaded } from '../../utilities/utilities';
import { PrimaryInsuredListItem } from './PrimaryInsuredListItem';
import { Field } from 'redux-form';
import { createRequiredLabel } from '../utility/required_label';
import { Strings as S } from '../../assets/common/strings';

type Props = {
	formName: string;
	contacts: Loaded<Contact>[];
	changeFieldValue: (formName: string, formPath: string, value: any) => void;
	householdOrEmployer: 'Household' | 'Employer';
}


export const PrimaryInsuredCard: React.FC<Props> = (props) => {



	const sortedContacts: Loaded<Contact>[] = props.householdOrEmployer === 'Employer' ?
		props.contacts.map(contact => ({ ...contact })).sort((a, b) => {
			if (a.data.employerPrimaryContact) return -1;
			else if (b.data.employerPrimaryContact) return 1;
			else {
				return a.data.lastName < b.data.lastName ? -1 : 1;
			}
		}) :
		props.contacts;

	return (
		<div>
			<Typography variant="h5" style={{ margin: '20px 0 5px 20px' }}>
				{S.ProductInsuredEditOptions.Insureds}
			</Typography>
			<Typography variant="body1" style={{ padding: '0px 16px' }}>
				{createRequiredLabel(S.ProductInsuredEditOptions.SelectTheInsureesForTheApplication)}
			</Typography>
			<Card style={styles.cardStyle}>
				<CardContent>
					<List>
						{sortedContacts.map((contact: Loaded<Contact>, index: number) => {
							return (
								<div key={index}>
									<Field
										name={`insureds.${contact.data.id}`}
										changeFieldValue={props.changeFieldValue}
										formName={props.formName}
										component={PrimaryInsuredListItem}
										contact={contact}
										householdOrEmployer={props.householdOrEmployer}
									/>
									{index !== props.contacts.length - 1 ? (
										<Divider />
									) : (
											<div />
										)}
								</div>
							);
						})}
					</List>
				</CardContent>
			</Card>
		</div>
	);
}

const styles: { [key: string]: React.CSSProperties } = {
	cardStyle: {
		margin: 10,
		marginTop: 0,
		cursor: 'pointer',
	},
	editListItem: {
		marginLeft: 5,
		width: '90%',
	},
};
