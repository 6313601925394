import { Application } from '../reducers/application_reducer';
import { Policy } from '../reducers/policy_reducer';
import { isType, Action } from 'typescript-fsa';
import { call, put, all } from 'redux-saga/effects';
import {
	DeleteApplication,
	GetApplication,
	GetAllApplications,
	CreateApplication,
	GetAllUnlinkedApplications,
	LinkApplication,
	UnlinkApplication,
	UpdateApplication,
	IUnlinkedGuide,
} from '../actions/application_actions';
import http, { HttpOptions } from '../utilities/http';
import { QueueSnackbar, SnackbarProps } from '../actions/snackbar_actions';
import { themePalette } from '../utilities/branding';
import { navigateToWithoutAddingToHistory, navigateBack } from '../actions/navigation_actions';
import { LinkPolicy, UnlinkPolicy, LinkingPolicyPayload } from '../actions/policy_actions';
import { Strings as S } from '../assets/common/strings';
import { takeLatestForActionType } from '../utilities/saga_util';
import { getSnackbarSuccessProps, getSnackbarErrorProps, getSnackbarInfoProps } from '../utilities/snackbar_util';

//////// Get Application ///////////
function* getApplicationSaga(action: Action<string>) {
	try {
		const response = yield call(getApplicationClient, action.payload);

		if (response.ok) {
			const application = yield response.json();
			yield put(
				GetApplication.done({ params: action.payload, result: application })
			);
		} else {
			yield put(
				GetApplication.failed({
					params: action.payload,
					error: response.status,
				})
			);
		}
	} catch (error) {
		yield put(GetApplication.failed({ params: action.payload, error }));
	}
}
function getApplicationClient(applicationID: string): Promise<any> {
	return http('application/' + applicationID);
}

//////// Get All Applications ///////////
function* getAllApplicationsSaga(action: Action<undefined>) {
	try {
		const response = yield call(getAllApplicationsCall);
		if (response.ok) {
			const data = yield response.json();
			yield put(
				GetAllApplications.done({ params: action.payload, result: data })
			);
		} else {
			yield put(
				GetAllApplications.failed({
					params: action.payload,
					error: response.status,
				})
			);
		}
	} catch (error) {
		yield put(GetAllApplications.failed({ params: action.payload, error }));
	}
}
function getAllApplicationsCall(): Promise<any> {
	return http('application?scope=Me', { method: S.Http.Get });
}

//////// Get All Unlinked Applications ///////////
function* getAllUnlinkedApplicationsSaga(action: Action<IUnlinkedGuide>) {
	try {
		const response = yield call(
			getAllUnlinkedApplicationsCall,
			action.payload
		);
		if (response.ok) {
			const data = yield response.json();
			yield put(
				GetAllUnlinkedApplications.done({
					params: action.payload,
					result: data,
				})
			);
		} else {
			yield put(
				GetAllUnlinkedApplications.failed({
					params: action.payload,
					error: response.status,
				})
			);
		}
	} catch (error) {
		yield put(
			GetAllUnlinkedApplications.failed({ params: action.payload, error })
		);
	}
}
function getAllUnlinkedApplicationsCall(
	unlinkedGuide: IUnlinkedGuide
): Promise<any> {
	return http(
		'application/unlinked/' +
		unlinkedGuide.id +
		'/' +
		unlinkedGuide.guide +
		'?scope=Me',
		{ method: S.Http.Get },
		{ index: 0, size: 1000 }
	);
}

//////////  Create Application  //////////
function* updateApplicationSaga(action: Action<Application>) {
	const snackBarProps: SnackbarProps = getSnackbarInfoProps('Unable to save Application', 6000);

	try {
		const response = yield call(updateApplicationClient, action.payload);

		if (response.ok) {
			const data = yield response.json();
			yield put(
				UpdateApplication.done({ params: action.payload, result: data })
			);
		} else {
			yield put(
				UpdateApplication.failed({
					params: action.payload,
					error: response.status,
				})
			);
			yield put(QueueSnackbar(snackBarProps));
		}
	} catch (error) {
		yield put(UpdateApplication.failed({ params: action.payload, error }));
		yield put(QueueSnackbar(snackBarProps));
	}
}
function updateApplicationClient(application: Application): Promise<any> {
	const options: HttpOptions = {
		method: S.Http.Put,
		body: JSON.stringify(application),
	};

	return http('application', options);
}

//////////  Create Application  //////////
function* createApplicationSaga(action: Action<Application>) {
	const snackBarProps: SnackbarProps = getSnackbarInfoProps('Unable to save Application', 6000);
	try {
		const response = yield call(createApplicationClient, action.payload);

		if (response.ok) {
			const data = yield response.json();
			yield put(
				CreateApplication.done({ params: action.payload, result: data })
			);
		} else {
			yield put(
				CreateApplication.failed({
					params: action.payload,
					error: response.status,
				})
			);
			yield put(QueueSnackbar(snackBarProps));
		}
	} catch (error) {
		yield put(CreateApplication.failed({ params: action.payload, error }));
		yield put(QueueSnackbar(snackBarProps));
	}
}
function createApplicationClient(application: Application): Promise<any> {
	const options: HttpOptions = {
		method: S.Http.Post,
		body: JSON.stringify(application),
	};

	return http('application', options);
}

//////// Delete Application ///////////
function* deleteApplicationSaga(action: Action<Application>) {
	const snackBarProps: SnackbarProps = getSnackbarSuccessProps('Application Deleted');

	try {
		const response = yield call(deleteApplicationClient, action.payload);
		if (response.ok) {
			const applicationId = yield response.json();
			yield put(
				DeleteApplication.done({
					params: action.payload,
					result: applicationId,
				})
			);
			yield put(QueueSnackbar(snackBarProps));
		} else {
			yield put(
				DeleteApplication.failed({
					params: action.payload,
					error: response.status,
				})
			);
		}
	} catch (error) {
		yield put(DeleteApplication.failed({ params: action.payload, error }));
	}
}
function deleteApplicationClient(application: Application): Promise<any> {
	return http('application/', {
		method: S.Http.Delete,
		body: JSON.stringify(application),
	});
}

//////// Link Application ///////
function* linkApplicationSaga(action: Action<LinkingPolicyPayload>) {
	const snackBarSuccessProps: SnackbarProps = getSnackbarSuccessProps('Application Linked');
	const snackBarFailureProps: SnackbarProps = getSnackbarErrorProps('Application Link Failed');

	try {
		const response = yield call(linkApplicationClient, action.payload.policy);
		if (response.ok) {
			const policy = yield response.json();
			yield put(
				LinkApplication.done({ params: action.payload, result: policy })
			);
			yield put(LinkPolicy.done({ params: action.payload, result: policy }));
			yield put(
				navigateToWithoutAddingToHistory(action.payload.route)
			);
			yield put(QueueSnackbar(snackBarSuccessProps));
		} else {
			yield put(
				LinkApplication.failed({
					params: action.payload,
					error: response.status,
				})
			);
			yield put(QueueSnackbar(snackBarFailureProps));
		}
	} catch (error) {
		yield put(LinkApplication.failed({ params: action.payload, error }));
		yield put(QueueSnackbar(snackBarFailureProps));
	}
}
function linkApplicationClient(policy: Policy): Promise<any> {
	return http('policy/link', {
		method: S.Http.Put,
		body: JSON.stringify(policy),
	});
}

//////// Unlink Application ///////
function* unlinkApplicationSaga(action: Action<LinkingPolicyPayload>) {
	const snackBarSuccessProps: SnackbarProps = getSnackbarSuccessProps('Application Unlinked');
	const snackBarFailureProps: SnackbarProps = getSnackbarErrorProps('Application Unlink Failed');

	if (isType(action, UnlinkApplication.started)) {
		let id = '';
		let guide = 'Employer';

		if (action.payload.policy.employerId) {
			id = action.payload.policy.employerId;
		} else if (
			action.payload.policy.primaryInsured &&
			action.payload.policy.primaryInsured.householdId
		) {
			id = action.payload.policy.primaryInsured.householdId;
			guide = 'Household';
		}

		try {
			const response = yield call(
				unlinkApplicationClient,
				action.payload.policy
			);
			if (response.ok) {
				const policy = yield response.json();
				yield put(
					UnlinkApplication.done({ params: action.payload, result: policy })
				);
				yield put(
					UnlinkPolicy.done({ params: action.payload, result: policy })
				);
				yield put(GetAllUnlinkedApplications.started({ id, guide }));

				yield put(navigateBack());

				yield put(QueueSnackbar(snackBarSuccessProps));
			} else {
				yield put(
					UnlinkApplication.failed({
						params: action.payload,
						error: response.status,
					})
				);
				yield put(QueueSnackbar(snackBarFailureProps));
			}
		} catch (error) {
			yield put(UnlinkApplication.failed({ params: action.payload, error }));
			yield put(QueueSnackbar(snackBarFailureProps));
		}
	}
}
function unlinkApplicationClient(policy: Policy): Promise<any> {
	return http('policy/unlink', {
		method: S.Http.Put,
		body: JSON.stringify(policy),
	});
}

export function* applicationSagas() {
	yield all([
		takeLatestForActionType(
			GetApplication.started,
			getApplicationSaga
		),
		takeLatestForActionType(
			GetAllApplications.started,
			getAllApplicationsSaga
		),
		takeLatestForActionType(
			GetAllUnlinkedApplications.started,
			getAllUnlinkedApplicationsSaga
		),
		takeLatestForActionType(
			UpdateApplication.started,
			updateApplicationSaga
		),
		takeLatestForActionType(
			CreateApplication.started,
			createApplicationSaga
		),
		takeLatestForActionType(
			DeleteApplication.started,
			deleteApplicationSaga
		),
		takeLatestForActionType(
			LinkApplication.started,
			linkApplicationSaga
		),
		takeLatestForActionType(
			UnlinkApplication.started,
			unlinkApplicationSaga
		),
	]);
}