import { Avatar } from '@material-ui/core';
import React from 'react';
import { themePalette } from '../../utilities/branding';
import { enforceStylesType } from '../../utilities/styles_util';

interface Props {
	size: number;
	lineOfBusinessShortString: string;
	displayInline: boolean;
	bubbleColor?: string;
	doubleCircle?: boolean;
}
export const LineOfBusinessAvatar: React.FC<Props> = (props) => {
	const { size, lineOfBusinessShortString, displayInline, bubbleColor, doubleCircle } = props;

	return (
		<Avatar
			style={{
				...(doubleCircle ? styles.bothAvatars : styles.avatarStyle),
				backgroundColor: doubleCircle 
					? themePalette.customer_avatar 
					: bubbleColor || themePalette.default_avatar,
				height: size,
				width: size,
				fontSize: size / 2,
				display: displayInline ? 'inline-flex' : 'flex',
			}}
		>
			{lineOfBusinessShortString}
		</Avatar>
	);
};

const styles = enforceStylesType({
	avatarStyle: {
		marginTop: 5,
		marginBottom: 5,
		marginLeft: 2,
		marginRight: 2,
	},
	bothAvatars: {
		marginTop: 5,
		marginBottom: 5,
		marginLeft: 2,
		marginRight: 2,
		borderRadius: '100%',
		borderStyle: 'solid',
		borderWidth: 2,
		borderColor: 'grey',
		padding: 1,
		backgroundClip: 'content-box',
	},
});
