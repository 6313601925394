import { isType } from 'typescript-fsa';
import { Action as ReduxAction } from 'redux';
import {
	getCityAutoCompleteData,
	getCountyAutoCompleteData,
	getZipCodeAutoCompleteData,
} from '../actions/geographic_search_actions';
import { ClearCaches } from '../actions/authentication_actions';
import { Logout } from '../actions/authentication_actions';

export interface BaseSearchResult {
	'@search.score': number;
	Key: string;
}

export interface CitySearchResult extends BaseSearchResult {
	CityName: string;
	StateName: string;
}

export interface CountySearchResult extends BaseSearchResult {
	CountyName: string;
	StateName: string;
}

export interface ZipCodeSearchResult extends BaseSearchResult {
	ZIPCode: string;
	CityName: string;
	CountyName: string;
	StateName: string;
}

export interface GeographicSearchState {
	cityAutoCompleteOptions: CitySearchResult[];
	countyAutoCompleteOptions: CountySearchResult[];
	zipCodeAutoCompleteOptions: ZipCodeSearchResult[];
}

const initialState: GeographicSearchState = {
	cityAutoCompleteOptions: [],
	countyAutoCompleteOptions: [],
	zipCodeAutoCompleteOptions: [],
};

export function geographicSearchReducer(
	state: GeographicSearchState = initialState,
	action: ReduxAction
): GeographicSearchState {
	if (isType(action, getCityAutoCompleteData.done)) {
		let currentStoredCities: CitySearchResult[] = state.cityAutoCompleteOptions.slice();
		action.payload.result.forEach((incomingCity: CitySearchResult) => {
			const reduxIndex: number = currentStoredCities.findIndex(
				(currentCity: CitySearchResult) => {
					return currentCity.Key === incomingCity.Key;
				}
			);
			if (reduxIndex === -1) {
				currentStoredCities.push(incomingCity);
			}
		});

		return {
			...state,
			cityAutoCompleteOptions: currentStoredCities,
		};
	} else if (isType(action, getCountyAutoCompleteData.done)) {
		let currentStoredCounties: CountySearchResult[] = state.countyAutoCompleteOptions.slice();
		action.payload.result.forEach((incomingCounty: CountySearchResult) => {
			const reduxIndex: number = currentStoredCounties.findIndex(
				(currentCounty: CountySearchResult) => {
					return currentCounty.Key === incomingCounty.Key;
				}
			);
			if (reduxIndex === -1) {
				currentStoredCounties.push(incomingCounty);
			}
		});

		return {
			...state,
			countyAutoCompleteOptions: currentStoredCounties,
		};
	} else if (isType(action, getZipCodeAutoCompleteData.done)) {
		let currentStoredZipCodes: ZipCodeSearchResult[] = state.zipCodeAutoCompleteOptions.slice();
		action.payload.result.forEach((incomingZipCode: ZipCodeSearchResult) => {
			const reduxIndex: number = currentStoredZipCodes.findIndex(
				(currentZipCode: ZipCodeSearchResult) => {
					return currentZipCode.Key === incomingZipCode.Key;
				}
			);
			if (reduxIndex === -1) {
				currentStoredZipCodes.push(incomingZipCode);
			}
		});

		return {
			...state,
			zipCodeAutoCompleteOptions: currentStoredZipCodes,
		};
	} else if (isType(action, Logout.started)) {
		return {
			...initialState,
		};
	} else if (isType(action, ClearCaches)) {
		return {
			...initialState,
		};
	} else {
		return state;
	}
}
