import { isMobileDevice, isAndroid } from '../is_mobile';
import { SQLCipher, getAppState } from '../../';
import { store } from '../../';
import { AppState } from '../../reducers/index';
import { touchIDEnabled } from '../../actions/authentication_actions';
import pin_auth from './pin_auth';
import { submitLog } from '../logging_util';
import { Strings } from '../../assets/common/strings';

export default class fingerprint_auth {
	// Initialization
	static initializeFingerprint() {
		return new Promise((resolve, reject) => {
			this._loadFingerprintSettings()
				.then(() => {
					resolve();
				})
				.catch(e => {
					submitLog(Strings.Log.Warn, `Failed to load fingerprint settings`, {
						error: e,
					});
					resolve();
				});
		});
	}

	// Fingerprint Authorization
	static authorizeFingerprint(): Promise<boolean> {
		return new Promise((resolve, reject) => {
			if (this.fingerprint_loaded) {
				this._doAuthorizeFingerprint().then(successful => {
					resolve(successful);
				});
			} else {
				this._loadFingerprintSettings().then(() => {
					this._doAuthorizeFingerprint().then(successful => {
						resolve(successful);
					});
				});
			}
		});
	}

	private static _doAuthorizeFingerprint(): Promise<boolean> {
		return new Promise((resolve, reject) => {
			if (this.fingerprint_enabled) {
				this.forceFingerprint().then((successful: boolean) => {
					resolve(successful);
				});
			} else {
				resolve(false);
			}
		});
	}

	static forceFingerprint(): Promise<boolean> {
		return new Promise((resolve, reject) => {
			if (isMobileDevice) {
				if (isAndroid) {
					this._authorize_fingerprint_android().then(successful => {
						resolve(successful);
					});
				} else {
					this._authorize_fingerprint_iOS().then(successful => {
						resolve(successful);
					});
				}
			} else {
				resolve(false);
			}
		});
	}

	private static _authorize_fingerprint_android(): Promise<boolean> {
		return new Promise((resolve, reject) => {
			const FingerprintAuth = (window as any).FingerprintAuth;
			FingerprintAuth.isAvailable(
				(result: any) => {
					if (
						result.isAvailable == true &&
						result.hasEnrolledFingerprints == true
					) {
						var encryptConfig = {
							clientId: 'aaapmobile',
							username: 'currentUser',
							password: 'currentUserPassword',
							maxAttempts: 5,
							disableBackup: true,
							locale: 'en_US',
							dialogTitle: 'Fingerprint Authentication',
							dialogMessage: 'Please place your finger on scanner',
							dialogHint:
								'This will authenticate against the fingerprints stored on this device',
						};

						FingerprintAuth.encrypt(
							encryptConfig,
							(_fingerResult: any) => {
								if (_fingerResult.withFingerprint) {
									resolve(true);
								} else {
									resolve(false);
								}
							},
							(err: any) => {
								resolve(false);
							}
						);
					} else {
						resolve(false);
					}
				},
				(message: string) => {
					resolve(false);
				}
			);
		});
	}

	private static _authorize_fingerprint_iOS(): Promise<boolean> {
		return new Promise((resolve, reject) => {
			const TouchID = (window as any).plugins.touchid;
			TouchID.isAvailable(
				() => {
					TouchID.verifyFingerprintWithCustomPasswordFallbackAndEnterPasswordLabel(
						'Scan fingerprint please',
						'Enter PIN',
						(msg: any) => {
							resolve(true);
						},
						(msg: any) => {
							resolve(false);
						}
					);
				},
				(msg: any) => {
					resolve(false);
				}
			);
		});
	}

	// Settings Management
	private static fingerprint_loaded = false;
	private static fingerprint_enabled = false;

	static setFingerprintEnabled(enabled: boolean, userID?: string) {
		this.fingerprint_loaded = true;
		this.fingerprint_enabled = enabled;
		store.dispatch(touchIDEnabled(enabled));
		this._saveFingerprintSettings(userID);
	}

	private static _saveFingerprintSettings(userId?: string) {
		if (SQLCipher) {
			const state = getAppState();
			const userID: string = (userId || state.user.agentID || '').toUpperCase();
			SQLCipher.sqlBatch(
				[
					'DELETE FROM fingerprint_settings WHERE userID="' + userID + '"',
					[
						'INSERT INTO fingerprint_settings VALUES (?,?)',
						[this.fingerprint_enabled, userID],
					],
				],
				() => {
					submitLog(Strings.Log.Info, `Fingerprint settings stored successfully into db`);
				},
				(error: Error) => {
					submitLog(Strings.Log.Error, `Failed to save fingerprint settings in SQL Cipher`,
						{ error }
					);
				}
			);
		}
	}

	private static _loadFingerprintSettings(): Promise<any> {
		return new Promise((resolve, reject) => {
			if (SQLCipher) {
				pin_auth
					.getLastLoggedInUserID()
					.then((response) => {
						const userID = response.userID.toUpperCase();
						SQLCipher.executeSql(
							'SELECT * FROM fingerprint_settings',
							[],
							(rs: any) => {
								const { rows } = rs;
								let i = 0,
									userRow;
								for (i; i < rows.length; i++) {
									const row = rows.item(i);
									const user = row
										? row.userID.toUpperCase()
										: '';
									if (user === userID) {
										userRow = row;
										break;
									}
								}
								this.fingerprint_enabled = userRow
									? userRow.value == 'true'
									: false;
								store.dispatch(touchIDEnabled(this.fingerprint_enabled));
								resolve();
							}
						);

					})
					.catch(resolve);
			} else {
				resolve();
			}
		});
	}

	// Check Capabilities
	static fingerprintAvailable(): Promise<any> {
		return new Promise((resolve, reject) => {
			if (!isMobileDevice) {
				resolve(false);
			} else if (cordova.platformId == 'android') {
				const FingerprintAuth = (window as any).FingerprintAuth;
				FingerprintAuth.isAvailable((result: any) => {
					// If has fingerprint device and has fingerprints registered
					if (
						result.isAvailable == true &&
						result.hasEnrolledFingerprints == true
					) {
						resolve(true);
					} else {
						resolve(false);
					}
				});
			} else {
				const TouchID = (window as any).plugins.touchid;
				TouchID.isAvailable(
					() => {
						resolve(true);
					},
					(msg: any) => {
						resolve(false);
					}
				);
			}
		}).then((available: boolean) => {
			return available;
		});
	}
}
