import React from 'react';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import { themePalette } from '../../utilities/branding';


export type Props = {
    visible: boolean;
    errorGeneral?: string;
    onSubmit: (event: any) => void;
    disabled?: boolean;
}

export const LoginForm: React.FC<Props> = (props: Props) => {

    return props.visible
        ? (
            <form onSubmit={props.onSubmit} style={{ maxWidth: 300, margin: 'auto' }}>
                {props.errorGeneral && (
                    <Grid container>
                        <Grid item xs={12} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                            {props.errorGeneral}
                        </Grid>
                    </Grid>
                )}
                <Grid container justifyContent="center" alignItems="center">
                    {props.disabled 
                        ? <CircularProgress color="secondary" />
                        : <Button
                            fullWidth
                            variant="contained"
                            style={{
                                backgroundColor: themePalette.accept_button,
                                color: themePalette.negative_text,
                                margin: 20
                            }}
                            type="submit"
                        >
                            Login
                        </Button>
                    }
                </Grid>
            </form>
        )
        : null
};