import { Attachment } from '../reducers/attachment_reducer';
import { Action as ReduxAction } from 'redux';
import {actionCreatorFactory} from 'typescript-fsa';
import uuid from 'uuid';
import moment from 'moment';

const actionCreator = actionCreatorFactory('Attachment');

export interface AttachmentUpload {
	fileName: string;
	file?: any;
	householdId?: string;
	employerId?: string;
}

export const AddAttachment = actionCreator.async<Attachment, Attachment>(
	'ADD_ATTACHMENT'
);

export function addAttachment(attachmentUpload: AttachmentUpload): ReduxAction {
	const newAttachment: Attachment = {
		id: uuid.v4(),
		fileName: attachmentUpload.fileName,
		file: attachmentUpload.file,
		employerId: attachmentUpload.employerId,
		householdId: attachmentUpload.householdId,
		dateCreated: moment
			.utc()
			.local()
			.toDate(),
	};
	return AddAttachment.started(newAttachment);
}

export function addAttachmentSuccess(
	attachmentUpload: Attachment,
	uploadedAttachmend: Attachment
) {
	return AddAttachment.done({
		params: attachmentUpload,
		result: uploadedAttachmend,
	});
}

export function addAttachmentFailure(attachmentUpload: Attachment, error: any) {
	return AddAttachment.failed({
		params: attachmentUpload,
		error: error,
	});
}

export const DeleteAttachment = actionCreator.async<Attachment, string>(
	'DELETE_ATTACHMENT'
);

export function deleteAttachment(attachmentToDelete: Attachment): ReduxAction {
	const strippedAttachment = Object.assign({}, attachmentToDelete);
	strippedAttachment.file = undefined;
	return DeleteAttachment.started(strippedAttachment);
}

export function deleteAttachmentSuccess(
	attachmentToDelete: Attachment,
	deleteAttachmentId: string
) {
	return DeleteAttachment.done({
		params: attachmentToDelete,
		result: deleteAttachmentId,
	});
}

export function deleteAttachmentFailure(
	attachmentToDelete: Attachment,
	error: any
) {
	return DeleteAttachment.failed({
		params: attachmentToDelete,
		error: error,
	});
}

// DOWNLOAD ATTACHMENT FROM CONTACT
export const DownloadAttachment = actionCreator<Attachment>(
	'DOWNLOAD_ATTACHMENT'
);
export function downloadAttachment(attachmentUpload: Attachment): ReduxAction {
	return DownloadAttachment(attachmentUpload);
}
