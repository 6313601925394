interface String {
    format(...args: any[]): string;
}

if (!String.prototype.format) {
    String.prototype.format = function(...args: any[]) {
        // var args = arguments;
        return this.replace(/{(\d+)}/g, function(match, number) { 
            return typeof args[number] != 'undefined'
                ? args[number]
                : match
            ;
        });
    };
}
