import React from 'react';
import { defaultSvgProps, SvgProps } from '..';

const NotificationsSvg: React.FC<SvgProps> = (props) => (
    <svg viewBox="0 0 35 35" {...props}>
        <path
            d="M7.77 27.49a.46.46 0 0 1-.23-.06.5.5 0 0 1-.22-.67l.28-.54c.88-1.69 1.17-2.26 1.61-8 .42-5.36 4.07-9.1 8.88-9.1h.61a.5.5 0 0 1 .5.5.5.5 0 0 1-.5.5h-.61c-4.33 0-7.5 3.29-7.88 8.17-.46 5.95-.78 6.56-1.72 8.39l-.28.53a.48.48 0 0 1-.44.28z"
        />
        <path
            d="M28.84 27.49a.48.48 0 0 1-.44-.28l-.28-.53c-.94-1.83-1.26-2.44-1.72-8.39-.38-4.88-3.55-8.17-7.88-8.17h-.61a.5.5 0 0 1-.5-.5.51.51 0 0 1 .5-.5h.61c4.81 0 8.46 3.74 8.88 9.09.44 5.75.74 6.32 1.61 8l.28.54a.5.5 0 0 1-.22.67.46.46 0 0 1-.23.07z"
        />
        <path
            d="M7.6 27.52a.5.5 0 0 1-.1-1 53.14 53.14 0 0 1 21.61 0 .5.5 0 0 1-.2 1 51.9 51.9 0 0 0-21.21 0zM18.31 9.92a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5.5.5 0 0 1 .5.5v2a.51.51 0 0 1-.5.5z"
        />
        <path
            d="M18.31 29.39a2.5 2.5 0 0 1-2.5-2.49.5.5 0 0 1 .5-.5.5.5 0 0 1 .5.5 1.5 1.5 0 0 0 3 0 .5.5 0 0 1 .5-.5.5.5 0 0 1 .5.5 2.5 2.5 0 0 1-2.5 2.49z"
        />
    </svg>
);
NotificationsSvg.defaultProps = defaultSvgProps;

export default NotificationsSvg;
