import * as React from 'react';
import { DialogForm } from './dialog_form';

interface ExpirationDateDialogProps {
	isOpen: boolean;
	handleClose: () => void;
	handleSubmit: () => void;
	expirationDate: string;
}

export const ExpirationDateDialog: React.FC<ExpirationDateDialogProps> = ({
	isOpen,
	handleClose,
	handleSubmit,
	expirationDate,
}) => {
	return (
		<>
			{isOpen && (
				<DialogForm onSubmit={handleSubmit} dismissSelf={handleClose} title="Are you sure?" submitText="Save">
					{`All recommendations created before ${expirationDate} will be automatically accepted`}
				</DialogForm>
			)}
		</>
	);
};
