import { makeEnforceMapType } from './object_util';
import { themePalette } from './branding';

const isInteger = (val: number) => Math.round(val) === val;
const isWithin12 = (val: number): val is 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 =>
    isInteger(val) && val >= 1 && val <= 12;

export const getColumnSizeForNumOfCols = (part: number) => {
    const sizeOfPart = Math.round(12 / part)
    if (isWithin12(sizeOfPart)) {
        return sizeOfPart;
    }
    return 1;
};

export const enforceStylesType = makeEnforceMapType<React.CSSProperties>();

export const getSmallIconStyle = (color?: string): React.CSSProperties => ({
    height: '25px',
    width: '25px',
    margin: 'auto',
    display: 'block',
    color: color || themePalette.sub_text,
});

export const getMediumIconStyle = (color?: string): React.CSSProperties => ({
    marginTop: 10,
    height: 35,
    width: 35,
    fontSize: 35,
    textDecoration: 'none',
    color: color || themePalette.sub_text
});

export const getBaseIconStyle = (color?: string): React.CSSProperties => ({
    margin: '15 auto',
    height: 55,
    width: 55,
    fontSize: 55,
    color: color || themePalette.sub_text,
    textDecoration: 'none',

});