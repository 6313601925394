export const findWithFallbacks = <T>(list: Array<T>, ...findFunctions: Array<(item: T, index?: number) => boolean>) => {
    for (const findFunction of findFunctions) {
        const foundItem = list.find(findFunction);
        if (foundItem) {
            return foundItem;
        }
    }

    return undefined;
}

export const findAndGet = <T, R>(list: Array<T>, findFunction: (item: T, index?: number) => boolean, getFunction: (item: T) => R) => {
    const foundItem = list.find(findFunction);
    return foundItem !== undefined
        ? getFunction(foundItem)
        : undefined;
};

export const isFirst = <T>(item: T, index?: number) => index === 0;

export const sum = <T>(list: Array<T>, getVal: (item: T) => number) => {
    return list.reduce((sum, item) => sum + getVal(item), 0);
};

/***
 * Modifies and returns the original array
 */
export const removeByValue = <T>(list: Array<T>, value: T) => {
    const foundIndex = list.findIndex(item => item === value);
    if (foundIndex >= 0) {
        list.splice(foundIndex, 1);
    }
    return list;
}

/***
 * Returns a new array identical to the input
 */
export const clone = <T>(list: Array<T>) => list.slice(0);

export const mapIgnoreUndefined = <T, K>(list: Array<T>, mapper: (item:T) => K | undefined) => {
    const mappedResults = list.map(mapper);
    const definedResults = mappedResults.filter(item => item !== undefined) as Array<K>;
    return definedResults;
}