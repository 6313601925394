import { themePalette } from "../../utilities/branding";

export const DEFAULT_SIZE = 24;
export interface SvgProps {
    fill?: string;
    width?: number;
    height?: number;
    style?: React.CSSProperties;
}
export const defaultSvgProps: SvgProps = {
    fill: themePalette.defaultSvg,
    width: DEFAULT_SIZE,
    height: DEFAULT_SIZE, 
};