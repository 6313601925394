import { Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import { AppState } from '../../../reducers';
import { Dispatch } from '@reduxjs/toolkit';
import { GetAgentLicenses } from '../../../actions/license_appointment_actions';
import { AgentLicense, LicenseForm } from '../../../reducers/license_appointment_reducer';
import { getCurrentAgentCode } from '../../../selectors/agent_selectors';
import { getAgentLicenses } from '../../../selectors/license_appointment_selectors';
import LicenseTable from '../../../components/agentLicenseAppointment/license/license_table';
import _ from 'lodash';

interface StateProps {
    loading: boolean;
    currentAgentCode: string;
    agentLicenses: AgentLicense[];
    filterForm: LicenseForm,
}

interface DispatchProps {
    getAgentLicenses: (filterForm: LicenseForm) => void
}

type Props = StateProps & DispatchProps;

const LicenseListComponent: React.FC<Props> = (props) => {

    const columns = [
        { key: 'State', name: 'State' },
        { key: 'StatusText', name: 'Status' },
        { key: 'ExpirationDate', name: 'Expiration' },
        { key: 'LineOfLicenseText', name: 'Line Of Authority' }
        // Add more columns as needed
    ];

    const { loading, currentAgentCode, agentLicenses, getAgentLicenses, filterForm } = props;

    useEffect(() => {
        if (currentAgentCode != 'unknown') {
            getAgentLicenses(filterForm);
        }
    }, [currentAgentCode]);

    const renderLicenseData = () => {
        if (loading) {
            return (
                <Typography align='center'>Loading...</Typography>
            );
        }
        else if (agentLicenses && agentLicenses.length > 0) {
            return (
                <LicenseTable columns={columns} rows={agentLicenses} />
            );
        } else {
            return null;
        }
    }

    return (
        <div>
            {renderLicenseData()}
        </div>
    );
}

const mapStateToProps = (state: AppState): StateProps => ({
    loading: state.licenseAppointment.loading,
    currentAgentCode: getCurrentAgentCode(state),
    agentLicenses: getAgentLicenses(state),
    filterForm: state.licenseAppointment.filterLicenseForm
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    getAgentLicenses: (filterForm: LicenseForm) => dispatch(GetAgentLicenses.started(filterForm)),
});

export const LicenseListContainer = connect(mapStateToProps, mapDispatchToProps, true)(LicenseListComponent);
