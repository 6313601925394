import { AppState } from '../reducers';
import { createSelector } from 'reselect';


export const getDialogState = (state: AppState) => state.dialogs;

export const getNextDialog = createSelector([getDialogState], dialogState => {
    if (dialogState.length > 0) {
        return dialogState[0].component;
    }
});
