import React, { useState } from 'react';
import { Menu, MenuItem, IconButton, Icon, MenuItemProps } from '@material-ui/core';
import { isNullOrUndefined } from 'util';
import { themePalette } from '../../utilities/branding';
import { makeOpenExternalLink } from '../../utilities';
import { StopPropagation } from '../../utilities/html';


export const createMenuAction = (name: string, onClick: () => void, disabled = false): MenuItemProps => ({
	children: name,
	onClick,
	disabled
});

export const createHelpAction = (url: string): MenuItemProps => 
	createMenuAction('Help', makeOpenExternalLink(url));

export interface MoreMenuProps {
	children: MenuItemProps[];
	hidden?: boolean;
	onMenuClick?: () => void;
	color?: string;
	icon?: string;
}
export const MoreMenu: React.FC<MoreMenuProps> = (props) => {

	const { icon='more_vert', color=themePalette.negative_text, hidden, onMenuClick, children } = props;
	const style: React.CSSProperties = { zIndex: 3, color  };
	const [anchorEl, setAnchorEl] = useState<HTMLElement>();

	const handleClick = (itemClick?: any) => {
		setAnchorEl(undefined);
		itemClick && itemClick();
	};
	
	return (
		<StopPropagation>
			<IconButton
				style={style}
				hidden={hidden}
				onClick={event => {
					if (onMenuClick) {
						onMenuClick();
					}
					setAnchorEl(event.currentTarget);
				}}
				key="menu_icon"
			>
				<Icon>{icon}</Icon>
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={!isNullOrUndefined(anchorEl)}
				onClose={() => setAnchorEl(undefined)}
				key="menu"
			>
				{children.map((childProps, index) => (
					<MenuItem
						{...childProps}
						button
						key={'more_menu-' + index}
						disabled={childProps.disabled}
						onClick={() => handleClick(childProps.onClick)}
					/>
				))}
			</Menu>
		</StopPropagation>
	);
}


