import { actionCreatorFactory } from 'typescript-fsa';
import { PagingOptions } from './notification_actions';
import { ManagedAgent, AccountUpsertForm } from '../reducers/admin_reducer';

export interface ManagedAgentResults {
	results: ManagedAgent[];
	totalResults: number;
}

const adminActionCreator = actionCreatorFactory('ADMIN');

export const GetAgentsAdmin = adminActionCreator.async<PagingOptions, ManagedAgentResults>('GET_AGENTS');
export const UpsertAgentAdmin = adminActionCreator.async<AccountUpsertForm, ManagedAgentResults>('UPSERT_AGENT');
export const UpdateUpsertForm = adminActionCreator<AccountUpsertForm>('UPDATE_UPSERT_FORM');
