import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

interface LeadChartStatsProps {
    result: string;
    slAvg: string;
    taken: string;
};

export const LeadChartStats: React.FC<LeadChartStatsProps> = props => {
    const classes = useStyles();
    const { result, slAvg, taken } = props;

    return (
        <Grid container>
        <Grid item xs={6} className={classes.stats}>
            <Typography>
                <span style={{marginRight: '20px'}}>Result</span>
                <span>{result}</span>
            </Typography>
            <Typography>
                <span style={{marginRight: '20px'}}>SL Avg</span>
                <span>{slAvg}</span>
            </Typography>
        </Grid>
        <Grid item xs={6} className={classes.stats}>
            {taken && (
                <>
                    <Typography># Taken</Typography>
                    <Typography>{taken}</Typography>
                </>)}
        </Grid>
    </Grid>
    )
};

const useStyles = makeStyles({
    stats: {
        overflow: 'hidden',
        WebkitTextSizeAdjust: '100%',
        marginTop: 7,
        marginBottom: 7,
        padding: 10,
    },
});
