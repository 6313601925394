import { Action as ReduxAction } from 'redux';
import {actionCreatorFactory} from 'typescript-fsa';
import uuid from 'uuid';
import moment from 'moment';
import { Note } from '../reducers/note_reducer';

const actionCreator = actionCreatorFactory('Note');

export interface CreateNoteParams {
	content: string;
	employerId?: string;
	householdId?: string;
}

export const CreateNote = actionCreator.async<Note, Note>('CREATE_NOTE');

export function createNote(noteParams: CreateNoteParams): ReduxAction {
	const newNote: Note = {
		id: uuid.v4(),
		content: noteParams.content,
		employerId: noteParams.employerId,
		householdId: noteParams.householdId,
		dateCreated: moment.utc().toDate(),
	};
	return CreateNote.started(newNote);
}

export const UpdateNote = actionCreator.async<Note, Note>('UPDATE_NOTE');

export function createNoteSuccess(newNote: Note, uploadedNote: Note) {
	return CreateNote.done({
		params: newNote,
		result: uploadedNote,
	});
}

export function createNoteFailure(newNote: Note, error: any) {
	return CreateNote.failed({
		params: newNote,
		error: error,
	});
}

export const DeleteNote = actionCreator.async<Note, string>('DELETE_NOTE');

export function deleteNote(note: Note): ReduxAction {
	return DeleteNote.started(note);
}

export function deleteNoteSuccess(noteToDelete: Note, deletedNoteId: string) {
	return DeleteNote.done({
		params: noteToDelete,
		result: deletedNoteId,
	});
}

export function deleteNoteFailure(noteToDelete: Note, error: any) {
	return DeleteNote.failed({
		params: noteToDelete,
		error: error,
	});
}
