import { Avatar } from '@material-ui/core';
import React from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import { navRoutes } from '../nav/Routes';
import { Notification } from '../../containers/notifications_page/notifications_page';
import { themePalette } from '../../utilities/branding';
import { Strings } from '../../assets/common/strings';
import { makeEnforceMapType, makeMakeGetByKey } from '../../utilities/object_util';
import { ActionPermission, P } from '../../utilities/auth/permissions';
import { isBrokerage } from '../../utilities/brokerage_utils';
import { AppState } from '../../reducers';
import { Dispatch } from '@reduxjs/toolkit';
import { getNewNotifications } from '../../selectors/notifications_selectors';
import { SimpleListItem } from '../utility/simple_list_item';
import { useNavigateTo } from '../../utilities/hooks';

interface ComponentProps {
	categorySelector: INotificationCategory;
}
interface StateProps {
	notifications: Notification[];
}
interface DispatchProps {}
type Props = ComponentProps & StateProps & DispatchProps;

const NotificationSummaryComponent: React.FC<Props> = (props: Props) => {

	const { notifications, categorySelector } = props;

	const categoryFilter = categorySelector.selector;
	const specificNotifications = notifications.filter(n => n.category === categoryFilter);
	const notificationsCount = specificNotifications.length;
	const showFilteredNotifications = useNavigateTo(navRoutes.filteredNotifications.path.replace(
		Strings.Navigation.CategorySelector,
		categoryFilter
	));

	if (notificationsCount > 0) {
		const notificationsCountDisplay: string = notificationsCount > 99
			? '99+'
			: notificationsCount.toString();
		const notificationHintSuffix = notificationsCount > 1
			? `...and ${notificationsCount - 1} more`
			: '';

		const firstNotification: Notification = specificNotifications[0];

		const notificationTitle = firstNotification?.title || '';
		const notificationContent = firstNotification?.content?.base?.substring(
			0,
			window.innerWidth / 10 - notificationHintSuffix.length - 4
		) + notificationHintSuffix || '';
		return (
			<SimpleListItem
				icon={
					<Avatar style={{ backgroundColor: themePalette.notification_count }}>
						{notificationsCountDisplay}
					</Avatar>
				}
				title={notificationTitle}
				subtitle={notificationContent}
				onClick={showFilteredNotifications}
				style={{ backgroundColor: themePalette.secondary_background }}
				divider={false}
			/>
		);
	}
	return null;
};

const mapStateToProps = (state: AppState): StateProps => ({
	notifications: getNewNotifications(state),
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({});
export const NotificationSummary = connect(mapStateToProps, mapDispatchToProps, true)(
	NotificationSummaryComponent
) as React.ComponentClass<ComponentProps>;


export interface INotificationCategory {
	selector: string;
	display: string;
	permission: ActionPermission;
	isHidden?: boolean;
	getPath?: () => string;
}

// Routes are functions because navRoutes is not available yet 
// because this file is transitively referenced by the file that declares navRoutes
const enforceNotificationType = makeEnforceMapType<INotificationCategory>();
export const notificationCategories = enforceNotificationType({
	general: {
		selector: 'general',
		display: 'General',
		permission: P.General,
	},
	announcements: {
		selector: 'announcements',
		display: 'Announcement',
		permission: P.News,
		isHidden: isBrokerage,
	},
	battles: {
		selector: 'battles',
		display: 'Battles',
		permission: P.Battle,
		isHidden: isBrokerage,
		getPath: () => navRoutes.battles.path
	},
	clientConnect: {
		selector: 'clientConnect',
		display: 'ClientConnect',
		permission: P.ClientConnect,
		isHidden: isBrokerage,
		getPath: () => navRoutes.clientConnect.path,
	},
	crm: {
		selector: 'crm',
		display: 'CRM',
		permission: P.Lead,
		getPath: () => navRoutes.leadDashboard.path,
	},
	documents: {
		selector: 'documents',
		display: 'Documents',
		permission: P.Document,
		isHidden: isBrokerage,
		getPath: () => navRoutes.documents.path,
	},
	finances: {
		selector: 'finances',
		display: 'Finances',
		permission: P.Finance,
		isHidden: isBrokerage,
		getPath: () => navRoutes.finance.path,
	},
	leads: {
		selector: 'leads',
		display: 'Leads',
		permission: P.LeadConnectRead,
		getPath: () => navRoutes.leadCircle.path,
	},
	notifications: {
		selector: 'notifications',
		display: 'Notification',
		permission: P.Notification,
		getPath: () => navRoutes.notificationList.path,
	},
	profile: {
		selector: 'profile',
		display: 'Profile',
		permission: P.Profile,
		getPath: () => navRoutes.profile.path,
	},
	settings: {
		selector: 'settings',
		display: 'Settings',
		permission: P.Settings,
		getPath: () => navRoutes.settings.path,
	},
	todoList: {
		selector: 'todoList',
		display: 'To-Do List',
		permission: P.TodoList,
		getPath: () => navRoutes.activities.path,
	},
	tools: {
		selector: 'tools',
		display: 'Tools',
		permission: P.Tool,
		getPath: () => navRoutes.tools.path,
	},
});

const makeGetNotificationByKey = makeMakeGetByKey<INotificationCategory>();
export const getNotificationCategoryByKey = makeGetNotificationByKey(notificationCategories);