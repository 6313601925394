import React from 'react';
import { reduxForm, StrictFormProps } from 'redux-form';
import { AddressFields, AddressFieldsData } from '../lead_upsert/address_fields';
import { Strings as S } from '../../assets/common/strings';
import { Address } from '../../reducers/ContactReducer';
import { getAppState } from '../..';

export const FORM_NAME = 'UpsertAddressForm';
export type FormData = AddressFieldsData;

interface Props {
	addressType?: string;
	upsertAddress?: (addresses: Address[]) => void;
	maxAddressCount?: number;
}

const _UpsertAddressForm = (
	props: Props & StrictFormProps<FormData, any, any>
) => {
	return (
		<form>
			<AddressFields
				formName={FORM_NAME}
				addressType={S.AddressType.Storefront}
				upsertAddress={props.upsertAddress}
				maxAddressCount={props.maxAddressCount}
			/>
		</form>
	);
};

export const UpsertAddressForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
	getFormState: () => getAppState().form,
})(_UpsertAddressForm) as React.ComponentClass<
	Props & Partial<StrictFormProps<FormData, any, any>>
>;
