import { SetTablePage, SetRowsPerPage } from './../actions/template_actions';
import moment from 'moment';
import { Action as ReduxAction } from 'redux';
import { isType } from 'typescript-fsa';
import {
	GetAgentTemplates,
	GetCorporateTemplates,
	DeleteTemplate,
	GetTemplateById,
	PostTemplate,
	GetTemplateSubstitutions,
	StoreTemplateFilter,
	ClearTemplateStores,
	StoreEditedTemplate,
	ClearEditedTemplate,
} from '../actions/template_actions';
import BaseDTO from '../utilities/base_dto';
import { Loaded } from '../utilities/utilities';
import { isNullOrUndefined } from 'util';
import { ClearCaches } from '../actions/authentication_actions';
import { SearchFilterFormValues } from './advanced_search_reducer';
import { EMPTY_GUID } from '../utilities/empty_entities';
import { Logout } from '../actions/authentication_actions';
import { Strings } from '../assets/common/strings'

export interface Template extends BaseDTO {
	agentId: string;
	description: string;
	emailType: string;
	htmlContent: string;
	isDraft: boolean;
	isInUseOnAutomation: boolean;
	language: string;
	linesOfBusiness: string[];
	plainTextContent: string;
	subject: string;
	title: string;
	emailAutomationEventTypeCategory: string[];
	eventId: string;
	domain: string;
};

const initialTemplate: Template = {
	agentId: EMPTY_GUID,
	createdBy: EMPTY_GUID,
	createdOn: moment().toDate(),
	description: '',
	eventId: '',
	emailType: Strings.TemplateManagement.Agent,
	htmlContent: '',
	id: '',
	isDeleted: false,
	isDraft: true,
	isInUseOnAutomation: false,
	language: Strings.Languages.English,
	linesOfBusiness: [],
	plainTextContent: '',
	subject: '',
	title: '',
	updatedBy: EMPTY_GUID,
	updatedOn: moment().toDate(),
	userId: EMPTY_GUID,
	emailAutomationEventTypeCategory: [],
	domain: ''
};

export interface BulkEmail {
	contactIds: string[];
	searchCriteria?: SearchFilterFormValues;
	emailType: string;
	priority: number;
	templateId: string;
};

export interface TemplateManagerState {
	agentTemplates: Loaded<Template>[];
	currentTemplate: Template;
	corporateTemplates: Loaded<Template>[];
	areAgentTemplatesLoading: boolean;
	areCorporateTemplatesLoading: boolean;
	areSubsLoading: boolean;
	subbedHtml: string;
	editedTemplate?: Template;
	filter: EmailTemplateFilter;
	rowsPerPage: number;
	currentPage: number;
};

export enum TemplateTypeOptions {
	agent = 'Agent',
	corporate = 'Corporate',
	all = 'All',
};

export interface EmailTemplateFilter {
	linesOfBusiness: string[];
	emailType: TemplateTypeOptions;
	removeDrafts?: boolean;
	emailEventTypeCategory: string[];
};

export const initialEmailFilter: EmailTemplateFilter = {
	emailType: TemplateTypeOptions.all,
	linesOfBusiness: [],
	emailEventTypeCategory: []
};

const initialState: TemplateManagerState = {
	agentTemplates: [],
	corporateTemplates: [],
	areAgentTemplatesLoading: false,
	areCorporateTemplatesLoading: false,
	subbedHtml: '',
	areSubsLoading: false,
	currentTemplate: initialTemplate,
	filter: initialEmailFilter,
	rowsPerPage: 10,
	currentPage: 0,
};

export interface TemplatePreview {
	htmlContent: string;
	emailType: string;
	agentId?: string;
};

export function templateManagerStateReducer(
	state: TemplateManagerState = initialState,
	action: ReduxAction
): TemplateManagerState {
	if (isType(action, StoreTemplateFilter)) {
		return {
			...state,
			currentPage: 0,
			filter: action.payload,
		};
	} else if (isType(action, GetAgentTemplates.started)) {
		return {
			...state,
			areAgentTemplatesLoading: true,
		};
	} else if (isType(action, GetCorporateTemplates.started)) {
		return {
			...state,
			areCorporateTemplatesLoading: true,
		};

	} else if (isType(action, SetTablePage)) {
		return {
			...state,
			currentPage: action.payload,
		};
	} else if (isType(action, SetRowsPerPage)) {
		return {
			...state,
			currentPage: 0,
			rowsPerPage: action.payload,
		};
	} else if (isType(action, DeleteTemplate.started) || isType(action, GetTemplateById.started) || isType(action, PostTemplate.started)) {
		return {
			...state,
			areAgentTemplatesLoading: true,
			areCorporateTemplatesLoading: true,
			subbedHtml: '',
		};

	} else if (isType(action, GetTemplateSubstitutions.started)) {
		return {
			...state,
			areSubsLoading: true,
			subbedHtml: '',
		};

		// DONE
	} else if (isType(action, PostTemplate.done)) {
		let slicedTemplates: Loaded<Template>[] =
			action.payload.params.emailType == Strings.TemplateManagement.Corporate
				? state.corporateTemplates.slice()
				: state.agentTemplates.slice();

		const templateIndex = slicedTemplates.findIndex(
			slicedTemplate => {
				return (
					slicedTemplate.data
					&& slicedTemplate.data.id == action.payload.params.id
				);
			}
		);

		if (templateIndex == -1) {
			let newTemplate = {
				data: action.payload.result,
				loading: false,
				errors: [],
			};
			slicedTemplates.push(newTemplate);
		} else {
			slicedTemplates[templateIndex] = {
				...slicedTemplates[templateIndex],
				data: action.payload.result,
				loading: false,
				errors: [],
			};
		};

		if (action.payload.params.emailType == Strings.TemplateManagement.Corporate) {
			return {
				...state,
				corporateTemplates: slicedTemplates,
				areAgentTemplatesLoading: false,
				areCorporateTemplatesLoading: false,
			};
		} else {
			return {
				...state,
				agentTemplates: slicedTemplates,
				areAgentTemplatesLoading: false,
				areCorporateTemplatesLoading: false,
			};
		};

	} else if (isType(action, GetTemplateSubstitutions.done)) {
		return {
			...state,
			areSubsLoading: false,
			subbedHtml: action.payload.result,
		};

	} else if (isType(action, GetAgentTemplates.done)) {
		let slicedTemplates = state.agentTemplates.slice();

		action.payload.result.forEach(template => {
			const templateIndex = slicedTemplates.findIndex(
				slicedTemplate => {
					return slicedTemplate.data && slicedTemplate.data.id == template.id;
				}
			);

			if (templateIndex == -1) {
				let newTemplate = {
					data: template,
					loading: false,
					errors: [],
				};
				slicedTemplates.push(newTemplate);
			} else {
				slicedTemplates[templateIndex] = {
					data: template,
					loading: false,
					errors: [],
				};
			}
		});

		return {
			...state,
			agentTemplates: slicedTemplates,
			areAgentTemplatesLoading: false,
		};

	} else if (isType(action, GetCorporateTemplates.done)) {
		let slicedTemplates = state.corporateTemplates.slice();
		action.payload.result.forEach(template => {
			const templateIndex = slicedTemplates.findIndex(
				slicedTemplate => {
					return slicedTemplate.data && slicedTemplate.data.id == template.id;
				}
			);

			if (templateIndex == -1) {
				let newTemplate = {
					data: template,
					loading: false,
					errors: [],
				};
				slicedTemplates.push(newTemplate);
			} else {
				slicedTemplates[templateIndex] = {
					data: template,
					loading: false,
					errors: [],
				};
			}
		});

		return {
			...state,
			corporateTemplates: slicedTemplates,
			areCorporateTemplatesLoading: false,
		};

	} else if (isType(action, DeleteTemplate.done)) {
		const templateId: string = action.payload.result;
		let agentTemplates = state.agentTemplates.slice();

		let matchedAgentIndex = agentTemplates.findIndex(
			template => {
				return (
					!isNullOrUndefined(template.data) && template.data.id === templateId
				);
			}
		);

		agentTemplates.splice(matchedAgentIndex, 1);

		let corporateTemplates = state.corporateTemplates.slice();
		let matchedCorporateIndex = corporateTemplates.findIndex(
			template => {
				return (
					!isNullOrUndefined(template.data) && template.data.id === templateId
				);
			}
		);

		corporateTemplates.splice(matchedCorporateIndex, 1);

		return {
			...state,
			agentTemplates: agentTemplates,
			corporateTemplates: corporateTemplates,
			areCorporateTemplatesLoading: false,
			areAgentTemplatesLoading: false,
		};

	} else if (isType(action, GetTemplateById.done)) {
		return {
			...state,
			currentTemplate: action.payload.result,
			areAgentTemplatesLoading: false,
			areCorporateTemplatesLoading: false,
		};

	} else if (isType(action, GetAgentTemplates.failed)) {
		return {
			...state,
			areAgentTemplatesLoading: false,
		};

	} else if (isType(action, GetCorporateTemplates.failed)) {
		return {
			...state,
			areCorporateTemplatesLoading: false,
		};

	} else if (isType(action, DeleteTemplate.failed) || isType(action, GetTemplateById.failed)) {
		return {
			...state,
			areAgentTemplatesLoading: false,
			areCorporateTemplatesLoading: false,
		};

	} else if (isType(action, GetTemplateSubstitutions.failed)) {
		return {
			...state,
			areSubsLoading: false,
			subbedHtml: ''
		};

	} else if (isType(action, Logout.started) || isType(action, ClearCaches)) {
		return {
			...initialState
		};

	}  else if (isType(action, ClearTemplateStores)) {
		return {
			...initialState,
		};

	} else if (isType(action, StoreEditedTemplate)) {
		return {
			...state,
			editedTemplate: action.payload
		};

	} else if (isType(action, ClearEditedTemplate)) {
		return {
			...state,
			editedTemplate: undefined,
			currentTemplate: initialTemplate
		};

	} else {
		return state;
	}
}
