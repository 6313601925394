import React from 'react';
import { Loaded } from '../utilities/utilities';
import { Activity, FollowupFields } from '../reducers/activity_reducer';
import { Lookup, Lookups } from '../utilities/lookup/lookup';
import { LookupDictionary } from '../utilities/lookup/lookup_dictionary';
import { NavigationProps } from '../components/nav/Routes';
import { isMobileDevice } from '../utilities/is_mobile';
import { createMenuAction } from '../components/nav/more_menu';
import { LeadFilters } from '../reducers/LeadFilterReducer';
import { fullName } from '../assets/common/string_builders';
import { Contact } from '../reducers/ContactReducer';
import { FollowUpActivityType } from './activity/upsert_activity';
import moment from 'moment';
import { makeAbstractMethod } from '../utilities/function_util';
import { get } from '../utilities/object_util';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@material-ui/core';
import { themePalette, themeLinks } from '../utilities/branding';
import { AttachmentUpload, addAttachment, downloadAttachment, deleteAttachment } from '../actions/attachment_actions';
import { CreateNoteParams, createNote, deleteNote, UpdateNote } from '../actions/note_actions';
import { getLeadsWithHistoryAsActivities, formatLeadFiltersPayload } from '../utilities/lead_util';
import { Lead } from '../reducers/LeadReducer';
import { enforceStylesType } from '../utilities/styles_util';
import { Application } from '../reducers/application_reducer';
import { Note } from '../reducers/note_reducer';
import { OwnershipHistory } from '../reducers/ownership_history_reducer';
import { Policy, AppOrPolicy } from '../reducers/policy_reducer';
import { Attachment } from '../reducers/attachment_reducer';
import { Dispatch } from '@optum-uhone-hmkts/rise';
import { SetProductTab } from '../actions/policy_actions';
import { UpdateLead, GetFilteredLeads } from '../actions/lead_actions';
import { DeleteActivity, StoreFollowupDescription } from '../actions/activity_actions';
import { DeleteApplication } from '../actions/application_actions';
import { DeleteContact } from '../actions/contact_actions';
import { GetAgentWithAgentId } from '../actions/agent_actions';
import { navigateTo as navigateToRoute } from '../actions/navigation_actions';
import { StoreLeadRoutesIndex } from '../actions/lead_routes_actions';
import { LeadRoutePages } from '../reducers/lead_routes_reducer';
import { Strings } from '../assets/common/strings';
import { DeleteQuoteActivity } from '../actions/quote_activity_actions';
import { StartCallSequence } from '../actions/sequence/call_sequence_actions';
import { TagsList } from '../components/lead/tags_list';
import { makeOpenExternalLink } from '../utilities';

export interface StateProps {
	applications: Loaded<Application>[];
	attachments: Loaded<Attachment>[];
	currentAgentCode: string;
	currentAgentDisplayName: string;
	hasActiveLead: boolean;
	impersonatingId?: string;
	leadAgent: any;
	leadFilters: LeadFilters;
	leads: Loaded<Lead>[];
	mostRecentPageLead: Lead;
	moreLeadsToLoad: boolean;
	nextActivity?: Activity;
	notes: Loaded<Note>[];
	openLead: Lead;
	originPage: LeadRoutePages;
	ownershipHistories: OwnershipHistory[];
	pageSize: number;
	policies: Loaded<Policy>[];
	userId: string;
	activities: Array<Loaded<Activity>>;
	hasError: boolean;
	leadRoutes: Array<string>;
	leadRoutesIndex: number;
	lookups: LookupDictionary;
	pageNumber: number;
	primaryContact: Contact;
	clickToCallEnabled: boolean;
	hasActiveBusinessRelationship: boolean; // this is if the lead has active/pending policies or activities, to determine if DNC matters
}

export interface DispatchProps {
	deleteActivity: (activityToDelete: Activity) => void;
	deleteQuoteActivity: (id: string) => void;
	deleteApplication: (application: Application) => void;
	deleteAttachment: (attachmentToDelete: Attachment) => void;
	deleteContact: (contact: Contact) => void;
	deleteNote: (noteToDelete: Note) => void;
	downloadAttachment: (attachmentUpload: Attachment) => void;
	getAgentWithAgentId: (id: string) => void;
	getFilteredLeads: (
		filters: LeadFilters,
		pageNum: number,
		pageSize: number
	) => any;
	navigate: (route: string) => any;
	setProductTab: (tabToDisplay: AppOrPolicy) => void;
	startCallSequence: (contacts: Contact[], hasActivities: boolean) => void;
	storeFollowupDescription: (params: FollowupFields) => void;
	updateLead: (lead: Lead) => void;
	updateNote: (noteToUpdate: Note) => void;
	addAttachment: (attachmentUpload: AttachmentUpload) => void;
	createNote: (noteContent: CreateNoteParams) => void;
	storeLeadRoutesIndex: (index: number) => void;
}

export interface ComponentProps {
	isAdvancedSearch?: boolean;
	parentHistory?: any;
	navigateFromParent?: (route: string) => void;
}

type Props = ComponentProps & StateProps & DispatchProps & NavigationProps;

export interface State {
	attachmentFile?: File;
	addAttachmentDialogIsOpen: boolean;
	contactId: string;
	createNoteDialogOpen: boolean;
	leadAgentSearched: boolean;
	showReassignDialog: boolean,
}

interface GeneralMeta {
	createAppointmentUrl: string;
	editPhoneUrl: string;
}
interface EmployerMeta extends GeneralMeta {
	employerId: string
}
interface HouseholdMeta extends GeneralMeta {
	householdId: string
}
type Meta = EmployerMeta | HouseholdMeta;

const makeIsMetaType = <T extends Meta>(key: keyof T) => function isMetaType(meta: Meta): meta is T {
	return Boolean((meta as T)[key]);
};
const isEmployerMeta = makeIsMetaType<EmployerMeta>('employerId');
const isHouseholdMeta = makeIsMetaType<HouseholdMeta>('householdId');

export class LeadPage<TProps extends Props, TState extends State> extends React.Component<TProps, TState> {

	static MAX_FILE_SIZE_BYTES = 5242572;
	static VALID_FILE_TYPES = ['pdf', 'jpg', 'jpeg', 'png', 'tif', 'bmp', 'gif'];

	constructor(props: TProps) {
		super(props);
	}

	private getItemId = () => {
		const meta = this.getMeta();

		if (isEmployerMeta(meta)) {
			return meta.employerId;
		} else if (isHouseholdMeta(meta)) {
			return meta.householdId
		}
	}

	private getMetaId = () => {
		const { createAppointmentUrl, editPhoneUrl, ...id } = this.getMeta();
		return id;
	}

	getMeta = makeAbstractMethod('getMeta') as () => Meta;
	getBaseUrl = makeAbstractMethod('getBaseUrl') as () => string;
	replaceIdInUrl = makeAbstractMethod('replaceIdInUrl') as (url: string) => string;

	getShouldShowLeadNavigationArrows = () => !this.props.isAdvancedSearch;

	getHasNextLead = () => {
		const hasLeadRoutes = this.props.leadRoutes
			&& this.props.leadRoutes.length > 0;
		const hasMoreLeadsToLoad = this.props.originPage !== LeadRoutePages.AdvancedSearch && this.props.moreLeadsToLoad
		const atEndOfLoadableLeads = !hasMoreLeadsToLoad
			&& this.props.leadRoutesIndex >= this.props.leadRoutes.length - 1;
		return hasLeadRoutes && !atEndOfLoadableLeads;
	}

	getHasPreviousLead = () => !!(this.props.leadRoutesIndex && this.props.leadRoutesIndex > 0);

	getHasOpenPhoneCalls = () => this.props.activities.some(activity =>
		activity.data.status == Strings.ActivityStatus.Open
		&& activity.data.type == Strings.Activity.PhoneCall);

	getGoToDetailsFunction = () => {
		if (this.props.navigateFromParent) {
			return () => {
				this.props.navigateFromParent!(
					this.replaceIdInUrl(this.getBaseUrl())
				);
			}
		}
	};

	createPrintMenuAction = () => createMenuAction(Strings.MoreMenu.Print, () => { window.print() }, isMobileDevice);

	createHelpMenuAction = () => createMenuAction('Help', makeOpenExternalLink(themeLinks.helpLinkCRM));

	loadMoreLeads = () => {
		if (!this.props.hasError && this.props.moreLeadsToLoad) {
			this.props.getFilteredLeads(this.props.leadFilters, this.props.pageNumber, this.props.pageSize);
		}
	};

	handleNavigateToNext = (goToNext: boolean) => {
		const hasNoNextLeads = this.props.leadRoutes && this.props.leadRoutesIndex >= this.props.leadRoutes.length - 2;
		if (goToNext && hasNoNextLeads) {
			this.loadMoreLeads();
		}

		const requestedIndex = goToNext ? this.props.leadRoutesIndex + 1 : this.props.leadRoutesIndex - 1;
		this.props.storeLeadRoutesIndex(requestedIndex);
		this.props.navigateToWithoutAddingToHistory(
			this.props.leadRoutes[requestedIndex]
		);
	};

	handleFollowUp = (status: Lookup, id: string, followUpType: FollowUpActivityType) => {
		const { createAppointmentUrl, editPhoneUrl } = this.getMeta();

		var followUpDescription = status.label;
		const primary = this.props.primaryContact
		const name = fullName(primary);

		//schedule appointment
		if (followUpType == FollowUpActivityType.APPOINTMENT) {
			this.props.storeFollowupDescription({
				description: followUpDescription,
				time: moment(),
				title: `Appointment with ${name}`
			});

			this.props.navigate(this.replaceIdInUrl(createAppointmentUrl));
		}

		//schedule phone call
		else {
			this.props.storeFollowupDescription({
				description: followUpDescription,
				time: moment(),
			});

			this.props.navigate(this.replaceIdInUrl(editPhoneUrl));
		}
	};

	handleNavigateToFollowup = (
		status: Lookup,
		householdId: string,
		employerId: string,
		isFollowUp: boolean
	) => {
		const id = householdId || employerId;
		if (status.isChildOf(Lookups.CreateNewAppointment)) {
			this.handleFollowUp(status, id, FollowUpActivityType.APPOINTMENT);
		} else if (isFollowUp && status.isChildOf(Lookups.NoSale)) {
			this.handleNoSaleFollowUp(status, id);
		} else if (
			(isFollowUp)
			|| (status.isChildOf(Lookups.AlwaysCreateNewPhoneCall))) {
			this.handleFollowUp(status, id, FollowUpActivityType.PHONE_CALL);
		}
	};

	renderAttachmentDialog() {
		const fileSize = get(() => this.state.attachmentFile!.size, 0);
		const validateFileSize = fileSize < LeadPage.MAX_FILE_SIZE_BYTES;
		const validateFileType =
			!this.state.attachmentFile ||
			LeadPage.VALID_FILE_TYPES.some(type =>
				this.state.attachmentFile!.name.toLowerCase().endsWith(type)
			);

		return (
			<Dialog
				open={this.state.addAttachmentDialogIsOpen}
				onClose={() => this.setState({ addAttachmentDialogIsOpen: false })}
			>
				<DialogTitle>Add a new attachment</DialogTitle>
				<DialogContent>
					<TextField
						type="file"
						placeholder="Select a file..."
						inputProps={{
							style: styles.pointer
						}}
						onChange={(event: any) => //might break download
							this.setState({
								attachmentFile: get(() => event.target.files![0]),
							})
						}
						error={!validateFileSize || !validateFileType}
						helperText={
							!validateFileSize
								? 'File should be no bigger than 5 MB'
								: !validateFileType ? 'File type not accepted' : undefined
						}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						color="secondary"
						onClick={() => this.setState({ addAttachmentDialogIsOpen: false })}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="primary"
						style={{ backgroundColor: themePalette.accept_button, color: themePalette.negative_text }}
						disabled={
							!validateFileSize ||
							!validateFileType ||
							!this.state.attachmentFile
						}
						onClick={this.handleAddAttachment}
					>
						Upload
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	handleAddAttachment = () => {
		if (this.state.attachmentFile) {
			const reader = new FileReader();

			const metaId = this.getMetaId();
			reader.onload = () => {
				this.props.addAttachment({
					...metaId,
					file: Array.from(new Uint8Array(reader.result as ArrayBuffer)),
					fileName: this.state.attachmentFile!.name,
				});
			};
			reader.readAsArrayBuffer(this.state.attachmentFile!);
		}
		this.setState({ addAttachmentDialogIsOpen: false });
	};

	handleSaveNote = (noteContent: string) => {
		const metaId = this.getMetaId();
		const newNoteParams = {
			...metaId,
			content: noteContent,
			userId:
				this.props.userId != this.props.impersonatingId &&
					this.props.impersonatingId
					? this.props.impersonatingId
					: this.props.userId,
		};
		this.props.createNote(newNoteParams);
	};

	getCompletedActivities = () => {
		const leadStatusChangeActivities: Loaded<Activity>[] = getLeadsWithHistoryAsActivities(this.props.leads, this.props.lookups);

		const completedActivities = this.props.activities.filter(
			activity => get(() => activity.data.status!.toLowerCase() == Strings.ActivityStatus.Completed)
		).concat(leadStatusChangeActivities);

		return completedActivities;
	};

	handleNoSaleFollowUp = makeAbstractMethod('handleNoSaleFollowUp') as (status: Lookup, id: string) => void;
}

const styles = enforceStylesType({
	pointer: {
		cursor: 'pointer',
		height: '2em',
	}
});

export function mapDispatchToProps(
	dispatch: Dispatch<any>, ownProps: Props
): DispatchProps {
	return {
		navigate: (route: string): any =>
			ownProps.navigateFromParent
				? ownProps.navigateFromParent(route)
				: dispatch(navigateToRoute(route)),
		setProductTab: (tabToDisplay: AppOrPolicy) =>
			dispatch(SetProductTab({ appOrPolicy: tabToDisplay })),
		updateLead: (lead: Lead) => dispatch(UpdateLead.started(lead)),
		deleteActivity: (activityToDelete: Activity) =>
			dispatch(DeleteActivity.started(activityToDelete)),
		deleteQuoteActivity: (id: string) =>
			dispatch(DeleteQuoteActivity.started(id)),
		deleteApplication: (application: Application) =>
			dispatch(DeleteApplication.started(application)),
		addAttachment: (attachmentUpload: Attachment) =>
			dispatch(addAttachment(attachmentUpload)),
		downloadAttachment: (attachmentUpload: Attachment) => {
			dispatch(downloadAttachment(attachmentUpload));
		},
		deleteAttachment: (attachmentToDelete: Attachment) => {
			dispatch(deleteAttachment(attachmentToDelete));
		},
		deleteContact: (contact: Contact) => {
			dispatch(DeleteContact.started(contact));
		},
		getAgentWithAgentId: (id: string) => {
			dispatch(GetAgentWithAgentId.started(id));
		},
		createNote: (newNoteParams: CreateNoteParams) => {
			dispatch(createNote(newNoteParams));
		},
		deleteNote: (noteToDelete: Note) => dispatch(deleteNote(noteToDelete)),
		storeFollowupDescription: (params: FollowupFields) => {
			dispatch(StoreFollowupDescription(params));
		},
		updateNote: (updatedNote: Note) => dispatch(UpdateNote.started(updatedNote)),
		getFilteredLeads: (
			leadFilters: LeadFilters,
			pageNum: number,
			pageSize: number
		) => {
			const payload = formatLeadFiltersPayload(leadFilters, pageNum, pageSize);
			return dispatch(GetFilteredLeads.started(payload));
		},
		storeLeadRoutesIndex: (index: number) => dispatch(StoreLeadRoutesIndex({
			index,
		})),
		startCallSequence: (contacts: Contact[], hasActivities: boolean) =>
			dispatch(
				StartCallSequence({
					contacts,
					hasActivities,
				})
			),
	};
}