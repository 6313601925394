import { isType } from 'typescript-fsa';
import { isEqual } from 'lodash';
import {
	PerformanceParams,
	GetPerformanceActivity,
	GetPerformanceLeads,
	GetPerformanceProduction,
	GetPerformanceQuality,
	UpdatePerformanceFilter,
	UpdatePerformanceDates,
	SetPerformanceDownlineAgent,
	UpdatePerformanceLineOfBusiness,
	GetAllPerformanceData,
} from '../actions/performance_actions';
import { ClearCaches } from '../actions/authentication_actions';
import moment from 'moment';
import { DownlineAgent } from './product_reducer';
import { EMPTY_DOWNLINE_AGENT } from '../utilities/empty_entities';
import { Logout } from '../actions/authentication_actions';

export interface GraphDataFilterValues {
	dateRange: string;
	startDate: Date;
	endDate: Date;
	lineOfBusiness?: string;
	comparePrevious?: boolean;
}

export interface ChartGroup {
	charts: IChart[];
	summaryValue: string;
	detail: ChartDetail[];
}

export interface XAxisData {
	value: number;
	label: string;
}

export interface ChartDetail {
	label: string;
    total: string;
    secondary: string;
	data?: ChartDetail[];
}
export interface IChart {
	title: string;
	summaryValue: string;
	data: XAxisData[];
	unit?: string;
	infinityValue?: Number;
}
export interface PerformanceState {
	downlineAgent: DownlineAgent;
	filter: GraphDataFilterValues;
	activityCharts: ChartGroup;
	leadCharts: ChartGroup;
	productionCharts: ChartGroup;
	qualityCharts: ChartGroup;
	loading: boolean;
}
export const getDefaultPerformanceParams = (): PerformanceParams => ({
	startDate: moment()
		.subtract(11, 'months')
		.startOf('month')
		.toDate(),
	endDate: moment()
		.endOf('day')
		.toDate(),
	utcOffset: moment().utcOffset(),
	lineOfBusiness: 'all'
});
export const initialPerformanceFilter: GraphDataFilterValues = (() => {
	const defaultParams = getDefaultPerformanceParams();
	return {
		dateRange: 'Custom Date Range',
		startDate: defaultParams.startDate,
		endDate: defaultParams.endDate,
		lineOfBusiness: defaultParams.lineOfBusiness ? defaultParams.lineOfBusiness : 'all'
	};
})();
const initialState: PerformanceState = {
	downlineAgent: EMPTY_DOWNLINE_AGENT,
	filter: initialPerformanceFilter,
	activityCharts: { charts: [{title: '', summaryValue: '', data: []}], summaryValue: '', detail: [] },
	leadCharts: { charts: [{title: '', summaryValue: '', data: []}], summaryValue: '', detail: [] },
	productionCharts: { charts: [{title: '', summaryValue: '', data: []}], summaryValue: '', detail: [] },
	qualityCharts: { charts: [{title: '', summaryValue: '', data: []}], summaryValue: '', detail: [] },
	loading: false,
};
export const performanceReducer = (state = initialState, action: any) => {
	if (isType(action, GetPerformanceActivity.done)) {
		if (isEqual(state.activityCharts, action.payload.result)) {
			return state;
		}
		return {
			...state,
			activityCharts: action.payload.result,
		};
	}
	else if (isType(action, GetPerformanceLeads.done)) {
		if (isEqual(state.leadCharts, action.payload.result)) {
			return state;
		}
		return {
			...state,
			leadCharts: action.payload.result,
		};
	}
	else if (isType(action, GetPerformanceProduction.done)) {
		if (isEqual(state.productionCharts, action.payload.result)) {
			return state;
		}
		return {
			...state,
			productionCharts: action.payload.result,
		};
	}
	else if (isType(action, GetPerformanceQuality.done)) {
		if (isEqual(state.qualityCharts, action.payload.result)) {
			return state;
		}
		return {
			...state,
			qualityCharts: action.payload.result,
		};
	}
	else if (isType(action, GetAllPerformanceData.started)) {
		return {
			...state,
			loading: true,
		};
	}
	else if (isType(action, GetAllPerformanceData.done)) {
		return {
			...state,
			loading: false,
		};
	}
	else if (isType(action, UpdatePerformanceFilter)) {
		return {
			...state,
			filter: {
				...state.filter,
				dateRange: action.payload.filter,
			},
		};
	}
	else if (isType(action, UpdatePerformanceDates)) {
		return {
			...state,
			filter: {
				...state.filter,
				startDate: action.payload.params.startDate,
				endDate: action.payload.params.endDate,
			},
		};
	}
	else if (isType(action, UpdatePerformanceLineOfBusiness)) {
		return {
			...state,
			filter: {
				...state.filter,
				lineOfBusiness: action.payload.params.lineOfBusiness
			},
		};
	}
	else if (isType(action, SetPerformanceDownlineAgent)) {
		return {
			...state,
			downlineAgent: action.payload.agent || EMPTY_DOWNLINE_AGENT,
		};
	}
	else if (isType(action, Logout.started)) {
		return {
			...initialState,
		};
	}
	else if (isType(action, ClearCaches)) {
		return {
			...initialState,
		};
	}
	return state;
};
