import {
	GetEmployer,
	UpdateEmployer,
	DeleteEmployer,
	ReassignEmployer,
} from './../actions/employer_actions';
import {
	GetHousehold,
	ReassignHousehold,
} from './../actions/household_actions';
import { isType } from 'typescript-fsa';
import { Loaded } from './../utilities/utilities';
import { Action as ReduxAction } from 'redux';
import _ from 'lodash';
import { ClearCaches } from '../actions/authentication_actions';
import { BulkReassign } from '../actions/lead_actions';
import { Logout } from '../actions/authentication_actions';

export interface OwnershipHistory {
	entity: string;
	reassignedFrom: string;
	reassignedFromName: string;
	reassignedOn: Date;
	reassignedBy: string;
	reassignedByName: string;
}

export interface OwnershipHistoryState {
	ownershipHistories: Loaded<OwnershipHistory>[];
}

export const initialState: OwnershipHistoryState = {
	ownershipHistories: [],
};

export function ownershipHistoryReducer(
	state: OwnershipHistoryState = initialState,
	action: ReduxAction
): OwnershipHistoryState {
	if (isType(action, GetHousehold.done)) {
		const ownershipHistoriesInState = state.ownershipHistories.slice();
		const householdId = action.payload.result.householdId;
		updateStateWithInboundHistory(
			ownershipHistoriesInState,
			action.payload.result.ownershipHistory,
			householdId,
			undefined
		);
		return {
			...state,
			ownershipHistories: ownershipHistoriesInState,
		};
	} else if (
		isType(action, GetEmployer.done) ||
		isType(action, UpdateEmployer.done)
	) {
		const ownershipHistoriesInState = state.ownershipHistories.slice();
		const employerId = action.payload.result.id;
		updateStateWithInboundHistory(
			ownershipHistoriesInState,
			action.payload.result.ownershipHistory,
			undefined,
			employerId
		);
		return {
			...state,
			ownershipHistories: ownershipHistoriesInState,
		};
	} else if (isType(action, DeleteEmployer.done)) {
		const ownershipHistoriesInState = state.ownershipHistories.filter(
			history => history.employerId !== action.payload.params
		);
		return {
			...state,
			ownershipHistories: ownershipHistoriesInState,
		};
	} else if (isType(action, ReassignEmployer.done)) {
		const ownershipHistoriesInState = state.ownershipHistories.filter(
			history => history.employerId !== action.payload.params.employerId
		);
		return {
			...state,
			ownershipHistories: ownershipHistoriesInState,
		};
	} else if (isType(action, BulkReassign.done)) {
		const ownershipHistoriesInState = state.ownershipHistories.slice();
		const filteredOwnershipHistories = ownershipHistoriesInState.filter(
			ownershipHistory =>
				action.payload.params.entities.some(
					entity =>
						entity.id != ownershipHistory.householdId &&
						entity.id != ownershipHistory.employerId
				)
		);
		return {
			...state,
			ownershipHistories: filteredOwnershipHistories,
		};
	} else if (isType(action, ReassignHousehold.done)) {
		const ownershipHistoriesInState = state.ownershipHistories.filter(
			history => history.householdId !== action.payload.params.householdId
		);
		return {
			...state,
			ownershipHistories: ownershipHistoriesInState,
		};
	} else if (isType(action, Logout.started)) {
		return {
			...initialState,
		};
	} else if (isType(action, ClearCaches)) {
		return {
			...initialState,
		};
	} else {
		return state;
	}
}

function updateStateWithInboundHistory(
	ownershipHistoriesInState: Loaded<OwnershipHistory>[],
	inboundHistories: OwnershipHistory[],
	householdId?: string,
	employerId?: string
) {
	inboundHistories.forEach(ownershipHistory => {
		const index = _.findIndex(
			ownershipHistoriesInState,
			cachedHistory =>
				cachedHistory.data.reassignedOn === ownershipHistory.reassignedOn
		);

		if (index > -1) {
			ownershipHistoriesInState[index] = {
				...ownershipHistoriesInState[index],
				data: ownershipHistory,
				loading: false,
				employerId: employerId,
				householdId: householdId,
				errors: [],
			};
		} else {
			const newOwnershipHistory = {
				data: ownershipHistory,
				loading: false,
				employerId: employerId,
				householdId: householdId,
				errors: [],
			};
			ownershipHistoriesInState.push(newOwnershipHistory);
		}
	});
}
