export const agentConnectTerms = "<p><u>Terms of Use</u></p>" +
"\n<p>Set forth below are the Terms and Conditions that govern your use of the Agent Connect application and/or website (the \"App\"). These Terms and Conditions describe your rights and obligations with respect to the App and should be read carefully. By logging onto using the App, you confirm that you have read these Terms and Conditions, and you agree to be bound by them. If you do not agree to these Terms and Conditions, you should exit from the App immediately and you do not have permission to use or access the App. Your use of the App is also governed by the Privacy Policy (the \"Privacy Policy\"), which is incorporated herein by reference.</p>"
+"\n<p>The Terms and Conditions and Privacy Policy set forth the legal terms and conditions governing your use of the App. Your use of the App confirms your unconditional agreement to be bound by the Terms and Conditions and Privacy Policy. If you do not agree to be bound, please do not use the App. Please read the Terms and Conditions and Privacy Policy carefully as they contain important information, including how any disputes between you and HealthMarkets Insurance Agency, Inc. (the \"Company\") must be resolved through arbitration or small claims court.</p>"
+"\n<p>Any client-specific information that is entered into and generated by the App, including online and/or printed proposals, is confidential and, with the exception of Company personnel and other parties authorized by the Company, may only be accessed by the user from whom the information originated.</p>"
+"\n<p>1.	Copyright and Intellectual Property Rights</p>"
+"\n<p>This App and all of its contents, including but not limited to, text and graphics (collectively, \"Content\"), are subject to copyright laws and are owned by the Company, its subsidiaries and/or affiliates. You also agree to review and abide by the Company's policies regarding intellectual property rights, which include both copyright and trademark policies. </p>"
+"\n<p>2.	Indemnification</p>"
+"\n<p>You agree to indemnify, defend and hold harmless the Company, its parents, subsidiaries, affiliates, officers, directors, employees and agents (collectively, the \"Indemnified Parties\") from and against all claims, losses, damages, liabilities and judgments, and all fees and expenses related thereto (including, without limitation, reasonable legal fees) incurred by an Indemnified Party as a result of any violation by you of your agreements with us.</p>"
+"\n<p>3.	Disclaimer of Warranties</p>"
+"\n<p>THE USE OF THE APP, THE CONTENT OR INFORMATION CONTAINED ON THE APP IS PROVIDED SOLELY AT YOUR OWN RISK. THE APP IS PROVIDED ON AN \"AS IS\" AND \"AS AVAILABLE\" BASIS. THE COMPANY EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND WITH RESPECT TO THE APP AND ITS CONTENT, WHETHER EXPRESS OR IMPLIED, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. THE COMPANY MAKES NO WARRANTY THAT THE APP AND/OR ANY CONTENT THEREIN WILL MEET YOUR REQUIREMENTS, OR WILL BE UNINTERRUPTED, TIMELY, SECURE, CURRENT, ACCURATE, COMPLETE OR ERROR-FREE OR THAT THE RESULTS THAT MAY BE OBTAINED BY USE OF THE APP AND/OR ANY CONTENT THEREIN WILL BE ACCURATE OR RELIABLE. YOU UNDERSTAND AND ACKNOWLEDGE THAT YOUR SOLE AND EXCLUSIVE REMEDY WITH RESPECT TO ANY DEFECT IN OR DISSATISFACTION WITH THE APP IS TO CEASE USING THE APP.</p>"
+"\n<p>4.	Products/Services Illustrated on App</p>"
+"\n<p>Nothing within the App, nor any proposals or any other materials it generates, should be deemed or represented as a contract for coverage or a solicitation of an application for coverage. The insurance quotations obtained by use of this App do not constitute an offer of insurance and are subject to the approval of the respective insurance providers. No contract for the provision of a policy of insurance is formed by the use of this App. The Company does not have the authority to bind and/or approve any application for coverage.</p>"
+"\n<p>5.	Prohibited Actions:</p>"
+"\n<ul>"
+"\n<li>Copying/duplicating any information presented on the App or creating subsets or derivative databases it, except for personal use only.</li>"
+"\n<li>Assigning, selling or passing along any information presented on the App.</li>"
+"\n<li>Publishing or otherwise disseminating any information presented on the App or creating subsets of derivative databases of it for commercial use or sale.</li>"
+"\n<li>Unauthorized distribution of user names, passwords and/or access codes is strictly prohibited.</li>"
+"\n<li>Adding, removing, or modifying identifying network header information (aka “spoofing”) in an effort to deceive or mislead</li>"
+"\n<li>Attempting to impersonate any person by using forged headers or other identifying information.</li>"
+"\n<li>Any activities which adversely affect the ability of other people or systems to use the Company’s services or the internet are prohibited.  This includes “denial of service” attacks against the Company’s servers, network hosts or individual user.</li>"
+"\n<li>Sending unsolicited commercial email.  Using a Company email or website address to distribute commercial email is prohibited.  </li>"
+"\n<li>Attempting to circumvent user authentication or security of any host, network or account (aka “cracking”) is strictly prohibited.  This includes, but is not limited to, accessing data not intended for the customer, logging into a server or account the customer is not expressly authorized to access, or probing the security of Company servers and networks.</li>"
+"\n<li>Using any program/script/command, or sending messages of any kind, designed to interfere with a user’s session, by any means, locally or by the internet.</li>"
+"\n</ul>"
+"\n<p>The Company reserves the right to monitor access to the App by authorized users, as part of the normal course of its business practice.  Should the Company discover users engaged in prohibited actions outlined above, the Company reserves the right to temporarily or permanently suspend such user’s access to the App.</p>"
+"\n<p>6.	Limitation of Liability</p>"
+"\n<p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE COMPANY SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA LOSS, OR OTHER LOSSES (EVEN IF THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES) RESULTING FROM:</p>"
+"\n<ul>"
+"\n<li>THE USE OR INABILITY TO USE THE APP, INCLUDING, WITHOUT LIMITATION, WHETHER CAUSED BY A COMPUTER VIRUS, SOFTWARE BUG, HUMAN ACTION OR INACTION, MALFUNCTIONS OF ANY HARDWARE, SOFTWARE OR OTHER ELEMENT OF A COMPUTER SYSTEM, ERRORS, FAILURE, MALFUNCTION OR DELAY IN COMPUTER TRANSMISSION OR NETWORK CONNECTIONS.</li>"
+"\n<li>THE COST OF PROCUREMENT OF ANY SUBSTITUTE PRODUCTS AND/OR SERVICES RESULTING FROM ANY PRODUCTS, DATA, INFORMATION OR SERVICES OBTAINED OR WHICH YOU WERE UNABLE TO OBTAIN OR TRANSACTIONS AFFECTED OR FAILED TO BE AFFECTED,</li>"
+"\n<li>ANY LINK PROVIDED IN CONNECTION WITH THE APP, OR</li>"
+"\n<li>ANY MATTER OTHERWISE RELATED TO YOUR USE OF THE APP.</li>"
+"\n</ul>"
+"\n<p></p>"
+"\n<p>7.	Governing Law, Personal Jurisdiction and Other Matters</p>"
+"\n<ul>"
+"\n<li>You are responsible for compliance with all local laws, if and to the extent your local laws are applicable.</li>"
+"\n<li>These Terms and Conditions and the relationship between you and the Company shall be governed and construed in accordance with the laws of the State of Texas applicable to contracts entered into and fully performed in Texas (without regard to its conflicts of law principles that would cause the application of any other jurisdiction's laws). With respect to any disputes or claims not subject to arbitration, you agree not to commence or prosecute any action in connection therewith other than in the state and federal courts located in the State of Texas, and you hereby consent to personal jurisdiction, and waive all defenses of lack of personal jurisdiction and forum non convenience with respect to venue and jurisdiction, in the state and federal courts therein.</li>"
+"\n<li>Additionally, you agree that regardless of any statute or law to the contrary, any dispute arising out of or related to the App or these Terms and Conditions must be filed within one year after such claim or cause of action arose.</li>"
+"\n</ul>"
+"\n<p></p>"
+"\n<p>8.	ARBITRATION AND CLASS ACTION WAIVER</p>"
+"\n<p>By using the App in any way, you unconditionally consent and agree that:</p>"
+"\n<ul>"
+"\n<li>Any claim, dispute, or controversy (whether in contract, tort, or otherwise) you may have against the officers, directors and employees of the Company and its parent, subsidiaries, affiliates (all such individuals and entities collectively referred to herein as the \"Company Entities\") arising out of, relating to, or connected in any way with the App or the determination of the scope or applicability of this agreement to arbitrate, will be resolved exclusively by final and binding arbitration administered by JAMS and conducted before a sole arbitrator in accordance with the rules of JAMS.</li>"
+"\n<li>This arbitration agreement is made pursuant to a transaction involving interstate commerce, and shall be governed by the Federal Arbitration Act (\"FAA\"), 9 U.S.C. §§ 1-16.</li>"
+"\n<li>The arbitrator's decision shall be controlled by the Terms and Conditions and any of the other agreements referenced herein that the applicable user may have entered into in connection with the App. </li>"
+"\n<li>The arbitrator shall apply Texas law consistent with the FAA and applicable statutes of limitations, and shall honor claims of privilege recognized at law. </li>"
+"\n<li>There shall be no authority for any claims to be arbitrated on a class or representative basis; arbitration can decide only your and/or the Company Entities’ individual claims; the arbitrator may not consolidate or join the claims of other persons or parties who may be similarly situated. </li>"
+"\n<li>The arbitrator may award declaratory or injunctive relief only in favor of the individual party seeking relief and only to the extent necessary to provide relief warranted by that party's individual claim without affecting other individuals. </li>"
+"\n<li>The arbitrator shall not have the power to award punitive damages against you or the Company Entities.</li>"
+"\n<li>In the event that the administrative fees and deposits that must be paid to initiate arbitration against the Company Entities exceed $125 USD, and you are unable (or not required under the rules of JAMS) to pay any fees and deposits that exceed this amount, the Company agrees to pay them and/or forward them on your behalf, subject to ultimate allocation by the arbitrator. In addition, if you are able to demonstrate that the costs of arbitration will be prohibitive as compared to the costs of litigation, the Company will pay as much of your filing and hearing fees in connection with the arbitration as the arbitrator deems necessary to prevent the arbitration from being cost-prohibitive.  </li>"
+"\n<li>With the exception of class certification, if any part of this arbitration provision is deemed to be invalid, unenforceable or illegal, or otherwise conflicts with the rules of JAMS, then the balance of this arbitration provision shall remain in effect and shall be construed in accordance with its terms as if the invalid, unenforceable, illegal or conflicting provision were not contained herein. If, however, the subpart dealing with class certification is found to be invalid, unenforceable or illegal, then the entirety of this Arbitration Provision shall be null and void, and neither you nor the Company Entities shall be entitled to arbitrate their dispute. </li>"
+"\n<li>For more information on JAMS and/or the rules of JAMS, visit their website at www.jamsadr.com.</li>"
+"\n</ul>"
+"\n<p>ACCORDINGLY, YOU ACKNOWLEDGE THAT YOU MAY NOT HAVE ACCESS TO A COURT, TO CLASS OR REPRESENTATIVE PROCEDURES, OR TO A JURY TRIAL. Notwithstanding any other provision of the Terms and Conditions, the Company Entities may resort to court action for injunctive relief at any time. Further, notwithstanding any other provision of the Terms and Conditions, you and the Company Entities may mutually agree to proceed in small claims court.</p>"
+"\n<p>9.	CHANGES TO THE APP AND/OR TERMS AND CONDITIONS</p>"
+"\n<p>You acknowledge and agree that the Company may, in its sole discretion, modify, add or remove any portion of these Terms and Conditions at any time and in any manner. If the Company believes, in its sole discretion, a revision is material, the Company will notify you. No changes to these Terms and Conditions occurring after a dispute between you and the Company arises will apply retroactively. It is your responsibility to check periodically for any changes we make to the Terms and Conditions. Your continued use of this App after any changes to the Terms and Conditions means you accept the changes.</p>"
+"\n<p>10.	EXTERNAL LINK DISCLAIMER</p>"
+"\n<p>All information provided on our App is provided for informational purposes only and is subject to change without prior notice. The App may contain information that is created and maintained by a variety of sources both internal and external to the Company. Hypertext links to other third party websites are for the convenience of our App users and the Company does not control, monitor or guarantee the information contained on those websites or in links to other external websites. The Company does not endorse any views expressed on those websites or the products or services offered therein. The fact that we have made these links available to you is not an endorsement or recommendation by the Company to you of any of these websites, any third party or any material found there. Such links do not constitute an endorsement by any third party of the Company or its services, and do not indicate that there is necessarily any relationship between the Company and these third parties. Any questions should be directed to the administrator(s) of this or any other specific website. In no event shall the Company be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such website or resource.</p>"
+"\n<p>11.	Electronic Signatures and System Requirements</p>"
+"\n<p>By logging onto the App you have signified your agreement to all of the terms and conditions to use the App, and shall have the same binding effect as it would had you signed the same agreement on paper. If you would like a paper copy of this agreement, you may obtain one by submitting a written request to:</p>"
+"\n<p>HealthMarkets Insurance Agency, Inc.</p>"
+"\n<p>9151 Boulevard 26</p>"
+"\n<p>North Richland Hills, Texas 76180</p>"
+"\n<p>Attention: Legal Department</p>"
+"\n<p>102.	Miscellaneous</p>"
+"\n<ul>"
+"\n<li>You agree that the Company shall have no liability for the deletion or failure to store any messages and other communications or other content maintained on the App or transmitted using the App.</li>"
+"\n<li>These Terms and Conditions constitute the entire agreement between you and the Company with respect to the App and govern your use of the App. You also may be subject to additional terms and conditions that may apply when you use third party content or websites available through the App.</li>"
+"\n<li>If any provision of these Terms and Conditions is found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms and Conditions, which shall remain in full force and effect.</li>"
+"\n<li>The failure of the Company to exercise or enforce any right or provision of these Terms and Conditions shall not constitute a waiver of such right or provision.</li>"
+"\n</ul>"
+"\n<p></p>"
+"\n<p>Privacy Policy</p>"
+"\n<p>Your privacy and the privacy of all users of the Agent Connect application and/or website (the \"App\") are very important to us. We have adopted the following Privacy Policy. This Privacy Policy relates only to personal information that we collect through the App or online.</p>"
+"\n<p>1.	NOTICE OF INFORMATION COLLECTED AND DISCLOSED</p>"
+"\n<p>This Privacy Policy tells you, among other things, what information we gather from you through our App, how we may use or disclose that information, and our efforts to protect it.</p>"
+"\n<p>In connection with your use of our App, we may collect the following information:</p>"
+"\n<ul>"
+"\n<li>The domain name, but not the e-mail address, of visitors to our App.</li>"
+"\n<li>The e-mail addresses of users who communicate with us via e-mail.</li>"
+"\n<li>Aggregate information on what pages on our App users access or visit.</li>"
+"\n<li>Information volunteered by the user, such as site registration, e-mail update requests and product or service related inquiries.</li>"
+"\n<li>Information about your transactions with us or others.</li>"
+"\n</ul>"
+"\n<p>We use the personally identifying information you voluntarily provide to register you as a user of the App, to administer App usage and monitor traffic-levels, and to contact you regarding improvements we can make to the  App and our services, if necessary. We may also use the information you provide to contact you directly regarding services, products or information that we believe may be of interest to you, to support your relationship with us, and improve your experience on the App.  We may make registration records and log file available on confidential basis to third parties such as carriers, select measurement and analysis firms, auditor or data storage firms.  In the future, we may use personally identifying information you voluntarily provide to register you as a user of the App, to administer App usage and monitor traffic-levels, and to contact you regarding improvements we can make to the  App and our services, if necessary. Except as disclosed in this Privacy Policy, we will not use your information for any purpose that you have not expressly authorized.</p>"
+"\n<p>From time to time, we may also disclose information to fulfill certain legal and regulatory requirements or if we believe, in good faith, that such disclosure is legally required or necessary to protect others' rights or to prevent harm.</p>"
+"\n<p>1.	CHOICE/OPTING-OUT</p>"
+"\n<p>You may choose to restrict our use of your information. If you are a user of our App or services, you may restrict distribution of your information by indicating such restrictions in your account when asked. On our App, you have the option to remove your name and personal information from any information that will or may be disclosed to a third party although disclosures to law enforcement or to meet other legal requirements may still be required.</p>"
+"\n<p>You may exercise your choice to \"opt-out\" of providing personal information in many ways. When selecting certain services or features of our App, you can choose whether to share personal information with respect to the particular service or feature.</p>"
+"\n<p>You may also \"opt-out\" entirely from providing personal information to us by not providing information or registering on our App. You may also choose at a later date to have your name removed from our database. However, certain sections of our App do require registration in order to use these sections and services. If you choose not to register or provide certain information, these sections will not be available for your use.</p>"
+"\n<p>2.	COLLECTION AND USE OF THIRD PARTY PII</p>"
+"\n<p>We provide you the functionality to enter your clients’ information on the App and generate proposals and quotes through our system.  This third party personally identifiable information, such as their name, date of birth and gender, along with sensitive information such as their medical information, is only used for the sole purpose of completing your request or for whatever reason it may have been provided, and is only disclosed to a service provider when you select to request a quote on the App.</p>"
+"\n<p>3.	ONWARD TRANSFER (TRANSFERS TO THIRD PARTIES)</p>"
+"\n<p>If we propose to disclose your information to a third party that is acting as our agent or on our behalf, we will ensure that the third party has agreed to assure adequate protection of your information.</p>"
+"\n<p>4.	SECURITY</p>"
+"\n<p>Your account containing your information is password-protected. Only those who log on with your password can access your account. We have taken appropriate security measures to protect your personal information from loss, misuse and unauthorized access, disclosure, alteration and destruction. However, please remember that you play a valuable part in security as well. You are responsible for protecting your password for accessing your account. If you provide any information to parties who operate websites that are linked to or from our App, different rules may apply to their collection, use, or disclosure of your personal information. We encourage you to review the privacy policies of such other sites before revealing any sensitive or personal information. Regardless of the precautions taken by you or by us, \"perfect security\" does not exist on the Internet. We cannot ensure or warrant the ultimate security of any information you transmit.</p>"
+"\n<p>5.	COOKIES</p>"
+"\n<p>A cookie is a small data file that certain websites write to your computer's hard drive when you visit those sites. A cookie file can contain information such as a user ID that a website uses to track the pages you have visited. A persistent cookie allows the App to remember you on subsequent visits. We do set persistent cookies.</p>"
+"\n<p>1.	AD SERVERS</p>"
+"\n<p>We use a third party provider to place our internet ads. The provider may collect anonymous information about your visits to the App and your interaction with our products and services. The provider may also use information about your visits to the App and other websites to target advertisements for merchandise and services. This anonymous information is collected through the use of a pixel tag which is industry standard technology used by most major websites. No personally identifiable information is collected or used in this process. The provider does not know your name, address, phone number, email address or any of your other personally identifiable information. If you would like more information about this practice and your option to not have this anonymous information used by our third party provider, click here. To opt-out of these ads click on this link.</p>"
+"\n<p>6.	CHILDREN'S PRIVACY</p>"
+"\n<p>We are concerned about the safety of children and their use of the Internet. This App is not directed at children under the age of 18 and we do not knowingly receive, collect or use any personal or health-related information or data from children under the age of 18 for any purpose, including for any marketing purpose without prior parental consent. As used in these terms and conditions: (i) personal information includes any individually identifiable information, such as name, address, social security number, email address, or any combination of identifiers, and (ii) health information includes any information relating to health, nutrition, drug use, physical condition, mental health, medical history, or medical insurance coverage. If we receive actual knowledge that we have received any such personal information without the required verifiable parental consent, we will delete the information from our database as soon as we practically can.</p>"
+"\n<p>7.	LINKING TO OTHER WEBSITES</p>"
+"\n<p>Please remember that when you use a link to go from the App to another website, this Privacy Policy is no longer in effect. Your browsing and interaction on any other website, including websites that have a link on the App, are subject to the terms, conditions and policies of that website. Please read those terms, conditions and policies before proceeding.</p>"
+"\n<p>8.	CHANGES IN OWNERSHIP</p>"
+"\n<p>In the event that our ownership were to change as a result of a merger, acquisition or transfer to another company, personal information that you provide to us through this App may be transferred. If such a transfer results in a material change in the use of your personal information, we will provide notice about your choices to decline to permit such a transfer.</p>"
+"\n<p>9.	EFFECTIVE DATE AND FUTURE CHANGES</p>"
+"\n<p>This Privacy Policy is effective as of February __, 2018. You acknowledge and agree that we may, in our sole discretion, modify, add or remove any portion of this Privacy Policy at any time and in any manner. If a revision, in our sole discretion, is material, we will notify you. No changes to this Privacy Policy occurring after a dispute between you and us arises will apply retroactively. It is your responsibility to check periodically for any changes we make to the Privacy Policy. Your continued use of this App after any changes to the Privacy Policy means you accept the changes.</p>"

export const brokerConnectTerms = "<p><u>Terms of Use</u></p>"
+"\n<p>Set forth below are the Terms and Conditions that govern your use of the BrokerConnect application and/or website (the \"App\"). These Terms and Conditions describe your rights and obligations with respect to the App and should be read carefully. By using the App, you confirm that you have read these Terms and Conditions, and you agree to be bound by them. Your use of the App is also governed by the Privacy Policy (the \"Privacy Policy\"), which is incorporated herein by reference.</p>"
+"\n<p>The Terms and Conditions and Privacy Policy set forth the legal terms and conditions governing your use of the App. Your use of the App confirms your unconditional agreement to be bound by the Terms and Conditions and Privacy Policy. If you do not agree to be bound, please do not use the App. Please read the Terms and Conditions and Privacy Policy carefully as they contain important information, including how any disputes between you and HealthMarkets Insurance Agency, Inc. (the \"Company\") must be resolved through arbitration or small claims court.<p>"
+"\n<p>Any client-specific information that is entered into and generated by the App, including online and/or printed proposals, is confidential and, with the exception of Company personnel and other parties authorized by the Company, may only be accessed by the user from whom the information originated.</p>"
+"\n<p>1.	Copyright and Intellectual Property Rights</p>"
+"\n<p>This App and all of its contents, including but not limited to, text and graphics (collectively, \"Content\"), are subject to copyright laws and are owned by the Company, its subsidiaries and/or affiliates. You also agree to review and abide by the Company's policies regarding intellectual property rights, which include both copyright and trademark policies. </p>"
+"\n<p>2.	Indemnification</p>"
+"\n<p>You agree to indemnify, defend and hold harmless the Company, its parents, subsidiaries, affiliates, officers, directors, employees and agents (collectively, the \"Indemnified Parties\") from and against all claims, losses, damages, liabilities and judgments, and all fees and expenses related thereto (including, without limitation, reasonable legal fees) incurred by an Indemnified Party as a result of any violation by you of your agreements with us.</p>"
+"\n<p>3.	Disclaimer of Warranties</p>"
+"\n<p>THE USE OF THE APP, THE CONTENT OR INFORMATION CONTAINED ON THE APP IS PROVIDED SOLELY AT YOUR OWN RISK. THE APP IS PROVIDED ON AN \"AS IS\" AND \"AS AVAILABLE\" BASIS. THE COMPANY EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND WITH RESPECT TO THE APP AND ITS CONTENT, WHETHER EXPRESS OR IMPLIED, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. THE COMPANY MAKES NO WARRANTY THAT THE APP AND/OR ANY CONTENT THEREIN WILL MEET YOUR REQUIREMENTS, OR WILL BE UNINTERRUPTED, TIMELY, SECURE, CURRENT, ACCURATE, COMPLETE OR ERROR-FREE OR THAT THE RESULTS THAT MAY BE OBTAINED BY USE OF THE APP AND/OR ANY CONTENT THEREIN WILL BE ACCURATE OR RELIABLE. YOU UNDERSTAND AND ACKNOWLEDGE THAT YOUR SOLE AND EXCLUSIVE REMEDY WITH RESPECT TO ANY DEFECT IN OR DISSATISFACTION WITH THE APP IS TO CEASE USING THE APP.</p>"
+"\n<p>4.	Products/Services Illustrated on App</p>"
+"\n<p>Nothing within the App, nor any proposals or any other materials it generates, should be deemed or represented as a contract for coverage or a solicitation of an application for coverage. The insurance quotations obtained by use of this App do not constitute an offer of insurance and are subject to the approval of the respective insurance providers. No contract for the provision of a policy of insurance is formed by the use of this App. The Company does not have the authority to bind and/or approve any application for coverage.</p>"
+"\n<p>5.	Prohibited Actions:</p>"
+"\n<ul>"
+"\n<li>Copying/duplicating any information presented on the App or creating subsets or derivative databases it, except for personal use only.</li>"
+"\n<li>Assigning, selling or passing along any information presented on the App.</li>"
+"\n<li>Publishing or otherwise disseminating any information presented on the App or creating subsets of derivative databases of it for commercial use or sale.</li>"
+"\n<li>Unauthorized distribution of user names, passwords and/or access codes is strictly prohibited.</li>"
+"\n<li>Adding, removing, or modifying identifying network header information (aka “spoofing”) in an effort to deceive or mislead</li>"
+"\n<li>Attempting to impersonate any person by using forged headers or other identifying information.</li>"
+"\n<li>Any activities which adversely affect the ability of other people or systems to use the Company’s services or the internet are prohibited.  This includes “denial of service” attacks against the Company’s servers, network hosts or individual user.</li>"
+"\n<li>Sending unsolicited commercial email.  Using a Company email or website address to distribute commercial email is prohibited.  </li>"
+"\n<li>Attempting to circumvent user authentication or security of any host, network or account (aka “cracking”) is strictly prohibited.  This includes, but is not limited to, accessing data not intended for the customer, logging into a server or account the customer is not expressly authorized to access, or probing the security of Company servers and networks.</li>"
+"\n<li>Using any program/script/command, or sending messages of any kind, designed to interfere with a user’s session, by any means, locally or by the internet.</li>"
+"\n</ul>"
+"\n<p>The Company reserves the right to monitor access to the App by authorized users, as part of the normal course of its business practice.  Should the Company discover users engaged in prohibited actions outlined above, the Company reserves the right to temporarily or permanently suspend such user’s access to the App.<p>"
+"\n<p>6.	Limitation of Liability<p>"
+"\n<p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE COMPANY SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA LOSS, OR OTHER LOSSES (EVEN IF THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES) RESULTING FROM:<p>"
+"\n<ul>"
+"\n<li>THE USE OR INABILITY TO USE THE APP, INCLUDING, WITHOUT LIMITATION, WHETHER CAUSED BY A COMPUTER VIRUS, SOFTWARE BUG, HUMAN ACTION OR INACTION, MALFUNCTIONS OF ANY HARDWARE, SOFTWARE OR OTHER ELEMENT OF A COMPUTER SYSTEM, ERRORS, FAILURE, MALFUNCTION OR DELAY IN COMPUTER TRANSMISSION OR NETWORK CONNECTIONS.</li>"
+"\n<li>THE COST OF PROCUREMENT OF ANY SUBSTITUTE PRODUCTS AND/OR SERVICES RESULTING FROM ANY PRODUCTS, DATA, INFORMATION OR SERVICES OBTAINED OR WHICH YOU WERE UNABLE TO OBTAIN OR TRANSACTIONS AFFECTED OR FAILED TO BE AFFECTED,</li>"
+"\n<li>ANY LINK PROVIDED IN CONNECTION WITH THE APP, OR</li>"
+"\n<li>ANY MATTER OTHERWISE RELATED TO YOUR USE OF THE APP.</li>"
+"\n</ul>"
+"\n<p></p>"
+"\n<p>7.	Governing Law, Personal Jurisdiction and Other Matters</p>"
+"\n<ul>"
+"\n<li>You are responsible for compliance with all local laws, if and to the extent your local laws are applicable.</li>"
+"\n<li>These Terms and Conditions and the relationship between you and the Company shall be governed and construed in accordance with the laws of the State of Texas applicable to contracts entered into and fully performed in Texas (without regard to its conflicts of law principles that would cause the application of any other jurisdiction's laws). With respect to any disputes or claims not subject to arbitration, you agree not to commence or prosecute any action in connection therewith other than in the state and federal courts located in the State of Texas, and you hereby consent to personal jurisdiction, and waive all defenses of lack of personal jurisdiction and forum non convenience with respect to venue and jurisdiction, in the state and federal courts therein.</li>"
+"\n<li>Additionally, you agree that regardless of any statute or law to the contrary, any dispute arising out of or related to the App or these Terms and Conditions must be filed within one year after such claim or cause of action arose.</li>"
+"\n</ul>"
+"\n<p></p>"
+"\n<p>8.	ARBITRATION AND CLASS ACTION WAIVER</p>"
+"\n<p>By using the App in any way, you unconditionally consent and agree that:</p>"
+"\n<ul>"
+"\n<li>Any claim, dispute, or controversy (whether in contract, tort, or otherwise) you may have against the officers, directors and employees of the Company and its parent, subsidiaries, affiliates (all such individuals and entities collectively referred to herein as the \"Company Entities\") arising out of, relating to, or connected in any way with the App or the determination of the scope or applicability of this agreement to arbitrate, will be resolved exclusively by final and binding arbitration administered by JAMS and conducted before a sole arbitrator in accordance with the rules of JAMS.</li>"
+"\n<li>This arbitration agreement is made pursuant to a transaction involving interstate commerce, and shall be governed by the Federal Arbitration Act (\"FAA\"), 9 U.S.C. §§ 1-16.</li>"
+"\n<li>The arbitrator's decision shall be controlled by the Terms and Conditions and any of the other agreements referenced herein that the applicable user may have entered into in connection with the App. </li>"
+"\n<li>The arbitrator shall apply Texas law consistent with the FAA and applicable statutes of limitations, and shall honor claims of privilege recognized at law. </li>"
+"\n<li>There shall be no authority for any claims to be arbitrated on a class or representative basis; arbitration can decide only your and/or the Company Entities’ individual claims; the arbitrator may not consolidate or join the claims of other persons or parties who may be similarly situated. </li>"
+"\n<li>The arbitrator may award declaratory or injunctive relief only in favor of the individual party seeking relief and only to the extent necessary to provide relief warranted by that party's individual claim without affecting other individuals. </li>"
+"\n<li>The arbitrator shall not have the power to award punitive damages against you or the Company Entities.</li>"
+"\n<li>In the event that the administrative fees and deposits that must be paid to initiate arbitration against the Company Entities exceed $125 USD, and you are unable (or not required under the rules of JAMS) to pay any fees and deposits that exceed this amount, the Company agrees to pay them and/or forward them on your behalf, subject to ultimate allocation by the arbitrator. In addition, if you are able to demonstrate that the costs of arbitration will be prohibitive as compared to the costs of litigation, the Company will pay as much of your filing and hearing fees in connection with the arbitration as the arbitrator deems necessary to prevent the arbitration from being cost-prohibitive.  </li>"
+"\n<li>With the exception of class certification, if any part of this arbitration provision is deemed to be invalid, unenforceable or illegal, or otherwise conflicts with the rules of JAMS, then the balance of this arbitration provision shall remain in effect and shall be construed in accordance with its terms as if the invalid, unenforceable, illegal or conflicting provision were not contained herein. If, however, the subpart dealing with class certification is found to be invalid, unenforceable or illegal, then the entirety of this Arbitration Provision shall be null and void, and neither you nor the Company Entities shall be entitled to arbitrate their dispute. </li>"
+"\n<li>For more information on JAMS and/or the rules of JAMS, visit their website at www.jamsadr.com.</li>"
+"\n</ul>"
+"\n<p>ACCORDINGLY, YOU ACKNOWLEDGE THAT YOU MAY NOT HAVE ACCESS TO A COURT, TO CLASS OR REPRESENTATIVE PROCEDURES, OR TO A JURY TRIAL. Notwithstanding any other provision of the Terms and Conditions, the Company Entities may resort to court action for injunctive relief at any time. Further, notwithstanding any other provision of the Terms and Conditions, you and the Company Entities may mutually agree to proceed in small claims court.</p>"
+"\n<p>9.	CHANGES TO THE APP AND/OR TERMS AND CONDITIONS</p>"
+"\n<p>You acknowledge and agree that the Company may, in its sole discretion, modify, add or remove any portion of these Terms and Conditions at any time and in any manner. If the Company believes, in its sole discretion, a revision is material, the Company will notify you. No changes to these Terms and Conditions occurring after a dispute between you and the Company arises will apply retroactively. It is your responsibility to check periodically for any changes we make to the Terms and Conditions. Your continued use of this App after any changes to the Terms and Conditions means you accept the changes.</p>"
+"\n<p>10.	EXTERNAL LINK DISCLAIMER</p>"
+"\n<p>All information provided on our App is provided for informational purposes only and is subject to change without prior notice. The App may contain information that is created and maintained by a variety of sources both internal and external to the Company. Hypertext links to other third party websites are for the convenience of our App users and the Company does not control, monitor or guarantee the information contained on those websites or in links to other external websites. The Company does not endorse any views expressed on those websites or the products or services offered therein. The fact that we have made these links available to you is not an endorsement or recommendation by the Company to you of any of these websites, any third party or any material found there. Such links do not constitute an endorsement by any third party of the Company or its services, and do not indicate that there is necessarily any relationship between the Company and these third parties. Any questions should be directed to the administrator(s) of this or any other specific website. In no event shall the Company be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such website or resource.</p>"
+"\n<p>11.	Electronic Signatures and System Requirements</p>"
+"\n<p>By logging onto the App you have signified your agreement to all of the terms and conditions to use the App, and shall have the same binding effect as it would had you signed the same agreement on paper. If you would like a paper copy of this agreement, you may obtain one by submitting a written request to:</p>"
+"\n<p>HealthMarkets Insurance Agency, Inc.</p>"
+"\n<p>9151 Boulevard 26</p>"
+"\n<p>North Richland Hills, Texas 76180</p>"
+"\n<p>Attention: Legal Department</p>"
+"\n<p>12.	Miscellaneous</p>"
+"\n<ul>"
+"\n<li>You agree that the Company shall have no liability for the deletion or failure to store any messages and other communications or other content maintained on the App or transmitted using the App.</li>"
+"\n<li>These Terms and Conditions constitute the entire agreement between you and the Company with respect to the App and govern your use of the App. You also may be subject to additional terms and conditions that may apply when you use third party content or websites available through the App.</li>"
+"\n<li>If any provision of these Terms and Conditions is found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms and Conditions, which shall remain in full force and effect.</li>"
+"\n<li>The failure of the Company to exercise or enforce any right or provision of these Terms and Conditions shall not constitute a waiver of such right or provision.</li>"
+"\n</ul>"
+"\n<p></p>"
+"\n<p>Privacy Policy</p>"
+"\n<p>Your privacy and the privacy of all users of the BrokerConnect application and/or website (the \"App\") are very important to us. We have adopted the following Privacy Policy. This Privacy Policy relates only to personal information that we collect through the App or online.</p>"
+"\n<p>1.	NOTICE OF INFORMATION COLLECTED AND DISCLOSED</p>"
+"\n<p>This Privacy Policy tells you, among other things, what information we gather from you through our App, how we may use or disclose that information, and our efforts to protect it.</p>"
+"\n<p>In connection with your use of our App, we may collect the following information:</p>"
+"\n<ul>"
+"\n<li>The domain name, but not the e-mail address, of visitors to our App.</li>"
+"\n<li>The e-mail addresses of users who communicate with us via e-mail.</li>"
+"\n<li>Aggregate information on what pages on our App users access or visit.</li>"
+"\n<li>Information volunteered by the user, such as site registration, e-mail update requests and product or service related inquiries.</li>"
+"\n<li>Information about your transactions with us or others.</li>"
+"\n</ul>"
+"\n<p>We may also use the information you provide to contact you directly regarding services, products or information that we believe may be of interest to you, to support your relationship with us, and improve your experience on the App.  We may make registration records and log file available on confidential basis to third parties such as carriers, select measurement and analysis firms, auditor or data storage firms.  In the future, we may use personally identifying information you voluntarily provide to register you as a user of the App, to administer App usage and monitor traffic-levels, and to contact you regarding improvements we can make to the  App and our services, if necessary. Except as disclosed in this Privacy Policy, we will not use your information for any purpose that you have not expressly authorized.</p>"
+"\n<p>From time to time, we may also disclose information to fulfill certain legal and regulatory requirements or if we believe, in good faith, that such disclosure is legally required or necessary to protect others' rights or to prevent harm.</p>"
+"\n<p>2.	COLLECTION AND USE OF THIRD PARTY PII</p>"
+"\n<p>We provide you the functionality to enter your clients’ information on the App and generate proposals and quotes through our system.  This third party personally identifiable information, such as their name, date of birth and gender, along with sensitive information such as their medical information, is only used for the sole purpose of completing your request or for whatever reason it may have been provided, and is only disclosed to a service provider when you select to request a quote on the App.</p>"
+"\n<p>3.	ONWARD TRANSFER (TRANSFERS TO THIRD PARTIES)</p>"
+"\n<p>If we propose to disclose your information to a third party that is acting as our agent or on our behalf, we will ensure that the third party has agreed to assure adequate protection of your information.</p>"
+"\n<p>4.	SECURITY</p>"
+"\n<p>Your account containing your information is password-protected. Only those who log on with your password can access your account. We have taken appropriate security measures to protect your personal information from loss, misuse and unauthorized access, disclosure, alteration and destruction. However, please remember that you play a valuable part in security as well. You are responsible for protecting your password for accessing your account. If you provide any information to parties who operate websites that are linked to or from our App, different rules may apply to their collection, use, or disclosure of your personal information. We encourage you to review the privacy policies of such other sites before revealing any sensitive or personal information. Regardless of the precautions taken by you or by us, \"perfect security\" does not exist on the Internet. We cannot ensure or warrant the ultimate security of any information you transmit.</p>"
+"\n<p>5.	COOKIES</p>"
+"\n<p>A cookie is a small data file that certain websites write to your computer's hard drive when you visit those sites. A cookie file can contain information such as a user ID that a website uses to track the pages you have visited. A persistent cookie allows the App to remember you on subsequent visits. We do set persistent cookies.</p>"
+"\n<p>6.	CHILDREN'S PRIVACY</p>"
+"\n<p>We are concerned about the safety of children and their use of the Internet. This App is not directed at children under the age of 18 and we do not knowingly receive, collect or use any personal or health-related information or data from children under the age of 18 for any purpose, including for any marketing purpose, without prior parental consent. As used in these terms and conditions: (i) personal information includes any individually identifiable information, such as name, address, social security number, email address, or any combination of identifiers, and (ii) health information includes any information relating to health, nutrition, drug use, physical condition, mental health, medical history, or medical insurance coverage. If we receive actual knowledge that we have received any such personal information without the required verifiable parental consent, we will delete the information from our database as soon as we practically can.</p>"
+"\n<p>7.	LINKING TO OTHER WEBSITES</p>"
+"\n<p>Please remember that when you use a link to go from the App to another website, this Privacy Policy is no longer in effect. Your browsing and interaction on any other website, including websites that have a link on the App, are subject to the terms, conditions and policies of that website. Please read those terms, conditions and policies before proceeding.</p>"
+"\n<p>8.	CHANGES IN OWNERSHIP</p>"
+"\n<p>In the event that our ownership were to change as a result of a merger, acquisition or transfer to another company, personal information that you provide to us through this App may be transferred. If such a transfer results in a material change in the use of your personal information, we will provide notice about your choices to decline to permit such a transfer.</p>"
+"\n<p>9.	EFFECTIVE DATE AND FUTURE CHANGES</p>"
+"\n<p>This Privacy Policy is effective as of February __, 2019. You acknowledge and agree that we may, in our sole discretion, modify, add or remove any portion of this Privacy Policy at any time and in any manner. If a revision, in our sole discretion, is material, we will notify you. No changes to this Privacy Policy occurring after a dispute between you and us arises will apply retroactively. It is your responsibility to check periodically for any changes we make to the Privacy Policy. Your continued use of this App after any changes to the Privacy Policy means you accept the changes.</p>";