import {actionCreatorFactory} from 'typescript-fsa';
import { ClientConnectSettings } from '../sagas/clientConnect_sagas';
import { ClientConnectActivity } from '../utilities/shared';

const actionCreator = actionCreatorFactory('ClientConnect');
export const ClearNewClientConnectActivity = actionCreator('CLEAR_NEW_CLIENTCONNECT_ACTIVITY');
export const FetchNewClientConnectActivity = actionCreator('FETCH_NEW_CLIENTCONNECT_ACTIVITY');
export const CacheNewClientConnectActivity = actionCreator<number>('CACHE_NEW_CLIENTCONNECT_ACTIVITY');
export const FetchClientConnectSettings = actionCreator('FETCH_CLIENTCONNECT_SETTINGS');
export const ReturnClientConnectSettings = actionCreator<ClientConnectSettings>('RETURN_CLIENTCONNECT_SETTINGS');
export const PostClientConnectSettings = actionCreator<ClientConnectSettings>('POST_CLIENTCONNECT_SETTINGS');
export const FetchClientConnectURL = actionCreator('FETCH_CLIENTCONNECT_URL');
export const CacheClientConnectURL = actionCreator<string>('CACHE_CLIENTCONNECT_URL');

export const getClientConnectInfo = actionCreator.async<void, void>('GET_CC_SETTINGS');
export const openClientConnectConfigureDialog = actionCreator.async<void, boolean>('OPEN_CC_CONFIGURE_DIALOG');
export const getClientConnectUsageActivities = actionCreator.async<void, ClientConnectActivity[]>('GET_CC_ACTIVITIES');