import React from 'react';
import {
	Avatar,
	Dialog,
	DialogContent,
	Icon,
	ListItem,
	ListItemText,
	ListItemAvatar,
} from '@material-ui/core';
import { SaveCancelHeaderBarComponent } from '../../../components/Layout/SaveCancelHeaderBar';
import { ExportResultsColumnSection } from './export_results_column_section';
import { wrapInCollapsibleCard } from '../../../components/higher_order_component/wrap_in_card';
import { themePalette } from '../../../utilities/branding';
const ColumnSectionCard = wrapInCollapsibleCard(ExportResultsColumnSection);

interface ExportResultsEntityProps {
	visible: boolean;
	exportResults: (columnsToExport: string[]) => void;
	closeExportDialog: () => void;
	employerColumnNames: string[];
	personColumnNames: string[];
	leadColumnNames: string[];
	productColumnNames: string[];
	agentColumnNames: string[];
	allColumns: string[];
}

interface ExportResultsEntityState {
	selectedColumns: string[];
	selectAll: boolean;
	selectAllEmployerColumns: boolean;
	selectAllPersonColumns: boolean;
	selectAllLeadColumns: boolean;
	selectAllProductColumns: boolean;
	selectAllAgentColumns: boolean;
}

type Props = ExportResultsEntityProps;

export class ExportResultsDialog extends React.Component<
	Props,
	ExportResultsEntityState
	> {
	constructor(props: Props) {
		super(props);
		this.state = {
			selectedColumns: [],
			selectAll: false,
			selectAllEmployerColumns: false,
			selectAllPersonColumns: false,
			selectAllLeadColumns: false,
			selectAllProductColumns: false,
			selectAllAgentColumns: false,
		};
	}

	handleCancel = () => {
		this.props.closeExportDialog();
		this.setState({
			selectedColumns: [],
			selectAll: false,
		});
	};

	selectColumn = (columnName: string) => {
		let selectedColumns = this.state.selectedColumns;
		if (selectedColumns.indexOf(columnName) !== -1) {
			selectedColumns.splice(selectedColumns.indexOf(columnName), 1);
		} else {
			selectedColumns.push(columnName);
		}
		this.setState({
			selectedColumns: selectedColumns,
		});
	};

	selectOrDeselectSection = (columnNames: string[], selectMode: boolean) => {
		let selectedColumns = this.state.selectedColumns;

		columnNames.forEach(columnName => {
			if (selectedColumns.indexOf(columnName) !== -1 && !selectMode) {
				selectedColumns.splice(selectedColumns.indexOf(columnName), 1);
			} else if (selectedColumns.indexOf(columnName) === -1 && selectMode) {
				selectedColumns.push(columnName);
			}
		});
		this.setState({
			selectedColumns: selectedColumns,
			selectAll: false,
		});
	};

	selectOrDeselectAll = () => {
		let isSelectAll = !this.state.selectAll;
		if (isSelectAll) {
			this.setState({
				selectedColumns: this.props.allColumns,
				selectAll: isSelectAll,
				selectAllEmployerColumns: true,
				selectAllPersonColumns: true,
				selectAllLeadColumns: true,
				selectAllProductColumns: true,
				selectAllAgentColumns: true,
			});
		} else {
			this.setState({
				selectedColumns: [],
				selectAll: isSelectAll,
				selectAllEmployerColumns: false,
				selectAllPersonColumns: false,
				selectAllLeadColumns: false,
				selectAllProductColumns: false,
				selectAllAgentColumns: false,
			});
		}
	};

	getNumberOfSelectedColumns = (sectionColumnNames: string[]) => {
		let numSelected = 0;
		sectionColumnNames.forEach(colName => {
			if (this.state.selectedColumns.indexOf(colName) !== -1) {
				numSelected++;
			}
		});
		return numSelected;
	};

	getSectionTitle = (sectionName: string, sectionColumnNames: string[]) => {
		let sectionTitle = sectionName + ': ';
		sectionTitle += this.getNumberOfSelectedColumns(sectionColumnNames);
		sectionTitle += '/' + sectionColumnNames.length + ' Selected';
		return sectionTitle;
	};

	export = () => {
		this.props.exportResults(this.state.selectedColumns);
		this.setState({
			selectedColumns: [],
			selectAll: false,
			selectAllEmployerColumns: false,
			selectAllPersonColumns: false,
			selectAllLeadColumns: false,
			selectAllProductColumns: false,
			selectAllAgentColumns: false,
		});
	};

	render() {
		return (
			<Dialog open={this.props.visible}>
				<SaveCancelHeaderBarComponent
					onSave={this.export}
					saveText={'Export'}
					title="Columns"
					isSaveDisabled={this.state.selectedColumns.length < 1}
					onCancel={this.handleCancel}
					bypassCancelConfirmation={true}
				/>
				<DialogContent>
					<ListItem
						style={{ padding: '7px 16px 2px 16px' }}
						button={true}
						onClick={this.selectOrDeselectAll}
					>
						<ListItemAvatar>
							<Avatar
								style={{
									color: themePalette.negative_avatar,
									backgroundColor: this.state.selectAll
										? themePalette.selected_item
										: themePalette.unselected_item,
									alignSelf: 'center',
								}}
							>
								<Icon>check</Icon>
							</Avatar>
						</ListItemAvatar>
						<ListItemText
							primary={this.getSectionTitle(
								this.state.selectAll ? 'Deselect All' : 'Select All',
								this.props.allColumns
							)}
						/>
					</ListItem>
					<ColumnSectionCard
						title={this.getSectionTitle(
							'Employer Columns',
							this.props.employerColumnNames
						)}
						startCollapsed={true}
						listName="Employer Columns"
						sectionColumnNames={this.props.employerColumnNames}
						selectedColumns={this.state.selectedColumns}
						selectColumn={this.selectColumn}
						isSectionSelected={this.state.selectAllEmployerColumns}
						selectOrDeselectSection={() => {
							let selectMode = !this.state.selectAllEmployerColumns;
							this.selectOrDeselectSection(
								this.props.employerColumnNames,
								selectMode
							);
							this.setState({
								selectAllEmployerColumns: selectMode,
								selectAll: false,
							});
						}}
					/>
					<ColumnSectionCard
						title={this.getSectionTitle(
							'Person Columns',
							this.props.personColumnNames
						)}
						startCollapsed={true}
						listName="Person Columns"
						sectionColumnNames={this.props.personColumnNames}
						selectedColumns={this.state.selectedColumns}
						selectColumn={this.selectColumn}
						isSectionSelected={this.state.selectAllPersonColumns}
						selectOrDeselectSection={() => {
							let selectMode = !this.state.selectAllPersonColumns;
							this.selectOrDeselectSection(
								this.props.personColumnNames,
								selectMode
							);
							this.setState({
								selectAllPersonColumns: selectMode,
								selectAll: false,
							});
						}}
					/>
					<ColumnSectionCard
						title={this.getSectionTitle(
							'Lead Columns',
							this.props.leadColumnNames
						)}
						startCollapsed={true}
						listName="Lead Columns"
						sectionColumnNames={this.props.leadColumnNames}
						selectedColumns={this.state.selectedColumns}
						selectColumn={this.selectColumn}
						isSectionSelected={this.state.selectAllLeadColumns}
						selectOrDeselectSection={() => {
							let selectMode = !this.state.selectAllLeadColumns;
							this.selectOrDeselectSection(
								this.props.leadColumnNames,
								selectMode
							);
							this.setState({
								selectAllLeadColumns: selectMode,
								selectAll: false,
							});
						}}
					/>
					<ColumnSectionCard
						title={this.getSectionTitle(
							'Product Columns',
							this.props.productColumnNames
						)}
						startCollapsed={true}
						listName="Product Columns"
						sectionColumnNames={this.props.productColumnNames}
						selectedColumns={this.state.selectedColumns}
						selectColumn={this.selectColumn}
						isSectionSelected={this.state.selectAllProductColumns}
						selectOrDeselectSection={() => {
							let selectMode = !this.state.selectAllProductColumns;
							this.selectOrDeselectSection(
								this.props.productColumnNames,
								selectMode
							);
							this.setState({
								selectAllProductColumns: selectMode,
								selectAll: false,
							});
						}}
					/>
					<ColumnSectionCard
						title={this.getSectionTitle(
							'Agent Columns',
							this.props.agentColumnNames
						)}
						startCollapsed={true}
						listName="Agent Columns"
						sectionColumnNames={this.props.agentColumnNames}
						selectedColumns={this.state.selectedColumns}
						selectColumn={this.selectColumn}
						isSectionSelected={this.state.selectAllAgentColumns}
						selectOrDeselectSection={() => {
							let selectMode = !this.state.selectAllAgentColumns;
							this.selectOrDeselectSection(
								this.props.agentColumnNames,
								selectMode
							);
							this.setState({
								selectAllAgentColumns: selectMode,
								selectAll: false,
							});
						}}
					/>
				</DialogContent>
			</Dialog>
		);
	}
}