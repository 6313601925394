import React from 'react';
import { Lead } from '../../reducers/LeadReducer';
import { SaveCancelHeaderBarComponent } from '../../components/Layout/SaveCancelHeaderBar';
import {
	Dialog,
	DialogContent,
	TextField,
	MenuItem,
	Icon,
} from '@material-ui/core';
import { WideButton } from '../utility/wide_button';
import { createRequiredLabel } from '../../components/utility/required_label';
import { Lookup, Lookups, LookupDictionary } from '../../utilities/lookup';
import _ from 'lodash';
import { Strings } from '../../assets/common/strings';
import { isMobileDevice } from '../../utilities/is_mobile';
import { SlideUp } from '../utility/transition';

interface LeadStatusCardProps {
	lead: Lead;
	updateLead: (lead: Lead) => void;
	hidden?: boolean;
	lookups: LookupDictionary;
	navigateToHouseholdFollowup?: (
		status: Lookup,
		householdId: string,
		employerId: string,
		isFollowUp: boolean
	) => void;
	navigateToEmployerFollowup?: (
		status: Lookup,
		householdId: string,
		employerId: string,
		isFollowUp: boolean
	) => void;
}

interface LeadStatusCardState {
	dialogOpenStatus: boolean;
	selection: Lookup;
	status: Lookup;
	statusCode: Lookup;
	disableSave: boolean;
	disableFollowUp: boolean;
}

export class LeadStatusCard extends React.Component<
	LeadStatusCardProps,
	LeadStatusCardState
	> {
	constructor(props: LeadStatusCardProps) {
		super(props);

		const currentStatus = this.props.lookups.findOrCreate(this.props.lead.status);
		const currentStatusCode = this.props.lookups.findOrCreate(
			this.props.lead.statusCode
		);
		this.state = {
			dialogOpenStatus: false,
			selection: currentStatus.isChildOf(Lookups.NoSale)
				? Lookups.NoSale
				: currentStatus,
			status: currentStatus,
			statusCode: currentStatusCode,
			disableSave: true,
			disableFollowUp: true,
		};
	}

	componentWillReceiveProps(nextProps: LeadStatusCardProps) {
		if (this.props.lead !== nextProps.lead || this.props.lookups !== nextProps.lookups) {
			const statusLookup = nextProps.lookups.findOrCreate(nextProps.lead.status);
			this.setState({
				status: statusLookup,
				statusCode: nextProps.lookups.findOrCreate(nextProps.lead.statusCode),
				selection: statusLookup.isChildOf(Lookups.NoSale)
					? Lookups.NoSale
					: statusLookup,
			});
		} 
	}

	onCancel = () => {
		const currentStatus = this.props.lookups.findOrCreate(this.props.lead.status);
		const currentStatusCode = this.props.lookups.findOrCreate(
			this.props.lead.statusCode
		);

		this.setState({
			dialogOpenStatus: false,
			statusCode: currentStatusCode,
			selection: currentStatus.isChildOf(Lookups.NoSale)
				? Lookups.NoSale
				: currentStatus,
			status: currentStatus,
			disableFollowUp: true,
			disableSave: true,
		});
	};

	onSave = () => {
		this.saveDisposition(false);
	};

	onFollowUp = () => {
		this.saveDisposition(true);
	};

	saveDisposition = (isFollowUp: boolean) => {
		if (this.props.lead) {
			const newLead = _.cloneDeep(this.props.lead);
			newLead.status = this.state.status.isLookup(Lookups.Sale)
				? this.state.status.label
				: this.state.status.value;
			newLead.statusCode = this.state.statusCode.value;
			this.props.updateLead(newLead);
		}
		this.setState({ dialogOpenStatus: false });

		if (
			this.props.lead &&
			this.state.status.isNotChildOf(Lookups.SkipNoSaleFollowUp)
		) {
			if (!this.props.lead.employer) {
				if (this.props.navigateToHouseholdFollowup) {
					this.props.navigateToHouseholdFollowup(
						this.state.status,
						this.props.lead.householdId as string,
						'',
						isFollowUp
					);
				}
			} else {
				if (this.props.navigateToEmployerFollowup) {
					this.props.navigateToEmployerFollowup(
						this.state.status,
						'',
						this.props.lead.employer.id as string,
						isFollowUp
					);
				}
			}
		}
	};

	handleDialogClick = () => {
		this.setState({
			dialogOpenStatus: true,
		});
	};

	handleSelection = (selection: string) => {
		const selectionLookup = this.props.lookups.findOrCreate(selection);
		if (!selectionLookup) return;

		if (selectionLookup.matches(Lookups.NoSale)) {
			this.setState({
				statusCode: Lookups.NoSale,
				disableSave: true,
				selection: selectionLookup,
				disableFollowUp: true
			});
		} else {
			const status = selectionLookup.getFirstParentOrSelfOfType(
				Lookups.LeadStatus
			);

			if (
				this.state.statusCode.matches(Lookups.NoSale) &&
				selectionLookup.isChildOf(Lookups.NoSale)
			) {
				this.setState({
					status,
					disableSave: false,
					disableFollowUp: false,
				});

				if (
					selectionLookup.matches(Lookups.LanguageBarrier) ||
					selectionLookup.matches(Lookups.DisconnectedPhone) || 
					selectionLookup.matches(Lookups.WrongNumber) || 
					selectionLookup.matches(Lookups.NeverInquired)
				) {
					this.setState({
						disableFollowUp: true
					});
				}
			} else {
				const newStatusCode = selectionLookup.getFirstParentOrSelfOfType(
					Lookups.LeadStatusCode
				);
				this.setState({
					status,
					statusCode: newStatusCode,
					disableSave: selectionLookup.matches(this.props.lead.status) ||
						selectionLookup.isChildOf(Lookups.AlwaysCreateNewPhoneCall) ||
						selectionLookup.isChildOf(Lookups.CreateNewAppointment),
					disableFollowUp: false,
					selection: selectionLookup,
				});
			}
		}
	};

	render() {
		const status = this.props.lookups.findOrCreate(this.props.lead.status);
		return (
			<div>
				<WideButton
					key="button"
					primary={'Status'}
					secondary={status.label}
					onClick={() => this.handleDialogClick()}
					icon={<Icon style={{ marginRight: 0 }}>create</Icon>}
					disabled={this.props.lead.statusCode == Strings.Status.Merged}
				/>
				<Dialog
					key="dialog"
					fullScreen
					open={this.state.dialogOpenStatus}
					onClose={() => this.setState({ dialogOpenStatus: false })}
					TransitionComponent={SlideUp}
				>
					<SaveCancelHeaderBarComponent
						isSaveDisabled={this.state.disableSave}
						isSecondaryButtonDisabled={this.state.disableFollowUp}
						onCancel={this.onCancel}
						title={'Status'}
						saveText="Done"
						onSecondaryButtonClick={this.onFollowUp}
						onSave={this.onSave}
					/>
					<DialogContent>
						<TextField
							key="status"
							label={createRequiredLabel('Status')}
							select
							fullWidth
							value={this.state.selection.value}
							onChange={(event: any) => {
								this.handleSelection(event.target.value);
							}}
							SelectProps={{ native: isMobileDevice }}
						>
							{this.props.lookups
								.getChildren(Lookups.LeadStatus, Lookups.NoSale)
								.map(lookup =>
									isMobileDevice
									? (
										<option key={'selection1-' + lookup.value} value={lookup.value}>{lookup.label}</option>
									)
									: (
										<MenuItem
											key={'selection1-' + lookup.value}
											value={lookup.value}
										>
											{lookup.label}
										</MenuItem>
									)
								)}
						</TextField>
						{this.state.selection.matches(Lookups.NoSale) ? (
							<TextField
								style={{ marginTop: '20px' }}
								key="reason"
								label={createRequiredLabel('Reason')}
								error={!this.state.status}
								helperText="No Sale Reason is required"
								select
								fullWidth
								value={this.state.status.value}
								onChange={(event: any) => {
									this.handleSelection(event.target.value);
								}}
								SelectProps={{ native: isMobileDevice }}
							>
								{this.props.lookups
									.getChildren([Lookups.DispositionReason, Lookups.NoSale])
									.map(lookup =>
										isMobileDevice
											? (
												<option key={'selection2-' + lookup.value} value={lookup.value}>{lookup.label}</option>
											)
											: (
												<MenuItem
													key={'selection2-' + lookup.value}
													value={lookup.value}
												>
													{lookup.label}
												</MenuItem>
											)
									)}
							</TextField>
						) : null}
					</DialogContent>
				</Dialog>
			</div>
		);
	}
}
