import Moment from 'moment';
import { Activity } from '../reducers/activity_reducer';
import { Contact } from '../reducers/ContactReducer';

export const getNearestActivities = (
	inputActivities: Activity[],
	number: number = 5
) => {
	const now = Moment.utc()
		.local()
		.valueOf();
	const activities = inputActivities.slice();

	const sortedActivities = activities
		.sort((a, b) => {
			const timeToA = Math.abs(
				now -
					Moment.utc(a.time)
						.local()
						.valueOf()
			);
			const timeToB = Math.abs(
				now -
					Moment.utc(b.time)
						.local()
						.valueOf()
			);
			if (timeToA < timeToB) {
				return -1;
			} else if (timeToA > timeToB) {
				return 1;
			} else {
				return 0;
			}
		})
		.slice(0, number);

	sortedActivities.sort((a, b) => {
		if (a.time < b.time) {
			return -1;
		} else if (a.time > b.time) {
			return 1;
		} else {
			return 0;
		}
	});

	return sortedActivities;
};

export const findContactActivities = (
	activities: Activity[],
	contacts: Contact[]
): Activity[] =>
	activities.filter(activity =>
		contacts.some(
			contact =>
				activity.contact && activity.contact.id == contact.id
		)
	);
