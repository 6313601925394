import actionCreatorFactory, {
	AsyncActionCreators,
} from 'typescript-fsa';
import { DownlineAgent } from '../reducers/product_reducer';
import { ChartGroup } from '../reducers/performance_reducer';

const actionCreator = actionCreatorFactory('Performance');

export interface PerformanceParams {
	startDate: Date;
	endDate: Date;
	agentCode?: string;
	lineOfBusiness?: string;
	dateRangeOverride?: number;
	utcOffset: number;
	parentPage?: string;
}

export type PerformanceActionCreator = AsyncActionCreators<
	PerformanceParams,
	ChartGroup,
	any
>;

export const UpdatePerformanceFilter = actionCreator<{ filter: string }>(
	'UPDATE_PERFORMANCE_FILTER'
);

export const GetPerformanceDownlineAgent = actionCreator<
    string
>('GET_PERFORMANCE_DOWNLINE_AGENT');

export const SetPerformanceDownlineAgent = actionCreator<{
	agent: DownlineAgent | undefined;
}>('SET_PERFORMANCE_DOWNLINE_AGENT');

export const UpdatePerformanceDates = actionCreator<{
	params: PerformanceParams;
}>('UPDATE_PERFORMANCE_DATES');

export const UpdatePerformanceLineOfBusiness = actionCreator<{
	params: PerformanceParams;
}>('UPDATE_PERFORMANCE_LINEOFBUSINESS');

export const GetAllPerformanceData = actionCreator.async<
	PerformanceParams,
	void
>('GET_ALL_PERFORMANCE_DATA');

export const GetPerformanceActivity = actionCreator.async<
	PerformanceParams,
	ChartGroup
>('GET_PERFORMANCE_ACTIVITY');

export const GetPerformanceLeads = actionCreator.async<
	PerformanceParams,
	ChartGroup
>('GET_PERFORMANCE_LEADS');

export const GetPerformanceProduction = actionCreator.async<
	PerformanceParams,
	ChartGroup
>('GET_PERFORMANCE_PRODUCTION');

export const GetPerformanceQuality = actionCreator.async<
	PerformanceParams,
	ChartGroup
>('GET_PERFORMANCE_QUALITY');
