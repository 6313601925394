import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React from 'react';
import { GetLicenseAppointmentStatusIcon, useStylesLicenseAppointment } from '../../utilities/agentLicenseAppointment_util';

interface Props {
	title: string;
	statusDialogOpen: boolean;
	statusList: string[];
	handleDialogClose: () => void;
}

const LAStatusIconLegendDialog: React.FC<Props> = (props) => {
	const classes = useStylesLicenseAppointment();
	const { title, statusDialogOpen, handleDialogClose, statusList } = props;

	return (
		<Dialog
			open={statusDialogOpen}
			onClose={handleDialogClose}
			PaperProps={{
				className: classes.dialog,
			}}>
			<DialogTitle className={classes.textCenter}>{title}</DialogTitle>
			<DialogContent>
				{statusList.map((status) => {
					return (
						<div key={status} className={classes.iconPosition}>
							<GetLicenseAppointmentStatusIcon status={status} showTooltip={false} />
							<Typography>&nbsp;= {status}</Typography>
						</div>
					);
				})}
			</DialogContent>

			<DialogActions className={classes.buttonActions}>
				<Button onClick={handleDialogClose}>Close</Button>
			</DialogActions>
		</Dialog>
	);
};

export default LAStatusIconLegendDialog;
