import React from 'react';
import { Contact } from '../../reducers/ContactReducer';
import { SearchResult } from '../utility/search_result';
import { fullName } from '../../assets/common/string_builders';

interface Props {
	contact: Contact;
	selected: boolean;
	secondaryDisplay: string;
	onSelectContact: (contact: Contact) => void;
}
export const ContactSearchResult: React.FC<Props> = (props) => {

	const { contact, selected, secondaryDisplay, onSelectContact } = props;
	const onClick = () => onSelectContact(contact);

	return (
		<SearchResult
			isSelected={selected}
			primaryText={fullName(contact)}
			secondaryText={secondaryDisplay}
			onClick={onClick}
		/>
	);
};