import { Action as ReduxAction } from 'redux';
import BaseDTO from '../utilities/base_dto';
import { isType } from 'typescript-fsa';
import { FilterPodcasts, CreatePodcast, UpdatePodcast, ChangePodcast, GetPodcastById, DeletePodcastById, UpdateTracking, TogglePlayer } from '../actions/podcast_actions';

export interface Podcast extends BaseDTO {
    channel?: string,
    title: string,
    description: string,
    fileName: string,
    file?: any
    isPublished: boolean,
    duration?: number,
    categories: string[],
    url?: string,
    uploadFile?: boolean
    tracking?: PodcastTracking;
    publishDate?: Date;
}

export interface PodcastTracking {
    podcastId: string;
    userId: string;
    createdOn: Date;
    updatedOn: Date;
    progress: number;
    finished: boolean;
}

export interface PodcastFilter {
    searchString: string;
    sortBy: string;
    sortDirection: string;
    status: string;
    pageSize: number,
    pageNumber: number,
}

export interface PodcastState {
    podcasts: Podcast[];
    isLoading: boolean;
    morePodcastsToLoad: boolean;
    currentPodcast?: Podcast;
    filter: PodcastFilter;
    showPlayer: boolean;
    isTracking: boolean;
    isUploading: boolean;
}


export const initialState: PodcastState = {
    podcasts: [],
    isLoading: false,
    isTracking: false,
    morePodcastsToLoad: false,
    currentPodcast: undefined,
    showPlayer: false,
    isUploading: false,
    filter: {
        searchString: '',
        sortBy: 'publishedon',
        sortDirection: 'desc',
        status: '',
        pageSize: 20,
        pageNumber: 0
    }
}

export function podcastReducer(
	state: PodcastState = initialState,
	action: ReduxAction
): PodcastState {
    if (isType(action, FilterPodcasts.started)) {
		return {
			...state,
            isLoading: true,
            filter: action.payload
		};
	} else if (isType(action, FilterPodcasts.failed)) {
		return {
			...state,
			isLoading: false,
			morePodcastsToLoad: false,
		};
	} else if(isType(action, FilterPodcasts.done)) {
		const podcastsInState =
            action.payload.params.pageNumber === 0 ? [] : state.podcasts.slice();
        const newPodcasts = action.payload.result;
        const morePodcasts = newPodcasts.length == state.filter.pageSize;

        newPodcasts.forEach(p => {
            podcastsInState.push(p);
        });

        return {
			...state,
			podcasts: podcastsInState,
			morePodcastsToLoad: morePodcasts,
			isLoading: false
		};
    } else if (isType(action, CreatePodcast.started)) {
        return {
            ...state,
            isUploading: action.payload.uploadFile == true,
            isLoading: true
        }
    } else if (isType(action, CreatePodcast.failed)) {
        return {
            ...state,
            isUploading: false,
            isLoading: false
        }
    } else if (isType(action, CreatePodcast.done)) {
		const podcastsInState =
            action.payload.params.pageNumber === 0 ? [] : state.podcasts.slice();
        var newPodcastsInState: Podcast[] = [action.payload.result];
        podcastsInState.forEach(p => {newPodcastsInState.push(p)});
        return {
            ...state,
            podcasts: newPodcastsInState,
            isUploading: false,
            isLoading: false
        }
    } else if (isType(action, UpdatePodcast.started)) {
        return {
            ...state,
            isUploading: action.payload.uploadFile == true,
            isLoading: true
        }
    } else if (isType(action, UpdatePodcast.failed)) {
        return {
            ...state,
            isUploading: false,
            isLoading: false
        }
    } else if (isType(action, UpdatePodcast.done)) {
        const podcastsInState = state.podcasts.slice();
        for(var i = 0; i < podcastsInState.length; i++) {
            if (podcastsInState[i].id == action.payload.result.id) 
                podcastsInState[i] = action.payload.result;
        }
        return {
            ...state,
            podcasts: podcastsInState,
            isUploading: false,
            isLoading: false
        }
    } else if (isType(action, GetPodcastById.started)) {
        return {
            ...state,
            isLoading: true
        }
    } else if (isType(action, GetPodcastById.failed)) {
        return {
            ...state,
            isLoading: false
        }
    } else if (isType(action, GetPodcastById.done)) {
        const podcasts = state.podcasts.slice();
        let podcast = podcasts.find(p => p.id == action.payload.params);
        if(podcast) {
            podcast = action.payload.result;
        } else {
            podcasts.push(action.payload.result);
        }
        return {
            ...state,
            podcasts: podcasts,
            isLoading: false
        }
    } else if(isType(action, ChangePodcast)) {
        return {
            ...state,
            currentPodcast: action.payload,
            showPlayer: action.payload !== undefined
        }
    } else if(isType(action, TogglePlayer)) {
        return {
            ...state,
            showPlayer: action.payload
        }
    } else if(isType(action, DeletePodcastById.done)) {
        const podcasts = state.podcasts.slice();
        for(var i = 0; i < podcasts.length; i++)
        {
            if(podcasts[i].id == action.payload.params) {
                podcasts.splice(i, 1);
                i--;
            }
        }

        return {
            ...state,
            podcasts: podcasts
        }
    } if(isType(action, UpdateTracking.started)) {
        return {
            ...state,
            isTracking: true
        }
    } if(isType(action, UpdateTracking.failed)) {
        return {
            ...state,
            isTracking: false
        }
    } if(isType(action, UpdateTracking.done)) {
        return {
            ...state,
            isTracking: false
        }
    } else {
        return state;
    }
}