import React from 'react';
import { Grid } from '@material-ui/core';
import { ReadOnlyTextField } from '../utility/read_only_text_field';
import { ProductStatus } from '../../reducers/policy_reducer';
import { Strings } from '../../assets/common/strings';
import { wrapInCollapsibleCard } from '../higher_order_component/wrap_in_card';

interface Props {
	lineOfBusiness?: string;
	carrier?: string;
	carrierFriendlyName?: string;
	productType?: string;
	productName?: string;
	metalLevel?: string;
	appDate?: string;
	effectiveDate?: string;
	applicationStatus?: ProductStatus;
	statusReason?: string;
	policyEndDate?: string;
	outstandingDocuments?: string;
	outstandingDocumentsDueDate?: string;
}
const GeneralInfo: React.FC<Props> = (props: Props) => {
	const appStatusEnum = props.applicationStatus || -1;
	const applicationStatus: string = ProductStatus[appStatusEnum as number];

	return (
		<Grid container>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label={Strings.ProductGeneralEditOptions.LineOfBusiness}
						value={props.lineOfBusiness}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label={Strings.ProductGeneralEditOptions.Carrier}
						value={props.carrier}
						multiline={true}
					/>
				</Grid>
			</Grid>
			{props.carrier == 'Not-contracted' && props.carrierFriendlyName && (
				<Grid container>
					<Grid item xs={12}>
						<ReadOnlyTextField
							label={Strings.ProductGeneralEditOptions.CarrierName}
							value={props.carrierFriendlyName}
							multiline={true}
						/>
					</Grid>
				</Grid>
			)}
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label={Strings.ProductGeneralEditOptions.ProductType}
						value={props.productType}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label={Strings.ProductGeneralEditOptions.Product}
						value={props.productName}
						multiline={true}
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={6}>
					<ReadOnlyTextField 
						label={Strings.ProductGeneralEditOptions.MetalLevel}
						value={props.metalLevel} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label={Strings.ProductGeneralEditOptions.ApplicationDate}
						value={props.appDate}
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label={Strings.ProductGeneralEditOptions.ApplicationStatus}
						value={applicationStatus}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label={Strings.ProductGeneralEditOptions.EffectiveDate}
						value={props.effectiveDate}
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label={Strings.ProductGeneralEditOptions.StatusReason}
						value={props.statusReason}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label={Strings.ProductGeneralEditOptions.PolicyEndDate}
						value={props.policyEndDate}
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label={Strings.ProductGeneralEditOptions.OutstandingDocument}
						value={props.outstandingDocuments}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label={Strings.ProductGeneralEditOptions.OutstandingDocumentDueDate}
						value={props.outstandingDocumentsDueDate}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export const GeneralInfoCard = wrapInCollapsibleCard(GeneralInfo);
