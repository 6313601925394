import React from 'react';
import {
    LaunchIconButton, Grid, riseStrings, useNavigation, risify, FactFinderIconSvg, useTheme
} from '@optum-uhone-hmkts/rise';

type Props = {

    color?: string;
    disabled?: boolean;
}

const _Component: React.FC<Props> = (props) => {
    const n = useNavigation();
    const theme = useTheme();
    /** Dispatch */

    const navigateToFactFinderPage = () => n.dispatchTo(riseStrings._Route_FactFinder_List);


    return <Grid
        item
        container
    >

        <LaunchIconButton
            icon={
                <FactFinderIconSvg
                    color={props.color || theme.palette.secondary.main}
                    size={60}
                    viewBox={"2 3 31 20"}
                />
            }
            color={props.color || theme.palette.secondary.main}
            disabled={props.disabled}
            caption={riseStrings.FactFinder}
            onClick={navigateToFactFinderPage}
        /></Grid>;
};

export const FactFinderBubble = risify(
    riseStrings._FactFinder,
    riseStrings._FactFinderBubbleItem
)(_Component);