import { all, call, put } from 'redux-saga/effects';
import moment from 'moment';
import {
	ProductivityActionCreator,
	ProductivityParams,
	GetYtdAVReport,
	GetNewLeadsReport,
	GetSurebridgePersistencyReport,
	GetSurebridgePersistencyAvgAndRank,
} from '../actions/productivity_actions';
import { Action } from 'typescript-fsa';
import { http } from '../utilities';
import { TeamViewRow } from '../reducers/teamview_reducer';
import { YTDAVReport, NewLeadsReport } from '../reducers/productivity_reducer';
import { takeLatestForActionType, makeHandleError } from '../utilities/saga_util';
import { Strings } from '../assets/common/strings';

const getProductivitySaga = (
    actionCreator: ProductivityActionCreator,
    apiRoute: string,
) =>
	function*(action: Action<ProductivityParams>) {
		try {
			const response = yield call(getProductivityData(apiRoute), action.payload);
			if (response.ok) {
				const teamViewTable: TeamViewRow[] = yield response.json();
				if (!teamViewTable) {
					return;
				}
				teamViewTable.forEach(row => {
					if (row.terminated && row.agentcode.includes('Terminated')) {
						row.hideChildren = true;
					}
				});
				yield put(
					actionCreator.done({
						params: action.payload,
						result: teamViewTable,
					}),
				);
			} else {
				yield handleError(actionCreator, action, {
					errorCode: response.status,
				});
				yield put(
					actionCreator.done({
						params: action.payload,
						result: [] as TeamViewRow[],
					}),
				);
			}
		} catch (error) {
			yield handleError(actionCreator, action, error);
			yield put(
				actionCreator.done({
					params: action.payload,
					result: [] as TeamViewRow[],
				}),
			);
		}
	}

const getYtdAvSaga = (
	actionCreator: ProductivityActionCreator,
) =>
	function*(action: Action<ProductivityParams>) {
		try {
			const response = yield call(getProductivityData(Strings.TeamViewAPI.YDTAV_ROUTE), action.payload);
			if (response.ok) {
				const ytdAvReport: YTDAVReport[] = yield response.json();
				if (!ytdAvReport) {
					return;
				}
				yield put(
					GetYtdAVReport.done({
						params: action.payload,
						result: ytdAvReport,
					}),
				);
			} else {
				yield handleError(GetYtdAVReport, action, {
					errorCode: response.status,
				});
				yield put(
					GetYtdAVReport.done({
						params: action.payload,
						result: [] as YTDAVReport[],
					}),
				);
			}
		} catch (error) {
			yield handleError(GetYtdAVReport, action, error);
			yield put(
				GetYtdAVReport.done({
					params: action.payload,
					result: [] as YTDAVReport[],
				}),
			);
		}
	}

const getNewLeadsSaga = (
	actionCreator: ProductivityActionCreator,
) =>
	function*(action: Action<ProductivityParams>) {
		try {
			const response = yield call(getProductivityData(Strings.TeamViewAPI.LEADS_CHARTS_ROUTE), action.payload);
			if (response.ok) {
				const newLeadsReport: NewLeadsReport[] = yield response.json();
				if (!newLeadsReport) {
					return;
				}
				yield put(
					GetNewLeadsReport.done({
						params: action.payload,
						result: newLeadsReport,
					}),
				);
			} else {
				yield handleError(GetNewLeadsReport, action, {
					errorCode: response.status,
				});
				yield put(
					GetNewLeadsReport.done({
						params: action.payload,
						result: [] as NewLeadsReport[],
					}),
				);
			}
		} catch (error) {
			yield handleError(GetNewLeadsReport, action, error);
			yield put(
				GetNewLeadsReport.done({
					params: action.payload,
					result: [] as NewLeadsReport[],
				}),
			);
		}
	}

const getProductivityData = (apiRoute: string) => (
	params: ProductivityParams,
): Promise<any> => {
	const api = params.parentPage ? `${params.parentPage}/${apiRoute}` : `TeamView/${apiRoute}`;
	// Convert to UTC here
	const startDate = moment.utc([params.startDate.getFullYear(), params.startDate.getMonth(), params.startDate.getDate()])
		.startOf('day')
		.subtract(params.utcOffset, 'minutes');
	const endDate = moment.utc([params.endDate.getFullYear(), params.endDate.getMonth(), params.endDate.getDate()])
		.endOf('day')
		.subtract(params.utcOffset, 'minutes');
	const urlParams = `
		?startDate=${startDate.format()}
		&endDate=${endDate.format()}
		&LOB=${params.lineOfBusiness}
		&comparePrevious=${params.comparePrevious}
		&utcOffset=${params.utcOffset}
		${params.agentCode ? `&agentCode=${params.agentCode}` : ''}
		${
		params.dateRangeOverride
			? `&dateRangeOverride=${params.dateRangeOverride}`
			: ''
		}
	`;
	return http(`${api}${params.agentCode ? Strings.TeamViewAPI.DOWNLINE_API : ''}${urlParams}`);
}

// UTILITY
const handleError = makeHandleError('Error getting team view data');

export function* productivitySagas() {
	yield all([
		takeLatestForActionType(
			GetYtdAVReport.started,
			getYtdAvSaga(GetYtdAVReport),
		),
		takeLatestForActionType(
			GetNewLeadsReport.started,
			getNewLeadsSaga(GetNewLeadsReport),
		),
		takeLatestForActionType(
			GetSurebridgePersistencyReport.started,
			getProductivitySaga(GetSurebridgePersistencyReport, Strings.TeamViewAPI.SUREBRIDGE_PERSISTENCY_ROUTE),
		),
		takeLatestForActionType(
			GetSurebridgePersistencyAvgAndRank.started,
			getProductivitySaga(GetSurebridgePersistencyAvgAndRank, Strings.TeamViewAPI.SUREBRIDGE_PERSISTENCY_AVG_RANK_ROUTE),
		),
	]);
};
