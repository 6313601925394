import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Strings } from '../../assets/common/strings';
import { TeamViewParams, GetTeamView206020 } from '../../actions/teamview_actions';
import { ProductivityTableCard } from '../../components/teamview/productivity_table_card';
import { ReportCard } from '../../components/utility/report_card';

export const TwentySixtyTwentyReport: React.FC<{params: TeamViewParams}> = props => {
    const dispatch = useDispatch();

    const reportData =  useSelector((state: any) => state.App.teamview.twentySixtyTwentyCard, shallowEqual);

    useEffect(() => {
        dispatch(GetTeamView206020.started(props.params))
    }, [props.params]);

    return (
        <>
            {reportData?.isLoading
            ? (
                <ReportCard
                    color="tertiary"
                    titleLeft={Strings.TeamView.TWENTY_SIXTY_TWENTY_CARD}
                    isLoading
                />)
            : (
                <ProductivityTableCard
                    rows={reportData?.cardTable}
                    color="tertiary"
                    titleLeft={Strings.TeamView.TWENTY_SIXTY_TWENTY_CARD}
                    rowsToHighlight={[3, 5]}
                />)}
        </>
    );
};
