import React from 'react';
import { Grid } from '@material-ui/core';
import { ReadOnlyTextField } from '../utility/read_only_text_field';
import { wrapInCollapsibleCard } from '../higher_order_component/wrap_in_card';

interface Props {
	applicationNumber?: string;
	quoteId?: string;
	ffmAppId?: string;
	carrierProducerCode?: string;
	sold?: string;
	createdOn?: string;
	createdByAgentName?: string;
	updatedOn?: string;
	updatedByAgentName?: string;
	enrollmentMethod: string;
	acaApplyId: string;
	enrollmentPeriodCode: string;
	sepCode: string;
}
const AdminInfo: React.FC<Props> = (props) => {
	return (
		<Grid container>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Application Number"
						value={props.applicationNumber}
						multiline
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Quote ID"
						value={props.quoteId}
						multiline
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="FFM ID"
						value={props.ffmAppId}
						multiline
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Carrier Producer Code"
						value={props.carrierProducerCode}
						multiline
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField label="Sold" value={props.sold} multiline />
				</Grid>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Enrollment Method"
						value={props.enrollmentMethod}
						multiline
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="ACA Apply ID"
						value={props.acaApplyId}
						multiline
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Enrollment Period"
						value={props.enrollmentPeriodCode}
						multiline
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="SEP Code"
						value={props.sepCode}
						multiline
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12}>
					<ReadOnlyTextField
						label="Created"
						value={props.createdOn + ' ' + props.createdByAgentName}
						multiline
					/>
				</Grid>
			</Grid>

			<Grid container>
				<Grid item xs={12}>
					<ReadOnlyTextField
						label="Modified"
						value={props.updatedOn + ' ' + props.updatedByAgentName}
						multiline
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export const AdminInfoCard = wrapInCollapsibleCard(AdminInfo);
