import React from 'react';
import _ from 'lodash';
import { AppState } from '../../reducers/index';
import { connect } from '@optum-uhone-hmkts/rise';
import { ContactHeaderBar } from '../../components/Layout/contact_header_bar';
import { NavigationProps, navRoutes } from '../../components/nav/Routes';
import {
	navigateTo,
	navigateToWithoutAddingToHistory,
} from '../../actions/navigation_actions';
import { Grid } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import { isNullOrUndefined } from 'util';
import moment from 'moment';
import { themeLinks } from '../../utilities/branding';
import { BasicInfo } from '../../components/contact/basic_info';
import { AdditionalInfo } from '../../components/contact/additional_info';
import { MedicareInfo } from '../../components/contact/medicare_info';
import { DeletePersonModal } from '../../components/contact/delete_person';
import { Loaded, getHeaderSubTitlesToDisplay } from '../../utilities/utilities';
import {
	GetContact,
	DeleteContact,
	UpdateContact,
} from '../../actions/contact_actions';
import { GetEmployer } from '../../actions/employer_actions';
import { Contact } from '../../reducers/ContactReducer';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import { ContactInfoList } from '../../components/contact/contact_info_list';
import { wrapInCollapsibleCard } from '../../components/higher_order_component/wrap_in_card';
import { Policy, ProductStatus } from '../../reducers/policy_reducer';
import { AddEmployerDialog } from '../../components/contact/add_employer_dialog';
import { RemoveEmployerDialog } from '../../components/contact/remove_employer_dialog';
import { normalizeHeight, getYesNoFromBool } from '../../utilities/formatting/data_normalizations';
import { MoreMenu, createMenuAction, createHelpAction } from '../../components/nav/more_menu';
import { Strings } from '../../assets/common/strings';
import { getLoadedContactsSelector, makeGetMatchedContact } from '../../selectors/contact_selectors';
import { isMobileDevice } from '../../utilities/is_mobile';
import { Redirect } from 'react-router-dom';
import { getOpenLead } from '../../selectors/lead_selectors';
import { Lead } from '../../reducers/LeadReducer';
import { StartCallSequenceAndLoadActivities } from '../../actions/sequence/call_sequence_actions';
import { SmsSequence } from '../dialogs/sequence/sms_sequence';
import { getId } from '../../selectors/base_selectors';
import { DesktopPadding } from '../../components/Layout/desktop_padding';
import { WideButton } from '../../components/utility/wide_button';

const ContactInfoCard = wrapInCollapsibleCard(ContactInfoList);
const BasicInfoCard = wrapInCollapsibleCard(BasicInfo);
const AdditionalInfoCard = wrapInCollapsibleCard(AdditionalInfo);
const MedicareInfoCard = wrapInCollapsibleCard(MedicareInfo);

interface InternalProps { }

interface StateProps extends NavigationProps {
	contact: Loaded<Contact>;
	contacts: Contact[];
	isLoading: boolean;
	policies: Loaded<Policy>[];
	hasActiveBusinessRelationship: boolean;
	shouldRedirect: boolean;
	openLead: Lead;
	householdId: string;
}

interface DispatchProps {
	deletePerson: (contact: Contact) => void;
	getContact: (id: string) => void;
	updatePerson: (contact: Contact) => void;
	getEmployer: (id: string) => void;
	navigateTo: (route: string) => void;
	navigateToWithoutAddingToHistory: (route: string) => void;
	startCallSequence: (householdId: string) => void;
}

type Props = InternalProps & StateProps & DispatchProps & NavigationProps;

interface State {
	householdId: string;
	contactId: string;
	deleteModalVisible: boolean;
	addEmployerOpen: boolean;
	removeEmployerOpen: boolean;
	dispositionDialogIsOpen: boolean;
	phoneCallDialogOpen: boolean;
}

class ContactDetails extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			householdId: '',
			contactId: '',
			deleteModalVisible: false,
			addEmployerOpen: false,
			removeEmployerOpen: false,
			dispositionDialogIsOpen: false,
			phoneCallDialogOpen: false,
		};
	}

	componentWillMount() {
		const householdID: string = _.get(
			this.props,
			'match.params.householdID',
			'new'
		).toString();

		const contactId = this.props.match.params.contactID;
		if (contactId != Strings.Navigation.ContactId) {
			this.props.getContact(contactId);
		}

		this.setState({
			deleteModalVisible: false,
			addEmployerOpen: false,
			removeEmployerOpen: false,
			dispositionDialogIsOpen: false,
			householdId: householdID,
			contactId,
		});
	}

	componentWillReceiveProps(nextProps: Props) {
		if (
			this.props.contact &&
			this.props.contact.loading &&
			nextProps.contact &&
			!nextProps.contact.loading
		) {
			//Load Employer Check
			if (nextProps.contact.employerId) {
				const employerContacts = this.props.contacts.filter(contact => {
					return contact.employerId === nextProps.contact.employerId;
				});

				if (employerContacts.length === 1) {
					nextProps.getEmployer(nextProps.contact.employerId);
				}
			}
		}
	}

	closeDispositionDialog = () =>
		this.setState({ dispositionDialogIsOpen: false });

	public nameToDisplay = (contact: Contact) =>
		(contact.preferredName || contact.firstName) + ' ' + contact.lastName;

	togglePhoneCallDialog = (phoneCallDialogOpen: boolean) =>
		this.setState({ phoneCallDialogOpen });

	openRemoveEmployer = () => {
		this.setState({ removeEmployerOpen: true });
	};

	openDelete = () => {
		this.setState({ deleteModalVisible: true });
	};

	printPage = () => window.print();

	render() {
		if (this.props.shouldRedirect) {
			return <Redirect to={navRoutes.leadList.path} />
		}

		const { hasActiveBusinessRelationship } = this.props;
		const employee = this.props.contacts.find(contact => {
			return (
				!isNullOrUndefined(contact.employerId || contact.employerId) &&
				contact.id == this.props.match.params.contactID
			);
		});
		const contactUpsertPage: string = employee
			? navRoutes.employeeUpsert.path
				.replace(Strings.Navigation.EmployerId, employee.employerId || employee.employerId)
				.replace(Strings.Navigation.ContactId, this.state.contactId)
			: navRoutes.contactUpsert.path
				.replace(Strings.Navigation.HouseholdId, this.state.householdId)
				.replace(Strings.Navigation.ContactId, this.state.contactId);
		const employerPage =
			employee
				? navRoutes.employer.path.replace(
					Strings.Navigation.EmployerId,
					employee.employerId || employee.employerId
				)
				: 'new';
		const householdPage: string = navRoutes.household.path.replace(
			Strings.Navigation.HouseholdId,
			this.state.householdId
		);

		const fetchedContact: Contact = this.props.contact.data;

		const maritalStatus = fetchedContact.familyStatusCode || '';
		const firstName: string = fetchedContact.firstName || '';
		const middleName: string = fetchedContact.middleName || '';
		const lastName: string = fetchedContact.lastName || '';

		const preferredName: string = fetchedContact.preferredName || '';
		const suffix: string = fetchedContact.suffix || '';
		const status: string = fetchedContact.status || '';
		const dateOfBirthRaw = fetchedContact.birthDate || ''; // "YYYY-MM-DDTHH:mm:ss.SSSZ"

		let dateOfBirth: string = moment.utc(dateOfBirthRaw).format('MM-DD-YYYY');
		if (dateOfBirth == 'Invalid date') {
			dateOfBirth = '';
		}

		const heightFeet: string = !isNullOrUndefined(fetchedContact.heightFt)
			? fetchedContact.heightFt.toString()
			: '';
		const heightInches: string = !isNullOrUndefined(fetchedContact.heightIn)
			? fetchedContact.heightIn.toString()
			: '';
		const height: string = normalizeHeight(heightFeet, heightInches);

		const weight: string = !isNullOrUndefined(fetchedContact.weightLbs)
			? fetchedContact.weightLbs.toString()
			: '';
		const displayWeight = weight === '0' ? '' : weight;

		const isSelfEmployerBool: boolean = fetchedContact.isSelfEmployed || false;
		const isSelfEmployed: string = getYesNoFromBool(
			isSelfEmployerBool
		);

		const isTobaccoUserBool: boolean = fetchedContact.isTobaccoUser || false;
		const isTobaccoUser: string = getYesNoFromBool(isTobaccoUserBool);

		const isStudentBool: boolean = fetchedContact.isStudent || false;
		const isStudent: string = getYesNoFromBool(isStudentBool);

		const annualIncome: number | undefined =
			fetchedContact.annualIncome || undefined;
		const annualIncomeString: string = !annualIncome
			? ''
			: '$' + String(annualIncome).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

		const lastFourSSN: string | undefined =
			fetchedContact.lastFourSsn || undefined;
		const languagePreference: string = fetchedContact.languagePreference || '';

		const clientType = fetchedContact.clientType || '';

		const subtitles = getHeaderSubTitlesToDisplay(fetchedContact);

		const hasEmployer = !!(fetchedContact && fetchedContact.employerId);

		const jobTitle = fetchedContact.jobTitle || '';

		const occupation = fetchedContact.occupation || '';
		const gender = fetchedContact.gender || '';
		const salutation = fetchedContact.salutation || '';

		return (
			<BasePageContainer
				topComponent={
					<ContactHeaderBar
						navigateTo={(route) => this.props.navigateTo(route)}
						title="Person"
						subtitles={subtitles}
						history={this.props.history}
						householdId={this.props.householdId}
						rightButtons={
							<MoreMenu
								children={[
									createMenuAction(Strings.MoreMenu.Edit, () => this.props.navigateTo(contactUpsertPage)),
									createMenuAction(Strings.MoreMenu.UnlinkFromEmployer, this.openRemoveEmployer, !hasEmployer),
									createMenuAction(Strings.MoreMenu.Delete, this.openDelete),
									createMenuAction(Strings.MoreMenu.Print, this.printPage, isMobileDevice),
									createHelpAction(themeLinks.helpLinkCRM),
								]}
							/>
						}
						contacts={[fetchedContact]}
						primaryContact={fetchedContact}
						isLoading={this.props.isLoading}
						hasActiveBusinessRelationship={hasActiveBusinessRelationship}
					/>
				}
			>
				<PersistentNotifications />
				<DesktopPadding>
					<Grid container>
						<Grid item xs={12} sm={6} style={{ marginBottom: -10 }}>
							<WideButton
								primary={'View Household'}
								onClick={() => this.props.navigateTo(householdPage)}
							/>
						</Grid>
						<Grid item xs={12} sm={6} style={{ marginBottom: -10 }}>
							{
							employee
								? ( 
									<WideButton
										primary={'View Employer'}
										onClick={() => this.props.navigateTo(employerPage)}
									/>
								)
								: (
									<WideButton
										primary={'Add Employer'}
										onClick={() => this.setState({ addEmployerOpen: true })}
										icon={<LinkIcon/>}
									/>
								)
							}
						</Grid>
					</Grid>

					<BasicInfoCard
						title={'Basic Info'}
						firstName={firstName}
						lastName={lastName}
						middleName={middleName}
						dateOfBirth={dateOfBirth}
						jobTitle={jobTitle}
						status={status}
					/>

					<AdditionalInfoCard
						title={'Additional Info'}
						startCollapsed={true}
						middleName={middleName}
						preferredName={preferredName}
						suffix={suffix}
						lastFourSSN={lastFourSSN || ''}
						height={height}
						weight={displayWeight}
						isTobaccoUser={isTobaccoUser}
						isStudent={isStudent}
						isSelfEmployed={isSelfEmployed}
						annualIncome={annualIncomeString}
						languagePref={languagePreference}
						familyStatusCode={maritalStatus}
						clientType={clientType}
						occupation={occupation}
						gender={gender}
						salutation={salutation}
						lastUnemploymentYear={fetchedContact.lastUnemploymentYear}
					/>
					{fetchedContact.medicareInfo ? (
						<MedicareInfoCard
							title={'Medicare Info'}
							startCollapsed={true}
							medicareInfo={fetchedContact.medicareInfo}
						/>
					) : null}
					<ContactInfoCard
						title={'Contact Info'}
						contact={fetchedContact}
						phoneClick={() => this.props.startCallSequence(this.props.match.params.householdID)}
						noPadding
					/>
				</DesktopPadding>
				<SmsSequence
					contacts={[fetchedContact]}
				/>
				<DeletePersonModal
					contact={fetchedContact}
					deleteContact={() => {
						this.props.deletePerson(fetchedContact);
					}}
					returnToDetailPage={() => {
						this.props.navigateTo(householdPage);
					}}
					goToLeadsDashboard={() => {
						this.props.navigateTo(navRoutes.leadDashboard.path);
					}}
					visible={this.state.deleteModalVisible}
					onClose={this.onClose}
					deleteFromContact
					deleteFromHousehold={!employee}
				/>
				<AddEmployerDialog
					isOpen={this.state.addEmployerOpen}
					onClose={() => {
						this.setState({
							addEmployerOpen: false,
						});
					}}
					currentContact={fetchedContact}
					history={this.props.history}
				/>
				<RemoveEmployerDialog
					isOpen={this.state.removeEmployerOpen}
					onClose={() => {
						this.setState({
							removeEmployerOpen: false,
						});
					}}
					unlink={() => {
						const updatedContact = _.cloneDeep(fetchedContact);
						updatedContact.employerId = '';
						this.props.updatePerson(updatedContact);
					}}
					contact={fetchedContact}
					history={this.props.history}
				/>
			</BasePageContainer>
		);
	}

	onClose = () => {
		this.setState({
			deleteModalVisible: false,
		});
	};
	
}

function mapStateToProps(state: AppState, ownProps: Props) {
	const getMatchedContact = makeGetMatchedContact();
	const contact = getMatchedContact(state, ownProps);
	const missingContactData = Object.keys(contact.data).length == 1;
	const householdId = getId(state, ownProps);
	const { policies } = state.policy;
	const { applications } = state.application;
	const hasActiveBusinessRelationship =
		policies.some(
			policy =>
				(policy.data.policyStatus == ProductStatus.Active ||
					policy.data.policyStatus == ProductStatus.Pending) &&
				(policy.data.primaryInsured || { id: '' }).id == contact.data.id
		) ||
		applications.some(
			application =>
				(application.data.applicationStatus == ProductStatus.Active ||
					application.data.applicationStatus == ProductStatus.Pending) &&
				(application.data.primaryInsured || { id: '' }).id == contact.data.id
		)
		;
	return {
		contact,
		contacts: getLoadedContactsSelector(state),
		policies,
		hasActiveBusinessRelationship,
		isLoading: state.contact.isLoading,
		shouldRedirect: missingContactData,
		openLead: getOpenLead(state, ownProps),
		householdId,
	};
};

function mapDispatchToProps(dispatch: any): DispatchProps {
	return {
		getContact: (id: string) => dispatch(GetContact.started(id)),
		getEmployer: (id: string) => dispatch(GetEmployer.started(id)),
		deletePerson: (contact: Contact) =>
			dispatch(DeleteContact.started(contact)),
		updatePerson: (contact: Contact) =>
			dispatch(UpdateContact.started(contact)),
		navigateTo: (route: string) =>
			dispatch(navigateTo(route)),
		navigateToWithoutAddingToHistory: (route: string) =>
			dispatch(navigateToWithoutAddingToHistory(route)),
		startCallSequence: (householdId: string) =>
			dispatch(StartCallSequenceAndLoadActivities.started({ householdId })), //todo
	};
}

export const ContactDetailsContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
	true
)(ContactDetails);
