import moment from 'moment';

export const orDefault = (str?: string, defaultValue?: string) => str || defaultValue || '';
export const toCurrencyString = (num?: number):string => (num || num == 0) ? '$' + num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '';

interface Name {
    firstName?: string;
    middleName?: string;
    preferredName?: string;
    lastName?: string;
}

interface BirthDate {
    birthDate?: Date;
}

export const firstNameDisplay = <TName extends Name>(nameObject: TName, defaultFirstName: string = '') => 
    nameObject?.preferredName || nameObject?.firstName || defaultFirstName;    

 const middleNameDisplay = <TName extends Name>(nameObject: TName, defaultMiddleName: string = '') => 
    nameObject?.middleName || defaultMiddleName; 

const lastNameDisplay = <TName extends Name>(nameObject: TName, defaultLastName: string = '') => 
    nameObject?.lastName || defaultLastName;    

export const fullName = <TName extends Name>(nameObject: TName, defaultFirstName?: string) => {
    const firstName = capitalize(firstNameDisplay(nameObject, defaultFirstName));
    const lastName = capitalize(lastNameDisplay(nameObject));
    return `${firstName} ${lastName}`.trim();
}

export const fullNameWithMiddleName = <TName extends Name>(nameObject: TName, defaultFirstName?: string) => {
    const firstName = firstNameDisplay(nameObject, defaultFirstName);
    const middleName = middleNameDisplay(nameObject, defaultFirstName);
    const lastName = lastNameDisplay(nameObject);

    return `${firstName} ${middleName} ${lastName}`.trim();
}

export const fullNameWithMiddleInitial = <TName extends Name>(nameObject: TName, defaultFirstName?: string) => {
    const firstName = firstNameDisplay(nameObject, defaultFirstName);
    const middleInitial = middleNameDisplay(nameObject).charAt(0);
    const lastName = lastNameDisplay(nameObject);

 return `${firstName} ${middleInitial} ${lastName}`.trim();
}


export const initials = <TName extends Name>(nameObject: TName) => {
    const firstInitial = firstNameDisplay(nameObject).charAt(0)?.toUpperCase();
    const lastInitial = lastNameDisplay(nameObject).charAt(0)?.toUpperCase();
    return `${firstInitial}${lastInitial}`;
}

export const capitalize = (str: string) => (
    str
        ? `${str.charAt(0).toUpperCase()}${str.substr(1).toLowerCase()}`
        : ''
);

export const formatPhone = (phoneNumber: string) => {
    if(!phoneNumber) return '';
    return (
        '(' +
        phoneNumber.slice(0, 3) +
        ') ' +
        phoneNumber.slice(3, 6) +
        '-' +
        phoneNumber.slice(6)
    );
};

export const birthDateDisplay = <TBirthDate extends BirthDate>(
    birthDateObject: TBirthDate, 
    defaultBirthDate: string = '',
    formatType: string = 'MM/DD/YYYY',
) => 
    (birthDateObject.birthDate && `(${moment.utc(birthDateObject.birthDate).format(formatType)})`) || defaultBirthDate;

export const birthDate = <TBirthDate extends BirthDate>(
    birthDateObject: TBirthDate,
    formatType: string = 'MM/DD/YYYY',
) => {
    const birthDate = birthDateDisplay(birthDateObject, '', formatType);
    return `${birthDate}`;
}
