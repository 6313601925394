import React from 'react';
import { Button } from '@material-ui/core';
import { connect } from '@optum-uhone-hmkts/rise';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { jwt_auth } from '../../utilities/auth';
import { isDesktop } from '../../utilities/is_mobile';
import { navRoutes, NavigationProps } from './Routes';
import { setDrawerStatus } from '../../actions/access_page_actions';
import { LoginScreen } from '../../containers/login/login_screen';
import { Logout } from '../../actions/authentication_actions';
import { ApiPermission } from '../../utilities/auth/permissions';
import { isAgentRole } from '../../utilities/agent_util';
import { Strings as S } from '../../assets/common/strings';
import { navigateBack } from '../../actions/navigation_actions';
import { Dispatch } from '@reduxjs/toolkit';
import { AppState } from '../../reducers';

export const drawerWidth = 256;

interface ComponentProps {
	exact?: boolean;
	path: string;
	permissions?: ApiPermission[];
	additionalPermission?: () => boolean;
	component: React.ComponentType;
}

interface StateProps {
	isAuthenticated: boolean;
	isImpersonating: boolean;
	needsTermsAndConditions: boolean;
	onTermsAndConditionsPage: boolean;
	needsPin: boolean;
	isDesktop: boolean;
	firstTimeLogin: boolean;
	needsVideo: boolean;
	videoFullScreen: boolean;
	clickToCallEnabled: boolean;

	//Mapped from JWT
	hasPermission: boolean;
	isTerminated: boolean;
	isAssistant: boolean;
}

interface DispatchProps {
	startLogout(): void;
	setDrawerStatus(payload: boolean): void;
	navigateBack: () => void;
}

type Props = StateProps &
	ComponentProps &
	DispatchProps &
	NavigationProps;

const AuthenticateRoute: React.FC<Props> = (props) => {

	const location = useLocation();

	const {
		component,
		isAuthenticated,
		needsTermsAndConditions,
		needsPin,
		needsVideo,
		firstTimeLogin,
		videoFullScreen,
		clickToCallEnabled,
		isTerminated,
		hasPermission,
		onTermsAndConditionsPage,
		isImpersonating,
		isAssistant,
		...rest
	} = props;

	const redirectToTermsAndConditions =
		!onTermsAndConditionsPage && needsTermsAndConditions;

	const redirectToVideo =
		!redirectToTermsAndConditions &&
		!(
			location.pathname === navRoutes.welcome.path ||
			onTermsAndConditionsPage
		) &&
		needsVideo;

	return (
		<Route
			{...rest}
			render={renderProps => {
				let componentToMount = <LoginScreen />;
				if (isAuthenticated && !needsPin) {
					if (redirectToTermsAndConditions && !isTerminated && (!isImpersonating || isAssistant)) {
						componentToMount = (
							<Redirect
								to={{
									pathname: navRoutes.termsAndConditions.path,
									state: { from: renderProps.location },
								}}
							/>
						);
					} else if (redirectToVideo && !isTerminated) {
						componentToMount = (
							<Redirect
								to={{
									pathname: navRoutes.welcome.path,
									state: { from: renderProps.location },
								}}
							/>
						);
					} else if (hasPermission && !isTerminated) {
						const showDrawer =
							isDesktop() &&
							!firstTimeLogin &&
							!needsTermsAndConditions &&
							!videoFullScreen &&
							!clickToCallEnabled;
						componentToMount = (
							<div
								style={showDrawer ? { marginLeft: drawerWidth } : undefined}
							>
								{React.createElement(component, { ...(renderProps as any) })}
							</div>
						);
					} else if (isTerminated) {
						props.setDrawerStatus(false);
						componentToMount = (
							<div style={{ textAlign: 'center', marginTop: 50 }}>
								<p>You do not have permission to use this application.</p>
								<Button
									variant="contained"
									color="primary"
									onClick={props.startLogout}
								>
									Return to log in
								</Button>
							</div>
						);
					} else if (!hasPermission) {
						componentToMount = (
							<div
								style={
									isDesktop()
										? {
											marginLeft: drawerWidth,
											textAlign: 'center',
											marginTop: 50,
										}
										: { textAlign: 'center', marginTop: 50 }
								}
							>
								<p>You do not have permission to view this page.</p>
								<Button
									variant="contained"
									color="primary"
									onClick={props.navigateBack}
								>
									Go Back
								</Button>
							</div>
						);
					}
				}
				return componentToMount;
			}}
		/>
	);
}

const mapStateToProps = (state: AppState, ownProps: Props): StateProps  => {
	const authState = state.authentication;
	let { hasPermission, isTerminated } = jwt_auth.checkPermissions(ownProps.permissions || []);
	if (ownProps.additionalPermission) {
		hasPermission = ownProps.additionalPermission();
	}

	return {
		onTermsAndConditionsPage: ownProps.location.pathname === navRoutes.termsAndConditions.path,
		isAuthenticated: authState.isAuthenticated && !!state.user.id && state.authentication.isAuthenticated,
		needsTermsAndConditions: !state.user.acceptanceDate,
		needsPin: state.authentication.needsPin,
		firstTimeLogin: state.authentication.firstTimeLogin,
		isDesktop: state.layout.desktop,
		needsVideo: !state.user.videoAcceptanceDate,
		videoFullScreen: state.user.videoFullScreen,
		clickToCallEnabled: state.clickToCall.enabled,
		hasPermission,
		isTerminated,
		isImpersonating: !!state.user.impersonatingId,
		isAssistant: isAgentRole([S.ADRoles.Assistant]),
	};
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	startLogout: () => dispatch(Logout.started(undefined)),
	setDrawerStatus: payload => dispatch(setDrawerStatus(payload)),
	navigateBack: () => dispatch(navigateBack()),
});

export const AuthRoute = connect(
	mapStateToProps,
	mapDispatchToProps,
	true
)(
	AuthenticateRoute
) as React.ComponentClass<ComponentProps>;
