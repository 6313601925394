import React from 'react';
import { Fab as MuiFab, Icon, makeStyles } from '@material-ui/core';

const getStyles = makeStyles(theme => ({
    fab: {
        position: 'fixed',
        right: 20,
        bottom: 20,
    }
}));

interface Props {
    icon: string;
    onClick: () => void;
}

const Fab: React.FC<Props> = (props) => {
    const styles = getStyles();
    return (
        <MuiFab
            className={styles.fab}
            onClick={props.onClick}
        >
            <Icon>{props.icon}</Icon>
        </MuiFab>
    );
};

export default Fab;