import React from 'react';
import ReactDOM from 'react-dom';
import Cropper from 'react-cropper';
import {
	Dialog,
	DialogContent,
	DialogActions,
	Button,
	Avatar,
	Divider,
	Switch,
	FormControlLabel,
} from '@material-ui/core';
import { AppState } from '../../reducers/index';
import { getAvatarAsImage } from '../../utilities/image_util';
import { initials } from '../../assets/common/string_builders';

interface Props {
	title?: string;
	buttonText?: string;
	finishBtnText?: string;
	handleFinalImage: (img?: string) => void;
	agentData: AppState['agent'];
}

interface State {
	invalidFileSize: boolean;
	invalidFileType: boolean;
	croppedImgData?: string;
	open: boolean;
	useInitials: boolean;
	openConfirm: boolean;
}

class ImageEditor extends React.Component<Props, State> {
	avatarEl: HTMLElement | null;

	constructor(props: Props) {
		super(props);
		this.state = {
			invalidFileType: false,
			invalidFileSize: false,
			croppedImgData: undefined,
			open: false,
			useInitials: !this.props.agentData.agentImage,
			openConfirm: false,
		};
	}

	handleClose() {
		this.setState({
			open: false,
			openConfirm: false,
			useInitials: !this.props.agentData.agentImage,
		});
	}

	handleConfirm = () => {
		this.setState({ openConfirm: true });
	};

	handleFinish() {
		if (this.state.useInitials && this.avatarEl) {
			let avatar = ReactDOM.findDOMNode(this.avatarEl.children[0]);
			getAvatarAsImage
				.bind(this)(avatar)
				.then((img: string) => {
					this.props.handleFinalImage(img);
					this.handleClose();
				});
		} else {
			this.props.handleFinalImage(this.state.croppedImgData);
			this.handleClose();
		}
	}

	dialogActions = [
		<Button onClick={this.handleClose.bind(this)}>Cancel</Button>,
		<Button color="primary" onClick={this.handleConfirm.bind(this)}>
			{this.props.finishBtnText || 'Finish'}
		</Button>,
	];

	confirmationActions = [
		<Button
			key="cancel"
			color="secondary"
			onClick={this.handleClose.bind(this)}
		>
			Cancel
		</Button>,
		<Button
			key="update"
			color="primary"
			variant="contained"
			onClick={this.handleFinish.bind(this)}
		>
			Update
		</Button>,
	];

	render() {
		return (
			<Dialog
				title={this.props.title || ''}
				open={this.state.open}
				onClose={this.handleClose}
			>
				<Divider />
				<div style={{ textAlign: 'right' }}>
					<FormControlLabel
						control={
							<Switch
								color="primary"
								checked={this.state.useInitials}
								disabled={!this.props.agentData.agentImage}
								onChange={(ev, isChecked) => {
									this.setState({ useInitials: isChecked });
								}}
							/>
						}
						label="Use Initials"
					/>
				</div>
				<div
					style={{
						fontSize: '12px',
						padding: '0 10px 10px',
					}}
				>
					Icon changes may not be reflected in AgentConnect immediately.
				</div>
				<Divider />
				<div style={styles.editor}>
					<span
						ref={el => {
							this.avatarEl = el;
						}}
					>
						<Avatar
							style={{
								...styles.profileAvatar,
								...styles.editorAvatar,
								display: this.state.useInitials ? 'inline-flex' : 'none',
							}}
						>
							{initials(this.props.agentData)}
						</Avatar>
					</span>
					<div
						style={{
							display: this.state.useInitials ? 'none' : 'block',
						}}
					>
						<Cropper
							ref="cropper"
							viewMode={1}
							style={styles.cropper}
							src={
								this.props.agentData.agentImage
									? this.props.agentData.agentImage.replace('http:', 'https:')
									: this.props.agentData.agentImage
							}
							aspectRatio={1}
							guides={false}
							zoomable={false}
							autoCrop={true}
							autoCropArea={1}
							crossOrigin="anonymous"
							crop={() => {
								let cropper = this.refs.cropper as Cropper;
								this.setState(() => {
									return {
										croppedImgData: cropper
											.getCroppedCanvas({ width: 192, height: 192 })
											.toDataURL(),
									};
								});
							}}
						/>
					</div>
				</div>
				<DialogActions children={this.dialogActions} />
				<Dialog open={this.state.openConfirm}>
					<DialogContent>
						Are you sure you want to update your icon?
					</DialogContent>
					<DialogActions children={this.confirmationActions} />
				</Dialog>
			</Dialog>
		);
	}
}

export const styles: { [key: string]: React.CSSProperties } = {
	image: {
		width: 120,
		height: 120,
	},
	editor: {
		height: '300px',
		textAlign: 'center',
		position: 'relative',
	},
	editorAvatar: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		margin: 'auto',
		width: '120px',
		height: '120px',
		fontSize: '60px',
		borderRadius: 'unset',
	},
	cropper: {
		height: '300px',
		width: 'auto',
	},
};

export default ImageEditor;
