import { FormControlLabel, FormGroup, Grid, MenuItem, Paper, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { Field, reduxForm, DecoratedFormProps } from 'redux-form';
import { DatePicker, TimePicker, ValueHolder } from '../../components/redux_form_material';
import { createRequiredLabel } from '../../components/utility/required_label';
import { themePalette } from '../../utilities/branding';
import { Address, Contact } from '../../reducers/ContactReducer';
import { Employer } from '../../reducers/employer_reducer';
import { AgentAddress } from '../../reducers/agent_reducer';
import { EmployerSuggestions } from '../../components/activity/employer_suggestions';
import { LocationSuggestions } from '../../components/activity/location_suggestions';
import { ContactSuggestions } from '../../components/activity/contact_suggestions';
import { maxLength, validateRequired } from '../../utilities/form_validation';
import { fullName } from '../../assets/common/string_builders';
import { Loaded, displayFullAddress, displayCity } from '../../utilities/utilities';
import { normalizeNumeric } from '../../utilities/formatting/string_normalization';
import { normalizePhone } from '../../utilities/formatting/data_normalizations';
import { Strings } from '../../assets/common/strings';
import { validateDateString } from '../../utilities/form_validation';
import { isIOS } from '../../utilities/is_mobile';
import { FormTextField, FormSelect, FormSwitch } from '../../utilities/forms';
import { getAppState } from '../..';
import { Activity } from '../../reducers/activity_reducer';

interface OwnProps {
	formValues: any;
	change?: (fieldName: string, value: any) => void;
	onUpdate: (pristine: boolean, invalid: boolean, submitting: boolean, overrideSave: boolean) => void;
	type: string;
	contacts: Loaded<Contact>[];
	employers: Loaded<Employer>[];
	changeFieldValue(formName: string, formPath: string, value: any): void;
	formName: string;
	businessAddresses?: AgentAddress[];
	storefrontAddresses?: Address[];
	presetContacts: Loaded<Contact>[];
	preselectedContact?: Contact;
	presetEmployers: Loaded<Employer>[];
	preselectedEmployer?: Employer;
	agentFirstName?: string;
	agentLastName?: string;
	history: any;
	followup: boolean;
	navigateToPerson: (householdId: string, id: string) => void;
	navigateToEmployer: (employerId: string) => void;
	handleContactUpdate: (searchText: string) => void;
	handleEmployerUpdate: (searchText: string) => void;
}

interface State {
	dueByDate: Date;
	dateSet: boolean;
	personSelected: boolean;
	matchedContacts: Loaded<Contact>[];
	selectedContact: Contact;
	personFieldDisabled: boolean;
	showLocationSuggestions: boolean;
	employerFieldDisabled: boolean;
	textBoxsDisabled: boolean;
	employerSelected: boolean;
	matchedEmployers: Loaded<Employer>[];
	selectedEmployer: Employer;
	propsReceivedFirst: boolean;
	selectedAddress: string;
	overrideSave: boolean;
}

const maxLength200 = maxLength(200);
const maxLength4k = maxLength(4000);
const phoneNumberValidation = (value: any) =>
	value && normalizeNumeric(value).length !== 10
		? 'Invalid phone number, must be 10 digits'
		: undefined;

let validateError = '';
const validateTime = (formTime: any, formDate: any) => {
	const time = moment(formTime)
	const date = moment(formDate).set('hour', time.hours()).set('minute', time.minutes());
	if (date.isBefore(moment())) {
		validateError = 'Task cannot be created in the past';
		return validateError;
	} else {
		validateError = '';
		return undefined;
	}
};
export type Props = DecoratedFormProps<OwnProps, any, any>;

class UpsertActivityForm extends React.Component<Props, State> {
	public minDate: any;
	constructor(props: Props) {
		super(props);
		this.state = {
			dueByDate: new Date(),
			dateSet: true,
			personSelected: false,
			matchedContacts:
				props.presetContacts.length > 0 ? props.presetContacts : [],
			selectedContact: {} as Contact,
			personFieldDisabled: false,
			showLocationSuggestions: false,
			employerFieldDisabled: false,
			employerSelected: false,
			matchedEmployers:
				props.presetEmployers.length > 0 ? props.presetEmployers : [],
			selectedEmployer: {} as Employer,
			propsReceivedFirst: false,
			selectedAddress: '',
			textBoxsDisabled: false,
			overrideSave: true,
		};

		this.minDate = moment().toDate();
		this.handleUpdateInput = this.handleUpdateInput.bind(this);
		this.onSelectContact = this.onSelectContact.bind(this);
		this.onDeselectContact = this.onDeselectContact.bind(this);
		this.onLocationFieldFocus = this.onLocationFieldFocus.bind(this);
		this.onSelectLocation = this.onSelectLocation.bind(this);
		this.onSelectEmployer = this.onSelectEmployer.bind(this);
		this.onDeselectEmployer = this.onDeselectEmployer.bind(this);
		this.handleUpdateEmployerInput = this.handleUpdateEmployerInput.bind(this);

		if (props.preselectedContact && !this.state.matchedContacts.length) {
			const preselectedContact: Loaded<Contact> = {
				data: props.preselectedContact,
				loading: false,
				errors: []
			}
			this.state.matchedContacts.push(preselectedContact);
		}
	}


	componentWillMount() {
		const {
			type,
			followup
		} = this.props;

		if (type === Strings.Activity.Appointment) {
			this.setState({
				showLocationSuggestions: true,
			});
		} else if (type === Strings.Activity.Task) {
			this.setState({
				employerFieldDisabled: true,
				personFieldDisabled: true,
			});
		}
		if (type != Strings.Activity.Appointment && followup) {
			this.setState({
				overrideSave: false
			});
		}
	}

	componentWillReceiveProps(nextProps: any) {
		nextProps.onUpdate(
			nextProps.pristine,
			nextProps.invalid,
			nextProps.submitting,
			this.state.overrideSave
		);

		if (!this.state.propsReceivedFirst) {
			this.setState({ propsReceivedFirst: true });
			if (nextProps.preselectedEmployer && nextProps.preselectedEmployer.id) {
				this.onSelectEmployer(nextProps.preselectedEmployer);

			} else if (nextProps.preselectedContact && nextProps.preselectedContact.id) {
				this.onSelectContact(nextProps.preselectedContact);
			}
		}
    }
    
    componentDidUpdate(prevProps: Props) {
        const { contacts, employers } = this.props;
        if (prevProps.contacts?.length !== contacts?.length) {
            this.setState({matchedContacts: contacts})
        }
        if (prevProps.employers?.length !== employers?.length) {
            this.setState({matchedEmployers: employers})
        }
    }

	onLocationFieldFocus() {
		this.setState({
			showLocationSuggestions: true,
		});
	}

	onLocationFieldBlur = (field: any) => {
		if (this.state.selectedAddress != field.target.value)
			this.setState({
				selectedAddress: '',
			});
	}

	onSelectLocation(address: Address) {
		var formattedAddress = displayFullAddress(address);
		this.props.changeFieldValue(
			this.props.formName,
			`location`,
			formattedAddress,
		);
		this.setState({
			selectedAddress: formattedAddress,
		});
	}

	onSelectContact(contact: Contact) {
		if (this.state.selectedContact && contact.id == this.state.selectedContact.id) {
			this.onDeselectContact();
		} else {
			this.setState({
				selectedContact: contact,
				employerFieldDisabled: true,
			});
			this.props.changeFieldValue(
				this.props.formName,
				`contact`,
				fullName(contact)
			);
			this.props.changeFieldValue(this.props.formName, `contactValue`, contact);
			this.props.changeFieldValue(this.props.formName, `employer`, '');
			this.props.changeFieldValue(this.props.formName, `employerValue`, '');
		}

	}

	onSelectEmployer(employer: Employer) {
		if (this.state.selectedEmployer.id && employer.id == this.state.selectedEmployer.id) {
			this.onDeselectEmployer();
		} else {
			this.setState({
				selectedEmployer: employer,
				personFieldDisabled: true

			});

			this.props.changeFieldValue(
				this.props.formName,
				`employer`,
				employer.companyName
			);
			this.props.changeFieldValue(this.props.formName, `employerValue`, employer);
			this.props.changeFieldValue(this.props.formName, `contact`, '');
			this.props.changeFieldValue(this.props.formName, `contactValue`, '');
		}
	}

	onDeselectContact() {
		this.setState({
			selectedContact: {} as Contact,
			personFieldDisabled: false,
			employerFieldDisabled: false,
		});
		this.props.changeFieldValue(this.props.formName, `contact`, '');
		this.props.changeFieldValue(this.props.formName, `contactValue`, '');
		this.props.changeFieldValue(this.props.formName, `employer`, '');
		this.props.changeFieldValue(this.props.formName, `employerValue`, '');
	}

	onDeselectEmployer() {
		this.setState({
			selectedEmployer: {} as Employer,
			employerFieldDisabled: false,
			personFieldDisabled: false,
			matchedEmployers: this.props.presetEmployers,
		});
		this.props.changeFieldValue(this.props.formName, `employer`, '');
		this.props.changeFieldValue(this.props.formName, `employerValue`, '');
		this.props.changeFieldValue(this.props.formName, `contact`, '');
		this.props.changeFieldValue(this.props.formName, `contactValue`, '');
	}

	handleUpdateInput(_ev: any, inputTerm: string) {
		const term = inputTerm.trim();
		if (term.length >= 3 && this.props.presetContacts.length < 1) {
			this.props.handleContactUpdate(term);
			const searchTokens = term.toLowerCase().split(' ');
			let matchedContacts = this.props.contacts.filter(contact => {
				const searchArray = [
					contact.data.firstName,
					contact.data.preferredName,
					contact.data.lastName,
				];
				const searchString = searchArray
					.filter(Boolean)
					.join('')
					.toLowerCase();
				return searchTokens.every((token: string, index: number) => {
					return searchString.indexOf(token.toLowerCase()) > -1;
				});
			});
			this.setState({
				matchedContacts: matchedContacts,
			});
		} else {
			this.setState({
				matchedContacts: this.props.presetContacts,
			});
		}
	}

	handleUpdateEmployerInput(_ev: any, inputTerm: string) {
		const term = inputTerm.trim();

		if (term.length >= 3) {
			if (this.props.presetEmployers.length < 1) {
				this.props.handleEmployerUpdate(term);
				var re = new RegExp(term.toLowerCase(), 'g');
				let matchedEmployers = this.props.employers.filter(employer =>
					employer.data.companyName &&
					employer.data.companyName.toLowerCase().match(re)
				);
				this.setState({
					matchedEmployers: matchedEmployers,
				});
			} else {
				this.setState({
					matchedEmployers: this.props.presetEmployers,
				});
			}
		}
	}

	focusOnClick(event: React.ChangeEvent<HTMLInputElement>) {
		event.target.focus();
	}

	getDisplayPhoneForContact(contact: Contact) {
		let displayPhoneNumber = '';

		if (contact.phones) {
			const preferredPhone = contact.phones.find(phone => phone.isPreferred);
			const homePhone = contact.phones.find(phone => phone.type === Strings.PhoneTypes.Home);

			const displayPhone = preferredPhone || homePhone;
			if (displayPhone !== undefined) {
				displayPhoneNumber = normalizePhone(displayPhone.number);
			}
		}

		return displayPhoneNumber;
	}

	render() {
		const {
			formValues,
			type
		} = this.props;

		let timeTitle: string;
		const personTitle: string = 'Person';
		const employerTitle: string = 'Employer';
		let personField: JSX.Element;
		let contactField: JSX.Element;
		let locationField: JSX.Element;
		let altPhone: JSX.Element;
		let employerField: JSX.Element;
		let employerContactField: JSX.Element;
		let selectedEmployerContact: Contact | undefined = undefined;

		const employerValue = formValues && formValues.employerValue;
		const contactValue = formValues && formValues.contactValue;
		const hasContactOrEmployer = employerValue || contactValue;

		if (type == Strings.Activity.Appointment || type == Strings.Activity.PhoneCall) {
			timeTitle = type == Strings.Activity.Appointment
				? 'Appointment Date & Time'
				: 'Call Date & Time';
			personField = (
				<Grid item xs={12}>
					<Field
						name="contact"
						component={FormTextField}
						placeholder={
							this.props.presetContacts.length > 0
								? 'Select a contact below'
								: 'Find Person By Name...'
						}
						fullWidth={true}
						onChange={this.handleUpdateInput}
						disabled={this.props.followup &&
							(this.state.selectedContact.id || this.state.selectedEmployer.id)}
						onClick={this.focusOnClick}
					/>
				</Grid>
			);
			contactField = <Field name="contactValue" component={ValueHolder} />;
			employerField = (
				<Grid item xs={12}>
					<Field
						name="employer"
						component={FormTextField}
						placeholder={
							this.props.presetEmployers.length > 0
								? 'Select an Employer below'
								: 'Find an Employer By Name...'
						}
						fullWidth={true}
						onChange={this.handleUpdateEmployerInput}
						disabled={this.props.followup &&
							(this.state.selectedContact.id || this.state.selectedEmployer.id)}
						onClick={this.focusOnClick}
					/>
				</Grid>
			);
			employerContactField = (
				<div hidden={true}>
					<Field name="employerValue" component={ValueHolder} />
				</div>
			);
			selectedEmployerContact = this.state.selectedEmployer.contacts
				? this.state.selectedEmployer.contacts.find(contact => contact.employerPrimaryContact)
				: undefined;

		} else {
			timeTitle = 'Due By';
			personField = <span />;
			contactField = <span />;
			employerContactField = <span />;
			employerField = <span />;
		}

		if (type === Strings.Activity.PhoneCall) {
			altPhone = (
				<Grid item xs={12}>
					<Field
						name="altPhone"
						component={FormTextField}
						label="Alternate Phone"
						fullWidth={true}
						normalize={normalizePhone}
						validate={[phoneNumberValidation]}
						onClick={this.focusOnClick}
					/>
				</Grid>
			);
		} else {
			altPhone = <span />;
		}

		if (type === Strings.Activity.Appointment) {
			locationField = (
				<Grid item xs={12}>
					<Field
						name="location"
						component={FormTextField}
						label={createRequiredLabel('Location')}
						fullWidth={true}
						onFocus={this.onLocationFieldFocus}
						onBlur={this.onLocationFieldBlur}
						validate={[validateRequired]}
					/>
				</Grid>
			);
		} else {
			locationField = <span />;
		}

		const showContactCity = type === Strings.Activity.Appointment;

		return (
			<Paper square elevation={0} style={styles.paperForm}>
				<form>
					<Grid container>
						<Grid item xs={12}>
							<Field
								name="activityTitle"
								component={FormTextField}
								label={createRequiredLabel('Title')}
								fullWidth={true}
								validate={[validateRequired, maxLength200]}
								maxLength={200}
								onClick={this.focusOnClick}
							/>
						</Grid>
						<Grid item xs={12}>
							<Field
								name="activityDescription"
								component={FormTextField}
								label="Description"
								fullWidth
								multiline
								rows={3}
								rowsMax={3}
								validate={[maxLength4k]}
								maxLength={4000}
								onClick={this.focusOnClick}
							/>
						</Grid>
						<Grid item xs={12}>
							<div
								style={styles.timeTitleLabel}
							>
								{createRequiredLabel(timeTitle)}
							</div>
						</Grid>
						<Grid item xs={isIOS ? 12 : 6}>
							<Field
								name="dueByDate"
								label={isIOS ? 'Date and Time' : 'Date'}
								component={DatePicker}
								fullWidth
								validate={[validateRequired]}
								minDate={this.minDate}
								dateAndTime={isIOS}
							/>
						</Grid>
						{!isIOS && <Grid item xs={6}>
							<Field
								name="dueByTime"
								label="Time"
								component={TimePicker}
								fullWidth
								validate={[validateRequired]}
							/>
						</Grid>}
						<Grid item xs={12}>
							<div
								style={styles.durationLabel}
							>
								Duration
							</div>
							<Field
								name="duration"
								component={FormSelect}
								fullWidth>
								{type == Strings.Activity.Task ? (
									<MenuItem value={'none'}>None</MenuItem>
								) : (
										undefined
									)}
								<MenuItem value={'15 minutes'}>15 minutes</MenuItem>
								<MenuItem value={'30 minutes'}>30 minutes</MenuItem>
								<MenuItem value={'45 minutes'}>45 minutes</MenuItem>
								<MenuItem value={'1 hour'}>1 hour</MenuItem>
								<MenuItem value={'1 hour and 30mins'}>
									1 hour and 30 minutes
								</MenuItem>
								<MenuItem value={'2 hours'}>2 hours</MenuItem>
							</Field>
						</Grid>
						<Field
							name="isHighPriority"
							component={FormSwitch}
							label="High Priority"
						/>

						{type != Strings.Activity.Task && !hasContactOrEmployer ? (
							<Typography
								variant="caption"
								style={{ color: themePalette.required_text, padding: '8px 8px 0px' }}
							>
								A Person OR an Employer is required
							</Typography>
						) : (
								undefined
							)}
						{type != Strings.Activity.Task ? (
							<Grid item xs={12}>
								<div style={{ ...styles.sectionHeaderStyle, color: themePalette.default_avatar }}>
									{!hasContactOrEmployer ||
										(hasContactOrEmployer && contactValue)
										? createRequiredLabel(personTitle)
										: personTitle}
								</div>
							</Grid>
						) : (
								undefined
							)}
						{personField}
						{this.state.personFieldDisabled ||
							<ContactSuggestions
								contacts={this.state.matchedContacts}
								selectedContactId={this.state.selectedContact.id}
								onSelectContact={this.onSelectContact}
								secondaryDisplay={(contact) => showContactCity ?
									displayCity(contact.addresses.find(a => a.isPreferred)) :
									this.getDisplayPhoneForContact(contact)
								}
							/>
						}
						{contactField}

						{type != Strings.Activity.Task &&
							<Grid item xs={12}>
								<div style={{ ...styles.sectionHeaderStyle, color: themePalette.default_avatar }}>
									{!hasContactOrEmployer ||
										(hasContactOrEmployer && employerValue)
										? createRequiredLabel(employerTitle)
										: employerTitle}
								</div>
							</Grid>
						}
						{employerField}
						{this.state.employerFieldDisabled ||
							<EmployerSuggestions
								employers={this.state.matchedEmployers}
								onSelectEmployer={this.onSelectEmployer}
								selectedEmployerId={this.state.selectedEmployer.id}
							/>
						}

						{employerContactField}

						{altPhone}

						{locationField}
						{this.state.showLocationSuggestions &&
							<LocationSuggestions
								contacts={this.props.presetContacts}
								selectedAddress={this.state.selectedAddress}
								onSelectLocation={this.onSelectLocation}
								selectedEmployerContact={selectedEmployerContact}
								selectedEmployerCompanyName={this.state.selectedEmployer.companyName}
								selectedContact={this.state.selectedContact}
								businessAddresses={this.props.businessAddresses}
								storefrontAddresses={this.props.storefrontAddresses}
								agentDisplayName={
									this.props.agentFirstName +
									' ' +
									this.props.agentLastName
								}
							/>
						}
					</Grid>
				</form>
			</Paper>
		);
	}
}

const validate = (values: any) => {
	const errors: any = {}
	const formValues: any = values
	if (formValues.dueByDate) {
		const validateDueDate = validateDateString('Task', true, false);
		errors.dueByDate = validateDueDate(formValues.dueByDate);
		if (isIOS) {
			errors.dueByDate = validateTime(formValues.dueByDate, formValues.dueByDate);
		}
	}
	if (formValues.dueByTime) {
		errors.dueByTime = validateTime(formValues.dueByTime, formValues.dueByDate)
	}
	return errors;
}

export const UpsertActivityFormComponent = reduxForm<Activity, Props>({
	form: 'UpsertActivityForm',
	validate,
	getFormState: (state) => state.App.form,
})(UpsertActivityForm);

const styles: { [key: string]: React.CSSProperties } = {
	sectionHeaderStyle: {
		fontWeight: 'lighter',
		marginTop: '20px',
	},
	suggestedStyle: {
		fontSize: '11px',
		fontStyle: 'italic',
	},
	paperForm: {
		padding: 20
	},
	timeTitleLabel: {
		color: themePalette.default_avatar,
		fontWeight: 'lighter',
	},
	durationLabel: {
		color: themePalette.default_avatar,
		fontWeight: 'lighter',
	},
};
