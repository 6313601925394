import { isType } from 'typescript-fsa';
import { Action as ReduxAction } from 'redux';
import BaseDTO from '../utilities/base_dto';
import moment from 'moment';
import {
	GetEmailAutomationsByAgentId,
	PostEmailAutomation,
	StoreEmailEvents,
} from '../actions/email_automation_actions';
import { ClearCaches } from '../actions/authentication_actions';
import { Logout } from '../actions/authentication_actions';

export interface EmailAutomationState {
	emailAutomation: EmailAutomation;
	areEmailAutomationsLoading: boolean;
	events: EmailAutomationEvent[];
}

export interface EmailAutomation {
	Corporate: EmailAutomationGroup;
	Agent: EmailAutomationGroup;
}

export interface EmailAutomationGroup {
	Active: EmailAutomationEvent[];
	Lost: EmailAutomationEvent[];
	Terminated: EmailAutomationEvent[];
	Working: EmailAutomationEvent[];
}

export interface EmailAutomationEvent {
	eventName: string;
	eventDescription: string;
	eventGroup: string;
	scope: string; 
	id: string;
	isUneditable: boolean;
	templateInfo: EmailAutomationTemplateInfo[];
	templateLimit: number;
	lob?: string;
	emailAutomationEventTypeCategory: string;
}

export interface EmailAutomationTemplateInfo extends BaseDTO {
	EmailTemplateId: string;
	Value: number;
	isEditting: boolean;
	isUpdated: boolean;
}

const initialEmailAutomationTemplateInfo: EmailAutomationTemplateInfo = {
	EmailTemplateId: '',
	Value: 0,
	isEditting: false,
	isUpdated: true,
	id: '',
	userId: '',
	createdOn: moment().toDate(),
	createdBy: '',
	updatedOn: moment().toDate(),
	updatedBy: '',
	isDeleted: false,
};

const initialEmailAutomationEvent: EmailAutomationEvent = {
	eventName: '',
	eventDescription: '',
	isUneditable: false,
	templateInfo: [initialEmailAutomationTemplateInfo],
	templateLimit: 3,
	lob: '',
	id: '',
	eventGroup: '',
	scope: '',
	emailAutomationEventTypeCategory: ''
};

const initialEmailAutomationGroup: EmailAutomationGroup = {
	Active: [initialEmailAutomationEvent],
	Lost: [initialEmailAutomationEvent],
	Terminated: [initialEmailAutomationEvent],
	Working: [initialEmailAutomationEvent],
};

const initialEmailAutomation: EmailAutomation = {
	Corporate: initialEmailAutomationGroup,
	Agent: initialEmailAutomationGroup,
};

const initialState: EmailAutomationState = {
	areEmailAutomationsLoading: false,
	emailAutomation: initialEmailAutomation,
	events: [],
};

export function emailAutomationReducer(
	state: EmailAutomationState = initialState,
	action: ReduxAction
): EmailAutomationState {
	// GET
	if (isType(action, GetEmailAutomationsByAgentId.started)) {
		return {
			...state,
			areEmailAutomationsLoading: true,
		};
	} else if (isType(action, GetEmailAutomationsByAgentId.done)) {
		return {
			...state,
			areEmailAutomationsLoading: false,
			emailAutomation: action.payload.result,
		};
	}  else if (isType(action, StoreEmailEvents)) {
		return {
			...state,
			areEmailAutomationsLoading: false,
			events: action.payload,
		};
	} else if (isType(action, GetEmailAutomationsByAgentId.failed)) {
		return {
			...state,
			areEmailAutomationsLoading: false,
		};

		// POST
	} else if (isType(action, PostEmailAutomation.started)) {
		return {
			...state,
			areEmailAutomationsLoading: true,
		};
	} else if (isType(action, PostEmailAutomation.done)) {
		return {
			...state,
			emailAutomation: action.payload.params,
			areEmailAutomationsLoading: false,
		};
	} else if (isType(action, PostEmailAutomation.failed)) {
		return {
			...state,
			areEmailAutomationsLoading: false,
		};
	} else if (isType(action, Logout.started)) {
		return {
			...initialState,
		};
	} else if (isType(action, ClearCaches)) {
		return {
			...initialState,
		};
	} else {
		return state;
	}
}
