import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Strings } from '../../assets/common/strings';
import { TeamViewParams, GetTeamViewNewAgentConversion } from '../../actions/teamview_actions';
import { ProductivityTableCard } from '../../components/teamview/productivity_table_card';
import { ReportCard } from '../../components/utility/report_card';

export const NewAgentConversionReport: React.FC<{params: TeamViewParams}> = props => {
    const dispatch = useDispatch();

    const reportData =  useSelector((state: any) => state.App.teamview.newAgentConversionCard, shallowEqual);

    useEffect(() => {
        dispatch(GetTeamViewNewAgentConversion.started(props.params))
    }, [props.params]);

    return (
        <>
            {reportData?.isLoading
            ? (
                <ReportCard
                    color="secondary"
                    titleLeft={Strings.TeamView.NEW_AGENT_CONVERSION_CARD}
                    isLoading
                />)
            : (
                <ProductivityTableCard
                    rows={reportData?.cardTable}
                    color="secondary"
                    titleLeft={Strings.TeamView.NEW_AGENT_CONVERSION_CARD}
                    bottomText={Strings.TeamView.NEW_AGENT_Bottom_Text}
                />)}
        </>
    );
};
