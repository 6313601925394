import {actionCreatorFactory} from 'typescript-fsa';
import { ProductFilters } from '../reducers/product_filter_reducer';
import { DownlineAgent, ProductSearchData } from '../reducers/product_reducer';

const actionCreator = actionCreatorFactory('Products');

export interface ProductFilterPayload extends ProductFilters {
	pageNumber: number;
	pageSize: number;
}

export const SearchProducts = actionCreator.async<ProductFilterPayload, ProductSearchData>('SEARCH_PRODUCTS');

export const StoreProductFilters = actionCreator<ProductFilterPayload>('STORE_PRODUCT_FILTERS');
export const StoreProductDownlineAgent = actionCreator<DownlineAgent>('STORE_PRODUCT_DOWNLINE_AGENT');
export const SetHasMore = actionCreator<boolean>('SET_HAS_MORE');
export const ToggleFilterDrawer = actionCreator<boolean>('TOGGLE_FILTER_DRAWER');