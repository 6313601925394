import React from 'react';
import SingleSelectDropdown from '../forms/single_select_dropdown';
import MultiselectDropdownWithChips from '../forms/multiselect_dropdown_with_chips';
import { Row } from '../table/filter_table';
import { FilterFacet } from './filter_drawer';
import { Dictionary } from '../../../utilities/object_util';

export { MultiselectDropdownWithChips, SingleSelectDropdown };

export type SelectionFilterFn<SearchObject> = (obj: SearchObject, filterSelection: Dictionary<any>) => boolean

export interface FilterFieldProps {
    label: string;
    options: {[label: string]: any}; //TODO: Remove any
    compareFn: SelectionFilterFn<Row> //TODO  1) include `| FilterFunction[]`
    component: FilterComponentType;
    defaultSelection: Dictionary<any>
    disabled?: string;
    includeAll?: boolean;
    onClick?: (selection: string | Dictionary<Row>) => void;
}

export type Filterfn = (obj: Row) => boolean

export interface MappedFilterFieldProps<SearchObject>{
    label: string;
    options: {[label: string]: any}; //TODO: Remove any
    compareFn: SelectionFilterFn<SearchObject>; //TODO  same as in field above
    initialSelection: Dictionary<SearchObject>;
    updateFilterFacet: (label: string, filterFacet: FilterFacet) => void;
    disabled?: string;
    includeAll?: boolean;
    onClick?: (selection: string | Dictionary<SearchObject>) => void;
}

export interface FilterDrawerFilter extends FilterFieldProps {
    updateSelection: (filterLabel: string, newSelection: Dictionary<string>) => void;
}

export enum FilterComponents {
    Dropdown = "Dropdown",
    MultiselectDropdown = "MultiselectDropdown",
}
export type FilterComponentType = FilterComponents.Dropdown | FilterComponents.MultiselectDropdown;
