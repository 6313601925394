import { Action as ReduxAction } from 'redux';
import { isType } from 'typescript-fsa';
import { StoreProductDownlineAgent } from '../actions/product_actions';
import { Logout } from '../actions/authentication_actions';
import { Application } from './application_reducer';
import { Policy } from './policy_reducer';

export interface DownlineAgent {
	agentCode: string;
	firstName: string;
	lastName: string;
	preferredName: string;
	state: string;
	zip: string;
	city: string;
	channel: string;
	image: string;
	id: string;
	agentRoles: string[];
}

export const initialDownlineAgent: DownlineAgent = {
	agentCode: '',
	channel: '',
	city: '',
	firstName: '',
	image: '',
	lastName: '',
	preferredName: '',
	state: '',
	zip: '',
	id: '',
	agentRoles: [],
};

export interface ProductState {
	downlineAgent: DownlineAgent;
}

export interface ProductSearchResult {
	Application?: Application;
	Policy?: Policy;
};

export type ProductSearchData = Array<ProductSearchResult>;

export const initialProductState = {
	downlineAgent: initialDownlineAgent,
};

export function productReducer(
	state: ProductState = initialProductState,
	action: ReduxAction
): ProductState {
	if (isType(action, StoreProductDownlineAgent)) {
		return {
			...state,
			downlineAgent: action.payload,
		};
	} else if (isType(action, Logout.started)) {
		return {
			...initialProductState,
		};
	} else {
		return {
			...state,
		};
	}
}
