import { ConfigSlice, riseStrings, useSelector, useState, useEffect, useListAsRecord, useRecordAsList, useLogging, useNavigation, r_pipe } from "@optum-uhone-hmkts/rise";
import { PowerPointActions, FactFinderSlice, emptyPowerPointTemplate } from "../fact_finder_slice";
import { shallowEqual, useDispatch } from "react-redux";

export const usePowerPointState = (
    options?: {
        overrideTemplateId?: string;
        count?: number;
    }
) => {
    const { log } = useLogging({ caller: usePowerPointState.name });
    const dispatch = useDispatch();
    const { overrideTemplateId, count } = options || {}

    const sessionState = useSelector(app => ConfigSlice.selectState(app).session, shallowEqual);
    const templateState = useSelector(app => FactFinderSlice.selectState(app).templates, shallowEqual);
    const activeTemplateId = useSelector(app => FactFinderSlice.selectState(app).activeTemplateId, shallowEqual);
    const activeTemplate = useSelector(app => FactFinderSlice.selectState(app).activeTemplate, shallowEqual);
    const templateCloseBehavior = useSelector(app => FactFinderSlice.selectState(app).activeTemplateCloseOnSave, shallowEqual);
    const navigate = useNavigation<{ presentationId: string }>();

    const [
        currentTemplate,
        setCurrentTemplateInState
    ] = useState(templateState[overrideTemplateId || activeTemplateId] || emptyPowerPointTemplate);

    const [
        activeSlides,
        setSlide,
        getSlideList
    ] = useListAsRecord<FactFinderSlice.PowerPointSlide>(currentTemplate.slides, undefined, count);


    useEffect(() => {
        if (navigate.params.presentationId) {
            const newTemplate = templateState[navigate.params.presentationId] || emptyPowerPointTemplate;
            setCurrentTemplateInState(newTemplate);
        }
    }, [navigate.params])


    useEffect(() => {
        const newTemplate = {
            ...currentTemplate,
            slides: getSlideList(),
        }
        if (activeTemplate !== newTemplate) {
            dispatch(FactFinderSlice.actions.SetActiveTemplate(newTemplate));
        }
    }, [currentTemplate, activeSlides])


    const [templates] = useRecordAsList({
        record: templateState,
        map: (template: FactFinderSlice.PowerPointTemplate) => ({
            value: template.id,
            titleText: template.title,
            masterTemplateId: template.masterTemplateId,
            subtitleText: r_pipe.statusCreatedUpdated(template),
            id: template.id,
            updatedOn: template.updatedOn
        }),
        count,
        sort: (a, b) => {
            if (a.updatedOn < b.updatedOn) {
                return 1;
            } else if (a.updatedOn > b.updatedOn) {
                return -1;
            } else {
                return 0;
            }
        }
    });

    const deleteTemplate = (templateId: string) => {
        if (!templateState[templateId]) {
            log("TemplateNotFoundInReduxState", { error: `The path 'templateState[${templateId}]' does not exist.` })
        } else {
            const template = {
                ...templateState[templateId],
                isDeleted: true,
                agentId: sessionState.userId,
            };
            dispatch(
                PowerPointActions.deleteTemplate(template)
            )
        }
    };

    const downloadTemplate = (templateId: string, templateName: string) => {


        dispatch(
            PowerPointActions.downloadPresentation({
                templateId: templateId,
                fileName: `P4L_${templateName}_${r_pipe.dateToString_YYYYMMDD()}.pptx`,
            })
        );
    };

    const saveTemplate = (overrideTemplate?) => {
        const templateToSave = {
            ...currentTemplate,
            slides: getSlideList(),
        }
        dispatch(
            PowerPointActions.saveTemplate(overrideTemplate || templateToSave)
        );
    };


    const refreshTemplates = () => {
        dispatch(
            PowerPointActions.getTemplatesByAgentId()
        );
    };

    const createSaveTemplateTemplate = (cb?: (itemId?: string) => void) => (templateId?: string) => {
        saveTemplate()
        cb && cb(templateId);
    }

    const getTemplates = () => {
        dispatch(
            PowerPointActions.getTemplatesByAgentId()
        )
    }

    const newTemplate = (masterTemplateId: string, cb?: () => void) => {
        dispatch(
            PowerPointActions.createTemplate(masterTemplateId)
        );
        cb && cb();
    };

    const createSetActiveTemplate = (cb?: (itemId?: string) => void) => (templateId?: string) => {
        setActiveTemplate(templateId || "")
        cb && cb(templateId);
    }


    const setActiveTemplate = (templateId: string) => {

        dispatch(
            FactFinderSlice.actions.SetActiveTemplateId({ templateId })
        );
    }


    const navigateToPresentation = (templateId) => {
        const template = templateState[templateId];

        dispatch(FactFinderSlice.actions.SetActiveTemplate(template));
        dispatch(FactFinderSlice.actions.SetActiveTemplateId({ templateId }));


        navigate.dispatchTo(riseStrings._Route_FactFinder_Presentation, { presentationId: templateId });
    }


    return {
        activeSlides,
        activeTemplate: currentTemplate,
        activeTemplateId,
        templateCloseBehavior,
        templates,
        setSlide,
        setCurrentTemplateInState, //form

        deleteTemplate,
        downloadTemplate,
        getTemplates,
        saveTemplate,
        refreshTemplates,
        newTemplate,
        setActiveTemplate,
        navigateToPresentation,

        createDeleteTemplate: (item: typeof templates[0]) =>
            () => deleteTemplate(item.value),
        createDownloadTemplate: (item: typeof templates[0]) =>
            () => downloadTemplate(item.value, item.titleText),
        createSetActiveTemplate,
        createSaveTemplateTemplate,

    }
};