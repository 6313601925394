import { SQLCipher, SecureStorage, store } from './../../index';
import { submitLog } from '../logging_util';
import { Strings } from '../../assets/common/strings';
import { LoginCredentials } from '../../actions/authentication_actions';
import { Log_Operation_Failed, Log_Operation_Succesful } from '../../assets/common/string_builders';

const tableConfig: { [tableName: string]: string[] } = {
	jwt: ['value'],
	ac_credentials: ['username'],
	ac_userid: ['userID'],
	pin_code: ['value', 'userID'],
	pin_settings: ['value', 'userID'],
	fingerprint_settings: ['value', 'userID'],
};

export namespace sql_cipher {
	export function configure(
		tableName: string,
		key: string,
		oldKeys: string[]
	) {
		return new Promise<{ db: any; password: string; resetDatabase: boolean }>(
			(resolve, reject) => {
				const initialize = (db: any): Promise<any> => {
					return new Promise<any>((res, rej) => {
						const actionArray: string[][] = Object.keys(tableConfig).map(
							table => {
								let params = '(';
								let i = 0;
								tableConfig[table].forEach((column, i) => {
									params += column;
									if (i < tableConfig[table].length - 1) {
										params += ', ';
									}
								});
								params += ')';
								return [`CREATE TABLE IF NOT EXISTS ${table} ${params}`];
							}
						);
						const promList = actionArray.map(actions => {
							return new Promise<any>((reso, reje) => {
								db.transaction(
									(tx: any) => {
										actions.forEach(action => {
											tx.executeSql(action);
										});
									},
									(e: any) => {
										const codeLocation = `Internal Initialize: Failed Actions`;
										if (e.hasOwnProperty('codeLocations')) {
											e.codeLocations.push(codeLocation);
										} else {
											e = { error: e, codeLocations: [codeLocation] };
										}
										reje(e);
									},
									(response: any) => {
										reso();
									}
								);
							});
						});

						Promise.all(promList)
							.then(() => {
								res(db);
							})
							.catch(e => {
								const codeLocation = `Internal Initialize: Promises Failed`;
								if (e.hasOwnProperty('codeLocations')) {
									e.codeLocations.push(codeLocation);
								} else {
									e = { error: e, codeLocations: [codeLocation] };
								}
								rej(e);
							});
					});
				};

				const retrievePassword = (db: any): Promise<string> => {
					return new Promise<string>((res, rej) => {
						db.transaction((tx: any) => {
							tx.executeSql(
								'SELECT password FROM ac_credentials',
								[],
								(tx: any, rs: any) => {
									if (rs.rows.length) {
										let password = rs.rows.item(0).password;
										res(password);
									} else {
										res('');
									}
								},
								(tx: any, error: any) => {
									res('');
								}
							);
						});
					});
				};

				const alterPassword = (db: any): Promise<any> => {
					return new Promise<any>((res, rej) => {
						const actionArray: string[][] = [
							[
								'CREATE TABLE IF NOT EXISTS ac_credentials (username)',
								'ALTER TABLE ac_credentials RENAME TO ac_credentials_old',
								'CREATE TABLE ac_credentials (username)',
								'INSERT INTO ac_credentials (username) SELECT username FROM ac_credentials_old',
								'DROP TABLE ac_credentials_old',
							],
						];
						const promList = actionArray.map(actions => {
							return new Promise<any>((reso, reje) => {
								db.transaction(
									(tx: any) => {
										actions.forEach(action => {
											tx.executeSql(action);
										});
									},
									(e: any) => {
										const codeLocation = `Internal Alter Password: Action Failed`;
										if (e.hasOwnProperty('codeLocations')) {
											e.codeLocations.push(codeLocation);
										} else {
											e = { error: e, codeLocations: [codeLocation] };
										}
										reje(e);
									},
									(response: any) => {
										reso();
									}
								);
							});
						});

						Promise.all(promList)
							.then(() => {
								res(db);
							})
							.catch(e => {
								const codeLocation = `Internal Alter Password: Failed to execute actions`;
								if (e.hasOwnProperty('codeLocations')) {
									e.codeLocations.push(codeLocation);
								} else {
									e = { error: e, codeLocations: [codeLocation] };
								}
								rej(e);
							});
					});
				};

				const getData = (db: any): Promise<any> => {
					return new Promise<any>((res, rej) => {
						let data: any = {};
						const tables: string[] = Object.keys(tableConfig);
						const promList = tables.map(table => {
							return new Promise<any>((reso, reje) => {
								db.transaction((tx: any) => {
									tx.executeSql(
										`SELECT * FROM ${table}`,
										[],
										(tx: any, rs: any) => {
											let dat: any[] = [];
											let i = 0;
											if (rs.rows && rs.rows.length) {
												while (i < rs.rows.length) {
													dat.push(rs.rows.item(i));
													i++;
												}
											}
											if (dat.length) {
												data[table] = dat;
											}
											reso();
										},
										(e: any) => {
											const codeLocation = `Internal Get Data: Failed to retrieve data from ${
												table
												}`;
											if (e.hasOwnProperty('codeLocations')) {
												e.codeLocations.push(codeLocation);
											} else {
												e = { error: e, codeLocations: [codeLocation] };
											}
											reje(e);
										}
									);
								});
							});
						});

						Promise.all(promList)
							.then(() => {
								res(data);
							})
							.catch(e => {
								const codeLocation = `Internal Get Data: Failed to retrieve info from tables`;
								if (e.hasOwnProperty('codeLocations')) {
									e.codeLocations.push(codeLocation);
								} else {
									e = { error: e, codeLocations: [codeLocation] };
								}
								rej(e);
							});
					});
				};

				const importData = (db: any, data: any): Promise<void> => {
					return new Promise<void>((res, rej) => {
						const tables = Object.keys(data);
						const actionsNested = tables.map(table => {
							const rows: any[] = data[table];
							const statements = rows.map(row => {
								const keys = Object.keys(row);

								let columns = '(';
								let params = '(';
								let dat: any[] = [];

								keys.forEach((key, i) => {
									if (key != 'password') {
										columns += key;
										params += '?';
										dat.push(row[key]);
										columns += ', ';
										params += ', ';
									}
								});
								columns = columns.substr(0, columns.length - 2);
								params = params.substr(0, params.length - 2);
								columns += ')';
								params += ')';

								return [`INSERT INTO ${table} ${columns} VALUES ${params}`, dat];
							});
							return statements;
						});
						const actions: any[] = [];
						actionsNested.forEach((actionList: any[]) => {
							actionList.forEach(action => {
								actions.push(action);
							});
						});
						db.sqlBatch(
							actions,
							function (response: any) {
								submitLog(Strings.Log.Info, `Data stored successfully into db`);
								res();
							},
							function (e: any) {
								const codeLocation = `Internal Import Data: Import Actions Failed`;
								if (e.hasOwnProperty('codeLocations')) {
									e.codeLocations.push(codeLocation);
								} else {
									e = { error: e, codeLocations: [codeLocation] };
								}
								rej(e);
							}
						);
					});
				};

				const openDatabase = (keyToTry: string): Promise<any> => {
					return new Promise<any>((res, rej) => {
						try {
							(window as any).sqlitePlugin.openDatabase(
								{
									name: tableName,
									location: 'default',
									key: keyToTry,
								},
								(db: any) => {
									res(db);
								},
								(e: any) => {
									const codeLocation = `Internal Open Database: Open Database Failed`;
									if (e.hasOwnProperty('codeLocations')) {
										e.codeLocations.push(codeLocation);
									} else {
										e = { error: e, codeLocations: [codeLocation] };
									}
									rej(e);
								}
							);
						} catch (e) {
							const codeLocation = `Internal Open Database: Catch Open Database`;
							if (e.hasOwnProperty('codeLocations')) {
								e.codeLocations.push(codeLocation);
							} else {
								e = { error: e, codeLocations: [codeLocation] };
							}
							rej(e);
						}
					});
				};

				const closeDatabase = (db: any): Promise<void> => {
					return new Promise<any>((res, rej) => {
						try {
							setTimeout(() => {
								db.close(
									() => {
										res();
									},
									() => {
										res();
									}
								);
							}, 500);
						} catch (e) {
							const codeLocation = `Internal Close Database: Catch Closing Database`;
							if (e.hasOwnProperty('codeLocations')) {
								e.codeLocations.push(codeLocation);
							} else {
								e = { error: e, codeLocations: [codeLocation] };
							}
							rej(e);
						}
					});
				};

				const deleteDatabase = (db: any, keyUsed: string): Promise<void> => {
					return new Promise<any>((res, rej) => {
						try {
							(window as any).sqlitePlugin.deleteDatabase(
								{
									name: tableName,
									location: 'default',
									key: keyUsed,
								},
								() => {
									res();
								},
								(e: any) => {
									const codeLocation = `Internal Delete Database: Delete Database Failed`;
									if (e.hasOwnProperty('codeLocations')) {
										e.codeLocations.push(codeLocation);
									} else {
										e = { error: e, codeLocations: [codeLocation] };
									}
									rej(e);
								}
							);
						} catch (e) {
							const codeLocation = `Internal Delete Database: Catch Delete Database`;
							if (e.hasOwnProperty('codeLocations')) {
								e.codeLocations.push(codeLocation);
							} else {
								e = { error: e, codeLocations: [codeLocation] };
							}
							rej(e);
						}
					});
				};

				const deleteOldDatabaseWithoutKey = (): Promise<void> => {
					return new Promise<any>((res, rej) => {
						try {
							(window as any).sqlitePlugin.deleteDatabase(
								{
									name: tableName,
									location: 'default',
								},
								() => {
									res();
								},
								(e: any) => {
									const codeLocation = `Internal Delete Old Database Without Key: Delete Database Failed`;
									if (e.hasOwnProperty('codeLocations')) {
										e.codeLocations.push(codeLocation);
									} else {
										e = { error: e, codeLocations: [codeLocation] };
									}
									rej(e);
								}
							);
						} catch (e) {
							const codeLocation = `Internal Delete Old Database Without Key: Catch Delete Database`;
							if (e.hasOwnProperty('codeLocations')) {
								e.codeLocations.push(codeLocation);
							} else {
								e = { error: e, codeLocations: [codeLocation] };
							}
							rej(e);
						}
					});
				};

				const openOldDatabase = async () => {
					let failedAttempts = 0;
					let e: any;
					for (let i = 0; i < oldKeys.length; i++) {
						try {
							let db = await openDatabase(oldKeys[i]);
							await closeDatabase(db);
							db = await openDatabase(oldKeys[i]);
							return { db, keyUsed: oldKeys[i] };
						} catch (er) {
							e = er;
							failedAttempts++;
						}
					}
					const codeLocation = `Internal Open Old Database: Failed Retries`;
					if (e.hasOwnProperty('codeLocations')) {
						e.codeLocations.push(codeLocation);
					} else {
						e = { error: e, codeLocations: [codeLocation] };
					}
					throw e;
				};

				const openNewDatabase = async () => {
					try {
						let db = await openDatabase(key);
						await closeDatabase(db);
						db = await openDatabase(key);
						return db;
					} catch (e) {
						const codeLocation = `Internal Open New Database: Catch Operations`;
						if (e.hasOwnProperty('codeLocations')) {
							e.codeLocations.push(codeLocation);
						} else {
							e = { error: e, codeLocations: [codeLocation] };
						}
						throw e;
					}
				};

				const rekey = async (): Promise<{
					db: any;
					password: string;
					resetDatabase: boolean;
				}> => {
					let data: any = undefined;
					let password: string = '';
					let resetDatabase: boolean = false;
					try {
						const { db, keyUsed } = await openOldDatabase();
						try {
							await new Promise<void>((res, _r) => {
								setTimeout(() => {
									res();
								}, 500);
							});
							data = await getData(db);
							password = await retrievePassword(db);
							await closeDatabase(db);
							await deleteDatabase(db, keyUsed);
						} catch (e) {
							const codeLocation = `Internal Rekey: Catch Retrieving Data from Old Database`;
							if (e.hasOwnProperty('codeLocations')) {
								e.codeLocations.push(codeLocation);
							} else {
								e = { error: e, codeLocations: [codeLocation] };
							}
							throw e;
						}
					} catch (e) {
						const codeLocation = `Internal Rekey: Old Database Operations`;
						if (e.hasOwnProperty('codeLocations')) {
							e.codeLocations.push(codeLocation);
						} else {
							e = { error: e, codeLocations: [codeLocation] };
						}
						submitLog(Strings.Log.Warn, `Internal Configure SQL Cipher error`, {
							error: e.error,
							trace: e.codeLocations,
						});
						try {
							resetDatabase = true;
							await deleteOldDatabaseWithoutKey();
						} catch (er) {
							const innerCodeLocation = `Internal Rekey: Catch Delete Old Database Without Key`;
							if (er.hasOwnProperty('codeLocations')) {
								er.codeLocations.push(codeLocation);
								er.codeLocations.push(innerCodeLocation);
							} else {
								er = {
									error: er,
									codeLocations: [codeLocation, innerCodeLocation],
								};
							}
							throw er;
						}
					}
					try {
						const newDb = await openNewDatabase();
						await initialize(newDb);
						if (data) {
							await importData(newDb, data);
						}
						return {
							db: newDb,
							password: password,
							resetDatabase: resetDatabase,
						};
					} catch (e) {
						const codeLocation = `Internal Rekey: Catch New Database Operations`;
						if (e.hasOwnProperty('codeLocations')) {
							e.codeLocations.push(codeLocation);
						} else {
							e = { error: e, codeLocations: [codeLocation] };
						}
						throw e;
					}
				};

				const moveToSecureStorage = async (db: any): Promise<string> => {
					try {
						const password = await retrievePassword(db);
						await alterPassword(db);
						return password;
					} catch (e) {
						const codeLocation = `Internal Move To Secure Storage: Catch Password Logic`;
						if (e.hasOwnProperty('codeLocations')) {
							e.codeLocations.push(codeLocation);
						} else {
							e = { error: e, codeLocations: [codeLocation] };
						}
						throw e;
					}
				};

				try {
					(window as any).sqlitePlugin.openDatabase(
						{
							name: tableName,
							location: 'default',
							key: key,
						},
						(db: any) => {
							initialize(db)
								.then(() => {
									moveToSecureStorage(db)
										.then((password: string) => {
											resolve({
												db: db,
												password: password,
												resetDatabase: false,
											});
										})
										.catch(e => {
											const codeLocation = `Catch Move To Secure Storage`;
											if (e.hasOwnProperty('codeLocations')) {
												e.codeLocations.push(codeLocation);
											} else {
												e = { error: e, codeLocations: [codeLocation] };
											}
											reject(e);
										});
								})
								.catch((e: any) => {
									const codeLocation = `Catch Initialize`;
									if (e.hasOwnProperty('codeLocations')) {
										e.codeLocations.push(codeLocation);
									} else {
										e = { error: e, codeLocations: [codeLocation] };
									}
									reject(e);
								});
						},
						() => {
							rekey()
								.then(output => {
									resolve({
										db: output.db,
										password: output.password,
										resetDatabase: output.resetDatabase,
									});
								})
								.catch((e: any) => {
									const codeLocation = `Catch Rekey`;
									if (e.hasOwnProperty('codeLocations')) {
										e.codeLocations.push(codeLocation);
									} else {
										e = { error: e, codeLocations: [codeLocation] };
									}
									reject(e);
								});
						}
					);
				} catch (e) {
					const codeLocation = `Catch Main Open Database`;
					if (e.hasOwnProperty('codeLocations')) {
						e.codeLocations.push(codeLocation);
					} else {
						e = { error: e, codeLocations: [codeLocation] };
					}
					reject(e);
				}
			}
		);
	}

	export const storeUserId = (userId: string) => SQLCipher.sqlBatch(
		[
			'DELETE FROM ac_userid',
			['INSERT INTO ac_userid VALUES (?)', [userId.toUpperCase().trim()]],
		],
		function (response: any) {
			return response;
		},
		function (error: any) {
			submitLog(Strings.Log.Error, `Error storing user id in SQL Cipher`, { error });
		}
	);


	export const storeCredentials = (credentials: LoginCredentials) => SQLCipher.sqlBatch(
		[
			'DELETE FROM ac_credentials',
			[
				'INSERT INTO ac_credentials VALUES (?)',
				[credentials.username.trim()],
			],
		],
		function (response: any) {
			if (SecureStorage) {
				SecureStorage.set(
					function (_key: any) {
						submitLog(Strings.Log.Info, Log_Operation_Succesful([Strings.Context.Cordova_Db], Strings.Op.Updated, _key));
					},
					function (_error: any) {
						submitLog(Strings.Log.Warn, Log_Operation_Failed([Strings.Context.Cordova_Db], Strings.Op.Updated, _error));
					},
					Strings.Cordova.SecureStoragePasswordKey,
					credentials.password.trim()
				);
			}
		},
		function (error: any) {
			submitLog(Strings.Log.Error, Strings.Cordova.ErrorStoringUsername, { error, });
		}
	);


	export function storeJwt(jwt: string) {
		if (jwt.length > 0) {
			if (SQLCipher && jwt) {
				SQLCipher.sqlBatch(
					['DELETE FROM jwt', ['INSERT INTO jwt VALUES (?)', [jwt]]],
					function () {
						submitLog(Strings.Log.Info, `JWT stored successfully in SQL cipher`);
					},
					function (error: any) {
						submitLog(Strings.Log.Warn, `Error saving jwt in SQL ipher`, { error });
					}
				);
			}
		} else {
			clearJwt();
		}
	}

	export function clearJwt(): Promise<any> {
		return new Promise((resolve, reject) => {
			if (SQLCipher) {
				SQLCipher.sqlBatch(
					['DELETE FROM jwt'],
					function () {
						resolve();
					},
					function (error: any) {
						submitLog(Strings.Log.Warn, `Error deleting jwt from SQL Cipher`, { error });
						resolve();
					}
				);
			} else {
				window.sessionStorage.removeItem('jwt');
				resolve();
			}
		});
	}

	export function getCredentials(): Promise<LoginCredentials> {
		return new Promise<LoginCredentials>((resolve, reject) => {
			if (SQLCipher) {
				SQLCipher.transaction((tx: any) => {
					tx.executeSql(
						'SELECT username FROM ac_credentials',
						[],
						(tx: any, rs: any) => {
							if (rs.rows.length) {
								let password = '';
								const username = rs.rows.item(0).username;
								if (SecureStorage) {
									SecureStorage.get(
										function (value: string) {
											password = value;
											resolve({ username, password });
										},
										function (_error: any) {
											resolve({ username, password });
										},
										Strings.Cordova.SecureStoragePasswordKey
									);
								} else {
									resolve({ username, password });
								}
							} else {
								resolve({ username: '', password: '' });
							}
						},
						(tx: any, error: any) => {
							resolve({ username: '', password: '' });
						}
					);
				});
			} else {
				resolve({ username: '', password: '' });
			}
		});
	}

	export function clearCredentials() {
		if (SQLCipher) {
			SQLCipher.transaction((tx: any) => {
				tx.executeSql(
					'DELETE FROM ac_credentials',
					[],
					(tx: any, rs: any) => { },
					(tx: any, error: any) => {
						submitLog(
							Strings.Log.Warn,
							'Failed to delete username and password from device'
						);
					}
				);
			});
		}
	}
}

export default sql_cipher;