import React from 'react';
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      position: "absolute"
    },
   
  }));
  
interface Props {
    open: boolean;
}
export const PageLoader: React.FC<Props> = ({ open }: Props) => {
    const styles = useStyles();
    return (
        <Backdrop
            className={styles.backdrop}
            open={open} >
                
            <CircularProgress size={40} />
        </Backdrop>
    );
};

