import { isType } from 'typescript-fsa';
import { isEqual } from 'lodash';
import {
	TeamViewParams,
	GetTeamViewActivity,
	SetTeamViewDownlineAgent,
	GetTeamViewLeads,
	GetTeamViewProduction,
	GetTeamViewQuality,
	GetTeamView206020,
	GetTeamViewNewAgentConversion,
	GetWritingAgentStats,
	GetTeamViewSummaryReport,
	GetActiveSalesLeaderCount,
	GetProfessionalAgents,
	ShowChildren,
	GetAllTeamViewData,
} from '../actions/teamview_actions';
import { Columns } from '../components/utility/expandable_table_row';
import { ClearCaches } from '../actions/authentication_actions';
import moment from 'moment';
import { DownlineAgent } from './product_reducer';
import { EMPTY_DOWNLINE_AGENT } from '../utilities/empty_entities';
import { Logout } from '../actions/authentication_actions';
import { Strings } from '../assets/common/strings';
import { GraphDataFilterValues } from './performance_reducer';

export interface TeamViewTable {
	cardTable: TeamViewRow[];
	isLoading: boolean;
}

export interface TeamViewYTDGraphs {
	graphs: TeamViewYTDGraph[];
	isLoading: boolean;
}
export interface TeamViewYTDGraph {
	title: string;
	data: StackedGraphDataPoint[]
	stats: StackedGraphStats;
	unit: string;
}

export interface StackedGraphDataPoint {
	label: string;
	week: number;
	value1: number;
	value2: number;
	value3?: number;
	value4?: number;
}

export interface StackedGraphStats {
	actual: string;
	rank: string;
	slPercent: string;
}

export interface TeamViewLeadsGraphs {
	graphs?: TeamViewLeadsGraph[];
	isLoading: boolean;
	// params?: TeamViewParams;
}
export interface TeamViewLeadsGraph {
	title: string;
	type: string;
	dataPoint: number;
	result: string;
	slAvg: string;
	taken: string;
	unit: string;
}

export interface WritingAgentState {
	report: WritingAgentReport,
	isLoading: boolean,
}
export interface WritingAgentReport {
	table: TeamViewRow[] | null,
	graph: WritingAgentGraph | null,
}

interface WritingAgentGraph {
	title?: string;
	data: StackedGraphDataPoint[]
}

export interface TeamViewDataValue {
	count?: number,
	isLoading: boolean,
}

export interface TeamViewRow {
	agentcode: string;
	results: Columns;
	children: TeamViewRow[];
	reports: number;
	terminated: boolean;
	hideChildren?: boolean;
	HasData?: boolean;
}

export interface TeamViewState {
	downlineAgent: DownlineAgent;
	filter: GraphDataFilterValues;
	activityCard: TeamViewTable;
	leadCard: TeamViewTable;
	productionCard: TeamViewTable;
	qualityCard: TeamViewTable;
	twentySixtyTwentyCard: TeamViewTable;
	newAgentConversionCard: TeamViewTable;
	writingAgentCard: WritingAgentState;
	summaryCard: TeamViewTable;
	activeSalesLeaderCount: TeamViewDataValue;
	professionalAgentsCard: TeamViewTable;
}

export const getDefaultTeamViewParams = (): TeamViewParams => ({
	startDate: moment()
		.subtract(11, 'months')
		.startOf('month')
		.toDate(),
	endDate: moment()
		.endOf('day')
		.toDate(),
	utcOffset: moment().utcOffset(),
	lineOfBusiness: 'all',
	comparePrevious: false,
});

export const initialTeamViewFilter: GraphDataFilterValues = (() => {
	const defaultParams = getDefaultTeamViewParams();
	return {
		dateRange: Strings.DateSelector.Last30Days,
		startDate: defaultParams.startDate,
		endDate: defaultParams.endDate,
		lineOfBusiness: defaultParams.lineOfBusiness ? defaultParams.lineOfBusiness : 'all',
		comparePrevious: defaultParams.comparePrevious ? defaultParams.comparePrevious : false,
	};
})();

const insertChildrenForAgent = (
	cachedData: TeamViewRow[],
	agentData: TeamViewRow[],
	agentCode: string | undefined,
): TeamViewRow[] => {
	if (!agentCode) {
		return agentData;
	}

	for (var i = 0; i < cachedData.length; i++) {
		if (cachedData[i].agentcode === agentCode) {
			cachedData[i] = {
				...cachedData[i],
				children: [...agentData],
			}
		} else {
			insertChildrenForAgent(cachedData[i].children, agentData, agentCode);
		}
	}

	return cachedData;
}

const showChildrenInsert = (
	cachedData: TeamViewRow[],
	agentCode: string | undefined,
): TeamViewRow[] => {
	if (!agentCode) {
		return cachedData;
	}

	for (var i = 0; i < cachedData.length; i++) {
		if (cachedData[i].agentcode === agentCode) {
			cachedData[i] = {
				...cachedData[i],
				hideChildren: false,
			}
		} else {
			showChildrenInsert(cachedData[i].children, agentCode);
		}
	}

	return cachedData;
}

const initialState: TeamViewState = {
	downlineAgent: EMPTY_DOWNLINE_AGENT,
	filter: initialTeamViewFilter,
	activityCard: { cardTable: [], isLoading: false },
	leadCard: { cardTable: [] , isLoading: false },
	productionCard: { cardTable: [], isLoading: false },
	qualityCard: { cardTable: [], isLoading: false },
	twentySixtyTwentyCard: { cardTable: [], isLoading: false },
	newAgentConversionCard: { cardTable: [], isLoading: false },
	writingAgentCard: { report: { table: null , graph: null}, isLoading: false },
	summaryCard: { cardTable: [], isLoading: false },
	activeSalesLeaderCount: { count: 105, isLoading: false },
	professionalAgentsCard: { cardTable: [], isLoading: false },
};

export const teamviewReducer = (state = initialState, action: any) => {
	if (isType(action, GetTeamViewActivity.started)) {
		return {
			...state,
			activityCard: {
				...state.activityCard,
				isLoading: true,
			},
		}
	} else if (isType(action, GetTeamViewActivity.done)) {
		if (isEqual(state.activityCard, action.payload.result)) {
			return state;
		}
		let updatedCardTable = {};
		if (state.activityCard.cardTable) {
			updatedCardTable = insertChildrenForAgent(
				state.activityCard.cardTable.slice(),
				action.payload.result,
				action.payload.params.agentCode,
			)
		}
		return {
			...state,
			activityCard: {
				isLoading: false,
				cardTable: updatedCardTable,
			},
		};
	} else if (isType(action, GetTeamViewLeads.started)) {
		return {
			...state,
			leadCard: {
				...state.leadCard,
				isLoading: true,
			},
		}
	} else if (isType(action, GetTeamViewLeads.done)) {
		if (isEqual(state.leadCard, action.payload.result)) {
			return state;
		}
		var updatedCardTable = {};
		if (state.leadCard.cardTable) {
			updatedCardTable = insertChildrenForAgent(
				state.leadCard.cardTable.slice(),
				action.payload.result,
				action.payload.params.agentCode,
			)
		}
		return {
			...state,
			leadCard: {
				isLoading: false,
				cardTable: updatedCardTable,
			},
		};
	} else if (isType(action, GetTeamViewProduction.started)) {
		return {
			...state,
			productionCard: {
				...state.productionCard,
				isLoading: true,
			},
		}
	} else if (isType(action, GetTeamViewProduction.done)) {
		if (isEqual(state.productionCard, action.payload.result)) {
			return state;
		}
		var updatedCardTable = {};
		if (state.productionCard.cardTable) {
			updatedCardTable = insertChildrenForAgent(
				state.productionCard.cardTable.slice(),
				action.payload.result,
				action.payload.params.agentCode,
			)
		}
		return {
			...state,
			productionCard: {
				isLoading: false,
				cardTable: updatedCardTable,
			},
		};
	} else if (isType(action, GetTeamViewQuality.started)) {
		return {
			...state,
			qualityCard: {
				...state.qualityCard,
				isLoading: true,
			},
		}
	} else if (isType(action, GetTeamViewQuality.done)) {
		if (isEqual(state.qualityCard, action.payload.result)) {
			return state;
		}
		var updatedCardTable = {};
		if (state.qualityCard.cardTable) {
			updatedCardTable = insertChildrenForAgent(
				state.qualityCard.cardTable.slice(),
				action.payload.result,
				action.payload.params.agentCode,
			)
		}
		return {
			...state,
			qualityCard: {
				isLoading: false,
				cardTable: updatedCardTable,
			},
		};
	} else if (isType(action, GetTeamView206020.started)) {
		return {
			...state,
			twentySixtyTwentyCard: {
				...state.twentySixtyTwentyCard,
				isLoading: true,
			},
		}
	} else if (isType(action, GetTeamView206020.done)) {
		if (isEqual(state.twentySixtyTwentyCard, action.payload.result)) {
			return state;
		}
		const updated206020CardTable = action.payload.result;

		return {
			...state,
			twentySixtyTwentyCard: {
				isLoading: false,
				cardTable: updated206020CardTable,
			},
		};
	} else if (isType(action, GetTeamViewNewAgentConversion.started)) {
		return {
			...state,
			newAgentConversionCard: {
				...state.newAgentConversionCard,
				isLoading: true,
			},
		}
	} else if (isType(action, GetTeamViewNewAgentConversion.done)) {
		if (isEqual(state.newAgentConversionCard, action.payload.result)) {
			return state;
		}
		const updatedNewAgentConversionCardTable = action.payload.result;

		return {
			...state,
			newAgentConversionCard: {
				isLoading: false,
				cardTable: updatedNewAgentConversionCardTable,
			},
		};
	} else if (isType(action, GetWritingAgentStats.started)) {
		return {
			...state,
			writingAgentCard: {
				...state.writingAgentCard,
				isLoading: true,
			},
		}
	} else if (isType(action, GetWritingAgentStats.done)) {
		if (isEqual(state.writingAgentCard, action.payload.result)) {
			return state;
		}
		const updatedWritingAgentCard = action.payload.result;

		return {
			...state,
			writingAgentCard: {
				isLoading: false,
				report: updatedWritingAgentCard,
			},
		};
	} else if (isType(action, GetTeamViewSummaryReport.started)) {
		return {
			...state,
			summaryCard: {
				...state.summaryCard,
				isLoading: true,
			},
		}
	} else if (isType(action, GetTeamViewSummaryReport.done)) {
		if (isEqual(state.summaryCard, action.payload.result)) {
			return state;
		}
		const updatedSummary = action.payload.result;

		return {
			...state,
			summaryCard: {
				isLoading: false,
				cardTable: updatedSummary,
			},
		};
	} else if (isType(action, GetActiveSalesLeaderCount.started)) {
		return {
			...state,
			activeSalesLeaderCount: {
				...state.activeSalesLeaderCount,
				isLoading: true,
			},
		}
	} else if (isType(action, GetActiveSalesLeaderCount.done)) {
		if (isEqual(state.activeSalesLeaderCount, action.payload.result)) {
			return state;
		}
		const updatedCount = action.payload.result;

		return {
			...state,
			activeSalesLeaderCount: {
				isLoading: false,
				count: updatedCount,
			},
		};
	} else if (isType(action, GetProfessionalAgents.started)) {
		return {
			...state,
			professionalAgentsCard: {
				...state.professionalAgentsCard,
				isLoading: true,
			},
		}
	} else if (isType(action, GetProfessionalAgents.done)) {
		if (isEqual(state.professionalAgentsCard, action.payload.result)) {
			return state;
		}
		const updatedprofessionalAgents = action.payload.result;

		return {
			...state,
			professionalAgentsCard: {
				isLoading: false,
				cardTable: updatedprofessionalAgents,
			},
		};
	} else if (isType(action, ShowChildren)) {
		let updatedCardTable = {};
		let cardName = '';
		switch (action.payload.card) {
			case Strings.TeamView.ACTIVITIES:
				cardName = Strings.TeamView.ACTIVITY_CARD;
				updatedCardTable = showChildrenInsert(
					state.activityCard.cardTable.slice(),
					action.payload.agentCode,
				)
				break;
			case Strings.TeamView.LEAD_PERFORMANCE:
				cardName = Strings.TeamView.LEAD_CARD;
				updatedCardTable = showChildrenInsert(
					state.leadCard.cardTable.slice(),
					action.payload.agentCode,
				)
				break;
			case Strings.TeamView.PRODUCTION:
				cardName = Strings.TeamView.PRODUCTION_CARD;
				updatedCardTable = showChildrenInsert(
					state.productionCard.cardTable.slice(),
					action.payload.agentCode,
				)
				break;
			case Strings.TeamView.QUALITY:
				cardName = Strings.TeamView.QUALITY_CARD;
				updatedCardTable = showChildrenInsert(
					state.qualityCard.cardTable.slice(),
					action.payload.agentCode,
				)
				break;
			default:
				return state;
		}

		return {
			...state,
			[cardName]: {
				isLoading: false,
				cardTable: updatedCardTable,
			},
		};
	} else if (isType(action, GetAllTeamViewData)) {
		return {
			...state,
			filter: {
				...action.payload,
			},
		};
	} else if (isType(action, SetTeamViewDownlineAgent)) {
		return {
			...state,
			downlineAgent: action.payload.agent || EMPTY_DOWNLINE_AGENT,
		};
	} else if (isType(action, Logout.started)) {
		return {
			...initialState,
		};
	} else if (isType(action, ClearCaches)) {
		return {
			...initialState,
		};
	}
	return state;
};
