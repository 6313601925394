import React from 'react';
import moment from 'moment';
import {
	Button,
	Chip,
} from '@material-ui/core';
import {
	GraphDataFilterForm,
	GRAPH_DATA_FORM,
} from './graph_data_filter_form';
import { getFormValues } from 'redux-form';
import { connect } from '@optum-uhone-hmkts/rise';
import { getFormSyncErrors } from 'redux-form';
import { DownlineAgent } from '../../reducers/product_reducer';
import { AgentSelectorComponent } from '../agent/agent_selector';
import { isAgentRole } from '../../utilities/agent_util';
import { GraphDataFilterValues } from '../../reducers/performance_reducer';
import { Strings } from '../../assets/common/strings';
import { fullName } from '../../assets/common/string_builders';
import { enforceStylesType } from '../../utilities/styles_util';
import { capitalize } from '../../assets/common/string_builders/format_string_builders';
import { ListFilter } from '../filter/list_filter';
import { AppState } from '../../reducers';
import { AgentAvatar } from '../agent/agent_avatar';

interface ComponentProps {
	filterValues: GraphDataFilterValues;
	applyFilters: (
		formValues: GraphDataFilterValues,
		formErrors: any,
		agent?: any
	) => void;
	downlineAgent: DownlineAgent;
	title: string;
	setDownlineAgent: (agent?: DownlineAgent) => void;
	showLineOfBusinessField?: boolean;
	showComparisonField?: boolean;
	viewDownlineDisabled?: boolean;
}
interface StateProps {
	agentCode: string;
	formValues: GraphDataFilterValues;
	formErrors: any | undefined;
}

type Props = ComponentProps & StateProps;
interface State {
	showAgentSelectDialog: boolean;
}

class GraphDataFilterComponent extends React.PureComponent<Props, State> {
	state: State = {
		showAgentSelectDialog: false,
	};

	viewDownline = isAgentRole([
		Strings.ADRoles.SVP,
		Strings.ADRoles.TVP,
		Strings.ADRoles.SalesLeader,
		Strings.ADRoles.Assistant,
	]);

	toggleAgentSelectorDialog = () => {
		this.setState(prevState => ({
			showAgentSelectDialog: !prevState.showAgentSelectDialog,
		}));
	};

	selectDownlineAgent = (agent: any) => {
		this.props.setDownlineAgent(agent);
		this.toggleAgentSelectorDialog();
	};

	removeDownlineAgent = () => {
		this.props.setDownlineAgent();
	};

	search = () => {
		const { formValues, formErrors } = this.props;
		this.props.applyFilters(formValues, formErrors);
	};

	formatSubtitle = (start: Date, end: Date) => {
		const dateFormat = 'MM/DD/YYYY';
		return `${moment(start).format(dateFormat)} - ${moment(end).format(
			dateFormat
		)}`;
	};

	render() {
		const { 
			filterValues, formValues, formErrors, agentCode, downlineAgent, 
			showLineOfBusinessField: showLineOfBusiness, viewDownlineDisabled = false,
		} = this.props;
		const style = getStyles();

		const initialValues: GraphDataFilterValues = {
			dateRange: formValues ? formValues.dateRange : filterValues.dateRange,
			startDate: formValues
				? moment(formValues.startDate).toDate()
				: filterValues.startDate,
			endDate: formValues
				? moment(formValues.endDate).toDate()
				: filterValues.endDate,
			lineOfBusiness: formValues ? formValues.lineOfBusiness : filterValues.lineOfBusiness,
			comparePrevious: formValues ? formValues.comparePrevious : filterValues.comparePrevious,
		};
		let subtitle =
			initialValues.dateRange === Strings.DateSelector.CustomDateRange
				? this.formatSubtitle(initialValues.startDate, initialValues.endDate)
				: initialValues.dateRange;
		if (showLineOfBusiness && formValues && formValues.lineOfBusiness) {
			subtitle += ', ' + Strings.GraphDataFilter.LineOfBusiness + ' - ' + capitalize(formValues.lineOfBusiness);
		}
		else if (showLineOfBusiness && filterValues && filterValues.lineOfBusiness) {
			subtitle += ', ' + Strings.GraphDataFilter.LineOfBusiness + ' - ' + capitalize(filterValues.lineOfBusiness);
		}

		return (
			<ListFilter
				drawerTitle={this.props.title}
				onSearch={this.search}
				cardSubtitle={subtitle}
				drawerSubtitle={subtitle}
				isSearchDisabled={!!formErrors && Object.keys(formErrors).length > 0}
				customContent={this.viewDownline && !viewDownlineDisabled && (
					<div style={style.lookUpAgentButton}>
						{downlineAgent.agentCode &&
							downlineAgent.agentCode !== agentCode ? (
								<Chip
									label={fullName(downlineAgent)}
									avatar={<AgentAvatar agent={downlineAgent} />}
									onDelete={this.removeDownlineAgent}
									style={style.chipStyle}
								/>
							) : (
								<Button onClick={this.toggleAgentSelectorDialog}>
									{Strings.GraphDataFilter.LookUpAgent}
								</Button>
							)}
					</div>
				)}
			>
				<GraphDataFilterForm
					initialValues={initialValues}
					formValues={formValues}
					showLineOfBusinessField={this.props.showLineOfBusinessField}
					showComparisonField={this.props.showComparisonField}
				/>
				<AgentSelectorComponent
					visible={this.state.showAgentSelectDialog}
					currentAgentCode={agentCode}
					onSave={this.selectDownlineAgent}
					onCancel={this.toggleAgentSelectorDialog}
					headerText={Strings.GraphDataFilter.FindAgent}
					saveText={Strings.GraphDataFilter.View}
					downlineOnly={true}
				/>
			</ListFilter>
		);
	}
}

const getStyles = () => enforceStylesType({
	lookUpAgentButton: {
		paddingLeft: 20,
	},
	chipStyle: {
		marginBottom: 10,
	},
});

const mapStateToProps = (state: AppState): StateProps => ({
	agentCode: state.user.agentID,
	formValues: getFormValues<GraphDataFilterValues>(GRAPH_DATA_FORM)(state),
	formErrors: getFormSyncErrors(GRAPH_DATA_FORM)(state),
});

export const GraphDataFilter = connect<StateProps, {}, ComponentProps>(
	mapStateToProps,
	undefined,
	true
)(GraphDataFilterComponent);
