import {
	GetAgentTemplates,
	GetCorporateTemplates,
} from '../../actions/template_actions';
import { EventDetailCard } from '../../components/email_automation/event_detail_card';
import { SnackbarProps, QueueSnackbar } from '../../actions/snackbar_actions';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { navigateBack, navigateTo } from '../../actions/navigation_actions';
import { NavigationProps, navRoutes } from '../../components/nav/Routes';
import { Template } from '../../reducers/template_reducer';
import { Loaded } from '../../utilities/utilities';
import { AppState } from '../../reducers/index';
import { themePalette } from '../../utilities/branding';
import { connect } from '@optum-uhone-hmkts/rise';
import React from 'react';
import _ from 'lodash';
import {
	EmailAutomation,
	EmailAutomationGroup,
	EmailAutomationEvent,
	EmailAutomationTemplateInfo,
} from '../../reducers/email_automation_reducer';
import {
	getEmailAutomationsByAgentId,
	postEmailAutomation,
} from '../../actions/email_automation_actions';
import {
	Card,
	DialogTitle,
	Dialog,
	DialogActions,
	Button,
	IconButton,
	Icon,
	Grid,
	Tabs,
	Tab,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Divider,
	Popover,
	MenuItem,
} from '@material-ui/core';
import { isDesktop } from '../../utilities/is_mobile';
import { Strings } from '../../assets/common/strings';
import { Lookup, Lookups } from '../../utilities/lookup';

interface EventTitle {
	tabName: MajorIndex;
	displayTitle: string;
	subtitle: string;
	isLob: boolean;
	currentTab: number;
	iconName: string;
}

interface EventTitleBundle {
	working: EventTitle;
	lost: EventTitle;
	active: EventTitle;
	terminated: EventTitle;
}

export const tabInfo: EventTitleBundle = {
	active: {
		tabName: 'Active',
		displayTitle: 'Active Products',
		subtitle: 'The following emails are sent out on a recurring basis to contacts with a matched Active Product.',
		isLob: false,
		currentTab: 2,
		iconName: 'done_outline',
	},
	lost: {
		tabName: 'Lost',
		displayTitle: 'No Sale Leads',
		subtitle: 'The following emails are sent out on a recurring basis to contacts with a Lead marked as No Sale.',
		isLob: true,
		currentTab: 1,
		iconName: 'person_outline',
	},
	terminated: {
		tabName: 'Terminated',
		displayTitle: 'Terminated Products',
		subtitle: 'The following emails are sent out on a recurring basis to contacts with a matched Terminated Product.',
		isLob: false,
		currentTab: 3,
		iconName: 'indeterminate_check_box',
	},
	working: {
		tabName: 'Working',
		displayTitle: 'Working',
		subtitle: 'The following emails are sent one time only and are not recurring.',
		isLob: true,
		currentTab: 0,
		iconName: 'directions_run',
	},
};

type MajorIndex = 'Working' | 'Active' | 'Lost' | 'Terminated';

interface EmailAutomationStateProps {
	areEmailAutomationsLoading: boolean;
	emailAutomationBundle: EmailAutomation;
	linesOfBusiness: string[];
	agentTemplates: Template[];
	corporateTemplates: Template[];
}

interface EmailAutomationDispatchProps extends NavigationProps {
	getEmailAutomations: () => void;
	saveEmailAutomations: (emailAutomations: EmailAutomation) => void;
	getAgentByAgentCode: () => void;
	getAgentTemplates: () => void;
	getCorporateTemplates: () => void;
	getSnacks: (snackbarProps: SnackbarProps) => void;
}

type EmailAutomationProps =
	& EmailAutomationStateProps
	& EmailAutomationDispatchProps;

interface EmailAutomationState {
	anchorElement?: HTMLAnchorElement;
	agentBundle: EmailAutomationGroup;
	isCancelDialogOpen: boolean;
	isCorporateBundleDialogOpen: boolean;
	isFirstUpdate: boolean;
	isPopoverOpen: boolean;
	isSaveDialogOpen: boolean;
	isUpdateNeeded: boolean;
	tabInfo: EventTitle;
	guide: string;
	selectedEvent?: EmailAutomationEvent;
}

class EmailAutomationPage extends React.Component<EmailAutomationProps, EmailAutomationState> {
	constructor(props: EmailAutomationProps) {
		super(props);
		this.state = {
			anchorElement: undefined,
			agentBundle: props.emailAutomationBundle.Agent,
			isCancelDialogOpen: false,
			isCorporateBundleDialogOpen: false,
			isFirstUpdate: true,
			isPopoverOpen: false,
			isSaveDialogOpen: false,
			isUpdateNeeded: false,
			tabInfo: tabInfo.working,
			guide: '',
		};
	}

	componentWillMount() {
		this.props.getAgentTemplates();
		this.props.getCorporateTemplates();
		this.props.getEmailAutomations();
	}

	componentWillReceiveProps(nextProps: EmailAutomationProps) {
		if (nextProps.emailAutomationBundle.Agent != this.state.agentBundle
			&& this.state.isFirstUpdate
		) {
			this.setState({
				agentBundle: _.cloneDeep(nextProps.emailAutomationBundle.Agent),
				isFirstUpdate: false,
			});
		}
	}

	decideEventSet = (useCorporate: boolean): EmailAutomationEvent[] => {
		return useCorporate
			? this.props.emailAutomationBundle.Corporate[this.state.tabInfo.tabName]
			: this.state.agentBundle[this.state.tabInfo.tabName];
	};

	getEventIndex = (event: EmailAutomationEvent) => {
		return this.state.agentBundle[this.state.tabInfo.tabName].findIndex(
			arrayEvent => {
				return arrayEvent.id == event.id;
			}
		);
	};

	getTemplateInfoIndex = (eventIndex: number, templateInfo: EmailAutomationTemplateInfo) => {
		return this.state.agentBundle[this.state.tabInfo.tabName][eventIndex].templateInfo.findIndex(
			arrayTemplateInfo => {
				return arrayTemplateInfo.id == templateInfo.id;
			}
		);
	};

	handleAddClick = (templateInfo: EmailAutomationTemplateInfo, event: EmailAutomationEvent) => {
		const eventIndex = this.getEventIndex(event);
		if (eventIndex < 0) return;

		let tempBundle: EmailAutomationGroup = _.cloneDeep(this.state.agentBundle);
		tempBundle[this.state.tabInfo.tabName][eventIndex].templateInfo.push(templateInfo);
		this.setState({ agentBundle: tempBundle, isUpdateNeeded: true });
	};

	handleTemplateInfoEditResets = (event: EmailAutomationEvent) => {
		let tempBundle: EmailAutomationGroup = _.cloneDeep(this.state.agentBundle);
		let majorIndex = this.state.tabInfo.tabName;
		const eventIndex = this.getEventIndex(event);

		for (let i = 0; i < tempBundle[majorIndex][eventIndex].templateInfo.length; i++) {
			tempBundle[majorIndex][eventIndex].templateInfo[i].isEditting = false;
		}

		this.setState({ agentBundle: tempBundle });
	};

	handleRowDelete = (
		oldTemplateInfo: EmailAutomationTemplateInfo,
		newTemplateInfo: EmailAutomationTemplateInfo,
		event: EmailAutomationEvent
	) => {
		let tempBundle: EmailAutomationGroup = _.cloneDeep(this.state.agentBundle);

		const eventIndex = this.getEventIndex(event);
		if (eventIndex < 0) return;

		const templateInfoIndex = this.getTemplateInfoIndex(eventIndex, oldTemplateInfo);
		if (templateInfoIndex < 0) return;

		tempBundle[this.state.tabInfo.tabName][eventIndex].templateInfo[templateInfoIndex] = newTemplateInfo;

		this.setState({ agentBundle: tempBundle, isUpdateNeeded: true });
	};

	handleRowEditAdd = (
		oldTemplateInfo: EmailAutomationTemplateInfo,
		newTemplateInfo: EmailAutomationTemplateInfo,
		event: EmailAutomationEvent
	) => {
		let tempBundle: EmailAutomationGroup = _.cloneDeep(this.state.agentBundle);

		const eventIndex = this.getEventIndex(event);
		if (eventIndex < 0) return;

		const templateInfoIndex = this.getTemplateInfoIndex(eventIndex, oldTemplateInfo);
		if (templateInfoIndex < 0) return;

		tempBundle[this.state.tabInfo.tabName][eventIndex].templateInfo[templateInfoIndex] = newTemplateInfo;

		this.setState({ agentBundle: tempBundle, isUpdateNeeded: true }, () =>
			this.handleTemplateInfoEditResets(event)
		);
	};

	handleUseCorporateBundle = () => {
		let tempBundle: EmailAutomationGroup = _.cloneDeep(this.state.agentBundle);
		const majorIndex = this.state.tabInfo.tabName;

		if (this.state.guide == 'All' && tempBundle[majorIndex]) {
			for (let i = 0; i < tempBundle[majorIndex].length; i++) {
				tempBundle[majorIndex][i].templateInfo.forEach(
					templateInfo => (templateInfo.isDeleted = true)
				);

				this.props.emailAutomationBundle.Corporate[majorIndex][i].templateInfo.forEach(
					templateInfo => tempBundle[majorIndex][i].templateInfo.push(templateInfo)
				);
			}

		} else if ((majorIndex == tabInfo.active.tabName || majorIndex == tabInfo.terminated.tabName)
			&& this.state.selectedEvent
		) {
			const eventIndex = this.getEventIndex(this.state.selectedEvent);
			if (eventIndex < 0) return;

			tempBundle[majorIndex][eventIndex].templateInfo.forEach(
				templateInfo => (templateInfo.isDeleted = true)
			);

			this.props.emailAutomationBundle.Corporate[majorIndex][eventIndex].templateInfo.forEach(
				templateInfo => tempBundle[majorIndex][eventIndex].templateInfo.push(templateInfo)
			);

		} else if ((majorIndex === tabInfo.lost.tabName || majorIndex == tabInfo.working.tabName) && tempBundle[majorIndex]) {
			for (let i = 0; i < tempBundle[majorIndex].length; i++) {
				if (tempBundle[majorIndex][i].lob && tempBundle[majorIndex][i].lob == this.state.guide) {
					tempBundle[majorIndex][i].templateInfo.forEach(
						(templateInfo) => {
							templateInfo.isDeleted = true;
						}
					);

					this.props.emailAutomationBundle.Corporate[majorIndex][i].templateInfo.forEach(
						corporateTemplateInfo => tempBundle[majorIndex][i].templateInfo.push(corporateTemplateInfo)
					);
				}
			}
		}

		this.setState({
			isPopoverOpen: false,
			agentBundle: tempBundle,
			isCorporateBundleDialogOpen: false,
			selectedEvent: undefined,
			isUpdateNeeded: true,
		});
	};

	saveEmailAutomations = () => {
		const emailAutomation: EmailAutomation = {
			Corporate: this.props.emailAutomationBundle.Corporate,
			Agent: this.state.agentBundle,
		};
		this.props.saveEmailAutomations(emailAutomation);
		this.props.navigateTo( navRoutes.settings.path);
	};

	setCancelDialogClosed = () => {
		this.setState({ isCancelDialogOpen: false });
	};

	setCancelDialogOpen = () => {
		this.setState({ isCancelDialogOpen: true });
	};

	setCorporateBundleDialogClosed = () => {
		this.setState({ isCorporateBundleDialogOpen: false });
	};
	setCorporateBundleDialogOpen = () => {
		this.setState({ isCorporateBundleDialogOpen: true });
	};

	setPopoverClosed = () => {
		this.setState({ isPopoverOpen: false, anchorElement: undefined });
	};
	setPopoverOpen = (event: any) => {
		this.setState({ isPopoverOpen: true, anchorElement: event.target });
	};

	setSaveDialogClosed = () => {
		this.setState({ isSaveDialogOpen: false });
	};
	setSaveDialogOpen = () => {
		this.setState({ isSaveDialogOpen: true });
	};

	setTab0 = () => {
		this.setState({ tabInfo: tabInfo.working });
	};
	setTab1 = () => {
		this.setState({ tabInfo: tabInfo.lost });
	};
	setTab2 = () => {
		this.setState({ tabInfo: tabInfo.active });
	};
	setTab3 = () => {
		this.setState({ tabInfo: tabInfo.terminated });
	};

	toggleUpdateNeeded = () => {
		this.setState({ isUpdateNeeded: true });
	};

	triggerEdit = (templateInfo: EmailAutomationTemplateInfo, event: EmailAutomationEvent) => {
		let tempBundle: EmailAutomationGroup = _.cloneDeep(this.state.agentBundle);
		const majorIndex = this.state.tabInfo.tabName;

		const eventIndex = this.getEventIndex(event);
		if (eventIndex < 0) return;

		const templateInfoIndex = this.getTemplateInfoIndex(eventIndex, templateInfo);
		if (templateInfoIndex < 0) return;

		tempBundle[majorIndex][eventIndex].templateInfo[templateInfoIndex].isEditting = true;

		this.setState({ agentBundle: tempBundle });
	};

	renderCancelDialog = (): JSX.Element => {
		return (
			<Dialog
				fullWidth
				open={this.state.isCancelDialogOpen}
				onClose={this.setCancelDialogClosed}
			>
				<DialogTitle>
					Are you sure you want to cancel without saving?
				</DialogTitle>
				<DialogActions>
					<Button color="secondary" onClick={this.setCancelDialogClosed}>
						Stay
					</Button>
					<Button
						variant="contained"
						color="primary"
						style={{ backgroundColor: themePalette.delete_remove_reject_button }}
						onClick={() => this.props.navigateBack()}
					>
						Leave
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	renderCorporateDialog = (): JSX.Element => {
		return (
			<Dialog
				open={this.state.isCorporateBundleDialogOpen}
				onClose={this.setCorporateBundleDialogClosed}
			>
				<DialogTitle>
					Are you sure you want to reset to the Corporate Bundle? All changes
					will be lost.
				</DialogTitle>
				<DialogActions>
					<Button
						color="secondary"
						onClick={this.setCorporateBundleDialogClosed}
					>
						Return
					</Button>
					<Button
						variant="contained"
						color="primary"
						style={{ backgroundColor: themePalette.delete_remove_reject_button }}
						onClick={this.handleUseCorporateBundle}
					>
						Reset
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	renderMiddleComponent = (): JSX.Element => {
		return this.props.corporateTemplates && this.props.agentTemplates ? (
			<Card
				style={{
					margin: 20,
				}}
			>
				<Tabs
					value={this.state.tabInfo.currentTab}
					centered={true}
					variant="fullWidth"
					indicatorColor="primary"
					style={{ marginBottom: '2px' }}
				>
					<Tab
						onClick={this.setTab0}
						icon={<Icon>{tabInfo.working.iconName}</Icon>}
						label={tabInfo.working.displayTitle}
					/>
					<Tab
						onClick={this.setTab1}
						icon={<Icon>{tabInfo.lost.iconName}</Icon>}
						label={tabInfo.lost.displayTitle}
					/>
					<Tab
						onClick={this.setTab2}
						icon={<Icon>{tabInfo.active.iconName}</Icon>}
						label={tabInfo.active.displayTitle}
					/>
					<Tab
						onClick={this.setTab3}
						icon={<Icon>{tabInfo.terminated.iconName}</Icon>}
						label={tabInfo.terminated.displayTitle}
					/>
				</Tabs>

				<Grid
					container
					style={{
						borderTop: '1px solid lightgrey',
						padding: '16px 25px 0',
					}}
				>
					<Grid item xs={9}>
						<Typography variant="h5">
							{this.state.tabInfo.displayTitle}
						</Typography>
						<Typography style={{ marginBottom: '1em' }}>
							{this.state.tabInfo.subtitle}
						</Typography>
					</Grid>
					<Grid item xs={3} style={{ marginTop: '5px', textAlign: 'right' }}>
						<Popover
							onClose={this.setPopoverClosed}
							open={this.state.isPopoverOpen}
							anchorEl={this.state.anchorElement}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
						>
							{this.state.tabInfo.isLob
								? this.props.linesOfBusiness.map((lob, index) => {
									return (
										<MenuItem
											key={'EA_PO_LOB_MAP:' + lob + '_' + index}
											onClick={() =>
												this.setState({
													guide: lob,
													isCorporateBundleDialogOpen: true,
												})
											}
										>
											{lob}
										</MenuItem>
									);
								})
								: this.decideEventSet(true).map((event, index) => {
									return (
										<MenuItem
											key={
												'EA_PO_NOLOB_EVENT_MAP:' +
												event.eventName +
												'_' +
												index
											}
											onClick={() =>
												this.setState({
													guide: event.eventName,
													isCorporateBundleDialogOpen: true,
													selectedEvent: event,
												})
											}
										>
											{event.eventName}
										</MenuItem>
									);
								})}
							<MenuItem
								key={'EA_PO_All'}
								onClick={() =>
									this.setState({
										guide: 'All',
										isCorporateBundleDialogOpen: true,
									})
								}
							>
								All
							</MenuItem>
						</Popover>
						<Button
							variant="contained"
							color="primary"
							onClick={this.setPopoverOpen}
						>
							Use Corporate Bundle
						</Button>
					</Grid>
				</Grid>

				{this.state.tabInfo.isLob
					? this.props.linesOfBusiness.map((lob, index) => {
						const lobEvents: EmailAutomationEvent[] = this.decideEventSet(false)?.filter(event => event.lob && event.lob == lob);

						return (
							<Accordion key={'EA_LOB_MAIN:' + lob + '_' + index}>
								<AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
									<Typography variant="h5">{lob}</Typography>
								</AccordionSummary>
								<Divider />
								<AccordionDetails
									children={
										<Grid container>
											{lobEvents?.map((event, index) => {
												return (
													<Grid item xs={12} key={'EventDetail_LOB_' + index}>
														<EventDetailCard
															agentTemplates={this.props.agentTemplates}
															corporateTemplates={
																this.props.corporateTemplates
															}
															event={event}
															tab={this.state.tabInfo.tabName}
															onAdd={this.handleAddClick}
															queueSnackBar={this.props.getSnacks}
															onDelete={this.handleRowDelete}
															triggerEdit={this.triggerEdit}
															resetEdits={this.handleTemplateInfoEditResets}
															editRowAdd={this.handleRowEditAdd}
															triggerSave={this.toggleUpdateNeeded}
														/>
													</Grid>
												);
											})}
										</Grid>
									}
								/>
							</Accordion>
						);
					})
					: this.decideEventSet(false).map((event, index) => {
						return (
							<div style={{ margin: '16px 0' }}>
								<EventDetailCard
									key={'EventDetail_NOLOB_' + index}
									agentTemplates={this.props.agentTemplates}
									corporateTemplates={this.props.corporateTemplates}
									event={event}
									tab={this.state.tabInfo.tabName}
									onAdd={this.handleAddClick}
									queueSnackBar={this.props.getSnacks}
									onDelete={this.handleRowDelete}
									triggerEdit={this.triggerEdit}
									resetEdits={this.handleTemplateInfoEditResets}
									editRowAdd={this.handleRowEditAdd}
									triggerSave={this.toggleUpdateNeeded}
								/>
							</div>
						);
					})}
				{this.renderCorporateDialog()}
				{this.renderCancelDialog()}
			</Card>
		) : (
				<div />
			);
	};

	renderTopComponent = (): JSX.Element => {
		return (
			<HeaderBarComponent
				title={'Email Automations'}
				isLoading={
					this.props.areEmailAutomationsLoading ||
					!this.props.corporateTemplates ||
					!this.props.agentTemplates
				}
				rightButtons={
					this.state.isUpdateNeeded && (
						<>
							<Button
								variant="text"
								onClick={this.setCancelDialogOpen}
								children={<span style={{ color: themePalette.negative_text }}>{Strings.ButtonText.Cancel}</span>}
							/>
							<Button
								variant="text"
								onClick={this.saveEmailAutomations}
								children={<span style={{ color: themePalette.negative_text }}>{Strings.ButtonText.Save}</span>}
							/>
						</>
					)
				}
			/>
		);
	};

	render() {
		return (
			<BasePageContainer
				// topStyle={{ textAlign: 'center' }}
				topComponent={this.renderTopComponent()}
				middleComponent={this.renderMiddleComponent()}
			/>
		);
	}
}

const mapStateToProps = (state: AppState): EmailAutomationStateProps => {
	const corporateTemplates: Loaded<Template>[] = _.sortBy(
		state.templateManagerState.corporateTemplates,
		(template: Loaded<Template>) => {
			return template.data.title;
		}
	);

	const agentTemplates: Loaded<Template>[] = _.sortBy(
		state.templateManagerState.agentTemplates,
		(template: Loaded<Template>) => {
			return template.data.title;
		}
	);

	return {
		areEmailAutomationsLoading:
			state.emailAutomation.areEmailAutomationsLoading,
		emailAutomationBundle: state.emailAutomation.emailAutomation,
		linesOfBusiness: state.lookup.getLabels(Lookups.LeadLineOfBusiness),
		corporateTemplates: corporateTemplates.map(template => template.data),
		agentTemplates: agentTemplates.map(template => template.data),
	};
};
const mapDispatchToProps = (dispatch: any): Partial<EmailAutomationDispatchProps> => ({
	navigateBack: () => dispatch(navigateBack()),
	navigateTo: (route: string) => dispatch(navigateTo(route)),
	getEmailAutomations: () => dispatch(getEmailAutomationsByAgentId()),
	saveEmailAutomations: (emailAutomations: EmailAutomation) => dispatch(postEmailAutomation(emailAutomations)),
	getAgentTemplates: () => dispatch(GetAgentTemplates.started(undefined)),
	getCorporateTemplates: () => dispatch(GetCorporateTemplates.started(undefined)),
	getSnacks: (snackbarProps: SnackbarProps) => dispatch(QueueSnackbar(snackbarProps)),
});
export const EmailAutomationContainer = connect(mapStateToProps, mapDispatchToProps, true)(EmailAutomationPage);