import React from 'react';
import { Address } from '../../reducers/ContactReducer';
import { displayFullAddress } from '../../utilities/utilities';
import { SearchResult } from '../utility/search_result';

interface Props {
	address: Address;
	name: string;
	selected: boolean;
	onClick: (address: Address) => void;
}
export const LocationSuggestion: React.FC<Props> = (props) => {

	const { address, name, selected, onClick } = props;
	const onClickAddress = () => onClick(address);
		
	return (
		<SearchResult
			isSelected={selected}
			primaryText={`${name} - ${address.type}`}
			secondaryText={displayFullAddress(address)}
			onClick={onClickAddress}
		/>
	);
	
};