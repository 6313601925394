import React from 'react';
import { LeadSearchResult, getDisplayStatusFromUiState, getUiStateFromReduxState, getUiStateFromDisplayStatus } from '../../reducers/lead_search_reducer';
import { TableRow, TableCell, TextField, Button, MenuItem } from '@material-ui/core';
import Moment from 'moment';
import { openMaps } from '../../utilities/maps';
import { normalizePhone } from '../../utilities/formatting/data_normalizations';
import { navRoutes } from '../nav/Routes';
import { Lookup, Lookups } from '../../utilities/lookup';
import { LeadUpdate } from '../../reducers/LeadReducer';
import { Strings } from '../../assets/common/strings';
import { isMobileDevice } from '../../utilities/is_mobile';

interface Props {
    lead: LeadSearchResult;
    statusLookups: Lookup[];
    noSaleReasonLookups: Lookup[];
    saveLeadUpdate: (update: LeadUpdate) => void;
}

interface State {
    update: LeadUpdate;
}

export class LeadSearchTableRow extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            update: this.generateLeadUpdate(props.lead),
        };
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.props.lead !== nextProps.lead) {
            this.setState({
                update: this.generateLeadUpdate(nextProps.lead),
            });
        }
    }

    generateLeadUpdate(lead: LeadSearchResult) {
        const {
            derivedStatus,
            derivedStatusReason
        } = getUiStateFromReduxState(lead.leadStatus, lead.leadStatusReason)
        const derivedLeadUpdate: LeadUpdate = {
            status: derivedStatus,
            reason: derivedStatusReason,
            dirty: false,
        };
        return derivedLeadUpdate;
    }

    createStatusSelect = () => {
        const { update } = this.state;
        const { statusLookups } = this.props;

        const status = getDisplayStatusFromUiState(update.status, update.reason);

        return (
            <TextField
                select
                value={status}
                onChange={this.handleStatusChangeEvent}
                SelectProps={{ style: styles.select, native: isMobileDevice }}
            >
                {statusLookups
                    .map(lookup => (
                        isMobileDevice
                            ? (
                                <option key={'selection1-' + lookup.value} value={lookup.value}>{lookup.label}</option>
                            )
                            : (
                                <MenuItem key={'selection1-' + lookup.value} value={lookup.value}>
                                    {lookup.label}
                                </MenuItem>
                            )
                    ))
                }
            </TextField>
        );
    };

    handleStatusChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => this.handleStatusChange(event.target.value);

    handleStatusChange = (status: string) => {
        const {
            trueStatus,
            trueReason
        } = getUiStateFromDisplayStatus(status);

        this.setState(prevState => ({
            ...prevState,
            update: {
                ...prevState.update,
                status: trueStatus,
                reason: trueReason,
                dirty: true,
            }
        }));
    };

    createNoSaleReasonSelect = () => {
        const { update } = this.state;
        const { noSaleReasonLookups } = this.props;

        const errorText =
            update
                && update.status === Lookups.NoSale.value
                && !update.reason
                ? 'No Sale Reason is required for Status of No Sale'
                : undefined;
        const disabled =
            update &&
            update.status !== Lookups.NoSale.value;

        return (
            <TextField
                disabled={disabled}
                select
                value={update.reason}
                onChange={this.handleNoSaleReasonChangeEvent}
                SelectProps={{ style: styles.select }}
                error={!!errorText}
                helperText={errorText}
            >
                {noSaleReasonLookups.map(statusReason => (
                    <MenuItem
                        key={'selection2-' + statusReason.value}
                        value={statusReason.value}
                    >
                        {statusReason.label}
                    </MenuItem>
                ))}
            </TextField>
        );
    };

    handleNoSaleReasonChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => this.handleNoSaleReasonChange(event.target.value);

    handleNoSaleReasonChange = (reason: string) => {
        this.setState(prevState => ({
            ...prevState,
            update: {
                ...prevState.update,
                reason: reason,
                dirty: true,
            }
        }));
    };

    handleSave = () => {
        const {
            update
        } = this.state;
        const {
            saveLeadUpdate
        } = this.props;

        saveLeadUpdate(update);

        this.setState(prevState => ({
            ...prevState,
            update: {
                ...prevState.update,
                dirty: false
            }
        }));
    };

    handleNameClick = () => {
        const {
            lead
        } = this.props;

        let route = `${window.location.origin}/#`;
        if (lead && lead.employerId) {
            route += navRoutes.employer.path.replace(
                Strings.Navigation.EmployerId,
                lead.employerId
            );
        } else if (lead && lead.householdId) {
            route += navRoutes.household.path.replace(
                Strings.Navigation.HouseholdId,
                lead.householdId
            );
        }
        window.open(route, '_blank');
    };

    openLeadAddressInMaps = () => {
        const {
            lead
        } = this.props;
        openMaps(lead.address);
    }

    render() {
        const {
            update
        } = this.state;
        const {
            lead
        } = this.props;
        
        const leadSearchPath = navRoutes.leadSearch.path

        return (
            <TableRow key={lead.leadId}>
                <TableCell padding='none'>
                    <a
                        href={`#${leadSearchPath}`}
                        onClick={this.handleNameClick}
                    >
                        {lead.name}
                    </a>
                </TableCell>
                <TableCell
                    size='small'
                >
                    {Moment.utc(lead.createdOn)
                        .local()
                        .format('MM-DD-YYYY')}
                </TableCell>
                <TableCell padding='none'>
                    <a
                        href={`#${leadSearchPath}`}
                        onClick={this.openLeadAddressInMaps}
                    >
                        {lead.address}
                    </a>
                </TableCell>
                <TableCell padding='none'>
                    <a href={`tel:${lead.phone}`}
                    >
                        {normalizePhone(lead.phone || '')}
                    </a>
                </TableCell>
                <TableCell padding='none'>
                    <a href={`mailto:${lead.email}`}>{lead.email}</a>
                </TableCell>
                <TableCell padding='none'>
                    {lead.linesOfBusiness.join(', ')}
                </TableCell>
                <TableCell padding='none'>
                    {this.createStatusSelect()}
                </TableCell>
                <TableCell padding='none'>
                    {this.createNoSaleReasonSelect()}
                </TableCell>
                <TableCell padding='none'>
                    <Button
                        color="primary"
                        size="small"
                        variant="contained"
                        onClick={this.handleSave}
                        disabled={
                            !update.dirty ||
                            (update.status ===
                                Lookups.NoSale.value &&
                                !update.reason)
                        }
                    >
                        Save
                    </Button>
                </TableCell>
            </TableRow>
        );
    }
}

const styles: { [key: string]: React.CSSProperties } = {
    select: {
        fontSize: '0.8rem',
    },
};
