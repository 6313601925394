import { Strings } from '../assets/common/strings';
import { ReShopDecision } from '../components/reshop/models/reshop_enums';
import { Application } from '../reducers/application_reducer';
import { DTCProductRecommendationTableData, ReshopTableData } from '../components/reshop/models/table_data';
import { isBlank } from './utilities';
import { Contact } from '../reducers/ContactReducer';

export interface ReshopSummary {
	currentPlans: Application[];
	primaryContact: Contact;
	recommendedPlans: DTCProductRecommendationTableData[];
	reshopResults: ReshopTableData;
}

export interface ReshopSummaryDisplay extends ReshopSummary {
	contactName: string;
	contactPhone: string;
	dateCreated: string;
	id: string;
	lobs: string[];
	planComparisons: { [key: string]: [Application?, DTCProductRecommendationTableData?] };
	recommendedProductTypes: string;
}

export const getDecisionByLOB = (reshopResults: ReshopTableData, lob: string): ReShopDecision => {
	switch (lob) {
		case Strings.ReShop.MedicarePrimary:
			return reshopResults.medicarePartCDecisionPrimary as ReShopDecision;
		case Strings.ReShop.MedicareSpouse:
			return reshopResults.medicarePartCDecisionSpouse as ReShopDecision;
		case Strings.ReShop.Health:
			return reshopResults.healthACADecision as ReShopDecision;
		case Strings.ReShop.Dental:
			return reshopResults.dentalDecision as ReShopDecision;
		case Strings.ReShop.Vision:
			return reshopResults.visionDecision as ReShopDecision;
		default:
			return ReShopDecision.Undetermined;
	}
};
export const summaryHasExpired = (summary: ReshopSummary, expiration: number) => {
	let todaysDate = new Date();
	let expirationDate = new Date(summary.reshopResults.createdOn);
	todaysDate.setDate(todaysDate.getDate());
	expirationDate.setDate(expirationDate.getDate() + expiration);
	return todaysDate > expirationDate;
};

export const getDecisionForDisplay = (decision: ReShopDecision) => {
	switch (decision) {
		case ReShopDecision.Switch:
			return Strings.ReShop.Switch;
		case ReShopDecision.Keep:
			return Strings.ReShop.Keep;
		case ReShopDecision.Exclude:
			return Strings.ReShop.Exclude;
		case ReShopDecision.NoPlan:
			return Strings.ReShop.NoPlan;
		case ReShopDecision.FitScoreNeeded:
			return Strings.ReShop.FitScoreNeeded;
		default:
			return 'Undetermined';
	}
};

export const getRecommendationByBucket = (
	recommendations: DTCProductRecommendationTableData[],
	bucket: 'medicare' | 'health' | 'dental' | 'vision'
) => {
	// sort highest fitscore first, then lowest premium
	return recommendations
		.filter((plan) => plan.productBucket === bucket)
		.sort((first, second) => second.fitScore - first.fitScore)
		.sort((first, second) => first.monthlyPremium - second.monthlyPremium);
};

export const mapReshopSummariesForDisplay = (summaries: ReshopSummary[]): ReshopSummaryDisplay[] => {
	if (!summaries) return [];
	return summaries.map((summary) => {
		const name = summary.primaryContact.firstName + ' ' + summary.primaryContact.lastName;
		const phone = summary.primaryContact.phones.length > 0 ? summary.primaryContact.phones[0].number : '';

		const lobs: string[] = [];
		const planComparisons: { [key: string]: [Application?, DTCProductRecommendationTableData?] } = {};

		if (!isBlank(summary.reshopResults.medicarePartCDecisionPrimary)) {
			lobs.push(Strings.ReShop.MedicarePrimary);
			const primaryCurrentMA = summary.currentPlans.filter(
				(plan) => plan.productType === 'Medicare' && plan.primaryInsured.id === summary.primaryContact.id
			);
			const primaryRecMA = getRecommendationByBucket(summary.recommendedPlans, 'medicare'); // FIXME: no way to filter by user

			planComparisons[Strings.ReShop.MedicarePrimary] = [
				primaryCurrentMA.length > 0 ? primaryCurrentMA[0] : undefined,
				primaryRecMA.length > 0 &&
				((summary.reshopResults.medicarePartCDecisionPrimary as ReShopDecision) === ReShopDecision.Switch ||
					(summary.reshopResults.medicarePartCDecisionPrimary as ReShopDecision) === ReShopDecision.NoPlan)
					? primaryRecMA[0]
					: undefined,
			];
		}
		if (!isBlank(summary.reshopResults.medicarePartCDecisionSpouse)) {
			lobs.push(Strings.ReShop.MedicareSpouse);
			const spouseCurrentMA = summary.currentPlans.filter(
				(plan) => plan.productType === 'Medicare' && plan.primaryInsured.id !== summary.primaryContact.id
			);
			const spouseRecMA = getRecommendationByBucket(summary.recommendedPlans, 'medicare'); // FIXME: no way to filter by user

			planComparisons[Strings.ReShop.MedicareSpouse] = [
				spouseCurrentMA.length > 0 ? spouseCurrentMA[0] : undefined,
				spouseRecMA.length > 0 &&
				((summary.reshopResults.medicarePartCDecisionSpouse as ReShopDecision) === ReShopDecision.Switch ||
					(summary.reshopResults.medicarePartCDecisionSpouse as ReShopDecision) === ReShopDecision.NoPlan)
					? spouseRecMA[0]
					: undefined,
			];
		}
		if (!isBlank(summary.reshopResults.healthACADecision)) {
			lobs.push(Strings.ReShop.Health);
			const currentHealth = summary.currentPlans.filter((plan) => plan.lineOfBusiness === 'Commercial Health');
			const recommendedHealth = getRecommendationByBucket(summary.recommendedPlans, 'health');

			planComparisons[Strings.ReShop.Health] = [
				currentHealth.length > 0 ? currentHealth[0] : undefined,
				recommendedHealth.length > 0 &&
				((summary.reshopResults.healthACADecision as ReShopDecision) === ReShopDecision.Switch ||
					(summary.reshopResults.healthACADecision as ReShopDecision) === ReShopDecision.NoPlan)
					? recommendedHealth[0]
					: undefined,
			];
		}
		// if (!isBlank(summary.ReshopResults.healthSTMDecision)) { // TODO: look at implementing this
		// 	lobs.push('Short Term Health');
		// }
		if (!isBlank(summary.reshopResults.dentalDecision)) {
			lobs.push(Strings.ReShop.Dental);
			const currentDental = summary.currentPlans.filter((plan) => plan.productType === 'Dental');
			const recommendedDental = getRecommendationByBucket(summary.recommendedPlans, 'dental');

			planComparisons[Strings.ReShop.Dental] = [
				currentDental.length > 0 ? currentDental[0] : undefined,
				recommendedDental.length > 0 &&
				((summary.reshopResults.dentalDecision as ReShopDecision) === ReShopDecision.Switch ||
					(summary.reshopResults.dentalDecision as ReShopDecision) === ReShopDecision.NoPlan)
					? recommendedDental[0]
					: undefined,
			];
		}
		if (!isBlank(summary.reshopResults.visionDecision)) {
			lobs.push(Strings.ReShop.Vision);
			const currentVision = summary.currentPlans.filter((plan) => plan.productType === 'Vision');
			const recommendedVision = getRecommendationByBucket(summary.recommendedPlans, 'vision');

			planComparisons[Strings.ReShop.Vision] = [
				currentVision.length > 0 ? currentVision[0] : undefined,
				recommendedVision.length > 0 &&
				((summary.reshopResults.visionDecision as ReShopDecision) === ReShopDecision.Switch ||
					(summary.reshopResults.visionDecision as ReShopDecision) === ReShopDecision.NoPlan)
					? recommendedVision[0]
					: undefined,
			];
		}

		const display: ReshopSummaryDisplay = {
			contactName: name,
			contactPhone: phone,
			currentPlans: summary.currentPlans,
			dateCreated: new Date(summary.reshopResults.createdOn).toLocaleString('en', {
				month: 'long',
				day: 'numeric',
				year: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
			}),
			id: summary.reshopResults.reShopId,
			lobs: lobs,
			planComparisons: planComparisons,
			primaryContact: summary.primaryContact,
			recommendedPlans: summary.recommendedPlans,
			recommendedProductTypes: lobs.join(),
			reshopResults: summary.reshopResults,
		};
		return display;
	});
};
