import { Avatar, Icon } from '@material-ui/core';
import React from 'react';
import { Contact } from '../../reducers/ContactReducer';
import { makeOpenMaps } from '../../utilities/maps';
import { PhoneIcon } from '../Layout/contact_header_bar/phone_icon';
import { themePalette } from '../../utilities/branding';
import { normalizePhoneWithExtension } from '../../utilities/formatting/data_normalizations';
import { displayAddress, generateTypeToDisplay, getContactInfo } from '../../utilities/contact_util';
import { SimpleListItem } from '../utility/simple_list_item';
import { makeSendEmail } from '../../utilities/phone_email_util';

interface Props {
	contact: Contact;
	phoneClick: () => void;
}
export const ContactInfoList: React.FC<Props> = (props) => {

	const { contact, phoneClick } = props;
	const contactInfo = getContactInfo(contact);
	
	return (
		<>
			{
				contactInfo.phones.map((phone, index) => (
					<SimpleListItem
						key={`${phone.number}${index}`}
						icon={
							<PhoneIcon avatar phone={phone} />
						}
						title={normalizePhoneWithExtension(phone)}
						subtitle={generateTypeToDisplay(phone)}
						disabled={phone.isCompanyDnc}
						onClick={phoneClick}
						divider
						hideArrow
					/>
				))
			}
			{
				contactInfo.emails.map((email, index) => {
					const backgroundColor = email.doNotEmail
						? themePalette.error
						: email.isPreferred 
							? themePalette.preferred_item
							: themePalette.default_avatar;
					const icon = email.doNotEmail ? 'report_problem' : 'email';
					const emailType = generateTypeToDisplay(email);
					return (
						<SimpleListItem
							key={`${email.emailAddress}${index}`}
							icon={
								<Avatar style={{ backgroundColor }}>
									<Icon>{icon}</Icon>
								</Avatar>
							}
							title={email.emailAddress}
							subtitle={email.doNotEmail
								? email.emailUndeliverableDate
									? 'Undeliverable - ' + emailType
									: 'Unsubscribed - ' + emailType
								: emailType}
							disabled={email.doNotEmail}
							onClick={makeSendEmail(email.emailAddress)}
							divider
							hideArrow
						/>
					);
				})
			}

			{
				contactInfo.addresses.map((address, index) => {
					const addressValue = displayAddress(address);
					const addressType = generateTypeToDisplay(address);
					const backgroundColor: string = address.isPreferred
						? themePalette.preferred_item 
						: themePalette.default_avatar;
					return (
						<SimpleListItem
							key={`${addressValue}${index}`}
							icon={
								<Avatar style={{ backgroundColor }}>
									<Icon>home</Icon>
								</Avatar>
							}
							title={addressValue}
							subtitle={addressType}
							onClick={makeOpenMaps(addressValue)}
							divider
							hideArrow
						/>
					);
				})
			}
		</>
	);
}
