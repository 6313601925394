import React from 'react';
import { connect, Dispatch } from '@optum-uhone-hmkts/rise';
import { Dialog, DialogContent, Button, DialogTitle, DialogContentText, DialogActions } from '@material-ui/core';
import { AppState } from "../../reducers";
import { Logout, RefreshLogin } from "../../actions/authentication_actions";
import { setIdledDialogVisible } from '../../actions/authentication_actions';

interface StateProps {
    idledDialogOpen: boolean;
}

interface DispatchProps {
    closeDialog: () => void;
    startLogout: () => void;
    restoreLogin: () => void;
}

type Props = StateProps & DispatchProps;

const IdleDialog: React.FC<Props> = (props) => {

    const { idledDialogOpen, closeDialog, restoreLogin, startLogout } = props;

    const stayLoggedIn = () => {
        restoreLogin();
        closeDialog();
    };
    const logOut = () => {
        startLogout();
        closeDialog();
    };

    return (
        <Dialog 
            open={idledDialogOpen} 
            onClose={closeDialog}
            disableBackdropClick
        >
            <DialogTitle>
                Idled Out
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You are being logged out automatically due to inactivity.  Would you like to stay logged in?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={logOut} color="primary">
                    Log Out
                </Button>
                <Button onClick={stayLoggedIn} color="primary" autoFocus>
                    Stay Logged In
                </Button>
            </DialogActions>
            
        </Dialog>
    );
}

const mapStateToProps = (state: AppState): StateProps => ({
    idledDialogOpen: state.authentication.idledDialogOpen,
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    closeDialog: () => dispatch(setIdledDialogVisible(false)),
    startLogout: () => dispatch(Logout.started(undefined)),
    restoreLogin: () => dispatch(RefreshLogin()),
});

export const IdledOutDialog = connect(mapStateToProps, mapDispatchToProps, true)(IdleDialog);