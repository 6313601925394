import React, { useState, useEffect } from 'react';
import {
    useFormActions, r_pipe, useFormState, useConfirmation, riseStrings,
    ResultListItem,
    CardProps,
    Grid,
    RiseList,
    ConfirmationDialog,
    FormSlice,
    createMenuAction, useLoading
} from '@optum-uhone-hmkts/rise';

type Props = CardProps & {
    onItemClick: (formId: string) => void;
    selectedIds?: string[];
    displayCount?: number;
}

export const FactFinderList: React.FC<Props> = (props) => {
    const { forms } = useFormState();
    const loading = useLoading();

    const ffActions = useFormActions();
    const {
        selectedIds = [],
        displayCount,
    } = props;

    const [items, setItems] = useState<ResultListItem[]>([]);

    useEffect(() => {
        const newItems = mapForms(forms);
        if (!!displayCount) {
            setItems(newItems.slice(0, displayCount));
        } else {
            setItems(newItems);
        }
    }, [forms]);


    const confirmDeleteForm = useConfirmation({
        onConfirm: ffActions.deleteForm,
        subtitleText: riseStrings.AreYouSureDeleteForm,
    });

    return (
        <Grid
            gridSize={"12|12"}
            style={{ padding: 0 }}
        >
            <RiseList
                items={items}
                onClick={props.onItemClick}
                selected={selectedIds}
                variations="agentConnect"
                defaultEmptyText={loading.isLoading ? "Loading..." : riseStrings.YouCurrentlydoNotHaveAnyFactFindersSaved}
                mapIconOptions={(item) => {
                    let options = [
                        createMenuAction("Duplicate", ffActions.createCopyForm(item.id)),
                        createMenuAction("Delete", () => (confirmDeleteForm as any).setOpen(item.id, riseStrings.AreYouSureDeleteFormName(item.titleText))),
                        createMenuAction("Download", ffActions.createDownloadForm(item.id)),
                    ]

                    if (!item.completed) {
                        options = [createMenuAction("Edit", () => ffActions.navigateToForm(item.id)), ...options]
                    }

                    return options;
                }}
            />

            <ConfirmationDialog {...confirmDeleteForm} />
        </Grid>
    );
}

const mapForms = <T extends ResultListItem>(forms: { [id: string]: FormSlice.Form }): T[] => {
    return (Object.keys(forms) || [])
        .filter(i => {
            const form: FormSlice.Form = forms[i] || {};
            return !!form
                && form.firstName
                && form.lastName
                && form.createdOn
        }).map(i => {
            const formStatus = r_pipe.subtitleCompleteInProgress(forms[i])
            const result = {
                value: forms[i].id,
                primaryText: forms[i].firstName + " " + forms[i].lastName,
                secondaryText: formStatus.titleText + " " + formStatus.subtitleText,
                id: forms[i].id,
                titleText: forms[i].firstName + " " + forms[i].lastName,
                masterFormId: forms[i].masterFormId,
                completed: !!forms[i].completed
            } as unknown as T;
            return result;
        });

}
