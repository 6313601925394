import React from 'react';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { connect } from '@optum-uhone-hmkts/rise';
import { FabMenu } from '../../components/nav/fab_menu';
import { AppState } from '../../reducers/index';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { navigateBack } from '../../actions/navigation_actions';
import { NavigationProps, navRoutes } from '../../components/nav/Routes';
import { themePalette } from '../../utilities/branding';
import { Template, TemplateTypeOptions } from '../../reducers/template_reducer';
import {
	Button,
	Icon,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContentText,
	DialogContent
} from '@material-ui/core';
import {
	GetAgentTemplates,
	GetCorporateTemplates,
	DeleteTemplate,
	ClearTemplateStores,
	ClearEditedTemplate
} from '../../actions/template_actions';
import { jwt_auth } from '../../utilities/auth';
import { navigateTo } from '../../actions/navigation_actions';
import { Strings as S } from '../../assets/common/strings';
import { P } from '../../utilities/auth/permissions';
import { templateInUse, deleteTemplateText } from '../../assets/common/string_builders';
import { createMenuAction, MoreMenuProps } from '../../components/nav/more_menu';
import { getEmailAutomationsByAgentId } from '../../actions/email_automation_actions';
import { Dictionary } from '../../utilities/object_util';
import { composeSearchString } from '../../utilities/search_util';
import moment from 'moment';
import { Lookups } from '../../utilities/lookup';
import { ColumnOption, renderCell, FilterTableList } from '../../components/Layout/table/filter_table';
import { FilterFieldProps, FilterComponents } from '../../components/Layout/filter';

interface StateProps {
	areAgentTemplatesLoading: boolean;
	areCorporateTemplatesLoading: boolean;
	templates: Template[];
	linesOfBusiness: string[];
	emailEventCategories: string[];
}

interface DispatchProps {
	deleteTemplate: (templateId: string) => void;
	clearTemplateState: () => void;
	navigateBack: () => void;
	navigateTo: (route: string) => void;
	clearEditedTemplate: () => void;
	getAgentTemplates: () => void;
	getCorporateTemplates: () => void;
	getEmailAutomations: () => void;
}

type Props =
	& StateProps
	& DispatchProps
	& NavigationProps;


type State = {
	isDialogOpen: boolean;
	isInUseDialogOpen: boolean;
	selectedTemplate: Template | undefined;
};

class TemplateManagementPage extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {
			isDialogOpen: false,
			isInUseDialogOpen: false,
			selectedTemplate: undefined,
		};
	}

	componentDidMount() {
		this.props.getAgentTemplates();
		this.props.getCorporateTemplates();
		this.props.getEmailAutomations();
		this.props.clearEditedTemplate();
	}

	deleteTemplate = () => {
		if (this.state.selectedTemplate) {
			this.props.deleteTemplate(this.state.selectedTemplate.id);
		}
		this.props.getAgentTemplates();
		this.props.getCorporateTemplates();
		this.setState({ isDialogOpen: false, selectedTemplate: undefined });
	};

	onClickMenuDelete = (template: Template) => {
		if (template.isInUseOnAutomation && !jwt_auth.hasPermission(P.CorporateTemplatesDelete)) {
			this.setState({ isInUseDialogOpen: true, selectedTemplate: undefined });
		} else {
			this.setState({ isDialogOpen: true, selectedTemplate: template });
		}
	};

	onClickMenuDetails = (template: Template) => {
		this.props.navigateTo(
			navRoutes.templateContent.path
				.replace(S.Navigation.TemplateId, template.id)
				.replace(S.Navigation.EditMode, S.Boolean.False)
		);
	};

	onClickMenuEdit = (template: Template) => {
		if (template.isInUseOnAutomation
			&& !jwt_auth.hasPermission(P.CorporateTemplatesUpdate)
		) {
			this.setState({ isInUseDialogOpen: true, selectedTemplate: undefined });
		} else {
			this.props.navigateTo(
				navRoutes.templateContent.path
					.replace(S.Navigation.TemplateId, template.id)
					.replace(S.Navigation.EditMode, S.Boolean.True)
			);
		}
	};

	onClickPreview = (template: Template) => {
		this.props.navigateTo(
			navRoutes.templatePreview.path
				.replace(S.Navigation.TemplateId, template.id)
				.replace(S.Navigation.From, S.Navigation.Management)
		);
	};

	setDialogOpen = () => {
		this.setState({ isDialogOpen: true });
	};

	setDialogClosed = () => {
		this.setState({ isDialogOpen: false });
	};

	renderTopComponent = () => {
		return (
			<HeaderBarComponent
				title={S.HeaderTitles.TemplateManager}
				isLoading={
					this.props.areCorporateTemplatesLoading ||
					this.props.areAgentTemplatesLoading
				}
			/>
		);
	};

	renderMiddleComponent = () => {
		const mapToDictionary = (strings: string[]) => {
			const dictionary: Dictionary<string> = {};
			strings.forEach(str => dictionary[str] = str);
			return dictionary;
		}

		const emailTypeOptions = mapToDictionary([
			TemplateTypeOptions.agent,
			TemplateTypeOptions.corporate
		]);

		const {
			linesOfBusiness,
			emailEventCategories,
			templates,
		} = this.props;

		const filters: FilterFieldProps[] = [
			{
				label: "Template Type",
				options: emailTypeOptions,
				compareFn: (t: Template, selection: Dictionary<string>) => !!selection["All"] || !!selection[t.emailType],
				includeAll: true,
				component: FilterComponents.Dropdown,
				defaultSelection: { All: "All" }
			}, {
				label: "Template Event",
				options: mapToDictionary(emailEventCategories),
				compareFn: (t: Template, selection: Dictionary<string>) => !!selection["All"] || !t.emailAutomationEventTypeCategory || !!t.emailAutomationEventTypeCategory.some(cat => !!selection[cat]),
				includeAll: true,
				component: FilterComponents.MultiselectDropdown,
				defaultSelection: { All: "All" }
			}, {
				label: "Lines of Business",
				options: mapToDictionary(linesOfBusiness),
				compareFn: (t: Template, selection: Dictionary<string>) => !!selection["All"] || !t.linesOfBusiness || t.linesOfBusiness.some(lob => !!selection[lob]),
				includeAll: true,
				component: FilterComponents.MultiselectDropdown,
				defaultSelection: { All: "All" }
			}
		];

		const columns: ColumnOption[] = [
			{
				label: "Title",
				field: "title",
				render: t => renderCell(t.title),
			}, {
				label: "Status",
				field: "isDraft",
				render: (t: Template) => renderCell(<Icon>{t.isDraft ? "edit" : "email"}</Icon>),
			}, {
				label: "Lines of Business",
				field: "linesOfBusiness",
				render: (t: Template) => renderCell(composeSearchString(t.linesOfBusiness, true)),
			}, {
				label: "Template Event",
				field: "emailAutomationEventTypeCategory",
				render: (t: Template) => renderCell(composeSearchString(t.emailAutomationEventTypeCategory, true)),
			}, {
				label: "Language",
				field: "language",
				render: (t: Template) => renderCell(t.language),
			}, {
				label: "Updated On",
				field: "updatedOn",
				render: (t: Template) => renderCell(moment(t.updatedOn).format(S.DateFormat.MDYYYYHMMA)), //TODO back to moment
			}, {
				label: "Template Type",
				field: "emailType",
				render: (t: Template) => renderCell(t.emailType),
			},
		];

		const typeFilteredTemplates = templates.filter(t => {
			if (t.emailType == "Corporate") {
				return !t.isDraft || jwt_auth.hasPermission(P.CorporateTemplatesDelete);
			}
			return true;
		});

		const hasCorporateUpdate = jwt_auth.hasPermission(P.CorporateTemplatesUpdate);
        const hasCorporateDelete = jwt_auth.hasPermission(P.CorporateTemplatesDelete);

		return (
			<FilterTableList
				filterProps={filters}
				columns={columns}
				rows={typeFilteredTemplates}
				rowActions={{
					[S.MoreMenu.Preview]: { action: this.onClickPreview, isDisabled: () => false },
					[S.MoreMenu.Edit]: { action: this.onClickMenuEdit, isDisabled: (row: Template) => row.emailType == S.TemplateType.Corporate && !hasCorporateUpdate },
					[S.MoreMenu.Delete]: { action: this.onClickMenuDelete, isDisabled: (row: Template) => row.emailType == S.TemplateType.Corporate && !hasCorporateDelete },
				}}
				onClick={this.onClickPreview}
			/>
		);
	}

	renderDeleteDialog = () => {
		return (
			<Dialog
				open={this.state.isDialogOpen}
				disableEscapeKeyDown
				onClose={this.setDialogClosed}
			>
				<DialogTitle>{
					deleteTemplateText(
						this.state.selectedTemplate
							? this.state.selectedTemplate.title
							: S.TemplateManagement.ThisTemplate
					)}
				</DialogTitle>
				<DialogActions>
					<Button
						color={S.Theming.Secondary}
						onClick={this.setDialogClosed}
						children={S.ButtonText.Cancel}
					/>
					<Button
						variant='contained'
						color={S.Theming.Primary}
						style={{ backgroundColor: themePalette.delete_remove_reject_button }}
						onClick={this.deleteTemplate}
						children={S.ButtonText.Delete}
					/>
				</DialogActions>
			</Dialog>
		);
	};

	renderIsInUseDialog = () => {
		return (
			<Dialog
				open={this.state.isInUseDialogOpen}
				disableEscapeKeyDown
				onClose={() => this.setState({ isInUseDialogOpen: false })}
			>
				<DialogTitle>{
					templateInUse(this.state.selectedTemplate
						? this.state.selectedTemplate.title
						: S.TemplateManagement.ThisTemplate
					)}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{S.TemplateManagement.RemoveBeforeEdit}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						variant='contained'
						color={S.Theming.Primary}
						style={{ backgroundColor: themePalette.accept_button }}
						onClick={() => this.setState({ isInUseDialogOpen: false })}
						children={S.ButtonText.Ok}
					/>
				</DialogActions>
			</Dialog>
		);
	};

	renderBottomComponent = () => {
		return (
			<FabMenu
				onClick={() => {
					this.props.navigateTo(
						navRoutes.templateContent.path
							.replace(S.Navigation.TemplateId, S.TemplateManagement.NewTemplate)
							.replace(S.Navigation.EditMode, S.Boolean.True)
					);
				}}
				items={[]}
			/>
		);
	};

	render() {
		return (
			<BasePageContainer
				topComponent={this.renderTopComponent()}
				middleComponent={this.renderMiddleComponent()}
				bottomComponent={this.renderBottomComponent()}
				pageDialogs={[
					this.renderDeleteDialog(),
					this.renderIsInUseDialog(),
				]}
			/>
		);
	}
}

function mapStateToProps(state: AppState): StateProps {
	const templates = state.templateManagerState.agentTemplates
		.concat(state.templateManagerState.corporateTemplates)
		.map(lt => lt.data);

	return {
		areAgentTemplatesLoading:
			state.templateManagerState.areAgentTemplatesLoading,
		areCorporateTemplatesLoading:
			state.templateManagerState.areCorporateTemplatesLoading,
		templates,
		linesOfBusiness: state.lookup.getLabels(Lookups.LeadLineOfBusiness),
		emailEventCategories: state.lookup.getLabels(Lookups.EmailAutomationEventTypeCategory),
	};
}

function mapDispatchToProps(dispatch: any): DispatchProps & Partial<NavigationProps> {
	return {
		navigateBack: () =>
			dispatch(navigateBack()),

		navigateTo: (route: string) =>
			dispatch(navigateTo(route)),

		deleteTemplate: (templateId: string) =>
			dispatch(DeleteTemplate.started(templateId)),

		getAgentTemplates: () =>
			dispatch(GetAgentTemplates.started(undefined)),

		getCorporateTemplates: () =>
			dispatch(GetCorporateTemplates.started(undefined)),

		clearTemplateState: () =>
			dispatch(ClearTemplateStores()),

		clearEditedTemplate: () =>
			dispatch(ClearEditedTemplate()),

		getEmailAutomations: () => dispatch(getEmailAutomationsByAgentId()),

	};
}

export const TemplateManagementContainer = connect(
	mapStateToProps,
	mapDispatchToProps, true
)(TemplateManagementPage);
