import React, { ComponentType } from 'react';
import {
	Card,
	IconButton,
	Grid,
	Collapse,
	LinearProgress,
	Divider,
	CardActions,
	ButtonProps,
	ListItem,
	ListItemText,
	ListItemIcon,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import classnames from 'classnames';
import { useToggle } from '../../utilities/hooks';
import { useCardStyles, useListItemStyles } from '../../utilities/hooks/styles';

interface Props {
	title?: string;
	subtitle?: string;
	contentCount?: number;
	alwaysExpanded?: boolean;
	startCollapsed?: boolean;
	isLoading?: boolean;
	noPadding?: boolean;
	actions?: React.ReactNode;
}
export const ToggleCard: React.FC<Props> = (props) => {
	const {
		title,
		subtitle,
		contentCount,
		actions,
		isLoading,
		noPadding=false,
		alwaysExpanded,
		startCollapsed,
		children
	} = props;

	const [expanded, toggleExpand] = useToggle(
		startCollapsed
			? false
			: true,
		alwaysExpanded
	);

	const cardStyles = useCardStyles();
	const itemStyles = useListItemStyles();

	return (
		<Card className={classnames(itemStyles.margin, cardStyles.noPadding)}>
			<ListItem
				button={!Boolean(alwaysExpanded)}
				onClick={toggleExpand}
				className={cardStyles.header}
			>
				<ListItemText
					primary={
						<div className={cardStyles.centered}>
							{title}
							{
								!expanded && contentCount !== undefined &&
									<Chip label={contentCount} style={{ marginLeft: 10 }} />
							}
						</div>
					}
					secondary={subtitle}
				/>
				<ListItemIcon className={itemStyles.arrowIcon}>
					{!alwaysExpanded && 
						<IconButton className={classnames(cardStyles.expand, { [cardStyles.expandOpen]: expanded })}>
							<ExpandMoreIcon />
						</IconButton>
					}
				</ListItemIcon>
			</ListItem>
			{isLoading && <LinearProgress />}
			<Collapse
				in={expanded || alwaysExpanded}
			>
				<Divider />
				<div className={classnames(noPadding ? cardStyles.noPadding : cardStyles.padded)}>
					{children}
				</div>
				{actions && <CardActions>{actions}</CardActions>}
			</Collapse>
		</Card>
	);
};

export const wrapInCollapsibleCard = <T extends {}>(
	Component: ComponentType<T>
) => {
	type ComponentProps = T & Props;
	const WrappedCard: React.FC<ComponentProps> = (props) => (
		<ToggleCard {...props}>
			<Component {...props} />
		</ToggleCard>
	);

	return WrappedCard as React.ComponentType<ComponentProps>;
};
