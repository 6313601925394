import React from 'react';
import { themePalette } from '../../../utilities/branding';
import { Activity } from '../../../reducers/activity_reducer';
import { displayDate, DateTimeFormat } from '../../../utilities/date_util';
import { trimString } from '../../../utilities/list_util';
import ClampLines from 'react-clamp-lines';
import { createMenuAction, MoreMenu } from '../../../components/nav/more_menu';
import { SimpleListItem } from '../../../components/utility/simple_list_item';
import { getActivityIcon } from './activity_item';

interface Props {
	activity: Activity;
	handleDeleteClick: (activity: Activity) => void;
	handleNavigateToActivity: (activity: Activity) => void;
	handleActivityEditClick: (activity: Activity) => void;
}

export const OpenActivityItem: React.FC<Props> = (props) => {

	const { activity, handleNavigateToActivity, handleActivityEditClick, handleDeleteClick } = props;

	const primaryText = (
		<ClampLines
			text={trimString(activity?.title || '', 50)}
			lines={1}
			buttons={false}
		/>
	);
	const secondaryText = (
		<ClampLines
			text={`${displayDate(activity?.time, DateTimeFormat.DateTime)}${activity?.description ? ` - ${activity?.description}` : ''}`}
			lines={2}
			buttons={false}
		/>
	);
	
	return (
		<SimpleListItem
			icon={getActivityIcon(activity)}
			title={primaryText}
			subtitle={secondaryText}
			onClick={() => handleNavigateToActivity(activity)}
			secondaryAction={
				<MoreMenu
					color={themePalette.tertiary_text}
					children={[
						createMenuAction('Edit', () => handleActivityEditClick(activity)),
						createMenuAction('Delete', () => handleDeleteClick(activity))
					]}
				/>
			}
		/>
	);

};
