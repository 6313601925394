import { httpForNonWebApiEndpoints, HttpOptions } from '../utilities/http';
import { call, put, all } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import {
	getCityAutoCompleteData,
	getCountyAutoCompleteData,
	getZipCodeAutoCompleteData,
} from '../actions/geographic_search_actions';
import { takeLatestForActionType } from '../utilities/saga_util';
import { AppConfig } from '../types/config';

//////////  Get City AutoComplete Data  //////////
function* getCityAutoCompleteDataSaga(action: Action<string>) {
	try {
		const response = yield call(
			getCityAutoCompleteDataClient,
			action.payload
		);

		if (response.ok) {
			const data = yield response.json();
			yield put(
				getCityAutoCompleteData.done({
					params: action.payload,
					result: data.value,
				})
			);
		} else {
			yield put(
				getCityAutoCompleteData.failed({
					params: action.payload,
					error: response.status,
				})
			);
		}
	} catch (error) {
		yield put(
			getCityAutoCompleteData.failed({
				params: action.payload,
				error: error,
			})
		);
	}
}

function getCityAutoCompleteDataClient(searchString: string): Promise<any> {
	const options: HttpOptions = {
		method: 'GET',
		headers: {
			'api-key': AppConfig.azure_geo_search_key,
		},
	};

	return httpForNonWebApiEndpoints(
		AppConfig.azure_geo_search_url +
		'/indexes/locations-cities/docs?api-version=2016-09-01&queryType=full&%24top=100&searchFields=CityName&search=' +
		searchString.trim() +
		'*',
		options
	);
}

//////////  Get County AutoComplete Data  //////////
function* getCountyAutoCompleteDataSaga(action: Action<string>) {
	try {
		const response = yield call(
			getCountyAutoCompleteDataClient,
			action.payload
		);

		if (response.ok) {
			const data = yield response.json();
			yield put(
				getCountyAutoCompleteData.done({
					params: action.payload,
					result: data.value,
				})
			);
		} else {
			yield put(
				getCountyAutoCompleteData.failed({
					params: action.payload,
					error: response.status,
				})
			);
		}
	} catch (error) {
		yield put(
			getCountyAutoCompleteData.failed({
				params: action.payload,
				error: error,
			})
		);
	}
}

function getCountyAutoCompleteDataClient(searchString: string): Promise<any> {
	const options: HttpOptions = {
		method: 'GET',
		headers: {
			'api-key': AppConfig.azure_geo_search_key,
		},
	};

	return httpForNonWebApiEndpoints(
		AppConfig.azure_geo_search_url +
		'/indexes/locations-counties/docs?api-version=2016-09-01&queryType=full&%24top=100&searchFields=CountyName&search=' +
		searchString.trim() +
		'*',
		options
	);
}

//////////  Get ZipCode AutoComplete Data  //////////
function* getZipCodeAutoCompleteDataSaga(action: Action<string>) {
	try {
		const response = yield call(
			getZipCodeAutoCompleteDataClient,
			action.payload
		);

		if (response.ok) {
			const data = yield response.json();
			yield put(
				getZipCodeAutoCompleteData.done({
					params: action.payload,
					result: data.value,
				})
			);
		} else {
			yield put(
				getZipCodeAutoCompleteData.failed({
					params: action.payload,
					error: response.status,
				})
			);
		}
	} catch (error) {
		yield put(
			getZipCodeAutoCompleteData.failed({
				params: action.payload,
				error: error,
			})
		);
	}
}

function getZipCodeAutoCompleteDataClient(searchString: string): Promise<any> {
	const options: HttpOptions = {
		method: 'GET',
		headers: {
			'api-key': AppConfig.azure_geo_search_key,
		},
	};

	return httpForNonWebApiEndpoints(
		AppConfig.azure_geo_search_url +
		'/indexes/lc-locations-zipcodes/docs?api-version=2016-09-01&queryType=full&%24top=100&searchFields=ZIPCode&search=' +
		searchString.trim() +
		'*',
		options
	);
}


export function* geographicSearchSagas() {
	yield all([
		takeLatestForActionType(
			getCityAutoCompleteData.started,
			getCityAutoCompleteDataSaga
		),
		takeLatestForActionType(
			getCountyAutoCompleteData.started,
			getCountyAutoCompleteDataSaga
		),
		takeLatestForActionType(
			getZipCodeAutoCompleteData.started,
			getZipCodeAutoCompleteDataSaga
		)
	])
}