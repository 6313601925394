import { Strings } from '../assets/common/strings';

export type IdObject = {
    id: string;
    [key: string]: any;
};

export type Dictionary<V> = { [key: string]: V };
export type IdDictionary<T extends IdObject> = Dictionary<T>;
export type Questionable<T> = T | undefined;

export function hasValue<T>(potentialValue: Questionable<T>) {
    return potentialValue !== undefined && potentialValue !== null;
}

export function hasValueAnd<T>(potentialValue: Questionable<T>, andCondition: (value: T) => boolean) {
    return hasValue(potentialValue) && andCondition(potentialValue!);
}

export function get<T>(accessor: () => T | undefined): T | undefined
export function get<T>(accessor: () => T, defaultValue: T): T
export function get<T>(accessor: () => T | undefined, defaultValue: T): T
export function get<T>(accessor: () => T, defaultValue?: T) {
    try {
        const value = accessor();
        if (value === undefined) {
            return defaultValue;
        } else {
            return value;
        }
    } catch {
        return defaultValue;
    }
}

export function cloneDeep<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
}

export function getKeys<T>(obj: T): Array<keyof T> {
    return Object.keys(obj) as Array<keyof T>;
}

export function sortKeys<T extends {}>(obj: T): T {
    const sortedKeys = getKeys(obj);
    const sortedObject = sortedKeys.reduce((result: Partial<T>, key: keyof T) => {
        result[key] = obj[key];
        return result;
    }, {} as Partial<T>);
    return sortedObject as T;
}

export function mapAcrossKeys<T, K>(obj: T, mapper: (key: keyof T) => K) {
    return getKeys(obj).map(mapper);
}

type MapType<TValue> = { [key in string]: TValue };
export const makeEnforceMapType = <TValue>() =>
    <TObjectMap extends MapType<TValue>>(objectMap: TObjectMap | MapType<TValue>) =>
        objectMap as { [key in keyof TObjectMap]: TValue }

export const makeMakeGetByKey = <TValue>() =>
    <TObjectMap extends {}>(objectMap: TObjectMap) =>
        (key: keyof TObjectMap) => (objectMap as MapType<TValue>)[key.toString()];

export const createOpenGetByKey = <TKey extends string | number, TValue>(getByKey: (key: TKey) => TValue) =>
    (openKey: string | number) => getByKey(openKey as TKey);


/**
 * List Options
 */
/**
 * Helper Function
 * @param currentSelection current selection
 * @param selectCount
 * @param obj
 */
export const selectNext = <T>(currentSelection: {[key: string]: T}, key: string, selectCount: number | boolean, obj?: T) => {
    if (selectCount > 0) {
        const nextSelection = { ...currentSelection };

        if (currentSelection[key]) {
            delete nextSelection[key];
            return nextSelection;

        } else if (!selectCount || Object.keys(currentSelection).length < selectCount) {
            nextSelection[key] = obj || (key as any) as T;
            return nextSelection;

        }
    }
    return currentSelection;
}

export const desc = <T>(a: T, b: T, orderBy: keyof T) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export const stableSort = <T extends IdObject>(array: T[], compareTo: (a: T, b: T) => number) => {
    array.sort((a, b) => {
        const order = compareTo(a, b);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return array;
}

export const makeStringDictionary = (strings: string[]) => {
    const dictionary: Dictionary<string> = {};
    strings.forEach(str => dictionary[str] = str);
    return dictionary
}