import React from 'react';
import {
    RiseCard, Button, FactFinderIconSvg, RiseTextField, RiseDropdown, RiseFieldRadio,
    riseStrings, useState, useSelector, useDispatch, risify,
    FormSlice, FormActions,
} from '@optum-uhone-hmkts/rise';


export const _Component: React.FC<{}> = () => {
    const dispatch = useDispatch();

    const formState: FormSlice.State = useSelector(FormSlice.selectState);

    const [filters, setFilters] = useState<FormSlice.Filter>(formState.activeFilter || {} as FormSlice.Filter);

    const handleApplyFilter = () =>
        dispatch(FormActions.GetFactFinderForms(filters))

    const createHandleChange = (key: keyof FormSlice.Filter) => (event) => {
        const newValue = event.target.value
        setFilters({ ...filters, [key]: newValue });
    }

    return <RiseCard
        headerIcon={<FactFinderIconSvg height={35} width={35} />}
        titleText={"Filter FactFinder"}
        variations='flat'
        container
        style={{
            padding: 8,
            height: "100%",
        }}
        cardActions={<Button
            style={{
                width: "100%"
            }}
            onClick={handleApplyFilter}
            color="primary"
        >
            {riseStrings.ApplyFilters}
        </Button>}
    >
        <RiseTextField
            gridSize="12|12"
            value={filters.name}
            onChange={createHandleChange("name")}
            label={"Name"}
            key={"form-pl-hol-name-static"}
        />
        <RiseFieldRadio
            gridSize="12|12"
            value={""}
            options={{
                Yes: false,
                No: false,
                Either: false,
            }}
            onChange={createHandleChange("completed")}
            label={"Completed"}
            key={"form-pl-hol-type-static"}
            disabled={false}
        />

        <RiseDropdown
            id="test"
            gridSize="6|12"
            value={filters.sortKey}
            onChange={createHandleChange("sortKey")}
            label={"Sort By"}
            key={"form-pl-hol-comp-static"}
            options={{
                updatedOn: false,
                createdOn: false,
                firstName: false,
                lastName: false,
            }}
        // options={[{ value: "updatedOn", label: "Updated On" }, { value: "createdOn", label: "Created On" }, { value: "firstName", label: "First Name" }, { value: "lastName", label: "Last Name" }]}
        />

        <RiseDropdown
            id="test"
            gridSize="6|12"
            value={filters.sortDirection}
            onChange={createHandleChange("sortDirection")}
            label={"Select"}
            key={"form-pl-hol-type-static"}
            options={{
                asc: false,
                desc: false,
            }}
        // options={[{ value: "ASC", label: "ASC" }, { value: "DESC", label: "DESC" }]}
        />
    </RiseCard>
}

export const FactFinderFilterWidget = risify(
    riseStrings._FactFinder,
    riseStrings._FactFinderFilterWidget
)(_Component);