
// TODO: use memory first then use local storage as fallback
namespace local_storage {
	export const getJwt = () => getCookie('jwt');
	export const getFedAuth = () => getCookie('fedAuthCookie');
	export const getImpersonatingJwt = () => getCookie('impersonatingJwt');
	function getCookie(c_name: string) {
        const item = localStorage.getItem(c_name);
        return item ? item : undefined;
	}

	export const storeJwt = (value: string) => setCookie('jwt', value);
	export const storeFedAuth = (value: string) => setCookie('fedAuthCookie', value);
	export const storeImpersonatingJwt = (value: string) => setCookie('impersonatingJwt', value);
	function setCookie(c_name: string, value: string) {
        localStorage.setItem(c_name, value);
	}


	export const clearJwt = () => deleteCookie('jwt');
	export const clearFedAuth = () => deleteCookie('fedAuthCookie');
	export const clearImpersonatingJwt = () => deleteCookie('impersonatingJwt');
	function deleteCookie(c_name: string) {
        localStorage.removeItem(c_name);
	}

	export function downloadFileFromBrowser(data: any, fileName: string) {
		// Initiate Browser file download
		if (window.navigator && window.navigator.msSaveBlob) {
			window.navigator.msSaveBlob(data, fileName);
		} else {
			let url = window.URL.createObjectURL(data);

			let a = document.createElement('a');
			const anchorId = `${fileName}_download_anchor`;

			a.id = anchorId;
			a.style.display = 'none';
			a.href = url;
			a.download = fileName;

			document.body.appendChild(a);
			a.click();

			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				let el = document.getElementById(anchorId);
				if (el) el.remove();
			}, 2000);
		}
	}

}

export default local_storage;