import { AppConfig } from '../types/config';
import { getRealmUrl, isBrokerage } from './brokerage_utils';
import http from './http';
import { isIOS, isMobileDevice } from './is_mobile';
const bowser = require('bowser');

export { http };

export const isIE: boolean = ((): boolean => bowser.name == 'Internet Explorer')();
export const isEdge: boolean = ((): boolean => bowser.name == 'Microsoft Edge')();

export const createExpiredLink = (agentCode: string, redirect?: string) => {
    const realm = getRealmUrl();
    const realmUrl = realm
        ? `&wtrealm=${encodeURIComponent(realm)}`
        : '';
    const redirectUrl = redirect
        ? `&ru=${encodeURIComponent(redirect)}`
        : '';
    return `${AppConfig.reset_password_link}${realmUrl}${redirectUrl}&expiredPwAgent=${agentCode}`;
};

export const createForgottenPasswordLink = (redirect?: string) => {
    const link = `${AppConfig.forgot_password_link}?CNY=${isBrokerage ? 'EX' : 'IN'}`;
    const redirectUrl = redirect
        ? `&ru=${encodeURIComponent(redirect)}`
        : '';
    return `${link}${redirectUrl}`;
};

export const openExternalLink = (url?: string) => {
    if (url) {
        window.open(url, isIOS ? '_self' : '_blank');
    }
}
export const makeOpenExternalLink = (url: string) => 
    () => openExternalLink(url);