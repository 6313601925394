import {
	GetHousehold,
	ReassignHousehold,
} from '../actions/household_actions';
import { isType } from 'typescript-fsa';
import { Action as ReduxAction } from 'redux';
import {
	GetActivity,
	CreateActivity,
	EditActivity,
	GetGoogleSignInCode,
	DeleteActivity,
	DisableGoogleCalendarSync,
	CreateEmployerActivity,
	GetPagedActivities,
	StoreActivityFilters,
	StoreFollowupDescription,
	GetActivitiesByContactId,
} from '../actions/activity_actions';
import {
	GetEmployer,
	UpdateEmployer,
	DeleteEmployer,
	SearchEmployers,
	ReassignEmployer,
} from '../actions/employer_actions';
import { SyncGoogleCalendar } from '../actions/user_actions';
import { Loaded } from '../utilities/utilities';
import { Contact } from './ContactReducer';
import BaseDTO from '../utilities/base_dto';
import { ClearCaches } from '../actions/authentication_actions';
import moment from 'moment';
import { BulkReassign, GetFilteredLeads } from '../actions/lead_actions';
import { Logout } from '../actions/authentication_actions';
import { Strings } from '../assets/common/strings';

export interface Activity extends BaseDTO {
	contact: Contact;
	lead?: string;
	title: string;
	description: string;
	correlationId?: string;
	time: Date;
	duration?:
	| 'none'
	| '15mins'
	| '30mins'
	| '45mins'
	| '1hr'
	| '1hr30mins'
	| '2hrs';
	isHighPriority: boolean;
	createdByName: string;
	modifiedBy: string;
	modifiedOn: Date;
	modifiedByName?: string;
	completedOn?: Date;
	completedBy?: string;
	completedByName?: string;
	location: string;
	alternatePhoneNumber: string;
	userId: string;
	isDeleted: boolean;
	status: Strings.ActivityStatus | null;
	disposition?: string;
	type: Strings.Activity;
	googleEventId: string;
	isAutomatedEmail?: boolean;
	isAllDayEvent?: boolean;
	sender: string;
	toRecipients: string;
	appointmentType: string;
	direction?: boolean;
	htmlContent?: string;
	subject?: string;
	householdId?: string;
	twilioSid: string;
	clickToCallDisposition: string;
	clickToCallSessionId?: string;
}

export interface GoogleSignInRequest {
	client_id: string;
	scope: string;
}
export interface GoogleSignInResponse {
	device_code: string;
	user_code: string;
	verification_url: string;
	expires_in: number;
	interval: number;
}

export interface GoogleVerifiedResponse {
	access_token: string;
	expires_in: number;
	token_type: string;
	refresh_token: string;
}

export interface FollowupFields {
	description: string;
	time?: moment.Moment;
	title?: string;
}

export interface ActivityState {
	activities: Loaded<Activity>[];
	isLoading: boolean;
	googleSignInCode: string;
	googleVerificationUrl: string;
	googleSignInCodeDone: number;
	googleDeviceCode: string;
	googlePollInterval: number;
	googlePollTimeout: number;
	googleSyncDisabledSuccessfully?: boolean;
	pagedActivities: {
		pageNum: number;
		pageSize: number;
		error: any;
		hasMore: boolean;
	};
	followupDescription: string;
	followupTime?: moment.Moment;
	followupTitle?: string;
}

export const initialState: ActivityState = {
	activities: [],
	isLoading: false,
	googleSignInCode: '',
	googleVerificationUrl: '',
	googleSignInCodeDone: 0,
	googleDeviceCode: '',
	googlePollInterval: 0,
	googlePollTimeout: 0,
	pagedActivities: {
		pageNum: 0,
		pageSize: 20,
		error: undefined,
		hasMore: false,
	},
	followupDescription: '',
	followupTime: undefined,
	followupTitle: '',
};

export function activityReducer(
	state: ActivityState = initialState,
	action: ReduxAction
): ActivityState {
	if (isType(action, GetActivity.started)) {
		const activitiesInState = state.activities.slice();
		upsertActivityStart(activitiesInState, { id: action.payload } as Activity);
		return {
			...state,
			isLoading: true,
			activities: activitiesInState,
		};
	} else if (
		isType(action, CreateActivity.started) ||
		isType(action, EditActivity.started) ||
		isType(action, CreateEmployerActivity.started) ||
		isType(action, DeleteActivity.started)
	) {
		const activitiesInState = state.activities.slice();
		upsertActivityStart(activitiesInState, action.payload);
		return {
			...state,
			isLoading: true,
			activities: activitiesInState,
		};
	} else if (
		isType(action, GetActivity.done) ||
		isType(action, CreateActivity.done) ||
		isType(action, EditActivity.done) ||
		isType(action, CreateEmployerActivity.done)
	) {
		const activitiesInState = state.activities.slice();
		upsertActivityDone(activitiesInState, action.payload.result);
		return {
			...state,
			isLoading: false,
			activities: activitiesInState,
		};
	} else if (isType(action, GetActivity.failed)) {
		const activitiesInState = state.activities.slice();
		upsertActivityDone(
			activitiesInState,
			{ id: action.payload.params } as Activity,
			action.payload.error
		);
		return {
			...state,
			isLoading: false,
		};
	} else if (
		isType(action, GetActivity.failed) ||
		isType(action, CreateActivity.failed) ||
		isType(action, EditActivity.failed) ||
		isType(action, CreateEmployerActivity.failed) ||
		isType(action, DeleteActivity.failed)
	) {
		const activityId = isType(action, GetActivity.failed)
			? action.payload.params
			: action.payload.params.id;
		const activitiesInState = state.activities.slice();
		upsertActivityDone(
			activitiesInState,
			{ id: activityId } as Activity,
			action.payload.error
		);
		return {
			...state,
			activities: activitiesInState,
		};
	} else if (isType(action, StoreActivityFilters)) {
		return {
			...state,
			activities: [],
			pagedActivities: {
				...initialState.pagedActivities,
			},
		};
	} else if (
		isType(action, GetPagedActivities.started) ||
		isType(action, GetActivitiesByContactId.started)
	) {
		return {
			...state,
			isLoading: true,
		};
	} else if (isType(action, GetPagedActivities.done)) {
		const activitiesInState = action.payload.params.clearCache
			? []
			: state.activities.slice();
		updateStateWithInboundActivities(activitiesInState, action.payload.result);
		const hasMore = action.payload.result.length === state.pagedActivities.pageSize;
		const pageSize =
			action.payload.params.pageSize >= 0
				? action.payload.params.pageSize
				: state.pagedActivities.pageSize;
		const pageNum =
			hasMore &&
				action.payload.params.pageNum >= 0 &&
				!action.payload.params.skipPageIncrease
				? action.payload.params.pageNum + 1
				: state.pagedActivities.pageNum;
		const pagedActivities = {
			pageSize,
			pageNum,
			error: undefined,
			hasMore,
		};
		return {
			...state,
			activities: activitiesInState,
			isLoading: false,
			pagedActivities,
		};
	} else if (isType(action, GetPagedActivities.failed)) {
		return {
			...state,
			isLoading: false,
			pagedActivities: {
				...state.pagedActivities,
				error: action.payload.error,
				hasMore: false,
			},
		};
	} else if (isType(action, DeleteActivity.done)) {
		const activitiesInState = state.activities.slice();
		const activityIndex = activitiesInState.findIndex(activity => activity.data.id == action.payload.params.id);
		activitiesInState.splice(activityIndex, 1);
		return {
			...state,
			isLoading: false,
			activities: activitiesInState,
		};
	} else if (
		isType(action, GetEmployer.done) ||
		isType(action, UpdateEmployer.done) ||
		isType(action, GetHousehold.done)
	) {
		const activitiesInState = state.activities.slice();
		updateStateWithInboundActivities(
			activitiesInState,
			action.payload.result.activities,
			true
		);
		
		return {
			...state,
			activities: activitiesInState,
		};
	} else if (isType(action, GetActivitiesByContactId.done)) {
		const activitiesInState = state.activities.slice();
		updateStateWithInboundActivities(
			activitiesInState,
			action.payload.result,
			true
		);
		return {
			...state,
			activities: activitiesInState,
		};
	} else if (isType(action, DeleteEmployer.done)) {
		const activitiesInState = state.activities.filter(
			activity => activity.employerId !== action.payload.params
		);
		return {
			...state,
			activities: activitiesInState,
		};
	} else if (isType(action, ReassignHousehold.done)) {
		const oldActivities = state.activities.slice();
		const newActivities = oldActivities.filter(activity => {
			return activity.householdId != action.payload.params.householdId;
		});
		return {
			...state,
			activities: newActivities,
		};
	} else if (isType(action, ReassignEmployer.done)) {
		const oldActivities = state.activities.slice();
		const newActivities = oldActivities.filter(activity => {
			return activity.employerId != action.payload.params.employerId;
		});
		return {
			...state,
			activities: newActivities,
		};
	} else if (isType(action, GetGoogleSignInCode.started)) {
		return {
			...state,
			isLoading: true,
			googleSignInCode: '',
			googleVerificationUrl: '',
			googleSignInCodeDone: 0,
			googleDeviceCode: '',
			googlePollInterval: 0,
			googlePollTimeout: 0,
		};
	} else if (isType(action, GetGoogleSignInCode.done)) {
		const result = action.payload.result;
		return {
			...state,
			isLoading: false,
			googleSignInCode: result.user_code,
			googleVerificationUrl: result.verification_url,
			googleSignInCodeDone: 100,
			googleDeviceCode: result.device_code,
			googlePollInterval: result.interval,
			googlePollTimeout: result.expires_in,
		};
	} else if (isType(action, GetGoogleSignInCode.failed)) {
		return {
			...state,
			isLoading: false,
			googleSignInCode: '',
			googleVerificationUrl: '',
			googleSignInCodeDone: 100,
			googleDeviceCode: '',
			googlePollInterval: 0,
			googlePollTimeout: 0,
		};
	} else if (isType(action, DisableGoogleCalendarSync.started)) {
		return {
			...state,
			googleSyncDisabledSuccessfully: undefined,
			isLoading: true,
		};
	} else if (isType(action, DisableGoogleCalendarSync.done)) {
		return {
			...state,
			googleSyncDisabledSuccessfully: true,
			isLoading: false,
		};
	} else if (isType(action, DisableGoogleCalendarSync.failed)) {
		return {
			...state,
			googleSyncDisabledSuccessfully: false,
			isLoading: false,
		};
	} else if (isType(action, SearchEmployers.done)) {
		const activitiesInState = state.activities.slice();
		let newActivities: Activity[] = [];
		action.payload.result.forEach(fullEmployer => {
			newActivities = newActivities.concat(fullEmployer.activities);
		});
		updateStateWithInboundActivities(activitiesInState, newActivities);
		return {
			...state,
			activities: activitiesInState,
		};
	} else if (isType(action, BulkReassign.done)) {
		const activitiesInState = state.activities.slice();
		const filteredActivities = activitiesInState.filter(activity =>
			action.payload.params.entities.some(
				entity =>
					entity.id != activity.householdId && entity.id != activity.employerId
			)
		);
		return {
			...state,
			activities: filteredActivities,
		};
	} else if (
		isType(action, Logout.started) ||
		isType(action, ClearCaches) ||
		isType(action, SyncGoogleCalendar.done)
	) {
		return {
			...initialState,
		};
	} else if (isType(action, GetFilteredLeads.done)) {
		const activitiesInState = state.activities.slice();
		const inboundActivities: Activity[] = [];
		action.payload.result.forEach(lead => {
			if (lead.nextActivity) {
				inboundActivities.push(lead.nextActivity);
			}
		});
		updateStateWithInboundActivities(activitiesInState, inboundActivities);
		return {
			...state,
			activities: activitiesInState,
		};
	} else if (isType(action, StoreFollowupDescription)) {
		return {
			...state,
			followupDescription: action.payload.description,
			followupTime: action.payload.time,
			followupTitle: action.payload.title || '',
		};
	} else {
		return state;
	}
}

function upsertActivityStart(
	cachedActivities: Loaded<Activity>[],
	activity: Activity
) {
	const matchedActivityIndex = cachedActivities.findIndex(cachedActivity => cachedActivity.data.id === activity.id);
	const householdId = activity.contact && activity.contact.householdId;
	const employerId = activity.contact && activity.contact.employerId;
	if (matchedActivityIndex !== -1) {
		cachedActivities[matchedActivityIndex] = {
			...cachedActivities[matchedActivityIndex],
			loading: true,
			employerId: employerId,
			householdId: householdId,
		};
	} else {
		const newActivity: Loaded<Activity> = {
			data: activity,
			loading: true,
			employerId: employerId,
			householdId: householdId,
			errors: [],
		};
		cachedActivities.push(newActivity);
	}
}

function upsertActivityDone(
	cachedActivities: Loaded<Activity>[],
	updatedActivity: Activity,
	error?: any
) {
	const index = cachedActivities.findIndex(cachedActivity => cachedActivity.data.id === updatedActivity.id);
	const householdId =
		updatedActivity.contact && updatedActivity.contact.householdId;
	const employerId =
		updatedActivity.contact && updatedActivity.contact.employerId;

	cleanupActivity(updatedActivity);

	if (index > -1) {
		if (error)
			cachedActivities[index] = {
				...cachedActivities[index],
				loading: false,
				errors: [...cachedActivities[index].errors, error],
			};
		else if (updatedActivity.isDeleted) cachedActivities.splice(index, 1);
		else {
			cachedActivities[index] = {
				...cachedActivities[index],
				data: updatedActivity,
				employerId: employerId,
				householdId: householdId,
				loading: false,
				errors: [],
			};
		}
	} else {
		const newActivity: Loaded<Activity> = {
			data: updatedActivity,
			loading: false,
			employerId: employerId,
			householdId: householdId,
			errors: error ? [error] : [],
		};
		cachedActivities.push(newActivity);
	}
}

function updateStateWithInboundActivities(
	activitiesInState: Loaded<Activity>[],
	inboundActivities: Activity[],
	keepMetaData?: boolean
) {
	inboundActivities
		.filter(activity => activity.type !== 'E-mail')
		.forEach(inboundActivity => {
			const activityId = inboundActivity.id;
			const householdId =
				inboundActivity.contact && inboundActivity.contact.householdId;
			const employerId =
				inboundActivity.contact && inboundActivity.contact.employerId;

			cleanupActivity(inboundActivity);

			const matchingIndexInState = activitiesInState.findIndex(currentActivity => currentActivity.data.id === activityId);
			if (matchingIndexInState > -1) {
				const { createdByName, modifiedByName, completedBy, completedByName, completedOn } = activitiesInState[matchingIndexInState].data;
				const cachedMetaData: Partial<Activity> = keepMetaData ? { createdByName, modifiedByName, completedBy, completedByName, completedOn } : {};

				activitiesInState[matchingIndexInState] = {
					...activitiesInState[matchingIndexInState],
					loading: false,
					data: { ...inboundActivity, ...cachedMetaData },
					employerId: employerId,
					householdId: householdId,
					errors: [],
				};
			} else {
				const newActivity: Loaded<Activity> = {
					loading: false,
					data: inboundActivity,
					errors: [],
					employerId: employerId,
					householdId: householdId,
				};
				activitiesInState.push(newActivity);
			}
		});
}

export function cleanupActivity(activity: Activity) {
	if (activity.status === 'completed' && !activity.completedOn) {
		activity.completedOn = activity.createdOn;
		activity.completedBy = activity.createdBy;
		activity.completedByName = activity.createdByName;
	}

	if (!activity.time) activity.time = activity.createdOn;

	if (!activity.contact) activity.contact = {} as Contact;
}
