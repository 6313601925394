import { Action as ReduxAction } from 'redux';
import { isType } from 'typescript-fsa';
import {
	getReshopResultsByAgentId,
	getReshopSettings,
	updateReshopSettings,
	updateReshopRecommendationStatus,
	clearReshopSummaries,
} from '../actions/reshop_actions';
import { ReshopResultsResponse } from '../components/reshop/models/reshop_responses';
import { AgentEnrollmentSettings } from '../components/reshop/models/table_data';
import { ReshopSummary, summaryHasExpired } from '../utilities/reshop_util';

export interface ReshopState {
	isLoading: boolean;
	reshopSummaries: ReshopSummary[];
	agentEnrollmentSettings: AgentEnrollmentSettings;
	reshopResults: ReshopResultsResponse;
}

export const initialSettingsState: AgentEnrollmentSettings = {
	agentId: '',
	isEnrolled: false,
	hasExpiration: false,
	expirationDays: 10,
};

const initialState: ReshopState = {
	isLoading: false,
	reshopSummaries: [],
	agentEnrollmentSettings: initialSettingsState,
	reshopResults: {
		currentPage: 1,
		pageSize: 20,
		results: [],
		totalCount: 0,
	},
};



export function reshopReducer(state: ReshopState = initialState, action: ReduxAction): ReshopState {
	if (isType(action, getReshopResultsByAgentId.started)) {
		return {
			...state,
			isLoading: true,
		};
	} else if (isType(action, getReshopResultsByAgentId.done)) {
		const currentSummaries = state.reshopSummaries;
		const allSummaries = [...currentSummaries, ...action.payload.result.results];
		const uniqueSummaries = allSummaries.filter(
			(item, index, array) =>
				index ===
				array.findIndex((foundItem) => foundItem.reshopResults.reShopId === item.reshopResults.reShopId)
		);
		return {
			...state,
			reshopSummaries: uniqueSummaries,
			reshopResults: action.payload.result,
			isLoading: false,
		};
	} else if (isType(action, getReshopResultsByAgentId.failed)) {
		return {
			...state,
			isLoading: false,
		};
	} else if (isType(action, updateReshopSettings.started)) {
		return {
			...state,
		};
	} else if (isType(action, updateReshopSettings.done)) {
		if(state.agentEnrollmentSettings.hasExpiration){
			const mutatedList = [...state.reshopSummaries];
			const itemsToUpdate = mutatedList.filter((item) => summaryHasExpired(item,state.agentEnrollmentSettings.expirationDays));
			return {
				...state,
				reshopSummaries: itemsToUpdate,
				agentEnrollmentSettings: action.payload.result,
			};
		}
		return {
			...state,
			agentEnrollmentSettings: action.payload.result,

		}
	} else if (isType(action, updateReshopSettings.failed)) {
		return {
			...state,
		};
	} else if (isType(action, getReshopSettings.started)) {
		return {
			...state,
			isLoading: true,
		};
	} else if (isType(action, getReshopSettings.done)) {
		return {
			...state,
			isLoading: false,
			agentEnrollmentSettings: action.payload.result,
		};
	} else if (isType(action, getReshopSettings.failed)) {
		return {
			...state,
			isLoading: false,
		};
	} else if (isType(action, updateReshopRecommendationStatus.started)) {
		return {
			...state,
			isLoading: true,
		};
	} else if (isType(action, updateReshopRecommendationStatus.done)) {
		const mutatedList = [...state.reshopSummaries];
		const itemToUpdate = mutatedList.find(
			(recommendation) => recommendation.reshopResults.reShopId === action.payload.result.reShopId
		);
		if (itemToUpdate) {
			itemToUpdate.reshopResults.isAgentReviewed = true;
			itemToUpdate.reshopResults.isAccepted = action.payload.result.isAccepted;
		}

		return {
			...state,
			isLoading: false,
			reshopSummaries: mutatedList,
		};
	} else if (isType(action, updateReshopRecommendationStatus.failed)) {
		return {
			...state,
		};
	} else if (isType(action, clearReshopSummaries)) {
		return {
			...state,
			reshopResults: {
				...state.reshopResults,
				results: [],
			},
		};
	} else {
		return state;
	}
}
