import { AppState } from '../reducers';


export const getSessionId = (state: AppState): string => 
    (state.clickToCall.session || {id: ''}).id || '';

export const getInCall = (state: AppState): boolean =>
    state.clickToCall.metadata.inCall;

export const getCallerId = (state: AppState): string =>
    state.user.callerId;

export const getCallerIdVerified = (state: AppState): boolean =>
    Boolean(state.clickToCall.callerIdVerified);