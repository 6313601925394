import React from 'react';
import _ from 'lodash';
import {
	Card,
	CardContent,
	Typography,
	Grid,
	FormControl,
	InputLabel,
} from '@material-ui/core';
import { Field } from 'redux-form';
import { DatePicker, SingleSelect } from '../../components/redux_form_material';
import { ApplicationUpsertFormValues } from './ApplicationUpsertForm';
import {
	validateRequired,
	validateCarrier,
	validateProductType,
	validateRequiredNoSpaces,
} from '../../utilities/form_validation';
import { SelectListOptions } from '../utility/select_list_options';
import { createRequiredLabel } from '../utility/required_label';
import { getYesNoFromBool, getBoolFromYesNo } from '../../utilities/formatting/data_normalizations';
import { Strings as S } from '../../assets/common/strings';
import { isDefaultDate } from '../../utilities/date_util';
import { FormTextField, FormSelect } from '../../utilities/forms';

interface ApplicationGeneralCardProps {
	formValues: ApplicationUpsertFormValues;
	linesOfBusiness: string[];
	metalLevels: string[];
	carriers: string[];
	productTypes: string[];
	formName: string;
	changeFieldValue: (formName: string, formPath: string, value: any) => void;
}

interface ApplicationGeneralCardState { }

export class ApplicationGeneralCard extends React.Component<
	ApplicationGeneralCardProps,
	ApplicationGeneralCardState
	> {
	private handleChange = (newAppStatus: string) => {
		if (newAppStatus === 'Pending') {
			this.props.changeFieldValue(
				this.props.formName,
				'application.statusReason',
				'Enrolled'
			);
		} else if (newAppStatus === 'Active' || newAppStatus === 'Terminated') {
			this.props.changeFieldValue(
				this.props.formName,
				'application.statusReason',
				''
			);
		}
	};

	private handleOutstandingDocumentsChange = (event: React.SyntheticEvent<HTMLSelectElement>) => {
		event?.preventDefault && event.preventDefault();

		this.props.changeFieldValue(
			this.props.formName,
			'application.isOutstandingDocumentsNeeded',
			getBoolFromYesNo(event.currentTarget.value)
		)
	}

	render() {
		const mode = window.innerHeight < 520 ? 'landscape' : 'portrait';
		const carrier =
			this.props.formValues &&
				this.props.formValues.application &&
				this.props.formValues.application.carrier
				? this.props.formValues.application.carrier
				: '';
		const displayCarrierFriendlyName: boolean =
			carrier.toLowerCase() === 'not-contracted';
		const carriersMenuList = this.props.carriers;

		const appStatusOptions: string[] = [
			S.ProductStatus.Pending,
			S.ProductStatus.Active,
			S.ProductStatus.Terminated
		];

		const statusReasonOptions: string[] = [
			S.ProductStatusReason.PendingEnrollment,
			S.ProductStatusReason.Enrolled
		];

		const outstandingDocumentsOptions: string[] = [
			S.YesNoOptionDisplay.Yes,
			S.YesNoOptionDisplay.No
		];

		const { formValues } = this.props;
		let carrierValue = '';
		let productType = '';
		let isOutstandingDocumentsNeededValue = false;
		if (formValues && formValues.application) {
			if (formValues.application.carrier) {
				carrierValue = formValues.application.carrier;
			}
			if (formValues.application.productType) {
				productType = formValues.application.productType;
			}
			if (formValues.application.isOutstandingDocumentsNeeded) {
				isOutstandingDocumentsNeededValue = formValues.application.isOutstandingDocumentsNeeded;
			}
		}

		return (
			<div>
				<Typography variant="h5" style={{ margin: '20px 0px 5px 20px' }}>
					{S.ProductGeneralEditOptions.General}
				</Typography>
				<Card style={styles.cardStyle}>
					<CardContent>
						<Grid container>
							<Grid item xs={12} sm={6}>
								<Field
									fullWidth
									name="application.lineOfBusiness"
									label={createRequiredLabel(S.ProductGeneralEditOptions.LineOfBusiness)}
									component={FormSelect}
									children={SelectListOptions({
										options: this.props.linesOfBusiness.map(
											(lineOfBusiness: string) => {
												return {
													option: lineOfBusiness,
												};
											}
										),
									})}
									validate={[validateRequired]}
									style={styles.editListItem}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Field
									name="application.carrier"
									label={createRequiredLabel(S.ProductGeneralEditOptions.Carrier)}
									component={SingleSelect}
									initialValue={carrierValue}
									resultSet={carriersMenuList}
									validate={[validateRequiredNoSpaces, validateCarrier]}
									styleObject={styles.editListItem}
								/>
							</Grid>
						</Grid>
						<Grid container>
							<Grid item xs={12}>
								<Field
									name="application.carrierFriendlyName"
									label={S.ProductGeneralEditOptions.CarrierName}
									component={FormTextField}
									style={
										displayCarrierFriendlyName
											? styles.editListItem
											: { display: 'none' }
									}
								/>
							</Grid>
						</Grid>
						<Grid container>
							<Grid item xs={12} sm={6}>
								<Field
									name="application.productType"
									label={createRequiredLabel(S.ProductGeneralEditOptions.ProductType)}
									component={SingleSelect}
									initialValue={productType}
									resultSet={this.props.productTypes}
									validate={[validateRequiredNoSpaces, validateProductType]}
									styleObject={styles.editListItem}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Field
									name="application.productName"
									label={S.ProductGeneralEditOptions.Product}
									component={FormTextField}
									style={styles.editListItem}
								/>
							</Grid>
						</Grid>
						<Grid container>
							<Grid item xs={12} sm={6}>
								<Field
									fullWidth
									name="application.metalLevel"
									label={S.ProductGeneralEditOptions.MetalLevel}
									component={FormSelect}
									children={SelectListOptions({
										options: this.props.metalLevels.map(
											(metalLevel: string) => {
												return {
													option: metalLevel,
												};
											}
										),
									})}
									style={styles.editListItem}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Field
									name={'application.appDate'}
									label={createRequiredLabel(S.ProductGeneralEditOptions.ApplicationDate)}
									component={DatePicker}
									validate={[validateRequired]}
									style={styles.editListItem}
								/>
							</Grid>
						</Grid>
						<Grid container>
							<Grid item xs={12} sm={6}>
								<Field
									fullWidth
									name="application.applicationStatus"
									label={S.ProductGeneralEditOptions.ApplicationStatus}
									component={FormSelect}
									children={SelectListOptions({
										options: appStatusOptions.map((appStatus: string) => {
											return {
												option: appStatus,
											};
										}),
									})}
									style={styles.editListItem}
									onChange={(_event: any, newAppStatus: string) =>
										this.handleChange(newAppStatus)
									}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Field
									name="application.effectiveDate"
									label={S.ProductGeneralEditOptions.EffectiveDate}
									component={DatePicker}
									style={styles.editListItem}
									useUtc
								/>
							</Grid>
						</Grid>
						<Grid container>
							<Grid item xs={12} sm={6}>
								<Field
									label={S.ProductGeneralEditOptions.StatusReason}
									name="application.statusReason"
									component={FormSelect}
									children={SelectListOptions({
										options: statusReasonOptions.map(
											(statusReason: string) => {
												return {
													option: statusReason,
												};
											}
										),
									})}
									fullWidth
									style={styles.editListItem}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Field
									name={"application.policyEndDate"}
									label={S.ProductGeneralEditOptions.PolicyEndDate}
									component={DatePicker}
									useUtc={true}
									style={styles.editListItem}
									initialValue={S.DateFormat.Default}
									format={(value: string) => isDefaultDate(value) ? S.DateFormat.Default : value}
								/>
							</Grid>
						</Grid>
						<Grid container>
							<Grid item xs={12} sm={6}>
								<Field
									fullWidth
									name="application.isOutstandingDocumentsNeeded"
									label={S.ProductGeneralEditOptions.OutstandingDocument}
									component={FormSelect}
									children={SelectListOptions({
										options: outstandingDocumentsOptions.map(
											(statusReason: string) => {
												return {
													option: statusReason,
												};
											}
										),
									})}
									style={styles.editListItem}
									onChange={this.handleOutstandingDocumentsChange}
									onBlur={this.handleOutstandingDocumentsChange}
									format={() => getYesNoFromBool(isOutstandingDocumentsNeededValue)}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Field
									name={"application.outstandingDocumentsDueDate"}
									label={S.ProductGeneralEditOptions.OutstandingDocumentDueDate}
									component={DatePicker}
									style={styles.editListItem}
								/>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</div>
		);
	}
}

const styles: { [key: string]: React.CSSProperties } = {
	cardStyle: {
		margin: 10,
		marginTop: 0,
	},
	editListItem: {
		marginLeft: 10,
		width: '90%',
	},
	readOnlyTextStyle: {
		color: 'black',
		backgroundColor: 'transparent',
		fontSize: 15,
	},
};
