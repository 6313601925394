import React from 'react';
import { defaultSvgProps, SvgProps } from '..';

const TrainingSvg: React.FC<SvgProps> = (props) => (
    <svg viewBox="0 0 35 35" {...props}>
        <path
            d="M16.92 29.25a.47.47 0 0 1-.35-.15.48.48 0 0 1-.11-.54 6.33 6.33 0 0 0-.68-6.08 9 9 0 0 1-1-.14.49.49 0 0 1-.39-.39 7.33 7.33 0 0 1-.15-1 6.4 6.4 0 0 0-6.07-.69.49.49 0 0 1-.55-.11.51.51 0 0 1-.11-.54A7 7 0 0 1 9 17.32a7.18 7.18 0 0 1 6.14-2 12.76 12.76 0 0 1 2.73-4 12.4 12.4 0 0 1 8.64-3.73 10.61 10.61 0 0 1 2 .2.5.5 0 0 1 .39.39 12.06 12.06 0 0 1-3.53 10.68 12.68 12.68 0 0 1-4 2.74 7.15 7.15 0 0 1-2 6.14 7 7 0 0 1-2.28 1.52.41.41 0 0 1-.17-.01zm-1.63-7.83l.79.08a.54.54 0 0 1 .36.2 7.36 7.36 0 0 1 1.33 6 6 6 0 0 0 .91-.75 6.16 6.16 0 0 0 1.66-5.64.5.5 0 0 1 .31-.57 11.91 11.91 0 0 0 4.08-2.66A11.13 11.13 0 0 0 28 8.66 11.21 11.21 0 0 0 18.6 12a11.8 11.8 0 0 0-2.67 4.07.5.5 0 0 1-.56.31A6.26 6.26 0 0 0 9.72 18a5.78 5.78 0 0 0-.74.91 7.48 7.48 0 0 1 6 1.33.49.49 0 0 1 .2.36c.04.31.07.57.11.82z"
        />
        <path
            d="M23.24 16a2.48 2.48 0 0 1-1.76-.73 2.51 2.51 0 0 1 0-3.54 2.57 2.57 0 0 1 3.54 0A2.5 2.5 0 0 1 23.24 16zm0-4a1.47 1.47 0 0 0-1.06.44 1.51 1.51 0 0 0 0 2.12 1.53 1.53 0 0 0 2.12 0A1.5 1.5 0 0 0 23.24 12zM11.87 26.32a6.61 6.61 0 0 1-1-.08.51.51 0 0 1-.41-.42 6.78 6.78 0 0 1 2-5.83.51.51 0 0 1 .71 0 .5.5 0 0 1 0 .71 5.83 5.83 0 0 0-1.76 4.6A5.86 5.86 0 0 0 16 23.53a.5.5 0 1 1 .71.71 6.9 6.9 0 0 1-4.84 2.08z"
        />
    </svg>
);
TrainingSvg.defaultProps = defaultSvgProps;

export default TrainingSvg;
