import { Button } from '@material-ui/core';
import React from 'react';
import { Widget } from './base_widget';
import { AppState } from '../../reducers';
import { Dispatch } from '@reduxjs/toolkit';
import { Strings } from '../../assets/common/strings';
import { themePalette } from '../../utilities/branding';
import { useLinkToExternal, useNavigateTo } from '../../utilities/hooks';
import ESigSvg from '../svgs/icons/eSig';
import { connect } from '@optum-uhone-hmkts/rise';
import { AppConfig } from '../../types';

interface StateProps {}

interface DispatchProps {}

type Props = StateProps & DispatchProps;

const ESignaturesWidget: React.FC<Props> = (props) => {

    const navToESignatures = useLinkToExternal(AppConfig.esig_dashboard_link);

	const renderWidget = () => {
		return null;
	};

	return (
		<Widget
			title={Strings.WidgetTitles.ESignatures}
			avatar={<ESigSvg width={30} height={30} fill={themePalette.menuSvgESignatures} />}
			actionButtons={[<Button onClick={navToESignatures}>View Details</Button>]}>
			{renderWidget()}
		</Widget>
	);
};

const mapStateToProps = (state: AppState): StateProps => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({});

export const ESignaturesWidgetContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
	true
)(ESignaturesWidget);