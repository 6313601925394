class TwoWayMap<K, V>{
    map: Map<K, V>;
    reverseMap: Map<V, K>;

    get size() {
        return this.map.size;
    }

    constructor(map?: Map<K, V>) {
        this.map = map || new Map<K, V>();

        this.reverseMap = new Map<V, K>();
        for (const [key, value] of this.map) {
            this.reverseMap.set(value, key);
        }
    }

    set(key: K, value: V) {
        this.map.set(key, value);
        this.reverseMap.set(value, key);
    }

    has(key: K) {
        return this.map.has(key);
    }

    delete(key: K) {
        const value = this.map.get(key);
        this.map.delete(key);
        if (value) {
            this.reverseMap.delete(value);
        }
    }

    keyValues() {
        const keyValues: [any, any][] = [];
        for (const [key, value] of this.map) {
            keyValues.push([key, value]);
        }
        return keyValues;
    }

    get(key: K) {
        return this.map.get(key);
    }

    revGet(key: V) {
        return this.reverseMap.get(key);
    }
}

const makeTimeZoneTwoWayMap = () => {
    const timeZoneMap = new TwoWayMap<string, string>();

    timeZoneMap.set('Eastern Standard Time', 'Eastern');
    timeZoneMap.set('Central Standard Time', 'Central');
    timeZoneMap.set('US Mountain Standard Time', 'Arizona'); //Mountain (no DST)
    timeZoneMap.set('Mountain Standard Time', 'Mountain');
    timeZoneMap.set('Pacific Standard Time', 'Pacific');
    timeZoneMap.set('Alaskan Standard Time', 'Alaska');
    timeZoneMap.set('Aleutian Standard Time', 'Aleutian');
    timeZoneMap.set('Hawaiian Standard Time', 'Hawaii');

    return timeZoneMap;
}

export const timeZoneTupple = [
    ['Eastern', 'Eastern Standard Time',],
    ['Central', 'Central Standard Time',],
    ['Arizona', 'US Mountain Standard Time',],
    ['Mountain', 'Mountain Standard Time',],
    ['Pacific', 'Pacific Standard Time',],
    ['Alaska', 'Alaskan Standard Time',],
    ['Aleutian', 'Aleutian Standard Time',],
    ['Hawaii', 'Hawaiian Standard Time',],
]
export const timeZoneStandardToDisplayMap = makeTimeZoneTwoWayMap();