import { actionCreatorFactory } from 'typescript-fsa';
import { Action as ReduxAction } from 'redux';

const actionCreator = actionCreatorFactory('Snackbar');
export interface SnackbarProps {
	text?: JSX.Element | string;
	autoHideDuration?: number;
	severity?: "error" | "warning" | "success" | "info";
	action?: JSX.Element[];
	onClose?: () => void;
}

export const QueueSnackbar = actionCreator<SnackbarProps>(
	'QUEUE_SNACKBAR'
);

export const DequeueSnackbar = actionCreator('DEQUEUE_SNACKBAR');

export const NextSnackbar = actionCreator('NEXT_SNACKBAR');

export const ClearSnackbar = actionCreator('CLEAR_SNACKBAR');

export const SetDeviceConnectionStatus = actionCreator<{
	connectionStatus: boolean;
}>('SET_DEVICE_CONNECTION_STATUS');

export function setDeviceConnectionStatus(
	connectionStatus: boolean
): ReduxAction {
	return SetDeviceConnectionStatus({ connectionStatus: connectionStatus });
}

export const SetShowedOfflineSnackBar = actionCreator<{
	showedOfflineSnackBar: boolean;
}>('SET_SHOWED_OFFLINE_SNACKBAR');

export function setShowedOfflineSnackBar(
	showedOfflineSnackBar: boolean
): ReduxAction {
	return SetShowedOfflineSnackBar({
		showedOfflineSnackBar: showedOfflineSnackBar,
	});
}
