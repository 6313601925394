import _ from 'lodash';
import {
	Button,
	FormControlLabel,
	FormGroup,
	Grid,
	List,
	ListSubheader,
	MenuItem,
	Paper,
	Switch,
	TextField,
	Dialog,
	DialogContent,
	DialogActions,
	DialogContentText,
	DialogTitle
} from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import {
	EditActivity,
	FilterOptions,
	GetPagedActivities,
	StoreActivityFilters,
	CloseOldActivities
} from '../../actions/activity_actions';
import { SearchEmployers } from '../../actions/employer_actions';
import { updateDispositionReturnPath, StoreScrollLocation, navigateTo } from '../../actions/navigation_actions';
import { QueueSnackbar, SnackbarProps } from '../../actions/snackbar_actions';
import { ActivityDispositionDialog } from '../../components/activity/disposition_dialog';
import { BasePageContainer } from '../../components/Layout/BasePage';
import {
	HeaderBarComponent,
} from '../../components/Layout/HeaderBar';
import { FabMenu } from '../../components/nav/fab_menu';
import { jwt_auth } from '../../utilities/auth';
import { navRoutes, getNavRouteByKey, NavigationProps } from '../../components/nav/Routes';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import {
	notificationCategories,
	NotificationSummary,
} from '../../components/notifications/notificationSummary';
import { themePalette, themeLinks } from '../../utilities/branding';
import { AppState } from '../../reducers';
import { ActivityFilters } from '../../reducers/activity_filter_reducer';
import { Activity } from '../../reducers/activity_reducer';
import { Employer } from '../../reducers/employer_reducer';
import { Loaded } from '../../utilities/utilities';
import { ActivityItem } from './list/activity_item';
import { DatePicker } from '../../components/redux_form_material';
import { InfiniteScroller } from '../../components/utility/infinite_scroller';
import { Strings as S, Strings } from '../../assets/common/strings';
import { MoreMenu, createMenuAction, createHelpAction } from '../../components/nav/more_menu';
import { P } from '../../utilities/auth/permissions';
import { fullName } from '../../assets/common/string_builders';
import { CallSequence } from '../dialogs/sequence/call_sequence';
import { nav } from '../..';
import { ListFilter } from '../../components/filter/list_filter';
import { Dispatch } from '@reduxjs/toolkit';
import { DesktopPadding } from '../../components/Layout/desktop_padding';
import { EmptyMessage } from '../../components/utility/empty_message';

interface StateProps {
	activities: Loaded<Activity>[];
	isLoading: boolean;
	userId: string;
	firstName: string;
	preferredName: string;
	lastName: string;
	filters: ActivityFilters;
	pageNum: number;
	hasMoreActivities: boolean;
	hasError: boolean;
	employers: Employer[];
	desktopCallEnabled: boolean;
	desktopTextEnabled: boolean;
}
interface DispatchProps {
	getPagedActivities: (options: FilterOptions, pageNum: number, clearCache?: boolean) => void;
	editActivity: (activityToEdit: Activity) => void;
	queueSnackbar: (snackbarProps: SnackbarProps) => void;
	searchEmployers: (searchText: string) => void;
	setFilters: (filters: ActivityFilters) => void;
	closeOldActivities: () => void;
	storeScrollLocation: (scrollLocation: string) => void;
}
interface Props extends NavigationProps, StateProps, DispatchProps {}

interface State {
	filteredActivities: Loaded<Activity>[];
	isUpdating: boolean;
	dispositionDialogIsOpen: boolean;
	passContact: boolean;
	pickedActivity: Loaded<Activity>;
	filterMessageOverride: string;
	tempFilterStore: ActivityFilters;
	closeOldActivitiesDialogOpen: boolean;
}

class ActivitiesPage extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			filteredActivities: [],
			isUpdating: false,
			dispositionDialogIsOpen: false,
			passContact: false,
			pickedActivity: {} as Loaded<Activity>,
			filterMessageOverride: '',
			tempFilterStore: this.props.filters,
			closeOldActivitiesDialogOpen: false
		};
	}

	componentDidMount() {
		this.applyFilterValues(true);
		this.setFilteredActivities(this.props.activities);
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.activities !== this.props.activities) {
			this.setFilteredActivities(this.props.activities);
		}

		if (prevProps.filters !== this.props.filters) {
			this.applyFilterValues();
		}

		if (prevProps.isLoading && !this.props.isLoading)
			this.networkCallLock = false;
	}

	renderActivityItem = (activity: Loaded<Activity>) => {
		return (
			<ActivityItem
				key={activity.data.id}
				setSelectedActivity={this.setPickedActivity}
				activity={activity}
				loading={activity.loading}
				handleUpdateActivity={this.handleActivityCompletedClick}
				handleNavigateToActivity={this.handleNavigateToActivity}
			/>
		);
	};

	applyFilterValues = (clearCache = false) => {
		this.networkCallLock = true;

		const { highPriorityFilterValue, searchFilterValue } = this.props.filters;
		const statusFilterValue = this.props.filters.statusFilter.value as Strings.ActivityStatus;
		const typeFilter = this.props.filters.typeFilter;

		const startTime = this.props.filters.startTime ? moment(this.props.filters.startTime).utc().toDate() : undefined;
		const endTime = this.props.filters.endTime ? moment(this.props.filters.endTime).utc().toDate() : undefined;

		this.props.getPagedActivities(
			{
				isHighPriority: highPriorityFilterValue
					? true
					: undefined,
				status: statusFilterValue,
				type: typeFilter.values[typeFilter.selectedIndex],
				startTime,
				endTime,
				searchText: searchFilterValue,
			},
			clearCache ? 0 : this.props.pageNum,
			clearCache
		);
	};

	networkCallLock = false;
	loadMoreActivities = () => {
		if (
			!this.props.hasError &&
			this.props.hasMoreActivities &&
			!this.networkCallLock
		) {
			this.applyFilterValues();
		}
	};

	handleOnFilterSave = () => {
		const { tempFilterStore } = this.state;
		this.props.setFilters(tempFilterStore);
		this.networkCallLock = false;

		if (
			tempFilterStore.searchFilterValue &&
			tempFilterStore.searchFilterValue.length >= 3
		) {
			this.props.searchEmployers(tempFilterStore.searchFilterValue);
		}
	};

	handleFilterReset = () => {
		const dueFilter = this.props.filters.dueFilter;
		const statusFilter = this.props.filters.statusFilter;
		const typeFilter = this.props.filters.typeFilter;
		const completedFilter = this.props.filters.completedFilter;
		const searchFilterValue = '';
		const highPriorityFilterValue = false;
		dueFilter.selectedIndex = dueFilter.defaultIndex;
		dueFilter.value = dueFilter.values[dueFilter.defaultIndex];
		statusFilter.selectedIndex = statusFilter.defaultIndex;
		statusFilter.value = statusFilter.values[statusFilter.defaultIndex];
		typeFilter.selectedIndex = typeFilter.defaultIndex;
		typeFilter.value = typeFilter.values[typeFilter.defaultIndex];
		completedFilter.selectedIndex = completedFilter.defaultIndex;
		completedFilter.value =
			completedFilter.values[completedFilter.defaultIndex];

		this.setState({
			tempFilterStore: {
				dueFilter,
				statusFilter,
				typeFilter,
				searchFilterValue,
				highPriorityFilterValue,
				completedFilter,
				...this.getStartEndDates(statusFilter.value, dueFilter.value)
			},
		});
	};

	flipCompletedStatus = (activityToUpdate: Activity, undo: boolean = false) => {
		let _activityToUpdate = Object.assign({}, activityToUpdate);
		const currentTime = moment.utc().toDate();
		const name = fullName(this.props);
		if (_activityToUpdate.status == Strings.ActivityStatus.Completed || undo) {
			_activityToUpdate.completedBy = undefined;
			_activityToUpdate.completedByName = undefined;
			_activityToUpdate.completedOn = undefined;
			_activityToUpdate.status = Strings.ActivityStatus.Open;
		} else {
			_activityToUpdate.completedBy = this.props.userId;
			_activityToUpdate.completedByName = name;
			_activityToUpdate.completedOn = currentTime;
			_activityToUpdate.status = Strings.ActivityStatus.Completed;
		}
		_activityToUpdate.updatedBy = this.props.userId;
		_activityToUpdate.modifiedByName = name;
		_activityToUpdate.updatedOn = currentTime;
		this.props.editActivity(_activityToUpdate);
	};

	openDispositionDialog = () =>
		this.setState({ dispositionDialogIsOpen: true });

	closeDispositionDialog = () =>
		this.setState({ dispositionDialogIsOpen: false });

	onUndo = (activityToUpdate: Activity) => {
		this.flipCompletedStatus(activityToUpdate, true);
	};

	handleActivityCompletedClick = (activityToUpdate: Loaded<Activity>) => {
		this.setState({
			pickedActivity: activityToUpdate,
		});
		if (
			activityToUpdate.data.type == Strings.Activity.PhoneCall ||
			activityToUpdate.data.type == Strings.Activity.Appointment
		) {
			this.openDispositionDialog();
		} else {
			this.flipCompletedStatus(activityToUpdate.data);
			this.props.queueSnackbar({
				text: <span>{_.startCase(activityToUpdate.data.type)} Completed</span>,
				autoHideDuration: 4000,
				action: [
					<Button
						key="checkSnackbar"
						color="inherit"
						onClick={() => this.onUndo(activityToUpdate.data)}
						children={S.ButtonText.Undo}
					/>
				],
			});
		}
	};

	setPickedActivity = (activity: Loaded<Activity>) => {
		this.setState({ pickedActivity: activity });
	};

	handleNavigateToActivity = (activityId: string, activityType: string, key: string) => {
		this.props.updateDispositionReturnPath(navRoutes.activities.path);
		this.props.storeScrollLocation(key);
		const detailRoute =
			activityType == Strings.Activity.PhoneCall
				? 'phoneDetail'
				: activityType.toLowerCase() + 'Detail';
		const route = getNavRouteByKey(detailRoute)?.path?.replace(S.Navigation.ActivityId, activityId);
		if (route) {
			nav.push(route);
		}
	};

	displayFilterValues = () => {
		const filters: string[] = [];
		const type = this.props.filters.typeFilter.displayOptions[
			this.props.filters.typeFilter.selectedIndex
		];
		filters.push(type);
		const status = this.props.filters.statusFilter.displayOptions[
			this.props.filters.statusFilter.selectedIndex
		];
		filters.push(status);
		this.props.filters.highPriorityFilterValue && filters.push('High Priority');
		let time = '';
		if (this.props.filters.statusFilter.selectedIndex == 0)
			time = this.props.filters.dueFilter.displayOptions[
				this.props.filters.dueFilter.selectedIndex
			];
		else if (this.props.filters.statusFilter.selectedIndex == 1)
			time = this.props.filters.completedFilter.displayOptions[
				this.props.filters.completedFilter.selectedIndex
			];
		else time = 'Unknown';
		filters.push(time);
		return filters.join(', ');
	};

	handleTypeFilterChange = (event: any) => {
		const typeFilter = this.state.tempFilterStore.typeFilter;
		typeFilter.selectedIndex = event.target.value;
		typeFilter.value = typeFilter.values[event.target.value];
		this.setState({
			tempFilterStore: {
				...this.state.tempFilterStore,
				typeFilter,
			},
		});
	};

	handleStatusFilterChange = (event: any) => {
		const statusFilter = this.state.tempFilterStore.statusFilter;
		statusFilter.selectedIndex = event.target.value;
		statusFilter.value = statusFilter.values[event.target.value];
		const timeRange = statusFilter.value === Strings.ActivityStatus.Open ? this.state.tempFilterStore.dueFilter.value : this.state.tempFilterStore.completedFilter.value;
		this.setState({
			tempFilterStore: {
				...this.state.tempFilterStore,
				statusFilter,
				...this.getStartEndDates(statusFilter.value, timeRange)
			},
		});
	};

	getStartEndDates = (status: string, timeRange: string): { startTime?: Date, endTime?: Date } => {
		if (status === Strings.ActivityStatus.Open) {
			switch (timeRange) {
				case 'anytime': {
					return { startTime: undefined, endTime: undefined };
				}
				case 'past': {
					return { startTime: undefined, endTime: moment().endOf('day').toDate() };
				}
				case '-30': {
					return { startTime: moment().startOf('day').subtract(30, 'days').toDate(), endTime: moment().endOf('day').toDate() };
				}
				case '-7': {
					return { startTime: moment().startOf('day').subtract(7, 'days').toDate(), endTime: moment().endOf('day').toDate() };
				}
				case '0': {
					return { startTime: moment().startOf('day').toDate(), endTime: moment().endOf('day').toDate() };
				}
				case '1': {
					return { startTime: moment().endOf('day').toDate(), endTime: moment().endOf('day').add(24, 'hours').toDate() };
				}
				case '7': {
					return { startTime: moment().startOf('day').toDate(), endTime: moment().endOf('day').add(7, 'days').toDate() };
				}
				case '30': {
					return { startTime: moment().startOf('day').toDate(), endTime: moment().endOf('day').add(30, 'days').toDate() };
				}
				default: {
					return { startTime: undefined, endTime: undefined };
				}
			}
		} else {
			switch (timeRange) {
				case 'anytime': {
					return { startTime: undefined, endTime: undefined };
				}
				case '0': {
					return { startTime: moment().startOf('day').toDate(), endTime: moment().endOf('day').toDate() };
				}
				case '1': {
					return { startTime: moment().startOf('day').subtract(24, 'hours').toDate(), endTime: moment().endOf('day').toDate() };
				}
				case '7': {
					return { startTime: moment().startOf('day').subtract(7, 'days').toDate(), endTime: moment().endOf('day').toDate() };
				}
				case '30': {
					return { startTime: moment().startOf('day').subtract(30, 'days').toDate(), endTime: moment().endOf('day').toDate() };
				}
				default: {
					return { startTime: undefined, endTime: undefined };
				}
			}
		}
	};

	handleTimeFilterChange = (event: any) => {
		if (this.state.tempFilterStore.statusFilter.selectedIndex === 0) {
			const dueFilter = this.props.filters.dueFilter;
			dueFilter.selectedIndex = event.target.value;
			dueFilter.value = dueFilter.values[event.target.value];
			this.setState(prevState => ({
				tempFilterStore: {
					...prevState.tempFilterStore,
					dueFilter,
				},
			}), () => {
				if (dueFilter.value !== 'custom') {
					this.setState(prevState => ({
						tempFilterStore: {
							...prevState.tempFilterStore,
							...this.getStartEndDates(this.state.tempFilterStore.statusFilter.value, dueFilter.value),
						},
					}));
				}
			});
		} else {
			const completedFilter = this.props.filters.completedFilter;
			completedFilter.selectedIndex = event.target.value;
			completedFilter.value = completedFilter.values[event.target.value];
			this.setState(prevState => ({
				tempFilterStore: {
					...prevState.tempFilterStore,
					completedFilter,
				},
			}), () => {
				if (completedFilter.value !== 'custom') {
					this.setState(prevState => ({
						tempFilterStore: {
							...prevState.tempFilterStore,
							...this.getStartEndDates(this.state.tempFilterStore.statusFilter.value, completedFilter.value),
						},
					}));
				}
			});
		}
	};

	handleCustomStartDateChange = (date: Date) => {
		this.setState(prevState => ({
			tempFilterStore: {
				...prevState.tempFilterStore,
				startTime: moment(date).startOf('day').toDate()
			}
		}));
	};

	handleCustomEndDateChange = (date: Date) => {
		this.setState(prevState => ({
			tempFilterStore: {
				...prevState.tempFilterStore,
				endTime: moment(date).endOf('day').toDate()
			}
		}));
	};

	handleSearchFilterChange = (event: any) => {
		this.setState({
			tempFilterStore: {
				...this.state.tempFilterStore,
				searchFilterValue: event.target.value,
			},
		});
	};

	handleHighPriorityOnlyFilterChange = (
		event: any,
		highPriorityFilterValue: boolean
	) => {
		this.setState({
			tempFilterStore: {
				...this.state.tempFilterStore,
				highPriorityFilterValue,
			},
		});
	};

	handleDialogOpen = () => {
		this.setState({ closeOldActivitiesDialogOpen: true });
	};

	handleDialogClose = () => {
		this.setState({ closeOldActivitiesDialogOpen: false });
	};

	handleCloseOldActivities = () => {
		this.props.closeOldActivities();
		this.handleDialogClose();
	}

	renderFilterSummary = (overrideMessage?: string) => {
		const subtitle = this.displayFilterValues();
		return (
			<ListFilter
				drawerTitle="Activities"
				onSearch={this.handleOnFilterSave}
				onReset={this.handleFilterReset}
				cardSubtitle={overrideMessage ? overrideMessage : subtitle}
				drawerSubtitle={subtitle}
			>
				{this.renderFilterForm()}
			</ListFilter>
		);
	};

	renderFilterForm = () => {
		let customDateSelected = false;
		if (this.state.tempFilterStore.statusFilter.value === Strings.ActivityStatus.Open && this.state.tempFilterStore.dueFilter.value === 'custom') {
			customDateSelected = true;
		} else if (this.state.tempFilterStore.statusFilter.value === Strings.ActivityStatus.Completed && this.state.tempFilterStore.completedFilter.value === 'custom') {
			customDateSelected = true;
		}

		let startDateCheck: string | undefined;
		if (!this.state.tempFilterStore.startTime)
			startDateCheck = 'Start Date must be a valid date';
		else if (moment(this.state.tempFilterStore.endTime).isBefore(this.state.tempFilterStore.startTime))
			startDateCheck = 'Start Date must be before End Date';

		let endDateCheck: string | undefined;
		if (!this.state.tempFilterStore.endTime)
			endDateCheck = 'End Date must be a valid date';

		return (
			<Grid container>
				<Grid item xs={12}>
					<TextField
						style={styles.filterStyle}
						label={'Search'}
						value={this.state.tempFilterStore.searchFilterValue}
						placeholder={'Search for To-Do'}
						onChange={this.handleSearchFilterChange}
						fullWidth
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						style={styles.filterStyle}
						select
						label="Type"
						value={this.state.tempFilterStore.typeFilter.selectedIndex}
						onChange={this.handleTypeFilterChange}
						fullWidth
					>
						{this.state.tempFilterStore.typeFilter.displayOptions.map(
							(option, index) => {
								return (
									<MenuItem key={index} value={index}>
										{option}
									</MenuItem>
								);
							}
						)}
					</TextField>
				</Grid>
				<Grid item xs={12}>
					<TextField
						style={styles.filterStyle}
						select
						label="Status"
						value={this.state.tempFilterStore.statusFilter.selectedIndex}
						onChange={this.handleStatusFilterChange}
						fullWidth
					>
						{this.state.tempFilterStore.statusFilter.displayOptions.map(
							(option, index) => {
								return (
									<MenuItem key={index} value={index}>
										{option}
									</MenuItem>
								);
							}
						)}
					</TextField>
				</Grid>
				{this.state.tempFilterStore.statusFilter.selectedIndex === 0 ? (
					<Grid item xs={12}>
						<TextField
							style={styles.filterStyle}
							select
							label="Time"
							value={this.state.tempFilterStore.dueFilter.selectedIndex}
							onChange={this.handleTimeFilterChange}
							fullWidth
						>
							{this.state.tempFilterStore.dueFilter.displayOptions.map(
								(option, index) => {
									return (
										<MenuItem key={index} value={index}>
											{option}
										</MenuItem>
									);
								}
							)}
						</TextField>
					</Grid>
				) : (
						<Grid item xs={12}>
							<TextField
								style={styles.filterStyle}
								select
								label="Time"
								value={this.state.tempFilterStore.completedFilter.selectedIndex}
								onChange={this.handleTimeFilterChange}
								fullWidth
							>
								{this.state.tempFilterStore.completedFilter.displayOptions.map(
									(option, index) => {
										return (
											<MenuItem key={index} value={index}>
												{option}
											</MenuItem>
										);
									}
								)}
							</TextField>
						</Grid>
					)}
				{customDateSelected &&
					<>
						<Grid item xs={6}>
							<DatePicker
								label='Start Date'
								meta={{ error: startDateCheck }}
								input={{
									value: this.state.tempFilterStore.startTime,
									onChange: this.handleCustomStartDateChange
								}}
								style={styles.filterSplitStyle}
							/>
						</Grid>
						<Grid item xs={6}>
							<DatePicker
								label='End Date'
								meta={{ error: endDateCheck }}
								input={{
									value: this.state.tempFilterStore.endTime,
									onChange: this.handleCustomEndDateChange
								}}
								style={styles.filterSplitStyle}
							/>
						</Grid>
					</>
				}
				<Grid item xs={12}>
					<FormGroup>
						<FormControlLabel
							control={
								<Switch
									checked={this.state.tempFilterStore.highPriorityFilterValue}
									onChange={this.handleHighPriorityOnlyFilterChange}
								/>
							}
							label="High priority only"
						/>
					</FormGroup>
				</Grid>
			</Grid>
		);
	};

	filterOnEmployer = (activity: Loaded<Activity>): boolean => {
		const searchString = this.props.filters.searchFilterValue
			.toLowerCase()
			.replace(/\s/g, '');

		return !activity.data.contact.employerid || this.props.employers.some(employer => {
			const employerSearchArray = employer.contacts
				.map(contact => {
					return [
						contact.firstName,
						contact.preferredName,
						contact.lastName,
					].join(' ');
				})
				.concat(employer.companyName);
			const employerSearchString = employerSearchArray
				.join(' ')
				.toLowerCase()
				.replace(/\s/g, '');
			return (
				activity.employerId == employer.id &&
				employerSearchString.indexOf(searchString) > 0
			);
		});
	};

	setFilteredActivities = (activities: Loaded<Activity>[]) => {
		const filteredActivities = activities.filter(activity => {
			const statusFilter =
				activity.data.status === this.props.filters.statusFilter.value;
			const typeFilter =
				this.props.filters.typeFilter.value == 'all'
					? true
					: activity.data.type === this.props.filters.typeFilter.value;
			const priorityFilter = this.props.filters.highPriorityFilterValue
				? activity.data.isHighPriority
				: true;

			const textFilter = this.props.filters.searchFilterValue
				? (activity.data.title || '')
					.toLowerCase()
					.includes(this.props.filters.searchFilterValue.toLowerCase()) ||
				(activity.data.description || '')
					.toLowerCase()
					.includes(this.props.filters.searchFilterValue.toLowerCase()) ||
				(activity.data.createdByName || '')
					.toLowerCase()
					.includes(this.props.filters.searchFilterValue.toLowerCase()) ||
				(activity.data.modifiedByName || '')
					.toLowerCase()
					.includes(this.props.filters.searchFilterValue.toLowerCase()) ||
				(activity.data.completedByName || '')
					.toLowerCase()
					.includes(this.props.filters.searchFilterValue.toLowerCase()) ||
				this.filterOnEmployer(activity)
				: true;
			const timeFilter = this.filterActivityByDate(activity.data);
			const isDeleted = activity.data.isDeleted;
			return (
				statusFilter &&
				typeFilter &&
				priorityFilter &&
				textFilter &&
				timeFilter &&
				!isDeleted
			);
		});
		this.setState({ filteredActivities });
	};

	renderActivities = () => {
		let activityListItems: JSX.Element[] = [];
		if (this.props.filters.statusFilter.value == Strings.ActivityStatus.Open) {
			if (['past', '-30', '-7'].indexOf(this.props.filters.dueFilter.value) !== -1)
				activityListItems = this.renderOldActivities();
			else
				activityListItems = activityListItems.concat(
					this.renderOldActivities(),
					this.renderTodaysActivities(),
					this.renderTomorrowsActivities(),
					this.renderFutureActivities()
				);
		} else if (this.props.filters.statusFilter.value == Strings.ActivityStatus.Completed)
			activityListItems = activityListItems.concat(
				this.renderTodaysActivities(),
				this.renderOldActivities()
			);

		if (activityListItems.length == 0 && !this.props.isLoading)
			return (
				<EmptyMessage text="There are no matches for your search..." />
			);
		else
			return (
				<Paper elevation={0} square>
					<List style={{ padding: 0 }}>{activityListItems}</List>
				</Paper>
			);
	};

	filterActivityByDate = (activity: Activity) => {
		let check = true;
		let activityTime: moment.Moment;

		if (this.props.filters.statusFilter.value === Strings.ActivityStatus.Open) {
			activityTime = moment.utc(activity.time).local();
		} else {
			activityTime = moment.utc(activity.completedOn).local();
		}

		if (this.props.filters.startTime) {
			check = check && activityTime.isSameOrAfter(this.props.filters.startTime);
		}
		if (this.props.filters.endTime) {
			check = check && activityTime.isSameOrBefore(this.props.filters.endTime);
		}

		return check;
	};

	renderOldActivities = () => {
		const isCompleted =
			this.props.filters.statusFilter.value ==
			Strings.ActivityStatus.Completed;
		const pastDueActivities = this.state.filteredActivities
			.filter(activity => {
				const timeProp =
					isCompleted && activity.data.completedOn
						? activity.data.completedOn
						: activity.data.time;
				const activityInLocalTime = moment.utc(timeProp).local();
				const beforeToday = activityInLocalTime.isSameOrBefore(
					moment().startOf('day')
				);
				return beforeToday;
			})
			.map(this.renderActivityItem);
		const sectionHeader: JSX.Element[] = [
			<ListSubheader
				style={{ backgroundColor: themePalette.default_background }}
				key="header_old"
			>
				{this.props.filters.statusFilter.value == Strings.ActivityStatus.Open
					? 'Past Due'
					: 'In The Past'}
			</ListSubheader>,
		];
		if (pastDueActivities.length > 0) {
			return sectionHeader.concat(pastDueActivities);
		} else return [];
	};

	renderTodaysActivities = () => {
		const isCompleted =
			this.props.filters.statusFilter.value ==
			Strings.ActivityStatus.Completed;
		const todaysActivities = this.state.filteredActivities
			.filter(activity => {
				const timeProp =
					isCompleted && activity.data.completedOn
						? activity.data.completedOn
						: activity.data.time;

				const activityInLocalTime = moment.utc(timeProp).local();
				const afterYesterday = activityInLocalTime > moment().startOf('day');
				const beforeTomorrow = activityInLocalTime < moment().endOf('day');
				return afterYesterday && beforeTomorrow;
			})
			.map(this.renderActivityItem);
		const sectionHeader: JSX.Element[] = [
			<ListSubheader
				style={{ backgroundColor: themePalette.negative_text }}
				key="header_today"
			>
				Today
			</ListSubheader>,
		];
		if (todaysActivities.length > 0) {
			todaysActivities.forEach((activity, index) => {
				sectionHeader.push(activity);
			});
			return sectionHeader;
		} else return [];
	};

	renderTomorrowsActivities = () => {
		const tomorrowsActivities = this.state.filteredActivities
			.filter(activity => {
				const activityInLocalTime = moment.utc(activity.data.time).local();
				const afterToday = activityInLocalTime > moment().endOf('day');
				const beforeTheEndOfNextDay =
					activityInLocalTime <
					moment()
						.endOf('day')
						.add(1, 'day');
				return afterToday && beforeTheEndOfNextDay;
			})
			.map(this.renderActivityItem);
		const sectionHeader: JSX.Element[] = [
			<ListSubheader
				style={{ backgroundColor: themePalette.negative_text }}
				key="header_tomorrow"
			>
				Tomorrow
			</ListSubheader>,
		];
		if (tomorrowsActivities.length > 0) {
			tomorrowsActivities.forEach((activity, index) => {
				sectionHeader.push(activity);
			});
			return sectionHeader;
		} else return [];
	};

	renderFutureActivities = () => {
		const futureActivities = this.state.filteredActivities
			.filter(activity => {
				const activityInLocalTime = moment.utc(activity.data.time).local();
				const afterTomorrow =
					activityInLocalTime >
					moment()
						.endOf('day')
						.add(1, 'day');
				return afterTomorrow;
			})
			.sort((a, b) => moment(a.data.time).unix() - moment(b.data.time).unix())
			.map(this.renderActivityItem);
		const sectionHeader: JSX.Element[] = [
			<ListSubheader
				style={{ backgroundColor: themePalette.negative_text }}
				key="header_future"
			>
				Future
			</ListSubheader>,
		];
		if (futureActivities.length > 0) {
			futureActivities.forEach((activity) => {
				sectionHeader.push(activity);
			});
			return sectionHeader;
		} else return [];
	};

	renderFabMenu() {
		const icons: any[] = [
			{
				label: Strings.Activity.PhoneCall,
				icon: 'local_phone',
				onClick: () => {
					nav.push(navRoutes.phoneCreate.path
						.replace(`/${S.Navigation.HouseholdId}?`, '')
						.replace(S.Navigation.EmployerId, '0')
						.replace(S.Navigation.FollowUp, '0')
					);
				},
				disabled: !jwt_auth.hasPermission(P.CreateActivity),
			},
			{
				label: Strings.Activity.Appointment,
				icon: 'date_range',
				onClick: () => {
					nav.push(
						navRoutes.appointmentCreate.path
							.replace(`/${S.Navigation.HouseholdId}?`, '')
							.replace(S.Navigation.EmployerId, '0')
					);
				},
				disabled: !jwt_auth.hasPermission(P.CreateActivity),
			},
			{
				label: Strings.Activity.Task,
				icon: 'event_available',
				onClick: () => {
					nav.push(
						navRoutes.activityCreate.path
							.replace(`/${S.Navigation.HouseholdId}?`, '')
							.replace(S.Navigation.EmployerId, '0')
					);
				},
				disabled: !jwt_auth.hasPermission(P.CreateActivity),
			},
		];

		return <FabMenu items={icons} />;
	}

	renderCallSequenceDialog = () => this.state.pickedActivity && this.state.pickedActivity.data && <CallSequence
		navigateTo={nav.push}
		householdId={this.state.pickedActivity.data.contact.householdId}
		key="PhoneCallAction"
		contacts={[this.state.pickedActivity.data.contact]}
	/>

	closeOldActivitiesDialogRender() {
		return (
			<Dialog open={this.state.closeOldActivitiesDialogOpen} onClose={this.handleDialogClose}>
				<DialogTitle>Close Old To-Dos</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to close <i>ALL</i> open To-Dos older than 60 days?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button color="secondary" onClick={this.handleDialogClose}>
						No
					</Button>
					<Button variant="contained" color="primary" onClick={this.handleCloseOldActivities}>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	render() {
		return (
			<BasePageContainer
				topComponent={
					<HeaderBarComponent
						title="To-Do List"
						rightButtons={
							<MoreMenu
								children={[
									createMenuAction('Close Old To-Dos', this.handleDialogOpen),
									createHelpAction(themeLinks.helpLinkCRM),
								]}
							/>
						}
						customContent={this.renderFilterSummary()}
						isLoading={this.props.isLoading}
					/>
				}
				bottomComponent={this.closeOldActivitiesDialogRender()}
				pageDialogs={[this.renderCallSequenceDialog()]}
			>
				<PersistentNotifications />
				<NotificationSummary categorySelector={notificationCategories.todoList} />
				<InfiniteScroller
					loadMore={this.loadMoreActivities}
					hasMore={this.props.hasMoreActivities}
					useWindow={false}
				>
					<DesktopPadding>
						{this.renderActivities()}
					</DesktopPadding>
				</InfiniteScroller>

				<ActivityDispositionDialog
					open={this.state.dispositionDialogIsOpen}
					isAppointment={
						this.state.pickedActivity &&
						this.state.pickedActivity.data &&
						this.state.pickedActivity.data.type ==
						Strings.Activity.Appointment
					}
					activities={[this.state.pickedActivity.data]}
					closeDispositionDialog={this.closeDispositionDialog}
					navigateTo={nav.push}
					contactId={
						this.state.passContact &&
							this.state.pickedActivity &&
							this.state.pickedActivity.data &&
							this.state.pickedActivity.data.contact
							? this.state.pickedActivity.data.contact.id
							: ''
					}
				/>
					
				{this.renderFabMenu()}
			</BasePageContainer>
		);
	}
}

const mapStateToProps = (state: AppState): StateProps => ({
	activities: state.activity.activities.filter(loadedActivity => loadedActivity.errors.length === 0),
	employers: state.employer.employers.map(
		loadedEmployer => loadedEmployer.data
	),
	isLoading: state.activity.isLoading,
	userId: state.user.id,
	firstName: state.agent.firstName,
	preferredName: state.agent.preferredName,
	lastName: state.agent.lastName,
	filters: state.activityFilters,
	pageNum: state.activity.pagedActivities.pageNum,
	hasMoreActivities: state.activity.pagedActivities.hasMore,
	hasError: state.activity.pagedActivities.error,
	desktopCallEnabled: state.user.enableDesktopCalls,
	desktopTextEnabled: state.user.enableDesktopTexts,
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps & Partial<NavigationProps> => ({
	navigateTo: (route: string) =>
		dispatch(navigateTo(route)),
	getPagedActivities: (options: FilterOptions, pageNum: number, clearCache = false) =>
		dispatch(
			GetPagedActivities.started({ filters: options, pageNum, pageSize: 20, clearCache })
		),
	editActivity: (activityToEdit: Activity) =>
		dispatch(EditActivity.started(activityToEdit)),
	queueSnackbar: (snackbarProps: SnackbarProps) =>
		dispatch(QueueSnackbar(snackbarProps)),
	searchEmployers: (searchText: string) =>
		dispatch(SearchEmployers.started(searchText)),
	setFilters: (filters: ActivityFilters) =>
		dispatch(StoreActivityFilters(filters)),
	closeOldActivities: () => dispatch(CloseOldActivities(undefined)),
	updateDispositionReturnPath: (returnPath: string) =>
		dispatch(updateDispositionReturnPath(returnPath)),
	storeScrollLocation: (scrollLocation: string) => dispatch(StoreScrollLocation(scrollLocation)),
});

export const ActivitiesPageContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
	true
)(ActivitiesPage);

const styles = {
	filterStyle: {
		backgroundColor: 'white',
	},
	filterSplitStyle: {
		backgroundColor: 'white',
	},
};
