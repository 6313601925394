import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { Application } from '../../reducers/application_reducer';
import { Contact } from '../../reducers/ContactReducer';
import { Policy } from '../../reducers/policy_reducer';
import { Loaded } from '../../utilities/utilities';
import { AdminInfoCard } from './AdminInfo';
import { GeneralInfoCard } from './GeneralInfo';
import { PolicyAmountsInfoCard } from './PolicyAmountsInfo';
import { PremiumInfoCard } from './PremiumInfo';
import { PrimaryInsuredInfoCard } from './PrimaryInsuredInfo';
import { themePalette } from '../../utilities/branding';
import { getYesNoFromBool } from '../../utilities/formatting/data_normalizations';
import { getFormattedDate } from '../../utilities/date_util';

interface Props {
	application: Application;
	currentPolicy?: Loaded<Policy>;
	unlinkApplication: (policy: Policy) => void;
}
interface State {
	modalVisible: boolean;
}
export class ApplicationDetail extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			modalVisible: false,
		};
	}

	closeModal = () => this.setState({ modalVisible: false });

	unlinkApplication = () => {
		this.closeModal();
		this.props.currentPolicy
			? this.props.unlinkApplication(this.props.currentPolicy.data)
			: {};
	};

	renderUnlinkConfirmation = () => {
		return (
			<Dialog
				open={this.state.modalVisible}
				onClose={this.closeModal}
			>
				<DialogTitle>Confirmation</DialogTitle>
				<DialogContent>
					Are you sure you want to unlink this application?
				</DialogContent>
				<DialogActions>
					<Button
						color="secondary"
						onClick={this.closeModal}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						style={{ backgroundColor: themePalette.accept_button }}
						onClick={this.unlinkApplication}
					>
						Unlink
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	render() {
		const application: Application = this.props.application
			? this.props.application
			: ({} as Application);

		const lineOfBusiness = application.lineOfBusiness || '';
		const carrier = application.carrier || '';
		const carrierFriendlyName = application.carrierFriendlyName || '';
		const productType = application.productType || '';
		const productName = application.productName || '';
		const metalLevel = application.metalLevel || '';
		const appDate = getFormattedDate(application.appDate, 'MM/DD/YY', false);

		const effectiveDate = getFormattedDate(application.effectiveDate, 'MM/DD/YY', false);
		const applicationStatus = application.applicationStatus || 0;
		const statusReason = application.statusReason || '';
		const policyEndDate = getFormattedDate(application.policyEndDate, 'MM/DD/YY', false);
		const outstandingDocuments = getYesNoFromBool(application.isOutstandingDocumentsNeeded);
		const outstandingDocumentsDueDate = getFormattedDate(application.outstandingDocumentsDueDate, 'MM/DD/YY', false);

		const annualPremium = application.annualPremium;
		const annualFederalSubsidy = application.annualFederalSubsidy;
		const annualNetCost = application.annualNetCost;
		const monthlyPremium = application.monthlyPremium;
		const monthlyFederalSubsidy = application.monthlyFederalSubsidy;
		const monthlyNetCost = application.monthlyNetCost;
		const costShare = application.costShare || '';

		const individualDeductible = application.individualDeductible;
		const perPersonDeductible = application.perPersonDeductible;
		const familyDeductible = application.familyDeductible;
		const individualMaxOutOfPocket = application.individualMaxOutOfPocket;
		const ppMaxOutOfPocket = application.ppMaxOutOfPocket;
		const familyMaxOutOfPocket = application.familyMaxOutOfPocket;
		const faceAmount =
			application.faceAmount ||
			(this.props.currentPolicy ? this.props.currentPolicy.data.faceAmount : undefined);

		const drugDeductible = application.drugDeductible;
		const annualDrugCost = application.annualDrugCost;
		const benefitAmount = application.benefitAmount;

		const applicationNumber = application.applicationNumber || '';
		const quoteId = application.quoteId || '';
		const ffmAppId = application.ffmAppId || '';
		const carrierProducerCode = application.carrierProducerCode || '';
		const sold = application.sold || '';
		const enrollmentMethod = application.enrollmentMethod || '';
		const createdByAgentName = application.createdByAgentName || '';
		const createdOn = getFormattedDate(application.createdOn, 'MM/DD/YY, hh:mm A', true)
		const updatedByAgentName = application.updatedByAgentName || '';
		const updatedOn = getFormattedDate(application.updatedOn, 'MM/DD/YY, hh:mm A', true)
		const acaApplyId = application.acaApplyId;
		const enrollmentPeriodCode = application.enrollmentPeriodCode || '';
		const sepCode = application.sepCode || '';

		const primaryInsured = application.primaryInsured || '';
		const secondaryInsured = application.secondaryInsured || '';
		let dependents = application.dependents || [];
		if (!dependents) {
			dependents = [];
		}
		let contactList: Contact[] = [];
		if (primaryInsured) {
			contactList.push(primaryInsured);
		}
		if (secondaryInsured) {
			contactList.push(secondaryInsured);
		}
		if (dependents.length > 0) {
			contactList = contactList.concat(dependents);
		}

		return (
			<Grid item>
				<GeneralInfoCard
					title={'General'}
					lineOfBusiness={lineOfBusiness}
					carrier={carrier}
					carrierFriendlyName={carrierFriendlyName}
					productType={productType}
					productName={productName}
					metalLevel={metalLevel}
					appDate={appDate}
					effectiveDate={effectiveDate}
					applicationStatus={applicationStatus}
					statusReason={statusReason}
					policyEndDate={policyEndDate}
					outstandingDocuments={outstandingDocuments}
					outstandingDocumentsDueDate={outstandingDocumentsDueDate}
				/>
				<PrimaryInsuredInfoCard
					title={'Insured'}
					contacts={contactList}
				/>
				<PremiumInfoCard
					title={'Premium Info'}
					annualPremium={annualPremium}
					annualFederalSubsidy={annualFederalSubsidy}
					annualNetCost={annualNetCost}
					monthlyPremium={monthlyPremium}
					monthlyFederalSubsidy={monthlyFederalSubsidy}
					monthlyNetCost={monthlyNetCost}
					costShare={costShare}
				/>
				<PolicyAmountsInfoCard
					title={'Policy Amounts'}
					individualDeductible={individualDeductible}
					perPersonDeductible={perPersonDeductible}
					familyDeductible={familyDeductible}
					individualMaxOutOfPocket={individualMaxOutOfPocket}
					ppMaxOutOfPocket={ppMaxOutOfPocket}
					familyMaxOutOfPocket={familyMaxOutOfPocket}
					faceAmount={faceAmount}
					benefitAmount={benefitAmount}
					annualDrugCost={annualDrugCost}
					drugDeductible={drugDeductible}
				/>
				<AdminInfoCard
					title={'Admin'}
					applicationNumber={applicationNumber}
					quoteId={quoteId}
					ffmAppId={ffmAppId}
					carrierProducerCode={carrierProducerCode}
					sold={sold}
					enrollmentMethod={enrollmentMethod}
					createdOn={createdOn}
					updatedOn={updatedOn}
					createdByAgentName={createdByAgentName}
					updatedByAgentName={updatedByAgentName}
					acaApplyId={acaApplyId}
					enrollmentPeriodCode={enrollmentPeriodCode}
					sepCode={sepCode}
				/>
				{this.props.currentPolicy && (
					<Grid container>
						<Button
							variant="contained"
							color="primary"
							fullWidth
							style={{ margin: 10 }}
							onClick={() => {
								this.setState({
									modalVisible: true,
								});
							}}
						>
							Unlink Application
						</Button>
					</Grid>
				)}
				{this.renderUnlinkConfirmation()}
			</Grid>
		);
	}
}
