import { connect } from '@optum-uhone-hmkts/rise';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { Dispatch } from '@reduxjs/toolkit';
import React from 'react';
import { nav } from '../..';
import { setDrawerStatus } from '../../actions/access_page_actions';
import { openClientConnectConfigureDialog } from '../../actions/client_connect_actions';
import { navRoutes } from '../../components/nav/Routes';
import { AppState } from '../../reducers';

interface StateProps {
    dialogOpen: boolean;
    isDesktop: boolean;
}
interface DispatchProps {
    closeDialog: () => void;
    setDrawerStatus: (visible: boolean) => void;
}
type Props = StateProps & DispatchProps;

const ConfigureClientConnect: React.FC<Props> = (props) => {

    const { dialogOpen, isDesktop, closeDialog, setDrawerStatus } = props;
    return (
        <Dialog open={dialogOpen}>
            <DialogContent>
                <DialogContentText>
                    Please configure your ClientConnect in your About Me section.
                    </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>
                    Later
                </Button>
                <Button
                    onClick={() => {
                        closeDialog();
                        setDrawerStatus(isDesktop);
                        nav.push(navRoutes.aboutMe.path);
                    }}
                >
                    Go to About Me
                    </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state: AppState): StateProps => ({
    dialogOpen: state.clientConnect.configureDialogOpen,
    isDesktop: state.layout.desktop,
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    closeDialog: () => dispatch(openClientConnectConfigureDialog.done({ result: false })),
    setDrawerStatus: (visible: boolean) => dispatch(setDrawerStatus(visible)),
});

export const ConfigureClientConnectDialog = connect(
    mapStateToProps,
    mapDispatchToProps,
    true
)(ConfigureClientConnect);