import React from 'react';
import { Grid } from '@material-ui/core';
import { AgentLicense } from '../../reducers/agent_reducer';
import { ReadOnlyTextField } from '../utility/read_only_text_field';

interface Props {
    license: AgentLicense;
}

export const Lisence: React.FC<Props> = ({ license }) => {
    return (
        <Grid container>
            <Grid item xs={6}>
                <ReadOnlyTextField label={'State'} value={license.LicenseState} />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyTextField
                    label={'License #'}
                    value={license.LicenseNumber}
                />
            </Grid>
        </Grid>
    );
}