import {actionCreatorFactory} from 'typescript-fsa';
import { Action as ReduxAction } from 'redux';
import { Notification } from '../containers/notifications_page/notifications_page';
import { NotificationInput } from '../reducers/notification_reducer';
const actionCreator = actionCreatorFactory('Notification');
export const UpdateCreateFormTags = actionCreator<{ tags: string[] }>(
	'UPDATE_CREATE_FORM_TAGS'
);
export function updateCreateFormTags(tags: string[]): ReduxAction {
	return UpdateCreateFormTags({ tags });
}

export const GetAllNotifications = actionCreator.async<string, undefined>(
	'GET_ALL_NOTIFICATIONS'
);

export const GetLoginNotification = actionCreator.async<{}, Notification[]>(
	'GET_LOGIN_NOTIFICATION'
);

export const GetPersistentNotification = actionCreator.async<string, Notification[]>(
	'GET_PERSISTENT_NOTIFICATION'
);

export const GetNewNotification = actionCreator.async<string, Notification[]>(
	'GET_NEW_NOTIFICATION'
);

export interface MarkNotificationsInput {
	notificationIds: string[],
	userId: string	
}
export const MarkNotificationsAsRead = actionCreator.async<
	MarkNotificationsInput,
	{}>(
		'MARK_NOTIFICATIONS_AS_READ'
	);


export const UpdateEditFormTags = actionCreator<{ tags: string[] }>(
	'UPDATE_EDIT_FORM_TAGS'
);
export function updateEditFormTags(tags: string[]): ReduxAction {
	return UpdateEditFormTags({ tags });
}

export const CreateNotification = actionCreator.async<NotificationInput, {}>(
	'CREATE_NOTIFICATIONS'
);

export const DeleteNotification = actionCreator.async<string, {}>(
	'DELETE_NOTIFICATIONS'
);

export const GetNotification = actionCreator.async<string, Notification>(
	'GET_NOTIFICATION'
);

export type PagingOptions = {
	pageNum: number,
	pageSize: number,
}
export const GetNotificationList = actionCreator.async<PagingOptions, Notification[]>(
	'GET_NOTIFICATION_LIST'
);

export const EditNotification = actionCreator.async<Notification, {}>(
	'EDIT_NOTIFICATION'
);

export const GetNotificationTargetCount = actionCreator.async<string, number>(
	'GET_NOTIFICATION_TARGET_COUNT'
);
