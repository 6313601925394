import { actionCreatorFactory } from 'typescript-fsa';
import H from 'history';
import { FullHousehold } from '../sagas/household_sagas';
import { Contact } from '../reducers/ContactReducer';

const actionCreator = actionCreatorFactory('Household');

// FETCH HOUSEHOLD
export const GetHousehold = actionCreator.async<string, FullHousehold>(
	'GET_HOUSEHOLD'
);

// FETCH HOUSEHOLD CONTACTS
export const GetHouseholdContacts = actionCreator.async<string, Contact[]>(
	'GET_HOUSEHOLD_CONTACTS'
);


export class MergeHouseholdRequest {
	primaryHouseholdId: string;
	secondaryHouseholdId: string;
}
export const MergeHouseholds = actionCreator.async<MergeHouseholdRequest, {}>(
	'MERGE_HOUSEHOLDS'
);

// SET NEW PRIMARY CONTACT
export interface ContactOnHouseholdParams {
	householdId: string;
	contactId: string
}
export const SetNewPrimaryContactOnHousehold = actionCreator.async<
	ContactOnHouseholdParams,
	FullHousehold
>('SET_NEW_PRIMARY_CONTACT');

// REMOVE CONTACT FROM HOUSEHOLD
export const RemoveContactFromHousehold = actionCreator.async<
	ContactOnHouseholdParams,
	FullHousehold
>('REMOVE_CONTACT_FROM_HOUSEHOLD');

// REASSIGN HOUSEHOLD
export interface ReassignHouseholdParams {
	householdId: string;
	agentCode: string;
	agentName: string;
	history: H.History;
}
export const ReassignHousehold = actionCreator.async<
	ReassignHouseholdParams,
	boolean
>('REASSIGN_HOUSEHOLD');

export interface HouseholdTagPayload {
	householdId: string;
	tags: string[];
}
export const saveHouseholdTags = actionCreator.async<HouseholdTagPayload, void>('SAVE_HOUSEHOLD_TAGS');
export const getHouseholdTags = actionCreator.async<string, string[]>('GET_HOUSEHOLD_TAGS');
