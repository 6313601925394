import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Strings } from '../../assets/common/strings';
import { TeamViewParams, GetWritingAgentStats } from '../../actions/teamview_actions';
import ChartTripleStackedBar from '../utility/chart_triple_stacked_bar';
import { ProductivityTableCard } from '../teamview/productivity_table_card';
import { ReportCard } from '../../components/utility/report_card';

export const WritingAgentReport: React.FC<{params: TeamViewParams}> = props => {
    const dispatch = useDispatch();

    const reportData =  useSelector((state: any) => state.App.teamview.writingAgentCard.report, shallowEqual);
    const isLoading = useSelector((state: any) => state.App.teamview.writingAgentCard.isLoading, shallowEqual);
    const isDesktop: boolean = useSelector((state: any) => state.App.layout.desktop, shallowEqual);

    useEffect(() => {
        dispatch(GetWritingAgentStats.started(props.params))
    }, [props.params]);

    const chartSettings = {
        legend: {
            align: 'center',
            verticalAlign: 'bottom',
            floating: true,
            backgroundColor: '#adbabd',
            reversed: true,
            shadow: false,
            y: isDesktop ? 13 : 17,
            padding: isDesktop ? 8 : 2,
        },
    }

    return (
        <>
            {isLoading
            ? (
                <ReportCard
                    color="primary"
                    titleLeft={Strings.TeamView.WRITING_AGENTS}
                    isLoading
                />)
            : reportData.graph === null
                ? (
                    <ReportCard
                        color="primary"
                        titleLeft={Strings.TeamView.WRITING_AGENTS}
                        noData
                    />)
                : (
                    <>
                        <ProductivityTableCard
                            rows={reportData.table}
                            color="primary"
                            titleLeft={Strings.TeamView.WRITING_AGENTS}
                            rowsToHighlight={[0, 1]}
                        >
                            <ChartTripleStackedBar
                                data={reportData.graph.data}
                                chartSettings={chartSettings}
                            />
                        </ProductivityTableCard>
                    </>)}
        </>
    );
};
