import { actionCreatorFactory } from 'typescript-fsa';
import { Action as ReduxAction } from 'redux';
import { AgentState, Agent, AgentHierarchyDto, AgencyChannel } from '../reducers/agent_reducer';
import { asyncCacheable } from '../utilities/loader_util';
import { Address } from '../reducers/ContactReducer';

export interface UploadIconPayload {
	agentId: string;
	iconImage: string;
	auth: string;
}
export interface IconImagesPayload {
	iconImages: Array<string>;
}

export interface AgentAppointmentWebsite {
	userId: string;
	appointmentWebsite: string;
}

export interface AgentTimeZone {
	userId: string;
	timeZone: string;
}

const actionCreator = actionCreatorFactory('Agent');

export const UpdateAgentState = actionCreator<AgentState>('Update_Agent_State');
export function updateAgentState(agentState: AgentState): ReduxAction {
	return UpdateAgentState(agentState);
}

// FETCH ACTIVITY
export const GetAgentFromJwt = actionCreator.async<undefined, Agent>(
	'GET_AGENT_FROM_JWT'
);

// FETCH AGENT FOR HOUSEHOLD/EMPLOYER LEAD
export const GetAgentWithAgentId = actionCreator.async<string, Agent>(
	'GET_AGENT_WITH_AGENTCODE'
);

export const UploadAgentIcon = actionCreator<UploadIconPayload>(
	'UPLOAD_AGENT_ICON'
);
export function uploadAgentIcon(
	agentId: string,
	iconImage: string,
	auth: string
): ReduxAction {
	return UploadAgentIcon({ agentId, iconImage, auth });
}

export const UpdateIconImages = actionCreator<IconImagesPayload>(
	'UPDATE_ICON_IMAGES'
);
export function updateIconImages(iconImages: Array<string>) {
	return UpdateIconImages({ iconImages });
}

export const UpsertAppointmentWebsite = actionCreator.async<
	AgentAppointmentWebsite,
	string
>('UPSERT_APPOINTMENT_WEBSITE');

export const UpsertTimeZone = actionCreator.async<
	AgentTimeZone,
	string
>('UPSERT_TIME_ZONE');

export const UpsertStorefrontAddress = actionCreator.async<
	Address[],
	Address[]
>('UPSERT_STOREFRONT_ADDRESS');

export const GetAgentHierarchy = asyncCacheable<string, AgentHierarchyDto>(actionCreator,
	'GET_AGENT_HIERARCHY'
);

export const GetAgencyChannels = actionCreator.async<undefined, AgencyChannel[]>('GET_AGENCY_CHANNELS');

export const GetSubagentsByAgentCodeNotCached = actionCreator.async<string, Agent[]>('GET_AGENT_SUBAGENTS_BY_AGENTCODE_NOT_CACHED');

export interface AgentEmailTitlePayload {
	agentId: string;
	title: string;
	scope: AgentScope;
};
export enum AgentScope {
	Downline = 'downline',
	Upline = 'upline',
	Subagents = 'subagents',
	Me = 'me'
};

export interface ConnectureSAMLResponseParams {
	userId: string;
	year: string;
	params: ConnectureSSORedirectParams;
}

export interface ConnectureSSORedirectParams {
	ssovalue?: string;	
}

export const ConnectureSSOredirect = actionCreator.async<ConnectureSAMLResponseParams, string>('CONNECTURE_SSO_REDIRECT');

export const UpdateAgentEmailTitle = actionCreator.async<AgentEmailTitlePayload, { result: boolean; scope: AgentScope; }>('UPDATE_AGENT_EMAIL_TITLE');
export interface AgentNeedsProposalApprovalPayload {
	agentId: string;
	value: boolean;
	scope: AgentScope;
}
export const UpdateAgentNeedsProposalApproval = actionCreator.async<AgentNeedsProposalApprovalPayload, { result: boolean; scope: AgentScope; }>('UPDATE_AGENT_NEEDS_PROPOSAL_APPROVAL');