import { Contact } from '../../../reducers/ContactReducer';

import { connect } from '@optum-uhone-hmkts/rise';
import React from 'react';
import { ActivitySelectionDialog } from '../../../components/activity/activity_selection_dialog';
import { ActivityDispositionDialog } from '../../../components/activity/disposition_dialog';
import { Activity } from '../../../reducers/activity_reducer';
import {
	makeCall,
	composePhoneMetaData,
	getPhoneEmailIconStyle,
	IconOptions,
} from '../../../utilities/phone_email_util';
import { findContactActivities } from '../../../utilities/activity_util';
import { Strings } from '../../../assets/common/strings';
import { SelectContactDialog } from '../select_contact_dialog';
import { CallSequenceStep } from '../../../reducers/sequences/call_sequence_reducer';
import { CancelCallSequence, SelectActivitiesForCallSequence, SelectContactForCallSequence, StartCallSequenceAndLoadActivities } from '../../../actions/sequence/call_sequence_actions';
import { Icon, IconButton } from '@material-ui/core';
import { themePalette } from '../../../utilities/branding';
import { Dispatch } from 'redux';
import { StartClickToCallPhoneCall, C2CSelectedContact } from '../../../actions/click_to_call_actions';
import { fullNameWithMiddleInitial } from '../../../assets/common/string_builders';
import { ProductStatus } from '../../../reducers/policy_reducer';
import { getBaseIconStyle } from '../../../utilities/styles_util';

interface StateProps {
	step: CallSequenceStep;
	contactActivities: Activity[];
	contactId?: string;
	fallbackContacts: Contact[];
	contactPhoneNumber?: string;
	desktopCallEnabled: boolean;
	clickToCallEnabled: boolean;
	inClickToCall: boolean;
	hasActiveBusinessRelationship?: boolean;
}

interface DispatchProps {
	closeDialogs: () => void;
	selectActivities: (activities: Activity[]) => void;
	selectContact: (contactId: string, contactPhoneNumber: string) => void;
	startC2CPhoneCall: (contact: C2CSelectedContact) => void;
}

interface InputProps {
	contacts: Contact[];
	householdId: string;
	employerId?: string;
	iconOptions?: IconOptions;
	displayIcon?: boolean;
	navigateTo: (route: string) => void;
}

interface State {
	selectedContacts: { contactId: string, phoneNumber: string }[];
	selectedPhoneNumber: string;
	selectedContactId: string;
	selectedActivities: Activity[];
}

type Props = InputProps & StateProps & DispatchProps;

class _CallSequence extends React.Component<Props, State> {
	state = {
		selectedPhoneNumber: "",
		selectedContactId: "",
		selectedContacts: [],
		selectedActivities: [],
	};

	componentDidMount() {
	}

	componentWillReceiveProps(nextProps: Props) {
		if(nextProps.step == CallSequenceStep.LoadActivities) {
			this.setState({selectedActivities: []})
		}
	}

	handleSelectActivities = (selectedActivities: Activity[]) =>
		this.setState({ selectedActivities },
			() => this.props.selectActivities(selectedActivities));

	handleSelectContact = (selection: { id: string, value: string }[]) => {
		const { clickToCallEnabled, contacts } = this.props;
		
		if (clickToCallEnabled) {
			const contact = contacts.find(c => c.id == selection[0].id);
			if (contact) {
				const phone = contact.phones.find(phone => phone.number == selection[0].value);
				if (phone) {
					const selected: C2CSelectedContact = {
						name: fullNameWithMiddleInitial(contact),
						number: phone.number,
						contactId: contact.id,
						phoneId: phone.id,
					};
					this.props.startC2CPhoneCall(selected);
				}
			}
		} else {
			const contact = selection.length ? selection[0] : { id: '', value: '' };
			this.props.selectContact(contact.id, contact.value);
			makeCall(contact.value, this.props.desktopCallEnabled);
		}
	};

	renderDialog() {
		const {
			closeDialogs,
			step,
			contacts: inputContacts,
			fallbackContacts,
			hasActiveBusinessRelationship
		} = this.props;

		const contacts = inputContacts.length ? inputContacts : fallbackContacts;

		switch (step) {

			case (CallSequenceStep.SelectActivities):
			case (CallSequenceStep.LoadActivities):
				return <ActivitySelectionDialog
					key={'call-activity-selection-dialog'}
					activities={this.props.contactActivities}
					handleCancel={closeDialogs}
					handleSelectActivities={this.handleSelectActivities}
					isLoading={step == CallSequenceStep.LoadActivities}
					open
				/>

			case (CallSequenceStep.SelectContact):
				return <SelectContactDialog
					key={'call-contact-selection-dialog'}
					activityType={Strings.Activity.PhoneCall}
					handleClose={closeDialogs}
					handleSelection={this.handleSelectContact}
					contacts={contacts}
					hasActiveBusinessRelationship={hasActiveBusinessRelationship}
					open
					autoselect
				/>

			case (CallSequenceStep.DispositionActivity):
				return <ActivityDispositionDialog
					key={'call-disposition-dialog'}
					isAppointment={false}
					closeDispositionDialog={closeDialogs}
					navigateTo={this.props.navigateTo}
					contactId={this.props.contactId}
					activities={this.state.selectedActivities}
					open
				/>

			default: return <div />

		}
	}

	render() {
		const {
			displayIcon,
			contacts,
			iconOptions,
			householdId,
			employerId,
			inClickToCall,
			hasActiveBusinessRelationship,
		} = this.props;

		return <>
			{this.renderDialog()}
			{displayIcon
				&& <CallSequenceIcon
					contacts={contacts}
					householdId={householdId}
					employerId={employerId}
					iconOptions={iconOptions}
					disabled={inClickToCall}
					hasActiveBusinessRelationship={hasActiveBusinessRelationship}
				/>
			}
		</>

	}
}


function mapStateToProps(state, ownProps: Props): StateProps {
	
	const { callSequence: sequence } = state;

	const activities = state.activity.activities.map(
		loadedActivity => loadedActivity.data
	);

	const contactActivities = findContactActivities(
		activities,
		ownProps.contacts
	).filter(
		activity =>
			activity.type == Strings.Activity.PhoneCall &&
			activity.status == Strings.ActivityStatus.Open
	);

	const contactId = sequence.contactId;
	const contact = state.contact.contacts.find(c => c.data.id == contactId);

	const fallbackContacts = state.contact.contacts.filter(c =>
		c.householdId == ownProps.householdId
		|| (!!ownProps.employerId
			&& c.employerId == ownProps.employerId))
		.map(c => c.data);

	const hasPolicies = state.policy.policies.some(p =>
        (p.data.manualPolicyStatus !== ProductStatus.Terminated ||
        	(p.data.manualPolicyStatus == null && p.data.policyStatus !== ProductStatus.Terminated)) &&
		(p.data.primaryInsured !== undefined  && p.data.primaryInsured !== null) && (
			(contact != null && p.data.primaryInsured.id === contactId) ||
			(!!ownProps.householdId && p.data.primaryInsured.householdId === ownProps.householdId) ||
			(!!ownProps.employerId && p.data.primaryInsured.employerId === ownProps.employerId)
		)
	);

	const hasApplications = state.application.applications.some(p =>
		p.data.applicationStatus !== ProductStatus.Terminated &&
		p.data.primaryInsured && (
			(contact != null && p.data.primaryInsured.id === contactId) ||
			(!!ownProps.householdId && p.data.primaryInsured.householdId === ownProps.householdId) ||
			(!!ownProps.employerId && p.data.primaryInsured.employerId === ownProps.employerId)
		)
	);

	return {
		contactId,
		step: sequence.step,
		contactActivities,
		desktopCallEnabled: state.user.enableDesktopCalls,
		fallbackContacts,
		clickToCallEnabled: state.clickToCall.enabled,
		inClickToCall: state.clickToCall.metadata.inCall,
		hasActiveBusinessRelationship: hasPolicies || hasApplications
	};
}

function mapDispatchToProps(dispatch: any, ownProps: Props): DispatchProps {
	return {
		closeDialogs: () => dispatch(CancelCallSequence()),
		selectActivities: (activities: Activity[]) =>
			dispatch(SelectActivitiesForCallSequence(activities)),
		selectContact: (contactId: string, contactPhoneNumber: string) => dispatch(SelectContactForCallSequence({ contactId, contactPhoneNumber })),
		
		startC2CPhoneCall: (contact: C2CSelectedContact) => dispatch(StartClickToCallPhoneCall(contact)),
	};
}

export const CallSequence = connect(mapStateToProps, mapDispatchToProps, true)(
	_CallSequence
) as React.ComponentClass<InputProps>;

export const moreInfoStyle: React.CSSProperties = {
	height: '25px',
	width: '25px',
	margin: 'auto',
	display: 'block',
	color: themePalette.tertiary_text,
};


type CallIconComponentProps = {
	contacts: Contact[];
	householdId: string;
	employerId?: string;
	avatar?: boolean;
	iconOptions?: IconOptions;
	handleClick?: () => void; // side effects besides starting the call
	disabled?: boolean;
	hasActiveBusinessRelationship?: boolean;
}

type CallIconDispatchProps = {
	startCall: () => void;
}

type CallIconProps = CallIconComponentProps & CallIconDispatchProps;

const _CallSequenceIcon: React.FC<CallIconProps> = (props: CallIconProps) => {
	const { iconOptions, startCall, handleClick, hasActiveBusinessRelationship } = props;
	const meta = composePhoneMetaData(props.contacts, hasActiveBusinessRelationship);

	const iconClass = meta.doNotContact ? 'icon-dnc-bttn' : 'icon-call-check-bttn';

	const disabled = !meta.phones.length || (iconOptions && iconOptions.hidden) || props.disabled;

	const iconStyle = getPhoneEmailIconStyle(iconOptions && iconOptions.style, !meta.phones.length);
	const baseStyle = getBaseIconStyle(iconOptions?.style?.color);

	const onClick = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		if (handleClick)
			handleClick();
		startCall();
	}

	const icon = iconOptions && iconOptions.overrideIcon
		? <Icon style={iconStyle}>
			{iconOptions.overrideIcon}
		</Icon>
		: <Icon
			onClick={disabled ? undefined : onClick}
			className={iconClass}
			style={iconStyle}
		/>;

	return (
		<div style={{ textAlign: 'center' }}>
			{(iconOptions && iconOptions.hidden)
				? <span />
				: <IconButton disabled={disabled} style={baseStyle} onClick={disabled ? undefined : onClick}>{icon}</IconButton>
			}
		</div>
	);
}

function mapOnClick(dispatch: Dispatch, ownProps: CallIconProps): CallIconDispatchProps {
	return {
		startCall: () => dispatch(StartCallSequenceAndLoadActivities.started({ householdId: ownProps.householdId, employerId: ownProps.employerId }))
	}
};

export const CallSequenceIcon = connect(
	undefined,
	mapOnClick
)(_CallSequenceIcon) as React.ComponentClass<CallIconComponentProps>;
