import { Grid, Icon, IconButton, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { Strings } from '../../assets/common/strings';
import { themePalette } from '../../utilities/branding';
import { ReshopSummaryDisplay, getDecisionByLOB, getDecisionForDisplay } from '../../utilities/reshop_util';
import { ReshopDecisionTooltip } from './reshop_decision_tooltip';
import { ReshopPlanCard } from './reshop_plan_card';

interface ReshopSummaryViewProps {
	displaySummary: ReshopSummaryDisplay;
}

const ReshopSummaryView: React.FC<ReshopSummaryViewProps> = ({ displaySummary }) => {
	if (displaySummary.lobs.length === 0) {
		return (
			<div>
				<Grid container direction="column" justify="center" alignItems="center" style={{ margin: '20px 0px' }}>
					<Typography variant="h5">{Strings.ReShop.NoDecisions}</Typography>
				</Grid>
			</div>
		);
	}

	return (
		<div>
			{displaySummary.lobs.map((lob) => (
				<Grid container direction="column" justify="center" alignItems="center" style={{ margin: '20px 0px' }}>
					<Grid container direction="row" alignItems="center" justify="center">
						<Grid item>
							<Typography variant="h5">
								{lob} {Strings.ReShop.Decision}:
							</Typography>
						</Grid>

						<Grid item>
							<ReshopDecisionTooltip decision={getDecisionByLOB(displaySummary.reshopResults, lob)}>
								<Grid container direction="row">
									<Typography variant="h5" color="secondary" style={{ marginLeft: 10 }}>
										{getDecisionForDisplay(getDecisionByLOB(displaySummary.reshopResults, lob))}
									</Typography>
									<Icon style={{ color: themePalette.tertiary_text, fontSize: 15 }}>info</Icon>
								</Grid>
							</ReshopDecisionTooltip>
						</Grid>
					</Grid>

					<Grid container direction="row" justify="center">
						{displaySummary.planComparisons[lob][0] && (
							<ReshopPlanCard
								carrierName={displaySummary.planComparisons[lob][0]?.carrierFriendlyName ?? ''}
								individualDeductible={displaySummary.planComparisons[lob][0]?.individualDeductible ?? 0}
								individualMoop={displaySummary.planComparisons[lob][0]?.individualMaxOutOfPocket ?? 0}
								isCurrent={true}
								metalLevel={displaySummary.planComparisons[lob][0]?.metalLevel}
								monthlyPremium={displaySummary.planComparisons[lob][0]?.monthlyPremium ?? 0}
								planId={displaySummary.planComparisons[lob][0]?.planId ?? ''}
								planName={displaySummary.planComparisons[lob][0]?.productName ?? ''}
								federalSubsidy={displaySummary.primaryContact.federalSubsidy}
							/>
						)}
						{displaySummary.planComparisons[lob][1] && (
							<ReshopPlanCard
								carrierName={displaySummary.planComparisons[lob][1]?.carrier ?? ''}
								fitscore={displaySummary.planComparisons[lob][1]?.fitScore}
								individualDeductible={displaySummary.planComparisons[lob][1]?.individualDeductible ?? 0}
								individualMoop={displaySummary.planComparisons[lob][1]?.individualMaxOutOfPocket ?? 0}
								isCurrent={false}
								metalLevel={displaySummary.planComparisons[lob][1]?.metalLevel}
								monthlyPremium={displaySummary.planComparisons[lob][1]?.monthlyPremium ?? 0}
								planId={displaySummary.planComparisons[lob][1]?.planId ?? ''}
								planName={displaySummary.planComparisons[lob][1]?.planName ?? ''}
								federalSubsidy={displaySummary.primaryContact.federalSubsidy}
							/>
						)}
					</Grid>
				</Grid>
			))}
		</div>
	);
};

export { ReshopSummaryView };

export const reshopSummaryViewFunction = (displaySummary: ReshopSummaryDisplay) => (
	<ReshopSummaryView displaySummary={displaySummary} />
);
