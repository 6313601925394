import * as React from 'react';
import { DialogForm } from './dialog_form';

interface AcceptRejectDialogProps {
	isOpen: boolean;
	handleClose: () => void;
	handleSubmit: () => void;
	customerName: string;
	isAccepted: boolean;
}

export const AcceptRejectDialog: React.FC<AcceptRejectDialogProps> = ({
	isOpen,
	handleClose,
	handleSubmit,
	customerName,
	isAccepted,
}) => {
	return (
		<>
			{isOpen && (
				<DialogForm
					onSubmit={handleSubmit}
					dismissSelf={handleClose}
					title="Are you sure?"
					submitText={isAccepted ? 'Accept' : 'Reject'}>
					{`This cannot be changed once ${customerName}'s recommendation is ${
						isAccepted ? 'accepted.' : 'rejected.'
					}`}
				</DialogForm>
			)}
		</>
	);
};
