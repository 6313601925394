import { CreateSmsTemplate, UpdateSmsTemplate, DeleteSmsTemplate, GetAllSmsTemplates } from './../actions/sms_template_actions';
import { call, put, all } from 'redux-saga/effects';
import http from '../utilities/http';
import { submitLog } from '../utilities/logging_util';
import { Strings } from '../assets/common/strings';
import { takeLatestForActionType, selectFromImmutable } from '../utilities/saga_util';
import { Action } from 'typescript-fsa';
import { SmsTemplate } from '../reducers/sms_template_reducer';
import { QueueSnackbar } from '../actions/snackbar_actions';
import { getSnackbarSuccessProps } from '../utilities/snackbar_util';
import { StartSmsSequence } from '../actions/sequence/sms_sequence_actions';
import { Contact } from '../reducers/ContactReducer';
import { Agent } from '../reducers/agent_reducer';
import { getAgentState } from '../selectors/agent_selectors';
import { getReferrerUrl } from '../utilities/brokerage_utils';

function* createSmsTemplateSaga(action: Action<SmsTemplate>) {
	try {

		const options = {
			method: Strings.Http.Post,
			body: JSON.stringify(action.payload),
		};

		const response = yield call(http, 'smstemplate/create', options);

		if (response.ok) {
			const data: SmsTemplate = yield response.json();
			yield all([
				put(CreateSmsTemplate.done({ ...composeAsyncResultPayload(action, data) })),
				put(QueueSnackbar(getSnackbarSuccessProps(`SMS Template Saved`))),
			])
		} else {
			yield put(CreateSmsTemplate.failed({ ...composeAsyncErrorPayload(action, "Failed to create SMS Template", response.status) }))
		}

	} catch (error) {
		yield put(CreateSmsTemplate.failed({ ...composeAsyncErrorPayload(action, "Failed to create SMS Template", error) }));

	}
}

function* updateSmsTemplateSaga(action: Action<SmsTemplate>) {
	try {

		const options = {
			method: Strings.Http.Put,
		};

		const { response, error } = yield call(http, 'smstemplate/edit', options);

		if (response.ok) {

			const data: SmsTemplate = yield response.json();
			yield all([
				put(CreateSmsTemplate.done({ ...composeAsyncResultPayload(action, data) })),
				put(QueueSnackbar(getSnackbarSuccessProps(`SMS Template Saved`))),
			])

		} else {
			throw error;
		}
	} catch (error) {
		yield put(UpdateSmsTemplate.failed({ ...composeAsyncErrorPayload(action, "Failed to update SMS Template", error) }));

	}
}


function* getAllSmsTemplatesSaga(action: Action<(string | undefined | Contact[])>) {
	try {

		const options = {
			method: Strings.Http.Get,
			headers: {
                referrerurl: getReferrerUrl(),
            }
		};

		const response = yield call(http, 'smstemplate/all', options);

		if (response.ok) {
			const templates: SmsTemplate[] = yield response.json();

			const substituteTemplateContent = templates.find(template => template.id == action.payload);

			if (substituteTemplateContent) {
				const agent: Agent = yield selectFromImmutable<Agent>(getAgentState);

				const body = {
					agentCode: agent.agentCode,
					contactId: "",
					inputString: substituteTemplateContent.content,
				};
				const options = {
					method: Strings.Http.Post,
					body: JSON.stringify(body),
				};
				const substitutionResponse = yield call(http, 'smstemplate/substitution', options);

				if (substitutionResponse.ok) {
					const previewContent = yield substitutionResponse.json();

					templates.forEach(t => {
						if(t.id == action.payload ){
							t.previewContent = previewContent;
						}
					})
				}

			}

			yield put(GetAllSmsTemplates.done({ params: action.payload, result: templates }));

		} else {
			yield put(GetAllSmsTemplates.failed({ ...composeAsyncErrorPayload(action, "Failed to get all SMS Templates", response.status) }));
		}
	} catch (error) {
		yield put(GetAllSmsTemplates.failed({ ...composeAsyncErrorPayload(action, "Failed to get all SMS Templates", error) }));

	}
}

function* deleteSmsTemplateSaga(action: Action<SmsTemplate>) {
	try {
		const options = {
			method: Strings.Http.Delete,
			body: JSON.stringify(action.payload),
		};

		const response = yield call(http, 'smstemplate', options);

		if (response.ok) {
			const data: SmsTemplate = yield response.json();
			yield put(DeleteSmsTemplate.done({ ...composeAsyncResultPayload(action, data) }));
		} else {
			yield put(DeleteSmsTemplate.failed({ ...composeAsyncErrorPayload(action, "Failed to delete SMS Template", response.status) }));
		}

	} catch (error) {
		yield put(DeleteSmsTemplate.failed({ ...composeAsyncErrorPayload(action, "Failed to delete SMS Template", error) }));

	}
}

const composeAsyncResultPayload = <T>(action: Action<T>, data: T) => ({ params: action.payload, result: data });

const composeAsyncErrorPayload = <T, Err extends Error>(action: Action<T>, errorString: string, error?: Err) => {
	submitLog(Strings.Log.Error, errorString, {
		error,
		payload: action.payload,
	});
	return { params: action.payload, error };
}


export function* smsTemplateSagas() {
	yield all([
		takeLatestForActionType(
			StartSmsSequence,
			getAllSmsTemplatesSaga
		),
		takeLatestForActionType(
			CreateSmsTemplate.started,
			createSmsTemplateSaga
		),
		takeLatestForActionType(
			UpdateSmsTemplate.started,
			updateSmsTemplateSaga
		),
		takeLatestForActionType(
			DeleteSmsTemplate.started,
			deleteSmsTemplateSaga
		),
		takeLatestForActionType(
			GetAllSmsTemplates.started,
			getAllSmsTemplatesSaga
		),
	]);
}