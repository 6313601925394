import React from 'react';
import {
	Dialog,
	DialogContent,
	DialogActions,
	Button,
} from '@material-ui/core';
import { themePalette } from '../../utilities/branding';
import { SaveCancelHeaderBarComponent } from '../Layout/SaveCancelHeaderBar';
import { LiveSearch } from '../search/live_search';
import { SearchResultProps } from '../utility/search_result';
import { Contact } from '../../reducers/ContactReducer';
import { Employer } from '../../reducers/employer_reducer';
import { Policy } from '../../reducers/policy_reducer';
import { Loaded } from '../../utilities/utilities';
import { navRoutes } from '../nav/Routes';
import { HouseholdSelection } from '../../components/Policy/policy_match_household_select';
import { FabMenu } from '../../components/nav/fab_menu';
import _ from 'lodash';
import { Strings } from '../../assets/common/strings';
import { DesktopPadding } from '../Layout/desktop_padding';

interface Props {
	filteredContacts: Contact[];
	filteredEmployers: Employer[];
	loadedContacts: Loaded<Contact>[];
	visible: boolean;
	loadMore: boolean;
	isEmployerPolicy: boolean;
	policyIsMatched: boolean;
	matchedContacts: Contact[];
	policy: Policy;
	closeDialog: () => void;
	handleSearch: (searchString: string) => void;
	resetSearch: () => void;
	getHouseholdContacts: (householdId: string) => void;
	matchContacts: (contactsToMatch: Contact[]) => void;
	matchEmployer: (employerToMatch?: Employer) => void;
	mapContactsToSearchResults: (contacts: Contact[]) => SearchResultProps[];
	mapEmployersToSearchResults: (employers: Employer[]) => SearchResultProps[];
	navigateToWithoutAddingToHistory: (route: string) => void;
	isLoading: boolean;
}

interface State {
	displayConfirmContactMatch: boolean;
	displayConfirmEmployerMatch: boolean;
	displayHouseholdSelection: boolean;
	householdSaveIsDisabled: boolean;
	anchorEl?: HTMLElement;
	selectedContact?: Contact;
	selectedEmployer?: Employer;
	selectedContactsToMatch: Contact[];
	householdSelectionUnclicked: boolean;
}

export class MatchSelectionDialog extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			displayConfirmContactMatch: false,
			displayConfirmEmployerMatch: false,
			displayHouseholdSelection: false,
			householdSaveIsDisabled: false,
			selectedContact: undefined,
			selectedEmployer: undefined,
			selectedContactsToMatch: [],
			householdSelectionUnclicked: true,
		};
	}

	componentWillMount() {
		if (this.props.policyIsMatched) {
			if (this.props.matchedContacts.length > 0) {
				this.props.getHouseholdContacts(
					this.props.matchedContacts[0].householdId
				);

				this.setState({
					displayHouseholdSelection: true,
					selectedContact: this.props.matchedContacts[0],
					selectedContactsToMatch: this.props.matchedContacts,
					householdSelectionUnclicked: true,
				});
			}
		}
	}

	isSaveButtonDisabled = () => {
		let isDisabled = false;
		if (!this.props.policyIsMatched && this.props.isEmployerPolicy) {
			isDisabled = this.state.selectedEmployer === undefined;
		} else if (
			this.state.displayHouseholdSelection &&
			!this.props.policyIsMatched &&
			!this.state.householdSelectionUnclicked
		) {
			isDisabled = this.state.householdSaveIsDisabled;
		} else if (!this.props.policyIsMatched && !this.props.isEmployerPolicy) {
			isDisabled = this.state.selectedContact === undefined;
		}
		return isDisabled;
	};

	updateHouseholdSelections = (
		selectedHouseholdMembers: Contact[],
		isPrimarySelected: boolean
	) => {
		let selectedContacts: Contact[] = [];
		if (isPrimarySelected && this.state.selectedContact) {
			selectedContacts.push(this.state.selectedContact);
		}

		if (selectedHouseholdMembers.length > 0) {
			selectedContacts = selectedContacts.concat(selectedHouseholdMembers);
		}

		const isSaveDisabled = selectedContacts.length > 0 ? false : true;
		this.setState({
			householdSaveIsDisabled: isSaveDisabled,
			selectedContactsToMatch: selectedContacts,
			householdSelectionUnclicked: false,
		});
	};

	fabButtonNavigate = () => {
		if (this.props.isEmployerPolicy) {
			this.props.navigateToWithoutAddingToHistory(
				navRoutes.employerLeadUpsertPolicy.path
					.replace(Strings.Navigation.LeadId, '0')
					.replace(Strings.Navigation.EmployerId, '0')
					.replace(Strings.Navigation.PolicyId, this.props.policy.id)
			);
		} else {
			this.props.navigateToWithoutAddingToHistory(
				navRoutes.leadUpsertPolicy.path
					.replace(Strings.Navigation.LeadId, '0')
					.replace(Strings.Navigation.HouseholdId, '0')
					.replace(Strings.Navigation.PolicyId, this.props.policy.id)
			);
		}
	};

	matchPolicyToContacts = () => {
		this.setState({
			displayConfirmContactMatch: false,
			displayHouseholdSelection: false,
			householdSelectionUnclicked: true,
		});

		this.props.matchContacts(this.state.selectedContactsToMatch);
	};

	matchPolicyToEmployer = () => {
		this.setState({
			displayConfirmEmployerMatch: false,
			householdSelectionUnclicked: true,
		});

		this.props.matchEmployer(this.state.selectedEmployer);
	};

	selectItem = (id: string) => {
		if (!this.props.isEmployerPolicy) {
			const selectedContactIndex = this.props.filteredContacts.findIndex(
				contact => contact.id == id
			);
			const selectedContact =
				selectedContactIndex > -1
					? _.cloneDeep(this.props.filteredContacts[selectedContactIndex])
					: undefined;
			this.setState({
				selectedContact: selectedContact,
				selectedContactsToMatch: selectedContact ? [selectedContact] : [],
			});
		} else {
			const selectedEmployerIndex = this.props.filteredEmployers.findIndex(
				employer => employer.id == id
			);
			const selectedEmployer =
				selectedEmployerIndex > -1
					? _.cloneDeep(this.props.filteredEmployers[selectedEmployerIndex])
					: undefined;
			this.setState({
				selectedEmployer,
			});
		}
	};

	renderConfirmationDialog = () => {
		return (
			<Dialog
				key={'match_contacts_confirmation_dialog'}
				open={
					this.state.displayConfirmContactMatch ||
					this.state.displayConfirmEmployerMatch
				}
				onClose={() =>
					this.setState({
						displayConfirmContactMatch: false,
						displayConfirmEmployerMatch: false,
					})
				}
			>
				<DialogContent>
					{this.state.selectedContactsToMatch.length > 0 ||
						this.props.isEmployerPolicy
						? 'Are you sure you want to link this policy?'
						: 'Are you sure you want to unlink this policy?'}
				</DialogContent>
				<DialogActions>
					<Button
						color="secondary"
						onClick={() =>
							this.setState({
								displayConfirmContactMatch: false,
								displayConfirmEmployerMatch: false,
								householdSelectionUnclicked: true,
							})
						}
					>
						Cancel
					</Button>
					<Button
						color="primary"
						variant="contained"
						onClick={
							this.props.isEmployerPolicy
								? this.matchPolicyToEmployer
								: this.matchPolicyToContacts
						}
						style={{ backgroundColor: themePalette.accept_button }}
					>
						{this.state.selectedContactsToMatch.length === 0 &&
							!this.props.isEmployerPolicy
							? Strings.SaveText.Unlink
							: Strings.SaveText.Link}
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	render() {
		const searchResults = this.props.isEmployerPolicy
			? this.props.mapEmployersToSearchResults(this.props.filteredEmployers)
			: this.props.mapContactsToSearchResults(this.props.filteredContacts);
		return (
			<Dialog
				id="addContactDialog"
				key="addContactDialog"
				open={this.props.visible}
				fullScreen
				PaperProps={{ style: { backgroundColor: themePalette.secondary_background } }}
			>
				<SaveCancelHeaderBarComponent
					isLoading={this.props.isLoading}
					onSave={() => {
						if (this.props.isEmployerPolicy && !this.props.policyIsMatched) {
							this.setState({ displayConfirmEmployerMatch: true });
						} else if (
							this.state.displayHouseholdSelection ||
							this.props.policyIsMatched
						) {
							this.setState({ displayConfirmContactMatch: true });
						} else if (
							!this.props.isEmployerPolicy &&
							!this.props.policyIsMatched
						) {
							if (this.state.selectedContact) {
								this.props.getHouseholdContacts(
									this.state.selectedContact.householdId
								);
							}
							this.setState({
								displayHouseholdSelection: true,
								householdSelectionUnclicked: true,
							});
						}
					}}
					isSaveDisabled={this.isSaveButtonDisabled()}
					saveText={
						!this.state.displayHouseholdSelection &&
							!this.props.policyIsMatched &&
							!this.props.isEmployerPolicy
							? Strings.SaveText.Next
							: (this.state.selectedContactsToMatch.length > 0 || this.props.isEmployerPolicy) ? Strings.SaveText.Link : Strings.SaveText.Unlink
					}
					title={this.props.isEmployerPolicy ? 'Find Employer' : 'Find Person'}
					onCancel={() => {
						if (!this.props.policyIsMatched && this.props.isEmployerPolicy) {
							this.props.closeDialog();
						} else if (
							this.state.displayHouseholdSelection ||
							this.props.policyIsMatched
						) {
							if (this.props.policyIsMatched) {
								this.props.closeDialog();
							} else {
								this.setState({
									displayHouseholdSelection: false,
									householdSelectionUnclicked: true,
								});
							}
						} else if (
							!this.props.policyIsMatched &&
							!this.props.isEmployerPolicy
						) {
							this.props.closeDialog();
						}
					}}
					trueCancelAndClose
				/>
				<DesktopPadding style={{ overflowY: 'scroll' }}>
					{(this.state.displayHouseholdSelection || this.props.policyIsMatched) &&
						this.state.selectedContact ? (
							<HouseholdSelection
								selectedContact={this.state.selectedContact}
								mapContactsToList={this.props.mapContactsToSearchResults}
								updateHouseholdSelections={(
									selectedHouseholdMembers: Contact[],
									isPrimarySelected: boolean
								) => {
									this.updateHouseholdSelections(
										selectedHouseholdMembers,
										isPrimarySelected
									);
								}}
								loadedContacts={this.props.loadedContacts}
								matchedContacts={this.props.matchedContacts}
							/>
						) : (
							<>
								<LiveSearch
									results={searchResults}
									handleSearch={this.props.handleSearch}
									resetSearch={this.props.resetSearch}
									label={'Enter name, phone, email'}
									onSelect={this.selectItem}
									loadMore={this.props.loadMore}
									loading={this.props.isLoading}
									defaultMessage={this.props.isEmployerPolicy
										? 'Click the + to add a new employer...'
										: 'Click the + to add a new person...'
									}
								/>
								<FabMenu inDialog onClick={this.fabButtonNavigate} />
							</>
						)
					}
				</DesktopPadding>

				{this.state.displayConfirmContactMatch || this.state.displayConfirmEmployerMatch 
					? this.renderConfirmationDialog()
					: null
				}
			</Dialog>
		);
	}
}
