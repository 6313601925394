import { all, call, put } from 'redux-saga/effects';
import moment from 'moment';
import {
	GetFinanceCash,
	GetFinanceBalances,
	GetFinanceInvestments,
	FinanceParams,
	FinanceActionCreator,
	GetAllFinanceData,
} from '../actions/finance_actions';
import { Action } from 'typescript-fsa';
import { http } from '../utilities';
import { ChartGroup } from '../reducers/performance_reducer';
import { takeLatestForActionType, makeHandleError } from '../utilities/saga_util';

const API = {
	CASH: 'Finance/GetCashGraphs',
	BALANCES: 'Finance/GetBalancesGraphs',
	INVESTMENTS: 'Finance/GetInvestmentsGraphs',
};
const DOWNLINE_API = 'ByDownline';

function* handleGetAllFinanceData(action: Action<FinanceParams>) {
	yield all([
		put(GetFinanceCash.started(action.payload)),
		put(GetFinanceBalances.started(action.payload)),
		put(GetFinanceInvestments.started(action.payload)),
	]);
}


const getFinanceSaga = (
	actionCreator: FinanceActionCreator,
	api: string
) =>
	function* (action: Action<FinanceParams>) {
		try {
			const response = yield call(getFinanceData(api), action.payload);
			if (response.ok) {
				const financeCharts: ChartGroup = yield response.json();
				if (!financeCharts) {
					return;
				}
				yield put(
					actionCreator.done({
						params: action.payload,
						result: financeCharts,
					})
				);
			} else {
				yield handleError(actionCreator, action, {
					errorCode: response.status,
				});
			}
		} catch (error) {
			yield handleError(actionCreator, action, error);
		}
	};
const getFinanceData = (api: string) => (
	params: FinanceParams
): Promise<any> => {
	// Convert to UTC here
	const startDate = moment(params.startDate)
		.startOf('day')
		.utc();
	const endDate = moment(params.endDate)
		.endOf('day')
		.utc();
	const urlParams = `
		?startDate=${startDate.format()}
		&endDate=${endDate.format()}
		${params.agentCode ? `&agentCode=${params.agentCode}` : ''}
		${
		params.dateRangeOverride
			? `&dateRangeOverride=${params.dateRangeOverride}`
			: ''
		}
	`;
	return http(`${api}${params.agentCode ? DOWNLINE_API : ''}${urlParams}`);
};

// UTILITY
const handleError = makeHandleError('Error getting finance data');

export function* financeSagas() {
	yield all([
		takeLatestForActionType(
			GetAllFinanceData.started,
			handleGetAllFinanceData
		),
		takeLatestForActionType(
			GetFinanceCash.started,
			getFinanceSaga(GetFinanceCash, API.CASH)
		),
		takeLatestForActionType(
			GetFinanceBalances.started,
			getFinanceSaga(GetFinanceBalances, API.BALANCES)
		),
		takeLatestForActionType(
			GetFinanceInvestments.started,
			getFinanceSaga(GetFinanceInvestments, API.INVESTMENTS)
		)
	])
}