import { AppState } from '../reducers';
import { selectFromImmutable } from './saga_util';
import { getAgeInSeconds } from './date_util';
import { Success, ActionCreatorFactory, ActionCreator, Action, Failure, AsyncActionCreators } from 'typescript-fsa';
import { put } from 'redux-saga/effects';


export enum LoaderState {
	Initial = 'INITIAL',
	Loading = 'LOADING',
	Done = 'DONE',
	Failed = 'FAILED',
};

export interface LastLoaded<TRequestProps> {
	time: Date;
	requestProps: TRequestProps;
}

export interface Loading<TData, TRequestProps = undefined> {
	data?: TData;
	error?: any;
	state: LoaderState;
	lastLoaded?: LastLoaded<TRequestProps>;
}

export function initialLoading<TData, TRequestProps = undefined>(): Loading<TData, TRequestProps> {
	return {
		state: LoaderState.Initial
	};
}

export function* getFromCache<TData, TRequestProps = undefined>
	(
		getLoadingTrackerFromState: (state: AppState) => Loading<TData, TRequestProps>,
		actionCreator: AsyncCacheableActionCreators<TRequestProps, TData>,
		startedAction: Action<TRequestProps>,
		timeToLiveSeconds: number,
		retrieveFromOrigin: () => Generator<any, void, any>

	) {
	const state = yield selectFromImmutable<AppState>();
	const loadingTracker = getLoadingTrackerFromState(state);

	let validCacheMatch = false;
	if (loadingTracker.lastLoaded) {
		const cacheMatch = loadingTracker.lastLoaded.requestProps === startedAction.payload;
		const cacheAge = getAgeInSeconds(loadingTracker.lastLoaded.time);
		const cacheValid = cacheAge <= timeToLiveSeconds;

		validCacheMatch = cacheMatch && cacheValid;
	}

	if (validCacheMatch) {
		yield put(
			actionCreator.cacheDone({
				params: startedAction.payload,
				result: loadingTracker.data!,
			})
		);
	} else {
		yield* retrieveFromOrigin();
	}
}

export function setFetched<TData, TRequestProps = undefined>
	(loadingTracker: Loading<TData, TRequestProps>, payload: Success<TRequestProps, TData>): Loading<TData, TRequestProps> {
	return {
		...loadingTracker,
		data: payload.result,
		state: LoaderState.Done,
		lastLoaded: {
			time: new Date(),
			requestProps: payload.params as TRequestProps
		}
	};
}

export function setFailed<TData, TRequestProps = undefined>
	(loadingTracker: Loading<TData, TRequestProps>, payload: Failure<TRequestProps, any>): Loading<TData, TRequestProps> {
	return {
		...loadingTracker,
		error: payload.error,
		state: LoaderState.Failed,
	};
}

export function setRetrievedFromCache<TData, TRequestProps = undefined>
	(loadingTracker: Loading<TData, TRequestProps>, payload: Success<TRequestProps, TData>): Loading<TData, TRequestProps> {
	return {
		...loadingTracker,
		data: payload.result,
		state: LoaderState.Done,
	};
}

interface AsyncCacheableActionCreators<P, S, E = any> extends AsyncActionCreators<P, S, E> {
	cacheDone: ActionCreator<Success<P, S>>;
}

export function asyncCacheable<TRequestProps, TData, TError = any>(actionCreator: ActionCreatorFactory, actionType: string) {
	const asyncCreator = actionCreator.async<TRequestProps, TData>(actionType);

	const cacheDone = actionCreator<Success<TRequestProps, TData>>(`${actionType}_RETRIEVED_CACHE`);

	return {
		...asyncCreator,
		cacheDone
	};
}