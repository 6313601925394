import {actionCreatorFactory} from 'typescript-fsa';
import { DocumentDto, DocumentForm } from '../reducers/document_reducer';

const actionCreator = actionCreatorFactory('DOCUMENTS');

export interface FavoriteParams {
    documentID: string;
    agentID: string;
}

export const GetAllDocuments = actionCreator.async<{loading: boolean;}, DocumentDto[]>('GET_ALL');
export const FavoriteDocument = actionCreator.async<FavoriteParams, undefined>('FAVORITE');
export const UnfavoriteDocument = actionCreator.async<FavoriteParams, undefined>('UN_FAVORITE');
export const EditFilterForm = actionCreator<DocumentForm>('EDIT_FILTER');
export const FilterDocuments = actionCreator<DocumentForm>('FILTER');
export const UpdateFilteredDocuments = actionCreator<DocumentDto[]>('UPDATE_FILTERED');