import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Strings } from '../../assets/common/strings';
import { TeamViewParams, GetTeamViewSummaryReport, GetActiveSalesLeaderCount } from '../../actions/teamview_actions';
import { SummaryTableCard } from '../teamview/summary_table_card';
import { ReportCard } from '../utility/report_card';

export const SummaryReport: React.FC<{params: TeamViewParams}> = props => {
    const dispatch = useDispatch();

    const reportData =  useSelector((state: any) => state.App.teamview.summaryCard, shallowEqual);
    const activeSalesLeaderCount =  useSelector((state: any) => state.App.teamview.activeSalesLeaderCount, shallowEqual);
    const isDesktop =  useSelector((state: any) => state.App.layout.desktop, shallowEqual);

    useEffect(() => {
        if (!!props.params) {
            dispatch(GetTeamViewSummaryReport.started(props.params));
            dispatch(GetActiveSalesLeaderCount.started(props.params));
        }
    }, [props.params]);

    return (
        <>
            {props.params && (reportData?.isLoading || activeSalesLeaderCount?.isLoading)
            ? (
                <ReportCard
                    color="secondary"
                    titleLeft={Strings.TeamView.SUMMARY_TAB_MOBILE}
                    isLoading
                />)
            : (
                <SummaryTableCard
                    color="secondary"
                    titleLeft={`Summary (ranks are out of ${activeSalesLeaderCount.count} SLs)`}
                    rows={reportData?.cardTable}
                    sLCount={activeSalesLeaderCount.count}
                    isDesktop={isDesktop}
                />)}
        </>
    );
};