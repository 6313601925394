import React from 'react';
import { ListItem, ListItemText, Divider } from '@material-ui/core';
import { OwnershipHistory } from '../../reducers/ownership_history_reducer';
import { displayDate } from '../../utilities/date_util';
import moment from 'moment';
import { wrapInCollapsibleCard } from '../higher_order_component/wrap_in_card';
import { SimpleListItem } from '../utility/simple_list_item';
import CustomList from '../utility/custom_list';

interface Props {
	ownershipHistory: OwnershipHistory[];
	createdByName: string;
	createdOn?: Date;
	leadAgent: string;
}

class OwnershipList extends React.Component<Props> {
	constructor(props: Props) {
		super(props);
	}
	renderHistory = () => {
		const sortedOwnershipHistory = this.props.ownershipHistory.sort((a, b) => {
			return (
				moment
					.utc(b.reassignedOn)
					.local()
					.valueOf() -
				moment
					.utc(a.reassignedOn)
					.local()
					.valueOf()
			);
		});
		return sortedOwnershipHistory.map((history, index) => (
			<div key={'ownership-history-' + index}>
				<ListItem>
					<ListItemText
						primary={
							history.reassignedFromName ? history.reassignedFromName +
								' - ' + displayDate(history.reassignedOn) :
								displayDate(history.reassignedOn)
						}
						secondary={`Reassigned by ${history.reassignedByName}`}
					/>
				</ListItem>
				<Divider />
			</div>
		));
	};
	render() {
		return (
			<CustomList>
				<SimpleListItem title={`Current Owner  -  ${this.props.leadAgent} `} />
				{this.renderHistory()}
				<SimpleListItem title={displayDate(this.props.createdOn)} subtitle={'Created by ' + this.props.createdByName} />
			</CustomList>
		);
	}
}

export const OwnershipListCard = wrapInCollapsibleCard(OwnershipList);