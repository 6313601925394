import React from 'react';
import { themePalette } from '../../utilities/branding';
import {
	Button,
	Card,
	CardHeader,
	CardContent,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
} from '@material-ui/core';
import { Application } from '../../reducers/application_reducer';
import { Policy, ProductStatus } from '../../reducers/policy_reducer';
import { ReadOnlyTextField } from '../utility/read_only_text_field';
import { QueueSnackbar } from '../../actions/snackbar_actions';
import { Loaded } from '../../utilities/utilities';
import { getSnackbarErrorProps } from '../../utilities/snackbar_util';
import { ToggleCard } from '../higher_order_component/wrap_in_card';

interface UnlinkedApplicationListProps {
	applications: Application[];
	householdId?: string;
	currentPolicy?: Loaded<Policy>;
	getAllUnlinkedApplications: () => void;
	linkApplication: (application: Application, policy: Policy) => void;
	createApplication: () => void;
}

interface UnlinkedApplicationListState {
	modalVisible: boolean;
	selectedApplication?: Application;
}

export class UnlinkedApplicationList extends React.PureComponent<
	UnlinkedApplicationListProps,
	UnlinkedApplicationListState
> {
	constructor(props: UnlinkedApplicationListProps) {
		super(props);
		this.state = {
			modalVisible: false,
		};
	}

	componentDidMount() {
		this.props.getAllUnlinkedApplications();
	}

	linkApplication() {
		this.setState({
			modalVisible: false,
		});

		if (this.state.selectedApplication && this.props.currentPolicy) {
			this.props.currentPolicy.data.applicationId = this.state.selectedApplication.id;
			this.props.linkApplication(
				this.state.selectedApplication,
				this.props.currentPolicy.data
			);
		} else {
			QueueSnackbar(getSnackbarErrorProps('Could not link policy', 6000));
		}
	}

	renderLinkConfirmation() {
		return (
			<Dialog
				open={this.state.modalVisible}
				onClose={() => {
					this.setState({
						modalVisible: false,
					});
				}}
			>
				<DialogTitle>Confirmation</DialogTitle>
				<DialogContent>
					Are you sure you want to link this application?
				</DialogContent>
				<DialogActions>
					<Button
						color="secondary"
						onClick={() =>
							this.setState({
								modalVisible: false,
								selectedApplication: undefined,
							})
						}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="primary"
						style={{ backgroundColor: themePalette.accept_button }}
						onClick={() => this.linkApplication()}
					>
						Link
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	populateApplicationList(applications: Application[]): JSX.Element[] {
		const applicationCardList: JSX.Element[] = [];

		applications.forEach((application, index) => {
			const address = application.primaryInsured.addresses.find(
				address => address.isPreferred
			);
			const addressString = address ? address.city + ', ' + address.state : '';

			applicationCardList.push(
				<div key={index}>
					<CardContent>
						<Grid container>
							<Grid item xs={6}>
								<ReadOnlyTextField value={application.carrier} />
							</Grid>
							<Grid item xs={6}>
								<ReadOnlyTextField
									value={ProductStatus[application.applicationStatus]}
								/>
							</Grid>
						</Grid>
						<Grid container>
							<Grid item xs={6}>
								<ReadOnlyTextField value={application.productType} />
							</Grid>
							<Grid item xs={6}>
								<ReadOnlyTextField value={application.statusReason} />
							</Grid>
						</Grid>
						<Grid container>
							<Grid item xs={6}>
								<ReadOnlyTextField
									value={
										application.primaryInsured.firstName +
										' ' +
										application.primaryInsured.lastName
									}
								/>
							</Grid>
							<Grid item xs={6}>
								<ReadOnlyTextField value={addressString} />
							</Grid>
						</Grid>
						<Grid container>
							<Grid item xs={12}>
								<Button
									variant="contained"
									color="primary"
									fullWidth={true}
									onClick={() =>
										this.setState({
											modalVisible: true,
											selectedApplication: application,
										})
									}
								>
									Link
								</Button>
							</Grid>
						</Grid>
					</CardContent>
					<Divider />
				</div>
			);
		});

		return applicationCardList;
	}

	render() {
		const applications = this.props.applications.filter(application => {
			return (
				application &&
				application.primaryInsured &&
				application.primaryInsured.householdId === this.props.householdId &&
				!application.isLinked
			);
		});

		return (
			<div>
				<div style={{ margin: 20 }}>
					<Button
						variant="contained"
						color="primary"
						onClick={this.props.createApplication}
						disabled={this.props.currentPolicy?.data?.primaryInsured == undefined}
						fullWidth
					>
						Create Application
					</Button>
				</div>
				<ToggleCard
					title={'Applications not Linked to a Policy'}
				>
					{applications.length > 0 
						? this.populateApplicationList(applications)
						: <ReadOnlyTextField value={'No unmatched applications at this time'} />
					}
				</ToggleCard>
				{this.renderLinkConfirmation()}
			</div>
		);
	}
}
