
export interface ActionPermission {
    mustHave: ApiPermission[];
    mustNotHave?: ApiPermission[];
}

function createActionPermission(mustHave: ApiPermission[], mustNotHave?: ApiPermission[]): ActionPermission {
    return {
        mustHave,
        mustNotHave
    };
}

export const P = {
    AccountManagement: createActionPermission(['pAccountManagement']),
    Admin: createActionPermission(['pAdminRead']),
    AdminNotificationRead: createActionPermission(['pAdminNotificationRead']),
    AdvancedSearchBLeads: createActionPermission(['pAdvancedSearchBLeads']),
    AdvancedSearchFilter: createActionPermission(['pAdvancedSearchAgentFilter']),
    AdvancedSearchFilterEdit: createActionPermission(['pAdvancedSearchAgentFilterEdit']),
    AgentSearch: createActionPermission(['pAgentSearchRead']),
    AutomatedEmail: createActionPermission(['pManageAutomatedEmails']),
    Battle: createActionPermission(['pBattleRead']),
    BulkEmail: createActionPermission(['pBulkEmailCreate']),
    ClickToCall: createActionPermission(['pClickToCall']),
    ClientConnect: createActionPermission(['pClientConnect']),
    CompanyDnc: createActionPermission(['pCompanyDNCRead', 'pCompanyDNCUpdate']),
    ConsumerSearch: createActionPermission(['pAdvancedSearch']),
    ContactCreate: createActionPermission(['pContactCreate', 'pContactUpdate']),
    CorporateTemplatesCreate: createActionPermission(['pCorporateTemplateCreate']),
    CorporateTemplatesDelete: createActionPermission(['pCorporateTemplateDelete']),
    CorporateTemplatesUpdate: createActionPermission(['pCorporateTemplateUpdate']),
    CorporateTemplatesRead: createActionPermission(['pCorporateTemplateRead']),
    CreateActivity: createActionPermission(['pActivityCreate']),
    CreateLead: createActionPermission(['pLeadCreate']),
    Crm: createActionPermission(['pCRMRead']),
    Document: createActionPermission(['pDocumentRead']),
    FactFinder: createActionPermission(['pFactFinder']),
    Finance: createActionPermission(['pFinanceRead']),
    FinanceBalanceCard: createActionPermission(['pFinanceCardBalanceRead']),
    FinanceCashCard: createActionPermission(['pFinanceCardCashRead']),
    FinanceInvestmentCard: createActionPermission(['pFinanceCardInvestmentRead']),
    General: createActionPermission(['pHomeRead']),
    Help: createActionPermission([]),
    IconManagement: createActionPermission(['pUpdateImage']),
    Impersonation: createActionPermission(['pImpersonate']),
    Import: createActionPermission(['pLeadCreate']),
    IsAdmin: createActionPermission(['pAdminRead']),
    Lead: createActionPermission(['pLeadRead']),
    LeadConnectRead: createActionPermission(['pLeadConnectRead']),
    LeadConnectUpdate: createActionPermission(['pLeadConnectUpdate']),
    News: createActionPermission(['pNewsRead']),
    Notification: createActionPermission(['pNotificationRead'], ['pAdminNotificationRead']),
    NotificationAdmin: createActionPermission(['pNotificationCreate', 'pNotificationUpdate', 'pNotificationDelete', 'pAdminNotificationRead']),
    Performance: createActionPermission(['pPerformanceRead']),
    Profile: createActionPermission(['pProfileRead']),
    Quote: createActionPermission(['pQuoteConnect']),
    ReassignLead: createActionPermission(['pContactCreate', 'pContactUpdate']),
    Settings: createActionPermission([]),
    TeamView: createActionPermission(['pTeamViewRead']),
    TodoList: createActionPermission(['pActivityRead']),
    Tool: createActionPermission(['pToolRead']),
    Training: createActionPermission(['pTrainingRead']),
    CorporateSmsCreate: createActionPermission(['pCorporateSmsCreate']),
    CorporateSmsUpdate: createActionPermission(['pCorporateSmsUpdate']),
    CorporateSmsDelete: createActionPermission(['pCorporateSmsDelete']),
    SmsCreate: createActionPermission(['pCorporateSmsCreate']),
    SmsTemplateRead: createActionPermission(['pSmsTemplateRead']),
    SmsTemplateCreate: createActionPermission(['pSmsTemplateCreate']),
    SmsTemplateUpdate: createActionPermission(['pSmsTemplateUpdate']),
    SmsTemplateDelete: createActionPermission(['pSmsTemplateDelete']),
    PodcastRead: createActionPermission(['pPodcastRead']),
    PodcastCreate: createActionPermission(['pPodcastCreate']),
    PodcastUpdate: createActionPermission(['pPodcastUpdate']),
    PodcastDelete: createActionPermission(['pPodcastDelete']),
    LicenseAppointment: createActionPermission(['pLicensesAndAppointmentsRead']),
    ESignature: createActionPermission(['pSignatureRead'])
};

export type ApiPermission =
    'pAccountManagement' |
    'pActivityCreate' |
    'pActivityRead' |
    'pActivityUpdate' |
    'pAdminNotificationRead' |
    'pAdminRead' |
    'pAdvancedSearch' |
    'pAdvancedSearchAgentFilter' |
    'pAdvancedSearchAgentFilterEdit' |
    'pAdvancedSearchBLeads' |
    'pAgentProfileRead' |
    'pAgentSearchRead' |
    'pApplicationCreate' |
    'pApplicationRead' |
    'pBattleCreate' |
    'pBattleRead' |
    'pBulkEmailCreate' |
    'pClickToCall' |
    'pClientConnect' |
    'pCompanyDNC' |
    'pCompanyDNCRead' |
    'pCompanyDNCUpdate' |
    'pContactCreate' |
    'pContactRead' |
    'pContactUpdate' |
    'pCorporateSmsCreate' |
    'pCorporateSmsDelete' |
    'pCorporateSmsRead' |
    'pCorporateSmsUpdate' |
    'pCorporateTemplateCreate' |
    'pCorporateTemplateDelete' |
    'pCorporateTemplateRead' |
    'pCorporateTemplateUpdate' |
    'pCRMRead' |
    'pDocumentRead' |
    'pEmployerCreate' |
    'pEmployerRead' |
    'pFactFinder' |
    'pFinanceCardBalanceRead' |
    'pFinanceCardCashRead' |
    'pFinanceCardInvestmentRead' |
    'pFinanceRead' |
    'pHomeRead' |
    'pHouseholdRead' |
    'pImpersonate' |
    'pLeadConnectRead' |
    'pLeadConnectUpdate' |
    'pLeadCreate' |
    'pLeadCreate' |
    'pLeadRead' |
    'pManageAutomatedEmails' |
    'pNewsRead' |
    'pNotificationCreate' |
    'pNotificationDelete' |
    'pNotificationRead' |
    'pNotificationUpdate' |
    'pPerformanceRead' |
    'pPodcastCreate' |
    'pPodcastDelete' |
    'pLicensesAndAppointmentsRead' |
    'pSignatureRead' |
    'pPodcastRead' |
    'pPodcastUpdate' |
    'pPolicyRead' |
    'pProfileRead' |
    'pQuoteConnect' |
    'pReshopRead' |
    'pReshopUpdate' |
    'pSettingsRead' |
    'pSettingsUpdate' |
    'pSmsTemplateCreate' |
    'pSmsTemplateDelete' |
    'pSmsTemplateRead' |
    'pSmsTemplateUpdate' |
    'pTeamViewRead' |
    'pToolRead' |
    'pTrainingRead' |
    'pUpdateImage' |
    'pUpdateImage' |
    'pUserAcceptTerms' |
    'pUserAcceptTerms';