import { actionCreatorFactory } from 'typescript-fsa';
import { Agent } from '../reducers/agent_reducer';
import { SearchAgent } from '../reducers/agent_search_reducer';

const actionCreator = actionCreatorFactory('AgentSearch');

export const UpdateAgentSearchFilter = actionCreator<{ filter: string; }>(
    'UPDATE_AGENT_SEARCH_FILTER'
);

export const UpdateAgentSearchAgents = actionCreator<{ agents: SearchAgent[]; }>(
    'UPDATE_AGENT_SEARCH_AGENTS'
);

export interface SearchAgentParams {
    term: string,
    searchTerminated: boolean
}
export const SearchAgentsByTerm = actionCreator.async<SearchAgentParams, SearchAgent[]>(
    'SEARCH_AGENTS_BY_TERM'
);

export const SearchAgentsByTag = actionCreator.async<string[], SearchAgent[]>(
    'SEARCH_AGENTS_BY_TAG'
);

export const GetTags = actionCreator.async<{}, string[]>(
    'GET_TAGS'
);

export const GetAgentProfile = actionCreator.async<string, Agent>(
    'GET_AGENT_PROFILE'
);