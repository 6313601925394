import { themeImages, BrandingPayload, themeLinks, themePalette, themeMisc } from '.';
import { getReferrerUrl } from '../brokerage_utils';
import { http } from '..';
import { BrandingAssets } from '../../assets';
import { Strings } from '../../assets/common/strings';
import { ThemeLinks } from './links';
import { ThemeMiscellaneous } from './misc';
import { ThemePalette } from './palette';
import { ThemeImages } from './images';
import { submitLog } from '../logging_util';

const acIcon180 = '/pwa/ac-apple-icon-180.png';
const acIcon192 = '/pwa/ac-manifest-icon-192.maskable.png';
const acIcon512 = '/pwa/ac-manifest-icon-512.maskable.png';

const bcIcon180 = '/pwa/bc-apple-icon-180.png';
const bcIcon192 = '/pwa/bc-manifest-icon-192.maskable.png';
const bcIcon512 = '/pwa/bc-manifest-icon-512.maskable.png';

const addToHead = (el: HTMLElement) => {
    const head = document.getElementsByTagName('head')[0] as HTMLElement;
	head.appendChild(el);
};
const createHeadElement = (element: string, rel: string, href: string, media?: string) => {
    const el = document.createElement(element);
    el.setAttribute('rel', rel);
    el.setAttribute('href', href);
    if (media) {
        el.setAttribute('media', media);
    }
    addToHead(el);
};

type SplashTuple = [string, string];
const splashscreens: SplashTuple[] = [
    ['apple-splash-2048-2732.jpg', '(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'],
    ['apple-splash-1668-2388.jpg', '(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'],
    ['apple-splash-1536-2048.jpg', '(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'],
    ['apple-splash-1668-2224.jpg', '(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'],
    ['apple-splash-1620-2160.jpg', '(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'],
    ['apple-splash-1290-2796.jpg', '(device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'],
    ['apple-splash-1179-2556.jpg', '(device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'],
    ['apple-splash-1284-2778.jpg', '(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'],
    ['apple-splash-1170-2532.jpg', '(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'],
    ['apple-splash-1125-2436.jpg', '(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'],
    ['apple-splash-1242-2688.jpg', '(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'],
    ['apple-splash-828-1792.jpg', '(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'],
    ['apple-splash-820-1180.jpg', '(device-width: 410px) and (device-height: 590px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'],
    ['apple-splash-1242-2208.jpg', '(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'],
    ['apple-splash-750-1334.jpg', '(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'],
    ['apple-splash-640-1136.jpg', '(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'],
];
const addApplePWA = (collection: string) => {
    const referer = collection && collection === Strings.Agencies.Hmkts ? 'ac' : 'bc';
    const href = referer === 'ac' ? acIcon180 : bcIcon180;
    createHeadElement('link', 'apple-touch-icon', href);

    splashscreens.forEach(tuple => 
        createHeadElement('link', 'apple-touch-startup-image', `${referer}-${tuple[0]}`, tuple[1])
    );
};

const updateFavicon = () => {
    createHeadElement('link', 'icon', themeImages.favicon);
};

const updateBrowserThemeColor = () => {
	const themeMeta = document.createElement('meta');
	themeMeta.setAttribute('name', 'theme-color');
	themeMeta.setAttribute('content', themePalette.primary);
    addToHead(themeMeta);
    document.body.style.backgroundColor = themePalette.primary;
};

const updateAppTitle = (collection: string) => {
	const title =
		collection && collection !== Strings.Agencies.Hmkts
			? Strings.AppTitle.BrokerConnect
			: Strings.AppTitle.AgentConnect;
	document.title = title;
};

const updateManifest = (collection: string) => {
    const referer = collection && collection === Strings.Agencies.Hmkts ? 'ac' : 'bc';
    const icon192 = referer === 'ac' ? acIcon192 : bcIcon192;
    const icon512: string = referer === 'ac' ? acIcon512 : bcIcon512;
    const manifestJson = {
        name: document.title,
        short_name: document.title,
        gcm_sender_id: '103953800507',
        theme_color: themePalette.primary,
        background_color: themePalette.primary,
        display: 'standalone',
        start_url: window.location.origin,
        orientation: 'portrait',
        icons: [
            {
                src: `${window.location.origin}/${icon192}`,  
                sizes: '192x192',
                type: 'image/png',
            },
            {
                src: `${window.location.origin}/${icon192}`,  
                sizes: '192x192',
                type: 'image/png',
                purpose: 'maskable'
            },
            {
                src: `${window.location.origin}/${icon512}`,
                sizes: '512x512',
                type: 'image/png'
            },
            {
                src: `${window.location.origin}/${icon512}`,
                sizes: '512x512',
                type: 'image/png',
                purpose: 'maskable'
            }
        ]
    };
    const manifestStr = JSON.stringify(manifestJson);
    const blob = new Blob([manifestStr], { type: 'application/json' });
    const manifestUrl = URL.createObjectURL(blob);
    
    createHeadElement('link', 'manifest', manifestUrl);
};

export const setupBranding = async () => {
	try {
		const referrerUrl = getReferrerUrl();
		const response = await http(`branding/?referrerUrl=${referrerUrl}`);
		const branding: BrandingPayload = await response.json();
	
		const { imageCollection } = branding;
	
		let broker: string = Strings.Agencies.Hmkts;
		if (imageCollection) {
			broker = imageCollection;
	
			Object.keys(themePalette).forEach((key: keyof ThemePalette) => {
				if (typeof branding[key] !== 'undefined') {
					themePalette[key] = branding[key];
				}
			});
			Object.keys(themeImages).forEach((key: keyof ThemeImages) => {
				if (typeof BrandingAssets[broker][key] !== 'undefined') {
					themeImages[key] = BrandingAssets[broker][key];
				}
			});
			Object.keys(themeLinks).forEach((key: keyof ThemeLinks) => {
				if (typeof branding[key] !== 'undefined') {
					themeLinks[key] = branding[key];
				}
			});
			Object.keys(themeMisc).forEach((key: keyof ThemeMiscellaneous) => {
				if (typeof branding[key] !== 'undefined') {
					themeMisc[key] = branding[key];
				}
			});
		}
		updateFavicon();
		updateBrowserThemeColor();
		updateAppTitle(imageCollection);
		updateManifest(imageCollection);
        addApplePWA(imageCollection);
	
		return true;
	}
	catch (err) {
        submitLog(Strings.Log.Error, 'Error getting branding', err);
		return false;
	}
};
