import React from 'react';
import { Icon, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { themePalette, themeMisc } from '../../utilities/branding';
import { isDesktop } from '../../utilities/is_mobile';
import { makeCall, sendEmail } from '../../utilities/phone_email_util';
import { normalizePhone } from '../../utilities/formatting/data_normalizations';
import { SimpleListItem } from '../../components/utility/simple_list_item';
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';
import { useMakeCall, useOpenEmail } from '../../utilities/hooks';


export type Props = {
    visible: boolean;
}
export const LoginFooter: React.FC<Props> = (props: Props) => {

    const helpTextStyle: React.CSSProperties = {
        color: themePalette.negative_text,
        fontSize: '12px',
        textAlign: 'left',
        width: 300,
        margin: 'auto',
    };

    const callSupport = useMakeCall(themeMisc.helpAndSupportPhone);
	const emailSupport = useOpenEmail(themeMisc.helpAndSupportEmail);
    
    return props.visible
        ? (
            <div style={{ paddingTop: 20 }}>
                <div style={helpTextStyle}>
                    For password reset help, contact Field Support at
                    <br/>
                    {Clickable(themeMisc.loginSupportEmail, 'email')} or {Clickable(themeMisc.loginSupportPhone, 'phone')}.
                    <br/>
                    For all other technical questions contact Field Support at the information below.
                </div>
                <List style={{ maxWidth: 300, margin: 'auto' }}>
                    <SimpleListItem
                        title={themeMisc.helpAndSupportEmail}
                        icon={<EmailIcon style={{color: themePalette.negative_icon}}/>}
                        onClick={emailSupport}
                        divider={false}
                        hideArrow
                        style={{ color: themePalette.negative_text }}
                    />
                    <SimpleListItem
                        title={normalizePhone(themeMisc.helpAndSupportPhone)}
                        icon={<CallIcon style={{color: themePalette.negative_icon}}/>}
                        onClick={callSupport}
                        divider={false}
                        hideArrow
                    />
                </List>
                <div
                    style={
                        isDesktop()
                            ? {
                                ...helpTextStyle,
                                width: 500,
                                marginTop: 30,
                                marginBottom: 30,
                            }
                            : {
                                ...helpTextStyle,
                                marginBottom: 30
                            }
                    }
                >
                    <br />
                    {`This site is intended only for authorized users. Three or more failures
                    could result in your account being locked.  If that occurs please use the contact information above for help.`}
                    <br />
                    <br />
                    {`If you encounter any problems with this site please contact your system administrator.`}
                </div>
            </div>
        )
        : null
        
};

export default LoginFooter;

const Clickable = (text: string, type: 'email' | 'phone') => (
    <span 
        style={{ cursor: 'pointer', textDecoration: 'underline' }} 
        onClick={() => {
            if (type === 'email') {
                sendEmail(text);
            }
            else if (type === 'phone') {
                makeCall(text, true);
            }
        }}
    >
        {type === 'phone' ? normalizePhone(text) : text}
    </span>
);