import { isNil } from 'lodash';
import { Grid } from '@material-ui/core';
import React from 'react';
import { ReadOnlyTextField } from '../utility/read_only_text_field';
import { toCurrencyString } from '../../assets/common/string_builders';
import { wrapInCollapsibleCard } from '../higher_order_component/wrap_in_card';

interface Props {
	annualPremium?: number;
	annualFederalSubsidy?: number;
	annualNetCost?: number;
	monthlyPremium?: number;
	monthlyFederalSubsidy?: number;
	monthlyNetCost?: number;
	costShare?: string;
}

const PremiumInfo: React.FC<Props> = (props) => {
	const annualPremiumCurrency = toCurrencyString(props.annualPremium);
	const annualFederalSubsidyCurrency = toCurrencyString(props.annualFederalSubsidy);
	const annualNetCostCurrency = toCurrencyString(props.annualNetCost);
	const monthlyPremiumCurrency = toCurrencyString(props.monthlyPremium);
	const monthlyFederalSubsidyCurrency = toCurrencyString(props.monthlyFederalSubsidy);
	const monthlyNetCostCurrency = toCurrencyString(props.monthlyNetCost);
	const costShare = !isNil(props.costShare) ? props.costShare : '';

	return (
		<Grid container>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Annual Premium"
						value={annualPremiumCurrency}
						multiline
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Annual Federal Subsidy"
						value={annualFederalSubsidyCurrency}
						multiline
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Annual Net Cost"
						value={annualNetCostCurrency}
						multiline
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Monthly Premium"
						value={monthlyPremiumCurrency}
						multiline
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Monthly Federal Subsidy"
						value={monthlyFederalSubsidyCurrency}
						multiline
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Monthly Net Cost"
						value={monthlyNetCostCurrency}
						multiline
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Cost Share"
						value={costShare}
						multiline
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export const PremiumInfoCard = wrapInCollapsibleCard(PremiumInfo);
