import React from 'react';
import { Grid, Chip, TextField, MenuItem } from '@material-ui/core';
import { MappedFilterFieldProps } from '.';
import { IdObject, selectNext, Dictionary } from '../../../utilities/object_util';


type State<T> = {
    selection: Dictionary<T>;
    /**
     * The last label that was selected in the dropdown OR the current value;
     */
    lastSelected?: string;
}

type DropDownFilterProps<T extends IdObject> = MappedFilterFieldProps<T> & {
    multiselect?: boolean;
}

export class DropdownFilter<T extends IdObject> extends React.Component<DropDownFilterProps<T>, State<T>>{
    multiselect: boolean;

    constructor(props: DropDownFilterProps<T>) {
        super(props);
        this.state = {
            selection: props.initialSelection,
        };

        this.multiselect = !!props.multiselect;
    }

    componentDidMount() {
        const {
            initialSelection: selected,
            includeAll,
        } = this.props;

        if (selected) {
            this.setSelection(selected);

        } else if (includeAll) {
            this.setSingleSelection("All");

        }
        this.updateParent();
    };

    componentWillReceiveProps(nextProps: DropDownFilterProps<T>){
        if(nextProps.initialSelection != this.props.initialSelection){
            this.setSelection(nextProps.initialSelection);
        }
    }

    updateParent = () => {
        const { label } = this.props;
        const { selection } = this.state;

        const filterString = Object.keys(this.state.selection).join(', ');

        this.props.updateFilterFacet(label,
            {
                hintText: filterString == "All" ? "" : filterString,
                filterFunction: (obj: T) => this.props.compareFn(obj, this.state.selection),
                selection,
            })
    }

    setSelection = (selection: Dictionary<T>) => {
        this.setState({
            selection: selection
        }, this.updateParent);
    }

    setSingleSelection = (value: string) => {
        const { options } = this.props;
        const selection = selectNext({} as Dictionary<T>, value, Object.keys(options).length);
        this.setSelection(selection);
    }

    handleUpdateMultiSelection = (value: string) => {
        if (this.props.disabled) {
            return;

        } else if (value == "All") {
            this.setSingleSelection(value);

        } else {
            const { options } = this.props;
            const { selection: currentSelection } = this.state;
            const selection = selectNext(currentSelection, value, Object.keys(options).length);

            delete selection["All"];

            if (Object.keys(selection).length)
                this.setSelection(selection);
            else
                this.setSingleSelection("All");
        }
    };

    onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.textContent || '';

        if (this.multiselect) {
            this.handleUpdateMultiSelection(value);

        } else {
            this.setSingleSelection(value);
        }

        if (this.props.onClick)
            this.props.onClick(value);
    }

    getOptions = (): { labels: string[], chips: string[], value: string } => {
        const {
            includeAll,
            options,
        } = this.props;

        const { selection } = this.state;

        const optionKeys = Object.keys(options);

        const allLabels = includeAll ?
            ['All'].concat(optionKeys)
            : optionKeys;

        const labels = this.multiselect ? allLabels.filter((option) => !selection[option]) : allLabels;
        const chips = allLabels.filter((option) => !!selection[option]);
        const value: string = this.multiselect || chips.length == 0 ? "" : chips[chips.length - 1];

        return {
            chips,
            labels,
            value,
        }
    }

    render() {
        const {
            disabled,
            label,
            multiselect
        } = this.props;

        const { labels, chips, value } = this.getOptions();



        return (
            <Grid item xs={12}>
                <TextField
                    select
                    onChange={this.onChange}
                    fullWidth
                    value={value}
                    label={label}
                    disabled={!!disabled}
                >
                    {labels.map((item: string, index: number) =>
                        <MenuItem
                            key={item + index}
                            value={item}
                        >
                            {item}
                        </MenuItem>
                    )}
                </TextField>
                {
                    !!multiselect
                    && <div style={styles.chipWrapper}>
                        {chips.map(chip => (
                            <Chip
                                style={styles.chip}
                                key={chip}
                                label={chip}
                                onDelete={() => this.handleUpdateMultiSelection(chip)}
                            />))
                        }
                    </div>}
            </Grid>
        );
    }
}

const styles: { [key: string]: React.CSSProperties } = {
    chip: {
        marginTop: '2%',
        marginRight: '.5%'
    },
    chipWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '10'
    }

};
