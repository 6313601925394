import React from 'react';
import { themePalette } from '../../../utilities/branding';

export const FitScoreRibbonIcon: React.FC = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="100%"
		height="100%"
		viewBox="0 0 67.395 51.918"
		preserveAspectRatio="none">
		<g id="Group_3198" data-name="Group 3198" transform="translate(-200 2.918)">
			<g id="Group_3197" data-name="Group 3197">
				<path
					id="Path_488"
					data-name="Path 488"
					d="M1.52,0H65.361a1.594,1.594,0,0,1,1.52,1.659v48.6a1.594,1.594,0,0,1-1.52,1.659l-32.3-7.954L1.52,51.917A1.594,1.594,0,0,1,0,50.258V1.659A1.594,1.594,0,0,1,1.52,0Z"
					transform="translate(200 -2.918)"
					fill={themePalette.secondary}
				/>
				<g id="Group_2728" data-name="Group 2728" transform="translate(203.123 2.342)">
					<g id="Group_2072" data-name="Group 2072" transform="translate(3 0)">
						<g id="Group_2733" data-name="Group 2733" transform="translate(3.877)" />
					</g>
				</g>
			</g>
		</g>
	</svg>
);
