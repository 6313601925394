import { AppState } from '../reducers';
import { createSelector } from 'reselect';
import { User } from '../reducers/user_reducer';
import { makeSubstateAccessorCreator } from '../utilities/selectors_util';
import {  } from '../utilities/utilities';
import { getDeviceId } from '../utilities/device_util';

export const getUserState = (state: AppState):User => state.user || {};
const createUserStateAccessor = makeSubstateAccessorCreator(getUserState);

export const selectIsFirstTimeLogin = createUserStateAccessor(user =>
    !Boolean(user.acceptanceDate)
);

export const selectIsVideoAccepted = createUserStateAccessor(user =>
    Boolean(user.videoAcceptanceDate)
);

export const selectIsImpersonating = createUserStateAccessor(user =>
    Boolean(user.impersonatingId) && user.impersonatingId != user.id
);

export const getUserId = createUserStateAccessor(user =>
    user.id
);

export const getCurrentDevice = createSelector(
    [getUserState],
    (user) =>
        user.devices.find(device => device.deviceID === getDeviceId())
);