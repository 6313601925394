import { call, put, all } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import http from '../utilities/http';
import { QueueSnackbar } from '../actions/snackbar_actions';
import { StoreApplications } from '../actions/application_actions';
import { GetProductCounts, StorePolicies } from '../actions/policy_actions';
import { Policy, ProductStatuses } from '../reducers/policy_reducer';
import { Application } from '../reducers/application_reducer';
import {
	StoreProductFilters,
	SearchProducts,
	SetHasMore,
	ProductFilterPayload,
} from '../actions/product_actions';
import { ProductFilters } from '../reducers/product_filter_reducer';
import { themePalette } from '../utilities/branding';
import moment from 'moment';
import { ProductSearchData } from '../reducers/product_reducer';
import { takeLatestForActionType } from '../utilities/saga_util';
import { getSortByProperties } from '../utilities/sort_util';
import { getSnackbarErrorProps } from '../utilities/snackbar_util';

//////////  Search Products  //////////
function* searchProducts(action: Action<ProductFilterPayload>) {
	const errorSnackbar = getSnackbarErrorProps('Product Search Failed');

	function* handleError(error: any) {
		yield all([
			put(QueueSnackbar(errorSnackbar)),
			put(SetHasMore(false)),
			put(SearchProducts.failed({ params: action.payload, error })),
		]);
	}

	try {
		action.payload.productStatusFilter = action.payload.productStatusFilter == 'All' ? '' : action.payload.productStatusFilter;
		action.payload.lineOfBusinessFilter = action.payload.lineOfBusinessFilter == 'All'? '' : action.payload.lineOfBusinessFilter;
		action.payload.carrierFilter = action.payload.carrierFilter == 'All'? '' : action.payload.carrierFilter;
		action.payload.productTypeFilter = action.payload.productTypeFilter == 'All'? '' : action.payload.productTypeFilter;
		action.payload.productMatchFilter = action.payload.productMatchFilter == 'All'? '' : action.payload.productMatchFilter;
		action.payload.productDateFilter = action.payload.productDateFilter == 'All'? '' : action.payload.productDateFilter;

		yield put(StoreProductFilters(action.payload));

		const payload = { ...action.payload };
		const sortingProperties: {
			category: string;
			direction: string;
		} = getSortByProperties(action.payload.sortBy);
		payload.fromDate = moment(payload.fromDate)
			.utc()
			.toDate();
		payload.toDate = moment(payload.toDate)
			.utc()
			.toDate();
		payload.sortCategory = sortingProperties.category;
		payload.sortDirection = sortingProperties.direction;

		


		const response = yield call(searchProductsClient, payload);

		if (response.ok) {
			const data: ProductSearchData = yield response.json();
			const applications: Application[] = [];
			const policies: Policy[] = [];

			data.forEach(item => {
				if (item.Application) applications.push(item.Application);
				if (item.Policy) policies.push(item.Policy);
			});

			const clearCache = action.payload.pageNumber === 0;
			if (applications.length > 0 || clearCache)
				yield put(StoreApplications({ applications, clearCache }));

			if (policies.length > 0 || clearCache)
				yield put(StorePolicies({ policies, clearCache }));

			yield all([
				put(SetHasMore(data.length == action.payload.pageSize)),
				put(SearchProducts.done({ params: action.payload, result: data })),
			]);
		} else {
			yield* handleError(response.error);
		}
	} catch (error) {
		yield* handleError(error);
	}
}

function searchProductsClient(filters: ProductFilters): Promise<any> {
	const { pageNumber, pageSize, isLoading, hasMore, ...rest } = filters;
	return http(
		'Product/GetFilteredProducts?scope=Me',
		{
			method: 'POST',
			body: JSON.stringify(rest),
		},
		{
			index: pageNumber || 0,
			size: pageSize || 20,
		}
	);
}

//////////  Get Product Counts  //////////
function* getProductCountsSaga(action: Action<undefined>) {
	const errorSnackbar = getSnackbarErrorProps('Get Product Status Counts Failed');
	try {
		const response = yield call(getProductCountsClient);

		if (response.ok) {
			const data: {
				[key in ProductStatuses]?: number
			} = yield response.json();

			yield put(GetProductCounts.done({ params: undefined, result: data }));
		} else {
			yield put(
				GetProductCounts.failed({ params: undefined, error: response.status })
			);
			yield put(QueueSnackbar(errorSnackbar));
		}
	} catch (error) {
		yield put(GetProductCounts.failed({ params: undefined, error: error }));
		yield put(QueueSnackbar(errorSnackbar));
	}
}

function getProductCountsClient(): Promise<any> {
	return http('Product/StatusCounts?scope=Me');
}

export function* productSagas() {
	yield all([
		takeLatestForActionType(
			SearchProducts.started,
			searchProducts
		),
		takeLatestForActionType(
			GetProductCounts.started,
			getProductCountsSaga
		),
	])
}