import { Action as ReduxAction } from 'redux';
import { isType } from 'typescript-fsa';
import { ContactDedupeCheck, CloseDedupeDialog } from '../actions/dedupe_actions';
import { QueueSnackbar } from '../actions/snackbar_actions';
import { getSnackbarErrorProps } from '../utilities/snackbar_util';

export interface ContactDedupeDto {
    phones: string[],
    emails: string[],
    callback?: Function
}

export interface ContactDedupeResult {
    firstName: string,
    lastName: string,
    preferredName: string,
    householdRole: string,
    id: string,
    householdId: string,
    phones: string[],
    emails: string[]
}

export interface DedupeState {
    open: boolean,
    loading: boolean,
    contactResults: ContactDedupeResult[],
    callback?: Function
}

const initialState: DedupeState = {
    open: false,
    loading: false,
    contactResults: [],
    callback: undefined
}

export function dedupeReducer(
	state: DedupeState = initialState,
	action: ReduxAction
): DedupeState {
    if(isType(action, ContactDedupeCheck.started)) {
        return {
            open: true,
            loading: true,
            contactResults: [],
            callback: action.payload.callback
        };
    } else if(isType(action, ContactDedupeCheck.done)) {
        return {
            ...state,
            loading: false,
            contactResults: action.payload.result
        }
    } else if(isType(action, ContactDedupeCheck.failed)) {
        QueueSnackbar(getSnackbarErrorProps('Server Error'))
        return state;
    } else if(isType(action, CloseDedupeDialog)) {
        return initialState;
    } else {
        return state;
    }
}