import { call, put, all, debounce } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import http, { HttpOptions } from '../utilities/http';
import { submitLog } from '../utilities/logging_util';
import { SearchAgentsByTerm, GetTags, SearchAgentsByTag, GetAgentProfile, SearchAgentParams } from '../actions/agent_search_actions';
import { Strings } from '../assets/common/strings';
import { QueueSnackbar } from '../actions/snackbar_actions';
import { SearchAgent } from '../reducers/agent_search_reducer';
import { takeLatestForActionType } from '../utilities/saga_util';


//////////  Get Agent Profile //////////
function* getAgentProfileSaga(action: Action<string>) {
	try {
		const response = yield call(getAgentProfileCall, action.payload);

		if (response.ok) {
			const data = yield response.json();
			yield put(GetAgentProfile.done({
				params: action.payload,
				result: data
			})
			);
		} else {
			yield put(
				GetAgentProfile.failed({
					params: action.payload,
					error: response.status
				})
			);
		}
	} catch (error) {
		submitLog(Strings.Log.Error, `Failed to retrieve agent data from web api`, {
			error,
			payload: action.payload,
		});
		yield put(
			GetAgentProfile.failed({ params: action.payload, error })
		);
	}
}

export function getAgentProfileCall(agentCode: string): Promise<any> {
	return http(`agent/${agentCode}`);
}


/// Agent term search

function searchAgentsByTerm(term: string, searchTerminated: boolean): Promise<Response> {
	const options: HttpOptions = {
		method: 'GET',
	};

	let url = `agent/search/${term}`;
	if (searchTerminated) {
		url += '/terminated';
	}

	return http(url, options);
}

function* searchAgentsByTermSaga(action: Action<SearchAgentParams>) {
	if (action.payload.term.length > 0) {
		try {
			const response: Response = yield call(
				searchAgentsByTerm,
				action.payload.term,
				action.payload.searchTerminated
			);

			if (response.ok) {
				const agents: SearchAgent[] = yield response.json();
				yield put(
					SearchAgentsByTerm.done({
						params: action.payload,
						result: agents,
					})
				);
			}
            else {
                yield put(
                    SearchAgentsByTerm.failed({ params: action.payload, error: response.statusText })
                );
            }
		} catch (error) {
			submitLog(Strings.Log.Error, `Error searching agents`, { error });
			yield put(
				SearchAgentsByTerm.failed({ params: action.payload, error })
			);
		}
	} else {
		yield put(
			SearchAgentsByTerm.done({
				params: action.payload,
				result: []
			})
		);
	}
}

function getTagsClient(): Promise<any> {
	return http('agent/getTags');
}

function* GetTagsSaga(action: Action<{}>) {
	try {
		const response = yield getTagsClient();
		if (response.ok) {
			const tags: string[] = yield response.json();
			yield put(GetTags.done({ params: action.payload, result: tags ? tags : [] }));

		} else {
			yield put(QueueSnackbar({ text: 'Unable to load tag data' }));
		}

	}
	catch (error) {
		submitLog(error, `Error upserting appointment website`, { error });
		yield put(QueueSnackbar({ text: 'Unable to load tag data' }));
	}
}

function getAgentsByTagsClient(tags: string[]) {
	const options: HttpOptions = {
		method: 'POST',
		body: JSON.stringify(tags),
	};

	return http(`agent/search/?Scope=Agency`, options);
}

function* getAgentsByTagsSaga(action: Action<string[]>) {
	try {
		const response = yield call(getAgentsByTagsClient, action.payload);
		if (response.ok) {
			const data: SearchAgent[] = yield response.json();
			yield put(SearchAgentsByTag.done({ params: action.payload, result: data }));
		} else {
			yield put(
				SearchAgentsByTag.failed({
					params: action.payload,
					error: { errorCode: response.status }
				})
			);
		}
	} catch (error) {
		submitLog(error, `Error searching Agents by tags`, { error });
		yield put(
			SearchAgentsByTag.failed({ params: action.payload, error })
		);
	}
}

export function* agentSearchSagas() {
	yield all([
		takeLatestForActionType(
			GetAgentProfile.started,
			getAgentProfileSaga
		),
		debounce(
            500,
			SearchAgentsByTerm.started,
			searchAgentsByTermSaga
		),
		takeLatestForActionType(
			GetTags.started,
			GetTagsSaga
		),
		takeLatestForActionType(
			SearchAgentsByTag.started,
			getAgentsByTagsSaga
		),
	])
}