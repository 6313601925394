import { submitLog } from '../logging_util';
import { store } from '../..';
import { Strings } from '../../assets/common/strings';
import { AppConfig, loadFirebaseConfiguration } from '../../types/config';
import { FirebaseOptions, initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { ReceivedFcmNotification } from '../../actions/fcm_actions';
import { isBrokerage } from '../brokerage_utils';

const acIcon180 = require('../../assets/pwa/ac-apple-icon-180.png');
const bcIcon180 = require('../../assets/pwa/bc-apple-icon-180.png');

declare const firebase: any;
export interface FcmNotification {
    data: {
        url: string;
        type: string;
        id: string;
    },
    notification: {
        title: string;
        body: string;
    }
}

const firebaseConfig: FirebaseOptions = await loadFirebaseConfiguration();
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

function registerReady(serviceWorkerScript) {
    return navigator.serviceWorker.register(serviceWorkerScript)
        .then(registration => {
            // If there is an active worker and nothing incoming, we are done.
            let incomingServiceWorker = registration.installing || registration.waiting;
            if (registration.active && !incomingServiceWorker) {
                return Promise.resolve<ServiceWorkerRegistration>(registration);
            }
            // If not, wait for the newest service worker to become activated.
            return new Promise<ServiceWorkerRegistration>(fulfill => {
                incomingServiceWorker!.onstatechange = (event) => {
                    let serviceWorker = event!.target as ServiceWorker;
                    if (serviceWorker!.state === 'activated') {
                        incomingServiceWorker!.onstatechange = null;
                        return fulfill(registration);
                    }
                };
            });
        })
}

export const registerFireBase = async () => {
    try {
        const serviceWorkerRegistration = await registerReady("/firebase-messaging-sw.js");
        const currentToken = await getToken(messaging, { vapidKey: AppConfig.firebaseVapidKey, serviceWorkerRegistration: serviceWorkerRegistration });
        if (currentToken) {
            submitLog(Strings.Log.Log, `Firebase push notification token issued/retrieved for the device. Token: ${currentToken}`, { currentToken });
            return currentToken;
        } else {
            submitLog(Strings.Log.Error, `An unknown error occurred while retrieving the Firebase messaging token.`);
            return Promise.reject(`An unknown error occurred while retrieving the Firebase messaging token.`);
        }
    }
    catch (error) {
        submitLog(Strings.Log.Error, `An error occurred while retrieving the Firebase messaging token. `, error);
        return Promise.reject(error);
    }
};

export const initializeOnMessageListener = async () => {
    const registration = await registerReady("/firebase-messaging-sw.js");

    onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);
        if (payload && payload.notification && payload.notification.title) {
            store.dispatch(ReceivedFcmNotification(payload));
            return registration.showNotification(payload.notification.title, {
                body: payload.notification.body,
                icon: `${window.location.origin}/${isBrokerage ? bcIcon180 : acIcon180}`,
                data: payload.data,
                tag: payload.data?.type,
            });
        }
    });
}