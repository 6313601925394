import { Podcast } from "../../reducers/podcast_reducer";
import React from 'react';
import { IconButton, Icon } from '@material-ui/core';
import { themePalette } from '../../utilities/branding';
import ClampLines from 'react-clamp-lines';
import { CustomListItem, SubtitleProps } from '../utility/custom_list_item';
import { displayDate, DateTimeFormat } from '../../utilities/date_util';
import moment from 'moment';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

interface PodcastItemProps {
    podcast: Podcast;
    handleNavigateToPodcast: (podcastId: string) => void;
    handlePlayPodcast: (podcast: Podcast) => void;
    short?: boolean;
}

export const PodcastItem: React.FC<PodcastItemProps> = (props) => {
    const { podcast, short, handleNavigateToPodcast, handlePlayPodcast } = props;

    const handleClick = () => {
        handleNavigateToPodcast(podcast.id);
    }

    const handlePlay = () => {
        handlePlayPodcast(podcast);
    }

    const subtitles: SubtitleProps[] = [
        {
            value:
                <ClampLines
                    text={podcast.description || ''}
                    lines={2}
                    buttons={false}
                />
        }
    ]

    if (podcast.categories.length > 0 && !short) {
        subtitles.push({ label: 'Category: ', value: podcast.categories.join(', ') })
    };

    const rightSubtitles: SubtitleProps[] = [
        { label: short ? '' : 'Length: ', value: moment.duration(podcast.duration, "seconds").format() }
    ];

    if (podcast.isPublished) {
        rightSubtitles.push({ label: short ? '' : 'Published: ', value: displayDate(podcast.publishDate, DateTimeFormat.Date) });
    }

    return (
        <CustomListItem
            header={
                short
                    ? (
                        <ClampLines
                            text={podcast.title || ''}
                            lines={2}
                            buttons={false}
                        />
                    )
                    : podcast.title
            }
            id={podcast.id}
            subtitles={subtitles}
            rightSubtitles={rightSubtitles}
            onClick={handleClick}
            leftActionElement={
                <Icon>audiotrack</Icon>
            }
            rightActionElement={
                <IconButton onClick={handlePlay}>
                    <PlayArrowIcon/>
                </IconButton>
            }
        />
    )

}