import React from 'react';
import { defaultSvgProps, SvgProps } from '..';

const ProfileSvg: React.FC<SvgProps> = (props) => (
    <svg viewBox="0 0 35 35" {...props}>
        <path
            d="M10.72 29.68a.45.45 0 0 1-.29-.1.5.5 0 0 1-.19-.56l2.64-8.1L6 15.9a.49.49 0 0 1-.18-.55.5.5 0 0 1 .46-.35h8.52l2.64-8.11a.52.52 0 0 1 .95 0L21 15h8.53a.49.49 0 0 1 .47.35.47.47 0 0 1-.18.55l-6.9 5 2.66 8.1a.5.5 0 0 1-.77.56l-6.9-5-6.9 5a.45.45 0 0 1-.29.12zm7.19-6.23a.57.57 0 0 1 .3.1l5.94 4.32-2.27-7a.5.5 0 0 1 .18-.56L28 16h-7.34a.5.5 0 0 1-.48-.35l-2.27-7-2.27 7a.5.5 0 0 1-.47.35H7.82l5.94 4.32a.49.49 0 0 1 .18.56l-2.27 7 6-4.32a.53.53 0 0 1 .24-.11z"
        />
        <path
            d="M10.72 29.68a.45.45 0 0 1-.29-.1.5.5 0 0 1-.19-.56l2.64-8.1L6 15.9a.49.49 0 0 1-.18-.55.5.5 0 0 1 .46-.35h8.52l2.64-8.11a.52.52 0 0 1 .95 0L21 15h8.53a.49.49 0 0 1 .47.35.47.47 0 0 1-.18.55l-6.9 5 2.66 8.1a.5.5 0 0 1-.77.56l-6.9-5-6.9 5a.45.45 0 0 1-.29.12zm7.19-6.23a.57.57 0 0 1 .3.1l5.94 4.32-2.27-7a.5.5 0 0 1 .18-.56L28 16h-7.34a.5.5 0 0 1-.48-.35l-2.27-7-2.27 7a.5.5 0 0 1-.47.35H7.82l5.94 4.32a.49.49 0 0 1 .18.56l-2.27 7 6-4.32a.53.53 0 0 1 .24-.11z"
        />
    </svg>
);
ProfileSvg.defaultProps = defaultSvgProps;

export default ProfileSvg;
