import { call, put, all } from 'redux-saga/effects';
import { CreateBattle, EditBattle, GetBattle, DeleteBattle, GetBattleList, AcceptOrRejectBattle, AddBattleMessage, BattleChoice, DeleteBattleMesssages } from '../actions/battles_actions';
import { Action } from 'typescript-fsa';
import { Battle, BattleMessage } from '../reducers/battles_reducer';
import { Strings as S } from '../assets/common/strings';
import { QueueSnackbar } from '../actions/snackbar_actions';
import http, { HttpOptions } from '../utilities/http';
import { navigateToWithoutAddingToHistory } from '../actions/navigation_actions';
import { navRoutes } from '../components/nav/Routes';
import { getRouteHistory } from '../selectors/navigation_selectors';
import H from 'history';
import { selectFromImmutable, takeLatestForActionType, createErrorMessages } from '../utilities/saga_util';
import { getSnackbarSuccessProps } from '../utilities/snackbar_util';

function* createBattleSaga(action: Action<Battle>) {
	try {
		const response = yield call(createBattleClient, action.payload)
		if (response.ok) {

			yield put(navigateToWithoutAddingToHistory(navRoutes.battles.path));
			successfulSnackBar(S.Battle.SuccessfullyCreated);
			yield put(CreateBattle.done({ params: action.payload, result: {} }));

		}
		else {
			yield createBattleError(response.status);
		}
	}
	catch (error) {
		yield createBattleError(error);
	}
}

function createBattleClient(battle: Battle): Promise<any> {
	const options: HttpOptions = {
		method: 'POST',
		body: JSON.stringify(battle),
	};

	return http('battle', options);
}

function* createBattleError(error: any) {
	let errorText: string = S.Battle.FailedToCreate;
	yield put(CreateBattle.failed);

	createErrorMessages(errorText, error);
}

function* editBattleSaga(action: Action<Battle>) {
	try {
		const response = yield call(editBattleClient, action.payload)
		if (response.ok) {

			yield put(
				EditBattle.done({ params: action.payload, result: {} })
			);


			successfulSnackBar(S.Battle.SuccessfullyUpdated);

			yield put(
				navigateToWithoutAddingToHistory(
					navRoutes.battle.path.replace(S.Navigation.BattleId, action.payload.id))
			);
		}
		else {
			yield editBattleError(response.status);
		}
	}
	catch (error) {
		yield editBattleError(error);
	}
}

function editBattleClient(battle: Battle): Promise<any> {
	const options: HttpOptions = {
		method: 'PUT',
		body: JSON.stringify(battle),
	};

	return http('battle', options);
}

function* editBattleError(error: any) {
	let errorText: string = S.Battle.FailedToUpdate;
	yield put(EditBattle.failed);

	createErrorMessages(errorText, error);
}

function* getBattleSaga(action: Action<string>) {
	try {
		const response = yield call(getBattleClient, action.payload)
		if (response.ok) {

			const battle: any = yield response.json();
			yield put(
				GetBattle.done({ params: action.payload, result: battle })
			);
		}
		else {
			yield getBattleError(response.status);
		}
	}
	catch (error) {
		yield getBattleError(error);
	}
}

function getBattleClient(battleId: string): Promise<any> {
	return http(`battle/${battleId}/`);
}

function* getBattleError(error: any) {
	let errorText: string = S.Battle.FailedToGet;
	yield put(GetBattle.failed);

	createErrorMessages(errorText, error);
}

function* deleteBattleSaga(action: Action<string>) {
	try {
		const response = yield call(deleteBattleClient, action.payload)
		if (response.ok) {
			yield put(
				DeleteBattle.done({ params: action.payload, result: {} })
			);

			successfulSnackBar(S.Battle.SuccessfullyDeleted);

			yield put(
				navigateToWithoutAddingToHistory(navRoutes.battles.path)
			);
		}
		else {
			yield deleteBattleError(response.status);
		}
	}
	catch (error) {
		yield deleteBattleError(error);
	}
}

function deleteBattleClient(battleId: string): Promise<any> {
	const options: HttpOptions = {
		method: 'DELETE'
	};
	return http(`battle/${battleId}/`, options);
}

function* deleteBattleError(error: any) {
	let errorText: string = S.Battle.FailedToDelete;
	yield put(DeleteBattle.failed);

	createErrorMessages(errorText, error);
}

function* getBattleListSaga(action: Action<{}>) {
	try {
		const response = yield call(getBattleListClient)
		if (response.ok) {

			const battles: Battle[] = yield response.json();
			yield put(
				GetBattleList.done({ params: action.payload, result: battles ? battles : [] })
			);
		}
		else {
			yield getBattleListError(response.status);
		}
	}
	catch (error) {
		yield getBattleListError(error);
	}
}

function getBattleListClient(): Promise<any> {
	return http(`battle/`);
}

function* getBattleListError(error: any) {
	let errorText: string = S.Battle.FailedToGet;
	yield put(GetBattleList.failed);

	createErrorMessages(errorText, error);
}

function* acceptOrRejectBattleSaga(action: Action<BattleChoice>) {
	try {
		const response = yield call(acceptOrRejectBattleClient, action.payload)
		if (response.ok) {
			yield put(
				AcceptOrRejectBattle.done({ params: action.payload, result: {} })
			);

			successfulSnackBar(action.payload.acceptedBattle ? S.Battle.SuccessfullyAccepted : S.Battle.SuccessfullyRejected);
			yield put(
				navigateToWithoutAddingToHistory(navRoutes.battles.path)
			);
		}
		else {
			yield acceptOrRejectBattleError(response.status);
		}
	}
	catch (error) {
		yield acceptOrRejectBattleError(error);
	}
}

function acceptOrRejectBattleClient(battleChoice: BattleChoice): Promise<any> {
	const options: HttpOptions = {
		method: 'PUT',
		body: JSON.stringify(battleChoice.id),
	};

	if (battleChoice.acceptedBattle) {
		return http(`battle/AcceptBattle/`, options);
	}

	else {
		return http(`battle/RejectBattle/`, options);
	}
}

function* acceptOrRejectBattleError(error: any) {
	let errorText: string = S.Battle.FailedToAcceptOrReject;
	yield put(AcceptOrRejectBattle.failed);

	createErrorMessages(errorText, error);
}

function* addBattleMessageSaga(action: Action<BattleMessage>) {
	try {
		const response = yield call(addBattleMessageClient, action.payload)
		if (response.ok) {


			yield put(
				AddBattleMessage.done({ params: action.payload, result: {} })
			);

			yield put(
				GetBattle.started(action.payload.battleId)
			);
		}
		else {
			yield addBattleMessageError(response.status);
		}
	}
	catch (error) {
		yield addBattleMessageError(error);
	}
}

function* successfulSnackBar(successText: string) {
	yield put(
		QueueSnackbar(getSnackbarSuccessProps(successText))
	);
};

function addBattleMessageClient(battleMessages: BattleMessage): Promise<any> {
	const options: HttpOptions = {
		method: 'POST',
		body: JSON.stringify(battleMessages),
	};

	return http(`battle/AddMessage`, options);
}

function* addBattleMessageError(error: any) {
	let errorText: string = S.Battle.FailedToAddMessage;
	yield put(AddBattleMessage.failed);

	createErrorMessages(errorText, error);
}


function* deleteBattleMesssagesSaga(action: Action<{}>) {
	try {
		const response = yield call(deleteBattleMesssagesClient)
		if (response.ok) {

			yield put(
				DeleteBattleMesssages.done({ params: action.payload, result: {} })
			);
		}
		else {
			yield deleteBattleMesssagesError(response.status);
		}
	}
	catch (error) {
		yield deleteBattleMesssagesError(error);
	}
}

function deleteBattleMesssagesClient(): Promise<any> {
	return http(`battle/purge`);
}

function* deleteBattleMesssagesError(error: any) {
	let errorText: string = S.Battle.FailedToDeleteMessage;
	yield put(DeleteBattleMesssages.failed);

	createErrorMessages(errorText, error);
}

export function* battleSagas() {
	yield all([
		takeLatestForActionType(
			CreateBattle.started,
			createBattleSaga
		),
		takeLatestForActionType(
			EditBattle.started,
			editBattleSaga
		),
		takeLatestForActionType(
			GetBattle.started,
			getBattleSaga
		),
		takeLatestForActionType(
			DeleteBattle.started,
			deleteBattleSaga
		),
		takeLatestForActionType(
			GetBattleList.started,
			getBattleListSaga
		),
		takeLatestForActionType(
			AcceptOrRejectBattle.started,
			acceptOrRejectBattleSaga
		),
		takeLatestForActionType(
			AddBattleMessage.started,
			addBattleMessageSaga
		),
		takeLatestForActionType(
			DeleteBattleMesssages.started,
			deleteBattleMesssagesSaga
		),
	]);
}