import React, { ReactNode } from 'react';
import { ListItem, Icon, ListItemText, Grid, Collapse, Tooltip } from '@material-ui/core';
import { themePalette } from '../../utilities/branding';

interface Props {
	title: string;
	value: string;
	children?: ReactNode;
	expandable?: boolean;
	level?: number;
	secondaryValue?: string;
}
interface State {
	expanded: boolean;
}

class ExpandableListItem extends React.PureComponent<Props, State> {
	static defaultProps = {
		expandable: true,
		level: 0,
	};

	constructor(props: Props) {
		super(props);
		this.state = {
			expanded: false,
		};
	}

	toggleExpand = () => {
		if (this.props.expandable) {
			this.setState(({ expanded }) => ({ expanded: !expanded }));
		}
	};

	render() {
		const { title, value, children, expandable, level = 0, secondaryValue = "" } = this.props;
		const { expanded } = this.state;
		const topLevel = level === 0;

		const valueRender = (
			secondaryValue ? (
				<Grid container alignItems={'center'} direction="row">
					<Grid item xs={3} sm={10}>
					</Grid>
					<Grid item xs={9} sm={2}>
						<Tooltip title={secondaryValue} placement="left" enterTouchDelay={300} leaveTouchDelay={500}>
							<ListItemText
								primary={value}
								style={expanded ? boldFont : {}}
								disableTypography
								/>
						</Tooltip>
					</Grid>
				</Grid>
			) : (
				<ListItemText
					primary={value}
					style={expanded ? boldFont : {}}
					disableTypography
				/>
		));

		return (
			<div key={title}>
				<ListItem
					style={{
						...cardHeaderStyle,
						borderBottomColor: themePalette.divider,
						color: themePalette.sub_text,
						...level !== 0
							? { backgroundColor: `rgba(0, 0, 0, ${level * 0.05})` }
							: {},
					}}
					onClick={this.toggleExpand}
				>
					<Grid container alignItems={'center'} direction="row">
						<Grid item xs={8}>
							<Grid
								container
								style={{ marginLeft: level * 15 }}
								alignItems={'center'}
								direction="row"
							>
								<Grid item xs={3} sm={2}>
									<Grid container alignItems={'center'} justify={'center'}>
										<Grid item>
											{topLevel ? (
												<div style={{
													...grayCircle,
													backgroundColor: themePalette.expandable_item_bubble,
												}} />
											) : (
												expandable &&
												!topLevel && <Icon>{expanded ? 'remove' : 'add'}</Icon>
											)}
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={9} sm={10}>
									<ListItemText
										primary={title}
										style={expanded ? boldFont : {}}
										disableTypography
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={4} style={valueContainerStyle}>
							{valueRender}
						</Grid>
					</Grid>
				</ListItem>
				{expandable && <Collapse in={expanded}>{children}</Collapse>}
			</div>
		);
	}
}

const grayCircle: React.CSSProperties = {
	height: 25,
	width: 25,
	borderRadius: '50%',
};
const cardHeaderStyle: React.CSSProperties = {
	backgroundColor: 'white',
	padding: 10,
	borderBottomWidth: 1,
	borderBottomStyle: 'solid',
	fontFamily: '"Open Sans", sans-serif',
};
const valueContainerStyle: React.CSSProperties = {
	textAlign: 'right',
	whiteSpace: 'nowrap',
};
const boldFont: React.CSSProperties = {
	fontWeight: 'bold',
};

export default ExpandableListItem;
