import { Action as ReduxAction } from 'redux';
import { isType } from 'typescript-fsa';
import { Loaded } from '../utilities/utilities';
import {
	GetCompanyDNCPhoneList,
	AddPhoneToCompanyDNC,
	SetCompanyDNCPageNum,
	ClearPhonesInState,
} from '../actions/dnc_phone_actions';
import _ from 'lodash';
import { ClearCaches } from '../actions/authentication_actions';
import { Logout } from '../actions/authentication_actions';

export interface CompanyDncPhone {
	id: string;
	number: string;
	extension: string;
	type: string;
	isPreferred: boolean;
	isNationalDnc: boolean;
	isCompanyDnc: boolean;
	wasCompanyDncSet: boolean;
	dateCreated: Date;
	dateAddedToCompanyDnc?: Date;
	addedToCompanyDncBy?: string;
	addedToCompanyDncByName?: string;
}

export interface DNCPhoneState {
	companyDncPhones: Loaded<CompanyDncPhone>[];
	isLoading: boolean;
	pageNumber: number;
	pageSize: number;
	morePhonesToLoad: boolean;
	error: any;
}

const initialState = {
	companyDncPhones: [],
	isLoading: false,
	pageNumber: 0,
	pageSize: 25,
	morePhonesToLoad: false,
	error: undefined,
};

export function dncPhoneReducer(
	state: DNCPhoneState = initialState,
	action: ReduxAction
): DNCPhoneState {
	if (isType(action, GetCompanyDNCPhoneList.started)) {
		return {
			...state,
			isLoading: true,
			morePhonesToLoad: false,
			error: undefined,
		};
	} else if (isType(action, GetCompanyDNCPhoneList.done)) {
		let cachedDNCs = state.companyDncPhones.slice();
		const morePhones =
			action.payload.result.length > 0 &&
			action.payload.result.length == state.pageSize;
		const newPageNum = !morePhones ? 0 : state.pageNumber + 1;
		action.payload.result.forEach((phone, index) => {
			const phoneIndex = _.findIndex(cachedDNCs, dnc => {
				return (
					dnc.data &&
					dnc.data.number === phone.number &&
					dnc.data.extension === phone.extension
				);
			});

			if (phoneIndex == -1) {
				let newDNC = {
					data: phone,
					loading: false,
					errors: [],
				};
				cachedDNCs.push(newDNC);
			} else {
				cachedDNCs[phoneIndex].data = phone;
				cachedDNCs[phoneIndex].loading = false;
				cachedDNCs[phoneIndex].errors = [];
			}
		});
		return {
			...state,
			companyDncPhones: cachedDNCs,
			isLoading: false,
			morePhonesToLoad: morePhones,
			pageNumber: newPageNum,
			error: undefined,
		};
	} else if (isType(action, GetCompanyDNCPhoneList.failed)) {
		return {
			...state,
			isLoading: false,
			error: action.payload.error,
			morePhonesToLoad: false,
		};
	} else if (isType(action, AddPhoneToCompanyDNC.started)) {
		return {
			...state,
			isLoading: true,
		};
	} else if (isType(action, AddPhoneToCompanyDNC.done)) {
		return {
			...state,
			isLoading: false,
		};
	} else if (isType(action, AddPhoneToCompanyDNC.failed)) {
		return {
			...state,
			isLoading: false,
		};
	} else if (isType(action, SetCompanyDNCPageNum)) {
		return {
			...state,
			pageNumber: action.payload,
			morePhonesToLoad: false,
			isLoading: false,
		};
	} else if (isType(action, ClearPhonesInState)) {
		return {
			...state,
			companyDncPhones: [],
			morePhonesToLoad: false,
			isLoading: false,
		};
	} else if (isType(action, Logout.started)) {
		return {
			...initialState,
		};
	} else if (isType(action, ClearCaches)) {
		return {
			...initialState,
		};
	} else {
		return state;
	}
}
