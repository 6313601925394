// tslint:disable: align
import React from 'react';
import {
	Table,
	Paper,
	TableRow,
	TableCell,
	TableBody,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TeamViewRow } from '../../reducers/teamview_reducer';
import { TableCardProps } from './teamview_table_card';
import { ReportCard } from '../utility/report_card';

export const ProductivityTableCard: React.FC<TableCardProps> = props => {
    const classes = useStyles();
    const { titleLeft, color = 'primary', rows, bottomText, rowsToHighlight = [], children } = props;

    const getColumnNames = (): TeamViewRow => {
        const columnNames: TeamViewRow = { agentcode: '', results: {}, reports: 0, children: [], terminated: false };
        if (rows.length > 0 && rows[0].results) {
            Object.keys(rows[0].results).forEach(key => {
                columnNames.results[key] = { value: key, delta: 0 };
                columnNames.agentcode = titleLeft + '-header';
            });
        }
        return columnNames;
    };

    const renderRow = (row: TeamViewRow, rowNumber: number, isHeaderRow: boolean = false): JSX.Element => {
		const columns = row.results;
		const id = row.agentcode;
		return (
			<TableRow key={`row_${rowNumber}`}>
				{Object.keys(columns).map((key, index) => (
					<TableCell
						key={`${id}_${index}`}
						className={isHeaderRow ? classes.headerRow : classes.row}
					>
						{columns[key].value}
					</TableCell>
				))}
			</TableRow>
		);
    };

    const isHeader = (index: number): boolean => {
        return rowsToHighlight.indexOf(index) !== -1;
    }

    return  (
        <ReportCard
            color={color}
            titleLeft={titleLeft}
        >
            <Paper style={{ overflowX: 'auto' }}>
                <Table>
                    <TableBody>
                        {renderRow(getColumnNames(), 1, true)}
                        {rows.map((row, index) => {
                            return renderRow(row, index, isHeader(index));
                        })}
                    </TableBody>
                </Table>
                {bottomText && <div className={classes.bottomText}>{bottomText}</div>}
                {children}
            </Paper>
        </ReportCard>
    )
}

const useStyles = makeStyles({
	headerRow: {
		fontWeight: 'bold',
        backgroundColor: 'rgb(222 237 251)',
        whiteSpace: 'nowrap',
	},
	row: {
        backgroundColor: 'white',
        whiteSpace: 'nowrap',
    },
    bottomText: {
        padding: 20,
        textAlign: 'center',
    },
});
