import { Action as ReduxAction } from 'redux';
import { isType } from 'typescript-fsa';
import {
	NavigateBack,
	NavigateTo,
	NavigateToWithoutAddingToHistory,
	UpdateDispositionReturnPath,
	ClearScrollLocation,
	StoreScrollLocation,
} from '../actions/navigation_actions';
import H from 'history';

export interface NavigationState {
	locationHistory: string[];
	reactRouterHistory?: H.History;
	dispositionReturnPath: string;
	scrollToOnBack: string;
}

const initialState: NavigationState = {
	locationHistory: [],
	reactRouterHistory: undefined,
	dispositionReturnPath: '',
	scrollToOnBack: '',
};

export function navigationReducer(
	state: NavigationState = initialState,
	action: ReduxAction
) {
	if (isType(action, NavigateBack)) {
		state.locationHistory.pop();
		return {
			...state,
			locationHistory: state.locationHistory,
			reactRouterHistory: action.payload.history,
		};
	} else if (isType(action, NavigateTo)) {
		const { history } = action.payload;
		state.locationHistory.push(history.location.pathname);

		return {
			...state,
			locationHistory: state.locationHistory,
			reactRouterHistory: history,
		};
	} else if (isType(action, NavigateToWithoutAddingToHistory)) {
		const { history } = action.payload;
		state.locationHistory.pop();
		state.locationHistory.push(history.location.pathname);
		return {
			...state,
			locationHistory: state.locationHistory,
			reactRouterHistory:history,
		};
	} else if (isType(action, StoreScrollLocation)) {
		return {
			...state,
			scrollToOnBack: action.payload,
		};
	} else if (isType(action, ClearScrollLocation)) {
		return {
			...state,
			scrollToOnBack: '',
		};
	} else if (isType(action, UpdateDispositionReturnPath)) {
		return {
			...state,
			dispositionReturnPath: action.payload.returnPath,
		};
	} else {
		return state;
	}
}
