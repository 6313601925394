import React from 'react';
import { Grid } from '@material-ui/core';
import { ReadOnlyTextField } from '../utility/read_only_text_field';
import { wrapInCollapsibleCard } from '../higher_order_component/wrap_in_card';

interface Props {
	carrier: string;
	primaryInsured: string;
	productType: string;
	productName: string;
}
const PolicyGeneral: React.FC<Props> = (props) => {
	return (
		<Grid container>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Carrier"
						value={props.carrier}
						multiline={true}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Primary Insured"
						value={props.primaryInsured}
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Product Type"
						value={props.productType}
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Product Name"
						value={props.productName}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export const PolicyGeneralCard = wrapInCollapsibleCard(PolicyGeneral);

