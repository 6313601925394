import { Grid } from '@material-ui/core';
import React from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import { navigateTo } from '../../actions/navigation_actions';
import { GetAllNotifications, GetNotification, MarkNotificationsAsRead } from '../../actions/notification_actions';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { NavigationProps, navRoutes } from '../../components/nav/Routes';
import { NotificationItem } from '../../components/notifications/notification_item';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import { getNotificationCategoryByKey } from '../../components/notifications/notificationSummary';
import { AppState } from '../../reducers/index';
import { StoreLeadFilters, GetFilteredLeads } from '../../actions/lead_actions';
import { LeadFilters } from '../../reducers/LeadFilterReducer';
import {
	getBulkReassignFilter,
	formatLeadFiltersPayload,
} from '../../utilities/lead_util';
import { themeLinks } from '../../utilities/branding';
import { MoreMenu, createMenuAction, createHelpAction } from '../../components/nav/more_menu';
import { Strings } from '../../assets/common/strings';
import { Dispatch } from 'redux';
import LoadingList from '../../components/utility/loading_list';
import { getNotificationsList } from '../../selectors/notifications_selectors';
import { DesktopPadding } from '../../components/Layout/desktop_padding';
import { EmptyMessage } from '../../components/utility/empty_message';
import { GAService } from '../../utilities/services/google_analytics';

export interface Content {
	base: string;
	extended: string | null;
}

export interface Notification {
	id: string;
	title: string;
	category: string;
	content: Content;
	isUrgent: boolean;
	isRead?: boolean;
	date: string;
	sequence: number;
	isPersistent?: boolean;
	endDate?: string;
	startDate: string;
	isPush?: boolean;
	tags?: string[];
	targetedAgents?: [string];
	userId?: string;
	groupId?: string;
	isDeleted?: boolean;
	senderAgentId?: string;
	senderName?: string;
	history: any;
	url?: string;
	referrerUrl?: string
}

interface StateProps {
	userID: string;
	impersonatingID: string;
	newNotifications: Notification[];
	pageSize: number;
	isLoading: boolean;
}

interface DispatchProps {
	navigateTo: (route: string) => void;
	fetchAllNotifications: (userID: string) => void;
	storeLeadFilters: (filterValues: LeadFilters) => void;
	getFilteredLeads: (filters: LeadFilters) => void;
	getNotificationDetail: (notificationId: string) => void;
	markNotificationsAsRead: (notificationIds: string[], userId: string) => void;
}


type NotificationsPageProps = DispatchProps &
	StateProps &
	NavigationProps;

interface NotificationsState {
	specialTitle: string;
	subTitle: string;
	isUrgentList: boolean;
	lastIndex: number;
	anchorEl?: HTMLElement;
	shouldRefetchNotifications: boolean;
	statefulNotifications: Notification[];
}

const pageSize: number = 10;

export class NotificationsPage extends React.PureComponent<
	NotificationsPageProps,
	NotificationsState
	> {

	constructor(props: NotificationsPageProps) {
		super(props);
		this.state = {
			specialTitle: 'Notifications',
			subTitle: '',
			isUrgentList: false,
			lastIndex: Math.ceil(window.outerHeight / 72),
			shouldRefetchNotifications: true,
			statefulNotifications: []
		};
	}

	showMore = () => {
		setTimeout(() => {
			this.setState(prevState => ({
				lastIndex: prevState.lastIndex + pageSize,
			}));
		}, 200);
	};

	public setStatefulNotifications(userID: string, newNotifications: Notification[]) {

		if (this.state.shouldRefetchNotifications && userID) {
			this.refetchNotifications(userID);
			this.setState({ shouldRefetchNotifications: false });
		} else if (newNotifications) {
			let statefulNotifications = newNotifications;
			this.setState({ statefulNotifications: [] });

			if (
				this.props.match.url.indexOf('notificationsFiltered') > -1 &&
				statefulNotifications.length > 0
			) {
				const category = this.props.match.params.categorySelector;
				const categoryLookup = getNotificationCategoryByKey(category);
				const passedTitle = categoryLookup.display;
				if (!(this.state.specialTitle == passedTitle)) {
					this.setState({
						specialTitle: `${passedTitle}`,
						subTitle: 'Notifications',
					});
				}
				statefulNotifications = statefulNotifications.filter(
					(notification: Notification) =>
						notification.category == category && !notification.isRead
				);
			}

			if (
				this.props.match.url.indexOf('notificationsUrgent') > -1 &&
				statefulNotifications.length > 0
			) {
				if (!this.state.isUrgentList) {
					this.setState({ isUrgentList: true });
				}
				if (!(this.state.specialTitle == 'Urgent')) {
					this.setState({ specialTitle: 'Urgent', subTitle: '' });
				}
				statefulNotifications = statefulNotifications.filter(
					(notification: Notification) =>
						notification.isUrgent == true && notification.isRead == false
				);
			}

			this.setState({ statefulNotifications });
		}
	}

	componentWillReceiveProps(nextProps: NotificationsPageProps) {
		const nextUserId = this.getUserIdToFetchNotificationsFor(nextProps);
		this.setStatefulNotifications(nextUserId, nextProps.newNotifications);
	}

	componentWillMount() {
		const { newNotifications } = this.props;
		const userId = this.getUserIdToFetchNotificationsFor(this.props);
		this.setStatefulNotifications(userId, newNotifications);
	}

	getUserIdToFetchNotificationsFor(props: NotificationsPageProps) {
		const { impersonatingID, userID } = props;
		const userId = impersonatingID || userID || '';
		return userId;
	}

	public refetchNotifications(passedID?: string) {
		const { impersonatingID, userID } = this.props;

		let userIdToFetchNotificationsFor;
		if (passedID) {
			userIdToFetchNotificationsFor = passedID;
		} else if (impersonatingID) {
			userIdToFetchNotificationsFor = impersonatingID;
		} else {
			userIdToFetchNotificationsFor = userID;
		}

		if (userIdToFetchNotificationsFor) {
			this.props.fetchAllNotifications(userIdToFetchNotificationsFor);
		}
	}

	public markAllRead = () => {
		const notificationIds: string[] = [];

		(this.props.newNotifications || [])
			.filter((N: any) => N.isRead == false)
			.forEach((notification: Notification) => {
				notificationIds.push(notification.id);
			});

		this.props.markNotificationsAsRead(notificationIds, this.props.userID);
	};

	navigateToDetail = (notification: Notification) => {
		GAService.sendEvent('Notifications', 'View_Detail');

		if (!notification.isRead) {
			this.props.markNotificationsAsRead([notification.id], this.props.userID);
		}

		const detailURL = navRoutes.notificationListDetail.path.replace(
			Strings.Navigation.NotificationId,
			notification.id
		);
		this.props.navigateTo(detailURL);
	};

	bulkReassignDlClick(url: string) {
		const leadFilters = getBulkReassignFilter(url);
		this.props.storeLeadFilters(leadFilters);
		this.props.getFilteredLeads(leadFilters);
		this.props.navigateTo(navRoutes.leadList.path);
	}

	composeCardList = (notificationsList: Notification[]) => {
		const cardArray: JSX.Element[] = notificationsList.map((notification: Notification) => {
			let dlClick = undefined;
			if (notification.url) {
				if (notification.url.includes('/lead/list?start')) {
					dlClick = this.bulkReassignDlClick.bind(this, notification.url);
				} else {
					dlClick = this.props.navigateTo.bind(this, notification.url);
				}
			}
			return (
				<NotificationItem
					key={notification.id}
					notification={notification}
					onClick={this.navigateToDetail.bind(this, notification)}
					deepLinkOnClick={dlClick}
				/>
			);
		});

		return cardArray;
	};

	public render() {
		const pageTitle =
			this.state.specialTitle.length > 0
				? this.state.specialTitle
				: 'Notifications';

		const newNotificationsList = this.state.statefulNotifications;
		const cardArray: JSX.Element[] = this.composeCardList(newNotificationsList);

		return (
			<BasePageContainer
				topComponent={
					<HeaderBarComponent
						title={pageTitle}
						rightButtons={
							<MoreMenu
								children={[
									createMenuAction('Mark All As Read', this.markAllRead),
									createHelpAction(themeLinks.helpLinkNotifications),
								]}
							/>
						}
						forceOverflow
						isLoading={this.props.isLoading}
					/>
				}
			>
				<PersistentNotifications />
				<DesktopPadding>
					{this.props.isLoading && cardArray.length === 0 
						? <LoadingList />
						: cardArray.length == 0 
							? <EmptyMessage text={'No notifications'}/>
							: cardArray
					}
				</DesktopPadding>
			</BasePageContainer>
		);
	}
}

const mapStateToProps = (state: AppState): StateProps => ({
	userID: state.user.id,
	impersonatingID: state.user.impersonatingId,
	newNotifications: getNotificationsList(state),
	pageSize: state.lead.leadListPageSize,
	isLoading: state.notifications.newLoading || state.notifications.persistentLoading,
});
const mapDispatchToProps = (dispatch: Dispatch, ownProps: NotificationsPageProps): DispatchProps => ({
	navigateTo: (route: string) =>
		dispatch(navigateTo(route)),
	fetchAllNotifications: (userID: string) =>
		dispatch(GetAllNotifications.started(userID)),
	storeLeadFilters: (filterValues: LeadFilters) =>
		dispatch(StoreLeadFilters(filterValues)),
	getNotificationDetail: (notificationId: string) =>
		dispatch(GetNotification.started(notificationId)),
	markNotificationsAsRead: (notificationIds: string[], userId: string) =>
		dispatch(MarkNotificationsAsRead.started({ notificationIds, userId })),
	getFilteredLeads: (leadFilters: LeadFilters) => {
		const payload = formatLeadFiltersPayload(
			leadFilters,
			0,
			ownProps.pageSize
		);
		return dispatch(GetFilteredLeads.started(payload));
	},
});

export const NotificationsPageContainer = connect(mapStateToProps, mapDispatchToProps, true)
	(NotificationsPage);
