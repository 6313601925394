import {actionCreatorFactory} from 'typescript-fsa';
import { Application } from '../reducers/application_reducer';
import { Policy } from '../reducers/policy_reducer';
import { LinkingPolicyPayload } from './policy_actions';

export interface IUnlinkedGuide {
	id: string;
	guide: string;
}

const actionCreator = actionCreatorFactory('Application');

//////////////////// GET APPLICATION BY ID ////////////////////////
export const GetApplication = actionCreator.async<string, Application>(
	'GET_APPLICATION'
);

//////////////////// GET ALL APPLICATIONS ////////////////////////
export const GetAllApplications = actionCreator.async<undefined, Application[]>(
	'GET_ALL_APPLICATIONS'
);

//////////////////// GET ALL UNLINKED APPLICATIONS ////////////////////////
export const GetAllUnlinkedApplications = actionCreator.async<
	IUnlinkedGuide,
	Application[]
>('GET_ALL_UNLINKED_APPLICATIONS');

/////////////////////// CREATE APPLICATION ///////////////////////
export const CreateApplication = actionCreator.async<Application, Application>(
	'CREATE_APPLICATION'
);

/////////////////////// UPDATE APPLICATION ///////////////////////
export const UpdateApplication = actionCreator.async<Application, Application>(
	'UPDATE_APPLICATION'
);

/////////////////////// DELETE APPLICATIONS ///////////////////////
export const DeleteApplication = actionCreator.async<Application, string>(
	'DELETE_APPLICATION'
);

// STORE APPLICATIONS
export const StoreApplications = actionCreator<{
	applications: Application[];
	clearCache?: boolean;
}>('STORE_HOUSEHOLD_APPLICATIONS');

///////// LINK APPLICATION /////////
export const LinkApplication = actionCreator.async<
	LinkingPolicyPayload,
	Policy
>('LINK_APPLICATION');

///////// UNLINK APPLICATION /////////
export const UnlinkApplication = actionCreator.async<
	LinkingPolicyPayload,
	Policy
>('UNLINK_APPLICATION');
