import React from 'react';
import { Card, CardHeader, Grid, Chip, CardContent } from '@material-ui/core';

import { themePalette, themeImages } from '../../utilities/branding';
import { renderLeadCount } from '../../utilities/lead_util';
import { PaperIcon } from '../utility/PaperIcon';
import { enforceStylesType, getColumnSizeForNumOfCols } from '../../utilities/styles_util';
import { sum } from '../../utilities/array_util';

export interface BubbleProps {
    counts: LeadTypeCount[];
    caption: string;
    styles?: React.CSSProperties;
    onClick: () => void;
}

interface LeadTypeCount {
    count: number;
    caption?: string;
    excludeFromTotalCount?: boolean;
}

interface Props {
    title: string;
    bubbles: BubbleProps[];
}

const getTotalCount = (bubbles: Array<BubbleProps>) => 
    sum(bubbles, bubble => 
        sum(bubble.counts, bubbleCount => bubbleCount.excludeFromTotalCount ? 0 : bubbleCount.count)
    );

export const CountTypeCard: React.FC<Props> = (props) => {
    const { title, bubbles } = props;
    const styles = getStyles();

    const totalCount = getTotalCount(bubbles);

    const bubbleXsSetting = bubbles.length < 3 
        ? true 
        : getColumnSizeForNumOfCols(bubbles.length);

    return (
        <Card style={styles.leadCardStyle}>
            <CardHeader
                title={
                    <Grid container style={styles.cardTitle} alignItems="center">
                        <Grid item>
                            <Chip
                                style={styles.cardHeaderChip}
                                label={renderLeadCount(totalCount)}
                            />
                        </Grid>
                        <Grid item>{title}</Grid>
                    </Grid>
                }
            />
            <CardContent style={styles.cardBackground}>
                <Grid container>
                    {bubbles.map(bubble => (
                        <Grid key={bubble.caption} item style={styles.gridItem} xs={bubbleXsSetting}>
                            <PaperIcon
                                content={renderBubbleContent(bubble.counts, bubble.styles || {})}
                                caption={bubble.caption}
                                onClick={bubble.onClick}
                            />
                        </Grid>
                    ))}
                </Grid>
            </CardContent>
        </Card>
    );
}

const renderBubbleContent = (bubbleCounts: LeadTypeCount[], bubbleStyles: React.CSSProperties) => {
    const styles = getStyles();

    if (bubbleCounts.length === 1) {
        const onlyBubbleCount = bubbleCounts[0];
        return renderLeadCount(onlyBubbleCount.count)
    } else if (bubbleCounts.length > 1) {
        return (
            <div style={{...styles.saleMadeCardContentStyle, ...bubbleStyles}}>
                {bubbleCounts.map(bubbleCount => (
                    <div key={`${bubbleCount.count}-${bubbleCount.caption}`}>{renderLeadCount(bubbleCount.count)} {bubbleCount.caption}</div>
                ))}
            </div>
        );
    } else {
        return (<></>);
    }
}

const getStyles = () => enforceStylesType({
    leadCardStyle: {
        margin: '10px',
        paddingBottom: 0,
    },
    gridItem: {
        textAlign: 'center',
    },
    saleMadeCardContentStyle: {
        fontSize: 12,
    },
    cardBackground: {
        padding: '20px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        backgroundColor: themePalette.bubbles_secondary,
        backgroundImage: `url('${themeImages.bubblesSecondary}')`,
    },
    cardTitle: { 
        fontSize: '15px' 
    },
    cardHeaderChip: {
        fontWeight: 'bold',
        background: themePalette.selected_overlay,
        marginRight: 10,
    }
});

