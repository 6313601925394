import React from 'react';
import { reduxForm, StrictFormProps } from 'redux-form';
import { Button } from '@material-ui/core';
import { PersonDemographicFields } from './fields/person_demographic_fields';
import { SearchConfigurationFields } from './fields/search_configuration_fields';
import { ProductFields } from './fields/products_fields';
import { LeadFields } from './fields/lead_fields';
import { EmployerFields } from './fields/employer_fields';
import { SearchFilterFormValues } from '../../reducers/advanced_search_reducer';
import { AgentFields } from './fields/agent_fields';
import jwt_auth from '../../utilities/auth/jwt_auth';
import { themePalette } from '../../utilities/branding';
import { P } from '../../utilities/auth/permissions';
import { getAppState } from '../..';
import { SubagentFields } from './fields/subagent_fields';

export const ADVANCED_SEARCH_FORM_NAME = '_AdvancedSearchFilterForm';


interface Props {
	formValues: SearchFilterFormValues;
	resetForm: () => void;
}

class _AdvancedSearchFilterForm extends React.Component<Props & StrictFormProps<SearchFilterFormValues, Props, {}>>{
	render() {
		let consumerType: string = '';
		if (this.props.formValues && this.props.formValues.consumerType) {
			consumerType = this.props.formValues.consumerType;
		}
		const agentPermission = jwt_auth.hasPermission(P.AdvancedSearchFilter);

		return (
			<div style={{ paddingTop: 10 }}>
				<SearchConfigurationFields
					formName={ADVANCED_SEARCH_FORM_NAME}
					consumerType={consumerType}
					onReset={this.props.reset}
				/>
				<ProductFields formName={ADVANCED_SEARCH_FORM_NAME} />
				<PersonDemographicFields formName={ADVANCED_SEARCH_FORM_NAME} />
				<LeadFields formName={ADVANCED_SEARCH_FORM_NAME} onlyConsumerSearch tagSearch />
				<EmployerFields formName={ADVANCED_SEARCH_FORM_NAME} />
				{agentPermission && <AgentFields formName={ADVANCED_SEARCH_FORM_NAME} consumerType={consumerType} />}
				<SubagentFields formName={ADVANCED_SEARCH_FORM_NAME} />

				<div style={{ padding: '20px' }}>
					<Button
						variant="contained"
						color="secondary"
						onClick={() => {
							this.props.resetForm();
							this.props.reset();
						}}
						fullWidth
					>
						<div style={{ color: themePalette.negative_text }}>Reset</div>
					</Button>
				</div>
			</div>
		);
	}
};


export const AdvancedSearchFilterForm = reduxForm({
	form: ADVANCED_SEARCH_FORM_NAME,
	destroyOnUnmount: false,
	enableReinitialize: true,
	getFormState: () => getAppState().form,
})(_AdvancedSearchFilterForm) as React.ComponentClass<
	Props & Partial<StrictFormProps<SearchFilterFormValues, any, any>>
>;
