import React from 'react';
import ReactPlaceholder from 'react-placeholder';
import { Paper } from '@material-ui/core';
import { themePalette } from '../../utilities/branding';

interface Props {
    size?: number;
    type?: 'text' | 'media' | 'textRow' | 'rect' | 'round';
    style?: React.CSSProperties;
}

const LoadingList: React.FC<Props> = ({ size=6, type='media', style }: Props) => (
    <div>
        {Array.from(Array(size)).map((val, index) => (
            <Paper
                key={index}
                style={{
                    ...styles.spacing,
                    backgroundColor: themePalette.default_background,
                    ...style,
                }}
            >
                <ReactPlaceholder
                    showLoadingAnimation
                    type={type}
                    rows={3}
                    ready={false}
                    children={[]}
                />
            </Paper>
        ))}
    </div>
);

const styles: { [key: string]: React.CSSProperties } = {
    spacing: {
        margin: 15,
        padding: 10,
    },
};

export default LoadingList;
