import { Action as ReduxAction } from 'redux';
import { isType } from 'typescript-fsa';
import {
    GetFilteredLeads,
} from '../actions/lead_actions';
import { navRoutes } from '../components/nav/Routes';
import { Strings } from '../assets/common/strings';
import { mapIgnoreUndefined } from '../utilities/array_util';
import { StoreLeadRoutesIndex, UpdateLeadRoutesFromAdvancedSearch } from '../actions/lead_routes_actions';
import { leadIdIsEmpty } from '../utilities/lead_util';

export interface LeadRoutes {
    routes: string[];
    index: number;
    origin: LeadRoutePages;
}

export enum LeadRoutePages {
    Unknown,
    LeadList = 'LEAD_LIST',
    AdvancedSearch = 'ADV_SEARCH',
};

const initialState: LeadRoutes = {
    routes: [],
    index: -1,
    origin: LeadRoutePages.Unknown,
};

export function leadRoutesReducer(state: LeadRoutes = initialState, action: ReduxAction): LeadRoutes {

    if (isType(action, GetFilteredLeads.done)) {

        let routes: string[] = action.payload.params.pageNum == 0
            ? []
            : state.routes
                ? state.routes
                : [];

        const leads = action.payload.result;
        leads.forEach(function (currentLead) {
            if (currentLead.employer) {
                routes.push(navRoutes.employer.path.replace(Strings.Navigation.EmployerId, currentLead.employer.id));
            } else {
                routes.push(navRoutes.household.path.replace(Strings.Navigation.HouseholdId, currentLead.householdId));
            }
        });
        return {
            ...state,
            routes: routes,
            origin: LeadRoutePages.LeadList,
        }
    } else if (isType(action, StoreLeadRoutesIndex)) {
        return {
            ...state,
            index: action.payload.index,
            origin: action.payload.origin || state.origin,
        }
    } else if (isType(action, UpdateLeadRoutesFromAdvancedSearch.done)) {
        const searchResults = action.payload.result;
        const routes = mapIgnoreUndefined(searchResults, searchResult => {
            if (!leadIdIsEmpty(searchResult.EmployerId)) {
                return navRoutes.employer.path.replace(Strings.Navigation.EmployerId, searchResult.EmployerId);
            } else if (!leadIdIsEmpty(searchResult.HouseholdId)) {
                return navRoutes.household.path.replace(Strings.Navigation.HouseholdId, searchResult.HouseholdId);
            }
        });
        return {
            ...state,
            routes,
            origin: LeadRoutePages.AdvancedSearch,
        };
    } else {
        return state;
    }
}