import React from 'react';
import {
	Divider,
	ListSubheader,
	Button,
	Dialog,
	DialogContent,
	DialogActions,
	DialogContentText,
} from '@material-ui/core';
import { Loaded } from '../../utilities/utilities';
import { Policy, ProductStatus } from '../../reducers/policy_reducer';
import _ from 'lodash';
import {
	Product,
	mapApplicationsToProduct,
	mapPoliciesToProduct,
} from '../../containers/products/product_list_page';
import { Application } from '../../reducers/application_reducer';
import { ProductListItem } from '../product/product_list_item';
import { themePalette } from '../../utilities/branding';
import { wrapInCollapsibleCard } from '../higher_order_component/wrap_in_card';
import CustomList from '../utility/custom_list';

interface HouseholdProductListProps {
	applications: Loaded<Application>[];
	policies: Loaded<Policy>[];
	handleApplicationDeleteClick: (applicationToDelete: Application) => void;
	handleProductClick: (
		productId: string,
		policyOrApp: 'application' | 'policy'
	) => void;
}

interface HouseholdProductListState {
	products: Loaded<Product>[];
	showDeleteConfirmation: boolean;
	focusedApp?: Application;
	anchorEl?: HTMLElement;
}

class ProductList extends React.Component<
	HouseholdProductListProps,
	HouseholdProductListState
	> {
	constructor(props: HouseholdProductListProps) {
		super(props);
		const mappedApplications = mapApplicationsToProduct(props.applications);
		const mappedPolicies = mapPoliciesToProduct(
			props.policies,
			props.applications
		);
		this.state = {
			showDeleteConfirmation: false,
			products: _.concat(mappedApplications, mappedPolicies),
		};
	}

	componentWillReceiveProps(nextProps: HouseholdProductListProps) {
		const mappedApplications = mapApplicationsToProduct(nextProps.applications);
		const mappedPolicies = mapPoliciesToProduct(
			nextProps.policies,
			nextProps.applications
		);
		this.setState({
			products: _.concat(mappedApplications, mappedPolicies),
		});
	}

	handleDeleteClick = (product: Application) => {
		this.setState({
			showDeleteConfirmation: true,
			focusedApp: product,
		});
	};

	handleDismiss = () => {
		this.setState({ showDeleteConfirmation: false });
	};

	handleClose = () => {
		this.setState({ showDeleteConfirmation: false });
	};

	deleteAndCloseDialog = () => {
		this.setState({ showDeleteConfirmation: false });
		if (this.state.focusedApp)
			this.props.handleApplicationDeleteClick(this.state.focusedApp);
	};

	filterProductByStatus(status: ProductStatus): Loaded<Product>[] {
		return this.state.products
			.filter(product => {
				return product.data.status == status;
			})
			.sort((a, b) => {
				if (a.data.productType === null) {
					return -1;
				} else if (b.data.productType === null) {
					return 1;
				} else if (a.data.productType > b.data.productType) {
					return 1;
				} else if (a.data.productType < b.data.productType) {
					return -1;
				} else if (a.data.carrier > b.data.carrier) {
					return 1;
				} else if (a.data.carrier < b.data.carrier) {
					return -1;
				} else {
					return 0;
				}
			});
	}

	composeProductListByStatus = (status: ProductStatus) => {
		const products = this.filterProductByStatus(status);
		const productListItems = products.map((product, index) => (
			<div key={ProductStatus[status] + index}>
				<ProductListItem
					{...product}
					onClick={this.props.handleProductClick}
				/>
				{index < products.length - 1 ? <Divider /> : ''}
			</div>
		));
		const subheader =
			productListItems.length > 0
				? ProductStatus[status]
				: `No ${ProductStatus[status]} Products`;
		const list = [
			<ListSubheader
				disableSticky
				key={ProductStatus[status].concat('Subheader')}
			>
				{subheader}
			</ListSubheader>,
		];
		return list.concat(productListItems);
	};

	renderDeleteConfirmationDialogue = () => {
		return (
			<Dialog
				open={this.state.showDeleteConfirmation}
				onClose={this.handleDismiss}
			>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to delete the application?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button color="secondary" onClick={this.handleDismiss}>
						Cancel
					</Button>
					<Button
						color="primary"
						variant="contained"
						onClick={this.deleteAndCloseDialog}
						style={{ backgroundColor: themePalette.delete_remove_reject_button }}
					>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	render() {
		return (
			<CustomList>
				{this.composeProductListByStatus(ProductStatus.Pending)}
				{this.composeProductListByStatus(ProductStatus.Active)}
				{this.composeProductListByStatus(ProductStatus.Terminated)}
				{this.renderDeleteConfirmationDialogue()}
			</CustomList>
		);
	}
}

export const ProductListCard = wrapInCollapsibleCard(ProductList);
