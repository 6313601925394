import React from 'react';
import { IconType, SvgIcon } from '../components/svgs/svg_icon';

export const AttributionList = [
	{
		icon: <SvgIcon type={IconType.CommissionStatement} />,
		title: (
			<>
				Commission icon by Vectors Market from{' '}
				<a
					href="https://thenounproject.com/browse/icons/term/commission/"
					target="_blank"
					title="commission Icons">
					Noun Project
				</a>{' '}
				(CC BY 3.0)
			</>
		),
	},
];
