import React, { PureComponent } from 'react';
import { Icon, IconButton } from '@material-ui/core';
import { Contact } from '../../../reducers/ContactReducer';
import { composeEmailMetaData, getPhoneEmailIconStyle, sendEmail, IconOptions } from '../../../utilities/phone_email_util';
import { SelectContactDialog } from '../../../containers/dialogs/select_contact_dialog';
import { Strings } from '../../../assets/common/strings';


export enum EmailSequenceStep {
	Inactive,
	SelectContact,
	SelectTemplate,
}

interface Props {
	contacts: Contact[];
	iconOptions?: IconOptions;
}

interface State {
	step: EmailSequenceStep;
}
/**
 * Component to handle the user interaction of clicking and completing a phone call.
 */
export class EmailActionIcon extends PureComponent<Props, State> {

	state = {
		step: EmailSequenceStep.Inactive,
	};

	handleCancel = () => this.setState({ step: EmailSequenceStep.Inactive });

	handleSelectContact = (selection: { id: string, value: string }[]) => {
		const email = selection.length ? selection[0] : { id: '', value: '' };
		sendEmail(email.value);
		this.handleCancel();
	}

	onIconClick = () => {
		this.setState({ step: EmailSequenceStep.SelectContact });
	}

	renderDialog(step: EmailSequenceStep) {
		switch (step) {
			case (EmailSequenceStep.SelectContact):
				return <SelectContactDialog
					key={'email-contact-selection-dialog'}
					activityType={Strings.Activity.Email}
					handleClose={this.handleCancel}
					handleSelection={this.handleSelectContact}
					contacts={this.props.contacts}
					open
				/>
			default: return <div />
		}
	}

	render() {
		const emailMeta = composeEmailMetaData(this.props.contacts || []);
		const { iconOptions } = this.props;
		const iconClass = emailMeta.doNotContact ? 'icon-email-dnc-button' : 'icon-email-contact-button'
		const disabled = !emailMeta.emails.length;
		const iconStyle = getPhoneEmailIconStyle(iconOptions && iconOptions.style, disabled);


		const onClick = () => !!emailMeta.emails.length && this.onIconClick();
		const icon = (iconOptions && iconOptions.overrideIcon)
			? (
				<Icon
					style={iconStyle}
					onClick={onClick}
				>
					{iconOptions.overrideIcon}
				</Icon>
			)
			: (
				<Icon
					onClick={onClick}
					className={iconClass}
					style={iconStyle}
				/>
			);

		return (
			<div style={{ textAlign: 'center' }}>
				{(iconOptions && iconOptions.hidden)
					? <span />
					: <IconButton disabled={disabled} style={iconStyle} onClick={onClick}>{icon}</IconButton>
				}
				{this.renderDialog(this.state.step)}
			</div>
		);
	}
}