import React, { useState } from 'react';
import {
	Grid,
	TextField,
	FormGroup,
	FormControlLabel,
	Switch,
	MenuItem
} from '@material-ui/core';
import { DocumentForm } from '../../reducers/document_reducer';
import { ListFilter } from '../../components/filter/list_filter';

interface Props {
	searchDocuments: (filter: DocumentForm) => void;
	filterForm: DocumentForm;
	categoryList: string[];
	lobList: string[];
}

const DocumentsFilter: React.FC<Props> = (props) => {

	const { lobList, categoryList, filterForm, searchDocuments } = props;

	const [editForm, setEditForm] = useState(filterForm);

	const searchDocs = () => {
		searchDocuments(editForm);
	};

	const changeSearch = (event) => {
		setEditForm({
			...editForm,
			searchText: event.currentTarget.value
		});
	};

	const changeCategory = (event) => {
		setEditForm({
			...editForm,
			category: event.target.value
		});
	};

	const changeLob = (event) => {
		setEditForm({
			...editForm,
			lineOfBusiness: event.target.value
		});
	};

	const toggleFavorites = (event: any, onlyFavorites: boolean) => {
		setEditForm({
			...editForm,
			onlyFavorites
		});
	};

	const { searchText, category, lineOfBusiness, onlyFavorites } = editForm;
	return (
		<ListFilter 
			drawerTitle={'Documents'}
			onSearch={searchDocs}
			cardSubtitle={`${searchText ? `${searchText} - ` : ''}${category} - ${lineOfBusiness}`}
			drawerSubtitle={`${category} - ${lineOfBusiness}`}
		>
			<Grid container alignContent="center" spacing={2}>
				<Grid item xs={12}>
					<TextField
						fullWidth
						label="Search"
						value={searchText}
						onChange={changeSearch}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						select
						label="Category"
						onChange={changeCategory}
						value={category}
						fullWidth
					>
						{categoryList.map((category: string) => (
							<MenuItem key={category} value={category}>
								{category}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid item xs={12}>
					<TextField
						select
						label="Line of Business"
						onChange={changeLob}
						value={lineOfBusiness}
						fullWidth
					>
						{lobList.map((lob: string) => (
							<MenuItem key={lob} value={lob}>
								{lob}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid item xs={12}>
					<FormGroup>
						<FormControlLabel
							control={
								<Switch
									checked={onlyFavorites}
									onChange={toggleFavorites}
								/>
							}
							label="Show only favorites"
						/>
					</FormGroup>
				</Grid>
			</Grid>
		</ListFilter>
	);
}

export default DocumentsFilter;
