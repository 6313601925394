import { Grid, IconButton, Icon } from '@material-ui/core';
import React from 'react';
import { Contact } from '../../reducers/ContactReducer';
import { openMaps } from '../../utilities/maps';
import { getHeaderLocationToDisplay } from '../../utilities/utilities';
import { themePalette } from '../../utilities/branding';
import { HeaderBarComponent } from './HeaderBar';
import { EmailActionIcon } from './contact_header_bar/email_action_icon';
import { CallSequence } from '../../containers/dialogs/sequence/call_sequence';
import { getBaseIconStyle } from '../../utilities/styles_util';
import { SmsSequence } from '../../containers/dialogs/sequence/sms_sequence';
import { isMobileDevice } from '../../utilities/is_mobile';

interface Props {
	title: string;
	subtitles?: string[];
	contacts: Contact[];
	primaryContact: Contact;
	rightButtons?: JSX.Element | JSX.Element[];
	isLoading?: boolean;
	hideTopRow?: boolean;
	goToDetails?: () => void;
	employerId?: string;
	householdId: string;
	navigateNextEnabled?: boolean;
	navigatePreviousEnabled?: boolean;
	navigateToLead?: (navigateForward: boolean) => void;
	navigateTo: (route: string) => void;
	hasActiveBusinessRelationship?: boolean;
}

export class ContactHeaderBar extends React.Component<Props> {

	renderCustomContent() {
		let headerStyle: React.CSSProperties = {
			textAlign: 'center',
			wordWrap: 'break-word',
			wordBreak: 'break-all',
			color: themePalette.negative_text,
			fontSize: '14px',
			fontWeight: 'bold',
			marginBottom: '4px',
			cursor: 'pointer',
		}

		return (
			<>
				<Grid container spacing={0}>
					<Grid item xs={12} md={12} lg={12} style={{
						textAlign: 'center',
					}}>
						<div style={{
							marginTop: this.props.hideTopRow 
								? 20 
								: isMobileDevice
									? -40
									: -44,
							paddingLeft: '20px',
							paddingRight: '20px',
							textAlign: 'center',
							wordWrap: 'break-word',
							wordBreak: 'break-all',
							color: themePalette.negative_text,
							fontSize: '18px',
						}}>{this.props.title}</div>
						{this.props.subtitles ? (
							<div
								style={{
									display: 'inline-block',
									marginTop: 10
								}}
								onClick={() =>
									this.props.subtitles &&
									openMaps(`${this.props.subtitles[1]} ${this.props.subtitles[2]}`)
								}
							>
								{this.props.subtitles.map((subtitle, index) => (
									<div
										key={'headerSubtitle-' + index}
										style={headerStyle}
									>
										{subtitle}
									</div>
								))}
							</div>
						) : (
								<div
									key={'headerSubtitle'}
									style={headerStyle}
									onClick={() =>
										openMaps(
											getHeaderLocationToDisplay(this.props.primaryContact.addresses)
										)
									}
								>
									{getHeaderLocationToDisplay(this.props.primaryContact.addresses)}
								</div>
							)}
					</Grid>
				</Grid>

				<Grid container spacing={0} style={{
					display: 'flex',
					alignItems: 'center',
					height: 60
				}}>
					<Grid item xs style={{
						textAlign: 'center',
						height: 60
					}}>
						{this.props.navigatePreviousEnabled
							&& <IconButton
								onClick={() => this.props.navigateToLead ? this.props.navigateToLead(false) : undefined}
								style={getBaseIconStyle(themePalette.negative_icon)}
							>
								<Icon>
									arrow_back
								</Icon>
							</IconButton>
						}
					</Grid>
					<Grid item xs style={{
						textAlign: 'center',
						height: 60
					}}>
						<CallSequence
							contacts={this.props.contacts}
							householdId={this.props.householdId}
							employerId={this.props.employerId}
							iconOptions={
								{
									style: { color: themePalette.negative_icon }
								}
							}
							navigateTo={this.props.navigateTo}
							displayIcon
						/>
					</Grid>
					<Grid item xs style={{
						textAlign: 'center',
						height: 60
					}}>
						<SmsSequence
							contacts={this.props.contacts}
							iconOptions={
								{
									style: { color: themePalette.negative_icon }
								}
							}
							displayIcon
						/>
					</Grid>
					<Grid item xs style={{
						textAlign: 'center',
						height: 60
					}}>
						<EmailActionIcon
							contacts={this.props.contacts}
							iconOptions={
								{
									style: { color: themePalette.negative_icon }
								}
							} />
					</Grid>
					{this.props.goToDetails
						? <Grid item xs style={{
							textAlign: 'center',
							height: 60
						}}>
							<div style={{ textAlign: 'center' }}>
								<IconButton onClick={this.props.goToDetails} style={getBaseIconStyle(themePalette.negative_icon)}>
									<Icon
										className={'icon-arrowForward'}
										style={{
											height: 30,
											width: 30,
											fontSize: 30,
										}}
									/>
								</IconButton>
							</div>
						</Grid>
						: undefined}
					<Grid item xs style={{
						textAlign: 'center',
						height: 60
					}}>
						{this.props.navigateNextEnabled
							?
							<IconButton
								onClick={() => this.props.navigateToLead ? this.props.navigateToLead(true) : undefined}
								style={getBaseIconStyle(themePalette.negative_icon)}
							>
								<Icon>
									arrow_forward
								</Icon>
							</IconButton>
							: undefined}
					</Grid>

				</Grid>
			</>
		);
	}

	render() {
		return (
			<HeaderBarComponent
				rightButtons={this.props.rightButtons}
				customContent={this.renderCustomContent()}
				isLoading={this.props.isLoading}
				hideTopRow={this.props.hideTopRow}
			/>
		);
	}
}
