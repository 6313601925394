import React from 'react';
import { defaultSvgProps, SvgProps } from '..';

const PerformanceSvg: React.FC<SvgProps> = (props) => (
    <svg viewBox="0 0 35 35" {...props}>
        <path
            d="M17.54 29.69a11.31 11.31 0 0 1-.76-22.59.46.46 0 0 1 .38.14.48.48 0 0 1 .16.36v11h11a.48.48 0 0 1 .36.16.52.52 0 0 1 .14.37 11.33 11.33 0 0 1-11.28 10.56zM16.32 8.15A10.31 10.31 0 1 0 27.77 19.6H16.82a.5.5 0 0 1-.5-.5z"
        />
        <path
            d="M27.8 16.89h-8.09a.51.51 0 0 1-.5-.5V8.3a.51.51 0 0 1 .5-.5 8.6 8.6 0 0 1 8.59 8.59.51.51 0 0 1-.5.5zm-7.59-1h7.07a7.59 7.59 0 0 0-7.07-7.07z"
        />
    </svg>
);
PerformanceSvg.defaultProps = defaultSvgProps;

export default PerformanceSvg;
