import { useDispatch, useSelector } from '@optum-uhone-hmkts/rise';
import { Link, Paper } from '@material-ui/core';
import React from 'react';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { DataTable, TableHeader } from '../../components/Layout/table/data_table';
import { makeCall } from '../../utilities/phone_email_util';
import { getReshopResultsByAgentId, getReshopSettings } from '../../actions/reshop_actions';
import { mapReshopSummariesForDisplay, ReshopSummary, ReshopSummaryDisplay } from '../../utilities/reshop_util';
import { navRoutes } from '../../components/nav/Routes';
import { Strings } from '../../assets/common/strings';
import { reshopSummaryViewFunction } from '../../components/reshop/reshop_summary_view';
import { formatPhone } from '../../assets/common/string_builders';
import { useNavigationProps } from '../../utilities/hooks';
import { RecommendationActionArea } from '../../components/reshop/recommendation_action_area';
import { ReshopSettings } from '../../components/reshop/reshop_settings';
import { RecommendationStatusType } from '../../components/reshop/models/reshop_enums';
import { ReshopResultsRequest } from '../../components/reshop/models/reshop_requests';
import { ReshopResultsResponse } from '../../components/reshop/models/reshop_responses';
import { AgentEnrollmentSettings } from '../../components/reshop/models/table_data';

export const ManageReshopping: React.FC = () => {
	const { navigateTo } = useNavigationProps();
	const dispatch = useDispatch();
	const agentId = useSelector<any, string>((state) => state.App.agent.id);
	const reshopSettings = useSelector<any, AgentEnrollmentSettings>(
		(state) => state.App.reshop.agentEnrollmentSettings
	);
	const lastResultsCall = useSelector<any, ReshopResultsResponse>((state) => state.App.reshop.reshopResults);
	const isLoading = useSelector<any, boolean>((state) => state.App.reshop.isLoading);
	const reshopSummaries = useSelector<any, ReshopSummary[]>((state) => state.App.reshop.reshopSummaries);
	const [displaySummaries, setDisplaySummaries] = React.useState<ReshopSummaryDisplay[]>([]);
	const initialPage = 0;
	const initialPageSize = 20;

	const headers: TableHeader<ReshopSummaryDisplay>[] = [
		{
			label: 'Member',
			key: 'contactName',
			fieldType: 'custom',
			renderCustom: (data: ReshopSummaryDisplay) => (
				<Link component="button" onClick={() => goToHousehold(data)}>
					{data.contactName}
				</Link>
			),
		},
		{
			label: 'Phone',
			key: 'contactPhone',
			fieldType: 'custom',
			renderCustom: (data: ReshopSummaryDisplay) => (
				<Link component="button" onClick={() => callNumber(data)}>
					{formatPhone(data.contactPhone)}
				</Link>
			),
		},
		{ label: 'Shopping For', key: 'recommendedProductTypes', fieldType: 'readonly' },
		{ label: 'Created On', key: 'dateCreated', fieldType: 'readonly' },
		{
			label: '',
			key: 'action',
			fieldType: 'custom',
			renderCustom: (recommendation) => <RecommendationActionArea recommendation={recommendation} />,
		},
	];

	const getReshopResults = (
		page = initialPage,
		pageSize = initialPageSize,
		statusType = RecommendationStatusType.NeedsReview
	) => {
		const requestOptions: ReshopResultsRequest = {
			agentId,
			statusType,
			page,
			pageSize,
		};
		dispatch(getReshopResultsByAgentId.started(requestOptions));
	};

	const goToHousehold = (data: ReshopSummaryDisplay) => {
		navigateTo(navRoutes.household.path.replace(Strings.Navigation.HouseholdId, data.reshopResults.householdId));
	};
	const callNumber = (data: ReshopSummaryDisplay) => {
		makeCall(data.contactPhone, true);
	};

	React.useEffect(() => {
		// get reshop settings
		if (agentId) {
			dispatch(getReshopSettings.started(agentId));
		}
	}, [agentId]);

	React.useEffect(() => {
		// get reshop results
		if (reshopSettings.agentId && reshopSettings.isEnrolled) {
			getReshopResults();
		} else {
			setDisplaySummaries([]);
		}
	}, [reshopSettings]);

	React.useMemo(() => {
		// set reshop results
		setDisplaySummaries(
			mapReshopSummariesForDisplay(reshopSummaries.filter((summary) => !summary.reshopResults.isAgentReviewed))
		);
	}, [reshopSummaries]);

	return (
		<BasePageContainer
			topComponent={<HeaderBarComponent title="Manage Re-Enrollment" isLoading={isLoading} />}
			middleComponent={
				<>
					{!isLoading && (
						<>
							{agentId !== '' && <ReshopSettings agentId={agentId} settings={reshopSettings} />}

							{/* TODO: Filter bar */}

							{displaySummaries.length !== 0 && (
								<Paper elevation={0}>
									<DataTable
										name={'MANAGE_RESHOPPING'}
										headers={headers}
										data={displaySummaries}
										total={lastResultsCall.totalCount}
										expandableRows
										renderExpandedRow={reshopSummaryViewFunction}
										updateData={(pagingOptions) =>
											getReshopResults(pagingOptions.pageNum, pagingOptions.pageSize)
										}
									/>
								</Paper>
							)}
						</>
					)}
				</>
			}
		/>
	);
};
