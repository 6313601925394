import React from 'react';
import { Loaded } from '../../utilities/utilities';
import { extractLeadInfo } from '../../utilities/lead_util';
import { Lead } from '../../reducers/LeadReducer';
import { themePalette } from '../../utilities/branding';
import { Icon, IconButton } from '@material-ui/core';
import { LineOfBusinessAvatar } from '../line_of_business/line_of_business_avatar';
import { displayDate } from '../../utilities/date_util';
import { isDesktop, isMobileDevice } from '../../utilities/is_mobile';
import { SubtitleProps, CustomListItem } from '../utility/custom_list_item';
import { normalizePhone } from '../../utilities/formatting/data_normalizations';
import { CallSequenceIcon } from '../../containers/dialogs/sequence/call_sequence';
import { enforceStylesType } from '../../utilities/styles_util';

interface Props extends Loaded<Lead> {
	iconClick: (lead: Lead) => void;
	onClick: (lead: Lead) => void;
	setLead: (lead: Lead) => void;
	isSelected?: boolean;
	multiSelect?: boolean;
}
export const LeadListItem: React.FC<Props> = (props: Props) => {

	const lead = props.data;
	const leadInfo = extractLeadInfo(lead);
	const onDesktop = isDesktop();

	let phoneNumbers = [] as JSX.Element[],
		multiplePhones = false;

	const contact = leadInfo.contact;
	if (onDesktop) {
		if (contact && contact.phones) {
			const firstTwo = contact.phones.slice(0, 2);
			multiplePhones = contact.phones.length > 2;
			phoneNumbers = firstTwo.map((phone, index) => <div key={`${index}-${phone.id}`}>
				{normalizePhone(phone.number)}
			</div>);
		}
	}

	const subtitles: SubtitleProps[] = [
		{ label: 'Lead Created:', value: displayDate(lead.createdOn) },
		{ label: 'Lead Modified:', value: displayDate(lead.updatedOn) },
		{
			label: 'Calls attempted:', value: lead.callCounter
				? lead.callCounter.toString()
				: '0',
		},
	];

	const rightSubtitles: SubtitleProps[] = [
		{ value: leadInfo.statusGroup.label },
		{ value: leadInfo.status.label },
		{ value: displayCarrierAvatars(leadInfo.linesOfBusiness) },
	];

	if (phoneNumbers) rightSubtitles.push({ value: phoneNumbers });
	if (multiplePhones) rightSubtitles.push({ value: <div>...</div> });

	const key = 'lead-list-item' + lead.id;
	const backgroundColor: string = props.multiSelect && props.isSelected
		? themePalette.selected_item
		: themePalette.unselected_item;
	const iconStyles = styles(backgroundColor);

	const iconClick = () => props.iconClick(lead);

	return (
		<CustomListItem
			key={key}
			id={key}
			header={leadInfo.headerName}
			subtitles={subtitles}
			rightSubtitles={rightSubtitles}
			onClick={() => props.onClick(lead)}
			skipStoreScrollLocation={props.multiSelect}
			leftActionElement={
				props.multiSelect
					? (
						<IconButton style={iconStyles.multiSelect} onClick={iconClick}>
							<Icon style={iconStyles.check}>check</Icon>
						</IconButton>
					)
					: (
						<IconButton onClick={iconClick}>
							<Icon style={iconStyles.moreInfo}>info_outline</Icon>
						</IconButton>
					)
			}
			rightActionElement={
				<CallSequenceIcon
					contacts={lead.contacts}
					householdId={lead.householdId}
					handleClick={() => props.setLead(lead)}
					employerId={lead.employer && lead.employer.id}
					iconOptions={{
						overrideIcon: "phone",
						hidden: !phoneNumbers || !phoneNumbers.length,
						style: iconStyles.iconOption
					}}
				/>
			}
			primaryColumnSize={!onDesktop ? 7 : 6}
			rightColumnSize={!onDesktop ? 0 : 1}
		/>
	);
}

const displayCarrierAvatars = (carriers: string[]): JSX.Element[] => {
	return carriers.map((carrier: string) => {
		let shortString: string = '~';
		if (carrier) {
			shortString = carrier.slice(0, 1);
		}
		return (
			<LineOfBusinessAvatar
				key={`lob-${carrier}`}
				size={20}
				lineOfBusinessShortString={shortString}
				displayInline={true}
				bubbleColor={themePalette.lob_avatar}
			/>
		);
	});
};

const styles = (backgroundColor?: string) => enforceStylesType({
	moreInfo: {
		height: 25,
		width: 25,
		margin: 'auto',
		display: 'block',
		color: themePalette.tertiary_text,
	},
	check: {
		color: themePalette.negative_text
	},
	iconOption: {
		height: 25,
		width: 25,
		fontSize: 25,
		color: themePalette.tertiary_text,
	},
	multiSelect: {
		backgroundColor,
		height: isMobileDevice ? 25 : 35,
		width: isMobileDevice ? 25 : 35
	}
});