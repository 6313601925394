import React from 'react';
import { reduxForm } from 'redux-form';
import { Typography } from '@material-ui/core';
import { BasicInfoFields, BasicInfoFieldsData } from './basic_info_fields';
import {
	LineOfBusinessFields,
	LineOfBusinessFieldsData,
} from './line_of_business_fields';
import { ContactFields, ContactFieldsData } from './contact_fields';
import { VendorFields, VendorFieldsData } from './vendor_fields';
import { createRequiredLabel } from '../../components/utility/required_label';
import { getAppState } from '../..';

export const FORM_NAME = 'UpsertLeadForm';
export type FormData = BasicInfoFieldsData &
	LineOfBusinessFieldsData &
	ContactFieldsData &
	VendorFieldsData;

interface Props {
	// onUpdate: (pristine: boolean, invalid: boolean, submitting: boolean) => void;
	mode?: string;
	syncronErrors?: any;
	hasFund: boolean;
	inputSource: string;
}

interface State {
	isFormInvalid: boolean;
}

class UpsertLeadForm extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = {
			isFormInvalid: true,
		};
	}

	componentWillReceiveProps(nextProps) {
		nextProps.onUpdate(
			nextProps.pristine,
			nextProps.invalid,
			nextProps.submitting
		);
		this.setState({
			isFormInvalid: nextProps.invalid,
		});
	}

	render() {
		const contactFields: JSX.Element = (
			<div>
				<BasicInfoFields formName={FORM_NAME} />
				<ContactFields
					formName={FORM_NAME}
					syncronErrors={this.props.syncronErrors}
				/>
			</div>
		);
		const leadFields: JSX.Element = (
			<div>
				<Typography
					variant="h5"
					style={{ paddingLeft: 16, paddingTop: 16 }}
				>
					{createRequiredLabel(`Interested in...`)}
				</Typography>
				<Typography variant="body1" style={{ padding: '0px 16px' }}>
					Select the product types the customer is interested in
				</Typography>
				<LineOfBusinessFields formName={FORM_NAME} />
				<VendorFields 
					isManual={this.props.inputSource !== 'LeadConnect'} 
					hasFund={this.props.hasFund} 
				/>
			</div>
		);
		const showContactFields = !(
			this.props.mode == 'newLeadExistingHousehold' ||
			this.props.mode == 'editLead'
		);

		return (
			<form>
				{showContactFields ? contactFields : undefined}
				{leadFields}
			</form>
		);
	}
}

export const UpsertLeadFormComponent = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
	getFormState: (app) => getAppState().form
})(UpsertLeadForm);
