import React from 'react';
import { TextField } from '@material-ui/core';
import { Grid } from '@optum-uhone-hmkts/rise';
import { ReduxFormsFieldProps, useReduxFormFieldProps } from './use_redux_form_field_props';

export const FormSelect: React.FC<ReduxFormsFieldProps> = (ownProps) => {

    const { gridSize, input, componentProps, ...props } = useReduxFormFieldProps(ownProps);

    // This is a controlled Select component to remove the delay on mobile taps
    const [open, setOpen] = React.useState(false);
    const onClose = (e: React.ChangeEvent<{}>) => {
        e.stopPropagation();
        if (!componentProps.disabled) {
            setOpen(false);
        }
    };
    const onOpen = () => {
        if (!componentProps.disabled) {
            setOpen(true);
        }
    }

    return (
        <Grid
            gridSize={gridSize}
        >
            <TextField
                {...componentProps}
                {...props}
                {...input}
                select
                fullWidth
                SelectProps={{
                    open,
                    onClose,
                    onClick: onOpen,
                }}
            />
        </Grid>

    )
}
