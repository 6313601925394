import React from 'react';
import { Employer } from '../../reducers/employer_reducer';
import { SearchResult } from '../utility/search_result';
import { composeAddressFromContact, getEmployerPrimaryContact } from '../../utilities/contact_util';

interface Props {
	employer: Employer;
	selected: boolean;
	onSelectEmployer: (employer: Employer) => void;
}
export const EmployerSearchResult: React.FC<Props> = (props) => {

	const { employer, selected, onSelectEmployer } = props;
	const onClick = () => onSelectEmployer(employer);
	const primaryContact = getEmployerPrimaryContact(employer);

	return primaryContact
		? (
			<SearchResult
				isSelected={selected}
				primaryText={`${employer.companyName}`}
				secondaryText={composeAddressFromContact(primaryContact)}
				onClick={onClick}
			/>
		)
		: null;
};