import { Button } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { updateReshopRecommendationStatus } from '../../actions/reshop_actions';
import { Strings } from '../../assets/common/strings';
import { AcceptRejectDialog } from '../../containers/dialogs/agent_review_dialog';
import { ReshopSummaryDisplay } from '../../utilities/reshop_util';

interface RecommendationActionAreaProps {
	recommendation: ReshopSummaryDisplay;
}

const RecommendationActionArea: React.FC<RecommendationActionAreaProps> = ({ recommendation }) => {
	const dispatch = useDispatch();
	const [open, setOpen] = React.useState(false);
	const [shouldAccept, setShouldAccept] = React.useState(false);

	const handleConfirmation = () => {
		dispatch(
			updateReshopRecommendationStatus.started({
				reshopId: recommendation.reshopResults.reShopId,
				isAccepted: shouldAccept,
			})
		);
	};

	return (
		<>
			<Button
				variant="outlined"
				color="secondary"
				onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
					setShouldAccept(true);
					setOpen(true);
				}}
				style={{ marginRight: 10 }}>
				{Strings.ReShop.Accept}
			</Button>
			<Button
				variant="outlined"
				color="primary"
				onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
					setShouldAccept(false);
					setOpen(true);
				}}>
				{Strings.ReShop.Reject}
			</Button>
			<AcceptRejectDialog
				isOpen={open}
				handleClose={() => setOpen(false)}
				handleSubmit={() => handleConfirmation()}
				isAccepted={shouldAccept}
				customerName={recommendation.contactName}
			/>
		</>
	);
};

export { RecommendationActionArea };
