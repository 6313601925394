export enum ReShopDecision {
	Keep = 'Keep',
	Switch = 'Switch',
	Exclude = 'Exclude',
	NoPlan = 'NoPlan',
	FitScoreNeeded = 'FitScoreNeeded',
	Undetermined = '',
}

export enum RecommendationStatusType {
	NeedsReview,
	Accepted,
	Rejected,
}
