import React from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import { DatePicker } from '../redux_form_material';
import moment, { Moment } from 'moment';
import { GraphDataFilterValues } from '../../reducers/performance_reducer';
import { Strings } from '../../assets/common/strings';
import { validateDateString } from '../../utilities/form_validation';
import { FormSelect, FormSwitch } from '../../utilities/forms';
import { getAppState } from '../..';

export const GRAPH_DATA_FORM = 'GraphDataFilterForm';

interface DateFormProps {
	formValues: GraphDataFilterValues;
	showLineOfBusinessField?: boolean;
	showComparisonField?: boolean;
}

const DateForm: React.FC<DateFormProps> = (props) => {

	const { formValues, showLineOfBusinessField, showComparisonField } = props;
	const showDatePicker = formValues ? formValues.dateRange === 'Custom Date Range' : false;
	return (
		<Grid container spacing={2}>
			<Grid container item xs={12}>
				<Grid item xs={12}>
					<Field name="dateRange" component={FormSelect} fullWidth>
						<MenuItem value={Strings.DateSelector.Last7Days}>{Strings.DateSelector.Last7Days}</MenuItem>
						<MenuItem value={Strings.DateSelector.Last30Days}>{Strings.DateSelector.Last30Days}</MenuItem>
						<MenuItem value={Strings.DateSelector.Last90Days}>{Strings.DateSelector.Last90Days}</MenuItem>
						<MenuItem value={Strings.DateSelector.MonthToDate}>{Strings.DateSelector.MonthToDate}</MenuItem>
						<MenuItem value={Strings.DateSelector.QuarterToDate}>{Strings.DateSelector.QuarterToDate}</MenuItem>
						<MenuItem value={Strings.DateSelector.YearToDate}>{Strings.DateSelector.YearToDate}</MenuItem>
						<MenuItem value={Strings.DateSelector.LastMonth}>{Strings.DateSelector.LastMonth}</MenuItem>
						<MenuItem value={Strings.DateSelector.LastQuarter}>{Strings.DateSelector.LastQuarter}</MenuItem>
						<MenuItem value={Strings.DateSelector.LastYear}>{Strings.DateSelector.LastYear}</MenuItem>
						<MenuItem value={Strings.DateSelector.CustomDateRange}>{Strings.DateSelector.CustomDateRange}</MenuItem>
					</Field>
				</Grid>
			</Grid>
			{showDatePicker &&
				<Grid container item xs={12} spacing={1}>
					<Grid item xs={12} md={6}>
						<Field
							name="startDate"
							label="Start Date"
							component={DatePicker}
							startOfDay
							fullWidth
							// TODO: This is causing an infinite render loop due to the validate* curried method returning a new function
							// each time.  Removing for now as we already supply a validate method to the redux form component (see bottom of file)
							// validate={[validateDateString('Start Date', true, true)]}
							useMoment
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Field
							name="endDate"
							label="End Date"
							component={DatePicker}
							endOfDay
							fullWidth
							// validate={[validateDateString('End Date', true, true)]}
							useMoment
						/>
					</Grid>
				</Grid>}
			{showLineOfBusinessField &&
				<Grid container item xs={12}>
					<Grid item xs={12}>
						<Field
							name={Strings.GraphDataFilterForm.LineOfBusiness}
							component={FormSelect}
							label={Strings.LeadField.LineOfBusiness}
							fullWidth={true}
						>
							<MenuItem value={'all'}>All</MenuItem>
							<MenuItem value={'Health'}>Health</MenuItem>
							<MenuItem value={'Medicare'}>Medicare</MenuItem>
							<MenuItem value={'Supplemental'}>Supplemental</MenuItem>
							<MenuItem value={'Life'}>Life</MenuItem>
							<MenuItem value={'Employer'}>Employer</MenuItem>
							<MenuItem value={'Other'}>Other</MenuItem>
						</Field>
					</Grid>
				</Grid>
			}
			{showComparisonField &&
				<Field
					name={Strings.GraphDataFilterForm.ComparePrevious}
					component={FormSwitch}
					fullWidth={true}
					label="Show Comparisons"
				/>
			}
		</Grid>
	);
}

const validateStartDate = (startDate: Moment, endDate: Moment) => {
	const yearsAgo = moment().subtract(2, 'years');
	const today = moment();
	if (startDate.isBefore(yearsAgo)) {
		return 'Start Date cannot be before 2 years ago';
	}
	if (startDate.isAfter(endDate)) {
		return 'Start Date cannot be after End Date';
	}
	if (startDate.isAfter(today)) {
		return 'Start Date cannot be after today';
	}
	return undefined;
};

const validateEndDate = (startDate: Moment, endDate: Moment) => {
	const tomorrow = moment()
		.add(1, 'day')
		.startOf('day');
	if (endDate.isAfter(tomorrow)) {
		return 'End Date cannot be after today';
	}
	if (endDate.isBefore(startDate)) {
		return 'End Date cannot be before Start Date';
	}
	return undefined;
};

const validate = (values: GraphDataFilterValues) => {
	const errors: any = {};
	const start = values.startDate;
	const end = values.endDate
	const dateRange = values.dateRange;

	if (start && end && dateRange && dateRange === 'Custom Date Range') {
		const startDate = moment(start);
		const endDate = moment(end);
		const startError = validateStartDate(startDate, endDate);
		const endError = validateEndDate(startDate, endDate);
		if (startError || endError) {
			errors.startDate = startError;
			errors.endDate = endError;
			return errors;
		}
	}
	return {};
};
export const GraphDataFilterForm = reduxForm<GraphDataFilterValues, DateFormProps>({
	form: GRAPH_DATA_FORM,
	validate,
	getFormState: () => getAppState().form,
})(DateForm);
