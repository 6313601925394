import {actionCreatorFactory} from 'typescript-fsa';
import { SmsTemplate } from '../reducers/sms_template_reducer';
import { Contact } from '../reducers/ContactReducer';

const actionCreator = actionCreatorFactory('SMS_TEMPLATE');

// TODO get with Anees on GUID generation for new objects
export const CreateSmsTemplate = actionCreator.async<SmsTemplate, SmsTemplate>('CREATE');
export const UpdateSmsTemplate = actionCreator.async<SmsTemplate, SmsTemplate>('UPDATE');
export const DeleteSmsTemplate = actionCreator.async<SmsTemplate, SmsTemplate>('DELETE');
export const GetAllSmsTemplates = actionCreator.async<(string | undefined | Contact[]), SmsTemplate[]>('GET_ALL');
