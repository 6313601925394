import {actionCreatorFactory} from 'typescript-fsa';
import { Contact } from '../../reducers/ContactReducer';
import { SmsTemplate } from '../../reducers/sms_template_reducer';
import { SmsSequenceStep } from '../../reducers/sequences/sms_sequence_reducer';

const actionCreator = actionCreatorFactory('SMS_SEQUENCE');

export type StartSmsParams = {
	householdId: string;
	employerId?: string;
}

export const StartSmsSequence = actionCreator<Contact[]>('START');
export const CancelSmsSequence = actionCreator('CANCEL');
export const ChangeSmsSequenceStep = actionCreator<SmsSequenceStep>('CHANGE_SMS_SEQUENCE_STEP');
export const SelectContactForSmsSequence = actionCreator<{ contactId: string; contactPhoneNumber: string; }>('SELECT_CONTACT');
export const SendSms = actionCreator<SendSmsPayload>('SELECT_TEMPLATE');
export const PopulateSmsText = actionCreator<PopulateSmsPayload>('SMS_CLIENTCONNECT');

export const SmsLeadSelectionCleanup = actionCreator('CLEAN_UP_SMS_LEAD_LIST');
export const StoreSmsTemplate = actionCreator<SmsTemplate>('STORE_SMS_TEMPLATE');
export const SendSmsToLeadListSelection = actionCreator('SEND_SMS_TO_LEAD_LIST_SELECTION');
export const SendSmsToLeadListAtIndex = actionCreator('SEND_SMS_TO_LEAD_LIST_AT_INDEX');

export type SendSmsPayload = {
	phoneNumber: string;
	contact: Contact;
	smsTemplate?: SmsTemplate;
	content?: string;
	phoneId?: string;
	keepDialogOpen?: boolean;
};

export type PopulateSmsPayload = {
	phoneNumber: string;
	content?: string;
}
