import React from 'react';
import { Field } from 'redux-form';
import {
	Typography,
	Card,
	CardContent,
	InputLabel,
	MenuItem,
} from '@material-ui/core';
import { HouseholdRole, Contact } from '../../reducers/ContactReducer';
import { FormSelect } from '../../utilities/forms';

export type HouseholdRoleFieldData = {
	householdRole: HouseholdRole;
};

interface Props {
	contact?: Contact;
	contacts?: Contact[];
	hidden?: boolean;
}

export const HouseholdRoleField = (props: Props) => {
	const isPrimary = props.contact
		? props.contact.householdRole == HouseholdRole.Primary
		: false;
	const isDependent = props.contact
		? props.contact.householdRole == HouseholdRole.Dependent
		: false;
	const isSpousePartner = props.contact
		? props.contact.householdRole == HouseholdRole.Secondary
		: false;
	const hasSpouse =
		props.contacts &&
		props.contacts.some(
			contact => contact.householdRole == HouseholdRole.Secondary
		);
	const hasContacts = props.contacts && props.contacts;
	const hasOtherPrimary =
		props.contacts &&
		props.contacts.some(
			contact => contact.householdRole == HouseholdRole.Primary
		);

	return (
		<div hidden={props.hidden}>
			<Typography
				variant="h5"
				style={{ paddingLeft: 16, paddingTop: 16 }}
			>
				{'Role'}
			</Typography>
			<Card style={{ margin: '10px' }}>
				<CardContent>
					<Field
						name="householdRole"
						component={FormSelect}
						label={'Role'}
						children={[
							<MenuItem
								key="Primary"
								selected={isPrimary}
								disabled={isPrimary || !props.contact || hasOtherPrimary}
								value={HouseholdRole.Primary}
							>
								{'Primary'}
							</MenuItem>,
							<MenuItem
								key="Dependent"
								selected={isDependent}
								disabled={isPrimary || !hasContacts}
								value={HouseholdRole.Dependent}
							>
								{'Dependent'}
							</MenuItem>,
							<MenuItem
								key="Secondary"
								selected={isSpousePartner}
								disabled={isPrimary || hasSpouse || !hasContacts}
								value={HouseholdRole.Secondary}
							>
								{'Spouse/Partner'}
							</MenuItem>,
						]}
						fullWidth
					/>
				</CardContent>
			</Card>
		</div>
	);
};
