import {
	Card,
	CardContent,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { Info } from '@material-ui/icons';
import React from 'react';
import { Strings } from '../../assets/common/strings';
import { useReshopCardStyles } from '../../utilities/hooks/styles';
import { isBlank } from '../../utilities/utilities';
import { SimpleListItem } from '../utility/simple_list_item';
import { ButtonCollapse } from './button_collapse';
import { FitScoreRibbon } from './ribbons';

interface ReshopPlanCardProps {
	carrierName: string;
	fitscore?: number;
	individualDeductible: number;
	individualMoop: number;
	isCurrent: boolean;
	metalLevel?: string;
	monthlyPremium: number;
	planId: string;
	planName: string;
	federalSubsidy?: number;
}

const ReshopPlanCard: React.FC<ReshopPlanCardProps> = ({
	carrierName,
	fitscore,
	individualDeductible,
	individualMoop,
	isCurrent,
	metalLevel,
	monthlyPremium,
	planId,
	planName,
	federalSubsidy,
}) => {
	const styles = useReshopCardStyles();

	return (
		<>
			<Grid item xs={12} sm={6} style={{ maxWidth: '500px', margin: 10 }}>
				<Typography
					align="center"
					noWrap={false}
					variant="subtitle2"
					className={isCurrent ? styles.currentPlanText : styles.recommendedPlanText}>
					<strong>{isCurrent ? Strings.ReShop.CurrentPlan : Strings.ReShop.RecommendedPlan}</strong>
				</Typography>
				<div style={{ position: 'relative' }}>{fitscore && <FitScoreRibbon fitscore={fitscore} />}</div>
				<Card
					variant="outlined"
					square={false}
					className={isCurrent ? styles.currentPlanCard : styles.recommededPlanCard}>
					<CardContent style={{ width: '100%', padding: 0 }}>
						<ListItem style={{ margin: 15, padding: 0 }}>
							<ListItemText
								primary={planName}
								secondary={isBlank(metalLevel) ? '' : metalLevel + ' ' + Strings.ReShop.Plan}
							/>
						</ListItem>
						<ButtonCollapse
							titleVariant="caption"
							themeColor={isCurrent ? 'primary' : 'recommended'}
							titleStyle={{ margin: 15 }}>
							<List>
								<SimpleListItem title={Strings.ReShop.Carrier} secondaryAction={carrierName} />
								<SimpleListItem
									title={Strings.ProductPolicyAmountsEditCard.IndividualDeductible}
									secondaryAction={individualDeductible.toLocaleString('en-US', {
										style: 'currency',
										currency: 'USD',
									})}
								/>
								<SimpleListItem
									title={Strings.ProductPolicyAmountsEditCard.IndividualMaxOutOfPocket}
									secondaryAction={individualMoop.toLocaleString('en-US', {
										style: 'currency',
										currency: 'USD',
									})}
								/>
								<SimpleListItem
									title={Strings.ReShop.PlanID}
									secondaryAction={planId}
									divider={false}
								/>
							</List>
						</ButtonCollapse>
						<Grid container direction="row" alignItems="center" justify="center" style={{ margin: 15 }}>
							<Typography variant="subtitle2">
								{Strings.ProductPremiumInfoEditOptions.MonthlyPremium}:
							</Typography>
							<Typography variant="h5" style={{ margin: 10 }}>
								<strong>
									{monthlyPremium.toLocaleString('en-US', {
										style: 'currency',
										currency: 'USD',
									})}
								</strong>
								{!isBlank(metalLevel) && (
									<Tooltip
										title={
											federalSubsidy
												? Strings.ReShop.SubsidyApplied +
												  ': ' +
												  federalSubsidy.toLocaleString('en-US', {
														style: 'currency',
														currency: 'USD',
												  })
												: Strings.ReShop.NoSubsidyApplied
										}>
										<Info color="disabled" style={{ fontSize: 15, marginBottom: 10 }} />
									</Tooltip>
								)}
							</Typography>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</>
	);
};

export { ReshopPlanCard };
