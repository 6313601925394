import { actionCreatorFactory } from 'typescript-fsa';
import { Action as ReduxAction } from 'redux';

const actionCreator = actionCreatorFactory('AccessPage');

export const SetDrawerStatus = actionCreator<{ visible: boolean }>(
	'Toggle_Showing_Access_Drawer'
);

export function setDrawerStatus(payload: boolean): ReduxAction {
	return SetDrawerStatus({ visible: payload });
}
