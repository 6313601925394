import actionCreatorFactory from 'typescript-fsa';
import { AgentLicense, AgentState, LicenseForm, AgentLicenseLineOfAuthority, AgentAppointment, AgentAppointmentLineOfBusiness, AgentAppointmentState, AppointmentForm } from '../reducers/license_appointment_reducer';

const actionCreator = actionCreatorFactory('LicenseAppointment');

export const GetAgentStates = actionCreator.async<void, AgentState[]>('GET_AGENT_STATES');

export const GetAgentLicenses = actionCreator.async<LicenseForm, AgentLicense[]>('GET_AGENT_LICENSES');

export const GetAgentLicensesByState = actionCreator.async<LicenseForm, AgentLicense[]>('GET_AGENT_LICENSES_BY_STATE');

export const GetAgentAppointmentCountByState = actionCreator.async<string, AgentAppointmentCount[]>('GET_AGENT_APPOINTMENT_COUNT_BY_STATE');

export const GetAgentLicenseLineOfAuhtorities = actionCreator.async<void, AgentLicenseLineOfAuthority[]>('GET_AGENT_LICENSE_LINE_OF_AUTHORITIES');

export const GetAgentAppointments = actionCreator.async<AppointmentForm, AgentAppointment[]>('GET_AGENT_APPOINTMENTS');

export const GetAgentAppointmentLineOfBusinesses = actionCreator.async<void, AgentAppointmentLineOfBusiness[]>('GET_AGENT_APPOINTMENT_LINE_OF_BUSINESSES');

export const GetAgentAppointmentStates = actionCreator.async<void, AgentAppointmentState[]>('GET_AGENT_APPOINTMENT_STATES');