import { Action as ReduxAction } from 'redux';
import { isType } from 'typescript-fsa';
import { ImpersonateAgent } from '../actions/impersonation_actions';
import { Logout } from '../actions/authentication_actions';
import { ClearCaches } from '../actions/authentication_actions';

export interface ImpersonationState {
    loading: boolean;
    complete: boolean;
    error: string;
}

const initialState: ImpersonationState = {
    loading: false,
    complete: false,
    error: '',
};

export const impersonationReducer = (state=initialState, action: ReduxAction) => {
    if (isType(action, ImpersonateAgent.started)) {
        return {
            ...state,
            loading: true,
            complete: false,
            error: '',
        };
    } else if (isType(action, ImpersonateAgent.failed)) {
        return {
            ...state,
            loading: false,
            complete: false,
            error: action.payload.error,
        };
    } else if (isType(action, ImpersonateAgent.done)) {
        return {
            ...state,
            loading: false,
            complete: true,
            error: '',
        };
    } else if (isType(action, Logout.started)) {
        return {
            ...initialState,
        };
    } else if (isType(action, ClearCaches)) {
        return {
            ...initialState,
        };
    } else {
        return state;
    }
};