import React, { useState } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Typography,
	TableSortLabel,
	IconButton,
	Icon,
} from '@material-ui/core';
import {
	GetLicenseAppointmentStatusIcon,
	useStylesLicenseAppointment,
} from '../../../utilities/agentLicenseAppointment_util';
import { Strings } from '../../../assets/common/strings';
import _ from 'lodash';
import LAStatusIconLegendDialog from '../la_status_icon_legend_dialog';
import AppointmentTerminatedDialog from './appointment_terminated_dialogue';

export default function AppointmentTable({ columns, rows }) {
	const classes = useStylesLicenseAppointment();
	// For Appointment status information Dialog
	const [statusDialogOpen, setStatusDialogOpen] = useState(false);

	// For Terminated Appointment Dialog
	const [appointmentTerminatedDialogOpen, setAppointmentTerminatedDialogOpen] = useState(false);
	const [dialogParams, setDialogParams] = useState({ carrierName: '', state: '', expirationDate: new Date() });

	const handleRowClick = (row) => {
		if (row.StatusText === Strings.LicenseStatusIcon.Terminated) {
			handleTerminatedStatusDialogOpen(row.CarrierName, row.State, row.ExpirationDate);
		}
		return;
	};

	const handleTerminatedStatusDialogOpen = (carrierName, state, expirationDate) => {
		setDialogParams({ carrierName, state, expirationDate });
		setAppointmentTerminatedDialogOpen(true);
	};

	const handleTerminatedStatusDialogClose = () => {
		setAppointmentTerminatedDialogOpen(false);
	};

	const groupedRows = rows.reduce((appointment, row) => {
		if (!appointment[row.LineOfBusinessText]) {
			appointment[row.LineOfBusinessText] = [];
		}
		appointment[row.LineOfBusinessText].push(row);
		return appointment;
	}, {});

	type Order = 'asc' | 'desc';
	const [order, setOrder] = useState<Order>('asc');
	const [orderBy, setOrderBy] = useState('Carrier');

	const handleDialogOpen = () => {
		setStatusDialogOpen(true);
	};

	const handleDialogClose = () => {
		setStatusDialogOpen(false);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const CustomizedStatusColumnHeader = ({columnName}) => (
		<div className={classes.positionCenter}>
			<IconButton onClick={handleDialogOpen}>
				<Icon className={classes.iconCheck}>info_outline</Icon>
			</IconButton>
			<TableSortLabel
				className={classes.bold_text}
				active={orderBy === columnName}
				direction={orderBy === columnName ? order : 'asc'}
				onClick={(event) => handleRequestSort(event, columnName)}>
				{columnName}
			</TableSortLabel>
		</div>
	);

	return (
		<>
			<Typography variant="h6">{Strings.LicenseAppointment.LineOfBusiness}</Typography>

			{Object.values(Strings.AppointmentLineOfBusiness).map((lineOfBusiness, index) => {
				var lineOfBusinessData = groupedRows[lineOfBusiness];

				if (lineOfBusinessData && lineOfBusinessData.length > 0) {
					const sortedRows = lineOfBusinessData.sort((rowx, rowy) => {
						if (orderBy === 'Carrier') {
							return order === 'asc'
								? rowx.CarrierName.localeCompare(rowy.CarrierName)
								: rowy.CarrierName.localeCompare(rowx.CarrierName);
						} else if (orderBy === 'Status') {
							return order === 'asc'
								? rowx.StatusText.localeCompare(rowy.StatusText)
								: rowy.StatusText.localeCompare(rowx.StatusText);
						} else if (orderBy === 'State') {
							return order === 'asc'
								? rowx.State.localeCompare(rowy.State)
								: rowy.State.localeCompare(rowx.State);
						}
						return 0;
					});

					return (
						<>
							<Typography className={classes.bold_text} variant="h6">
								{lineOfBusiness === Strings.AppointmentLineOfBusiness.CommercialHealth
									? Strings.AppointmentLineOfBusinessHeading[lineOfBusiness]
									: lineOfBusiness}
							</Typography>
							<TableContainer component={Paper}>
								<Table key={lineOfBusiness} className={classes.table} aria-label="appointment table">
									<TableHead>
										<TableRow>
											{columns.map((col) => {
												return (
													<TableCell
														align="center"
														key={col.key}
														className={classes.bold_text}>
														{col.key === 'StatusText' ? (
															<CustomizedStatusColumnHeader columnName={col.name} />
														) : (
															<TableSortLabel
																active={orderBy === col.name}
																direction={orderBy === col.name ? order : 'asc'}
																onClick={(event) => handleRequestSort(event, col.name)}
																className={classes.bold_text}>
																{col.name}
															</TableSortLabel>
														)}
													</TableCell>
												);
											})}
										</TableRow>
									</TableHead>
									<TableBody>
										{sortedRows.map((row, index) => {
											return (
												<TableRow
													onClick={() => {
														handleRowClick(row);
													}}
													className={
														row.StatusText === Strings.LicenseStatusIcon.Terminated
															? classes.clickableRows
															: ''
													}
													key={index}>
													{columns.map((col) => {
														return (
															<TableCell key={`${col.key}-row${index}`} align="center">
																{col.key === 'StatusText' ? (
																	<GetLicenseAppointmentStatusIcon
																		status={row.StatusText}
																	/>
																) : (
																	row[col.key]
																)}
															</TableCell>
														);
													})}
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
						</>
					);
				}
			})}

			<LAStatusIconLegendDialog
				title={Strings.LicenseAppointment.AppointmentStatusDialogueTitle}
				statusDialogOpen={statusDialogOpen}
				statusList={Object.keys(Strings.AppointmentStatusIcon)}
				handleDialogClose={handleDialogClose}
			/>

			<AppointmentTerminatedDialog
				title={`${dialogParams.carrierName} ${dialogParams.state}`}
				expirationDate={dialogParams.expirationDate}
				statusDialogOpen={appointmentTerminatedDialogOpen}
				handleDialogClose={handleTerminatedStatusDialogClose}></AppointmentTerminatedDialog>
		</>
	);
}
