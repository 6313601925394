export namespace secure_storage {
	export function configure(
		tableName: string
	): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			let ss = new (cordova.plugins as any).SecureStorage(
				function () {
					// Successful configuration
					setTimeout(() => {
						resolve(ss);
					}, 50);
				},
				function () {
					ss.secureDevice(
						function () {
							configure(tableName)
								.then(s => {
									resolve(s);
								})
								.catch(() => {
									reject();
								});
						},
						function () {
							reject();
						}
					);
				},
				tableName
			);
		});
	}
}

export default secure_storage;