import React from 'react';
import { reduxForm } from 'redux-form';
import { PersonDemographicFields } from '../../advanced_search/fields/person_demographic_fields';
import { LeadFields } from '../../advanced_search/fields/lead_fields';
import { getAppState } from '../../..';

export const FORM_NAME = 'LEAD_SEARCH_FORM';

interface Props {
	tagSearch?: boolean;
}

const LeadSearchFilterForm: React.FC<Props> = (props) => {
	const { tagSearch = false } = props;
	return (
		<div>
			<PersonDemographicFields formName={FORM_NAME} onlyCommon />
			<LeadFields formName={FORM_NAME} onlyCommon tagSearch={tagSearch} />
		</div>
	);
};

export default reduxForm({
	form: FORM_NAME,
	destroyOnUnmount: false,
	getFormState: () => getAppState().form,
})(
	LeadSearchFilterForm
) as React.ComponentClass<Props>;
