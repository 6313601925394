import React, { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import {
    Button, Grid, Icon, InputAdornment, Select, TextField, makeStyles, FormControl, InputLabel, MenuItem, Typography,
} from '@material-ui/core';
import { FormSlice, useNormalization, useFormActions, useSelector } from '@optum-uhone-hmkts/rise';
import { Provider } from './';
import { Strings } from '../../../../../assets/common/strings';

export interface SearchValues {
    zipCode: string;
    radius: number;
    providerName: string;
}

export const defaultSearchValues: SearchValues = {
    zipCode : '',
    radius : 10,
    providerName: '',
};

interface Props {
    searchValues: SearchValues;
    addedProviders: Provider[];
    updateSearchValues: (searchValues: SearchValues) => void;
    handleSearch: () => void;
    disabled?: boolean;
}

export const ProviderSearch: React.FC<Props> = props => {
    const { addedProviders, updateSearchValues, handleSearch, disabled } = props;
    const classes = useStyles();

    const formDispatch = useFormActions();
    const { normalize } = useNormalization('zipCode');

    const matchingZipCodes = useSelector((state: any) => state?.FormBuilder?.activeForm?.providerInfo?.zipCodeResults, shallowEqual);

    const [zipCodeError, setZipCodeError] = useState('');
    const searchValues = props.searchValues;

    const handleZipCodeChange = (event: React.ChangeEvent<{ value: string }>) => {
        const newValue = event.target.value;
        const normalizedField = normalize(newValue);
        updateSearchValues({...searchValues, zipCode: normalizedField});
        if (normalizedField.length === 5) {
            formDispatch.providerZipCodeLookUp(newValue);
        } else {
            setZipCodeError('');
        }
    }

    const handleDistanceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        updateSearchValues({...searchValues, radius: event.target.value as number});
    }
    const handleProviderChange = (newValue: string) => {
        updateSearchValues({...searchValues, providerName: newValue})
    }

    const handleProviderSearch = () => {
        handleSearch();
    }

    const handleKeyUp = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleProviderSearch();
        }
    }

    const validateZipCode = (matchingZipCodesResults: FormSlice.CountyLookUpResponse[]) => {
        if (matchingZipCodesResults.length === 0) {
            setZipCodeError(Strings.FactFinderProviderLookup.invalidZipCodeError)
        } else {
            setZipCodeError('')
        }
    }

    const validateOnZipFieldExit = (event: React.ChangeEvent<{ value: string }>) => {
        const newValue = event.target.value;
        const normalizedField = normalize(newValue);
        if (normalizedField.length < 5 && searchValues.zipCode.length !== 0) {
            setZipCodeError(Strings.FactFinderProviderLookup.lengthZipCodeError)
        }
    }

    useEffect(() => {
        if (searchValues.zipCode.length === 5) {
            validateZipCode(matchingZipCodes);
        } else {
            setZipCodeError('')
        }
    }, [matchingZipCodes]);

    const readyToSearch: boolean = !zipCodeError && searchValues.zipCode?.length === 5 && matchingZipCodes?.length > 0 && searchValues.providerName?.length > 1;

    return (
        <>
            <form>
                <Grid container spacing={3} className={classes.formSection}>
                    <Grid item xs={6}>
                        <TextField
                            autoFocus={addedProviders.length === 0}
                            label="Zip Code"
                            InputLabelProps={{ shrink: true}}
                            value={searchValues.zipCode}
                            onChange={handleZipCodeChange}
                            fullWidth
                            error={!!zipCodeError}
                            helperText={zipCodeError}
                            onBlur={validateOnZipFieldExit}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <FormControl style={{width: '100%'}}>
                            <InputLabel>Distance</InputLabel>
                            <Select
                                value={searchValues.radius}
                                onChange={handleDistanceChange}
                                disabled={disabled}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={15}>15</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={25}>25</MenuItem>
                                <MenuItem value={30}>30</MenuItem>
                                <MenuItem value={35}>35</MenuItem>
                                <MenuItem value={40}>40</MenuItem>
                                <MenuItem value={45}>45</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            name="provider"
                            label="Provider Name"
                            value={searchValues.providerName}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon>search</Icon>
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(e) => handleProviderChange(e.target.value)}
                            onKeyUp={handleKeyUp}
                            fullWidth
                            disabled={disabled}
                        />
                        <Typography
                            variant={'caption'}
                            color={'textSecondary'}
                            className={classes.subtitle}
                        >
                            {Strings.FactFinderProviderLookup.maxDoctorAmount}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} container alignItems="center">
                        <Button
                            color="primary"
                            size="large"
                            onClick={handleProviderSearch}
                            disabled={!readyToSearch}
                        >
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    )
};

const useStyles = makeStyles({
    formSection: {
        marginTop: 10
    },
    subtitle: {
        marginTop: '-5px' ,
        fontStyle: 'italic' ,
        textAlign: 'start' ,
    },
});
