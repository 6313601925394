import React from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import { Button } from '@material-ui/core';
import { AppState } from '../../reducers/index';
import ImageEditor from '../../components/Image/ImageEditor';
import { uploadAgentIcon } from '../../actions/agent_actions';
import { getLargestIcon } from '../../utilities/image_util';
import { AgentState } from '../../reducers/agent_reducer';
import { ImageCard } from './image_card_content';
import { Strings } from '../../assets/common/strings';
import { initials } from '../../assets/common/string_builders';
import { Dispatch } from 'redux';


interface StateProps {
	agent: AgentState;
	jwt: string;
}

interface DispatchProps {
	updateAgentIcon: (
		agentId: string,
		iconImage: string,
		jwt: string
	) => void;
}

interface State {
	largestIcon?: string;
}

type Props = StateProps & DispatchProps;

class IconManagement extends React.Component<Props, State> {

	imageEditor: ImageEditor | null;

	constructor(props: Props) {
		super(props);
		const largestIcon = getLargestIcon(props.agent.iconImages || []);
		this.state = { largestIcon };
	}

	componentWillReceiveProps(nextProps: Props) {
		const icon = getLargestIcon(nextProps.agent.iconImages || this.props.agent.iconImages || []);
		if (icon && this.state.largestIcon != icon) {
			this.setState({ largestIcon: icon });
		}
	}

	openEditor = () => {
		const imageEditor = this.imageEditor as ImageEditor;
		imageEditor.setState({ open: true });
	};

	updateIcon = (icon: string) => {
		const { agent, updateAgentIcon, jwt } = this.props;
		updateAgentIcon(agent.agentCode, icon, jwt);
	};

	render() {
		const { agent } = this.props;
		const loading = !initials(agent);
		return (
			<>
				<ImageCard
					title={Strings.AboutMeLabels.IconManagement}
					largestIcon={this.state.largestIcon as any}
					actions={[
						<Button
							onClick={this.openEditor}
						>
							EDIT
						</Button>
					]}
				/>
				{!loading ? (
					<ImageEditor
						ref={ref => this.imageEditor = ref}
						title={Strings.AboutMeLabels.IconEditor}
						buttonText="Change Photo"
						finishBtnText="Approve"
						agentData={agent}
						handleFinalImage={this.updateIcon}
					/>
				) : null}
			</>
		);
	}
}

const mapStateToProps = (state: AppState): StateProps => {
	return {
		agent: state.agent,
		jwt: state.authentication.jwt,
	};
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	updateAgentIcon: (agentId: string, iconImage: string, jwt: string) =>
		dispatch(uploadAgentIcon(agentId, iconImage, jwt)),
});

const IconManagementContainer = connect(
	mapStateToProps,
	mapDispatchToProps, true
)(IconManagement);

export default IconManagementContainer;
