import React from 'react';
import Highcharts from 'highcharts';
import { themePalette } from '../../utilities/branding';
import { isMobileDevice } from '../../utilities/is_mobile';
import { XAxisData } from '../../reducers/performance_reducer';

const MAX_INTERVAL = 2000;

interface Props {
	title: string;
	xAxis: XAxisData[];
	unit?: string;
	style?: React.CSSProperties;
	chartSettings?: any;
	infinityValue?: Number; 
}

class Chart extends React.PureComponent<Props, any> {
	chartRef: any;

	componentDidMount() {
		this.updateChart();
	}

	componentDidUpdate() {
		this.updateChart();
	}

	getInterval(value: number): number {
		if (value <= 5) {
			return 1;
		}
		if (value <= 20) {
			return 5;
		}
		if (value <= 60) {
			return 15;
		}
		if (value <= 100) {
			return 25;
		}
		if (value <= 200) {
			return 50;
		}
		if (value <= 500) {
			return 100;
		}
		if (value <= 1000) {
			return 200;
		}
		if (value <= 2000) {
			return 500;
		}
		if (value <= 5000) {
			return 1000;
		}
		return MAX_INTERVAL;
	}

	getMaxValue = (data: XAxisData[]) => {
		if (!data || !data.length) {
			return 0;
		}
		const max = data.reduce(
			(max, val) => (val.value > max ? val.value : max),
			data[0].value
		);
		return Math.ceil(max);
	};

	getLabelFormat = () => {
		const { unit = 'default' } = this.props;
		switch (unit) {
			case 'percentage':
				return '{value}%';
			case 'money':
				return '${value}';
			default:
				return '{value}';
		}
	};

	updateChart() {
		const { title, chartSettings, xAxis, unit = 'default' } = this.props;
		const settings = {
			...defaultChartSettings,
			...chartSettings,
			tooltip: {
				enabled: !isMobileDevice,
				formatter(): string {
					const self: any = this;
					if (self.y > self.series.chart.yAxis[0].max) {
						return 'Infinity';
					} else {
						const value = Number(self.y).toFixed(2);
						switch (unit) {
							case 'percentage':
								return `${value}%`;
							case 'money':
								return `\$${value}`;
							default:
								return `${value}`;
						}
					}
				},
			},
		};

		let yAxisMax = this.getMaxValue(xAxis) || 5;
		const tickInterval = this.getInterval(yAxisMax);
		const labelFormat = this.getLabelFormat();

		const updatedChartSettings = {
			series: [
				{
					data: xAxis.map(
						x =>
							this.props.infinityValue && x.value == this.props.infinityValue
								? {
										y: yAxisMax + MAX_INTERVAL + 1,
										color: themePalette.chart_infinity,
									}
								: x.value
					),
					showInLegend: false,
				},
			],
			...settings,
			title: {
				...settings.title,
				text: title,
			},
			xAxis: {
				...settings.xAxis,
				categories: xAxis.map(x => x.label),
			},
			yAxis: {
				...settings.yAxis,
				max: yAxisMax,
				tickInterval,
				labels: {
					format: labelFormat,
					style: defaultChartSettings.labels.style,
				},
			},
		};
		Highcharts.chart(this.chartRef, updatedChartSettings);
	}

	render() {
		const { style = {} } = this.props;
		return (
			<div
				ref={chart => (this.chartRef = chart)}
				style={{ ...defaultStyle, ...style }}
			/>
		);
	}
}

const defaultStyle: React.CSSProperties = {
	height: 260,
	overflow: 'hidden',
};
const defaultChartSettings = {
	credits: {
		enabled: false,
	},
	loading: {
		showDuration: 5000,
		labelStyle: {
			color: 'white',
		},
		style: {
			position: 'absolute',
			backgroundColor: '#ffffff',
			opacity: 0.5,
			textAlign: 'center',
		},
	},
	chart: {
		type: 'column',
		backgroundColor: 'transparent',
		spacing: [20, 40, 40, 20],
	},
	plotOptions: {
		column: {
			color: 'white',
			animation: true,
			maxPointWidth: 4,
			borderWidth: 0,
		},
	},
	subtitle: {},
	labels: {
		style: {
			color: 'white',
			opacity: 1,
		},
	},
	yAxis: {
		title: {
			text: null,
		},
		labels: {
			style: {
				color: 'white',
				opacity: 0.6,
			},
			align: 'center',
		},
	},
	title: {
		style: {
			color: 'white',
			opacity: 1,
		},
	},
	xAxis: {
		lineWidth: '0.5',
		tickLength: 0,
		labels: {
			step: 1,
			rotation: -45,
			style: {
				color: 'white',
				opacity: 1,
			},
		},
	},
};

export default Chart;
