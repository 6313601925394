import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  useDispatch, useSelector,
  RiseTextField, Grid, Typography, RiseCard
} from '@optum-uhone-hmkts/rise';
import { FactFinderSlice } from '../..';
import { shallowEqual } from 'react-redux';
import { PowerPointTemplateCard } from './powerpoint_template_card';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardWrapper: {}
  }),
);

type OwnProps = {
  selectedTemplateId?: string;
}

export const PowerPointTemplateFormWidget: React.FC<OwnProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const activeTemplate = useSelector(app => FactFinderSlice.selectState(app).activeTemplate || {} as FactFinderSlice.PowerPointTemplate, shallowEqual);

  const updateTemplate = (newTemplate: FactFinderSlice.PowerPointTemplate) => {
    dispatch(FactFinderSlice.actions.SetActiveTemplate(newTemplate));
  }

  const handleTitleChange = (event) => {
    const newTitle = event.currentTarget.value;
    updateTemplate({ ...activeTemplate, title: newTitle });
  }

  return (!activeTemplate?.slides
    ? <span />
    : <RiseCard container gridSize='12|12' alignContent="center">
      <RiseTextField
        gridSize="12|12"
        label={"Template Name"}
        key={"form-pl-hol-name-static"}
        value={activeTemplate.title}
        onChange={handleTitleChange}
      />
      <Typography
        variant="h6"
        style={{ alignContent: "flex-start", margin: 24 }}
        children="Select which slides you want to display"
      />
      <Grid container={true} justify="center" className={classes.cardWrapper}>
        {activeTemplate?.slides.map((slide) => (
          <PowerPointTemplateCard slide={slide} />
        ))}
      </Grid>
    </RiseCard>
  );
}