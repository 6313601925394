import {actionCreatorFactory} from 'typescript-fsa';
import { Activity } from '../reducers/activity_reducer';

const actionCreator = actionCreatorFactory('EmailHistorySummary');

export const getEmailHistorySummarySetByEmployer = actionCreator.async<
	string,
	Activity[]
>('GET_EMAIL_HISTORY_SUMMARY_SET_BY_EMPLOYER');

export const GetEmailHistorySummarySetByHousehold = actionCreator.async<
	string,
	Activity[]
>('GET_EMAIL_HISTORY_SUMMARY_SET_BY_HOUSEHOLD');
