import { call, put, all } from 'redux-saga/effects';
import { http } from "../utilities";
import { GetAgentLicenses, GetAgentLicensesByState, GetAgentStates, GetAgentLicenseLineOfAuhtorities, GetAgentAppointmentCountByState, GetAgentAppointments, GetAgentAppointmentLineOfBusinesses, GetAgentAppointmentStates } from '../actions/license_appointment_actions';
import { submitLog } from '../utilities/logging_util';
import { Strings } from '../assets/common/strings';
import { AgentLicense, AgentState, LicenseForm, AgentLicenseLineOfAuthority, AgentAppointmentCount, AgentAppointment, AgentAppointmentLineOfBusiness, AgentAppointmentState, AppointmentForm } from '../reducers/license_appointment_reducer';
import { takeLatestForActionType } from '../utilities/saga_util';
import { Action } from 'typescript-fsa';
import _ from 'lodash';

// GET AGENT STATES
function* getAgentStateSaga() {
	try {
		const response = yield call(getAgentStateClient);
		if (response.ok) {
			const data: AgentState[] = yield response.json();
			yield put(GetAgentStates.done({ result: data }));
		} else {
			yield put(
				GetAgentStates.failed({
					error: { errorCode: response.status },
				})
			);
		}
	} catch (error) {
		submitLog(Strings.Log.Error, `Error getting agent states`, {
			error,
		});
		yield put(GetAgentStates.failed({ error }));
	}
}

function getAgentStateClient() {
	return http(Strings.LicenseAppointment.GetAgentStatesEndpoint);
}
// GET AGENT LICENSES
function* getAgentLicenseSaga(action: Action<LicenseForm>) {
	try {
		const params = {
			state: action.payload.state || '',
			statusText: action.payload.status || '',
			lineOfAuthority: action.payload.lineOfAuhtority || '',
		};

		const filteredParams = Object.entries(params).reduce((params, [key, value]) => {
			params[key] = value === 'All' ? '' : value;
			return params;
		}, {});

		const response = yield call(getAgentLicenseClient, filteredParams);
		if (response.ok) {
			const data: AgentLicense[] = yield response.json();
			yield put(GetAgentLicenses.done({ params: action.payload, result: data }));
		} else {
			yield put(
				GetAgentLicenses.failed({
					error: { errorCode: response.status }, params: action.payload,
				})
			);
		}
	} catch (error) {
		submitLog(Strings.Log.Error, `Error getting agent licenses`, {
			error,
		});
		yield put(GetAgentLicenses.failed({ error, params: action.payload }));
	}
}

function getAgentLicenseClient(params) {
	const queryParams = new URLSearchParams(params).toString();
	const url = `${Strings.LicenseAppointment.GetAgentLicenseEndpoint}?${queryParams}`
	return http(url);
}

// GET APPOINTMENT COUNT
function* getAgentAppointmentCountSaga(action: Action<string>) {
	try {

		const response = yield call(getAgentAppointmentCountClient, action.payload);
		if (response.ok) {
			const data: AgentAppointmentCount[] = yield response.json();
			yield put(GetAgentAppointmentCountByState.done({ params: action.payload, result: data }));
		} else {
			yield put(
				GetAgentAppointmentCountByState.failed({
					error: { errorCode: response.status }, params: action.payload,
				})
			);
		}
	} catch (error) {
		submitLog(Strings.Log.Error, `Error getting agent appointment count`, {
			error,
		});
		yield put(GetAgentAppointmentCountByState.failed({ error, params: action.payload }));
	}
}

function getAgentAppointmentCountClient(params) {
	const url = `${Strings.LicenseAppointment.GetAgentAppointmentCountByStateEndpoint}?state=${params}`
	return http(url);
}


// GET AGENT LICENSE LINE OF AUTHORITIES
function* getAgentLicenseLineOfAuthoritiesSaga() {
	try {
		const response = yield call(getAgentLicenseLineOfAuhtoritiesClient);

		if (response.ok) {
			const data: AgentLicenseLineOfAuthority[] = yield response.json();
			yield put(GetAgentLicenseLineOfAuhtorities.done({ result: data }));
			yield put(GetAgentLicenseLineOfAuhtorities.done({ result: data }));
		} else {
			yield put(
				GetAgentLicenseLineOfAuhtorities.failed({
					error: { errorCode: response.status },
				})
			);
		}
	} catch (error) {
		submitLog(Strings.Log.Error, `Error getting agent license lineOfAuthorities`, {
			error,
		});
		yield put(GetAgentLicenseLineOfAuhtorities.failed({ error }));
	}
}

function getAgentLicenseLineOfAuhtoritiesClient() {
	return http(Strings.LicenseAppointment.GetAgentLicenseLineOfAuthoritiesEndpoint);
}

// GET AGENT APPOINTMENT
function* getAgentAppointmentSaga(action: Action<AppointmentForm>) {
	try {
		const params = {
			state: action.payload.state || '',
			statusText: action.payload.status || '',
			lineOfBusiness: action.payload.lineOfBusiness || '',
		};

		const filteredParams = Object.entries(params).reduce((params, [key, value]) => {
			params[key] = value === 'All' ? '' : value;
			if (value === Strings.AppointmentLineOfBusinessHeading['Commercial Health']) {
				params[key] = Strings.AppointmentLineOfBusiness.CommercialHealth;
			}
			return params;
		}, {});

		const response = yield call(getAgentAppointmentClient, filteredParams);

		if (response.ok) {
			const data: AgentAppointment[] = yield response.json();
			yield put(GetAgentAppointments.done({ params: action.payload, result: data }));
		} else {
			yield put(
				GetAgentAppointments.failed({
					error: { errorCode: response.status }, params: action.payload,
				})
			);
		}
	} catch (error) {
		submitLog(Strings.Log.Error, `Error getting agent appointments`, {
			error,
		});
		yield put(GetAgentAppointments.failed({ error, params: action.payload }));
	}
}

function getAgentAppointmentClient(params) {
	const queryParams = new URLSearchParams(params).toString();
	const url = `${Strings.LicenseAppointment.GetAgentAppointmentEndpoint}?${queryParams}`
	return http(url);
}

// GET AGENT APPOINTMENT
function* getAgentAppointmentLineOfBusinessSaga() {
	try {
		const response = yield call(getAgentAppointmentLineOfBusinessClient);

		if (response.ok) {
			const data: AgentAppointmentLineOfBusiness[] = yield response.json();
			yield put(GetAgentAppointmentLineOfBusinesses.done({ result: data }));
			yield put(GetAgentAppointmentLineOfBusinesses.done({ result: data }));
		} else {
			yield put(
				GetAgentAppointmentLineOfBusinesses.failed({
					error: { errorCode: response.status },
				})
			);
		}
	} catch (error) {
		submitLog(Strings.Log.Error, `Error getting agent appointment lineOfBusinesses`, {
			error,
		});
		yield put(GetAgentAppointmentLineOfBusinesses.failed({ error }));
	}
}

function getAgentAppointmentLineOfBusinessClient() {
	return http(Strings.LicenseAppointment.GetAgentAppointmentLineOfBusinessEndpoint);
}

// GET AGENT APPOINTMENT
function* getAgentAppointmentStateSaga() {
	try {
		const response = yield call(getAgentAppointmentStateClient);

		if (response.ok) {
			const data: AgentAppointmentState[] = yield response.json();
			yield put(GetAgentAppointmentStates.done({ result: data }));
			yield put(GetAgentAppointmentStates.done({ result: data }));
		} else {
			yield put(
				GetAgentAppointmentStates.failed({
					error: { errorCode: response.status },
				})
			);
		}
	} catch (error) {
		submitLog(Strings.Log.Error, `Error getting agent appointment states.`, {
			error,
		});
		yield put(GetAgentAppointmentStates.failed({ error }));
	}
}

function getAgentAppointmentStateClient() {
	return http(Strings.LicenseAppointment.GetAgentAppointmentStateEndpoint);
}

export function* licenseAppointmentSaga() {
	yield all([
		takeLatestForActionType(
			GetAgentStates.started,
			getAgentStateSaga
		),
		takeLatestForActionType(
			GetAgentLicenses.started,
			getAgentLicenseSaga
		),
		takeLatestForActionType(
			GetAgentLicensesByState.started,
			getAgentLicenseSaga
		),
		takeLatestForActionType(
			GetAgentLicenseLineOfAuhtorities.started,
			getAgentLicenseLineOfAuthoritiesSaga
		),

		takeLatestForActionType(
			GetAgentAppointmentCountByState.started,
			getAgentAppointmentCountSaga
		),
		takeLatestForActionType(
			GetAgentAppointments.started,
			getAgentAppointmentSaga
		),
		takeLatestForActionType(
			GetAgentAppointmentLineOfBusinesses.started,
			getAgentAppointmentLineOfBusinessSaga
		),
		takeLatestForActionType(
			GetAgentAppointmentStates.started,
			getAgentAppointmentStateSaga
		)
	])
}