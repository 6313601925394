import {
	Avatar,
	List,
	ListItem,
	ListItemText,
	Icon,
	IconButton,
} from '@material-ui/core';
import React from 'react';
import _ from 'lodash';
import { themePalette } from '../../utilities/branding';
import { Contact, HouseholdRole } from '../../reducers/ContactReducer';
import { Loaded } from '../../utilities/utilities';
import { fullName } from '../../assets/common/string_builders';

interface PrimaryInsuredListItemProps {
	input: any;
	formName: string;
	contact: Loaded<Contact>;
	changeFieldValue: (formName: string, formPath: string, value: any) => void;
	householdOrEmployer: 'Household' | 'Employer';
}

export const PrimaryInsuredListItem = (props: PrimaryInsuredListItemProps) => {
	function toggleInsuredListItem(
		formName: string,
		contactId: string,
		contactSelected: boolean
	) {
		props.changeFieldValue(formName, `insureds.${contactId}`, !contactSelected);
	}

	const isContactSelected = props.input.value;

	const backgroundColor: string = isContactSelected
		? themePalette.selected_avatar
		: themePalette.default_avatar;

	const leftAvatar = (
		<Avatar style={{ ...styles.avatarStyle, backgroundColor }}>
			<Icon style={{ color: themePalette.negative_icon }}>check</Icon>
		</Avatar>
	);

	const personName: string = fullName(props.contact.data);

	const getSecondaryTitle = () => { 
		if(props.householdOrEmployer === 'Employer'){ 
			const primaryEmployerContact = _.get(
				props,
				'contact.data.employerPrimaryContact',
				false
			);

			return primaryEmployerContact ? "Primary" : "Employee";
		}else{ 
			const householdRole = _.get(
				props,
				'contact.data.householdRole',
				HouseholdRole.Dependent
			);

			return HouseholdRole[householdRole];
		}
	}

	return (
		<List style={{ backgroundColor: themePalette.default_background }}>
			<ListItem
				key={'PrimaryInsuredCard'}
				onClick={() =>
					toggleInsuredListItem(
						props.formName,
						props.contact.data.id,
						isContactSelected
					)
				}
			>
				{leftAvatar}
				<ListItemText
					primary={personName}
					secondary={getSecondaryTitle()}
					style={{ marginLeft: 30 }}
				/>
			</ListItem>
		</List>
	);
};

const styles: { [key: string]: React.CSSProperties } = {
	avatarStyle: {
		alignSelf: 'center',
	},
};
