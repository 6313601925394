import * as React from 'react';
import { Collapse, Typography, IconButton, TypographyVariant, Link, Grid } from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { useButtonCollapseStyles } from '../../utilities/hooks/styles';

export type ArrowPlacement = 'start' | 'end';

export interface ButtonCollapseProps {
	openTitle?: string;
	closeTitle?: string;
	arrowPlacement?: ArrowPlacement;
	style?;
	titleStyle?: React.CSSProperties;
	titleVariant?: TypographyVariant;
	arrowStyle?: React.CSSProperties;
	alwaysOpen?: boolean;
	collapseHeight?: string;
	themeColor: 'default' | 'primary' | 'recommended';
}

export const ButtonCollapse: React.FC<ButtonCollapseProps> = ({
	children,
	openTitle = 'See More',
	closeTitle = 'See Less',
	arrowPlacement = 'end',
	style,
	titleStyle,
	titleVariant,
	alwaysOpen,
	collapseHeight,
	themeColor,
}) => {
	const styles = useButtonCollapseStyles();
	const [open, setOpen] = React.useState(false);

	const isStartPlacement = arrowPlacement === 'start';
	const isEndPlacement = arrowPlacement === 'end';

	const linkClassName =
		themeColor === 'primary' ? styles.primaryColor : themeColor === 'recommended' ? styles.recommendedColor : '';

	const collapseClassName =
		themeColor === 'primary'
			? styles.primaryCollapse
			: themeColor === 'recommended'
			? styles.recommendedCollapse
			: '';

	if (!children) {
		return <div />;
	}

	return (
		<div style={style}>
			<Link component="button" className={linkClassName} onClick={() => setOpen(!open)} style={titleStyle}>
				<Grid container direction="row" alignItems="center">
					{isStartPlacement && !alwaysOpen && <>{open ? <ExpandLess /> : <ExpandMore />}</>}
					<Typography variant={titleVariant || 'subtitle2'}>{open ? closeTitle : openTitle}</Typography>
					{isEndPlacement && !alwaysOpen && <>{open ? <ExpandLess /> : <ExpandMore />}</>}
				</Grid>
			</Link>
			<div className={collapseClassName}>
				<Collapse in={open} collapsedHeight={collapseHeight}>
					{children}
				</Collapse>
			</div>
		</div>
	);
};
