import { Action as ReduxAction } from 'redux';
import { Loaded } from '../utilities/utilities';
import { isType } from 'typescript-fsa';
import _ from 'lodash';
import {
	GetPolicy,
	GetAllUnlinkedPolicies,
	SetProductTab,
	LinkPolicy,
	UnlinkPolicy,
	UpdatePolicy,
	GetProductCounts,
	StorePolicies,
	MatchPolicy,
	UnmatchPolicy,
} from '../actions/policy_actions';
import { isNullOrUndefined } from 'util';
import { Contact } from './ContactReducer';
import BaseDTO from '../utilities/base_dto';
import { GetHousehold, ReassignHousehold } from '../actions/household_actions';
import {
	GetEmployer,
	UpdateEmployer,
	DeleteEmployer,
	SearchEmployers,
	ReassignEmployer,
} from '../actions/employer_actions';
import { ClearCaches } from '../actions/authentication_actions';
import { Logout } from '../actions/authentication_actions';

export type ProductStatuses =
	| 'Pending'
	| 'Active'
	| 'Terminated'
	| 'Unmatched'
	| 'ActiveCustomers'
	| 'PendingCustomers'
	| 'TerminatedCustomers';

export interface PolicyState {
	policies: Loaded<Policy>[];
	isLoading: boolean;
	applicationOrPolicyTab: AppOrPolicy;
	productStatusCounts: { [key in ProductStatuses]?: number };
}

export enum AppOrPolicy {
	Application,
	Policy,
}

export interface Policy extends BaseDTO {
	owningUser: string;
	applicationId: string;
	primaryInsured?: Contact;
	primaryInsuredName?: string;
	secondaryInsured?: Contact;
	dependents: Contact[];
	productId: string;
	productName: string;
	productType: string;
	employerId: string;
	employerName?: string;
	carrierId: string;
	actionRequired: string;
	adminFee?: number;
	annualPremium?: number;
	applicationDate?: Date;
	applicationNumber: string;
	apsOrderDate?: Date;
	carrier: string;
	carrierCaseManager: string;
	carrierReceivedDate?: Date;
	carrierStatus: string;
	carrierStatusDate?: Date;
	carrierStatusText: string;
	cashWithApp?: number;
	county: string;
	isCycleTimeExceeded: boolean;
	daysToFirstActivity?: number;
	decisionClassification: string;
	effectiveDate?: Date;
	faceAmount?: number;
	firstCaseActivityDate?: Date;
	insuredEmail: string;
	insuredType: string;
	isReviewed: boolean;
	issuedDate?: Date;
	lastCaseActivityDate?: Date;
	manualStatusReason: string;
	manualPolicyStatus: ProductStatus;
	modalPremium?: number;
	mode: string;
	nonRenewDate?: Date;
	originatingApplication: string;
	outstandingUWRequirementsCount?: number;
	paramedOrderDate?: Date;
	paymentMode: string;
	placementEndDate?: Date;
	policyExpirationDate?: Date;
	policyMailDate?: Date;
	policyNumber: string;
	premiumAmountQuoted?: number;
	splitAgentCode: string;
	splitPercentage?: number;
	state: string;
	statusCodeReason: string;
	policyStatusDate?: Date;
	statusDetail: string;
	statusReason: string;
	statusReasonDate?: Date;
	submittedDate?: Date;
	targetAmount?: number;
	vueInsuredId: string;
	vuePolicyId: string;
	policyState: string;
	policyStatus: ProductStatus;
	lineOfBusiness: string;
	createdByAgentName: string;
	updatedByAgentName: string;
	benefitAmount?: number;
	paidThroughDate?: Date;
	cancellationReason: string;
}

export enum ProductStatus {
	Pending = 3,
	Active = 1,
	Terminated = 4,
}

export const initialPolicy: Loaded<Policy> = {
	loading: false,
	data: {
		id: '',
	} as Policy,
	errors: [],
};

const initialState: PolicyState = {
	policies: [],
	isLoading: false,
	applicationOrPolicyTab: AppOrPolicy.Application,
	productStatusCounts: {},
};

export function policyReducer(
	state: PolicyState = initialState,
	action: ReduxAction
): PolicyState {
	if (isType(action, SetProductTab)) {
		const newTabToShow: AppOrPolicy = action.payload.appOrPolicy;
		return {
			...state,
			applicationOrPolicyTab: newTabToShow,
		};
	} else if (
		isType(action, GetPolicy.started) ||
		isType(action, LinkPolicy.started) ||
		isType(action, UnlinkPolicy.started) ||
		isType(action, UpdatePolicy.started) ||
		isType(action, MatchPolicy.started) ||
		isType(action, UnmatchPolicy.started)
	) {
		const policiesInState = state.policies.slice();
		const policyId =
			isType(action, GetPolicy.started) || isType(action, UnmatchPolicy.started)
				? action.payload
				: isType(action, UpdatePolicy.started) ||
					isType(action, MatchPolicy.started)
					? action.payload.id
					: action.payload.policy.id;
		upsertPolicyStart(policiesInState, { id: policyId } as Policy);
		return {
			...state,
			policies: policiesInState,
		};
	} else if (
		isType(action, GetPolicy.done) ||
		isType(action, LinkPolicy.done) ||
		isType(action, UnlinkPolicy.done) ||
		isType(action, UpdatePolicy.done) ||
		isType(action, MatchPolicy.done) ||
		isType(action, UnmatchPolicy.done)
	) {
		const policiesInState = state.policies.slice();
		upsertPolicyDone(policiesInState, action.payload.result);
		return {
			...state,
			policies: policiesInState,
		};
	} else if (
		isType(action, GetPolicy.failed) ||
		isType(action, LinkPolicy.failed) ||
		isType(action, UnlinkPolicy.failed) ||
		isType(action, UpdatePolicy.failed) ||
		isType(action, MatchPolicy.failed) ||
		isType(action, UnmatchPolicy.failed)
	) {
		const policiesInState = state.policies.slice();
		const policyId: string =
			isType(action, GetPolicy.failed) || isType(action, UnmatchPolicy.failed)
				? action.payload.params
				: isType(action, UpdatePolicy.failed) ||
					isType(action, MatchPolicy.failed)
					? action.payload.params.id
					: action.payload.params.policy.id;
		upsertPolicyDone(
			policiesInState,
			{ id: policyId } as Policy,
			action.payload.error
		);
		return {
			...state,
			policies: policiesInState,
		};
	} else if (isType(action, GetAllUnlinkedPolicies.started)) {
		return {
			...state,
			isLoading: true,
		};
	} else if (isType(action, GetAllUnlinkedPolicies.done)) {
		const policiesInState = state.policies.slice();
		updateStateWithInboundPolicies(policiesInState, action.payload.result);
		return {
			...state,
			isLoading: false,
			policies: policiesInState,
		};
	} else if (isType(action, GetAllUnlinkedPolicies.failed)) {
		return {
			...state,
			isLoading: false,
		};
	} else if (
		isType(action, GetEmployer.done) ||
		isType(action, UpdateEmployer.done) ||
		isType(action, GetHousehold.done) ||
		isType(action, StorePolicies)
	) {
		const policiesInState = state.policies.slice();
		let newPolicies: Policy[] = [];
		if (isType(action, StorePolicies)) {
			if (action.payload.clearCache) policiesInState.length = 0;
			newPolicies = action.payload.policies;
		} else {
			newPolicies = action.payload.result.policies;
		}

		updateStateWithInboundPolicies(policiesInState, newPolicies);
		return {
			...state,
			policies: policiesInState,
		};
	} else if (isType(action, DeleteEmployer.done)) {
		const policiesInState = state.policies.filter(
			policy => policy.employerId !== action.payload.params
		);
		return {
			...state,
			policies: policiesInState,
		};
	} else if (isType(action, ReassignHousehold.done)) {
		const oldPolicies = state.policies.slice();
		const newPolicies = oldPolicies.filter(policy => {
			return policy.householdId != action.payload.params.householdId;
		});
		return {
			...state,
			policies: newPolicies,
		};
	} else if (isType(action, ReassignEmployer.done)) {
		const oldPolicies = state.policies.slice();
		const newPolicies = oldPolicies.filter(policy => {
			return policy.employerId != action.payload.params.employerId;
		});
		return {
			...state,
			policies: newPolicies,
		};
	} else if (isType(action, SearchEmployers.done)) {
		const policiesInState = state.policies.slice();
		let newPolicies: Policy[] = [];
		action.payload.result.forEach(fullEmployer => {
			newPolicies = newPolicies.concat(fullEmployer.policies);
		});
		updateStateWithInboundPolicies(policiesInState, newPolicies);
		return {
			...state,
			policies: policiesInState,
			isLoading: false,
		};
	} else if (isType(action, GetProductCounts.done)) {
		return {
			...initialState,
			productStatusCounts: action.payload.result,
		};
	} else if (isType(action, Logout.started)) {
		return {
			...initialState,
		};
	} else if (isType(action, ClearCaches)) {
		return {
			...initialState,
		};
	} else {
		return state;
	}
}

export function getMatchingPolicyIndex(
	policiesToLookThrough: Loaded<Policy>[],
	policyId: string
) {
	return _.findIndex(policiesToLookThrough, (policy: Loaded<Policy>) => {
		return !isNullOrUndefined(policy.data) && policy.data.id === policyId;
	});
}

function upsertPolicyStart(cachedPolicies: Loaded<Policy>[], policy: Policy) {
	const matchedPolicyIndex = _.findIndex(
		cachedPolicies,
		cachedPolicy => cachedPolicy.data.id === policy.id
	);
	if (matchedPolicyIndex !== -1) {
		cachedPolicies[matchedPolicyIndex] = {
			...cachedPolicies[matchedPolicyIndex],
			loading: true,
			employerId: policy.employerId,
			householdId: policy.primaryInsured
				? policy.primaryInsured.householdId
				: undefined,
		};
	} else {
		const newPolicy: Loaded<Policy> = {
			data: policy,
			loading: true,
			employerId: policy.employerId,
			householdId: policy.primaryInsured
				? policy.primaryInsured.householdId
				: undefined,
			errors: [],
		};
		cachedPolicies.push(newPolicy);
	}
}

function upsertPolicyDone(
	cachedPolicies: Loaded<Policy>[],
	updatedPolicy: Policy,
	error?: any
) {
	const index = _.findIndex(
		cachedPolicies,
		cachedPolicy => cachedPolicy.data.id === updatedPolicy.id
	);
	if (index > -1) {
		if (error)
			cachedPolicies[index] = {
				...cachedPolicies[index],
				loading: false,
				errors: [...cachedPolicies[index].errors, error],
			};
		else if (updatedPolicy.isDeleted) cachedPolicies.splice(index, 1);
		else
			cachedPolicies[index] = {
				...cachedPolicies[index],
				data: updatedPolicy,
				employerId: updatedPolicy.employerId,
				householdId: updatedPolicy.primaryInsured
					? updatedPolicy.primaryInsured.householdId
					: undefined,
				loading: false,
				errors: [],
			};
	} else {
		const newPolicy: Loaded<Policy> = {
			data: updatedPolicy,
			loading: false,
			employerId: updatedPolicy.employerId,
			householdId: updatedPolicy.primaryInsured
				? updatedPolicy.primaryInsured.householdId
				: undefined,
			errors: error ? [error] : [],
		};
		cachedPolicies.push(newPolicy);
	}
}

function updateStateWithInboundPolicies(
	policiesInState: Loaded<Policy>[],
	inboundPolicies: Policy[]
) {
	inboundPolicies.forEach(inboundPolicy => {
		const policyId = inboundPolicy.id;
		let wasAPolicyUpdated = false;

		policiesInState.forEach((currentPolicy, index) => {
			const currentPolicyId = currentPolicy.data.id;
			if (currentPolicyId === policyId) {
				wasAPolicyUpdated = true;
				policiesInState[index] = {
					...policiesInState[index],
					loading: false,
					data: inboundPolicy,
					householdId: inboundPolicy.primaryInsured
						? inboundPolicy.primaryInsured.householdId
						: undefined,
					employerId: inboundPolicy.employerId,
					errors: [],
				};
			}
		});
		if (!wasAPolicyUpdated) {
			const newPolicy: Loaded<Policy> = {
				loading: false,
				data: inboundPolicy,
				errors: [],
				householdId: inboundPolicy.primaryInsured
					? inboundPolicy.primaryInsured.householdId
					: undefined,
				employerId: inboundPolicy.employerId,
			};
			policiesInState.push(newPolicy);
		}
	});
}
