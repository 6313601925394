import { StartSmsSequence, SelectContactForSmsSequence, CancelSmsSequence, StoreSmsTemplate, ChangeSmsSequenceStep } from './../../actions/sequence/sms_sequence_actions';
import { PhoneMetaData, composePhoneMetaData } from '../../utilities/phone_email_util';
import { isType } from 'typescript-fsa';
import { Logout } from '../../actions/authentication_actions';
import { ClearCaches } from '../../actions/authentication_actions';
import { Action } from 'redux';
import { ToggleLeadListMultiSelect } from '../../actions/lead_actions';
import { SmsTemplate } from '../sms_template_reducer';

export enum SmsSequenceStep {
	Inactive,
	SelectContact,
	SelectTemplate,
}

export interface SmsSequenceState {
	step: SmsSequenceStep;
	phoneMetaData: PhoneMetaData;
	contactId?: string;
	contactPhoneNumber?: string;
	leadListMultiSelect: boolean;
	template?: SmsTemplate;
}

const initialState: SmsSequenceState = {
	step: SmsSequenceStep.Inactive,
	phoneMetaData: {
		doNotContact: false,
		phones: [],
		preferred: '',
		doNotSms: false,
	},
	contactId: undefined,
	contactPhoneNumber: undefined,
	leadListMultiSelect: false,
	template: undefined,
};


export function smsSequenceReducer(
	state: SmsSequenceState = initialState,
	action: Action
): SmsSequenceState {
	if (isType(action, StartSmsSequence)) {
		const phoneMetaData = composePhoneMetaData(action.payload);
		return {
			...state,
			phoneMetaData,
			step: action.payload.length >= 1 ? SmsSequenceStep.SelectContact : SmsSequenceStep.SelectTemplate,
		};
	} else if (isType(action, SelectContactForSmsSequence)) {
		return {
			...state,
			...action.payload,
			step: state.leadListMultiSelect
				? SmsSequenceStep.SelectContact
				: SmsSequenceStep.SelectTemplate,
		};
	} else if (isType(action, ChangeSmsSequenceStep)) {
		return {
			...state,
			step: action.payload,
		};
	} else if (isType(action, ToggleLeadListMultiSelect)) {
		return {
			...state,
			leadListMultiSelect: action.payload,
		};
	} else if (isType(action, StoreSmsTemplate)) {
		return {
			...state,
			template: action.payload,
		};
	 } else if (isType(action, Logout.started) || isType(action, ClearCaches)) {
		return {
			...initialState,
		};
	} else if (isType(action, CancelSmsSequence)) {
		return {
			...initialState,
			leadListMultiSelect: state.leadListMultiSelect,
		};
	} else {
		return state;
	}
}
