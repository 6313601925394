import { applyMiddleware, createStore, Action } from 'redux';
import { appReducer } from '../../reducers/index';
import { persistState } from 'redux-devtools';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from '../../sagas/index';
import { createLogger } from 'redux-logger';
import { CheckTimeout, UpdateIdle } from '../../actions/authentication_actions';
import { Middleware } from 'redux';
import { AppConfig } from '../../types/config';

namespace redux_store {
	declare const module: any;
	declare const window: any;

	const actionPredicate = (state: any, action: Action<any>) => !(
		Boolean(action.type) && (
			action.type === CheckTimeout.type ||
			action.type === UpdateIdle.type ||
			action.type.indexOf('redux-form') > -1)
	);

	const sagaMiddleware = createSagaMiddleware();
	
	const logger = createLogger({
		predicate: actionPredicate,
	});

	export function configure<S>(initialState: S) {
		const middles: Middleware[] = [
			sagaMiddleware,
		];
		let enhancer;
		if (AppConfig.environment !== 'production') {
			middles.push(logger);
			const composeEnhancers = composeWithDevTools({
				predicate: actionPredicate
			});
			enhancer = composeEnhancers(
				applyMiddleware(...middles),
				persistState(window.location.href.match(/[?&]debug_session=([^&#]+)\b/))
			);
		} else {
			enhancer = applyMiddleware(...middles);
		}

		const store = createStore(appReducer, initialState as any, enhancer);
		sagaMiddleware.run(rootSaga);

		if (module.hot) {
			module.hot.accept('../../reducers', () =>
				store.replaceReducer(require('../../reducers').default)
			);
		}

		return store;
	}
}

export default redux_store;