import React from 'react';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { connect } from '@optum-uhone-hmkts/rise';
import { AppState } from '../../reducers/index';
import { NavigationProps, navRoutes } from '../../components/nav/Routes';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import { navigateToWithoutAddingToHistory } from '../../actions/navigation_actions';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Grid,
	Icon,
} from '@material-ui/core';
import {
	Loaded,
	isLoading,
	hasBLead,
	getHeaderSubTitlesToDisplay,
} from '../../utilities/utilities';
import { Contact } from '../../reducers/ContactReducer';
import { Activity } from '../../reducers/activity_reducer';
import { AppOrPolicy, ProductStatus } from '../../reducers/policy_reducer';
import { Application } from '../../reducers/application_reducer';
import moment from 'moment';
import { ContactHeaderBar } from '../../components/Layout/contact_header_bar';
import { LeadStatusCard } from '../../components/lead/LeadStatusCard';
import { getPageNotes } from '../../selectors/note_selectors';
import { getPageAttachments } from '../../selectors/attachment_selectors';
import {
	getPageContacts,
	getPrimaryContact,
} from '../../selectors/contact_selectors';
import { getPageLeads, getOpenLead, getMostRecentPageLead } from '../../selectors/lead_selectors';
import {
	getPageActivities,
	getNextActivity,
} from '../../selectors/activity_selectors';
import { getPageApplications } from '../../selectors/application_selectors';
import { getPagePolicies } from '../../selectors/policy_selectors';
import { getCurrentAgentDisplayName } from '../../selectors/agent_selectors';
import { getId } from '../../selectors/base_selectors';
import { ContactListCard } from '../../components/contact/contact_list';
import { LeadListCard } from '../../components/lead/lead_list';
import { OwnershipListCard } from '../../components/lead/ownership_card';
import { CompanyInfoCard } from '../../components/employer/employer_detail';
import { OpenActivityListCard } from '../../components/activity/open_activity_list';
import { CompletedActivityListCard } from '../../components/activity/completed_activity_list';
import { NoteListCard } from '../../components/notes/note_list';
import { ProductListCard } from '../../components/product/product_list';
import { AttachmentListCard } from '../../components/attachment/attachment_list';
import {
	ContactLiveSearch,
	AddContactToEmployer,
} from '../../actions/contact_actions';
import { Employer } from '../../reducers/employer_reducer';
import { PrimaryContactCard } from '../../components/contact/primary_contact';
import { Attachment } from '../../reducers/attachment_reducer';
import { Note } from '../../reducers/note_reducer';
import {
	GetEmployer,
	UpdateEmployer,
	SetPrimaryEmployee,
	DeleteEmployer,
	ReassignEmployer,
	EmployerTagPayload,
	saveEmployerTags,
} from '../../actions/employer_actions';
import { FabMenu } from '../../components/nav/fab_menu';
import { AdminInfo } from '../../components/admin/admin_info';
import { jwt_auth } from '../../utilities/auth';
import { getPageEmployer } from '../../selectors/employer_selectors';
import { NextActivityCard } from '../../components/activity/next_activity_card';
import { AddExistingContactDialog } from '../../components/employer/add_existing_contact_dialog';
import { ResetInfiniteScroll } from '../../actions/infinite_scroll_actions';
import { getCurrentAgentCode } from '../../selectors/agent_selectors';
import { ReassignDialog } from '../../components/reassign/reassign_dialog';
import { getPageOwnershipHistory } from '../../selectors/ownership_history_seletors';
import { Lookup, Lookups } from '../../utilities/lookup';
import { themePalette } from '../../utilities/branding';
import { MoreMenu, createMenuAction } from '../../components/nav/more_menu';
import { FollowUpActivityType } from '../activity/upsert_activity';
import { WideButton } from '../../components/utility/wide_button';
import { P } from '../../utilities/auth/permissions';
import {
	LeadPage, StateProps as LeadStateProps, DispatchProps as LeadDispatchProps,
	ComponentProps as LeadComponentProps, State as LeadState, mapDispatchToProps as leadMapDispatchToProps
} from '../lead_page';
import { getLeadRouteOriginPage } from '../../selectors/lead_route_selectors';
import { Strings } from '../../assets/common/strings';
import { EMPTY_LEAD } from '../../utilities/empty_entities';
import { isCompanyLead } from '../../utilities/lead_util';
import { nav } from '../..';
import { TagsList } from '../../components/lead/tags_list';
import { openExternalLink } from '../../utilities';
import { AppConfig } from '../../types/config';

/**
 * Props & State
 */
interface StateProps extends LeadStateProps {
	contactPageIndex: number;
	contactPageSize: number;
	employees: Loaded<Contact>[];
	employer?: Loaded<Employer>;
	employerId: string;
	hasMoreContacts: boolean;
	loadedContacts: Loaded<Contact>[];
	desktopCallEnabled: boolean;
	contactSearchLoading: boolean;
}

interface DispatchProps extends LeadDispatchProps {
	addContactToEmployer: (contact: Contact) => void;
	contactLiveSearch: (
		searchString: string,
		includeEmployees: boolean,
		pageIndex: number,
		pageSize: number
	) => void;
	deleteEmployer: (employer: Employer) => void;
	getEmployer: (id: string) => void;
	reassignEmployer: (
		householdId: string,
		agentCode: string,
		agentName: string,
	) => void;
	resetLiveSearch: () => void;
	setPrimaryEmployee: (employerId: string, contactId: string) => void;
	updateEmployer: (employer: Employer) => void;
	saveEmployerTags: (payload: EmployerTagPayload) => void;
}

interface ComponentProps extends LeadComponentProps {
}

type Props = StateProps & DispatchProps & ComponentProps & NavigationProps;
interface State extends LeadState {
	activityToDisposition?: Loaded<Activity>;
	addContactDialogIsOpen: boolean;
	confirmDelete: boolean;
	contactSearchString: string;
	showCannotDeleteReassignDialog: boolean,
}

/**
 * Employer Page
 */
class EmployerPage extends LeadPage<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			confirmDelete: false,
			createNoteDialogOpen: false,
			addAttachmentDialogIsOpen: false,
			leadAgentSearched: false,
			contactSearchString: '',
			addContactDialogIsOpen: false,
			contactId: '',
			showReassignDialog: false,
			showCannotDeleteReassignDialog: false,
		};

		this.handleReassignToAgent = this.handleReassignToAgent.bind(this);
	}

	/**
	 * Lifecycle Hooks
	 */
	componentWillMount() {
		if (!this.isLoading()) {
			this.props.getEmployer(this.props.employerId);
		}
	}

	componentWillReceiveProps(nextProps: Props) {
		if (this.props.employerId != nextProps.employerId) {
			this.props.getEmployer(nextProps.employerId);
		}

		if (nextProps.employer && nextProps.employer.data.userId && !this.state.leadAgentSearched) {
			this.props.getAgentWithAgentId(nextProps.employer.data.userId)
			this.setState({
				leadAgentSearched: true
			})
		}
	}

	getMeta = () => ({
		employerId: this.props.employerId,
		createAppointmentUrl: navRoutes.appointmentCreate.path
			.replace(`/${Strings.Navigation.HouseholdId}?`, ''),
		editPhoneUrl: navRoutes.phoneEdit.path
			.replace(`/${Strings.Navigation.HouseholdId}?`, '')
			.replace(Strings.Navigation.Reschedule, FollowUpActivityType.PHONE_CALL)
	});
	getBaseUrl = () => navRoutes.employer.path;

	replaceIdInUrl = (url: string) => url.replace(Strings.Navigation.EmployerId, this.props.match.params.employerID);

	/**
	 * Business Logic
	 */
	isLoading = (): boolean => {
		return this.props.employer ? this.props.employer.loading : false;
	};

	/**
	 * onClick
	 */
	onActivityDeleteClick = (activityToUpdate: Activity) => {
		let _activityToUpdate = Object.assign({}, activityToUpdate);
		const currentTime = moment.utc().toDate();
		_activityToUpdate.updatedBy = this.props.userId;
		_activityToUpdate.modifiedByName = this.props.currentAgentDisplayName;
		_activityToUpdate.updatedOn = currentTime;
		this.props.deleteActivity(_activityToUpdate);
	};

	onActivityEditClick = (activity: Activity) => {
		const route =
			activity.type == 'Phone Call'
				? navRoutes.phoneEdit
				: activity.type == Strings.Activity.Appointment
					? navRoutes.appointmentEdit
					: navRoutes.activityEdit;
		this.props.navigate(
			route.path
				.replace(Strings.Navigation.ActivityId, activity.id)
				.replace(Strings.Navigation.Reschedule, '0')
		);
	};

	onActivityNavigationClick = (activity?: Activity) => {
		if (!activity) return;

		switch (activity.type) {
			case Strings.Activity.Task:
				this.props.navigate(
					navRoutes.taskDetail.path
						.replace(Strings.Navigation.ActivityId, activity.id)
				);
				break;

			case Strings.Activity.PhoneCall:
				this.props.navigate(
					navRoutes.phoneDetail.path
						.replace(Strings.Navigation.ActivityId, activity.id)
				);
				break;

			case Strings.Activity.Appointment:
				this.props.navigate(
					navRoutes.appointmentDetail.path.replace(Strings.Navigation.ActivityId, activity.id)
				);
				break;

			case Strings.Activity.LeadStatusChange:
				this.props.navigate(
					navRoutes.leadDetail.path
						.replace(Strings.Navigation.HouseholdId, activity.householdId || '')
						.replace(Strings.Navigation.LeadId, activity.lead || '')
						.replace(Strings.Navigation.FromAdvSearch, 'detail')
				);
				break;

			case Strings.Activity.Email:
				this.props.navigate(
					navRoutes.templatePreview.path
						.replace(Strings.Navigation.TemplateId, activity.description)
						.replace(Strings.Navigation.From, 'History')
				);
				break;

			case Strings.Activity.Sms:
				activity.disposition && this.props.navigate(
					navRoutes.smsTemplatePreview.path
						.replace(Strings.Navigation.TemplateId, activity.disposition)
						.replace(Strings.Navigation.TemplatePageState, "Preview"));
				break;
		}
	};

	startPhoneCallDisposition = (activityId?: string) => {
		this.setState({
			activityToDisposition: this.props.activities.find(activity => activity.data.id == activityId) || {} as Loaded<Activity>
		})
	}

	onDeleteClick = () => {
		this.setState({
			confirmDelete: true,
		});
	};

	handleDelete = () => {
		if (!this.props.employer) return;
		this.props.deleteEmployer(this.props.employer.data);
		this.props.navigate(navRoutes.leadDashboard.path)
	}

	handleCancelDelete = () => {
		this.setState({
			confirmDelete: false,
		});
	};

	onApplicationDeleteClick = (applicationToUpdate: Application) => {
		let _applicationToUpdate = Object.assign({}, applicationToUpdate);
		const name = this.props.currentAgentDisplayName;
		const currentTime = moment.utc().toDate();
		_applicationToUpdate.updatedBy = this.props.userId;
		_applicationToUpdate.updatedByAgentName = name;
		_applicationToUpdate.updatedOn = currentTime;
		this.props.deleteApplication(_applicationToUpdate);
	};

	onAttachmentDeleteClick = (attachment: Attachment) => {
		this.props.deleteAttachment(attachment);
	};

	onAttachmentDownloadClick = (attachment: Attachment) => {
		this.props.downloadAttachment(attachment);
	};

	onProductNavigationClick = (productId: string, policyOrApp: 'application' | 'policy') => {
		if (policyOrApp === 'application') {
			this.props.setProductTab(AppOrPolicy.Application);
		} else {
			this.props.setProductTab(AppOrPolicy.Policy);
		}

		this.props.navigate(navRoutes.productDetail.path.replace(Strings.Navigation.ProductId, productId))
	};

	handleAddExistingContactToEmployer = (contactId: string) => {
		const loadedContact = this.props.loadedContacts.find(
			loadedContact => loadedContact.data.id == contactId
		);
		if (loadedContact && loadedContact.data) {
			const contactToUpdate = {
				...loadedContact.data,
				employerId: this.props.employerId,
			};
			this.props.addContactToEmployer(contactToUpdate);
		}
		this.setState({ addContactDialogIsOpen: false });
	};

	handleRemoveContactFromEmployer = (contactId: string) => {
		if (!this.props.employer) return;
		const newEmployer = Object.assign({}, this.props.employer).data;
		const contactIndex = newEmployer.contacts.findIndex(contact => {
			return contact.id == contactId;
		});
		if (contactIndex > -1) {
			const wasPrimaryContact =
				newEmployer.contacts[contactIndex].employerPrimaryContact;
			newEmployer.contacts.splice(contactIndex, 1);
			if (wasPrimaryContact && newEmployer.contacts.length > 0)
				newEmployer.contacts[0].employerPrimaryContact = true;
			this.props.updateEmployer(newEmployer);
		}
	};

	handleSetNewPrimaryContactOnEmployer = (newPrimaryContactId: string) => {
		if (!this.props.employer) return;
		this.props.setPrimaryEmployee(this.props.employer.data.id, newPrimaryContactId);
	};

	handleNoSaleFollowUp = (status: Lookup, employerId: string) => {
		var followUpDescription = Lookups.NoSale.label + (status.label ? ' - ' + status.label : '');

		this.props.storeFollowupDescription({
			description: followUpDescription,
			time: moment(),
		});

		this.props.navigate(navRoutes.phoneEdit.path
			.replace(`/${Strings.Navigation.HouseholdId}?`, '')
			.replace(Strings.Navigation.EmployerId, employerId)
			.replace(Strings.Navigation.Reschedule, FollowUpActivityType.PHONE_CALL_3_MONTHS)
			.replace(Strings.Navigation.ActivityId, '0')
		);
	}

	setReassignDialogVisibility(visible: boolean) {
		this.setState({
			showReassignDialog: visible,
		});
	}

	setCannotDeleteOrReassignDialogVisibility(visible: boolean) {
		this.setState({
			showCannotDeleteReassignDialog: visible,
		});
	}

	handleReassignToAgent(agentCode: string, agentName: string) {
		this.setReassignDialogVisibility(false);
		this.props.reassignEmployer(
			this.props.employerId,
			agentCode,
			agentName,
		);
	}

	renderHeaderBar(): JSX.Element {
		const { clickToCallEnabled, hasActiveBusinessRelationship } = this.props;

		const canDeleteOrReassign = this.props.policies.length === 0 && this.props.applications.length === 0;
		const editAction = createMenuAction(Strings.MoreMenu.Edit, () => this.props.navigate(
			navRoutes.employerUpsert.path.replace(
				Strings.Navigation.EmployerId,
				this.props.employerId
			)
		));
		const deleteAction = createMenuAction(Strings.MoreMenu.Delete, () => {
			if (canDeleteOrReassign)
				this.onDeleteClick();
			else
				this.setCannotDeleteOrReassignDialogVisibility(true);
		});
		const reassignAction = createMenuAction(Strings.MoreMenu.Reassign, () => {
			if (canDeleteOrReassign) {
				this.setReassignDialogVisibility(true);
			} else {
				this.setCannotDeleteOrReassignDialogVisibility(true);
			}
		},
			!jwt_auth.hasPermission(P.ReassignLead) || clickToCallEnabled,
		);
		const helpAction = this.createHelpMenuAction();
		const quoteAction = createMenuAction(Strings.MoreMenu.Quote, () => {
			const url = AppConfig.benefitter_link;
			openExternalLink(url);
		});
		const printAction = this.createPrintMenuAction();


		const subtitles = getHeaderSubTitlesToDisplay(this.props.primaryContact, (this.props.employer && this.props.employer.data));

		const goToDetails = this.getGoToDetailsFunction();

		const shouldShowLeadNavigationArrows = this.getShouldShowLeadNavigationArrows();
		const hasNextLead = this.getHasNextLead();
		const hasPreviousLead = this.getHasPreviousLead();

		return (
			<ContactHeaderBar
				employerId={this.props.employerId}
				householdId={this.props.primaryContact.householdId}
				rightButtons={
					<MoreMenu
						children={[
							reassignAction,
							quoteAction,
							editAction,
							deleteAction,
							printAction,
							helpAction
						]}
					/>}
				contacts={this.props.employees.map(contact => contact.data)}
				primaryContact={this.props.primaryContact}
				isLoading={this.isLoading()}
				title='Employer'
				subtitles={subtitles}
				hideTopRow={!!this.props.navigateFromParent}
				navigateTo={nav.push}
				goToDetails={goToDetails}
				navigatePreviousEnabled={shouldShowLeadNavigationArrows && hasPreviousLead && !clickToCallEnabled}
				navigateNextEnabled={shouldShowLeadNavigationArrows && hasNextLead && !clickToCallEnabled}
				navigateToLead={this.handleNavigateToNext}
				hasActiveBusinessRelationship={hasActiveBusinessRelationship}
			/>
		);
	}

	renderStatusCard() {
		if (!this.props.hasActiveLead) return null;
		return (
			<LeadStatusCard
				lead={this.props.openLead || EMPTY_LEAD}
				updateLead={this.props.updateLead}
				navigateToEmployerFollowup={this.handleNavigateToFollowup}
				lookups={this.props.lookups}
			/>
		);
	}

	renderNextActivityCard(): JSX.Element {
		return (
			<NextActivityCard
				nextActivity={this.props.nextActivity}
				onClick={() => this.onActivityNavigationClick(this.props.nextActivity)}
			/>
		);
	}

	renderQuoteCard(): JSX.Element {
		const openBenefitter = () => {
			const url = AppConfig.benefitter_link;
			openExternalLink(url);
		};
		return (
			<WideButton
				primary={'Quote'}
				secondary={'Benefitter'}
				icon={<Icon style={{ marginRight: 0 }}>open_in_new</Icon>}
				onClick={openBenefitter}
			/>
		);
	}

	renderCompanyInfoCard(): JSX.Element {
		return (
			<CompanyInfoCard
				startCollapsed={true}
				title={'Company Info'}
				employer={this.props.employer}
			/>
		);
	}

	renderPrimaryContactCard(): JSX.Element {
		const hasOpenPhoneCalls = this.props.activities.some(activity =>
			activity.data.status == Strings.ActivityStatus.Open
			&& activity.data.type == Strings.Activity.PhoneCall);
		return (
			<PrimaryContactCard
				title={'Primary Contact Info'}
				contact={this.props.primaryContact}
				navigateTo={this.props.navigate}
				desktopCallEnabled={this.props.desktopCallEnabled}
				phoneClick={() => this.props.startCallSequence(this.props.employees.map(loadedEmployer => loadedEmployer.data), hasOpenPhoneCalls)}
				history={this.props.history}
				noPadding
			/>
		);
	}

	renderLeadsCard(): JSX.Element {
		const { leads, hasActiveLead } = this.props;
		return (
			<LeadListCard
				isLoading={isLoading(leads)}
				title={'Leads Summary'}
				leads={leads}
				parentId={this.props.employerId}
				startCollapsed={!hasActiveLead}
				onLeadClick={(householdID: string, leadID: string) => {
					if (this.props.isAdvancedSearch) {
						this.props.navigate(
							navRoutes.leadDetailAdvSearch.path
								.replace(Strings.Navigation.HouseholdId, this.props.primaryContact.householdId)
								.replace(Strings.Navigation.LeadId, leadID)
								.replace(Strings.Navigation.FromAdvSearch, 'true')
						)
					} else {
						this.props.navigate(
							navRoutes.leadDetail.path
								.replace(Strings.Navigation.HouseholdId, this.props.primaryContact.householdId)
								.replace(Strings.Navigation.LeadId, leadID)
						)
					}
				}}
				contentCount={leads.length}
				noPadding
			/>
		);
	}

	renderOpenActivitiesCard(): JSX.Element {
		const openActivities = this.props.activities?.filter(
			activity => activity.data.status == Strings.ActivityStatus.Open && activity.data.userId === this.props.userId
		);
		return (
			<OpenActivityListCard
				title={'Open To-Do Summary'}
				openActivities={openActivities}
				handleActivityDeleteClick={this.onActivityDeleteClick}
				handleActivityEditClick={this.onActivityEditClick}
				handleNavigateToActivity={this.onActivityNavigationClick}
				startCollapsed={!openActivities?.length}
				contentCount={openActivities?.length}
				noPadding
			/>
		);
	}

	renderCompletedActivitiesCard(): JSX.Element {
		const completedActivities = this.getCompletedActivities();

		return (
			<CompletedActivityListCard
				isLoading={isLoading(completedActivities)}
				title={'History Summary'}
				completedActitvites={completedActivities}
				handleClick={this.onActivityNavigationClick}
				handleDeleteClick={this.onActivityDeleteClick}
				startCollapsed
				contentCount={completedActivities.length}
				noPadding
			/>
		);
	}

	renderNotesCard(): JSX.Element {
		return (
			<NoteListCard
				isLoading={isLoading(this.props.notes)}
				title={'Notes'}
				notes={this.props.notes}
				handleDeleteNote={(note: Note) => this.props.deleteNote(note)}
				canDelete
				createNoteDialogIsOpen={this.state.createNoteDialogOpen}
				updateCreateNoteDialogState={(isOpen: boolean) =>
					this.setState({ createNoteDialogOpen: isOpen })
				}
				handleSaveNote={(noteContent: string) =>
					this.handleSaveNote(noteContent)
				}
				updateNote={this.props.updateNote}
				createNoteFullScreen={!this.props.clickToCallEnabled}
				startCollapsed
				contentCount={this.props.notes?.length}
				noPadding
			/>
		);
	}

	renderProductsCard(): JSX.Element {
		const { applications, policies } = this.props;
		const productCount = (applications?.length || 0) + (policies?.length || 0);
		return (
			<ProductListCard
				isLoading={isLoading(applications) || isLoading(policies)}
				title={'Products Summary'}
				applications={applications}
				policies={policies}
				handleApplicationDeleteClick={this.onApplicationDeleteClick}
				handleProductClick={this.onProductNavigationClick}
				startCollapsed
				contentCount={productCount}
				noPadding
			/>
		);
	}

	renderAttachmentsCard(): JSX.Element {
		return (
			<AttachmentListCard
				isLoading={isLoading(this.props.attachments)}
				title={'Attachment Summary'}
				attachments={this.props.attachments.map(attachment => attachment.data)}
				onDelete={this.onAttachmentDeleteClick}
				onDownload={this.onAttachmentDownloadClick}
				startCollapsed
				contentCount={this.props.attachments?.length}
				noPadding
			/>
		);
	}

	renderEmployeesCard(): JSX.Element {
		const employerID = this.props.match.params.employerID;
		const employeeCount = this.props.employees.length;
		const roleSpan = {
			setSpanText: (contact: Contact) => {
				return contact.employerPrimaryContact ? 'Primary' : '';
			},
			newCss: {
				float: 'right',
				marginRight: 20,
				fontSize: 'small',
			} as React.CSSProperties,
		};
		const sortedContacts: Loaded<Contact>[] = this.props.employees.map(contact => ({ ...contact })).sort((a, b) => {
			if (a.data.employerPrimaryContact) return -1;
			else if (b.data.employerPrimaryContact) return 1;
			else {
				return a.data.lastName < b.data.lastName ? -1 : 1;
			}
		})
		return (
			<ContactListCard
				isLoading={isLoading(sortedContacts)}
				title={'Employees'}
				onContactClick={(contact: Contact) =>
					this.props.navigate(
						navRoutes.contactDetail.path
							.replace(Strings.Navigation.HouseholdId, contact.householdId)
							.replace(Strings.Navigation.ContactId, contact.id)
					)
				}
				contacts={sortedContacts}
				setNewPrimaryContact={(contactId: string) =>
					this.handleSetNewPrimaryContactOnEmployer(contactId)
				}
				removeContact={(contactId: string) =>
					this.handleRemoveContactFromEmployer(contactId)
				}
				parentId={employerID}
				customSpans={[roleSpan]}
				deletePerson={(contact) => {
					this.props.deleteContact(contact)
					employeeCount > 1 ?
						null :
						this.props.navigate(navRoutes.leadDashboard.path)
				}}
				policies={this.props.policies}
				returnToDetailPage={() => {
					this.props.navigate(
						navRoutes.employer.path.replace(
							Strings.Navigation.EmployerId,
							this.props.employerId
						)
					);
				}}
				goToLeadsDashboard={() => {
					this.props.navigate(
						navRoutes.leadDashboard.path
					)
				}}
				deleteContactDisabled={this.props.employees.length == 1 &&
					(this.props.policies.length > 0 || this.props.applications.length > 0)}
				enableMenu
				deleteFromHousehold={false}
				showJobTitles={true}
				editPerson={(contact: Contact) => {
					if (contact && contact.id && contact.householdId) {
						this.props.navigate(navRoutes.contactUpsert.path
							.replace(Strings.Navigation.HouseholdId, contact.householdId)
							.replace(Strings.Navigation.ContactId, contact.id));
					}
				}}
				contentCount={sortedContacts.length}
				startCollapsed
				noPadding
			/>
		);
	}

	renderAdminInfoCard(): JSX.Element {
		const { mostRecentPageLead, isAdvancedSearch, employer } = this.props;
		return (
			<AdminInfo
				employer={
					employer ? employer.data : ({} as Employer)
				}
				showDisclaimer={!isCompanyLead(mostRecentPageLead)}
				isAdvancedSearch={isAdvancedSearch}
			/>
		);
	}

	renderOwnershipCard(): JSX.Element {

		const { employer, leadAgent, ownershipHistories } = this.props;

		const leadAgentName = `${leadAgent.preferredOrFirstName} ${leadAgent.lastName}`
		return (
			<OwnershipListCard
				title={'Ownership History'}
				ownershipHistory={ownershipHistories}
				leadAgent={leadAgentName}
				createdOn={employer?.data?.createdOn}
				createdByName={employer?.data?.createdByName || ''}
				startCollapsed
				noPadding
			/>
		);
	}

	renderFabMenu() {
		const icons: any[] = [
			{
				label: 'Attachment',
				icon: 'attach_file',
				onClick: () => {
					this.setState({ addAttachmentDialogIsOpen: true });
				},
			},
			{
				label: 'Application',
				icon: 'event',
				onClick: () => {
					const path = navRoutes.applicationUpsert.path
						.replace(Strings.Navigation.EmployerId, this.props.employerId)
					this.props.navigate(
						path.replace(Strings.Navigation.ApplicationId, 'new')
					)
				},
			},
			{
				label: 'Add Lead',
				icon: 'perm_identity',
				onClick: () => {
					this.props.navigate(
						navRoutes.employerLeadUpsert.path
							.replace(Strings.Navigation.LeadId, '0')
							.replace(Strings.Navigation.EmployerId, this.props.match.params.employerID)
					);
				},
				disabled: !jwt_auth.hasPermission(P.CreateLead) || this.props.hasActiveLead,
			},
			{
				label: 'Add Employee',
				icon: 'perm_identity',
				onClick: () => {
					this.setState({ addContactDialogIsOpen: true });
				},
			},
			{
				label: 'Phone Call',
				icon: 'local_phone',
				onClick: () => {
					this.props.navigate(
						navRoutes.phoneCreate.path
							.replace(Strings.Navigation.EmployerId, this.props.employerId)
							.replace(`/${Strings.Navigation.HouseholdId}?`, '')
							.replace(Strings.Navigation.FollowUp, '0')
					);
				},
				disabled: !jwt_auth.hasPermission(P.CreateActivity),
			},
			{
				label: 'Appointment',
				icon: 'date_range',
				onClick: () => {
					this.props.navigate(
						navRoutes.appointmentCreate.path
							.replace(Strings.Navigation.EmployerId, this.props.employerId)
							.replace(`/${Strings.Navigation.HouseholdId}?`, '')
					);
				},
				disabled: !jwt_auth.hasPermission(P.CreateActivity),
			},
			{
				label: 'Note',
				icon: 'event_note',
				onClick: () => this.setState({ createNoteDialogOpen: true }),
				disabled: false,
			},
		];
        return <FabMenu disabled={this.isLoading()} items={icons} placeholderPropsIds={["FactFinder-FactFinderFabItem"]} />;
	}

	renderCannotReassignDialog() {
		return (
			<Dialog
				open={this.state.showCannotDeleteReassignDialog}
			>
				<DialogContent>
					There are one or more products tied to this employer. You cannot delete nor reassign this employer.
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						color="primary"
						onClick={() => this.setCannotDeleteOrReassignDialogVisibility(false)}
					>
						Ok
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	renderDeleteDialog() {
		return (
			<Dialog
				disableBackdropClick
				disableEscapeKeyDown
				open={this.state.confirmDelete}
			>
				<DialogTitle>Confirmation</DialogTitle>
				<DialogContent>
					If you delete this employer, all associated leads and activities will
					be deleted. Are you sure you want to delete this employer?
        		</DialogContent>
				<DialogActions>
					<Button color="secondary" onClick={this.handleCancelDelete}>
						Cancel
          			</Button>
					<Button
						color="primary"
						style={{ backgroundColor: themePalette.delete_remove_reject_button }}
						onClick={this.handleDelete}
						variant="contained"
					>
						Delete
          			</Button>
				</DialogActions>
			</Dialog>
		);
	}

	renderAddExistingContactDialog() {
		const searchTokens = this.state.contactSearchString.split(' ');

		const filteredContacts = this.props.loadedContacts
			.filter(loadedContact => {
				const contact = loadedContact.data;
				const emails = (contact.emails || []).map(email => email.emailAddress);
				const phones = (contact.phones || []).map(phone => phone.number);
				const searchArray = [
					contact.firstName,
					contact.preferredName,
					contact.lastName,
				].concat(emails, phones);

				const searchString = searchArray.join().toLowerCase();
				searchString.replace(/\s/g, '');
				const matchSearch = searchTokens.every(
					token => searchString.indexOf(token.toLowerCase()) > -1
				);
				return matchSearch && !contact.employerId;
			})
			.map(contact => contact.data)
			.sort((a, b) => {
				if (a.lastName < b.lastName) return -1;
				else if (a.lastName > b.lastName) return 1;
				else if (a.firstName < b.firstName) return -1;
				else if (a.firstName > b.firstName) return 1;
				else return 0;
			});
		return (
			<AddExistingContactDialog
				companyName={
					this.props.employer && this.props.employer.data
						? this.props.employer.data.companyName
						: 'this employer'
				}
				visible={this.state.addContactDialogIsOpen}
				contacts={
					this.state.contactSearchString.length > 0 ? filteredContacts : []
				}
				closeDialog={() =>
					this.setState({ addContactDialogIsOpen: false })
				}
				addContactToEmployer={this.handleAddExistingContactToEmployer}
				handleSearch={(searchString: string) => {
					this.props.contactLiveSearch(
						searchString.trim(),
						false,
						this.props.contactPageIndex,
						this.props.contactPageSize
					);
					this.setState({
						contactSearchString: searchString.toLowerCase(),
					});
				}}
				resetSearch={() => {
					this.props.resetLiveSearch();
					this.setState({
						contactSearchString: '',
					});
				}}
				loadMore={this.props.hasMoreContacts}
				navigateToAddEmployee={() =>
					this.props.navigate(
						navRoutes.employeeUpsert.path
							.replace(Strings.Navigation.EmployerId, this.props.employerId)
							.replace(Strings.Navigation.ContactId, 'new')
					)
				}
				isLoading={this.props.contactSearchLoading}
			/>
		);
	}

	saveTags = (tags: string[]) => {
		const { employerId, saveEmployerTags } = this.props;
		saveEmployerTags({
			employerId,
			tags,
		});
	}

	render() {
		return (
			<BasePageContainer
				isAdvancedSearch={this.props.isAdvancedSearch}
				topComponent={this.renderHeaderBar()}
				bottomComponent={
					<>
						{this.renderAddExistingContactDialog()}
						{this.renderFabMenu()}
					</>
				}
			>
				<PersistentNotifications />
				<Grid container style={{ marginBottom: this.props.isAdvancedSearch ? 80 : 30 }}>
					<Grid item lg={3} />
					<Grid item xs={12} lg={this.props.parentHistory ? 12 : 6}>
						<TagsList
							tags={this.props.employer?.data?.tags} 
							saveTags={this.saveTags}
						/>
						{this.renderStatusCard()}
						{this.renderNextActivityCard()}
						{this.renderQuoteCard()}
						{this.renderCompanyInfoCard()}
						{this.renderPrimaryContactCard()}
						{this.renderLeadsCard()}
						{this.renderOpenActivitiesCard()}
						{this.renderCompletedActivitiesCard()}
						{this.renderNotesCard()}
						{this.renderProductsCard()}
						{this.renderAttachmentsCard()}
						{this.renderEmployeesCard()}
						{(!hasBLead(this.props.leads) || this.props.impersonatingId != this.props.userId) ?
							this.renderOwnershipCard()
							: null}
						{this.renderAdminInfoCard()}
					</Grid>
				</Grid>
				{this.renderDeleteDialog()}
				{this.renderAttachmentDialog()}
				<ReassignDialog
					visible={this.state.showReassignDialog}
					currentAgentCode={this.props.currentAgentCode}
					reassignToAgent={this.handleReassignToAgent}
					closeReassignDialog={() =>
						this.setState({ showReassignDialog: false })
					}
					fullScreen={!this.props.clickToCallEnabled}
				/>
				{this.renderCannotReassignDialog()}
			</BasePageContainer>
		);
	}
}

/**
 *  Map state and actions
 */
function mapStateToProps(state: AppState, props: any): StateProps {
	const employerId = props.match.params.employerID;
	const sortedAttachments = getPageAttachments(state, props).sort((a, b) => {
		return (
			moment
				.utc(b.data.dateCreated)
				.local()
				.valueOf() -
			moment
				.utc(a.data.dateCreated)
				.local()
				.valueOf()
		);
	});

	const emailActivities = state.emailHistorySummary.emailHistorySummarySet.filter(emailActivity => emailActivity.employerId === employerId);
	const activities = emailActivities.concat(getPageActivities(state, props));

	const leads = getPageLeads(state, props);
	const hasActiveLead = leads.some(lead => lead.data.statusCode === 'Open');
	const mostRecentPageLead = getMostRecentPageLead(state, props);

	const policies = getPagePolicies(state, props);
	const applications = getPageApplications(state, props);
	const hasActiveBusinessRelationship = 
		policies.some(
			policy => 
				policy.data.policyStatus == ProductStatus.Active ||
				policy.data.policyStatus == ProductStatus.Pending
		) ||
		applications.some(
			application =>
				application.data.applicationStatus == ProductStatus.Active ||
				application.data.applicationStatus == ProductStatus.Pending
		);


	return {
		notes: getPageNotes(state, props),
		employees: getPageContacts(state, props),
		loadedContacts: state.contact.contacts,
		leads,
		mostRecentPageLead,
		hasActiveLead,
		activities,
		applications: getPageApplications(state, props),
		policies,
		openLead: getOpenLead(state, props),
		primaryContact: getPrimaryContact(state, props),
		employerId: getId(state, props),
		currentAgentDisplayName: getCurrentAgentDisplayName(state),
		attachments: sortedAttachments,
		userId: state.user.id,
		leadAgent: state.agent.leadAgent,
		impersonatingId: state.user.impersonatingId,
		employer: getPageEmployer(state, props),
		nextActivity: getNextActivity(state, props),
		contactPageSize: state.contact.pageSize,
		contactPageIndex: state.infiniteScroll.pagesReceived,
		hasMoreContacts: state.infiniteScroll.loadMore,
		currentAgentCode: getCurrentAgentCode(state),
		ownershipHistories: getPageOwnershipHistory(state, props),
		originPage: getLeadRouteOriginPage(state),
		lookups: state.lookup,
		desktopCallEnabled: state.user.enableDesktopCalls,
		leadRoutes: state.leadRoutes.routes,
		leadRoutesIndex: state.leadRoutes.index,
		leadFilters: state.leadFilters,
		pageNumber: state.lead.leadListPageNumber,
		pageSize: state.lead.leadListPageSize,
		hasError: state.lead.error,
		moreLeadsToLoad: state.lead.moreLeadsToLoad,
		clickToCallEnabled: state.clickToCall.enabled,
		hasActiveBusinessRelationship,
		contactSearchLoading: state.contact.isLoading,
	};
}

function mapDispatchToProps(
	dispatch: any, ownProps: Props
): DispatchProps & Partial<NavigationProps> {
	return {
		...leadMapDispatchToProps(dispatch, ownProps),
		updateEmployer: (employer: Employer) => dispatch(UpdateEmployer.started(employer)),
		deleteEmployer: (employer: Employer) => {
			dispatch(DeleteEmployer.started(employer.id));
		},
		getEmployer: (id: string) => {
			dispatch(GetEmployer.started(id));
		},
		contactLiveSearch: (
			searchText: string,
			includeEmployees: boolean,
			pageIndex: number,
			pageSize: number
		) =>
			dispatch(
				ContactLiveSearch.started({ searchText, includeEmployees, pageRequested: pageIndex, pageSize })
			),
		resetLiveSearch: () => dispatch(ResetInfiniteScroll()),
		addContactToEmployer: (contact: Contact) =>
			dispatch(AddContactToEmployer.started(contact)),
		reassignEmployer: (
			employerId: string,
			agentCode: string,
			agentName: string
		) => {
			dispatch(ReassignEmployer.started({ employerId, agentCode, agentName }));
		},
		setPrimaryEmployee: (employerId: string, contactId: string) => dispatch(SetPrimaryEmployee.started({ employerId, contactId })),
		navigateToWithoutAddingToHistory: (route: string) =>
			dispatch(navigateToWithoutAddingToHistory(route)),

		saveEmployerTags: (payload: EmployerTagPayload) => dispatch(saveEmployerTags.started(payload)),
	};
}

export const EmployerPageContainer = connect(
	mapStateToProps,
	mapDispatchToProps, true
)(EmployerPage);
