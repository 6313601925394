export interface ThemeLinks {
    helpLinkHome: string;
    helpLinkNotifications: string;
    helpLinkBattles: string;
    helpLinkTools: string;
    helpLinkDocuments: string;
    helpLinkFinance: string;
    helpLinkTeamView: string;
    helpLinkPerformance: string;
    helpLinkProfile: string;
    helpLinkContests: string;
    helpLinkInstall: string;
    helpLinkTermsAndConditions: string;
    helpLinkSettings: string;
    helpLinkLeads: string;
    helpLinkCRM: string;
    helpLinkClientConnect: string;
    quoteConnect: string;
    connectureLink: string;
}

export const themeLinks: ThemeLinks = {
    helpLinkHome: 'https://rc.model.healthmarkets.com/ACsupport',
    helpLinkNotifications: 'https://rc.model.healthmarkets.com/notifications',
    helpLinkBattles: 'https://rc.model.healthmarkets.com/battle',
    helpLinkTools: 'https://rc.model.healthmarkets.com/tools',
    helpLinkDocuments: 'https://rc.model.healthmarkets.com/documents',
    helpLinkFinance: 'https://rc.model.healthmarkets.com/finance',
    helpLinkTeamView: 'https://rc.model.healthmarkets.com/teamview',
    helpLinkPerformance: 'https://rc.model.healthmarkets.com/performance',
    helpLinkProfile: 'https://rc.model.healthmarkets.com/profile',
    helpLinkContests: 'https://rc.model.healthmarkets.com/contest',
    helpLinkInstall: 'https://rc.model.healthmarkets.com/installation',
    helpLinkTermsAndConditions: 'https://rc.model.healthmarkets.com/termsconditions',
    helpLinkSettings: 'https://rc.model.healthmarkets.com/settings',
    helpLinkLeads: 'https://rc.model.healthmarkets.com/Leads',
    helpLinkCRM: 'https://rc.model.healthmarkets.com/CRM',
    helpLinkClientConnect: 'https://rc.model.healthmarkets.com/CRM',
    quoteConnect: 'https://quoteconnect.dev.healthmarkets.com/',
    connectureLink: 'https://www.connecture.com'
};