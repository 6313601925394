import { Action } from 'typescript-fsa';
import { call, put, all } from 'redux-saga/effects';
import http, { HttpOptions } from '../utilities/http';
import {
	FileUpload,
	getImportHistory,
	getProcessedImportFile,
	getTemplateFile,
	uploadImportTable,
} from '../actions/file_actions';
import { QueueSnackbar } from '../actions/snackbar_actions';
import { local_storage } from '../utilities/storage';
import { takeLatestForActionType } from '../utilities/saga_util';
import { ImportHistory } from '../reducers/file_reducer';
import { getSnackbarInfoProps, getSnackbarErrorProps } from '../utilities/snackbar_util';

//////////  Get Import History //////////
function* getTemplateFileSaga(action: Action<string>) {
	const fileName: string = action.payload;
	const snackbar = getSnackbarInfoProps(`Unable to get template file, ${fileName}`, 6000);

	try {
		const response = yield call(getTemplateFileClient, action.payload);

		if (response.ok) {
			const data = yield response.blob();
			local_storage.downloadFileFromBrowser(data, fileName);

			yield put(
				getTemplateFile.done({ params: fileName, result: undefined })
			);
		} else {
			yield put(
				getTemplateFile.failed({ params: fileName, error: response.status })
			);
			yield put(QueueSnackbar(snackbar));
		}
	} catch (error) {
		yield put(getTemplateFile.failed({ params: fileName, error: error }));
		yield put(QueueSnackbar(snackbar));
	}
}

function getTemplateFileClient(id: string): Promise<any> {
	return http('File/Template?fileName=' + id);
}

//////////  Get Processed Import File //////////
function* getProcessedImportFileSaga(action: Action<string>) {
	const fileName: string = action.payload;
	const snackbar = getSnackbarInfoProps(`Unable to get processed file, ${fileName}`);

	try {
		const response = yield call(getProcessedImportFileClient, fileName);

		if (response.ok) {
			const data = yield response.blob();
			local_storage.downloadFileFromBrowser(data, fileName);

			yield put(
				getProcessedImportFile.done({
					params: action.payload,
					result: undefined,
				})
			);
		} else {
			yield put(
				getProcessedImportFile.failed({
					params: action.payload,
					error: response.status,
				})
			);
			yield put(QueueSnackbar(snackbar));
		}
	} catch (error) {
		yield put(
			getProcessedImportFile.failed({
				params: action.payload,
				error: error,
			})
		);
		yield put(QueueSnackbar(snackbar));
	}
}

function getProcessedImportFileClient(fileName: string): Promise<any> {
	return http('File/ProcessedTable?fileName=' + fileName);
}

//////////  Get Import History //////////
function* getImportHistorySaga(action: Action<undefined>) {
	const snackbar = getSnackbarInfoProps('Unable to get import history', 6000);

	try {
		const response = yield call(getImportHistoryClient);

		if (response.ok) {
			const data = yield response.json();

			yield put(getImportHistory.done({ params: undefined, result: data }));
		} else {
			yield put(
				getImportHistory.failed({
					params: undefined,
					error: response.status,
				})
			);
			yield put(QueueSnackbar(snackbar));
		}
	} catch (error) {
		yield put(getImportHistory.failed({ params: undefined, error: error }));
		yield put(QueueSnackbar(snackbar));
	}
}

function getImportHistoryClient(): Promise<any> {
	return http('File/ImportHistory');
}

//////////  Upload Import Table //////////
function* uploadImportTableSaga(action: Action<FileUpload>) {
	const snackbar = getSnackbarErrorProps('Error: Wrong Template', 6000);
	try {
		const response = yield call(uploadImportTableClient, action.payload);

		if (response.ok) {
			const data: ImportHistory = yield response.json();
			yield put(
				uploadImportTable.done({ params: action.payload, result: data })
			);
			const resultStatus = data && data.status;
			if (resultStatus === 'Wrong Format') {
				yield put(QueueSnackbar(snackbar));
			}
		} else {
			yield put(
				uploadImportTable.failed({
					params: action.payload,
					error: response.status,
				})
			);
		}
	} catch (error) {
		yield put(
			uploadImportTable.failed({ params: action.payload, error: error })
		);
	}
}

function uploadImportTableClient(filePayload: FileUpload): Promise<any> {
	const options: HttpOptions = {
		method: 'POST',
		body: JSON.stringify(filePayload),
	};

	return http('File/UploadImport', options);
}


export function* fileSagas() {
	yield all([
		takeLatestForActionType(
			getTemplateFile.started,
			getTemplateFileSaga
		),
		takeLatestForActionType(
			getProcessedImportFile.started,
			getProcessedImportFileSaga
		),
		takeLatestForActionType(
			getImportHistory.started,
			getImportHistorySaga
		),
		takeLatestForActionType(
			uploadImportTable.started,
			uploadImportTableSaga
		),
	]);
}