import React from 'react';
import { Field } from 'redux-form';
import {
	Paper,
	Avatar,
	Icon,
	Typography,
} from '@material-ui/core';
import { themePalette } from '../../utilities/branding';
import { Lead } from '../../reducers/LeadReducer';
import { extractLeadInfo } from '../../utilities/lead_util';
import { displayDate } from '../../utilities/date_util';
import { createRequiredLabel } from '../utility/required_label';
import { LineOfBusinessAvatar } from '../line_of_business/line_of_business_avatar';
import { CustomListItem } from '../utility/custom_list_item';
import { Strings as S } from '../../assets/common/strings';

type FieldProps = {
	input: {
		onChange: (value: string) => void;
		value: string;
	};
	meta: {
		error?: string;
	};
};
type Props = {
	leads: Lead[];
};

const getStyles = (): { [key: string]: React.CSSProperties } => ({
	infoTitle: {
		color: themePalette.primary_text,
	},
	leadLeftCardHeader: {
		paddingLeft: '5px',
		textAlign: 'left',
		textEmphasis: 'bold',
		fontSize: '16px',
	},
	leadLeftCard: {
		paddingLeft: '5px',
		textAlign: 'left',
		fontSize: '13px',
		color: themePalette.tertiary_text,
	},
	leadRightCard: {
		textAlign: 'right',
		fontSize: '13px',
		color: themePalette.tertiary_text,
	},
	card: {
		padding: 10,
		overflow: 'hidden',
		cursor: 'pointer',
	},
	avatar: {
		margin: 10,
		color: themePalette.negative_text,
		backgroundColor: themePalette.lead_avatar,
	},
	avatarSelected: {
		margin: 10,
		color: themePalette.negative_text,
		backgroundColor: themePalette.selected_avatar,
	},
	cardStyle: {
		margin: 10,
		marginTop: 0,
	},
	header: {
		margin: '20px 0 5px 20px',
	},
	required: {
		padding: '0px 16px',
	},
});

const LeadSelector = (props: Props & FieldProps) => {
	const styles = getStyles();

	return (
		<Paper elevation={0}>
			{props.leads.map(lead => {
				const leadInfo = extractLeadInfo(lead);

				const lineOfBusinessAvatars = leadInfo.linesOfBusiness.map((lob, index) => (
					<LineOfBusinessAvatar
						key={'lob-' + index}
						size={20}
						lineOfBusinessShortString={(lob || '').slice(0, 1)}
						displayInline={true}
						bubbleColor={themePalette.lob_avatar}
					/>
				))

				const rightSubtitles = [
					{ value: leadInfo.statusGroup.label },
					{ value: leadInfo.status.label },
					{ value: lineOfBusinessAvatars }

				];

				const subtitles = [
					{ value: leadInfo.fullAddress },
					{ label: S.LeadSelector.LeadCreated, value: displayDate(lead.createdOn) },
					{ label: S.LeadSelector.LeadModified, value: displayDate(lead.updatedOn) },
				]

				return (
					<CustomListItem
						key={lead.id}
						id={lead.id}
						leftActionElement={
							<Avatar
								onClick={() => props.input.onChange(lead.id)}
								style={
									props.input.value === lead.id
										? styles.avatarSelected
										: styles.avatar
								}
							>
								<Icon>check</Icon>
							</Avatar>}
						leftColumnSize={2}
						rightSubtitles={rightSubtitles}
						rightColumnSize={1}
						header={leadInfo.headerName}
						subtitles={subtitles}
						primaryColumnSize={6}
						secondaryColumnSize={3}
						onClick={() => props.input.onChange(lead.id)}


					/>
				);
			})}
			{props.leads.length === 0 && (
				<p style={{ color: themePalette.error }}>
					{S.LeadSelector.NoLeadForEntity}
				</p>
			)}
		</Paper>
	);
};

const LeadSelectorCard = (props: Props) => {
	const styles = getStyles();
	const required = (value: any) =>
		value && props.leads.find(lead => lead?.id === value)
			? undefined
			: S.LeadSelector.LeadMustBeSelected;
	return (
		<div>
			<Typography variant="h5" style={styles.header}>
				{S.LeadSelector.Lead}
			</Typography>
			<Typography variant="body1" style={styles.required}>
				{createRequiredLabel(S.LeadSelector.SelectTheLead)}
			</Typography>
			<Field
				name="application.opportunityId"
				component={LeadSelector}
				leads={props.leads}
				validate={[required]}
			/>
		</div>
	);
};

export default LeadSelectorCard;
