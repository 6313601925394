import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Strings } from '../../assets/common/strings';
import { ProductivityParams, GetSurebridgePersistencyReport, GetSurebridgePersistencyAvgAndRank } from '../../actions/productivity_actions';
import { ProductivityTableCard } from '../teamview/productivity_table_card';
import { ProductivityTableExtraRow } from '../teamview/Productivity_table_extra_row';
import { ReportCard } from '../utility/report_card';

interface Props {
    params: ProductivityParams;
    showSLStats: boolean;
    hidden?: boolean;
}

export const SurebridgePersistencyReport: React.FC<Props> = props => {
    const dispatch = useDispatch();

    const reportData =  useSelector((state: any) => state.App.productivity.persistencyReport, shallowEqual);
    const reportAvgAndRank =  useSelector((state: any) => state.App.productivity.persistencyAvgAndRank, shallowEqual);

    useEffect(() => {
        dispatch(GetSurebridgePersistencyReport.started(props.params))
        if (props.showSLStats) {
            dispatch(GetSurebridgePersistencyAvgAndRank.started(props.params));
        }
    }, [props.params]);

    return (
        <div hidden={props.hidden}>
            {reportData?.isLoading
            ? (
                <ReportCard
                    color="primary"
                    titleLeft={Strings.TeamView.SUREBRIDGE_PERSISTENCY}
                    isLoading
                />)
            : (
                <ProductivityTableCard
                    rows={reportData?.table}
                    color="primary"
                    titleLeft={Strings.TeamView.SUREBRIDGE_PERSISTENCY}
                    rowsToHighlight={[3]}
                >
                    {props.showSLStats && (
                        <ProductivityTableExtraRow
                            rows={reportAvgAndRank?.table}
                        />)}
                </ProductivityTableCard>)}
        </div>
    );
};
