import { AppState } from '../reducers/index';
import { Loaded } from '../utilities/utilities';
import { OwnershipHistory } from '../reducers/ownership_history_reducer';
import { createSelector } from 'reselect';
import { getPage, getId, PageType } from './base_selectors';

/**
 * Ownership history selectors
 */
const getOwnershipHistory = (state: AppState) =>
	state.ownershipHistory.ownershipHistories || [];

const getOwnershipHistoryState = createSelector(
	[getOwnershipHistory],
	ownershipHistory => ownershipHistory
);

export const getPageOwnershipHistory = createSelector(
	[getPage, getId, getOwnershipHistoryState],
	(
		page: PageType,
		id: string,
		ownershipHistories: Loaded<OwnershipHistory>[]
	) => {
		switch (page) {
			case 'HOUSEHOLD_PAGE': {
				return ownershipHistories
					.filter(ownershipHistory => {
						return ownershipHistory.householdId == id;
					})
					.map(loadedOwnership => loadedOwnership.data);
			}
			case 'EMPLOYER_PAGE': {
				return ownershipHistories
					.filter(ownershipHistory => {
						return ownershipHistory.employerId == id;
					})
					.map(loadedOwnership => loadedOwnership.data);
			}
			default:
				return ownershipHistories.map(loadedOwnership => loadedOwnership.data);
		}
	}
);
