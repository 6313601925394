import { OpenRiseFormDialog } from './../actions/dialogs_actions';
import { Action, isType } from 'typescript-fsa';
import { QueueDialog, DismissDialog, ToggleLeadInfoDialog, ToggleFactFinderContactLinkDialog, TogglePrescriptionSearchDialog, ToggleProviderSearchDialog } from '../actions/dialogs_actions';


export interface DialogFrame {
    component: JSX.Element;
    id: string;
    name: string;
}

export type DialogsState = DialogFrame[];

const initialState: DialogsState = [];

export function dialogsReducer(
    state: DialogsState = initialState,
    action: Action<any>
): DialogsState {
    if (isType(action, QueueDialog)) {
        return [
            ...state,
            action.payload,
        ];
    } else if (isType(action, DismissDialog)) {
        return state.filter(dialogData => dialogData.id !== action.payload);
    } else {
        return state;
    }
}
export type LeadInfoDialogState = {
    isOpen: boolean;
    isLoading: boolean;
}
export function leadInfoDialogReducer(
    state: LeadInfoDialogState,
    action: Action<any>
): LeadInfoDialogState {
    if (isType(action, ToggleLeadInfoDialog)) {

        return {
            ...state,
            isOpen: action.payload,
        };
    } else {
        return state
            || {
            isOpen: false,
            isLoading: false,
        };

    }

}

export type LeadContactDialogState = {
    isOpen: boolean;
}

export function leadInfoContactReducer(
    state: LeadContactDialogState,
    action: Action<any>
): LeadContactDialogState {
    if (isType(action, ToggleFactFinderContactLinkDialog)) {
        return {
            ...state,
            isOpen: action.payload
        }
    } else {
        return state || { isOpen: false };
    }
}

export type PrescriptionSearchDialogState = {
    isOpen: boolean;
}

export function prescriptionSearchReducer(
    state: PrescriptionSearchDialogState,
    action: Action<any>
): PrescriptionSearchDialogState {
    if (isType(action, TogglePrescriptionSearchDialog)) {
        return {
            ...state,
            isOpen: action.payload
        }
    } else if (isType(action, OpenRiseFormDialog)) {
        return {
            ...state,
            isOpen: action.payload === "prescriptions",
        }
    } else {
        return state || { isOpen: false };
    }
}

export type ProviderSearchDialogState = {
    isOpen: boolean;
}

export function providerSearchReducer(
    state: ProviderSearchDialogState,
    action: Action<any>
): ProviderSearchDialogState {
    if (isType(action, ToggleProviderSearchDialog)) {
        return {
            ...state,
            isOpen: action.payload
        }
    } else if (isType(action, OpenRiseFormDialog)) {
        return {
            ...state,
            isOpen: action.payload === "providers",
        }
    } else {
        return state || { isOpen: false };
    }
}
