import { User } from './../../reducers/user_reducer';
import { LogToSplunk } from './../../actions/logging_actions';
import { CancelSmsSequence, SendSmsPayload, SendSms, PopulateSmsPayload, PopulateSmsText, SendSmsToLeadListSelection, StartSmsSequence, SendSmsToLeadListAtIndex, SmsLeadSelectionCleanup } from './../../actions/sequence/sms_sequence_actions';
import { Action } from 'typescript-fsa';
import { Activity } from '../../reducers/activity_reducer';
import { put, all, call, race } from 'redux-saga/effects';
import { Strings } from '../../assets/common/strings';
import { takeLatestForActionType, selectFromImmutable } from '../../utilities/saga_util';
import { Contact } from '../../reducers/ContactReducer';
import { Lead, LeadListSelected } from '../../reducers/LeadReducer';
import { Lookups, DefaultDictionary } from '../../utilities/lookup';
import { isLookupSequenceValid } from '../../utilities/lead_status_validation';
import { cloneDeep } from '../../utilities/object_util';
import { UpdateLead, StoreSelectedLeadContacts, ToggleLeadListMultiSelect, ClearSelectedLeads, CheckLeadListIndex } from '../../actions/lead_actions';
import { fullName } from '../../assets/common/string_builders';
import moment from 'moment'
import { getAgentState } from '../../selectors/agent_selectors';
import { getUserState } from '../../selectors/user_selectors';
import { Agent } from '../../reducers/agent_reducer';
import http from '../../utilities/http';
import { SmsTemplate } from '../../reducers/sms_template_reducer';
import uuid from 'uuid';
import { EMPTY_ACTIVITY } from '../../utilities/empty_entities';
import { normalizePhone } from '../../utilities/formatting/data_normalizations';
import { getLeads, findOpenLead, getSelectedLeads, getSelectedLeadIndex, getSelectedLeadContacts } from '../../selectors/lead_selectors';
import { getSnackbarErrorProps, getSnackbarSuccessProps } from '../../utilities/snackbar_util';
import { QueueSnackbar } from '../../actions/snackbar_actions';
import { isMobileDevice, isIOS, isMac } from '../../utilities/is_mobile';
import { CreateActivity } from '../../actions/activity_actions';
import { selectStoredTemplate, getSelectedContactId, getSelectedContactPhoneNumber } from '../../selectors/sms_selectors';
import { take } from 'lodash';


function* sendSmsTemplate(action: Action<SendSmsPayload>) {
	try {
		const { contact, smsTemplate, phoneNumber, phoneId, keepDialogOpen=false } = action.payload;
		const agent: Agent = yield selectFromImmutable<Agent>(getAgentState);

		let smsContent: string = '';

		if (smsTemplate) {

			const body = {
				agentCode: agent.agentCode,
				contactId: contact.id,
				inputString: smsTemplate.content,
				phoneId: phoneId
			};

			const options = {
				method: Strings.Http.Post,
				body: JSON.stringify(body),
			};

			const response = yield call(http, 'smstemplate/substitution', options);

			if (response.ok) {
				smsContent = yield response.json();

			} else {
				yield put(QueueSnackbar(getSnackbarErrorProps('Unable to get SMS template substitutions')));
			}
		}

		const leads: Lead[] = yield selectFromImmutable<Lead[]>(getLeads)
		const lead = findOpenLead(leads, contact.householdId);
		const activity = createSmsActivity(contact, agent, phoneNumber, lead, smsTemplate);

		yield put(CreateActivity.started(activity));
		if (lead) {
			const newLeadStatus = Lookups.TextedAwaitingAnswer;
			const newLeadStatusCode = newLeadStatus.getFirstParentOrSelfOfType(
				Lookups.LeadStatusCode
			);
			const currentLeadStatus = DefaultDictionary.findOrCreate(lead.status);
	
			if (
				isLookupSequenceValid(
					Lookups.DispositionStage,
					currentLeadStatus,
					newLeadStatus
				) && lead.id
			) {
				const leadToUpdate: Lead = cloneDeep(lead);
				leadToUpdate.status = newLeadStatus.isLookup(Lookups.Sale)
					? newLeadStatus.label
					: newLeadStatus.value;
				leadToUpdate.statusCode = newLeadStatusCode.value;
				yield put(UpdateLead.started(leadToUpdate));

				// Wait for lead to be updated
				// TODO: Maybe do something with success/fail here
				yield race({ done: take(UpdateLead.done.type), failed: take(UpdateLead.failed.type) });
			}
		}

		// Wait for activity to be created
		yield race({ done: take(CreateActivity.done.type), failed: take(CreateActivity.failed.type) });

		// Actually open sms application and close dialogs
		yield call(sendSms, phoneNumber, contact, smsContent, action);
		if (!keepDialogOpen) {
			yield put(CancelSmsSequence());
		}


	} catch (error) {
		yield put(QueueSnackbar(getSnackbarErrorProps('Unable to send SMS')));
		console.error(error);
	}
}

function* populateSms(action: Action<PopulateSmsPayload>) {
	try {
		const { phoneNumber, content = '' } = action.payload;
		yield call(sendSms, phoneNumber, undefined, content, action);
	} catch (error) {
		yield put(QueueSnackbar(getSnackbarErrorProps('Unable to send SMS')));
		console.error(error);
	}
}

function* sendSms(
	phoneNumbers: string[] | string,
	contact?: Contact,
	content?: string,
	action?: any,
) {
	const user: User = yield selectFromImmutable<User>(getUserState);
	const appleUser = isIOS || isMac;

	if (isMobileDevice || user.enableDesktopTexts) {
		const numbers = Array.isArray(phoneNumbers) ? phoneNumbers.join(',') : phoneNumbers;
		const message = (`${appleUser ? 'sms://open?addresses=' : 'sms:'}` + numbers)
			.concat(content ? `${appleUser ? ';?&' : '?'}body=${encodeURIComponent(content)}` : '');

		try {
			yield call(window.open, message, '_self');
			yield put(LogToSplunk.started({
				level: Strings.Log.Info,
				message: `Successfully send SMS: Sent from ${user.agentID}, sent to ${(contact && contact.id) || phoneNumbers.toString()} at ${phoneNumbers.toString()}; Content=${content}`,
				metadata: JSON.stringify(action.payload)
			}));
		}
		catch (err) {
			console.error(err);
		}
	}
};	

// Clear selected lead list
function* smsLeadSelectionCleanup() {
	yield all([
		put(ToggleLeadListMultiSelect(false)),
		put(ClearSelectedLeads()),
		put(CancelSmsSequence()),
	]);
}

// SEND SMS TO SELECTED LEADS FROM LEAD LIST
function* startLeadListSmsSequence(action: Action<undefined>) {
	const leads: Lead[] = yield selectFromImmutable<Lead[]>(getLeads);
	const selected: LeadListSelected = yield selectFromImmutable<LeadListSelected>(getSelectedLeads);

	// Store the filteredLeads and index
	const filteredLeads = leads.filter(
		lead => Boolean(selected[lead.id]) &&
			lead.contacts &&
			lead.contacts.length &&
			lead.contacts.some(contact => Boolean(contact.phones && contact.phones.length))
	);
	const mappedContacts = filteredLeads.map(lead => lead.contacts);
	yield put(StoreSelectedLeadContacts(mappedContacts));

	// Show template selection
	yield put(StartSmsSequence([]));
}

function* sendSmsToLeadAtStoredIndex() {
	const index: number = yield selectFromImmutable<number>(getSelectedLeadIndex);
	const contacts: Contact[][] = yield selectFromImmutable<Contact[][]>(getSelectedLeadContacts);
	if (index >= contacts.length) {
		return yield put(SmsLeadSelectionCleanup());
	}

	const contactsAtIndex = contacts[index];
	const template: SmsTemplate | undefined = yield selectFromImmutable<SmsTemplate | undefined>(selectStoredTemplate);

	const selectedContactId: string | undefined = yield selectFromImmutable<string | undefined>(getSelectedContactId);
	const selectedContactNumber: string | undefined = yield selectFromImmutable<string | undefined>(getSelectedContactPhoneNumber);

	if (template && contactsAtIndex && selectedContactId && selectedContactNumber) {
		const contact = contactsAtIndex.find(c => c.id == selectedContactId);
		if (contact) {
			const phone = contact.phones.find(p => p.number == selectedContactNumber);
			const phoneId = phone ? phone.id : ''
			yield put(SendSms({
				contact,
				phoneId,
				phoneNumber: selectedContactNumber,
				smsTemplate: template,
				keepDialogOpen: true,
			}));
		}
	}

	yield put(StartSmsSequence(contactsAtIndex));
}

function* checkLeadListIndex() {
	const index: number = yield selectFromImmutable<number>(getSelectedLeadIndex);
	const contacts: Contact[][] = yield selectFromImmutable<Contact[][]>(getSelectedLeadContacts);
	if (index >= contacts.length) {
		yield put(SmsLeadSelectionCleanup());
		yield put(QueueSnackbar(getSnackbarSuccessProps('Completed sending SMS Template')));
	}
}

const createSmsActivity = (contact: Contact, agent: Agent, phoneNumber: string, lead?: Lead, smsTemplate?: SmsTemplate): Activity => {
	const description = "SMS - " + (smsTemplate ? smsTemplate.subject : "Free Form");

	const numbers = phoneNumber.split(',').map(normalizePhone);
	const name: string = fullName(contact);
	const title = "To: " + name + " " + numbers.join(', ');

	const currentTime = moment.utc().toDate();

	// strip medicare info
	const contactNoMed: Contact = {
		...contact,
		medicareInfo: undefined,
	};

	return {
		...EMPTY_ACTIVITY,
		id: uuid.v4(),
		contact: contactNoMed,
		lead: lead && lead.id,
		title,
		description,
		userId: agent.id,
		createdBy: agent.id,
		updatedBy: agent.id,
		completedBy: agent.id,
		completedOn: currentTime,
		modifiedBy: agent.id,
		modifiedByName: name,
		disposition: smsTemplate ? smsTemplate.id : "Free-Form",
		completedByName: name,
		status: Strings.ActivityStatus.Completed,
		type: Strings.Activity.Sms,
	}
}


export function* smsSequenceSagas() {
	yield all([
		takeLatestForActionType(
			SendSms,
			sendSmsTemplate
		),
		takeLatestForActionType(
			PopulateSmsText,
			populateSms
		),
		takeLatestForActionType(
			SmsLeadSelectionCleanup,
			smsLeadSelectionCleanup
		),
		takeLatestForActionType(
			SendSmsToLeadListSelection,
			startLeadListSmsSequence
		),
		takeLatestForActionType(
			SendSmsToLeadListAtIndex,
			sendSmsToLeadAtStoredIndex
		),
		takeLatestForActionType(
			CheckLeadListIndex,
			checkLeadListIndex
		),
	])
}