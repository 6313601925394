import { Podcast } from "../../reducers/podcast_reducer";
import { NavigationProps, navRoutes } from '../../components/nav/Routes';
import React from 'react';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { connect } from '@optum-uhone-hmkts/rise';
import { GetPodcastById, ChangePodcast, DeletePodcastById } from '../../actions/podcast_actions';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import { CardContent, CardHeader, Card, Grid, Icon, Button } from '@material-ui/core';
import moment from 'moment';
import { displayDate, DateTimeFormat } from '../../utilities/date_util';
import { jwt_auth } from '../../utilities/auth';
import { P } from '../../utilities/auth/permissions';
import { createMenuAction, MoreMenu } from '../../components/nav/more_menu';
import { Strings } from '../../assets/common/strings';
import { navigateTo, navigateToWithoutAddingToHistory } from '../../actions/navigation_actions';
import { themePalette } from '../../utilities/branding';
import { AppState } from "../../reducers";
import { Dispatch } from "@reduxjs/toolkit";

interface PodcastProps {
    podcast?: Podcast;
    isLoading: boolean;
}
interface DispatchProps {
    getPodcast: (id: string) => void;
    playPodcast: (podcast: Podcast) => void;
    deletePodcast: (id: string) => void;
    navigateToWithoutAddingToHistory: (route: string) => void;
}

interface Props extends PodcastProps, DispatchProps, NavigationProps {}

class PodcastDetail extends React.PureComponent<Props> {

    componentWillMount() {
        if(!this.props.podcast) {
            this.props.getPodcast(this.props.match.params.podcast_id);
        }
    }
    playPodcast = () => {
        if(this.props.podcast) {
            this.props.playPodcast(this.props.podcast);
        }
    }

    editPodcast = () => {
        if(this.props.podcast) {
            this.props.navigateTo(
                navRoutes.podcastUpsert.path.replace(Strings.Navigation.PodcastId, this.props.podcast.id)
            );
        }
    }

    deletePodcast = () => {
        if(this.props.podcast) {
            this.props.deletePodcast(this.props.podcast.id);
            this.props.navigateToWithoutAddingToHistory(navRoutes.podcastList.path);
        }
    }

    renderCategories = () => {
        if(this.props.podcast && this.props.podcast.categories) {
            return this.props.podcast.categories.join(', ');
        }
    }

    getMenuActions = () => {
        const menuActions: any[] = [];
        if(jwt_auth.hasPermission(P.PodcastUpdate)) {
            menuActions.push(createMenuAction(Strings.MoreMenu.Edit, this.editPodcast));
        }

        if(jwt_auth.hasPermission(P.PodcastDelete)) {
            menuActions.push(createMenuAction(Strings.MoreMenu.Delete, this.deletePodcast));
        }

        if(menuActions.length > 0) {
            return <MoreMenu
                children={menuActions}
            />;
        } else {
            return undefined;
        }
    }

    render() {
        const podcast = this.props.podcast;
        return (
            <BasePageContainer
                topComponent={
                    <HeaderBarComponent
                        title={"Podcast"}
                        forceOverflow
                        isLoading={this.props.isLoading}
                        rightButtons={this.getMenuActions()}
                    />
                }
            >
                <PersistentNotifications />
                <Grid container>
					<Grid item lg={3} />
                    <Grid item xs={12} lg={6}>
                        <Card style={styles.paperCardStyle}>
                            <CardHeader
                                title={
                                    <span style={styles.title}>
                                        {podcast ? podcast.title : ''}
                                    </span>
                                }
                            />
                            <CardContent
								style={{
									...styles.wordWrap,
									marginTop: -15,
									whiteSpace: 'pre-wrap'
								}}
                            >
                                {podcast && 
                                    <>
                                        {podcast.description}
                                        <br /><br />
                                        <Button onClick={this.playPodcast}>
                                            <Icon>
                                                play_arrow
                                            </Icon>
                                            Play
                                        </Button>
                                        <br /><br />
                                        { podcast.categories.length > 0 && 
                                        <>
                                            Categories: {this.renderCategories()}
                                            <br />
                                        </>
                                        }
                                        Length: {moment.duration(podcast.duration, "seconds").format()}
                                        <br />
                                        { podcast.isPublished && <div>Published: {displayDate(podcast.publishDate, DateTimeFormat.Date)}</div>}
                                    </>
                                }
                            </CardContent>
                        </Card>
                        {podcast && jwt_auth.hasPermission(P.PodcastCreate) && <div style={{ padding: '0px 25px 20px' }}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <div style={styles.detailStyle}>
                                        {podcast.createdOn
                                            ? 'Created ' +
                                                displayDate(moment.utc(podcast.createdOn).local(), 1) +
                                                ' @ ' +
                                                displayDate(moment.utc(podcast.createdOn).local(), 3)
                                            : 'Created unknown'}
                                        {podcast.createdByName
                                            ? ' by ' + podcast.createdByName
                                            : ' by unknown'}
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                    <div style={styles.detailStyle}>
                                        {podcast.updatedOn
                                            ? 'Modified ' +
                                                displayDate(moment.utc(podcast.updatedOn).local(), 1) +
                                                ' @ ' +
                                                displayDate(moment.utc(podcast.updatedOn).local(), 3)
                                            : 'Modified unknown'}
                                        {podcast.updatedByName
                                            ? ' by ' + podcast.updatedByName
                                            : ' by unknown'}
                                    </div>
                                </Grid>
                            </Grid>
                        </div>}
                    </Grid>
                </Grid>
            </BasePageContainer>
        );
    }

}

const mapStateToProps = (state: AppState, props: Props) : PodcastProps => ({
        podcast: state.podcast?.podcasts?.find(
            podcast => podcast?.id == props.match.params.podcast_id
        ),
        isLoading: state.podcast?.isLoading
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps & Partial<NavigationProps> => ({
    navigateTo: (route: string) => dispatch(navigateTo(route)),
    getPodcast: (id: string) => dispatch(GetPodcastById.started(id)),
    deletePodcast: (id: string) => dispatch(DeletePodcastById.started(id)),
    playPodcast: (podcast: Podcast) => dispatch(ChangePodcast(podcast)),
    navigateToWithoutAddingToHistory: (route: string) =>
        dispatch(navigateToWithoutAddingToHistory(route)),
});

export const PodcastDetailContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
    true
)(PodcastDetail);

const styles: { [key: string]: React.CSSProperties } = {
	wordWrap: {
		wordBreak: 'break-word',
	},
	paperCardStyle: {
		margin: '0 10px 10px',
	},
	paddingStyle: {
		paddingTop: '10px',
	},
	title: {
		fontSize: '1.5rem',
    },
    detailStyle: {
		color: themePalette.primary_text,
		fontSize: '11px',
	}
};
