import { Action as ReduxAction } from 'redux';
import { isNullOrUndefined } from 'util';
import { isType } from 'typescript-fsa';
import { Loaded } from '../utilities/utilities';
import {
	GetApplication,
	GetAllApplications,
	GetAllUnlinkedApplications,
	StoreApplications,
	CreateApplication,
	UpdateApplication,
	DeleteApplication,
} from '../actions/application_actions';
import _ from 'lodash';
import { Contact } from './ContactReducer';
import { ProductStatus } from './policy_reducer';
import BaseDTO from '../utilities/base_dto';
import { GetHousehold, ReassignHousehold } from '../actions/household_actions';
import {
	GetEmployer,
	UpdateEmployer,
	DeleteEmployer,
	SearchEmployers,
	ReassignEmployer,
} from '../actions/employer_actions';
import { ClearCaches } from '../actions/authentication_actions';
import { Logout } from '../actions/authentication_actions';

export interface ApplicationState {
	applications: Loaded<Application>[];
	isLoading: boolean;
}

export interface Application extends BaseDTO {
	primaryInsured: Contact;
	isLinked: boolean;
	secondaryInsured: Contact | null;
	insureds?: any;
	dependents: Contact[];
	employerId?: string;
	//Might not need this? Should always belong to an Oppty? Id of the Opportunity associated with Application.
	opportunityId?: string;
	//Might not need this? Should always belong to an Oppty? Id of the Opportunity associated with Application.
	originalUserId?: string;
	carrier: string;
	//Needs Domain Discussion Id of the Product associated with Application
	productId: string;
	productName: string;
	productType: string;
	firstPremiumPaid: Date;
	applicationEnrolledIn: string;
	appDate: Date;
	applicationNumber: string;
	applicationsSubmitted?: number;
	applicationStatus: ProductStatus;
	statusReason: string;
	appliedSubsidy?: number;
	applyPath: string;
	applicationStartedIn: string;
	annualPremium?: number;
	benefitPeriod?: number;
	carrierFriendlyName: string;
	carrierProducerCode: string;
	reconciliationStatusComments: string;
	healthPlanCommissionStatus: string;
	isContracted: boolean;
	costShare: string;
	effectiveDate?: Date;
	isEligible: boolean;
	eliminationPeriod?: number;
	employerName: string;
	benefitAmount?: number;
	faceAmount?: number;
	familyDeductible?: number;
	familyMaxOutOfPocket?: number;
	annualFederalSubsidy?: number;
	ffmAppId: string;
	fromRhoSync: boolean;
	hmAssisted: boolean;
	individualDeductible?: number;
	individualMaxOutOfPocket?: number;
	lastFourSSN: string;
	lineOfBusiness: string;
	metalLevel: string;
	annualNetCost?: number;
	monthlyNetCost?: number;
	monthlyPremium?: number;
	monthlyFederalSubsidy?: number;
	isNewReconciliationRequest: boolean;
	numberOfApplicants?: number;
	outstandingDocuments: string;
	outstandingDocumentsDueDate?: Date;
	isOutstandingDocumentsNeeded: boolean;
	paymentAttempted?: Date;
	perPersonDeductible?: number;
	planId: string;
	policyNumber: string;
	ppMaxOutOfPocket?: number;
	quoteId: string;
	reconciliationStatus: string;
	reconciliationStatusDate?: Date;
	isReEnrollment: boolean;
	sold: string;
	//Encrypted right??
	ssn: string;
	//Is this needed?? Unique identifier for State associated with Application.
	stateId: string;
	createdByAgentName: string;
	updatedByAgentName: string;
	drugDeductible?: number;
	annualDrugCost?: number;
	enrollmentMethod: string;
	acaApplyId: string;
	policyEndDate: string;
	enrollmentPeriodCode: string;
	sepCode: string;
}

export const initialApplication: Loaded<Application> = {
	loading: false,
	data: {
		id: '',
	} as Application,
	errors: [],
	householdId: '',
};

const initialState = {
	applications: [],
	isLoading: false,
};

export function applicationReducer(
	state: ApplicationState = initialState,
	action: ReduxAction
): ApplicationState {
	if (
		isType(action, GetApplication.started) ||
		isType(action, CreateApplication.started) ||
		isType(action, UpdateApplication.started) ||
		isType(action, DeleteApplication.started)
	) {
		const applicationsInState = state.applications.slice();
		const applicationId = isType(action, GetApplication.started)
			? action.payload
			: action.payload.id;
		upsertApplicationStart(applicationsInState, {
			id: applicationId,
		} as Application);
		return {
			...state,
			applications: applicationsInState,
		};
	} else if (
		isType(action, GetApplication.done) ||
		isType(action, CreateApplication.done) ||
		isType(action, UpdateApplication.done)
	) {
		const applicationsInState = state.applications.slice();
		upsertApplicationDone(applicationsInState, action.payload.result);
		return {
			...state,
			applications: applicationsInState,
		};
	} else if (
		isType(action, GetApplication.failed) ||
		isType(action, CreateApplication.failed) ||
		isType(action, UpdateApplication.failed) ||
		isType(action, DeleteApplication.failed)
	) {
		const applicationsInState = state.applications.slice();
		const applicationId = isType(action, GetApplication.failed)
			? action.payload.params
			: action.payload.params.id;
		upsertApplicationDone(
			applicationsInState,
			{ id: applicationId } as Application,
			action.payload.error
		);
		return {
			...state,
			applications: applicationsInState,
		};
	} else if (
		isType(action, GetAllApplications.started) ||
		isType(action, GetAllUnlinkedApplications.started)
	) {
		return {
			...state,
			isLoading: true,
		};
	} else if (
		isType(action, GetAllApplications.done) ||
		isType(action, GetAllUnlinkedApplications.done)
	) {
		const applicationsInState = state.applications.slice();
		updateStateWithInboundApplications(
			applicationsInState,
			action.payload.result
		);
		return {
			...state,
			isLoading: false,
			applications: applicationsInState,
		};
	} else if (
		isType(action, GetAllApplications.failed) ||
		isType(action, GetAllUnlinkedApplications.failed)
	) {
		return {
			...state,
			isLoading: false,
		};
	} else if (isType(action, DeleteApplication.done)) {
		const applicationsInState = state.applications.slice();
		const index = getMatchingApplicationIndex(
			applicationsInState,
			action.payload.params.id
		);
		applicationsInState.splice(index, 1);
		return {
			...state,
			applications: applicationsInState,
		};
	} else if (
		isType(action, GetEmployer.done) ||
		isType(action, UpdateEmployer.done) ||
		isType(action, GetHousehold.done) ||
		isType(action, StoreApplications)
	) {
		const applicationsInState = state.applications.slice();
		let newApplications: Application[] = [];
		if (isType(action, StoreApplications)) {
			if (action.payload.clearCache) applicationsInState.length = 0;
			newApplications = action.payload.applications;
		} else {
			newApplications = action.payload.result.applications;
		}

		updateStateWithInboundApplications(applicationsInState, newApplications);
		return {
			...state,
			applications: applicationsInState,
		};
	} else if (isType(action, DeleteEmployer.done)) {
		const applicationsInState = state.applications.filter(
			application => application.employerId !== action.payload.params
		);
		return {
			...state,
			applications: applicationsInState,
		};
	} else if (isType(action, ReassignHousehold.done)) {
		const oldApplications = state.applications.slice();
		const newApplications = oldApplications.filter(application => {
			return application.householdId != action.payload.params.householdId;
		});
		return {
			...state,
			applications: newApplications,
		};
	} else if (isType(action, ReassignEmployer.done)) {
		const oldApplications = state.applications.slice();
		const newApplications = oldApplications.filter(application => {
			return application.employerId != action.payload.params.employerId;
		});
		return {
			...state,
			applications: newApplications,
		};
	} else if (isType(action, SearchEmployers.done)) {
		const applicationsInState = state.applications.slice();
		let newApplications: Application[] = [];
		action.payload.result.forEach(fullEmployer => {
			newApplications = newApplications.concat(fullEmployer.applications);
		});
		updateStateWithInboundApplications(applicationsInState, newApplications);
		return {
			...state,
			applications: applicationsInState,
			isLoading: false,
		};
	} else if (isType(action, Logout.started)) {
		return {
			...initialState,
		};
	} else if (isType(action, ClearCaches)) {
		return {
			...initialState,
		};
	} else {
		return state;
	}
}

export function getMatchingApplicationIndex(
	applicationsToLookThrough: Loaded<Application>[],
	applicationId: string
) {
	return _.findIndex(applicationsToLookThrough, application => {
		return (
			!isNullOrUndefined(application.data) &&
			application.data.id === applicationId
		);
	});
}

function upsertApplicationStart(
	cachedApplications: Loaded<Application>[],
	application: Application
) {
	const matchedApplicationIndex = _.findIndex(
		cachedApplications,
		cachedApplication => cachedApplication.data.id === application.id
	);
	if (matchedApplicationIndex !== -1) {
		cachedApplications[matchedApplicationIndex] = {
			...cachedApplications[matchedApplicationIndex],
			loading: true,
			employerId: application.employerId,
			householdId: application.primaryInsured
				? application.primaryInsured.householdId
				: undefined,
		};
	} else {
		const newApplication: Loaded<Application> = {
			data: application,
			loading: true,
			employerId: application.employerId,
			householdId: application.primaryInsured
				? application.primaryInsured.householdId
				: undefined,
			errors: [],
		};
		cachedApplications.push(newApplication);
	}
}

function upsertApplicationDone(
	cachedApplications: Loaded<Application>[],
	updatedApplication: Application,
	error?: any
) {
	const index = _.findIndex(
		cachedApplications,
		cachedApplication => cachedApplication.data.id === updatedApplication.id
	);
	if (index > -1) {
		if (error)
			cachedApplications[index] = {
				...cachedApplications[index],
				loading: false,
				errors: [...cachedApplications[index].errors, error],
			};
		else if (updatedApplication.isDeleted) cachedApplications.splice(index, 1);
		else
			cachedApplications[index] = {
				...cachedApplications[index],
				data: updatedApplication,
				employerId: updatedApplication.employerId,
				householdId: updatedApplication.primaryInsured
					? updatedApplication.primaryInsured.householdId
					: undefined,
				loading: false,
				errors: [],
			};
	} else {
		const newApplication: Loaded<Application> = {
			data: updatedApplication,
			loading: false,
			employerId: updatedApplication.employerId,
			householdId: updatedApplication.primaryInsured
				? updatedApplication.primaryInsured.householdId
				: undefined,
			errors: error ? [error] : [],
		};
		cachedApplications.push(newApplication);
	}
}

function updateStateWithInboundApplications(
	applicationsInState: Loaded<Application>[],
	inboundApplications: Application[]
) {
	inboundApplications.forEach(inboundApplication => {
		const applicationId = inboundApplication.id;
		let wasAApplicationUpdated = false;

		applicationsInState.forEach((currentApplication, index) => {
			const currentApplicationId = currentApplication.data.id;
			if (currentApplicationId === applicationId) {
				wasAApplicationUpdated = true;
				applicationsInState[index] = {
					...applicationsInState[index],
					loading: false,
					data: inboundApplication,
					householdId: inboundApplication.primaryInsured
						? inboundApplication.primaryInsured.householdId
						: undefined,
					employerId: inboundApplication.employerId,
					errors: [],
				};
			}
		});
		if (!wasAApplicationUpdated) {
			const newApplication: Loaded<Application> = {
				loading: false,
				data: inboundApplication,
				errors: [],
				householdId: inboundApplication.primaryInsured
					? inboundApplication.primaryInsured.householdId
					: undefined,
				employerId: inboundApplication.employerId,
			};
			applicationsInState.push(newApplication);
		}
	});
}
