import React from 'react';
import { Paper, TextField } from '@material-ui/core';
import { SearchResultProps, SearchResult } from '../utility/search_result';
const InfiniteScroll = require('react-infinite-scroller');

interface Props {
	results: SearchResultProps[];
	handleSearch: (input: string) => void;
	resetSearch: () => void;
	label: string | React.ReactNode;
	loadMore?: boolean;
	onResultClick?: (result: any) => void;
	onSelect?: (selection: any) => void;
	resultString?: string;
	loading?: boolean;
	defaultMessage?: string;
}

interface State {
	displayConfirmationDialog: boolean;
	selectedIndex?: number;
	searchString: string;
	hasSearched: boolean;
}

export class LiveSearch extends React.Component<Props, State> {
	typingTimer: any;
	constructor(props: Props) {
		super(props);
		this.state = {
			displayConfirmationDialog: false,
			searchString: '',
			hasSearched: false,
		};
	}

	componentWillReceiveProps(nextProps: Props) {
		if (!this.state.hasSearched && nextProps.loading) {
			this.setState({ hasSearched: true });
		}
	}

	componentWillUnmount() {
		this.props.resetSearch();
		if (this.typingTimer) clearTimeout(this.typingTimer);
	}

	handleUpdateInput = (event: any) => {
		const value = event.target.value || '';
		this.setState({ searchString: value });
		if (value.length >= 2) {
			if (this.typingTimer) clearTimeout(this.typingTimer);
			this.typingTimer = setTimeout(() => {
				this.props.handleSearch(value);
			}, 1200);
		} else if (value.length == 0) {
			this.props.resetSearch();
		}
	};

	loadMoreResults = () => {
		if (this.props.loadMore) {
			this.props.handleSearch(this.state.searchString);
		}
	};

	handleResultClick = (data: any, index: number) => {
		if (this.props.onSelect) {
			const unselect = this.state.selectedIndex == index;
			this.setState((prevState: State) => {
				return {
					selectedIndex: unselect ? undefined : index,
				};
			});
			this.props.onSelect(unselect ? undefined : data);
		}
		if (this.props.onResultClick) {
			this.props.onResultClick(data);
		}
	};

	render() {
		const inputValue =
			this.props.resultString && this.props.resultString.length > 0
				? this.props.resultString
				: this.state.searchString;
		return [
			<Paper key={'search-input'} square elevation={1} style={{ padding: 20 }}>
				<TextField
					label={this.props.label}
					onChange={this.handleUpdateInput}
					onKeyDown={event =>
						event.key == 'Enter'
							? this.props.handleSearch(this.state.searchString)
							: undefined
					}
					value={inputValue}
					fullWidth
				/>
			</Paper>,
			<div
				key={'no-results-msg'}
				hidden={this.props.loading}
				style={{
					fontSize: 12,
					textAlign: 'center',
					padding: 10,
				}}
			>
				{!this.state.hasSearched
					? this.props.defaultMessage
					: this.props.results.length == 0
						? 'There are no matches for your search...'
						: `Showing ${this.props.results.length} results`}
			</div>,
			<InfiniteScroll
				key={'live_search_infinite_scroll'}
				initialLoad={false}
				loadMore={this.loadMoreResults}
				hasMore={this.props.loadMore ? this.props.loadMore : false}
				useWindow={false}
			>
				<Paper key={'search-results'} square elevation={0}>
					{this.props.results.map((result, index) => {
						return (
							<SearchResult
								{...result}
								key={'live-search-result-' + index}
								onClick={(data: any) => this.handleResultClick(data, index)}
								isSelected={this.state.selectedIndex == index}
							/>
						);
					})}
				</Paper>
			</InfiniteScroll>,
		];
	}
}
