import React from 'react';
import { FormControl, Grid } from '@material-ui/core';
import { Field } from 'redux-form';
import { themePalette } from '../../utilities/branding';
import { makeRestrictRange } from '../../utilities/formatting/data_normalizations';
import { Strings } from '../../assets/common/strings';
import { FormTextField } from '../../utilities/forms';

interface Props {
	name: string;
	title: string;
	min?: number;
	max?: number;
	integer?: boolean;
	minError?: string;
	maxError?: string;
	minPlaceholder?: string;
	maxPlaceholder?: string;
}

const MinMax = (props: Props) => {
	const { min, max, integer, minError, maxError, minPlaceholder, maxPlaceholder } = props;

	const restrictRange = makeRestrictRange({
		min,
		max,
		onlyIntegerValues: integer
	});

	return (
		<FormControl fullWidth style={{ paddingBottom: 5 }}>
			<span
				style={{
					fontSize: '16px',
					color: themePalette.sub_text,
				}}
			>
				{props.title}
			</span>
			<Grid container>
				<Grid item xs={5}>
					<Field
						name={props.name + 'Min'}
						component={FormTextField}
						type="number"
						fullWidth
						normalize={restrictRange}
						helperText={minError}
						error={!!minError}
						placeholder={minPlaceholder}
					/>
				</Grid>
				<Grid item xs={2}>
					<span
						style={{
							margin: '35px 0 0 25px',
							color: themePalette.sub_text,
							width: '100%',
							textAlign: 'center',
						}}
					>
						{Strings.ConsumerSearchFilters.To}
					</span>
				</Grid>
				<Grid item xs={5}>
					<Field
						name={props.name + 'Max'}
						component={FormTextField}
						type="number"
						fullWidth
						normalize={restrictRange}
						helperText={maxError}
						error={!!maxError}
						placeholder={maxPlaceholder}
					/>
				</Grid>
			</Grid>
		</FormControl>
	);
};

export default MinMax;
