import { AppState } from '../reducers/index';
import { createSelector } from 'reselect';
import { Loaded } from '../utilities/utilities';
import { isNullOrUndefined } from 'util';
import { AccountSummary, Lead, LeadListSelected } from '../reducers/LeadReducer';
import { getPage, getId, PageType } from './base_selectors';
import { Lookups } from '../utilities/lookup';
import { makeGetContactFromFirstActivity } from './contact_selectors';
import { getNewestItem } from '../utilities/date_util';
import { EMPTY_LEAD } from '../utilities/empty_entities';
import { Contact } from '../reducers/ContactReducer';


const leadMostRecentUpdatedSort = (a: Loaded<Lead>, b: Loaded<Lead>) =>
	b.data.updatedOn.valueOf() - a.data.updatedOn.valueOf();
/**
 * Lead selectors
 */
const getLoadedLeads = (state: AppState) => state.lead.leads || [];

export const getLeads = (state: AppState) => state.lead.leads.map(loadedLead => loadedLead.data);

export const getSelectedLeads = (state: AppState): LeadListSelected => state.lead.selectedLeads;
export const getSelectedLeadContacts = (state: AppState): Contact[][] => state.lead.selectedLeadContacts;
export const getSelectedLeadIndex = (state: AppState): number => state.lead.selectedLeadIndex;
export const getLeadAccountSummary = (state: AppState): AccountSummary => state.lead.accountSummary;

export const getPageLeads = createSelector(
	[getPage, getId, getLoadedLeads],
	(page: PageType, id: string, leads: Loaded<Lead>[]) => {
		switch (page) {
			case 'HOUSEHOLD_PAGE': {
				return leads
					.filter(lead => !isNullOrUndefined(lead.householdId) && lead.householdId == id)
					.sort(leadMostRecentUpdatedSort);
			}
			case 'EMPLOYER_PAGE': {
				return leads
					.filter(lead => !isNullOrUndefined(lead.householdId) && lead.employerId == id)
					.sort(leadMostRecentUpdatedSort);
			}
			default:
				return leads;
		}
	}
);

export const getMostRecentPageLead = createSelector(
	[getPageLeads],
	(leads: Loaded<Lead>[]) => {
		const ordered = leads.sort(leadMostRecentUpdatedSort);
		return ordered.length
			? ordered[0].data
			: EMPTY_LEAD;
	}
);

export const makeGetLeadsForDisposition = () => createSelector(
	[getLoadedLeads, makeGetContactFromFirstActivity()],
	(leads, loadedContact) => {
		if (loadedContact) {
			const contact = loadedContact.data;

			const leadHasSomeMatchingContact = (lead: Lead) => lead.contacts.some(
				leadContact => leadContact.id === contact.id
			);
			const employerOrHouseholdIdMatchesContact = contact.employerPrimaryContact
				? (lead: Loaded<Lead>) => lead.employerId === contact.employerId
				: (lead: Loaded<Lead>) => lead.householdId === contact.householdId;

			const matchingLeads = leads
				.filter(
					lead =>
						leadHasSomeMatchingContact(lead.data)
						&& employerOrHouseholdIdMatchesContact(lead)
						&& Lookups.OpenStatusCode.matches(lead.data.statusCode)
				)
				.map(lead => lead.data);

			return matchingLeads;
		}
	}
);

export const makeGetNewestMatchingLeadForDispotion = () => createSelector(
	[makeGetLeadsForDisposition()],
	(matchingLeads) => {
		if (matchingLeads) {
			return getNewestItem(matchingLeads, (lead) => lead.updatedOn);
		}
	}
);

export const getOpenLead = createSelector(
	[getPageLeads],
	(leads: Loaded<Lead>[]) => {
		if (leads && leads.length > 0) {
			let correspondingLeads = leads.filter(
				lead =>
					!lead.data.isDeleted &&
					Lookups.OpenStatusCode.matches(lead.data.statusCode)
			);
			if (correspondingLeads.length > 0) return correspondingLeads[0].data;
		};
		return EMPTY_LEAD;

	}
);


export const findOpenLead = (leads: Lead[], householdOrEmployerId?: string) => {
	return leads.find((lead: Lead) => !lead.isDeleted
		&& Lookups.OpenStatusCode.matches(lead.statusCode)
		&& (!householdOrEmployerId
			|| lead.householdId == householdOrEmployerId
			|| (lead.employer && lead.employer.id == householdOrEmployerId))
	)
}