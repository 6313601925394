import React from 'react';
import { Strings } from '../../assets/common/strings';
import { LineOfBusinessAvatar } from '../line_of_business/line_of_business_avatar';
import { themePalette } from '../../utilities/branding';

export const displayLineOfBusinessAvatars = (leadLoBs: string[], productLoBs: string[]): React.ReactNode => {
	const avatarList: JSX.Element[] = [];

	const intersection = leadLoBs.filter((leadLoB: string) => productLoBs.includes(leadLoB));
	const productLoBOnly = productLoBs.filter((productLoB: string) => !intersection.includes(productLoB));
	const leadLoBOnly = leadLoBs.filter((leadLoB: string) => !intersection.includes(leadLoB));

	intersection.forEach((LoB: string) => {
		if (LoB.toLowerCase() == Strings.LineOfBusiness.LowerCommericalHealth) {
			LoB = 'H';
		}
		avatarList.push(
			<LineOfBusinessAvatar
				key={'lob-' + LoB}
				size={24}
				lineOfBusinessShortString={LoB.slice(0, 1)}
				displayInline
				doubleCircle
			/>
		);
	});
	productLoBOnly.forEach((LoB: string) => {
		if (LoB.toLowerCase() == Strings.LineOfBusiness.LowerCommericalHealth) {
			LoB = 'H';
		}
		avatarList.push(
			<LineOfBusinessAvatar
				key={'lob-' + LoB}
				size={24}
				lineOfBusinessShortString={LoB.slice(0, 1)}
				displayInline
				bubbleColor={themePalette.customer_avatar}
			/>
		);
	});
	leadLoBOnly.forEach((LoB: string) => {
		avatarList.push(
			<LineOfBusinessAvatar
				key={'lob-' + LoB}
				size={24}
				lineOfBusinessShortString={LoB.slice(0, 1)}
				displayInline
			/>
		);
	});

	return avatarList;
};