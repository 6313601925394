import React, { useEffect } from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import moment from 'moment';
import { navigateTo } from '../../actions/navigation_actions';
import { NavigationProps } from '../../components/nav/Routes';
import { DocumentDto, DocumentForm } from '../../reducers/document_reducer';
import { GetAllDocuments, FilterDocuments, FavoriteParams, FavoriteDocument, UnfavoriteDocument } from '../../actions/document_actions';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { MoreMenu, createHelpAction } from '../../components/nav/more_menu';
import { themeLinks, themePalette } from '../../utilities/branding';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import { NotificationSummary, notificationCategories } from '../../components/notifications/notificationSummary';
import { Avatar, IconButton, Icon } from '@material-ui/core';
import { CustomListItem } from '../../components/utility/custom_list_item';
import DocumentsFilter from './documents_filter';
import CustomList from '../../components/utility/custom_list';
import { Strings } from '../../assets/common/strings';
import { getIconSource } from '../../utilities/image_util';
import { Lookups } from '../../utilities/lookup';
import { DesktopPadding } from '../../components/Layout/desktop_padding';
import { SimpleListItem } from '../../components/utility/simple_list_item';
import { makeOpenExternalLink } from '../../utilities';

interface StateProps {
	loading: boolean;
	agentID: string;
	filteredDocuments: DocumentDto[],
	filterForm: DocumentForm,
	categoryList: string[];
	lobList: string[];
}
interface DispatchProps {
	getAllDocs: () => void;
	filterDocs: (filter: DocumentForm) => void;
	faveDoc: (params: FavoriteParams) => void;
	unfaveDoc: (params: FavoriteParams) => void;
}
type Props = StateProps & DispatchProps & NavigationProps;

const _DocumentsPage: React.FC<Props> = (props) => {

	const { loading, filteredDocuments, filterForm, categoryList, lobList, filterDocs, getAllDocs, agentID, faveDoc, unfaveDoc, navigateTo } = props;

	useEffect(() => {
		getAllDocs()
	}, []);

	const toggleFavorite = (documentID: string, favorite: boolean) => () => {
		const params: FavoriteParams = {
			agentID,
			documentID,
		};
		if (favorite) {
			unfaveDoc(params);
		} else {
			faveDoc(params);
		}
	};

	return (
		<BasePageContainer
			topComponent={
				<HeaderBarComponent
					title="Documents"
					rightButtons={
						<MoreMenu
							children={[createHelpAction(themeLinks.helpLinkDocuments)]}
						/>
					}
					customContent={
						<DocumentsFilter
							searchDocuments={filterDocs}
							filterForm={filterForm}
							categoryList={categoryList}
							lobList={lobList}
						/>
					}
				/>
			}
		>
			<PersistentNotifications />
			<NotificationSummary categorySelector={notificationCategories.documents}/>
			<DesktopPadding>
				<CustomList loading={loading}>
					{filteredDocuments.map(
						(document: DocumentDto) => (
							<SimpleListItem
								key={document.id}
								onClick={makeOpenExternalLink(document.urlPath)}
								title={document.title}
								subtitle={`${Strings.DocumentSubtitleLabels.Updated} ${moment(document.modifiedDate).format('MM/DD/YYYY')}`}
								icon={
									<Avatar
										src={getIconSource(document.fileExtension)}
									/>
								}
								secondaryAction={
									<IconButton
										onClick={toggleFavorite(document.id, document.favorite)}
										style={{
											top: 0,
											color: document.favorite
												? themePalette.favorite
												: themePalette.unfavorite
										}}
									>
										<Icon>star</Icon>
									</IconButton>
								}
							/>
						)
					)}
				</CustomList>
			</DesktopPadding>
		</BasePageContainer>
	);
}

const mapStateToProps = (state): StateProps => {
	return {
		loading: state.documents.loading,
		agentID: state.user.agentID,
		filteredDocuments: state.documents.filteredDocuments,
		filterForm: state.documents.filterForm,
		categoryList: state.lookup.getLabels(Lookups.SharepointFilterCategory),
		lobList: state.lookup.getLabels(Lookups.SharepointFilterLineOfBusiness),
	};
};
const mapDispatchToProps = (dispatch: any): DispatchProps & Partial<NavigationProps> => ({
	navigateTo: (route: string) => dispatch(navigateTo(route)),
	getAllDocs: () => dispatch(GetAllDocuments.started({ loading: true })),
	filterDocs: (filter: DocumentForm) => dispatch(FilterDocuments(filter)),
	faveDoc: (params: FavoriteParams) => dispatch(FavoriteDocument.started(params)),
	unfaveDoc: (params: FavoriteParams) => dispatch(UnfavoriteDocument.started(params)),
});

export const DocumentsPage = connect(mapStateToProps, mapDispatchToProps, true)(_DocumentsPage);
