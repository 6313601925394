import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react';
import { useStylesLicenseAppointment } from '../../../utilities/agentLicenseAppointment_util';
import { Strings } from '../../../assets/common/strings';
import { getFormattedDate } from '../../../utilities/date_util';
import { openExternalLink } from '../../../utilities';
import { isAgentRole } from '../../../utilities/agent_util';
import { AppConfig } from '../../../types/config';
import { local_storage } from '../../../utilities/storage';

interface Props {
	title: string;
	statusDialogOpen: boolean;
	handleDialogClose: () => void;
	expirationDate?: Date
}

const AppointmentTerminatedDialog: React.FC<Props> = (props) => {

	const classes = useStylesLicenseAppointment();
	const { title, statusDialogOpen, handleDialogClose, expirationDate } = props;
	const isImpersonating = Boolean(local_storage.getImpersonatingJwt())

	const onClickGetAppointedBtn = () => {
		const url = AppConfig.resource_center_base_url + Strings.LicenseAppointment.HASSLinkPath;
		openExternalLink(url);
		handleDialogClose();
	};

	return (
		<Dialog
			open={statusDialogOpen}
			onClose={handleDialogClose}
			PaperProps={{
				className: classes.terminatedDialog,
			}}>
			<DialogTitle className={`${classes.textCenter} ${classes.terminatedDialogTextColor}`}>{title} {Strings.Activity.Appointment}</DialogTitle>

			<DialogContent>
				<h3 className={`${classes.terminatedAppointmentTextColor} ${classes.bold_text} ${classes.textCenter}`}>{Strings.AppointmentStatusIcon.Terminated}</h3>
				<div className={`${classes.positionCenter} ${classes.terminatedDialogTextColor}`}>{Strings.AppointmentStatusIcon.Terminated} Date:&nbsp;<div className={`${classes.terminatedAppointmentTextColor} ${classes.bold_text} `}>{getFormattedDate(expirationDate, 'MM/DD/YYYY', false)}</div></div>
			</DialogContent>

			<DialogActions className={classes.buttonActions}>

				<Button onClick={handleDialogClose} className={classes.terminatedDialogTextColor} >CLOSE</Button>
				{!isImpersonating && <div className={classes.appointedButton}>
					<Button className={classes.getAppointedBtnTextColor}
						variant="contained"
						fullWidth
						color="secondary"
						onClick={onClickGetAppointedBtn}
					>GET APPOINTED</Button>
				</div>
				}

			</DialogActions>
		</Dialog>
	);
};

export default AppointmentTerminatedDialog;
