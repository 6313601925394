import { put, call, takeEvery, all, throttle } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { LogToSplunk, SentLogs, LoggingProps } from '../actions/logging_actions';
import { http } from '../utilities';
import { Strings } from '../assets/common/strings';
import { HttpOptions } from '../utilities/http';
import { getPendingLogMessages } from '../selectors/logging_selectors';
import { selectFromImmutable } from '../utilities/saga_util';
import { Dictionary } from '../utilities/object_util';
import { Message } from '../reducers/logging_reducer';
import { getSession } from '../utilities/logging_util';

const loggingIntervalMs = 30 * 1000;

function* handleLogs(action: Action<LoggingProps>) {
	const logs: Dictionary<Message> = yield selectFromImmutable<Dictionary<Message>>(getPendingLogMessages);
	const logIds = Object.keys(logs);

	if (logIds && logIds.length) {
		const messages = logIds.map(id => logs[id]);
		const session = getSession();
		const body = JSON.stringify({ messages, session });

		const options: HttpOptions = {
			method: Strings.Http.Post,
			body,
		};
		const response = yield call(http, Strings.ApiUrls.Splunk, options);

		if (response.ok) {
			yield put(SentLogs(logIds));
			yield put(LogToSplunk.done({ params: action.payload, result: undefined }));
		} else {
			yield put(LogToSplunk.failed({ params: action.payload, error: response.error }));
		}
	}
}

const isFailedAction = (action: Action<any>) => action.type.endsWith('_FAILED');
function* handleFailedActions(action: Action<any>) {
	yield put(LogToSplunk.started({
		level: Strings.Log.Error,
		message: action.type,
		metadata: action.payload
	}));
}

export function* loggingSagas() {
	yield all([
		takeEvery(
			isFailedAction,
			handleFailedActions
		),
		throttle(loggingIntervalMs,
			LogToSplunk.started,
			handleLogs
		),
	])
}
