import { AcceptTermsAndConditions, UpdateDesktopCallSettings, GetUserByIdParams } from '../actions/user_actions';
import { call, put, all } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import http, { HttpOptions } from '../utilities/http';
import {
	StoreGoogleCalendarSettings,
	storeGoogleCalendarSettingsSuccess,
	storeGoogleCalendarSettingsFailure,
	syncGoogleCalendarSuccess,
	syncGoogleCalendarFailure,
	GetUserById,
	getUserByIdSuccess,
	getUserByFailure,
	GetUserByAgentCode,
	AcceptWelcomeVideo,
	UpdateDeviceSettings,
} from '../actions/user_actions';
import { QueueSnackbar } from '../actions/snackbar_actions';
import { User, DeviceSettings } from '../reducers/user_reducer';
import { isNullOrUndefinedOrEmpty } from '../utilities/utilities';
import { Strings } from '../assets/common/strings';
import { takeLatestForActionType } from '../utilities/saga_util';
import { getSnackbarSuccessProps, getSnackbarErrorProps } from '../utilities/snackbar_util';

function* handleGetUserByAgentCodeSaga(action: Action<string>) {
	const params = action.payload;
	try {
		const response = yield call(http, `user/agent/${params}/`);
		if (response.ok) {
			const data: User = yield response.json();
			yield put(GetUserByAgentCode.done({ params, result: data }));
		} else {
			yield put(GetUserByAgentCode.failed({ params, error: `${response.status} - Failed to get user by agent code` }));
		}
	} catch (error) {
		yield put(GetUserByAgentCode.failed({ params, error: `Failed to get user by agent code - ${error}` }));
	}
}

function* handleGetUserById(action: Action<GetUserByIdParams>) {
	const { id, sync } = action.payload;
	try {
		const response = yield call(http, `user/${id}`);
		let syncResponse: any;
		if (response.ok) {
			const data: User = yield response.json();
			yield put(getUserByIdSuccess(id, data, sync));
			if (
				sync &&
				data.googleRefreshToken &&
				(data.syncGoogleAppointments ||
					data.syncGooglePhoneCalls ||
					data.syncGoogleTasks)
			) {
				syncResponse = yield call(syncGoogleCalendarCall);
				if (syncResponse.ok) {
					const syncData = yield syncResponse.json();
					yield put(syncGoogleCalendarSuccess(syncData));
					if (!isNullOrUndefinedOrEmpty(syncData['googleRefreshToken'])) {
						yield put(
							QueueSnackbar(getSnackbarSuccessProps('Google Sync was successful'))
						);
					} else {
						yield put(
							QueueSnackbar(getSnackbarSuccessProps('Your account is unverified with Google'))
						);
					}
				} else {
					yield put(
						syncGoogleCalendarFailure({
							errorCode: syncResponse.status,
						})
					);
					if (syncResponse.status === 409) {
						yield put(
							QueueSnackbar(getSnackbarSuccessProps('Google Sync already in progress'))
						);
					} else {
						yield put(
							QueueSnackbar(getSnackbarErrorProps('Failed to sync To Dos'))
						);
					}
				}
			}
		} else {
			yield put(
				getUserByFailure(
					id,
					{
						errorCode: response.status,
					},
					sync
				)
			);
		}
	} catch (error) {
		yield put(getUserByFailure(id, error, sync));
	}
}

function* handleAcceptWelcomeVideo() {
	yield call(http, 'User/AcceptVideoIntro', { method: 'POST' });
}

function* handleUpdateDeviceSettings(action: Action<DeviceSettings>) {
	try {
		const response = yield call(http, 'User/EditDevice', {
			method: 'PUT',
			body: JSON.stringify(action.payload)
		});
		if (response.ok) {
			yield put(UpdateDeviceSettings.done({ params: action.payload, result: undefined }));
		} else {
			yield put(UpdateDeviceSettings.failed({ params: action.payload, error: `${response.status} - Failed to edit notification setting` }));
		}
	} catch (error) {
		yield put(UpdateDeviceSettings.failed({ params: action.payload, error: `Failed to edit notification setting - ${error}` }));
	}
}

function* handleUpdateDesktopCallSettings(action: Action<User>) {
	try {
		const response = yield call(http, 'User/UpdateDesktopCallSettings', {
			method: 'POST',
			body: JSON.stringify(action.payload)
		});
		if (response.ok) {
			const result = yield response.json() || "Unknown";
			yield all([
				put(UpdateDesktopCallSettings.done({ params: action.payload, result })),
				put(QueueSnackbar(getSnackbarSuccessProps('Updated settings')))
			]);
		} else {
			yield all([
				put(UpdateDesktopCallSettings.failed({ params: action.payload, error: `${response.status} - Failed to edit desktop call setting` })),
				put(QueueSnackbar(getSnackbarErrorProps(Strings.Settings.FailedToUpdate)))
			]);
		}
	} catch (error) {
		yield all([
			put(UpdateDesktopCallSettings.failed({ params: action.payload, error: `Failed to edit desktop call setting - ${error}` })),
			put(QueueSnackbar(getSnackbarErrorProps(Strings.Settings.FailedToUpdate)))
		]);
	}
}

//TODO: extract into update user saga
function* storeGoogleCalendarSettingsAndSyncSaga(action: Action<User>) {
	try {
		const settingsResponse = yield call(
			storeGoogleCalendarSettingsCall,
			action.payload
		);
		yield put(
			QueueSnackbar(getSnackbarSuccessProps('Storing your sync settings...'))
		);
		let syncResponse: any;
		if (settingsResponse.ok) {
			const data = yield settingsResponse.json();
			yield put(storeGoogleCalendarSettingsSuccess(action.payload, data));
			syncResponse = yield call(syncGoogleCalendarCall);
			if (syncResponse.ok) {
				const syncData = yield syncResponse.json();
				yield put(syncGoogleCalendarSuccess(syncData));
				if (!isNullOrUndefinedOrEmpty(syncData['googleRefreshToken'])) {
					yield put(
						QueueSnackbar(getSnackbarSuccessProps('Google Sync was successful'))
					);
				} else {
					yield put(
						QueueSnackbar(getSnackbarSuccessProps('Your account is unverified'))
					);
				}
			} else {
				yield put(
					syncGoogleCalendarFailure({
						errorCode: syncResponse.status,
					})
				);
				if (syncResponse.status === 409) {
					yield put(
						QueueSnackbar(getSnackbarSuccessProps('Google Sync already in progress'))
					);
				} else {
					yield put(
						QueueSnackbar(getSnackbarErrorProps('Failed to sync To Dos'))
					);
				}
			}
		} else {
			yield put(
				storeGoogleCalendarSettingsFailure(action.payload, {
					errorCode: settingsResponse.status,
				})
			);
			yield put(
				QueueSnackbar(getSnackbarErrorProps('Failed to store your sync settings.'))
			);
		}
	} catch (error) {
		yield put(storeGoogleCalendarSettingsFailure(action.payload, error));
	}
}

function storeGoogleCalendarSettingsCall(user: User): Promise<any> {
	const options: HttpOptions = {
		method: 'PUT',
		body: JSON.stringify(user),
	};
	return http('user', options);
}

function syncGoogleCalendarCall(): Promise<any> {
	return http('activity/syncactivitieswithgoogle', {
		method: Strings.Http.Post,
		mode: Strings.HttpMode.Cors,
	});
}


function* handleAcceptTermsAndConditions() {
	yield http('User/AcceptTermsAndConditions', {
		method: Strings.Http.Post,
	})
}

export function* userSagas() {
	yield all([
		takeLatestForActionType(
			AcceptTermsAndConditions,
			handleAcceptTermsAndConditions
		),
		takeLatestForActionType(
			GetUserByAgentCode.started,
			handleGetUserByAgentCodeSaga
		),
		takeLatestForActionType(
			GetUserById.started,
			handleGetUserById
		),
		takeLatestForActionType(
			UpdateDesktopCallSettings.started,
			handleUpdateDesktopCallSettings
		),
		takeLatestForActionType(
			AcceptWelcomeVideo,
			handleAcceptWelcomeVideo
		),
		takeLatestForActionType(
			UpdateDeviceSettings.started,
			handleUpdateDeviceSettings
		),
		takeLatestForActionType(
			StoreGoogleCalendarSettings.started,
			storeGoogleCalendarSettingsAndSyncSaga
		)
	]);
}