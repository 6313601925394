import { Action } from 'typescript-fsa';
import { call, put, all } from 'redux-saga/effects';
import http from '../utilities/http';
import { GetTools } from '../actions/tools_actions';
import { Tool } from '../reducers/tools_reducer';
import { submitLog } from '../utilities/logging_util';
import { QueueSnackbar } from '../actions/snackbar_actions';
import { Strings } from '../assets/common/strings';
import { takeLatestForActionType } from '../utilities/saga_util';
import { getSnackbarErrorProps } from '../utilities/snackbar_util';
import { navRoutes } from '../components/nav/Routes';

function* getToolsSaga(action: Action<undefined>) {
	const errorSnackbarProps = getSnackbarErrorProps('Error getting tools');

	try {
		const response = yield call(http, 'Tools');
		if (response.ok) {
			const data: Tool[] = yield response.json();

			const battleTool: Tool = {
				id: Strings.SideBarMenu.Battles,
				name: Strings.SideBarMenu.Battles,
				description: '',
				learnMore: '',
				url: navRoutes.battles.path,
				isSSO: false,
				isWidgetTool: true,
			};

			const toolItemList = data && data.concat(battleTool);

			yield put(GetTools.done({ params: action.payload, result: toolItemList }));
		} else {
			yield put(
				GetTools.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
		}
	} catch (error) {
		submitLog(Strings.Log.Error, `Error getting tools`, {
			error,
			payload: action.payload,
		});
		yield put(GetTools.failed({ params: action.payload, error }));
		yield put(QueueSnackbar(errorSnackbarProps));
	}
}

export function* toolsSagas() {
	yield all([
		takeLatestForActionType(
			GetTools.started,
			getToolsSaga
		),
	])
}