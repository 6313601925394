import { IconButton } from '@material-ui/core';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import React from 'react';
import { themePalette } from '../../utilities/branding';
import ClampLines from 'react-clamp-lines';
import { SvgIcon } from '../svgs/svg_icon';
import { displayDate } from '../../utilities/date_util';
import { SimpleListItem } from '../utility/simple_list_item';
import classNames from 'classnames';
import { useListItemStyles } from '../../utilities/hooks/styles';
import { Notification } from '../../containers/notifications_page/notifications_page';

interface Props {
	notification: Notification;
	singleLine?: boolean;
	onClick: () => void;
	deepLinkOnClick?: () => void;
}
export const NotificationItem: React.FC<Props> = (props) => {

	const { notification, singleLine, onClick, deepLinkOnClick } = props;
	const { id, isRead, category, isUrgent, title, content, startDate } = notification;
	const styles = useListItemStyles();

	return (
		<div style={{ 
			backgroundColor: isRead 
				? themePalette.secondary_background
				: themePalette.default_background,
			}} 
			key={id}
		>
			<SimpleListItem
				onClick={onClick}
				icon={getNotificationIcon(category, isUrgent)}
				hideArrow
				title={
					<span className={classNames(!isRead && styles.fontBold)}>
						{singleLine ? (
							<ClampLines
								text={title || ''}
								lines={1}
								buttons={false}
							/>
						) : (
							title
						)}
					</span>
				}
				subtitle={
					singleLine ? (
						<ClampLines
							text={content?.base || ''}
							lines={1}
							buttons={false}
						/>
					) : (
						content.base
					)
				}
				rightText={
					<span className={classNames(!isRead && styles.fontBold)}>
						{displayDate(startDate)}
					</span>
				}
				secondaryAction={deepLinkOnClick && (
					<IconButton onClick={deepLinkOnClick} color="secondary">
						<OpenInBrowserIcon/>
					</IconButton>
				)}
			/>
		</div>
	);
};


export function getNotificationIcon(type: string, isUrgent?: boolean) {
	const badgeType = isUrgent ? 'urgent' : (type || 'default').toLowerCase();
	return (
		<SvgIcon type={badgeType} size={32} />
	);
}
