import { themePalette } from '../utilities/branding';

export const trimString = (str: string, maxLength: number) => {
	if (str && str.length > maxLength) {
		return str.substr(0, maxLength - 2).concat('...');
	} else {
		return str;
	}
};

export const ListStyle: { [key: string]: React.CSSProperties } = {
	itemDate: {
		position: 'absolute',
		fontWeight: 100,
		color: themePalette.tertiary_text,
		margin: 0,
		top: 0,
		right: '2%',
		textAlign: 'right',
		fontSize: 11,
	},
	name: {
		position: 'absolute',
		fontWeight: 100,
		color: themePalette.tertiary_text,
		margin: 0,
		top: 0,
		left: 80,
		fontSize: 11,
	},
	item: {
		marginTop: '4px',
		paddingTop: '5px',
	},
};
