import { Drawer } from '@material-ui/core';
import React from 'react';
import { Dispatch } from 'redux';
import { connect } from '@optum-uhone-hmkts/rise';
import { setDrawerStatus } from '../../actions/access_page_actions';
import { SideMenu } from './side_menu';
import { AppState } from '../../reducers';

interface StateProps {
	drawerOpenStatus: boolean;
	isAuthenticated: boolean;
	isDesktop: boolean;
	firstTimeLogin: boolean;
	needsTermsAndConditions: boolean;
	needsVideo: boolean;
	videoFullScreen: boolean;
	needsPin: boolean;
	clickToCallEnabled: boolean;
	isSideMenuVisible:boolean;
}
interface DispatchProps {
	setDrawerStatus(visible: boolean): void;
}
type Props = StateProps & DispatchProps;

class _SideMenuWrapper extends React.PureComponent<Props> {

	componentDidUpdate(prevProps: Props) {
		if (
			prevProps.isAuthenticated !== this.props.isAuthenticated ||
			prevProps.isDesktop !== this.props.isDesktop ||
			prevProps.firstTimeLogin !== this.props.firstTimeLogin ||
			prevProps.needsTermsAndConditions !== this.props.needsTermsAndConditions ||
			prevProps.needsVideo !== this.props.needsVideo ||
			prevProps.videoFullScreen !== this.props.videoFullScreen ||
			prevProps.needsPin
		) {
			const {
				isAuthenticated,
				isDesktop,
				firstTimeLogin,
				needsTermsAndConditions,
				needsVideo,
				needsPin,
				videoFullScreen,
			} = this.props;

			this.props.setDrawerStatus(
				isAuthenticated &&
				isDesktop &&
				!videoFullScreen &&
				!needsPin &&
				!firstTimeLogin &&
				!needsTermsAndConditions &&
				!needsVideo
			);
		}
	}

	render() {
		const { drawerOpenStatus, clickToCallEnabled, isSideMenuVisible } = this.props;
		return (
			(!clickToCallEnabled && isSideMenuVisible) && (
				<Drawer
					key={'DesktopDrawer'}
					open={drawerOpenStatus}
					variant={this.props.isDesktop ? "persistent" : "temporary"}
					onClose={() => this.props.setDrawerStatus(false)}
					keepMounted
				>
					<SideMenu/>
				</Drawer>	
			)
		);
	}
}

const mapStateToProps = (state: AppState): StateProps => ({
	drawerOpenStatus: state.accessPage.drawerOpenStatus && !!state.user.id,
	isAuthenticated: state.authentication.isAuthenticated,
	isDesktop: state.layout.desktop,
	firstTimeLogin: state.authentication.firstTimeLogin,
	needsTermsAndConditions: !state.user.acceptanceDate,
	needsVideo: !state.user.videoAcceptanceDate,
	videoFullScreen: state.user.videoFullScreen,
	needsPin: state.authentication.needsPin,
	clickToCallEnabled: state.clickToCall.enabled,
	isSideMenuVisible: state.layout.isSideMenuVisible
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	setDrawerStatus: (visible: boolean) => dispatch(setDrawerStatus(visible)),
});

export const SideMenuWrapper = connect(mapStateToProps, mapDispatchToProps, true)(_SideMenuWrapper);
