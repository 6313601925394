import { IconButton, Paper } from '@material-ui/core';
import React from 'react';
import { useBubbleStyles } from '../../utilities/hooks/styles';

interface Props {
	content: string | JSX.Element;
	caption?: string;
	onClick?: () => void;
}
export const PaperIcon: React.FC<Props> = (props) => {

	const { content, caption, onClick } = props;
	const styles = useBubbleStyles();

	return (
		<>
			<IconButton className={styles.noPadding} onClick={onClick}>
				<Paper className={styles.bubble} elevation={0}>
					<div className={styles.content}>{content}</div>
				</Paper>
			</IconButton>
			<div className={styles.caption}>{caption}</div>
		</>
	);
};
