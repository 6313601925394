import {
	Grid,
	Icon,
	Chip,
	InputAdornment,
	MenuItem,
	TextField,
} from '@optum-uhone-hmkts/rise';
import moment from 'moment';
import React from 'react';
import {
	initialFilterState,
	LeadFilters,
} from '../../reducers/LeadFilterReducer';
import { isNullOrUndefinedOrEmpty } from '../../utilities/utilities';
import { LookupDictionary, Lookups } from '../../utilities/lookup';
import { DatePicker, MultiSelect } from '../redux_form_material';
import { getLeadStatusReasonLabels } from '../../utilities/lead_util';
import { Strings } from '../../assets/common/strings';
import { ListFilter } from '../filter/list_filter';

interface Props extends LeadFilters {
	onSearch: (filterValues: LeadFilters, startOver?: boolean) => void;
	ratingList: string[];
	sortList: string[];
	lineOfBusinessList: string[];
	vendorList: string[];
	inputSourceList: string[];
	campaignList: string[];
	dateTypeList: string[];
	pageSize: number;
	lookups: LookupDictionary;
	callAttemptsList: string[];

	leadTagFilterResult: string[];
	leadTagSearchCallback: (search: string) => void;
}

interface State extends LeadFilters {
	toDateError: string;
	vendorList: string[];
	inputSourceList: string[];
	fromDateError: string;
	vendorError: string;
	leadTypeChips: string[];
	inputSourceChips: string[];
}

export class LeadsFilter extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			filterIsOpen: props.filterIsOpen,
			ratingFilter: props.ratingFilter,
			statusFilter: props.statusFilter.clone(),
			statusGroupFilter: props.statusGroupFilter.clone(),
			lineOfBusinessFilter: props.lineOfBusinessFilter,
			vendorFilter: props.vendorFilter,
			vendorList: [Lookups.All.label].concat(this.props.vendorList),
			inputSourceList: [Lookups.All.label].concat(this.props.inputSourceList),
			campaignFilter: props.campaignFilter,
			dateTypeFilter: props.dateTypeFilter,
			sortBy: props.sortBy,
			sortCategory: props.sortCategory,
			sortDirection: props.sortDirection,
			filterBySearch: props.filterBySearch,
			fromDate: props.fromDate,
			toDate: props.toDate,
			toDateError: '',
			vendorError: '',
			fromDateError: '',
			leadTypeFilter: [...props.leadTypeFilter],
			leadTypeChips: props.leadTypeFilter.length == 0 ? ['All'] : [...props.leadTypeFilter],
			inputSourceFilter: [...props.inputSourceFilter],
			inputSourceChips: props.inputSourceFilter.length == 0 ? ['All'] : [...props.inputSourceFilter],
			callAttempts: props.callAttempts,
			leadTags: props.leadTags,
		};
	}

	createMenuItems = (options: string[], includeAllOption: boolean) => {
		const items = includeAllOption
			? [Lookups.All.label].concat(options)
			: options;
		return items.map((item: string, index: number) => {
			return (
				<MenuItem key={index} value={item}>
					{item}
				</MenuItem>
			);
		});
	};

	createSelectItems = (options: string[], includeAllOption: boolean) => {
		const items = includeAllOption
			? [Lookups.All.label].concat(options)
			: options;
		return items.map((item: string, index: number) => {
			return (
				<option value={item}>
					{item}
				</option>
			);
		});
	};

	createTextField = (
		fieldOptions: string[],
		selectedItem: string,
		changeFunction: any,
		label: string
	) => {
		const includeAllOption =
			label != Strings.LeadField.DateType &&
			label != Strings.LeadField.Sort &&
			fieldOptions.length > 1;
		const value = fieldOptions.length == 1 ? fieldOptions[0] : selectedItem;
		return (
			<Grid item xs={12}>
				<TextField
					select
					onChange={changeFunction}
					fullWidth
					value={value}
					label={label}
				>
					{label === Strings.LeadField.DateType && <MenuItem key={`${selectedItem}-None`} value={''} />}
					{this.createMenuItems(fieldOptions || [], includeAllOption)}
				</TextField>
			</Grid>
		);
	};


	createMultiSelectBadgeField = (
		fieldOptions: string[],
		selectedItem: string[],
		changeFunction: any,
		label: string,
		chips: string[],
		onDelete: (value: string) => (event: any) => void
	) => {
		const includeAllOption =
			label != Strings.LeadField.DateType &&
			label != Strings.LeadField.Sort &&
			fieldOptions.length > 1;
		const value = fieldOptions.length == 1 ? fieldOptions[0] : selectedItem;
		return (
			<Grid item xs={12}>
				<TextField
					select
					onChange={changeFunction}
					fullWidth
					value={value}
					label={label}
				>
					{label === Strings.LeadField.DateType && <MenuItem key={`${selectedItem}-None`} value={''} />}
					{this.createMenuItems(fieldOptions || [], includeAllOption)}
				</TextField>

				{chips.map(chip => (
					<Chip
						style={{
							marginTop: '2%',
							marginRight: '.5%'
						}}
						key={chip}
						label={chip}
						onDelete={onDelete(chip)}
					/>))}
			</Grid>

		);
	};

	generateSelectedFilters = () => {
		const status =
			this.state.statusGroupFilter.isLookup(Lookups.All) &&
				this.state.statusFilter.isLookup(Lookups.All)
				? undefined
				: this.state.statusGroupFilter.isLookup(Lookups.All) ||
					this.state.statusGroupFilter.label == this.state.statusFilter.label
					? this.state.statusFilter.label
					: this.state.statusFilter.isLookup(Lookups.All)
						? this.state.statusGroupFilter.label
						: `${this.state.statusGroupFilter.label}, ${
						this.state.statusFilter.label
						}`;
		const filterArray = [
			this.state.ratingFilter == 'All' ? '' : this.state.ratingFilter,
			status,
			this.state.lineOfBusinessFilter == 'All'
				? ''
				: this.state.lineOfBusinessFilter,
			this.state.leadTypeFilter.indexOf('All') > -1 ? [] : this.state.leadTypeFilter.join(', '),
			this.state.vendorFilter == 'All' ? '' : this.state.vendorFilter,
			this.state.inputSourceFilter.indexOf('All') > -1 ? [] : this.state.inputSourceFilter.join(', '),
			this.state.campaignFilter == 'All' ? '' : this.state.campaignFilter,
			this.state.callAttempts == 'All' ? '' : "Calls Attempted - " + this.state.callAttempts,
			this.state.leadTags && this.state.leadTags.length ? 'Tags' : '',
			this.state.dateTypeFilter,
			this.state.filterBySearch,
		].filter(Boolean);

		return filterArray.length > 0 ? filterArray.join(', ') : 'All Leads';
	};

	handleRatingFilter = (event: any) => {
		let value = '';
		event.target.value == 'All'
			? (value = 'All')
			: (value = event.target.value);
		this.setState({ ratingFilter: value });
	};

	handleDateFrom = (event: any) => {
		this.setState({ fromDate: event.target.value });
	};

	handleDateTo = (event: any) => {
		this.setState({ toDate: event.target.value });
	};

	handleLineOfBusinessFilter = (event: any) => {
		this.setState({ lineOfBusinessFilter: event.target.value });
	};

	handleLeadTypeFilter = (event: any) => {
		let chips = this.state.leadTypeChips;

		if (chips.indexOf(event.target.value) === -1) {
			chips = chips.filter(chips => chips != 'All');
			chips.push(event.target.value);
			if (event.target.value == 'All') {
				this.setState({ leadTypeFilter: [], leadTypeChips: ['All'] });
			} else {
				this.setState({ leadTypeFilter: chips, leadTypeChips: chips });
			}
		}
	};

	removeLeadTypeChip = (value: string) => (event: any) => {
		var chips = this.removeSelected(this.state.leadTypeChips, value);
		const filter = chips.indexOf('All') > -1 ? [] : chips;
		this.setState({ leadTypeFilter: filter, leadTypeChips: chips });
	}

	removeSelected = (chips: string[], value: string) : string[] => {
		if (value == 'All') {
			return ['All'];
		}
		else {
			const newChips = chips.filter(chips => chips != value);
			if (!newChips.length) {
				return ['All'];
			} else {
				return newChips;
			}
		}
	};

	handleStatusCodeFilter = (event: any) => {
		const statusGroupFilter = this.props.lookups.findOrCreate(event.target.value);
		const statusFilter = this.state.statusFilter.isChildOf([statusGroupFilter])
			? this.state.statusFilter
			: Lookups.All;
		this.setState({ statusGroupFilter, statusFilter });
	};

	handleStatusFilter = (event: any) => {
		const statusFilter = this.props.lookups.findOrCreate(event.target.value).clone();
		this.setState({ statusFilter });
	};

	handleVendorFilter = (event: any) => {
		this.setState({ vendorFilter: event.target.value });
	};

	handleInputSourceFilter = (event: any) => {
		let chips = this.state.inputSourceChips;

		if (chips.indexOf(event.target.value) === -1) {
			chips = chips.filter(chips => chips != 'All');
			chips.push(event.target.value);
			if (event.target.value == 'All') {
				this.setState({ inputSourceFilter: [], inputSourceChips: ['All'] });
			} else {
				this.setState({ inputSourceFilter: chips, inputSourceChips: chips });
			}
		}
	};

	removeInputSourceChip = (value: string) => (event: any) => {
		var chips = this.removeSelected(this.state.inputSourceChips, value);
		const filter = chips.indexOf('All') > -1 ? [] : chips;
		this.setState({ inputSourceFilter: filter, inputSourceChips: chips });
	}

	handleCampaignFilter = (event: any) => {
		this.setState({ campaignFilter: event.target.value });
	};

	handleDateTypeFilter = (event: any) => {
		//Allow 'Any' to pass through for browserURL logic
		this.setState({ dateTypeFilter: event.target.value });
	};

	handleSortBy = (event: any) => {
		this.setState({ sortBy: event.target.value });
	};

	handleCallAttempts = (event: any) => {
		this.setState({ callAttempts: event.target.value });
	};

	handleFilterBySearch = (event: any) => {
		this.setState({ filterBySearch: event.target.value });
	};

	handleReset = () => {
		this.setState({ ...initialFilterState, leadTypeChips: ['All'], inputSourceChips: ['All'] });
	};

	handleSearch = () => {
		this.props.onSearch(
			{
				ratingFilter: this.state.ratingFilter,
				statusFilter: this.state.statusFilter,
				statusGroupFilter: this.state.statusGroupFilter,
				lineOfBusinessFilter: this.state.lineOfBusinessFilter,
				vendorFilter: this.state.vendorFilter,
				inputSourceFilter: this.state.inputSourceFilter,
				campaignFilter: this.state.campaignFilter,
				dateTypeFilter: this.state.dateTypeFilter,
				sortBy: this.state.sortBy,
				fromDate: this.state.fromDate,
				toDate: this.state.toDate,
				filterBySearch: this.state.filterBySearch,
				sortCategory: this.state.sortCategory,
				sortDirection: this.state.sortDirection,
				filterIsOpen: this.state.filterIsOpen,
				leadTypeFilter: this.state.leadTypeFilter,
				callAttempts: this.state.callAttempts,
				leadTags: this.state.leadTags,
			},
			true
		);
	};

	cancelSearch = () => {
		this.setState(
			{
				ratingFilter: this.props.ratingFilter,
				statusFilter: this.props.statusFilter.clone(),
				statusGroupFilter: this.props.statusGroupFilter.clone(),
				lineOfBusinessFilter: this.props.lineOfBusinessFilter,
				vendorFilter: this.props.vendorFilter,
				inputSourceFilter: this.props.inputSourceFilter,
				campaignFilter: this.props.campaignFilter,
				dateTypeFilter: this.props.dateTypeFilter,
				sortBy: this.props.sortBy,
				sortCategory: this.props.sortCategory,
				sortDirection: this.props.sortDirection,
				filterBySearch: this.props.filterBySearch,
				fromDate: this.props.fromDate,
				toDate: this.props.toDate,
				leadTypeFilter: [...this.props.leadTypeFilter],
				callAttempts: this.props.callAttempts,
				leadTags: this.props.leadTags,
			}
		);
	};

	renderForm = () => {
		const dateFormat = 'YYYY-MM-DD';
		return (
			<Grid container>
				<Grid item xs={12}>
					<TextField
						name="filterBySearchField"
						label=""
						value={this.state.filterBySearch}
						onChange={this.handleFilterBySearch}
						fullWidth
						placeholder="Search for name, email, phone, etc."
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Icon>search</Icon>
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<MultiSelect
						label="Lead Tags"
						resultSet={this.props.leadTagFilterResult}
						fullWidth
						forceAutoComplete
						onChangeCallback={this.props.leadTagSearchCallback}
						input={{
							value: this.state.leadTags,
							onChange: (values: string[]) => this.setState({ leadTags: values })
						}}
					/>
				</Grid>
				{this.createTextField(
					this.props.ratingList,
					this.state.ratingFilter,
					this.handleRatingFilter,
					Strings.LeadField.Rating
				)}
				{this.createTextField(
					this.props.lookups.getLabels(Lookups.DerivedLeadStatus),
					this.state.statusGroupFilter.label,
					this.handleStatusCodeFilter,
					'Status'
				)}
				{this.createTextField(
					getLeadStatusReasonLabels(
						this.state.statusGroupFilter,
						this.props.lookups
					),
					this.state.statusFilter.label,
					this.handleStatusFilter,
					'Status Reason'
				)}
				{this.createTextField(
					this.props.callAttemptsList,
					this.state.callAttempts,
					this.handleCallAttempts,
					'Call Attempts'
				)}
				{this.createTextField(
					this.props.lookups.getLabels(Lookups.LeadLineOfBusiness),
					this.state.lineOfBusinessFilter,
					this.handleLineOfBusinessFilter,
					Lookups.LeadLineOfBusiness.label
				)}
				{this.createMultiSelectBadgeField(
					this.props.lookups.getLabels(Lookups.LeadType).sort(),
					this.state.leadTypeFilter,
					this.handleLeadTypeFilter,
					Lookups.LeadType.label,
					this.state.leadTypeChips,
					this.removeLeadTypeChip
				)}
				{this.createTextField(
					this.props.vendorList,
					this.state.vendorFilter,
					this.handleVendorFilter,
					Strings.LeadField.Vendor
				)}
				<Grid item xs={12}>
					<TextField
						name="campaignFilter"
						label={Strings.LeadField.Campaign}
						value={this.state.campaignFilter}
						onChange={this.handleCampaignFilter}
						fullWidth
					/>
				</Grid>
				{this.createMultiSelectBadgeField(
					this.props.lookups.getLabels(Lookups.InputSource).sort(),
					this.state.inputSourceFilter,
					this.handleInputSourceFilter,
					Lookups.InputSource.label,
					this.state.inputSourceChips,
					this.removeInputSourceChip
				)}
				{this.createTextField(
					this.props.dateTypeList,
					this.state.dateTypeFilter,
					this.handleDateTypeFilter,
					Strings.LeadField.DateType
				)}
				<Grid item xs={6}>
					<DatePicker
						label="From"
						input={{
							onChange: date =>
								this.setFrom(moment(date, dateFormat).startOf('day')),
							value: moment(this.state.fromDate).toDate(),
						}}
						fullWidth
						maxDate={moment(this.state.toDate).toDate()}
						disabled={isNullOrUndefinedOrEmpty(this.state.dateTypeFilter)}
						meta={
							{
								error: this.state.fromDateError,
							} as any
						}
					/>
				</Grid>
				<Grid item xs={6}>
					<DatePicker
						label="To"
						input={{
							onChange: date =>
								this.setTo(moment(date, dateFormat).endOf('day')),
							value: moment(this.state.toDate).toDate(),
						}}
						fullWidth
						minDate={moment(this.state.fromDate).toDate()}
						disabled={isNullOrUndefinedOrEmpty(this.state.dateTypeFilter)}
						meta={
							{
								error: this.state.toDateError,
							} as any
						}
					/>
				</Grid>
				{this.createTextField(
					this.props.sortList,
					this.state.sortBy,
					this.handleSortBy,
					'Sort'
				)}
			</Grid>
		);
	};

	render() {
		const subtitle = this.generateSelectedFilters();
		return (
			<ListFilter
				drawerTitle="Leads"
				onSearch={this.handleSearch}
				onCancel={this.cancelSearch}
				onReset={this.handleReset}
				cardSubtitle={subtitle}
				drawerSubtitle={subtitle}
				isSearchDisabled={!!(this.state.toDateError || this.state.fromDateError)}
				startOpen={this.props.filterIsOpen}
			>
				{this.renderForm()}
			</ListFilter>
		);
	}

	setFrom = (date: moment.Moment) => {
		const error = date.isAfter(this.state.toDate)
			? 'From Date is after To Date'
			: '';
		this.setState({
			fromDate: date.toDate(),
			fromDateError: error,
			toDateError: '',
		});
	};

	setTo = (date: moment.Moment) => {
		const error = date.isBefore(this.state.fromDate)
			? 'To Date is before From Date'
			: '';
		this.setState({
			toDate: date.toDate(),
			toDateError: error,
			fromDateError: '',
		});
	};
}
