import { Button } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect } from 'react';
import ClampLines from 'react-clamp-lines';
import { FilterOptions, GetPagedActivities } from '../../actions/activity_actions';
import { navRoutes, getNavRouteByKey } from '../nav/Routes';
import { themePalette } from '../../utilities/branding';
import { Activity } from '../../reducers/activity_reducer';
import { Loaded } from '../../utilities/utilities';
import { Widget } from './base_widget';
import ActivitiesSvg from '../svgs/icons/activities';
import { Strings } from '../../assets/common/strings';
import { connect } from '@optum-uhone-hmkts/rise';
import { nav } from '../..';
import LoadingList from '../utility/loading_list';
import { AppState } from '../../reducers';
import { getTodaysLatestActivities } from '../../selectors/activity_selectors';
import { Dispatch } from '@reduxjs/toolkit';
import { EmptyMessage } from '../utility/empty_message';
import { SimpleListItem } from '../utility/simple_list_item';
import { getActivityIcon } from '../../containers/activity/list/activity_item';
import { useNavigateTo } from '../../utilities/hooks';

interface StateProps {
	activities: Loaded<Activity>[];
	isLoading: boolean;
	userId: string;
}
interface DispatchProps {
	getPagedActivities: (options: FilterOptions, clearCache?: boolean) => void;
}

type Props = StateProps & DispatchProps;

export const todayOpenActivityParams: FilterOptions = {
	startTime: moment()
		.startOf('day')
		.utc()
		.toDate(),
	endTime: moment()
		.endOf('day')
		.utc()
		.toDate(),
	status: 'open',
	type: 'all',
};

const ActivityWidget: React.FC<Props> = (props) => {

	const { activities, isLoading, getPagedActivities } = props;

	useEffect(() => {
		getPagedActivities(todayOpenActivityParams, true);
	}, []);

	const navToActivities = useNavigateTo(navRoutes.activities.path);

	const handleNavigateToActivity = (activityId: string, activityType: string) => {
		const detailRoute = activityType == 'Phone Call'
			? 'phoneDetail'
			: activityType.toLowerCase() + 'Detail';
		nav.push(getNavRouteByKey(detailRoute).path.replace(Strings.Navigation.ActivityId, activityId));
	};

	const renderWidgetBody = (): JSX.Element => {
		if (isLoading && activities.length === 0) {
			return (
				<LoadingList size={2} />
			);
		}
		if (activities.length === 0) {
			return (
				<EmptyMessage text={'Nothing scheduled today'} />
			);
		}
		return (
			<>
				{activities.map((activity: Loaded<Activity>, index: number) => {
					const primaryText = (
						<ClampLines
							text={activity?.data?.title || ''}
							lines={1}
							buttons={false}
						/>
					);
					return (
						<SimpleListItem
							key={`activity-widget-item-${index}`}
							icon={getActivityIcon(activity.data)}
							title={primaryText}
							subtitle={moment(activity.data.time).format('h:mm A')}
							onClick={() => handleNavigateToActivity(activity.data.id, activity.data.type)}
							hideArrow
						/>
					);
				})}
			</>
		);
	}

	return (
		<Widget
			title={Strings.WidgetTitles.Activities}
			avatar={<ActivitiesSvg width={30} height={30} fill={themePalette.menuSvgActivities} />}
			actionButtons={[
				<Button onClick={navToActivities}>
					View All
				</Button>,
			]}
			onClick={navToActivities}
		>
			{renderWidgetBody()}
		</Widget>
	);
}

const mapStateToProps = (state: AppState): StateProps => ({
	activities: getTodaysLatestActivities(state),
	isLoading: state.activity.isLoading,
	userId: state.user.impersonatingId
		? state.user.impersonatingId
		: state.user.id,
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	getPagedActivities: (options: FilterOptions, clearCache: boolean) =>
		dispatch(
			GetPagedActivities.started({
				filters: options,
				pageNum: 0,
				pageSize: 100,
				skipPageIncrease: true,
				clearCache,
			})
		),
});
export const ActivityWidgetContainer = connect(mapStateToProps, mapDispatchToProps, true)(ActivityWidget);
