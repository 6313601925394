import {
	Button,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Switch,
	TextField,
} from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import React from 'react';
import { Strings } from '../../assets/common/strings';
import { ToggleCard } from '../higher_order_component/wrap_in_card';
import { AgentEnrollmentSettings } from '../../reducers/reshop_reducer';
import { themePalette } from '../../utilities/branding';
import { useDispatch } from 'react-redux';
import { updateReshopSettings } from '../../actions/reshop_actions';
import { ExpirationDateDialog } from '../../containers/dialogs/expiration_date_dialog';

interface ReshopSettingsProps {
	agentId: string;
	settings: AgentEnrollmentSettings;
}

const ReshopSettings: React.FC<ReshopSettingsProps> = ({ agentId, settings }) => {
	const dispatch = useDispatch();
	const [isEnrolled, setIsEnrolled] = React.useState(settings.isEnrolled);
	const [shouldExpire, setShouldExpire] = React.useState(settings.hasExpiration);
	const [daysUntilExpire, setDaysUntilExpire] = React.useState(settings.expirationDays);
	const [open, setOpen] = React.useState(false);

	const handleConfirmation = () => {
		const reshopSettings: AgentEnrollmentSettings = {
			agentId: agentId,
			isEnrolled: isEnrolled,
			hasExpiration: isEnrolled ? shouldExpire : false,
			expirationDays: daysUntilExpire,
		};
		dispatch(updateReshopSettings.started(reshopSettings));
	};

	const revertSettings = () => {
		setIsEnrolled(settings.isEnrolled);
		setShouldExpire(settings.hasExpiration);
		setDaysUntilExpire(settings.expirationDays);
	};

	const enableCancel = () => {
		if (
			isEnrolled !== settings.isEnrolled ||
			shouldExpire !== settings.hasExpiration ||
			daysUntilExpire !== settings.expirationDays
		)
			return true;
		return false;
	};

	const hasExpirationError = () => isNaN(daysUntilExpire) || daysUntilExpire < 0;

	const enableSave = () => {
		if (
			(isEnrolled !== settings.isEnrolled ||
				shouldExpire !== settings.hasExpiration ||
				daysUntilExpire !== settings.expirationDays) &&
			!hasExpirationError()
		)
			return true;
		return false;
	};

	const handleError = () => {
		if (hasExpirationError()) {
			return 'Enter valid number of days';
		}
	};

	const handleDate = () => {
		let date = new Date();
		date.setDate(date.getDate() - daysUntilExpire);
		return date.toDateString();
	};

	return (
		<>
			<ToggleCard title="Settings">
				<List style={{ padding: 0 }}>
					<ListItem divider>
						<ListItemIcon>
							<InputIcon />
						</ListItemIcon>
						<ListItemText primary="Opt-In" secondary="Opt-In for re-enrollment" />
						<ListItemSecondaryAction>
							<Switch
								color="secondary"
								checked={isEnrolled}
								onChange={(_, checked) => setIsEnrolled(checked)}
							/>
						</ListItemSecondaryAction>
					</ListItem>
					<ListItem>
						<ListItemIcon>
							<EventBusyIcon />
						</ListItemIcon>
						<ListItemText
							primary="Auto-Accept Recommendations"
							secondary="Enable automated email delivery after set expiration period"
						/>
						<ListItemSecondaryAction>
							<Switch
								color="secondary"
								disabled={!isEnrolled}
								checked={shouldExpire}
								onChange={(_, checked) => setShouldExpire(checked)}
							/>
						</ListItemSecondaryAction>
					</ListItem>
					<ListItem divider>
						<ListItemIcon />
						<ListItemText
							primary=""
							secondary={`Allow re-enrollment emails to be sent after ${
								hasExpirationError() ? '-' : daysUntilExpire
							} days`}
						/>
						<ListItemSecondaryAction>
							<TextField
								style={{ marginBottom: 6 }}
								error={hasExpirationError()}
								helperText={handleError()}
								id="days-expired"
								type="number"
								value={daysUntilExpire}
								disabled={!isEnrolled || !shouldExpire}
								onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
									setDaysUntilExpire(parseInt(e.target.value));
								}}
							/>
						</ListItemSecondaryAction>
					</ListItem>
					<ListItem>
						<Grid
							container
							justify="flex-start"
							direction="row-reverse"
							alignContent="flex-end"
							spacing={1}>
							<Grid item>
								<Button
									color="secondary"
									size="small"
									variant="contained"
									disabled={!enableSave()}
									onClick={(e: React.MouseEvent<HTMLButtonElement>): void => {
										if (shouldExpire) setOpen(true);
										else handleConfirmation();
									}}
									style={{
										color: themePalette.negative_text,
									}}>
									{Strings.ButtonText.Save}
								</Button>
							</Grid>
							<Grid item>
								<Button
									color="secondary"
									size="small"
									disabled={!enableCancel()}
									onClick={(e: React.MouseEvent<HTMLButtonElement>): void => revertSettings()}>
									{Strings.ButtonText.Cancel}
								</Button>
							</Grid>
						</Grid>
					</ListItem>
				</List>
				<ExpirationDateDialog
					isOpen={open}
					handleClose={() => setOpen(false)}
					handleSubmit={() => handleConfirmation()}
					expirationDate={handleDate()}
				/>
			</ToggleCard>
		</>
	);
};

export { ReshopSettings };
