import { GetPerformanceDownlineAgent } from './../actions/performance_actions';
import { all, call, put, race, take } from 'redux-saga/effects';
import moment from 'moment';
import {
	GetPerformanceActivity,
	GetPerformanceLeads,
	GetPerformanceProduction,
	GetPerformanceQuality,
	PerformanceParams,
	PerformanceActionCreator,
    GetAllPerformanceData,
    SetPerformanceDownlineAgent,
} from '../actions/performance_actions';
import { getAgentProfileCall } from '../sagas/agent_search_sagas';
import { Action } from 'typescript-fsa';
import { http } from '../utilities';
import { IChart } from '../reducers/performance_reducer';
import { takeLatestForActionType, makeHandleError } from '../utilities/saga_util';

const API = {
	ACTIVITY: 'SalesPerformance/GetActivityGraphs',
	LEADS: 'SalesPerformance/GetLeadGraphs',
	PRODUCTION: 'SalesPerformance/GetProductionGraphs',
	QUALITY: 'SalesPerformance/GetQualityGraphs',
};
const DOWNLINE_API = 'ByDownline';

function* handleGetAllPerformanceData(action: Action<PerformanceParams>) {
	yield all([
		put(GetPerformanceActivity.started(action.payload)),
		put(GetPerformanceLeads.started(action.payload)),
		put(GetPerformanceProduction.started(action.payload)),
		put(GetPerformanceQuality.started(action.payload)),
	]);
	yield all([
        race({ done: take(GetPerformanceActivity.done.type), failed: take(GetPerformanceActivity.failed.type) }),
		race({ done: take(GetPerformanceLeads.done.type), failed: take(GetPerformanceLeads.failed.type) }),
		race({ done: take(GetPerformanceProduction.done.type), failed: take(GetPerformanceProduction.failed.type) }),
		race({ done: take(GetPerformanceQuality.done.type), failed: take(GetPerformanceQuality.failed.type) }),
    ]);
	yield put(GetAllPerformanceData.done({ params: action.payload }));
}

const getPerformanceSaga = (
	actionCreator: PerformanceActionCreator,
	api: string
) =>
	function* (action: Action<PerformanceParams>) {
		try {
			const response = yield call(getPerformanceData(api), action.payload);
			if (response.ok) {
				const performanceCharts: IChart[] = yield response.json();
				if (!performanceCharts) {
					return;
				}
				yield put(
					actionCreator.done({
						params: action.payload,
						result: performanceCharts,
					})
				);
			} else {
				yield handleError(actionCreator, action, {
					errorCode: response.status,
				});
			}
		} catch (error) {
			yield handleError(actionCreator, action, error);
		}
	};
const getPerformanceData = (api: string) => (
	params: PerformanceParams
): Promise<any> => {
	// Convert to UTC here
	const startDate = moment.utc([params.startDate.getFullYear(), params.startDate.getMonth(), params.startDate.getDate()])
		.startOf('day')
		.subtract(params.utcOffset, 'minutes');
	const endDate = moment.utc([params.endDate.getFullYear(), params.endDate.getMonth(), params.endDate.getDate()])
		.endOf('day')
		.subtract(params.utcOffset, 'minutes');
	const urlParams = `
		?startDate=${startDate.format()}
		&endDate=${endDate.format()}
		&LOB=${params.lineOfBusiness}
		&utcOffset=${params.utcOffset}
		${params.agentCode ? `&agentCode=${params.agentCode}` : ''}
		${
		params.dateRangeOverride
			? `&dateRangeOverride=${params.dateRangeOverride}`
			: ''
		}
    `;
	return http(`${api}${params.agentCode ? DOWNLINE_API : ''}${urlParams}`);
};

function* getDownlineAgent(action: Action<string>) {
	try {
		const response = yield call(getAgentProfileCall, action.payload);
		if (response.ok) {
			const data = yield response.json();
			yield put(SetPerformanceDownlineAgent({
				agent: data
			}));
		}
	} catch (error) {
		console.error('Unable to get performance downline agent');
	}
}

// UTILITY
const handleError = makeHandleError('Error getting performance data');

export function* performanceSagas() {
	yield all([
		takeLatestForActionType(
			GetAllPerformanceData.started,
			handleGetAllPerformanceData
		),
		takeLatestForActionType(
			GetPerformanceLeads.started,
			getPerformanceSaga(GetPerformanceLeads, API.LEADS)
		),
		takeLatestForActionType(
			GetPerformanceProduction.started,
			getPerformanceSaga(GetPerformanceProduction, API.PRODUCTION)
		),
		takeLatestForActionType(
			GetPerformanceQuality.started,
			getPerformanceSaga(GetPerformanceQuality, API.QUALITY)
		),
		takeLatestForActionType(
			GetPerformanceActivity.started,
			getPerformanceSaga(GetPerformanceActivity, API.ACTIVITY)
        ),
        takeLatestForActionType(
            GetPerformanceDownlineAgent,
            getDownlineAgent
        )
	]);
}