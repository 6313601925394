import { isType } from 'typescript-fsa';
import { GetTools } from '../actions/tools_actions';
import { ClearCaches } from '../actions/authentication_actions';
import { Logout } from '../actions/authentication_actions';

export interface Tool {
    id: string;
    name: string;
    description: string;
    learnMore: string;
    url: string;
    isSSO: boolean;
    isWidgetTool: boolean;
    isConnecture?: boolean;
    year?: string;
}

export interface ToolsState {
    tools: Tool[];
    loading: boolean;
}

const initialState: ToolsState = {
    tools: [],
    loading: false,
};

export const toolsReducer = (state = initialState, action: any) => {
    if (isType(action, GetTools.done)) {
        return {
            ...state,
            tools: action.payload.result,
            loading: false,
        };
    }
    if (isType(action, GetTools.started)) {
        return {
            ...state,
            loading: true,
        }
    }
    if (isType(action, Logout.started)) {
		return {
			...initialState,
		};
	}
	if (isType(action, ClearCaches)) {
		return {
			...initialState,
		};
	}
    return state;
};
