import { SnackbarState } from '../reducers/snackbar_reducer';
import {
	QueueSnackbar,
	NextSnackbar,
	ClearSnackbar,
	DequeueSnackbar,
} from '../actions/snackbar_actions';
import { put, all, delay } from 'redux-saga/effects';
import { takeEveryForActionType, selectFromImmutable } from '../utilities/saga_util';
import { getSnackbarState } from '../selectors/snackbar_selectors';


function* queueSnackbar() {
	const state: SnackbarState = yield selectFromImmutable<SnackbarState>(getSnackbarState);

	if (!state.open) {
		yield delay(50);
		yield put(DequeueSnackbar());
	}
}

function* nextSnackbar() {
	const state: SnackbarState = yield selectFromImmutable<SnackbarState>(getSnackbarState);

	if (state.queue.length == 0) {
		yield put(ClearSnackbar());
	} else {
		yield put(DequeueSnackbar());
	}
}

export function* snackbarSagas() {
	yield all([
		takeEveryForActionType(
			QueueSnackbar,
			queueSnackbar
		),
		takeEveryForActionType(
			NextSnackbar,
			nextSnackbar
		),
	]);
}
