import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { ProductivityParams, GetYtdAVReport } from '../../actions/productivity_actions';
import ChartStackedBar from '../utility/chart_stacked_bar';
import YtdGraphHeader from '../teamview/ytd_graph_header';
import { ReportCard } from '../utility/report_card';

interface Props {
    params: ProductivityParams;
    showSLStats: boolean;
    hidden?: boolean;
}

export const YtdAvReports: React.FC<Props> = props => {
    const dispatch = useDispatch();

    const ytdGraphs =  useSelector((state: any) => state.App.productivity.yTDAVReport, shallowEqual);

    useEffect(() => {
        dispatch(GetYtdAVReport.started(props.params))
    }, [props.params]);

    return (
        <div hidden={props.hidden}>
            {ytdGraphs?.isLoading
            ? (
                <ReportCard
                    color="secondary"
                    titleLeft="YTD AV (000s)"
                    isLoading
                />)
            : (
                ytdGraphs?.graphs.map(graph => (
                    <ReportCard
                        key={graph.title}
                        titleLeft={`${graph.title} (000s)`}
                        color="secondary"
                    >
                        {props.showSLStats
                        ? (
                            <YtdGraphHeader
                                stats={graph.stats}
                            />)
                        : (
                            <div style={{marginBottom: 20}} />
                        )}
                        <div>
                            <ChartStackedBar
                                data={graph.data}
                            />
                        </div>
                    </ReportCard>)))}
        </div>
    );
}
