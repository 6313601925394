import { AppState } from '../reducers/index';
import { createSelector } from 'reselect';
import { Loaded } from '../utilities/utilities';
import { Policy } from '../reducers/policy_reducer';
import { getPage, getId, PageType } from './base_selectors';

/**
 * Policy selectors
 */
const getPolicies = (state: AppState) => state.policy.policies || [];
export const getPagePolicies = createSelector(
	[getPage, getId, getPolicies],
	(page: PageType, id: string, policies: Loaded<Policy>[]) => {
		switch (page) {
			case 'HOUSEHOLD_PAGE': {
				return policies.filter(
					policy => policy.data.primaryInsured && policy.householdId == id
				);
			}
			case 'EMPLOYER_PAGE': {
				return policies.filter(policy => policy.employerId == id);
			}
			default:
				return policies;
		}
	}
);
