import {
	CreateNotification,
	DeleteNotification,
	GetPersistentNotification,
	GetNewNotification,
	MarkNotificationsAsRead,
	GetNotificationList,
	EditNotification,
	GetNotificationTargetCount,
	GetLoginNotification,
	MarkNotificationsInput
} from '../actions/notification_actions';
import { call, put, all, throttle } from 'redux-saga/effects';
import {
	GetAllNotifications,
	GetNotification,
	PagingOptions
} from '../actions/notification_actions';
import { submitLog } from '../utilities/logging_util';
import { isType, Action } from 'typescript-fsa';
import { Action as TypescriptAction } from 'typescript-fsa';
import http, { HttpOptions } from '../utilities/http';
import { Strings as S } from '../assets/common/strings';
import { Notification } from '../containers/notifications_page/notifications_page';
import { getAppState } from '..';
import { getReferrerUrl } from '../utilities/brokerage_utils';
import { takeLatestForActionType, createErrorMessages } from '../utilities/saga_util';
import { NotificationInput } from '../reducers/notification_reducer';

function* handleFetchLoginNotifications(action: Action<{}>) {
	try {
		const referralUrl: string = getReferrerUrl();
		const response = yield call(http, `notification/login?referrerUrl=${referralUrl}`);
		if (response.ok) {
			const notification = yield response.json();
			yield put(
				GetLoginNotification.done({ params: {}, result: notification })
			);
		}
		else {
			yield getLoginNotificationError(response.status);
		}
	}
	catch (error) {
		yield getLoginNotificationError(error);
	}

}

function* getLoginNotificationError(error: Error) {
	let errorText: string = S.Notification.FailedToGet;;
	yield put(GetLoginNotification.failed({ params: {}, error }));

	createErrorMessages(errorText, error);
}

function* getPersistentNotificationSaga(action: Action<string>) {
	if (isType(action, GetPersistentNotification.started)) {
		try {
			const response = yield call(fetchPersistentNotifications, action.payload)
			if (response.ok) {

				const notification: any = yield response.json();
				yield put(
					GetPersistentNotification.done({ params: action.payload, result: notification })
				);
			}
			else {
				yield getPersistentNotificationError(response.status, action.payload);
			}
		}
		catch (error) {
			yield getPersistentNotificationError(error, action.payload);
		}
	}
}

function fetchPersistentNotifications(userID: string): Promise<any> {
	const referrerUrl = getReferrerUrl();
	return http(`notification/user/${userID}/persistent/${referrerUrl}`);
}

function* getPersistentNotificationError(error: any, params: string) {
	let errorText: string = S.Notification.FailedToGet;;
	yield put(GetPersistentNotification.failed({ params: params, error: error }));

	createErrorMessages(errorText, error);
}

function* getNewNotificationSaga(action: Action<string>) {
	try {
		const response = yield call(fetchNewNotifications, action.payload)
		if (response.ok) {
			const notifications: Notification[] = yield response.json();
			yield put(GetNewNotification.done({ params: action.payload, result: notifications }));
		}
		else {
			yield getNewNotificationError(response.status, action.payload);
		}
	}
	catch (error) {
		yield getNewNotificationError(error, action.payload);
	}
}

function fetchNewNotifications(userID: string): Promise<any> {
	const referrerUrl = getReferrerUrl();
	return http(`notification/user/${userID}/new/${referrerUrl}`);
}

function* getNewNotificationError(error: any, params: string) {
	let errorText: string = S.Notification.FailedToGet;;
	yield put(GetNewNotification.failed({ params: params, error: error }));

	createErrorMessages(errorText, error);
}

function* getAllNotificationSaga(action: TypescriptAction<string>) {
	try {
		const state = yield call(getAppState);
		if (!state.notifications.persistentLoading) {
			yield put(GetPersistentNotification.started(action.payload));
		}
		if (!state.notifications.newLoading) {
			yield put(GetNewNotification.started(action.payload));
		}
	} catch (error) {
		yield put(GetAllNotifications.failed({ params: action.payload, error }))
		submitLog(S.Log.Error, "Failed to retrieve all notifications");
	}
}

function* createNotificationSaga(action: Action<NotificationInput>) {
	try {
		const response = yield call(createNotificationClient, action.payload);
		if (response.ok) {

			yield put(
				CreateNotification.done({ params: action.payload, result: {} })
			);
		}
		else {
			yield createNotificationError(response.status, action.payload);
		}
	}
	catch (error) {
		yield createNotificationError(error, action.payload);
	}
}

function createNotificationClient(notification: NotificationInput): Promise<any> {
	const options: HttpOptions = {
		method: 'POST',
		body: JSON.stringify(notification),
	};

	return http('notification', options);
}

function* createNotificationError(error: any, params: NotificationInput) {
	let errorText: string = S.Notification.FailedToCreate;
	yield put(CreateNotification.failed({ params: params, error: error }));

	createErrorMessages(errorText, error);
}

function* markNotificationsAsReadSaga(action: Action<MarkNotificationsInput>) {
	try {
		const response = yield call(markNotificationsAsReadClient, action.payload.notificationIds, action.payload.userId);
		if (response.ok) {

			yield put(
				MarkNotificationsAsRead.done({ params: action.payload, result: {} })
			);

			yield put(GetAllNotifications.started(action.payload.userId));
		}
		else {
			yield markNotificationsAsReadError(response.status, action.payload);
		}
	}
	catch (error) {
		yield markNotificationsAsReadError(error, action.payload);
	}
}

function markNotificationsAsReadClient(notificationIds: string[], userId: string): Promise<any> {
	const options: HttpOptions = {
		method: 'POST',
		body: JSON.stringify(notificationIds)
	};

	return http(`notification/user/${userId}/read`, options);
}

function* markNotificationsAsReadError(error: any, params: any) {
	let errorText: string = S.Notification.FailedToGet;;
	yield put(MarkNotificationsAsRead.failed({ params: params, error: error }));

	createErrorMessages(errorText, error);
}

function* deleteNotificationSaga(action: Action<string>) {
	try {
		const response = yield call(deleteNotificationClient, action.payload)
		if (response.ok) {

			yield put(
				DeleteNotification.done({ params: action.payload, result: true })
			);
		}
		else {
			yield deleteNotificationError(response.status, action.payload);
		}
	}
	catch (error) {
		yield deleteNotificationError(error, action.payload);
	}
}

function deleteNotificationClient(notificationId: string) {
	const options: HttpOptions = {
		method: 'DELETE'
	};

	return http('notification/' + notificationId, options);
}

function* deleteNotificationError(error: any, params: string) {
	let errorText: string = S.Notification.FailedToDelete;;
	yield put(DeleteNotification.failed);

	createErrorMessages(errorText, error);
}

function* getNotificationSaga(action: Action<string>) {
	try {
		const response = yield call(getNotificationClient, action.payload)
		if (response.ok) {

			const notification: any = yield response.json();
			yield put(
				GetNotification.done({ params: action.payload, result: notification })
			);
		}
		else {
			yield getNotificationError(response.status, action.payload);
		}
	}
	catch (error) {
		yield getNotificationError(error, action.payload);
	}
}

function* getNotificationError(error: any, params: string) {
	let errorText: string = S.Notification.FailedToGet;;
	yield put(GetNotification.failed);

	createErrorMessages(errorText, error);
}


function getNotificationClient(notificationId: string) {
	return http('notification/' + notificationId);
}

function* getNotificationListSaga(action: Action<PagingOptions>) {
	try {
		const response = yield call(getNotificationListClient, action.payload);
		if (response.ok) {

			const notification: Notification[] = yield response.json();
			yield put(
				GetNotificationList.done({ params: action.payload, result: notification })
			);
		}
		else {
			yield getNotificationListError(response.status, action.payload);
		}
	}
	catch (error) {
		yield getNotificationListError(error, action.payload);
	}
}

function getNotificationListClient(pagingOptions: PagingOptions) {
	const referralUrl: string = getReferrerUrl();
	return http(`notification/list?referrerUrl=${referralUrl}&pageSize=${pagingOptions.pageSize}&page=${pagingOptions.pageNum}`);
}

function* getNotificationListError(error: any, PagingOptions: PagingOptions) {
	let errorText: string = S.Notification.FailedToGet;
	yield put(GetNotificationList.failed({ params: PagingOptions, error: error }));

	createErrorMessages(errorText, error);

}

function* editNotificationSaga(action: Action<Notification>) {
	try {
		const response = yield call(editNotificationClient, action.payload);
		if (response.ok) {

			yield put(
				EditNotification.done({ params: action.payload, result: {} })
			);
		}
		else {
			yield editNotificationError(response.status, action.payload);
		}
	}
	catch (error) {
		yield editNotificationError(error, action.payload);
	}
}

function editNotificationClient(notification: Notification): Promise<any> {
	const options: HttpOptions = {
		method: 'POST',
		body: JSON.stringify(notification),
	};

	return http('notification/persistent', options);
}

function* editNotificationError(error: any, params: Notification) {
	let errorText: string = S.Notification.FailedToCreate;
	yield put(EditNotification.failed({ params: params, error: error }));

	createErrorMessages(errorText, error);

}

function* getNotificationTargetCountSaga(action: Action<string>) {
	try {
		const response = yield call(getNotificationTargetCountClient, action.payload)
		if (response.ok) {

			const numberTargeted: number = yield response.json();
			yield put(
				GetNotificationTargetCount.done({ params: action.payload, result: numberTargeted || 0 })
			);
		}
		else {
			yield getNotificationTargetCountError(response.status, action.payload);
		}
	}
	catch (error) {
		yield getNotificationTargetCountError(error, action.payload);
	}
}

function getNotificationTargetCountClient(notificationId: string): Promise<any> {
	return http(`notification/${notificationId}/count`);
}

function* getNotificationTargetCountError(error: any, params: string) {
	let errorText: string = S.Notification.FailedToGet;;
	yield put(GetNotificationTargetCount.failed({ params: params, error: error }));

	createErrorMessages(errorText, error);
}

export function* notificationSagas() {
	yield all([
		takeLatestForActionType(
			GetLoginNotification.started,
			handleFetchLoginNotifications
		),
		takeLatestForActionType(
			GetPersistentNotification.started,
			getPersistentNotificationSaga
		),
		takeLatestForActionType(
			GetNewNotification.started,
			getNewNotificationSaga
		),
		throttle(
            500,
			GetAllNotifications.started,
			getAllNotificationSaga
		),
		takeLatestForActionType(
			CreateNotification.started,
			createNotificationSaga
		),
		takeLatestForActionType(
			MarkNotificationsAsRead.started,
			markNotificationsAsReadSaga
		),
		takeLatestForActionType(
			DeleteNotification.started,
			deleteNotificationSaga
		),
		takeLatestForActionType(
			GetNotification.started,
			getNotificationSaga
		),
		takeLatestForActionType(
			GetNotificationList.started,
			getNotificationListSaga
		),
		takeLatestForActionType(
			EditNotification.started,
			editNotificationSaga
		),
		takeLatestForActionType(
			GetNotificationTargetCount.started,
			getNotificationTargetCountSaga
		),
	]);
}