import { http } from "../utilities";
import { call, put, all, take, race, takeLatest } from 'redux-saga/effects';
import { CheckLeadConnectBalance, CheckCallerId, VerifyClickToCallContacts, StartClickToCallSession, SetupTwilioClient, StartCallTimer, EndCallTimer, IncrementCallTimer, GetClickToCallSession, EndClickToCallSession, GetClickToCallSessionHousehold, StartClickToCallPhoneCall, IncrementClickToCallCounter, C2CSelectedContact, VerifyCallerId, TwilioOnConnect, TwilioOnDisconnect, TwilioOnCancel, TwilioOnError, TwilioOnReady, TwilioOnIncoming, EndClickToCallPhoneCall, ClickToCallPhoneCallAccepted, TwilioOnAccept, StoreCallSid } from '../actions/click_to_call_actions';
import { Action } from 'typescript-fsa';
import { selectFromImmutable } from '../utilities/saga_util';
import { ContactVerificationRequest, SessionHousehold, ClickToCallSession } from '../reducers/click_to_call_reducer';
import { Strings } from '../assets/common/strings';
import { HttpOptions } from '../utilities/http';
import { QueueSnackbar } from '../actions/snackbar_actions';
import { getSnackbarErrorProps, getSnackbarSuccessProps } from '../utilities/snackbar_util';
import { isBrokerage } from '../utilities/brokerage_utils';
import { delay } from 'redux-saga/effects';
import { StartClickToCallSummaryDialog } from '../components/click_to_call/session_summary_dialog';
import { QueueDialog } from '../actions/dialogs_actions';
import { makeDialogInstance } from '../containers/dialogs';
import { getSessionId, getCallerId, getCallerIdVerified, getInCall } from '../selectors/click_to_call_selectors';
import { getUserId, selectIsImpersonating } from '../selectors/user_selectors';
import { P } from '../utilities/auth/permissions';
import { jwt_auth } from '../utilities/auth';
import { isMobileDevice } from '../utilities/is_mobile';
const Twilio = require('twilio-client');

function* checkLeadConnectBalanceSaga(action: Action<undefined>) {
    const snackBarErrorProps = getSnackbarErrorProps('Failed to check LeadConnect funds', 5000);
    try {
        const response = yield call(checkLeadConnectBalanceClient);

        if (response.ok) {
            const data: boolean = yield response.json();
            yield put(CheckLeadConnectBalance.done({ params: action.payload, result: data }));
        } else {
            yield put(CheckLeadConnectBalance.failed({ params: action.payload, error: response.status }));
            yield put(QueueSnackbar(snackBarErrorProps));
        }
    } catch (error) {
        yield put(CheckLeadConnectBalance.failed({ params: action.payload, error }));
        yield put(QueueSnackbar(snackBarErrorProps));
    }
}

function checkLeadConnectBalanceClient(): Promise<any> {
    return http('/ClickToCall/CheckBalance');
}

function* checkCallerIdSaga(action: Action<undefined>) {
    const snackBarErrorProps = getSnackbarErrorProps('Failed to check Caller ID', 5000);
    try {
        const callerIdVerified = yield selectFromImmutable(getCallerIdVerified);
        const response = yield call(checkCallerIdClient);

        if (response.ok) {
            const data: boolean = yield response.json();
            yield put(CheckCallerId.done({ params: action.payload, result: data }));
            if (data && !callerIdVerified) {
                yield put(QueueSnackbar(getSnackbarSuccessProps(' Verified Caller Id')));
            }
        } else {
            yield put(CheckCallerId.failed({ params: action.payload, error: response.status }));
            yield put(QueueSnackbar(snackBarErrorProps));
        }
    } catch (error) {
        yield put(CheckCallerId.failed({ params: action.payload, error }));
        yield put(QueueSnackbar(snackBarErrorProps));
    }
}

function checkCallerIdClient(): Promise<any> {
    return http('/ClickToCall/CheckCallerId');
}

function* verifyClickToCallContactsSaga(action: Action<ContactVerificationRequest>) {
    const snackBarErrorProps = getSnackbarErrorProps('Failed to initialize Click to Call', 5000);
    try {
        const response = yield call(verifyClickToCallContactsClient, action.payload);
        if (response.ok) {
            const data = yield response.json();
            yield put(
                VerifyClickToCallContacts.done({
                    params: action.payload,
                    result: data
                })
            );
        } else {
            yield put(
                VerifyClickToCallContacts.failed({
                    params: action.payload,
                    error: response.status
                })
            );
            yield put(QueueSnackbar(snackBarErrorProps));
        }
    } catch (error) {
        yield put(
            VerifyClickToCallContacts.failed({
                params: action.payload,
                error
            })
        );
        yield put(QueueSnackbar(snackBarErrorProps));
    }
}

function* verifyClickToCallCallerId(action: Action<string>) {
    const snackBarErrorProps = getSnackbarErrorProps('Failed to verify caller ID', 5000);
    try {
        const response = yield call(verifyClickToCallCallerIdPost, action.payload);
        if (response.ok) {
            const data = yield response.json();
            if(data == null) {
                yield put(QueueSnackbar(getSnackbarErrorProps('Caller ID is already in use', 5000)));
            }
            yield all([
                put(
                    VerifyCallerId.done({
                        params: action.payload,
                        result: data
                    })
                ),
            ]);
        } else {
            yield put(
                VerifyCallerId.failed({
                    params: action.payload,
                    error: response.status
                })
            );
            yield put(QueueSnackbar(snackBarErrorProps));
        }
    } catch (error) {
        yield put(
            VerifyCallerId.failed({
                params: action.payload,
                error
            })
        );
        yield put(QueueSnackbar(snackBarErrorProps));
    }
}

function verifyClickToCallCallerIdPost(
    number: string
): Promise<any> {
    const options: HttpOptions = {
        method: Strings.Http.Post 
    }
    return http('ClickToCall/CallerId?phoneNumber='+ number + '&reVerify=true', options);
}

function verifyClickToCallContactsClient(
    contacts: ContactVerificationRequest
): Promise<any> {
    const options: HttpOptions = {
        method: Strings.Http.Post,
        body: JSON.stringify(contacts)
    }
    return http('/ClickToCall/VerifyContacts', options);
}

function* startClickToCallSessionSaga(action: Action<SessionHousehold>) {
    const snackBarErrorProps = getSnackbarErrorProps('Failed to start Click To Call Session', 5000);
    try {
        const response = yield call(startClickToCallSessionClient, action.payload);
        if (response.ok) {
            const data = yield response.json();
            yield all([
                put(
                    StartClickToCallSession.done({
                        params: action.payload,
                        result: data
                    })),
                put(SetupTwilioClient.started(undefined)),
            ]);
        } else {
            yield put(
                StartClickToCallSession.failed({
                    params: action.payload,
                    error: response.status
                })
            );
            yield put(QueueSnackbar(snackBarErrorProps));
        }
    } catch (error) {
        yield put(
            StartClickToCallSession.failed({
                params: action.payload,
                error
            })
        );
        yield put(QueueSnackbar(snackBarErrorProps));
    }
}

function startClickToCallSessionClient(
    sessionHousehold: SessionHousehold
): Promise<any> {
    const options: HttpOptions = {
        method: Strings.Http.Post,
        body: JSON.stringify(sessionHousehold)
    }
    return http('/ClickToCall/Session', options);
}

function* setupTwilio() {
    if (!isBrokerage && !isMobileDevice) {
        const response = yield call(http, 'ClickToCall/AccessToken');
        if (response.ok) {
            const data = yield response.json();
            const token: string = data.token || '';
            yield put(SetupTwilioClient.done({ params: undefined, result: token }));
            Twilio.Device.setup(token);
        }
        else {
            yield put(QueueSnackbar(getSnackbarErrorProps('Error getting Twilio token')));
        }
    }
}

const startTimer = (begin, end: string) => 
    function*() {
        yield race({
            task: call(incrementTimer, begin),
            cancel: take(end),
        });
    };

function* incrementTimer(action) {
    while (true) {
        yield put(action());
        yield delay(1000);
    }
}

function* startC2CPhoneCall(action: Action<C2CSelectedContact>) {
    const [sessionId, callerId, userId] = yield all([
        selectFromImmutable(getSessionId),
        selectFromImmutable(getCallerId),
        selectFromImmutable(getUserId),
    ]);
    Twilio.Device.connect({
        SessionId: sessionId,
        To: action.payload.number,
        From: callerId,
        ContactId: action.payload.contactId,
        PhoneId: action.payload.phoneId,
        UserId: userId,
    });
}

function* endC2CPhoneCall() {
    yield Twilio.Device.disconnectAll();
}

function* getClickToCallSessionSaga(action: Action<undefined>) {
    const isImpersonating = yield selectFromImmutable(selectIsImpersonating);
    if (jwt_auth.hasPermission(P.ClickToCall) && !isImpersonating) {
        const response = yield call(http, 'ClickToCall/Session');
        if (response.ok) {
            const data: ClickToCallSession = yield response.json();
            yield put(GetClickToCallSession.done({ params: undefined, result: data }));
            if (data != null) {
                if (data.endedOn != undefined && !data.wasBilled) {
                    yield put(EndClickToCallSession.started(undefined));
                } else {
                    yield put(GetClickToCallSessionHousehold.started(data.id));
                }
            }
        }
        else {
            yield put(QueueSnackbar(getSnackbarErrorProps('Error getting Click To Call Session')));
        }
    }
}

function* getClickToCallSessionHouseholdSaga(action: Action<string>) {
    try {
        const response = yield call(getClickToCallSessionHouseholdClient, action.payload);
        if (response.ok) {
            const data = yield response.json();
            yield put(GetClickToCallSessionHousehold.done({ params: action.payload, result: data }));
        }
        else {
            yield put(QueueSnackbar(getSnackbarErrorProps('Error getting Click To Call Session Households')));
        }
    } catch (error) {
        yield put(QueueSnackbar(getSnackbarErrorProps('Error getting Click To Call Session Households')));
    }
}

function getClickToCallSessionHouseholdClient(
    sessionId: string
): Promise<any> {
    return http('/ClickToCall/Session/Households/' + sessionId);
}

function* endClickToCallSessionSaga(action: Action<undefined>) {
    try {
        yield put(QueueDialog(makeDialogInstance(StartClickToCallSummaryDialog)));
        const sessionId = yield selectFromImmutable(getSessionId);
        const response = yield call(endClickToCallSessionClient, sessionId);
        if (response.ok) {
            const data = yield response.json();
            const inCall = yield selectFromImmutable(getInCall);
            if (!inCall && !isMobileDevice) {
                Twilio.Device.audio.unsetInputDevice();
                Twilio.Device.destroy();
            }
            yield put(EndClickToCallSession.done({ params: action.payload, result: data }));
        } else {
            yield put(EndClickToCallSession.failed({ params: action.payload, error: response.status }));
            yield put(QueueSnackbar(getSnackbarErrorProps('Error ending Click To Call Session')));
        }
    } catch (error) {
        yield put(EndClickToCallSession.failed({ params: action.payload, error }));
        yield put(QueueSnackbar(getSnackbarErrorProps('Error ending Click To Call Session')));
    }
}

function endClickToCallSessionClient(
    sessionId: string
): Promise<any> {
    const options: HttpOptions = {
        method: Strings.Http.Post
    };
    return http('ClickToCall/EndSession/' + sessionId, options)
}

// Twilio Connection object
function* twilioOnConnectSaga(action: Action<any>) {

    // const channel = eventChannel<boolean>(emit => {
    //     const connection = action.payload;
    //     connection.on('accept', () => emit(true));
    //     return () => {};
    // });
    
    // // Wait for connection to accept before starting timer
    // yield take(channel);

    // TODO: Connection event "accept" does not fire
    yield all([
        put(StartCallTimer()),
        put(StoreCallSid(action.payload.parameters.CallSid))
    ]);
}
// Twilio Connection object
function* twilioOnDisconnectSaga(action: Action<any>) {
    yield all([
        put(EndCallTimer()),
        put(IncrementClickToCallCounter()),
    ]);
}

// TODO: Do we need the below??

// Twilio Connection object
function* twilioOnCancelSaga(action: Action<any>) {
    yield 42;
}
// Twilio Device object
function* twilioOnReadySaga(action: Action<any>) {
    if(action.payload.audio) {
        action.payload.audio.setInputDevice('default');

        if(action.payload.audio.isOutputSelectionSupported) {
            action.payload.audio.speakerDevices.set('default');
        }
    }
    yield 42;
}
// Twilio Error object
function* twilioOnErrorSaga(action: Action<any>) {
    // If error code = "Connection declined", end the call
    // if (action.payload.code == 31002) {
    //     yield put(TwilioOnDisconnect(undefined));
    // }
    yield 42;
}
// Twilio Connection object
function* twilioOnIncomingSaga(action: Action<any>) {
    yield 42;
}




export function* clickToCallSagas() {
    yield all([
        takeLatest(
            SetupTwilioClient.started,
            setupTwilio
        ),
        takeLatest(
            CheckLeadConnectBalance.started,
            checkLeadConnectBalanceSaga
        ),
        takeLatest(
            CheckCallerId.started,
            checkCallerIdSaga
        ),
        takeLatest(
            VerifyCallerId.started,
            verifyClickToCallCallerId
        ),
        takeLatest(
            VerifyClickToCallContacts.started,
            verifyClickToCallContactsSaga
        ),
        takeLatest(
            StartClickToCallSession.started,
            startClickToCallSessionSaga
        ),
        takeLatest(
            GetClickToCallSession.started,
            getClickToCallSessionSaga
        ),
        takeLatest(
            GetClickToCallSessionHousehold.started,
            getClickToCallSessionHouseholdSaga
        ),
        takeLatest(
            EndClickToCallSession.started,
            endClickToCallSessionSaga
        ),

        takeLatest(
            StartClickToCallPhoneCall,
            startC2CPhoneCall
        ),
        takeLatest(
            EndClickToCallPhoneCall,
            endC2CPhoneCall
        ),
        takeLatest(
            StartCallTimer,
            startTimer(IncrementCallTimer, EndCallTimer.type)
        ),


        takeLatest(
            TwilioOnConnect,
            twilioOnConnectSaga
        ),
        takeLatest(
            TwilioOnDisconnect,
            twilioOnDisconnectSaga
        ),
        takeLatest(
            TwilioOnCancel,
            twilioOnCancelSaga
        ),
        takeLatest(
            TwilioOnError,
            twilioOnErrorSaga
        ),
        takeLatest(
            TwilioOnReady,
            twilioOnReadySaga
        ),
        takeLatest(
            TwilioOnIncoming,
            twilioOnIncomingSaga
        ),
    ]);
}