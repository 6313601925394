import { Action as ReduxAction } from 'redux';
import { Loaded } from '../utilities/utilities';
import { isType } from 'typescript-fsa';
import { GetHousehold } from '../actions/household_actions';
import BaseDTO from '../utilities/base_dto';
import { GetQuoteActivity, DeleteQuoteActivity } from '../actions/quote_activity_actions';

export interface QuoteActivity extends BaseDTO {
	id: string,
	quoteId: string,
	type: string,
	agentCode: string,
	url: string,
	leadId: string,
	source: string,
	householdId: string,
	createdByName: string,
	updatedByName: string
}

export interface QuoteActivityState {
	isLoading: boolean;
	quoteActivities: Loaded<QuoteActivity>[];
}

export const initialState: QuoteActivityState = {
	isLoading: false,
    quoteActivities: []
}

export function quoteActivityReducer(
	state: QuoteActivityState = initialState,
	action: ReduxAction
): QuoteActivityState {
    if (isType(action, GetHousehold.done)) {
        const quoteActivities = state.quoteActivities.slice();
        if(action.payload.result.quoteActivities) {
            updateStateWithInboundQuoteActivities(
                quoteActivities,
                action.payload.result.quoteActivities,
            );
        }
		
		return {
			...state,
			quoteActivities
		};
	}
	else if(isType(action, GetQuoteActivity.started) || 
			isType(action, DeleteQuoteActivity.started)
		) {
		const quoteActivities = state.quoteActivities.slice();
		var existing = quoteActivities.find(q => q.data.id == action.payload);
		if(existing) {
			existing = {
				...existing,
				loading: true,
			}
		}

        return {
			...state,
			isLoading: true,
			quoteActivities
		};
    } else if(isType(action, GetQuoteActivity.done)) {
        const quoteActivities = state.quoteActivities.slice();
        updateStateWithInboundQuoteActivities(
            quoteActivities,
            [action.payload.result]
        );

        return {
			...state,
			isLoading: false,
			quoteActivities
		};
    } else if(isType(action, DeleteQuoteActivity.done)) {
		const quoteActivities = state.quoteActivities.slice();
		const index = quoteActivities.findIndex(quote => quote.data.id == action.payload.params)

		if(index > -1) {
			quoteActivities.splice(index, 1);
		}

        return {
			...state,
			isLoading: false,
			quoteActivities
		};
    } else {
		return state;
	}
}

function updateStateWithInboundQuoteActivities(
	quoteActivitiesInState: Loaded<QuoteActivity>[],
	inboundQuoteActivities: QuoteActivity[]
) {
	inboundQuoteActivities.forEach(quote => {
		var existingIndex = quoteActivitiesInState.findIndex(q => q.data.id == quote.id);
		if(existingIndex > -1) {
			const existing = quoteActivitiesInState[existingIndex];
			if(!quote.createdByName || !quote.updatedByName) {
				quote.createdByName = existing.data.createdByName;
				quote.updatedByName = existing.data.updatedByName;
			}
			quoteActivitiesInState[existingIndex] = {
				...existing,
				loading: false,
				data: quote
			}
		} else {
			quoteActivitiesInState.push({
				loading: false,
				data: quote,
				errors: [],
				householdId: quote.householdId,
			})
		}
	});
}


