import React from 'react';
import { Grid, CircularProgress, Button } from '@material-ui/core';

interface Props {
    visible: boolean;
    cancelText?: string;
    onCancel?: () => void;
}
export const PreparingExperience: React.FC<Props> = (props: Props) => (
    props.visible 
        ? 
            <Grid container justifyContent="center" alignItems="center" direction="column" spacing={2}>
                <h2>Preparing experience...</h2>
                <CircularProgress color="secondary" />
                <Grid item>
                    <Button
                        variant="text"
                        color="inherit"
                        onClick={props.onCancel}
                    >
                        Cancel Login
                    </Button>
                </Grid>
            </Grid> 
        : null
);

export default PreparingExperience;