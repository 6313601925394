import { Policy } from '../reducers/policy_reducer';
import { call, put, all } from 'redux-saga/effects';
import { isType, Action } from 'typescript-fsa';
import http, { HttpOptions } from '../utilities/http';
import {
	GetAllPolicies,
	GetAllUnlinkedPolicies,
	GetPolicy,
	LinkPolicy,
	UnlinkPolicy,
	UpdatePolicy,
	MatchPolicy,
	UnmatchPolicy,
	LinkingPolicyPayload,
} from '../actions/policy_actions';
import { QueueSnackbar, SnackbarProps } from '../actions/snackbar_actions';
import { navigateToWithoutAddingToHistory } from '../actions/navigation_actions';
import {
	LinkApplication,
	UnlinkApplication,
	IUnlinkedGuide,
} from '../actions/application_actions';
import { takeLatestForActionType } from '../utilities/saga_util';
import { getSnackbarSuccessProps, getSnackbarErrorProps } from '../utilities/snackbar_util';

//////////  Get Policy  //////////
function* getPolicySaga(action: Action<string>) {
	try {
		const response = yield call(getPolicyClient, action.payload);

		if (response.ok) {
			const policy = yield response.json();
			yield put(GetPolicy.done({ params: action.payload, result: policy }));
		} else {
			yield put(
				GetPolicy.failed({ params: action.payload, error: response.status })
			);
		}
	} catch (error) {
		yield put(GetPolicy.failed({ params: action.payload, error }));
	}
}

function getPolicyClient(id: string): Promise<any> {
	return http('policy/' + id);
}

////// Get All Policies /////////
function* getAllPoliciesSaga(action: Action<string>) {
	try {
		const response = yield call(getAllPoliciesCall);
		if (response.ok) {
			const data = yield response.json();
			yield put(GetAllPolicies.done({ params: action.payload, result: data }));
		} else {
			yield put(
				GetAllPolicies.failed({
					params: action.payload,
					error: response.status,
				})
			);
		}
	} catch (error) {
		yield put(GetAllPolicies.failed({ params: action.payload, error }));
	}
}

function getAllPoliciesCall(): Promise<any> {
	return http('policy?scope=Me');
}

////// Get All Unlinked Policies /////////
function* getAllUnlinkedPoliciesSaga(action: Action<IUnlinkedGuide>) {
	try {
		const response = yield call(getAllUnlinkedPoliciesCall, action.payload);

		if (response.ok) {
			const data = yield response.json();
			yield put(
				GetAllUnlinkedPolicies.done({ params: action.payload, result: data })
			);
		} else {
			yield put(
				GetAllUnlinkedPolicies.failed({
					params: action.payload,
					error: response.status,
				})
			);
		}
	} catch (error) {
		yield put(
			GetAllUnlinkedPolicies.failed({ params: action.payload, error })
		);
	}
}

function getAllUnlinkedPoliciesCall(
	unlinkedGuide: IUnlinkedGuide
): Promise<any> {
	return http(
		'policy/unlinked/' +
		unlinkedGuide.id +
		'/' +
		unlinkedGuide.guide +
		'?scope=Me',
		undefined,
		{ index: 0, size: 1000 }
	);
}

/////// Link Policy /////////
function* linkPolicySaga(action: Action<LinkingPolicyPayload>) {
	const snackBarSuccessProps: SnackbarProps = getSnackbarSuccessProps('Policy Linked');

	const snackBarFailureProps: SnackbarProps = getSnackbarErrorProps('Policy Link Failed');

	try {
		const response = yield call(linkPolicyClient, action.payload.policy);

		if (response.ok) {
			const data = yield response.json();
			yield put(
				LinkApplication.done({ params: action.payload, result: data })
			);
			yield put(LinkPolicy.done({ params: action.payload, result: data }));
			yield put(
				navigateToWithoutAddingToHistory(action.payload.route)
			);
			yield put(QueueSnackbar(snackBarSuccessProps));
		} else {
			yield put(
				LinkPolicy.failed({ params: action.payload, error: response.status })
			);
			yield put(QueueSnackbar(snackBarFailureProps));
		}
	} catch (error) {
		yield put(LinkPolicy.failed({ params: action.payload, error }));
		yield put(QueueSnackbar(snackBarFailureProps));
	}
}

function linkPolicyClient(policy: Policy): Promise<any> {
	const options: HttpOptions = {
		method: 'PUT',
		body: JSON.stringify(policy),
	};

	return http('policy/link', options);
}

/////// Unlink Policy /////////
function* unlinkPolicySaga(action: Action<LinkingPolicyPayload>) {
	const snackBarSuccessProps: SnackbarProps = getSnackbarSuccessProps('Policy Unlinked');
	const snackBarFailedProps: SnackbarProps = getSnackbarErrorProps('Policy Unlink Failed');

	try {
		const response = yield call(unlinkPolicyClient, action.payload.policy);

		if (response.ok) {
			const data = yield response.json();
			yield put(
				UnlinkApplication.done({ params: action.payload, result: data })
			);
			yield put(UnlinkPolicy.done({ params: action.payload, result: data }));
			yield put(
				navigateToWithoutAddingToHistory(action.payload.route)
			);
			yield put(QueueSnackbar(snackBarSuccessProps));
		} else {
			yield put(
				UnlinkPolicy.failed({
					params: action.payload,
					error: response.status,
				})
			);
			yield put(QueueSnackbar(snackBarFailedProps));
		}
	} catch (error) {
		yield put(UnlinkPolicy.failed({ params: action.payload, error }));
		yield put(QueueSnackbar(snackBarFailedProps));
	}
}
function unlinkPolicyClient(policy: Policy): Promise<any> {
	const options: HttpOptions = {
		method: 'PUT',
		body: JSON.stringify(policy),
	};

	return http('policy/unlink', options);
}

//////////  UPDATE POLICY  //////////
export function* watchForUpdatePolicy(): any {
	;
}
function* updatePolicySaga(action: Action<Policy>) {
	const snackBarFailedProps: SnackbarProps = getSnackbarErrorProps('Updating Product Status Failed');
	try {
		const response = yield call(updatePolicyClient, action.payload);

		if (response.ok) {
			const data = yield response.json();
			yield put(UpdatePolicy.done({ params: action.payload, result: data }));
		} else {
			yield put(
				UpdatePolicy.failed({
					params: action.payload,
					error: response.status,
				})
			);
			yield put(QueueSnackbar(snackBarFailedProps));
		}
	} catch (error) {
		yield put(UpdatePolicy.failed({ params: action.payload, error }));
		yield put(QueueSnackbar(snackBarFailedProps));
	}
}
function updatePolicyClient(policy: Policy): Promise<any> {
	const options: HttpOptions = {
		method: 'PUT',
		body: JSON.stringify(policy),
	};
	return http('policy/', options);
}

/////// Match Policy /////////
function* matchPolicySaga(action: Action<Policy>) {
	const snackBarSuccessProps: SnackbarProps = getSnackbarSuccessProps('Policy Linked');
	const snackBarFailedProps: SnackbarProps = getSnackbarErrorProps('Policy Link Failed');

	try {
		const response = yield call(matchPolicyClient, action.payload);

		if (response.ok) {
			const data = yield response.json();
			yield put(MatchPolicy.done({ params: action.payload, result: data }));
			yield put(QueueSnackbar(snackBarSuccessProps));
		} else {
			yield put(
				MatchPolicy.failed({ params: action.payload, error: response.status })
			);
			yield put(QueueSnackbar(snackBarFailedProps));
		}
	} catch (error) {
		yield put(MatchPolicy.failed({ params: action.payload, error }));
		yield put(QueueSnackbar(snackBarFailedProps));
	}
}
function matchPolicyClient(policy: Policy): Promise<any> {
	const options: HttpOptions = {
		method: 'PUT',
		body: JSON.stringify(policy),
	};
	return http('policy/', options);
}

//////////  Unmatch Policy  //////////
function* unmatchPolicySaga(action: Action<string>) {
	const snackBarSuccessProps: SnackbarProps = getSnackbarSuccessProps('Policy Unlinked');
	const snackBarFailedProps: SnackbarProps = getSnackbarErrorProps('Policy Unlink Failed');

	if (isType(action, UnmatchPolicy.started)) {
		//type narrowing, will always be true
		try {
			const response = yield call(unmatchPolicyClient, action.payload);

			if (response.ok) {
				const data = yield response.json();
				yield put(UnmatchPolicy.done({ params: action.payload, result: data }));
				yield put(QueueSnackbar(snackBarSuccessProps));
			} else {
				yield put(
					UnmatchPolicy.failed({
						params: action.payload,
						error: response.status,
					})
				);
				yield put(QueueSnackbar(snackBarFailedProps));
			}
		} catch (error) {
			yield put(UnmatchPolicy.failed({ params: action.payload, error }));
			yield put(QueueSnackbar(snackBarFailedProps));
		}
	}
}
function unmatchPolicyClient(id: string): Promise<any> {
	const options: HttpOptions = {
		method: 'PUT',
	};
	return http('policy/unmatch/' + id, options);
}


export function* policySagas() {
	yield all([
		takeLatestForActionType(
			GetPolicy.started,
			getPolicySaga
		),
		takeLatestForActionType(
			GetAllPolicies.started,
			getAllPoliciesSaga
		),
		takeLatestForActionType(
			GetAllUnlinkedPolicies.started,
			getAllUnlinkedPoliciesSaga
		),
		takeLatestForActionType(
			LinkPolicy.started,
			linkPolicySaga
		),
		takeLatestForActionType(
			UnlinkPolicy.started,
			unlinkPolicySaga
		),
		takeLatestForActionType(
			UpdatePolicy.started,
			updatePolicySaga
		),
		takeLatestForActionType(
			MatchPolicy.started,
			matchPolicySaga
		),
		takeLatestForActionType(
			UnmatchPolicy.started,
			unmatchPolicySaga
		),
	]);
}