import React, { useEffect, useState } from 'react';
import {
    Button, Grid, Typography, makeStyles, FormControlLabel, Checkbox, FormGroup
} from '@material-ui/core';
import { Provider } from './';

interface Props {
    provider: Provider;
    handleDeleteProvider: (value: Provider) => void;
    handleTogglePCP: (value: Provider) => void;
    readOnly?: boolean;
}

export const ProviderSelectListItem: React.FC<Props> = ({ provider, provider: { provider: providerData, address }, handleDeleteProvider, handleTogglePCP, readOnly }) => {
    const classes = useStyles();

    const streetAddress = address.street2 === '' ? address.street1 : `${address.street1} ${address.street2}`;
    const providerAddress = `${streetAddress}, ${address.city} ${address.state} ${address.zipcode}`;
    const specialties = providerData.specialties.join(' | ');

    const [pcpCheckbox, setPcpCheckbox] = useState(providerData.isPCP);

    useEffect(() => {
        setPcpCheckbox(providerData.isPCP)
    }, [providerData.isPCP])

    const handleRemove = () => {
        handleDeleteProvider(provider)
    }

    const handleTogglePCPCheckbox = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const updatedProvider = { ...provider, provider : { ...provider.provider, isPCP: checked }};
        handleTogglePCP(updatedProvider);
    }

    return (
        <div className={classes.root}>
            <Grid
                container
                alignItems="center"
            >
                <Grid item xs={12} sm={8}>
                    <Typography variant="button" display="block" className={classes.providerName}>
                        {providerData.name}
                    </Typography>
                    <Typography variant="caption" color="textSecondary" display="block">
                        {specialties}
                    </Typography>
                    <Typography variant="caption" color="textSecondary" display="block">
                        {providerAddress}
                    </Typography>
                </Grid>
                <div/>
                <Grid item xs={12} sm={4} container justify="flex-end" >
                    <div>
                        <Button
                            size="small"
                            color="primary"
                            onClick={handleRemove}
                            disabled={readOnly}
                        >
                            Remove
                        </Button>
                        <FormGroup row>
                            <FormControlLabel
                                classes={{ label: classes.pcpCheck }}
                                control={<Checkbox disabled={readOnly} size="small" checked={pcpCheckbox} onChange={handleTogglePCPCheckbox} name="PCP" />}
                                label="PCP"
                            />
                        </FormGroup>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

const useStyles = makeStyles({
    root: {
        // wordBreak: 'break-all',
        padding: 16,
        borderBottom: '1px solid #dcdedf',
    },
    providerName: {
        fontWeight: 600,
    },
    button: {
        marginRight: 10,
    },
    pcpCheck: {
        fontSize: '.8rem',
    }
  });
