import React, { useState, useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import {
    Button, Dialog, DialogContent, DialogTitle, DialogActions, Grid
} from '@material-ui/core';
import {
    useDispatch, useSelector, useFormActions, useFormFieldActions
} from '@optum-uhone-hmkts/rise';
import { ToggleProviderSearchDialog } from '../../../../actions/dialogs_actions';
import { Provider, ProviderSearch, ProviderDropdown, ProviderSelectedList, defaultSearchValues } from './provider_lookup';
import { Strings } from '../../../../assets/common/strings';

const PROVIDER_FIELD_ID = 'd0e0ee5d-c371-40de-90c8-85c8a5897df0';

export const FactFinderProviderSearchDialog: React.FC<{}> = () => {
    const dispatch = useDispatch();

    const formDispatch = useFormActions();
    const formFieldDispatch = useFormFieldActions();

    const isOpen = useSelector((state: any) => state?.App?.providerSearchDialog.isOpen);
    const isLoading = useSelector((state: any) => state?.FormBuilder?.activeForm?.providerInfo?.isLoading, shallowEqual);
    const initialAddedProviderField = useSelector((state: any) => state?.FormBuilder?.fields[PROVIDER_FIELD_ID], shallowEqual);
    const isFormCompleted = useSelector((state: any) => 
        Boolean(state?.FormBuilder?.activeForm?.activeForm?.completed) && 
        state?.FormBuilder?.activeForm?.formId === state?.FormBuilder?.activeForm?.activeForm?.id
    );

    const initialAddedProviderResponse = initialAddedProviderField?.response;
    let initialAddedProviders = [];
    try {
        initialAddedProviders = initialAddedProviderResponse ? JSON.parse(initialAddedProviderResponse) : [];
    } catch (ex) {
        console.warn('Failed to set initialAddedProviders');
    }

    const [hasSearched, setHasSearched] = useState(false);
    const [searchValues, setSearchValues] = useState(defaultSearchValues);
    const [addedProviders, setAddedProviders] = useState<Provider[]>([]);
    const [hasChanged, setHasChanged] = useState(false);

    useEffect(() => {
        setAddedProviders(initialAddedProviders)
    }, [isOpen])

    const handleProviderSearch = () => {
        formDispatch.providerLookup(searchValues.providerName, searchValues.zipCode);
        setHasSearched(true);
    }

    const handleAddProvider = (provider: Provider) => {
        let newProviderList = addedProviders;
        if (newProviderList.length >= 10) {
            return;
        }
        const matchingProviderIndex = newProviderList.findIndex(item => item.provider.npi === provider.provider.npi);
        if (matchingProviderIndex !== -1) {
            return;
        }
        newProviderList = [...newProviderList, provider];
        setAddedProviders(newProviderList);
        formDispatch.resetProviderSearchResults();
        setSearchValues({ ...defaultSearchValues, zipCode: searchValues.zipCode });
        setHasSearched(false);
        setHasChanged(true);
    }

    const handleEditProvider = (provider: Provider) => {
        const newProviderList = [...addedProviders];
        const matchingProviderIndex = newProviderList.findIndex(item => item.provider.npi === provider.provider.npi);
        newProviderList[matchingProviderIndex] = provider;

        setAddedProviders(newProviderList);
        setHasChanged(true);
    }

    const handleDeleteProvider = (provider: Provider) => {
        const newProviderList = addedProviders.filter(item => item.provider.npi !== provider.provider.npi);
        setAddedProviders(newProviderList);
        setHasChanged(true);
    }

    const closeDialog = () => {
        dispatch(ToggleProviderSearchDialog(false))
    }

    const handleClearSearch = () => {
        formDispatch.resetProviderSearchResults();
        setSearchValues({ ...defaultSearchValues, zipCode: searchValues.zipCode });
        setHasSearched(false);
    }

    const handleClose = () => {
        formDispatch.resetProviderSearchResults();
        formDispatch.resetZipCodeSearchResults();
        setSearchValues(defaultSearchValues);
        setHasSearched(false);
        setHasChanged(false);
        closeDialog();
    }

    const handleSave = () => {
        const providersListResponse = JSON.stringify(addedProviders)
        formFieldDispatch.setValidField(PROVIDER_FIELD_ID, providersListResponse)
        handleClose();
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            fullWidth
        >
            {!isFormCompleted && <DialogContent style={{ marginBottom: 20, overflowY: 'visible' }}>
                <DialogTitle style={{ padding: '0px 0px 8px 0px' }}>
                    {Strings.FactFinderProviderLookup.doctorLookup}
                </DialogTitle>
                <ProviderSearch
                    searchValues={searchValues}
                    addedProviders={addedProviders}
                    updateSearchValues={setSearchValues}
                    handleSearch={handleProviderSearch}
                    disabled={addedProviders.length >= 10}
                />
            </DialogContent>}
            <DialogContent style={{minHeight: 200, overflowY: 'hidden'}}>
                {isFormCompleted && (addedProviders?.length < 1 || !addedProviders) &&
                    <DialogTitle style={{ padding: '0px 0px 8px 0px' }}>
                        {'Cannot Add Doctors to completed form'}
                    </DialogTitle>}
                {hasSearched && (
                    <ProviderDropdown
                        addedProviders={addedProviders}
                        isLoading={isLoading}
                        searchRadius={searchValues.radius}
                        handleClearSearch={handleClearSearch}
                        handleAdd={handleAddProvider}
                    />)}
                {addedProviders.length > 0 && !hasSearched && (
                    <ProviderSelectedList
                        addedProviders={addedProviders}
                        deleteProvider={handleDeleteProvider}
                        togglePCP={handleEditProvider}
                        readOnly={isFormCompleted}
                    />)}
            </DialogContent>
            <DialogActions>
                <Grid item container xs={12} justify="flex-end">
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            style={{color: 'white'}}
                            disabled={!hasChanged}
                            onClick={handleSave}
                        >
                            {Strings.FactFinderProviderLookup.saveDoctors}
                        </Button>
                        <Button
                            color="primary"
                            onClick={handleClose}
                            style={{marginLeft: 10, paddingLeft: 25, paddingRight: 25}}
                        >
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};
