import React from 'react';
import {
	TextField,
	Chip,
	Menu,
	MenuItem,
	Checkbox,
	ListItemText,
	Paper,
} from '@material-ui/core';
import { isDesktop } from '../../utilities/is_mobile';
const bowser = require('bowser');

interface Props {
	label: string;
	fullWidth?: boolean;
	resultSet: string[];
	input: {
		value: string[];
		onChange: (value: string[]) => void;
	};
	onChangeCallback?: (value: string) => void;
	forceAutoComplete?: boolean;
};

interface State {
	currentResultSet: string[];
	searchText: string;
	autocompleteMenuOpen: boolean;
	anchorEl?: HTMLElement;
};

class MultiSelect extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			currentResultSet: props.resultSet.slice(),
			searchText: '',
			autocompleteMenuOpen: false,
		};
	}

	componentDidMount() {
		const inputValue = this.props.input.value;
		const resultSet = this.props.resultSet;
		if (Array.isArray(inputValue) && resultSet.length > 5) {
			inputValue.forEach((val) => {
				if (resultSet.indexOf(val) != -1) {
					resultSet.splice(resultSet.indexOf(val), 1);
				}
			})
		}
		this.setState({ currentResultSet: resultSet });
	}

	componentDidUpdate() {
		if (
			this.props.input.value.length == 0 &&
			this.props.resultSet.length > this.state.currentResultSet.length
		) {
			this.resetResultSet();
		}
	}

	componentWillReceiveProps(nextProps: Props) {
		if (nextProps.forceAutoComplete) {
			const propsResults: string[] = this.removeSelectedFromOptions(
				nextProps.resultSet,
				nextProps.input.value
			);
			const stateResults: string[] = this.removeSelectedFromOptions(
				this.state.currentResultSet,
				nextProps.input.value
			);

			if (propsResults.length !== stateResults.length) {
				this.setState({ currentResultSet: propsResults });
			} else {
				for (let i = 0; i < propsResults.length; i++) {
					if (propsResults[i] !== stateResults[i]) {
						this.setState({ currentResultSet: propsResults });
						break;
					}
				}
			}
		}
	}

	simpleOnChange = (event: any) => {
		this.props.input.onChange(event.target.value);
	};

	resetResultSet = () => {
		this.setState({
			currentResultSet: this.props.resultSet,
		});
	};

	removeSelectedFromOptions = (
		resultSet: string[],
		currentSelected: string[]
	): string[] => {
		const updatedCurrentResultSet: string[] = [];

		resultSet.forEach((currentOption: string) => {
			const inputValue: string[] = {...currentSelected};
			if (Array.isArray(inputValue)) {
				const resultIndex: number = inputValue.indexOf(currentOption);
				if (resultIndex === -1) {
					updatedCurrentResultSet.push(currentOption);
				}
			} else if (currentOption !== inputValue) {
				updatedCurrentResultSet.push(currentOption);
			}
		});
		return updatedCurrentResultSet;
	};

	autocompleteOnResultItemSelected = (item: string) => {
		let updatedCurrentResultSet: string[] = this.props.resultSet.slice();
		updatedCurrentResultSet = this.removeSelectedFromOptions(
			updatedCurrentResultSet,
			this.props.input.value
		);

		const itemIndex: number = updatedCurrentResultSet.indexOf(item);
		if (itemIndex !== -1) {
			updatedCurrentResultSet.splice(itemIndex, 1);
		}

		this.setState({
			autocompleteMenuOpen: false,
			searchText: '',
			currentResultSet: updatedCurrentResultSet,
		});
		const inputValue: string[] = this.props.input.value;
		const updatedSelectedValues = Array.isArray(inputValue)
			? inputValue.slice()
			: [];
		if (!~updatedSelectedValues.indexOf(item)) {
			updatedSelectedValues.push(item);
		}
		this.props.input.onChange(updatedSelectedValues);
	};

	chipOnDelete = (item: string) => {
		const inputValue: string[] = this.props.input.value;
		const updatedSelectedValues = Array.isArray(inputValue)
			? inputValue.slice()
			: [];
		const itemIndex = updatedSelectedValues.indexOf(item);
		updatedSelectedValues.splice(itemIndex, 1);
		this.props.input.onChange(updatedSelectedValues);

		if (updatedSelectedValues.length === 0)
			this.setState({
				currentResultSet: this.props.resultSet,
			});
		else
			this.setState(prevState => {
				const updatedCurrentResultSet = prevState.currentResultSet.slice();
				updatedCurrentResultSet.push(item);
				return {
					currentResultSet: updatedCurrentResultSet,
				};
			});
	};

	checkNumberOfResultItems = () => {
		return this.state.currentResultSet.filter(
			item =>
				item.toLowerCase().indexOf(this.state.searchText.toLowerCase()) != -1
		).length;
	};

	renderSimpleResultItems = () => {
		return this.state.currentResultSet.map((item, index) => {
			const isSelected = this.props.input.value.indexOf(item) != -1;
			return (
				<MenuItem key={item} value={item}>
					<Checkbox
						checked={isSelected}
						color="primary"
						value={`simple_check_${index}`}
					/>
					<ListItemText primary={item} />
				</MenuItem>
			);
		});
	};

	renderSimpleValues = (selected: string[]) => selected.join(', ');

	renderSimpleMultiselect = () => {
		const inputValue: string[] = this.props.input.value;
		return (
			<TextField
				label={this.props.label}
				select
				SelectProps={{ multiple: true, renderValue: this.renderSimpleValues }}
				onChange={this.simpleOnChange}
				fullWidth={this.props.fullWidth}
				value={inputValue || []}
			>
				{this.renderSimpleResultItems()}
			</TextField>
		);
	};

	renderChipValues = () => {
		const inputValue: string[] = this.props.input.value;
		if (Array.isArray(inputValue)) {
			return (
				<div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 10 }}>
					{inputValue.map((value: string) => {
						return (
							<Chip
								key={value}
								label={value}
								style={{ margin: '0 2px' }}
								onDelete={() => this.chipOnDelete(value)}
							/>
						);
					})}
				</div>
			);
		}
	};

	renderAutocompleteResults = () => {
		const resultItems = this.state.currentResultSet
			.filter(
				item =>
					item.toLowerCase().indexOf(this.state.searchText.toLowerCase()) != -1
			)
			.map(item => {
				return (
					<MenuItem
						key={item}
						value={item}
						onClick={() => this.autocompleteOnResultItemSelected(item)}
						autoFocus={false}
					>
						{item}
					</MenuItem>
				);
			});
		return (
			isDesktop() && bowser.name === 'Internet Explorer'
				? (
					this.state.autocompleteMenuOpen && this.state.searchText &&
					<Paper>
						{resultItems}
					</Paper>
				)
				: (
					<Menu
						anchorEl={this.state.anchorEl}
						open={this.state.autocompleteMenuOpen && resultItems.length > 0}
						onClose={() => this.setState({ autocompleteMenuOpen: false })}
						style={{ marginTop: 50 }}
						MenuListProps={{ style: { maxHeight: 300 } }}
						onEnter={() => this.state.anchorEl!.focus()}
						autoFocus={false}
						disableAutoFocus
						disableEnforceFocus
						disableAutoFocusItem
					>
						{resultItems}
					</Menu>
				)
		);
	};

	renderAutocompleteMultiselect = () => {
		return (
			<div>
				<TextField
					InputProps={{
						onFocus: event => this.setState({ anchorEl: event.currentTarget }),
						autoComplete: 'off',
					}}
					label={this.props.label}
					onChange={event => {
						if (this.props.onChangeCallback) {
							this.props.onChangeCallback(event.target.value);
						}
						this.setState({
							searchText: event.target.value,
							autocompleteMenuOpen: true,
						});
					}}
					fullWidth={this.props.fullWidth}
					value={this.state.searchText}
					error={!this.props.forceAutoComplete && this.checkNumberOfResultItems() === 0}
				/>
				{this.renderAutocompleteResults()}
				{this.renderChipValues()}
			</div>
		);
	};

	render() {
		return this.props.resultSet.length <= 5 && !this.props.forceAutoComplete
			? this.renderSimpleMultiselect()
			: this.renderAutocompleteMultiselect();
	}
}

export default MultiSelect;
