export class KeyPressEventUtility {
    public static integerOnly(event: React.KeyboardEvent<HTMLDivElement>): void {
        const specialKeys = ['Backspace', 'Tab', 'End', 'Home'];
        if (specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        if (event.keyCode >= 48 && event.keyCode <= 57) {
            return; 
        }
        if (event.keyCode >= 96 && event.keyCode <= 105) {
            return;
        }
        event.preventDefault();
    }

}