import { Email } from './../reducers/ContactReducer';
import moment, { Moment } from 'moment';
import { Phone, Contact, HouseholdRole } from '../reducers/ContactReducer';
import { isMobileDevice } from './is_mobile';
import { fullName } from '../assets/common/string_builders';
import { getBaseIconStyle } from './styles_util';
import { Strings } from '../assets/common/strings';
import { themePalette } from './branding';
import { openExternalLink } from '.';

export const isPhoneWithinGracePeriod = (phone: Phone): boolean => {
	const gracePeriodEndDate: Moment = moment.utc(
		phone.gracePeriodEndDate || moment.utc().subtract(12, 'years')
	);
	let withinGracePeriod = false;
	if (gracePeriodEndDate) {
		withinGracePeriod = moment.utc().isSameOrBefore(gracePeriodEndDate);
	}

	return withinGracePeriod;
};

export enum PhoneVariant {
	NationalDnc = 'nationalDNC',
	CompanyDnc = 'companyDNC',
	Preferred = 'preferred',
	Other = '',
}

export interface ExtendedPhoneObject extends Phone {
	contactId: string;
	name: string;
}

export interface ExtendedEmailObject extends Email {
	contactId: string;
	name: string;
}

export type PhoneMetaData = {
	phones: ExtendedPhoneObject[];
	doNotContact: boolean;
	doNotSms: boolean;
	preferred: string;
};


export type EmailMetaData = {
	emails: ExtendedEmailObject[];
	doNotContact: boolean;
	preferred: string;
}


export const composeEmailMetaData = (contacts: Contact[]): EmailMetaData => {

	const isDnc = contacts.some(contact => contact.emails.length > 0) && contacts.every(contact => contact.emails.every(email => email.doNotEmail || !!email.unsubscribeDate));

	const meta: EmailMetaData = {
		emails: [],
		doNotContact: isDnc,
		preferred: '',
	};

	contacts.forEach((contact, index) => {
		const contactName = fullName(contact);
		if (contact.status == Strings.Status.Active && contact.emails.length) {
			const contactEmails = contact.emails.map(email => {
				if (contact.householdRole == HouseholdRole.Primary && email.isPreferred)
					meta.preferred = email.emailAddress;

				return {
					...email,
					contactId: contact.id,
					name: contactName,
				} as ExtendedEmailObject;
			});
			meta.emails = meta.emails.concat(contactEmails);
		}
	});
	return meta;
};


export const composePhoneMetaData = (contacts: Contact[] = [], hasActiveBusinessRelationship?: boolean): PhoneMetaData => {

	const isDnc = !contacts.every(contact => (contact.phones || []).every(phone => !phone.isCompanyDnc && !phone.isNationalDnc));
	const isSmsDnc = !contacts.every(contact => (contact.phones || []).every(phone => !phone.isSmsDnc));

	const meta: PhoneMetaData = {
		phones: [],
		doNotContact: isDnc,
		doNotSms: isSmsDnc,
		preferred: '',
	};

	contacts.forEach((contact) => {
		const contactName = fullName(contact);
		if (contact.status == Strings.Status.Active && contact.phones.length) {
			const contactPhones = contact.phones.map(phone => {
				meta.doNotContact = meta.doNotContact
					? phone.isCompanyDnc || (phone.isNationalDnc && !isPhoneWithinGracePeriod(phone) && !hasActiveBusinessRelationship)
					: false;
				meta.doNotSms = meta.doNotSms ? phone.isSmsDnc : false;
				if (contact.householdRole == HouseholdRole.Primary && phone.isPreferred)
					meta.preferred = phone.number;

				return {
					...phone,
					contactId: contact.id,
					name: contactName,
				} as ExtendedPhoneObject;
			});
			meta.phones = meta.phones.concat(contactPhones);
		}
	});
	return meta;
};

export const makeCall = (phoneNumber?: string, desktopCallEnabled: boolean = false): void => {
	if (!phoneNumber) {
		return;
	}
	const number = phoneNumber.replace(/(\(|\)|-| )/g, '');
	const link = `tel:${number}`;
    window.open(link, '_self');
};

export const sendEmail = (emailAddress?: string) => {
	if (!emailAddress) {
		return;
	}
	const link = `mailto:${emailAddress}`;
	openMail(link);
};
export const makeSendEmail = (emailAddress: string) => () => sendEmail(emailAddress);

export const openMail = (mailTo: string) => {
    window.open(mailTo, '_self');
};

export const getPhoneEmailIconStyle = (style?: React.CSSProperties & { disabledColor?: string }, disabled?: boolean) => {
	const {
		color = undefined,
		disabledColor = themePalette.disabled_action_icon,
		...injectedStyle
	} = style || {};

	return {
		...getBaseIconStyle(!disabled ? color : disabledColor),
		...injectedStyle
	}
}


export type IconOptions = {
	style?: React.CSSProperties;
	hidden?: boolean;
	overrideIcon?: string;
	button?: boolean;
};
