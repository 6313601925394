import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { Employer } from '../../reducers/employer_reducer';
import { HouseholdMetaData } from '../../reducers/household_reducer';
import { DateTimeFormat, displayDate } from '../../utilities/date_util';
import moment from 'moment';
import { Strings as S } from '../../assets/common/strings';

interface Props {
	employer?: Employer;
	household?: HouseholdMetaData;
	showDisclaimer?: boolean;
	isAdvancedSearch?: boolean;
}
export const AdminInfo: React.FC<Props> = ({ employer, household, showDisclaimer, isAdvancedSearch }) => {

	const createdOn = employer?.createdOn ?? household?.createdOn;
	const updatedOn = employer?.updatedOn ?? household?.updatedOn;
	const createdByName = employer?.createdByName ?? household?.createdByName;
	const updatedByName = employer?.updatedByName ?? household?.updatedByName;

	const createdInfo = `Created ${formatAdminInfo(createdOn, createdByName)}`;
	const updatedInfo = `Modified ${formatAdminInfo(updatedOn, updatedByName)}`;

	return (
		<Grid container>
			<Grid item xs={12}>
				<Typography variant="caption">
					<Box fontSize={11} marginLeft={2}>{createdInfo}</Box>
					<Box fontSize={11} marginLeft={2}>{updatedInfo}</Box>
					{showDisclaimer && <Box fontSize={11} fontStyle="italic" margin={2} marginRight={isAdvancedSearch ? 8 : 2}>{S.Disclaimer.Dnc}</Box>}
				</Typography>
			</Grid>
		</Grid>
	);
};

const formatAdminInfo = (date?: Date, name?: string) => {
	let info = '';
	if (date) {
		const local = moment.utc(date).local();
		info += `${displayDate(local, DateTimeFormat.Date)} @ ${displayDate(local, DateTimeFormat.Time)}`;
	}
	else {
		info += 'unknown';
	}
	return `${info} by ${name || 'unknown'}`;
};
