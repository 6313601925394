import React from 'react';
import { AppState } from '../../reducers';
import { UpsertAddressForm, FORM_NAME } from './upsert_address_form';
import { getFormValues } from 'redux-form';
import { connect } from '@optum-uhone-hmkts/rise';
import { Address } from '../../reducers/ContactReducer';
import { Strings as S, Strings } from '../../assets/common/strings';
import { UpsertStorefrontAddress } from '../../actions/agent_actions';

interface ComponentProps { }

interface StateProps {
	addresses: Address[];
	formValues: any
}

interface DispatchProps {
	upsertAddress: (addresses: Address[]) => void;
}

type Props = ComponentProps & StateProps & DispatchProps;

const _UpsertStorefrontAddress = (props: Props) => {
	const { addresses } = props;
	const storefrontAddresses = addresses && addresses.filter(address => address.type === Strings.AddressType.Storefront);
	return (
		<UpsertAddressForm
			initialValues={{
				addresses: storefrontAddresses
			}}
			addressType={S.AddressType.Storefront}
			upsertAddress={props.upsertAddress}
			maxAddressCount={5}
		/>
	);
}

function mapStateToProps(
	state
): StateProps {
	
	return {
		addresses: state.agent.storefrontAddresses,
		formValues: getFormValues(FORM_NAME)(state),
	}
}

function mapDispatchToProps(dispatch: any): DispatchProps {
	return {
		upsertAddress: (address: Address[]) => {
			dispatch(UpsertStorefrontAddress.started(address));
		},
	}
}

export const UpsertStorefrontAddressContainer = connect(
	mapStateToProps,
	mapDispatchToProps, true
)(_UpsertStorefrontAddress);