import {actionCreatorFactory} from 'typescript-fsa';
import { Action as ReduxAction } from 'redux';
import { CompanyDncPhone } from '../reducers/dnc_phone_reducer';

const actionCreator = actionCreatorFactory('DNC_PHONE');

// FETCH DNCs
export interface GetDncParams {
	searchText: string;
	pageNum: number;
	pageSize: number;
}
export const GetCompanyDNCPhoneList = actionCreator.async<
	GetDncParams,
	CompanyDncPhone[]
>('GET_COMPANY_DNC_PHONE_LIST');

export function getCompanyDNCPhoneList(
	searchText: string,
	pageNum: number,
	pageSize: number
): ReduxAction {
	return GetCompanyDNCPhoneList.started({
		searchText: searchText,
		pageNum: pageNum,
		pageSize: pageSize,
	});
}

export function getCompanyDNCPhoneListSuccess(
	params: {
		searchText: string;
		pageNum: number;
		pageSize: number;
	},
	dncs: CompanyDncPhone[]
): ReduxAction {
	return GetCompanyDNCPhoneList.done({
		params: params,
		result: dncs,
	});
}

export function getCompanyDNCPhoneListFailure(
	params: {
		searchText: string;
		pageNum: number;
		pageSize: number;
	},
	error: any
): ReduxAction {
	return GetCompanyDNCPhoneList.failed({
		params: params,
		error: error,
	});
}

// ADD PHONE TO COMPANY DNC
export const AddPhoneToCompanyDNC = actionCreator.async<
	string,
	CompanyDncPhone
>('ADD_PHONE_TO_COMPANY_DNC');

export function addPhoneToCompanyDNC(number: string): ReduxAction {
	return AddPhoneToCompanyDNC.started(number);
}

export function addPhoneToCompanyDNCSuccess(
	number: string,
	dnc: CompanyDncPhone
): ReduxAction {
	return AddPhoneToCompanyDNC.done({
		params: number,
		result: dnc,
	});
}

export function addPhoneToCompanyDNCFailure(
	number: string,
	error: any
): ReduxAction {
	return AddPhoneToCompanyDNC.failed({
		params: number,
		error: error,
	});
}

// SET PAGE NUM
export const SetCompanyDNCPageNum = actionCreator<number>(
	'SET_COMPANY_DNC_PAGE_NUM'
);

export function setCompanyDNCPageNum(pageNum: number): ReduxAction {
	return SetCompanyDNCPageNum(pageNum);
}

// CLEAR PHONES
export const ClearPhonesInState = actionCreator<undefined>(
	'CLEAR_PHONES_IN_STATE'
);

export function clearPhonesInState(): ReduxAction {
	return ClearPhonesInState(undefined);
}
