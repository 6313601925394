import actionCreatorFactory from 'typescript-fsa';
import { RecommendationStatusRequest, ReshopResultsRequest } from '../components/reshop/models/reshop_requests';
import { ReshopResultsResponse } from '../components/reshop/models/reshop_responses';
import { AgentEnrollmentSettings, ReshopTableData } from '../components/reshop/models/table_data';

const actionCreator = actionCreatorFactory('Reshop');

export const updateReshopSettings = actionCreator.async<AgentEnrollmentSettings, AgentEnrollmentSettings>(
	'UPDATE_RESHOP_SETTINGS'
);

export const getReshopSettings = actionCreator.async<string, AgentEnrollmentSettings>('GET_RESHOP_SETTINGS');

export const clearReshopSummaries = actionCreator('CLEAR_RESHOP_SUMMARIES');

export const getReshopResultsByAgentId = actionCreator.async<ReshopResultsRequest, ReshopResultsResponse>(
	'GET_RESHOP_RESULTS_BY_AGENT'
);

export const updateReshopRecommendationStatus = actionCreator.async<RecommendationStatusRequest, ReshopTableData>(
	'UPDATE_RESHOP_REVIEW_SETTINGS'
);

export const confirmReviewDecision = actionCreator<boolean>('CONFIRM_REVIEW_DECISION');
