import { Activity } from '../../reducers/activity_reducer';
import React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	List,
	Grow,
	CircularProgress,
} from '@material-ui/core';
import { displayDate, DateTimeFormat } from '../../utilities/date_util';
import { SearchResult } from '../utility/search_result';
import { themePalette } from '../../utilities/branding';
import { getNearestActivities } from '../../utilities/activity_util';
import Moment from 'moment';

interface Props {
	isLoading: boolean;
	activities: Activity[];
	open: boolean;
	handleCancel: () => void;
	handleSelectActivities: (activities: Activity[]) => void;
}

interface State {
	selectedActivityIds: string[];
}

export class ActivitySelectionDialog extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			selectedActivityIds: [],
		};
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.isLoading && !this.props.isLoading && (!this.props.activities || !this.props.activities.length)) {
			this.handleContinue();
		}
	}

	handleChange = (value: string) => {
		const targetIndex = this.state.selectedActivityIds.findIndex(
			activityId => activityId == value
		);
		const newActivityIds = this.state.selectedActivityIds.slice();
		if (targetIndex > -1) {
			newActivityIds.splice(targetIndex, 1);
		} else {
			newActivityIds.push(value);
		}
		this.setState({
			selectedActivityIds: newActivityIds,
		});
	};

	handleCancel = (event: any) => {
		this.props.handleCancel();
		this.setState({
			selectedActivityIds: [],
		});
	};

	handleContinue = () => {
		const selectedActivities = this.props.activities.filter(activity =>
			this.state.selectedActivityIds.some(id => activity.id == id)
		);
		this.props.handleSelectActivities(selectedActivities);
		this.setState({
			selectedActivityIds: [],
		});
	};

	renderContent = (activities: Activity[]) => {
		if (this.props.isLoading) {
			return (
				<div style={{ textAlign: 'center', width: '100%' }}>
					<CircularProgress />
				</div>
			);
		} else if (activities.length > 0) {
			return (
				<List>
					{activities.map(activity => {
						return (
							<SearchResult
								key={activity.id}
								onClick={this.handleChange}
								primaryText={activity.title}
								secondaryText={displayDate(
									Moment.utc(activity.time).local(),
									DateTimeFormat.MonthDateTime
								)}
								resultId={activity.id}
								isSelected={this.state.selectedActivityIds.some(
									id => activity.id == id
								)}
							/>
						);
					})}
				</List>
			);
		}
	};

	render() {
		const activities = getNearestActivities(this.props.activities);
		const title = this.props.isLoading
			? 'Loading phone calls...'
			: 'Which phone call(s) are you completing?';
		return (
			<Dialog
				open={this.props.open}
				disableBackdropClick
				fullWidth
				style={{ margin: '5px' }}
				TransitionComponent={Grow}
				onBackdropClick={this.props.handleCancel}
				onEscapeKeyDown={this.props.handleCancel}
			>
				<DialogTitle>{title}</DialogTitle>
				<DialogContent>{this.renderContent(activities)}</DialogContent>
				<DialogActions>
					<Button color="secondary" onClick={this.handleCancel}>
						Cancel
					</Button>
					<Button
						onClick={this.handleContinue}
						style={{
							backgroundColor: this.props.isLoading
								? themePalette.disabled_button
								: themePalette.loaded,
							color: themePalette.negative_text,
						}}
						disabled={this.props.isLoading}
					>
						Continue
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}
