import { isType } from 'typescript-fsa';
import { SetDrawerStatus } from '../actions/access_page_actions';

export interface AccessPageState {
	drawerOpenStatus: boolean;
}

const initialState = {
	drawerOpenStatus: false,
};

export function accessPageReducer(
	state: AccessPageState = initialState,
	action: any
): AccessPageState {
	if (isType(action, SetDrawerStatus)) {
		return {
			...state,
			drawerOpenStatus: action.payload.visible,
		};
	} else {
		return state;
	}
}
