import React from 'react';
import { Dialog, DialogContent, TextField, Slide } from '@material-ui/core';
import { SaveCancelHeaderBarComponent } from '../Layout/SaveCancelHeaderBar';
import { SlideUp } from '../utility/transition';

interface CreateNoteDialogProps {
	onSave: (content: string) => void;
	closeDialog: () => void;
	initialContent?: string;
	isOpen: boolean;
	createNoteFullScreen?: boolean;
}

interface CreateNoteDialogState {
	content: string;
	hasError: boolean;
	errorText: string;
	editing: boolean;
	disableSave: boolean;
}

export class CreateNoteDialog extends React.Component<
	CreateNoteDialogProps,
	CreateNoteDialogState
	> {

	static defaultProps = {
		createNoteFullScreen: true,
	};

	constructor(props: CreateNoteDialogProps) {
		super(props);

		this.state = {
			content: props.initialContent || '',
			hasError: false,
			errorText: '',
			editing: false,
			disableSave: true,
		};
	}

	componentWillReceiveProps(nextProps: CreateNoteDialogProps) {
		if (
			(nextProps.initialContent || nextProps.initialContent == '')
			&& this.state.editing === false
		) {
			this.setState({ content: nextProps.initialContent });
		}
	}

	cancelAndClose = () => {
		this.setState({
			content: '',
			hasError: false,
			errorText: '',
			editing: false,
			disableSave: true,
		});
		this.props.closeDialog();
	};

	saveAndClose = () => {
		if (!this.state.content) {
			this.setState({
				errorText: 'This field is required',
				hasError: true,
				editing: false,
				disableSave: true,
			});
			return;
		} else if (!this.state.hasError) {
			this.props.onSave(this.state.content);
			this.setState({ editing: false });
		}
	};

	setContent = (event: any) => {
		const { value } = event.target;
		const hasError = value.length > 4000;
		this.setState({
			hasError,
			disableSave: false,
			errorText: hasError ? 'Exceeded 4000 character limit' : '',
			content: event.target.value,
			editing: true,
		});
	};

	render() {
		return (
			<Dialog
				fullScreen={this.props.createNoteFullScreen}
				TransitionComponent={SlideUp}
				open={this.props.isOpen}
				onClose={this.props.closeDialog}
				fullWidth
			>
				<SaveCancelHeaderBarComponent
					onCancel={this.cancelAndClose}
					title={'New Note'}
					saveText="Save"
					onSave={this.saveAndClose}
					isSaveDisabled={this.state.hasError || this.state.content.length == 0 || this.props.initialContent == this.state.content}
				/>
				<DialogContent>
					<TextField
						placeholder="Write a new note..."
						error={this.state.hasError}
						helperText={this.state.hasError ? this.state.errorText : null}
						fullWidth
						onChange={this.setContent}
						multiline={true}
						autoFocus
						value={this.state.content}
					/>
				</DialogContent>
			</Dialog>
		);
	}
}
