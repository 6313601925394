import {actionCreatorFactory} from 'typescript-fsa';
import { LeadSearchResult } from '../reducers/lead_search_reducer';
const actionCreator = actionCreatorFactory('LeadSearch');

export type LeadSearchFilters = {
	ageMin?: number;
	ageMax?: number;
	familyStatus?: string;
	selfEmployed?: string;
	numFamilyMembersMin?: number;
	numFamilyMembersMax?: number;
	householdIncomeMin?: number;
	householdIncomeMax?: number;
	leadLOB?: string;
	leadCreatedDateSearch?: string;
	leadCreatedDateFrom?: Date;
	leadCreatedDateTo?: Date;
	leadContactedDateSearch?: string;
	leadContactedDateFrom?: Date;
	leadContactedDateTo?: Date;
	leadStatusCode?: string[];
	leadStatusReason?: string[];
	callable?: string;
	callAttempts?: string;
};

export type SearchLeadsInput = {
	filters: LeadSearchFilters;
	pageIndex: number;
	pageSize: number;
};
export type SearchLeadsOutput = { 
	results: LeadSearchResult[]; 
	totalResults: number;
};

export type SaveLeadStatusInput = {
	id: string;
	statusCode: string;
	statusReason: string;
};
export type SaveLeadStatusOutput = {

};

// FETCH RESULTS
export const SearchLeads = actionCreator.async<
	SearchLeadsInput,
	SearchLeadsOutput
>('SEARCH_LEADS');

// SAVE STATUS UPDATE
export const SaveLeadStatus = actionCreator.async<
	SaveLeadStatusInput,
	SaveLeadStatusOutput
>('SAVE_LEAD_STATUS');
