import moment from 'moment';

import { get } from '../object_util';
import { normalizeInteger } from './data_normalizations';
import { normalizeNumeric, stringToNDigitIntegerString } from './string_normalization';

export const normalizeMonth = (value: number) => {
	if (1 <= value && value <= 12) {
		return normalizeInteger(value);
	} else {
		return null;
	}
};

export const normalizeDay = (value: number) => {
	if (1 <= value && value <= 31) {
		return normalizeInteger(value);
	} else {
		return null;
	}
};

export const normalizeYear = (value: number) => {
	if (1 <= value && value <= 9999) {
		return normalizeInteger(value);
	}
	return null;
};

export function normalizeDate(value: string) {
	if(!value) return;
	const onlyNumbers = normalizeNumeric(value);
	if (onlyNumbers.length > 4)
		return `${onlyNumbers.slice(0, 2)}/${onlyNumbers.slice(
			2,
			4
		)}/${onlyNumbers.slice(4, 8)}`;
	else if (onlyNumbers.length > 2)
		return `${onlyNumbers.slice(0, 2)}/${onlyNumbers.slice(2, 4)}`;
	else return onlyNumbers;
}

export function normalizeDateIE(value: string) {
	let normalizedDate = value;
	if (moment(value, 'MM/DD/YYYY', true).isValid() || moment(value, 'M/D/YYYY', true).isValid()) {
		normalizedDate = moment(value, 'MM/DD/YYYY').format('MM/DD/YYYY');
	}
	return normalizedDate;
}

export function normalizeTimeIE(value: string) {
	const timeParts = value.split(':');

	const hourToken = get(() => timeParts[0]);
	const hourToken2Digits = stringToNDigitIntegerString(hourToken, 2, 23);

	const minuteToken = get(() => timeParts[1]);
	const minuteToken2Digits = stringToNDigitIntegerString(minuteToken, 2, 59);

	let normalizedTime = '';
	if (hourToken) {
		normalizedTime += hourToken2Digits;

		if (minuteToken) {
			normalizedTime += ':' + minuteToken2Digits;
		}
	}
	return normalizedTime;
}

export const countdownFuzzyTimeRemaining = (
	startTime: string,
	endTime: string
): string => {
	const now = moment();
	const startMoment = moment(startTime).endOf('day');
	const endMoment = moment(endTime).endOf('day');

	const useStart = now.isBefore(startMoment);
	const deadline = useStart ? startMoment : endMoment;
	const beginning = useStart ? 'Starts ' : 'Ends ';

	//Do the day calculation during the same/previous month to avoid
	//inconsistencies caused by months having different numbers of days
	const nowAtDeadlineMonth = moment()
		.year(deadline.year())
		.month(deadline.month());
	if (now.date() > deadline.date()) {
		nowAtDeadlineMonth.subtract(1, 'months');
	}

	let diffMs = deadline.diff(now);

	const msInSecond = 1000;
	const msInMinute = msInSecond * 60;
	const msInHour = msInMinute * 60;
	const msInDay = msInHour * 24;
	const msInYear = msInDay * 365;
	if (diffMs <= 0) {
		return 'Complete';
	} else if (diffMs <= 44 * msInSecond) {
		return beginning + 'in seconds';
	} else if (diffMs <= 89 * msInSecond) {
		return beginning + 'in a minute';
	} else if (diffMs <= 44 * msInMinute) {
		const numMinutes = Math.ceil(diffMs / msInMinute);
		return beginning + `in ${numMinutes.toString()} minutes`;
	} else if (diffMs <= 89 * msInMinute) {
		return beginning + 'in an hour';
	} else if (diffMs <= 21 * msInHour) {
		const numHours = Math.ceil(diffMs / msInHour);
		return beginning + `in ${numHours.toString()} hours`;
	} else if (diffMs <= 35 * msInHour) {
		return beginning + 'in a day';
	} else if (diffMs <= 25 * msInDay) {
		const numDays = Math.ceil(diffMs / msInDay);
		return beginning + `in ${numDays.toString()} days`;
	} else if (diffMs <= 45 * msInDay) {
		return beginning + 'in a month';
	} else if (diffMs <= 319 * msInDay) {
		return beginning + `in ${moment.duration(diffMs).months()} months`;
	} else if (diffMs <= 547 * msInDay) {
		return beginning + 'in a year';
	} else {
		const numYears = Math.ceil(diffMs / msInYear);
		return beginning + `in ${numYears.toString()} minutes`;
	}
};