import React from 'react';
import { Dialog, DialogContent, Avatar, Icon } from '@material-ui/core';
import { SaveCancelHeaderBarComponent } from '../../components/Layout/SaveCancelHeaderBar';
import { InjectedProps as DialogInjectedProps, connectDialog } from '.';
import {
    SearchResult,
} from '../../reducers/advanced_search_reducer';
import { AppState } from '../../reducers';
import { connect, Dispatch } from '@optum-uhone-hmkts/rise';
import { MergeHouseholds } from '../../actions/household_actions';
import { CustomListItem } from '../../components/utility/custom_list_item';
import { themePalette } from '../../utilities/branding';
import { displayLineOfBusinessAvatars } from '../../components/utility/advanced_search_lob';
import { NavigationProps, navRoutes } from '../../components/nav/Routes';

interface StateProps {
    householdOne: SearchResult;
    householdTwo: SearchResult;

}

interface DispatchProps {
    mergeHouseholds: (primaryHouseholdId: string, secondaryHousheoldId: string) => void;
}

type Props = DispatchProps
    & DialogInjectedProps
    & StateProps
    & NavigationProps;

const getStyles = (): { [key: string]: React.CSSProperties } => ({
    avatar: {
        margin: 10,
        color: themePalette.negative_text,
        backgroundColor: themePalette.lead_avatar,
    },
    avatarSelected: {
        margin: 10,
        color: themePalette.negative_text,
        backgroundColor: themePalette.selected_avatar,
    },
    listItem: {
        minWidth: 425
    },
});

interface State {
    primaryHouseholdId: string;
    secondaryHouseholdId: string;
    onConfirmationScreen: boolean;
}

class MergeHouseholdDialogComponent extends React.Component<Props, State>{

    constructor(props: Props) {
        super(props);
        this.state = {
            primaryHouseholdId: '',
            secondaryHouseholdId: '',
            onConfirmationScreen: false,
        }
    }

    mergeHousehold() {
        this.props.mergeHouseholds(this.state.primaryHouseholdId, this.state.secondaryHouseholdId);
        this.props.dismissSelf();
    }

    togglePrimaryHouseholdId(householdId: string) {
        let secondaryHouseholdId: string = this.props.householdOne.HouseholdId && this.props.householdOne.HouseholdId == householdId
            ? this.props.householdTwo.HouseholdId || ""
            : this.props.householdOne.HouseholdId || "";

        this.setState({
            primaryHouseholdId: householdId,
            secondaryHouseholdId
        });
    }

    renderHousehold(searchResult: SearchResult) {
        const styles = getStyles();
        let houseHoldId = searchResult.HouseholdId || "";

        var centerHeader = searchResult.FirstName + ' ' + searchResult.LastName;

        var fullAddress = searchResult.City
            ? searchResult.City + ', ' + searchResult.State + ' ' + searchResult.ZipCode
            : '';

        var centerContent = [
            { value: fullAddress },
            { value: searchResult.HouseholdRole }
        ];

        var rightContent = [
            { value: searchResult.ContactedStatus },
            { value: displayLineOfBusinessAvatars(searchResult.Products, searchResult.ProductLinesOfBusiness) }

        ];
        var onClickUrl = window.location.origin +'/#'+ navRoutes.household.path.replace(':householdID', houseHoldId);

        if (this.props.householdOne.HouseholdId && this.props.householdTwo.HouseholdId) {
            return (<CustomListItem
                key={houseHoldId}
                id={houseHoldId}
                leftActionElement={
                    <Avatar
                        onClick={() => this.togglePrimaryHouseholdId(houseHoldId)}
                        style={
                            this.state.primaryHouseholdId === houseHoldId
                                ? styles.avatarSelected
                                : styles.avatar
                        }
                    >
                        <Icon>check</Icon>
                    </Avatar>}
                leftColumnSize={2}
                rightColumnSize={4}
                header={centerHeader}
                subtitles={centerContent}
                rightSubtitles={rightContent}
                onClick={() => window.open(onClickUrl) }
                style={styles.listItem}
            />);
        }
    }

    render() {
        return (
            <Dialog open={true}>
                <SaveCancelHeaderBarComponent
                    onSave={this.state.onConfirmationScreen
                        ? () => this.mergeHousehold()
                        : () => this.setState({ onConfirmationScreen: true })}
                    saveText={'Merge'}
                    title={this.state.onConfirmationScreen ? "Confirm Merge" : "Select Main Household"}
                    onCancel={
                        this.state.onConfirmationScreen
                            ? () => this.setState({ onConfirmationScreen: false })
                            : this.props.dismissSelf}
                    bypassCancelConfirmation={true}
                    isSaveDisabled={!(this.state.primaryHouseholdId && this.state.secondaryHouseholdId)}
                />
                <DialogContent>
                    {this.state.onConfirmationScreen
                        ? (
                            <div>
                                Are you sure you want to merge?  This action cannot be undone.
                            </div>)
                        : (
                            <div>
                                {this.renderHousehold(this.props.householdOne)}
                                {this.renderHousehold(this.props.householdTwo)}
                            </div>
                        )}
                </DialogContent>
            </Dialog>
        )
    }
}


function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return {
        mergeHouseholds: (primaryHouseholdId: string, secondaryHouseholdId: string) =>
            dispatch(MergeHouseholds.started({
                primaryHouseholdId: primaryHouseholdId,
                secondaryHouseholdId: secondaryHouseholdId
            })),
    };
}

function mapStateToProps(state): StateProps {
    

    let selectedResults: SearchResult[] = state.advancedSearch.results.filter(result => result.IsSelected);

    return {
        householdOne: selectedResults[0],
        householdTwo: selectedResults[1],
    };
}
const MergeHouseholdDialogContainer = connect(mapStateToProps, mapDispatchToProps, true)(MergeHouseholdDialogComponent);
export const MergeHouseholdDialog = connectDialog(MergeHouseholdDialogContainer);