import React from 'react';
import { compose } from 'redux';
import { NavigationProps } from '../../components/nav/Routes';
import { themePalette } from '../../utilities/branding';
import {
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Button,
	Grid,
} from '@material-ui/core';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import moment from 'moment';
import { getNotificationIcon } from '../../components/notifications/notification_item';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import H from 'history';
import { navigateTo } from '../../actions/navigation_actions';
import { connect } from '@optum-uhone-hmkts/rise';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { AppState } from '../../reducers/index';
import { LeadFilters } from '../../reducers/LeadFilterReducer';
import { StoreLeadFilters, GetFilteredLeads } from '../../actions/lead_actions';
import {
	getBulkReassignFilter,
	formatLeadFiltersPayload,
} from '../../utilities/lead_util';
import { Notification } from './notifications_page';
import { Strings as S } from '../../assets/common/strings';
import { GetNotification } from '../../actions/notification_actions';

interface NotificationListDetailProps extends NavigationProps {
	userId: string;
	storeLeadFilters: (filterValues: LeadFilters) => void;
	pageSize: number;
	getFilteredLeads: (filters: LeadFilters) => void;
	notification: Notification;
	loading: boolean;
	getNotification: (notificationId: string, userId: string) => void;
}

interface NotificationListDetailState {

}

class NotificationListDetail extends React.Component<
	NotificationListDetailProps,
	NotificationListDetailState
	> {
	constructor(props: any) {
		super(props);

	}

	componentDidMount() {
		this.props.getNotification(this.props.match.params.notificationId, this.props.match.params.userId);
	}

	bulkReassignFilter = () => {
		if (this.props.notification && this.props.notification.url) {
			const { url } = this.props.notification;
			const filter = getBulkReassignFilter(url);
			this.props.storeLeadFilters(filter);
			this.props.getFilteredLeads(filter);
		}
	};

	renderDeepLinkButton = () => {
		const notification = this.props.notification;
		let secondaryAction: any = undefined,
			viewButton: boolean = true;
		if (notification && notification.url) {
			let buttonText = S.LinkVerbiage.Default;
			let url = notification.url;
			if (notification.url.startsWith('/household')) {
				buttonText = S.LinkVerbiage.Household;
			} else if (notification.url.startsWith('/employer')) {
				buttonText = S.LinkVerbiage.Employer;
			} else if (notification.url.startsWith('/battles/')) {
				buttonText = S.LinkVerbiage.Battle;
			} else if (notification.url.startsWith('/battles')) {
				buttonText = S.LinkVerbiage.Battles;
			} else if (notification.url.startsWith('/lead/list')) {
				buttonText = S.LinkVerbiage.Leads;
				const foundNumbers = notification.title.match(/\d+/);
				const numberOfLeads = parseInt(
					foundNumbers
						? foundNumbers[0]
						: '0'
				);
				if (numberOfLeads === 0) {
					viewButton = false;
				}
				if (notification.url.includes('/lead/list?start')) {
					secondaryAction = this.bulkReassignFilter;
					url = '/lead/list';
				}
			}

			return (
				<CardActions>
					{viewButton && <Button
						color="primary"
						onClick={() => {
							secondaryAction && secondaryAction();
							this.props.navigateTo( url);
						}}
					>
						{buttonText}
					</Button>}
				</CardActions>
			);
		}
	};

	render() {
		return (
			<BasePageContainer
				topComponent={
					<HeaderBarComponent
						title="Notification"
						forceOverflow={true}
					/>
				}
				
				middleComponent={
					<div>
						<PersistentNotifications
							displayingNotificationId={this.props.notification ? this.props.notification.id : undefined}
						/>

						{this.props.notification ? (
							<Grid container>
								<Grid item lg={3} />
								<Grid item xs={12} lg={6}>
									<Card style={styles.paperCardStyle}>
										<CardHeader
											avatar={getNotificationIcon(
												this.props.notification.category,
												this.props.notification.isUrgent
											)}
											title={this.props.notification.title}
										/>
										<CardContent>
											<div>{this.props.notification.content.base}</div>
											<p>{this.props.notification.content.extended}</p>
											<p style={{ color: themePalette.tertiary_text }}>
												{moment(this.props.notification.startDate).format(
													'MM-DD-YYYY @ hh:mm A'
												)}
											</p>
										</CardContent>
										{this.renderDeepLinkButton()}
									</Card>
								</Grid>
							</Grid>
						) : null}
					</div>
				}
			/>
		);
	}
}

const styles: { [key: string]: React.CSSProperties } = {
	paperCardStyle: {
		margin: '10px',
	},
};

function mapStateToProps(
	state: any
): Partial<NotificationListDetailProps> {
	
	return {
		userId: state.user.impersonatingId
			? state.user.impersonatingId
			: state.user.id,
		pageSize: state.lead.leadListPageSize,
		notification: state.notifications.selectedNotification
	};
}

function mapDispatchToProps(
	dispatch: any,
	ownProps: NotificationListDetailProps
): Partial<Readonly<NotificationListDetailProps>> {
	return {
		getNotification: (notificationId: string) =>
			dispatch(GetNotification.started(notificationId)),
		navigateTo: (route: string) =>
			dispatch(navigateTo(route)),
		storeLeadFilters: (filterValues: LeadFilters) =>
			dispatch(StoreLeadFilters(filterValues)),
		getFilteredLeads: (leadFilters: LeadFilters) => {
			const payload = formatLeadFiltersPayload(
				leadFilters,
				0,
				ownProps.pageSize
			);
			return dispatch(GetFilteredLeads.started(payload));
		},
	};
}

export const NotificationListDetailContainer = compose(
	connect(mapStateToProps, mapDispatchToProps, true),
)(NotificationListDetail);
