import { Strings } from '../assets/common/strings';

export const isBrokerage = ((): boolean =>
    !(~window.location.host.indexOf('agentconnect')) &&
    !(~window.location.host.indexOf('localhost')) &&
    !(~window.location.host.indexOf('127.0.0.1'))
)();

export const getReferrerUrl = (): string => {
    let referrerUrl: string = Strings.AgencyReferralUrl.Hmkts;
    const url = window.location.hostname.toLowerCase();
    if (!~url.indexOf('localhost')) {
        const match = url.match(`([a-z-]+?).com`);
        if (match && match.length > 0) {
            referrerUrl = match[0].replace('.com', '');
        }
    }
    return referrerUrl;
};

export const getEmailDomain = () => {
    const referrerUrl = getReferrerUrl();
    if (referrerUrl === Strings.AgencyReferralUrl.Hmkts) {
        return Strings.TemplateManagement.AtHMKTS;
    }
    else if (referrerUrl === Strings.AgencyReferralUrl.Excelsior) {
        return Strings.TemplateManagement.AtEIB;
    }
    return `@${referrerUrl}.com`;
};

export const getRealmUrl = (): string => {
    return window.location.origin;
};