import React from 'react';
import { InjectedProps as DialogInjectedProps, connectDialog } from '.';
import { DialogForm } from './dialog_form';
import { navRoutes } from '../../components/nav/Routes';
import { Strings } from '../../assets/common/strings';
import { useNavigationProps } from '../../utilities/hooks';
import { connect, Dispatch } from '@optum-uhone-hmkts/rise';
import { FcmRegistration } from '../../actions/fcm_actions';



const EditNotificationsSettingsDialog: React.FC<DialogInjectedProps> = (props) => {

    const { dismissSelf } = props;
    const { navigateTo } = useNavigationProps();

    return (
        <DialogForm
            onSubmit={() => navigateTo(navRoutes.settingsNotifications.path)}
            dismissSelf={dismissSelf}
            title={<>Push notifications enabled.<br /> Would you like to edit your push
                notification settings?</>}
            submitText={Strings.ButtonText.ChangeSettings}
        />
    );

};

export const EditNotificationSettingsDialog = connectDialog(EditNotificationsSettingsDialog);



interface DispatchProps {
    beginRegistration: () => void;
}
const RegisterNotifications: React.FC<DialogInjectedProps & DispatchProps> = (props) => {

    const { dismissSelf, beginRegistration } = props;

    return (
        <DialogForm
            onSubmit={beginRegistration}
            dismissSelf={dismissSelf}
            title={'Allow Notifications?'}
            submitText={'Ok'}
        >
            <>
                You will be prompted to allow push notifications to this device.
            </>
        </DialogForm>
    );

};
const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    beginRegistration: () => dispatch(FcmRegistration.started())
});
export const RegisterNotificationsDialog = connectDialog(connect(undefined, mapDispatchToProps, true)(RegisterNotifications));