import {actionCreatorFactory} from 'typescript-fsa';
import { SelectedDataTableOptions } from '../reducers/data_table_reducer';

const dataTableActionCreator = actionCreatorFactory('DataTable');

interface UpdateProps {
    dataTableName: string;
    options: Partial<SelectedDataTableOptions>;
}
export const UpdateSelectedOptions = dataTableActionCreator<UpdateProps>('UPDATE_SELECTED_OPTIONS');
