import React from 'react';
import { defaultSvgProps, SvgProps } from '..';

const QuoteSvg: React.FC<SvgProps> = (props) => (
    <svg viewBox="0 0 35 35" {...props}>
        <path
            d="M14.57 18.35v.47a2.77 2.77 0 0 1 .79.19 1.72 1.72 0 0 1 .58.43 1.81 1.81 0 0 1 .3.46 1 1 0 0 1 .1.43.51.51 0 0 1-.15.37.53.53 0 0 1-.39.16.54.54 0 0 1-.55-.46 1 1 0 0 0-.68-.72v1.8c.35.1.64.19.85.27a1.87 1.87 0 0 1 .57.35 1.54 1.54 0 0 1 .41.57 1.72 1.72 0 0 1 .15.72 2 2 0 0 1-.23.93 1.9 1.9 0 0 1-.68.7 2.4 2.4 0 0 1-1.07.32 1.09 1.09 0 0 1-.05.37.23.23 0 0 1-.22.11.25.25 0 0 1-.22-.09.55.55 0 0 1-.06-.29v-.06a2.43 2.43 0 0 1-.89-.24 2.28 2.28 0 0 1-.63-.44 1.91 1.91 0 0 1-.37-.57 1.3 1.3 0 0 1-.12-.57.52.52 0 0 1 .16-.38.54.54 0 0 1 .4-.16.58.58 0 0 1 .33.09.48.48 0 0 1 .19.25c.08.24.14.42.2.55a1.19 1.19 0 0 0 .26.34 1.3 1.3 0 0 0 .47.24v-2a5.55 5.55 0 0 1-1-.36 1.62 1.62 0 0 1-.63-.56 1.65 1.65 0 0 1-.24-.93 1.62 1.62 0 0 1 .47-1.22 2.08 2.08 0 0 1 1.38-.6v-.46q0-.36.27-.36c.2 0 .3.12.3.35zm-.55 3v-1.69a1.69 1.69 0 0 0-.57.29.67.67 0 0 0-.2.54.63.63 0 0 0 .19.51 1.55 1.55 0 0 0 .56.32zm.55 1.29v1.86a1.17 1.17 0 0 0 .67-.35.87.87 0 0 0 .24-.61.74.74 0 0 0-.23-.58 1.88 1.88 0 0 0-.68-.35zM19.49 28.5A.51.51 0 0 1 19 28v-6a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5.5.5 0 0 1-.5.5H20V28a.5.5 0 0 1-.51.5z"
        />
        <path
            d="M19.49 28.5H9.16a.5.5 0 0 1-.5-.5V7.47a.5.5 0 0 1 .5-.5h16.33a.5.5 0 0 1 .5.5V22a.5.5 0 0 1-.14.35l-6 6a.49.49 0 0 1-.36.15zm-9.83-1h9.63L25 21.79V8H9.66z"
        />
        <path
            d="M18.41 11.76h-5.87a.5.5 0 0 1-.5-.5.5.5 0 0 1 .5-.5h5.87a.5.5 0 0 1 .5.5.5.5 0 0 1-.5.5zM22.29 14.18h-9.75a.5.5 0 0 1 0-1h9.75a.5.5 0 0 1 0 1zM22.29 16.6h-9.75a.5.5 0 0 1-.5-.5.5.5 0 0 1 .5-.5h9.75a.5.5 0 0 1 .5.5.51.51 0 0 1-.5.5z"
        />
    </svg>
);
QuoteSvg.defaultProps = defaultSvgProps;

export default QuoteSvg;
