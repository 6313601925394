import {
	CacheNewClientConnectActivity,
	ReturnClientConnectSettings,
	CacheClientConnectURL,
	openClientConnectConfigureDialog,
	getClientConnectUsageActivities,
} from '../actions/client_connect_actions';
import { isType } from 'typescript-fsa';
import { ClientConnectSettings, InitialCCAppointmentSettings } from '../sagas/clientConnect_sagas';
import { ClearCaches } from '../actions/authentication_actions';
import { Logout } from '../actions/authentication_actions';
import { Strings } from '../assets/common/strings';
import { ClientConnectActivity } from '../utilities/shared';
import { DaysOfTheWeek } from '../utilities/date_util';

export interface ClientConnectState {
	newActivity: number;
	settings: ClientConnectSettings;
	url?: string;
	configureDialogOpen: boolean;
	activities: ClientConnectActivity[];
	loading: boolean;
}

const initialState: ClientConnectState = {
	newActivity: 0,
	url: undefined,
	settings: {
		isActivated: false,
		appointmentSettings: InitialCCAppointmentSettings
	},
	configureDialogOpen: false,
	activities: [],
	loading: false,
};

export function clientConnectReducer(
	state: ClientConnectState = initialState,
	action: any
): ClientConnectState {
	if (isType(action, CacheNewClientConnectActivity)) {
		return {
			...state,
			newActivity: action.payload,
		};
	} else if (isType(action, ReturnClientConnectSettings)) {
		return {
			...state,
			settings: action.payload,
		};
	} else if (isType(action, CacheClientConnectURL)) {
		return {
			...state,
			url: action.payload,
		};
	} else if (isType(action, openClientConnectConfigureDialog.done)) {
		return {
			...state,
			configureDialogOpen: action.payload.result,
		};
	} else if (isType(action, getClientConnectUsageActivities.started)) {
		return {
			...state,
			loading: true,
		};
	} else if (isType(action, getClientConnectUsageActivities.done)) {
		return {
			...state,
			activities: action.payload.result,
			loading: false,
		};
	} else if (isType(action, getClientConnectUsageActivities.failed)) {
		return {
			...state,
			loading: false,
		};
	} else if (isType(action, Logout.started)) {
		return {
			...initialState,
		};
	} else if (isType(action, ClearCaches)) {
		return {
			...initialState,
		};
	} else {
		return state;
	}
}

export class Contact {
	static CONTACT_TYPES = [
		Strings.ClientConnectContactInfoType.Mobile,
		Strings.ClientConnectContactInfoType.Office,
		Strings.ClientConnectContactInfoType.Email,
		Strings.ClientConnectContactInfoType.Assistant
	];

	type: Strings.ClientConnectContactInfoType;
	data: {
		number?: string;
		email?: string;
		canText?: boolean;
		canCall?: boolean;
		asstName?: string;
		asstNumber?: string;
	};
	errorMessages?: Array<string>;

	constructor(newType: Strings.ClientConnectContactInfoType) {
		this.type = newType;

		if (newType === Strings.ClientConnectContactInfoType.Mobile) {
			this.data = {
				number: '',
				canCall: true,
				canText: true,
			};
		} else if (newType === Strings.ClientConnectContactInfoType.Office) {
			this.data = {
				number: '',
			};
		} else if (newType === Strings.ClientConnectContactInfoType.Email) {
			this.data = {
				email: '',
			};
		} else if (newType === Strings.ClientConnectContactInfoType.Assistant) {
			this.data = {
				asstName: '',
				asstNumber: '',
			};
		} else {
			this.data = {
				number: '',
				email: '',
				asstName: '',
				asstNumber: '',
			};
		}
		this.errorMessages = [];
		this.errorMessages[0] = 'Empty values not allowed.';
		if (newType === 'Assistant') {
			this.errorMessages[1] = 'Empty values not allowed.';
		}
	}

	static getKey(contact: Contact) {
		return `${contact.type}-${Contact.getUniqueData(contact)}`;
	}

	private static getUniqueData(contact: Contact) {
		if (contact.type === Strings.ClientConnectContactInfoType.Mobile) {
			contact.data = {
				number: '',
				canCall: true,
				canText: true,
			};
		} else if (contact.type === Strings.ClientConnectContactInfoType.Office) {
			return contact.data.number;
		} else if (contact.type === Strings.ClientConnectContactInfoType.Email) {
			return contact.data.email;
		} else if (contact.type === Strings.ClientConnectContactInfoType.Assistant) {
			return `${contact.data.asstName}-${contact.data.asstNumber}`;
		} else {
			return JSON.stringify(contact.data)
		}
	}
}