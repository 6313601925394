import * as React from "react";
import { Button, Icon, TextField, Grid } from '@material-ui/core';
import { wrapInCollapsibleCard } from '../higher_order_component/wrap_in_card';
import { Strings } from '../../assets/common/strings';
import { enforceStylesType } from '../../utilities/styles_util';

interface Props {
    timeZoneDisplayString: string;
    handleClickEdit: () => void;
}

const TimeZone: React.FC<Props> = (props) => {
    const { timeZoneDisplayString, handleClickEdit } = props;

    return (
        <Grid container>
            <TextField
                value={timeZoneDisplayString}
                disabled
                label={Strings.AboutMeFields.TimeZone}
            />
            <Button onClick={handleClickEdit}>
                <Icon>edit</Icon>
            </Button>
        </Grid>
    );
}

export const TimeZoneCard = wrapInCollapsibleCard(TimeZone);
