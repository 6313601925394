import moment from "moment";
import { createSelector } from "reselect";
import { Notification } from "../containers/notifications_page/notifications_page";
import { AppState } from "../reducers";

const mostRecentSort = (a: Notification, b: Notification) => {
    const date1 = Date.parse(a.startDate);
    const date2 = Date.parse(b.startDate);
    return date2 - date1;
};
const validPersistentNotification = (notification: Notification) => {
	const { startDate, endDate } = notification;

	const nowAsMomentString = moment().format();
	const validStartDate = Boolean(startDate) && moment(startDate).format() < nowAsMomentString;
	const validEndDate = Boolean(endDate) && moment(endDate).format() > nowAsMomentString;

	return validStartDate && validEndDate;
}
const notificationsWidgetFilter = (notification: Notification) =>
    !notification.isRead && !notification.isPersistent;
const notificationsPersistentFilter = (notification: Notification) =>
    notification.isPersistent;
const notificationsNotPersistentFilter = (notification: Notification) =>
    !notification.isPersistent;
const notificationsNotReadFilter = (notification: Notification) =>
    !notification.isRead;
const urgentNotificationsFilter = (notification: Notification) =>
    notification.isUrgent && !notification.isRead;
const adminNotificationsFilter = (notification: Notification) =>
    notification.senderAgentId && notification.title !== 'Google Calendar sync is unverified'

const getNotifications = (state: AppState) => (state.notifications.newNotifications || []);
const getPersistent = (state: AppState) => (state.notifications.persistentNotifications || []);

export const getLatestNotifications = createSelector(
    [getNotifications],
	(notifications: Notification[]) =>
        notifications.filter(notificationsWidgetFilter)
            .sort(mostRecentSort)
);

export const getWidgetNotifications = createSelector(
	[getLatestNotifications],
	(notifications: Notification[]) =>
        notifications.slice(0, 3)
);

export const getPersistentNotifications = createSelector(
    [getPersistent],
    (notifications: Notification[]) =>
        notifications.filter(notificationsPersistentFilter)
            .sort(mostRecentSort)
);

export const getValidPersistentNotifications = createSelector(
    [getPersistentNotifications],
    (notifications: Notification[]) =>
        notifications.filter(validPersistentNotification)
);

const getNotPersistentNotifications = createSelector(
    [getNotifications],
    (notifications: Notification[]) =>
        notifications.filter(notificationsNotPersistentFilter)
);

export const getNotificationsList = createSelector(
    [getNotPersistentNotifications],
    (notifications: Notification[]) =>
        notifications.sort(mostRecentSort)
);

export const getUrgentNotifications = createSelector(
    [getNotPersistentNotifications],
    (notifications: Notification[]) =>
        notifications.filter(urgentNotificationsFilter)
            .sort(mostRecentSort)
);

export const getNewNotifications = createSelector(
    [getNotPersistentNotifications],
    (notifications: Notification[]) =>
        notifications.filter(notificationsNotReadFilter)
            .sort(mostRecentSort)
);

export const getAdminNotifications = createSelector(
    [getNotifications, getPersistent],
    (notifications: Notification[], persistent: Notification[]) =>
        notifications
            .concat(persistent)
            .filter(adminNotificationsFilter)      
            .sort(mostRecentSort)
);