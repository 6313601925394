import React from 'react';
import { StrictFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';
import { Typography } from '@material-ui/core';

import { EmployerFields, EmployerFieldsData } from './employer_fields';
import {
	BasicInfoFields,
	BasicInfoFieldsData,
} from '../lead_upsert/basic_info_fields';
import {
	ContactFields,
	ContactFieldsData,
} from '../lead_upsert/contact_fields';
import { VendorFields, VendorFieldsData } from '../lead_upsert/vendor_fields';
import {
	LineOfBusinessFields,
	LineOfBusinessFieldsData,
} from '../lead_upsert/line_of_business_fields';
import { createRequiredLabel } from '../../components/utility/required_label';
import { getAppState } from '../..';

export const FORM_NAME = 'UpsertNewEmployerLeadForm';

export type FormData = EmployerFieldsData &
	BasicInfoFieldsData &
	ContactFieldsData &
	VendorFieldsData &
	LineOfBusinessFieldsData;

interface Props {
	mode?: string;
	synchronErrors?: any;
}

const validate = values => {
	let errors = {
	};
	if (values?.['linesOfBusiness']) {
		if (!values['linesOfBusiness']?.['Employer']) {
			errors["employer"] = 'Employer is required';
		}
	}

	return errors;
};

const _UpsertLeadEmployerForm = (
	props: Props & StrictFormProps<FormData, any, any>
) => {
	const contactFields: JSX.Element = (
		<div>
			<EmployerFields />
			<BasicInfoFields
				formName={FORM_NAME}
				employee
				title="Primary Contact Basic Info"
			/>
			<ContactFields
				formName={FORM_NAME}
				syncronErrors={props.synchronErrors}
			/>
		</div>
	);
	const leadFields: JSX.Element = (
		<div>
			<Typography
				variant="h5"
				style={{ paddingLeft: 16, paddingTop: 16 }}
			>
				{createRequiredLabel(`Interested in...`)}
			</Typography>
			<Typography variant="body1" style={{ padding: '0px 16px' }}>
				Select the product types the customer is interested in and Employer is
				required
			</Typography>
			<LineOfBusinessFields formName={FORM_NAME} />
			<VendorFields isManual={true} />
		</div>
	);
	const showContactFields = !(
		props.mode == 'newLeadExistingEmployer' || props.mode == 'editLead'
	);

	return (
		<form>
			{showContactFields ? contactFields : undefined}
			{leadFields}
		</form>
	);
};

export const UpsertLeadEmployerForm = reduxForm({
	form: FORM_NAME,
	validate: validate,
	enableReinitialize: true,
	getFormState: (state) => getAppState().form,
})(_UpsertLeadEmployerForm) as React.ComponentClass<
	Props & Partial<StrictFormProps<FormData, any, any>>
>;
