import React from 'react';
import { Grid, Divider, GridSize } from '@material-ui/core';
import { Loaded } from '../../utilities/utilities';
import { isNullOrUndefined } from 'util';
import { Employer } from '../../reducers/employer_reducer';
import { ReadOnlyTextField } from '../utility/read_only_text_field';
import { wrapInCollapsibleCard } from '../higher_order_component/wrap_in_card';
import { getYesNoFromBool } from '../../utilities/formatting/data_normalizations';

interface Props {
	employer?: Loaded<Employer>;
}
const EmployerDetail = (props: Props) => {
	const employer = props.employer ? (props.employer.data as Employer) : undefined;
	const companyName = employer?.companyName || '';
	const website = employer?.website || '';
	const annualRevenue = formatNumCurrency(employer?.annualRevenue);
	const numEmployees = employer?.numberOfEmployees || '';
	const numPartTime = employer?.numberOfPartTime || '';
	const industry = employer?.industry || '';
	const currentCarrier = employer?.currentCarrier || '';
	const empContribution = formatNumCurrency(employer?.employerContributionAmount);
	const numGroupParticipants = employer?.numberOfGroupParticipants || '';
	const groupCoverageAdvantage = employer?.groupCoverageAdvantage || '';
	const empContributionBase = formatNumCurrency(employer?.employerContributionBaseAmount);
	const numGroupElibiles = employer?.numberOfGroupEligibles || '';
	const ancilliariesOffered = getYesNoFromBool(employer?.isAncillariesOffered);
	const famCoverageOffered = getYesNoFromBool(employer?.isFamilyCoverageOffered);
	const groupPlanOffered = getYesNoFromBool(employer?.isGroupPlanOffered);
	const empContributesGroupCoverage = getYesNoFromBool(employer?.isEmployerContributesToGroupCoverage);
	const age65up = getYesNoFromBool(employer?.isAge65OrAboveAllowed);

	return (
		<Grid container>
			<Grid item container>
				{generateItem('Company Name', companyName)}
				{generateItem('Website', website, 12, { wordWrap: 'break-word' })}
				{generateItem('Annual Revenue', annualRevenue, 6)}
				{generateItem('Number of Employees', numEmployees as string, 6)}
				{generateItem('Number of Part Time', numPartTime as string, 6)}
				{generateItem('Industry', industry, 6)}
				{generateItem('Current Carrier', currentCarrier)}
			</Grid>
			<Divider style={{ marginTop: 16, marginBottom: 16 }} />
			<Grid item container>
				{generateItem('Employer Contribution Amount', empContribution)}
				{generateItem('Employer Contribution Amount (Base)', empContributionBase)}
				{generateItem('Number of Group Participants', numGroupParticipants as string)}
				{generateItem('Number of Group Eligibles', numGroupElibiles as string)}
				{generateItem('Group Coverage Advantage', groupCoverageAdvantage)}
			</Grid>
			<Divider style={{ marginTop: 16, marginBottom: 16 }} />
			<Grid item container>
				{generateItem('Ancillaries Offered?', ancilliariesOffered)}
				{generateItem('Family Coverage Offered?', famCoverageOffered)}
				{generateItem('Group Plan Offered?', groupPlanOffered)}
				{generateItem('Employer Contributes to Group Coverage?', empContributesGroupCoverage)}
				{generateItem('Employees Age 65 or Above?', age65up)}
			</Grid>
		</Grid>
	);
};

export const CompanyInfoCard = wrapInCollapsibleCard(EmployerDetail);


const generateItem = (title: string, value: string, size: GridSize=12, style?: React.CSSProperties) => (
	<Grid item xs={size|| 12}>
		<ReadOnlyTextField style={style} label={title} value={value || ''} />
	</Grid>
);
const formatNumCurrency = (amount?: number) => {
	if (isNullOrUndefined(amount)) {
		return '';
	}
	return (
		'$' +
		amount.toFixed(2).replace(/./g, function(c, i, a) {
			return i && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c;
		})
	);
}