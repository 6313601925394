import { AddAttachment, DeleteAttachment } from '../actions/attachment_actions';
import {
	GetEmployer,
	UpdateEmployer,
	DeleteEmployer,
	ReassignEmployer,
	SearchEmployers,
} from './../actions/employer_actions';
import {
	GetHousehold,
	ReassignHousehold,
} from './../actions/household_actions';
import { isType } from 'typescript-fsa';
import { Loaded } from './../utilities/utilities';
import { Action as ReduxAction } from 'redux';
import _ from 'lodash';
import { ClearCaches, Logout } from '../actions/authentication_actions';
import { BulkReassign } from '../actions/lead_actions';

export interface Attachment {
	id: string;
	dateCreated: Date;
	fileName: string;
	file?: any;
	householdId?: string;
	employerId?: string;
}

export interface AttachmentState {
	attachments: Loaded<Attachment>[];
}

export const initialState = {
	attachments: [],
};

export function attachmentReducer(
	state: AttachmentState = initialState,
	action: ReduxAction
): AttachmentState {
	if (
		isType(action, GetEmployer.done) ||
		isType(action, UpdateEmployer.done) ||
		isType(action, GetHousehold.done)
	) {
		const attachmentsInState = state.attachments.slice();
		updateStateWithInboundAttachments(
			attachmentsInState,
			action.payload.result.attachments
		);
		return {
			...state,
			attachments: attachmentsInState,
		};
	} else if (isType(action, DeleteEmployer.done)) {
		const attachmentsInState = state.attachments.filter(
			attachment => attachment.employerId !== action.payload.params
		);
		return {
			...state,
			attachments: attachmentsInState,
		};
	} else if (isType(action, ReassignEmployer.done)) {
		const attachmentsInState = state.attachments.filter(
			attachment => attachment.employerId !== action.payload.params.employerId
		);
		return {
			...state,
			attachments: attachmentsInState,
		};
	} else if (isType(action, ReassignHousehold.done)) {
		const attachmentsInState = state.attachments.filter(
			attachment => attachment.householdId !== action.payload.params.householdId
		);
		return {
			...state,
			attachments: attachmentsInState,
		};
	} else if (
		isType(action, AddAttachment.started) ||
		isType(action, DeleteAttachment.started)
	) {
		const attachmentsInState = state.attachments.slice();
		upsertAttachmentStart(attachmentsInState, action.payload);
		return {
			...state,
			attachments: attachmentsInState,
		};
	} else if (isType(action, AddAttachment.done)) {
		const attachmentsInState = state.attachments.slice();
		upsertAttachmentDone(attachmentsInState, action.payload.result);
		return {
			...state,
			attachments: attachmentsInState,
		};
	} else if (isType(action, DeleteAttachment.done)) {
		const attachmentsInState = state.attachments.slice();
		const attachmentIndex = attachmentsInState.findIndex(
			cachedAttachment => cachedAttachment.data.id == action.payload.result
		);
		if (attachmentIndex > -1) attachmentsInState.splice(attachmentIndex, 1);
		return {
			...state,
			attachments: attachmentsInState,
		};
	} else if (
		isType(action, AddAttachment.failed) ||
		isType(action, DeleteAttachment.failed)
	) {
		const attachmentsInState = state.attachments.slice();
		upsertAttachmentDone(
			attachmentsInState,
			action.payload.params,
			action.payload.error
		);
		return {
			...state,
			attachments: attachmentsInState,
		};
	} else if (isType(action, SearchEmployers.done)) {
		const attachmentsInState = state.attachments.slice();
		let newAttachments: Attachment[] = [];
		action.payload.result.forEach(fullEmployer => {
			newAttachments = newAttachments.concat(fullEmployer.attachments);
		});
		updateStateWithInboundAttachments(attachmentsInState, newAttachments);
		return {
			...state,
			attachments: attachmentsInState,
		};
	} else if (isType(action, BulkReassign.done)) {
		const attachmentsInState = state.attachments.slice();
		const filteredAttachments = attachmentsInState.filter(attachment =>
			action.payload.params.entities.some(
				entity =>
					entity.id != attachment.householdId &&
					entity.id != attachment.employerId &&
					entity.id != attachment.data.householdId &&
					entity.id != attachment.data.employerId
			)
		);
		return {
			...state,
			attachments: filteredAttachments,
		};
	} else if (isType(action, Logout.started)) {
		return {
			...initialState,
		};
	} else if (isType(action, ClearCaches)) {
		return {
			...initialState,
		};
	} else {
		return state;
	}
}

function upsertAttachmentStart(
	cachedAttachments: Loaded<Attachment>[],
	attachment: Attachment
) {
	const matchedAttachmentIndex = _.findIndex(
		cachedAttachments,
		cachedAttachment => cachedAttachment.data.id === attachment.id
	);
	if (matchedAttachmentIndex !== -1) {
		cachedAttachments[matchedAttachmentIndex] = {
			...cachedAttachments[matchedAttachmentIndex],
			loading: true,
			employerId: attachment.employerId,
			householdId: attachment.householdId,
		};
	} else {
		const newAttachment: Loaded<Attachment> = {
			data: attachment,
			loading: true,
			employerId: attachment.employerId,
			householdId: attachment.householdId,
			errors: [],
		};
		cachedAttachments.push(newAttachment);
	}
}

function upsertAttachmentDone(
	cachedAttachments: Loaded<Attachment>[],
	updatedAttachment: Attachment,
	error?: any
) {
	const index = _.findIndex(
		cachedAttachments,
		cachedAttachment => cachedAttachment.data.id === updatedAttachment.id
	);
	if (index > -1) {
		if (error)
			cachedAttachments[index] = {
				...cachedAttachments[index],
				loading: false,
				errors: [...cachedAttachments[index].errors, error],
			};
		else
			cachedAttachments[index] = {
				...cachedAttachments[index],
				data: updatedAttachment,
				employerId: updatedAttachment.employerId,
				householdId: updatedAttachment.householdId,
				loading: false,
				errors: [],
			};
	} else {
		const newAttachment: Loaded<Attachment> = {
			data: updatedAttachment,
			loading: false,
			employerId: updatedAttachment.employerId,
			householdId: updatedAttachment.householdId,
			errors: error ? [error] : [],
		};
		cachedAttachments.push(newAttachment);
	}
}

function updateStateWithInboundAttachments(
	attachmentsInState: Loaded<Attachment>[],
	inboundAttachments: Attachment[]
) {
	inboundAttachments.forEach(inboundAttachment => {
		const attachmentId = inboundAttachment.id;
		let wasAAttachmentUpdated = false;

		attachmentsInState.forEach((currentAttachment, index) => {
			const currentAttachmentId = currentAttachment.data.id;
			if (currentAttachmentId === attachmentId) {
				wasAAttachmentUpdated = true;
				attachmentsInState[index] = {
					...attachmentsInState[index],
					loading: false,
					data: inboundAttachment,
					householdId: inboundAttachment.householdId,
					employerId: inboundAttachment.employerId,
					errors: [],
				};
			}
		});
		if (!wasAAttachmentUpdated) {
			const newAttachment: Loaded<Attachment> = {
				loading: false,
				data: inboundAttachment,
				errors: [],
				householdId: inboundAttachment.householdId,
				employerId: inboundAttachment.employerId,
			};
			attachmentsInState.push(newAttachment);
		}
	});
}
