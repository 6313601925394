import React from 'react';
import { themePalette } from '../../utilities/branding';


interface InputProps {
	label: string;
}

type Props = InputProps;

export const RequiredLabel: React.FC<Props> = ({ label }) => (
	<span>
		{label}
		<span style={{ color: themePalette.required_text }}> *</span>
	</span>
);

export const createRequiredLabel = (label: string) => <RequiredLabel label={label} />;