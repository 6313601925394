import {actionCreatorFactory} from 'typescript-fsa';
import { Strings } from '../assets/common/strings';

export interface LoggingProps {
	level: Strings.Log;
	message: string;
	metadata?: any;
}
const actionCreator = actionCreatorFactory('LOGGING');

export const LogToSplunk = actionCreator.async<LoggingProps, undefined>('LOG_TO_SPLUNK');

export const SentLogs = actionCreator<string[]>('SENT_LOGS');
