import React from 'react';
import { Grid, Divider, TextFieldProps } from '@material-ui/core';
import { ReadOnlyTextField } from '../utility/read_only_text_field';
import { MedicareInfo as MedicareInfoType, LisAmountEnum } from '../../reducers/ContactReducer';
import { DateTimeFormat, displayDate } from '../../utilities/date_util';
import { Strings } from '../../assets/common/strings';

interface MedicareInfoProps {
	medicareInfo: MedicareInfoType;
}

function generateItem(title: string, value?: string, props?: TextFieldProps) {
	return <ReadOnlyTextField label={title} value={value || ''} {...props} />;
}

export const MedicareInfo = (props: MedicareInfoProps) => {
	const { medicareInfo } = props;
	const medicareOptions = medicareInfo.MedicareOptions || {};

	// Multiple pharmacies - 21.1
	const pharmacies = medicareOptions.Pharmacies;
	const pharmacyLength = pharmacies?.length ?? 0;
	const pharmacyNames = pharmacies?.filter(p => p.Name).map(p => p.Name).join(', ')
		?? medicareOptions.Pharmacy?.Name
		?? '';

	return (
		<div>
			{medicareOptions.Medications
				? medicareOptions.Medications.map((item, index) => {
					const nameArray = item.Name ? item.Name.split(' ') : [];
					const dose =
						nameArray.length > 0 ? nameArray[nameArray.length - 1] : '';
					const medStyle = index > 0 ? styles.topMargin : {};
					return (
						<div key={item.Id} style={medStyle}>
							<Grid container>
								<Grid item xs={8}>
									{generateItem(Strings.MedicareInfoFieldLabels.Prescription, item.Name)}
								</Grid>
								<Grid item xs={4}>
									{generateItem(Strings.MedicareInfoFieldLabels.Dosage, dose)}
								</Grid>
							</Grid>
							<Divider style={styles.dividerStyle} />
						</div>
					);
				})
				: null}
			{medicareOptions.Providers
				? medicareOptions.Providers.map((provider, index) => {
					const location = provider.SelectedLocation;
					return (
						<div key={provider.ProviderId} style={styles.topMargin}>
							<Grid container>
								<Grid item xs={4}>
									{generateItem(
										Strings.MedicareInfoFieldLabels.Provider,
										`${provider.FirstName} ${provider.LastName}`
									)}
								</Grid>
								<Grid item xs={4}>
									{generateItem(Strings.MedicareInfoFieldLabels.PhoneNumber, location.PhoneNumber)}
								</Grid>
								<Grid item xs={4}>
									{generateItem(Strings.MedicareInfoFieldLabels.ZipCode, location.Zip)}
								</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={8}>
									{generateItem(Strings.MedicareInfoFieldLabels.Address, location.Address)}
								</Grid>
								<Grid item xs={3}>
									{generateItem(Strings.MedicareInfoFieldLabels.City, location.City)}
								</Grid>
								<Grid item xs={1}>
									{generateItem(Strings.MedicareInfoFieldLabels.State, location.State)}
								</Grid>
							</Grid>
							<Divider style={styles.dividerStyle} />
						</div>
					);
				})
				: null}
			<Grid container style={styles.topMargin}>
				<Grid item xs>
					{(Boolean(pharmacyLength) || Boolean(pharmacyNames)) &&
						generateItem(
							pharmacyLength > 1
								? Strings.MedicareInfoFieldLabels.Pharmacies
								: Strings.MedicareInfoFieldLabels.Pharmacy,
							pharmacyNames,
							{
								multiline: true
							}
						)
					}
				</Grid>
			</Grid>
			{medicareInfo.PartAEffectiveDate &&
				<Grid container style={styles.topMargin}>
					<Grid item xs>
						{generateItem(Strings.MedicareInfoFieldLabels.PartAEffectiveDate, displayDate(medicareInfo.PartAEffectiveDate, DateTimeFormat.Date))}
					</Grid>
				</Grid>
			}
			{medicareInfo.PartBEffectiveDate &&
				<Grid container style={styles.topMargin}>
					<Grid item xs>
						{generateItem(Strings.MedicareInfoFieldLabels.PartBEffectiveDate, displayDate(medicareInfo.PartBEffectiveDate, DateTimeFormat.Date))}
					</Grid>
				</Grid>
			}
			{medicareInfo.MedicareNumber &&
				<Grid container style={styles.topMargin}>
					<Grid item xs>
						{generateItem(Strings.MedicareInfoFieldLabels.MedicareNumber, medicareInfo.MedicareNumber)}
					</Grid>
				</Grid>
			}
			{Boolean(medicareOptions.Lis) && medicareOptions.Lis !== LisAmountEnum.Undefined &&
				<Grid container style={styles.topMargin}>
					<Grid item xs>
						{generateItem(Strings.MedicareInfoFieldLabels.LowIncomeSubsidy, Strings.LisMap[medicareOptions.Lis])}
					</Grid>
				</Grid>
			}
		</div>
	);
};

const styles: { [key: string]: React.CSSProperties } = {
	dividerStyle: {
		paddingBottom: '1.1px',
	},
	topMargin: {
		marginTop: 10,
	},
};
