import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Icon, InputAdornment, TextField, Typography
} from '@material-ui/core';
import { Strings } from '../../../../../assets/common/strings';

interface Props {
    value: string;
    isDisabled: boolean;
    handleChange: (value: string) => void;
}

export const PrescriptionSearch: React.FC<Props> = ({ value, isDisabled, handleChange }) => {
    const classes = useStyles();

    return (
        <>
            <form>
                <TextField
                    autoFocus
                    name="prescriptionSearch"
                    label="Drug Name"
                    value={value}
                    onChange={(e) => handleChange(e.target.value)}
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Icon>search</Icon>
                            </InputAdornment>
                        ),
                    }}
                    className={classes.searchField}
                    disabled={isDisabled}
                />
            </form>
            <Typography
                variant={'caption'}
                color={'textSecondary'}
                className={classes.subtitle}
            >
                {Strings.FactFinderPrescriptionLookup.maxDrugAmount}
            </Typography>
        </>
    )
};

const useStyles = makeStyles({
    searchField: {
        margin: '10px 10px 10px 0px',
    },
    subtitle: {
        marginTop: '-5px' ,
        fontStyle: 'italic' ,
        textAlign: 'start' ,
    },
});
