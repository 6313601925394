import { Grid, GridSize } from '@material-ui/core';
import React from 'react';
import { toCurrencyString } from '../../assets/common/string_builders';
import { wrapInCollapsibleCard } from '../../components/higher_order_component/wrap_in_card';
import { ReadOnlyTextField } from '../../components/utility/read_only_text_field';
import { Lead } from '../../reducers/LeadReducer';
import { getYesNoFromBool } from '../../utilities/formatting/data_normalizations';

const parseLeadCost = (leadCost: number | undefined) => {
    if (typeof leadCost == undefined || !leadCost) {
        return 'none';
    } else {
        return toCurrencyString(leadCost);
    }
};
const generateItem = (label: string, value: string, gridSize: GridSize = 6) => (
    <Grid item xs={gridSize}>
        <ReadOnlyTextField label={label} value={value} />
    </Grid>
);

interface Props {
    lead: Lead;
}
const LeadInfo: React.FC<Props> = ({ lead }) => {
    const vendor = lead.vendor || 'none';
    const leadType = lead.leadType || 'none';
    const campaign = lead.campaign || 'none';
    const fund = lead.fund || '';
    const leadCost = parseLeadCost(lead.leadCost);
    const isLeadCostRefunded = getYesNoFromBool(lead.isLeadCostRefunded);

    const inputSource = lead.inputSource || 'none';
    const leadLmsId = lead.lmsId || 'none';
    const rating = lead.opportunityRatingCode;
    const quoteId = lead.quoteId || 'none';

    return (
        <Grid container>

            {!lead.fund && generateItem('Vendor', vendor)}
            {generateItem('Lead Type', leadType)}
            {generateItem('Lead Cost', leadCost)}
            {generateItem('Lead Credit Received?', isLeadCostRefunded)}
            {generateItem('Input Source', inputSource, 12)}
            {lead.fund
                ? generateItem('Fund', fund)
                : generateItem('Campaign', campaign)
            }
            {generateItem('Rating', rating)}
            {generateItem('Lead Id', leadLmsId, 12)}
            {generateItem('Quote Id', quoteId, 12)}
        </Grid>
    );
};

export const LeadInfoCard = wrapInCollapsibleCard(LeadInfo);
