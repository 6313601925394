import { AppState } from '../reducers/index';
import { createSelector } from 'reselect';
import { Loaded } from '../utilities/utilities';
import { Note } from '../reducers/note_reducer';
import moment from 'moment';
import _ from 'lodash';
import { getPage, getId, PageType } from './base_selectors';

/**
 * Note selectors
 */
const getNotes = (state: AppState) => state.note.notes || [];

export const getPageNotes = createSelector(
	[getPage, getId, getNotes],
	(page: PageType, id: string, notes: Loaded<Note>[]) => {
		notes.sort((a: Loaded<Note>, b: Loaded<Note>) => {
			return (
				moment(_.get(b.data, 'dateCreated')).valueOf() -
				moment(_.get(a.data, 'dateCreated')).valueOf()
			);
		});
		switch (page) {
			case 'HOUSEHOLD_PAGE': {
				return notes.filter(note => note.householdId == id);
			}
			case 'EMPLOYER_PAGE': {
				return notes.filter(note => note.employerId == id);
			}
			default:
				return notes;
		}
	}
);
