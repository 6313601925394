import React from 'react';
import { TextField, TextFieldProps, Typography } from '@material-ui/core';

export enum Fieldtype {
    Title,
    Field,
}

type TitleTextFieldProps = TextFieldProps & {
    fieldType?: Fieldtype;
    value?: string;
}

export const CustomTextField: React.SFC<TitleTextFieldProps> = (props) => {

    if (!props.disabled) {
        switch (props.fieldType) {

        }

    } else {
        switch (props.fieldType) {

            case (Fieldtype.Title):
                return <Typography variant="h5" gutterBottom>{props.label + ": " + props.value}</Typography>;

            case (Fieldtype.Field):
                return <Typography variant="h4" gutterBottom>{props.value}</Typography>;

        }
    }

    return <TextField {...props} />;


}
