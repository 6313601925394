import React from 'react';
import { ReadOnlyTextField } from '../utility/read_only_text_field';
import { Agent } from '../../reducers/agent_reducer';
import { Grid } from '@material-ui/core';
import { wrapInCollapsibleCard } from '../higher_order_component/wrap_in_card';
import { Strings } from '../../assets/common/strings';

interface Props {
    agent: Agent;
}

const Name: React.FC<Props> = ({ agent }) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <ReadOnlyTextField
                    label={Strings.AboutMeLabels.Title}
                    value={agent.title || ''}
                />
            </Grid>
            <Grid item xs={5}>
                <ReadOnlyTextField
                    label={Strings.AboutMeLabels.FirstName}
                    value={agent.firstName || ''}
                />
            </Grid>
            <Grid item xs={1}>
                <ReadOnlyTextField
                    label={Strings.AboutMeLabels.MiddleInitial}
                    value={agent.middleInitial || ''}
                />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyTextField
                    label={Strings.AboutMeLabels.LastName}
                    value={agent.lastName || ''}
                />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyTextField
                    label={Strings.AboutMeLabels.PreferredName}
                    value={agent.preferredName || ''}
                />
            </Grid>

            <Grid item xs={6}>
                <ReadOnlyTextField
                    label={Strings.AboutMeLabels.Suffix}
                    value={agent.suffix || ''}
                />
            </Grid>
        </Grid>
    );
}

export const NameCard = wrapInCollapsibleCard(Name);