import React from 'react';
import {
	Dialog,
	DialogContent,
	DialogActions,
	Button,
} from '@material-ui/core';
import { themePalette } from '../../utilities/branding';
import { SaveCancelHeaderBarComponent } from '../Layout/SaveCancelHeaderBar';
import { LiveSearch } from '../search/live_search';
import { mapContactsToSearchResults } from '../utility/search_result';
import { Contact } from '../../reducers/ContactReducer';
import { fullName } from '../../assets/common/string_builders';
import { FabMenu } from '../nav/fab_menu';
import { DesktopPadding } from '../Layout/desktop_padding';

interface Props {
	companyName: string;
	contacts: Contact[];
	visible: boolean;
	loadMore: boolean;
	closeDialog: () => void;
	addContactToEmployer: (contactId: any) => void;
	handleSearch: (searchString: string) => void;
	resetSearch: () => void;
	navigateToAddEmployee: () => void;
	isLoading: boolean;
}
interface State {
	displayConfirmationDialog: boolean;
	selectedContact?: any;
}

export class AddExistingContactDialog extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			displayConfirmationDialog: false,
			selectedContact: undefined,
		};
	}

	selectContact = (contactId: string) => {
		const selectedContactIndex = this.props.contacts.findIndex(
			contact => contact.id == contactId
		);
		const selectedContact =
			selectedContactIndex > -1
				? Object.assign({}, this.props.contacts[selectedContactIndex])
				: undefined;
		this.setState({
			selectedContact,
		});
	};

	renderReassignConfirmationDialog = () => {
		const contactFullName = this.state.selectedContact
			? fullName(this.state.selectedContact)
			: '';
		
		return (
			<Dialog
				key={'add_contact_confirmation_dialog'}
				open={this.state.displayConfirmationDialog}
				onClose={() => this.setState({ displayConfirmationDialog: false })}
			>
				<DialogContent>
					Are you sure you want to add {contactFullName} to {this.props.companyName}?
				</DialogContent>
				<DialogActions>
					<Button
						color="secondary"
						onClick={() => this.setState({ displayConfirmationDialog: false })}
					>
						Cancel
					</Button>
					<Button
						color="primary"
						variant="contained"
						onClick={() => {
							this.state.selectedContact
								? this.props.addContactToEmployer(this.state.selectedContact.id)
								: {};
							this.setState({ displayConfirmationDialog: false });
						}}
						style={{ backgroundColor: themePalette.accept_button }}
					>
						Add
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	render() {
		const searchResults = mapContactsToSearchResults(this.props.contacts, {
			includeAgeInSecondaryText: true,
			showPrimaryText: false,
			useDefaultAddressIfNone: false
		});
		return (
			<Dialog
				id="addContactDialog"
				key="addContactDialog"
				open={this.props.visible}
				fullScreen
				PaperProps={{ style: { backgroundColor: themePalette.secondary_background } }}
			>
				<SaveCancelHeaderBarComponent
					onSave={() => this.setState({ displayConfirmationDialog: true })}
					isSaveDisabled={!this.state.selectedContact}
					saveText={'Add'}
					title="Find Person"
					onCancel={this.props.closeDialog}
					isLoading={this.props.isLoading}
				/>
				<DesktopPadding style={{ overflowY: 'scroll' }}>
					<LiveSearch
						results={searchResults}
						handleSearch={this.props.handleSearch}
						resetSearch={this.props.resetSearch}
						label={'Enter name, phone, email'}
						onSelect={this.selectContact}
						loadMore={this.props.loadMore}
						defaultMessage={'Click the + to add a new person...'}
						loading={this.props.isLoading}
					/>
				</DesktopPadding>
				<FabMenu
					onClick={this.props.navigateToAddEmployee}
					inDialog
				/>
				{this.renderReassignConfirmationDialog()}
			</Dialog>
		);
	}
}
