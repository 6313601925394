import React from 'react';
import { Grid } from '@material-ui/core';
import { ReadOnlyTextField } from '../utility/read_only_text_field';
import { isDefaultDate } from '../../utilities/date_util';
import { wrapInCollapsibleCard } from '../higher_order_component/wrap_in_card';

interface Props {
	applicationNumber: string;
	applicationDate: string;
	submittedDate: string;
}

const PolicyApplicationInformation: React.FC<Props> = (props) => {
	const applicationDate = isDefaultDate(props.applicationDate) ? "None" : props.applicationDate;
	const submittedDate = isDefaultDate(props.submittedDate) ? "None" : props.submittedDate;

	return (
		<Grid container>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Application Number"
						value={props.applicationNumber}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Application Date"
						value={applicationDate}
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ReadOnlyTextField
						label="Submitted Date"
						value={submittedDate}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export const PolicyApplicationInformationCard = wrapInCollapsibleCard(PolicyApplicationInformation);
