import React from 'react';
import { TabContext, TabPanel } from '@material-ui/lab';
import { Strings } from '../../assets/common/strings';
import { useHistory, useLocation } from 'react-router-dom';
import { LicenseListContainer } from './license/license';
import { DesktopPadding } from '../../components/Layout/desktop_padding';
import { AppointmentListContainer } from './appointment/appointment';
import { useStylesLicenseAppointment } from '../../utilities/agentLicenseAppointment_util';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { LicenseAppointmentHeader } from '../../components/agentLicenseAppointment/license_appointment_header';

const LicenseAppointmentPage = () => {

    const location = useLocation();
    
    const getTabValue = () => {
        switch (location.pathname) {
            case Strings.LicenseAppointment.LicenseRoute:
                return Strings.LicenseAppointment.LicenseTab
            case Strings.LicenseAppointment.AppointmentRoute:
                return Strings.LicenseAppointment.AppointmentTab
            default:
                return Strings.LicenseAppointment.LicenseTab
        }
    }

    const history = useHistory();

    const handleTabChange = (event, newTabValue) => {
        const newPath = newTabValue === Strings.LicenseAppointment.LicenseTab ? Strings.LicenseAppointment.LicenseRoute : Strings.LicenseAppointment.AppointmentRoute;
        if (history.location.pathname !== newPath) {
            history.push(newPath);
        }
    }

    const classes = useStylesLicenseAppointment();

    return (
        <>
        <TabContext
            value={getTabValue()}
        >
            <BasePageContainer
                topComponent={
                    <LicenseAppointmentHeader getTabValue={getTabValue} handleTabChange={handleTabChange}></LicenseAppointmentHeader>
                }
            >

                <TabPanel
                    className={classes.tabRoot}
                    value={Strings.LicenseAppointment.LicenseTab}
                    key={Strings.LicenseAppointment.LicenseTab}
                >
                    <DesktopPadding>
                        <LicenseListContainer></LicenseListContainer>
                    </DesktopPadding>
                </TabPanel>

                <TabPanel
                    value={Strings.LicenseAppointment.AppointmentTab}
                    key={Strings.LicenseAppointment.AppointmentTab}
                >
                    <DesktopPadding>
                        <AppointmentListContainer></AppointmentListContainer>
                    </DesktopPadding>
                </TabPanel>

            </BasePageContainer>

        </TabContext>
        </>
    );
}

export const LicenseAppointmentContainer = LicenseAppointmentPage;