import React from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import { Button, Icon, Grid } from '@material-ui/core';
import { copyToClipboard, isMobileDevice } from '../../utilities/is_mobile';
import { FetchClientConnectURL } from '../../actions/client_connect_actions';
import { normalizePhone } from '../../utilities/formatting/data_normalizations';
import { validatePhone, validateEmail } from '../../utilities/form_validation';
import { QueueSnackbar, SnackbarProps } from '../../actions/snackbar_actions';
import { Field, reduxForm } from 'redux-form';
import { getFormValues, isValid, reset } from 'redux-form';
import { PopulateSmsText, PopulateSmsPayload } from '../../actions/sequence/sms_sequence_actions';
import { FormTextField } from '../../utilities/forms';
import { getAppState } from '../..';
import { getSnackbarSuccessProps } from '../../utilities/snackbar_util';
import { ReadOnlyTextField } from '../../components/utility/read_only_text_field';

const FORM_NAME = 'ClientConnectShare';

const required = (value: string) =>
	value && value.trim().length > 0 ? undefined : 'This field is required.';

const formFields: any[] = [
	{
		name: 'FirstName',
		label: 'First Name',
		validate: [required],
		size: 6
	},
	{
		name: 'LastName',
		label: 'Last Name',
		validate: [required],
		size: 6
	},
	{
		name: 'PhoneNumber',
		label: 'Phone Number',
		validate: [required, validatePhone],
		format: normalizePhone,
		type: 'tel',
		size: 12
	},
	{
		name: 'Email',
		label: 'Email',
		validate: [required, validateEmail],
		size: 12
	},
	{
		name: 'Message',
		label: 'Personal Message (optional)',
		multiline: true,
		size: 12
	},
];

const initialFormValues = {
	Message:
		'My information is now at your fingertips. Please schedule an appointment or request a quote anytime!',
};


export interface ClientConnectShareFormValues {
	FirstName?: string;
	LastName?: string;
	PhoneNumber?: string;
	Email?: string;
	Message?: string;
}
interface ComponentProps {
	onSend?: () => void;
}
interface StateProps {
	agentName: string;
	agentCode: string;
	url?: string;
	formValues: ClientConnectShareFormValues;
	isValid: boolean;
}
interface DispatchProps {
	getClientConnectURL: () => void;
	queueSnackbar: (snackbarProps: SnackbarProps) => void;
	resetForm: () => void;
	sendSms: (payload: PopulateSmsPayload) => void;
}
type Props = ComponentProps & StateProps & DispatchProps;

class ShareCard extends React.Component<Props> {

	componentDidMount() {
		this.props.getClientConnectURL();
	}

	handleSubmit = () => {
		const { formValues, url, queueSnackbar, resetForm, onSend } = this.props;
		let formInputs = {
			firstName: formValues.FirstName || '',
			lastName: formValues.LastName || '',
			contactMethod:
				(isMobileDevice
					? formValues.PhoneNumber
					: formValues.Email) || '',
			message: formValues.Message || '',
		};
		if (isMobileDevice) {
			this.openSMS(
				formInputs.contactMethod,
				formInputs.firstName,
				url || '',
				formInputs.message
			);
		} else {
			this.openEmail(
				formInputs.contactMethod,
				formInputs.firstName,
				url || '',
				formInputs.message
			);
		}

		queueSnackbar(getSnackbarSuccessProps(`Opened ${isMobileDevice ? 'SMS' : 'Email'}`, 10000));
		resetForm();
		onSend?.();
	};

	openSMS(
		phoneNumber: string,
		firstName: string,
		url: string,
		personalMsg?: string
	) {
		const message =
			firstName +
			', this link will take you to my mobile app.\n' +
			(personalMsg ? personalMsg + '\n' : '') +
			'\n' +
			'Thank you,\n' +
			this.props.agentName +
			'\n' +
			url;

		this.props.sendSms({
			phoneNumber,
			content: message
		});
	}

	openEmail(
		email: string,
		firstName: string,
		url: string,
		personalMsg?: string
	) {
		const subject = 'Check out my mobile app';
		const message =
			firstName +
			', this link will take you to my mobile app.\n' +
			(personalMsg ? personalMsg + '\n' : '') +
			'\n' +
			'Thank you,\n' +
			this.props.agentName +
			'\n' +
			url;
		const mailToLink =
			'mailto:' +
			email +
			'?subject=' +
			subject +
			'&body=' +
			encodeURIComponent(message);
		window.location.href = mailToLink;
	}

	render() {
		return (
			<Grid container spacing={2}>
				{formFields.map((field) => {
					if (field.name !== (isMobileDevice ? 'Email' : 'PhoneNumber')) {
						const { size, ...props } = field;
						return (
							<Grid key={field.name} item xs={12} lg={size}>
								<Field
									component={FormTextField}
									fullWidth={true}
									{...props}
								/>
							</Grid>
						);
					} else {
						return null;
					}
				})}

				<Button
					disabled={!this.props.isValid}
					variant="contained"
					color="primary"
					fullWidth
					disableElevation
					onClick={this.handleSubmit}
				>
					Send
				</Button>

				{this.props.url && (
					<Grid container item xs={12} alignItems="center">
						<Grid item xs={9}>
							<ReadOnlyTextField
								value={this.props.url || ''}
								label="Share URL"
							/>
						</Grid>
						<Grid container item xs={3} justify="center">
							<Button
								variant="contained"
								color="default"
								disableElevation
								onClick={() => {
									copyToClipboard(this.props.url || '');
									this.props.queueSnackbar(getSnackbarSuccessProps('Copied URL to clipboard', 2000));
								}}
							>
								Copy
								<Icon>content_copy</Icon>
							</Button>
						</Grid>
					</Grid>
				)}
			</Grid>
		);
	}
}

const mapStateToProps = (state): StateProps => ({
	agentName: state.agent.preferredName || state.agent.firstName,
	agentCode: state.user.agentID,
	url: state.clientConnect.url,
	formValues: getFormValues(FORM_NAME)(state) as {
		FirstName: string;
		LastName: string;
		PhoneNumber: string;
		Message: string;
	},
	isValid: isValid(FORM_NAME)(state),
});
const mapDispatchToProps = (dispatch: any): DispatchProps => ({
	getClientConnectURL: () => dispatch(FetchClientConnectURL()),
	queueSnackbar: (snackbarProps: SnackbarProps) =>
		dispatch(QueueSnackbar(snackbarProps)),
	resetForm: () => dispatch(reset(FORM_NAME)),
	sendSms: (payload: PopulateSmsPayload) => dispatch(PopulateSmsText(payload))
});

const ClientConnectShareContainer = connect(mapStateToProps, mapDispatchToProps, true)(ShareCard);

const ClientConnectShareForm = reduxForm({
	form: FORM_NAME,
	initialValues: initialFormValues,
	getFormState: (state) => getAppState().form,
})(ClientConnectShareContainer);

export default ClientConnectShareForm as React.ComponentType<ComponentProps & { initialValues: any; }>;
