import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import moment from 'moment';
import { Strings } from '../../assets/common/strings';
import { TeamViewParams, GetProfessionalAgents } from '../../actions/teamview_actions';
import { ProductivityTableCard } from '../../components/teamview/productivity_table_card';
import { ReportCard } from '../../components/utility/report_card';

export const ProfessioalAgentsReport: React.FC<{params: TeamViewParams}> = props => {
    const dispatch = useDispatch();

    const reportData =  useSelector((state: any) => state.App.teamview.professionalAgentsCard, shallowEqual);

    useEffect(() => {
        dispatch(GetProfessionalAgents.started(props.params))
    }, [props.params]);

    const month = moment().subtract(1, 'months').format('MMMM');

    return (
        <>
            {reportData?.isLoading
            ? (
                <ReportCard
                    color="tertiary"
                    titleLeft={Strings.TeamView.PROFESSIONAL_AGENTS}
                    isLoading
                />)
            : (
                <ProductivityTableCard
                    rows={reportData?.cardTable}
                    color="tertiary"
                    titleLeft={`${month} ${Strings.TeamView.PROFESSIONAL_AGENTS}`}
                    rowsToHighlight={[2]}
                />)}
        </>
    );
};
