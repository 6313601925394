import { HouseholdMetaData } from './../reducers/household_reducer';
import { Contact, HouseholdRole, Address } from './../reducers/ContactReducer';
import { Lead } from './../reducers/LeadReducer';
import { Lookup, Lookups } from './lookup';
import { Employer } from '../reducers/employer_reducer';
import { Activity } from '../reducers/activity_reducer';
import { Loaded } from './utilities';
import { Battle, BattleItemStatuses } from '../reducers/battles_reducer';
import { DownlineAgent } from '../reducers/product_reducer';
import { Success, Failure } from 'typescript-fsa';
import { get } from './object_util';
import { Strings } from '../assets/common/strings';

export const loaded = <T extends object>(
	data: T,
	meta?: { householdId?: string; employerId?: string }
): Loaded<T> => {
	return {
		data: { ...(data as object) } as T,
		loading: false,
		errors: [],
		householdId: get(() => meta!.householdId, ''),
		employerId: get(() => meta!.employerId, ''),
	};
};

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

export const EMPTY_LEAD: Lead = {
	id: '',
	userId: '',
	createdOn: new Date(),
	createdBy: '',
	updatedOn: new Date(),
	updatedBy: '',
	isDeleted: false,
	contacts: [],
	linesOfBusiness: [],
	employer: {} as Employer,
	assetOwnerId: '',
	opportunityRatingCode: '',
	sourceOther: '',
	status: Strings.Status.NotContacted,
	statusCode: Strings.Status.Open,
	inputSource: '',
	leadTypeOther: '',
	vendorOther: '',
	leadType: '',
	vendor: '',
	campaign: '',
	lmsId: '',
	isCompanyLead: false,
	isLeadCostRefunded: false,
	marketingRefCode: '',
	marketoOppId: '',
	quoteId: '',
	priorCarrierName: '',
	isPriorCoverage: false,
	reasonforChange: '',
	dialerCallId: '',
	dialerCallType: '',
	dialerDisposition: '',
	telephonicSoaCompleted: true,
	householdId: '',
	createdByName: '',
};

export const EMPTY_CONTACT: Contact = {
	id: '',
	userId: '',
	createdOn: new Date(),
	createdBy: '',
	updatedOn: new Date(),
	updatedBy: '',
	isDeleted: false,
	phones: [],
	emails: [],
	addresses: [],
	notes: [],
	employerId: '',
	salutation: '',
	jobTitle: '',
	firstName: '',
	preferredName: '',
	middleName: '',
	lastName: '',
	suffix: '',
	birthDate: new Date(),
	gender: '',
	weightLbs: -1,
	heightFt: -1,
	heightIn: -1,
	employeeStatus: '',
	annualIncome: -1,
	familyStatusCode: '',
	doNotPostalMail: false,
	clientType: '',
	combinedMarginalTaxRate: -1,
	occupation: '',
	isSelfEmployed: false,
	isTobaccoUser: false,
	status: 'Active',
	vueInsuredId: '',
	isStudent: false,
	assetOwnerId: '',
	allowTexts: false,
	languagePreference: '',
	isEnglishSpeaker: false,
	annualHouseHoldIncome: -1,
	federalSubsidy: -1,
	marketoMunchkinId: '',
	isSyncToMarketo: false,
	marketoLeadId: '',
	empGrossContribution: -1,
	empNetContribution: -1,
	employerPrimaryContact: false,
	dateOfHire: new Date(),
	groupEligibilityStatus: '',
	groupEnrollmentStatus: '',
	numberOfFamilyMembers: 0,
	householdId: '',
	householdRole: HouseholdRole.Primary,
	rpmHouseholdId: '',
	rpmIndividualId: '',
	rpmResidenceId: '',
	attachments: [],
	householdOwnershipHistory: [],
	wasRpmNameChanged: false,
	unsubscribed: false,
	unsubscribedReason: '',
	unsubscribedDate: new Date(),
	lastFourSsn: '',
	lastUnemploymentYear: '',
};

export const EMPTY_ACTIVITY: Activity = {
	id: '',
	userId: '',
	createdOn: new Date(),
	createdBy: '',
	updatedOn: new Date(),
	updatedBy: '',
	modifiedOn: new Date(),
	modifiedBy: '',
	isDeleted: false,
	contact: EMPTY_CONTACT,
	lead: '',
	title: '',
	description: '',
	time: new Date(),
	duration: 'none',
	isHighPriority: false,
	createdByName: '',
	modifiedByName: '',
	completedOn: new Date(),
	completedBy: '',
	completedByName: '',
	location: '',
	alternatePhoneNumber: '',
	status: Strings.ActivityStatus.Open,
	disposition: '',
	type: Strings.Activity.Appointment,
	googleEventId: '',
	isAutomatedEmail: false,
	isAllDayEvent: false,
	sender: '',
	toRecipients: '',
	appointmentType: '',
	direction: false,
	htmlContent: '',
	subject: '',
	twilioSid: '',
	clickToCallDisposition: '',
	clickToCallSessionId: undefined
};

export const EMPTY_HOUSEHOLD_META_DATA: HouseholdMetaData = {
	id: '',
	createdOn: new Date(),
	createdBy: '',
	updatedOn: new Date(),
	updatedBy: '',
	updatedByName: '',
	createdByName: '',
};

export const EMPTY_EMPLOYER: Employer = {
	id: '',
	userId: '',
	createdOn: new Date(),
	createdBy: '',
	updatedOn: new Date(),
	updatedBy: '',
	isDeleted: false,
	owningUser: '',
	accountNumber: '',
	isAncillariesOffered: false,
	clientType: '',
	groupCoverageAdvantage: '',
	currentCarrier: '',
	employerContributionAmount: -1,
	employerContributionBaseAmount: -1,
	isEmployerContributesToGroupCoverage: false,
	isFamilyCoverageOffered: false,
	isGroupPlanOffered: false,
	marketoCompanyId: '',
	marketoLastUpdated: new Date(),
	numberOfGroupEligibles: -1,
	numberOfGroupParticipants: -1,
	numberOfPartTime: -1,
	isAge65OrAboveAllowed: false,
	purchased: '',
	renewalDate: new Date(),
	state: '',
	isSyncedToMarketo: false,
	leadSource: '',
	vueInsuredId: '',
	description: '',
	industry: '',
	companyName: '',
	numberOfEmployees: -1,
	annualRevenue: -1,
	sicCode: '',
	website: '',
	contacts: [],
	updatedByName: '',
	createdByName: '',
};

export const EMPTY_DOWNLINE_AGENT: DownlineAgent = {
	agentCode: '',
	firstName: '',
	lastName: '',
	preferredName: '',
	state: '',
	zip: '',
	city: '',
	channel: '',
	image: '',
	id: '',
};

export const EMPTY_ADDRESS: Address = {
	type: '',
	line1: '',
	line2: '',
	city: '',
	state: '',
	zipCode: '',
	county: '',
	country: '',
	isPreferred: false,
	wasRpmAddressChanged: false,
	dateCreated: new Date(),
};

export const EMPTY_BATTLE: Battle = {
	id: '',
	title: '',
	sendingUser: EMPTY_DOWNLINE_AGENT,
	receivingUser: EMPTY_DOWNLINE_AGENT,
	status: BattleItemStatuses.new,
	startTime: '',
	endTime: '',
	metric: '',
	lineOfBusiness: '',
	messages: []
}

export const EMPTY_SUCCESS: Success<{}, {}> = {
	params: {},
	result: {}
};

export const EMPTY_FAILURE: Failure<{}, any> = {
	params: {},
	error: undefined,
}