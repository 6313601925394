import React from 'react';
import {
	LinearProgress,
	Card,
	CardContent,
	CardActions,
	Grid,
	Button,
	List,
	GridSize,
	Typography,
} from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import Chart from './chart';
import { SliderViewDots } from './slider_view_dots';
import { themePalette, themeImages } from '../../utilities/branding';
import { IChart, ChartDetail } from '../../reducers/performance_reducer';
import ExpandableListItem from './expandable_list_item';
import { ReadOnlyTextField } from './read_only_text_field';

export type ChartCardProps = {
	color: 'primary' | 'secondary' | 'tertiary';
	loading?: boolean;
	details?: boolean;
	detailData: ChartDetail[];
	titleLeft: string;
	titleRight?: string;
	charts: IChart[];
	clickDisabled?: boolean;
	onClick?: () => void;
	flat?: boolean;
	style?: React.CSSProperties;
	chartStyle?: React.CSSProperties;
	compressed?: boolean;
	tooltip?: JSX.Element;
};

type Props = ChartCardProps;

type State = {
	step: number;
};

class ChartCard extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			step: 0,
		};
	}

	setStep = (index: number) => {
		this.setState({ step: index });
	};

	render() {
		const { style = {}, chartStyle } = this.props;
		let cardContentStyle: React.CSSProperties = {},
			summaryBoxStyle: React.CSSProperties = {};
		if (this.props.compressed) {
			cardContentStyle = { paddingTop: 0, paddingBottom: 36 };
			summaryBoxStyle = { marginTop: 0, height: 20 };
		}
		let activeColor = themePalette.bubbles_primary,
			activeBackgroundImage = themeImages.bubblesPrimary;
		if (this.props.color === 'secondary') {
			activeColor = themePalette.bubbles_secondary;
			activeBackgroundImage = themeImages.bubblesSecondary;
		} else if (this.props.color === 'tertiary') {
			activeColor = themePalette.bubbles_tertiary;
			activeBackgroundImage = themeImages.bubblesTertiary;
		}

		const charts = this.props.charts
			? this.props.charts.filter(chartData =>
				(!chartData.summaryValue || (chartData.summaryValue && chartData.summaryValue !== 'None')))
			: [];
		let gridSize: GridSize = 4;
		if (charts.length === 1) {
			gridSize = 12;
		}
		else if (charts.length === 2) {
			gridSize = 6;
		}

		return (
			<Card style={style} elevation={this.props.flat ? 0 : undefined}>
				{this.props.loading && <LinearProgress />}

				<Grid
					container
					style={{ padding: 16 }}
					onClick={!this.props.clickDisabled ? this.props.onClick : undefined}
				>
					<Grid item xs>
						<Typography variant="body2">{this.props.titleLeft}</Typography>
					</Grid>
					<Grid item xs style={{ textAlign: 'right' }}>
						<Typography variant="body2">{this.props.titleRight}</Typography>
					</Grid>
				</Grid>

				<div style={{
					...baseBackgroundStyle,
					backgroundColor: activeColor,
					backgroundImage: `url('${activeBackgroundImage}')`,
				}}>
					<SwipeableViews
						onChangeIndex={this.setStep}
						index={this.state.step}
						enableMouseEvents
					>
						{this.props.charts && this.props.charts.map(chartData => (
							<Chart
								key={chartData.title}
								title={chartData.title}
								xAxis={chartData.data}
								style={chartStyle}
								unit={chartData.unit}
								infinityValue={chartData.infinityValue}
							/>
						))}
					</SwipeableViews>
					<SliderViewDots
						activeColor={activeColor}
						activeIndex={this.state.step}
						size={this.props.charts ? this.props.charts.length : 0}
						handleChangeIndex={this.setStep}
					/>
				</div>

				<CardContent
					style={cardContentStyle}
				>
					<Grid container>
						{this.props.details
							? this.renderDetails()
							: charts.map((chartData, index) => (
									<Grid key={chartData.title} item xs={gridSize} style={summaryBoxStyle}>
										<ReadOnlyTextField
											label={this.props.compressed 
												? chartData.title.slice(0, 12).concat(chartData.title.length > 11 ? '...' : '')
												: chartData.title
											}
											value={chartData.summaryValue || '-'}
										/>
									</Grid>
								))}
					</Grid>
				</CardContent>
				{this.props.onClick && (
					<CardActions>
						<Button
							onClick={this.props.onClick}
							disabled={this.props.clickDisabled}
						>
							View Details
						</Button>
						{this.props.tooltip && !this.props.details ? this.props.tooltip : null}
					</CardActions>
				)}
			</Card>
		);
	}

	formatDetailValue = (value: string) => {
		if (!value || value === '$-1' || value === '-') {
			return '--';
		}
		return value;
	};

	getExpandableItem = (detail: ChartDetail, depth: number = 0): JSX.Element => {
		return (
			<ExpandableListItem
				key={detail.label}
				title={detail.label}
				value={this.formatDetailValue(detail.total)}
				expandable={detail.data ? !!detail.data.length : false}
				level={depth}
				secondaryValue={detail.secondary}
			>
				{(detail.data || []).map(deet =>
					this.getExpandableItem(deet, depth + 1)
				)}
			</ExpandableListItem>
		);
	};

	renderDetails = () => {
		return (
			<List style={{ ...cardListStyle }} component="nav">
				{this.props.detailData.map(detailData => (
					this.getExpandableItem(detailData, 0)
				))}
			</List>
		);
	};
}

export default ChartCard;

// STYLES
const baseBackgroundStyle: React.CSSProperties = {
	backgroundRepeat: 'no-repeat',
	backgroundSize: '150%',
};
const cardListStyle: React.CSSProperties = {
	backgroundColor: 'white',
	padding: 0,
	borderBottomColor: themePalette.divider,
	borderBottomWidth: 1,
	borderBottomStyle: 'solid',
	color: themePalette.sub_text,
	width: '100%',
};
