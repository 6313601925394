import React from 'react';
import { Agent } from '../../reducers/agent_reducer';
import { ReadOnlyTextField } from '../utility/read_only_text_field';
import { wrapInCollapsibleCard } from '../higher_order_component/wrap_in_card';
import { Grid } from '@material-ui/core';
import { getGenericBio } from '../../utilities/agent_util';

interface Props {
    agent: Agent;
}
const Bio: React.FC<Props> = ({ agent }) => {
    return (
        <Grid container>
            <ReadOnlyTextField
                multiline
                value={agent?.isLoading ? '' : agent?.bio || getGenericBio(agent)}
            />
        </Grid>
    );
};

export const BioCard = wrapInCollapsibleCard(Bio);