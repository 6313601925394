import { Action as ReduxAction } from 'redux';
import { isType } from 'typescript-fsa';
import _ from 'lodash';
import {
	CreateContact,
	GetContact,
	UpdateContact,
	DeleteContact,
	ContactLiveSearch,
	AddContactToEmployer,
	SetApplyAddressToHousehold,
	ResetApplyAddressToHousehold,
} from '../actions/contact_actions';
import {
	GetEmployer,
	UpdateEmployer,
	SetPrimaryEmployee,
	DeleteEmployer,
	ReassignEmployer,
	SearchEmployers,
} from '../actions/employer_actions';
import { Loaded } from '../utilities/utilities';
import BaseDTO from '../utilities/base_dto';
import {
	GetHousehold,
	GetHouseholdContacts,
	SetNewPrimaryContactOnHousehold,
	RemoveContactFromHousehold,
	ReassignHousehold,
} from '../actions/household_actions';
import { Note } from './note_reducer';
import { Attachment } from './attachment_reducer';
import { OwnershipHistory } from './ownership_history_reducer';
import { ClearCaches } from '../actions/authentication_actions';
import { GetFilteredLeads, BulkReassign } from '../actions/lead_actions';
import { Logout } from '../actions/authentication_actions';
import { Strings } from '../assets/common/strings';

export enum HouseholdRole {
	Primary,
	Secondary,
	Dependent,
}

export const mapEnumToHouseholdRole = (
	enumValue?: HouseholdRole
) => {
	if (enumValue === HouseholdRole.Primary) {
		return 'Primary';
	} else if (enumValue === HouseholdRole.Secondary) {
		return 'Spouse/Partner';
	} else if (enumValue === HouseholdRole.Dependent) {
		return 'Dependent';
	} else {
		return 'Household Role Not Defined';
	}
};

export interface Address extends PreferredItem {
	id?: string;
	line1: string;
	line2: string;
	city: string;
	state: string;
	stateId?: string;
	zipCode: string;
	county: string;
	country: string;
	wasRpmAddressChanged: boolean;
	isDeleted?: boolean;
	createdBy?: string;
	updatedBy?: string;
	userId?: string;
	createdOn?: string;
	updatedOn?: string;
}
export interface HouseholdAddress { [type: string]: Address | undefined };

export interface Email extends PreferredItem {
	emailAddress: string;
	doNotEmail: boolean;
	emailUndeliverableDate?: Date;
	unsubscribeDate?: Date;
}

export interface Phone extends PreferredItem {
	id: string;
	number: string;
	extension: string;
	type: Strings.PhoneTypes;
	isNationalDnc: boolean;
	isCompanyDnc: boolean;
	wasCompanyDncSet: boolean;
	wasRpmPhoneNumberChanged: boolean;
	gracePeriodEndDate?: Date;
	isSmsDnc: boolean;
}
export interface PreferredItem {
	type: string;
	isPreferred: boolean;
	dateCreated: Date;
}

export interface Owner {
	id: string;
}

interface BaseContact<MedicareInfoType> extends BaseDTO {
	phones: Phone[];
	emails: Email[];
	addresses: Address[];
	notes: Note[];
	employerId: string;
	salutation: string;
	jobTitle: string;
	firstName: string;
	preferredName: string;
	middleName: string;
	lastName: string;
	suffix: string;
	birthDate?: Date;
	gender: string;
	weightLbs: number;
	heightFt: number;
	heightIn: number;
	employeeStatus?: string;
	annualIncome?: number;
	familyStatusCode: string;
	doNotPostalMail: boolean;
	clientType?: string;
	combinedMarginalTaxRate?: number;
	occupation: string;
	isSelfEmployed: boolean;
	isTobaccoUser: boolean;
	status: string;
	vueInsuredId: string;
	isStudent: boolean;
	assetOwnerId: string;
	allowTexts: boolean;
	languagePreference: string;
	isEnglishSpeaker: boolean;
	annualHouseHoldIncome?: number;
	federalSubsidy?: number;
	marketoMunchkinId: string;
	isSyncToMarketo: boolean;
	marketoLeadId: string;
	empGrossContribution?: number;
	empNetContribution?: number;
	employerPrimaryContact: boolean;
	dateOfHire?: Date;
	groupEligibilityStatus?: string;
	groupEnrollmentStatus?: string;
	numberOfFamilyMembers?: number;
	numberOfHouseholdMembers?: number;
	householdId: string;
	householdRole: HouseholdRole;
	rpmHouseholdId: string;
	rpmIndividualId: string;
	rpmResidenceId: string;
	attachments: Attachment[];
	householdOwnershipHistory: OwnershipHistory[];
	wasRpmNameChanged: boolean;
	unsubscribed?: boolean;
	unsubscribedReason: string;
	unsubscribedDate?: Date;
	medicareInfo?: MedicareInfoType;
	lastFourSsn: string;
	lastUnemploymentYear?: string;
}

export type Contact = BaseContact<MedicareInfo>;
export type ContactDto = BaseContact<string>;

export interface Contacts {
	contacts: Loaded<Contact>[];
	isLoading: boolean;
	householdAddresses: HouseholdAddress;
	pageSize: number;
	pageNumber: number;
}

interface BaseMedicareInfo<DateType> {
	Disabled: boolean;
	MedicareNumber: string;
	MedicareOptions: MedicareOptions;
	PartAEffectiveDate?: DateType;
	PartBEffectiveDate?: DateType;
}

export type MedicareInfo = BaseMedicareInfo<Date>;
export type MedicareInfoDto = BaseMedicareInfo<string>;

export interface MedicareOptions {
	Lis: LisAmountEnum;
	Medications: Array<Medication>;
	Providers: Array<Provider>;
	Pharmacy?: Pharmacy;
	Pharmacies?: Array<Pharmacy>;
	RefillType: RefillTypeEnum;
}

export enum LisAmountEnum {
	Undefined = "Undefined",
	LTE100 = "LTE100",
	GT100 =  "GT100",
	BT136_149_0 = "BT136_149_0",
	BT136_149_25 = "BT136_149_25",
	BT136_149_50 = "BT136_149_50",
	BT136_149_75 = "BT136_149_75",
	LIS_PAYS_IDK = "LIS_PAYS_IDK",
	LIS_NONE = "LIS_NONE",
	LIS_IDK = "LIS_IDK",
}

export enum RefillTypeEnum {
	Undefined = "Undefined",
	MailOrder = "MailOrder",
	LocalPharmacy = "LocalPharmacy",
}

export interface Medication {
	Id: number;
	Name: string;
	Qty: number;
	Strength: string;
	StrengthUOM: string;
	Form: string;
	Frequency: DosageFrequencyEnum;
	Alt: boolean;
	PkgId: number;
	Pm: number;
	Packages: Array<Package>;
	ParentName: string;
	ParentId: number;
};

export enum DosageFrequencyEnum {
	Monthly = 30,
	EveryTwo = 60,
	EveryThree = 90,
	EveryYear = 365,
};

export interface Package {
	PkgId: number;
	PkgDesc: string;
	Pm: number;
};

export interface Provider {
	ProviderIds: Array<number>;
	ProviderId: number;
	FirstName: string;
	LastName: string;
	MiddleName: string;
	Suffix: string;
	Degrees: Array<string>;
	Gender: string;
	FacilityName: string;
	AllSpecialties: string;
	Locations: Array<ProviderLocation>;
	SelectedLocation: ProviderLocation;
};

export interface ProviderLocation {
	ProviderLocationId: number;
	Address: string;
	Address2: string;
	City: string;
	State: string;
	Zip: string;
	Latitude: number;
	Longitube: number;
	Languages: Array<ProviderLanguage>;
	Distance: string;
	PhoneNumber: string;
	PhoneExtension: string;
	NetworkInfoCompressed: string;
	IsSelectedLocation: string;
};

export interface ProviderLanguage {
	Id: number;
	Description: string;
};

export interface Pharmacy {
	Id: string;
	Name: string;
	Address1: string;
	Address2: string;
	City: string;
	State: string;
	Zip: number;
	Distance: number;
	MailOrder: boolean;
};

const initialState: Contacts = {
	contacts: [],
	isLoading: false,
	householdAddresses: {},
	pageNumber: 0,
	pageSize: 20,
};

export function contactReducer(
	state: Contacts = initialState,
	action: ReduxAction
): Contacts {
	if (isType(action, GetContact.started)) {
		const contactId = action.payload;
		const contactsInState = state.contacts.slice();
		upsertContactStart(contactsInState, { id: contactId } as Contact);
		return {
			...state,
			contacts: contactsInState,
		};
	} else if (
		isType(action, SetNewPrimaryContactOnHousehold.started) ||
		isType(action, RemoveContactFromHousehold.started) ||
		isType(action, SetPrimaryEmployee.started)
	) {
		const contactId = action.payload.contactId;
		const contactsInState = state.contacts.slice();
		upsertContactStart(contactsInState, { id: contactId } as Contact);
		return {
			...state,
			contacts: contactsInState,
		};
	} else if (
		isType(action, ContactLiveSearch.started) ||
		isType(action, GetHouseholdContacts.started) ||
		isType(action, GetHousehold.started)
	) {
		return {
			...state,
			isLoading: true,
		};
	} else if (
		isType(action, ContactLiveSearch.done) ||
		isType(action, GetHouseholdContacts.done)
	) {
		const contactsInState = state.contacts.slice();
		updateStateWithInboundContacts(contactsInState, action.payload.result);
		return {
			...state,
			contacts: contactsInState,
			isLoading: false,
		};
	} else if (
		isType(action, ContactLiveSearch.failed) ||
		isType(action, GetHouseholdContacts.failed)
	) {
		return {
			...state,
			isLoading: false,
		};
	} else if (
		isType(action, DeleteContact.started) ||
		isType(action, UpdateContact.started) ||
		isType(action, CreateContact.started) ||
		isType(action, AddContactToEmployer.started)
	) {
		const contact = action.payload;
		const contactsInState = state.contacts.slice();
		upsertContactStart(contactsInState, contact);
		return {
			...state,
			isLoading: true,
			contacts: contactsInState,
		};
	} else if (
		isType(action, UpdateContact.done) ||
		isType(action, CreateContact.done) ||
		isType(action, AddContactToEmployer.done)
	) {
		const contact = action.payload.result;
		const contactsInState = state.contacts.slice();
		upsertContactDone(contactsInState, contact, action.payload
			.params as Contact);
		return {
			...state,
			isLoading: false,
			contacts: contactsInState,
		};
	} else if (isType(action, GetContact.done)) {
		const contactDto = action.payload.result;
		const contact = getContactFromDto(contactDto);

		const contactsInState = state.contacts.slice();
		upsertContactDone(contactsInState, contact);
		return {
			...state,
			contacts: contactsInState,
		};
	} else if (isType(action, DeleteContact.done)) {
		const contactId = action.payload.result;
		const contactsInState = state.contacts.slice();
		const indexToRemove = getMatchingContactIndex(contactsInState, contactId);
		contactsInState.splice(indexToRemove, 1);

		return {
			...state,
			contacts: contactsInState,
			isLoading: false,
		};
	} else if (isType(action, GetContact.failed)) {
		const contactId = action.payload.params;
		const contactsInState = state.contacts.slice();
		upsertContactDone(
			contactsInState,
			{ id: contactId } as Contact,
			undefined,
			action.payload.error
		);
		return {
			...state,
			contacts: contactsInState,
			isLoading: false
		};
	} else if (
		isType(action, SetNewPrimaryContactOnHousehold.failed) ||
		isType(action, RemoveContactFromHousehold.failed) ||
		isType(action, SetPrimaryEmployee.failed)
	) {
		const contactId = action.payload.params.contactId;
		const contactsInState = state.contacts.slice();
		upsertContactDone(
			contactsInState,
			{ id: contactId } as Contact,
			undefined,
			action.payload.error
		);
		return {
			...state,
			contacts: contactsInState,
		};
	} else if (
		isType(action, DeleteContact.failed) ||
		isType(action, CreateContact.failed) ||
		isType(action, UpdateContact.failed) ||
		isType(action, AddContactToEmployer.failed)
	) {
		const contact = action.payload.params;
		const contactsInState = state.contacts.slice();
		upsertContactDone(
			contactsInState,
			contact,
			undefined,
			action.payload.error
		);
		return {
			...state,
			isLoading: false,
			contacts: contactsInState,
		};
	} else if (
		isType(action, GetEmployer.done) ||
		isType(action, UpdateEmployer.done) ||
		isType(action, GetHousehold.done) ||
		isType(action, SetNewPrimaryContactOnHousehold.done)
	) {
		const contactsInState = state.contacts.slice();
		const newContacts = action.payload.result.contacts;
		updateStateWithInboundContacts(contactsInState, newContacts);
		if (isType(action, UpdateEmployer.done))
			unlinkOldEmployees(contactsInState, newContacts);

		return {
			...state,
			contacts: contactsInState,
			isLoading: false,
		};
	} else if (isType(action, GetFilteredLeads.done)) {
		const contactsInState = state.contacts.slice();
		action.payload.result.forEach(lead => {
			updateStateWithInboundContacts(contactsInState, lead.contacts);
		});
		return {
			...state,
			contacts: contactsInState,
			isLoading: false,
		};
	} else if (isType(action, SetPrimaryEmployee.done)) {
		const { employerId, contactId } = action.payload.params;
		const contactsInState = state.contacts.slice();
		updateStateWithNewPrimaryEmployee(contactsInState, employerId, contactId);
		return {
			...state,
			contacts: contactsInState,
		};
	} else if (isType(action, DeleteEmployer.done)) {
		const contactsInState = state.contacts.filter(
			contact => contact.employerId !== action.payload.params
		);
		return {
			...state,
			contacts: contactsInState,
		};
	} else if (isType(action, RemoveContactFromHousehold.done)) {
		const contactsInState = state.contacts.filter(
			contact => contact.data.id !== action.payload.params.contactId
		);
		return {
			...state,
			contacts: contactsInState,
		};
	} else if (isType(action, ReassignHousehold.done)) {
		const oldContacts = state.contacts.slice();
		const newContacts = oldContacts.filter(contact => {
			return contact.householdId != action.payload.params.householdId;
		});
		return {
			...state,
			contacts: newContacts,
		};
	} else if (isType(action, ReassignEmployer.done)) {
		const oldContacts = state.contacts.slice();
		const newContacts = oldContacts.filter(contact => {
			return contact.employerId != action.payload.params.employerId;
		});
		return {
			...state,
			contacts: newContacts,
		};
	} else if (isType(action, SearchEmployers.done)) {
		const contactsInState = state.contacts.slice();
		let newContacts: Contact[] = [];
		action.payload.result.forEach(employer => {
			newContacts = newContacts.concat(employer.contacts);
		});
		updateStateWithInboundContacts(contactsInState, newContacts);
		return {
			...state,
			contacts: contactsInState,
			isLoading: false,
		};
	} else if (isType(action, BulkReassign.done)) {
		const contactsInState = state.contacts.slice();
		const filteredContacts = contactsInState.filter(contact =>
			action.payload.params.entities.some(
				entity =>
					entity.id != contact.householdId &&
					entity.id != contact.employerId &&
					entity.id != contact.data.householdId &&
					entity.id != contact.data.employerId
			)
		);
		return {
			...state,
			contacts: filteredContacts,
		};
	} else if (isType(action, SetApplyAddressToHousehold)) {
		return {
			...state,
			householdAddresses: {
				...state.householdAddresses,
				...action.payload,
			},
		};
	} else if (isType(action, ResetApplyAddressToHousehold)) {
		return {
			...state,
			householdAddresses: {},
		};
	} else if (isType(action, Logout.started)) {
		return {
			...initialState,
		};
	} else if (isType(action, ClearCaches)) {
		return {
			...initialState,
		};
	} else {
		return state;
	}
}

export function getMatchingContactIndex(
	contactsToLookThrough: Loaded<Contact>[],
	contactId: string
) {
	return _.findIndex(contactsToLookThrough, contact => {
		return contact.data.id.toLowerCase() === contactId.toLowerCase();
	});
}

function upsertContactStart(
	cachedContacts: Loaded<Contact>[],
	contact: Contact
) {
	const matchedContactIndex = getMatchingContactIndex(
		cachedContacts,
		contact.id
	);
	if (matchedContactIndex !== -1) {
		cachedContacts[matchedContactIndex] = {
			...cachedContacts[matchedContactIndex],
			loading: true,
			employerId:
				contact.employerId || cachedContacts[matchedContactIndex].employerId,
			householdId:
				contact.householdId || cachedContacts[matchedContactIndex].householdId,
		};
	} else {
		const newContact: Loaded<Contact> = {
			data: contact,
			loading: true,
			employerId: contact.employerId,
			householdId: contact.householdId,
			errors: [],
		};
		cachedContacts.push(newContact);
	}
}

function upsertContactDone(
	cachedContacts: Loaded<Contact>[],
	updatedContact: Contact,
	contactInsertedAtStart?: Contact,
	error?: any
) {
	if (
		contactInsertedAtStart &&
		contactInsertedAtStart.id &&
		contactInsertedAtStart.id != updatedContact.id
	) {
		const deletedIndex = getMatchingContactIndex(
			cachedContacts,
			contactInsertedAtStart.id
		);
		if (deletedIndex > -1) {
			cachedContacts.splice(deletedIndex, 1);
		}
	}

	const index = getMatchingContactIndex(cachedContacts, updatedContact.id);
	if (index > -1) {
		if (error)
			cachedContacts[index] = {
				...cachedContacts[index],
				loading: false,
				errors: [...cachedContacts[index].errors, error],
			};
		else if (updatedContact.isDeleted) cachedContacts.splice(index, 1);
		else
			cachedContacts[index] = {
				...cachedContacts[index],
				data: updatedContact,
				employerId: updatedContact.employerId,
				householdId: updatedContact.householdId,
				loading: false,
				errors: [],
			};
	} else {
		const newContact: Loaded<Contact> = {
			data: updatedContact,
			loading: false,
			employerId: updatedContact.employerId,
			householdId: updatedContact.householdId,
			errors: error ? [error] : [],
		};
		cachedContacts.push(newContact);
	}
}

function updateStateWithInboundContacts(
	contactsInState: Loaded<Contact>[],
	inboundContacts: Contact[]
) {
	inboundContacts.forEach(inboundContact => {
		const contactId = inboundContact.id;
		let wasAContactUpdated = false;

		contactsInState.forEach((currentContact, index) => {
			const currentContactId = currentContact.data.id;
			if (currentContactId === contactId) {
				wasAContactUpdated = true;
				contactsInState[index] = {
					...contactsInState[index],
					loading: false,
					data: inboundContact,
					householdId: inboundContact.householdId,
					employerId: inboundContact.employerId,
					errors: [],
				};
			}
		});
		if (!wasAContactUpdated) {
			const newContact: Loaded<Contact> = {
				loading: false,
				data: inboundContact,
				errors: [],
				householdId: inboundContact.householdId,
				employerId: inboundContact.employerId,
			};
			contactsInState.push(newContact);
		}
	});
}

function unlinkOldEmployees(
	contactsInState: Loaded<Contact>[],
	employerContacts: Contact[]
) {
	const employerId = employerContacts[0].employerId;
	contactsInState.forEach((contact, index) => {
		if (contact.employerId === employerId) {
			const isEmployee = employerContacts.some(
				employee => employee.id === contact.data.id
			);
			if (!isEmployee) {
				contactsInState[index] = {
					...contact,
					employerId: undefined,
				};
			}
		}
	});
}

function updateStateWithNewPrimaryEmployee(
	contactsInState: Loaded<Contact>[],
	employerId: string,
	contactId: string
) {
	contactsInState.forEach((contact, index) => {
		if (contact.employerId === employerId) {
			let isPrimary = contact.data.id === contactId;
			contactsInState[index] = {
				...contactsInState[index],
				loading: false,
				data: {
					...contactsInState[index].data,
					employerPrimaryContact: isPrimary,
				},
			};
		}
	});
}

function getContactFromDto(contactDto: ContactDto): Contact {
	let medicareInfoResult: MedicareInfo | undefined = undefined;

	if (contactDto.medicareInfo) {
		try {
			const medicareInfoParsed: MedicareInfoDto = JSON.parse(contactDto.medicareInfo);
			const PartAEffectiveDate = medicareInfoParsed.PartAEffectiveDate ? new Date(medicareInfoParsed.PartAEffectiveDate) : undefined;
			const PartBEffectiveDate = medicareInfoParsed.PartBEffectiveDate ? new Date(medicareInfoParsed.PartBEffectiveDate) : undefined;

			medicareInfoResult = {
				...medicareInfoParsed,
				PartAEffectiveDate,
				PartBEffectiveDate
			};
		} catch (error) {
			console.error(error, 'Medicare info could not be JSON parsed', contactDto.medicareInfo);
		}
	}

	let contact: Contact = {
		...contactDto,
		medicareInfo: medicareInfoResult
	};

	return contact;
}