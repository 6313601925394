import React from 'react';
import { Product } from '../../containers/products/product_list_page';
import { Loaded, isNullOrUndefinedOrEmpty } from '../../utilities/utilities';
import { LineOfBusinessAvatar } from '../line_of_business/line_of_business_avatar';
import uuid from 'uuid';
import { Icon, Paper, IconButton } from '@material-ui/core';
import { themePalette } from '../../utilities/branding';
import { ProductStatus } from '../../reducers/policy_reducer';
import { DateTimeFormat, displayDate } from '../../utilities/date_util';
import moment from 'moment';
import { CustomListItem } from '../utility/custom_list_item';
import { Strings as S } from '../../assets/common/strings';
import { fullName, birthDate } from '../../assets/common/string_builders';

interface ProductListItemProps extends Loaded<Product> {
	onClick: (productId: string, policyOrApp: 'application' | 'policy' | 'both') => void;
	iconClick?: (id: string, type?: 'household' | 'employer') => void;
	dateLabel?: string;
}

export const ProductListItem = (props: ProductListItemProps) => {
	let city = '';
	let state = '';
	let id = '';
	let productName = '';
	let productNumber = '';
	let carrier = '';
	let status = 0;
	let statusReason = '';
	let lineOfBusiness = '~';
	let primaryFullName = '';
	let primaryBirthDate = '';

	if (props.data) {
		productName = props.data.data.productName || productName;
		primaryFullName = props.data.fullName || primaryFullName;
		productNumber = props.data.productNumber || productNumber;
		carrier = props.data.carrier || carrier;
		status = (props.data.status as number) || status;
		statusReason = props.data.statusReason || '';
		lineOfBusiness = (props.data.lineOfBusiness as string) || lineOfBusiness;
		if (props.data.data) {
			if (props.data.data.primaryInsured) {
				primaryFullName = fullName(props.data.data.primaryInsured);
				primaryBirthDate = birthDate(props.data.data.primaryInsured);

				if (
					props.data.data.primaryInsured.addresses &&
					props.data.data.primaryInsured.addresses.length > 0
				) {
					city = props.data.data.primaryInsured.addresses[0].city || city;
					state = props.data.data.primaryInsured.addresses[0].state || state;
				}
			}
			id = props.data.data.id || id;
		}
	}

	const subtitle = [
		{ value: primaryFullName + ' ' + primaryBirthDate },		
		{ label: carrier, value: '' },
		{ value: productName }
	];
	if (props.data.date) {
		subtitle.push({
			value: displayDate(
				moment.utc(props.data.date),
				DateTimeFormat.Date,
				props.data.dateLabel
			)
		});
	}

	const rightSubtitle = [
		{ label: ProductStatus[status], value: '' },
		{ value: statusReason },
		{ value: props.data.policyOrApplication == 'policy' || props.data.policyOrApplication == 'both' ? props.data.productNumber : '' },
		{
			value: props.data.cityState
				? props.data.cityState !== ', ' ? props.data.cityState : ''
				: ''
		}
	];

	{
		displayDate(
			moment.utc(props.data.date),
			DateTimeFormat.Date,
			props.data.dateLabel
		)
	}

	return (
		<CustomListItem
			id={'product-' + id}
			onClick={() => {
				if (props.onClick) {
					props.onClick(
						props.data.productId,
						props.data.policyOrApplication
					);
				}
			}}
			leftActionElement={displayLOBAvatar(lineOfBusiness)}
			subtitles={subtitle}
			rightSubtitles={rightSubtitle}
			rightActionElement={renderRightIcon(props)}
			header={''}
		/>
	);

	function renderRightIcon(props: ProductListItemProps) {
		if (props.iconClick !== undefined) {
			const clickIcon = props.data.isMatched ? (
				<Icon>people</Icon>
			) : (
					<Icon>person_add</Icon>
				);
			return (
				<IconButton
					onClick={() => {
						if (props.iconClick) {
							if (props.data.isMatched) {
								if (props.data.employerId)
									props.iconClick(props.data.employerId, S.ProductList.employer);
								else
									props.iconClick(props.data.contact!.householdId, S.ProductList.household);
							} else {
								props.onClick(props.data.productId, S.ProductList.policy);
							}
						}
					}}
				>
					{clickIcon}
				</IconButton>
			);
		}
	}
};

function displayLOBAvatar(carrier: string) {
	let shortString: string = '~';
	if (!isNullOrUndefinedOrEmpty(carrier)) {
		if (carrier.toLowerCase() == S.ProductList.commercialHealth) {
			shortString = S.LineOfBusinessAbbreviations.commercialHealth;
		} else {
			shortString = carrier.slice(0, 1);
		}
	}
	const carrierId: string = uuid.v4();

	return (
		<LineOfBusinessAvatar
			key={carrierId}
			size={20}
			lineOfBusinessShortString={shortString}
			displayInline={false}
			bubbleColor={themePalette.customer_avatar}
		/>
	);
}