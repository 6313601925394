import React from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import { IconButton } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import { AppState } from '../../reducers/index';
import { NavigationProps } from '../../components/nav/Routes';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { themePalette } from '../../utilities/branding';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { isMobileDevice } from '../../utilities/is_mobile';
import { isBrokerage } from '../../utilities/brokerage_utils';
import { AgentState, AgentContactInfo } from '../../reducers/agent_reducer';
import { fullName } from '../../assets/common/string_builders';
import { DesktopPadding } from '../../components/Layout/desktop_padding';
import { ToggleCard } from '../../components/higher_order_component/wrap_in_card';
import { Dispatch } from '@reduxjs/toolkit';
import { openMail } from '../../utilities/phone_email_util';
import { SimpleListItem } from '../../components/utility/simple_list_item';
const bowser = require('bowser');


const generateListItem = (title: string, subtitle: string) => (
	<SimpleListItem
		title={title}
		subtitle={subtitle}
		divider={false}
	/>
);

interface StateProps {
	agent: AgentState;
}
type Props = StateProps;

const SettingsDevicePage: React.FC<Props> = (props) => {

	const { agent } = props;
	let connected = navigator.onLine ? 'Connected' : 'Offline';
	let platform = bowser.osname + ' ' + bowser.osversion;
	let manufacturer = navigator.vendor;
	let model = navigator.appName;
	let version = navigator.appVersion;
	let uuid = 'N/A';


    // TODO: handle this
	// if (isMobileDevice) {
		// platform = device.platform;
		// manufacturer = device.manufacturer;
		// model = device.model;
		// version = device.version;
		// uuid = device.uuid;
	// }

	const sendEmail = () => {
		const name = fullName(agent);
		const primaryContacts = agent.contactInfo.filter(
			(contact: AgentContactInfo) => contact.isPrimary == 'Yes'
		);
		const phones = primaryContacts.filter((contact: AgentContactInfo) => contact.contactMode == 'Phone');
		const phone = phones[0].contactInfo;
		const emails = primaryContacts.filter((contact: AgentContactInfo) => contact.contactMode == 'Email');
		const email = emails[0].contactInfo;
		const address = 'hmfieldsupport@hmkts.com';
		const subject = `${agent.agentCode} - User Device Information`;
		const body = `
			%0D%0A
			%0D%0A
			%0D%0A
			Please do not change any information below this line.%0D%0A
			------------------------------------------------%0D%0A
			Agent Code: ${agent.agentCode}%0D%0A
			Agent Name: ${name}%0D%0A
			Agent Phone: ${phone}%0D%0A
			Version: ${VERSION}%0D%0A
			Connection State: ${connected}%0D%0A
			Platform: ${platform}%0D%0A
			Device Model: ${model}%0D%0A
			Device ID: ${uuid}
        `;
		const mailTo = `mailto:${address}?cc=${email}&subject=${subject}&body=${body}`;
		openMail(mailTo);
	};	

	const buttons: JSX.Element[] = [];
	if (!isBrokerage) {
		buttons.push(
			<IconButton onClick={sendEmail}>
				<EmailIcon style={{ color: themePalette.negative_text }}/>
			</IconButton>
		);
	}
	
	return (
		<BasePageContainer
			topComponent={
				<HeaderBarComponent
					title="App Support"
					rightButtons={buttons}
				/>
			}
		>
			<PersistentNotifications />
			<DesktopPadding>
				<ToggleCard title={'About'} noPadding>
					{generateListItem('App Version', VERSION)}
					{generateListItem('Connection State', connected)}
					{generateListItem('Platform', platform)}
					{isMobileDevice && (
						<>
							{generateListItem('Device Manufacturer', manufacturer)}
							{generateListItem('Device Model', model)}
							{generateListItem('Device Version', version)}
							{/* {generateListItem('Device Id', uuid)} */}
						</>
					)}
				</ToggleCard>
			</DesktopPadding>
		</BasePageContainer>
	);
}

const mapStateToProps = (state: AppState): StateProps => ({
	agent: state.agent,
});
const mapActionsToProps = (dispatch: Dispatch): Partial<NavigationProps> => ({});

export const SettingsDeviceContainer = connect(
	mapStateToProps, 
	mapActionsToProps,
	true
)(SettingsDevicePage);
