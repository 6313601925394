import { Action } from 'typescript-fsa';
import { call, put, all } from 'redux-saga/effects';
import {
	executeAdvancedSearchSuccess,
	executeAdvancedSearchFailure,
	ExecuteAdvancedSearch,
	getSavedCorporateAdvSearchFilters,
	getSavedUserAdvSearchFilters,
	upsertAdvancedSearchFilters,
	deleteSavedAdvancedSearchFilters,
	selectSavedSearchName,
	exportAdvancedSearchResults,
	AdvSearchResultExportObject,
	AdvancedSearchParams,
} from '../actions/advanced_search_actions';
import http, { HttpOptions } from '../utilities/http';
import { StoreInfiniteScrollMeta } from '../actions/infinite_scroll_actions';
import {
	AdvSearchFilterPresetDto,
	DEFAULT_UNSELECTED,
	SearchFilterFormValues,
} from '../reducers/advanced_search_reducer';
import { QueueSnackbar } from '../actions/snackbar_actions';
import { Strings } from '../assets/common/strings';
import { local_storage } from '../utilities/storage';
import { takeLatestForActionType } from '../utilities/saga_util';
import { getSnackbarErrorProps, getSnackbarSuccessProps } from '../utilities/snackbar_util';
import { DateUtility } from '../utilities/date_util';

function* executeAdvancedSearchSaga(action: Action<AdvancedSearchParams>) {
	const { searchFilterObject, pageIndex, pageSize, processId } = Object.assign(
		{},
		action.payload
	);

	const snackBarProps = getSnackbarErrorProps('Unable to get search results', 5000);
	try {
		const response = yield call(
			executeAdvancedSearchCall,
			searchFilterObject,
			pageIndex,
			pageSize,
			processId
		);

		if (response.ok) {		
			const data = yield response.json();
			yield all([
				put(
					StoreInfiniteScrollMeta({
						query: action.payload.searchFilterObject,
						pageRequested: pageIndex,
						pageSize,
						resultSize: data.Items.length,
					})
				),
				put(executeAdvancedSearchSuccess(action.payload, data)),
			]);
		} else {
			yield put(QueueSnackbar(snackBarProps));
			yield put(
				executeAdvancedSearchFailure(action.payload, {
					errorCode: response.status,
				})
			);
		}
	} catch (error) {
		yield put(QueueSnackbar(snackBarProps));
		yield put(executeAdvancedSearchFailure(action.payload, error));
	}
}

function adjustTimesForApiCall(criteria: SearchFilterFormValues):void {
	if (criteria.leadContactedDateFrom) {
		criteria.leadContactedDateFrom = DateUtility.MomentToUtc(
			criteria.leadContactedDateFrom
		);
	}
	if (criteria.leadContactedDateTo) {
		criteria.leadContactedDateTo = DateUtility.MomentToUtc(
			criteria.leadContactedDateTo
		);
	}
	if (criteria.leadCreatedDateFrom) {
		criteria.leadCreatedDateFrom = DateUtility.MomentToUtc(
			criteria.leadCreatedDateFrom
		);
	}
	if (criteria.leadCreatedDateTo) {
		criteria.leadCreatedDateTo = DateUtility.MomentToUtc(criteria.leadCreatedDateTo);
	}
	if (criteria.effectiveDateFrom) {
		criteria.effectiveDateFrom = DateUtility.DateOnlyToUtc(criteria.effectiveDateFrom);
	}
	if (criteria.effectiveDateTo) {
		criteria.effectiveDateTo = DateUtility.DateTimeToUtc(criteria.effectiveDateTo);
	}
	if (criteria.applicationDateFrom) {
		criteria.applicationDateFrom = DateUtility.DateTimeToUtc(criteria.applicationDateFrom);
	}
	if (criteria.applicationDateTo) {
		criteria.applicationDateTo = DateUtility.DateTimeToUtc(criteria.applicationDateTo);
	}
	if (criteria.issueDateFrom) {
		criteria.issueDateFrom = DateUtility.DateTimeToUtc(criteria.issueDateFrom);
	}
	if (criteria.issueDateTo) {
		criteria.issueDateTo = DateUtility.DateTimeToUtc(criteria.issueDateTo);
	}
	if (criteria.openTodoInNextDaysFrom) {
		criteria.openTodoInNextDaysFrom = DateUtility.MomentToUtc(criteria.openTodoInNextDaysFrom);
	}
	if (criteria.policyEndDateFrom) {
		criteria.policyEndDateFrom = DateUtility.DateTimeToUtc(criteria.policyEndDateFrom);
	}
	if (criteria.policyEndDateTo) {
		criteria.policyEndDateTo = DateUtility.DateTimeToUtc(criteria.policyEndDateTo);
	}
}

function executeAdvancedSearchCall(
	searchFilterObject: SearchFilterFormValues,
	pageIndex: number,
	pageSize: number,
	processId?: string
): Promise<any> {
	const paging = { index: pageIndex, size: pageSize };
	adjustTimesForApiCall(searchFilterObject);
	return http(
		'/AdvSearch/GetResults',
		{
			method: Strings.Http.Post,
			body: JSON.stringify({
				...searchFilterObject,
				processId: processId
			}),
		},
		paging
	);
}

//////////  Get Saved Corporate Advanced Search Filters  //////////
function* getSavedCorporateAdvSearchFiltersSaga(action: Action<undefined>) {
	const snackBarProps = getSnackbarErrorProps('Unable to get saved Corporate search filters', 5000);

	try {
		const response = yield call(getSavedCorporateAdvSearchFiltersClient);

		if (response.ok) {
			const data = yield response.json();
			yield put(
				getSavedCorporateAdvSearchFilters.done({
					params: undefined,
					result: data,
				})
			);
		} else {
			yield put(
				getSavedCorporateAdvSearchFilters.failed({
					params: undefined,
					error: response.status,
				})
			);
			yield put(QueueSnackbar(snackBarProps));
		}
	} catch (error) {
		yield put(
			getSavedCorporateAdvSearchFilters.failed({
				params: undefined,
				error: error,
			})
		);
		yield put(QueueSnackbar(snackBarProps));
	}
}

function getSavedCorporateAdvSearchFiltersClient(): Promise<any> {
	return http('/AdvSearch/GetCorporateFilterPresets');
}

//////////  Get Saved User Advanced Search Filters  //////////
function* getSavedUserAdvSearchFiltersSaga(action: Action<undefined>) {
	const snackBarProps = getSnackbarErrorProps('Unable to get saved User search filters', 5000);

	try {
		const response = yield call(getSavedUserAdvSearchFiltersClient);

		if (response.ok) {
			const data = yield response.json();
			yield put(
				getSavedUserAdvSearchFilters.done({ params: undefined, result: data })
			);
		} else {
			yield put(
				getSavedUserAdvSearchFilters.failed({
					params: undefined,
					error: response.status,
				})
			);
			yield put(QueueSnackbar(snackBarProps));
		}
	} catch (error) {
		yield put(
			getSavedUserAdvSearchFilters.failed({ params: undefined, error: error })
		);
		yield put(QueueSnackbar(snackBarProps));
	}
}

function getSavedUserAdvSearchFiltersClient(): Promise<any> {
	return http('/AdvSearch/GetFilterPresetsForUser');
}

//////////  Upsert Advanced Search Filters  //////////
function* upsertSavedAdvancedSearchFiltersSaga(action: Action<AdvSearchFilterPresetDto>) {
	const snackBarErrorProps = getSnackbarErrorProps('Unable to save consumer search filters', 5000);
	const snackBarSuccessProps = getSnackbarSuccessProps('Successfully saved consumer search filter', 5000);
	try {
		const response = yield call(
			upsertSavedAdvancedSearchFiltersClient,
			action.payload
		);

		if (response.ok) {
			const data = yield response.json();
			yield put(
				upsertAdvancedSearchFilters.done({
					params: action.payload,
					result: data,
				})
			);

			yield put(QueueSnackbar(snackBarSuccessProps));

		} else {
			yield put(
				upsertAdvancedSearchFilters.failed({
					params: action.payload,
					error: response.status,
				})
			);
			yield put(QueueSnackbar(snackBarErrorProps));
		}
	} catch (error) {
		yield put(
			upsertAdvancedSearchFilters.failed({
				params: action.payload,
				error: error,
			})
		);
		yield put(QueueSnackbar(snackBarErrorProps));
	}
}

function upsertSavedAdvancedSearchFiltersClient(
	searchFilters: AdvSearchFilterPresetDto
): Promise<any> {
	const options: HttpOptions = {
		method: Strings.Http.Post,
		body: JSON.stringify(searchFilters),
	};

	return http('AdvSearch/SaveFilterPreset', options);
}

//////////  Delete Saved Advanced Search Filters  //////////
function* deleteSavedAdvancedSearchFiltersSaga(action: Action<string>) {
	const snackBarErrorProps = getSnackbarErrorProps('Unable to delete saved consumer search filters', 5000);
	const snackBarSuccessProps = getSnackbarSuccessProps('Successfully deleted consumer search filter', 5000);
	try {
		const response = yield call(
			deleteSavedAdvancedSearchFiltersClient,
			action.payload
		);

		if (response.ok) {
			const data = yield response.json();
			yield put(
				deleteSavedAdvancedSearchFilters.done({
					params: action.payload,
					result: data,
				})
			);
			yield put(selectSavedSearchName(DEFAULT_UNSELECTED));
			yield put(QueueSnackbar(snackBarSuccessProps));
		} else {
			yield put(
				deleteSavedAdvancedSearchFilters.failed({
					params: action.payload,
					error: response.status,
				})
			);
			yield put(QueueSnackbar(snackBarErrorProps));
		}
	} catch (error) {
		yield put(
			deleteSavedAdvancedSearchFilters.failed({
				params: action.payload,
				error: error,
			})
		);
		yield put(QueueSnackbar(snackBarErrorProps));
	}
}

function deleteSavedAdvancedSearchFiltersClient(
	savedSearchFilterQueryId: string
): Promise<any> {
	const options: HttpOptions = {
		method: Strings.Http.Delete,
	};

	return http(
		'AdvSearch/DeleteFilterPreset/' + savedSearchFilterQueryId,
		options
	);
}

//////////  Upsert Advanced Search Filters  //////////
function* exportdAdvancedSearchResultsSaga(action: Action<AdvSearchResultExportObject>) {
	const fileName = 'AdvSearchResults.xlsx';

	const snackBarProps = getSnackbarErrorProps('Unable to download file, ' + fileName, 15000);

	try {
		const response = yield call(
			exportAdvancedSearchResultsClient,
			action.payload
		);

		if (response.ok) {
			const data = yield response.blob();
			local_storage.downloadFileFromBrowser(data, fileName);

			yield put(
				exportAdvancedSearchResults.done({
					params: action.payload,
					result: undefined,
				})
			);
		} else {
			yield put(
				exportAdvancedSearchResults.failed({
					params: action.payload,
					error: response.status,
				})
			);
			yield put(QueueSnackbar(snackBarProps));
		}
	} catch (error) {
		yield put(
			exportAdvancedSearchResults.failed({
				params: action.payload,
				error: error,
			})
		);
		yield put(QueueSnackbar(snackBarProps));
	}
}

function exportAdvancedSearchResultsClient(
	searchFilters: AdvSearchResultExportObject
): Promise<any> {
	const options: HttpOptions = {
		method: Strings.Http.Post,
		body: JSON.stringify(searchFilters),
	};

	return http('AdvSearch/Export', options);
}


export function* advancedSearchSagas() {
	yield all([
		takeLatestForActionType(
			ExecuteAdvancedSearch.started,
			executeAdvancedSearchSaga
		),
		takeLatestForActionType(
			getSavedCorporateAdvSearchFilters.started,
			getSavedCorporateAdvSearchFiltersSaga
		),
		takeLatestForActionType(
			getSavedUserAdvSearchFilters.started,
			getSavedUserAdvSearchFiltersSaga
		),
		takeLatestForActionType(
			upsertAdvancedSearchFilters.started,
			upsertSavedAdvancedSearchFiltersSaga
		),
		takeLatestForActionType(
			exportAdvancedSearchResults.started,
			exportdAdvancedSearchResultsSaga
		),
		takeLatestForActionType(
			deleteSavedAdvancedSearchFilters.started,
			deleteSavedAdvancedSearchFiltersSaga
		),
	]);
}