import { call, put, all } from 'redux-saga/effects';
import { http } from "../utilities";
import { submitLog } from '../utilities/logging_util';
import { Strings } from '../assets/common/strings';
import { takeLatestForActionType } from '../utilities/saga_util';
import _ from 'lodash';
import { GetAgentCommissionStatementSummary, GetAgentCommissionStatementDates } from '../actions/commission_statement_actions';
import { AgentCommissionStatementSummary } from '../reducers/commission_statement_reducer';
import { Action } from 'typescript-fsa';

// GET AGENT COMMISSION STATEMENT DATE
function* getAgentCommissionStatementDatesSaga() {
	try {
		const response = yield call(getAgentCommissionStatementDatesClient);
		if (response.ok) {
			const data: Date[] = yield response.json();
			yield put(GetAgentCommissionStatementDates.done({ result: data }));
		} else {
			yield put(
				GetAgentCommissionStatementDates.failed({
					error: { errorCode: response.status },
				})
			);
		}
	} catch (error) {
		submitLog(Strings.Log.Error, `Error getting agent commission statement dates`, {
			error,
		});
		yield put(GetAgentCommissionStatementDates.failed({ error }));
	}
}

function getAgentCommissionStatementDatesClient() {
	return http(Strings.AgentCommissionStatement.GetAgentCommissionStatementDatesEndpoint);
}

// GET AGENT COMMISSION STATEMENT SUMMARY
function* getAgentCommissionStatementSummarySaga(action: Action<Date>) {
	try {
		const response = yield call(getAgentCommissionStatementSummaryClient, action.payload);
		if (response.ok) {
			const data: AgentCommissionStatementSummary = yield response.json();
			yield put(GetAgentCommissionStatementSummary.done({ params: action.payload, result: data }));
		} else {
			yield put(
				GetAgentCommissionStatementSummary.failed({
					error: { errorCode: response.status }, params: action.payload, 
				})
			);
		}
	} catch (error) {
		submitLog(Strings.Log.Error, `Error getting agent commission statement summary`, {
			error,
		});
		yield put(GetAgentCommissionStatementSummary.failed({ error, params: action.payload }));
	}
}

function getAgentCommissionStatementSummaryClient(params) {
	const url = `${Strings.AgentCommissionStatement.GetAgentCommissionStatementSummaryEndpoint}?statementDate=${params}`
	return http(url);
}

export function* commissionStatementSaga() {
	yield all([
		takeLatestForActionType(
			GetAgentCommissionStatementDates.started,
			getAgentCommissionStatementDatesSaga
		),
		takeLatestForActionType(
			GetAgentCommissionStatementSummary.started,
			getAgentCommissionStatementSummarySaga
		)
	])
}