import { call, put, all } from 'redux-saga/effects';
import { GetAllDocuments, FilterDocuments, UpdateFilteredDocuments, FavoriteDocument, UnfavoriteDocument, FavoriteParams } from '../actions/document_actions';
import http, { HttpOptions } from '../utilities/http';
import { Action } from 'typescript-fsa';
import { DocumentDto, DocumentState, DocumentForm } from '../reducers/document_reducer';
import { Strings } from '../assets/common/strings';
import { selectDocumentState } from '../selectors/document_selectors';
import { takeLatestForActionType, selectFromImmutable, makeHandleError } from '../utilities/saga_util';

function* handleGetDocuments(action: Action<{ loading: boolean; }>) {
    const error = 'Failed to get documents';
    const options: HttpOptions = {
        method: Strings.Http.Get,
        credentials: 'include'
    };
    try {
        const response = yield call(http, Strings.ApiUrls.Doc, options);
        if (response.ok) {
            const result = yield response.json();
            yield put(GetAllDocuments.done({ params: action.payload, result }))
            const docState: DocumentState = yield selectFromImmutable(selectDocumentState);
            yield put(FilterDocuments(docState.filterForm));
        } else {
            yield handleError(GetAllDocuments, action, error);
        }
    } catch (err) {
        yield handleError(GetAllDocuments, action, error);
    }
}

const favoriteSaga = (favorite: boolean) => function* (action: Action<FavoriteParams>) {
    const error = `Could not ${!favorite && 'un'}favorite document`;
    const options: HttpOptions = {
        method: favorite ? Strings.Http.Post : Strings.Http.Delete,
        body: JSON.stringify(action.payload),
    };
    try {
        const response = yield call(http, Strings.ApiUrls.DocFavorite, options);
        if (response.ok) {
            yield put(GetAllDocuments.started({ loading: false }));
        } else {
            yield handleError(favorite ? FavoriteDocument : UnfavoriteDocument, action, error);
        }
    } catch (err) {
        yield handleError(favorite ? FavoriteDocument : UnfavoriteDocument, action, error);
    }
};

function* handleFilterDocuments(action: Action<DocumentForm>) {
    const state: DocumentState = yield selectFromImmutable(selectDocumentState);
    const filterForm = action.payload;
    const filteredDocs = state.documents.filter((document: DocumentDto) => {
        const title: string = document.title ? document.title.toLowerCase() : '';

        const anyLineOfBusiness: boolean =
            filterForm.lineOfBusiness == Strings.DocumentFilterLabels.AllLinesOfBusiness;
        const anyCategory: boolean =
            filterForm.category == Strings.DocumentFilterLabels.AllCategories;
        const hasMatchingLineOfBusiness =
            document.lineOfBusiness && document.lineOfBusiness.includes(
                filterForm.lineOfBusiness
            );
        const hasMatchingCategory =
            document.category && document.category.includes(
                filterForm.category
            );

        return (
            (title && filterForm.searchText
                ? ~title.indexOf(filterForm.searchText.toLowerCase())
                : true) &&
            (hasMatchingCategory || anyCategory) &&
            (hasMatchingLineOfBusiness || anyLineOfBusiness) &&
            (filterForm.onlyFavorites ? document.favorite : true)
        );
    });

    yield put(UpdateFilteredDocuments(filteredDocs));
}

const handleError = makeHandleError();

export function* documentSagas() {
    yield all([
        takeLatestForActionType(
            GetAllDocuments.started,
            handleGetDocuments
        ),
        takeLatestForActionType(
            FavoriteDocument.started,
            favoriteSaga(true)
        ),
        takeLatestForActionType(
            UnfavoriteDocument.started,
            favoriteSaga(false)
        ),
        takeLatestForActionType(
            FilterDocuments,
            handleFilterDocuments
        ),
    ]);
}