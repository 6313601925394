import { Action as ReduxAction } from 'redux';
import { StoreLeadFilters } from '../actions/lead_actions';
import { isType } from 'typescript-fsa';
import moment from 'moment';
import { ClearCaches } from '../actions/authentication_actions';
import { Logout } from '../actions/authentication_actions';
import { Lookup, Lookups } from '../utilities/lookup/lookup';

export interface LeadFilters {
	ratingFilter: string;
	statusGroupFilter: Lookup;
	statusFilter: Lookup;
	lineOfBusinessFilter: string;
	vendorFilter: string;
	inputSourceFilter: string[];
	campaignFilter: string;
	dateTypeFilter: string;
	sortBy: string;
	fromDate?: Date;
	toDate?: Date;
	filterBySearch: string;
	sortCategory: string;
	sortDirection: string;
	filterIsOpen: boolean;
	leadTypeFilter: string[];
	callAttempts: string;
	leadTags: string[];
}

export const initialFilterState: LeadFilters = {
	ratingFilter: 'All',
	statusFilter: Lookups.All,
	statusGroupFilter: Lookups.All,
	lineOfBusinessFilter: 'All',
	vendorFilter: 'All',
	inputSourceFilter: [],
	campaignFilter: '',
	dateTypeFilter: '',
	sortBy: 'Newest to Oldest by Created Date',
	filterBySearch: '',
	fromDate: moment()
		.subtract(12, 'm')
		.startOf('d')
		.toDate(),
	toDate: moment()
		.add(12, 'm')
		.endOf('d')
		.toDate(),
	sortCategory: '',
	sortDirection: '',
	filterIsOpen: false,
	leadTypeFilter: [],
	callAttempts: 'All',
	leadTags: [],
};

export function leadFilterReducer(
	state: LeadFilters = initialFilterState,
	action: ReduxAction
): LeadFilters {
	if (isType(action, StoreLeadFilters)) {
		return { ...state, ...action.payload };
	} else if (isType(action, Logout.started)) {
		return {
			...initialFilterState,
		};
	} else if (isType(action, ClearCaches)) {
		return {
			...initialFilterState,
		};
	} else {
		return state;
	}
}
