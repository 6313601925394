import React, { ReactNode } from 'react';
import { ListItem, Icon, Grid, Collapse, LinearProgress } from '@material-ui/core';
import { themePalette } from '../../utilities/branding';
import { Strings as S } from '../../assets/common/strings';
import { enforceStylesType } from '../../utilities/styles_util';

export interface Columns {
	[key: string]: Value;
}

interface Value {
	value: string;
	delta: number;
}
interface Props {
	id: string;
	isHidden: boolean;
	columns: Columns;
	children: React.ReactElement<ReactNode>[];
	numChildren: number;
	incomingNestedRow?: boolean;
	isItalicText?: boolean;
	onClickIcon?: (id: string) => void;
	onClickRow?: () => void;
	isHeader: boolean;
	headerBottomBorderColor?: string;
	expandable?: boolean;
	level?: number;
	style?: React.CSSProperties;
}

interface State {
	expanded: boolean;
	hasExpanded: boolean;
	isLoading: boolean;
}

class ExpandableTableRow extends React.PureComponent<Props, State> {
	static defaultProps = {
		expandable: true,
		level: 0,
		isItalicText: false,
	};

	constructor(props: Props) {
		super(props);
		this.state = {
			expanded: false,
			hasExpanded: false,
			isLoading: false,
		}
	}

	componentWillReceiveProps(nextProps: Props) {
		const { expandable, incomingNestedRow } = this.props;
		const { hasExpanded } = this.state;

		if (expandable && !hasExpanded && !incomingNestedRow && nextProps.children.length > 0) {
			this.setState({
				expanded: true,
				hasExpanded: true,
			});
		}

		if (nextProps.children.length > 0) {
			this.setState({
				isLoading: false,
			});
		}

		if (nextProps.children.length === 0) {
			this.setState({
				expanded: false,
				hasExpanded: false,
			});
		}
	}

	componentWillUnmount() {
		this.setState({
			expanded: false,
			hasExpanded: false,
			isLoading: false,
		});
	}

	toggleExpand = () => {
		const {
			children,
			numChildren,
			onClickRow,
			expandable,
			incomingNestedRow,
		} = this.props;
		const { expanded, hasExpanded, isLoading } = this.state;

		if (expandable) {
			this.setState({
				expanded: !expanded,
				hasExpanded: true
			});
		}

		if (onClickRow && !expanded && numChildren > 0 && children.length === 0 && !isLoading) {
			this.setState({
				isLoading: true,
			});
			onClickRow();
		}

		if (onClickRow && !expanded && !hasExpanded && incomingNestedRow) {
			onClickRow();
		}
	}

	handleOnClick = (event: React.MouseEvent<HTMLSpanElement>) => {
		const { id, onClickIcon } = this.props;

		if (event) {
			event.stopPropagation();
		}
		if (onClickIcon) {
			onClickIcon(id);
		}
	}

	handleSymbolConvert = (value: string, delta: number) => {
		if (delta == S.TeamViewDelta.NODELTA) { return value }
		if (delta == S.TeamViewDelta.POSTIVEDELTA) { return `${value} ${S.TeamView.POSITIVE}` }
		if (delta == S.TeamViewDelta.NEGATIVEDELTA) { return `${value} ${S.TeamView.NEGATIVE}` }
		if (delta == S.TeamViewDelta.NOCHANGEDELTA) {
			if (!/\d/.test(value)) { return value }
			else { return `${value} ${S.TeamView.NOCHANGE}` }
		}
		return value
	}

	render() {
		const {
			id,
			isHidden,
			isHeader,
			columns,
			children,
			numChildren,
			incomingNestedRow,
			expandable,
		} = this.props;
		const { expanded, isLoading } = this.state;

		return (
			<div key={id}>
				<Collapse in={!isHidden}>
					<ListItem
						style={{
							...styles.rowStyle,
							...styles.list(this.props)
						}}
						onClick={this.toggleExpand}
					>
						<Grid container alignItems="center">
							<Grid item style={styles.smallColumnWidth}>
								{numChildren > 0 &&
									<Icon style={styles.cellIcon}>
										{expanded ? 'remove' : 'add'}
									</Icon>
								}
							</Grid>
							{Object.keys(columns).map((key, index) => (
								<Grid
									key={id + '_' + index}
									item
									style={{ ...styles.columnWidth(this.props) }}>
									<span
										style={{ ...styles.columnText(this.props, columns[key].delta) }}
									>{this.handleSymbolConvert(columns[key].value, columns[key].delta)}</span>
								</Grid>
							))}
							<Grid item style={styles.smallColumnWidth}>
								{!isHeader && !incomingNestedRow &&
									<Icon
										style={styles.cellIcon}
										onClick={this.handleOnClick}
									>launch</Icon>}
							</Grid>
						</Grid>
					</ListItem>

					{isLoading && <LinearProgress/>}
					{expandable && <Collapse in={expanded}>{children}</Collapse>}
				</Collapse>
			</div>
		);
	}
}

export default ExpandableTableRow;

const styles = {
	rowStyle: {
		backgroundColor: 'white',
		padding: '10px 10px 10px 5px',
		borderBottomStyle: 'solid',
		fontFamily: '"Open Sans", sans-serif',
	} as React.CSSProperties,
	smallColumnWidth: {
		width: '4%'
	},
	cellIcon: {
		fontSize: '15px',
		cursor: 'pointer',
	},
	columnWidth: (props: Props): React.CSSProperties => {
		return {
			width: 'calc(95%/' + Object.keys(props.columns).length.toString() + ')',
			textAlign: 'center',
		}
	},
	columnText: (props: Props, delta: number): React.CSSProperties => {
		return {
			fontWeight: props.isHeader ? 'bold' as 'bold' : undefined,
			fontStyle: props.isItalicText ? 'italic' as 'italic' : undefined,
			color: delta == 2 ? themePalette.down_arrow
				: delta == 3 ? themePalette.up_arrow
					: themePalette.sub_text,
		}
	},
	list: (props: Props): React.CSSProperties => {
		const level = props.level ? props.level : 0;
		return {
			borderBottomWidth:
				props.headerBottomBorderColor && props.isHeader
					? 2
					: 1,
			borderBottomColor:
				props.headerBottomBorderColor && props.isHeader
					? props.headerBottomBorderColor
					: themePalette.divider,
			color: themePalette.sub_text,
			...props.level !== 0
				? { backgroundColor: `rgba(0, 0, 0, ${level * 0.075})` }
				: {},
		}
	}
};