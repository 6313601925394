import { Select, Box, makeStyles, InputLabel, MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import { AppState } from '../../reducers';
import { Dispatch } from '@reduxjs/toolkit';
import { Strings } from '../../assets/common/strings';
import { getCommissionStatementDates } from '../../selectors/agent_commission_statement_selectors';
import { getFormattedDate } from '../../utilities/date_util';
import { SelectListOptions } from '../utility/select_list_options';
import { CommissionStatementSummary } from './commission_statement_summary';
import { GetAgentCommissionStatementSummary } from '../../actions/commission_statement_actions'

const useStyles = makeStyles(theme => ({
	control: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'left',
	},
	label: {
		padding: '0 1em 0 0',
	},
	dropdown: {
		width: '50%',
	}
}));

interface StateProps {
	agentCommissionStatementDates: Date[];
}

interface DispatchProps { 
	getAgentCommissionStatementSummary: (params: Date) => void
}

type Props = StateProps & DispatchProps;

const _CommissionStatementTile: React.FC<Props> = (props) => {

	const classes = useStyles();
	const { agentCommissionStatementDates, getAgentCommissionStatementSummary } = props;
	const initial = agentCommissionStatementDates.length > 0 ? agentCommissionStatementDates[0] : null;
	const [selectedDate, setStatementDate] = useState(initial);

	useEffect(() => {
		getAgentCommissionStatementSummary(selectedDate!);
	}, [selectedDate]);
	
	const handleDateChange = (event: any) => {
		const date = event.target.value;
		setStatementDate(date);
	};

	return (
		<>
			<Box className={classes.control}>
				<InputLabel
					className={classes.label}
					children={Strings.AgentCommissionStatement.StatementDate + ':'}
				/>
				<Select
					className={classes.dropdown}
					value={selectedDate}
					onChange={handleDateChange}
					children={SelectListOptions({
						options: agentCommissionStatementDates.map(
							(commissionStatementDate) => {
								return {
									option: getFormattedDate(commissionStatementDate, 'MM/DD/YYYY', false),
									value: commissionStatementDate.toString()
								};
							}
						),
					})}
				>
				</Select>
			</Box>
			<CommissionStatementSummary />
		</>
	);
}

const mapStateToProps = (state: AppState): StateProps => ({
	agentCommissionStatementDates: getCommissionStatementDates(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	getAgentCommissionStatementSummary: (params: Date) => dispatch(GetAgentCommissionStatementSummary.started(params))
});

export const CommissionStatementTile = connect(mapStateToProps, mapDispatchToProps, true)(_CommissionStatementTile);