import actionCreatorFactory, {
	AsyncActionCreators,
} from 'typescript-fsa';
import { DownlineAgent } from '../reducers/product_reducer';
import { TeamViewRow, WritingAgentReport } from '../reducers/teamview_reducer';

const actionCreator = actionCreatorFactory('TeamView');

export interface TeamViewParams {
	startDate: Date;
	endDate: Date;
	dateRange?: string;
	agentCode?: string;
	lineOfBusiness?: string;
	comparePrevious?: boolean;
	dateRangeOverride?: number;
	utcOffset: number;
	parentPage?: string;
}

export interface NavigatePayload {
	agentCode: string;
	route: string;
}

export interface ShowChildrenPayload {
	agentCode: string,
	card: string,
}

export type TeamViewActionCreator = AsyncActionCreators<
	TeamViewParams,
	TeamViewRow[],
	any
>;

export type TeamViewNewActionCreator = AsyncActionCreators<
	TeamViewParams,
	WritingAgentReport,
	any
>;

export type TeamViewValueActionCreator = AsyncActionCreators<
	TeamViewParams,
	number,
	any
>;

export const GetAllTeamViewData = actionCreator<
	TeamViewParams
>('GET_ALL_TEAMVIEW_DATA');

export const SetTeamViewDownlineAgent = actionCreator<{
	agent: DownlineAgent | undefined;
}>('SET_TEAMVIEW_DOWNLINE_AGENT');

export const GetTeamViewActivity = actionCreator.async<
	TeamViewParams,
	TeamViewRow[]
>('GET_TEAMVIEW_ACTIVITY');

export const GetTeamViewLeads = actionCreator.async<
	TeamViewParams,
	TeamViewRow[]
>('GET_TEAMVIEW_LEADS');

export const GetTeamViewProduction = actionCreator.async<
	TeamViewParams,
	TeamViewRow[]
>('GET_TEAMVIEW_PRODUCTION');

export const GetTeamViewQuality = actionCreator.async<
	TeamViewParams,
	TeamViewRow[]
>('GET_TEAMVIEW_QUALITY');

export const GetTeamView206020 = actionCreator.async<
	TeamViewParams,
	TeamViewRow[]
>('GET_TEAMVIEW_206020');

export const GetTeamViewNewAgentConversion = actionCreator.async<
	TeamViewParams,
	TeamViewRow[]
>('GET_TEAMVIEW_NEW_AGENT_CONVERSION');

export const GetWritingAgentStats = actionCreator.async<
	TeamViewParams,
	WritingAgentReport
>('GET_WRITING_AGENT_STATS');

export const GetTeamViewSummaryReport = actionCreator.async<
	TeamViewParams,
	TeamViewRow[]
>('GET_TEAMVIEW_SUMMARY');

export const GetActiveSalesLeaderCount = actionCreator.async<
	TeamViewParams,
	number
>('GET_TEAMVIEW_ACTIVE_SL_COUNT');

export const GetProfessionalAgents = actionCreator.async<
	TeamViewParams,
	TeamViewRow[]
>('GET_PROFESSIONAL_AGENTS');

export const NavigateToAgentPerformance = actionCreator<
	NavigatePayload
>('NAVIGATE_TO_AGENT_PERFORMANCE');

export const ShowChildren = actionCreator<
	ShowChildrenPayload
>('SHOW_CHILDREN');
