import { Action } from 'typescript-fsa';
import {
	getEmailHistorySummarySetByEmployer,
	GetEmailHistorySummarySetByHousehold,
} from '../actions/email_summary_actions';
import { put, call, all } from 'redux-saga/effects';
import { http } from '../utilities';
import { QueueSnackbar, SnackbarProps } from '../actions/snackbar_actions';
import { Activity } from '../reducers/activity_reducer';
import { Contact } from '../reducers/ContactReducer';
import { getAppState } from '../';
import { Loaded } from '../utilities/utilities';
import { takeLatestForActionType, selectFromImmutable } from '../utilities/saga_util';
import { getSnackbarErrorProps } from '../utilities/snackbar_util';
import { getContacts } from '../selectors/contact_selectors';

// Employer
function* getEmailHistorySummarySetByEmployerSaga(action: Action<string>) {
	try {
		const response = yield call(
			getEmailHistorySummarySetByEmployerClient,
			action.payload
		);
		if (response.ok) {
			const emailActivities: Activity[] = yield response.json();
			const contacts: Loaded<Contact>[] = yield selectFromImmutable<Loaded<Contact>[]>(getContacts);
			const employerContactsInState = contacts.filter(
				contact => contact.employerId === action.payload
			);
			hydrateActivityContact(emailActivities, employerContactsInState);
			yield put(
				getEmailHistorySummarySetByEmployer.done({
					params: action.payload,
					result: emailActivities,
				})
			);
		} else {
			yield put(
				getEmailHistorySummarySetByEmployer.failed({
					params: action.payload,
					error: response.status,
				})
			);

			yield put(QueueSnackbar(snackBarProps));
		}
	} catch (error) {
		yield put(
			getEmailHistorySummarySetByEmployer.failed({
				params: action.payload,
				error: error,
			})
		);

		yield put(QueueSnackbar(snackBarProps));
	}
}
function getEmailHistorySummarySetByEmployerClient(id: string): Promise<any> {
	return http('Email/EmailHistorySummarySetByEmployer/' + id);
}

// Household
function* getEmailHistorySummarySetByHouseholdSaga(action: Action<string>) {
	try {
		const response = yield call(
			getEmailHistorySummarySetByHouseholdClient,
			action.payload
		);
		if (response.ok) {
			const emailActivities: Activity[] = yield response.json();
			const contacts: Loaded<Contact>[] = yield selectFromImmutable<Loaded<Contact>[]>(getContacts);
			const householdContactsInState = contacts.filter(
				contact => contact.householdId === action.payload
			);
			hydrateActivityContact(emailActivities, householdContactsInState);
			yield put(
				GetEmailHistorySummarySetByHousehold.done({
					params: action.payload,
					result: emailActivities,
				})
			);
		} else {
			yield put(
				GetEmailHistorySummarySetByHousehold.failed({
					params: action.payload,
					error: response.status,
				})
			);

			yield put(QueueSnackbar(snackBarProps));
		}
	} catch (error) {
		yield put(
			GetEmailHistorySummarySetByHousehold.failed({
				params: action.payload,
				error: error,
			})
		);

		yield put(QueueSnackbar(snackBarProps));
	}
}
function getEmailHistorySummarySetByHouseholdClient(id: string): Promise<any> {
	return http('Email/EmailHistorySummarySetByHousehold/' + id);
}

const snackBarProps: SnackbarProps = getSnackbarErrorProps('Unable to get Email History Summary set.', 6000);

export function hydrateActivityContact(
	activities: Activity[],
	contacts: Loaded<Contact>[],
	contactId?: string
) {
	activities.forEach(activity => {
		const contactInState = contacts.find(contact => {
			return contactId
				? contact.data.id == contactId
				: activity.contact && activity.contact.id == contact.data.id;
		});

		if (contactInState) activity.contact = contactInState.data;
	});
}

export function* emailSummarySagas() {
	yield all([
		takeLatestForActionType(
			getEmailHistorySummarySetByEmployer.started,
			getEmailHistorySummarySetByEmployerSaga
		),
		takeLatestForActionType(
			GetEmailHistorySummarySetByHousehold.started,
			getEmailHistorySummarySetByHouseholdSaga
		)
	])
}