import { SearchFilterFormValues } from './advanced_search_reducer';
import { Action as ReduxAction } from 'redux';
import { isType } from 'typescript-fsa';
import {
    CheckLeadConnectBalance,
    VerifyClickToCallContacts,
    CheckCallerId,
    StartClickToCallSession,
    SetupTwilioClient,
    GetClickToCallSession,
    GetClickToCallSessionHousehold,
    EndClickToCallSession,
    IncrementClickToCallCounter,
    IncrementHouseholdIndex,
    IncrementCallTimer,
    EndCallTimer,
    StartClickToCallPhoneCall,
    C2CSelectedContact,
    VerifyCallerId,
    StartCallTimer,
    StoreCallSid,
    ClearClickToCallData,
    TwilioOnReady,
} from '../actions/click_to_call_actions';
import { store } from '..';
import moment from 'moment'

export interface ClickToCallSession {
    id: string;
    userId: string;
    createdOn: Date;
    wasBilled: boolean;
    billedAmount: number;
    endedOn: Date;
    consumerSearchFilter: string;
    totalCallTime: number;
    timeoutMinutes: number;
    lastCallTime?: Date;
}

export interface CallerIdVerificationRequest {
    callerId: string;
}

export interface ContactVerificationRequest {
    contactIds: string[];
    criteria?: SearchFilterFormValues;
}

export interface ContactPhoneNumberResult {
    contactId: string;
    householdId: string;
    number: string;
    firstName: string;
    lastName: string;
    earliestDialTimeGmt: Date;
    latestDialTimeGmt: Date;
    dncReason: string;
    wasCalled: boolean;
}

export interface SessionHousehold {
    sessionId: string;
    households: ContactPhoneNumberResult[];
    consumerSearchFilters: SearchFilterFormValues;
}

export interface ContactVerificationResult {
    sessionHousehold: SessionHousehold;
    dncReasonCount: { [index: string]: number }
}

export interface TwilioAccessToken {
    token: string;
    identity: string;
}

export interface SessionSummary {
    sessionId: string;
    dispositions: SessionDispositionCount[];
    totalCallDuration: number;
    sessionCost: number;
    totalCalls: number;
}

export interface C2CMetaData {
    callsMade: number;
    callTimer: number;
    totalCallTimer: number;
    householdIndex: number;
    inCall: boolean;
    contact?: C2CSelectedContact;
    callSid: string;
    timeoutCheck: any
}
export interface SessionDispositionCount {
    disposition: string;
    count: number;
}

export interface ClickToCallState {
    hasSufficientFunds?: boolean;
    audioCheck?: boolean;
    verificationResult?: ContactVerificationResult,
    session?: ClickToCallSession,
    sessionHousehold?: SessionHousehold
    callerIdVerified?: boolean;
    loading: boolean;
    verifyingCallerId: boolean;
    enabled: boolean;
    twilioToken: string;
    isTwilioSetup: boolean;
    metadata: C2CMetaData;
    sessionSummary?: SessionSummary;
    summaryLoading: boolean;
    showSummaryDialog: boolean;
    verificationCode: string;
}

const initialState: ClickToCallState = {
    hasSufficientFunds: undefined,
    audioCheck: undefined,
    verificationResult: undefined,
    session: undefined,
    sessionHousehold: undefined,
    callerIdVerified: undefined,
    loading: false,
    verifyingCallerId: false,
    enabled: false,
    twilioToken: '',
    isTwilioSetup: false,
    metadata: {
        callsMade: 0,
        callTimer: 0,
        totalCallTimer: 0,
        householdIndex: 0,
        contact: undefined,
        inCall: false,
        callSid: '',
        timeoutCheck: 0
    },
    sessionSummary: undefined,
    summaryLoading: false,
    showSummaryDialog: false,
    verificationCode:''
}

export function clickToCallReducer(
    state: ClickToCallState = initialState,
    action: ReduxAction
): ClickToCallState {
    if (isType(action, CheckLeadConnectBalance.started)) {
        return {
            ...state,
            hasSufficientFunds: undefined
        }
    } else if (isType(action, CheckLeadConnectBalance.done)) {
        return {
            ...state,
            hasSufficientFunds: action.payload.result
        }
    } else if (isType(action, CheckLeadConnectBalance.failed)) {
        return {
            ...state,
            hasSufficientFunds: false
        }
    } else if (isType(action, CheckCallerId.done)) {
        return {
            ...state,
            callerIdVerified: action.payload.result,
            verificationCode: action.payload.result ? '' : state.verificationCode,
        }
    } else if (isType(action, CheckCallerId.started)) {
        return {
            ...state,
            callerIdVerified: undefined,
        };
    } else if (isType(action, VerifyCallerId.started)) {
        return {
            ...state,
            verifyingCallerId: true,
            callerIdVerified: undefined
        };
    } else if (isType(action, VerifyCallerId.done)) {
        return {
            ...state,
            verificationCode: action.payload.result,
            verifyingCallerId: false,
        };
    } else if (isType(action, VerifyCallerId.failed)) {
        return {
            ...state,
            verifyingCallerId: false,
            callerIdVerified: undefined
        };
    } else if (isType(action, CheckCallerId.failed)) {
        return {
            ...state,
            callerIdVerified: false,
        };
    } else if (isType(action, StoreCallSid)) {
        return {
            ...state,
            metadata: {
                ...state.metadata,
                callSid: action.payload
            }
        }
    } else if (isType(action, VerifyClickToCallContacts.started)) {
        return {
            ...state,
            verificationResult: undefined
        }
    } else if (isType(action, VerifyClickToCallContacts.done)) {
        return {
            ...state,
            verificationResult: action.payload.result,
        }
    } else if (isType(action, VerifyClickToCallContacts.failed)) {
        return {
            ...state,
            verificationResult: {
                sessionHousehold: {
                    sessionId: '',
                    households: [],
                    consumerSearchFilters: {}
                },
                dncReasonCount: {}
            }
        };
    } else if (isType(action, StartClickToCallSession.started)) {
        return {
            ...state,
            session: undefined,
            loading: true,
            enabled: false,
            sessionHousehold: state.verificationResult ? state.verificationResult.sessionHousehold : undefined
        };
    } else if (isType(action, StartClickToCallSession.done)) {
        const session = action.payload.result;

        let timeoutCheck = session ?
            setTimeout(() => {
                store.dispatch(GetClickToCallSession.started(undefined))
            }, (session!.timeoutMinutes + .5) * 60000)
            : 0;

        return {
            ...state,
            session,
            enabled: true,
            metadata: {
                ...state.metadata,
                timeoutCheck
            },
        };
    } else if (isType(action, StartClickToCallSession.failed)) {
        return {
            ...state,
            session: undefined,
            enabled: false
        };
    } else if (isType(action, SetupTwilioClient.done)) {
        return {
            ...state,
            twilioToken: action.payload.result,
        };
    } else if (isType(action, IncrementClickToCallCounter)) {
        return {
            ...state,
            metadata: {
                ...state.metadata,
                callsMade: state.metadata.callsMade + 1,
            },
        };
    } else if (isType(action, IncrementHouseholdIndex)) {
        return {
            ...state,
            metadata: {
                ...state.metadata,
                householdIndex: state.metadata.householdIndex + 1,
                callTimer: 0,
            },
        };
    } else if (isType(action, IncrementCallTimer)) {
        return {
            ...state,
            metadata: {
                ...state.metadata,
                callTimer: state.metadata.callTimer + 1,
                totalCallTimer: state.metadata.totalCallTimer + 1,
            },
        };
    } else if (isType(action, StartCallTimer)) {
        if (state.metadata.timeoutCheck > 0) {
            clearTimeout(state.metadata.timeoutCheck);
        }

        return {
            ...state,
            metadata: {
                ...state.metadata,
                callTimer: 0,
                timeoutCheck: 0
            },
        };
    } else if (isType(action, EndCallTimer)) {
        let timeoutCheck = state.session ?
            setTimeout(() => {
                store.dispatch(GetClickToCallSession.started(undefined))
            }, (state.session!.timeoutMinutes + .5) * 60000)
            : 0;

        return {
            ...state,
            metadata: {
                ...state.metadata,
                contact: undefined,
                inCall: false,
                timeoutCheck
            },
        };
    } else if (isType(action, StartClickToCallPhoneCall)) {
        return {
            ...state,
            metadata: {
                ...state.metadata,
                contact: action.payload,
                inCall: true,
            },
        };
    } else if (isType(action, GetClickToCallSession.done)) {
        let timeoutCheck: any = 0;
        if (action.payload.result != null) {
            let timeoutMinutes = action.payload.result.timeoutMinutes;
            if (action.payload.result.lastCallTime) {
                timeoutMinutes -= moment.duration(moment.utc().diff(action.payload.result.lastCallTime)).asMinutes();
            } else {
                timeoutMinutes -= moment.duration(moment.utc().diff(action.payload.result.createdOn)).asMinutes();
            }

            timeoutCheck = timeoutMinutes > 0 ?
                setTimeout(() => {
                    store.dispatch(GetClickToCallSession.started(undefined))
                }, (timeoutMinutes + .5) * 60000)
                : 0;
        }

        const enabled = action.payload.result != null && action.payload.result.endedOn == undefined;
        return {
            ...state,
            session: action.payload.result == null ? undefined : action.payload.result,
            enabled,
            metadata: {
                ...state.metadata,
                timeoutCheck
            }
        }
    } else if (isType(action, GetClickToCallSessionHousehold.done)) {
        return {
            ...state,
            sessionHousehold: action.payload.result
        }
    } else if (isType(action, EndClickToCallSession.started)) {
        return {
            ...state,
            sessionSummary: undefined,
            enabled: false,
            summaryLoading: true
        }
    } else if (isType(action, EndClickToCallSession.done)) {
        return {
            ...initialState,
            sessionSummary: action.payload.result,
        }
    } else if (isType(action, EndClickToCallSession.failed)) {
        return {
            ...state,
            sessionSummary: undefined,
            summaryLoading: false
        }
    } else if (isType(action, ClearClickToCallData)) {
        return {
            ...initialState,
            isTwilioSetup: state.isTwilioSetup,
        };
    } else if (isType(action, TwilioOnReady)) {
        return {
            ...state,
            isTwilioSetup: true,
        };
    } else {
        return { ...state };
    }
}
