import React from 'react';
import { FormControlLabel, RadioGroup, FormControl, Paper, Dialog, Radio, Slide, Divider, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { SaveCancelHeaderBarComponent } from '../../../components/Layout/SaveCancelHeaderBar';
import { Row } from '../../../components/Layout/table/filter_table';
import { FilterFieldProps, FilterComponents } from '../../../components/Layout/filter';
import { FilterFacet } from '../../../components/Layout/filter/filter_drawer';
import { DropdownFilter } from '../../../components/Layout/filter/drowdown_filter';
import { isBrokerage } from '../../../utilities/brokerage_utils';
import { SlideUp } from '../../../components/utility/transition';

type Props<T> = {
    handleSelect: (option?: T) => void;
    handleCancel: () => void;
    options: { [label: string]: T };
    title: string;
    open: boolean;
    labelKey: keyof T;
    filters?: FilterFieldProps[];
    secondaryOptions?: { [label: string]: T };
    saveText?: string;
};

type State = {
    selection?: string;
    filterSelection: { [filterLabel: string]: FilterFacet };
};


export class RadioSelectDialog<T extends Row> extends React.Component<Props<T>, State> {
    constructor(props: Props<T>) {
        super(props);
        this.state = {
            filterSelection: {}
        }
    }

    componentDidMount() {
        this.setDefaultSelection(false);
        this.setState({ selection: undefined });
    }

    componentWillUnmount() {
        this.setState({ selection: undefined });
    }

    setDefaultSelection = (reset: boolean, callback?: () => void) => {
        const { filters = [] } = this.props;
        const filterSelection: { [filterLabel: string]: FilterFacet } = {}

        filters.forEach(f => {
            const facet = {
                filterFunction: () => true,
                selection: f.defaultSelection || {},
                hintText: '',
            };

            if (reset) {
                facet.selection = f.defaultSelection || {};
            };

            filterSelection[f.label] = facet;
        });
        this.setState({ filterSelection }, callback);
    }

    onSave = () => {
        const { options, secondaryOptions = {}, handleSelect } = this.props;
        const { selection = '' } = this.state;

        const templateName =
            Object.keys(options).find(key => options[key].id == selection) ||
            Object.keys(secondaryOptions).find(key => secondaryOptions[key].id == selection) ||
            '';
        const template = options[templateName] || secondaryOptions[templateName];
        handleSelect(template);
    }

    handleChange = (event) => this.setState({ selection: event.target.value });

    handleUpdateFilterFacet = (label: string, facet: FilterFacet) => {
        const { filterSelection } = this.state;

        this.setState({
            filterSelection: {
                ...filterSelection,
                [label]: facet
            },
        })
    };

    renderFilterComponent = (opts: FilterFieldProps) => {
        const { filterSelection } = this.state;

        const selection = filterSelection[opts.label] && filterSelection[opts.label].selection
            ? filterSelection[opts.label].selection
            : opts.defaultSelection || {};

        const props = {
            key: opts.label,
            updateFilterFacet: this.handleUpdateFilterFacet,
            initialSelection: selection,
            ...opts,
        }

        switch (opts.component) {
            case (FilterComponents.Dropdown):
                return <DropdownFilter {...props} />

            case (FilterComponents.MultiselectDropdown):
                return <DropdownFilter {...props} multiselect />
        }
    }

    renderFilteredRows = (dictionaryOptions?: { [label: string]: T }) => {
        if (dictionaryOptions) {
            const { filterSelection } = this.state;
            const { labelKey } = this.props;

            const options = Object.keys(dictionaryOptions).map(opt => dictionaryOptions[opt]);

            const filterArr = Object.keys(filterSelection)
                .map(f => filterSelection && filterSelection[f]
                    ? filterSelection[f].filterFunction
                    : () => true
                );

            const filteredRows = options.filter(row => filterArr.every(fn => fn(row)));

            return filteredRows.map(row => <FormControlLabel
                value={row.id}
                key={'selection-' + row.id}
                control={<Radio />}
                label={row[labelKey]}
                color="secondary"
            />)
        }
    };

    render() {
        const { title, options, secondaryOptions, open, filters = [], handleCancel, saveText } = this.props;

        return (
            <Dialog
                fullWidth
                maxWidth="sm"
                open={open}
                TransitionComponent={SlideUp}
                onEscapeKeyDown={handleCancel}
                scroll="paper"
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    {filters.map(this.renderFilterComponent)}
                    <FormControl component="fieldset" fullWidth>
                        <RadioGroup
                            key="radio-group"
                            name="reason"
                            onChange={this.handleChange}
                            value={this.state.selection}
                        >
                            {this.renderFilteredRows(options)}
                            {!isBrokerage && secondaryOptions != undefined && <Divider key="radio-divider" />}
                            {this.renderFilteredRows(secondaryOptions)}
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={this.onSave} color="secondary" disabled={!Boolean(this.state.selection)} autoFocus>{saveText || 'Save'}</Button>
                </DialogActions>
            </Dialog>
        );
    }
};