import { FC } from 'react';
import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { Strings } from '../../assets/common/strings';


export type Props = {
    visible: boolean;
    headerText: Strings.LoginState;
    loading: boolean;
}

export const VersionCheck: FC<Props> = (props: Props) => props.visible
    ? <div>
        {props.headerText !== Strings.LoginState.DisplayForm && props.headerText !== Strings.LoginState.CheckingAuth && <h2>{props.headerText}</h2>}
        {props.loading && <CircularProgress color="secondary" />}
    </div>
    : null;

export default VersionCheck;