import H from 'history';
import { Button, Dialog, DialogContent, DialogActions, DialogTitle, Grid } from '@material-ui/core';
import React from 'react';
import { isNullOrUndefined } from 'util';

import { themePalette } from '../../utilities/branding';
import { Application } from '../../reducers/application_reducer';
import { Address, Contact, Email, Phone } from '../../reducers/ContactReducer';
import { Employer } from '../../reducers/employer_reducer';
import { Policy, ProductStatus } from '../../reducers/policy_reducer';
import { Loaded } from '../../utilities/utilities';
import { PolicyMatchCard } from './policy_match_card';
import { PolicyAdminCard } from './PolicyAdmin';
import { PolicyApplicationInformationCard } from './PolicyApplicationInformation';
import { PolicyDetailInformationCard } from './PolicyDetailInformation';
import { PolicyGeneralCard } from './PolicyGeneral';
import { PolicyInsuredPeopleCard } from './PolicyInsuredPeople';
import { PolicyStatusInformationCard } from './PolicyStatusInformation';
import { Strings } from '../../assets/common/strings';
import { toCurrencyString } from '../../assets/common/string_builders';
import { normalizePhone, getYesNoFromBool } from '../../utilities/formatting/data_normalizations';
import { getFormattedDate } from '../../utilities/date_util';

interface Props {
	policy?: Loaded<Policy>;
	currentApplication?: Loaded<Application>;
	loadedContacts: Loaded<Contact>[];
	hasMoreContacts: boolean;
	history: any;
	loadedEmployers: Loaded<Employer>[];
	isLoading: boolean;
	unlinkPolicy: (policy: Policy) => void;
	handleContactSearch: (searchString: string) => void;
	resetLiveSearch: () => void;
	navigateToWithoutAddingToHistory: (route: string) => void;
	getHouseholdContacts: (householdId: string) => void;
	matchPolicy: (policy: Policy) => void;
	unmatchPolicy: (policyId: string) => void;
	searchEmployers: (searchText: string) => void;
	getEmployer: (id: string) => void;
}
interface State {
	modalVisible: boolean;
}
export class PolicyDetail extends React.PureComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			modalVisible: false,
		};
	}

	closeModal = () => this.setState({ modalVisible: false });

	unlinkPolicy = () => {
		this.closeModal();
		if (
			this.props.currentApplication &&
			this.props.policy &&
			this.props.policy.data.applicationId
		) {
			this.props.unlinkPolicy(this.props.policy.data);
		}
	};

	renderUnlinkConfirmation = () => {
		return (
			<div>
				<Dialog
					open={this.state.modalVisible}
					onClose={this.closeModal}
				>
					<DialogTitle>Confirmation</DialogTitle>
					<DialogContent>
						Are you sure you want to unlink this application?
					</DialogContent>
					<DialogActions>
						<Button
							color="secondary"
							onClick={this.closeModal}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							style={{ backgroundColor: themePalette.accept_button }}
							onClick={this.unlinkPolicy}
						>
							Unlink
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	};

	isEmployerPolicy = (policy?: Policy) => {
		if (policy) {
			if (
				!(
					policy.productType &&
					policy.productType.toLowerCase().includes('small')
				) &&
				!(
					policy.insuredType &&
					policy.insuredType.toLowerCase().includes('small')
				)
			) {
				if (policy.primaryInsured !== null) {
					return false;
				}
				return false;
			} else {
				if (policy.employerId) {
					return true;
				}
				return true;
			}
		}
		return false;
	}

	render() {
		const policy: Policy = this.props.policy
			? this.props.policy.data
			: ({} as Policy);

		const carrier = policy.carrier || '';
		const productType = policy.productType || '';
		const productName = policy.productName || '';
		const policyStatusEnum = policy.policyStatus || -1;
		const policyStatus = ProductStatus[policyStatusEnum];
		const statusReason = policy.statusReason || '';
		const carrierStatus = policy.carrierStatus || '';
		const policyStatusDate = getFormattedDate(policy.policyStatusDate, 'MM/DD/YY', false)
		const statusReasonDate = getFormattedDate(policy.statusReasonDate, 'MM/DD/YY', false)
		const policyNumber = policy.policyNumber || '';
		const issuedDate = getFormattedDate(policy.issuedDate, 'MM/DD/YY', false);
		const effectiveDate = getFormattedDate(policy.effectiveDate, 'MM/DD/YY', false)
		const faceAmount = toCurrencyString(policy.faceAmount)
		const paymentMode = policy.paymentMode || '';
		const insuredType = policy.insuredType || '';
		const annualPremium = toCurrencyString(policy.annualPremium);
		const applicationNumber = policy.applicationNumber || '';
		const applicationDate = getFormattedDate(policy.applicationDate, 'MM/DD/YY', false);
		const submittedDate = getFormattedDate(policy.submittedDate, 'MM/DD/YY', false);
		const paidThroughDate = getFormattedDate(policy.paidThroughDate, 'MM/DD/YY', false);
		const benefitAmount = toCurrencyString(policy.benefitAmount);

		const primaryInsured: Contact | undefined =
			policy.primaryInsured || ({} as Contact);
		let firstName: string = primaryInsured.firstName || '';
		let lastName: string = primaryInsured.lastName || '';

		const addresses: Address[] = primaryInsured.addresses || [];
		const primaryAddressIndex: number = addresses.findIndex(address => {
			return address.isPreferred;
		});

		let address: Address = {} as Address;
		if (primaryAddressIndex !== -1) {
			address = addresses[primaryAddressIndex];
		} else if (!isNullOrUndefined(addresses) && addresses.length > 0) {
			address = addresses[0];
		}
		const line1 = address.line1 || '';
		const line2 = address.line2 || '';
		const city = address.city || '';
		const state = address.state || policy.state || '';
		const zipCode = address.zipCode || '';

		const phones: Phone[] = primaryInsured.phones || [];
		let homePhoneIndex = phones.findIndex((phone: Phone) => {
			return phone.type === Strings.PhoneTypes.Home && phone.isPreferred === true;
		});
		if (homePhoneIndex === -1) {
			homePhoneIndex = phones.findIndex((phone: Phone) => {
				return phone.type === Strings.PhoneTypes.Home;
			});
		}
		let homePhone: string;
		if (homePhoneIndex > -1) {
			homePhone = primaryInsured.phones[homePhoneIndex].number || '';
			homePhone = normalizePhone(homePhone);
		} else {
			homePhone = '';
		}

		const emails: Email[] = primaryInsured.emails || [];
		let businessEmailIndex = emails.findIndex((email: Email) => {
			return email.type === 'Work' && email.isPreferred === true;
		});
		if (businessEmailIndex === -1) {
			businessEmailIndex = emails.findIndex((email: Email) => {
				return email.type === 'Work';
			});
		}
		let businessEmail: string;
		if (businessEmailIndex > -1) {
			businessEmail =
				primaryInsured.emails[businessEmailIndex].emailAddress || '';
		} else {
			businessEmail = '';
		}

		const createdOn = getFormattedDate(policy.createdOn, 'MM/DD/YY, hh:mm A', true);
		const createdByAgentName = policy.createdByAgentName || '';
		const modifiedOn = getFormattedDate(policy.updatedOn, 'MM/DD/YY, hh:mm A', true);
		const updatedByAgentName = policy.updatedByAgentName || '';
		const isReviewedString = policy.isReviewed || '';
		const isReviewed = getYesNoFromBool(Boolean(isReviewedString));

		return (
			<Grid item>
				<PolicyMatchCard
					title={this.isEmployerPolicy(this.props.policy?.data) ? 'Employer' : 'Person'}
					
					policy={this.props.policy ? this.props.policy.data : ({} as Policy)}
					loadedContacts={this.props.loadedContacts}
					handleContactSearch={this.props.handleContactSearch}
					hasMoreContacts={this.props.hasMoreContacts}
					resetLiveSearch={this.props.resetLiveSearch}
					navigateToWithoutAddingToHistory={this.props.navigateToWithoutAddingToHistory}
					getHouseholdContacts={this.props.getHouseholdContacts}
					matchPolicy={this.props.matchPolicy}
					unmatchPolicy={this.props.unmatchPolicy}
					handleEmployerSearch={this.props.searchEmployers}
					loadedEmployers={this.props.loadedEmployers}
					getEmployer={this.props.getEmployer}
					isLoading={this.props.isLoading}
				/>

				<PolicyGeneralCard
					title={'General'}
					carrier={carrier}
					primaryInsured={
						firstName
							? firstName + ' ' + lastName
							: `${policy.primaryInsuredName || ''}`
					}
					productType={productType}
					productName={productName}
				/>
				<PolicyStatusInformationCard
					title={'Status Information'}
					policyStatus={policyStatus}
					statusReason={statusReason}
					carrierStatus={carrierStatus}
					policyStatusDate={policyStatusDate}
					statusReasonDate={statusReasonDate}
					cancellationReason={policy.cancellationReason}
				/>
				<PolicyDetailInformationCard
					title={'Policy Information'}
					policyNumber={policyNumber}
					issuedDate={issuedDate}
					effectiveDate={effectiveDate}
					faceAmount={faceAmount}
					paymentMode={paymentMode}
					insuredType={insuredType}
					annualPremium={annualPremium}
					paidThroughDate={paidThroughDate}
					benefitAmount={benefitAmount}
				/>
				<PolicyApplicationInformationCard
					title={'Application Information'}
					applicationNumber={applicationNumber}
					applicationDate={applicationDate}
					submittedDate={submittedDate}
				/>

				<PolicyInsuredPeopleCard
					title={'Primary Insured'}
					firstName={firstName}
					lastName={lastName}
					line1={line1}
					line2={line2}
					city={city}
					state={state}
					zipCode={zipCode}
					preferredPhone={homePhone}
					preferredEmail={businessEmail}
					nameFromCarrier={policy.primaryInsuredName}
				/>

				<PolicyAdminCard
					title={'Admin'}
					createdOn={createdOn}
					modifiedOn={modifiedOn}
					isReviewed={isReviewed}
					createdByAgentName={createdByAgentName}
					updatedByAgentName={updatedByAgentName}
				/>

				{this.props.currentApplication && (
					<Grid container>
						<Button
							variant="contained"
							color="primary"
							fullWidth
							style={{ margin: 10 }}
							onClick={() => {
								this.setState({
									modalVisible: true,
								});
							}}
						>
							Unlink Policy
						</Button>
					</Grid>
				)}
				{this.renderUnlinkConfirmation()}
			</Grid>
		);
	}
}
