import React from 'react';
import { TextField } from '@material-ui/core';
import { themePalette } from '../../utilities/branding';

interface Props {
	value: string | number | undefined;
	label?: string;
	multiline?: boolean;
	style?: React.CSSProperties;
}

export const ReadOnlyTextField: React.FC<Props> = (props: Props) => {
	const value = props.value || '';

	return (
		<TextField
			label={props.label}
			value={value}
			disabled={true}
			multiline={props.multiline}
			fullWidth={true}
			inputProps={{
				style: {
					...props.style,
					color: themePalette.primary_text,
					overflow: 'hidden',
					textOverflow: 'ellipsis',
				},
			}}
			InputProps={{ disableUnderline: true }}
			InputLabelProps={{ shrink: true }}
		/>
	);
};
