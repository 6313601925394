import { call, put, all } from 'redux-saga/effects';
import {
	CreateNote,
	createNoteSuccess,
	createNoteFailure,
	DeleteNote,
	deleteNoteSuccess,
	deleteNoteFailure,
	UpdateNote,
} from '../actions/note_actions';
import http from '../utilities/http';
import { Note } from '../reducers/note_reducer';
import { submitLog } from '../utilities/logging_util';
import { Strings } from '../assets/common/strings';
import { takeLatestForActionType } from '../utilities/saga_util';
import { Action } from 'typescript-fsa';

//////////  Create Note  //////////
function* createNoteSaga(action: Action<Note>) {
	try {
		const response = yield call(createNoteCall, action.payload);

		if (response.ok) {
			const data = yield response.json();
			yield put(createNoteSuccess(action.payload, data));
			submitLog(Strings.Log.Log, `Created note`, { data });
		} else {
			yield put(
				createNoteFailure(action.payload, { errorCode: response.status })
			);
		}
	} catch (error) {
		submitLog(Strings.Log.Error, `Error creating note`, {
			error,
			payload: action.payload,
		});
		yield put(createNoteFailure(action.payload, error));
	}
}

function createNoteCall(noteToCreate: Note): Promise<any> {
	return http('note/AddNote/', {
		method: 'POST',
		body: JSON.stringify(noteToCreate),
	});
}

function* editNoteSaga(action: Action<Note>) {
	try {
		const response = yield call(editNoteCall, action.payload);

		if (response.ok) {
			const result: Note = yield response.json();
			yield put(UpdateNote.done({ params: action.payload, result: result }));
			submitLog(Strings.Log.Log, `Created note`, { result });
		} else {
			yield put(
				UpdateNote.failed({
					params: action.payload,
					error: response.status
				})
			);
		}
	} catch (error) {
		submitLog(Strings.Log.Error, `Error creating note`, {
			error,
			payload: action.payload,
		});
		yield put(UpdateNote.failed({
			params: action.payload,
			error
		}));
	}
}

function editNoteCall(noteToEdit: Note): Promise<any> {
	return http('note/EditNote/', {
		method: 'POST',
		body: JSON.stringify(noteToEdit),
	});
}

//////////  Delete Note  //////////
function* deleteNoteSaga(action: Action<Note>) {
	try {
		const response = yield call(deleteNoteCall, action.payload);

		if (response.ok) {
			yield put(deleteNoteSuccess(action.payload, action.payload.id));
		} else {
			yield put(
				deleteNoteFailure(action.payload, { errorCode: response.status })
			);
		}
	} catch (error) {
		submitLog(Strings.Log.Error, `Error deleting note`, {
			error,
			payload: action.payload,
		});
		yield put(deleteNoteFailure(action.payload, error));
	}
}

function deleteNoteCall(noteToDelete: Note): Promise<any> {
	return http('note/DeleteNote/', {
		method: 'POST',
		body: JSON.stringify(noteToDelete),
	});
}


export function* noteSagas() {
	yield all([
		takeLatestForActionType(
			CreateNote.started,
			createNoteSaga
		),
		takeLatestForActionType(
			UpdateNote.started,
			editNoteSaga
		),
		takeLatestForActionType(
			DeleteNote.started,
			deleteNoteSaga
		),
	]);
}