import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Button, Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText, Grid, TextField, InputAdornment, Icon } from '@material-ui/core';
import { themePalette } from '../../utilities/branding';
import { Lead } from '../../reducers/LeadReducer';
import { ToggleFactFinderContactLinkDialog } from '../../actions/dialogs_actions';
import { connect, FormActions, FormSlice, useFormState } from '@optum-uhone-hmkts/rise';
import { LeadFilters } from '../../reducers/LeadFilterReducer';
import { formatLeadFiltersPayload, extractLeadInfo } from '../../utilities/lead_util';
import { GetFilteredLeads } from '../../actions/lead_actions';
import { Lookups } from '../../utilities/lookup';
import { Loaded } from '../../utilities/utilities';
import LoadingList from '../../components/utility/loading_list';
import { Contact, HouseholdRole } from '../../reducers/ContactReducer';
import { Strings } from '../../assets/common/strings';

interface DispatchProps {
    onClose: () => void;
    setFactFinderContacts: (contacts: Contact[]) => void;
    getFilteredLeads: (
        filters: LeadFilters,
        pageNum: number,
        pageSize: number
    ) => any;
};

interface StateProps {
    isOpen: boolean;
    fromDate: Date;
    toDate: Date;
    leads: Loaded<Lead>[];
    isLoading: boolean;
};

type Props = DispatchProps & StateProps;

const _FactFinderContactLinkDialog: React.FC<Props> = (props) => {
    const { forms } = useFormState();
    const { isOpen, onClose, leads } = props;
    const [searchCriteria, handleChange] = useState('');
    const [contacts, setContacts] = useState([] as Loaded<Lead>[]);
    const [searched, hasSearched] = useState(false);

    useEffect(() => {
        searched && setContacts(leads);
    }, [leads, searched])

    const searchLeads = (event) => {
        event.preventDefault();
        const leadFilter: LeadFilters = {
            callAttempts: 'All',
            campaignFilter: '',
            dateTypeFilter: '',
            filterBySearch: searchCriteria,
            fromDate: props.fromDate,
            inputSourceFilter: [] as string[],
            leadTypeFilter: [] as string[],
            ratingFilter: 'All',
            sortBy: 'Newest to Oldest by Created Date',
            sortCategory: '',
            sortDirection: '',
            vendorFilter: 'All',
            toDate: props.toDate,
            statusFilter: Lookups.All,
            statusGroupFilter: Lookups.All,
            lineOfBusinessFilter: 'All'
        }

        props.getFilteredLeads(leadFilter, 0, 20);
        hasSearched(true);
    }

    const handleClick = (leadInfo) => {
        props.setFactFinderContacts(leadInfo.contacts);
        handleClose();
    }

    const handleClose = () => {
        hasSearched(false);
        setContacts([]);
        handleChange('');
        onClose();
    }

    const contactForms = Object.keys(forms).map(f => forms[f]);

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            style={{ overflowY: 'inherit' }}
            fullWidth
        >
            <DialogContent
                style={styles.content}
            >
                <DialogTitle style={{ padding: '0px 0px 8px 0px' }}>
                    Contact Link
                </DialogTitle>
                <DialogContentText style={styles.dialogFont}>
                    Search by first name, last name, email, or phone number
                </DialogContentText>
                <form onSubmit={searchLeads}>

                    <TextField
                        autoFocus
                        name="filterBySearchField"
                        label=""
                        value={searchCriteria}
                        onChange={(e) => handleChange(e.target.value)}
                        fullWidth
                        placeholder="Enter info"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon>search</Icon>
                                </InputAdornment>
                            ),
                        }}
                    />
                </form>

            </DialogContent>
            {props.isLoading
                ? <LoadingList size={3} type={'text'} style={{ border: `.5px solid ${themePalette.tertiary_text}` }} />
                : contacts?.length > 0 &&
                <DialogContent
                    style={styles.leadListContainer}
                >
                    <DialogTitle style={{ padding: '0px 0px 8px 0px' }}>
                        Results:
                        </DialogTitle>
                    {contacts.map(lead => {
                        const leadInfo = extractLeadInfo(lead.data);
                        const primaryContact = leadInfo.contact;
                        const email = primaryContact.emails?.length > 0 && primaryContact.emails[0].emailAddress;
                        const phoneNumber = primaryContact.phones?.length > 0 && primaryContact.phones[0].number;

                        const leadHasForm = contactForms.some(f => {
                            return f.activeForm
                                && f.firstName === primaryContact.firstName
                                && f.lastName === primaryContact.lastName
                        })

                        return (
                            <Grid key={lead.data.id} style={styles.leadGrid}>
                                <Grid item>
                                    <DialogContentText style={styles.leadName}>
                                        Name: {leadInfo.headerName}
                                    </DialogContentText>
                                    {email &&
                                        <DialogContentText style={styles.leadName}>
                                            Email: {email}
                                        </DialogContentText>
                                    }
                                    {phoneNumber &&
                                        <DialogContentText style={styles.leadName}>
                                            Phone number: {phoneNumber}
                                        </DialogContentText>
                                    }
                                </Grid>
                                <DialogActions>
                                    {!leadHasForm ? <Button
                                        variant="contained"
                                        color="secondary"
                                        style={{ color: "#fff" }}
                                        onClick={() => handleClick(leadInfo)}
                                    >
                                        Link contact
                                        </Button>
                                        : <Button
                                            variant="contained"
                                            color="secondary"
                                            style={{ color: "#fff" }}
                                            disabled={leadHasForm}
                                        >
                                            Active Fact Finder
                                        </Button>
                                    }
                                </DialogActions>
                            </Grid>
                        );
                    })}
                </DialogContent>
            }
            <DialogActions>
                <Grid container item xs={12} justify="flex-end">
                    <Grid item>
                        <Button
                            onClick={handleClose}
                            color="secondary"
                        >
                            Close
                    </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={searchLeads}
                        >
                            Search
                    </Button>
                    </Grid>
                </Grid>
            </DialogActions>

        </Dialog>
    );
};

function mapStateToProps(state): StateProps {
    return {
        isOpen: state.leadSearchDialog.isOpen,
        fromDate: state.leadFilters.fromDate,
        toDate: state.leadFilters.toDate,
        leads: state.lead.leads,
        isLoading: state.lead.isLoading
    };
}

function mapDispatchToProps(dispatch: any): DispatchProps {
    return {
        onClose: () => dispatch(ToggleFactFinderContactLinkDialog(false)),
        setFactFinderContacts: (contacts: Contact[]) => {
            dispatch(FormSlice.actions.setFactFinderContacts(contacts));
            const primaryContact = contacts.find(c => c.householdRole === HouseholdRole.Primary);
            const primaryAddress = primaryContact?.addresses.find(a => a.isPreferred === true);
            if (primaryAddress && primaryAddress.zipCode && !primaryAddress.county) {
                setTimeout(() => {
                    dispatch(FormActions.zipCodeLookup(primaryAddress.zipCode) as any);
                }, 1000)
            }
        },
        getFilteredLeads: (
            leadFilters: LeadFilters,
            pageNum: number,
            pageSize: number
        ) => {
            const payload = formatLeadFiltersPayload(leadFilters, pageNum, pageSize);
            return dispatch(GetFilteredLeads.started(payload));
        },
    };
}

export const FactFinderContactLinkDialog = connect(
    mapStateToProps,
    mapDispatchToProps,
    true,
)(_FactFinderContactLinkDialog) as React.ComponentClass<{}>;

const styles: { [key: string]: React.CSSProperties } = {
    topPadding: {
        paddingTop: '8px',
    },
    dialogFont: {
        fontSize: '14px',
        marginBottom: 0
    },
    dialogField: {
        fontSize: '13px',
        paddingTop: '8px',
    },
    dialogSpanText: {
        color: themePalette.primary_text,
    },
    leadListContainer: {
        padding: '24px 24px 0px 24px',
        marginBottom: '24px',
        maxHeight: '40vh'
    },
    leadGrid: {
        border: `.5px solid ${themePalette.tertiary_text}`,
        borderRadius: 5,
        margin: '5px auto',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    leadName: {
        color: themePalette.primary_text,
        paddingLeft: 8,
        margin: '10px auto'
    },
    loader: {
        margin: '8px',
        textAlign: 'center',
        fontSize: '15px',
        clear: 'both',
    },
};