import React from 'react';
import Autosuggest from 'react-autosuggest';
import { MenuItem, Paper, TextField } from '@material-ui/core';

export type AutocompleteValue =
	| string
	| { value: string | number | boolean; text: string };

interface AutocompleteProps {
	label: string;
	dataSource: AutocompleteValue[];
	disabled?: boolean;
	maxResults?: number;
	touched?: boolean;
	error?: string;
	warning?: string;
	onBlur?: () => void;
	onFocus?: () => void;
	onNewRequest?: (newValue: AutocompleteValue) => void;
	onUpdateInput?: (value: string) => void;
	fullWidth?: boolean;
	initialValue?: string;
	clearOnSelect?: boolean;
}

interface AutocompleteState {
	value: string;
	suggestions: AutocompleteValue[];
	isSelected: boolean;
}

class AutoComplete extends React.Component<
	AutocompleteProps,
	AutocompleteState
> {
	constructor(props: AutocompleteProps) {
		super(props);
		this.state = {
			value: props.initialValue || '',
			suggestions: [],
			isSelected: false,
		};
	}

	componentWillReceiveProps(nextProps: AutocompleteProps) {
		if (nextProps.initialValue != this.props.initialValue) {
			this.setState({ value: nextProps.initialValue || '' });
		}
	}

	getSuggestions = (value: string) => {
		const inputValue = value.trim().toLowerCase();
		const maxResults = this.props.maxResults || 100000000;
		let count = 0;

		return this.props.dataSource.filter((item: any) => {
			const itemValue = item.text || item || '';
			const keep =
				count < maxResults &&
				inputValue.length >= 2 &&
				itemValue
					.toLowerCase()
					.trim()
					.indexOf(inputValue.toLowerCase()) !== -1;

			if (keep) {
				count += 1;
			}

			return keep;
		});
	};

	handleSuggestionsFetchRequested = ({ value }: any) => {
		this.setState({
			suggestions: this.getSuggestions(value),
			isSelected: false,
		});
	};

	handleSuggestionsClearRequested = () => {
		this.setState({
			suggestions: [],
			isSelected: false,
		});
	};

	handleChange = (event: any) => {
		this.setState({
			value: event.target.value,
			isSelected: false,
		});
		if (this.props.onUpdateInput) this.props.onUpdateInput(event.target.value);
	};

	handleSelect = (data: any) => {
		this.setState({
			value: this.props.clearOnSelect ? '' : data.text,
			isSelected: true,
		});
		if (this.props.onNewRequest) this.props.onNewRequest(data);
	};

	renderInput = () => {
		const id = `autocomplete_${Math.random()}`;
		const error = !!(
			this.props.touched &&
			(this.props.error || this.props.warning)
		);
		const errorText = this.props.error || this.props.warning;
		return (
			<TextField
				label={this.props.label}
				id={id}
				value={this.state.value}
				disabled={this.props.disabled}
				onBlur={this.props.onBlur}
				onFocus={this.props.onFocus}
				fullWidth={this.props.fullWidth}
				error={error}
				helperText={errorText}
				onChange={this.handleChange}
			/>
		);
	};

	renderSuggestion = (suggestion: any, { query, isHighlighted }: any) => {
		return (
			<MenuItem
				key={suggestion.value}
				onClick={() => this.handleSelect(suggestion)}
			>
				{suggestion.text}
			</MenuItem>
		);
	};

	renderSuggestionsContainer = (options: any) => {
		let { containerProps, children } = options;
		if (
			!children &&
			!this.state.isSelected &&
			options.query.length >= 2 &&
			!this.props.disabled
		) {
			children = this.getSuggestions(options.query).map(item => {
				return this.renderSuggestion(item, {
					query: options.query,
					isHighlighted: false,
				});
			});
		}
		return (
			<Paper {...containerProps} square>
				{children}
			</Paper>
		);
	};

	render() {
		return (
			<Autosuggest
				renderInputComponent={this.renderInput}
				suggestions={this.state.suggestions}
				onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
				onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
				onSuggestionSelected={this.handleSelect}
				renderSuggestionsContainer={this.renderSuggestionsContainer}
				getSuggestionValue={(suggestion: any) => suggestion.value || suggestion}
				renderSuggestion={this.renderSuggestion}
				inputProps={{
					autoFocus: true,
					value: this.state.value,
					onChange: this.handleChange,
				}}
			/>
		);
	}
}

export default AutoComplete;
