import { Action as ReduxAction } from 'redux';
import { isType } from 'typescript-fsa';
import { LogToSplunk, SentLogs } from '../actions/logging_actions';
import uuid from 'uuid';
import { Dictionary } from '../utilities/object_util';

export interface Message {
	level:
	| 'debug'
	| 'info'
	| 'warn'
	| 'error'
	| 'crit'
	| 'emerg'
	| 'alert'
	| 'log'
	| 'notice';
	message: string;
	metadata?: any;
}

export interface LoggingState {
	messages: Dictionary<Message>;
}

const initialState = {
	messages: {},
};

export function loggingReducer(
	state: LoggingState = initialState,
	action: ReduxAction
): LoggingState {
	if (isType(action, LogToSplunk.started)) {
		const id = uuid.v4();
		return {
			...state,
			messages: { ...state.messages, [id]: action.payload }
		};
	} else if (isType(action, SentLogs)) {
		const nextState = { ...state };
		action.payload.forEach(id => {
			if (nextState.messages.hasOwnProperty(id)) {
				delete nextState.messages[id];
			}
		});
		return nextState;
	} else {
		return state;
	}
}
