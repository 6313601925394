import { Action as ReduxAction } from 'redux';
import {
	GetHousehold,
	SetNewPrimaryContactOnHousehold,
	RemoveContactFromHousehold,
	ReassignHousehold,
	MergeHouseholds,
	saveHouseholdTags
} from '../actions/household_actions';
import { isType } from 'typescript-fsa';
import { ClearCaches } from '../actions/authentication_actions';
import { Loaded } from '../utilities/utilities';
import { EMPTY_HOUSEHOLD_META_DATA } from '../utilities/empty_entities';
import { Logout } from '../actions/authentication_actions';
import { FullHousehold } from '../sagas/household_sagas';

export interface HouseholdMetaData extends FullHousehold {
	id: string;
	createdOn: Date;
	createdBy: string;
	updatedOn: Date;
	updatedBy: string;
	updatedByName: string;
	createdByName: string;
}

export interface HouseholdMetaDataState {
	loadedHouseholdMetaData: Loaded<HouseholdMetaData>[];
	householdMergeLoading: boolean;
}

const initialState: HouseholdMetaDataState = {
	loadedHouseholdMetaData: [],
	householdMergeLoading: false

};

export function householdReducer(
	state: HouseholdMetaDataState = initialState,
	action: ReduxAction
): HouseholdMetaDataState {
	if (isType(action, GetHousehold.started)) {
		const householdsInState = state.loadedHouseholdMetaData.slice();
		updateStateWithLoadingHousehold(householdsInState, action.payload);
		return {
			...state,
			loadedHouseholdMetaData: householdsInState,
		};
	} else if (isType(action, saveHouseholdTags.started)) {
		const householdsInState = state.loadedHouseholdMetaData.slice();
		updateStateWithLoadingHousehold(householdsInState, action.payload.householdId);
		return {
			...state,
			loadedHouseholdMetaData: householdsInState,
		};
	} else if (
		isType(action, GetHousehold.done) ||
		isType(action, SetNewPrimaryContactOnHousehold.done) ||
		isType(action, RemoveContactFromHousehold.done)
	) {
		const householdsInState = state.loadedHouseholdMetaData.slice();
		const inboundHousehold = {
			...action.payload.result,
			id: action.payload.result.householdId,
		};
		updateStateWithInboundHouseholdMetaData(
			householdsInState,
			inboundHousehold
		);
		return {
			...state,
			loadedHouseholdMetaData: householdsInState,
		};
	} else if (isType(action, ReassignHousehold.done)) {
		const cachedHouseholds = state.loadedHouseholdMetaData.filter(
			household => household.data.id !== action.payload.params.householdId
		);
		return {
			...state,
			loadedHouseholdMetaData: cachedHouseholds,
		};
	} else if (
		isType(action, MergeHouseholds.done) ||
		isType(action, MergeHouseholds.failed)) {
		return {
			...state,
			householdMergeLoading: false,
		};
	} else if (isType(action, MergeHouseholds.started)) {
		return {
			...state,
			householdMergeLoading: true,
		};
	} else if (isType(action, Logout.started)) {
		return {
			...initialState,
		};
	} else if (isType(action, ClearCaches)) {
		return {
			...initialState,
		};
	} else {
		return state;
	}
}

function updateStateWithLoadingHousehold(
	householdsInState: Loaded<HouseholdMetaData>[],
	householdId: string
) {
	const targetIndex = householdsInState.findIndex(
		householdInState =>
			householdInState.householdId == householdId ||
			householdInState.data.id == householdId
	);

	if (targetIndex > -1) {
		householdsInState[targetIndex] = {
			...householdsInState[targetIndex],
			loading: true,
		};
	} else {
		householdsInState.push({
			loading: false,
			data: {
				...EMPTY_HOUSEHOLD_META_DATA,
				id: householdId,
			},
			errors: [],
			householdId: householdId,
		});
	}
}

function updateStateWithInboundHouseholdMetaData(
	householdsInState: Loaded<HouseholdMetaData>[],
	inboundHouseholdMetaData: HouseholdMetaData[] | HouseholdMetaData
) {
	const inboundHouseholds = Array.isArray(inboundHouseholdMetaData)
		? inboundHouseholdMetaData
		: [inboundHouseholdMetaData];
	inboundHouseholds.forEach(inboundHousehold => {
		const householdId = inboundHousehold.id;
		let updateOccurred = false;

		householdsInState.forEach((currentHousehold, index) => {
			const currentHouseholdId = currentHousehold.data.id;
			if (currentHouseholdId === householdId) {
				updateOccurred = true;
				householdsInState[index] = {
					...householdsInState[index],
					loading: false,
					data: inboundHousehold,
					householdId: inboundHousehold.id,
					errors: [],
				};
			}
		});
		if (!updateOccurred) {
			householdsInState.push({
				loading: false,
				data: inboundHousehold,
				errors: [],
				householdId: inboundHousehold.id,
			});
		}
	});
}
