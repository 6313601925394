import { RouteComponentProps } from 'react-router-dom';
import { AppState } from './../reducers';
import { createSelector } from 'reselect';

export type PageType = 'HOUSEHOLD_PAGE' | 'EMPLOYER_PAGE' | 'OTHER_PAGE';

export const getPage = <Props extends RouteComponentProps<any>>(
	state: AppState,
	props: Props
) => {
	if (
		props.location.pathname.indexOf('household') > -1 ||
		(props.location.hash.indexOf('advancedSearch') > -1 &&
			props.match.params.householdID != '')
	) {
		return 'HOUSEHOLD_PAGE';
	} else if (
		props.location.pathname.indexOf('employer') > -1 ||
		(props.location.hash.indexOf('advancedSearch') > -1 &&
			props.match.params.employer_id != '')
	) {
		return 'EMPLOYER_PAGE';
	} else {
		return 'OTHER_PAGE';
	}
};

export const getPath = <Props extends RouteComponentProps<any>>(
	state: AppState,
	props: Props
) => props.match;

export const getId = createSelector([getPage, getPath], (page, matchPath) => {
	switch (page) {
		case 'HOUSEHOLD_PAGE': {
			return matchPath.params.householdID;
		}
		case 'EMPLOYER_PAGE': {
			return matchPath.params.employerID;
		}
		default: {
			return 'new';
		}
	}
});
