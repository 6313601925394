import React from 'react';
import { defaultSvgProps, SvgProps } from '..';

const ToolsSvg: React.FC<SvgProps> = (props) => (
    <svg viewBox="0 0 35 35" {...props}>
        <path
            d="M9.64 29.42A2.34 2.34 0 0 1 8 28.77a2.42 2.42 0 0 1-.62-2.09 3.91 3.91 0 0 1 1.12-2.15l4.92-4.91a.48.48 0 0 1 .7 0l3 3a.47.47 0 0 1 .15.35.51.51 0 0 1-.15.36l-4.91 4.91a3.72 3.72 0 0 1-2.57 1.18zm4.1-8.74l-4.56 4.56a2.86 2.86 0 0 0-.84 1.58 1.43 1.43 0 0 0 .34 1.24 1.34 1.34 0 0 0 1 .36 2.72 2.72 0 0 0 1.86-.86L16.06 23zM26.06 12.61a2 2 0 0 1-1.37-.56 1.93 1.93 0 0 1 0-2.72l1.66-1.67a.51.51 0 0 1 .71 0l2 2a.5.5 0 0 1 0 .71l-1.66 1.67a1.94 1.94 0 0 1-1.34.57zm.66-3.89L25.4 10a.93.93 0 0 0 0 1.31 1 1 0 0 0 1.31 0L28 10z"
        />
        <path
            d="M19.55 17.69a.47.47 0 0 1-.35-.15.48.48 0 0 1 0-.7l5.5-5.5a.48.48 0 0 1 .7 0 .5.5 0 0 1 0 .71l-5.5 5.49a.47.47 0 0 1-.35.15z"
        />
        <path
            d="M24.36 29.84a3.36 3.36 0 0 1-2.37-1l-9.75-9.75A6.07 6.07 0 0 1 5.33 11a.53.53 0 0 1 .36-.31.49.49 0 0 1 .46.14l2.9 2.89 2-.54.54-2-2.9-2.9a.47.47 0 0 1-.13-.45.49.49 0 0 1 .31-.37A6.27 6.27 0 0 1 11 7.08a6 6 0 0 1 4.3 1.78 6.12 6.12 0 0 1 1.7 5.51l9.75 9.75a3.36 3.36 0 0 1 1 2.37 3.35 3.35 0 0 1-3.35 3.35zm-12-11.78a.47.47 0 0 1 .35.15l10 10a2.33 2.33 0 0 0 1.66.68 2.36 2.36 0 0 0 2.35-2.35 2.33 2.33 0 0 0-.72-1.71l-9.94-9.95a.49.49 0 0 1-.14-.47A5.06 5.06 0 0 0 10 8.18l2.5 2.5a.48.48 0 0 1 .13.48L12 13.74a.48.48 0 0 1-.35.35L9 14.78a.48.48 0 0 1-.48-.13l-2.5-2.5a5.07 5.07 0 0 0 1.38 4.6 5.15 5.15 0 0 0 4.85 1.33z"
        />
        <path
            d="M24.22 25.44a.84.84 0 1 0 0 1.67.84.84 0 0 0 0-1.67z"
        />
    </svg>
);
ToolsSvg.defaultProps = defaultSvgProps;

export default ToolsSvg;
