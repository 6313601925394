import React from 'react';
import { Avatar } from '@material-ui/core';
import { themePalette } from '../../utilities/branding';
import { SimpleListItem } from '../utility/simple_list_item';
import { connect } from '@optum-uhone-hmkts/rise';
import { AppState } from '../../reducers';
import { SnackbarProps, QueueSnackbar } from '../../actions/snackbar_actions';
import { getSnackbarInfoProps } from '../../utilities/snackbar_util';
import { Strings } from '../../assets/common/strings';
import { navRoutes } from '../nav/Routes';
import { openExternalLink } from '../../utilities';
import { getRealmUrl } from '../../utilities/brokerage_utils';

interface DispatchProps {
    getSnackbar: (props: SnackbarProps) => void;
}

interface StateProps {}

type Props = DispatchProps & StateProps;
export const ConnectureToolItem: React.FC<Props> = (props) => {
    const {  getSnackbar } = props;

    const redirectConnectureSSO = () => {
        const route = `${getRealmUrl()}/#${navRoutes.openConnecture.path}`;
        openExternalLink(route);
        getSnackbar(getSnackbarInfoProps("Redirecting...", 3000));
    };

    return (
        <SimpleListItem
            title={Strings.SideBarMenu.connectureDRX + ' (Over 65)'}
            subtitle={''}
            onClick={redirectConnectureSSO}
            hideArrow
            icon={
                <Avatar
                    color={themePalette.negative_text}
                    style={{ backgroundColor: themePalette.tools_avatar }}
                >
                    {Strings.SideBarMenu.connectureDRX.charAt(0)}
                </Avatar>
            }
        />
    );
};

const mapStateToProps = (state: AppState): StateProps => ({
});
const mapDispatchToProps = (dispatch: any): DispatchProps => ({
    getSnackbar: (props: SnackbarProps) =>
        dispatch(QueueSnackbar(props))
});

export const ConnectureToolItemComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
    true
)(ConnectureToolItem);