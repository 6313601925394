import { Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { ReShopDecision } from './models/reshop_enums';

interface ReshopDecisionTooltipProps {
	decision: ReShopDecision;
}

const ReshopDecisionTooltip: React.FC<ReshopDecisionTooltipProps> = ({ children, decision }) => {
	return (
		<>
			<Tooltip
				placement="right"
				arrow
				title={
					<>
						{decision === ReShopDecision.Keep && (
							<>
								<Typography>
									<strong>Keep Current Plan</strong>
								</Typography>
								<Typography variant="caption">
									We couldn't find any plans that were better than the customer's current plan.
								</Typography>
							</>
						)}
						{decision === ReShopDecision.Switch && (
							<>
								<Typography>
									<strong>Switch To A Better Plan</strong>
								</Typography>
								<Typography variant="caption">
									We think we found a plan that works for this customer better than their current
									plan.
								</Typography>
							</>
						)}
						{decision === ReShopDecision.Exclude && (
							<>
								<Typography>
									<strong>Excluded From ReShopping</strong>
								</Typography>
								<Typography variant="caption">
									This customer was excluded from the ReShop process because they met certain
									exclusion criteria.
								</Typography>
							</>
						)}
						{decision === ReShopDecision.NoPlan && (
							<>
								<Typography>
									<strong>Couldn't Find The Current Plan</strong>
								</Typography>
								<Typography variant="caption">
									We were not able to find the customer's current plan, so we recommend they enroll in
									the best plan we could find.
								</Typography>
							</>
						)}
						{decision === ReShopDecision.FitScoreNeeded && (
							<>
								<Typography>
									<strong>We Need More Information</strong>
								</Typography>
								<Typography variant="caption">
									We utilize the FitScore algorithm to compare plans for customers. Without the
									information gathered through the FitScore wizard, we are unable to provide a
									recommendation.
								</Typography>
							</>
						)}
						{decision === ReShopDecision.Undetermined && (
							<>
								<Typography>
									<strong>Something Went Wrong</strong>
								</Typography>
								<Typography variant="caption">
									There was an issue calculating a ReShop recommendation for this customer. We
									apologize for any inconvenience.
								</Typography>
							</>
						)}
					</>
				}>
				<div>{children}</div>
			</Tooltip>
		</>
	);
};

export { ReshopDecisionTooltip };
