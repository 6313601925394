import { call, put, all } from 'redux-saga/effects';
import { Action } from "typescript-fsa";
import { GetQuoteActivity, DeleteQuoteActivity } from '../actions/quote_activity_actions';
import http from '../utilities/http';
import { QueueSnackbar } from '../actions/snackbar_actions';
import { getSnackbarErrorProps, getSnackbarSuccessProps } from '../utilities/snackbar_util';
import { takeLatestForActionType } from '../utilities/saga_util';
import { QuoteActivity } from '../reducers/quote_activity_reducer';

function* getQuoteActivitySaga(action: Action<string>) {
	const errorSnackbarProps = getSnackbarErrorProps('Failed to load quote activity');
	try {
		const response = yield call(getQuoteActivityCall, action.payload);
		if (response.ok) {
			const data: QuoteActivity = yield response.json();
			yield put(GetQuoteActivity.done({ params: action.payload, result: data }));
		} else {
			yield put(
				GetQuoteActivity.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
			yield put(QueueSnackbar(errorSnackbarProps));
		}
	} catch (error) {
		yield put(GetQuoteActivity.failed({ params: action.payload, error }));
		yield put(QueueSnackbar(errorSnackbarProps));
	}
}
function getQuoteActivityCall(id: string) {
	return http('quoteactivity/' + id);
}

function* deleteQuoteActivitySaga(action: Action<string>) {
	const errorSnackbarProps = getSnackbarErrorProps('Failed to delete quote activity');
	const successSnackbarProps = getSnackbarSuccessProps('Quote activity deleted');
	try {
		const response = yield call(deleteQuoteActivityCall, action.payload);
		if (response.ok) {
			yield put(
				DeleteQuoteActivity.done({ params: action.payload, result: true })
			);
			yield put(
				QueueSnackbar(successSnackbarProps)
			);
		} else {
			yield put(
				DeleteQuoteActivity.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
			yield put(QueueSnackbar(errorSnackbarProps));
		}
	} catch (error) {
		yield put(DeleteQuoteActivity.failed({ params: action.payload, error }));
		yield put(QueueSnackbar(errorSnackbarProps));
	}
}
function deleteQuoteActivityCall(id: string): Promise<any> {
	return http('quoteactivity/' + id, {
		method: 'DELETE'
	});
}

export function* quoteActivitySagas() {
	yield all([
		takeLatestForActionType(
			GetQuoteActivity.started,
			getQuoteActivitySaga
		),
		takeLatestForActionType(
			DeleteQuoteActivity.started,
			deleteQuoteActivitySaga
		),
	]);
}