import React from 'react';
import { getPrimaryContactOnLead, isNullOrUndefinedOrEmpty } from '../../utilities/utilities';
import { EMPTY_CONTACT, EMPTY_LEAD } from '../../utilities/empty_entities';
import { composeNameGenderDobAge } from '../../utilities/contact_util';
import _ from 'lodash';
import moment from 'moment';
import { displayDate, DateTimeFormat } from '../../utilities/date_util';
import { firstNameDisplay } from '../../assets/common/string_builders';
import { Button, Dialog, DialogContent, DialogTitle, DialogContentText, Grid, DialogActions } from '@material-ui/core';
import { normalizePhone } from '../../utilities/formatting/data_normalizations';
import { themePalette } from '../../utilities/branding';
import { composePhoneMetaData } from '../../utilities/phone_email_util';
import { Strings } from '../../assets/common/strings';
import { Lead } from '../../reducers/LeadReducer';
import { Address, Contact } from '../../reducers/ContactReducer';
import { Activity } from '../../reducers/activity_reducer';
import { LineOfBusinessAvatar } from '../../components/line_of_business/line_of_business_avatar';
import { GetHousehold } from '../../actions/household_actions';
import { ToggleLeadInfoDialog } from '../../actions/dialogs_actions';
import { connect } from '@optum-uhone-hmkts/rise';
import { navRoutes } from '../../components/nav/Routes';
import { SmsSequence } from '../dialogs/sequence/sms_sequence';
import { CallSequence } from '../dialogs/sequence/call_sequence';
import { EmailActionIcon } from '../../components/Layout/contact_header_bar/email_action_icon';
import { ProductStatus } from '../../reducers/policy_reducer';
import { nav } from '../..';

type ComponentProps = {
    householdId: string;
    storeLeadRoutesIndex?: () => void
};

type DispatchProps = {
    onClose: () => void;
    getHousehold: (id: string) => void;
};

type StateProps = {
    lead: Lead;
    contact: Contact;
    activities: Activity[];
    isOpen: boolean;
    hasActiveBusinessRelationship?: boolean;
};

type Props = ComponentProps & DispatchProps & StateProps;

class _LeadInfoDialog extends React.Component<Props> {

    componentWillReceiveProps(newProps: Props) {
        if (newProps.householdId && newProps.householdId != this.props.householdId) {
            this.props.getHousehold(newProps.householdId);
        }
    }

    routeToLead = () => {
        if (this.props.storeLeadRoutesIndex) {
            this.props.storeLeadRoutesIndex();
        }

        if (this.props.lead.employer) {
            nav.push(navRoutes.employer.path.replace(Strings.Navigation.EmployerId, this.props.lead.employer.id));
        } else {
            nav.push(navRoutes.household.path.replace(Strings.Navigation.HouseholdId, this.props.lead.householdId));
        }

        // Close the modal
        this.props.onClose();
    }

    composeLineOfBusinessAvatars = () => {
        const { lead } = this.props;
        const { linesOfBusiness } = lead;
        return linesOfBusiness?.map(lob => (
            <LineOfBusinessAvatar
                key={`${lead.id}-${lob}`}
                size={20}
                lineOfBusinessShortString={lob.charAt(0)}
                displayInline={true}
            />
        ));
    };

    getNextActivityDisplay = () => {
        const activities = this.props.activities;

        const filteredActivities = activities.filter(
            activity =>
                activity.status == Strings.ActivityStatus.Open &&
                activity.type != Strings.Activity.Email &&
                activity.type != Strings.Activity.Task
        );

        if (filteredActivities == undefined || !filteredActivities.length) {
            return 'No future activities scheduled at this time';
        }

        const sortedActivities = _.sortBy(filteredActivities, function (value) {
            return new Date(value.time);
        });
        const activity = sortedActivities[0] || undefined;
        if (activity == undefined) {
            return 'No future activities scheduled at this time';
        }

        const activityDate = new Date(activity.time);
        const activityDateString = moment(new Date(activity.time)).isValid()
            ? moment(activityDate).format('M/D/YYYY') +
            ' at ' +
            moment(activityDate).format('LT')
            : '';

        if (activity.type == Strings.Activity.PhoneCall) {
            const name = activity.contact && firstNameDisplay(activity.contact);
            return (
                'Call with ' +
                (!isNullOrUndefinedOrEmpty(name) ? name : 'Lead') +
                ' on ' +
                activityDateString
            );
        } else {
            return 'Appointment on ' + activityDateString;
        }
    }

    handleNavigation = (route: string) => {
        this.props.onClose();
        nav.push(route);
    };

    getCompletedPhoneCallDisplay = (): { length: number; lastCall: string } => {
        if (isNullOrUndefinedOrEmpty(this.props.householdId)) {
            return { length: 0, lastCall: '' };
        }
        const activities = this.props.activities.slice();
        const filtered = activities
            .filter(
                activity =>
                    activity.type === Strings.Activity.PhoneCall &&
                    activity.status === Strings.ActivityStatus.Completed
            )
            .sort((a: Activity, b: Activity) => {
                return moment(b.completedOn).valueOf() - moment(a.completedOn).valueOf()
            });
        const length = filtered.length;
        return {
            length,
            lastCall: length
                ? displayDate(
                    filtered[length - 1].completedOn,
                    DateTimeFormat.DateTime,
                    ''
                )
                : '',
        };
    };

    render() {
        const contactNameGenderDobAge = composeNameGenderDobAge(this.props.contact);
        const isEmployerLead = this.props.lead && this.props.lead.employer && this.props.lead.employer.id;
        const companyName =
            this.props.lead &&
                this.props.lead.employer &&
                this.props.lead.employer.companyName
                ? this.props.lead.employer.companyName
                : '';
        const addresses =
            this.props.contact && this.props.contact.addresses
                ? this.props.contact.addresses
                : ([] as Address[]);
        const preferredAddress =
            addresses && addresses.length > 0
                ? _.find(addresses, address => address.isPreferred) || addresses[0]
                : ({} as Address);

        const line1 = preferredAddress.line1 || '';
        const line2 = preferredAddress.line2 || '';
        const zipCode = preferredAddress.zipCode || '';
        const city = preferredAddress.city || '';
        const state = preferredAddress.state || '';
        const address =
            line1 && city && state && zipCode
                ? `${line1}${' ' + line2}${' ' + city}, ${state} ${zipCode}`
                : '';

        const leadStatus: string = this.props.lead.status || '';
        const leadType: string = this.props.lead.leadType || '';
        const leadVendor: string = this.props.lead.vendor || '';
        const campaign: string = this.props.lead.campaign || '';
        const fund: string | undefined = this.props.lead.fund;

        const createdOn: Date =
            this.props.lead && this.props.lead.createdOn
                ? this.props.lead.createdOn
                : ({} as Date);
        const createdDateString =
            createdOn && moment(createdOn).isValid()
                ? displayDate(createdOn, DateTimeFormat.DateTime, '')
                : '';

        const modifiedOn: Date =
            this.props.lead && this.props.lead.updatedOn
                ? this.props.lead.updatedOn
                : ({} as Date);
        const modifiedDateString =
            modifiedOn && moment(modifiedOn).isValid()
                ? displayDate(modifiedOn, DateTimeFormat.DateTime, '')
                : '';
        const createdByValue = this.props.lead.createdByName || '';
        const createdBy =
            createdByValue === 'Legacy Api' ? 'System' : createdByValue;

        const nextActivity = this.getNextActivityDisplay();

        const activityCalls = this.getCompletedPhoneCallDisplay();
        const completedPhoneCallsCount =
            this.props.lead.callAttempts || activityCalls.length;

        const preferredPhone = composePhoneMetaData(this.props.lead.contacts).preferred;

        return (
            <Dialog
                open={this.props.isOpen}
                onClose={this.props.onClose}
            >
                <DialogContent
                    style={{ padding: '24px 24px 0px 24px', marginBottom: '24px' }}
                >
                    <DialogTitle style={{ padding: '0px 0px 8px 0px' }}>
                        {
                            isEmployerLead
                                ? companyName
                                : `${contactNameGenderDobAge}`}
                    </DialogTitle>
                    <DialogContentText style={styles.dialogFont}>
                        {address}
                    </DialogContentText>
                    <DialogContentText style={styles.dialogField}>
                        {normalizePhone(preferredPhone)}
                    </DialogContentText>
                    <DialogContentText
                        style={{
                            color: themePalette.primary_text,
                            fontSize: '13px',
                            ...styles.topPadding,
                        }}
                    >
                        {leadStatus}
                    </DialogContentText>
                    <div>{this.composeLineOfBusinessAvatars()}</div>
                    <DialogContentText style={styles.dialogField}>
                        <span style={styles.dialogSpanText}>Next: </span>
                        {nextActivity}
                    </DialogContentText>
                    <DialogContentText style={styles.dialogField}>
                        <span style={styles.dialogSpanText}>Created: </span>
                        {(createdDateString ? ' ' + createdDateString : '') +
                            (!isNullOrUndefinedOrEmpty(createdBy) ? ' by ' + createdBy : '')}
                    </DialogContentText>
                    <DialogContentText style={styles.dialogField}>
                        <span style={styles.dialogSpanText}>
                            Calls attempted:{' '}
                        </span>
                        {completedPhoneCallsCount}
                    </DialogContentText>
                    <DialogContentText style={styles.dialogField}>
                        <span style={styles.dialogSpanText}>
                            Last Contacted:{' '}
                        </span>
                        {activityCalls.lastCall ? activityCalls.lastCall : " "}
                    </DialogContentText>

                    {modifiedDateString && (
                        <DialogContentText style={styles.dialogField}>
                            <span style={styles.dialogSpanText}>
                                Last Modified:{' '}
                            </span>
                            {modifiedDateString}
                        </DialogContentText>
                    )}
                    <DialogContentText style={styles.dialogField}>
                        <span style={styles.dialogSpanText}>Lead Type: </span>
                        {leadType}
                    </DialogContentText>
                    {fund
                        ? <DialogContentText style={styles.dialogField}>
                            <span style={styles.dialogSpanText}>Fund: </span>
                            {fund}
                        </DialogContentText>
                        : <>
                            <DialogContentText style={styles.dialogField}>
                                <span style={styles.dialogSpanText}>Campaign: </span>
                                {campaign}
                            </DialogContentText>
                            <DialogContentText style={styles.dialogField}>
                                <span style={styles.dialogSpanText}>Vendor: </span>
                                {leadVendor}
                            </DialogContentText>
                        </>
                    }
                    <DialogContentText style={styles.contactHeader}>
                        Contact
                </DialogContentText>
                    <Grid container>
                        <Grid item xs={4}>
                            <CallSequence
                                contacts={this.props.lead.contacts}
                                householdId={this.props.householdId}
                                iconOptions={
                                    {
                                        style: {
                                            color: themePalette.action_icon,
                                        }
                                    }
                                }
                                navigateTo={this.handleNavigation}
                                displayIcon
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <SmsSequence
                                contacts={this.props.lead.contacts}
                                iconOptions={
                                    {
                                        style: { color: themePalette.action_icon }
                                    }
                                }
                                displayIcon
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <EmailActionIcon
                                contacts={this.props.lead.contacts}
                                iconOptions={
                                    {
                                        style: { color: themePalette.action_icon }
                                    }
                                }
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={styles.buttonActions}>
                    <Button
                        // color="primary"
                        onClick={this.props.onClose}
                    >
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={this.routeToLead}
                        disableElevation
                    >
                        <div style={{ color: themePalette.negative_text }}>{isEmployerLead ? 'View Employer' : 'View Household'}</div>
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };
}

function mapStateToProps(state, ownProps: Props): StateProps {
    
    const leadIndex = state.lead.leads.findIndex(lead => lead.householdId == ownProps.householdId);
    const lead = leadIndex > -1 ? state.lead.leads[leadIndex].data : EMPTY_LEAD;
    let contact: Contact = EMPTY_CONTACT;
    if (window.location.href.indexOf('activities') > -1) {
        const activityId = window.location.href.split("/").pop();
        const activity = state.activity.activities.find(act => act.data.id == activityId);
        contact = activity && activity.data.contact ? activity.data.contact : EMPTY_CONTACT;
    } else {
        contact = lead && lead.contacts && lead.contacts.length > 0 ? getPrimaryContactOnLead(lead) : EMPTY_CONTACT;
    }

    const hasPolicies = state.policy.policies.some(p =>
        (p.data.manualPolicyStatus !== ProductStatus.Terminated ||
            (p.data.manualPolicyStatus == null && p.data.policyStatus !== ProductStatus.Terminated)) &&
        (p.data.primaryInsured !== undefined && p.data.primaryInsured !== null) &&
        p.data.primaryInsured.householdId === contact.householdId);

    const hasApplications = state.application.applications.some(a =>
        a.data.applicationStatus !== ProductStatus.Terminated &&
        (a.data.primaryInsured !== undefined && a.data.primaryInsured !== null) &&
        a.data.primaryInsured.householdId === contact.householdId)

    return {
        activities: state.activity.activities
            .filter(activity => activity.householdId == ownProps.householdId)
            .map(loadedactivity => loadedactivity.data),
        lead,
        contact,
        isOpen: state.leadInfoDialog.isOpen,
        hasActiveBusinessRelationship: hasPolicies || hasApplications
    };
}



function mapDispatchToProps(dispatch: any, ownProps: Props): DispatchProps {
    return {
        onClose: () => dispatch(ToggleLeadInfoDialog(false)),
        getHousehold: (id) => dispatch(GetHousehold.started(id)),
    };
}

export const LeadInfoDialog = connect(
    mapStateToProps,
    mapDispatchToProps,
    true,
)(_LeadInfoDialog) as React.ComponentClass<ComponentProps>;




const styles: { [key: string]: React.CSSProperties } = {
    contactHeader: {
        color: themePalette.primary_text,
        textAlign: 'center',
        paddingTop: 8,
        fontWeight: 'bold',
    },
    buttonActions: {
        paddingBottom: 16,
        margin: '0px 14px',
    },
    topPadding: {
        paddingTop: 8,
    },
    dialogFont: {
        fontSize: 13,
    },
    dialogField: {
        fontSize: 13,
        paddingTop: 4,
        marginBottom: 2,
    },
    dialogSpanText: {
        color: themePalette.primary_text,
    },
    loader: {
        margin: 8,
        textAlign: 'center',
        fontSize: 15,
        clear: 'both',
    },
};