import { getAppState } from '../../';
import { isBrokerage } from '../brokerage_utils';
import { ActionPermission, P, ApiPermission } from './permissions';
import { DevicePermissionSettings } from '../../reducers/user_reducer';
import { Strings } from '../../assets/common/strings';
import { DecodedJwt } from '../../reducers/authentication_reducer';
import { getDecodedJwt } from '../../selectors/authentication_selectors';
import { AppConfig } from '../../types/config';

interface PermissionResponse {
	hasPermission: boolean;
	isTerminated: boolean;
}

export default class JWT_auth {
	static decodeJwt = (encodedJwt: string): DecodedJwt => !!encodedJwt ? JWT_auth.parseJwtBody(encodedJwt.split('.')[1]) : undefined;

	static parseJwtBody = (jwt: string) : any => {
		var decoded: DecodedJwt = JSON.parse(atob(jwt));
		if(decoded.fileStorageSignature) {
			decoded.fileStorageSignature = 
				decoded.fileStorageSignature
				.replace(/-percent-/g, '%')
				.replace(/-and-/g, "&")
				.replace(/-equal-/g, '=')
				.replace(/-question-/g, '?')
				;
		}

		return decoded;
	}

	static checkPermissions(requiredPermissions: ApiPermission[]): PermissionResponse {
		if (AppConfig.bypass_permissions)
			return {
				isTerminated: false,
				hasPermission: true,
			};

		let isTerminated = false;
		let hasPermission = false;

		const decodedJWT = getDecodedJwt(getAppState());

		if (decodedJWT) {
			isTerminated = !!decodedJWT.roles && !!~decodedJWT.roles.indexOf(
				isBrokerage
					? Strings.ADRoles.BrokerTerminated
					: Strings.ADRoles.AgentTerminated
			);

			if (requiredPermissions.length == 0) {
				hasPermission = true;
			} else {
				const possessedPermisions = decodedJWT.permissions;
				if (possessedPermisions) {
					hasPermission = requiredPermissions.every(requiredPermission =>
						possessedPermisions.some(permission => permission.permissionCode == requiredPermission)
					);
				} else {
					hasPermission = false;
				}
			}
		}

		return {
			isTerminated,
			hasPermission,
		};
	}

	static getAgentRoles() {
		const decodedJwt = getDecodedJwt(getAppState());
		return decodedJwt && decodedJwt.roles ? decodedJwt.roles : []
	}

	static checkUser(userId: string) {
		const decodedJwt = getDecodedJwt(getAppState());
		if (!decodedJwt) return false;
		const storedUserID = decodedJwt.impersonatingId || decodedJwt.userID;
		return storedUserID && storedUserID === userId;
	}

	static hasPermission(actionPermission: ActionPermission) {
		const hasNecessary = this.checkPermissions(actionPermission.mustHave).hasPermission;
		const avoidsProhibited = actionPermission.mustNotHave === undefined || !this.checkPermissions(actionPermission.mustNotHave).hasPermission;
		return hasNecessary && avoidsProhibited
	}

	static findPermission(route: string) {
		const actionPermission = this.getActionPermission(route);
		if (actionPermission) {
			return this.hasPermission(actionPermission);
		} else {
			return false;
		}
	}

	static getActionPermission(deviceSetting: string): ActionPermission | undefined {
		switch (deviceSetting) {
			case DevicePermissionSettings.General:
				return P.General;
			case DevicePermissionSettings.Battles:
				return P.Battle;
			case DevicePermissionSettings.ClientConnect:
				return P.ClientConnect;
			case DevicePermissionSettings.Crm:
				return P.Crm;
			case DevicePermissionSettings.Documents:
				return P.Document;
			case DevicePermissionSettings.Finances:
				return P.Finance;
			case DevicePermissionSettings.Leads:
				return P.Lead;
			case DevicePermissionSettings.Performance:
				return P.Performance
			case DevicePermissionSettings.Profile:
				return P.Profile;
			case DevicePermissionSettings.Settings:
				return P.Settings;
			case DevicePermissionSettings.TodoList:
				return P.TodoList;
			case DevicePermissionSettings.Tools:
				return P.Tool;
			case DevicePermissionSettings.Training:
				return P.Training
			default:
				console.error('unfound key', deviceSetting);
				return undefined;
		}
	};

}
