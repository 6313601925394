import React from 'react';
import { defaultSvgProps, SvgProps } from '..';

const ActivitiesSvg: React.FC<SvgProps> = (props) => (
    <svg viewBox="0 0 35 35" {...props}>
        <path
            d="M13.74 27.94a.53.53 0 0 1-.38-.16l-6.89-7.24a.52.52 0 1 1 .75-.72l6.49 6.81L28.55 8.54a.52.52 0 0 1 .8.66L14.14 27.75a.56.56 0 0 1-.38.19z"
        />
    </svg>
);
ActivitiesSvg.defaultProps = defaultSvgProps;

export default ActivitiesSvg;
