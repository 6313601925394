import {
	Grid,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	ListItemIcon,
} from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import moment from 'moment';
import React from 'react';
import { Lead } from '../../reducers/LeadReducer';
import { Loaded } from '../../utilities/utilities';
import { LineOfBusinessAvatar } from '../line_of_business/line_of_business_avatar';
import { DefaultDictionary, Lookups } from '../../utilities/lookup';
import { wrapInCollapsibleCard } from '../higher_order_component/wrap_in_card';
import CustomList from '../utility/custom_list';

interface LeadListProps {
	leads: Loaded<Lead>[];
	parentId: string;
	onLeadClick: (householdID: string, leadID: string) => void;
}

const composeLineOfBusinessAvatars = (linesOfBusiness: string[]) => {
	return linesOfBusiness.map(lob => (
		<LineOfBusinessAvatar
			key={lob}
			size={23}
			lineOfBusinessShortString={lob.charAt(0)}
			displayInline={true}
		/>
	));
};

const LeadList = (props: LeadListProps) => {
	return (
		<CustomList emptyMessage="You have no leads">
			{props.leads?.map(
				(lead: Loaded<Lead>) => {
					const leadId: string = lead.data?.id || '';
					const leadLinesOfBusiness: string[] = lead.data?.linesOfBusiness || [];
					const status = DefaultDictionary.findOrCreate(lead.data.status);
					const statusCode = status.getFirstParentOfType(Lookups.LeadStatusCode);
					const statusCodeLabel = statusCode.matches(Lookups.NoSale)
						? statusCode.label.concat(' - ')
						: '';
					const displayStatus: string = statusCodeLabel + status.label;
					const updatedDate: string = 'Last Updated: '.concat(moment(lead.data.updatedOn).fromNow());

					return (
						<ListItem
							button
							divider
							key={leadId}
							onClick={() => props.onLeadClick(props.parentId, leadId)}
						>
							<Grid container>
								<ListItemText
									primary={displayStatus}
									secondary={updatedDate}
								/>
								<ListItemSecondaryAction>
									<ListItemIcon><KeyboardArrowRightIcon/></ListItemIcon>
								</ListItemSecondaryAction>
								<Grid item xs={12}>
									{composeLineOfBusinessAvatars(leadLinesOfBusiness)}
								</Grid>
							</Grid>
						</ListItem>
					);
				}
			)}
		</CustomList>
	);
};


export const LeadListCard = wrapInCollapsibleCard(LeadList);
