import React from 'react';
const bowser = require('bowser');
import {
	CircularProgress,
	Paper,
	Grid,
	Icon,
    Fade,
} from '@material-ui/core';
import { connect } from '@optum-uhone-hmkts/rise';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { isMobileDevice, isDesktop } from '../../utilities/is_mobile';
import { AuthOptions, Authenticate } from '../../actions/authentication_actions';
import { themePalette, themeImages } from '../../utilities/branding';
import { Strings } from '../../assets/common/strings';
import PreparingExperience from './preparing_experience';
import { LoginForm } from './login_form';
import LoginFooter from './login_footer';
import VersionCheck from './version_check';
import { CancelLogin } from '../../actions/authentication_actions';
import { GetLoginNotification } from '../../actions/notification_actions';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import { AppState } from '../../reducers';
import { Dispatch } from '@reduxjs/toolkit';
import { DesktopPadding } from '../../components/Layout/desktop_padding';

type StateProps = {
    errorMessage: string;
	loginState: Strings.LoginState;
	updateAvailable: boolean;
	newVersionUrl: string;
}

type DispatchProps = {
	onCancelLogin: () => void;
	getLoginNotifications: () => void;
	startAuth: (opts: AuthOptions) => void;
};

type ComponentProps = {}

type Props = ComponentProps & DispatchProps & StateProps;

type State = {};

class _LoginScreen extends React.PureComponent<Props, State> {
	isSupportedBrowser = bowser.name === 'Chrome' ||
		bowser.name === 'Safari' ||
		bowser.name === 'Microsoft Edge';

	styles: { [key: string]: React.CSSProperties } = {
		body: {
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
			color: themePalette.negative_text,
			textAlign: 'center',
		},
		logoMobile: {
			marginTop: '10%',
			width: '256px',
			marginBottom: 20,
		},
		logoDesktop: {
			marginTop: '7%',
			width: '350px',
			marginBottom: 20,
		},
		unsuportedBrowser: {
			backgroundColor: themePalette.unsupported_browser,
			padding: 10,
			marginTop: 45,
			border: `1px solid ${themePalette.primary_text}`,
			color: themePalette.negative_text,
		},
	};

	loginStateIs = (checkState: Strings.LoginState | Strings.LoginState[]): boolean => (
		Array.isArray(checkState)
			? checkState.some(state => state == this.props.loginState)
			: this.props.loginState == checkState
	);

	componentDidMount() {
		const { startAuth, getLoginNotifications } = this.props;
        getLoginNotifications();
        if (!this.loginStateIs(Strings.LoginState.LogoutPending)) {
            startAuth({});
        }
	}

	onFormSubmit = (event: any) => {
		event.preventDefault();
		this.props.startAuth({ redirect: true });
	};

	renderedUpdateCheck = (
		<div>
			<h2>Checking for updates...</h2>
			<CircularProgress color="secondary" />
		</div>
	);

	renderedUnsupportedBrowserMessage = (
		<Grid container alignContent="center">
			<Grid item xs={12}>
				<Paper style={this.styles.unsuportedBrowser}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							marginBottom: '0px',
						}}
					>
						<Icon>warning</Icon>
						<h4>Unsupported Browser</h4>
					</div>
					<div>
						{`Your browser is currently not supported. ${document.title} is supported
						on the following up-to-date browsers: Chrome, Safari, or Microsoft
						Edge.`}
					</div>
				</Paper>
			</Grid>
		</Grid>
	);

	render() {
		const unsupportedBrowser = !isMobileDevice && !this.isSupportedBrowser;
		const logoStyle = isDesktop() ? this.styles.logoDesktop : this.styles.logoMobile;
		return (
            <Fade in>
                <BasePageContainer
                    blockDialogs
                    middleStyle={{
                        ...this.styles.body,
                        backgroundColor: themePalette.login_background,
                        backgroundImage: `url('${themeImages.loginBackground}')`,
                    }}
                >
                    <Grid container>
                        {
                            !unsupportedBrowser && (
                                <Grid item xs={12} style={{ paddingTop: 10 }}>
                                    <PersistentNotifications disabled />
                                </Grid>
                            )
                        }
                        <Grid item xs={12}>
                            <img
                                style={logoStyle}
                                src={themeImages.loginLogo}
                            />
                        </Grid>
                    </Grid>
                    <DesktopPadding>
                        {unsupportedBrowser
                            ? this.renderedUnsupportedBrowserMessage
                            : (
                                <>
                                    <VersionCheck
                                        visible={!this.loginStateIs([Strings.LoginState.PreparingExperience, Strings.LoginState.Authenticated, Strings.LoginState.CheckingAuth])
                                            && (this.props.updateAvailable || this.loginStateIs([Strings.LoginState.CheckingForUpdates, Strings.LoginState.LogoutPending]))}
                                        headerText={this.props.loginState}
                                        loading={this.loginStateIs([Strings.LoginState.CheckingForUpdates, Strings.LoginState.LogoutPending, Strings.LoginState.CheckingAuth])}
                                    />
                                    <LoginForm
                                        visible={this.loginStateIs([Strings.LoginState.DisplayForm, Strings.LoginState.CheckingAuth])}
                                        errorGeneral={this.props.errorMessage}
                                        onSubmit={this.onFormSubmit}
                                        disabled={this.loginStateIs(Strings.LoginState.CheckingAuth)}
                                    />
                                    <LoginFooter
                                        visible={this.loginStateIs([Strings.LoginState.DisplayForm, Strings.LoginState.CheckingAuth])}
                                    />
                                    <PreparingExperience
                                        visible={this.loginStateIs([Strings.LoginState.PreparingExperience, Strings.LoginState.Authenticated])}
                                        onCancel={this.props.onCancelLogin}
                                    />
                                </>
                            )}
                    </DesktopPadding>
                </BasePageContainer>
            </Fade>
		);
	}
}

const mapStateToProps = (state: AppState): StateProps => {
	const {
        errorMessage,
		loginState,
		updateAvailable,
		newVersionUrl,
	} = state.authentication;

	return {
		updateAvailable,
		errorMessage,
		loginState,
		newVersionUrl,
	};
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onCancelLogin: () => dispatch(CancelLogin()),
	getLoginNotifications: () => dispatch(GetLoginNotification.started({})),
	startAuth: (opts: AuthOptions) => dispatch(Authenticate.started(opts)),
});

export const LoginScreen = connect(mapStateToProps, mapDispatchToProps, true)(
	_LoginScreen
);
