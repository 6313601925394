import { Button } from '@material-ui/core';
import React from 'react';
import { SnackbarProps } from '../actions/snackbar_actions';

const AUTO_HIDE_TIME_MS = 4000;
type GetSnackbarProps = (errorText?: string | JSX.Element, autoHideMs?: number, handleClick?: () => void) => SnackbarProps;

const getSnackbarProps = (autoHideMs?: number, handleClick?: () => void) => ({
	autoHideDuration: autoHideMs || AUTO_HIDE_TIME_MS,
	action: handleClick && [
		<Button
			key="view"
			color="inherit"
			size="small"
			onClick={handleClick}
		>
			View
		</Button>
	]
});

export const getSnackbarErrorProps: GetSnackbarProps = (errorText?: string | JSX.Element, autoHideMs?: number, handleClick?: () => void) => ({
	...getSnackbarProps(autoHideMs, handleClick),
	text: errorText || 'Error',
	severity: 'error',
});

export const getSnackbarSuccessProps: GetSnackbarProps = (successText?: string | JSX.Element, autoHideMs?: number, handleClick?: () => void) => ({
	...getSnackbarProps(autoHideMs, handleClick),
	text: successText || 'Success',
	severity: 'success',
});

export const getSnackbarInfoProps: GetSnackbarProps = (text: string | JSX.Element, autoHideMs?: number, handleClick?: () => void) => ({
	...getSnackbarProps(autoHideMs, handleClick),
	text,
	severity: 'info',
});

export const getSnackbarWarningProps: GetSnackbarProps = (text: string | JSX.Element, autoHideMs?: number, handleClick?: () => void) => ({
	...getSnackbarProps(autoHideMs, handleClick),
	text,
	severity: 'warning',
});