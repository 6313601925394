import React from 'react';
import { Dispatch } from 'redux';
import { DismissDialog } from '../../actions/dialogs_actions';
import { connect } from '@optum-uhone-hmkts/rise';
import { DialogFrame } from '../../reducers/dialogs_reducer';
import uuid from 'uuid';

export interface InjectedProps {
    dismissSelf: () => void;
}

export interface ExternalProps {
    id: string;
}

interface OwnProps extends InjectedProps, ExternalProps {
}

export const connectDialog = <TOriginalProps extends {}>(
    Component:
    | React.ComponentClass<TOriginalProps>
    | React.FC<TOriginalProps>,
    dialogProps?: Partial<TOriginalProps>
) => {
    type Props = TOriginalProps & OwnProps;

    const ConnectedDialog: React.FC<Props> = (props) => (
        <Component
            {...props}
            {...dialogProps}
        />
    );  

    const mapStateToProps = (state: any, props: Props) => ({});
    const mapDispatchToProps = (dispatch: Dispatch, props: Props): InjectedProps => ({
        dismissSelf: () => dispatch(DismissDialog(props.id)),
    });

    const container =  connect(
        mapStateToProps,
        mapDispatchToProps,
        true
    )(ConnectedDialog);
    container.displayName = `ConnectDialog(${Component.displayName})`;
    return container;
}

export const makeDialogInstance = <TProps extends ExternalProps>(
    Component:
    | React.ComponentClass<TProps>
    | React.FC<TProps>
): DialogFrame => {
    const name = Component.displayName || typeof Component;
    const id = uuid.v4();
    const dialogProps = { id } as TProps;
    return {
        id,
        name,
        component: <Component {...dialogProps} />
    }
};
