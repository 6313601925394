import { Button, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import { Widget } from './base_widget';
import { AppState } from '../../reducers';
import { Dispatch } from '@reduxjs/toolkit';
import { Strings } from '../../assets/common/strings';
import {useNavigateTo } from '../../utilities/hooks';
import { themePalette } from '../../utilities/branding';
import LicenseAppointmentSvg from '../svgs/icons/licenseAppointment';
import { nav } from '../..';
import { navRoutes, } from '../nav/Routes';
import { GetAgentStates } from '../../actions/license_appointment_actions';
import { LicenseAppointmentTile } from '../agentLicenseAppointment/license_appointment_tile'
import { getCurrentAgentCode } from '../../selectors/agent_selectors';
import { AgentState } from '../../reducers/license_appointment_reducer';
import { getAgentStates } from '../../selectors/license_appointment_selectors';

interface StateProps {
	loading: boolean;
	currentAgentCode: string;
	agentStates: AgentState[];
}

interface DispatchProps {
	getAgentStates: () => void
}

type Props = StateProps & DispatchProps;

const LicenseAppointmentWidget: React.FC<Props> = (props) => {
	
	const {loading, currentAgentCode, agentStates, getAgentStates} = props;

	useEffect(() => {
		if (currentAgentCode != 'unknown') {
			getAgentStates();
		}
	}, [currentAgentCode]);

	const navToLicenseAndAppointment = useNavigateTo(navRoutes.appointment.path);

	const renderWidget = () => {
		if (loading) {
			return (
				<Typography align='center'>Loading...</Typography>
			);
		} else if (agentStates && agentStates.length > 0) {
			return (
				<LicenseAppointmentTile />
			);
		} else {
			return null;
		}
	}
		
	return (
		<Widget
			title={Strings.WidgetTitles.LicenseAppointment}
			avatar={<LicenseAppointmentSvg width={30} height={30} fill={themePalette.menuSvgLicenseAppointment} />}
			onClick={navToLicenseAndAppointment}
			actionButtons={[
				<Button onClick={navToLicenseAndAppointment}>View All</Button>,
			]}
		>
			{renderWidget()}
		</Widget>
	);
}

const mapStateToProps = (state: AppState): StateProps => ({
	loading: state.licenseAppointment.loading,
	currentAgentCode: getCurrentAgentCode(state),
	agentStates: getAgentStates(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	getAgentStates: () => dispatch(GetAgentStates.started()),
});

export const LicenseAppointmentWidgetContainer = connect(mapStateToProps, mapDispatchToProps, true)(LicenseAppointmentWidget);
