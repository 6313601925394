import {actionCreatorFactory} from 'typescript-fsa';
import { Action as ReduxAction } from 'redux';
import {
	SearchResultObject,
	SearchFilterFormValues,
	AdvSearchFilterPresetDto,
} from '../reducers/advanced_search_reducer';

const actionCreator = actionCreatorFactory('AdvancedSearch');

type SearchResultToggle  = {
	index: number,
	selected: boolean
};

export const SelectAdvancedSearchResult = actionCreator<SearchResultToggle>(
	'SELECT_ADVANCED_SEARCH_RESULT'
);

//////// Temporarily Save Filters
export const SaveAdvSearchFormFilters = actionCreator<{
	filters: SearchFilterFormValues;
}>('SAVE_ADVANCED_SEARCH_FORM_FILTERS');

//////// Store Filters that have been Applied
export const StoreAdvancedSearchFilters = actionCreator<{
	filters: SearchFilterFormValues;
}>('STORE_APPLIED_ADVANCED_SEARCH_FILTERS');

export const SelectAllAdvancedSearchResults = actionCreator<boolean>(
	'SELECT_ALL_ADVANCED_SEARCH_RESULTS'
);
export function selectAllAdvancedSearchResults(toggle: boolean): ReduxAction {
	return SelectAllAdvancedSearchResults(toggle);
}

export const StoreSelectedContactIds = actionCreator(
	'STORE_SELECTED_CONTACT_IDS'
);
export function storeSelectedContactIds(): ReduxAction {
	return StoreSelectedContactIds();
}

//////// Search
export interface AdvancedSearchParams {
	searchFilterObject: SearchFilterFormValues;
	pageIndex: number;
	pageSize: number;
	processId?: string;
}
export const ExecuteAdvancedSearch = actionCreator.async<
	AdvancedSearchParams,
	SearchResultObject
>('EXECUTE_ADVANCED_SEARCH');

export function executeAdvancedSearch(
	searchFilterObject: SearchFilterFormValues,
	pageIndex: number,
	pageSize: number,
	processId?: string
): ReduxAction {
	return ExecuteAdvancedSearch.started({
		searchFilterObject,
		pageIndex,
		pageSize,
		processId
	});
}

export function executeAdvancedSearchSuccess(
	params: {
		searchFilterObject: SearchFilterFormValues;
		pageIndex: number;
		pageSize: number;
	},
	searchResults: SearchResultObject
): ReduxAction {
	return ExecuteAdvancedSearch.done({
		params,
		result: searchResults,
	});
}

export function executeAdvancedSearchFailure(
	params: {
		searchFilterObject: SearchFilterFormValues;
		pageIndex: number;
		pageSize: number;
	},
	error: any
): ReduxAction {
	return ExecuteAdvancedSearch.failed({
		params,
		error: error,
	});
}

//////// Select Saved Search Name
export const selectSavedSearchName = actionCreator<string>(
	'SELECT_SAVED_SEARCH_NAME'
);

//////// Set isBLeadSearch Value
export const setIsBLeadSearch = actionCreator<boolean>('SET_IS_B_LEAD_SEARCH');

//////// Get All Saved Corporate Advanced Search Filters
export const getSavedCorporateAdvSearchFilters = actionCreator.async<
	undefined,
	AdvSearchFilterPresetDto[]
>('GET_SAVED_CORPORATE_ADVANCED_SEARCH_FILTERS');

//////// Get All Saved User Advanced Search Filters
export const getSavedUserAdvSearchFilters = actionCreator.async<
	undefined,
	AdvSearchFilterPresetDto[]
>('GET_SAVED_USER_ADVANCED_SEARCH_FILTERS');

//////// Upsert Advanced Search Filters
export const upsertAdvancedSearchFilters = actionCreator.async<
	AdvSearchFilterPresetDto,
	AdvSearchFilterPresetDto
>('UPSERT_ADVANCED_SEARCH_FILTERS');

//////// Delete Advanced Search Filters
export const deleteSavedAdvancedSearchFilters = actionCreator.async<
	string,
	string
>('DELETE_SAVED_ADVANCED_SEARCH_FILTERS');

//////// Export Results
export interface AdvSearchResultExportObject {
	contactIds: string[];
	criteria: SearchFilterFormValues;
	selectFields: string[];
}

export const exportAdvancedSearchResults = actionCreator.async<
	AdvSearchResultExportObject,
	undefined
>('EXPORT_ADVANCED_SEARCH_RESULTS');

//////// Toggle Select All Button
export const SetAdvSearchSelectAllFlag = actionCreator<boolean>(
	'SET_ADV_SEARCH_SELECT_ALL_FLAG'
);

export const ExpandFilterFields = actionCreator<string>('EXPAND_FILTER_FIELDS');
