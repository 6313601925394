import { Action } from 'typescript-fsa';
import { ContactDedupeCheck } from "../actions/dedupe_actions";
import { call, put, all } from 'redux-saga/effects';
import { ContactDedupeDto, ContactDedupeResult } from '../reducers/dedupe_reducer';
import { http } from '../utilities';
import { HttpOptions } from '../utilities/http';
import { takeLatestForActionType } from '../utilities/saga_util';

function* contactDedupeCheckSaga(action: Action<ContactDedupeDto>) {
    try {
        const response = yield call(contactDedupeCheckClient, action.payload);
        if(response.ok) {
            const data: ContactDedupeResult[] = yield response.json();
			yield put(ContactDedupeCheck.done({ params: action.payload, result: data }));
        } else {
            yield put(
                ContactDedupeCheck.failed({
                    params: action.payload,
                    error: { errorCode: response.status }
                })
            );
        }
    } catch(error) {
        yield put(ContactDedupeCheck.failed({params: action.payload, error}));
    }
}

function contactDedupeCheckClient(dto: ContactDedupeDto) {
    const options: HttpOptions = {
        method: 'POST',
        body: JSON.stringify(dto)
    }
	return http('contact/CheckDuplicates', options);
}

export function* dedupeSagas() {
	yield all([
		yield takeLatestForActionType(ContactDedupeCheck.started, contactDedupeCheckSaga),
	]);
}