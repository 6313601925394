import { store } from '../';
import { QueueSnackbar } from '../actions/snackbar_actions';
import { submitLog } from './logging_util';
import { Strings } from '../assets/common/strings';
import { getSnackbarInfoProps } from './snackbar_util';
import { isBrokerage } from './brokerage_utils';
import { local_storage } from './storage';
import { AppConfig } from '../types/config';

export interface HttpOptions {
	method: 'GET' | 'POST' | 'PUT' | 'DELETE';
	headers?: { [key: string]: string } | undefined;
	mode?: Strings.HttpMode;
	cache?:
	| 'default'
	| 'no-store'
	| 'reload'
	| 'no-cache'
	| 'force-cache'
	| undefined;
	body?: string;
	credentials?: 'include';
}

export default async function http(
	endpoint: string,
	options?: HttpOptions,
	pagination?: { index: number; size: number }
): Promise<Response> {
	try {
		let jwt = local_storage.getJwt();

		const impersonatingJwt = local_storage.getImpersonatingJwt();
		if (impersonatingJwt) {
			jwt = impersonatingJwt;
		}

		const fetchOptions: HttpOptions = options || { method: 'GET' };

		const fetchHeaders = Object.assign(fetchOptions.headers || {}, {
			'Content-Type': 'application/json',
		});

		if (jwt) {
			fetchHeaders.Authorization = `Bearer ${jwt}`;
		}
		fetchOptions.headers = fetchHeaders;
		const baseUrl = (isBrokerage ? AppConfig.broker_web_api : AppConfig.web_api) + '/api/' + endpoint;

		let url = pagination
			? baseUrl.indexOf('?') < 0
				? baseUrl.concat(
					'?page=' +
					pagination.index.toString() +
					'&pageSize=' +
					pagination.size.toString()
				)
				: baseUrl.concat(
					'&page=' +
					pagination.index.toString() +
					'&pageSize=' +
					pagination.size.toString()
				)
			: baseUrl;

		if(url.indexOf('?') < 0) {
			url = url + '/';
		}

		const response = await fetch(url, fetchOptions);

		if (endpoint !== 'auth' && endpoint !== 'contest' && endpoint !== 'doc') {
			handleErrors(response);
		}
		return response;
	} catch (ex) {
		submitLog(Strings.Log.Error, `Fetch utility failed for web api endpoint`, {
			endpoint,
			error: ex,
		});
		return ex;
	}
}

export async function httpForNonWebApiEndpoints(
	fullEndpointUrl: string,
	options?: HttpOptions
) {
	try {
		let fetchOptions: HttpOptions = options || { method: 'GET' };
		fetchOptions.headers = Object.assign(fetchOptions.headers || {}, {
			'Content-Type': 'application/json',
		});

		const response = await fetch(fullEndpointUrl, fetchOptions);

		handleErrors(response);
		return response;
	} catch (ex) {
		submitLog(Strings.Log.Error, `Fetch utility failed for not web api endpoint`, {
			endpoint: fullEndpointUrl,
			error: ex,
		});
		return ex;
	}
}

function handleErrors(response: any) {
	if (!response.ok) {
		if (response.status === 401) {
			displaySnackbar('Unauthenticated user');
		} else if (response.status === 403) {
			displaySnackbar('User is unauthorized');
		} else if (response.status === 404) {
			displaySnackbar('Unable to reach server');
		} else if (response.status === 409) {
			return;
		} else if (response.status >= 500) {
			displaySnackbar('Server error');
		} else {
			displaySnackbar('Something went wrong');
		}
	}
}

function displaySnackbar(text: string) {
	store.dispatch(QueueSnackbar(getSnackbarInfoProps(text, 5000)));
}
