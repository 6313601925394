export const composeSearchString = (values: (string | string[] | undefined)[], inclueAllLabel?: boolean): string => {

    const normalizedValues: string[] = values.map(value => Array.isArray(value)
        ? composeSearchString(value)
        : value || ''
    );

    const result = normalizedValues
        .filter(value => Boolean(value) && (value != 'All' || inclueAllLabel))
        .join(', ');

    return result || 'All';
};

export const getDistinct = (values: string[], includeAllLabel?: boolean) => {
    const distinctValues = includeAllLabel
        ? ['All']
        : [];

    values.forEach(newValue => {
        if (distinctValues.every(value => value != newValue)) {
            distinctValues.push(newValue);
        }
    });

    return distinctValues;
};