import React from 'react';
import { defaultSvgProps, SvgProps } from '..';

const FinanceSvg: React.FC<SvgProps> = (props) => (
    <svg viewBox="0 0 35 35" {...props}>
        <path
            d="M18.75 12.62v.52a2.64 2.64 0 0 1 .89-.2 1 1 0 0 1 .64.19 1.1 1.1 0 0 1 .33.35.88.88 0 0 1 .12.43.85.85 0 0 1-.18.49 1 1 0 0 1-.42.37c-.32.14-.52.06-.61-.23a.61.61 0 0 0-.77-.44v2a9.24 9.24 0 0 1 .95-.1 1.22 1.22 0 0 1 .64.11.89.89 0 0 1 .45.43 1.49 1.49 0 0 1 .17.73 2.85 2.85 0 0 1-.26 1.14 4 4 0 0 1-.76 1.12 4.75 4.75 0 0 1-1.19.91v1.2a1.61 1.61 0 0 1-.05.44.44.44 0 0 1-.25.25q-.16.07-.24 0a.44.44 0 0 1-.07-.29v-1.3a2.8 2.8 0 0 1-1 .2 1.34 1.34 0 0 1-.7-.19 1.1 1.1 0 0 1-.42-.44 1.27 1.27 0 0 1-.13-.58.85.85 0 0 1 .18-.49 1 1 0 0 1 .45-.39.62.62 0 0 1 .36-.06.27.27 0 0 1 .21.19 3.75 3.75 0 0 0 .22.5.71.71 0 0 0 .29.25 1 1 0 0 0 .52 0v-2.27a4.57 4.57 0 0 1-1.08.11 1 1 0 0 1-.7-.31 1.31 1.31 0 0 1-.27-.92 2.75 2.75 0 0 1 .53-1.59 4.42 4.42 0 0 1 1.52-1.32v-.51a.56.56 0 0 1 .3-.55c.23-.1.33-.01.33.25zm-.61 3.59v-1.84a3.07 3.07 0 0 0-.63.61 1.14 1.14 0 0 0-.22.7q0 .39.21.48a1.34 1.34 0 0 0 .64.05zm.61 1.14v2.11a2.43 2.43 0 0 0 .75-.75 1.45 1.45 0 0 0 .27-.8c0-.28-.09-.46-.26-.53a1.51 1.51 0 0 0-.76-.03z"
        />
        <path
            d="M11 26.7a7.79 7.79 0 0 1-4.69-1.56.48.48 0 0 1-.2-.4v-13a.5.5 0 0 1 .27-.45.51.51 0 0 1 .52 0A6.83 6.83 0 0 0 11 12.7c2.38 0 4.86-1.18 7.27-2.32S23.31 8 25.91 8a7.85 7.85 0 0 1 4.69 1.52.52.52 0 0 1 .21.41v13a.49.49 0 0 1-.28.44.49.49 0 0 1-.52 0 6.77 6.77 0 0 0-4.1-1.37c-2.38 0-4.86 1.17-7.26 2.31S13.56 26.7 11 26.7zm-3.94-2.22A6.76 6.76 0 0 0 11 25.7c2.38 0 4.86-1.18 7.27-2.32S23.31 21 25.91 21a7.65 7.65 0 0 1 3.9 1V10.19A6.79 6.79 0 0 0 25.91 9c-2.38 0-4.86 1.17-7.26 2.31S13.56 13.7 11 13.7a7.62 7.62 0 0 1-3.89-1z"
        />
        <path
            d="M10.94 28.83a7.85 7.85 0 0 1-4.68-1.56.51.51 0 0 1-.11-.7.5.5 0 0 1 .7-.11c3.72 2.67 7.43.91 11.37-.95s8.18-3.88 12.38-.86a.5.5 0 0 1-.59.81c-3.71-2.68-7.43-.92-11.36.95-2.53 1.19-5.1 2.42-7.71 2.42zM10.94 16.38a7.78 7.78 0 0 1-1.61-.16.5.5 0 1 1 .2-1 9.4 9.4 0 0 0 5.29-.67.5.5 0 0 1 .35.93 12.13 12.13 0 0 1-4.23.9zM21.87 12.5a.5.5 0 0 1-.47-.32.49.49 0 0 1 .29-.64 10.33 10.33 0 0 1 5.84-.72.5.5 0 0 1 .39.59.51.51 0 0 1-.59.39 9.4 9.4 0 0 0-5.29.67.47.47 0 0 1-.17.03zM10.94 24a7.78 7.78 0 0 1-1.61-.17.5.5 0 0 1-.39-.59.52.52 0 0 1 .59-.39 9.4 9.4 0 0 0 5.29-.67.5.5 0 0 1 .64.3.5.5 0 0 1-.29.64 12.13 12.13 0 0 1-4.23.88zM21.87 20.13a.5.5 0 0 1-.18-1 10.33 10.33 0 0 1 5.84-.72.51.51 0 0 1 .39.6.5.5 0 0 1-.59.38 9.41 9.41 0 0 0-5.33.71.47.47 0 0 1-.13.03z"
        />
    </svg>
);
FinanceSvg.defaultProps = defaultSvgProps;

export default FinanceSvg;
