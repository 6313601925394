import React, { useEffect } from 'react';
import { initialAgentAddress } from '../../reducers/agent_reducer';
import { getAgentFullName, getBusinessAddress, getBusinessContactInfo, getIsTerminated } from '../../utilities/agent_util';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { Strings } from '../../assets/common/strings';
import { AgentHeader } from '../../components/agent/agent_header';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import { navRoutes } from '../../components/nav/Routes';
import { LandingProps } from './agent_profile_landing';
import { agentCannotBeDisplayed } from '../../assets/common/string_builders';
import { notificationCategories, NotificationSummary } from '../../components/notifications/notificationSummary';
import { DesktopPadding } from '../../components/Layout/desktop_padding';
import { BioCard } from '../../components/Profile/bio_card';
import { WideButton } from '../../components/utility/wide_button';
import { ContactInformationCard } from '../../components/Profile/contact_information_card';
import { EmptyMessage } from '../../components/utility/empty_message';
import { useNavigateTo } from '../../utilities/hooks';


type Props = LandingProps;

export const AgentProfile: React.FC<Props> = (props) => {

    const { agent, match, getAgentProfile } = props;
    
    useEffect(() => {
        getAgentProfile(match.params.agentcode);
    }, []);
    const navToHierarchy = useNavigateTo(navRoutes.agentHierarchy.path.replace(
        Strings.Navigation.AgentCode,
        match.params.agentcode,
    ));

    const agentFullName = getAgentFullName(agent);
    const isTerminated = getIsTerminated(agent) && agent.agentCode;
    const businessAddress = getBusinessAddress(agent, initialAgentAddress)!;
    const { businessEmail, businessPhone } = getBusinessContactInfo(agent);

    return (
        <BasePageContainer
            topComponent={
                <HeaderBarComponent
                    title={Strings.AboutMeLabels.AgentProfile}
                    customContent={
                        <AgentHeader agent={agent} isLoading={agent.isLoading} />
                    }
                    forceOverflow
                    isLoading={agent.isLoading}
                />
            }
        >
            <PersistentNotifications />
            <NotificationSummary categorySelector={notificationCategories.profile} />
            <DesktopPadding>
                {isTerminated
                    ? <EmptyMessage text={agentCannotBeDisplayed(agentFullName)} />
                    : (
                        <>
                            <BioCard
                                title={'Bio'}
                                agent={agent}
                            />
                            <ContactInformationCard
                                title="Contact Information"
                                agent={agent}
                                readOnly
                                businessEmail={businessEmail}
                                businessPhone={businessPhone}
                                businessAddress={businessAddress}
                            />
                            {/* {isAgentRole([Strings.AgentRoles.SponsorAgent, Strings.ADRoles.Assistant]) && 
                            agent.agentRoles.some(role => role === Strings.AgentRoles.SubAgent) && 
                            agent.reportsTo === viewingAgentCode
                                (
                                    <AgentEditCard 
                                        title={'Edit Fields'}
                                        agent={agent}
                                    />
                                )
                            } */}
                            <WideButton
                                primary={'View Hierarchy'}
                                onClick={navToHierarchy}
                            />
                        </>
                    )}
            </DesktopPadding>
        </BasePageContainer>
    );
};