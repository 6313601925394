import { AppState } from '../reducers';
import { select, takeLatest, takeEvery, put } from 'redux-saga/effects';
import { ActionCreator, Action, AsyncActionCreators } from 'typescript-fsa';
import { throttle } from 'redux-saga/effects';
import { submitLog } from './logging_util';
import { QueueSnackbar } from '../actions/snackbar_actions';
import { getSnackbarErrorProps } from './snackbar_util';
import { Strings } from '../assets/common/strings';

export function* selectFromImmutable<TValue, TProps = undefined>(selector?: (state: AppState, props?: TProps) => TValue, props?: TProps) {
    const state = yield select();

    const result = selector
        ? selector(state.App, props)
        : state.App;

    return result; 
}

const makeTakeForActionType = <TTake extends typeof takeLatest>(take: TTake) =>
    function <TActionPayload>(actionCreator: ActionCreator<TActionPayload>, handler: (action: Action<TActionPayload>) => IterableIterator<any> | Generator<any, void, any> | void) {
        return take(actionCreator.type, handler);
    }

export const takeLatestForActionType = makeTakeForActionType(takeLatest)
export const takeEveryForActionType = makeTakeForActionType(takeEvery)


export const throttleForActionType = function <TActionPayload>(throttleMs: number, actionCreator: ActionCreator<TActionPayload>, handler: (action: Action<TActionPayload>) => IterableIterator<any> | void) {
    return throttle(throttleMs, actionCreator.type, handler);
}

export function* createErrorMessages(errorText: string, error: any) {
    yield submitLog(error, errorText, {
        error: error,
    });
    yield put(
        QueueSnackbar(getSnackbarErrorProps(errorText))
    );
}

export const makeHandleError = (errorPreface?: string) => function* handleError(
    actionCreator: AsyncActionCreators<any, any, any>,
    action: Action<any>,
    error: any
) {
    const includeErrorInText = typeof error === typeof ''
    let errorText = '';
    if (errorPreface) {
        if (includeErrorInText) {
            errorText = `${errorPreface}: ${error}`
        } else {
            errorText = errorPreface
        }
    } else {
        if (includeErrorInText) {
            errorText = error;
        }
    }

    const errorSnackbarProps = getSnackbarErrorProps(errorText);
    submitLog(Strings.Log.Error, errorText, {
        error,
        payload: action.payload,
    });
    yield put(actionCreator.failed({ params: action.payload, error }));
    yield put(QueueSnackbar(errorSnackbarProps));
};
