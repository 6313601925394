import _ from 'lodash';

import { store, getAppState } from '../..';
import { setShowedOfflineSnackBar, QueueSnackbar, setDeviceConnectionStatus } from '../../actions/snackbar_actions';
import { SnackbarState } from '../../reducers/snackbar_reducer';

// TODO: Put these functionalities into top level app components so that they can
// TODO: managed by normal flow of redux (no need to pass store around) 
export const setupConnectivitySnackBars = () => {
    window.addEventListener('offline', offLineSnackBar, false);
    window.addEventListener('online', onLineSnackBar, false);
}

const displayOfflineSnackBar = () => {
	const networkState = _.get(navigator, 'connection.type', 'not found');
	if (networkState.toLowerCase() === 'none') {
		store.dispatch(setShowedOfflineSnackBar(true));
		store.dispatch(
			QueueSnackbar({
				text: 'No Connection',
				autoHideDuration: 8000,
			})
		);
	}
}

const offLineSnackBar = () => {
	store.dispatch(setDeviceConnectionStatus(false));

	setTimeout(displayOfflineSnackBar, 4000);
}

const onLineSnackBar = () => {
	const state: SnackbarState = getAppState().snackbar;

	if (state.showedOfflineSnackBar === true && state.deviceIsOnline === false) {
		store.dispatch(setDeviceConnectionStatus(true));
		store.dispatch(setShowedOfflineSnackBar(false));

		store.dispatch(
			QueueSnackbar({
				text: 'Connected',
				autoHideDuration: 3000,
			})
		);
	}
}
