import { AppState } from '../reducers/index';
import { createSelector } from 'reselect';
import { Loaded } from '../utilities/utilities';
import { Attachment } from '../reducers/attachment_reducer';
import { getPage, getId, PageType } from './base_selectors';

/**
 * Attachment selectors
 */
const getAttachments = (state: AppState) =>
	state.attachment.attachments || [];

export const getPageAttachments = createSelector(
	[getPage, getId, getAttachments],
	(page: string, id: string, attachments: Loaded<Attachment>[]) => {
		switch (page) {
			case 'HOUSEHOLD_PAGE': {
				return attachments.filter(attachment => attachment.householdId == id);
			}
			case 'EMPLOYER_PAGE': {
				return attachments.filter(attachment => attachment.employerId == id);
			}
			default:
				return attachments;
		}
	}
);
