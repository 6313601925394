import React from 'react';
import { User } from '../../reducers/user_reducer';
import { SmsTemplate, SmsTemplateField } from '../../reducers/sms_template_reducer';
import { NavigationProps, navRoutes } from '../../components/nav/Routes';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { Strings } from '../../assets/common/strings';
import H from 'history';
import uuid from 'uuid';
import { jwt_auth } from '../../utilities/auth';
import { P } from '../../utilities/auth/permissions';
import { Lookup, Lookups } from '../../utilities/lookup';
import { navigateBack, navigateTo } from '../../actions/navigation_actions';
import { GetAllSmsTemplates, CreateSmsTemplate, UpdateSmsTemplate, DeleteSmsTemplate } from '../../actions/sms_template_actions';
import { Dispatch } from 'redux';
import { connect } from '@optum-uhone-hmkts/rise';
import { SaveCancelHeaderBarComponent } from '../../components/Layout/SaveCancelHeaderBar';
import { TextField, MenuItem, Menu, Button } from '@material-ui/core';
import { DesktopPadding } from '../../components/Layout/desktop_padding';
import { GridContainer, GridItem } from '../../components/Layout/grid_wrapper';
import { CustomTextField, Fieldtype } from '../../components/Layout/forms/custom_text_field';
import SingleSelectDropdown from '../../components/Layout/forms/single_select_dropdown';
import { isMobileDevice } from '../../utilities/is_mobile';

export type SmsTemplatePreviewState = "Create" | "Edit" | "Preview" | "Activity";

export const initialSmsTemplate = (user: User): SmsTemplate => ({
    id: uuid.v4(),
    userId: user.id,
    createdOn: new Date(),
    createdBy: user.id,
    updatedOn: new Date(),
    updatedBy: user.id,
    isDeleted: false,
    content: '',
    subject: '',
    templateType: 'Agent',
    language: 'English',
    previewContent: '',
});

interface StateProps {
    templatePreviewState: SmsTemplatePreviewState;
    templates: SmsTemplate[];
    template?: SmsTemplate;
    user: User;
    isLoading: boolean;
    substitutions: string[];
}

interface DispatchProps {
    getSmsTemplates: (loadedTemplateId: string) => void;
    createSmsTemplate: (template: SmsTemplate) => void;
    updateSmsTemplate: (template: SmsTemplate) => void;
    deleteSmsTemplate: (template: SmsTemplate) => void;
    navigateBack: () => void;
}

type Props =
    & StateProps
    & DispatchProps
    & NavigationProps;

interface State {
    templateForm: SmsTemplate;
    variableMenuAnchoEl?: HTMLElement;
}

class _SmsTemplatePage extends React.Component<Props, State> {
    isPreviewMode: boolean;
    canEditCorporate: boolean;
    hasBeenEdited: boolean;
    errors?: string;
    isFreeForm: boolean;

    constructor(props: Props) {
        super(props);
        this.state = {
            templateForm: props.template || initialSmsTemplate(props.user),
        }
    }

    componentDidMount() {
        this.isPreviewMode = this.props.templatePreviewState == "Preview";
        this.isFreeForm = this.props.match.params.templateId == "Free-Form";
        this.canEditCorporate = jwt_auth.hasPermission(P.CorporateSmsUpdate);
        this.hasBeenEdited = false;

        if (!this.props.templates.length || this.isPreviewMode)
            this.props.getSmsTemplates(this.isPreviewMode ? this.props.match.params.templateId : undefined);
        else if (this.props.templatePreviewState == 'Create')
            this.setState({ templateForm: initialSmsTemplate(this.props.user) });
    };

    componentWillReceiveProps(nextProps: Props) {
        const templateId: string = this.getLoadedTemplateId();

        if (!this.state.templateForm || templateId || nextProps.template) {

            this.isPreviewMode = nextProps.templatePreviewState == "Preview";
            this.isFreeForm = this.props.match.params.templateId == "Free-Form";

            if (!this.hasBeenEdited) {
                const templateForm = nextProps.templates.find(template => template.id == templateId) || initialSmsTemplate(this.props.user);
                this.setState({
                    templateForm: templateForm,
                });
            }
        }
    };

    getLoadedTemplateId = () => this.props.match.params.templateId || '';

    getTemplateFieldFromProps = (field: keyof SmsTemplate) => this.props.template ? this.props.template[field] : null;

    getTemplateFieldFromState = (field: keyof SmsTemplate) => this.state.templateForm[field];

    validateField = (field: keyof SmsTemplate) => this.getTemplateFieldFromProps(field) != this.getTemplateFieldFromState(field);

    validateFieldWithError = (field: keyof SmsTemplate): string => {
        if (!this.validateField(field)) {
            switch (field) {
                case (SmsTemplateField.content): {
                    return "error SmsTemplateField.content";
                }
                case (SmsTemplateField.language): {
                    return "error SmsTemplateField.language";
                }
                case (SmsTemplateField.subject): {
                    return "error SmsTemplateField.subject";
                }
                case (SmsTemplateField.templateType): {
                    return "error SmsTemplateField.templateType";
                }
            }
        }
        return "";
    }

    validate = () => {
        const { templateForm } = this.state;


        if (!templateForm)
            return false;

        const errors: string[] = [];

        Object.keys(SmsTemplateField).forEach(key => {

            const error = this.validateFieldWithError(key);

            if (error)
                errors.push(error);

        });

        return errors;
    };

    isCorporateTemplate = () => Boolean(this.state.templateForm.templateType == Strings.TemplateType.Corporate);

    saveTemplate = () => {
        const error = this.validate();
        if (window.location.href.indexOf('Create')) {
            this.props.createSmsTemplate(this.state.templateForm);
        }
        else {
            this.props.updateSmsTemplate(this.state.templateForm);
        }
        this.props.navigateTo(navRoutes.smsTemplateManagement.path);
    };

    handleFieldChange = (field: keyof SmsTemplate) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = typeof event == 'string' ? event : event.target.value;

        this.hasBeenEdited = true;
        this.setState(prevState => ({
            templateForm: {
                ...prevState.templateForm,
                [field]: value
            }
        }));
    }

    navigateBack = () => {
        this.props.navigateBack();
    };


    onClickEdit = () => this.props.navigateTo(
        navRoutes.smsTemplatePreview.path
            .replace(Strings.Navigation.TemplateId, this.state.templateForm.id)
            .replace(Strings.Navigation.TemplatePageState, "Edit")
    );

    onCloseVariableMenu = () => this.setState({ variableMenuAnchoEl: undefined });

    onVariableClick = (variable: string) => () => {
        const { content } = this.state.templateForm;

        const newContent = content + Strings.TemplateInput.LeftSub + variable + Strings.TemplateInput.RightSub;

        this.setState(prevState => ({
            templateForm: {
                ...prevState.templateForm,
                content: newContent,
            }
        }), this.onCloseVariableMenu);
    }

    renderVariableMenu = (): JSX.Element => {
        return (
            <Menu
                open={Boolean(this.state.variableMenuAnchoEl)}
                anchorEl={this.state.variableMenuAnchoEl}
                onClose={this.onCloseVariableMenu}
            >
                {this.props.substitutions.map((substitution, index) => <MenuItem
                    key={index}
                    value={substitution}
                    onClick={this.onVariableClick(substitution)}
                >
                    {substitution}

                </MenuItem>
                )}
            </Menu>
        );
    };

    navigateToEdit = () => this.props.navigateTo(
        navRoutes.smsTemplatePreview.path
            .replace(Strings.Navigation.TemplateId, this.state.templateForm.id)
            .replace(Strings.Navigation.TemplatePageState, "Edit")
    );

    renderTopComponent = (): JSX.Element => {
        let saveText = '';
        let saveAction = () => { };
        const canEdit = !this.isCorporateTemplate() || this.canEditCorporate;

        saveText = this.isPreviewMode ? "Edit" : "Save";
        saveAction = this.isPreviewMode ? this.navigateToEdit : this.saveTemplate;

        const title = this.isPreviewMode ? Strings.TemplateDefaults.SmsTemplatePreview
            : Strings.TemplateDefaults.SmsTemplateContentEditor;

        return <SaveCancelHeaderBarComponent
            title={title}
            isSaveDisabled={!this.state.templateForm.subject || !this.state.templateForm.content || isMobileDevice}
            onSave={saveAction}
            saveText={saveText}
            isLoading={this.props.isLoading}
            onCancel={this.props.navigateBack}
            overrideCancelDialog
            useBackArrow={this.isPreviewMode}
            hideSave={!canEdit}
        />
    };

    renderMiddleComponent = (): JSX.Element => {
        return (
            <DesktopPadding showBackground >
                <GridContainer
                    style={{ padding: 45 }}
                >

                    <GridItem
                        hide={!jwt_auth.hasPermission(P.CorporateSmsCreate)}
                        fullWidth
                    >
                        <SingleSelectDropdown
                            label={Strings.TemplateInput.TType}
                            disabled={this.isPreviewMode}
                            options={[Strings.TemplateType.Agent, Strings.TemplateType.Corporate]}
                            value={this.state.templateForm.templateType}
                            changeFunction={this.handleFieldChange(Strings.TemplateInput.TemplateType)}
                        />
                    </GridItem>


                    <GridItem
                        centerContent={this.isPreviewMode}
                        fullWidth
                    >
                        <CustomTextField
                            fullWidth
                            defaultValue={this.state.templateForm.subject}
                            disabled={this.isPreviewMode}
                            label={Strings.TemplateInput.TTitle}
                            onChange={this.handleFieldChange(Strings.TemplateInput.Subject)}
                            value={
                                this.isFreeForm
                                    ? "Free-Form SMS"
                                    : this.state.templateForm.subject
                            }
                            fieldType={Fieldtype.Title}
                            inputProps={{
                                maxLength: 255
                            }}
                        />
                    </GridItem>


                    <GridItem>
                        <SingleSelectDropdown
                            label={Strings.TemplateInput.TLanguage}
                            disabled={this.isPreviewMode}
                            options={[Strings.Languages.English, Strings.Languages.Spanish]}
                            value={this.state.templateForm.language}
                            changeFunction={this.handleFieldChange(Strings.TemplateInput.Language)}
                        />
                    </GridItem>

                    <GridItem
                        hide={this.isPreviewMode}
                    >
                        <Button
                            aria-owns={this.state.variableMenuAnchoEl ? 'simple-menu' : undefined}
                            aria-haspopup="true"
                            disabled={this.isPreviewMode}
                            onClick={event => this.setState({
                                variableMenuAnchoEl: event.currentTarget
                            })}
                            children={Strings.ContentEditor.Button}
                            color="primary"
                            fullWidth
                        />
                    </GridItem>

                    <GridItem fullWidth>
                        <TextField
                            placeholder="Write a new SMS template..."
                            fullWidth
                            disabled={this.isPreviewMode}
                            onChange={this.handleFieldChange(Strings.TemplateInput.Content)}
                            multiline={true}
                            label={Strings.TemplateInput.TContent}
                            value={
                                this.isFreeForm
                                    ? "Free-Form SMS. No SMS Template was used."
                                    : this.isPreviewMode
                                        ? this.state.templateForm.previewContent || ""
                                        : this.state.templateForm.content
                            }
                            inputProps={{
                                maxLength: 800
                            }}
                        />
                    </GridItem>

                </GridContainer>
            </DesktopPadding >
        );
    };

    render() {
        return (
            <BasePageContainer
                topStyle={{ textAlign: Strings.CSS.Center }}
                topComponent={this.renderTopComponent()}
                middleComponent={this.renderMiddleComponent()}
                pageDialogs={[this.renderVariableMenu()]}
            />
        );
    }
}


function mapStateToProps(
    state,
    ownProps: Props,
): StateProps {
    

    const dictionary = state.smsTemplate.smsTemplates;

    const smsTemplates = Object.keys(dictionary).map(templateId => dictionary[templateId]);
    const templateId = ownProps.match.params.templateId;

    let smsTemplate = smsTemplates.find(template => template.id == templateId);

    const smsTemplateState = ownProps.match.params.templateState;

    return {
        templates: smsTemplates,
        template: smsTemplate,
        isLoading: state.smsTemplate.isLoading,
        templatePreviewState: smsTemplateState,
        user: state.user,
        substitutions: state.lookup.getLabels(Lookups.EmailTemplateSubstitution),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: Props): DispatchProps & Partial<NavigationProps> {
    return {
        navigateBack: () =>
            dispatch(navigateBack()),
        navigateTo: (route: string) =>
            dispatch(navigateTo(route)),
        getSmsTemplates: (templateId) =>
            dispatch(GetAllSmsTemplates.started(templateId)),
        createSmsTemplate: (template: SmsTemplate) =>
            dispatch(CreateSmsTemplate.started(template)),
        updateSmsTemplate: (template: SmsTemplate) =>
            dispatch(UpdateSmsTemplate.started(template)),
        deleteSmsTemplate: (template: SmsTemplate) =>
            dispatch(DeleteSmsTemplate.started(template)),

    };
}

export const SmsTemplatePage = connect(
    mapStateToProps,
    mapDispatchToProps,
    true
)(_SmsTemplatePage);