import React from 'react';
import { Contact } from '../../reducers/ContactReducer';
import { isMobileDevice } from '../../utilities/is_mobile';
import { normalizePhone } from '../../utilities/formatting/data_normalizations';
import { Strings } from '../../assets/common/strings';
import { connect } from '@optum-uhone-hmkts/rise';
import { fullName } from '../../assets/common/string_builders';
import { SelectDialog, ListSelectionAction } from './templates/select_dialog';
import jwt_auth from '../../utilities/auth/jwt_auth';
import { P } from '../../utilities/auth/permissions';
import { orderContacts } from '../../utilities/contact_util';
import { isPhoneWithinGracePeriod } from '../../utilities/phone_email_util';
import { ContactVerificationResult } from '../../reducers/click_to_call_reducer';
import moment from 'moment';

type PartialSelectDialogProps = {
	title: string;
	listTitle: string;
	secondaryListTitle: string;
	selectActionIconClass: string;
	selectActionLabel: string;
};

type CommunicationListItemProps = {
	primary: string;
	secondary: string;
	isDisabled?: boolean;
	id: string;
	value: string;
}

type StateProps = {
	desktopCallEnabled: boolean;
	desktopTextEnabled: boolean;
	leadListMultiSelect: boolean;
	templateTitle: string;
	click2callEnabled: boolean;
	verificationResult?: ContactVerificationResult;
}
interface OwnProps {
	open: boolean;
	handleClose: () => void;
	handleSelection: (selection: { id: string, value: string }[]) => void;
	selectableCount?: number;
	activityType: Strings.Activity;
	contacts: Contact[];
	secondaryActions?: ListSelectionAction[];
	autoselect?: boolean;
	hasActiveBusinessRelationship?: boolean;
}

type Props = StateProps & OwnProps;

const _SelectContactDialog: React.FC<Props> = (props) => {
	const {
		desktopCallEnabled,
		open,
		contacts,
		handleClose,
		handleSelection,
		selectableCount,
		activityType,
		secondaryActions,
		leadListMultiSelect,
		templateTitle,
		autoselect,
		hasActiveBusinessRelationship,
		click2callEnabled,
		verificationResult,
	} = props;

	const getSelectDialogProps = (): PartialSelectDialogProps => {

		let result: PartialSelectDialogProps = {
			title: '',
			listTitle: Strings.SelectContact.PreferredNumbers,
			secondaryListTitle: Strings.SelectContact.OtherNumbers,
			selectActionIconClass: '',
			selectActionLabel: '',
		}

		switch (activityType) {
			case Strings.Activity.PhoneCall:
				result.title = desktopCallEnabled || isMobileDevice ? 'Make a call to:' : 'Made call to:';
				result.selectActionIconClass = 'icon-call-check-bttn';
				result.selectActionLabel = 'Next';
				break;

			case Strings.Activity.Sms:
				result.title = 'Send SMS to:';
				if (jwt_auth.hasPermission(P.SmsTemplateCreate) && !leadListMultiSelect) {
					result.selectActionIconClass = 'icon-text-contact-button';
					result.selectActionLabel = 'USE TEMPLATE';
				}
				break;

			case Strings.Activity.Email:
				result = {
					title: 'Send email to:',
					listTitle: Strings.SelectContact.PreferredEmails,
					secondaryListTitle: Strings.SelectContact.SecondaryEmails,
					selectActionIconClass: 'icon-email-contact-button',
					selectActionLabel: 'Open Email',
				};
				break;

			case Strings.Activity.ClientConnect:
				result.title = 'Share ClientConnect to:';
				result.listTitle = isMobileDevice ? Strings.SelectContact.PreferredNumbers : Strings.SelectContact.PreferredEmails;
				result.secondaryListTitle = isMobileDevice ? Strings.SelectContact.OtherNumbers : Strings.SelectContact.SecondaryEmails;
				result.selectActionIconClass = isMobileDevice ? 'icon-text-contact-button' : 'icon-email-contact-button';
				result.selectActionLabel = 'Share';
				break;
		}

		return result;
	}

	const getListItems = (contacts: Contact[], activityType: Strings.Activity): { listItems: CommunicationListItemProps[], secondaryListItems: CommunicationListItemProps[] } => {
		switch (activityType) {

			case (Strings.Activity.Email):
				return getEmailListItems(contacts);

			case (Strings.Activity.PhoneCall):
				return getPhoneListItems(contacts, undefined, undefined);

			case (Strings.Activity.Sms):
				return getPhoneListItems(contacts, undefined, true);

			case (Strings.Activity.ClientConnect):
				return isMobileDevice
					? getPhoneListItems(contacts, Strings.PhoneTypes.Mobile, undefined)
					: getEmailListItems(contacts);

			default:
				return { listItems: [], secondaryListItems: [] }
		}
	}

	const getEmailListItems = (contacts: Contact[]) => {
		const listItems: CommunicationListItemProps[] = [];
		const preferredListItems: CommunicationListItemProps[] = [];

		contacts.forEach(contact => {
			contact.emails.forEach(email => {
				const item: CommunicationListItemProps = {
					primary: fullName(contact),
					secondary: email.type + ': ' + email.emailAddress,
					value: email.emailAddress,
					id: contact.id,
					isDisabled: contact.status == Strings.Status.Inactive || email.doNotEmail
				};

				if (email.isPreferred)
					preferredListItems.push(item);
				else
					listItems.push(item);
			});
		});
		return { listItems: preferredListItems, secondaryListItems: listItems };
	}

	const getPhoneListItems = (contacts: Contact[], phoneType?: Strings.PhoneTypes, isSms?: boolean) => {
		const listItems: CommunicationListItemProps[] = [];
		const preferredListItems: CommunicationListItemProps[] = [];

		if (contacts) {
			const ordered = orderContacts(contacts);
			ordered.forEach(contact => {
				contact.phones.forEach(phone => {
					if (!phoneType || phone.type == phoneType) {
						const item: CommunicationListItemProps = {
							primary: fullName(contact),
							secondary: `${phone.type}: ${normalizePhone(phone.number)}`,
							isDisabled: (
								phone.isCompanyDnc ||
								(phone.isNationalDnc && !isPhoneWithinGracePeriod(phone) && !hasActiveBusinessRelationship) ||
								(isSms && phone.isSmsDnc) ||
								(contact.status == Strings.Status.Inactive) ||
								(click2callEnabled && verificationResult && verificationResult.sessionHousehold &&
									Boolean(verificationResult.sessionHousehold.households.length) &&
									verificationResult.sessionHousehold.households.some(
										result =>
											contact.householdId == result.householdId &&
											result.wasCalled
									)
								)
							),
							value: phone.number,
							id: contact.id,
						};

						if (phone.isPreferred) {
							if (!preferredListItems.find(i => i.value == item.value)) {
								preferredListItems.push(item);
							}
						} else {
							if (!listItems.find(i => i.value == item.value)) {
								listItems.push(item);
							}
						}
					}
				});
			});
		}
		return { listItems: preferredListItems, secondaryListItems: listItems };
	}


	const allListItems = getListItems(contacts, activityType);
	const { listItems, secondaryListItems } = allListItems;
	const options = [...listItems, ...secondaryListItems];

	const onlyOneNotDisabled = (options.length == 1 && !options[0].isDisabled);

	return <SelectDialog
		key='select-contact-dialog'
		open={open}
		alternateTitle={leadListMultiSelect ? templateTitle : ''}
		selectableCount={leadListMultiSelect ? options.length : selectableCount || 1}
		handleClose={handleClose}
		handleSelect={handleSelection}
		secondaryActions={secondaryActions}
		{...getSelectDialogProps()}
		{...allListItems}
		autoselect={autoselect && onlyOneNotDisabled}
	/>
}


function mapStateToProps(state, ownProps: Props): StateProps {

	const { template } = state.smsSequence;
	return {
		desktopTextEnabled: state.user.enableDesktopTexts,
		desktopCallEnabled: state.user.enableDesktopCalls,
		leadListMultiSelect: state.smsSequence.leadListMultiSelect,
		templateTitle: template ? template.subject : '',
		click2callEnabled: state.clickToCall.enabled,
		verificationResult: state.clickToCall.verificationResult,
	};
}

export const SelectContactDialog = connect<StateProps, {}, OwnProps>(mapStateToProps, undefined, true)(_SelectContactDialog);