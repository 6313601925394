import { ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';
import React from 'react';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { StopPropagation } from '../../utilities/html';
import { useListItemStyles } from '../../utilities/hooks/styles';
import classNames from 'classnames';

interface Props {
    title?: React.ReactNode;
    subtitle?: React.ReactNode;
    divider?: boolean;
    onClick?: () => void;
    icon?: React.ReactNode;
    secondaryAction?: React.ReactNode;
    hideArrow?: boolean;
    disabled?: boolean;
    style?: React.CSSProperties;
    rightText?: React.ReactNode;
}
export const SimpleListItem: React.FC<Props> = (props) => {

    const { 
        title, subtitle, divider=true, onClick, icon, secondaryAction, 
        hideArrow=false, disabled=false, style, rightText 
    } = props;
    const clickable = !!onClick;
    const styles = useListItemStyles();

    return (
        <ListItem
            button={clickable}
            divider={divider}
            onClick={onClick}
            style={style}
            disabled={disabled}
            className={classNames(disabled && styles.disabled)}
        >
            {icon && <ListItemIcon className={styles.leftIcon}>{icon}</ListItemIcon>}
            <ListItemText
                primary={title}
                secondary={subtitle}
                className={classNames(secondaryAction && styles.textPadding, rightText && styles.padded)}
            />
            {rightText && <Typography className={styles.rightText} variant="caption">{rightText}</Typography>}
            {secondaryAction
                ? (
                    <StopPropagation>
                        <ListItemSecondaryAction>{secondaryAction}</ListItemSecondaryAction>
                    </StopPropagation>
                )
                : (clickable && !hideArrow) 
                    ? (<ListItemIcon className={styles.arrowIcon}><KeyboardArrowRightIcon/></ListItemIcon>)     
                    : undefined       
            }
        </ListItem>
    );
};