import { actionCreatorFactory } from 'typescript-fsa';
import { Action as ReduxAction } from 'redux';
import {
	Activity,
	GoogleSignInRequest,
	GoogleSignInResponse,
} from '../reducers/activity_reducer';
import { ActivityFilters } from '../reducers/activity_filter_reducer';
import moment from 'moment';
import { Strings } from '../assets/common/strings';
import { AppConfig } from '../types/config';

const actionCreator = actionCreatorFactory('Activity');

// FETCH ACTIVITY
export const GetActivity = actionCreator.async<string, Activity>(
	'GET_ACTIVITY'
);

// SEARCH ACTIVITIES
export type FilterOptions = {
	searchText?: string;
	type?: 'all' | Strings.Activity.Task | Strings.Activity.PhoneCall | Strings.Activity.Appointment;
	status?: 'open' | 'completed';
	isHighPriority?: boolean;
	startTime?: Date;
	endTime?: Date;
};
export interface GetActivitiesParams {
	filters: FilterOptions;
	pageNum: number;
	pageSize: number;
	skipPageIncrease?: boolean;
	clearCache?: boolean;
}
export const GetPagedActivities = actionCreator.async<
	GetActivitiesParams,
	Activity[]
>('GET_PAGED_ACTIVITIES');

// CREATE HOUSEHOLD ACTIVITY
export const CreateActivity = actionCreator.async<Activity, Activity>(
	'CREATE_HOUSEHOLD_ACTIVITY'
);

// CREATE EMPLOYER ACTIVITY
export const CreateEmployerActivity = actionCreator.async<Activity, Activity>(
	'CREATE_EMPLOYER_ACTIVITY'
);

// EDIT ACTIVITY
export const EditActivity = actionCreator.async<Activity, Activity>(
	'EDIT_ACTIVITY'
);

// DELETE ACTIVITY
export const DeleteActivity = actionCreator.async<Activity, undefined>(
	'DELETE_ACTIVITY'
);

// GET GOOGLE SIGN IN CODE
export const GetGoogleSignInCode = actionCreator.async<
	GoogleSignInRequest,
	GoogleSignInResponse
>('GET_GOOGLE_CODE');
export function getGoogleSignInCode(): ReduxAction {
	let request = {
		client_id: AppConfig.google_client_id,
		scope: AppConfig.google_calendar_code_scope,
	} as GoogleSignInRequest;

	return GetGoogleSignInCode.started(request);
}

// DISABLE GOOGLE CALENDAR SYNC
export const DisableGoogleCalendarSync = actionCreator.async<void, void>(
	'DISABLE_GOOGLE_CALENDAR_SYNC'
);

// STORE FILTERS
export const StoreActivityFilters = actionCreator<ActivityFilters>(
	'Store_Activity_Filters'
);

// STORE FOLLOWUP DESCRIPTION STATUS AND DATE
export const StoreFollowupDescription = actionCreator<{
	description: string;
	time?: moment.Moment;
	title?: string;
}>('STORE_FOLLOWUP_DESCRIPTION');

export const GetActivitiesByContactId = actionCreator.async<string, Activity[]>(
	'GET_ACTIVITIES_BY_CONTACT_ID'
);

// CLOSE OLD ACTIVITIES
export const CloseOldActivities = actionCreator<undefined>(
	'CLOSE_OLD_ACTIVITIES'
);
