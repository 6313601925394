import { AppState } from '../reducers/index';
import { createSelector } from 'reselect';
import { Loaded } from '../utilities/utilities';
import { Application } from '../reducers/application_reducer';
import { getPage, getId, PageType } from './base_selectors';

/**
 * Application selectors
 */
const getApplications = (state: AppState) =>
	state.application.applications || [];

export const getPageApplications = createSelector(
	[getPage, getId, getApplications],
	(page: PageType, id: string, applications: Loaded<Application>[]) => {
		switch (page) {
			case 'HOUSEHOLD_PAGE': {
				return applications.filter(
					application =>
						application.data.primaryInsured && application.householdId == id
				);
			}
			case 'EMPLOYER_PAGE': {
				return applications.filter(application => application.employerId == id);
			}
			default:
				return applications;
		}
	}
);
