import React, { useState } from 'react';
import { FormControl, Grid } from '@material-ui/core';
import { Field } from 'redux-form';
import { themePalette } from '../../utilities/branding';
import { DatePicker } from '.';

interface Props {
	name: string;
	title?: string;
	onChange?: () => void;
	fromMinDate?: Date;
}

const ToFromPickers: React.FC<Props> = (props) => {

	const [minDate, setMinDate] = useState<Date>();
	const [maxDate, setMaxDate] = useState<Date>();
	const fromChange = (val: any) => {
		setMinDate(val as Date);
	};
	const toChange = (val: any) => {
		setMaxDate(val as Date);
	};

	return (
		<FormControl fullWidth>
			<span
				style={{
					fontSize: '16px',
					color: themePalette.sub_text,
				}}
			>
				{props.title}
			</span>
			<Grid container>
				<Grid item xs={6}>
					<Field
						name={props.name + 'From'}
						label="From"
						component={DatePicker}
						fullWidth
						onChange={fromChange}
						startOfDay
						maxDate={maxDate}
					/>
				</Grid>
				<Grid item xs={6}>
					<Field
						name={props.name + 'To'}
						label="To"
						component={DatePicker}
						fullWidth
						onChange={toChange}
						endOfDay
						minDate={minDate}
					/>
				</Grid>
			</Grid>
		</FormControl>
	);
}

export default ToFromPickers;
