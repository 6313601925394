import actionCreatorFactory from "typescript-fsa";
import { StateProps } from "../components/Layout/table/filter_table";

const actionCreator = actionCreatorFactory('Table');

export type UpdateTablePayload = {
	tableId: string;
	tableState: Partial<StateProps>;
};

export const UpdateTable = actionCreator<UpdateTablePayload>('UpdateTable');