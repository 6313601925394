import React, { useEffect, useState } from 'react';
import {
    Avatar, Grid, List, ListItem, ListItemAvatar, ListItemText, ChevronRightIcon, RiseCard, Button,
    useDispatch, riseStrings, connect, nav, useSelector, useFormActions,
    FormSlice, LayoutSlice, ConfigSlice
} from '@optum-uhone-hmkts/rise'
import { Strings } from '../../../../assets/common/strings';
import { useLinkToExternal } from '../../../../utilities/hooks';

type StateProps = {}
type DispatchProps = {}
type OwnProps = {
    id: string;
}

type Props = StateProps &
    DispatchProps &
    OwnProps;

const _FactFinderCompleteWidget: React.FC<Props> = (props) => {
    const dispatch = useDispatch();

    const redirectLinks = useSelector(app => ConfigSlice.selectState(app).appLinks);
    const formId = useSelector(app => FormSlice.selectState(app).activeForm.formId);
    const contactId = useSelector(app => FormSlice.selectState(app).activeForm.contactId);
    const householdId = useSelector(app => FormSlice.selectState(app).activeForm.householdId);
    const contactIsLoading = useSelector((app: any) => app?.App?.contact?.isLoading);

    //put contact into state (App.contact.contacts) for suggested contact
    useEffect(() => {
        if (householdId) {
            dispatch({
                type: 'Household/GET_HOUSEHOLD_STARTED',
                payload: householdId
            });
        }
    }, [householdId]);

    //indicate when contact is loaded in state
    const [loadContactStarted, setLoadContactStarted] = useState(false);
    const [loadContactFinished, setLoadContactFinished] = useState(false);
    useEffect(() => {
        if (contactIsLoading) {
            setLoadContactStarted(true)
        }

        if (!contactIsLoading && loadContactStarted) {
            setLoadContactFinished(true)
        }
    }, [contactIsLoading, loadContactStarted]);

    //indicate when ids are loading in state
    const [loadIDsFinished, setloadIDsFinished] = useState(false);
    useEffect(() => {
        if (contactId !== '' && householdId) {
            setloadIDsFinished(true)
        }
    }, [contactId, householdId]);

    //enable buttons when contact and ids are in state
    const [disableButton, setDisableButton] = useState(true);
    useEffect(() => {
        if (loadContactFinished && loadIDsFinished) {
            setDisableButton(false)
        }
    }, [loadContactFinished, loadIDsFinished]);

    const formActions = useFormActions();

    const closeDialog = () => {
        dispatch(
            LayoutSlice.actions.DialogClose({
                id: riseStrings.Main,
                sliceName: riseStrings._FactFinder,
                componentName: riseStrings._FactFinderCompleteWidget
            })
        );
    }

    const qcQuoteId = contactId;
    const url = `${redirectLinks.quoteConnect}Quote/GetActivityCenterFamily?activityCenterContactId=${qcQuoteId || "not-linked"}`;
    const navToQuoteConnect = useLinkToExternal(url);

    return (
        <Grid
            container
            alignContent="center"
            justifyContent="center"
        >
            <RiseCard
                gridSize="6|12"
                gridStyle={{ paddingTop: 48 }}
                cardStyle={{ padding: 16 }}
                titleText={riseStrings.FactFinderCompleteHeading}
                subtitleText={riseStrings.FactFinderCompleteSubheading}
                isLoading={disableButton}
                cardActions={
                    <Button
                        children={riseStrings.GoBack}
                        gridSize="12"
                        onClick={() => {
                            closeDialog();
                            if (!!householdId) {
                                dispatch({ type: "Household/GET_HOUSEHOLD_STARTED", payload: householdId });
                            }
                            nav.goBack()
                        }}
                    />
                }
            >
                <List>
                    <ListItem
                        button
                        disabled={disableButton}
                        onClick={() => {
                            closeDialog();
                            nav.push(riseStrings.PhoneCreatePath.replace(":householdId", householdId));
                        }}
                    >
                        <ListItemAvatar>
                            <Avatar>
                                <ChevronRightIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={riseStrings.SchedulePhoneCall} />
                    </ListItem>
                    <ListItem
                        button
                        disabled={disableButton}
                        onClick={() => {
                            closeDialog();
                            nav.push(riseStrings.AppointmentCreatepath.replace(":householdId", householdId));
                        }}
                    >
                        <ListItemAvatar>
                            <Avatar>
                                <ChevronRightIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={riseStrings.ScheduleAppointment} />
                    </ListItem>
                    <ListItem
                        button
                        disabled={disableButton}
                        onClick={navToQuoteConnect}
                    >
                        <ListItemAvatar>
                            <Avatar>
                                <ChevronRightIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={riseStrings.StartQuote}
                        />
                    </ListItem>
                    <ListItem
                        button
                        disabled={disableButton}
                        onClick={formActions.createDownloadForm(formId)}
                    >
                        <ListItemAvatar>
                            <Avatar>
                                <ChevronRightIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={riseStrings.DownloadPdf} />
                    </ListItem>
                </List>
            </RiseCard>
        </Grid>

    )
}


const mapStateToProps = (): StateProps => {
    return {};
}


const mapDispatchToProps = (): DispatchProps => {
    return {};
}

export const FactFinderCompleteWidget = connect(
    mapStateToProps,
    mapDispatchToProps,
    [
        riseStrings._FactFinder,
        riseStrings._FactFinderCompleteWidget
    ]
)(_FactFinderCompleteWidget);
