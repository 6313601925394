import { Action } from 'typescript-fsa';
import { call, put, all } from 'redux-saga/effects';
import {
	GetCompanyDNCPhoneList,
	getCompanyDNCPhoneListFailure,
	getCompanyDNCPhoneListSuccess,
	AddPhoneToCompanyDNC,
	addPhoneToCompanyDNCFailure,
	addPhoneToCompanyDNCSuccess,
	GetDncParams,
} from '../actions/dnc_phone_actions';
import http, { HttpOptions } from '../utilities/http';
import { QueueSnackbar } from '../actions/snackbar_actions';
import { themePalette } from '../utilities/branding';
import { takeLatestForActionType } from '../utilities/saga_util';
import { getSnackbarSuccessProps } from '../utilities/snackbar_util';

//////////  Get Company Dnc List  //////////
function* getCompanyDNCPhoneListSaga(action: Action<GetDncParams>) {
	try {
		const response = yield call(getCompanyDNCPhoneListClient, action.payload);

		if (response.ok) {
			const data = yield response.json();
			yield put(getCompanyDNCPhoneListSuccess(action.payload, data));
		} else {
			yield put(
				getCompanyDNCPhoneListFailure(action.payload, {
					errorCode: response.status,
				})
			);
			yield put(
				QueueSnackbar({
					text: 'Failed to retrieve DNC List',
					textColor: themePalette.negative_text,
					backgroundColor: themePalette.info_snackbar,
					autoHideDuration: 4000,
				})
			);
		}
	} catch (error) {
		yield put(getCompanyDNCPhoneListFailure(action.payload, error));
		yield put(
			QueueSnackbar({
				text: 'Failed to retrieve DNC List',
				textColor: themePalette.negative_text,
				backgroundColor: themePalette.info_snackbar,
				autoHideDuration: 4000,
			})
		);
	}
}

function getCompanyDNCPhoneListClient(params: {
	searchText: string;
	pageNum: number;
	pageSize: number;
}): Promise<any> {
	const options: HttpOptions = {
		method: 'GET',
	};
	return http(
		'dnc/getCompanyDncListByPhone?searchText=' + params.searchText,
		options,
		{ index: params.pageNum, size: params.pageSize }
	);
}

//////////  Add Phone To Company DNC List  //////////
function* addPhoneToCompanyDNCListSaga(action: Action<string>) {
	try {
		const response = yield call(
			addPhoneToCompanyDNCListClient,
			action.payload
		);

		if (response.ok) {
			const data = yield response.json();
			yield put(addPhoneToCompanyDNCSuccess(action.payload, data));
			yield put(
				QueueSnackbar(getSnackbarSuccessProps('Phone Number added'))
			);
		} else {
			yield put(
				addPhoneToCompanyDNCFailure(action.payload, {
					errorCode: response.status,
				})
			);
			yield put(
				QueueSnackbar({
					text: 'Failed to add number to Company DNC',
					textColor: themePalette.negative_text,
					backgroundColor: themePalette.info_snackbar,
					autoHideDuration: 4000,
				})
			);
		}
	} catch (error) {
		yield put(addPhoneToCompanyDNCFailure(action.payload, error));
		yield put(
			QueueSnackbar({
				text: 'Failed to add number to Company DNC',
				textColor: themePalette.negative_text,
				backgroundColor: themePalette.info_snackbar,
				autoHideDuration: 4000,
			})
		);
	}
}

function addPhoneToCompanyDNCListClient(number: string): Promise<any> {
	const options: HttpOptions = {
		method: 'POST',
	};
	return http('dnc/AdminAddToCompanyDnc?phoneNumber=' + number, options);
}


export function* dncPhoneSagas() {
	yield all([
		takeLatestForActionType(
			GetCompanyDNCPhoneList.started,
			getCompanyDNCPhoneListSaga
		),
		takeLatestForActionType(
			AddPhoneToCompanyDNC.started,
			addPhoneToCompanyDNCListSaga
		)
	])
}