import {
	GetEmailAutomationsByAgentId,
	PostEmailAutomation,
	postEmailAutomationSuccess,
	postEmailAutomationFailure,
	deconstructAndStoreEmailEvents,
} from '../actions/email_automation_actions';
import { QueueSnackbar } from '../actions/snackbar_actions';
import { call, put, all } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { HttpOptions } from '../utilities/http';
import { http } from '../utilities';
import { EmailAutomation, EmailAutomationEvent } from '../reducers/email_automation_reducer';
import { Strings } from '../assets/common/strings';
import { takeLatestForActionType } from '../utilities/saga_util';
import { getSnackbarInfoProps, getSnackbarSuccessProps } from '../utilities/snackbar_util';
import { getReferrerUrl } from '../utilities/brokerage_utils';


export interface EmailAutomationResponse {
	[emailScope: string]: { [emailGroup: string]: EmailAutomationEvent[] }
};

//Watch for GET
function* getEmailAutomationsByAgentIdSaga(action: Action<undefined>) {
	const snackbar = getSnackbarInfoProps('Unable to retrieve Email Automation Events.', 6000);
	try {
		const response = yield call(getEmailAutomationsByAgentIdClient,);
		if (response.ok) {
			const data = yield response.json();
			yield put(GetEmailAutomationsByAgentId.done({ params: undefined, result: data }));
			yield put(deconstructAndStoreEmailEvents(data))
		} else {
			yield put(
				GetEmailAutomationsByAgentId.failed({
					params: undefined,
					error: response.status,
				})
			);
			yield put(QueueSnackbar(snackbar));
		}
	} catch (error) {
		yield put(GetEmailAutomationsByAgentId.failed({ params: undefined, error }));
		yield put(QueueSnackbar(snackbar));
	}
}

function getEmailAutomationsByAgentIdClient(): Promise<any> {
	return http(`Email/EmailAutomation?referrerUrl=${getReferrerUrl()}`);
}

// Watch for POST
function* postEmailAutomationSaga(action: Action<EmailAutomation>) {
	const snackbar = getSnackbarInfoProps('Unable to Update Email Automation Events.', 6000);
	try {
		const response = yield call(postEmailAutomationClient, action.payload);
		if (response.ok) {
			yield all([
				put(postEmailAutomationSuccess(action.payload)),
				put(QueueSnackbar(getSnackbarSuccessProps('Updated Email Automation Configuration'))),
			]);
		} else {
			yield put(
				postEmailAutomationFailure(action.payload, {
					errorCode: response.status,
				})
			);
			yield put(QueueSnackbar(snackbar));
		}
	} catch (error) {
		yield all([
			put(postEmailAutomationFailure(action.payload, error)),
			put(QueueSnackbar(snackbar)),
		]);
	}
}

function postEmailAutomationClient(
	emailAutomation: EmailAutomation
): Promise<any> {
	const options: HttpOptions = {
		method: Strings.Http.Post,
		body: JSON.stringify(emailAutomation),
	};

	return http('Email/EmailAutomation', options);
}

export function* emailAutomationSagas() {
	yield all([
		takeLatestForActionType(
			GetEmailAutomationsByAgentId.started,
			getEmailAutomationsByAgentIdSaga
		),
		takeLatestForActionType(
			PostEmailAutomation.started,
			postEmailAutomationSaga
		),
	])
}