import React from 'react';
import {
	Card,
	CardContent,
	Typography,
	Grid,
} from '@material-ui/core';
import { SelectListOptions } from '../utility/select_list_options';
import { Field } from 'redux-form';
import { Strings as S } from '../../assets/common/strings';
import { FormTextField, FormSelect } from '../../utilities/forms';

interface Props { }
export const AdminCard: React.FC<Props> = (props) => {
	return (
		<div>
			<Typography variant="h5" style={{ margin: '20px 0 5px 20px' }}>
				{S.ProductAdminEditCard.Admin}
			</Typography>
			<Card style={styles.cardStyle}>
				<CardContent>
					<Grid container>
						<Grid item xs={12} sm={6}>
							<Field
								name="application.applicationNumber"
								label={S.ProductAdminEditCard.ApplicationNumber}
								component={FormTextField}
								style={styles.editListItem}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Field
								name="application.carrierProducerCode"
								label={S.ProductAdminEditCard.CarrierProducerCode}
								component={FormTextField}
								style={styles.editListItem}
							/>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12} sm={6}>
							<Field
								fullWidth
								label={S.ProductAdminEditCard.Sold}
								name="application.sold"
								component={FormSelect}
								children={SelectListOptions({
									options: [
										{ option: S.ProductAdminEditCard.OnExchange },
										{ option: S.ProductAdminEditCard.OffExchange },
									],
								})}
								style={styles.editListItem}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Field
								fullWidth
								label={S.ProductAdminEditCard.EnrollmentMethod}
								name="application.enrollmentMethod"
								component={FormSelect}
								children={SelectListOptions({
									options: [
										{ option: S.ProductAdminEditCard.Electronic },
										{ option: S.ProductAdminEditCard.Paper }
									],
								})}
								style={styles.editListItem}
							/>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</div>
	);
};

const styles: { [key: string]: React.CSSProperties } = {
	cardStyle: {
		margin: 10,
		marginTop: 0,
	},
	editListItem: {
		marginLeft: 10,
		width: '90%',
	},
};
