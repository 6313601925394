import { createSelector } from 'reselect';
import { AppState } from '../reducers/index';
import { AuthenticationState } from '../reducers/authentication_reducer';

export const getAuthenticationState = (state: AppState) => state.authentication || {};

export const getDecodedJwt = createSelector(
    [getAuthenticationState],
	(authState: AuthenticationState) => authState.decodedJwt
);
