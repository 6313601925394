import { Avatar } from '@material-ui/core';
import React from 'react';
import { wrapInCollapsibleCard } from '../../components/higher_order_component/wrap_in_card';
import { SimpleListItem } from '../../components/utility/simple_list_item';
import { Lead } from '../../reducers/LeadReducer';
import { themePalette } from '../../utilities/branding';


interface Props {
    lead: Lead;
}
const LeadProducts: React.FC<Props> = ({ lead }) => {
    const products = lead?.linesOfBusiness || [];
    return (
        <>
            {products.map((product: string) => (
                <SimpleListItem
                    key={product}
                    title={product}
                    divider={false}
                    hideArrow
                    icon={
                        <Avatar style={{ color: themePalette.negative_text }}>
                            {product.substr(0, 1)}
                        </Avatar>
                    }
                />
            ))}
        </>
    );
};

export const LeadProductsCard = wrapInCollapsibleCard(LeadProducts);