import {
	LinearProgress,
	Switch,
	Button,
	Icon,
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
	DialogContentText,
	TextField,
	Grid,
} from '@material-ui/core';
import React from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { themePalette } from '../../utilities/branding';
import { AppState } from '../../reducers/index';
import { navigateTo } from '../../actions/navigation_actions';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import {
	getGoogleSignInCode,
	DisableGoogleCalendarSync,
} from '../../actions/activity_actions';
import { isNullOrUndefinedOrEmpty } from '../../utilities/utilities';
import { User } from '../../reducers/user_reducer';
import {
	StoreGoogleCalendarSettings,
	getUserById,
} from '../../actions/user_actions';
import { GoogleVerifiedResponse } from '../../reducers/activity_reducer';
import { QueueSnackbar, SnackbarProps } from '../../actions/snackbar_actions';
import { copyToClipboard, isIOS } from '../../utilities/is_mobile';
import { getSnackbarSuccessProps } from '../../utilities/snackbar_util';
import { Dispatch } from '@reduxjs/toolkit';
import { openExternalLink } from '../../utilities';
import { DesktopPadding } from '../../components/Layout/desktop_padding';
import { ToggleCard } from '../../components/higher_order_component/wrap_in_card';
import { SimpleListItem } from '../../components/utility/simple_list_item';
import DateRangeIcon from '@material-ui/icons/DateRange';
import PhoneIcon from '@material-ui/icons/Phone';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import { AppConfig } from '../../types/config';

interface StateProps {
	googleSignInCode?: string;
	googleVerificationLink?: string;
	googleSignInCodeDone: number;
	googleDeviceCode: string;
	googlePollInterval: number;
	googlePollTimeout: number;
	googleSyncDisabledSuccessfully?: boolean;
	user: User;
	isLoading: boolean;
}
interface DispatchProps {
	navigateTo: (route: string) => void;
	getGoogleSignInCode: () => void;
	storeGoogleCalendarSettings: (user: User) => void;
	disableGoogleCalendarSync: () => void;
	getUser: (id: string, sync?: boolean) => void;
    queueSnackbar: (snackbarProps: SnackbarProps) => void;
}
type GoogleCalendarSyncPageProps = StateProps & DispatchProps;

interface GoogleCalendarSyncState {
	isSignInDialogOpen: boolean;
	isDisableDialogOpen: boolean;
	syncGoogle: boolean;
	appointmentsEnabled: boolean;
	phoneCallsEnabled: boolean;
	tasksEnabled: boolean;
	timeoutReached?: boolean;
	googleVerifiedResponse: GoogleVerifiedResponse;
	isPreSync: boolean;
}

class GoogleCalendarSyncPage extends React.Component<
	GoogleCalendarSyncPageProps,
	GoogleCalendarSyncState
	> {
	timer: any;
	signInStarted: boolean;
	constructor(props: GoogleCalendarSyncPageProps) {
		super(props);
		this.state = {
			isSignInDialogOpen: false,
			isDisableDialogOpen: false,
			syncGoogle: !isNullOrUndefinedOrEmpty(this.props.user
				.googleRefreshToken as string),
			appointmentsEnabled: this.props.user.syncGoogleAppointments || false,
			phoneCallsEnabled: this.props.user.syncGooglePhoneCalls || false,
			tasksEnabled: this.props.user.syncGoogleTasks || false,
			timeoutReached: undefined,
			googleVerifiedResponse: {
				access_token: '',
				expires_in: 0,
				token_type: '',
				refresh_token: '',
			},
			isPreSync: false,
		};
	}

	componentWillMount() {
		this.props.getUser(this.props.user.id, false);
	}

	componentWillReceiveProps(nextProps: GoogleCalendarSyncPageProps) {
		this.setState({
			appointmentsEnabled: nextProps.user.syncGoogleAppointments || false,
			phoneCallsEnabled: nextProps.user.syncGooglePhoneCalls || false,
			tasksEnabled: nextProps.user.syncGoogleTasks || false,
		});

		if (nextProps.googleSyncDisabledSuccessfully === true) {
			const clearedGoogleVerifiedState: GoogleVerifiedResponse = {
				access_token: '',
				expires_in: 0,
				token_type: '',
				refresh_token: '',
			};
			this.setState({
				timeoutReached: undefined,
				googleVerifiedResponse: clearedGoogleVerifiedState,
			});
		}
	}

	componentWillUnmount() {
		window.clearInterval(this.timer);
	}

	createGoogleSignInCodeDisplay = () => {
		if (this.props.googleSignInCodeDone == 100) {
			return isNullOrUndefinedOrEmpty(this.props.googleSignInCode as string)
				? 'Error'
				: this.props.googleSignInCode;
		} else {
			return '';
		}
	};

	disableGoogleSync = () => {
		this.closeDisableDialog();
		window.clearInterval(this.timer);
		this.props.disableGoogleCalendarSync();
	};

	closeSignInDialog = () => {
		this.setState({ isSignInDialogOpen: false });
	};

	closeDisableDialog = () => {
		this.setState({ isDisableDialogOpen: false });
	};

	openDialog = () => {
		if (
			isNullOrUndefinedOrEmpty(this.props.user.googleRefreshToken as string)
		) {
			this.signInStarted = true;
			this.setState({ isSignInDialogOpen: true, isDisableDialogOpen: false });
			this.props.getGoogleSignInCode();
		} else {
			this.setState({ isDisableDialogOpen: true, isSignInDialogOpen: false });
		}
	};

	async verifyGoogleSignIn() {
		//'https://cors-anywhere.herokuapp.com/' +
		const response = await fetch(
			AppConfig.google_poll_request_link +
			'?client_id=' +
			AppConfig.google_client_id +
			'&client_secret=' +
			AppConfig.google_api_key +
			'&code=' +
			this.props.googleDeviceCode +
			'&grant_type=' +
			AppConfig.google_poll_request_grant_type,
			{
				method: 'POST',
				mode: 'cors',
			}
		);

		if (response.ok) {
			const data = await response.json();
			window.clearInterval(this.timer);
			if (this.signInStarted) {
				this.setState({
					googleVerifiedResponse: {
						access_token: data['access_token'],
						expires_in: data['expires_in'],
						token_type: data['token_type'],
						refresh_token: data['refresh_token'],
					},
					isPreSync: true,
				});
				this.signInStarted = false;
			}
			QueueSnackbar(getSnackbarSuccessProps('Google Calendar successfully verified'));
		}
	}

	handleSignInClick = () => {
		this.closeSignInDialog();
		this.navigateToGoogle();
	};

	navigateToGoogle = () => {
		const { googlePollInterval, googlePollTimeout, googleVerificationLink } = this.props;
		const interval = googlePollInterval * 1000;
		let start = Date.now();
		const end = start + googlePollTimeout * 1000;
		this.setState({ timeoutReached: false });
		this.timer = window.setInterval(() => {
			this.verifyGoogleSignIn();
			start = Date.now();
			if (start > end) {
				window.clearInterval(this.timer);
				this.setState({ timeoutReached: true });
			}
		}, interval);
		openExternalLink(googleVerificationLink);
	};

	savePreferences = () => {
		let newUserSettings = this.props.user;
		newUserSettings.googleRefreshToken = !isNullOrUndefinedOrEmpty(this.props
			.user.googleRefreshToken as string)
			? this.props.user.googleRefreshToken
			: this.state.googleVerifiedResponse.refresh_token;
		newUserSettings.syncGoogleAppointments = this.state.appointmentsEnabled;
		newUserSettings.syncGooglePhoneCalls = this.state.phoneCallsEnabled;
		newUserSettings.syncGoogleTasks = this.state.tasksEnabled;
		this.props.storeGoogleCalendarSettings(newUserSettings);
		this.setState({ isPreSync: false });
	};

	toggleAppointmentsEnabled = () => {
		this.setState({ appointmentsEnabled: !this.state.appointmentsEnabled });
	};

	togglePhoneCallsEnabled = () => {
		this.setState({ phoneCallsEnabled: !this.state.phoneCallsEnabled });
	};

	toggleTasksEnabled = () => {
		this.setState({ tasksEnabled: !this.state.tasksEnabled });
	};

	createEnableDisableDisplay = () => {
		if (
			!isNullOrUndefinedOrEmpty(this.props.user.googleRefreshToken as string)
		) {
			return 'Enabled';
		} else {
			if (
				!isNullOrUndefinedOrEmpty(
					this.state.googleVerifiedResponse.refresh_token
				) &&
				this.state.isPreSync
			) {
				return 'Sync Now to Enable';
			}
			return 'Disabled';
		}
	};

	renderSignInDialog() {
        const googleCode = this.createGoogleSignInCodeDisplay();
		return (
			<Dialog open={this.state.isSignInDialogOpen}>
				{this.props.googleSignInCodeDone != 100 ? (
					<LinearProgress />
				) : null}
				<DialogTitle>
					Sync To-Dos with Google Calendar
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Copy this code for Google Sign In:
					</DialogContentText>
					<TextField
						style={{ color: themePalette.primary_text }}
						id="googleCal"
						value={googleCode}
					/>
					<Icon
						onClick={() => {
                            copyToClipboard(googleCode || '');
                            this.props.queueSnackbar(getSnackbarSuccessProps('Copied URL to clipboard', 2000));
                        }}
					>
						file_copy
					</Icon>
				</DialogContent>
				<DialogActions>
					<Button
						color="secondary"
						onClick={this.closeSignInDialog}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={this.handleSignInClick}
						disabled={isNullOrUndefinedOrEmpty(this.props.googleSignInCode as string)}
						style={{ backgroundColor: themePalette.accept_button }}
					>
						Sign In
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	renderDisableDialog() {
		return (
			<Dialog open={this.state.isDisableDialogOpen}>
				<DialogTitle>
					Disable Google Sync
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to discontinue syncing with Google Calendar?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						color="secondary"
						onClick={this.closeDisableDialog}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={this.disableGoogleSync}
						style={{ backgroundColor: themePalette.delete_remove_reject_button }}
					>
						Disable
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	render() {
		return (
			<BasePageContainer
				topComponent={
					<HeaderBarComponent
						title="Settings"
						isLoading={
							(!this.state.timeoutReached &&
								this.state.timeoutReached != undefined &&
								isNullOrUndefinedOrEmpty(
									this.state.googleVerifiedResponse.refresh_token
								)) ||
							this.props.isLoading
						}
					/>
				}
			>
				<PersistentNotifications />
				<DesktopPadding>
					<ToggleCard title={'Google Calendar Settings'} noPadding>
						<SimpleListItem
							title="Google Calendar Sync"
							subtitle={this.createEnableDisableDisplay()}
							secondaryAction={
								<Switch
									color="primary"
									onClick={this.openDialog}
									checked={
										!isNullOrUndefinedOrEmpty(this.props.user.googleRefreshToken as string) ||
										!isNullOrUndefinedOrEmpty(this.state.googleVerifiedResponse.refresh_token)
									}
									disabled={
										!this.state.timeoutReached &&
										this.state.timeoutReached != undefined &&
										isNullOrUndefinedOrEmpty(this.state.googleVerifiedResponse.refresh_token)
									}
								/>
							}
						/>
						{(!isNullOrUndefinedOrEmpty(this.props.user.googleRefreshToken as string) ||
							!isNullOrUndefinedOrEmpty(this.state.googleVerifiedResponse.refresh_token)) && (
								<>
									<SimpleListItem subtitle={'To-Dos'} />
									<SimpleListItem 
										title={'Appointments'}
										icon={<DateRangeIcon/>}
										secondaryAction={
											<Switch
												color="primary"
												checked={this.state.appointmentsEnabled}
												onChange={this.toggleAppointmentsEnabled}
											/>
										}
									/>
									<SimpleListItem 
										title={'Phone Calls'}
										icon={<PhoneIcon/>}
										secondaryAction={
											<Switch
												color="primary"
												checked={this.state.phoneCallsEnabled}
												onChange={this.togglePhoneCallsEnabled}
											/>
										}
									/>
									<SimpleListItem 
										title={'Tasks'}
										icon={<EventAvailableIcon/>}
										secondaryAction={
											<Switch
												color="primary"
												checked={this.state.tasksEnabled}
												onChange={this.toggleTasksEnabled}
											/>
										}
									/>
									<Grid container>
										<Button
											variant="contained"
											color="primary"
											fullWidth
											style={{ backgroundColor: themePalette.accept_button, margin: 20 }}
											onClick={this.savePreferences}
										>
											<div>Sync Now</div>
										</Button>
									</Grid>
								</>
							)
						}
					</ToggleCard>
				</DesktopPadding>
				{this.renderDisableDialog()}
				{this.renderSignInDialog()}
			</BasePageContainer>
		);
	}
}

const mapStateToProps = (state: AppState): StateProps => ({
	googleSignInCode: state.activity.googleSignInCode,
	googleVerificationLink: state.activity.googleVerificationUrl,
	googleSignInCodeDone: state.activity.googleSignInCodeDone,
	googleDeviceCode: state.activity.googleDeviceCode,
	googlePollInterval: state.activity.googlePollInterval,
	googlePollTimeout: state.activity.googlePollTimeout,
	googleSyncDisabledSuccessfully: state.activity.googleSyncDisabledSuccessfully,
	user: state.user,
	isLoading: state.activity.isLoading,
});
const mapDispatchToProps = (dispatch: Dispatch): Partial<GoogleCalendarSyncPageProps> => ({
	navigateTo: (route: string) =>
		dispatch(navigateTo(route)),
	getGoogleSignInCode: () => dispatch(getGoogleSignInCode()),
	storeGoogleCalendarSettings: (user: User) =>
		dispatch(StoreGoogleCalendarSettings.started(user)),
	disableGoogleCalendarSync: () =>
		dispatch(DisableGoogleCalendarSync.started(undefined)),
	getUser: (id: string, sync?: boolean) => dispatch(getUserById(id, sync)),
    queueSnackbar: (snackbarProps: SnackbarProps) => dispatch(QueueSnackbar(snackbarProps)),
});

export const GoogleCalendarSyncContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
	true
)(GoogleCalendarSyncPage);
