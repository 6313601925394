import { Action } from 'typescript-fsa';
import { call, put, all } from 'redux-saga/effects';
import http, { HttpOptions } from '../utilities/http';
import { PodcastFilter, Podcast, PodcastTracking } from '../reducers/podcast_reducer';
import { takeEveryForActionType } from '../utilities/saga_util';
import { FilterPodcasts, CreatePodcast, UpdatePodcast, UpdateTracking, GetPodcastById, DeletePodcastById } from '../actions/podcast_actions';
import { QueueSnackbar } from '../actions/snackbar_actions';
import { getSnackbarErrorProps, getSnackbarSuccessProps } from '../utilities/snackbar_util';

function* getPodcastByIdHandler(action: Action<string>) {
    const errorSnackbarProps = getSnackbarErrorProps('Failed to load podcast');
    try {
        const response = yield call(getPodcastByIdClient, action.payload);
        if(response.ok) {
			const data: Podcast = yield response.json();
			yield put(GetPodcastById.done({ params: action.payload, result: data }));
        } else {        
            yield put(QueueSnackbar(errorSnackbarProps));
            yield put(
				GetPodcastById.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
        }
    } catch (error) {
        yield put(QueueSnackbar(errorSnackbarProps));
		yield put(GetPodcastById.failed({ params: action.payload, error }));
    }
}

function getPodcastByIdClient(id: string) {
	return http('podcast/' + id);
}

function* deletePodcastByIdHandler(action: Action<string>) {
    const errorSnackbarProps = getSnackbarErrorProps('Failed to delete podcast');
	const successSnackbarProps = getSnackbarSuccessProps(`Podcast deleted`);
    try {
        const response = yield call(deletePodcastByIdClient, action.payload);
        if(response.ok) {
            yield put(QueueSnackbar(successSnackbarProps));
			yield put(DeletePodcastById.done({ params: action.payload, result: true }));
        } else {        
            yield put(QueueSnackbar(errorSnackbarProps));
            yield put(
				DeletePodcastById.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
        }
    } catch (error) {
        yield put(QueueSnackbar(errorSnackbarProps));
		yield put(DeletePodcastById.failed({ params: action.payload, error }));
    }
}

function deletePodcastByIdClient(id: string) {
	return http('podcast/' + id, {method: 'DELETE'});
}

function* filterPodcastsHandler(action: Action<PodcastFilter>) {
    const errorSnackbarProps = getSnackbarErrorProps('Failed to load podcasts');
    try {
        const response = yield call(filterPodcastsClient, action.payload);
        if(response.ok) {
			const data: Podcast[] = yield response.json();
			yield put(FilterPodcasts.done({ params: action.payload, result: data }));
        } else {        
            yield put(QueueSnackbar(errorSnackbarProps));
            yield put(
				FilterPodcasts.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
        }
    } catch (error) {
        yield put(QueueSnackbar(errorSnackbarProps));
		yield put(FilterPodcasts.failed({ params: action.payload, error }));
    }
};

function filterPodcastsClient(
	filter: PodcastFilter,
) {
    return http(
        'podcast/filter',
		{
			method: 'POST',
			body: JSON.stringify(filter),
        },
        { index: filter.pageNumber, size: filter.pageSize }
    )
}

function* createPodcastHandler(action: Action<Podcast>) {
    const errorSnackbarProps = getSnackbarErrorProps('Failed to save podcast');
	const successSnackbarProps = getSnackbarSuccessProps(`Podcast saved successfully`);
    try {
        const response = yield call(createPodcastClient, action.payload);
        if(response.ok) {
            const data:Podcast = yield response.json();
            yield put(QueueSnackbar(successSnackbarProps));
            yield put(CreatePodcast.done({ params: action.payload, result: data }));
        } else {
            yield put(QueueSnackbar(errorSnackbarProps));
            yield put(
				CreatePodcast.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
        }
    } catch (error) {
        yield put(QueueSnackbar(errorSnackbarProps));
		yield put(CreatePodcast.failed({ params: action.payload, error }));
    }
}

function createPodcastClient(podcast: Podcast) {
	const options: HttpOptions = {
		method: 'POST',
		body: JSON.stringify(podcast),
	};

	return http('podcast', options);
}

function* updatePodcastHandler(action: Action<Podcast>) {
    const errorSnackbarProps = getSnackbarErrorProps('Failed to save podcast');
	const successSnackbarProps = getSnackbarSuccessProps(`Podcast saved successfully`);
	const uploadSnackbarProps = getSnackbarSuccessProps(`Uploading podcast`);
    try {
        if(action.payload.uploadFile) {
            yield put(QueueSnackbar(uploadSnackbarProps));
        }
        const response = yield call(updatePodcastClient, action.payload);
        if(response.ok) {
            const data:Podcast = yield response.json();
            yield put(QueueSnackbar(successSnackbarProps));
            yield put(UpdatePodcast.done({ params: action.payload, result: data }));
        } else {
            yield put(QueueSnackbar(errorSnackbarProps));
            yield put(
				UpdatePodcast.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
        }

    } catch (error) {
        yield put(QueueSnackbar(errorSnackbarProps));
		yield put(UpdatePodcast.failed({ params: action.payload, error }));
    }
}

function updatePodcastClient(podcast: Podcast) {
	const options: HttpOptions = {
		method: 'PUT',
		body: JSON.stringify(podcast),
	};

	return http('podcast', options);
}

function* updatePodcastTrackingHandler(action: Action<PodcastTracking>) {
    try {
        const response = yield call(updatePodcastTrackingClient, action.payload);
        if(response.ok) {
            const data: PodcastTracking = yield response.json();
            yield put(UpdateTracking.done({ params: action.payload, result: data }));
        } else {
            yield put(
				UpdateTracking.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
        }

    } catch (error) {
		yield put(UpdateTracking.failed({ params: action.payload, error }));
    }
}

function updatePodcastTrackingClient(tracking: PodcastTracking) {
	const options: HttpOptions = {
		method: 'POST',
		body: JSON.stringify(tracking),
	};

	return http('podcast/tracking', options);
}

export function* podcastSagas() {
    yield all([
        takeEveryForActionType(FilterPodcasts.started, filterPodcastsHandler),
        takeEveryForActionType(CreatePodcast.started, createPodcastHandler),
        takeEveryForActionType(UpdatePodcast.started, updatePodcastHandler),
        takeEveryForActionType(UpdateTracking.started, updatePodcastTrackingHandler),
        takeEveryForActionType(GetPodcastById.started, getPodcastByIdHandler),
        takeEveryForActionType(DeletePodcastById.started, deletePodcastByIdHandler)
    ])
}