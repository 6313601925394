import { Typography } from '@material-ui/core';
import React from 'react';
import { Strings } from '../../assets/common/strings';
import { FitScoreRibbonIcon } from '../svgs/icons/fitscore_ribbon_icon';
import { useFitScoreRibbonStyles } from '../../utilities/hooks/styles';
interface FitScoreRibbonProps {
	fitscore: number;
}

export const FitScoreRibbon: React.FC<FitScoreRibbonProps> = ({ fitscore }) => {
	const styles = useFitScoreRibbonStyles();

	return (
		<>
			<div className={styles.ribbon}>
				<div>
					<FitScoreRibbonIcon />
				</div>
				<div className={styles.ribbonText}>
					<Typography variant="body2" color="inherit">
						<strong>{Strings.ReShop.FitScoreTrademarked}</strong>
					</Typography>
					<Typography variant="h6" color="inherit" style={{ marginTop: -5 }}>
						{fitscore}
					</Typography>
				</div>
			</div>
		</>
	);
};
