import { NavigationProps, navRoutes } from "../nav/Routes";
import React from 'react';
import { Dispatch, connect } from '@optum-uhone-hmkts/rise';
import { InjectedProps as DialogInjectedProps, connectDialog } from '../../containers/dialogs';
import { SearchFilterFormValues, SearchResult } from '../../reducers/advanced_search_reducer';
import { CheckLeadConnectBalance, VerifyClickToCallContacts, CheckCallerId, StartClickToCallSession, ClearClickToCallData } from '../../actions/click_to_call_actions';
import { ContactVerificationRequest, ContactVerificationResult, SessionHousehold, ClickToCallSession } from '../../reducers/click_to_call_reducer';
import { Dialog, DialogContent, Grid, Avatar, ListItem, List, Icon, ListItemText, DialogActions, Button, CircularProgress, LinearProgress, Typography } from '@material-ui/core';
import { Strings } from '../../assets/common/strings';
import { themePalette } from '../../utilities/branding';
import { User } from '../../reducers/user_reducer';
import { GetUserById } from '../../actions/user_actions';
import { navigateTo } from '../../actions/navigation_actions';
import { getUserMedia } from '../../utilities/polyfills/get_user_media';
import { isIE } from '../../utilities';

interface StateProps {
    searchFilters: SearchFilterFormValues;
    selectedResults: SearchResult[];
    selectAll: boolean;
    hasSufficientFunds?: boolean;
    callerIdVerified?: boolean;
    contactVerificationResult?: ContactVerificationResult;
    session?: ClickToCallSession;
    loading: boolean;
    userId: string;
    user?: User;
    callerId: string;
}

interface DispatchProps {
    getUser: (id: string) => void;
    verifyCallerId: () => void;
    checkLeadConnectBalance: () => void;
    verifyContacts: (request: ContactVerificationRequest) => void;
    startSession: (sessionHousehold: SessionHousehold) => void;
    navigateTo: (route: string) => void;
    clearClickToCallData: () => void;
}

type Props = DispatchProps & DialogInjectedProps & StateProps & NavigationProps;

interface State {
    audioLevel: number;
    audioCheck?: boolean;
    audioStream?: MediaStream;
    audioStreamName: string;
}

class StartClickToCallDialogComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            audioLevel: 0,
            audioCheck: undefined,
            audioStream: undefined,
            audioStreamName: ''
        }
    }

    componentDidMount() {
        if (this.props.user == undefined && this.props.userId) {
            this.props.getUser(this.props.userId);
        } else if (this.props.user) {
            this.props.clearClickToCallData();
            this.props.verifyCallerId();
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.props.user == undefined && this.props.userId == undefined && nextProps.userId) {
            this.props.getUser(nextProps.userId);
        } else if (this.props.user == undefined && nextProps.user != undefined) {
            this.props.verifyCallerId();
        } else if (this.props.callerIdVerified == undefined && nextProps.callerIdVerified == true) {
            this.checkAudio();
        } else if (this.props.hasSufficientFunds == undefined && nextProps.hasSufficientFunds == true) {
            const request: ContactVerificationRequest = {
                contactIds: this.props.selectedResults.map(result => result.ContactId),
                criteria: this.props.selectAll ? this.props.searchFilters : undefined
            }
            this.props.verifyContacts(request);
        } else if (this.props.session == undefined && nextProps.session != undefined) {
            this.closeDialog();
        }
    }

    startSession = () => {
        if (this.props.contactVerificationResult) {
            //this.props.contactVerificationResult.sessionHousehold.consumerSearchFilters = this.props.searchFilters;
            const sessionHousehold = this.props.contactVerificationResult.sessionHousehold;
            sessionHousehold.consumerSearchFilters = this.props.searchFilters;
            this.props.startSession(sessionHousehold);

            const firstCallable = sessionHousehold.households.filter(house => house.dncReason == null);
            if (firstCallable.length) {
                this.props.navigateTo( navRoutes.household.path.replace(
                    Strings.Navigation.HouseholdId,
                    firstCallable[0].householdId
                ));
            }

            this.closeDialog();
        }
    }

    checkAudio = async() => {
        try {
            const stream = await getUserMedia({ audio: true, video: false });
            // Other browser support, ie Safari
            const Context: any = (window as any).AudioContext || (window as any).webkitAudioContext;
            const audioContext = new Context();
            const input = audioContext.createMediaStreamSource(stream);
            const analyser = audioContext.createAnalyser();
            const scriptProcessor = audioContext.createScriptProcessor(512);

            input.connect(analyser);
            analyser.connect(scriptProcessor);
            scriptProcessor.connect(audioContext.destination);
            scriptProcessor.onaudioprocess = this.processAudio;

            this.setState({
                audioCheck: true,
                audioStream: stream,
                audioStreamName: stream.getTracks()[0].label
            }, this.props.checkLeadConnectBalance);
        }
        catch { 
            this.setState({
                audioCheck: false
            });
        }
    }

    processAudio = (audioProcessingEvent: AudioProcessingEvent) => {
        const buffer = audioProcessingEvent.inputBuffer.getChannelData(0);
        let sum = 0;

        for (var i = 0; i < buffer.length; i++) {
            const x = buffer[i];
            sum += x * x;
        }

        var rms = Math.sqrt(sum / buffer.length);

        this.setState({
            audioLevel: Math.max(rms, this.state.audioLevel * .2)
        });
    }

    closeDialog = () => {
        if (this.state.audioStream != undefined) {
            this.state.audioStream.getTracks().forEach(track => {
                track.stop();
            })
        }

        this.props.dismissSelf();
    }

    render() {
        const dncReasons = [Strings.DncReason.CompanyDNC, Strings.DncReason.NationalDNC];
        const telemarketingReasons = [Strings.DncReason.TelemarketingRules, Strings.DncReason.RecentlyCalled];
        const missingDataReaons = [Strings.DncReason.MissingPhone, Strings.DncReason.MissingTimeZone, Strings.DncReason.MissingZip];

        return (
            <Dialog open={true} fullWidth={true}>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography style={{ margin: 'auto' }} align='center' variant='h5'>Initializing Click to Call</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <List>
                                <ListItem>
                                    {this.props.callerIdVerified != undefined ?
                                        <Avatar style={{
                                            backgroundColor: this.props.callerIdVerified ? themePalette.selected_item : themePalette.unselected_item
                                        }}>
                                            <Icon>check</Icon>
                                        </Avatar> :
                                        <CircularProgress variant={(this.props.user) ? 'indeterminate' : 'static'} />
                                    }
                                    <ListItemText primary="Verify Caller ID"
                                        secondary={this.props.callerIdVerified != undefined && (this.props.callerIdVerified ?
                                            "Caller ID verified" :
                                            "Caller ID is not verified, please go to Settings and verify your Caller ID.")}
                                    />
                                    <div style={{ width: '50%' }}>
                                        <Typography variant='caption'>{this.props.callerId}</Typography>
                                    </div>
                                </ListItem>
                                <ListItem>
                                    {this.state.audioCheck != undefined ?
                                        <Avatar style={{
                                            backgroundColor: this.state.audioCheck ? themePalette.selected_item : themePalette.unselected_item
                                        }}>
                                            <Icon>check</Icon>
                                        </Avatar> :
                                        <CircularProgress variant={this.props.callerIdVerified == true ? 'indeterminate' : 'static'} />
                                    }
                                    <ListItemText primary="Audio Check"
                                        secondary={this.state.audioCheck != undefined && (this.state.audioCheck ?
                                            "Microphone connected" :
                                            isIE 
                                                ? "Microphone Access not available in Internet Explorer"
                                                : "Audio Check failed. Please check that your microphone is plugged in and that you have allowed access to your microphone.")}
                                    />
                                    {this.state.audioCheck == true &&
                                        <div style={{ width: '50%' }}>
                                            <LinearProgress variant="determinate" value={this.state.audioLevel * 100 / (.05)} />
                                            <Typography variant='caption'>Input device: {this.state.audioStreamName}</Typography>
                                        </div>
                                    }
                                </ListItem>
                                <ListItem>
                                    {this.props.hasSufficientFunds != undefined ?
                                        <Avatar style={{
                                            backgroundColor: this.props.hasSufficientFunds ? themePalette.selected_item : themePalette.unselected_item
                                        }}>
                                            <Icon>check</Icon>
                                        </Avatar> :
                                        <CircularProgress variant={this.state.audioCheck == true ? 'indeterminate' : 'static'} />
                                    }
                                    <ListItemText primary="Sufficient Funds"
                                        secondary={this.props.hasSufficientFunds != undefined && (this.props.hasSufficientFunds ?
                                            "You have sufficient funds" :
                                            <Typography style={{color: themePalette.error}}>Insufficient Funds. LeadConnect balance must be $10 available to start call session.</Typography>
                                        )}
                                    />
                                </ListItem>
                                <ListItem>
                                    {this.props.contactVerificationResult != undefined ?
                                        <Avatar style={{
                                            backgroundColor: this.props.contactVerificationResult ? themePalette.selected_item : themePalette.unselected_item
                                        }}>
                                            <Icon>check</Icon>
                                        </Avatar> :
                                        <CircularProgress variant={this.props.hasSufficientFunds == true ? 'indeterminate' : 'static'} />
                                    }
                                    <ListItemText primary="DNC"
                                        secondary={this.props.contactVerificationResult != undefined ?
                                            dncReasons.reduce((count, reason) => {
                                                return count + (this.props.contactVerificationResult!.dncReasonCount[reason] || 0)
                                            }, 0) + " Households excluded"
                                            : ""
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    {this.props.contactVerificationResult != undefined ?
                                        <Avatar style={{
                                            backgroundColor: this.props.contactVerificationResult ? themePalette.selected_item : themePalette.unselected_item
                                        }}>
                                            <Icon>check</Icon>
                                        </Avatar> :
                                        <CircularProgress variant={this.props.hasSufficientFunds == true ? 'indeterminate' : 'static'} />
                                    }
                                    <ListItemText primary="Telemarketing Rules"
                                        secondary={this.props.contactVerificationResult != undefined ?
                                            telemarketingReasons.reduce((count, reason) => {
                                                return count + (this.props.contactVerificationResult!.dncReasonCount[reason] || 0)
                                            }, 0) + " Households excluded"
                                            : ""
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    {this.props.contactVerificationResult != undefined ?
                                        <Avatar style={{
                                            backgroundColor: this.props.contactVerificationResult ? themePalette.selected_item : themePalette.unselected_item
                                        }}>
                                            <Icon>check</Icon>
                                        </Avatar> :
                                        <CircularProgress variant={this.props.hasSufficientFunds == true ? 'indeterminate' : 'static'} />
                                    }
                                    <ListItemText primary="Missing Phone Number or Zip Code"
                                        secondary={this.props.contactVerificationResult != undefined ?
                                            missingDataReaons.reduce((count, reason) => {
                                                return count + (this.props.contactVerificationResult!.dncReasonCount[reason] || 0)
                                            }, 0) + " Households excluded"
                                            : ""
                                        }
                                    />
                                </ListItem>
                                {this.props.contactVerificationResult &&
                                    <ListItem>
                                        <ListItemText primary={"Total Households Ready for Click To Call Campaign Session: " +
                                            (this.props.contactVerificationResult.dncReasonCount[Strings.DncReason.None] || 0) + "/" +
                                            this.props.contactVerificationResult.sessionHousehold.households.length}
                                        />
                                    </ListItem>
                                }
                            </List>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color={Strings.Theming.Primary}
                        onClick={this.closeDialog}
                    >
                        Cancel
					</Button>
                    <Button
                        variant="contained"
                        color={Strings.Theming.Primary}
                        onClick={this.startSession}
                        disabled={this.props.loading || this.props.contactVerificationResult == undefined || !(this.props.contactVerificationResult.dncReasonCount[Strings.DncReason.None] > 0)}
                    >
                        Begin
					</Button>
                </DialogActions>
            </Dialog>
        )

    }

}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return {
        getUser: (id: string) => dispatch(GetUserById.started({ id, sync: false })),
        verifyCallerId: () => dispatch(CheckCallerId.started(undefined)),
        checkLeadConnectBalance: () => dispatch(CheckLeadConnectBalance.started(undefined)),
        verifyContacts: (request: ContactVerificationRequest) => dispatch(VerifyClickToCallContacts.started(request)),
        startSession: (sessionHousehold: SessionHousehold) => dispatch(StartClickToCallSession.started(sessionHousehold)),
        navigateTo: (route: string) => dispatch(navigateTo(route)),
        clearClickToCallData: () => dispatch(ClearClickToCallData())
    }
}

function mapStateToProps(state): StateProps {
    
    return {
        searchFilters: state.advancedSearch.appliedSearchFilters,
        selectedResults: state.advancedSearch.results.filter(result => result.IsSelected),
        selectAll: state.advancedSearch.isSelectAll,
        hasSufficientFunds: state.clickToCall.hasSufficientFunds,
        callerIdVerified: state.clickToCall.callerIdVerified,
        contactVerificationResult: state.clickToCall.verificationResult,
        session: state.clickToCall.session,
        loading: state.clickToCall.loading,
        userId: state.agent.id,
        user: state.user,
        callerId: state.user.callerId,
    }
}

const StartClickToCallDialogContainer = connect(mapStateToProps, mapDispatchToProps, true)(StartClickToCallDialogComponent as any);
export const StartClickToCallDialog = connectDialog(StartClickToCallDialogContainer);

const styles = {
    listItemStyle: {
        minHeight: '40px'
    }
}