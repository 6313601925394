import { isType } from 'typescript-fsa';
import { isEqual } from 'lodash';
import {
    GetYtdAVReport,
    GetNewLeadsReport,
	GetSurebridgePersistencyReport,
	GetSurebridgePersistencyAvgAndRank,
} from '../actions/productivity_actions';
import { TeamViewRow } from '../reducers/teamview_reducer';

export interface ProductivityTable {
	table: TeamViewRow[];
	isLoading: boolean;
}

export interface YTDAVState {
	graphs: YTDAVReport[];
	isLoading: boolean;
}

export interface YTDAVReport {
	title: string;
	data: StackedGraphDataPoint[]
	stats: StackedGraphStats;
	unit: string;
}

export interface StackedGraphDataPoint {
	label: string;
	week: number;
	value1: number;
	value2: number;
	value3?: number;
	value4?: number;
}

export interface StackedGraphStats {
	actual: string;
	rank: string;
	slPercent: string;
}

export interface NewLeadsState {
	graphs?: NewLeadsReport[];
	isLoading: boolean;
}

export interface NewLeadsReport {
	title: string;
	type: string;
	dataPoint: number;
	result: string;
	slAvg: string;
	taken: string;
	unit: string;
}

export interface WritingAgentState {
	report: WritingAgentReport,
	isLoading: boolean,
}

export interface WritingAgentReport {
	table: TeamViewRow[] | null,
	graph: WritingAgentGraph | null,
}

export interface WritingAgentGraph {
	title?: string;
	data: StackedGraphDataPoint[]
}

export interface ProductivityState {
	yTDAVReport: YTDAVState;
    newLeadReport: NewLeadsState;
	persistencyReport: ProductivityTable;
	persistencyAvgAndRank: ProductivityTable;
}

const initialState: ProductivityState = {
	yTDAVReport: { graphs: [], isLoading: false },
	newLeadReport: { graphs: [], isLoading: false },
	persistencyReport: { table: [], isLoading: false },
	persistencyAvgAndRank: { table: [], isLoading: false },
};

export const productivityReducer = (state = initialState, action: any) => {
    if (isType(action, GetYtdAVReport.started)) {
		return {
			...state,
			yTDAVReport: {
				...state.yTDAVReport,
				isLoading: true,
			},
		}
	} else if (isType(action, GetYtdAVReport.done)) {
		if (isEqual(state.yTDAVReport, action.payload.result)) {
			return state;
		}
		const updatedYTDReport = action.payload.result;
		return {
			...state,
			yTDAVReport: {
                graphs: updatedYTDReport,
				isLoading: false,
			},
		};
	} else if (isType(action, GetNewLeadsReport.started)) {
		return {
			...state,
			newLeadReport: {
				...state.newLeadReport,
				isLoading: true,
			},
		}
	} else if (isType(action, GetNewLeadsReport.done)) {
		if (isEqual(state.newLeadReport, action.payload.result)) {
			return state;
		}
		const updatedLeadsReport = action.payload.result;
		return {
			...state,
			newLeadReport: {
                graphs: updatedLeadsReport,
                isLoading: false,
			},
		};
	} else if (isType(action, GetSurebridgePersistencyReport.started)) {
		return {
			...state,
			persistencyReport: {
				...state.persistencyReport,
				isLoading: true,
			},
		}
	} else if (isType(action, GetSurebridgePersistencyReport.done)) {
		if (isEqual(state.persistencyReport, action.payload.result)) {
			return state;
		}
		const updatedPersistencyReport = action.payload.result;
		return {
			...state,
			persistencyReport: {
                table: updatedPersistencyReport,
                isLoading: false,
			},
		};
	} else if (isType(action, GetSurebridgePersistencyAvgAndRank.started)) {
		return {
			...state,
			persistencyAvgAndRank: {
				...state.persistencyAvgAndRank,
				isLoading: true,
			},
		}
	} else if (isType(action, GetSurebridgePersistencyAvgAndRank.done)) {
	if (isEqual(state.persistencyAvgAndRank, action.payload.result)) {
		return state;
	}
	const updatedPersistencyAvgAndRank = action.payload.result;
	return {
		...state,
		persistencyAvgAndRank: {
			table: updatedPersistencyAvgAndRank,
			isLoading: false,
		},
	};
};

    return state;
};
