import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	Paper,
	Switch,
} from '@material-ui/core';
import React from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import {
	enableFirstTimeLoginPrompt,
} from '../../actions/authentication_actions';
import { navigateTo } from '../../actions/navigation_actions';
import { AcceptTermsAndConditions } from '../../actions/user_actions';
import { BasePageContainer } from '../../components/Layout/BasePage';
import {
	HeaderBarComponent,
} from '../../components/Layout/HeaderBar';
import { NavigationProps, navRoutes } from '../../components/nav/Routes';
import { themePalette, themeLinks } from '../../utilities/branding';
import { AppState } from '../../reducers/index';
import { isBrokerage } from '../../utilities/brokerage_utils';
import { brokerConnectTerms, agentConnectTerms } from '../../assets/text/termsAndConditions';
import { Logout } from '../../actions/authentication_actions';
import { MoreMenu, createHelpAction } from '../../components/nav/more_menu';
import { Dispatch } from 'redux';

interface StateProps {
	userId: string;
	alreadyAccepted: boolean;
}

interface DispatchProps {
	enableFirstTimeLoginPrompt: () => void;
	acceptTermsAndConditions: () => void;
	startLogout: () => void;
}

type Props = StateProps & NavigationProps & DispatchProps;

interface TermsAndConditionsState {
	finishedScrolling: boolean;
	termsAndConditionsAccepted: boolean;
	loading: boolean;
	anchorEl?: HTMLElement;
}

class TermsAndConditions extends React.Component<
	Props,
	TermsAndConditionsState
	> {
	private scrollableDiv: HTMLDivElement | null = null;

	constructor(props: Props) {
		super(props);
		this.state = {
			finishedScrolling: false,
			termsAndConditionsAccepted: false,
			loading: false,
		};
		this.handleScroll = this.handleScroll.bind(this);
		this.handleBack = this.handleBack.bind(this);
		this.canScrollDown = this.canScrollDown.bind(this);
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);

		this.setState({
			termsAndConditionsAccepted: this.props.alreadyAccepted || false,
			finishedScrolling: !this.canScrollDown(),
		});
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
		this.setState({
			finishedScrolling: false,
		});
	}

	canScrollDown() {
		if (this.scrollableDiv) {
			return (
				this.scrollableDiv.scrollHeight - this.scrollableDiv.clientHeight !==
				this.scrollableDiv.scrollTop
			);
		} else {
			return false;
		}
	}

	handleScroll() {
		if (!this.canScrollDown()) {
			this.setState({
				finishedScrolling: true,
			});
		}
	}

	handleBack = (): any => {
		this.props.startLogout();
	};

	private isAccepted(): boolean {
		return this.props.alreadyAccepted || this.state.termsAndConditionsAccepted;
	}

	handleToggle(accepted: boolean) {
		this.setState({
			termsAndConditionsAccepted: accepted,
		});
	}

	handleAccept = () => {
		this.props.enableFirstTimeLoginPrompt();
		this.props.acceptTermsAndConditions();
		this.props.navigateTo( navRoutes.welcome.path);
	}

	createTextMarkup() {
		const markup = isBrokerage ? brokerConnectTerms : agentConnectTerms;

		return {
			__html: markup,
		};
	}

	renderTermsAndConditions() {
		return (
			<Card>
				<CardContent style={{ textAlign: 'left' }}>
					<div dangerouslySetInnerHTML={this.createTextMarkup()} />
				</CardContent>
			</Card>
		);
	}

	public render() {
		return (
			<BasePageContainer
				blockDialogs
				topComponent={
					<HeaderBarComponent
						title={
							<span style={{ color: themePalette.negative_text, marginLeft: 10 }}>
								{'Terms & Conditions'}
							</span>
						}
						rightButtons={<MoreMenu children={[createHelpAction(themeLinks.helpLinkTermsAndConditions)]} />}
						hideSideMenuButton={!this.props.alreadyAccepted}
						hideBackButton={!this.props.alreadyAccepted}
						forceOverflow={true}
						customContent={
							<CardHeader
								style={{
									textAlign: 'center',
									display: this.props.alreadyAccepted ? 'none' : 'block',
								}}
								subheader={
									<span style={{ color: themePalette.negative_text }}>
										Please review the terms and conditions. Scroll to the bottom
										to accept and continue.
									</span>
								}
							/>
						}
						style={{ paddingBottom: 0 }}
					/>
				}
				middleComponent={
					<div
						style={{
							overflowX: 'hidden',
							overflowY: 'scroll',
							height: '100%',
						}}
						ref={ref => {
							this.scrollableDiv = ref;
							if (this.scrollableDiv) {
								this.scrollableDiv.onscroll = this.handleScroll;
							}
						}}
					>
						<Grid container>
							<Grid item lg={3} />
							<Grid item xs={12} lg={6} style={{ padding: 10 }}>
								{this.renderTermsAndConditions()}
							</Grid>
						</Grid>
						<Dialog open={this.state.loading}>
							<DialogTitle>Accepting</DialogTitle>
							<DialogContent>
								<Grid container justify="center" alignItems="center">
									<Grid item xs={12} style={{ textAlign: 'center' }}>
										<CircularProgress />
									</Grid>
								</Grid>
							</DialogContent>
						</Dialog>
					</div>
				}
				bottomComponent={
					this.props.alreadyAccepted ? (
						undefined
					) : (
							<Paper
								style={{
									height: '110px',
									zIndex: 10,
								}}
								elevation={3}
							>
								<CardContent style={styles.bottomBar}>
									<Grid container alignItems="center">
										<Grid item xs={10}>
											{this.props.alreadyAccepted
												? 'Thank you for accepting the terms and conditions'
												: 'I agree to the terms and conditions'}
										</Grid>
										<Grid item xs={1}>
											<Switch
												color={'primary'}
												disabled={this.props.alreadyAccepted}
												checked={this.isAccepted()}
												onChange={() =>
													this.handleToggle(
														!this.state.termsAndConditionsAccepted
													)
												}
											/>
										</Grid>
										<Grid item xs={1} />
									</Grid>
									<CardActions
										style={{ flex: 1, justifyContent: 'space-between' }}
									>
										<Button
											variant="contained"
											style={{
												backgroundColor: themePalette.error,
												float: 'left'
											}}
											onClick={this.handleBack}
										>
											Back
									</Button>
										<Button
											variant="contained"
											style={{
												backgroundColor: themePalette.accept_button,
												float: 'right'
											}}
											onClick={this.handleAccept}
											disabled={
												!this.state.termsAndConditionsAccepted ||
												this.props.alreadyAccepted
											}
										>
											Accept
									</Button>
									</CardActions>
								</CardContent>
							</Paper>
						)
				}
			/>
		);
	}
}

const styles: { [key: string]: React.CSSProperties } = {
	bottomBar: {
		paddingLeft: 20,
		paddingRight: 20,
		paddingTop: 0,
		paddingBottom: 0
	},
};

function mapStateToProps(state): StateProps {
	
	return {
		userId: state.user.id,
		alreadyAccepted: !!state.user.acceptanceDate,
	};
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps & Partial<NavigationProps> {
	return {
		navigateTo: (route: string) =>
			dispatch(navigateTo(route)),
		startLogout: () => dispatch(Logout.started(undefined)),
		enableFirstTimeLoginPrompt: () => dispatch(enableFirstTimeLoginPrompt()),
		acceptTermsAndConditions: () => dispatch(AcceptTermsAndConditions()),
	};
}

export const TermsAndConditionsContainer = connect(mapStateToProps, mapDispatchToProps, true)(TermsAndConditions);

