interface LookupProps {
	id: string;
	label: string;
	value: string;
	sequence: number;
	parents?: Lookup[];
}

export class Lookup {
	/**
	 * Full stack reference for the lookup - values retrieved from the backend for a given ID will replace other values on the UI.
	 */
	id: string;
	/**
	 * The displayed representation of the lookup; this is to be used in all display logic.
	 */
	label: string;
	/**
	 * The stored value for the lookup; this is the value being manipulated in the backend. Always use this value to send to the API.
	 */
	value: string;
	/**
	 * An order that can be added to lookups
	 */
	sequence: number;
	/**
	 * The lookups to whom this belongs. Used to organize fields and perform business logic.
	 */
	parents: Lookup[];

	/**
	 * Constructor
	 * @param params if string, sets all fields to string value; if LookupProps, sets fields to match props; else, uses falsey values
	 */
	constructor(params?: LookupProps | string) {
		if (params) {
			if (typeof params == 'string') {
				this.id = params;
				this.label = params;
				this.value = params;
				this.sequence = 0;
				this.parents = [];
			} else {
				this.id = params.id;
				this.label = params.label;
				this.value = params.value;
				this.sequence = params.sequence;
				this.parents = params.parents ? params.parents : [];
			}
		} else {
			this.id = '';
			this.label = '';
			this.value = '';
			this.sequence = -1;
			this.parents = [];
		}
	}

	/**
	 * Returns a deep copy of the current Lookups.
	 */
	clone(): Lookup {
		return new Lookup({
			id: this.id,
			label: this.label,
			value: this.value,
			sequence: this.sequence,
			parents: this.parents,
		});
	}

	/**
	 * Returns true if target matches the Lookup or its label, id, or value. This is case insensitive.
	 * @param target target value to match
	 * @param type matches on Type as well
	 */
	matches(target: string | Lookup, type?: string): boolean {
		if (typeof target == 'string') {
			const normalizedValue = target ? target.toLowerCase() : target;
			const matchesId = this.id
				? this.id.toLowerCase() == normalizedValue
				: false;
			const matchesType = type ? this.isChildOf(type) : true;
			return normalizedValue == 'all'
				? true
				: matchesType &&
				(this.label.toLowerCase() == normalizedValue ||
					this.value.toLowerCase() == normalizedValue ||
					matchesId);
		} else {
			return target && (this.isLookup(target) || target.isLookup(Lookups.All));
		}
	}

	/**
	 * Returns true if this matches the id, label, value, and parents of the other Lookups.
	 * @param other the other lookup to be compared
	 */
	isLookup(other: Lookup): boolean {
		return (
			this.id == other.id &&
			this.label == other.label &&
			this.value == other.value &&
			this.parents == other.parents
		);
	}

	/**
	 * Returns this lookup's parents of the target type
	 * @param targetType the type to filtered against
	 */
	getParentsOfType(
		targetType: Lookup | string | (Lookup | string)[]
	): Lookup[] {
		return this.parents.filter(lookup => lookup.isChildOf(targetType));
	}

	/**
	 * Returns this Lookup's first parent of type TargetType;
	 * @param targetType the type to filtered against
	 */
	getFirstParentOfType(targetType: Lookup | string): Lookup {
		const parents = this.getParentsOfType(targetType);
		return parents.length > 0 ? parents[0] : new Lookup();
	}

	/**
	 * If this lookup is of targetType, returns self. Else returns the first parent of type TargetType;
	 * @param targetType the type to filtered against
	 */
	getFirstParentOrSelfOfType(
		targetType: Lookup | string | (Lookup | string)[]
	): Lookup {
		if (this.isChildOf(targetType)) return this;
		const parents = this.getParentsOfType(targetType);
		return parents.length > 0 ? parents[0] : new Lookup();
	}

	/**
	 * Returns true if this lookup has the target(s) as one of its parents
	 * @param targets the target parent(s)
	 */
	isChildOf(targets: (Lookup | string) | (Lookup | string)[]): boolean {
		return Array.isArray(targets)
			? targets.every(
				target =>
					this.parents.some(parent => parent.matches(target)) ||
					Lookups.All.matches(target)
			)
			: this.parents
				? this.parents.some(parent => parent.matches(targets))
				: false;
	}

	/**
	 * Returns true if this lookup does not have the target(s) as any of its parents
	 * @param targets the target parent(s)
	 */
	isNotChildOf(targets: (Lookup | string) | (Lookup | string)[]): boolean {
		return !this.isChildOf(targets);
	}

	/**
	 * Returns true if this lookup matches the target or one of its children matches the target
	 * @param targetLookup the lookup being searched against
	 */
	containsLookup(targetLookup: Lookup): boolean {
		return targetLookup
			? this.isLookup(targetLookup) ||
			DefaultDictionary.getChildren(targetLookup).length > 0
			: false;
	}

	/**
	 * Returns true if this lookup matches the target or one of its children matches the target
	 * @param targetString the string being searched against
	 */
	containsLabel(target: string): boolean {
		const lookup = DefaultDictionary.find(target);
		return lookup ? this.containsLookup(lookup) : false;
	}

	/**
	 * Returns true if this lookup matches the target or one of its children matches the target
	 * @param target the string or lookup being searched for
	 */
	contains(target: Lookup | string): boolean {
		return typeof target == 'string'
			? this.containsLabel(target)
			: this.containsLookup(target);
	}

	/**
	 * Returns an array of the children to this Lookup value, excluding the current Lookups.
	 */
	getChildren() {
		return DefaultDictionary.getChildren(this);
	}
}

import { Strings } from "../../assets/common/strings";
import { LookupDictionary } from './lookup_dictionary';
/**
		 * Notification Type
		 */
export const NotificationType = new Lookup({
    id: '',
    label: 'Type',
    value: 'Type',
    sequence: 0,
    parents: []
});

/**
 * Carrier
 */
export const Carrier = new Lookup({
    id: '',
    label: 'Carrier',
    value: 'Carrier',
    sequence: 0,
    parents: [],
});

/**
 * Gender
 */
export const Gender = new Lookup({
    id: '',
    label: 'Gender',
    value: 'Gender',
    sequence: 0,
    parents: [],
});

/**
* Status
*/
export const Status = new Lookup({
    id: '',
    label: 'Status',
    value: 'ContactStatus',
    sequence: 0,
    parents: [],
});

/**
 * Client Type
 */
export const ClientType = new Lookup({
    id: '',
    label: 'ClientType',
    value: 'ClientType',
    sequence: 0,
    parents: [],
});

/**
 * Marital Status
 */
export const MaritalStatus = new Lookup({
    id: '',
    label: 'MaritalStatus',
    value: 'MaritalStatus',
    sequence: 0,
    parents: [],
});

/**
 * Employer Advanced Search Column
 */
export const EmployerAdvSearchColumn = new Lookup({
    id: '',
    label: 'EmployerAdvSearchColumn',
    value: 'EmployerAdvSearchColumn',
    sequence: 0,
    parents: [],
});

/**
 * Person Advanced Search Column
 */
export const PersonAdvSearchColumn = new Lookup({
    id: '',
    label: 'PersonAdvSearchColumn',
    value: 'PersonAdvSearchColumn',
    sequence: 0,
    parents: [],
});

/**
 * Lead Advanced Search Column
 */
export const LeadAdvSearchColumn = new Lookup({
    id: '',
    label: 'LeadAdvSearchColumn',
    value: 'LeadAdvSearchColumn',
    sequence: 0,
    parents: [],
});

/**
 * Product Advanced Search Column
 */
export const ProductAdvSearchColumn = new Lookup({
    id: '',
    label: 'ProductAdvSearchColumn',
    value: 'ProductAdvSearchColumn',
    sequence: 0,
    parents: [],
});

/**
 * Agent Advanced Search Column
 */
export const AgentAdvSearchColumn = new Lookup({
    id: '',
    label: 'AgentAdvSearchColumn',
    value: 'AgentAdvSearchColumn',
    sequence: 0,
    parents: [],
});

/**
 * Employer Industry
 */
export const EmployerIndustry = new Lookup({
    id: '',
    label: 'EmployerIndustry',
    value: 'EmployerIndustry',
    sequence: 0,
    parents: [],
});

/**
 * Lead Status Reason
 */
export const LeadStatusReason = new Lookup({
    id: '',
    label: 'LeadStatusReason',
    value: 'LeadStatusReason',
    sequence: 0,
    parents: [],
});

/**
 * Lead Type
 */
export const LeadType = new Lookup({
    id: '',
    label: 'Lead Type',
    value: 'LeadType',
    sequence: 0,
    parents: [],
});

/**
 * Vendor
 */
export const Vendor = new Lookup({
    id: '',
    label: 'Vendor',
    value: 'Vendor',
    sequence: 0,
    parents: [],
});

/**
 * Fund
 */
export const Fund = new Lookup({
    id: '',
    label: 'Fund',
    value: 'Fund',
    sequence: 0,
    parents: [],
})

/**
 * Language
 */
export const Language = new Lookup({
    id: '',
    label: 'Language',
    value: 'Language',
    sequence: 0,
    parents: [],
});

/**
 * Product Type
 */
export const ProductType = new Lookup({
    id: '',
    label: 'ProductType',
    value: 'ProductType',
    sequence: 0,
    parents: [],
});

/**
 * Product Lines of Business
 */
export const ProductLineOfBusiness = new Lookup({
    id: '',
    label: 'ProductLineOfBusiness',
    value: 'ProductLineOfBusiness',
    sequence: 0,
    parents: [],
});

/**
 * Product Status Reason
 */
export const ProductStatusReason = new Lookup({
    id: '',
    label: 'ProductStatusReason',
    value: 'ProductStatusReason',
    sequence: 0,
    parents: [],
});

/**
 * Product Metal Level
 */
export const ProductMetalLevel = new Lookup({
    id: '',
    label: 'ProductMetalLevel',
    value: 'ProductMetalLevel',
    sequence: 0,
    parents: [],
});

/**
 * Sharepoint Filter Category
 */
export const SharepointFilterCategory = new Lookup({
    id: '',
    label: 'SharepointFilterCategory',
    value: 'SharepointFilterCategory',
    sequence: 0,
    parents: [],
});

/**
 * Sharepoint Filter Line of Business
 */
export const SharepointFilterLineOfBusiness = new Lookup({
    id: '',
    label: 'SharepointFilterLineOfBusiness',
    value: 'SharepointFilterLineOfBusiness',
    sequence: 0,
    parents: [],
});

/**
 * Suffix
 */
export const Suffix = new Lookup({
    id: '',
    label: 'Suffix',
    value: 'Suffix',
    sequence: 0,
    parents: [],
});

/**
 * Employee Status
 */
export const EmployeeStatus = new Lookup({
    id: '',
    label: 'EmployeeStatus',
    value: 'EmployeeStatus',
    sequence: 0,
    parents: [],
});

/**
 * Employer Group Status
 */
export const EmployerGroupStatus = new Lookup({
    id: '',
    label: 'EmployerGroupStatus',
    value: 'EmployerGroupStatus',
    sequence: 0,
    parents: [],
});

/**
 * Input Source
 */
export const InputSource = new Lookup({
    id: '',
    label: 'Input Source',
    value: 'InputSource',
    sequence: 0,
    parents: [],
});

/**
 * Lead Rating
 */
export const LeadRating = new Lookup({
    id: '',
    label: 'LeadRating',
    value: 'LeadRating',
    sequence: 0,
    parents: [],
});

/**
 * DateType
 */
export const DateType = new Lookup({
    id: '',
    label: 'DateType',
    value: 'DateType',
    sequence: 0,
    parents: [],
});

/**
 * DateSort
 */
export const DateSort = new Lookup({
    id: '',
    label: 'DateSort',
    value: 'DateSort',
    sequence: 0,
    parents: [],
});

/**
 * Disposition Reason
 */
export const DispositionReason = new Lookup({
    id: '',
    label: "Disposition Reason",
    value: "Disposition Reason",
    sequence: 0,
    parents: [],
});
/**
 * Catch-All Lookup
 */
export const All = new Lookup({
    id: '',
    label: 'All',
    value: '',
    sequence: 0,
    parents: [],
});

/**
 * Lead Line of Business
 */
export const LeadLineOfBusiness = new Lookup({
    id: '',
    label: Strings.LeadField.LineOfBusiness,
    value: 'LeadLineOfBusiness',
    sequence: 0,
    parents: [],
});

/**
 * Lead Status Codes
 */
export const LeadStatusCode = new Lookup({
    id: '',
    label: 'Status Code',
    value: 'Status Code',
    sequence: 0,
    parents: [],
});


/**
 * Lead Call Attempts
 */
export const CallAttempts = new Lookup({
    id: '',
    label: 'CallAttempts',
    value: 'CallAttempts',
    sequence: 0,
    parents: [],
});

export const OpenStatusCode = new Lookup({
    id: '',
    label: 'Open',
    value: Strings.Status.Open,
    sequence: 0,
    parents: [LeadStatusCode],
});

/**
 * Lead Status
 */
export const LeadStatus = new Lookup({
    id: '',
    label: 'Status',
    value: 'Status',
    sequence: 0,
    parents: [],
});

/**
 * Derived Lead Status
 */
export const DerivedLeadStatus = new Lookup({
    id: '',
    label: 'Derived Status',
    value: 'Derived Status',
    sequence: 0,
    parents: [],
});

/**
 * Activity Disposition Options
 */
export const PhoneCall = new Lookup({
    id: '',
    label: Strings.Activity.PhoneCall,
    value: Strings.Activity.PhoneCall,
    sequence: 0,
    parents: [],
});
export const LeadStatusChange = new Lookup({
    id: '',
    label: Strings.Activity.LeadStatusChange,
    value: Strings.Activity.LeadStatusChange,
    sequence: 0,
    parents: [],
});
export const Appointment = new Lookup({
    id: '',
    label: Strings.Activity.Appointment,
    value: Strings.Activity.Appointment,
    sequence: 0,
    parents: [],
});

/**
 * Disposition Stage
 */
export const DispositionStage = new Lookup({
    id: '',
    label: 'Lead Status Stage',
    value: 'Lead Status Stage',
    sequence: 0,
    parents: [],
});
export const New = new Lookup({
    id: '',
    label: 'New',
    value: Strings.Status.New,
    sequence: 1,
    parents: [DerivedLeadStatus, DispositionStage],
});
export const Attempted = new Lookup({
    id: '',
    label: 'Attempted',
    value: Strings.Status.Attempted,
    sequence: 2,
    parents: [DerivedLeadStatus, DispositionStage],
});
export const Contacted = new Lookup({
    id: '',
    label: 'Contacted',
    value: Strings.Status.Contacted,
    sequence: 3,
    parents: [DerivedLeadStatus, DispositionStage],
});
export const DealsInProgress = new Lookup({
    id: '',
    label: 'Deals in Progress',
    value: Strings.Status.DealsInProgress,
    sequence: 4,
    parents: [DerivedLeadStatus, DispositionStage],
});
export const Complete = new Lookup({
    id: '',
    label: 'Complete',
    value: Strings.Status.Complete,
    sequence: 5,
    parents: [DerivedLeadStatus, DispositionStage],
});

/**
 * Disposition Follow Up
 */
export const DispositionFollowUpAction = new Lookup({
    id: '',
    label: 'Disposition Follow Up',
    value: 'DispositionFollowUp',
    sequence: 0,
    parents: [],
});
export const CreateNewAppointment = new Lookup({
    id: '',
    label: 'Disposition New Appointment',
    value: 'DispositionNewAppointment',
    sequence: 0,
    parents: [DispositionFollowUpAction],
});
export const CreateNewPhoneCall = new Lookup({
    id: '',
    label: 'Disposition New Phone Call',
    value: 'DispositionNewPhoneCall',
    sequence: 0,
    parents: [DispositionFollowUpAction],
});
export const AlwaysCreateNewPhoneCall = new Lookup({
    id: '',
    label: 'Disposition New Phone Call',
    value: 'AlwaysDispositionNewPhoneCall',
    sequence: 0,
});
export const TriggerNoSaleDisposition = new Lookup({
    id: '',
    label: 'Trigger No Sale Disposition',
    value: 'TriggerNoSaleDisposition',
    sequence: 0,
    parents: [DispositionFollowUpAction],
});

/**
 * Activity Disposition Label
 */
export const DispositionLabel = new Lookup({
    id: '',
    label: 'Disposition Follow Up',
    value: 'Disposition Follow Up',
    sequence: 0,
    parents: [],
});
export const PhoneCallCompleteLabel = new Lookup({
    id: '',
    label: 'Phone Call Complete',
    value: 'PhoneCallCompleteLabel',
    sequence: 0,
    parents: [DispositionLabel, PhoneCall],
});
export const PhoneCallLeftMessageLabel = new Lookup({
    id: '',
    label: 'Left Message',
    value: 'PhoneCallLeftMessageLabel',
    sequence: 0,
    parents: [DispositionLabel, PhoneCall],
});
export const PhoneCallNoAnswerLabel = new Lookup({
    id: '',
    label: 'No Answer',
    value: 'PhoneCallNoAnswerLabel',
    sequence: 0,
    parents: [DispositionLabel, PhoneCall],
});
export const AppointmentHeldLabel = new Lookup({
    id: '',
    label: 'Appointment Held',
    value: 'AppointmentCompleteLabel',
    sequence: 0,
    parents: [DispositionLabel, Appointment],
});
export const AppointmentNoShowLabel = new Lookup({
    id: '',
    label: 'No Show',
    value: 'NoShowAppointmentLabel',
    sequence: 0,
    parents: [DispositionLabel, Appointment],
});
export const RescheduleLabel = new Lookup({
    id: '',
    label: 'Rescheduled',
    value: 'RescheduleLabel',
    sequence: 0,
    parents: [DispositionLabel, Appointment],
});

/**
 * Statuses
 */
export const Sale = new Lookup({
    id: 'f5c76922-7c08-4293-ae1f-265eff642325',
    label: 'Sale',
    value: Strings.Status.Sale,
    sequence: 0,
    parents: [
        LeadStatus,
        LeadStatusCode,
        DispositionReason,
        PhoneCallCompleteLabel,
        PhoneCall,
        Appointment,
        AppointmentHeldLabel,
        Complete,
    ],
});
export const NoSale = new Lookup({
    id: '',
    label: 'No Sale',
    value: Strings.Status.NoSale,
    sequence: 0,
    parents: [
        LeadStatusCode,
        LeadStatus,
        PhoneCall,
        PhoneCallCompleteLabel,
        DispositionReason,
        Complete,
        TriggerNoSaleDisposition,
        AppointmentHeldLabel,
    ],
});
export const Called = new Lookup({
    id: '',
    label: 'Called',
    value: Strings.Status.Called,
    sequence: -1,
    parents: [OpenStatusCode, DerivedLeadStatus, Attempted],
});
export const Quoted = new Lookup({
    id: '32ac0d2a-42f6-431d-9b2d-a57f6c2b4c3c',
    label: 'Quoted',
    value: Strings.Status.Quoted,
    sequence: 0,
    parents: [LeadStatus, DealsInProgress, OpenStatusCode, CreateNewPhoneCall],
});
export const AgentDeparture = new Lookup({
    id: '9be4c3d6-a80a-49e1-8114-f5fdfd2a19c7',
    label: 'Agent Departure',
    value: Strings.Status.AgentDeparture,
    sequence: 0,
    parents: [NoSale, LeadStatus, Complete],
});
export const AgentInactivity = new Lookup({
    id: 'f1a567ec-dda5-4fa4-8cfd-fe3f9ce85d26',
    label: 'Agent Inactivity',
    value: Strings.Status.AgentInactivity,
    sequence: 0,
    parents: [NoSale, LeadStatus, Complete],
});
export const ApplicationInProgress = new Lookup({
    id: '70c12a91-be45-4bd5-a161-95f1a1ad625a',
    label: 'Application In Progress',
    value: Strings.Status.ApplicationInProgress,
    sequence: 0,
    parents: [
        LeadStatus,
        OpenStatusCode,
        DealsInProgress,
        DispositionReason,
        CreateNewPhoneCall,
        PhoneCallCompleteLabel,
        PhoneCall,
        Appointment,
        AppointmentHeldLabel,
    ],
});
export const AppointmentSet = new Lookup({
    id: 'bd4611ea-deaa-44f2-ab78-71b2a52c09f5',
    label: 'Appointment Set',
    value: Strings.Status.AppointmentSet,
    sequence: 0,
    parents: [
        LeadStatus,
        Contacted,
        OpenStatusCode,
        DispositionReason,
        PhoneCall,
        PhoneCallCompleteLabel,
        CreateNewAppointment,
    ],
});
export const CallbackRequested = new Lookup({
    id: '5e7b8f9c-61df-44c9-b3c1-f788afe5e365',
    label: 'Callback Requested',
    value: Strings.Status.CallbackRequested,
    sequence: 0,
    parents: [
        LeadStatus,
        Contacted,
        OpenStatusCode,
        DispositionReason,
        PhoneCall,
        PhoneCallCompleteLabel,
        Appointment,
        AppointmentHeldLabel,
        AlwaysCreateNewPhoneCall,
    ],
});
export const CalledLeftMessage = new Lookup({
    id: 'f5446788-0d11-40a0-adf7-a7d8029b0c02',
    label: 'Called - Left Message',
    value: Strings.Status.CalledLeftMessage,
    sequence: 0,
    parents: [
        LeadStatus,
        OpenStatusCode,
        Attempted,
        Called,
        DispositionReason,
        PhoneCall,
        PhoneCallLeftMessageLabel,
        CreateNewPhoneCall,
    ],
});
export const CalledNoAnswer = new Lookup({
    id: '8bfb9063-2b12-4e4e-a3dd-b14639c5f9d8',
    label: 'Called - No Answer',
    value: Strings.Status.CalledNoAnswer,
    sequence: 0,
    parents: [
        LeadStatus,
        Attempted,
        Called,
        PhoneCall,
        PhoneCallNoAnswerLabel,
        CreateNewPhoneCall,
        DispositionReason,
        OpenStatusCode,
    ],
});
export const CalledNoMessage = new Lookup({
    id: '08A814A7-8702-4D5D-A592-4C163BC84D11',
    label: 'Called - No Message',
    value: Strings.Status.CalledNoMessage,
    sequence: 0,
    parents: [
        LeadStatus,
        Attempted,
        Called,
        PhoneCall,
        PhoneCallNoAnswerLabel,
        CreateNewPhoneCall,
        DispositionReason,
        OpenStatusCode,
    ],
});
export const NoShowCallback = new Lookup({
    id: '',
    label: Strings.Status.NoShowCallback,
    value: Strings.Status.NoShowCallback,
    sequence: 0,
    parents: [
        AppointmentNoShowLabel,
        CreateNewPhoneCall,
        Appointment,
        DispositionReason,
        CallbackRequested,
        AlwaysCreateNewPhoneCall
    ],
});
export const RescheduleAppointment = new Lookup({
    id: '',
    label: Strings.Status.RescheduleAppointment,
    value: Strings.Status.RescheduleAppointment,
    sequence: 0,
    parents: [
        AppointmentSet,
        RescheduleLabel,
        CreateNewAppointment,
        DispositionReason,
        Appointment,
    ],
});
export const QuotedCallbackRequested = new Lookup({
    id: '',
    label: Strings.DispositionReason.QuotedCallbackRequested,
    value: Strings.DispositionReason.QuotedCallbackRequested,
    sequence: 0,
    parents: [
        Quoted,
        PhoneCall,
        Appointment,
        DispositionReason,
        PhoneCallCompleteLabel,
        AppointmentHeldLabel,
        AlwaysCreateNewPhoneCall,
    ],
});
export const QuotedAppointmentSet = new Lookup({
    id: '',
    label: Strings.DispositionReason.QuotedAppointmentSet,
    value: Strings.DispositionReason.QuotedAppointmentSet,
    sequence: 0,
    parents: [
        Quoted,
        PhoneCall,
        Appointment,
        PhoneCallCompleteLabel,
        AppointmentHeldLabel,
        CreateNewAppointment,
        DispositionReason,
    ],
});

export const AppointmentHeldNoSale = new Lookup({
    id: '',
    label: Strings.DispositionReason.AppointmentHeldNoSale,
    value: Strings.DispositionReason.AppointmentHeldNoSale,
    sequence: 0,
    parents: [DispositionReason, Appointment, TriggerNoSaleDisposition],
});
export const CancelledNoSale = new Lookup({
    id: '',
    label: Strings.DispositionReason.CancelledNoSale,
    value: Strings.DispositionReason.CancelledNoSale,
    sequence: 0,
    parents: [Appointment, DispositionReason, TriggerNoSaleDisposition],
});
export const CoverageReviewNoChange = new Lookup({
    id: '',
    label: Strings.DispositionReason.CoverageReviewNoChange,
    value: Strings.DispositionReason.CoverageReviewNoChange,
    sequence: 0,
    parents: [
        DispositionReason,
        LeadStatus,
        NoSale,
        Complete
    ],
});
export const SkipNoSaleFollowUp = new Lookup({
    id: '',
    label: Strings.Status.SkipNoSaleFollowUp,
    value: Strings.Status.SkipNoSaleFollowUp,
    sequence: 0,
    parents: [],
});
export const Cost = new Lookup({
    id: 'f8134c67-ffa0-4ccf-8cc5-bda35aa594a7',
    label: 'Cost',
    value: Strings.Status.Cost,
    sequence: 0,
    parents: [LeadStatus, NoSale, DispositionReason, Complete],
});
export const CoveredByCompetition = new Lookup({
    id: '6d861a2e-2b64-49ac-8afd-dc98ba3ec2bd',
    label: 'Covered by Competitor',
    value: Strings.Status.CoveredByCompetition,
    sequence: 0,
    parents: [LeadStatus, DispositionReason, NoSale, Complete],
});
export const CoveredBySpouse = new Lookup({
    id: 'e6c1f4c8-2731-43b9-8bfc-3f1fe0c75e47',
    label: 'Covered by Spouse',
    value: Strings.Status.CoveredBySpouse,
    sequence: 0,
    parents: [LeadStatus, DispositionReason, NoSale, Complete],
});
export const CustomerWillCallBack = new Lookup({
    id: '1ca7d233-1a05-4c39-8498-24f9e1df7216',
    label: 'Customer Will Call Back',
    value: Strings.Status.CustomerWillCallBack,
    sequence: 0,
    parents: [
        LeadStatus,
        DispositionReason,
        PhoneCall,
        PhoneCallCompleteLabel,
        OpenStatusCode,
        Contacted,
        LeadStatusReason,
        CreateNewPhoneCall
    ]
});
export const Deceased = new Lookup({
    id: 'e5ec8abe-60e6-4eda-87d8-f15d17a8f86e',
    label: 'Deceased',
    value: Strings.Status.Deceased,
    sequence: 0,
    parents: [
        LeadStatus,
        DispositionReason,
        NoSale,
        Complete,
        SkipNoSaleFollowUp,
    ],
});
export const DisconnectedPhone = new Lookup({
    id: '08a62d64-b496-4247-a911-fce8315dd4e3',
    label: 'Disconnected Phone',
    value: Strings.Status.DisconnectedPhone,
    sequence: 0,
    parents: [
        LeadStatus,
        DispositionReason,
        NoSale,
        Complete,
        SkipNoSaleFollowUp,
    ],
});
export const EmailedAwaitingResponse = new Lookup({
    id: 'f198ccb1-da67-4125-9609-6753839d340f',
    label: 'Emailed - Awaiting Response',
    value: Strings.Status.EmailedAwaitingResponse,
    sequence: 0,
    parents: [LeadStatus, Attempted, OpenStatusCode, CreateNewPhoneCall],
});
export const GroupInsurance = new Lookup({
    id: 'a41e3be9-8a66-4e89-9883-ccd5c26567b1',
    label: 'Group Insurance',
    value: Strings.Status.GroupInsurance,
    sequence: 0,
    parents: [LeadStatus, DispositionReason, NoSale, Complete],
});
export const InsufficientCoverage = new Lookup({
    id: '7b30142a-5af4-490f-b17f-d5c27eb05dca',
    label: 'Insufficient Coverage',
    value: Strings.Status.InsufficientCoverage,
    sequence: 0,
    parents: [LeadStatus, NoSale, Complete],
});
export const InsuranceNotDesired = new Lookup({
    id: '541d2df3-d126-458c-b90d-0ac850d50b5a',
    label: 'Insurance Not Desired',
    value: Strings.Status.InsuranceNotDesired,
    sequence: 0,
    parents: [
        LeadStatus,
        NoSale,
        Complete,
        DispositionReason,
        CreateNewPhoneCall
    ],
});
export const LanguageBarrier = new Lookup({
    id: 'e790d8b3-f2ec-40d8-a533-279338ad65df',
    label: 'Language Barrier (formerly "Does not speak English")',
    value: Strings.Status.LanguageBarrier,
    sequence: 0,
    parents: [
        LeadStatus,
        DispositionReason,
        NoSale,
        Complete,
        SkipNoSaleFollowUp,
    ],
});
export const Medicaid = new Lookup({
    id: 'f522b68c-20e7-4607-aa2c-5a1a6dfbefb2',
    label: 'Medicaid',
    value: Strings.Status.Medicaid,
    sequence: 0,
    parents: [LeadStatus, DispositionReason, NoSale, Complete],
});
export const NeverInquired = new Lookup({
    id: 'ebda0020-1d9a-4dc3-8e2c-94312ab55a7b',
    label: 'Never Inquired',
    value: Strings.Status.NeverInquired,
    sequence: 0,
    parents: [
        LeadStatus,
        DispositionReason,
        NoSale,
        Complete,
        SkipNoSaleFollowUp,
    ],
});
export const NoAnswer = new Lookup({
    id: 'be3d0c68-4974-4ecd-a124-002e00e827f9',
    label: 'No Answer',
    value: Strings.Status.NoAnswer,
    sequence: 0,
    parents: [
        LeadStatus,
        DispositionReason,
        NoSale,
        Complete],
});
export const NotContacted = new Lookup({
    id: 'a7eda598-586e-4967-9ef4-b485d59414ed',
    label: 'Not Contacted',
    value: Strings.Status.NotContacted,
    sequence: 0,
    parents: [
        LeadStatus,
        New,
        OpenStatusCode,
        CreateNewPhoneCall],
});
export const Occupation = new Lookup({
    id: '02b1b954-3031-4b15-a9a7-cc9a23fcce5e',
    label: 'Occupation',
    value: Strings.Status.Occupation,
    sequence: 0,
    parents: [LeadStatus, NoSale, Complete],
});
export const Other = new Lookup({
    id: '5efb0179-4a81-45bf-81a2-54ecb9d85ea8',
    label: 'Other',
    value: Strings.Status.Other,
    sequence: 0,
    parents: [LeadStatus, NoSale, Complete],
});
export const OutOfArea = new Lookup({
    id: '5a5aaa0c-1141-489e-8ddb-c3714af4792b',
    label: 'Out of Area',
    value: Strings.Status.OutOfArea,
    sequence: 0,
    parents: [LeadStatus, NoSale, Complete],
});
export const OverAge = new Lookup({
    id: '1d3a0a95-c51e-4107-9351-ad7f19fe73d3',
    label: 'Over Age',
    value: Strings.Status.OverAge,
    sequence: 0,
    parents: [LeadStatus, NoSale, Complete],
});
export const TextedAwaitingAnswer = new Lookup({
    id: 'f198ccb1-da67-4125-9609-6753839d340f',
    label: 'Texted - Awaiting Response',
    value: Strings.Status.TextedAwaitingAnswer,
    sequence: 0,
    parents: [
        LeadStatus,
        Attempted,
        OpenStatusCode,
        CreateNewPhoneCall],
});
export const UnderAge = new Lookup({
    id: '885c36be-13cc-44b2-a897-e6fafc7ef33f',
    label: 'Under Age',
    value: Strings.Status.UnderAge,
    sequence: 0,
    parents: [
        LeadStatus,
        NoSale,
        Complete],
});
export const Uninsurable = new Lookup({
    id: '89fa251b-c11c-4333-9e6f-206b348e4d15',
    label: 'Uninsurable',
    value: Strings.Status.Uninsurable,
    sequence: 0,
    parents: [
        LeadStatus, NoSale, Complete],
});
export const WorkingWithAgent = new Lookup({
    id: 'b1b1c0b1-4d1e-492d-805b-8380ac344199',
    label: 'Working With Agent',
    value: Strings.Status.WorkingWithAgent,
    sequence: 0,
    parents: [LeadStatus, DispositionReason, NoSale, Complete],
});
export const WrongNumber = new Lookup({
    id: '3cb31f4a-4bab-4026-b4ed-4bf2c1a28c26',
    label: Strings.Status.WrongNumber,
    value: Strings.Status.WrongNumber,
    sequence: 0,
    parents: [
        LeadStatus,
        DispositionReason,
        NoSale,
        Complete,
        SkipNoSaleFollowUp,
    ],
});
export const EmailTemplateSubstitution = new Lookup({
    id: '',
    label: 'EmailTemplateSubstitution',
    sequence: 0,
    value: 'EmailTemplateSubstitution',
    parents: [],
});
export const GroupCoverageAdvantage = new Lookup({
    id: '',
    label: 'GroupCoverageAdvantage',
    sequence: 0,
    value: 'GroupCoverageAdvantage',
    parents: [],
});
export const IntroVideoURL = new Lookup({
    id: 'cc99bb47-a0ed-4368-b0dc-36825d222c60',
    label: 'IntroVideoURL',
    sequence: 0,
    value: 'IntroVideoURL',
    parents: [],
});
export const HelpAndSupportEmail = new Lookup({
    id: '',
    label: 'HelpAndSupportEmail',
    sequence: 0,
    value: 'HelpAndSupportEmail',
    parents: [],
});
export const HelpAndSupportPhone = new Lookup({
    id: '',
    label: 'HelpAndSupportPhone',
    sequence: 0,
    value: 'HelpAndSupportPhone',
    parents: [],
});
export const Persistent = new Lookup({
    id: '',
    label: 'Persistent',
    value: 'persistent',
    sequence: 0,
    parents: [NotificationType],
});

export const EmailAutomationEventTypeCategory = new Lookup({
    id: '',
    label: 'EmailAutomationEventTypeCategory',
    value: 'EmailAutomationEventTypeCategory',
    sequence: 0,
    parents: [],
});

export const Lookups = {
    All,
    OpenStatusCode,
    LeadStatus,
    DispositionStage,
    NotificationType,
    New,
    AlwaysCreateNewPhoneCall,
    Attempted,
    Carrier,
    Complete,
    Contacted,
    DealsInProgress,
    AgentDeparture,
    AgentInactivity,
    ApplicationInProgress,
    AppointmentSet,
    CallbackRequested,
    DerivedLeadStatus,
    CalledLeftMessage,
    CalledNoAnswer,
    CalledNoMessage,
    Cost,
    CoverageReviewNoChange,
    CoveredByCompetition,
    CoveredBySpouse,
    CustomerWillCallBack,
    Deceased,
    DisconnectedPhone,
    EmailedAwaitingResponse,
    GroupInsurance,
    InsufficientCoverage,
    InsuranceNotDesired,
    LanguageBarrier,
    Medicaid,
    NeverInquired,
    NoAnswer,
    NotContacted,
    Occupation,
    Other,
    OutOfArea,
    OverAge,
    Quoted,
    TextedAwaitingAnswer,
    UnderAge,
    LeadStatusCode,
    Uninsurable,
    WorkingWithAgent,
    WrongNumber,
    DispositionReason,
    PhoneCall,
    Appointment,
    NoShowCallback,
    RescheduleAppointment,
    QuotedCallbackRequested,
    QuotedAppointmentSet,
    Sale,
    AppointmentHeldNoSale,
    CancelledNoSale,
    NoSale,
    LeadLineOfBusiness,
    EmailTemplateSubstitution,
    DispositionFollowUpAction,
    CreateNewAppointment,
    CreateNewPhoneCall,
    PhoneCallCompleteLabel,
    AppointmentHeldLabel,
    AppointmentNoShowLabel,
    RescheduleLabel,
    PhoneCallLeftMessageLabel,
    PhoneCallNoAnswerLabel,
    DispositionLabel,
    TriggerNoSaleDisposition,
    LeadStatusChange,
    SkipNoSaleFollowUp,
    GroupCoverageAdvantage,
    IntroVideoURL,
    HelpAndSupportEmail,
    HelpAndSupportPhone,
    Persistent,
    Gender,
    ClientType,
    MaritalStatus,
    EmployerAdvSearchColumn,
    PersonAdvSearchColumn,
    LeadAdvSearchColumn,
    ProductAdvSearchColumn,
    AgentAdvSearchColumn,
    EmployerIndustry,
    LeadStatusReason,
    LeadType,
    Vendor,
	Fund,
	Called,
    Language,
    ProductType,
    ProductLineOfBusiness,
    ProductStatusReason,
    ProductMetalLevel,
    SharepointFilterCategory,
    SharepointFilterLineOfBusiness,
    Suffix,
    EmployeeStatus,
    EmployerGroupStatus,
    InputSource,
    LeadRating,
    DateType,
    DateSort,
    EmailAutomationEventTypeCategory,
    Status,
    CallAttempts
};

export const DefaultLookups = [
    Lookups.All,
    Lookups.OpenStatusCode,
    Lookups.New,
    Lookups.Attempted,
    Lookups.Carrier,
    Lookups.Complete,
    Lookups.Contacted,
    Lookups.DealsInProgress,
    Lookups.AgentDeparture,
    Lookups.AgentInactivity,
    Lookups.ApplicationInProgress,
    Lookups.AppointmentSet,
    Lookups.CallbackRequested,
    Lookups.CalledLeftMessage,
    Lookups.CalledNoAnswer,
    Lookups.CalledNoMessage,
    Lookups.Cost,
    Lookups.CoverageReviewNoChange,
    Lookups.CoveredByCompetition,
    Lookups.CoveredBySpouse,
    Lookups.CustomerWillCallBack,
    Lookups.Deceased,
    Lookups.DisconnectedPhone,
    Lookups.EmailedAwaitingResponse,
    Lookups.GroupInsurance,
    Lookups.InsufficientCoverage,
    Lookups.InsuranceNotDesired,
    Lookups.LanguageBarrier,
    Lookups.Medicaid,
    Lookups.NeverInquired,
    Lookups.NoAnswer,
    Lookups.NotContacted,
    Lookups.Occupation,
    Lookups.Other,
    Lookups.OutOfArea,
    Lookups.OverAge,
    Lookups.Quoted,
    Lookups.TextedAwaitingAnswer,
    Lookups.UnderAge,
    Lookups.Uninsurable,
    Lookups.WorkingWithAgent,
    Lookups.WrongNumber,
    Lookups.DispositionReason,
    Lookups.PhoneCall,
    Lookups.Appointment,
    Lookups.NoShowCallback,
    Lookups.RescheduleAppointment,
    Lookups.QuotedCallbackRequested,
    Lookups.QuotedAppointmentSet,
    Lookups.Sale,
    Lookups.AppointmentHeldNoSale,
    Lookups.CancelledNoSale,
    Lookups.NoSale,
    Lookups.LeadLineOfBusiness,
    Lookups.EmailTemplateSubstitution,
    Lookups.DispositionFollowUpAction,
    Lookups.CreateNewAppointment,
    Lookups.CreateNewPhoneCall,
    Lookups.PhoneCallCompleteLabel,
    Lookups.AppointmentHeldLabel,
    Lookups.AppointmentNoShowLabel,
    Lookups.RescheduleLabel,
    Lookups.PhoneCallLeftMessageLabel,
    Lookups.PhoneCallNoAnswerLabel,
    Lookups.DispositionLabel,
    Lookups.TriggerNoSaleDisposition,
    Lookups.LeadStatusChange,
    Lookups.SkipNoSaleFollowUp,
    Lookups.GroupCoverageAdvantage,
    Lookups.IntroVideoURL,
    Lookups.HelpAndSupportEmail,
    Lookups.HelpAndSupportPhone,
    Lookups.Persistent,
    Lookups.Gender,
    Lookups.ClientType,
    Lookups.MaritalStatus,
    Lookups.EmployerAdvSearchColumn,
    Lookups.PersonAdvSearchColumn,
    Lookups.LeadAdvSearchColumn,
    Lookups.ProductAdvSearchColumn,
    Lookups.AgentAdvSearchColumn,
    Lookups.EmployerIndustry,
    Lookups.LeadStatusReason,
    Lookups.LeadType,
    Lookups.Vendor,
    Lookups.Fund,
    Lookups.Language,
    Lookups.ProductType,
    Lookups.ProductLineOfBusiness,
    Lookups.ProductStatusReason,
    Lookups.ProductMetalLevel,
    Lookups.SharepointFilterCategory,
    Lookups.SharepointFilterLineOfBusiness,
    Lookups.Suffix,
    Lookups.EmployeeStatus,
    Lookups.EmployerGroupStatus,
    Lookups.InputSource,
    Lookups.LeadRating,
    Lookups.DateType,
    Lookups.DateSort,
    Lookups.EmailAutomationEventTypeCategory,
    Lookups.Status,
    Lookups.CallAttempts
];

export const DefaultDictionary = new LookupDictionary(DefaultLookups);
