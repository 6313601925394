import { Action as ReduxAction } from 'redux';
import moment from 'moment';
import { StoreActivityFilters } from '../actions/activity_actions';
import { isType } from 'typescript-fsa';
import { ClearCaches } from '../actions/authentication_actions';
import { Logout } from '../actions/authentication_actions';
import { Strings } from '../assets/common/strings';

export interface FilterField {
	displayOptions: any[];
	values: any[];
	value: string;
	defaultIndex: number;
	selectedIndex: number;
}

export interface ActivityFilters {
	searchFilterValue: string;
	highPriorityFilterValue: boolean;
	typeFilter: FilterField;
	statusFilter: FilterField;
	dueFilter: FilterField;
	completedFilter: FilterField;
	startTime?: Date;
	endTime?: Date;
}

export const initialFilterState: ActivityFilters = {
	searchFilterValue: '',
	highPriorityFilterValue: false,
	typeFilter: {
		displayOptions: ['All Types', 'Phone', 'Appointment', 'Task'],
		values: ['all', Strings.Activity.PhoneCall, Strings.Activity.Appointment, Strings.Activity.Task],
		value: 'all',
		defaultIndex: 0,
		selectedIndex: 0,
	},
	statusFilter: {
		displayOptions: ['Open', 'Completed'],
		values: ['open', 'completed'],
		value: 'open',
		defaultIndex: 0,
		selectedIndex: 0,
	},
	dueFilter: {
		displayOptions: [
			'Due Anytime',
			'Past Due',
			'Last 30 Days',
			'Last 7 Days',
			'Due Today',
			'Due Tomorrow',
			'Due Next 7 Days',
			'Due Next 30 Days',
			'Custom Date Range',
		],
		values: ['anytime', 'past', '-30', '-7', '0', '1', '7', '30', 'custom'],
		value: '7',
		defaultIndex: 6,
		selectedIndex: 6,
	},
	completedFilter: {
		displayOptions: [
			'Completed Anytime',
			'Completed Today',
			'Completed Yesterday',
			'Completed Last 7 Days',
			'Completed Last 30 Days',
			'Custom Date Range',
		],
		values: ['anytime', '0', '1', '7', '30', 'custom'],
		value: '7',
		defaultIndex: 3,
		selectedIndex: 3,
	},
	startTime: moment()
		.startOf('day')
		.toDate(),
	endTime: moment()
		.endOf('day')
		.add(7, 'days')
		.toDate(),
};

export function activityFilterReducer(
	state: ActivityFilters = initialFilterState,
	action: ReduxAction
): ActivityFilters {
	if (isType(action, StoreActivityFilters)) {
		return { 
			...state, 
			...action.payload 
		};
	} else if (isType(action, Logout.started) || isType(action, ClearCaches)) {
		return {
			...initialFilterState,
		};
	} else {
		return state;
	}
}
