import { UpdateSmsTemplate, CreateSmsTemplate, DeleteSmsTemplate, GetAllSmsTemplates } from './../actions/sms_template_actions';
import { IdObject } from './../utilities/object_util';
import { Action as ReduxAction } from 'typescript-fsa';
import { ClearCaches } from '../actions/authentication_actions';
import { Logout } from '../actions/authentication_actions';
import BaseDTO from '../utilities/base_dto';

export interface SmsTemplate extends BaseDTO {
    content: string;
    previewContent: string;
    subject: string;
    templateType: string;
    language: string;
}

export enum SmsTemplateField {
    content = 'content',
    subject = 'subject',
    templateType = 'templateType',
    language = 'language',
}

export interface SmsTemplateState {
    smsTemplates: { [templateId: string]: SmsTemplate };
    isLoading: boolean;
}

export const initialState: SmsTemplateState = {
    smsTemplates: {},
    isLoading: false,
};

export function smsTemplateReducer<T extends IdObject>(
    state: SmsTemplateState = initialState,
    action: ReduxAction<T>
): SmsTemplateState {

    switch (action.type) {

        case (Logout.type):
        case (ClearCaches.type):
            return initialState;

        case (GetAllSmsTemplates.started.type):
            return {
                ...state,
                isLoading: true,
            }

        case (GetAllSmsTemplates.done.type):
        case (UpdateSmsTemplate.done.type):
        case (CreateSmsTemplate.done.type):
            const updatedSmsTemplates = isSmsTemplate(action.payload.result) || isSmsTemplateArray(action.payload.result)
                ? updateCachedSmsTemplates(state.smsTemplates, action.payload.result)
                : state.smsTemplates;

            return {
                ...state,
                isLoading: false,
                smsTemplates: updatedSmsTemplates,
            };

        case (DeleteSmsTemplate.done.type):
            const id = action.payload.result.id;
            const updatedTemplates = Object.assign({}, state.smsTemplates);
            delete updatedTemplates[id];

            return {
                ...state,
                smsTemplates: updatedTemplates
            }

        default:
            return state;
    }
}

const isSmsTemplate = (payload: any): payload is SmsTemplate => {
    const smsTemplatePayload = payload as any as SmsTemplate;

    if (smsTemplatePayload.content && smsTemplatePayload.id)
        return true;
    return false;
}

const isSmsTemplateArray = (payload: any): payload is SmsTemplate[] => {

    if (!Array.isArray(payload))
        return false;

    const smsTemplateArr = payload as any as SmsTemplate[];

    if (smsTemplateArr.every(smsTemplate => isSmsTemplate(smsTemplate)))
        return true;
    else
        return false;
}

const updateCachedSmsTemplates = (cached: { [templateId: string]: SmsTemplate }, inbound: SmsTemplate | SmsTemplate[]) => {
    const cachedCopy = Object.assign({}, cached);

    if (Array.isArray(inbound)) {

        inbound.forEach(smsTemplate => {

            if (smsTemplate.isDeleted)
                delete cachedCopy[smsTemplate.id];
            else
                cachedCopy[smsTemplate.id] = smsTemplate

            const myObject = {
                subObject: 'id'
            }

            cachedCopy[myObject.subObject]
        });

    } else {
        cachedCopy[inbound.id] = inbound;

    }

    return cachedCopy;
}