import { jwt_auth } from './auth';
import { Agent, AgentChannel, AgentAddress } from '../reducers/agent_reducer';
import { Strings } from '../assets/common/strings';
import { DownlineAgent } from '../reducers/product_reducer';
import { getReferrerUrl } from './brokerage_utils';
import { SearchAgent } from '../reducers/agent_search_reducer';
import { themeMisc } from '../utilities/branding';
import { HouseholdSelection } from '../components/Policy/policy_match_household_select';

export const isAgentRole = (roles: string[]): boolean => {
	const agentRoles = jwt_auth.getAgentRoles();
	return agentRoles.some(agentRole => roles.some(role => role == agentRole));
};

export const findAgentRole = (agent: Agent) => {
	const roles: string[] = agent.agentRoles || [];
	return findAgentRoleFromArray(roles);
};

export const findDownlineAgentRole = (agent: DownlineAgent) => {
	const roles: string[] = agent.agentRoles || [];
	return findAgentRoleFromArray(roles);
}

export const findAgentRoleFromArray = (roles: string[]) => {
	let agentRole: string = '';
	if (~roles.indexOf(Strings.AgentRoles.SVPAbbrev) || ~roles.indexOf(Strings.AgentRoles.SVP)) {
		agentRole = Strings.AgentRoles.SVPAbbrev;
	} else if (~roles.indexOf(Strings.AgentRoles.TVPAbbrev) || ~roles.indexOf(Strings.AgentRoles.TVP)) {
		agentRole = Strings.AgentRoles.TVPAbbrev;
	} else if (~roles.indexOf(Strings.AgentRoles.SalesLead)) {
		agentRole = Strings.AgentRoles.SalesLead;
	} else if (~roles.indexOf(Strings.AgentRoles.Agent)) {
		agentRole = Strings.AgentRoles.Agent;
	} else if (~roles.indexOf(Strings.AgentRoles.SubAgent)) {
		agentRole = Strings.AgentRoles.SubAgent;
	}
	return agentRole;
}

const activeFn = (channel: AgentChannel) => channel.channelStatus === Strings.AgentChannelStatus.Active;
const suspendedFn = (channel: AgentChannel) => channel.channelStatus === Strings.AgentChannelStatus.Suspended;
export const getAgentStatus = (agent: Agent) => {
	const channels = agent?.channels || [];
	let status: string = Strings.AgentChannelStatus.Terminated;
	if (channels.some(activeFn)) {
		status = Strings.AgentChannelStatus.Active;
	} else if (channels.some(suspendedFn)) {
		status = Strings.AgentChannelStatus.Suspended;
	}
	return status;
};

export const getAgentFullName = (agent: Agent) => {
	const agentPrefix = agent.title || '';
	const agentMiddleInitial = agent.middleInitial || '';
	const agentLastName = agent.lastName || '';
	const agentSuffix = agent.suffix || '';
	let agentFullName = agentPrefix ? agentPrefix + ' ' : '';
	agentFullName = agentFullName + getAgentName(agent) + ' ';
	agentFullName =
		agentFullName + (agentMiddleInitial ? agentMiddleInitial + ' ' : '');
	agentFullName = agentFullName + (agentLastName ? agentLastName : '');
	agentFullName = agentFullName + (agentSuffix ? ' ' + agentSuffix : '');
	return agentFullName.trim();
};

export const getAgentName = (agent: Agent) => {
	let preferredName = agent.preferredName || '';
	if (preferredName == null || preferredName === '') {
		preferredName = agent.firstName || '';
	}
	return preferredName;
};

export const getGenericBio = (agent: Agent): string => {
	const name = getAgentFullName(agent);
	const businessAddress = (agent.addresses || []).find(
		address => address.addressType == Strings.AddressType.BusinessAddress
	);
	const city = businessAddress ? businessAddress.city : '';
	const state = businessAddress ? businessAddress.state : '';
	let location = `${city ? `${city},` : ''}${state ? ` ${state}` : ''}`;
	location = location ? ` from ${location}` : '';
	return `Hello, my name is ${name}${location}${getReferrerUrl() === Strings.AgencyReferralUrl.Hmkts ? ', and I am proud to represent HealthMarkets Insurance Agency' : ''}.  My goal is to provide the insurance plan that best fits my clients' needs.  Please contact me today for a free consultation.`;
};

export const getBusinessAddress = (agent: Agent, defaultAddress?: AgentAddress) => {
	const addresses = agent.addresses || [];
	const businessAddress = addresses.find(address => address.addressType == Strings.AddressType.BusinessAddress)
		|| defaultAddress;
	return businessAddress;
};

export const getBusinessContactInfo = (agent: Agent) => {
	const contactInfos = agent.contactInfo || [];

	// This returns the *last* matched business email and phone
	let businessEmail = '';
	let businessPhone = '';
	contactInfos.forEach(contactInfo => {
		const type = contactInfo.contactType || '';
		const method = contactInfo.contactMode || '';
		if (type.includes(Strings.ContactInfoTypes.Business) && method === Strings.ContactInfoModes.Email) {
			businessEmail = contactInfo.contactInfo || '';
		} else if (type.includes(Strings.ContactInfoTypes.Business) && method === Strings.ContactInfoModes.Phone) {
			businessPhone = contactInfo.contactInfo || '';
		}
	});

	return {
		businessEmail,
		businessPhone
	};
}

export const getAgentContactInfo = (agent: Agent) => {
	const contact = agent.contactInfo || [];
	let businessEmail = '';
	let personalEmail = '';
	let businessPhone = '';
	let personalPhone = '';
	contact.forEach(con => {
		const type = con.contactType || '';
		const method: string = con.contactMode || '';
		if (type.includes(Strings.ContactInfoTypes.Business)) {
			if (method === Strings.ContactInfoModes.Email) {
				businessEmail = con.contactInfo || '';
			} else if (method === Strings.ContactInfoModes.Phone) {
				businessPhone = con.contactInfo || '';
			}
		} else if (type.includes(Strings.ContactInfoTypes.Home)) {
			if (method === Strings.ContactInfoModes.Email) {
				personalEmail = con.contactInfo || '';
			} else if (method === Strings.ContactInfoModes.Phone) {
				personalPhone = con.contactInfo || '';
			}
		}
	});

	return {
		businessEmail,
		personalEmail,
		businessPhone,
		personalPhone,
	};
};

export const isConnectureDRXAllowed = (agentOfficeName: string, householdState: string,agentCode:string) => {
	const allowedOffices = themeMisc.connectureAllowedOffices?.split(",").map(office => office?.toLowerCase()) ?? [];
	const allowedStates = themeMisc.connectureAllowedStates?.split(",").map(state => state?.toLowerCase()) ?? [];
	const allowedAgents = themeMisc.connectureAllowedAgents?.split(",").map(agent => agent?.toLowerCase())?? [];
	return (allowedOffices.includes('*') || allowedOffices.includes(agentOfficeName?.toLowerCase()))
		&& (allowedStates.includes('*') || allowedStates.includes(householdState?.toLowerCase()) || !householdState)
		&& (allowedAgents.includes('*') || allowedAgents.includes(agentCode?.toLowerCase())); //also check state if passed in
}

export const getIsTerminated = (agent: Agent) => getAgentStatus(agent) === Strings.AgentChannelStatus.Terminated;
export const getAgentLocation = (agent: SearchAgent) =>
	`${agent?.city ? `${agent.city},` : ''}${agent?.state ? ` ${agent.state} ` : ''}${agent?.zip ? ` ${agent.zip}` : ''}`;