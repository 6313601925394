import {actionCreatorFactory} from 'typescript-fsa';
import {
	AppOrPolicy,
	Policy,
	ProductStatuses,
} from '../reducers/policy_reducer';
import H from 'history';
import { IUnlinkedGuide } from './application_actions';

const actionCreator = actionCreatorFactory('Policy');

/////////////////////// SET PRODUCT TAB ///////////////////////
export const SetProductTab = actionCreator<{ appOrPolicy: AppOrPolicy }>(
	'SET_PRODUCT_TAB'
);

/////////////////////// GET POLICY ///////////////////////
export const GetPolicy = actionCreator.async<string, Policy>('GET_POLICY');

/////////////////////// UPDATE POLICY ///////////////////////
export const UpdatePolicy = actionCreator.async<Policy, Policy>(
	'UPDATE_POLICY'
);

/////////////////////// GET ALL POLICIES ///////////////////////
export const GetAllPolicies = actionCreator.async<string, Policy[]>(
	'GET_ALL_POLICIES'
);

/////////////////////// GET ALL UNLINKED POLICIES ///////////////////////
export const GetAllUnlinkedPolicies = actionCreator.async<
	IUnlinkedGuide,
	Policy[]
>('GET_ALL_UNLINKED_POLICIES');

///////// LINK POLICY /////////
export interface LinkingPolicyPayload {
	policy: Policy;
	history: H.History;
	route: string;
}
export const LinkPolicy = actionCreator.async<LinkingPolicyPayload, Policy>(
	'LINK_POLICY'
);

///////// UNLINK POLICY /////////
export const UnlinkPolicy = actionCreator.async<LinkingPolicyPayload, Policy>(
	'UNLINK_POLICY'
);

// STORE POLICIES
export const StorePolicies = actionCreator<{
	policies: Policy[];
	clearCache?: boolean;
}>('STORE_HOUSEHOLD_POLICIES');

///////// MATCH POLICY /////////
export const MatchPolicy = actionCreator.async<Policy, Policy>('MATCH_POLICY');

///////// UNMATCH POLICY /////////
export const UnmatchPolicy = actionCreator.async<string, Policy>(
	'UNMATCH_POLICY'
);

///////// GET PRODUCT COUNTS /////////
export const GetProductCounts = actionCreator.async<
	void,
	{ [key in ProductStatuses]?: number }
>('GET_PRODUCT_COUNTS');
