import moment, { Moment } from 'moment';
import { isNullOrUndefined } from 'util';
import {
	Address,
	HouseholdRole,
	Contact,
} from '../reducers/ContactReducer';
import { Lead } from '../reducers/LeadReducer';
import {
	normalizeZipCode,
} from './formatting/data_normalizations';
import { Employer } from '../reducers/employer_reducer';
import { isMobileDevice } from './is_mobile';
import { EMPTY_ADDRESS } from './empty_entities';
import { AgentAddress } from '../reducers/agent_reducer';
import { fullNameWithMiddleInitial } from '../assets/common/string_builders';
import { Strings } from '../assets/common/strings';
import { themeMisc } from './branding/misc';

export const timeSince = (date: Date | Moment): string =>
	moment(moment().diff(date)).format("HH:mm:ss");

export const timeFromSeconds = (seconds: number, format: string = 'HH:mm:ss'): string =>
	moment.utc(seconds * 1000).format(format);

export const isNullOrUndefinedOrEmpty = (inputValue: string | null | undefined): inputValue is null | undefined | '' => (
	isNullOrUndefined(inputValue) || inputValue === ''
);

export const isBlank = (field: any): boolean => (
	!field || (
		field && typeof field === 'string'
			? field.trim() === ''
			: false
	)
);

export const hasBLead = (leads: Loaded<Lead>[]): boolean => (
	leads && leads.findIndex(
		lead =>
			lead.data &&
			!lead.data.isDeleted &&
			lead.data.statusCode == Strings.Status.Open &&
			(lead.data.inputSource as String) &&
			lead.data.inputSource.toLowerCase().replace(/[^a-z]/, '') === 'blead'
	) > -1
);

export const getPrimaryContactOnLead = (lead: Lead) => {
	const isEmployer = lead.employer && lead.employer.id;
	const primaryList = lead.contacts.filter(
		contact => isEmployer
			? contact.employerPrimaryContact
			: contact.householdRole === HouseholdRole.Primary
	);

	if (primaryList.length) {
		return primaryList[0];
	}
	// If there are no primary contacts, just pick the first one
	return lead.contacts[0];
};

export const getAgeFromDate = (birthdate: Date | string | undefined) => {
	if (birthdate) {
		return moment().diff(birthdate, 'years');
	}
	return null;
};

export const displayGenderAndAge = (
	gender: string,
	age: number | null
): string =>
	gender || age
		? `(${gender || ''}${age && gender ? `/${age}` : age || ''})`
		: '';

export const displayCity = (address: Address | undefined): string => (
	address
		? `${address.city || ''}, ${address.state || ''} ${address.zipCode || ''}`
		: ''
);

export const displayFullAddress = (address: Address | undefined): string => {
	if (address) {
		const address1 = address.line1 ? address.line1.trim() + ', ' : '';
		const address2 = address.line2 ? address.line2.trim() + ', ' : '';
		const address3 = address.city ? address.city.trim() + ', ' : '';
		const address4 = address.state ? address.state.trim() + ', ' : '';
		const address5 = address.zipCode ? address.zipCode.trim() : '';
		return `${address1} ${address2} ${address3} ${address4} ${address5} `;
	}
	return '';
}

export const agentAddressToAddress = (agentAddress: AgentAddress): Address => {
	const address = {
		line1: agentAddress.address1,
		line2: agentAddress.address2,
		city: agentAddress.city,
		state: agentAddress.state,
		zipCode: agentAddress.zipCode,
		type: agentAddress.addressType,
	} as Address;
	return address;
}

export interface Loaded<T> {
	loading: boolean;
	data: T;
	errors: any[];
	householdId?: string;
	employerId?: string;
}
export const isLoading = (
	loadableObjects: Loaded<any>[] | Loaded<any>
): boolean => {
	if (Array.isArray(loadableObjects)) {
		return loadableObjects.some((obj: Loaded<any>) => obj.loading);
	}
	return loadableObjects.loading;
};

export const getHeaderLocationToDisplay = (addresses: Address[]) => {
	if (addresses && addresses.length) {
		const primaryAddressIndex: number = addresses
			.sort(
				(a: Address, b: Address) =>
					moment(b.dateCreated).toDate().getTime() - moment(a.dateCreated).toDate().getTime()
			)
			.findIndex(
				(a: Address) => a.isPreferred
			);

		let address: Address = EMPTY_ADDRESS;
		if (primaryAddressIndex !== -1) {
			address = addresses[primaryAddressIndex];
		} else if (!isNullOrUndefined(addresses) && addresses.length > 0) {
			address = addresses[0];
		}

		return `${address.line1 || ''} ${address.line2 || ''} ${address.city || ''}${address.city ? ',' : ''} ${address.state || ''} ${address.zipCode || ''}`;
	}
	return '';
};

export const getHeaderSubTitlesToDisplay = (
	contact: Contact,
	employer?: Employer
) => {
	const displayName = employer
		? employer.companyName
		: fullNameWithMiddleInitial(contact);

	const addresses = [...contact.addresses];

	const primaryAddressIndex: number = addresses
		.sort(
			(a: Address, b: Address) =>
				moment(b.dateCreated).toDate().getTime() - moment(a.dateCreated).toDate().getTime()
		)
		.findIndex(
			(a: Address) => a.isPreferred
		);

	const address = primaryAddressIndex > -1
		? addresses[primaryAddressIndex]
		: EMPTY_ADDRESS;

	const addressLine1Line2Concat =
		`${address.line1 || ''} ${address.line2 || ''}`;

	const cityStateZipAddress =
		`${address.city || ''}${address.city ? ',' : ''} ${address.state || ''} ${normalizeZipCode(address.zipCode)}`;

	return [displayName, addressLine1Line2Concat, cityStateZipAddress];
};

export const PageSize = () => isMobileDevice ? 20 : 100;

export const stringJoin = (list: string[], separator: string = ', '): string => (
	list
		.filter(str => (str || '').trim() != '')
		.join(separator)
);

export const cleanStringForRegex = (str: string): string =>
	str?.replace(/\\/g, '').toLowerCase();

export interface US_State {
	name: string;
	abbreviation: string;
}
export const US_STATES: US_State[] = [
	{
		name: 'Alabama',
		abbreviation: 'AL',
	},
	{
		name: 'Alaska',
		abbreviation: 'AK',
	},
	{
		name: 'American Samoa',
		abbreviation: 'AS',
	},
	{
		name: 'Arizona',
		abbreviation: 'AZ',
	},
	{
		name: 'Arkansas',
		abbreviation: 'AR',
	},
	{
		name: 'California',
		abbreviation: 'CA',
	},
	{
		name: 'Colorado',
		abbreviation: 'CO',
	},
	{
		name: 'Connecticut',
		abbreviation: 'CT',
	},
	{
		name: 'Delaware',
		abbreviation: 'DE',
	},
	{
		name: 'District Of Columbia',
		abbreviation: 'DC',
	},
	{
		name: 'Federated States Of Micronesia',
		abbreviation: 'FM',
	},
	{
		name: 'Florida',
		abbreviation: 'FL',
	},
	{
		name: 'Georgia',
		abbreviation: 'GA',
	},
	{
		name: 'Guam',
		abbreviation: 'GU',
	},
	{
		name: 'Hawaii',
		abbreviation: 'HI',
	},
	{
		name: 'Idaho',
		abbreviation: 'ID',
	},
	{
		name: 'Illinois',
		abbreviation: 'IL',
	},
	{
		name: 'Indiana',
		abbreviation: 'IN',
	},
	{
		name: 'Iowa',
		abbreviation: 'IA',
	},
	{
		name: 'Kansas',
		abbreviation: 'KS',
	},
	{
		name: 'Kentucky',
		abbreviation: 'KY',
	},
	{
		name: 'Louisiana',
		abbreviation: 'LA',
	},
	{
		name: 'Maine',
		abbreviation: 'ME',
	},
	{
		name: 'Marshall Islands',
		abbreviation: 'MH',
	},
	{
		name: 'Maryland',
		abbreviation: 'MD',
	},
	{
		name: 'Massachusetts',
		abbreviation: 'MA',
	},
	{
		name: 'Michigan',
		abbreviation: 'MI',
	},
	{
		name: 'Minnesota',
		abbreviation: 'MN',
	},
	{
		name: 'Mississippi',
		abbreviation: 'MS',
	},
	{
		name: 'Missouri',
		abbreviation: 'MO',
	},
	{
		name: 'Montana',
		abbreviation: 'MT',
	},
	{
		name: 'Nebraska',
		abbreviation: 'NE',
	},
	{
		name: 'Nevada',
		abbreviation: 'NV',
	},
	{
		name: 'New Hampshire',
		abbreviation: 'NH',
	},
	{
		name: 'New Jersey',
		abbreviation: 'NJ',
	},
	{
		name: 'New Mexico',
		abbreviation: 'NM',
	},
	{
		name: 'New York',
		abbreviation: 'NY',
	},
	{
		name: 'North Carolina',
		abbreviation: 'NC',
	},
	{
		name: 'North Dakota',
		abbreviation: 'ND',
	},
	{
		name: 'Northern Mariana Islands',
		abbreviation: 'MP',
	},
	{
		name: 'Ohio',
		abbreviation: 'OH',
	},
	{
		name: 'Oklahoma',
		abbreviation: 'OK',
	},
	{
		name: 'Oregon',
		abbreviation: 'OR',
	},
	{
		name: 'Palau',
		abbreviation: 'PW',
	},
	{
		name: 'Pennsylvania',
		abbreviation: 'PA',
	},
	{
		name: 'Puerto Rico',
		abbreviation: 'PR',
	},
	{
		name: 'Rhode Island',
		abbreviation: 'RI',
	},
	{
		name: 'South Carolina',
		abbreviation: 'SC',
	},
	{
		name: 'South Dakota',
		abbreviation: 'SD',
	},
	{
		name: 'Tennessee',
		abbreviation: 'TN',
	},
	{
		name: 'Texas',
		abbreviation: 'TX',
	},
	{
		name: 'Utah',
		abbreviation: 'UT',
	},
	{
		name: 'Vermont',
		abbreviation: 'VT',
	},
	{
		name: 'Virgin Islands',
		abbreviation: 'VI',
	},
	{
		name: 'Virginia',
		abbreviation: 'VA',
	},
	{
		name: 'Washington',
		abbreviation: 'WA',
	},
	{
		name: 'West Virginia',
		abbreviation: 'WV',
	},
	{
		name: 'Wisconsin',
		abbreviation: 'WI',
	},
	{
		name: 'Wyoming',
		abbreviation: 'WY',
	},
];
