import { Card, CardActions, CardContent, CardHeader, makeStyles } from '@material-ui/core';
import React from 'react';
import classnames from 'classnames';

interface Props {
	title: string;
	avatar: JSX.Element;
	actionButtons: JSX.Element[];
	onClick?: () => void;
	contentStyle?: React.CSSProperties;
	disabled?: boolean;
}
export const Widget: React.FC<Props> = (props) => {

	const { title, avatar, actionButtons, onClick, contentStyle, disabled, children } = props;

	const styles = widgetStyles();

	return (
		<Card className={styles.container}>
			<CardHeader
				className={classnames(styles.header, disabled && styles.disabled)}
				title={<div className={classnames(disabled && styles.disabled)}>{title}</div>}
				avatar={<div className={classnames(disabled && styles.disabled)}>{avatar}</div>}
				onClick={disabled ? undefined : onClick}
			/>
			<CardContent className={styles.content} style={contentStyle}>
				{children}
			</CardContent>
			<CardActions>
				{
					actionButtons.map(
						(action, index) =>
							<span key={index}>
								{action}
							</span>
					)
				}
			</CardActions>
		</Card>
	);

};

const widgetStyles = makeStyles((theme) => ({
    container: {
		height: 456,
		position: 'relative',
	},
	content: {
		height: 330,
		paddingTop: 0,
	},
	header: {
		cursor: 'pointer',
	},
	disabled: {
		opacity: 0.6,
		cursor: 'default',
	}
}));
