import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { SvgIcon } from '../svgs/svg_icon';

export interface ILaunchIconProps {
	icon: string;
	color: string;
	caption: string;
	itemCount: number | string;
	iconClick?: any;
	isClickable?: boolean;
}

export const LaunchIcon = (props: ILaunchIconProps) => {
	const clickIt = () => {
		if (typeof props.iconClick === 'function' && props.isClickable) {
			props.iconClick(props.caption);
		}
	};

	return (
		<div>
			<IconButton
				onClick={clickIt}
				style={{
					...circleStyle,
					cursor: props.isClickable ? 'pointer' : 'initial',
					borderColor: props.color,
				}}
			>
				<Grid container justify='center' alignItems='center' style={{height: '100%'}}>
					<Grid item><SvgIcon type={props.icon} size={40} /></Grid>
				</Grid>
				<div style={badgeStyle(props)}>
					<span>{props.itemCount > 99 ? '99+' : props.itemCount}</span>
				</div>
			</IconButton>
			<div style={captionStyle}>
				<p>{props.caption}</p>
			</div>
		</div>
	);
};

const circleStyle: React.CSSProperties = {
	border: '1px solid ',
	borderRadius: 42,
	height: 83,
	width: 83,
	margin: '0 auto',
	position: 'relative',
	textAlign: 'center',
};

const badgeStyle = (props: ILaunchIconProps): React.CSSProperties => ({
	visibility: props.itemCount > 0 ? 'visible' : 'hidden',
	position: 'absolute',
	borderRadius: '50%',
	height: '28px',
	width: '28px',
	color: 'white',
	fontSize: 12,
	backgroundColor: props.color,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	lineHeight: 0,
	right: -3,
	top: -3,
});

const captionStyle: React.CSSProperties = {
	color: '#6c6c6c',
	fontSize: '9.5pt',
	textAlign: 'center',
	fontFamily: 'Roboto, sans-serif, thin',
};