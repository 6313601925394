import html2canvas from 'html2canvas';
import { Strings } from '../assets/common/strings';

import PowerPointSvg from '../assets/document_icons/powerpoint.svg';
import ExcelSvg from '../assets/document_icons/excel.svg';
import WordSvg from '../assets/document_icons/word.svg';
import ImageSvg from '../assets/document_icons/image.svg';
import PdfSvg from '../assets/document_icons/pdf.svg';
import FileSvg from '../assets/document_icons/file.svg';

export const getAvatarAsImage = async (avatar: HTMLElement) => {
	const canvas = await html2canvas(avatar);
	return canvas.toDataURL();
};

export const getLargestIcon = (iconImages: string[]): string => {
	if (iconImages) {
		const profileIndex = iconImages.findIndex(value =>
			value.search('-profile') != -1
		);

		const sizes = iconImages.map((value, index) => {
			if (index != profileIndex) {
				let index = value.lastIndexOf('-');
				return parseInt(value.substr(index + 1));
			} else {
				return 0;
			}
		});

		const largestSize = Math.max(...sizes);
		const iconIndex = iconImages.findIndex((value, index) => (
			value.search(largestSize.toString()) != -1 && index != profileIndex
		));

		if (iconIndex > -1) {
			return iconImages[iconIndex];
		}
	}
	return '';
};

export const getIconSource = (fileExtension: string): string => {
	switch (fileExtension) {
		case Strings.DocumentFileExtensions.PowerPointX:
		case Strings.DocumentFileExtensions.PowerPointX:
			return PowerPointSvg;
		case Strings.DocumentFileExtensions.ExcelX:
		case Strings.DocumentFileExtensions.Excel:
			return ExcelSvg;
		case Strings.DocumentFileExtensions.WordX:
		case Strings.DocumentFileExtensions.Word:
			return WordSvg;
		case Strings.DocumentFileExtensions.Gif:
		case Strings.DocumentFileExtensions.Jpg:
		case Strings.DocumentFileExtensions.Jpeg:
		case Strings.DocumentFileExtensions.Png:
			return ImageSvg;
		case Strings.DocumentFileExtensions.Pdf:
			return PdfSvg
		default:
			return FileSvg;
	}
};
