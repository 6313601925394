import React from 'react';
import H from 'history';
import _ from 'lodash';
import { connect } from '@optum-uhone-hmkts/rise';
import { FormErrors, getFormSyncErrors, getFormValues, isValid } from 'redux-form';
import { AppState } from '../../reducers';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { SaveCancelHeaderBarComponent } from '../../components/Layout/SaveCancelHeaderBar';
import { NavigationProps } from '../../components/nav/Routes';
import { navigateBack } from '../../actions/navigation_actions';
import { GetEmployer, UpdateEmployer } from '../../actions/employer_actions';
import {
	FormData,
	FORM_NAME,
	UpsertEmployerForm,
} from './upsert_employer_form';
import { Employer } from '../../reducers/employer_reducer';
import moment from 'moment';
import { Loaded } from '../../utilities/utilities';
import { Strings } from '../../assets/common/strings';
import { get } from '../../utilities/object_util';
import { normalizeMoneyNumeric } from '../../utilities/formatting/string_normalization';
import { getSnackbarErrorProps } from '../../utilities/snackbar_util';
import { QueueSnackbar, SnackbarProps } from '../../actions/snackbar_actions';

interface ComponentProps { }

interface StateProps {
	formValues: FormData;
	isValid: boolean;
	syncErrors: FormErrors;
	anyTouched: boolean;
	userId: string;
	employer?: Loaded<Employer>;
}

interface DispatchProps {
	getEmployer: (id: string) => void;
	updateEmployer: (employer: Employer) => void;
	showSnackbar(snackbar: SnackbarProps): void;
}

type Props = ComponentProps & StateProps & DispatchProps & NavigationProps;

class UpsertEmployer extends React.Component<Props, {}> {
	componentWillMount() {
		const employerId = this.props.match.params.employerId;
		if (
			employerId &&
			employerId != Strings.Navigation.EmployerId &&
			employerId != '0' &&
			!this.props.employer
		)
			this.props.getEmployer(employerId);
	}

	onValidateAndSave = () => {
		if (this.props.isValid) {
			this.onSave();
		}
		else {
			this.showError()
		}
	}

	onSave = () => {
		if (this.props.employer) {
			let employerToEdit: Employer = Object.assign(
				{},
				this.props.employer.data
			);
			employerToEdit.companyName = this.props.formValues.companyName;
			employerToEdit.industry = this.props.formValues.industry;
			employerToEdit.website = this.props.formValues.companyWebsite;

			employerToEdit.isAncillariesOffered = this.props.formValues.isAncillariesOffered;
			employerToEdit.annualRevenue = Number(
				normalizeMoneyNumeric(this.props.formValues.annualRevenue)
			);
			(employerToEdit.numberOfEmployees = this.props.formValues.numberEmployees),
				(employerToEdit.numberOfPartTime = this.props.formValues.numberPartTime),
				(employerToEdit.numberOfGroupEligibles = this.props.formValues.numberGroupEligibles),
				(employerToEdit.numberOfGroupParticipants = this.props.formValues.numberGroupParticipants),
				(employerToEdit.currentCarrier = this.props.formValues.currentCarrier),
				(employerToEdit.employerContributionAmount = Number(
					normalizeMoneyNumeric(
						this.props.formValues.employerContributionAmount
					)
				));
			employerToEdit.employerContributionBaseAmount = Number(
				normalizeMoneyNumeric(
					this.props.formValues.employerContributionAmountBase
				)
			);
			employerToEdit.groupCoverageAdvantage = this.props.formValues.groupCoverageAdvantage;
			employerToEdit.isAge65OrAboveAllowed = this.props.formValues.isEmployeesAge65Above;
			employerToEdit.isEmployerContributesToGroupCoverage = this.props.formValues.isEmployerContributesGroupCoverage;
			employerToEdit.isFamilyCoverageOffered = this.props.formValues.isFamilyCoverageOffered;
			(employerToEdit.isGroupPlanOffered = this.props.formValues.isGroupPlanOffered),
				(employerToEdit.updatedBy = this.props.userId);
			employerToEdit.updatedOn = moment()
				.utc()
				.toDate();
			this.props.updateEmployer(employerToEdit);
			this.props.navigateBack();
		}
	};

	showError = () => {
		const errorKeys = Object.keys(this.props.syncErrors);
		if (errorKeys.length > 0) {
			const key = errorKeys.pop();
			const errorProps = getSnackbarErrorProps(key + ': ' + this.props.syncErrors[key!]);	
			this.props.showSnackbar(errorProps);
		}
	}

	createInitalValues() {
		const employer = this.props.employer!.data;
		return {
			companyName: employer.companyName,
			industry: employer.industry,
			companyWebsite: employer.website,
			isAncillariesOffered: employer.isAncillariesOffered,
			annualRevenue: `${employer.annualRevenue}`,
			numberEmployees: employer.numberOfEmployees,
			numberPartTime: employer.numberOfPartTime,
			numberGroupEligibles: employer.numberOfGroupEligibles,
			numberGroupParticipants: employer.numberOfGroupParticipants,
			currentCarrier: employer.currentCarrier,
			employerContributionAmount: `${employer.employerContributionAmount}`,
			employerContributionAmountBase: `${
				employer.employerContributionBaseAmount
				}`,
			groupCoverageAdvantage: employer.groupCoverageAdvantage,
			isEmployeesAge65Above: employer.isAge65OrAboveAllowed,
			isEmployerContributesGroupCoverage:
				employer.isEmployerContributesToGroupCoverage,
			isFamilyCoverageOffered: employer.isFamilyCoverageOffered,
			isGroupPlanOffered: employer.isGroupPlanOffered,
		};
	}

	render() {
		return (
			<BasePageContainer
				topComponent={
					<SaveCancelHeaderBarComponent
						title={`Edit Employer`}
						onSave={this.onValidateAndSave}
						isSaveDisabled={!this.props.anyTouched}
						history={this.props.history}
					/>
				}
				
			>
				<UpsertEmployerForm initialValues={this.createInitalValues()} />
			</BasePageContainer>
		);
	}
}

function mapStateToProps(
	state,
	ownProps: Props & StateProps
): StateProps {
	
	return {
		formValues: getFormValues(FORM_NAME)(state),
		isValid: isValid(FORM_NAME)(state),
		syncErrors: getFormSyncErrors(FORM_NAME)(state),
		anyTouched: get(() => state.form[FORM_NAME].anyTouched, false)!,
		userId: state.user.id,
		employer: state.employer.employers.find(employer => {
			return (
				employer.data.id == ownProps.match.params.employerID &&
				employer.errors.length == 0
			);
		}),
	};
}

function mapDispatchToProps(dispatch: any): DispatchProps & Partial<NavigationProps> {
	return {
		navigateBack: () => dispatch(navigateBack()),
		getEmployer: (id: string) => {
			dispatch(GetEmployer.started(id));
		},
		updateEmployer: (employer: Employer) => {
			dispatch(UpdateEmployer.started(employer));
		},
		showSnackbar: (snackbar: SnackbarProps) => dispatch(QueueSnackbar(snackbar)),
	};
}

export const UpsertEmployerContainer = connect(
	mapStateToProps,
	mapDispatchToProps, true
)(UpsertEmployer) as React.ComponentClass<Props>;
