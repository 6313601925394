import { ToggleDesktopView } from '../actions/layout_actions';
import { Action as ReduxAction } from 'redux';
import { isType } from 'typescript-fsa';
import { WindowResized, SetSideMenuBarState } from '../actions/layout_actions';

export interface LayoutState {
	width: number;
	height: number;
	desktop: boolean;
	isSideMenuVisible:boolean;
	
}

const initialState = {
	width: 0,
	height: 0,
	desktop: false,
	isSideMenuVisible:true
};

export function layoutReducer(
	state: LayoutState = initialState,
	action: ReduxAction
): LayoutState {
	if (isType(action, WindowResized)) {
		return {
			...state,
			width: action.payload.width,
			height: action.payload.height,
		};
	} else if (isType(action, ToggleDesktopView)) {
		return {
			...state,
			desktop: action.payload.desktop,
		};
	}else if (isType(action, SetSideMenuBarState)) {
		return {
			...state,
			isSideMenuVisible: action.payload.isVisible,
		}
	} else {
		return state;
	}
}
