// tslint:disable: align
import React from 'react';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import moment from 'moment';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { NavigationProps, navRoutes, getNavRouteByKey } from '../../components/nav/Routes';
import { compose } from 'redux';
import { connect } from '@optum-uhone-hmkts/rise';
import { GraphDataFilter } from '../../components/utility/graph_data_filter';
import {
	TeamViewParams,
	GetAllTeamViewData,
	GetTeamViewActivity,
	GetTeamViewLeads,
	GetTeamViewProduction,
	GetTeamViewQuality,
	NavigateToAgentPerformance,
	ShowChildren,
	SetTeamViewDownlineAgent
} from '../../actions/teamview_actions';
import { DownlineAgent } from '../../reducers/product_reducer';
import { navigateTo } from '../../actions/navigation_actions';
import { MoreMenu, createHelpAction } from '../../components/nav/more_menu';
import TableCard, { TableCardProps } from '../../components/teamview/teamview_table_card';
import { themeLinks } from '../../utilities/branding';
import { isMobileDevice } from '../../utilities/is_mobile';
import { Strings } from '../../assets/common/strings';
import { GraphDataFilterValues } from '../../reducers/performance_reducer';
import { findDownlineAgentRole, findAgentRole } from '../../utilities/agent_util';
import { NewAgentConversionReport, NewLeadsReports, ProfessioalAgentsReport, SummaryReport, SurebridgePersistencyReport, TwentySixtyTwentyReport, WritingAgentReport, YtdAvReports } from '../../components/productivity';
import { Agent } from '../../reducers/agent_reducer';

interface Props { }

interface StateProps {
	filter: GraphDataFilterValues;
	downlineAgent: DownlineAgent;
	activityTableProps: TableCardProps,
	leadTableProps: TableCardProps,
	productionTableProps: TableCardProps,
	qualityTableProps: TableCardProps,
	isDesktop?: boolean,
	agent: Agent,
}

interface DispatchProps {
	getAllTeamView: (params: TeamViewParams) => any;
	getActivityCard: (params: TeamViewParams) => any;
	getLeadCard: (params: TeamViewParams) => any;
	getProductionCard: (params: TeamViewParams) => any;
	getQualityCard: (params: TeamViewParams) => any;
	navigateTo(route: string): void;
	navigateToAgentPerformance: (agentCode: string, route: string) => void;
	setDownlineAgent: (agent?: DownlineAgent) => void;
	showChildren: (agentCode: string, card: string) => void;
}

interface TeamViewPageState {
	selectedTab: number;
 }

type TeamViewPageProps = Props &
	StateProps &
	DispatchProps &
	NavigationProps;

class TeamViewPage extends React.PureComponent<
	TeamViewPageProps,
	TeamViewPageState
	> {
	params: TeamViewParams;
	title = Strings.TeamView.TEAMVIEW;
	scrollRef: HTMLDivElement;

	state = {
		selectedTab: 0,
	}

	componentDidMount() {
		this.applyFilters();
	}

	componentDidUpdate(prevProps: TeamViewPageProps) {
		if (prevProps.downlineAgent.agentCode !==
			this.props.downlineAgent.agentCode) {
			this.resetTabs();
			this.applyFilters();
		}
		if (this.props.location.pathname !== navRoutes.teamview.path) {
			this.scrollRef.scrollTop = 0;
		}
	}

	resetTabs = () => {
		this.setState({
			selectedTab: 0,
		});
	}

	applyFilters = (
		formValues?: GraphDataFilterValues,
		formErrors?: any | undefined,
	) => {
		if (!!formErrors && Object.keys(formErrors).length > 0) {
			return;
		}
		if (!formValues) {
			formValues = this.props.filter;
		}

		let startDate: moment.Moment | Date = moment();
		let	endDate: moment.Moment | Date = moment();
		let updateDates = false;
		let	dateRangeOverride = 0;
		const utcOffset = moment().utcOffset();

		switch (formValues.dateRange) {
			case Strings.DateSelector.Last7Days: {
				startDate = startDate.subtract(6, 'days');
				break;
			}
			case Strings.DateSelector.Last30Days: {
				startDate = startDate.subtract(29, 'days');
				break;
			}
			case Strings.DateSelector.Last90Days: {
				startDate = startDate.subtract(89, 'days');
				break;
			}
			case Strings.DateSelector.MonthToDate: {
				startDate = startDate.startOf('month');
				break;
			}
			case Strings.DateSelector.QuarterToDate: {
				startDate = startDate.startOf('quarter');
				break;
			}
			case Strings.DateSelector.YearToDate: {
				startDate = startDate.startOf('year');
				break;
			}
			case Strings.DateSelector.LastMonth: {
				const lastMonth = startDate.subtract(1, 'month');
				startDate = lastMonth.clone().startOf('month');
				endDate = lastMonth.clone().endOf('month');
				break;
			}
			case Strings.DateSelector.LastQuarter: {
				const lastQuarter = startDate.subtract(1, 'quarter');
				startDate = lastQuarter.clone().startOf('quarter');
				endDate = lastQuarter.clone().endOf('quarter');
				break;
			}
			case Strings.DateSelector.LastYear: {
				const lastYear = startDate.subtract(1, 'year');
				startDate = lastYear.clone().startOf('year');
				endDate = lastYear.clone().endOf('year');
				break;
			}
			default: {
				startDate = formValues.startDate;
				endDate = formValues.endDate;
				updateDates = true;
			}
		}

		const { downlineAgent } = this.props;

		if (downlineAgent.agentRoles && findDownlineAgentRole(downlineAgent) === Strings.AgentRoles.Agent) {
			this.props.setDownlineAgent();
			return this.navigateToAgentPerformance(downlineAgent.agentCode);
		}

		const agentCode = downlineAgent.agentCode;

		const params: TeamViewParams = {
			agentCode,
			startDate: moment.isMoment(startDate) ? startDate.toDate() : startDate,
			endDate: moment.isMoment(endDate) ? endDate.toDate() : endDate,
			lineOfBusiness: formValues.lineOfBusiness || 'all',
			comparePrevious: formValues.comparePrevious || false,
			dateRange: formValues.dateRange,
			utcOffset,
			parentPage: Strings.TeamViewAPI.TEAMVIEW_API,
		};
		this.params = params;
		return this.props.getAllTeamView(params);
	}

	navigateToAgentPerformance = (agentCode: string) => {
		if (isMobileDevice) {
			this.props.navigateToAgentPerformance(agentCode, getNavRouteByKey('performance').path);
		} else {
			window.open(window.location.origin + '/#' + getNavRouteByKey('performance').path + '/' + agentCode, '_blank');
		}
	}

	requestAgentDownline = (agentCode: string, cardName: string) => {
		const params = this.params;
		params.agentCode = agentCode;

		switch (cardName) {
			case Strings.TeamView.ACTIVITIES:
				this.props.getActivityCard(params);
				break;
			case Strings.TeamView.LEAD_PERFORMANCE:
				this.props.getLeadCard(params);
				break;
			case Strings.TeamView.PRODUCTION:
				this.props.getProductionCard(params);
				break;
			case Strings.TeamView.QUALITY:
				this.props.getQualityCard(params);
				break;
			default:
				break;
		}
	}

	toggleTabs = (event: React.ChangeEvent<{}>, selectedTab: any) => {
		this.setState({selectedTab})
	}

	render() {
		const {
			filter,
			activityTableProps,
			leadTableProps,
			productionTableProps,
			qualityTableProps,
			showChildren,
			isDesktop,
			downlineAgent,
			agent,
		} = this.props;

		const onClickFunctions = {
			onClickCell: this.navigateToAgentPerformance,
			onClickRow: this.requestAgentDownline,
			showChildren,
		}

		const showSummary = findAgentRole(agent) === Strings.AgentRoles.SalesLead || (downlineAgent.agentRoles && findDownlineAgentRole(downlineAgent) === Strings.AgentRoles.SalesLead);
		const selectedTab = this.state.selectedTab === 0 && !showSummary ? 1 : this.state.selectedTab;

		return (
			<BasePageContainer
				topComponent={
					<HeaderBarComponent
						title={this.title}
						rightButtons={
							<MoreMenu children={[createHelpAction(themeLinks.helpLinkTeamView)]} />}
						customContent={
							<>
								<GraphDataFilter
									filterValues={filter}
									applyFilters={this.applyFilters}
									title={Strings.TeamView.TEAMVIEW}
									downlineAgent={this.props.downlineAgent}
									setDownlineAgent={this.props.setDownlineAgent}
									showLineOfBusinessField={true}
								/>
								<AppBar position="static" color="inherit">
									<Tabs
										value={selectedTab}
										onChange={this.toggleTabs}
										indicatorColor="primary"
										variant="fullWidth"
									>
										{showSummary && (
											<Tab label={isDesktop ? Strings.TeamView.SUMMARY_TAB : Strings.TeamView.SUMMARY_TAB_MOBILE} value={0} />
										)}
										<Tab label={isDesktop ? Strings.TeamView.LEADS_TAB : Strings.TeamView.LEADS_TAB_MOBILE} value={1} />
										<Tab label={isDesktop ? Strings.TeamView.RECRUITING_TAB : Strings.TeamView.RECRUITING_TAB_MOBILE} value={2} />
										<Tab label={isDesktop ? Strings.TeamView.SALES_TAB : Strings.TeamView.SALES_TAB_MOBILE} value={3} />
									</Tabs>
								</AppBar>
							</>
						}
					/>
				}
				middleRef={ref => (this.scrollRef = ref)}
				middleComponent={
					<div style={styles.middleComponent}>
						{selectedTab === 0 && (
							<SummaryReport
								params={this.params}
							/>)}
						{selectedTab === 1 && (
							<>
								<TableCard
									key={`card-activity`}
									{...activityTableProps}
									{...onClickFunctions}
								/>
								<TableCard
									key={`card-lead`}
									{...leadTableProps}
									{...onClickFunctions}
								/>
								{this.params && (
									<NewLeadsReports
										params={this.params}
									/>)}
							</>)}
						{selectedTab === 2 && (
							<>
								<NewAgentConversionReport
									params={this.params}
								/>
								<WritingAgentReport
									params={this.params}
								/>
								<ProfessioalAgentsReport
									params={this.params}
								/>
							</>)}
        				{selectedTab === 3 && (
							<>
								<TableCard
									key={`card-production`}
									{...productionTableProps}
									{...onClickFunctions}
								/>
								<TableCard
									key={`card-quality`}
									{...qualityTableProps}
									{...onClickFunctions}
								/>
								<YtdAvReports
									params={this.params}
									showSLStats={showSummary}
								/>
								<TwentySixtyTwentyReport
									params={this.params}
								/>
								<SurebridgePersistencyReport
									params={this.params}
									showSLStats={showSummary}
								/>
							</>)}
					</div>}
			/>
		);
	}
}

const styles: { [key: string]: React.CSSProperties } = {
	middleComponent: {
		margin: '10px',
	},
};

const mapStateToProps = (state: any): StateProps => {
	const defaultCardProps = {
		maxRows: 5,
		style: { marginBottom: 20 },
		clickDisabled: true,
	}

	const activityTableProps: TableCardProps = {
		...defaultCardProps,
		color: 'primary',
		titleLeft: Strings.TeamView.ACTIVITIES,
		rows: state.teamview.activityCard.cardTable,
		loading: state.teamview.activityCard.isLoading,
	}

	const leadTableProps: TableCardProps = {
		...defaultCardProps,
		color: 'tertiary',
		titleLeft: Strings.TeamView.LEAD_PERFORMANCE,
		rows: state.teamview.leadCard.cardTable,
		loading: state.teamview.leadCard.isLoading,
	}

	const productionTableProps: TableCardProps = {
		...defaultCardProps,
		color: 'tertiary',
		titleLeft: Strings.TeamView.PRODUCTION,
		rows: state.teamview.productionCard.cardTable,
		loading: state.teamview.productionCard.isLoading,
	}

	const qualityTableProps: TableCardProps = {
		...defaultCardProps,
		color: 'primary',
		titleLeft: Strings.TeamView.QUALITY,
		rows: state.teamview.qualityCard.cardTable,
		loading: state.teamview.qualityCard.isLoading,
	}

	return {
		filter: state.teamview.filter,
		downlineAgent: state.teamview.downlineAgent,
		activityTableProps,
		leadTableProps,
		productionTableProps,
		qualityTableProps,
		isDesktop: state.layout.desktop,
		agent: state.agent,
	};
};
const mapDispatchToProps = (dispatch: any): DispatchProps => ({
	getAllTeamView: (params: TeamViewParams) =>
		dispatch(GetAllTeamViewData(params)),
	getActivityCard: (params: TeamViewParams) =>
		dispatch(GetTeamViewActivity.started(params)),
	getLeadCard: (params: TeamViewParams) =>
		dispatch(GetTeamViewLeads.started(params)),
	getProductionCard: (params: TeamViewParams) =>
		dispatch(GetTeamViewProduction.started(params)),
	getQualityCard: (params: TeamViewParams) =>
		dispatch(GetTeamViewQuality.started(params)),
	navigateTo: (route: string) =>
		dispatch(navigateTo(route)),
	navigateToAgentPerformance: (agentCode: string, route: string) =>
		dispatch(NavigateToAgentPerformance({ agentCode, route })),
	setDownlineAgent: (agent?: DownlineAgent) =>
		dispatch(SetTeamViewDownlineAgent({ agent })),
	showChildren: (agentCode: string, card: string) =>
        dispatch(ShowChildren({ agentCode, card })),
});

export const TeamViewContainer = compose(
	connect(mapStateToProps, mapDispatchToProps, true),
)(TeamViewPage);
