// tslint:disable: align
import Highcharts from 'highcharts';
import React from 'react';
import { StackedGraphDataPoint } from '../../reducers/teamview_reducer';
import { themePalette } from '../../utilities/branding';
import { isMobileDevice } from '../../utilities/is_mobile';

const MAX_INTERVAL = 50000;

interface Props {
    title?: string;
	unit?: string;
    style?: React.CSSProperties;
    data: StackedGraphDataPoint[];
    chartSettings?: any;
}

class ChartStackedBar extends React.PureComponent<Props, any> {
    chartRef: any;

    componentDidMount() {
		this.updateChart();
	}

    componentDidUpdate() {
		this.updateChart();
	}

    getInterval(value: number): number {
		if (value <= 100) {
			return 25;
		}
		if (value <= 500) {
			return 50;
		}
		if (value <= 1000) {
			return 100;
		}
		if (value <= 5000) {
			return 500;
		}
		if (value <= 10000) {
			return 1000;
		}
		if (value <= 100000) {
			return 10000;
        }
        if (value <= 200000) {
			return 20000;
		}
		return MAX_INTERVAL;
	}

    getMaxValue = (data: StackedGraphDataPoint[]) => {
		if (!data || !data.length) {
			return 0;
		}
		const max = data.reduce(
			(maxVal, val) => {
                const total: number = val.value1 + val.value2;
                return (total > maxVal ? total : maxVal)
            },
			data[0].value1 + data[0].value2,
		);
		return Math.ceil(max);
    };

    getWeeksForXaxis = () => {
        return Array.from(Array(52), (_, i) => i + 1);
    }

    getYTDSeries1 = () => {
        const seriesData = new Array(52).fill(0);
        this.props.data.forEach(x => {
            seriesData[x.week - 1] = x.value1;
        })
        return seriesData;
    };

    getYTDSeries2 = () => {
        const seriesData = new Array(52).fill(0);
        this.props.data.forEach(x => {
            seriesData[x.week - 1] = x.value2;
        })
        return seriesData;
    };

    getDataTotalAverage = () => (
        this.props.data.reduce((sum, item) => sum + item.value1 + item.value2, 0) / this.props.data.length
    );

    updateChart() {
        const { data, chartSettings } = this.props;
        const settings = {
			...defaultChartSettings,
			...chartSettings,
			tooltip: {
                enabled: !isMobileDevice,
                dataLabels: {
                    enabled: true,
                },
                formatter(): string {
                    // tslint:disable-next-line: no-this-assignment
                    const self: any = this;
                    return `week ${self.x}<br/>${self.series.name} : $${(self.y).toLocaleString('en')}`;
                },
            },
		};

        const yAxisMax = this.getMaxValue(data) || 100000;
        const tickInterval = this.getInterval(yAxisMax);
        const dataValue1 = this.getYTDSeries1();
        const dataValue2 = this.getYTDSeries2();
        const dataTotalAverage = this.getDataTotalAverage();

        const updatedChartSettings = {
			series: [
                {
                    name: 'SL Only',
                    data: dataValue2,
                    color: '#009FDA',
				},
				{
                    name: 'Team',
                    data: dataValue1,
                    color: '#69BE28',
                },
                {
                    // Series that mimics the plot line
                    name: 'AVG',
                    type: 'line',
                    marker: {
                        enabled: false,
                    },
                    color: 'red',
                },
			],
			...settings,
			xAxis: {
                ...settings.xAxis,
                categories: this.getWeeksForXaxis(),
                tickLength: 0,
			},
			yAxis: {
                ...settings.yAxis,
                min: 0,
                max: yAxisMax * 1.1,
				tickInterval,
				labels: {
					formatter(): string {
                        // tslint:disable-next-line: no-this-assignment
                        const self: any = this;
                        const value = self.value / 1000;
                        return `$${value}`;
                    },
					style: defaultChartSettings.labels.style,
                },
                plotLines: [{
                    color: 'red',
                    value: dataTotalAverage,
                    width: 2,
                  }],
            },
		};
        Highcharts.chart(this.chartRef, updatedChartSettings);
	}

    render() {
		const { style = {} } = this.props;
		return (
			<div
				ref={chart => (this.chartRef = chart)}
				style={{ ...defaultStyle, ...style }}
			/>
		);
	}
}

const defaultStyle: React.CSSProperties = {
	height: 280,
	overflow: 'hidden',
};
const defaultChartSettings = {
    chart: {
        type: 'column',
    },
    title: {
        text: undefined,
    },
    plotOptions: {
        column: {
            stacking: 'normal',
            dataLabels: {
                enabled: false,
            },
        },
        series: {
            pointPadding: 0,
            groupPadding: 0,
            animation: false,
        },
    },
    legend: {
        align: 'right',
        x: -30,
        verticalAlign: 'top',
        y: -10,
        floating: true,
        backgroundColor: 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false,
    },
    xAxis: {
        title: {
            enabled: true,
            text: 'Week',
        },
    },
    yAxis: {
        title: {
            enabled: false,
        },
    },
	labels: {
		style: {
			color: 'black',
			opacity: 1,
		},
    },
    credits: {
		enabled: false,
	},
};

export default ChartStackedBar;
