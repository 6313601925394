import { Box, Button, Checkbox, Collapse, IconButton, MenuItem, TableCell, TableRow, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { TableData, TableHeader } from './data_table';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { isMobileDevice } from '../../../utilities/is_mobile';

interface Props<T extends TableData> {
    data: T;
    headers: TableHeader<T>[];
    expandable?: boolean;
    renderExpanded?: (obj: T) => JSX.Element;
}
export const DataRow: React.FC<Props<TableData>> = (props) => {

    const { data, headers, expandable, renderExpanded } = props;
    const [open, setOpen] = useState(false);

    const toggleOpen = () => setOpen(open => !open);

    const renderDropdown = (header: TableHeader<TableData>) => {
        return (
            <TextField
                select
                value={data[header.key]}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => header.onOptionChange && header.onOptionChange(data.id, event.target.value)}
                SelectProps={{ native: isMobileDevice }}
            >
                {header?.options?.map(option => (
                    isMobileDevice
                        ? <option key={'selection1-' + option} value={option}>{option}</option>
                        : <MenuItem key={'selection1-' + option} value={option}>{option}</MenuItem>
                ))}
            </TextField>
        );
    };

    const renderCheckbox = (header: TableHeader<TableData>) => {
        return (
            <Checkbox
                checked={data[header.key]}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { 
                    event.stopPropagation();
                    const { checked } = event.target;
                    header.onCheck && header.onCheck(data, checked);
                }}
            />
        );
    };

    const renderButton = (header: TableHeader<TableData>) => {
        const { buttonText } = header;
        return (
            <Button color="primary">{buttonText || 'Action'}</Button>
        );
    };

    return (
        <>
            <TableRow>
                {expandable && <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={toggleOpen}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>}
                {
                    headers.map(header => {

                        const value = (data[header.key] || '').toString();
                        let component: React.ReactNode;
                        if (header.fieldType === 'dropdown') {
                            component = renderDropdown(header);
                        }
                        else if (header.fieldType === 'readonly') {
                            component = header.action
                                ? (
                                    <span
                                        style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}
                                        onClick={() => header.action!(data)}
                                    >
                                        {value}
                                    </span>
                                )
                                : value;
                        }
                        else if (header.fieldType === 'checkbox') {
                            component = renderCheckbox(header);
                        }
                        else if (header.fieldType === 'button') {
                            component = renderButton(header);
                        }
                        else if (header.fieldType === 'custom' && header.renderCustom) {
                            component = header.renderCustom(data);
                        }
                        return (
                            <TableCell key={header.key}>{component}</TableCell>
                        );
                    })
                }
            </TableRow>
            {expandable && renderExpanded && <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={headers.length + 1}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box margin={1}>
                        {renderExpanded(data)}
                    </Box>
                </Collapse>
                </TableCell>
            </TableRow>}
        </>
    );
};