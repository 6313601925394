import React from 'react';
import { Activity } from '../../reducers/activity_reducer';
import moment from 'moment';
import { Loaded } from '../../utilities/utilities';
import { CompletedActivityItem } from '../../containers/activity/list/completed_activity_item';
import { wrapInCollapsibleCard } from '../higher_order_component/wrap_in_card';
import { Strings } from '../../assets/common/strings';
import CustomList from '../utility/custom_list';
import { SimpleListItem } from '../utility/simple_list_item';

interface Props {
	completedActitvites: Loaded<Activity>[];
	handleClick: (activity: Activity) => void;
	handleDeleteClick: (activity: Activity) => void;
}
const CompletedActivityList: React.FC<Props> = (props) => {
	const callCount = props.completedActitvites.filter(act => act.data.type == Strings.Activity.PhoneCall).length;
	const activities: JSX.Element[] = props.completedActitvites
		.filter(activity => {
			return (
				(activity.data.status || '').toLowerCase() ===
				Strings.ActivityStatus.Completed &&
				(activity.data.type === Strings.Activity.Appointment ||
					activity.data.type === Strings.Activity.PhoneCall ||
					activity.data.type === Strings.Activity.Email ||
					activity.data.type === Strings.Activity.LeadStatusChange ||
					activity.data.type === Strings.Activity.Quoted ||
					activity.data.type === Strings.Activity.Sms || 
					activity.data.type === Strings.Activity.FactFinder)
			);
		})
		.sort((a, b) => {
			return (
				moment(b.data.completedOn).valueOf() -
				moment(a.data.completedOn).valueOf()
			);
		}).map(activity => (
			<CompletedActivityItem
				key={activity.data.id}
				activity={activity.data}
				handleNavigateToActivity={props.handleClick}
				handleDeleteActivity={props.handleDeleteClick}
			/>
		));

	if (callCount > 0) {
		activities.unshift(<SimpleListItem subtitle={`Calls attempted: ${callCount}`} divider={false} />);
	}
	return (
		<CustomList emptyMessage="You have no completed activities">
			{activities}
		</CustomList>
	);
};

export const CompletedActivityListCard = wrapInCollapsibleCard(CompletedActivityList);
