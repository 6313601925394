import React, { useEffect } from 'react';
import { Snackbar } from '@material-ui/core';
import { AppState } from '../../reducers/index';
import { connect, Dispatch, Alert } from '@optum-uhone-hmkts/rise';
import { NextSnackbar as nextSnackbar, SnackbarProps } from '../../actions/snackbar_actions';
import { ActionCreator, bindActionCreators } from 'redux';

interface StateProps {
	open: boolean;
	current?: SnackbarProps;
}

interface DispatchProps {
	nextSnackbar: ActionCreator<void>;
}

type Props = StateProps & DispatchProps;



const SnackbarQueue: React.FC<Props> = (props: Props) => {

	const handleSnackbarTimeout = () => {
		if (!!props.current) {
			setTimeout(() => {
				props.nextSnackbar()
			}, props.current.autoHideDuration)
		}
	}

	useEffect(handleSnackbarTimeout, [props]);

	const handleClose = (event: React.SyntheticEvent | MouseEvent, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		props.nextSnackbar();
	};

	return (
		<Snackbar
			open={props.open}
			onClose={handleClose}
			autoHideDuration={props.current?.autoHideDuration || 4000}
		>
			<Alert
				severity={props.current?.severity}
				variant={'standard'}
				onClose={handleClose}
				action={
					props.current?.action?.length  
						? <React.Fragment>
							{
								props.current?.action?.map(a => a)
							}
						</React.Fragment>
						: undefined
				}>
				{props.current?.text}
			</Alert>
		</Snackbar>
	);
};

const mapStateToProps = (state: AppState): StateProps => ({
	open: state.snackbar.open,
	current: state.snackbar.current,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => bindActionCreators({
	nextSnackbar,
}, dispatch);

export const SnackbarComponent = connect(mapStateToProps, mapDispatchToProps, true)(
	SnackbarQueue
);