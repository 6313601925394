import actionCreatorFactory from 'typescript-fsa';
import { Podcast, PodcastFilter, PodcastTracking } from '../reducers/podcast_reducer';

const actionCreator = actionCreatorFactory('Lead');

export const CreatePodcast = actionCreator.async<Podcast, Podcast>('CREATE_PODCAST');
export const UpdatePodcast = actionCreator.async<Podcast, Podcast>('UPDATE_PODCAST');
export const FilterPodcasts = actionCreator.async<PodcastFilter, Podcast[]>('FILTER_PODCASTS');
export const UpdateTracking = actionCreator.async<PodcastTracking, PodcastTracking>('UPDATE_TRACKING');
export const ChangePodcast = actionCreator<Podcast | undefined>('CHANGE_PODCAST');
export const GetPodcastById = actionCreator.async<string, Podcast>('GET_PODCAST_BY_ID');
export const DeletePodcastById = actionCreator.async<string, boolean>('DELETE_PODCAST_BY_ID');
export const TogglePlayer = actionCreator<boolean>('TOGGLE_PLAYER');