import React from 'react';
import H from 'history';
import _ from 'lodash';
import uuid from 'uuid';
import moment from 'moment';
import { connect } from '@optum-uhone-hmkts/rise';
import { FormErrors, getFormSyncErrors, getFormValues, isValid } from 'redux-form';
import { AppState } from '../../reducers';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { SaveCancelHeaderBarComponent } from '../../components/Layout/SaveCancelHeaderBar';
import { NavigationProps } from '../../components/nav/Routes';
import { navigateTo, navigateBack } from '../../actions/navigation_actions';
import {
	Contact,
	HouseholdRole,
	Address,
	Phone,
	Email,
	HouseholdAddress,
} from '../../reducers/ContactReducer';
import {
	GetContact,
	ResetApplyAddressToHousehold,
	UpdateContact
} from '../../actions/contact_actions';
import { UpsertContactForm, ContactData, FORM_NAME } from './upsert_contact_form';
import { GetHouseholdContacts } from '../../actions/household_actions';
import { Strings } from '../../assets/common/strings';
import { normalizeMoneyNumeric } from '../../utilities/formatting/string_normalization';
import { ContactDedupeDto } from '../../reducers/dedupe_reducer';
import { ContactDedupeDialog } from '../../components/contact/contact_dedupe_dialog';
import { ContactDedupeCheck } from '../../actions/dedupe_actions';
import { getSnackbarErrorProps } from '../../utilities/snackbar_util';
import { QueueSnackbar, SnackbarProps } from '../../actions/snackbar_actions';

interface StateProps {
	formValues: ContactData;
	anyTouched: boolean;
	isValid: boolean;
	syncErrors: FormErrors;
	contact?: Contact;
	contacts: Contact[];
	primaryAddress?: Address;
	primaryPhone?: Phone;
	userId: string;
	spousePartnerContact?: Contact;
	isEmployee: boolean;
	isNewContact: boolean;
	impersonatingId: string;
	isLoading: boolean;
	householdAddresses: HouseholdAddress;
}

interface DispatchProps {
	getContact(id: string): void;
	getHouseholdContacts: (householdId: string) => void;
	updateContact(contact: Contact): void;
	dedupeCheck(dto: ContactDedupeDto): void;
	resetApplyHouseholdAddresses: () => void;
	showSnackbar(snackbar: SnackbarProps): void;
}

type Props = StateProps & DispatchProps & NavigationProps;

interface State {
	contact?: Contact;
}

class UpsertContact extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {};
	}

	componentWillMount() {
		if (
			!this.props.isEmployee &&
			this.props.match.params.householdID != Strings.Navigation.HouseholdId
		) {
			this.props.getHouseholdContacts(this.props.match.params.householdID);
		}

		if (!this.props.contact && !this.props.isNewContact)
			this.props.getContact(this.props.match.params.contactID);
	}

	onValidateAndSave = () => {
		if (this.props.isValid) {
			this.onSave();
		}
		else {
			this.showError()
		}
	}

	onSave = () => {
		const parentAttributes: any = {};
		if (this.props.isEmployee)
			parentAttributes.employerId = this.props.match.params.employerID;
		else parentAttributes.householdId = this.props.match.params.householdID;

		let emails = this.props.formValues.emails;
		let phones = this.props.formValues.phones;
		const validNewEmail = (this.props.formValues.email || '').length > 0;
		const validNewPhone = (this.props.formValues.phone || '').length > 0;
		const validNewEntry = validNewEmail || validNewPhone;

		if (validNewEntry) {
			if (validNewEmail) {
				emails = (this.props.formValues.emails || []).slice();
				let email: Email = {
					emailAddress: this.props.formValues.email as string,
					type: 'Home',
					isPreferred: true,
					dateCreated: moment().toDate(),
					doNotEmail: false,
				};
				if (emails.length) {
					email.isPreferred = false;
				}
				emails.push(email);
			}
			if (validNewPhone) {
				phones = (this.props.formValues.phones || []).slice();
				let phone: Phone = {
					id: uuid.v4(),
					number: this.props.formValues.phone as string,
					type: Strings.PhoneTypes.Home,
					isPreferred: true,
					isCompanyDnc: false,
					wasCompanyDncSet: false,
					isNationalDnc: false,
					wasRpmPhoneNumberChanged: false,
					extension: '',
					dateCreated: moment().toDate(),
					isSmsDnc: false
				};
				if (phones.length) {
					// not the first phone
					phone.isPreferred = false;
				}
				phones.push(phone);
			}
		}

		const heightFtStr = (this.props.formValues?.heightFt?.toString() || '').split(' ');
		const heightInStr = (this.props.formValues?.heightIn?.toString() || '').split(' ');
		const heightFt = heightFtStr[0] ? parseInt(heightFtStr[0], 10) : undefined;
		const heightIn = heightInStr[0] ? parseInt(heightInStr[0], 10) : undefined;

		const contactToSend: Contact = {
			...this.props.contact,
			id: this.props.contact ? this.props.contact.id : uuid.v4(),
			phones: phones,
			emails: emails,
			addresses: this.props.formValues.addresses,
			salutation: this.props.formValues.salutation,
			jobTitle: this.props.formValues.jobTitle,
			firstName: this.props.formValues.firstName,
			preferredName: this.props.formValues.preferredName,
			middleName: this.props.formValues.middleName,
			lastName: this.props.formValues.lastName,
			suffix: this.props.formValues.suffix,
			wasRpmNameChanged: this.props.formValues.wasRpmNameChanged || false,
			birthDate: this.props.formValues.DOB
				? moment.utc(this.props.formValues.DOB, 'MM/DD/YYYY').toDate()
				: null,
			gender: this.props.formValues.gender,
			annualIncome: Number(
				normalizeMoneyNumeric(this.props.formValues.annualIncome)
			),
			familyStatusCode: this.props.formValues.maritalStatus,
			occupation: this.props.formValues.occupation,
			isSelfEmployed: this.props.formValues.isSelfEmployed,
			isTobaccoUser: this.props.formValues.isTobaccoUser,
			status: this.props.formValues.status,
			heightFt,
			heightIn,
			weightLbs: this.props.formValues.weight,
			isStudent: this.props.formValues.isStudent,
			languagePreference: this.props.formValues.languagePreference,
			employeeStatus: this.props.formValues.employeeStatus,
			groupEligibilityStatus: this.props.formValues.groupEligibilityStatus,
			groupEnrollmentStatus: this.props.formValues.groupEnrollmentStatus,
			clientType: this.props.formValues.clientType,
			attachments: [],
			notes: [],
			userId: !this.props.isNewContact && this.props.contact
				? this.props.contact.userId
				: this.props.impersonatingId || this.props.userId,
			householdRole:
				this.props.isNewContact && this.props.isEmployee
					? HouseholdRole.Primary
					: this.props.formValues.householdRole,
			...parentAttributes,
			lastFourSsn: this.props.formValues.lastFourSsn,
			lastUnemploymentYear: this.props.formValues.lastUnemploymentYear,
		};
		this.handleUpsertContact(contactToSend);

		const householdAddresses = Object.keys(this.props.householdAddresses)
			.map(key => this.props.householdAddresses[key])
			.filter(Boolean) as Address[];
		if (householdAddresses.length) {
			this.props.contacts.forEach(contact => {
				if (contact.id !== this.props.contact?.id) {
					this.props.updateContact({
						...contact,
						// Get the addresses that don't match
						addresses: (contact.addresses || []).filter(a => !householdAddresses.some(ha => ha.id === a.id)).concat(householdAddresses)
					});
				}
			});
		}
		this.props.resetApplyHouseholdAddresses();
	};

	onCancel = () => {
		this.props.resetApplyHouseholdAddresses();
		this.props.navigateBack();
	};

	showError = () => {
		const errorKeys = Object.keys(this.props.syncErrors);
		if (errorKeys.length > 0) {
			const key = errorKeys.pop();
			const errorProps = getSnackbarErrorProps(key + ': ' + this.props.syncErrors[key!]);	
			this.props.showSnackbar(errorProps);
		}
	}

	handleUpsertContact = (contact: Contact) => {
		if (
			this.props.spousePartnerContact &&
			contact.householdRole == HouseholdRole.Secondary
		) {
			const updatedSpouseContact = { ...this.props.spousePartnerContact };
			updatedSpouseContact.householdRole = HouseholdRole.Dependent;
			this.props.updateContact(updatedSpouseContact);
		}
		if (this.props.isNewContact) {
			this.setState(
				{ contact },
				() => this.dedupeCheck(contact, () => this.props.navigateBack())
			);
		} else {
			this.props.updateContact(contact);
			this.props.navigateBack();
		}
	};

	dedupeCheck = (contact: Contact, callback?: () => void) => {
		const dto = {
			phones: contact.phones ? contact.phones.map(phone => phone.number.replace(/\D/g, '')) : [],
			emails: contact.emails ? contact.emails.map(email => email.emailAddress) : [],
			callback
		}
		this.props.dedupeCheck(dto);
	}

	createInitalValues = () => {
		const primaryContacts = this.props.contacts.filter((contact) => contact.householdRole == HouseholdRole.Primary)
		const phones = this.props.primaryPhone ? [this.props.primaryPhone] : [];
		const address = this.props.primaryAddress
			? [this.props.primaryAddress]
			: [];
		if (this.props.contact) {
			const initialValues: ContactData = {
				emails: this.props.contact.emails,
				phones: this.props.contact.phones,
				addresses: this.props.contact.addresses,
				firstName: this.props.contact.firstName,
				lastName: this.props.contact.lastName,
				jobTitle: this.props.contact.jobTitle,
				salutation: this.props.contact.salutation,
				preferredName: this.props.contact.preferredName,
				middleName: this.props.contact.middleName,
				suffix: this.props.contact.suffix,
				DOB: this.props.contact.birthDate
					? moment.utc(this.props.contact.birthDate).format('MM/DD/YYYY')
					: '',
				gender: this.props.contact.gender,
				maritalStatus: this.props.contact.familyStatusCode,
				heightFt: this.props.contact.heightFt,
				householdRole: this.props.contact.householdRole,
				heightIn: this.props.contact.heightIn,
				weight: this.props.contact.weightLbs,
				annualIncome: this.props.contact.annualIncome ? `${this.props.contact.annualIncome}` : '',
				occupation: this.props.contact.occupation,
				languagePreference: this.props.contact.languagePreference,
				isTobaccoUser: this.props.contact.isTobaccoUser,
				status: this.props.contact.status,
				isStudent: this.props.contact.isStudent,
				isSelfEmployed: this.props.contact.isSelfEmployed,
				employeeStatus: this.props.contact.employeeStatus,
				groupEligibilityStatus: this.props.contact.groupEligibilityStatus,
				groupEnrollmentStatus: this.props.contact.groupEnrollmentStatus,
				lastFourSsn: this.props.contact.lastFourSsn,
				wasRpmNameChanged: false,
				clientType: this.props.contact.clientType,
				lastUnemploymentYear: this.props.contact.lastUnemploymentYear,
			};
			return initialValues;
		} else {
			return {
				emails: primaryContacts.length && !this.props.isEmployee
					? primaryContacts[0].emails.filter(email => email.isPreferred)
					: [],
				phones: primaryContacts.length && !this.props.isEmployee
					? primaryContacts[0].phones.filter(phone => phone.isPreferred)
					: phones,
				addresses: primaryContacts.length && !this.props.isEmployee
					? primaryContacts[0].addresses.filter(address => address.isPreferred)
					: address,
				firstName: '',
				lastName: '',
				jobTitle: '',
				salutation: '',
				preferredName: '',
				middleName: '',
				suffix: '',
				DOB: '',
				gender: '',
				maritalStatus: '',
				annualIncome: '',
				occupation: '',
				languagePreference: '',
				employeeStatus: '',
				groupEligibilityStatus: '',
				groupEnrollmentStatus: '',
				householdRole: HouseholdRole.Dependent,
				lastFourSsn: '',
				status: 'Active',
			};
		}
	};

	render() {
		const { emails, phones, firstName, lastName } = this.props.formValues || {
			emails: [],
			phones: [],
			firstName: '',
			lastName: '',
		};
		const contactInfoCheck =
			(emails || []).length + (phones || []).length === 0 ||
			!firstName ||
			!lastName;
		const householdRoleCheck = this.props.contact
			? this.props.formValues &&
			this.props.formValues.householdRole !== this.props.contact.householdRole
			: false;
		const isSaveDisabled =
			!(this.props.anyTouched || householdRoleCheck) || 
			contactInfoCheck;
		const topComponent = (
			<SaveCancelHeaderBarComponent
				title={`${this.props.isNewContact ? 'New' : 'Edit'} ${
					this.props.isEmployee ? 'Employee' : 'Person'
					}`}
				onSave={this.onValidateAndSave}
				isSaveDisabled={isSaveDisabled}
				history={this.props.history}
				isLoading={this.props.isLoading}
				onCancel={this.onCancel}
			/>
		);

		return (
			<BasePageContainer
				topComponent={topComponent}
				
			>
				<ContactDedupeDialog
					contact={this.state.contact}
					history={this.props.history}
				/>
				<UpsertContactForm
					initialValues={this.createInitalValues()}
					isEmployee={this.props.isEmployee}
					primaryAddress={this.props.primaryAddress}
					contact={this.props.contact}
					contacts={this.props.contacts}
					isNewContact={this.props.isNewContact}
				/>
			</BasePageContainer>
		);
	}
}

function mapStateToProps(
	state: AppState,
	ownProps: Props
): Partial<Props> {


	const contactId = ownProps.match.params.contactID;
	const isNewContact = contactId == 'new';
	const householdId = (ownProps.match.params.householdID || '').replace(
		Strings.Navigation.HouseholdId,
		''
	);
	const employerId = (ownProps.match.params.employerID || '').replace(
		Strings.Navigation.EmployerId,
		''
	);
	let contacts: Contact[] = [];
	if (employerId)
		contacts = state.contact.contacts
			.filter(loadedContact => loadedContact.employerId == employerId)
			.map(loadedContact => loadedContact.data);
	else if (householdId)
		contacts = state.contact.contacts
			.filter(loadedContact => loadedContact.householdId == householdId)
			.map(loadedContact => loadedContact.data);

	// Get Contact info
	const contact = contacts.find(_contact => _contact.id == contactId);

	// Get Employer/Household primary address
	const primaryContact = contacts.find(_contact => {
		if (employerId) return _contact.employerPrimaryContact;
		else return _contact.householdRole == HouseholdRole.Primary;
	});
	let primaryPhone: Phone | undefined = undefined;
	let primaryAddress: Address | undefined = undefined;
	if (primaryContact && !isNewContact) {
		primaryPhone = primaryContact.phones.find(_phone => _phone.isPreferred);
		primaryAddress = primaryContact.addresses.find(_addr => _addr.isPreferred);
	}

	let spousePartnerContact: Contact | undefined = undefined;
	if (householdId) {
		spousePartnerContact = contacts.find(
			_contact =>
				_contact.householdRole === HouseholdRole.Secondary &&
				_contact.id !== contactId
		);
	}

	const contactData = getFormValues(FORM_NAME)(state) as ContactData;

	return {
		contact,
		contacts,
		primaryAddress,
		primaryPhone,
		formValues: contactData,
		anyTouched: _.get(state, `form.${FORM_NAME}.anyTouched`, false),
		isValid: isValid(FORM_NAME)(state),
		syncErrors: getFormSyncErrors(FORM_NAME)(state),
		userId: state.user.id,
		spousePartnerContact,
		isEmployee: !!employerId,
		isNewContact,
		impersonatingId: state.user.impersonatingId,
		isLoading: state.dedupe.loading,
		householdAddresses: state.contact.householdAddresses,
	};
}

function mapDispatchToProps(dispatch: any): DispatchProps & Partial<NavigationProps> {
	return {
		navigateTo: (route: string) =>
			dispatch(navigateTo(route)),
		navigateBack: () => dispatch(navigateBack()),
		getContact: (id: string) => dispatch(GetContact.started(id)),
		updateContact: (contact: Contact) =>
			dispatch(UpdateContact.started(contact)),
		getHouseholdContacts: (householdId: string) =>
			dispatch(GetHouseholdContacts.started(householdId)),
		dedupeCheck: (dto: ContactDedupeDto) => dispatch(ContactDedupeCheck.started(dto)),
		resetApplyHouseholdAddresses: () => dispatch(ResetApplyAddressToHousehold()),
		showSnackbar: (snackbar: SnackbarProps) => dispatch(QueueSnackbar(snackbar)),
	};
}

export const UpsertContactContainer = connect(
	mapStateToProps,
	mapDispatchToProps, true
)(UpsertContact) as React.ComponentClass<StateProps>;
