import { Box, Button, Grid } from '@material-ui/core';
import React from 'react';
import ReactPlaceholder from 'react-placeholder';
import { connect } from '@optum-uhone-hmkts/rise';
import {
	GetHomeStatusCounts,
	StoreLeadFilters,
	GetFilteredLeads,
} from '../../actions/lead_actions';
import { navigateTo } from '../../actions/navigation_actions';
import {
	ProductFilterPayload,
	SearchProducts,
	ToggleFilterDrawer,
} from '../../actions/product_actions';
import { navRoutes } from '../nav/Routes';
import { PaperIcon } from '../../components/utility/PaperIcon';
import { LeadFilters } from '../../reducers/LeadFilterReducer';
import { HomeLeadStatuses } from '../../reducers/LeadReducer';
import { initialState as FilterInitialState } from '../../reducers/product_filter_reducer';
import { Widget } from './base_widget';
import {
	formatLeadFiltersToStore,
	renderLeadCount,
	formatLeadFiltersPayload,
} from '../../utilities/lead_util';
import { Lookup, Lookups } from '../../utilities/lookup';
import CrmSvg from '../svgs/icons/crm';
import { themePalette, themeImages } from '../../utilities/branding';
import { nav } from '../..';
import { Strings } from '../../assets/common/strings';
import { Dispatch } from '@reduxjs/toolkit';
import { AppState } from '../../reducers';

interface StateProps {
	homeStatusCounts: { [key in HomeLeadStatuses]?: number };
	isLoading: boolean;
	userId: string;
	pageSize: number;
}
interface DispatchProps {
	navigateTo(route: string): void;
	getHomeStatusCounts(): void;
	storeLeadFilters(filterValues: Partial<LeadFilters>): void;
	getFilteredLeads: (filters: LeadFilters) => void;
	searchProducts: (filterValues: ProductFilterPayload) => void;
	toggleFilterDrawer: (toggle: boolean) => void;
}
type Props = StateProps & DispatchProps;

class CrmWidget extends React.PureComponent<Props> {

	placeHolderCircle = (
		<Grid item xs={3} style={style.placeholderCircle}>
			<ReactPlaceholder
				showLoadingAnimation
				type="round"
				ready={false}
			>
				<span />
			</ReactPlaceholder>
		</Grid>
	);

	componentDidMount() {
		this.props.getHomeStatusCounts();
	}

	handleLeadFilters(leadStatus: Lookup) {
		const leadFilters = formatLeadFiltersToStore(leadStatus);
		this.props.getFilteredLeads(leadFilters);
		this.props.storeLeadFilters(leadFilters);
	}

	handleProductFilters(productStatus: string, productMatch: string) {
		const productFilters: ProductFilterPayload = {
			...FilterInitialState,
			productCategoryFilter: 'all',
			productStatusFilter: productStatus || 'All',
			productMatchFilter: productMatch || 'All',
			pageNumber: 0,
			pageSize: 100,
		};

		this.props.searchProducts(productFilters);
		this.props.toggleFilterDrawer(false);

	}

	onClickNewLeads = () => {
		this.handleLeadFilters(Lookups.NotContacted);
		nav.push(navRoutes.leadList.path);
	};

	onClickAttemptedLeads = () => {
		this.handleLeadFilters(Lookups.Attempted);
		nav.push(navRoutes.leadList.path);
	};

	onClickContactedLeads = () => {
		this.handleLeadFilters(Lookups.Contacted);
		nav.push(navRoutes.leadList.path);
	};

	onClickUnmatchedPolicies = () => {
		this.handleProductFilters('', 'Unmatched');
		nav.push(navRoutes.productListPage.path);
	};

	navigateToLeadDashboard = () => nav.push(navRoutes.leadDashboard.path);

	renderLoading = () => {
		return (
			<Grid container direction="row" style={style.container}>
				<Grid container alignItems="center" justify="center" style={style.row}>
					<Grid item xs={2} />
					{this.placeHolderCircle}
					<Grid item xs={2} />
					{this.placeHolderCircle}
					<Grid item xs={2} />
				</Grid>
				<Grid container alignItems="center" justify="center" style={style.row}>
					<Grid item xs={2} />
					{this.placeHolderCircle}
					<Grid item xs={2} />
					{this.placeHolderCircle}
					<Grid item xs={2} />
				</Grid>
			</Grid>
		);
	};

	renderWidgetBody(): JSX.Element {
		const { isLoading, homeStatusCounts } = this.props;
		if (isLoading) {
			return this.renderLoading();
		}

		return (
			<Box width="100%" height="100%" paddingTop={4}>
				<Box width="100%" height="50%">
					<Grid container alignItems="center">
						<Grid item xs={1} />
						<Grid item xs={5} style={style.center}>
							<PaperIcon
								content={renderLeadCount(homeStatusCounts['New Leads'])}
								caption="New Leads"
								onClick={this.onClickNewLeads}
							/>
						</Grid>
						<Grid item xs={5} style={style.center}>
							<PaperIcon
								content={renderLeadCount(homeStatusCounts.Attempted)}
								caption="Attempted"
								onClick={this.onClickAttemptedLeads}
							/>
						</Grid>
						<Grid item xs={1} />
					</Grid>
				</Box>
				<Box width="100%" height="50%">
					<Grid container alignItems="center">
						<Grid item xs={1} />
						<Grid item xs={5} style={style.center}>
							<PaperIcon
								content={renderLeadCount(homeStatusCounts.Contacted)}
								caption="Contacted"
								onClick={this.onClickContactedLeads}
							/>
						</Grid>
						<Grid item xs={5} style={style.center}>
							<PaperIcon
								content={renderLeadCount(homeStatusCounts['Unmatched Policies'])}
								caption="Unmatched Policies"
								onClick={this.onClickUnmatchedPolicies}
							/>
						</Grid>
						<Grid item xs={1} />
					</Grid>
				</Box>
			</Box>
		);
	}

	render() {
		return (
			<Widget
				title={Strings.WidgetTitles.Crm}
				avatar={<CrmSvg width={30} height={30} fill={themePalette.menuSvgCRM} />}
				onClick={this.navigateToLeadDashboard}
				actionButtons={[
					<Button onClick={this.navigateToLeadDashboard}>
						View All
					</Button>,
				]}
				contentStyle={{
					...style.cardBody,
					backgroundImage: `url('${themeImages.bubblesSecondary}')`,
					backgroundColor: themePalette.bubbles_secondary,
				}}
			>
				{this.renderWidgetBody()}
			</Widget>
		);
	}
}

const mapStateToProps = (state: AppState): StateProps => ({
	homeStatusCounts: state.lead.homeStatusCounts,
	isLoading: state.lead.isLoading,
	pageSize: state.lead.leadListPageSize,
	userId: state.user.impersonatingId
		? state.user.impersonatingId
		: state.user.id,
});
const mapDispatchToProps = (dispatch: Dispatch, ownProps: Props): DispatchProps => ({
	navigateTo: (route: string) =>
		dispatch(navigateTo(route)),
	getHomeStatusCounts: () => dispatch(GetHomeStatusCounts.started()),
	storeLeadFilters: (filterValues: LeadFilters) =>
		dispatch(StoreLeadFilters(filterValues)),
	getFilteredLeads: (leadFilters: LeadFilters) => {
		const payload = formatLeadFiltersPayload(
			leadFilters,
			0,
			ownProps.pageSize
		);
		return dispatch(GetFilteredLeads.started(payload));
	},
	searchProducts: (filterValues: ProductFilterPayload) =>
		dispatch(SearchProducts.started(filterValues)),
	toggleFilterDrawer: (toggle: boolean) =>
		dispatch(ToggleFilterDrawer(toggle)),
});

export const CrmWidgetContainer = connect(mapStateToProps, mapDispatchToProps, true)(CrmWidget);

const style: { [key: string]: React.CSSProperties } = {
	placeholderCircle: {
		height: 90,
		width: 90,
		maxWidth: 90,
	},
	container: {
		height: 310,
	},
	center: {
		textAlign: 'center',
	},
	row: {
		height: '50%',
	},
	cardBody: {
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
	},
};
