// tslint:disable: align
import Highcharts from 'highcharts';
import React from 'react';
import { StackedGraphDataPoint } from '../../reducers/teamview_reducer';
import { themePalette } from '../../utilities/branding';
import { isMobileDevice } from '../../utilities/is_mobile';

interface Props {
    title?: string;
	unit?: string;
    style?: React.CSSProperties;
    data: StackedGraphDataPoint[];
    chartSettings?: any;
}

class ChartTripleStackedBar extends React.PureComponent<Props, any> {
    chartRef: any;

    componentDidMount() {
		this.updateChart();
	}

    componentDidUpdate() {
		this.updateChart();
	}

    getMaxValue = (data: StackedGraphDataPoint[]) => {
		if (!data || !data.length) {
			return 0;
		}
		const max = data.reduce(
			(maxVal, val) => {
                const total: number = val.value1 + val.value2 + (val.value3 || 0) + (val.value4 || 0);
                return (total > maxVal ? total : maxVal)
            },
			data[0].value1 + data[0].value2 + (data[0].value3 || 0) + (data[0].value4 || 0)
		);
		return Math.ceil(max);
    };

    getWeeksForXaxis = () => {
        return this.props.data.map(item => item.week).reverse();
    }

    getSeries1 = () => {
        const { data } = this.props;
        return data.map(item => item.value1).reverse();
    };

    getSeriesData = (key: string) => {
        return this.props.data.map(item => item[key]).reverse();
    }

    updateChart() {
        const { data, chartSettings } = this.props;
        const settings = {
			...defaultChartSettings,
			...chartSettings,
		};

        const updatedChartSettings = {
			series: [
                {
                    name: 'Health',
                    data: this.getSeriesData('value4'),
                    color: themePalette.pin_lock_background,
                },
                {
                    name: 'Medicare',
                    data: this.getSeriesData('value3'),
                    color: themePalette.bubbles_tertiary,
                },
                {
                    name: 'Life',
                    data: this.getSeriesData('value2'),
                    color: themePalette.bubbles_secondary,
                },
                {
                    name: 'Supps',
                    data: this.getSeriesData('value1'),
                    color: themePalette.bubbles_primary,
				},
			],
			...settings,
			xAxis: {
                ...settings.xAxis,
                categories: this.getSeriesData('week'),
			},
			yAxis: {
                ...settings.yAxis,
                min: 0,
                max: this.getMaxValue(data) * 1.1,
            },
		};
        Highcharts.chart(this.chartRef, updatedChartSettings);
	}

    render() {
		const { style = {} } = this.props;
		return (
			<div
				ref={chart => (this.chartRef = chart)}
				style={{ ...defaultStyle, ...style }}
			/>
		);
	}
}

const defaultStyle: React.CSSProperties = {
	height: 380,
	overflow: 'hidden',
};
const defaultChartSettings = {
    chart: {
        type: 'column',
    },
    title: {
        text: undefined,
    },
    plotOptions: {
        column: {
            stacking: 'normal',
            dataLabels: {
                enabled: true,
            },
        },
        series: {
            pointPadding: 0,
            groupPadding: 0,
            animation: false,
        },
    },
    legend: {
        align: 'center',
        verticalAlign: 'bottom',
        y: 13,
        floating: true,
        backgroundColor: '#adbabd',
        reversed: true,
        shadow: false,
    },
    xAxis: {
        title: {
            enabled: true,
            text: 'Week',
            align: 'low',
        },
    },
    yAxis: {
        title: {
            enabled: false,
        },
        labels: {
            enabled: false,
        },
        stackLabels: {
            enabled: true,
            style: {
                fontWeight: 'bold',
                color: 'gray',
            },
        },
    },
    tooltip: {
        enabled: !isMobileDevice,
        dataLabels: {
            enabled: true,
        },
        headerFormat: '<b>week {point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
    },
    credits: {
		enabled: false,
	},
};

export default ChartTripleStackedBar;
