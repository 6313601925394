import React from 'react';
import { List, Paper } from '@material-ui/core';
import { Loaded } from '../../utilities/utilities';
import { SearchResultProps, SearchResult } from '../utility/search_result';
import { Contact } from '../../reducers/ContactReducer';
import { SimpleListItem } from '../utility/simple_list_item';

interface Props {
	selectedContact: Contact;
	loadedContacts: Loaded<Contact>[];
	mapContactsToList: (contacts: Contact[]) => SearchResultProps[];
	updateHouseholdSelections: (
		selectedHouseholdMembers: Contact[],
		isPrimarySelected: boolean
	) => void;
	matchedContacts: Contact[];
}

interface State {
	displayConfirmationDialog: boolean;
	anchorEl?: HTMLElement;
	selectedHouseholdMembers: Contact[];
	isPrimarySelected: boolean;
}

export class HouseholdSelection extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			displayConfirmationDialog: false,
			selectedHouseholdMembers: [],
			isPrimarySelected: true,
		};
	}

	componentWillMount() {
		if (this.props.matchedContacts.length > 1) {
			let matchedContacts = this.props.matchedContacts;
			matchedContacts.splice(0, 1);
			let matchedContactIds = matchedContacts.map(contact => contact.id);
			let selectedHouseholdMembers = this.getHouseholdMembers(
				matchedContacts[0].householdId
			).filter(member => {
				return matchedContactIds.indexOf(member.id) !== -1;
			});

			this.setState({
				selectedHouseholdMembers: selectedHouseholdMembers,
			});
		}
	}

	getHouseholdMembers = (householdId: string) => {
		let householdMembers = this.props.loadedContacts
			.filter(loadedContact => {
				var inSelectedHousehold = false;
				if (
					loadedContact.data.householdId &&
					this.props.selectedContact &&
					loadedContact.data.householdId ===
						this.props.selectedContact.householdId &&
					loadedContact.data.id !== this.props.selectedContact.id
				) {
					inSelectedHousehold = true;
				}
				return inSelectedHousehold;
			})
			.map(contact => contact.data);

		return householdMembers;
	};

	handlePrimaryClick = (data: any, index: number) => {
		this.props.updateHouseholdSelections(
			this.state.selectedHouseholdMembers,
			!this.state.isPrimarySelected
		);
		this.setState({
			isPrimarySelected: !this.state.isPrimarySelected,
		});
	};

	handleHouseholdMemberClick = (householdMember: Contact, index: number) => {
		let tempArray = this.state.selectedHouseholdMembers;
		if (this.state.selectedHouseholdMembers.indexOf(householdMember) !== -1) {
			tempArray.splice(
				this.state.selectedHouseholdMembers.indexOf(householdMember),
				1
			);
			this.setState({
				selectedHouseholdMembers: tempArray,
			});
			this.props.updateHouseholdSelections(
				tempArray,
				this.state.isPrimarySelected
			);
		} else {
			tempArray.push(householdMember);
			this.setState({
				selectedHouseholdMembers: tempArray,
			});
			this.props.updateHouseholdSelections(
				tempArray,
				this.state.isPrimarySelected
			);
		}
	};

	render() {
		const selectedContact = this.props.mapContactsToList([
			this.props.selectedContact,
		]);
		const householdMembers = this.getHouseholdMembers(
			this.props.selectedContact.householdId
		);
		const householdMemberResults = this.props.mapContactsToList(
			householdMembers
		);
		return (
			<Paper square elevation={0}>
				<SimpleListItem subtitle={'Selected Contact'}/>
				{selectedContact.map((contact, index) => (
					<SearchResult
						{...contact}
						key={'household-member' + index}
						onClick={(data: any) => this.handlePrimaryClick(data, index)}
						isSelected={this.state.isPrimarySelected}
					/>
				))}
				{householdMemberResults.length > 0 && (
					<>
						<SimpleListItem subtitle={'Other Household Members'}/>
						{householdMemberResults.map((member, index) => (
							<SearchResult
								{...member}
								key={'household-member' + index}
								onClick={(data: any) =>
									this.handleHouseholdMemberClick(
										householdMembers[index],
										index
									)
								}
								isSelected={
									this.state.selectedHouseholdMembers.indexOf(
										householdMembers[index]
									) !== -1
								}
							/>
						))}
					</>
				)}
			</Paper>
		);
	}
}
