import { Strings } from '../../assets/common/strings';
import React from 'react';
import { InputLabel, Select, MenuItem, Typography } from '@material-ui/core';
import { timeZoneTupple } from '../../utilities/mapping_util';
import { InjectedProps as DialogInjectedProps, connectDialog } from '.';
import { AgentTimeZone, UpsertTimeZone } from '../../actions/agent_actions';
import { connect, Dispatch } from '@optum-uhone-hmkts/rise';
import { DialogForm } from './dialog_form';


interface StateProps {
    userId: string;
    agentSavedTimeZone?: string;
}

interface DispatchProps {
    upsertTimeZone: (payload: AgentTimeZone) => void;
}

interface OwnProps {
    firstTimeLogin?: boolean;
}

interface Props extends OwnProps, StateProps, DispatchProps, DialogInjectedProps { }

interface State {
    selectedTimeZone: string;
}

const DEFAULT_TIMEZONE_VALUE = '';

class TimeZoneDialogComponent extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedTimeZone: this.props.agentSavedTimeZone || DEFAULT_TIMEZONE_VALUE,
        };
    }

    setTimezone = (event) => {
        this.setState({
            selectedTimeZone: event.target.value,
        });
    }

    saveTimeZone = () => {
        if (this.state.selectedTimeZone) {
            const payload: AgentTimeZone = {
                userId: this.props.userId,
                timeZone: this.state.selectedTimeZone,
            };
            this.props.upsertTimeZone(payload);
        }
    }

    render() {
        const { dismissSelf, firstTimeLogin } = this.props;

        return (
            <DialogForm
                title={Strings.AboutMeFields.TimeZone}
                onSubmit={this.saveTimeZone}
                dismissSelf={dismissSelf}
                preventCancel={Boolean(firstTimeLogin)}
            >
                {Boolean(firstTimeLogin)
                    && <Typography>
                        {Strings.AboutMeFields.SetTimeZone}
                    </Typography>
                }

                <InputLabel>{Strings.AboutMeFields.TimeZone}</InputLabel>
                <Select
                    value={this.state.selectedTimeZone}
                    onChange={this.setTimezone}
                    fullWidth
                >
                    <MenuItem value={DEFAULT_TIMEZONE_VALUE} disabled={true}> Select a timezone </MenuItem>
                    {timeZoneTupple.map((timezone: [string, string]) => {
                        const [standardName, displayName] = timezone;
                        return (
                            <MenuItem
                                key={standardName}
                                value={displayName}
                            >
                                {displayName}
                            </MenuItem>
                        )
                    })}
                </Select>
            </DialogForm>
        );
    }
}

function mapStateToProps(state: any): StateProps {
    return {
        userId: state.user.id,
        agentSavedTimeZone: state.agent.timeZone
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return {
        upsertTimeZone: (payload: AgentTimeZone) =>
            dispatch(UpsertTimeZone.started(payload)),
    };
}

const TimeZoneDialogContainer = connect(mapStateToProps, mapDispatchToProps, true)(TimeZoneDialogComponent) as React.ComponentClass<OwnProps>;
export const TimeZoneDialog = connectDialog(TimeZoneDialogContainer);
export const TimeZoneDialogFirstTimeLogin = connectDialog(TimeZoneDialogContainer, { firstTimeLogin: true });
