import {
	Avatar,
	CircularProgress,
	Icon,
	IconButton,
	Grid,
	ListItemIcon,
	useMediaQuery,
	Theme,
} from '@material-ui/core';
import SmsIcon from '@material-ui/icons/Sms';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import moment from 'moment';
import React from 'react';
import ClampLines from 'react-clamp-lines';
import { themePalette } from '../../../utilities/branding';
import { Activity } from '../../../reducers/activity_reducer';
import { DateTimeFormat, displayDate } from '../../../utilities/date_util';
import { fullName, orDefault } from '../../../assets/common/string_builders';
import { Strings } from '../../../assets/common/strings';
import { openMaps } from '../../../utilities/maps';
import { Loaded } from '../../../utilities/utilities';
import { CallSequenceIcon } from '../../dialogs/sequence/call_sequence';
import { IconType, SvgIcon } from '../../../components/svgs/svg_icon';
import { CustomListItem, SubtitleProps } from '../../../components/utility/custom_list_item';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import CheckIcon from '@material-ui/icons/Check';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { StopPropagation } from '../../../utilities/html';

interface Props {
	key: string;
	activity: Loaded<Activity>;
	loading: boolean;
	setSelectedActivity: (activity: Loaded<Activity>) => void;
	handleUpdateActivity: (activity: Loaded<Activity>) => void;
	handleNavigateToActivity: (activityId: string, activityType: string, key: string) => void;
}
export const ActivityItem: React.FC<Props> = (props) => {

	const { activity, key, loading, handleUpdateActivity, handleNavigateToActivity, setSelectedActivity } = props;
	const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
	const handleAvatarClick = () => handleUpdateActivity(activity);
	const selectActivity = () => setSelectedActivity(activity);
	const getPersonString = () => fullName(activity?.data?.contact);
	const handleClick = () => handleNavigateToActivity(activity?.data?.id, activity?.data?.type, key);

	const getDateString = () => {
		const isCompletedLabel =
			activity?.data?.status == 'completed' ? 'Completed' : undefined;
		const timeProp =
			isCompletedLabel && activity?.data?.completedOn
				? activity?.data?.completedOn
				: activity?.data?.time;

		const activityDate = moment.utc(timeProp).local();

		const leftLabel = isCompletedLabel ? `${isCompletedLabel} - ` : ''

		const _displayDate = displayDate(activityDate, DateTimeFormat.DayMonthTime, leftLabel);
		return _displayDate;
	};

	const getLeftAction = () => {
		const displayExclamation = activity?.data?.isHighPriority ? (
			<PriorityHighIcon style={{ marginLeft: -8 }} color="error"/>
		) : null;
		const style: React.CSSProperties = {};
		let iconClick: (() => void) | undefined = undefined;
		if (activity?.data?.status !== 'open') {
			style.backgroundColor = themePalette.selected_item;
		} else {
			iconClick = handleAvatarClick;
		}
		return (
			<StopPropagation>
				<ListItemIcon onClick={iconClick}>
					<Grid container alignItems="center">
						<Avatar style={style}><CheckIcon/></Avatar>
						{displayExclamation}
					</Grid>
				</ListItemIcon>
			</StopPropagation>
		);
	}

	const determineIcon = (loadedActivity: Loaded<Activity>) => {
		const activity = loadedActivity.data;

		switch (activity.type) {
			case Strings.Activity.Task:
				return (
					<IconButton onClick={handleClick}>
						<InfoOutlinedIcon/>
					</IconButton>
				);
			case Strings.Activity.PhoneCall:
				return (
					<CallSequenceIcon
						contacts={[activity.contact]}
						householdId={activity.contact.householdId}
						handleClick={selectActivity}
						iconOptions={
							{
								button: true,
								style: {
									paddingRight: 10,
									color: themePalette.default_avatar,
								}
							}
						}
					/>
				);
			case Strings.Activity.Appointment:
				return (
					<IconButton onClick={() => openMaps(activity.location)}>
						<LocationOnOutlinedIcon/>
					</IconButton>
				);
		}
	};

	const header = (
		<ClampLines
			text={activity?.data?.title || ''}
			lines={2}
			buttons={false}
		/>
	);
	const descriptionWithoutSeperator = orDefault(activity?.data?.description).replace(Strings.Activity.ActivitySeparator, '');
	const subtitles: SubtitleProps[] = [
		{ value: getPersonString() },
		{ value: (
			<ClampLines
				text={descriptionWithoutSeperator || ''}
				lines={2}
				buttons={false}
			/> )
		},
	];
	const rightSubtitles: SubtitleProps[] = [
		{ value: getDateString() },
	];
	const id = activity?.data?.id;
	return (
		<CustomListItem
			key={id}
			id={id}
			header={header}
			subtitles={subtitles}
			rightSubtitles={rightSubtitles}
			onClick={handleClick}
			leftActionElement={loading ? <CircularProgress size={38} /> : getLeftAction()}
			rightActionElement={determineIcon(activity)}
			leftColumnSize={isSmallScreen ? 2 : 1}
			primaryColumnSize={7}
			secondaryColumnSize={isSmallScreen ? 2 : 3}
			style={{ paddingLeft: 16 }}
		/>
	);

}

export const getActivityIcon = (activity: Activity) => {
	let icon: JSX.Element = <span/>;
	const { type, isHighPriority } = activity;
	switch (type) {
		case Strings.Activity.PhoneCall: {
			icon = <Icon>phone</Icon>;
			break;
		}
		case Strings.Activity.Appointment: {
			icon = <Icon>date_range</Icon>;
			break;
		}
		case Strings.Activity.FactFinder: {
			icon = <Icon><SvgIcon color="#fff" type={IconType.FactFinder} /></Icon>;
			break;
		}
		case Strings.Activity.Task: {
			icon = <Icon>event_available</Icon>;
			break;
		}
		case Strings.Activity.Email: {
			icon = <Icon>email</Icon>;
			break;
		}
		case Strings.Activity.LeadStatusChange: {
			icon = <Icon>edit</Icon>;
			break;
		}
		case Strings.Activity.Sms: {
			icon = <SmsIcon/>
			break;
		}
		case Strings.Activity.Quoted: {
			icon = <Icon>description</Icon>;
			break;
		}
		default: {
			break;
		}
	}

	const displayExclamation = isHighPriority ? (
		<PriorityHighIcon style={{ marginLeft: -8 }} color="error"/>
	) : null;
	return (
		<Grid container alignItems="center">
			<Avatar>
				{icon}
			</Avatar>
			{displayExclamation}
		</Grid>
	);
}