// tslint:disable: align
import React from 'react';
import {
	Table,
    Paper,
    Grid,
	TableRow,
	TableCell,
	TableBody,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TeamViewRow } from '../../reducers/teamview_reducer';
import { ReportCard } from '../utility/report_card';

export interface OwnProps {
    color?: 'primary' | 'secondary' | 'tertiary';
	titleLeft: string;
    rows: TeamViewRow[];
    sLCount: number;
    isDesktop: boolean;
}

export const SummaryTableCard: React.FC<OwnProps> = props => {
    const classes = useStyles();
    const { color = 'primary', titleLeft, rows, sLCount, isDesktop, children } = props;

    const getColumnNames = (): TeamViewRow => {
        const columnNames: TeamViewRow = { agentcode: '', results: {}, reports: 0, children: [], terminated: false };
        if (rows.length > 0 && rows[0].results) {
            Object.keys(rows[0].results).forEach(key => {
                columnNames.results[key] = { value: key, delta: 0 };
                columnNames.agentcode = titleLeft + '-header';
            });
        }
        return columnNames;
    };

    const renderRow = (row: TeamViewRow, rowNumber: number, isHeaderRow: boolean = false, sLCount: number): JSX.Element => {
		const columns = row.results;
        const id = row.agentcode;

        const topCutOff = Math.floor(sLCount / 3);
        const middleCutOff = sLCount - (sLCount / 3);

        const getBackground = (value: number, index: number) => {
            if (isHeaderRow) {
                return 'headerRow';
            } else if (index === 3) {
                if (value <= topCutOff) {
                    return 'green';
                } else if (value > middleCutOff) {
                    return 'red';
                } else {
                    return 'yellow';
                }
            } else {
                return '';
            }
        }

		return (
            <TableRow
                key={`row_${rowNumber}`}
                className={isHeaderRow ? classes.headerRow : classes.row}
            >
				{Object.keys(columns).map((key, index) => {
                    const value = +(columns[key].value);
                    return (
					<TableCell
						key={`${id}_${index}`}
						className={classes[getBackground(value, index)]}
					>
						{columns[key].value}
					</TableCell>
                )})}
            </TableRow>
		);
    };

    return  (
        <ReportCard
            color={color}
            titleLeft={titleLeft}
        >
        {isDesktop
            ? (
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <Paper style={{ overflowX: 'auto' }}>
                            <Table>
                                <TableBody>
                                    {renderRow(getColumnNames(), 1, true, sLCount)}
                                    {rows.slice(0, 6).map((row, index) => {
                                        return renderRow(row, index, false, sLCount);
                                    })}
                                </TableBody>
                            </Table>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper style={{ overflowX: 'auto' }}>
                            <Table>
                                <TableBody>
                                    {renderRow(getColumnNames(), 1, true, sLCount)}
                                    {rows.slice(6).map((row, index) => {
                                        return renderRow(row, index, false, sLCount);
                                    })}
                                </TableBody>
                            </Table>
                        </Paper>
                    </Grid>
                </Grid>)
            : (
                <Paper style={{ overflowX: 'auto' }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Table>
                                <TableBody>
                                    {renderRow(getColumnNames(), 1, true, sLCount)}
                                    {rows.slice(0, 6).map((row, index) => {
                                        return renderRow(row, index, false, sLCount);
                                    })}
                                    {renderRow(getColumnNames(), 1, true, sLCount)}
                                    {rows.slice(6).map((row, index) => {
                                        return renderRow(row, index, false, sLCount);
                                    })}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </Paper>
            )}
            {children}
        </ReportCard>
    )
}

const useStyles = makeStyles({
	headerRow: {
		fontWeight: 'bold',
        backgroundColor: 'rgb(222 237 251)',
        whiteSpace: 'nowrap',
	},
	row: {
        backgroundColor: 'white',
        whiteSpace: 'nowrap',
    },
    bottomText: {
        padding: 20,
        textAlign: 'center',
    },
    green: {
        backgroundColor: '#b1e0be',
    },
    yellow: {
        backgroundColor: '#f2dc8d',
    },
    red: {
        backgroundColor: '#f2a7a7',
    },
});
