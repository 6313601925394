import { TextField } from '@material-ui/core';
import moment, { Moment } from 'moment';
import React, { ChangeEvent } from 'react';
import { MuiPickersUtilsProvider, DatePicker as MuiDatePicker, DateTimePicker as MuiDateTimePicker, } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import { isDesktop, isIOS, isMobileDevice } from '../../utilities/is_mobile';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

const bowser = require('bowser');
const isSafari = isDesktop() && (bowser.name === 'Safari');


interface Props {
	label: string;
	fullWidth?: boolean;
	disabled?: boolean;
	startOfDay?: boolean;
	endOfDay?: boolean;
	minDate?: Date;
	maxDate?: Date;
	useUtc?: boolean;
	meta: {
		dirty?: boolean;
		error?: string;
		invalid?: boolean;
		pristine?: boolean;
		touched?: boolean;
		valid?: boolean;
		visited?: boolean;
		warning?: string;
	};
	input: {
		value: Date | moment.Moment | undefined | string;
		onChange: (value: Date | moment.Moment | string) => void;
	};
	style?: React.CSSProperties;
	useMoment?: boolean;
	dateAndTime?: boolean;
}

interface State {
	internalValue: string;
}

const labelConfig = { shrink: true };

class DatePicker extends React.PureComponent<Props, State> {

	checkValue = (val: Moment | null) => {
		if (val?.isValid()) {
			if (this.props.startOfDay) {
				val = val.startOf('day');
			} else if (this.props.endOfDay) {
				val = val.endOf('day');
			}
			const dateVal = this.props.useMoment ? val : val.toDate();
			this.props.input.onChange(dateVal);
		}
	};

	onChange = (event: ChangeEvent<HTMLInputElement>) => {
		const val = moment(event.target.value);
		this.checkValue(val);
	};

	muiDateChange = (date: MaterialUiPickersDate) => {
		this.checkValue(date);
	};

	render() {
		const {
			meta,
			input,
			minDate,
			maxDate,
			startOfDay,
			endOfDay,
			useUtc,
			useMoment,
			dateAndTime,
			...config
		} = this.props;
		const { error, warning } = meta;
		const errorText = error || warning;
		const errorCheck = !!errorText;
		const inputProps: any = {};
		const dateFormat = isIOS && dateAndTime
			? 'MM/DD/YYYY hh:mm A'
			: isSafari 
				? 'MM/DD/YYYY' 
				: 'YYYY-MM-DD';
		if (minDate) inputProps.min = moment(minDate).format(dateFormat);
		if (maxDate) inputProps.max = moment(maxDate).format(dateFormat);
		let trueValue = '';
		if (input.value) {
			trueValue = moment(input.value).format(dateFormat);
		}

		if (useUtc) {
			const date = moment.utc(input.value).format(dateFormat);
			trueValue = date;
		}

		const type = isIOS && dateAndTime
			? 'datetime-local'
			: 'date';

		return isMobileDevice
			? (
				<MuiPickersUtilsProvider utils={MomentUtils}>
					{
						dateAndTime
							? <MuiDateTimePicker
								{...config}
								error={errorCheck}
								helperText={errorText}
								value={trueValue}
								onChange={this.muiDateChange}
								inputProps={inputProps}
								format={dateFormat}
							/>
							: <MuiDatePicker
								{...config}
								error={errorCheck}
								helperText={errorText}
								value={trueValue}
								onChange={this.muiDateChange}
								inputProps={inputProps}
								format={dateFormat}
							/>
					}
				</MuiPickersUtilsProvider>
			)
			: (
				<TextField
					{...config}
					error={errorCheck}
					helperText={errorText}
					value={trueValue}
					onChange={this.onChange}
					inputProps={inputProps}
					InputLabelProps={labelConfig}
					type={type}
				/>
			);
	}
}

export default DatePicker;
