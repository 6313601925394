import React from 'react';
import { Avatar } from '@material-ui/core';
import { fullName, initials } from '../../assets/common/string_builders';
import { SimpleListItem } from '../utility/simple_list_item';
import { SearchAgent } from '../../reducers/agent_search_reducer';
import { getAgentLocation } from '../../utilities/agent_util';

interface Props {
	agent: SearchAgent;
	term?: string;
	onClick: (agentCode: string) => void;
}
export const AgentSearchResultItem: React.FC<Props> = (props) => {

	const { term, onClick, agent } = props;

	const lowerTerm = term?.toLowerCase();
	let name = fullName(agent);
	let address = getAgentLocation(agent);
	if (lowerTerm) {
		const regex = new RegExp(`(${lowerTerm})`, 'gi');
		name = name?.replace(regex, highlightHtml) || name;
		address = address?.replace(regex, highlightHtml) || address;
	}
	return (
		<SimpleListItem
			title={<div dangerouslySetInnerHTML={{ __html: name }} />}
			subtitle={<div dangerouslySetInnerHTML={{ __html: address }} />}
			onClick={() => onClick(agent.agentCode)}
			icon={
                <Avatar src={agent.image?.replace('http://', 'https://')} imgProps={{ loading: 'lazy', width: '30px', height: '30px' }}>
                    {initials(agent)}
                </Avatar>
            }
		/>
	);
}

const highlightHtml = '<mark>$1</mark>';
