import { all, call, put } from 'redux-saga/effects';
import { takeLatestForActionType } from '../utilities/saga_util';
import { GetAgentsAdmin, UpsertAgentAdmin } from '../actions/admin_actions';
import { http } from '../utilities';
import { Strings as S } from '../assets/common/strings';
import { Action } from 'typescript-fsa';
import { PagingOptions } from '../actions/notification_actions';
import { ManagedAgent } from '../reducers/admin_reducer';
import { QueueSnackbar } from '../actions/snackbar_actions';
import { getSnackbarSuccessProps } from '../utilities/snackbar_util';

function* getAgentsAdmin(action: Action<PagingOptions>) {
    try {
        const paging = { index: action.payload.pageNum, size: action.payload.pageSize };
        const response = yield call(http, S.ApiUrls.AdminAgents, { method: S.Http.Get }, paging);
        if (response.ok) {
            const result = yield response.json();
            yield put(GetAgentsAdmin.done({ params: action.payload, result }));
        } else {
            yield put(GetAgentsAdmin.failed({ params: action.payload, error: { errorCode: response.status } }));
        }
    }
    catch (error) {
        yield put(GetAgentsAdmin.failed({ params: action.payload, error }));
    }
}

function* upsertAgentAdmin(action: Action<ManagedAgent>) {
    try {
        const response = yield call(http, S.ApiUrls.AdminAgents, { method: S.Http.Post, body: JSON.stringify(action.payload) });
        if (response.ok) {
            const result = yield response.json();
            yield all([
                put(UpsertAgentAdmin.done({ params: action.payload, result })),
                put(QueueSnackbar(getSnackbarSuccessProps('Internal User updated'))),
            ]);
        } else {
            yield put(UpsertAgentAdmin.failed({ params: action.payload, error: { errorCode: response.status } }));
        }
    }
    catch (error) {
        yield put(UpsertAgentAdmin.failed({ params: action.payload, error }));
    }
}

export function* adminSagas() {
    yield all([
        takeLatestForActionType(
            GetAgentsAdmin.started,
            getAgentsAdmin
        ),
        takeLatestForActionType(
            UpsertAgentAdmin.started,
            upsertAgentAdmin
        )
    ]);
}