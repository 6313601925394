import React from 'react';
import { Grid } from '@material-ui/core';
import { ReadOnlyTextField } from '../utility/read_only_text_field';
import { wrapInCollapsibleCard } from '../higher_order_component/wrap_in_card';

interface Props {
	createdOn: string;
	modifiedOn: string;
	createdByAgentName?: string;
	updatedByAgentName?: string;
	isReviewed: string;
}
const PolicyAdmin: React.FC<Props> = (props) => {
	return (
		<Grid container>
			<Grid container>
				<Grid item xs={12}>
					<ReadOnlyTextField
						label="Created On"
						value={props.createdOn + ' ' + props.createdByAgentName}
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12}>
					<ReadOnlyTextField
						label="Modified On"
						value={props.modifiedOn + ' ' + props.updatedByAgentName}
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12}>
					<ReadOnlyTextField label="Is Reviewed" value={props.isReviewed} />
				</Grid>
			</Grid>
		</Grid>
	);
};

export const PolicyAdminCard = wrapInCollapsibleCard(PolicyAdmin);
