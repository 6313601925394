import { BulkReassign } from '../actions/lead_actions';
import {
	GetEmployer,
	UpdateEmployer,
	DeleteEmployer,
	ReassignEmployer,
	SearchEmployers,
} from './../actions/employer_actions';
import {
	GetHousehold,
	SetNewPrimaryContactOnHousehold,
	ReassignHousehold,
} from './../actions/household_actions';
import { isType } from 'typescript-fsa';
import { Loaded } from './../utilities/utilities';
import { Action as ReduxAction } from 'redux';
import _ from 'lodash';
import { CreateNote, DeleteNote, UpdateNote } from '../actions/note_actions';
import { ClearCaches } from '../actions/authentication_actions';
import { Logout } from '../actions/authentication_actions';

export interface Note {
	id: string;
	content: string;
	dateCreated: Date;
	householdId?: string;
	employerId?: string;
}

export interface NoteState {
	notes: Loaded<Note>[];
}

export const initialState: NoteState = {
	notes: [],
};

export function noteReducer(
	state: NoteState = initialState,
	action: ReduxAction
): NoteState {
	if (
		isType(action, GetEmployer.done) ||
		isType(action, UpdateEmployer.done) ||
		isType(action, GetHousehold.done) ||
		isType(action, SetNewPrimaryContactOnHousehold.done)
	) {
		const notesInState = state.notes.slice();
		updateStateWithInboundNotes(notesInState, action.payload.result.notes);
		return {
			...state,
			notes: notesInState,
		};
	} else if (isType(action, DeleteEmployer.done)) {
		const notesInState = state.notes.filter(
			note => note.employerId !== action.payload.params
		);
		return {
			...state,
			notes: notesInState,
		};
	} else if (isType(action, ReassignEmployer.done)) {
		const notesInState = state.notes.filter(
			note => note.employerId !== action.payload.params.employerId
		);
		return {
			...state,
			notes: notesInState,
		};
	} else if (isType(action, ReassignHousehold.done)) {
		const notesInState = state.notes.filter(
			note => note.householdId !== action.payload.params.householdId
		);
		return {
			...state,
			notes: notesInState,
		};
	} else if (
		isType(action, CreateNote.started) ||
		isType(action, DeleteNote.started) ||
		isType(action, UpdateNote.started)
	) {
		const notesInState = state.notes.slice();
		upsertNoteStart(notesInState, action.payload);
		return {
			...state,
			notes: notesInState,
		};
	} else if (
		isType(action, CreateNote.done) ||
		isType(action, UpdateNote.done)
	) {
		const notesInState = state.notes.slice();
		upsertNoteDone(notesInState, action.payload.result);
		return {
			...state,
			notes: notesInState,
		};
	} else if (isType(action, DeleteNote.done)) {
		const notesInState = state.notes.slice();
		const indexToRemove = _.findIndex(
			notesInState,
			note => note.data.id === action.payload.params.id
		);
		if (indexToRemove !== -1) notesInState.splice(indexToRemove, 1);
		return {
			...state,
			notes: notesInState,
		};
	} else if (
		isType(action, CreateNote.failed) ||
		isType(action, DeleteNote.failed) ||
		isType(action, UpdateNote.failed)
	) {
		const notesInState = state.notes.slice();
		upsertNoteDone(notesInState, action.payload.params, action.payload.error);
		return {
			...state,
			notes: notesInState,
		};
	} else if (isType(action, SearchEmployers.done)) {
		const notesInState = state.notes.slice();
		let newNotes: Note[] = [];
		action.payload.result.forEach(fullEmployer => {
			newNotes = newNotes.concat(fullEmployer.notes);
		});
		updateStateWithInboundNotes(notesInState, newNotes);
		return {
			...state,
			notes: notesInState,
		};
	} else if (isType(action, BulkReassign.done)) {
		const notesInState = state.notes.slice();
		const filteredNotes = notesInState.filter(note =>
			action.payload.params.entities.some(
				entity =>
					entity.id != note.householdId &&
					entity.id != note.employerId &&
					entity.id != note.data.householdId &&
					entity.id != note.data.employerId
			)
		);
		return {
			...state,
			notes: filteredNotes,
		};
	} else if (isType(action, Logout.started)) {
		return {
			...initialState,
		};
	} else if (isType(action, ClearCaches)) {
		return {
			...initialState,
		};
	} else {
		return state;
	}
}

function upsertNoteStart(cachedNotes: Loaded<Note>[], note: Note) {
	const matchedNoteIndex = _.findIndex(
		cachedNotes,
		cachedNote => cachedNote.data.id === note.id
	);
	if (matchedNoteIndex !== -1) {
		cachedNotes[matchedNoteIndex] = {
			...cachedNotes[matchedNoteIndex],
			loading: true,
			employerId: note.employerId,
			householdId: note.householdId,
		};
	} else {
		const newNote: Loaded<Note> = {
			data: note,
			loading: true,
			employerId: note.employerId,
			householdId: note.householdId,
			errors: [],
		};
		cachedNotes.push(newNote);
	}
}

function upsertNoteDone(
	cachedNotes: Loaded<Note>[],
	updatedNote: Note,
	error?: any
) {
	const index = cachedNotes.findIndex(
		cachedNote => cachedNote.data.id === updatedNote.id
	);
	if (index > -1) {
		if (error)
			cachedNotes[index] = {
				...cachedNotes[index],
				loading: false,
				errors: [...cachedNotes[index].errors, error],
			};
		else
			cachedNotes[index] = {
				...cachedNotes[index],
				data: updatedNote,
				employerId: updatedNote.employerId,
				householdId: updatedNote.householdId,
				loading: false,
				errors: [],
			};
	} else {
		const newNote: Loaded<Note> = {
			data: updatedNote,
			loading: false,
			employerId: updatedNote.employerId,
			householdId: updatedNote.householdId,
			errors: error ? [error] : [],
		};
		cachedNotes.push(newNote);
	}
}

function updateStateWithInboundNotes(
	notesInState: Loaded<Note>[],
	inboundNotes: Note[]
) {
	inboundNotes.forEach(inboundNote => {
		const noteId = inboundNote.id;
		let wasANoteUpdated = false;

		notesInState.forEach((currentNote, index) => {
			const currentNoteId = currentNote.data.id;
			if (currentNoteId === noteId) {
				wasANoteUpdated = true;
				notesInState[index] = {
					...notesInState[index],
					loading: false,
					data: inboundNote,
					householdId: inboundNote.householdId,
					employerId: inboundNote.employerId,
					errors: [],
				};
			}
		});
		if (!wasANoteUpdated) {
			const newNote: Loaded<Note> = {
				loading: false,
				data: inboundNote,
				errors: [],
				householdId: inboundNote.householdId,
				employerId: inboundNote.employerId,
			};
			notesInState.push(newNote);
		}
	});
}
