import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
HC_more(Highcharts);
interface PlotBandSettings {
    to: number;
    from: number;
    color: string;
}
export interface GaugeChartSettings {
    yAxisMin: number;
    yAxisMax: number;
    plotBands: PlotBandSettings[];
}
export interface GaugeChartProps {
    data: number[];
    chartSettings: GaugeChartSettings;
}

export const ChartGauge: React.FC<GaugeChartProps> = props => {
    let chartRef: any;
    const { data, chartSettings } = props;

    const updateChart = () => {
        const updatedChartSettings = {
            series: [{
                data,
            }],
            ...defaultChartSettings(chartSettings),
        };

        Highcharts.chart(chartRef, updatedChartSettings)
    };

    useEffect(() => {
        updateChart();
    });

    return (
        <div
            ref={chart => (chartRef = chart)}
            style={{ ...defaultStyle }}
        />

    )
};

const defaultStyle: React.CSSProperties = {
	height: 200,
	overflow: 'hidden',
};

const defaultChartSettings = (settings: GaugeChartSettings) => ({
    chart: {
        type: 'gauge',
    },
    title: {
        text: ''
    },
    pane: {
        center: ['50%', '85%'],
        startAngle: -90,
        endAngle: 91,
        background: [{
            backgroundColor: '#fff',
            borderWidth: 0,
            outerRadius: '100%',
            innerRadius: '40%',
            shape: 'arc',
        }]
    },
    yAxis: {
        min: settings.yAxisMin,
        max: settings.yAxisMax,
        lineColor: '#3A3A3A',
        tickColor: '#3A3A3A',
        tickPosition: 'outside',
        tickWidth: 0,
        minorTickWidth: 0,
        lineWidth: 0,
        offset: -4,
        labels: {
            distance: 60,
            step: 0,
            rotation: 0,
            formatter(): string {
                // tslint:disable-next-line: no-this-assignment
                const self: any = this;
                const value = self.value;
                return `${value * 100}%`;
            },
            style: {
                color: '#3A3A3A',
                fontWeigth: 'normal'
            }
        },
        plotBands: settings.plotBands.map(pb => ({
            ...pb,
            innerRadius: 50,
            outerRadius:  116,
        })),
    },
    plotOptions: {
        gauge: {
            dial: {
                baseWidth: 9,
                backgroundColor: '#3A3A3A',
                rearLength: 0,
                baseLength: 90,
                radius: 145,
            },
            pivot: {
                radius: 4,
                backgroundColor: '#3A3A3A'
            },
            dataLabels: {
                enabled: false,
            },
            animation: false,
            wrap: false
        }
    },
   	exporting: {
        enabled: false
    },
    credits: {
        enabled: false
    },
    tooltip: {
        enabled: false
    },
});
