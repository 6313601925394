const sortByDate = <T>(ascending: boolean, list: Array<T>, getDate: (item: T) => Date) => {
    const greater = ascending ? 1 : -1;
    const less = -1 * greater;

    return list.sort((a,b) => {
        if (getDate(a) > getDate(b)) return greater;
        if (getDate(a) < getDate(b)) return less;
        return 0;
    });
}

export const sortOldToNew = <T>(list: Array<T>, getDate: (item: T) => Date) => sortByDate(true, list, getDate);
export const sortNewToOld = <T>(list: Array<T>, getDate: (item: T) => Date) => sortByDate(false, list, getDate);


export const getSortByProperties = (sortBy: string) => {
	let category = '';
	let direction = '';
	switch (sortBy) {
		case 'Oldest to Newest by Created Date':
			category = 'createdon';
			direction = 'asc';
			break;
		case 'Newest to Oldest by Created Date':
			category = 'createdon';
			direction = 'desc';
			break;
		case 'Oldest to Newest by Modified Date':
			category = 'modifiedon';
			direction = 'asc';
			break;
		case 'Newest to Oldest by Modified Date':
			category = 'modifiedon';
			direction = 'desc';
			break;
	}
	return { category: category, direction: direction };
}

export const greaterIgnoreCase = (a?: string | null, b?: string | null) => (a || '').toUpperCase() > (b || '').toUpperCase();
export const sortAsc = (val1: string, val2: string) => {
	if (greaterIgnoreCase(val1, val2))
		return 1;
	else if (greaterIgnoreCase(val2, val1))
		return -2;
	else
		return 0;
}
