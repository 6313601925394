import copy from 'copy-to-clipboard';


// TODO: add methods for matching installed pwa (use in pwa dialog component)

const matchUserAgent = (regex: RegExp) => !!navigator?.userAgent?.match(regex);

const androidRegex = /Android/i;
const iOSRegex = /(iPhone|iPad|iPod)/i;
const mobileRegex = /(Android|webOS|iPhone|iPad|iPod)/i;
const iPadRegex = /Macintosh/i;
const macRegex = /(Mac OS X)/i;

export const isIPad: boolean = (() => (
    matchUserAgent(iPadRegex) && navigator.maxTouchPoints > 1
))();
export const isIOS: boolean = (() => (
	matchUserAgent(iOSRegex) || isIPad
))();

export const isMobileDevice: boolean = (() => (
    matchUserAgent(mobileRegex) || isIOS
))();

export const isAndroid: boolean = (() => (
	matchUserAgent(androidRegex)
))();

export const isMac: boolean = (() => (
    matchUserAgent(macRegex)
))();

export const isIPhoneX = () => (
	isIOS &&
	// Check width and height - using >= for iPhone 12 Mini all the way to iPhone Max
	(window.innerHeight >= 812 && window.innerWidth >= 375)
);

export const isDesktop = (): boolean => (
	window.matchMedia(`(min-width: 960px)`).matches
);

export const isTablet = (): boolean => (
	(isMobileDevice || isIPad) &&
	(/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(navigator.userAgent?.toLowerCase())
        || isIPad)
);

export const isLandscape = (): boolean => (
	isMobileDevice &&
	window.screen?.orientation.type.includes('landscape')
);

export const minWidthMatch = (px: Number) =>
	window.matchMedia(`(min-width: ${px}px)`).matches;

export const copyToClipboard = (text: string) => {
	copy?.(text);
};
