import React from 'react';
import { defaultSvgProps, SvgProps } from '..';

const CrmSvg: React.FC<SvgProps> = (props) => (
    <svg viewBox="0 0 35 35" {...props}>
        <path
            d="M27.2 16.3c-.1.2-.4.2-.6.1-.1-.1-.1-.2-.2-.3 0-.1 0-.2.1-.3 1-1.4.8-3.3-.4-4.5-.7-.7-1.5-1-2.5-1-.9 0-1.8.4-2.5 1-.2.2-.4.4-.5.7l-.4.6-.2-.6c-.2-.3-.3-.5-.5-.7-.7-.7-1.5-1-2.5-1-.9 0-1.8.4-2.5 1-1.2 1.2-1.4 3.2-.3 4.6.1.2.1.4-.1.6-.1.1-.2.1-.2.1-.1 0-.3-.1-.3-.2-1.3-1.7-1.1-4.2.4-5.7.8-.8 1.9-1.3 3-1.3s2.2.4 3 1.3l.3.3.3-.3c1.7-1.7 4.4-1.7 6.1 0 1.5 1.6 1.7 3.9.5 5.6zM29 19.9c-1-1.3-3.2.2-3.2.2s-1.9 1.2-3.4 1.7v-.2-.3l3.8-3.8c.2-.2.2-.4 0-.6-.2-.2-.4-.2-.6 0L22 20.4c-.2-.2-.3-.3-.6-.5-.2-.1-.3-.1-.5-.1h-2.8l-3-3c-.2-.2-.4-.2-.6 0-.2.2-.2.4 0 .6l2.4 2.4c-.1 0-.3-.1-.4-.1l-2.7-1.2c-.2-.1-.3-.1-.5-.1h-2.7v-1.1H5.7v9.3h4.8v-1.4h1.7l5.3 1.9c.1.1 1 .5 1.9.5 1 0 1.6-.5 1.6-.5l7-4.6s1-.6 1.2-1.4c.1-.5 0-.9-.2-1.2zM9.7 25.7H8.5v-5.5h-.8v5.5H6.5V18h3.2v7.7zm17.9-4l-7.1 4.7s-.5.3-1.1.3c-.6 0-1.3-.3-1.5-.4l-5.4-2h-1.9v-5.2H13.5l2.7 1.2c.3.1.6.2 1 .2h4c.4.2.5.5.5.8 0 .1 0 .2-.1.3-.3.4-.7.4-.9.4h-4.1c-.2 0-1.5.4-1.5 1.5v.4h.9v-.4c0-.4.7-.6.8-.7H21.4c1.7-.1 4.7-2 4.8-2.1 0 0 1.5-1 2.1-.4.1.2.2.3.1.4 0 .5-.6.9-.8 1z"
        />
    </svg>
);
CrmSvg.defaultProps = defaultSvgProps;

export default CrmSvg;