import { SearchFilterFormValues } from '../../../reducers/advanced_search_reducer';
import React from 'react';
import { getFormValues } from 'redux-form';
import { Field } from 'redux-form';
import { FormSelect, FormTextField } from '../../../utilities/forms';
import {
	Typography,
	Icon,
	Collapse,
	CardActions,
	IconButton,
	MenuItem,
	Grid,
} from '@material-ui/core';
import { connect } from '@optum-uhone-hmkts/rise';
import _ from 'lodash';
import {
	MinMax,
	ToFromPickers,
	MultiSelect,
} from '../../../components/redux_form_material';
import { ExpandFilterFields } from '../../../actions/advanced_search_actions';
import { themePalette } from '../../../utilities/branding';
import { Lookup, Lookups } from '../../../utilities/lookup';
import { Strings } from '../../../assets/common/strings';
import { normalizeCarrierStatus } from '../../../utilities/formatting/string_normalization';

interface Props {
	formName: string;
}

interface StateProps {
	formValues: SearchFilterFormValues;
	productTypes: string[];
	linesOfBusiness: string[];
	productStatusReasons: string[];
	carriers: string[];
	productTypeExpanded: boolean;
	productInfoExpanded: boolean;
	benefitAmountsExpanded: boolean;
	productsAdvancedExpanded: boolean;
}

interface DispatchProps {
	expandFilterFields: (filterFieldGroup: string) => void;
}

class _ProductFields extends React.Component<
	Props & StateProps & DispatchProps,
	{}
	> {

	constructor(props: any) {
		super(props);
		this.state = {
			expanded: false,
			infoExpanded: false,
			benefitAmountExpanded: false,
			advancedExpanded: false,
		};
	}

	public composeConsumerPossessionOptions = (): JSX.Element[] => {
		const possessionOptions = ['All', 'Any'];
		const possessionOptionsMenuList: JSX.Element[] = [];
		_.map(possessionOptions, (possessionOption: string, index: number) => {
			possessionOptionsMenuList.push(
				<MenuItem value={possessionOption} key={index}>
					{possessionOption}
				</MenuItem>
			);
		});
		return possessionOptionsMenuList;
	};

	handleExpandTypeClick = () => {
		this.props.expandFilterFields('productTypeExpanded');
	};

	handleExpandInfoClick = () => {
		this.props.expandFilterFields('productInfoExpanded');
	};

	handleExpandBenefitAmountClick = () => {
		this.props.expandFilterFields('benefitAmountsExpanded');
	};

	handleExpandAdvancedClick = () => {
		this.props.expandFilterFields('productsAdvancedExpanded');
	};

	render() {
		let isProductFacetHidden: boolean = false;
		if (
			this.props.formValues &&
			this.props.formValues.consumerType &&
			this.props.formValues.consumerType === Strings.ASearch.BLeads
		) {
			isProductFacetHidden = true;
		}

		return (
			<div hidden={isProductFacetHidden}>
				<Typography
					variant="h5"
					style={{ paddingLeft: 16, paddingTop: 16 }}
				>
					{'Products'}
				</Typography>
				<div onClick={this.handleExpandTypeClick} style={styles.pointer}>
					<CardActions style={{ marginBottom: '0px' }}>
						<span
							style={{
								fontSize: '20px',
								color: themePalette.sub_text,
								paddingLeft: 10,
							}}
						>
							Product Type
						</span>
						<IconButton
							style={
								this.props.productTypeExpanded
									? styles.expandOpen
									: styles.expand
							}
						>
							<Icon>keyboard_arrow_down</Icon>
						</IconButton>
					</CardActions>
				</div>
				<Collapse
					in={this.props.productTypeExpanded}
					style={{ padding: '0 20px' }}
				>
					<Grid container>
						<Grid item xs={3}>
							<span
								style={{
									fontSize: '14px',
									color: themePalette.sub_text,
								}}
							>
								Consumer has
							</span>
						</Grid>
						<Grid item xs={9}>
							<Field
								name="consumerHas"
								component={FormSelect}
								children={this.composeConsumerPossessionOptions()}
							/>
						</Grid>
					</Grid>
					<Field
						name="productTypesConsumerHas"
						component={MultiSelect}
						resultSet={this.props.productTypes}
						fullWidth
					/>
					<span
						style={{
							fontSize: '14px',
							color: themePalette.sub_text,
						}}
					>
						Consumer does not have any:
					</span>
					<Field
						name="productTypesConsumerDoesNotHave"
						component={MultiSelect}
						resultSet={this.props.productTypes}
						fullWidth
					/>
				</Collapse>
				<div onClick={this.handleExpandInfoClick} style={styles.pointer}>
					<CardActions style={{ marginBottom: '0px' }}>
						<span
							style={{
								fontSize: '20px',
								color: themePalette.sub_text,
								paddingLeft: 10,
							}}
						>
							Product Info
						</span>
						<IconButton
							style={
								this.props.productInfoExpanded
									? styles.expandOpen
									: styles.expand
							}
						>
							<Icon>keyboard_arrow_down</Icon>
						</IconButton>
					</CardActions>
				</div>
				<Collapse
					in={this.props.productInfoExpanded}
					style={{ padding: '0 20px' }}
				>
					<Field
						name="appStatus"
						label="Status"
						component={MultiSelect}
						resultSet={['Pending', 'Active', 'Terminated']}
						fullWidth
					/>
					<Field
						name="LOB"
						label="Line of Business"
						component={MultiSelect}
						resultSet={this.props.linesOfBusiness}
						fullWidth
					/>
					<Field
						name="carrier"
						label="Carrier"
						component={MultiSelect}
						resultSet={this.props.carriers}
						fullWidth
					/>
				</Collapse>
				<div
					onClick={this.handleExpandBenefitAmountClick}
					style={styles.pointer}
				>
					<CardActions style={{ marginBottom: '0px' }}>
						<span
							style={{
								fontSize: '20px',
								color: themePalette.sub_text,
								paddingLeft: 10,
							}}
						>
							Benefit Amounts
						</span>
						<IconButton
							style={
								this.props.benefitAmountsExpanded
									? styles.expandOpen
									: styles.expand
							}
						>
							<Icon>keyboard_arrow_down</Icon>
						</IconButton>
					</CardActions>
				</div>
				<Collapse
					in={this.props.benefitAmountsExpanded}
					style={{ padding: '0 20px' }}
				>
					<MinMax name="benefitAmount" title="Benefit Amount" min={0} />
					<MinMax name="faceAmount" title="Face Amount" min={0} />
					<MinMax name="familyDeductible" title="Family Deductible" min={0} />
					<MinMax
						name="familyMaxOutOfPocket"
						title="Family Max out of Pocket"
						min={0}
					/>
					<MinMax
						name="individualDeductible"
						title="Individual Deductible"
						min={0}
					/>
					<MinMax
						name="individualMaxOutOfPocket"
						title="Individual Max out of Pocket"
						min={0}
					/>
					<MinMax
						name="perPersonDeductible"
						title="Per Person Deductible"
						min={0}
					/>
					<MinMax
						name="perPersonMaxOutOfPocket"
						title="Per Person Max out of Pocket"
						min={0}
					/>
				</Collapse>
				<div onClick={this.handleExpandAdvancedClick} style={styles.pointer}>
					<CardActions style={{ marginBottom: '0px' }}>
						<span
							style={{
								fontSize: '20px',
								color: themePalette.sub_text,
								paddingLeft: 10,
							}}
						>
							Advanced
						</span>
						<IconButton
							style={
								this.props.productsAdvancedExpanded
									? styles.expandOpen
									: styles.expand
							}
						>
							<Icon>keyboard_arrow_down</Icon>
						</IconButton>
					</CardActions>
				</div>
				<Collapse
					in={this.props.productsAdvancedExpanded}
					style={{ padding: '0 20px' }}
				>
					<Field
						name="product"
						label="Product"
						component={FormTextField}
						fullWidth
					/>
					<Field
						name="metalLevel"
						label="Metal Level"
						component={MultiSelect}
						resultSet={[
							'Bronze',
							'Expanded Bronze',
							'Silver',
							'Gold',
							'Platinum',
							'Catastrophic',
						]}
						fullWidth
					/>
					<ToFromPickers name="applicationDate" title="Application Date" />
					<ToFromPickers name="issueDate" title="Issue Date" />
					<ToFromPickers name="effectiveDate" title="Effective Date" />
					<Field
						name="carrierStatus"
						label="Carrier Status"
						component={FormTextField}
						fullWidth
						normalize={normalizeCarrierStatus}
					/>
					<Field
						name="statusReason"
						label="Status Reason"
						component={MultiSelect}
						resultSet={this.props.productStatusReasons}
						fullWidth
					/>
					<MinMax name="monthlyPremium" title="Monthly Premium" min={0} />
					<MinMax name="monthlySubsidy" title="Monthly Subsidy" min={0} />
					<MinMax name="monthlyNetCost" title="Monthly Net Cost" min={0} />
					<MinMax
						name="numberOfApplicants"
						title="Number of Applicants"
						min={0}
					/>
					<Field
						name="policyNumber"
						component={FormTextField}
						label="Enter Policy Number"
						fullWidth
					/>
					<ToFromPickers name="policyEndDate" title="Policy End Date" />
					<Field
						name="ffmAppId"
						component={FormTextField}
						label="Enter FFM Application ID"
						fullWidth
					/>
					<Field
						name="appQuoteId"
						component={FormTextField}
						label="Quote ID"
						fullWidth
					/>
				</Collapse>
			</div>
		);
	}
}

function mapStateToProps(state: any, ownProps: Props): StateProps {
	

	let expandedStates = state.advancedSearch.expandedStates;

	return {
		formValues: getFormValues(ownProps.formName)(state),
		productTypes: state.lookup.getLabels(Lookups.ProductType),
		linesOfBusiness: state.lookup.getLabels(Lookups.ProductLineOfBusiness),
		productStatusReasons: state.lookup.getLabels(Lookups.ProductStatusReason),
		carriers: state.lookup.getLabels(Lookups.Carrier),
		productTypeExpanded: expandedStates.productTypeExpanded,
		productInfoExpanded: expandedStates.productInfoExpanded,
		benefitAmountsExpanded: expandedStates.benefitAmountsExpanded,
		productsAdvancedExpanded: expandedStates.productsAdvancedExpanded,
	};
}

function mapDispatchToProps(dispatch: any): DispatchProps {
	return {
		expandFilterFields: (filterFieldGroup: string) =>
			dispatch(ExpandFilterFields(filterFieldGroup)),
	};
}

export const ProductFields = connect(mapStateToProps, mapDispatchToProps, true)(
	_ProductFields
);

const styles: { [key: string]: React.CSSProperties } = {
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
	},
	expandOpen: {
		transform: 'rotate(180deg)',
		marginLeft: 'auto',
	},
	pointer: {
		cursor: 'pointer',
	},
};
