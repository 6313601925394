import { Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import { Dispatch } from '@reduxjs/toolkit';
import { Switch } from '@material-ui/core';
import { SimpleListItem } from '../utility/simple_list_item';
import { TransferStatus } from '../../reducers/LeadReducer';
import { LeadAccountSummary } from '../lead/lead_account_summary';
import { TotalLeadIntake } from '../lead/total_lead_intake';
import { GetAccountSummary } from '../../actions/lead_actions';
import { Strings } from '../../assets/common/strings';

interface StateProps {
	isReceivingLiveTransferLeads: TransferStatus;
	isReceivingLeadConnectLeads: TransferStatus;
	toggleLiveTransferLeads: (status: TransferStatus) => void;
	toggleLeadConnectLeads: (status: TransferStatus) => void;
	canUpdate: boolean;
}

interface DispatchProps {
	getLeadAccountSummary: () => void;
}

type Props = StateProps & DispatchProps;

const _LeadOrderStatusTile: React.FC<Props> = (props) => {

	const { canUpdate, isReceivingLeadConnectLeads, isReceivingLiveTransferLeads, toggleLeadConnectLeads, toggleLiveTransferLeads, getLeadAccountSummary } = props;

	useEffect(() => {
		getLeadAccountSummary();
	}, []);

	const allLeadsTitle = () => {
		if (isReceivingLeadConnectLeads == TransferStatus.Unknown)
			return (
				<span>{Strings.LeadTransferStatus.AllLeads}</span>
			);
		else
			return (
				<span>{Strings.LeadTransferStatus.AllLeads} <span style={styles.light}>({TransferStatus[isReceivingLeadConnectLeads]})</span></span>
			);
	};
	const liveTransferTitle = () => {
		return (
			<span>{Strings.LeadTransferStatus.LiveTransfer} <span style={styles.light}>({TransferStatus[isReceivingLiveTransferLeads]})</span></span>
		);
	}

	return (
		<>
			<SimpleListItem
				divider={false}
				title={allLeadsTitle()}
				subtitle={
					isReceivingLeadConnectLeads == TransferStatus.Unknown
						? Strings.LeadTransferStatus.UnableToReceive
						: undefined
				}
				secondaryAction={
					<Switch
						color="primary"
						onChange={() =>
							toggleLeadConnectLeads(
								isReceivingLeadConnectLeads == TransferStatus.Active
									? TransferStatus.Paused
									: TransferStatus.Active
							)
						}
						checked={isReceivingLeadConnectLeads == TransferStatus.Active}
						disabled={!canUpdate || isReceivingLeadConnectLeads == TransferStatus.Unknown}
					/>
				}
				style={styles.compact}
			/>
			<SimpleListItem
				divider={false}
				title={liveTransferTitle()}
				secondaryAction={
					<Switch
						color="primary"
						onChange={event =>
							toggleLiveTransferLeads(
								event.target.checked
									? TransferStatus.Active
									: TransferStatus.Paused
							)
						}
						checked={isReceivingLiveTransferLeads == TransferStatus.Active}
						disabled={!canUpdate || isReceivingLeadConnectLeads != TransferStatus.Active}
					/>
				}
				style={styles.compact}
			/>
			<Typography style={styles.header}>Account Summary</Typography>
			<LeadAccountSummary style={styles.panel} />
			<Typography style={styles.header}>Total Lead Intake</Typography>
			<TotalLeadIntake style={styles.table} />
		</>
	);
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	getLeadAccountSummary: () => dispatch(GetAccountSummary.started()),
});

export const LeadOrderStatusTile = connect(
	() => ({}),
	mapDispatchToProps,
	true
)(_LeadOrderStatusTile);

const styles: { [key: string]: React.CSSProperties } = {
	light: {
		color: 'rgba(0, 0, 0, 0.54)',
	},
	header: {
		fontSize: '1.125rem',
		marginBottom: '5px',
		marginTop: '20px',
	},
	compact: {
		padding: 0,
	},
	panel: {
		marginTop: 0,
	},
	table: {
		marginTop: 0,
	},
};