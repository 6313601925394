import { Action } from 'typescript-fsa';
import { call, put, all } from 'redux-saga/effects';
import http from '../utilities/http';
import { QueueSnackbar } from '../actions/snackbar_actions';
import {
	SearchLeads,
	SaveLeadStatus,
	SearchLeadsInput,
	SaveLeadStatusInput,
} from '../actions/lead_search_actions';
import { takeLatestForActionType } from '../utilities/saga_util';
import { getSnackbarSuccessProps, getSnackbarErrorProps } from '../utilities/snackbar_util';

// SEARCH LEADS
function* searchLeadsSaga(action: Action<SearchLeadsInput>) {
	try {
		const response = yield call(searchLeadsClient, action.payload);
		if (response.ok) {
			const data = yield response.json();
			yield put(SearchLeads.done({ params: action.payload, result: data }));
		} else {
			yield put(
				SearchLeads.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
		}
	} catch (error) {
		yield put(SearchLeads.failed({ params: action.payload, error }));
	}
}
function searchLeadsClient(payload: SearchLeadsInput) {
	const { pageIndex, pageSize, filters } = payload;
	return http(
		'leadSearch/',
		{
			method: 'POST',
			body: JSON.stringify(filters),
		},
		{ index: pageIndex, size: pageSize }
	);
}

// SAVE LEAD STATUS
function* saveLeadStatusSaga(action: Action<SaveLeadStatusInput>) {
	try {
		const response = yield call(saveLeadStatusClient, action.payload);

		if (response.ok) {
			yield put(
				QueueSnackbar(getSnackbarSuccessProps('Lead saved'))
			);
			yield put(SaveLeadStatus.done({
				params: action.payload,
				result: response
			}));
		} else {
			yield put(
				QueueSnackbar(getSnackbarErrorProps('Unable to save lead'))
			);
			yield put(SaveLeadStatus.failed({
				params: action.payload,
				error: response.error
			}));
		}
	} catch (error) {
		yield put(
			QueueSnackbar(getSnackbarErrorProps('Unable to save lead'))
		);
		yield put(SaveLeadStatus.failed({
			params: action.payload,
			error
		}));
	}
}
function saveLeadStatusClient(payload: SaveLeadStatusInput) {
	return http('lead/status', {
		method: 'POST',
		body: JSON.stringify(payload),
	});
}


export function* leadSearchSagas() {
	yield all([
		takeLatestForActionType(
			SearchLeads.started,
			searchLeadsSaga
		),
		takeLatestForActionType(
			SaveLeadStatus.started,
			saveLeadStatusSaga
		),
	])
}