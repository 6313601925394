import React from 'react';
import { Strings } from '../../assets/common/strings';
import { TabList } from '@material-ui/lab';
import {
    Tab
} from '@optum-uhone-hmkts/rise';
import { IconButton, Toolbar, AppBar } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import classnames from 'classnames';
import { useHeaderBarStyles } from '../../utilities/hooks/styles';
import { LicenseFilter } from '../../containers/agentLicenseAppointment/license/license_filter';
import { AppointmentFilter } from '../../containers/agentLicenseAppointment/appointment/appointment_filter';
import { nav } from '../..';

interface ParentProps {
    getTabValue: () => string;
    handleTabChange: (event: React.ChangeEvent<{}>, newValue: string) => void;
}
type Props = ParentProps;

const _LicenseAppointmentHeader: React.FC<Props> = (props) => {
    const { getTabValue, handleTabChange } = props;
    const headerStyles = useHeaderBarStyles();

    return (
        <>
            <AppBar position="sticky" className={classnames(headerStyles.depth, headerStyles.top)}>

                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="back" onClick={nav.goBack}>
                        <ChevronLeftIcon />
                    </IconButton>

                    <TabList
                        onChange={handleTabChange}
                    >
                        <Tab
                            label={Strings.LicenseAppointment.License}
                            value={Strings.LicenseAppointment.LicenseTab}
                            key={Strings.LicenseAppointment.LicenseTab}
                        />

                        <Tab
                            label={Strings.LicenseAppointment.Appointment}
                            value={Strings.LicenseAppointment.AppointmentTab}
                            key={Strings.LicenseAppointment.AppointmentTab}
                        />

                    </TabList>

                </Toolbar>

            </AppBar>

            {getTabValue() === Strings.LicenseAppointment.LicenseTab && (<LicenseFilter />)}

            {getTabValue() === Strings.LicenseAppointment.AppointmentTab && (<AppointmentFilter />)}
        </>
    );
};

export const LicenseAppointmentHeader = _LicenseAppointmentHeader;
