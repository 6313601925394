import React from 'react';
import { Loaded } from '../../utilities/utilities';
import { SuggestedValues } from './suggested_values';
import { Contact } from '../../reducers/ContactReducer';
import { ContactSearchResult } from './contact_search_result';
import { Strings } from '../../assets/common/strings';

interface Props {
    contacts: Loaded<Contact>[],
    selectedContactId: string,
    onSelectContact: (contact: Contact) => void,
    secondaryDisplay: (contact: Contact) => string;
}

export const ContactSuggestions: React.FC<Props> = ({
    contacts,
    selectedContactId,
    onSelectContact,
    secondaryDisplay
}) => {
    
    return (
        <SuggestedValues>
            {contacts.filter((contact) => (
                contact.data.status !== Strings.Status.Inactive
            ))
                .map((contact) => (
                    <ContactSearchResult
                        selected={contact.data.id === selectedContactId}
                        contact={contact.data}
                        key={'contact-' + contact.data.id}
                        onSelectContact={onSelectContact}
                        secondaryDisplay={secondaryDisplay(contact.data)}
                    />
                ))}
        </SuggestedValues>
    );
}