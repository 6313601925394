import {actionCreatorFactory} from 'typescript-fsa';
import { Action as ReduxAction } from 'redux';



const actionCreator = actionCreatorFactory('Authentication');

export const UserAuthenticated = actionCreator('User_Authenticated');
export function userAuthenticated(): ReduxAction {
	return UserAuthenticated();
}

export interface AuthOptions {
	redirect?: boolean;
	skipCheck?: boolean;
}
export const Authenticate = actionCreator.async<AuthOptions, boolean>('START_AUTH');
export const IncrementAuthTimer = actionCreator<number>('INCREMENT_TIMER');
export const ResetAuthTimer = actionCreator('RESET_TIMEOUT');

export const CheckTimeout = actionCreator('Check_Timeout');
export function checkTimeout(): ReduxAction {
	return CheckTimeout();
}

export const setIdledDialogVisible = actionCreator<boolean>('SET_IDLED_DIALOG_VISIBLE');

export const UpdateIdle = actionCreator('Update_Idle');

export const TouchIDEnabled = actionCreator<{ enabled: boolean }>(
	'TouchID_Set_Enabled'
);
export function touchIDEnabled(enabled: boolean): ReduxAction {
	return TouchIDEnabled({ enabled });
}

export const PinEnabled = actionCreator<{ enabled: boolean }>(
	'Pin_Set_Enabled'
);
export function pinEnabled(enabled: boolean): ReduxAction {
	return PinEnabled({ enabled });
}

export const PauseTimeout = actionCreator<{ paused: boolean }>(
	'Paused_Timeout'
);
export function pauseTimeout(paused: boolean): ReduxAction {
	return PauseTimeout({ paused });
}

export const ShowPin = actionCreator<boolean>('SHOW_PIN');


export const EnableFirstTimeLoginPrompt = actionCreator(
	'Enable_First_Time_Login_Prompt'
);
export function enableFirstTimeLoginPrompt(): ReduxAction {
	return EnableFirstTimeLoginPrompt();
}

export const DisableFirstTimeLoginPrompt = actionCreator(
	'Disable_First_Time_Login_Prompt'
);
export function disableFirstTimeLoginPrompt(): ReduxAction {
	return DisableFirstTimeLoginPrompt();
}

export const FinishFirstTimeLogin = actionCreator('Finish_First_Time_Login');
export function finishFirstTimeLogin(): ReduxAction {
	return FinishFirstTimeLogin();
}


export const SetLoginInfo = actionCreator<{
	username: string;
	password: string;
}>('Set_Login_Info');

export const DisplayConfirmUpdatePin = actionCreator(
	'Display_Confirm_Update_Pin'
);
export function displayConfirmUpdatePin(): ReduxAction {
	return DisplayConfirmUpdatePin();
}

export const ConfirmUpdatePin = actionCreator('Confirm_Update_Pin');
export function confirmUpdatePin(): ReduxAction {
	return ConfirmUpdatePin();
}

export const SecureStorageAvailable = actionCreator<{ available: boolean }>(
	'Secure_Storage_Available'
);
export function setSecureStorageAvailable(available: boolean): ReduxAction {
	return SecureStorageAvailable({ available });
}

export const ClearCaches = actionCreator('Clear_Caches');
export function clearCaches(): ReduxAction {
	return ClearCaches();
}


// Login specific actions
export type LoginTokens = {
	fedAuthCookie?: string;
	jwt: string;
	upn?: string;
};

export type VersionResponse = {
	Deprecated: boolean,
	NewVersionURL: string,
	Unsupported: boolean,
}

//Login actions
const login = actionCreatorFactory('LOGIN');

export const CheckVersion = login.async<{}, VersionResponse>('CHECK_VERSION')
export const NewLogin = login('NEW');
export const RefreshLogin = login('REFRESH_DESKTOP');
export const SuccessfulLogin = login<LoginTokens>("SUCCESS");
export const StoreJwt = login<string>("STORE_JWT");
export const CancelLogin = login('CANCEL');
export const Logout = login.async('LOGOUT');
export const ImpersonateJwt = login<string>('IMPERSONATE_JWT');
export const PromptTouchId = login('TOUCH_ID');

//Login errors
const loginError = actionCreatorFactory('LOGIN_ERROR');

export const FailedLogin = loginError<{ error: string }>("NEW_LOGIN");
export const FailedRefreshLogin = loginError<string>("REFRESH_LOGIN");
export const FailedTouchId = loginError<string>("FAILED_TOUCH_ID");