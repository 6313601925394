import {actionCreatorFactory} from 'typescript-fsa';
import { Action as ReduxAction } from 'redux';
import { Battle, BattleMessage, BattleListType } from '../reducers/battles_reducer';

const actionCreator = actionCreatorFactory('Battles');
export const ChangeBattlesList = actionCreator<{ battlesList: BattleListType }>(
	'CHANGE_BATTLES_LIST'
);

export function changeBattlesList(battlesList: BattleListType): ReduxAction {
	return ChangeBattlesList({ battlesList });
}

export const CreateBattle = actionCreator.async<Battle, {}>(
	'CREATE_BATTLE'
);

export const EditBattle = actionCreator.async<Battle, {}>(
	'EDIT_BATTLE'
);

export const GetBattle = actionCreator.async<string, Battle>(
	'GET_BATTLE'
);

export const DeleteBattle = actionCreator.async<string, {}>(
	'DELETE_BATTLE'
);

export const GetBattleList = actionCreator.async< {}, Battle[]>(
	'GET_BATTLE_LIST'
);

export interface BattleChoice {
	id: string,
	acceptedBattle: boolean
}
export const AcceptOrRejectBattle = actionCreator.async<BattleChoice, {}>(
	'ACCEPT_OR_REJECT'
);

export const AddBattleMessage = actionCreator.async<BattleMessage, {}>(
	'ADD_BATTLE_MESSAGE'
);

export const DeleteBattleMesssages = actionCreator.async<{}, {}>(
	'DELETE_BATTLE_MESSAGES'
);