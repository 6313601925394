import uuid from 'uuid';
import { isMobileDevice } from "../is_mobile";
import { submitLog } from '../logging_util';
import { Strings } from '../../assets/common/strings';
import { AppConfig } from '../../types/config';

declare const ga: any;
let initialized = false;

export class GAService {

    static initialize() {
        if (!initialized) {
            window.ga =
            window.ga || function() {
                (ga.q = ga.q || []).push(arguments);
            };

            ga.l = +new Date();

            GAService.createEvent(uuid.v4());
        }
        initialized = true;
    }

    static createEvent(clientId: string) {
        try {
            const ga = window.ga;
            if (ga) {
                ga('create', AppConfig.ga_id, {
                    storage: 'none',
                    clientId,
                });
            }
            if (isMobileDevice) {
                ga('set', 'checkProtocolTask', null);
                ga('set', 'anonymizeIp', true);
                ga('set', 'forceSSL', true);
                // ga('set', 'dimension1', `${device?.model}`);
                ga('set', 'dimension2', `${VERSION}`);
                ga('set', 'userId', 'Unknown');
            }
        }
        catch (err) {
            console.error(err);
        }
    }

    static sendEvent(category: string, title: string, value?: string) {
        try {
            const ga = (navigator as any).analytics ?? window.ga;
            if (ga) {
                ga.sendEvent?.(category, title, value);
                ga('send', 'event', category, title, value);
            }
        }
        catch (error) {
            submitLog(Strings.Log.Error, 'Error sending GA event', { error });
        }
    }

    static sendDeviceEvent(userId: string, deviceId: string) {
        const ga = window.ga;
        if (ga) {
            ga('set', 'userId', userId);
            ga('send', 'event', 'Login', userId, deviceId);
        }
    }

    static endSession() {
        const ga = window.ga;
        if (ga) {
            ga('set', 'userId', 'Unknown');
        }
    }

}