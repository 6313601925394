import React from 'react';
import { defaultSvgProps, SvgProps } from '..';

const ClientConnectSvg: React.FC<SvgProps> = (props) => (
    <svg viewBox="0 0 35 35" {...props}>
        <path
            d="M28.85 29.12H7.08a.5.5 0 0 1-.5-.5V6.85a.5.5 0 0 1 .5-.5h21.77a.5.5 0 0 1 .5.5v21.77a.5.5 0 0 1-.5.5zm-21.27-1h20.77V7.35H7.58z"
        />
        <path
            d="M28.49 13.18h-21a.5.5 0 0 1-.5-.5.5.5 0 0 1 .5-.5h21a.5.5 0 0 1 .5.5.5.5 0 0 1-.5.5zM10 8.66a1.11 1.11 0 1 0 1.1 1.1 1.1 1.1 0 0 0-1.1-1.1zM13.19 8.66a1.11 1.11 0 1 0 1.1 1.1 1.11 1.11 0 0 0-1.1-1.1zM16.36 8.66a1.11 1.11 0 0 0 0 2.21 1.11 1.11 0 1 0 0-2.21zM25.36 18.19h-6.83a.5.5 0 0 1-.5-.5.5.5 0 0 1 .5-.5h6.83a.5.5 0 0 1 .5.5.51.51 0 0 1-.5.5zM25.36 21.35h-6.83a.5.5 0 0 1-.5-.5.5.5 0 0 1 .5-.5h6.83a.51.51 0 0 1 .5.5.5.5 0 0 1-.5.5zM25.36 24.52h-6.83A.5.5 0 0 1 18 24a.5.5 0 0 1 .5-.5h6.83a.51.51 0 0 1 .5.5.5.5 0 0 1-.47.52zM13 16.63a1.88 1.88 0 0 0 0 3.74 1.88 1.88 0 0 0 0-3.74zm0 2.87a1 1 0 0 1 0-2 1 1 0 0 1 0 2zM14.91 21a5.7 5.7 0 0 0-3.8 0 1.62 1.62 0 0 0-1 1.54l.1 1.54a.45.45 0 0 0 .44.41h4.79a.43.43 0 0 0 .43-.41L16 22.5a1.65 1.65 0 0 0-1.09-1.5zm-4 1.49a.78.78 0 0 1 .46-.67 4.88 4.88 0 0 1 3.2 0 .8.8 0 0 1 .47.67L15 23.58h-4z"
        />
    </svg>
);
ClientConnectSvg.defaultProps = defaultSvgProps;

export default ClientConnectSvg;
