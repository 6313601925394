import {actionCreatorFactory} from 'typescript-fsa';
import { FcmNotification } from '../utilities/event_listeners/fcm_listeners';
import { MessagePayload } from 'firebase/messaging';

export type FcmError = Error & { message: string};

const actionCreator = actionCreatorFactory('FCM');

export const FcmRegistration = actionCreator.async<void, boolean>('REGISTRATION');
export const ReceivedFcmNotification = actionCreator<MessagePayload>('RECEIVED_NOTIFICATION_DESKTOP');
export const FcmError = actionCreator<FcmError>('ERROR');