import { call, put, all } from 'redux-saga/effects';
import http from '../utilities/http';
import { GetLookupData, LookupData, removeHiddenValuesThenStoreLookups } from '../actions/lookup_actions';
import { QueueSnackbar } from '../actions/snackbar_actions';
import { Strings } from '../assets/common/strings';
import { takeLatestForActionType } from '../utilities/saga_util';
import { Action } from 'typescript-fsa';


// GET LOOKUPS FROM WEB API
export function* handleGetLookupData(action: Action<undefined>) {
	try {
		const response = yield call(http, Strings.ApiUrls.Lookups);

		if (response.ok) {
			const lookupData: LookupData[] = yield response.json();
			yield put(removeHiddenValuesThenStoreLookups(lookupData));
		} else {
			yield put(QueueSnackbar({ text: 'Unable to load lookup data' }));
		}
	} catch (error) {
		yield put(QueueSnackbar({ text: 'Unable to load lookup data' }));
	}
}

export function* lookupSagas() {
	yield all([
		takeLatestForActionType(
			GetLookupData,
			handleGetLookupData
		),
	]);
}