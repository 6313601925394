import React from 'react';
import { enforceStylesType } from '../../utilities/styles_util';

interface Props {
    submit: () => void;
    buttonTitle?: string;
    hideOverflow?: boolean;
};
export const BaseFilterForm: React.FC<Props> = (props) => {

    const { submit, buttonTitle, hideOverflow=false, children } = props;

    const apply = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        submit();
    };

    return (
        <form method="post" onSubmit={apply} style={!hideOverflow ? styles.scroll : {}}>
            <input type="submit" style={styles.hidden}>{buttonTitle}</input>
            {children}
        </form>
    );
}

const styles: {[key: string]: React.CSSProperties} = enforceStylesType({
    hidden: {
        width: 0,
        height: 0,
        display: 'none',
        position: 'absolute',
    },
    scroll: {
        overflowY: 'scroll'
    }
});
