import actionCreatorFactory, {
	AsyncActionCreators,
} from 'typescript-fsa';
import { TeamViewRow, TeamViewYTDGraph, TeamViewLeadsGraph } from '../reducers/teamview_reducer';
import { Strings } from '../assets/common/strings';

const actionCreator = actionCreatorFactory('Productivity');

export interface ProductivityParams {
	startDate: Date;
	endDate: Date;
	dateRange?: string;
	agentCode?: string;
	lineOfBusiness?: string;
	comparePrevious?: boolean;
	dateRangeOverride?: number;
    utcOffset: number;
    parentPage?: Strings.TeamViewAPI.TEAMVIEW_API | Strings.TeamViewAPI.PERFORMANCE_API;
}

export type ProductivityActionCreator = AsyncActionCreators<
	ProductivityParams,
	any
>;

export const GetYtdAVReport = actionCreator.async<
	ProductivityParams,
	TeamViewYTDGraph[]
>('GET_YTDAV');

export const GetNewLeadsReport = actionCreator.async<
	ProductivityParams,
	TeamViewLeadsGraph[]
>('GET_LEADS_CHART');

export const GetSurebridgePersistencyReport = actionCreator.async<
    ProductivityParams,
	TeamViewRow[]
>('GET_SUREBRIDGE_PERSISTENCY');

export const GetSurebridgePersistencyAvgAndRank = actionCreator.async<
    ProductivityParams,
	TeamViewRow
>('GET_SUREBRIDGE_PERSISTENCY_AVG_RANK');
