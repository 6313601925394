import _ from 'lodash';
import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
} from '@material-ui/core';
import React from 'react';
import { QueueSnackbar } from '../../actions/snackbar_actions';
import { themePalette } from '../../utilities/branding';
import { Application } from '../../reducers/application_reducer';
import { Policy, ProductStatus } from '../../reducers/policy_reducer';
import {
	getProductStatus,
	getProductStatusReason,
} from '../../utilities/product_util';
import { Loaded } from '../../utilities/utilities';
import { ReadOnlyTextField } from '../utility/read_only_text_field';
import { getSnackbarErrorProps } from '../../utilities/snackbar_util';
import { ToggleCard } from '../higher_order_component/wrap_in_card';

interface UnlinkedPolicyListProps {
	policies: Policy[];
	currentApplication: Loaded<Application>;
	getAllUnlinkedPolicies: () => void;
	linkPolicy: (policy: Policy) => void;
	householdId: string;
}

interface UnlinkedPolicyState {
	modalVisible: boolean;
	selectedPolicy?: Policy;
}

export class UnlinkedPolicyList extends React.Component<
	UnlinkedPolicyListProps,
	UnlinkedPolicyState
	> {
	componentWillMount() {
		this.setState({
			modalVisible: false,
		});

		this.props.getAllUnlinkedPolicies();
	}

	linkPolicy() {
		this.setState({
			modalVisible: false,
		});

		if (this.state.selectedPolicy) {
			this.state.selectedPolicy.applicationId = this.props.currentApplication.data.id;
			const currentPolicy: Policy = this.state.selectedPolicy;
			this.props.linkPolicy(currentPolicy);
		} else {
			QueueSnackbar(getSnackbarErrorProps('Could not link application', 6000));
		}
	}

	renderLinkConfirmation() {
		return (
			<div>
				<Dialog
					open={this.state.modalVisible}
					onClose={() => {
						this.setState({
							modalVisible: false,
						});
					}}
				>
					<DialogTitle>Confirmation</DialogTitle>
					<DialogContent>
						Are you sure you want to link this policy?
					</DialogContent>
					<DialogActions>
						<Button
							color="secondary"
							onClick={() =>
								this.setState({
									modalVisible: false,
								})
							}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							style={{ backgroundColor: themePalette.accept_button }}
							onClick={() => this.linkPolicy()}
						>
							Link
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}

	populatePolicyList(policies: Policy[]): JSX.Element[] {
		const policyCardList: JSX.Element[] = [];

		policies.forEach((policy, index) => {
			const carrier = _.get(policy, 'carrier', '[No Carrier]');
			const status =
				ProductStatus[getProductStatus(undefined, policy)] || '[No Status]';
			const productType = _.get(policy, 'productType', '[No Product Type]');
			const statusReason =
				getProductStatusReason(undefined, policy) || '[No Status Reason]';
			const firstName = _.get(
				policy,
				'primaryInsured.firstName',
				'[No First Name]'
			);
			const lastName = _.get(
				policy,
				'primaryInsured.lastName',
				'[No Last Name]'
			);
			const city = _.get(
				policy,
				'primaryInsured.addresses[0].city',
				'[No City]'
			);
			const state = _.get(
				policy,
				'primaryInsured.addressess[0].state',
				'[No State]'
			);

			policyCardList.push(
				<div key={index}>
					<CardContent>
						<Grid container>
							<Grid item xs={6}>
								<ReadOnlyTextField value={carrier} />
							</Grid>
							<Grid item xs={6}>
								<ReadOnlyTextField value={status} />
							</Grid>
						</Grid>
						<Grid container>
							<Grid item xs={6}>
								<ReadOnlyTextField value={productType} />
							</Grid>
							<Grid item xs={6}>
								<ReadOnlyTextField value={statusReason} />
							</Grid>
						</Grid>
						<Grid container>
							<Grid item xs={6}>
								<ReadOnlyTextField value={firstName + ' ' + lastName} />
							</Grid>
							<Grid item xs={6}>
								<ReadOnlyTextField value={city + ', ' + state} />
							</Grid>
						</Grid>
						<Grid container>
							<Grid item xs={12}>
								<Button
									variant="contained"
									color="primary"
									fullWidth={true}
									onClick={() =>
										this.setState({
											selectedPolicy: policy,
											modalVisible: true,
										})
									}
								>
									Link
								</Button>
							</Grid>
						</Grid>
					</CardContent>
					<Divider />
				</div>
			);
		});
		return policyCardList;
	}

	filterByHousehold(): Policy[] {
		const policies = this.props.policies;
		return policies.filter(policy => {
			const primaryInsuredHouseholdId = policy.primaryInsured
				? policy.primaryInsured.householdId
				: '';

			return (!this.props.householdId || primaryInsuredHouseholdId === this.props.householdId)
				&& !policy.applicationId && primaryInsuredHouseholdId;
		});
	};

	render() {
		let policies: Policy[] = [];
		if (Array.isArray(this.props.policies)) {
			policies = this.filterByHousehold();
		}

		return (
			<div>
				<ToggleCard
					title={'Policies not Linked to an Application'}
				>
					{policies.length > 0
						? this.populatePolicyList(policies)
						: <ReadOnlyTextField value={'No unmatched policies at this time'} />
					}
				</ToggleCard>
				{this.renderLinkConfirmation()}
			</div>
		);
	}
}
