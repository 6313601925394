import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Icon,
	IconButton,
	Tooltip,
} from '@material-ui/core';
import React from 'react';
import { themePalette } from '../../utilities/branding';
import { isMobileDevice } from '../../utilities/is_mobile';
import { makeOpenExternalLink } from '../../utilities';
import { AppConfig } from '../../types/config';

interface AccountHealthTooltipProps { }

interface AccountHealthTooltipState {
	accountHealthDialogOpen: boolean;
}

export class AccountHealthTooltip extends React.Component<
	AccountHealthTooltipProps,
	AccountHealthTooltipState
	> {
	constructor(props: any) {
		super(props);
		this.state = {
			accountHealthDialogOpen: false,
		};
	}

	private get tooltipTextMobile() {
		return (
			<div>
				Letter grade indicating the quality (taken rate and/or persistency) of
				your business
			</div>
		);
	}

	private get tooltipTextDesktop() {
		return (
			<div>
				<div
					style={{
						fontSize: '12px',
						whiteSpace: 'normal',
						lineHeight: '15px',
						padding: '10px',
					}}
				>
					Letter grade indicating the quality (taken rate and/or persistency) of
					your business
				</div>
				<div style={{ textAlign: 'center' }}>Click for more info</div>
			</div>
		);
	}

	public handleOpen = () => {
		this.setState({
			accountHealthDialogOpen: true,
		});
	};

	public handleClose = () => {
		this.setState({
			accountHealthDialogOpen: false,
		});
	};

	public openFinanceLink = makeOpenExternalLink(AppConfig.finance_link);

	public render() {
		return isMobileDevice
			? <div>
				<IconButton onClick={this.handleOpen}>
					<Icon style={{ color: themePalette.tertiary_text }}>help</Icon>
				</IconButton>
				<Dialog
					open={this.state.accountHealthDialogOpen}
					onClose={this.handleClose}
				>
					<DialogContent>{this.tooltipTextMobile}</DialogContent>
					<DialogActions>
						<Button color="secondary" onClick={this.handleClose}>
							Cancel
							</Button>
						<Button color="primary" onClick={this.openFinanceLink}>
							More Info
							</Button>
					</DialogActions>
				</Dialog>
			</div>
			: <Tooltip title={this.tooltipTextDesktop}>
				<IconButton onClick={this.openFinanceLink}>
					<Icon style={{ color: themePalette.tertiary_text }}>help</Icon>
				</IconButton>
			</Tooltip>;
	}
}