import { AppState } from '../reducers/index';
import { createSelector } from 'reselect';
import { RouteComponentProps } from 'react-router-dom';
import { EMPTY_EMPLOYER, loaded } from '../utilities/empty_entities';

/**
 * Employer selectors
 */
const getEmployers = (state: AppState, props: any) =>
	state.employer.employers || [];

const getEmployerId = <Props extends RouteComponentProps<any>>(
	state: any,
	props: Props
): string => props.match.params.employerID;

export const getPageEmployer = createSelector(
	[getEmployers, getEmployerId],
	(employers, employerId) => {
		const employerIndex = employers.findIndex(
			loadedEmployer =>
				loadedEmployer.data.id == employerId ||
				loadedEmployer.employerId == employerId
		);
		return employerIndex > -1
			? { ...employers[employerIndex] }
			: loaded(EMPTY_EMPLOYER, { employerId });
	}
);
