import { Action } from 'typescript-fsa';
import { call, put, all, debounce } from 'redux-saga/effects';
import {
	CreateContact,
	GetContact,
	UpdateContact,
	DeleteContact,
	ContactLiveSearch,
	AddContactToEmployer,
	ContactLiveSearchParams,
} from '../actions/contact_actions';
import http, { HttpOptions } from '../utilities/http';
import { Contact, HouseholdRole, ContactDto } from '../reducers/ContactReducer';
import { QueueSnackbar } from '../actions/snackbar_actions';
import { setNewPrimaryContactClient } from './household_sagas';
import { StoreInfiniteScrollMeta } from '../actions/infinite_scroll_actions';
import { takeLatestForActionType, takeEveryForActionType, selectFromImmutable } from '../utilities/saga_util';
import { getSnackbarErrorProps, getSnackbarSuccessProps } from '../utilities/snackbar_util';
import { AppState } from '../reducers';
import { isAgentRole } from '../utilities/agent_util';
import { Strings } from '../assets/common/strings';

// GET CONTACT

function* getContactSaga(action: Action<string>) {
	try {
		const response = yield call(getContactClient, action.payload);
		if (response.ok) {
			const data: ContactDto = yield response.json();
			yield put(GetContact.done({ params: action.payload, result: data }));
		} else {
			yield put(
				GetContact.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
		}
	} catch (error) {
		yield put(GetContact.failed({ params: action.payload, error }));
	}
}
function getContactClient(id: string) {
	return http('contact/' + id);
}

// UPDATE CONTACT
function* updateContactSaga(action: Action<Contact>) {
	const errorSnackbarProps = getSnackbarErrorProps('Unable to update contact');
	try {
		const contactToUpdate = action.payload;
		let shouldUpdateContact = true;
		if (contactToUpdate.householdRole == HouseholdRole.Primary) {
			const markAsPrimary = yield call(
				setNewPrimaryContactClient,
				contactToUpdate.householdId,
				contactToUpdate.id
			);
			shouldUpdateContact = markAsPrimary.ok;
		}
		if (shouldUpdateContact) {
			const response = yield call(updateContactClient, action.payload);
			if (response.ok) {
				const updatedContact: Contact = yield response.json();
				yield all([
					put(
						UpdateContact.done({
							params: action.payload,
							result: updatedContact,
						})
					),
					put(QueueSnackbar(getSnackbarSuccessProps(updatedContact.employerId ? 'Employee updated' : 'Person updated'))),
				]);
			} else {
				yield put(
					UpdateContact.failed({
						params: action.payload,
						error: {
							errorCode: response.status,
						},
					})
				);
				yield put(QueueSnackbar(errorSnackbarProps));
			}
		} else {
			yield put(
				UpdateContact.failed({
					params: action.payload,
					error: {
						errorCode: 'Failed to mark as primary',
					},
				})
			);
			yield put(QueueSnackbar(errorSnackbarProps));
		}
	} catch (error) {
		yield put(UpdateContact.failed({ params: action.payload, error }));
		yield put(QueueSnackbar(errorSnackbarProps));
	}
}

// ADD CONTACT TO EMPLOYER
function* addContactToEmployer(action: Action<Contact>) {
	const contactToUpdate = action.payload;
	try {
		const response = yield call(updateContactClient, action.payload);
		if (response.ok) {
			const updatedContact: Contact = yield response.json();
			yield put(
				AddContactToEmployer.done({
					params: contactToUpdate,
					result: updatedContact,
				})
			);
			yield put(
				QueueSnackbar(getSnackbarSuccessProps('Employee added'))
			);
		} else {
			yield put(
				AddContactToEmployer.failed({
					params: contactToUpdate,
					error: { errorCode: response.status },
				})
			);
			yield put(
				QueueSnackbar(getSnackbarErrorProps('Unable to add contact'))
			);
		}
	} catch (error) {
		yield put(
			AddContactToEmployer.failed({ params: contactToUpdate, error })
		);
		yield put(
			QueueSnackbar(getSnackbarErrorProps('Unable to add contact'))
		);
	}
}
function updateContactClient(contact: Contact) {
	const options: HttpOptions = {
		method: 'PUT',
		body: JSON.stringify(contact),
	};

	return http('contact', options);
}

// CREATE CONTACT
function* createContactSaga(action: Action<Contact>) {
	try {
		const meta = action.meta as any;
		const dupeCheck = (meta && meta.dupeCheck == undefined) ? true : meta.dupeCheck
		const response = yield call(createContactClient, action.payload, dupeCheck);
		if (response.ok) {
			const data: Contact = yield response.json();
			yield put(CreateContact.done({ params: action.payload, result: data }));
			yield put(
				QueueSnackbar(getSnackbarSuccessProps(data.employerId ? 'Employee added' : 'Person added'))
			);
		} else {
			yield put(
				CreateContact.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
			yield put(
				QueueSnackbar(getSnackbarErrorProps('Failed to create contact'))
			);
		}
	} catch (error) {
		yield put(CreateContact.failed({ params: action.payload, error }));
		yield put(
			QueueSnackbar(getSnackbarErrorProps('Failed to create contact'))
		);
	}
}
function createContactClient(contact: Contact, dupeCheck: boolean) {
	const options: HttpOptions = {
		method: 'POST',
		body: JSON.stringify(contact),
	};

	return http('contact?dupeCheck=' + dupeCheck.toString(), options);
}

// DELETE CONTACT
function* deleteContactSaga(action: Action<Contact>) {
	try {
		const contactNoMed: Contact = {
			...action.payload,
			medicareInfo: undefined,
		};
		const response = yield call(http, 'contact', {
			method: 'DELETE',
			body: JSON.stringify(contactNoMed),
		});
		if (response.ok) {
			const data: string = yield response.json();
			yield all([
				put(DeleteContact.done({ params: action.payload, result: data })),
				put(QueueSnackbar(getSnackbarErrorProps('Person deleted'))),
			]);
		} else {
			const error = yield response.json();
			yield all([
				put(
					DeleteContact.failed({
						params: action.payload,
						error: { errorCode: response.status },
					})
				),
				put(QueueSnackbar(getSnackbarErrorProps(error.Message || 'Failed to delete contact')))
			]);
		}
	} catch (error) {
		yield all([
			put(DeleteContact.failed({ params: action.payload, error })),
			put(QueueSnackbar(getSnackbarErrorProps('Failed to delete contact')))
		]);
	}
}

// CONTACT SEARCH
function* contactLiveSearchSaga(action: Action<ContactLiveSearchParams>) {
	const snackbarProps = getSnackbarErrorProps('Error searching contacts');
	const {
		searchText,
		includeEmployees,
		pageRequested,
		pageSize,
	} = action.payload;
	try {
		const state: AppState = yield selectFromImmutable<AppState>();
		const downlineOnly = isAgentRole([Strings.AgentRoles.SponsorAgent]) && state.product.downlineAgent.agentCode !== '';
		const response = yield call(
			contactLiveSearchClient,
			searchText,
			includeEmployees,
			downlineOnly,
			pageRequested,
			pageSize
		);

		if (response.ok) {
			const contacts: Contact[] = yield response.json();
			yield all([
				put(
					ContactLiveSearch.done({ params: action.payload, result: contacts })
				),
				put(
					StoreInfiniteScrollMeta({
						query: searchText,
						pageSize,
						pageRequested,
						resultSize: contacts.length,
					})
				),
			]);
		} else {
			yield put(QueueSnackbar(snackbarProps));
		}
	} catch (error) {
		yield put(QueueSnackbar(snackbarProps));
	}
}
function contactLiveSearchClient(
	searchText: string,
	includeEmployees: boolean,
	downlineOnly: boolean,
	pageIndex: number,
	pageSize: number
) {
	const options: HttpOptions = {
		method: 'GET',
	};
	const searchParam = searchText.length > 0 ? searchText : '.';
	return http(
		`contact/search/${searchParam}/${includeEmployees}/${downlineOnly}` + '?scope=Me',
		options,
		{
			index: pageIndex,
			size: pageSize,
		}
	);
}

export function* contactSagas() {
	yield all([
		takeEveryForActionType(
			GetContact.started,
			getContactSaga
		),
		takeEveryForActionType(
			UpdateContact.started,
			updateContactSaga
		),
		takeLatestForActionType(
			AddContactToEmployer.started,
			addContactToEmployer
		),
		takeLatestForActionType(
			CreateContact.started,
			createContactSaga
		),
		takeLatestForActionType(
			DeleteContact.started,
			deleteContactSaga
		),
		debounce(
			1000,
			ContactLiveSearch.started,
			contactLiveSearchSaga
		),
	]);
}