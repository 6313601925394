import { EMPTY_GUID } from './../utilities/empty_entities';
import { call, put, all, debounce } from 'redux-saga/effects';
import { isType, Action } from 'typescript-fsa';
import http, { HttpOptions } from '../utilities/http';
import {
	GetEmployer,
	UpdateEmployer,
	SetPrimaryEmployee,
	DeleteEmployer,
	SearchEmployers,
	ReassignEmployer,
	SetPrimaryEmployerParams,
	ReassignEmployerParams,
	EmployerTagPayload,
	saveEmployerTags,
	getEmployerTags,
} from '../actions/employer_actions';
import { Lead } from '../reducers/LeadReducer';
import { Activity } from '../reducers/activity_reducer';
import { Policy } from '../reducers/policy_reducer';
import { Application } from '../reducers/application_reducer';
import { Attachment } from '../reducers/attachment_reducer';
import { Note } from '../reducers/note_reducer';
import { OwnershipHistory } from '../reducers/ownership_history_reducer';
import { Employer } from '../reducers/employer_reducer';
import { QueueSnackbar } from '../actions/snackbar_actions';
import {
	navigateBack,
	navigateToWithoutAddingToHistory,
} from '../actions/navigation_actions';
import { navRoutes } from '../components/nav/Routes';
import { getEmailHistorySummarySetByEmployer } from '../actions/email_summary_actions';
import { submitLog } from '../utilities/logging_util';
import { Strings } from '../assets/common/strings';
import { takeLatestForActionType, takeEveryForActionType } from '../utilities/saga_util';
import { getSnackbarErrorProps, getSnackbarSuccessProps } from '../utilities/snackbar_util';
import { TagDto } from '../reducers/shared/tags';

export interface FullEmployer extends Employer {
	leads: Lead[];
	activities: Activity[];
	policies: Policy[];
	applications: Application[];
	attachments: Attachment[];
	notes: Note[];
	ownershipHistory: OwnershipHistory[];
}

// GET EMPLOYER BY ID
function* getEmployerSaga(action: Action<string>) {
	const errorSnackbarProps = getSnackbarErrorProps('Error getting employer data');
	try {
		const response = yield call(getEmployerClient, action.payload);

		if (response.ok) {
			const fullEmployer: FullEmployer = yield response.json();
			if (!fullEmployer) return;

			hydrateEmployerLeadContacts(fullEmployer);
			yield put(
				GetEmployer.done({ params: action.payload, result: fullEmployer })
			);
			yield put(getEmailHistorySummarySetByEmployer.started(action.payload));
		} else {
			yield put(
				GetEmployer.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
			yield put(QueueSnackbar(errorSnackbarProps));
		}
	} catch (error) {
		yield put(GetEmployer.failed({ params: action.payload, error }));
		yield put(QueueSnackbar(errorSnackbarProps));
	}
}
export function getEmployerClient(id: string): Promise<any> {
	return http('employer/' + id);
}

// UPDATE EMPLOYER
function* updateEmployerSaga(action: Action<Employer>) {
	const errorSnackbarProps = getSnackbarErrorProps('Unable to update employer');
	try {
		const response = yield call(updateEmployerFetch, action.payload);
		if (response.ok) {
			const data: FullEmployer = yield response.json();
			hydrateEmployerLeadContacts(data);

			yield put(
				UpdateEmployer.done({ params: action.payload, result: data })
			);
			yield put(
				QueueSnackbar(getSnackbarSuccessProps('Employer updated'))
			);
		} else {
			yield put(
				UpdateEmployer.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
			yield put(QueueSnackbar(errorSnackbarProps));
		}
	} catch (ex) {
		yield put(UpdateEmployer.failed({ params: action.payload, error: ex }));
		yield put(QueueSnackbar(errorSnackbarProps));
	}
}
function updateEmployerFetch(employer: Employer) {
	return http('employer/', {
		method: 'PUT',
		body: JSON.stringify(employer),
	});
}

// SET PRIMARY EMPLOYEE
function* setPrimaryEmployeeSaga(action: Action<SetPrimaryEmployerParams>) {
	const errorSnackbarProps = getSnackbarErrorProps('Unable to set primary');
	try {
		const response = yield call(
			setPrimaryEmployeeClient,
			action.payload.employerId,
			action.payload.contactId
		);
		if (response.ok) {
			const data: string = yield response.json();
			yield put(
				SetPrimaryEmployee.done({ params: action.payload, result: data })
			);
		} else {
			yield put(
				SetPrimaryEmployee.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
			yield put(QueueSnackbar(errorSnackbarProps));
		}
	} catch (ex) {
		yield put(
			SetPrimaryEmployee.failed({ params: action.payload, error: ex })
		);
		yield put(QueueSnackbar(errorSnackbarProps));
	}
}
function setPrimaryEmployeeClient(employerId: string, contactId: string) {
	return http(`employer/SetPrimaryContact/${employerId}/${contactId}`, {
		method: 'POST',
	});
}

// DELETE EMPLOYER
function* deleteEmployerSaga(action: Action<string>) {
	if (isType(action, DeleteEmployer.started)) {
		try {
			const response = yield call(deleteEmployerFetch, action.payload);
			if (response.ok) {
				const data: string = yield response.json();
				yield put(
					DeleteEmployer.done({ params: action.payload, result: data })
				);
			} else {
				yield put(
					DeleteEmployer.failed({
						params: action.payload,
						error: { errorCode: response.status },
					})
				);
			}
		} catch (ex) {
			yield put(DeleteEmployer.failed({ params: action.payload, error: ex }));
		}
	}
}
function deleteEmployerFetch(employerId: string) {
	return http('employer/' + employerId, {
		method: 'DELETE',
	});
}

// SEARCH EMPLOYERS
function* searchEmployersSaga(action: Action<string>) {
	const errorSnackbarProps = getSnackbarErrorProps('Failed to search employers');
	try {
		const response = yield call(searchEmployersClient, action.payload);

		if (response.ok) {
			const data: FullEmployer[] = yield response.json();
			data.forEach(fullEmployer => hydrateEmployerLeadContacts(fullEmployer));

			yield put(
				SearchEmployers.done({ params: action.payload, result: data })
			);
		} else {
			yield put(
				SearchEmployers.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
			yield put(QueueSnackbar(errorSnackbarProps));
		}
	} catch (error) {
		yield put(SearchEmployers.failed({ params: action.payload, error }));
		yield put(QueueSnackbar(errorSnackbarProps));
	}
}
function searchEmployersClient(searchText: string): Promise<any> {
	return http('employer/search/' + searchText + '?scope=Me');
}

// REASSIGN EMPLOYER
function* startReassignEmployerSaga(action: Action<ReassignEmployerParams>) {
	const errorSnackbarProps = getSnackbarErrorProps(`Unable to reassign employer`);
	try {
		const response = yield call(
			reassignEmployerClient,
			action.payload.employerId,
			action.payload.agentCode
		);
		if (response.ok) {
			const data: boolean = yield response.json();
			yield put(
				ReassignEmployer.done({ params: action.payload, result: data })
			);
			yield put(QueueSnackbar(getSnackbarSuccessProps(`This lead has been reassigned to ${action.payload.agentName}`)));
			yield put(
				navigateToWithoutAddingToHistory(navRoutes.leadDashboard.path)
			);
		} else {

			yield put(
				ReassignEmployer.failed({
					params: action.payload,
					error: {
						errorCode: response.status,
					},
				})
			);
			yield put(QueueSnackbar(errorSnackbarProps));
			yield put(navigateBack());
		}
	} catch (error) {
		yield put(ReassignEmployer.failed({ params: action.payload, error }));
		yield put(QueueSnackbar(errorSnackbarProps));
		submitLog(Strings.Log.Error, `Failed to reassign employer`, {
			error,
			payload: action.payload,
		});
	}
}
function reassignEmployerClient(employerId: string, newAgentCode: string) {
	const options: HttpOptions = { method: 'POST' };
	let employerURL =
		'employer/reassign/' + employerId + '?newAgentCode=' + newAgentCode;
	return http(employerURL, options);
}

function* saveEmployerTagsSaga(action: Action<EmployerTagPayload>) {
	try {
		const body = JSON.stringify({ tags: action.payload.tags });
		const response = yield call(http, `employer/${action.payload.employerId}/tags`, { method: 'POST', body });
		if (response.ok) {
			const employer: FullEmployer = yield response.json();
			yield put(GetEmployer.done({ params: action.payload.employerId, result: employer }));
		} else {
			yield all([
				put(GetEmployer.failed({
					params: action.payload.employerId,
					error: { errorCode: response.status },
				})),
				put(QueueSnackbar(getSnackbarErrorProps('Unable to save tags to employer'))),
			]);
		}
	} catch (err) {
		yield all([
			put(GetEmployer.failed({
				params: action.payload.employerId,
				error: err,
			})),
			put(QueueSnackbar(getSnackbarErrorProps('Unable to save tags to employer'))),
		]);
	}
}

function* getEmployerTagsSaga(action: Action<string>) {
	try {
		if (action.payload) {
			const response = yield call(http, `employer/tags?search=${action.payload}`);
			if (response.ok) {
				const tags: TagDto[] = yield response.json();
				yield put(getEmployerTags.done({ params: action.payload, result: tags?.map(t => t.tag) }));
			} else {
				yield all([
					put(getEmployerTags.failed({
						params: action.payload,
						error: { errorCode: response.status },
					})),
					put(QueueSnackbar(getSnackbarErrorProps('Unable to get employer tags'))),
				]);
			}
		}
	} catch (err) {
		yield all([
			put(getEmployerTags.failed({
				params: action.payload,
				error: err,
			})),
			put(QueueSnackbar(getSnackbarErrorProps('Unable to get employer tags'))),
		]);
	}
}

// HELPER FUNCTIONS
export function hydrateEmployerLeadContacts(fullEmployer: FullEmployer) {
	if (!fullEmployer) return;
	const {
		leads,
		notes,
		activities,
		attachments,
		applications,
		policies,
		ownershipHistory,
		...employer
	} = fullEmployer;

	employer.contacts.forEach(contact => {
		contact.numberOfHouseholdMembers = employer.contacts.length - 1;
	})
	fullEmployer.leads.forEach(lead => {
		lead.contacts.forEach((contact, index) => {
			const contactIndex = employer.contacts.findIndex(_contact => {
				return _contact.id === contact.id;
			});

			if (contactIndex > -1) {
				lead.contacts[index] = employer.contacts[contactIndex];
			}
		});

		lead.employer = employer;
	});
	const primaryEmployee = employer.contacts.find(
		contact => contact.employerPrimaryContact
	);
	fullEmployer.activities.forEach(activity => {
		if (
			(!activity.contact || activity.contact.employerId == EMPTY_GUID) &&
			primaryEmployee
		)
			activity.contact = { ...primaryEmployee };
		else {
			const contact = fullEmployer.contacts.find(
				contact => contact.id == activity.contact.id
			);
			activity.contact = contact ? contact : activity.contact;
		}
	});
}

export function* employerSagas() {
	yield all([
		takeEveryForActionType(
			GetEmployer.started,
			getEmployerSaga
		),
		takeLatestForActionType(
			UpdateEmployer.started,
			updateEmployerSaga
		),
		takeLatestForActionType(
			SetPrimaryEmployee.started,
			setPrimaryEmployeeSaga
		),
		takeLatestForActionType(
			DeleteEmployer.started,
			deleteEmployerSaga
		),
		takeLatestForActionType(
			SearchEmployers.started,
			searchEmployersSaga
		),
		takeEveryForActionType(
			ReassignEmployer.started,
			startReassignEmployerSaga
		),
		takeLatestForActionType(
			saveEmployerTags.started,
			saveEmployerTagsSaga
		),
		debounce(
			600,
			getEmployerTags.started,
			getEmployerTagsSaga
		),
	]);
}
