import {
	Grid,
	MenuItem,
	TextField,
	RadioGroup,
	Radio,
	FormControlLabel,
} from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import {
	initialState as FilterInitialState,
	ProductFilters,
} from '../../reducers/product_filter_reducer';
import { DatePicker } from '../redux_form_material';
import { Strings } from '../../assets/common/strings';
import { ListFilter } from '../filter/list_filter';

interface Props extends ProductFilters {
	carrier: string[];
	lineOfBusinessTypeList: string[];
	product: string[];
	productDate: string[];
	productStatus: string[];
	productType: string[];
	productMatch: string[];
	onSearch: (filterValues: ProductFilters) => void;
	urlStatus: string;
	urlMatch: string;
	sortList: string[];
}

interface State extends ProductFilters {	
	toDateError: string;
	fromDateError: string;
}

export class ProductFilter extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			downlineAgentCode: (props.downlineAgentCode || ''),
			contactFilter: (props.contactFilter || ''),
			productNumberFilter: (props.productNumberFilter || ''),
			productCategoryFilter: (props.productCategoryFilter || 'policies'),
			carrierFilter: (props.carrierFilter || 'All'),
			lineOfBusinessFilter: (props.lineOfBusinessFilter || 'All'),
			productDateFilter: (props.productDateFilter || 'All'),
			productFilter: (props.productFilter || ''),
			productStatusFilter: (props.productStatusFilter || 'All'),
			productTypeFilter: (props.productTypeFilter || 'All'),
			productMatchFilter: (props.productMatchFilter || 'All'),
			fromDate: (
				props.fromDate ||
				moment()
					.subtract(12, 'm')
					.startOf('d')
					.toDate()
			),
			toDate: (
				props.toDate ||
				moment()
					.add(12, 'm')
					.endOf('d')
					.toDate()
			),
			sortBy: (props.sortBy || ''),
			sortCategory: (props.sortCategory || ''),
			sortDirection: (props.sortDirection || ''),			
			toDateError: '',
			fromDateError: '',
		};
	}

	createMenuItem = (options: string[], includeAllOption: boolean) => {
		const items = includeAllOption
			? ["All"].concat(options)
			: options;
		return items
			.filter(item => {
				return (
					item != 'Oldest to Newest by Created Date' &&
					item != 'Newest to Oldest by Created Date'
				);
			})
			.map((item: string, index: number) => {
				return (
					<MenuItem key={index + '_' + item} value={item}>
						{item}
					</MenuItem>
				);
			});
	};

	createTextField = (
		menuItemList: string[],
		filterItem: string,
		name: string,
		label: string,
		includeAllOption: boolean
	) => {
		return (
			<Grid item xs={12}>
				<TextField
					select
					onChange={this.handleFieldChange}
					fullWidth
					value={filterItem}
					label={label}
					name={name}
				>				
				  {this.createMenuItem(menuItemList || [],includeAllOption)}
				</TextField>
			</Grid>
		);
	};

	generateFilterString = () => {	
		const filters = this.state;
		const filterArray = [
		    filters.contactFilter,
			filters.productNumberFilter,
			filters.carrierFilter == Strings.PolicySearch.All ? '' : filters.carrierFilter,
			filters.lineOfBusinessFilter == Strings.PolicySearch.All ? '' : filters.lineOfBusinessFilter,
			filters.productDateFilter == Strings.PolicySearch.All ? '' : filters.productDateFilter,
			filters.productFilter,
			filters.productStatusFilter == Strings.PolicySearch.All ? '' : filters.productStatusFilter,
			filters.productTypeFilter == Strings.PolicySearch.All ? '' : filters.productTypeFilter,
			filters.productMatchFilter == Strings.PolicySearch.All ? '' : filters.productMatchFilter,
			].filter(Boolean);
		const final = filterArray.length > 0 ? filterArray.join(', ') : 'All Products';
		return final;			
	};

	handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		this.setState(state => ({ ...state, [name]: value } as Pick<State, keyof State>));
	}

	handleSearch = () => {
		const { isFilterOpen: toggleFilterDrawer, ...productFilters } = this.state;
		this.props.onSearch(productFilters);
	};

	handleReset = () => {
		this.setState(FilterInitialState);
	};

	cancelSearch = () => {
		this.setState(
			{
				contactFilter: (this.props.contactFilter || ''),
				productNumberFilter: (this.props.productNumberFilter || ''),
				carrierFilter: (this.props.carrierFilter || 'All'),
				lineOfBusinessFilter: (
					this.props.lineOfBusinessFilter || 'All'
				),
				productDateFilter: (this.props.productDateFilter || 'All'),
				productFilter: (this.props.productFilter || ''),
				productStatusFilter: (this.props.productStatusFilter || 'All'),
				productTypeFilter: (this.props.productTypeFilter || 'All'),
				productMatchFilter: (this.props.productMatchFilter || 'All'),
				productCategoryFilter: (this.props.productCategoryFilter || 'all'),
				fromDate: (
					this.props.fromDate ||
					moment()
						.subtract(12, 'm')
						.startOf('d')
						.toDate()
				),
				toDate: (
					this.props.toDate ||
					moment()
						.add(12, 'm')
						.endOf('d')
						.toDate()
				),
			}
		);
	};

	renderForm = () => {
		const dateFormat = 'YYYY-MM-DD';
		return (
			<Grid container>
				<Grid item xs={12}>
					<TextField
						name="contactFilter"
						label="Search for contact or employer"
						value={this.state.contactFilter}
						onChange={this.handleFieldChange}
						fullWidth
					/>
				</Grid>
				<Grid item xs={12} style={{ paddingTop: '10px' }}>
					<RadioGroup
						name="productCategoryFilter"
						value={this.state.productCategoryFilter}
						onChange={this.handleFieldChange}
					>
						<FormControlLabel value="applications" control={<Radio />} label="Applications" />
						<FormControlLabel value="policies" control={<Radio />} label="Policies" />
						<FormControlLabel value="all" control={<Radio />} label="Either" />
					</RadioGroup>
				</Grid>
				<Grid item xs={12}>
					<TextField
						name="productNumberFilter"
						label="Policy or Application Number"
						value={this.state.productNumberFilter}
						onChange={this.handleFieldChange}
						fullWidth
					/>
				</Grid>
				{this.createTextField(
					this.props.productStatus,
					this.state.productStatusFilter,
					'productStatusFilter',
					'Product Status', 
					true
				)}
				{this.createTextField(
					this.props.lineOfBusinessTypeList,
					this.state.lineOfBusinessFilter,
					'lineOfBusinessFilter',
					'Line of Business', true
				)}
				{this.createTextField(
					this.props.carrier,
					this.state.carrierFilter,
					'carrierFilter',
					'Carrier', true
				)}
				{this.createTextField(
					this.props.productType,
					this.state.productTypeFilter,
					'productTypeFilter',
					'Product Type', true
				)}
				{this.createTextField(
					this.props.productMatch,
					this.state.productMatchFilter,
					'productMatchFilter',
					'Product Match', true
				)}
				{this.createTextField(
					this.props.productDate,
					this.state.productDateFilter,
					'productDateFilter',
					'Select Date', true
				)}
				{this.state.productDateFilter ? (
					<Grid container>
						<Grid item xs={6}>
							<DatePicker
								label="From"
								input={{
									onChange: date =>
										this.setFrom(moment(date, dateFormat).startOf('day')),
									value: moment(this.state.fromDate).toDate(),
								}}
								fullWidth
								maxDate={moment(this.state.toDate).toDate()}
								meta={
									{
										error: this.state.fromDateError,
									} as any
								}
							/>
						</Grid>
						<Grid item xs={6}>
							<DatePicker
								label="To"
								input={{
									onChange: date =>
										this.setTo(moment(date, dateFormat).endOf('day')),
									value: moment(this.state.toDate).toDate(),
								}}
								fullWidth
								minDate={moment(this.state.fromDate).toDate()}
								meta={
									{
										error: this.state.toDateError,
									} as any
								}
							/>
						</Grid>
					</Grid>
				) : null}
				{this.createTextField(
					this.props.sortList,
					this.state.sortBy,
					'sortBy',
					'Sort', false
				)}
			</Grid>
		);
	};

	render() {
		const subtitle = this.generateFilterString();
		return (
			<ListFilter
				drawerTitle="Policy Search"
				onSearch={this.handleSearch}
				onReset={this.handleReset}
				onCancel={this.cancelSearch}
				cardSubtitle={subtitle}
				drawerSubtitle={subtitle}
				isSearchDisabled={!!(this.state.toDateError || this.state.fromDateError)}
			>
				{this.renderForm()}
			</ListFilter>
		);
	}

	setFrom = (date: moment.Moment) => {
		this.setState({
			fromDate: date.toDate(),
			fromDateError: date.isAfter(this.state.toDate)
				? 'From Date is after To Date'
				: '',
		});
	};

	setTo = (date: moment.Moment) => {
		this.setState({
			toDate: date.toDate(),
			toDateError: date.isBefore(this.state.fromDate)
				? 'To Date is before From Date'
				: '',
		});
	};
}
