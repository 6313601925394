import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { StackedGraphStats } from '../../reducers/teamview_reducer';

interface Props {
    stats: StackedGraphStats,
}

const YtdGraphHeader: React.FC<Props> = ({ stats }) => {
    const classes = useStyles();
    return (
        <Grid container>
            <Grid item xs className={classes.stats}>
                <span className={classes.label}>Actual</span>
                <span>{stats.actual}</span>
            </Grid>
            <Grid item xs className={classes.stats}>
                <span className={classes.label}>Rank</span>
                <span>{stats.rank}</span>
            </Grid>
            <Grid item xs className={classes.stats}>
                <span className={classes.label}>SL%</span>
                <span>{stats.slPercent}</span>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles({
	stats: {
        overflow: 'hidden',
        WebkitTextSizeAdjust: '100%',
        fontFamily: 'Roboto, sans-serif',
        fontSize: '14px',
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: 7,
        marginBottom: 7,
        padding: 10,
        cursor: 'pointer',
    },
    label: {
        marginRight: '10px',
    },
});

export default YtdGraphHeader;
